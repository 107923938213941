import { v4 as uuidv4 } from "uuid";
import {
  FormInputTypes,
  EditableTypes,
  ItemTypes,
} from "../../../shareable/types";
import {
  getDefaultRules,
  getDefaultRulesInlineFlex,
  getDefaultGallery,
  getImageDefault,
  getDefaultButton,
  getDefaultSocialComplete,
} from "../../helpers";

export const amazingGameDefault = {
  name: "Section principale",
  key: "amazing",
  inputs: {
    backgroundColor: {
      label: "Couleur de fond",
      type: FormInputTypes.COLOR,
      value: "#FFFFFF",
    },
    backgroundImage: {
      label: "Image de fond",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    backgroundImageTablet: {
      label: "Image de fond tablet",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    backgroundImageMobile: {
      label: "Image de fond mobile",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    className: {
      label: "Définir la ou les classes du container",
      type: FormInputTypes.TEXT,
      value: null,
    },
    paddingTop: {
      type: FormInputTypes.SLIDER,
      value: 25,
      label: "Padding Top (px)",
      params: { max: 200, min: 0, step: 1 },
    },
    paddingBottom: {
      type: FormInputTypes.SLIDER,
      value: 25,
      label: "Padding Bottom (px)",
      params: { max: 200, min: 0, step: 1 },
    },
  },
  blocks: {
    insideBlock: {
      id: uuidv4(),
      name: "Bloc à compléter",
      type: EditableTypes.AMAZING_BLOCKS,
      inputs: {
        backgroundColor: {
          label: "Couleur de fond",
          type: FormInputTypes.COLOR,
          value: "#F0F7FA",
        },
        backgroundImage: {
          label: "Image de fond",
          type: FormInputTypes.IMAGE,
          value: null,
        },
        border: {
          type: FormInputTypes.GROUP,
          label: "Border",
          inputs: {
            borderColor: {
              type: FormInputTypes.COLOR,
              value: "#000",
              label: "Couleur de la bordure",
            },
            borderWidth: {
              type: FormInputTypes.SLIDER,
              value: 0,
              label: "Largeur de la bordure :",
              params: { max: 5, min: 0, step: 1 },
            },
            borderRadius: {
              type: FormInputTypes.SLIDER,
              value: 0,
              label: "Arrondi des angles :",
              params: { max: 50, min: 0, step: 1 },
            },
          },
        },
        maxWidth: {
          type: FormInputTypes.SLIDER,
          value: 1080,
          label: "Taille maximale (px)",
          params: { max: 1920, min: 360, step: 10 },
        },
        width: {
          type: FormInputTypes.SLIDER,
          value: 90,
          label: "Taille (%)",
          params: { max: 100, min: 20, step: 1 },
        },
        paddingTop: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Padding Top (px)",
          params: { max: 200, min: 0, step: 1 },
        },
        paddingBottom: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Padding Bottom (px)",
          params: { max: 200, min: 0, step: 1 },
        },
      },
      blocks: [],
    },
  },
};

export const titleAmazingGameDefault = {
  type: EditableTypes.SHORT_TEXT,
  name: "Titre",
  id: uuidv4(),
  key: "title",
  inputs: {
    value: {
      type: FormInputTypes.TEXT,
      label: "Changer le texte",
      value: "Titre",
    },
    size: {
      type: FormInputTypes.SLIDER,
      value: 26,
      label: "Taille du texte",
      params: { max: 100, min: 8, step: 2 },
    },
    color: {
      type: FormInputTypes.COLOR,
      value: "#000000",
      label: "Couleur du texte",
    },
    backgroundColor: {
      type: FormInputTypes.COLOR,
      value: "#FFFFFF",
      label: "Couleur de fond",
    },
    backgroundImage: {
      label: "Image de fond",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    textTransform: {
      type: FormInputTypes.SELECT,
      label: "Transformation du texte",
      value: "initial",
      params: [
        { label: "Initial", value: "initial" },
        { label: "Tout Majuscule", value: "uppercase" },
        { label: "Tout Minuscule", value: "lowercase" },
      ],
    },
    textAlign: {
      type: FormInputTypes.SELECT,
      label: "Alignement du texte",
      value: "center",
      params: [
        { label: "Initial", value: "initial" },
        { label: "Centré", value: "center" },
        { label: "Droite", value: "end" },
      ],
    },
    maxWidth: {
      type: FormInputTypes.SLIDER,
      value: 1080,
      label: "Taille maximale (px)",
      params: { max: 1920, min: 360, step: 10 },
    },
    width: {
      type: FormInputTypes.SLIDER,
      value: 90,
      label: "Taille (%)",
      params: { max: 100, min: 20, step: 1 },
    },
    padding: {
      type: FormInputTypes.GROUP,
      label: "Padding",
      inputs: {
        paddingTop: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Padding Top (px)",
          params: { max: 200, min: 0, step: 1 },
        },
        paddingBottom: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Padding Bottom (px)",
          params: { max: 200, min: 0, step: 1 },
        },
      },
    },
    margin: {
      type: FormInputTypes.GROUP,
      label: "Margin",
      inputs: {
        marginTop: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Margin Top (px)",
          params: { max: 200, min: 0, step: 1 },
        },
        marginBottom: {
          type: FormInputTypes.SLIDER,
          value: 25,
          label: "Margin Bottom (px)",
          params: { max: 200, min: 0, step: 1 },
        },
      },
    },
    border: {
      type: FormInputTypes.GROUP,
      label: "Border",
      inputs: {
        borderColor: {
          type: FormInputTypes.COLOR,
          value: "#000",
          label: "Couleur de la bordure",
        },
        borderWidth: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Largeur de la bordure :",
          params: { max: 5, min: 0, step: 1 },
        },
        borderRadius: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Arrondi des angles :",
          params: { max: 50, min: 0, step: 1 },
        },
      },
    },
    decoration: {
      type: FormInputTypes.SELECT,
      label: "Décoration du texte",
      value: "none",
      params: [
        { label: "Aucune", value: "none" },
        { label: "Entre des lignes", value: "betweenLine" },
        { label: "Souligné", value: "underline" },
      ],
    },
  },
};

export const paragraphAmazingGameDefault = {
  type: EditableTypes.SHORT_TEXT,
  name: "Paragraph",
  id: uuidv4(),
  key: "paragraph",
  inputs: {
    value: {
      type: FormInputTypes.TEXT,
      label: "Changer le texte",
      value: "Texte",
    },
    className: {
      type: FormInputTypes.TEXT,
      label: "Ajouter une classe",
      value: "",
    },
    size: {
      type: FormInputTypes.SLIDER,
      value: 16,
      label: "Taille du texte",
      params: { max: 100, min: 8, step: 2 },
    },
    color: {
      type: FormInputTypes.COLOR,
      value: "#000000",
      label: "Couleur du texte",
    },
    backgroundColor: {
      type: FormInputTypes.COLOR,
      value: "rgba(0,0,0,0)",
      label: "Couleur de fond",
    },
    backgroundImage: {
      label: "Image de fond",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    textTransform: {
      type: FormInputTypes.SELECT,
      label: "Transformation du texte",
      value: "initial",
      params: [
        { label: "Initial", value: "initial" },
        { label: "Tout Majuscule", value: "uppercase" },
        { label: "Tout Minuscule", value: "lowercase" },
      ],
    },
    textAlign: {
      type: FormInputTypes.SELECT,
      label: "Alignement du texte",
      value: "center",
      params: [
        { label: "Initial", value: "initial" },
        { label: "Centré", value: "center" },
        { label: "Droite", value: "end" },
      ],
    },
    maxWidth: {
      type: FormInputTypes.SLIDER,
      value: 1920,
      label: "Taille maximale (px)",
      params: { max: 1920, min: 100, step: 10 },
    },
    width: {
      type: FormInputTypes.SLIDER,
      value: 100,
      label: "Taille (%)",
      params: { max: 100, min: 20, step: 1 },
    },
    padding: {
      type: FormInputTypes.GROUP,
      label: "Padding",
      inputs: {
        paddingTop: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Padding Top (px)",
          params: { max: 200, min: 0, step: 1 },
        },
        paddingBottom: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Padding Bottom (px)",
          params: { max: 200, min: 0, step: 1 },
        },
      },
    },
    margin: {
      type: FormInputTypes.GROUP,
      label: "Margin",
      inputs: {
        marginTop: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Margin Top (px)",
          params: { max: 200, min: 0, step: 1 },
        },
        marginBottom: {
          type: FormInputTypes.SLIDER,
          value: 0,
          label: "Margin Bottom (px)",
          params: { max: 200, min: 0, step: 1 },
        },
      },
    },
  },
};

export const rulesAmazingGameDefault = {
  id: uuidv4(),
  name: "Bloc d'explication",
  type: EditableTypes.MINI_BLOCKS,
  key: "rules",
  blocks: {
    rules: {
      title: "Règles",
      name: "Règles",
      type: EditableTypes.MULTIPLE_BLOCKS,
      subtype: ItemTypes.RULE,
      limit: 6,
      config: [
        getDefaultRules("", "Règle 1"),
        getDefaultRules("", "Règle 2"),
        getDefaultRules("", "Règle 3"),
      ],
    },
  },
};
export const galleryAmazingGameDefault = {
  id: uuidv4(),
  name: "Bloc gallery",
  type: EditableTypes.MINI_BLOCKS,
  key: "gallery",
  blocks: {
    gallery: {
      title: "Galerie",
      name: "Galerie",
      type: EditableTypes.MULTIPLE_BLOCKS,
      subtype: ItemTypes.GALLERY_GAME,
      limit: 8,
      config: [
        getDefaultGallery("", ""),
        getDefaultGallery("", ""),
        getDefaultGallery("", ""),
      ],
    },
  },
};

export const rulesInlineFleAmazingGameDefault = {
  id: uuidv4(),
  name: "Règles en ligne sans image",
  type: EditableTypes.MINI_BLOCKS,
  key: "rules-inline-flex",
  blocks: {
    rules: {
      title: "Règles",
      name: "Règles",
      type: EditableTypes.MULTIPLE_BLOCKS,
      subtype: ItemTypes.RULES_INLINE_FLEX_AMAZING_GAME,
      limit: 3,
      config: [getDefaultRulesInlineFlex()],
    },
  },
};

export const oneImageAmazingGameDefault = {
  id: uuidv4(),
  name: "Bloc d'image",
  type: EditableTypes.IMAGE,
  key: "one-image",
  inputs: {
    image: {
      label: "Image desktop",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    imageTablet: {
      label: "Image tablet",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    imageMobile: {
      label: "Image mobile",
      type: FormInputTypes.IMAGE,
      value: null,
    },
    link: {
      label: "Lien",
      type: FormInputTypes.TEXT,
      value: "",
    },
    maxWidth: {
      type: FormInputTypes.SLIDER,
      value: 1080,
      label: "Taille maximale (px)",
      params: { max: 1920, min: 100, step: 10 },
    },
    width: {
      type: FormInputTypes.SLIDER,
      value: 90,
      label: "Taille (%)",
      params: { max: 100, min: 20, step: 1 },
    },
    paddingTop: {
      type: FormInputTypes.SLIDER,
      value: 25,
      label: "Padding Top (px)",
      params: { max: 200, min: 0, step: 1 },
    },
    paddingBottom: {
      type: FormInputTypes.SLIDER,
      value: 25,
      label: "Padding Bottom (px)",
      params: { max: 200, min: 0, step: 1 },
    },
    alignItem: {
      type: FormInputTypes.SELECT,
      label: "Alignement de l'élément",
      value: "center",
      params: [
        { label: "Initial", value: "flex-start" },
        { label: "Centré", value: "center" },
        { label: "Droite", value: "flex-end" },
      ],
    },
  },
};

export const socialListAmazingGameDefault = {
  id: uuidv4(),
  name: "Social bloc",
  type: EditableTypes.MINI_BLOCKS,
  key: "social-list",
  inputs: {
    styleDisplay: {
      type: FormInputTypes.SELECT,
      label: "Positionnement",
      value: "iconText",
      params: [
        { label: "Icone + Texte", value: "iconText" },
        { label: "Image", value: "image" },
        { label: "Texte", value: "texte" },
      ],
    },
  },
  blocks: {
    socials: {
      title: "Socials",
      name: "Socials",
      type: EditableTypes.MULTIPLE_BLOCKS,
      subtype: ItemTypes.SOCIAL_COMPLETE,
      limit: 3,
      config: [getDefaultSocialComplete(), getDefaultSocialComplete()],
    },
  },
};

export const buttonAmazingGameDefault = getDefaultButton(
  "Boutton",
  "C'est parti",
  20,
  "#F0F7FA",
  "#53839D",
  1,
  "rgba(255,255,255,1)",
  0,
  "",
  10,
  20,
  "initial",
  null,
  "center"
);
