import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCategorie2, getDefaultTextareaCms, getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc textes multiples',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: 'FFFFFF',
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Top (px)",
            params: {max: 200, min: 0, step: 1},
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Bottom (px)",
            params: {max: 200, min: 0, step: 1},
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
        gridNumber: {
            type: FormInputTypes.SLIDER,
            value: 3,
            label: "Nombre d'image par ligne",
            params: {max: 5, min: 1, step: 1},
        },
        gridGap: {
            type: FormInputTypes.SLIDER,
            value: 20,
            label: "Espace entre les images (px)",
            params: {max: 200, min: 0, step: 1},
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre de la section", '#000000', 20, 'px',null,true),
        texts:{
            title: "Textes",
            name: "Textes",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_TEXTAREA,
            buttonAdd: 'un bloc de texte',
            limit: 9,
            config: [
                getDefaultTextareaCms("Contenu", "Sed mi quam, dictum at malesuada ", true,"#000",true,null,true,20,"px",null),
            ]
        }
    }
};