import React, { useEffect } from 'react';
import { eventService } from '../../../js/services/event.service';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import Button from '../button/Button';

const ListCustom = styled(List)`
    padding: 1rem;
`;
const ListItemCustom = styled(ListItem)`
    padding: 0px 11px 0px 11px !important;
    cursor: pointer;
`;
const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    width: 42px;
    padding: 0 9px;
    &:hover{
        background: unset !important;
    }
    input{
        width: 40px;
    }
    svg{
        fill: ${colors.grey.regular}
    }
`;
const WrapperAttributs = styled(Grid)`
    background: ${colors.white};
    border: 1px solid ${colors.grey.lighter.hue900};
    border-radius: 5px;
    min-height: 150px;
    max-height: 350px;
    overflow: auto;
    margin-top: 12px;
    padding-bottom: 8px;
`;
const TitleGroup = styled(Typography)`
    color: ${colors.black.regular}
    font-size: 16px;
`;
const TextAttribut = styled(Typography)`
    text-align: center;
    color: ${colors.black.regular}
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: 'rgb(250, 251, 251)',
    },
}));

export default function CheckboxAttributs(props) {
    const classes = useStyles();

    const handleToggle = (value) => {
        const currentIndex  = props.allState.attributesSelected.indexOf(value.node.id);
        const newChecked    = [...props.allState.attributesSelected];

        if (currentIndex === -1) {
            newChecked.push(value.node.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        props.stateCallback('attributesSelected', newChecked)
    };

    const selectAll = (group, allSelected) => {
        let newChecked    = [...props.allState.attributesSelected];
        if (!allSelected){
            for (let attribut of group){
                const currentIndex  = props.allState.attributesSelected.indexOf(attribut.node.id);
                if (currentIndex === -1) {
                    newChecked.push(attribut.node.id);
                }
            }
        }else{
            for (let attribut of group){
                // const currentIndex  = props.allState.attributesSelected.indexOf(attribut.node.id);
                newChecked = newChecked.filter(e => e !== attribut.node.id);
            }
        }
        props.stateCallback('attributesSelected', newChecked)
    }

    const [seeError, setSeeError]                   = React.useState(false);
    const [errorMessage, setErrorMessage]           = React.useState(null);

    const checkError = (value = null) => {
        let val     = value === null ? props.allState.attributesSelected : value;
        let error   = false;
        let message = null;
        let type = null;
        if (val?.length === 0 && props.optionsInputs.required) {
            error   = true;
            message = `Veuillez sélectionner au moins un attribut`;
        }
        if (val){
            setSeeError(false);
        }

        setErrorMessage(error ? message : null);  
        if (props.errorCallback){  
            props.errorCallback(props.optionsInputs?.stateName,error);
        }
    };

    useEffect(() => {
        checkError(); 

        let subscription = eventService.get().subscribe((data) => {
            setSeeError(true);
            if (data && props.optionsInputs.stateName === data.stateName) {
                setErrorMessage(data.errorMessage);
                setSeeError(true)
            }
        });

        return () => subscription.unsubscribe();
    }, [props.allState.attributesSelected]);

    return (
        <ListCustom dense className={classes.root}>
            <Grid container direction="row">
                {
                    props.optionsInputs?.value.length > 0 ?
                        props.optionsInputs?.value.map((group,index) =>{
                            let nameGroup = group.node?.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                            let allCheck = [];
                            for (let getAttr of group.node.attributes?.edges){
                                const currentIndex  = props.allState.attributesSelected.indexOf(getAttr.node.id);
                                if (currentIndex === -1) {
                                    allCheck.push(false);
                                }else{
                                    allCheck.push(true);
                                }
                            }
                            let allSelected = allCheck.every(v => v === true);
                            return(
                                <Grid container style={{marginTop: index > 0 ? 32 : 8}} key={`GridCheckboxGroupAttribut${index}`}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <TitleGroup>{nameGroup ? nameGroup.node.value : group.node.identifier}</TitleGroup>
                                        {
                                            group.node.attributes?.edges.filter(e => e.node.status).length > 0 ?
                                                <Button style={{margin: 0}} bgcolor={allSelected ? colors.red.regular : null} bgcolorhover={allSelected ? colors.red.darker : null} onClick={() => selectAll(group.node.attributes?.edges, allSelected)}>{allSelected ? 'Tout désélectionner' : 'Tout sélectionner'}</Button>
                                            : null
                                        }
                                    </Grid>
                                    <WrapperAttributs container>
                                        {group.node.attributes?.edges.filter(e => e.node.status).length > 0 ?
                                            group.node.attributes.edges.filter(e => e.node.status).map((attribut, index) =>{
                                                let nameValue = attribut.node.translation.translationDatas.edges.find(e => e.node.locale.code === props.allState.currentLang)
                                                const labelId   = `checkbox-list-secondary-label-${attribut.node.id}`;
                                                return (
                                                    attribut.node.status ? 
                                                    (
                                                        <Grid container key={`ItemCheckbox${index}`} style={{marginTop: 8}}>
                                                            <ListItemCustom onClick={() => handleToggle(attribut)}>
                                                                <Grid container direction="row" alignItems='center'>
                                                                    <CheckboxCustom
                                                                        edge="start"
                                                                        checked={(props.allState.attributesSelected?.indexOf(attribut.node.id) ?? -1) !== -1}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                    />
                                                                    <TextAttribut variant ={'body2'}>
                                                                        {nameValue ? nameValue.node.value : attribut.node.identifier}
                                                                    </TextAttribut>
                                                                </Grid>
                                                            </ListItemCustom>
                                                        </Grid>
                                                    ) : null
                                                );
                                            })
                                        : <TitleGroup>Pas d'attributs</TitleGroup>
                                        }
                                    </WrapperAttributs>
                                </Grid>
                            )
                        })
                    : null
                }
                {
                    !seeError ? null : 
                    <span className="error-label" style={{marginLeft: 10}}>{ errorMessage }</span>
                }
            </Grid>
        </ListCustom>
    );
}