import React from 'react';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import colors from '../../../config/theme/colors';
import Button from '../../../components/ui/button/Button';
import mediaPickerConfig from '../../../components/ui/form/config/mediaPicker.config';
import LayoutBuilder from '../../../components/ui/form/LayoutFormBuilder';
import Cropper from 'react-cropper';
import CheckIcon from '@material-ui/icons/Check';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { b64ToBlob, extractExtensionFromB64, extractMimeTypeFromB64 } from '../../../js/utils/image';
import Axios from '../../../js/utils/axios';
import { VIDEOS, ALLOWED } from '../../../js/constants/medias-types';

const styles = {
  root: {
    width: '100%',
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    margin: '0 auto 5px',
    backgroundColor: colors.blue.lighter.hue900,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    flexDirection: 'column'
  },
  cropperContainer: {
    position: 'relative'
  },
  cropper: {
    height: 'auto',
    width: '100%'
  },
  cropperButton: {
    position: 'absolute',
    top: 10,
    left: 10
  },
  validateButton: {
    position: 'absolute',
    top: 10,
    left: 60
  }
};

class ImagePicker extends React.Component {
  state = {
    mediasOpen: false,
    hover: false
  };

  cropper = null;

  handleClick = () => {
    this.setState({ mediasOpen: true });
  };

  onSelect = (media) => {
    this.props.onChange(media, media);
    this.setState({ mediasOpen: false });
  };

  crop = () => {
    // console.log(this.cropper.getImageData());
    // console.log(this.cropper.getCanvasData());
    // console.log(this.cropper.getCropBoxData());
    // console.log(this.cropper.getData());
  };

  onCropperInit = (cropper) => {
    this.cropper = cropper;
  };

  render() {
    const { value, valueRef, isCroppable } = this.props;

    // const isVideo = () => background: `url(${
    //   DOCUMENTS.concat(VIDEOS).includes(value?.type)  
    //         ? getFileConfig(value?.type).image 
    //         : value?.data 
    //             ? process.env.REACT_APP_API_ROOT+'/medias/'+value.data
    //             : value?.filePath 
    //                 ? process.env.REACT_APP_MEDIAS+'/'+value.filePath 
    //                 : `${colors.grey.lighter.hue900}`
    // }) no-repeat center`;
    let isVideo = VIDEOS.includes(value?.type) ? true : false
    return (
      <>
        {
          valueRef && isCroppable ? (
            <>
              <div style={styles.cropperContainer}>
                <Cropper
                  src={typeof valueRef === 'string' ? `${valueRef}` : `${process.env.REACT_APP_MEDIAS}/${valueRef?.filePath}`}
                  style={styles.cropper}
                  checkCrossOrigin={true} // headers should be on the image
                  checkOrientation={false}
                  guides={true}
                  crop={this.crop.bind(this)}
                  onInitialized={this.onCropperInit.bind(this)}
                  preview=".img-preview"
                  viewMode={2}
                  autoCropArea={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  cropend={() => {
                    let canvas = this.cropper.getCroppedCanvas();

                    try {
                      let b64 = canvas.toDataURL();
                      this.props.onChange(b64, valueRef);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                />

                <Fab
                  color="success"
                  aria-label="add"
                  size={'small'}
                  style={styles.cropperButton}
                  onClick={async () => {
                    if (typeof value === 'string') {
                      let formData = new FormData();

                      let ext = extractExtensionFromB64(value);
                      let mime = extractMimeTypeFromB64(value);
                      let blob = await b64ToBlob(value);

                      formData.append('file', blob, `crop.${ext}`);
                      formData.append('type', mime);
                      formData.append('is_internal', true);
                      formData.append('origin', 'component');

                      let resultMedia = await Axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);

                      this.props.onChange(resultMedia, null);
                    } else {
                      this.props.onChange(value, null);
                    }
                  }}
                >
                  <CheckIcon />
                </Fab>
              </div>

              <div className='img-preview' style={{ top: 0 }}></div>
            </>
          ) : (
            <>
              <div style={{
                ...styles.root,
                backgroundImage: typeof value === 'string' ? `url(${value})` : `url(${process.env.REACT_APP_MEDIAS}/${value?.filePath})`,
                display: "flex",
                flexDirection: 'row',
              }}>
                {value ? (
                  <Fab color="primary" aria-label="add" onClick={this.handleClick} size={'small'} style={{ borderRadius: 0 }}>
                    {isVideo ?
                      <PlayCircleFilledIcon />
                      :
                      <EditIcon />

                    }
                  </Fab>
                ) : (
                  <WallpaperIcon style={{ fontSize: 80, color: colors.blue.lighter.hue600 }} />
                )
                }
                {value && this.props.onDelete ? (
                  <Fab color="error" aria-label="add" onClick={this.props.onDelete} size={'small'} style={{ borderRadius: 0, marginLeft: 8 }}>
                    <DeleteIcon />
                  </Fab>
                ) : null
                }
              </div>
              {
                !value ? (
                  <Button
                    onClick={this.handleClick}
                    text={'Charger une photo'}
                    colortext={colors.blue.lighter.hue300}
                    bgcolor="transparent"
                    shadowcolor={colors.green.darker}
                    style={{ margin: 0, background: 'transparent', boxShadow: 'inherit', top: 0, textTransform: 'initial', textDecoration: 'underline', color: colors.blue.lighter.hue300 }}
                  />
                ) : null
              }
            </>
          )
        }

        <LayoutBuilder
          isSublayout={false}
          opened={this.state.mediasOpen}
          forClose={() => this.setState({ mediasOpen: false })}
          dataLayout={mediaPickerConfig(this.onSelect, false, false, false, false, ALLOWED)}
          isBuilder={true}
        />
      </>
    );
  }
}

export default ImagePicker;