import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H1 from '../../../../shared/components/CustomH1';
import { useDrop } from 'react-dnd';

// Who Are We

const OfferSectionTitle = (props) => {
    const [hover, setHover] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
        data.inputs['backgroundImage'].value = item.media.node;
        update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: !preview ? '#eee' : '#fff',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        minHeight: '700px',
        maxHeight: '880px',
        marginBottom: 20
    };

    let image = data.inputs['sideImage'].value;

    return (
        <div
            className={`offer-template-scope`}  
            ref={drop} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            style={{
                ...style,
            }}
        >
            { !preview && props.children }
            <div className="container" style={{height: '100%', display: 'flex'}}>
                <div style={{width: '70%'}}>
                    <div className="text-left" 
                        style={{
                            height: '100%',
                            backgroundColor: data.inputs['backgroundColor'].value,
                            paddingTop: data.paddingTop, 
                            paddingBottom: data.paddingBottom,
                            paddingLeft: 100,
                            paddingRight: 50,
                        }}
                    >
                        <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                        <Pg data={data} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                    </div>
                </div>
                <div style={{height: '100%', width: '30%', overflow: 'hidden'}}>
                    <img src={typeof image === 'string' ? `${image}` : image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : 'https://via.placeholder.com/333x880/F0F7FA/C5DEEC'} style={{height: '100%'}}/>
                </div>
            </div>
        </div>
    );
};

OfferSectionTitle.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default OfferSectionTitle;
