import React, {useState, useEffect} from 'react';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import {Grid, Typography, Box} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import imgNotFound from '../../../../assets/images/not-found.png';
import { withTranslation } from 'react-i18next';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`
const BoxImage = styled(Box)`
    img{
        filter : ${props => props.status === 'disabled' ? 'grayscale(1)' : 'grayscale(0)'};
        opacity: ${props => props.status === 'disabled' ? '0.3' : '1'};
    }
`
const BoxCheck = styled(Box)`
    position: absolute;
    width: 15px;
    height: 15px;
    top:0;
    bottom:0;
    left:0;
    right:0;
    svg{
        width: 100%;
        height: auto;
        fill: ${colors.white}
    }
`
const ContainerClickable = styled(Grid)`
    cursor: pointer;
`

const Title = styled(Typography)`
    // color: ${colors.blue.darker.hue300};
`

const Subtitle = styled(Typography)`
    color: ${colors.grey.regular};
`
const StatusMinisite = styled.span`
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    background: ${
        props => props.status === 'disabled' 
            ? colors.grey.lighter.hue900 
            : props.status === 'incomplete' 
                ? colors.orange.regular
                : colors.green.regular
    }
`

const BlockMinisite = (props) => {
    const [ title, setTitle ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ image, setImage ] = useState('');
    
    useEffect(() => {
        SettingDatas(props.data.node, props.type)
    }, [])

    const getBlockName = (libelle) => {
        switch (libelle) {
            case "Product_details": return props.t("spread.active_assets.productDetails");
            case "Email_contact": return "Email contact";
            case "Email_wishlist": return "Email wishlist";
            default: return libelle;
        }
    };

    function SettingDatas(data, type){
        let imageChannel = null;
        const requireImages = require.context('../../../../assets/images', true);
        try{
            if (data.assetGamePageType){
                imageChannel    = requireImages('./' + data.assetGamePageType.image);
            }else{
                if(type === 'flipbook' || type === "flipbook_with_cart"){
                    if(data.assetMinisitePageType.identifier === 'home'){
                        let image = data.assetMinisitePageType.image;
                        image = image.replace('minisite', 'flipbook');
                        imageChannel    = requireImages('./' + image);
                    }
                    else{
                        imageChannel    = requireImages('./' + data.assetMinisitePageType.image);
                    }
                }else{
                    imageChannel    = requireImages('./' + data.assetMinisitePageType.image);
                }
            }
        } catch(e){
            imageChannel=imgNotFound;
        }
        setTitle(getBlockName(data.libelle));
        setStatus(data.status === '0' ? 'disabled' : 'completed');
        setImage(imageChannel);
    }

    return (
        <GridCustom item xs={3}>
            <ContainerClickable container alignItems={"center"} justifyContent={"center"} onClick={() => {props.onClick()}}>
                <Grid item xs={12} style={{transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <BoxImage textAlign={"center"} status={status}>
                        <img src={typeof image === "string" ? image : image?.default} width={"100%"}/>
                    </BoxImage>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign={"center"} mt={4}>
                        <Title variant={"h3"}>
                            {title}
                        </Title>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign={"center"} mt={1}>
                        <Subtitle variant={"body2"}>
                            {props.t("spread.active_assets.manageYourPage")}
                        </Subtitle>
                    </Box>
                </Grid>
                <Box textAlign={"center"} mt={2}>
                    {/* <Button bgcolor={colors.blue.darker.hue300} shadowcolor={colors.blue.darker.hue300} text={textButton ? `${textButton}` : null} onClick={onClick} className="button" disabled={openForm} /> */}
                    <StatusMinisite status={status}>
                        {
                            status === 'completed' ? <BoxCheck><CheckIcon/> </BoxCheck>: null
                        }
                    </StatusMinisite>
                </Box>
            </ContainerClickable>
        </GridCustom>
    );
};

export default withTranslation()(BlockMinisite);