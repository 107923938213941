import React, { Fragment, useContext, useEffect, useMemo } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Context } from '../../../../../store';
import notFound from '../../../../../not-found.png';
import H3 from "../../../../shared/components/CustomH3";
import PG from "../../../../shared/components/CustomParagraph";
import CustomButton from '../../../../shared/components/CustomButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const LinkCustom = (props) => {
  if (props.spread) {
    return <>
      {props.children}
    </>
  } else {
    return <Link href={props.href} as={props.as} shallow={true}>{props.children}</Link>
  }
}

const Wishlist = ({
  preview = false,
  id = null,
  data = null,
  inputCallback = null,
  spread = false,
  products = [],
  children = null,
  params = null
}) => {
  const context = useContext(Context);
  const router = useRouter();

  const [items, setItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [departement, setDepartement] = React.useState('');
  const [submitedOnce, setSubmitedOnce] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  useEffect(() => {
    let wishlistItems = products.filter(e => context.products?.indexOf(e.id) > -1);

    if (spread)
      wishlistItems = products?.slice(0, 1) ?? [];

    setItems(wishlistItems);
  }, [context, products]);

  const formatted = useMemo(() => {
    return items.map(product => {
      let allNames = product.attributes.product_name;
      let name = allNames?.['fr_FR'] ?? 'n/a';

      let allPrices = product.attributes.product_price;
      let price = allPrices?.['fr_FR'] ?? 'n/a';

      let allImages = product?.attributes.product_image;
      let image = allImages?.['fr_FR'] ?? null;

      return {
        id: product.id,
        name,
        image,
        price
      };
    });
  }, [items]);

  const close = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setCompany('');
    setDepartement('');
    setOpen(false);
    setSubmitedOnce(false);
  };

  const showPrice = params?.displayPrices?.value;
  const documentAttributeIdentifier = params?.documentAttribute?.value;

  return (
    <div
      className="builder-template-scope cart-main-area"
      style={{
        width: '100%',
        marginBottom: 20
      }}
    >
      {!preview && children}

      <div className="container pt-40 pb-20">
        {formatted && formatted.length >= 1 ? (
          <Fragment>
            <H3
              data={data}
              preview={preview}
              component={id}
              block={'title'}
              inputCallback={inputCallback}
              nopadding={true}
              className="mb-15 cart-page-title"
            />
            <div className="row">
              <div className="col-12">
                <div className="table-content table-responsive cart-table-content">
                  <table>
                    <thead>
                      <tr>
                        <th>{data.inputs?.['productImage']?.value}</th>
                        <th>{data.inputs?.['productName']?.value}</th>
                        {showPrice ? <th>{data.inputs?.['productPrice']?.value}</th> : null}
                        <th>{data.inputs?.['productAction']?.value}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formatted.map((wishlistItem, key) => {
                        return (
                          <tr key={key}>
                            <td className="product-thumbnail">
                              <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${wishlistItem?.id.toString().replace('/api/products/', '')}`}>
                                <a>
                                  <img
                                    className="img-fluid"
                                    src={wishlistItem.image ? `${process.env.REACT_APP_MEDIAS}/${wishlistItem.image}` : notFound}
                                    alt={wishlistItem.name}
                                  />
                                </a>
                              </LinkCustom>
                            </td>

                            <td className="product-name text-center">
                              <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${wishlistItem?.id.toString().replace('/api/products/', '')}`}>
                                <a>
                                  {wishlistItem.name}
                                </a>
                              </LinkCustom>
                            </td>

                            {showPrice ? (
                              <td className="product-price-cart">
                                <span className="amount">
                                  {wishlistItem.price} €
                                </span>
                              </td>
                            ) : null}

                            <td className="product-remove">
                              <button onClick={() => {
                                let all = context.products;

                                if (all) {
                                  all = all.filter(e => e !== wishlistItem.id);
                                  context.update(all);
                                }

                                localStorage.setItem('products', JSON.stringify(all || null));

                                let wishlistItems = products.filter(e => all?.indexOf(e.id) > -1);

                                setItems(wishlistItems);
                              }}>
                                <i className="fa fa-times"></i> Retirer
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="cart-shiping-update-wrapper">
                  <div className="cart-shiping-update">
                    <CustomButton
                      data={data}
                      preview={preview}
                      component={id}
                      block={'buttonSend'}
                      inputCallback={inputCallback}
                      nopadding={true}
                      type="button"
                      className="mb-20 button"
                      dataToggle="modal"
                      dataTarget="#exampleModal"
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  </div>
                  <div className="cart-clear">
                    <CustomButton
                      data={data}
                      preview={preview}
                      component={id}
                      block={'buttonClear'}
                      inputCallback={inputCallback}
                      nopadding={true}
                      type="button"
                      className="mb-20 button"
                      onClick={() => {
                        context.update([]);
                        localStorage.setItem('products', JSON.stringify([]));
                        setItems([]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div className="item-empty-area text-center">
                <div className="item-empty-area__icon mb-30">
                  <i className="pe-7s-like"></i>
                </div>
                <div className="item-empty-area__text">
                  <PG
                    data={data}
                    preview={preview}
                    component={id}
                    block={'noResult'}
                    inputCallback={inputCallback}
                    nopadding={true}
                  />
                  <br />{" "}
                  <CustomButton
                    data={data}
                    preview={preview}
                    component={id}
                    block={'buttonAdd'}
                    inputCallback={inputCallback}
                    type="button"
                    className="mb-20 button"
                    onClick={() => {
                      if (!spread && router)
                        router.push('/');
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Téléchargement des documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour télécharger les documents, veuillez remplir les informations suivantes :
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="firstname"
            label="Prénom"
            type="text"
            fullWidth
            required={true}
            value={firstname}
            onChange={(value) => setFirstname(value.target.value)}
            error={submitedOnce && !firstname}
          />
          <TextField
            margin="dense"
            id="lastname"
            label="Nom"
            type="text"
            fullWidth
            required={true}
            value={lastname}
            onChange={(value) => setLastname(value.target.value)}
            error={submitedOnce && !firstname}
          />
          <TextField
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            required={true}
            value={email}
            onChange={(value) => setEmail(value.target.value)}
            error={submitedOnce && !firstname}
          />
          <TextField
            margin="dense"
            id="company"
            label="Société"
            type="text"
            fullWidth
            value={company}
            onChange={(value) => setCompany(value.target.value)}
          />
          <TextField
            margin="dense"
            id="departement"
            label="Département"
            type="text"
            fullWidth
            value={departement}
            onChange={(value) => setDepartement(value.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Annuler
          </Button>
          <Button onClick={async () => {
            if (saving)
              return;

            setSubmitedOnce(true);

            if (!firstname || !lastname || !email)
              return;

            setSaving(true);

            let links = [];

            for (let item of items) {
              let file = item.attributes?.[documentAttributeIdentifier]?.fr_FR;

              if (file)
                links.push(`${process.env.REACT_APP_MEDIAS}/${file}`);
            }

            let api = process.env.REACT_APP_MINISITE_API || 'http://127.0.0.1:4000/api';

            try {
              let response = await fetch(`${api}/zip`, { 
                method: 'POST',
                body: JSON.stringify({ links })
              });
  
              let data = await response.json();
              
              await fetch(`${process.env.REACT_APP_API}/public/shells/shell-downloads/create`, { 
                method: 'POST',
                body: JSON.stringify({ 
                  listDatas: [
                    {
                      'identifier': 'contact_firstname',
                      'value': firstname
                    },
                    {
                      'identifier': 'contact_lastname',
                      'value': lastname
                    },
                    {
                      'identifier': 'contact_email',
                      'value': email
                    },
                    {
                      'identifier': 'contact_company',
                      'value': company
                    },
                    {
                      'identifier': 'contact_departement',
                      'value': departement
                    },
                     {
                      'identifier': 'contact_archive',
                      'value': data?.file
                    }
                  ]
                })
              });

              window.open(`${api}/download?file=${data?.file}`);

              setSaving(false);
              close();
            } catch(e) {
              console.log(e?.message ?? e);
              setSaving(false);
            }
          }} color="primary">
            Télécharger
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Wishlist;