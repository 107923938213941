import {Grid,List} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import {connect} from 'react-redux';
import ButtonCustom from '../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import _ from 'lodash';
import ListingProductsSimple from './ListingProductSimple';


const ListCustom = styled(List)`
    padding: 0 0rem 1rem 0; 
    &>div{
        padding: 24px;
        background: ${colors.white};
    }
`;

class ProductSelectorMerch extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            targetCatId: this.props.allState.selectedCategorie,
            currentLang : this.props.allState.currentLang,
            selectAll: [],
            productByCategorie: {},
            selectedProducts: [],
            uniqueProduct: null,
            formatedDatas: {},
            load: true,
            selectedCategorie : this.props.allState.selectedCategorie,
        }
    }

    handleFormatedDatas = () => {
        let targetCatId = parseInt(this.state.targetCatId.replace('/api/categories/', ''));
        let formatedDatas = this.state.formatedDatas;
        formatedDatas.targetCatId = targetCatId;
        let idList = _.clone(this.state.selectedProducts[this.state.selectedCategorie]);
        let i = 0;
        if(idList){
            for(let id of idList){
                idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                i++;
            }
        }
        formatedDatas.products = idList;
    }

    handleToggle = (value) => {
        let currentIndex  = this.state.selectedProducts.indexOf(value.node.id);
        let newChecked    = [...this.state.selectedProducts];
        let getProduct    = null;
        let selectedProducts = this.state.selectedProducts;
        if (this.props.allProps.dataLayout.formConfig.uniqueSelect){
            getProduct = value.node
            newChecked = getProduct.id
        }else{
            if (currentIndex === -1) {
                newChecked.push(value.node.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        }
        selectedProducts = newChecked;
        this.props.stateCallback('productToUse', getProduct)
        this.setState({
            uniqueProduct: getProduct,
            selectedProducts,
        }, () => {
            this.props.allProps.handlerMutation()
            // this.handlePrepareRecap();
            // this.handleFormatedDatas();
        });
    };

    handleSelectAll = (listProducts, checkSelectAll) => {
        let getProductsId = listProducts.map(e => e.node.id)
        let newArraySelected = this.state.selectedProducts
        for(let productId of getProductsId){
            let currentIndex  = this.state.selectedProducts[this.state.selectedCategorie].indexOf(productId);
            if(currentIndex !== -1){
                if (checkSelectAll){
                    newArraySelected[this.state.selectedCategorie].splice(currentIndex, 1);
                }
            }else{
                newArraySelected[this.state.selectedCategorie].push(productId)
            }
        }
        this.setState({
            selectedProducts: newArraySelected
        },() => {
            // this.handlePrepareRecap();
            // this.handleFormatedDatas();
        })
    }

    componentDidMount(){
        let selectAll = this.state.selectAll;
        if(typeof selectAll[this.state.selectedCategorie] === 'undefined'){
            selectAll[this.state.selectedCategorie] = false;
        }
        this.setState({
            selectAll,
            load: true,
        }); 

        let selectedProducts = this.state.selectedProducts;
        if(!selectedProducts[this.state.selectedCategorie]){
            selectedProducts[this.state.selectedCategorie] = [];
        }
        this.setState({selectedProducts})
    }


    render(){
        return(
            <Grid container style={{background: colors.grey.lighter.hue980}}>
                <Grid item xs={12}>
                    <ListCustom dense>
                        <Grid container direction="row" style={{marginTop: 0}}>
                            <ListingProductsSimple 
                                productBypage = {{
                                    card: [9,18,36],
                                    list: [10,20,30]
                                }}
                                nbperpage = {9}
                                currentLang={this.props.allState.currentLang} 
                                windowWidth={this.props.windowWidth}
                                openForm={this.state.openForm}
                                handleToggle={this.handleToggle}
                                catalogId={parseInt(this.props.allState.catalogId.replace('/api/catalogs/', '')) ?? null}
                                selectedProducts={this.state.selectedProducts}
                                cardSize = {{
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 4
                                }}
                                isCatalog = {true}
                                searchStatus = {"active"}
                                activeCategorie={this.props.allState.currentCat}
                                // handleSelectAll={this.handleSelectAll}
                                // selectAll={this.state.selectAll[this.state.selectedCategorie]}
                                inForm={true}
                                hoverCard = {false}
                                uniqueSelect = {this.props.allProps.dataLayout.formConfig.uniqueSelect}
                                {...this.props}
                                // reverseCheck = {this.state.selectAll[this.state.selectedCategorie]}
                                buttonText={"products.list.cardproduct.insertProduct"}
                                buttonOnClick={this.handleToggle}
                            />
                        </Grid>
                    </ListCustom>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelectorMerch)));