import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../../shareable/components/assets/book/scss/style.scss';
import HeaderBook from '../../../shareable/components/assets/book/components/HeaderBook/HeaderBook';
import FooterBook from '../../../shareable/components/assets/book/components/FooterBook/FooterBook';
import RecapBlockBook from '../../../shareable/components/assets/book/components/RecapBlockBook/RecapBlockBook';
import CopyrightBook from '../../../shareable/components/assets/book/components/CopyrightBook/CopyrightBook';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});
export default ({ config, params, getComponent, preview }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} id="builder-template-scope-book">
            <HeaderBook />
            <RecapBlockBook />
            <CopyrightBook />
            <FooterBook />
        </div>
    );
};