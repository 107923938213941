import { EditableTypes, FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultSlideProduct, getDefaultTextSimple, getDefaultButton } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Slider produit",
    inputs: {
        category: {
            label: 'Catégorie liée',
            type: FormInputTypes.CATEGORY,
            value: null
        }
    },
    blocks: {
        title: getDefaultTextSimple("Titre section", "Ajouter votre titre", 20, "#666"),
        slides: {
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.SLIDER_PRODUCT,
            limit: false,
            id: uuidv4(),
            inputs: {
                color: {
                    type: FormInputTypes.COLOR,
                    value: "#212121",
                    label: "Couleur pagination :"
                }
            },
            config: [
                getDefaultSlideProduct("https://via.placeholder.com/300", "Titre"),
                getDefaultSlideProduct("https://via.placeholder.com/300", "Titre"),
                getDefaultSlideProduct("https://via.placeholder.com/300", "Titre")
            ]
        },
        button: getDefaultButton("Bouton", "Voir plus", 16, "#666", {backgroundColor: "#fff"})
    }
};
