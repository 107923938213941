import { gql } from 'apollo-boost';


export const get_productSourcePriceWatchers = gql`
query productSourcePriceWatchers($date:String, $sku:String, $nbperpage: Int, $cursor: String, $cursorLast: String, $productSourcePriceWatchers_hasDiff: Boolean) {
  products(sku: $sku, first: $nbperpage, after: $cursor, before: $cursorLast, productSourcePriceWatchers_hasDiff: $productSourcePriceWatchers_hasDiff,exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        _id
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (createdAt:{after:$date}, source_isDisplay:true){
          edges{
            node{
              createdAt
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }
}`;



//--- recherche des produits par écart en EURO GTE
/*
 Exemple de variables
  {
    "value" : 120
  }
*/
export const productSourcePriceWatchers_diff_flat_gte = gql`
query productSourcePriceWatchersDiffFlatGte ($value: String, $product:String, $source: String, $date:String, $sku:String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(sku: $sku, first: $nbperpage, after: $cursor, before: $cursorLast, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffPlus:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (product: $product, source: $source, diff_flat:{gte:$value}, createdAt:{after:$date}, source_isDisplay:true){
          edges{
            node{
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }  
}`;


//--- recherche des produits par écart en EURO LTE
/*
 Exemple de variables
  {
    "value" : 120
  }
*/
export const productSourcePriceWatchers_diff_flat_lte = gql`
query productSourcePriceWatchersDiffFlatLte ($value: String, $product:String, $source: String, $date:String, $sku:String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(sku: $sku, first: $nbperpage, after: $cursor, before: $cursorLast, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffMin:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (product: $product, source: $source, diff_flat:{lte:$value}, createdAt:{after:$date}, source_isDisplay:true){
          edges{
            node{
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }  
}`;


//--- recherche des produits par écart en % GTE
/*
 Exemple de variables
  {
    "value" : 120
  }
*/
export const productSourcePriceWatchers_diff_percent_gte = gql`
query productSourcePriceWatchersDiffPercentGte ($value: String, $product:String, $source: String, $date:String, $sku:String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(sku: $sku, first: $nbperpage, after: $cursor, before: $cursorLast, productSourcePriceWatchers_diffPercent:{gte:$value}, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffPlus:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (product: $product, source: $source, diffPercent:{gte:$value}, createdAt:{after:$date}, source_isDisplay:true, hasDiff:true){
          edges{
            node{
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }  
}`;



//--- recherche des produits par écart en % GTE
/*
 Exemple de variables
  {
    "value" : 120
  }
*/
export const productSourcePriceWatchers_diff_percent_lte = gql`
query productSourcePriceWatchersDiffPercentLte ($value: String, $product:String, $source: String, $date:String, $sku:String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(sku: $sku, first: $nbperpage, after: $cursor, before: $cursorLast, productSourcePriceWatchers_diffPercent:{lte:$value}, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffMin:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (product: $product, source: $source, diffPercent:{lte:$value}, createdAt:{after:$date}, source_isDisplay:true){
          edges{
            node{
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }  
}`;


//--- TOTAL PRODUCTS

export const totalProducts = gql`
query productSourcePriceWatchersTotal {
  products {
    totalCount
  }  
}`;


//---- TOTAL COUNT ECART > 0 
export const totalProductGT = gql`
query productSourcePriceWatchersTotalGT ($value: String, $date:String){
  products(productSourcePriceWatchers_createdAt:{after:$date}, productSourcePriceWatchers_diffPercent:{gte:$value}, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffPlus:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
  } 
}`;


//---- TOTAL COUNT ECART < 0 
export const totalProductLT = gql`
query productSourcePriceWatchersTotalLT ($value: String, $date:String){
  products(productSourcePriceWatchers_createdAt:{after:$date}, productSourcePriceWatchers_diffPercent:{lte:$value}, productSourcePriceWatchers_hasDiff:true, productSourcePriceWatchers_hasDiffMin:true, exists:{productSourcePriceWatchers:true}) {
    totalCount
  }
}`;


export const getSources = gql`
query productSourcePriceWatchersSources($date:String, $isBrand: Boolean){
  productSources(isDisplay:true, isBrand:$isBrand){
    edges {
      node{
        id
        libelle
        productSourcePriceWatchers(createdAt:{after:$date}){
          totalCount
          edges{
            node {
              diff_flat
            }
          }
        }
      }
    }
  } 
}`;




export const get_productDetails = gql`
query productSourcePriceWatchersDetails($date:String, $dateBefore:String, $sku: String, $id: Int, $order: String, $productSources_isBrand: Boolean) {
  products(id: $id, sku: $sku, productSources_isBrand:$productSources_isBrand, exists:{productSourcePriceWatchers:true}) {
    edges{
      node{
        id
        sku
        productSourcePriceWatchers (createdAt:{after:$date, before:$dateBefore}, source_isDisplay:true,order:{createdAt:$order}){
          edges{
            node{
              createdAt
              product_price
              source_price
              diff_flat
              diffPercent
              brand
              source{
                id
                libelle
                isBrand
              }
            }
          }
        }
      }
    }
  }
}`;



export const get_productSourcePriceWatchers_by_brand = gql`
query productSourcePriceWatchersByBrand($date:String, $sku:String, $productSourcePriceWatchers_source:String, $productSourcePriceWatchers_hasDiff:Boolean, $nbperpage: Int, $cursor: String, $cursorLast: String) {
  products(sku: $sku, productSourcePriceWatchers_source: $productSourcePriceWatchers_source, productSourcePriceWatchers_hasDiff:$productSourcePriceWatchers_hasDiff,  first: $nbperpage, after: $cursor, before: $cursorLast,exists:{productSourcePriceWatchers:true}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        _id
        sku
        productDatas(attribute_identifier_list:["product_name","product_price","product_image"]){
          edges{
            node{
              attribute{identifier}
              value
              media {
                id
                filePath
              }
            }
          }
        }
        productSourcePriceWatchers (createdAt:{after:$date}, source_isDisplay:true){
          edges{
            node{
              createdAt
              product_price
              source_price
              diff_flat
              diffPercent
              source{
                libelle
              }
            }
          }
        }
      }
    }
  }
}`;