import React from 'react';
import Toolbar from '../shareable/tools/Toolbar';

export default ({ children, preview = false, onEdit, name= "n/a" }) => {
    return (
        <div style={{width: "100%"}}>
            {
                !preview ? (
                    <Toolbar name={name} noBorder={true} onEdit={onEdit} />
                ) : null
            }
            { children }
        </div>
    );
};