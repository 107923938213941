import React from 'react';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors'
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

const Div = styled.div`
    display: flex;
    align-items: center;
    p{
        margin: 0;
        margin-right: 16px;
        color: ${colors.black.regular};
    }
    &>div{
        margin-right: 10px;
        &:last-of-type{
            margin-right: 30px;
        }
    }
`;

function ChipMedias(props) {
    const categories = props.categories;
    const [value, setValue] = React.useState(props.defaultTab ? props.defaultTab : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Typography color='primary' style={{
                fontSize: '20px',
                marginBottom: '10px',
            }}>{props.t("medias.medias.files")}</Typography>
            <Div>
                {categories.map((categorie, index) =>
                    categorie &&
                    <Chip style={{
                        fontSize: '14px',
                        height: '29px',
                        backgroundColor: value === index ? colors.blue.darker.hue300 : colors.grey.lighter.hue980,
                        border: value !== index && `1px solid ${colors.grey.border}`,
                        fontWeight: value !== index && `100`,
                    }}
                        key={`ChipMedia${index}`}
                        color="primary"
                        variant={value === index ? 'default' : 'outlined'}
                        label={categorie.libelle}
                        onClick={(event) => { handleChange(event, index); props.handleChangeTab(categorie.type); }}></Chip>

                )}
            </Div>
        </Box>
    )
}

export default withTranslation()(ChipMedias);