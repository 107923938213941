import React, { useEffect } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import styled from 'styled-components';
import ListingProducts from './components/ListingProducts';
import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';

function ListNewProducts(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [reloadProducts, setReloadProducts] =  React.useState(false);

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
        setReloadProducts(true)
    }, [
        props.productTarget
    ])

    const handleLang = (event) => {
        setCurrentLang(event.target.value);
        eventService.fire();
    };

    const handleExportProducts = () => {
        try {
            props.startLoading();
            let data = new FormData();
            data.append('name', 'export-nouveautes');
            data.append('format', 'csv');
            data.append('locale', 'fr_FR');
            data.append('status', 'all');
            data.append('image', 'all');
            data.append('onlyNew', "true");

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.csv'}`, '_blank');
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };

    let productsDataFilters = [];
    let optionId = "113";
    let titre = '';
    let sousTitre = '';
    if(props.productTarget=='workflow_products_to_complete'){
        titre = 'Produits à enrichir';
        sousTitre = 'Vue d’ensemble de tous les produits à enrichir.';
        optionId = "113";
    }
    else if(props.productTarget=='workflow_products_delayed'){
        titre = 'Produits en retard';
        sousTitre = 'Vue d’ensemble de tous les produits en retard.';
        optionId = "114";
    }
    else if(props.productTarget=='workflow_products_errors'){
        titre = 'Produits en erreur';
        sousTitre = 'Vue d’ensemble de tous les produits en erreur.';
        optionId = "115";
    }

    productsDataFilters.push(JSON.stringify({
        'identifier': "etat",
        'value': null,
        'locale': "fr_FR",
        'option': optionId
    }));

    return (
        <div>
            <TopPanel
                title={props.t(titre)}
                subtitle={props.t(sousTitre)}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container direction='column'>
                <Grid container>
                    <ListingProducts
                        productBypage = {{
                            card: [12,24,48,72],
                            list: [30,40,50]
                        }}
                        variables = {{
                            productDatas:productsDataFilters
                        }}
                        isCatalog = {false}
                        nbperpage = {12}
                        currentLang={currentLang} 
                        handleLang={handleLang}
                        windowWidth={props.windowWidth}
                        inAccordion={false}
                        handlerExport={false}
                        reload={reloadProducts}                 
                        onReload={() => setReloadProducts(false)}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListNewProducts)))));