import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Gift finder',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFD400'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        title: getDefaultTextarea("Titre", "FINDEN SIE DAS PERFEKTE GESCHENK", true),
        logo: {
            type: EditableTypes.IMAGE,
            name: "Logo",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: null
                }
            }
        },
        finder: {
            type: EditableTypes.FINDER,
            name: "Configuration",
            id: uuidv4(),
            config: {
                categories: [],
                filters: []
            }
        }
    }
};