import React, { Fragment } from "react";
import { 
    HeaderBook,
    FooterBook,
    FlipBook,
    PostCardBook,
    RecapBlockBook,
    ResultScreenBook,
    TopHomepageBook,
    BannerLinkBook,
    CopyrightBook
} from "../../../../shareable/components/assets/book/components";
import FlipbookSuccessEmail from "../../../../../email/flipbook/emailSuccess";
import FlipbookWinEmail from "../../../../../email/flipbook/emailWin";
import FlipbookLooseEmail from "../../../../../email/flipbook/emailLoose";
import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";
import { v4 as uuidv4 } from "uuid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const getComponent = (element, preview, config, apiConfig, updateConfig, inputCallback, pagesFlipbook, deleteComponent, Droppable, index, asset) => {
    let lang = asset.locales.edges[0].node.code
   
    switch (element.type) {
        case ItemTypes.HEADER_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <HeaderBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </HeaderBook>
                    <Droppable type={ItemTypes.HEADER_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.FOOTER_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <FooterBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        lang={lang}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </FooterBook>
                    <Droppable type={ItemTypes.FOOTER_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.TOP_HOMEPAGE_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TopHomepageBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        lang={lang}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </TopHomepageBook>
                    <Droppable type={ItemTypes.TOP_HOMEPAGE_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.POSTCARD_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <PostCardBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        lang={lang}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                        // toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </PostCardBook>
                    <Droppable type={ItemTypes.POSTCARD_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.FLIPBOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <FlipBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        pagesFlipbook={pagesFlipbook}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        inputCallback={inputCallback}
                        params={config.params}
                        spread={true}
                        lang={lang}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />}
                        toolbarSubSection={<Toolbar name={element.data.blocks.subBlock.name} onEdit={() => inputCallback(element.key, 'subBlock')} />}
                        // toolbarGiftBlock={<Toolbar name={element.data.blocks.giftBlock.name} onEdit={() => inputCallback(element.key, 'giftBlock')} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </FlipBook>
                    <Droppable type={ItemTypes.FLIPBOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.FLIPBOOK_WITH_CART:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <FlipBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        pagesFlipbook={pagesFlipbook}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        inputCallback={inputCallback}
                        params={config.params}
                        spread={true}
                        toolbarHeader={<Toolbar name={element.data.blocks.header.name} onEdit={() => inputCallback(element.key, 'header')} />}
                        toolbarSection={<Toolbar name={element.data.blocks.flipbook.name} onEdit={() => inputCallback(element.key)} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </FlipBook>
                    <Droppable type={ItemTypes.FLIPBOOK_WITH_CART} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.COPYRIGHT_BOOK:
            return (
            <Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <CopyrightBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        pagesFlipbook={pagesFlipbook}
                        data={element.data}
                        categories={apiConfig.categories}
                        products={apiConfig.products}
                        inputCallback={inputCallback}
                        params={config.params}
                        spread={true}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </CopyrightBook>
                    <Droppable type={ItemTypes.COPYRIGHT_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>
            )
        case ItemTypes.RECAP_BLOCK_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <RecapBlockBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        redirect={'#'}
                        emailTemplate={FlipbookSuccessEmail}
                        lang={lang}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarBlock={<Toolbar name={element.data.blocks.explanation.name} onEdit={() => inputCallback(element.key, 'explanation')} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </RecapBlockBook>
                    <Droppable type={ItemTypes.RECAP_BLOCK_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.RESULTS_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <ResultScreenBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        lang={lang}
                        toolbarSection={<Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />}
                        toolbarBlock={<Toolbar name={element.data.blocks.subblock.name} onEdit={() => inputCallback(element.key, 'subblock')} />}
                        // toolbarCustom={<Toolbar name={element.data.blocks.result.name} onEdit={() => inputCallback(element.key, 'subblock')} />}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteComponent(element.key)} />
                    </ResultScreenBook>
                    <Droppable type={ItemTypes.RESULTS_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.BANNER_LINK_BOOK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <BannerLinkBook
                        id={element.key}
                        noSticky={preview}
                        update={updateConfig}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        spread={true}
                        lang={lang}
                    >
                        <Toolbar name={element.data.name} onEdit={() => inputCallback(element.key)} />
                    </BannerLinkBook>
                    <Droppable type={ItemTypes.BANNER_LINK_BOOK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.EMAIL_SUCCESS_BOOK:
            return (<FlipbookSuccessEmail data={element.data} id={uuidv4()} />);
        case ItemTypes.EMAIL_WIN_BOOK:
        case ItemTypes.EMAIL_WIN_BOOK_2:
            return (<FlipbookWinEmail data={element.data} id={uuidv4()} />);
        case ItemTypes.EMAIL_LOOSE_BOOK:
            return (<FlipbookLooseEmail data={element.data} id={uuidv4()} />);
    }
}