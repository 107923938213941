import React from "react";
import colors from '../../../colors';

const H5 = ({ data, preview, component, block, inputCallback, className }) => {
    return (
        <h5 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null} 
            style={{ 
                fontSize: data.blocks[block].inputs.size.value,
                color: data.blocks[block].inputs.color.value,
                backgroundColor: data.blocks[block].inputs.backgroundColor?.value,
                lineHeight: '1.2',
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
            }}
        >
            { data.blocks[block].inputs.value.value }
        </h5> 
    );
};

export default H5;