import React, { useEffect, useRef, useState } from "react";
import H2 from '../shared/h2';
import Button from '../shared/a';
import Div from '../shared/div';
import P from '../shared/p';
import Img from '../shared/img';

const SliderImage = (props) => {
    const [hover, setHover] = useState(false);
    let {
        preview = false,
        data = null
    } = props;

    const vidRef = useRef(null);

    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];
    
    return (
        <Div 
            style={{ 
                position: 'relative', 
                background: data && data?.blocks?.background?.inputs?.backgroundColor?.value, 
                padding:data.inputs.noPaddingHorizontal.value?0:"62px",
                paddingTop: data && data?.inputs?.paddingTop?.value ? data?.inputs?.paddingTop?.value : data?.inputs?.paddingTop?.value === 0 ? 0 : null, 
                paddingBottom: data && data?.inputs?.paddingBottom?.value ? data?.inputs?.paddingBottom?.value : data?.inputs?.paddingBottom?.value === 0 ? 0 : null,
            }} 
            className="grid-image" 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}            
        >
            { hover && !preview && props.children }
            {
                data.blocks.title?.inputs.value.value || (data.blocks.link?.inputs.link.value && data.blocks.link?.inputs.value.value) ? (
                    <Div className="title-box" style={{justifyContent: !data.blocks.title?.inputs.value.value && (data.blocks.link?.inputs.link.value && data.blocks.link?.inputs.value.value) ? 'flex-end' : null}}>
                        {
                            data.blocks.title?.inputs.value.value ? (
                                <H2 className="w100 uppercase">{data.blocks.title?.inputs.value.value}</H2>
                            ) : null
                        }
                        {
                            data.blocks.link?.inputs.link.value && data.blocks.link?.inputs.value.value ? (
                                <a href={data.blocks.link?.inputs.link.value}>{data.blocks.link?.inputs.value.value}</a>
                            ) : null
                        }
                    </Div>
                ) : null
            }
            <Div 
            className="container-grid-image"
            style={{
                display:"grid",
                gridTemplateColumns:`repeat(${data.inputs.gridNumber.value},1fr)`,
                gap:`${data.inputs.gridGap.value}px`
            }}
            >
                {
                    data.blocks.slides.config.map((slide, index) => (
                        <Div key={index} className="relative" style={{width:"100%",height:"100%"}}>
                            {
                                slide.blocks.link?.inputs.link?.value ? (
                                    <a href={slide.blocks.link?.inputs.link?.value}>
                                        {
                                            VIDEOS.includes(slide.blocks.imagePrincipale?.inputs.value.value.type) ? (
                                                <video autoPlay={true} muted={true} loop={true} ref={vidRef}>
                                                    <source src={`${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}`} type="video/mp4"/>
                                                </video>
                                            ) : (
                                                <Img 
                                                    src={
                                                        typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                            slide.blocks.imagePrincipale?.inputs.value.value 
                                                        : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                        : null
                                                    }
                                                    alt={slide.blocks.imagePrincipale?.inputs.alt?.value ?? "Illustration"}
                                                />
                                            )
                                        }
                                    </a>
                                ) : (
                                    VIDEOS.includes(slide.blocks.imagePrincipale?.inputs.value.value.type) ? (
                                        <video autoPlay={true} muted={true} loop={true} ref={vidRef}>
                                            <source src={`${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}`} type="video/mp4"/>
                                        </video>
                                    ) : (
                                        <Img 
                                            src={
                                                typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                    slide.blocks.imagePrincipale?.inputs.value.value 
                                                : slide.blocks.imagePrincipale?.inputs.value.value.filePath ? 
                                                    `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                : null
                                            }
                                            alt={slide.blocks.imagePrincipale?.inputs.alt?.value ?? "Illustration"}
                                        />
                                    )
                                )
                            }
                            {
                                slide.blocks.link?.inputs.link?.value ? (
                                    <a href={slide.blocks.link?.inputs.link?.value}>
                                        {
                                            slide.blocks.text?.inputs.value.value || slide.blocks.link?.inputs.value.value ? (
                                                <Div className="absolute" style={{left:40, bottom: 40, maxWidth: 'calc(100% - 80px)'}}>
                                                    {
                                                        slide.blocks.text?.inputs.value.value ? (
                                                            <P 
                                                                color={slide.blocks.text?.inputs.color.value}
                                                                fontSize={slide.blocks.text?.inputs.fontSize.value}
                                                                textTransform={slide.blocks.text?.inputs.textTransform.value}
                                                            >
                                                                {slide.blocks.text?.inputs.value.value}
                                                            </P>
                                                        ) : null
                                                    }
                                                    {
                                                        slide.blocks.link?.inputs.value.value ? (
                                                            <Button 
                                                                href={slide.blocks.link?.inputs.link?.value}
                                                                aria-label={`${slide.blocks.link?.inputs.value.value}`} title={`${slide.blocks.link?.inputs.value.value}`}
                                                                backgroundColor={slide.blocks.link?.inputs.backgroundColor.value} 
                                                                color={slide.blocks.link?.inputs.color.value}
                                                                borderWidth={slide.blocks.link?.inputs.border?.inputs.borderWidth.value}
                                                                borderColor={slide.blocks.link?.inputs.border?.inputs.borderColor.value}
                                                                borderRadius={slide.blocks.link?.inputs.border?.inputs.borderRadius.value}
                                                                style={{width: 'max-content'}}
                                                            >
                                                                {slide.blocks.link?.inputs.value.value}
                                                            </Button>
                                                        ) : null
                                                    }
                                                </Div>
                                            ) : null
                                        }
                                    </a>
                                ) : (
                                    slide.blocks.text?.inputs.value.value || slide.blocks.link?.inputs.value.value ? (
                                        <Div className="absolute" style={{left:40, bottom: 40, maxWidth: 'calc(100% - 80px)'}}>
                                            {
                                                slide.blocks.text?.inputs.value.value ? (
                                                    <P 
                                                        color={slide.blocks.text?.inputs.color.value}
                                                        fontSize={slide.blocks.text?.inputs.fontSize.value}
                                                        textTransform={slide.blocks.text?.inputs.textTransform.value}
                                                    >
                                                        {slide.blocks.text?.inputs.value.value}
                                                    </P>
                                                ) : null
                                            }
                                            {
                                                slide.blocks.link?.inputs.value.value ? (
                                                    <Button 
                                                        href={slide.blocks.link?.inputs.link?.value}
                                                        aria-label={`${slide.blocks.link?.inputs.value.value}`} title={`${slide.blocks.link?.inputs.value.value}`}
                                                        backgroundColor={slide.blocks.link?.inputs.backgroundColor.value} 
                                                        color={slide.blocks.link?.inputs.color.value}
                                                        borderWidth={slide.blocks.link?.inputs.border?.inputs.borderWidth.value}
                                                        borderColor={slide.blocks.link?.inputs.border?.inputs.borderColor.value}
                                                        borderRadius={slide.blocks.link?.inputs.border?.inputs.borderRadius.value}
                                                        style={{width: 'max-content'}}
                                                    >
                                                        {slide.blocks.link?.inputs.value.value}
                                                    </Button>
                                                ) : null
                                            }
                                        </Div>
                                    ) : null
                                )
                            }
                        </Div>
                    ))
                }
            </Div>
        </Div>
    );
};

export default SliderImage;