import { Box, Grid, Typography } from "@material-ui/core"
import styled from "styled-components"
import colors from "../../../../config/theme/colors"
import OurButton from "../../../ui/button/Button"
import OurMenu from "../../../ui/menu/Menu"
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import OurTypography from "../../../ui/typography/Typography"

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`
const CustomItem = styled(Grid)`
    h4 {
        font-size: 13px;
    }
`
const CustomButton = styled(OurButton)`
    width: 100%;
    height: 40px;
    margin: 0px;
`

const CardCompletenessGroup = (props) => {
  const editMenuOptions = [
      {
          label: 'Dupliquer',
          isDisabled: true,
          color: colors.blue.darker.hue300,
          icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
          action: (e) => {
              e.stopPropagation();
              // props.toggleDuplicateDialog(props.data.node.id)
          }
      },
      {
          label: 'Supprimer',
          color: colors.red.regular,
          icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
          action: (e) => {
              e.stopPropagation();
              props.handlerDelete(props.data)
          }
      }
  ]

  return (
    <ContainerCard>
      <Grid container justifyContent='space-between' direction='column' style={{ height: '100%' }}>
          <Grid container direction='column'>
              <Grid container justifyContent='space-between' style={{marginBottom: '15px'}}>
                  <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '25px' }}>
                    {props.data.node.libelle}
                  </Typography>
                  <Box mt={'3px'}>
                      <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                  </Box>
              </Grid>
              {/* // TODO: Add description (Form, cards and backend) */}
              {/* <Typography variant="h4" style={{ fontSize: '13px', marginBottom: '13px' }}>{props.data.node.name}</Typography> */}
              <Grid container direction="column" style={{ marginBottom: '20px' }}>
                  <Grid container alignItems="center">
                      <CustomItem item xs={6}><Typography variant="h4">Nb d'attributs :</Typography></CustomItem>
                      <Grid container xs={6}>
                        <OurTypography fontweighttext={700} colortext={colors.black.regular}>
                          {props.data.node.completenessGroupAttributes.edges.length}
                        </OurTypography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <Grid container>
              <CustomButton
                  text={'Modifier'}
                  icon={'edit'}
                  color={colors.blue.darker.hue300}
                  colorHover={colors.white}
                  bgcolorhover={colors.blue.lighter.hue600}
                  bgcolor={colors.blue.lighter.hue900}
                  border={`1px solid ${colors.blue.darker.hue300}`}
                  stopPropagation={true}
                  onClick={() => props.handlerEdit(props.data)}
              />
          </Grid>
      </Grid>
  </ContainerCard>
  )
}

export default CardCompletenessGroup