import { useEffect } from 'react';
import { Grid, Box, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Chip } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {  ExpandMore } from "@material-ui/icons";
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import moment from 'moment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { spaceOnNumbers } from '../../../../../js/utils/spaceOnNumber';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';

const CardCustom = styled(Grid)`
    border:0.5px solid ${colors.grey.lighter.hue700};
    padding: 10px 20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;



const StatsRealTime =props=>{
    const {stats}= props
    const getRealTime = () => {
        const date = `${moment().format('dddd DD MMM YYYY [-] HH:mm:ss')}`
        const formattedDate = date[0].toUpperCase() + date.substring(1);
        return formattedDate;
    }

    return (
        <Box pb={2}>
            <AccordionCustom defaultExpanded={true} title={<Typography variant={"h4"} style={{ fontSize: 14 }}>Statistiques globales : <strong>{getRealTime()}</strong></Typography>}>
                {
                    stats?
                        <Grid direction='row' justifyContent='space-between' style={{display:'flex', gap:"10px", width: '100%'}}>
                            <CardCustom item xs={3}>
                                <Box style={{display:"flex",flexDirection:'column',rowGap:10}}>
                                    <Typography variant="h2" style={{fontSize:14}}>Jours le plus prolifique</Typography>
                                    <Typography style={{fontSize:35,fontWeight:'bold'}}>{stats.mostAttractiveDay?.date}</Typography>
                                    <span>{spaceOnNumbers(stats.mostAttractiveDay?.playCount)}</span>
                                </Box>
                                <EventAvailableIcon style={{ color: "#4CAA5F",fontSize: 40 }} />                                    
                            </CardCustom>
                            <CardCustom item xs={3}>
                                <Box style={{display:"flex",flexDirection:'column',rowGap:10}}>
                                    <Typography variant="h2" style={{fontSize:14}}>Nombre de parties</Typography>                                        
                                    <Typography style={{fontSize:35,fontWeight:'bold'}}>{spaceOnNumbers(stats.playCount)}</Typography>
                                    <span>{spaceOnNumbers(stats.mostAttractiveDay?.playCount)}</span>
                                </Box>
                                <EventAvailableIcon style={{ color: "#4CAA5F" }} />                                     
                            </CardCustom>
                            <CardCustom item xs={3}>
                                <Box style={{display:"flex",flexDirection:'column',rowGap:10}}>
                                    <Typography variant="h2" style={{fontSize:14}}>Nombre de joueurs</Typography>                                        
                                    <Typography style={{fontSize:35,fontWeight:'bold'}}>{spaceOnNumbers(stats.playerCount)}</Typography>
                                    <span>{spaceOnNumbers(stats.mostAttractiveDay?.playCount)}</span>
                                </Box>
                                <EventAvailableIcon style={{ color: "#4CAA5F" }} />                                     
                            </CardCustom>
                            <CardCustom item xs={3}>
                                <Box style={{display:"flex",flexDirection:'column',rowGap:10}}>
                                    <Typography variant="h2" style={{fontSize:14}}>Joueurs unique</Typography>                                        
                                    <Typography style={{fontSize:35,fontWeight:'bold'}}>{spaceOnNumbers(stats.onePlayPlayer)}</Typography>
                                    <span>{spaceOnNumbers(stats.mostAttractiveDay?.playCount)}</span>
                                </Box>
                                <EventAvailableIcon style={{ color: "#4CAA5F" }} />                              
                            </CardCustom>
                        </Grid>                    
                    :<PageLoader/>
                }  
            </AccordionCustom>
        </Box>

    )
}


export default StatsRealTime;
