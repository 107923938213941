import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { UPDATE_GUIDELINE } from "../../../../../queries/brand_guideline";

import { Grid, List, InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import DocumentPicker from './DocumentPicker';
import AddIcon from '@material-ui/icons/Add';


const AddIconCustom = styled(AddIcon)`
    fill: white;
    background: ${colors.blue.lighter.hue150};
    border-radius: 50%;
    padding: 4px;
    width: calc(1em + 10px);
    height: calc(1em + 10px);
    margin-bottom: 10px;
`;
const SpanAddCustom = styled.span`
    color: ${colors.blue.lighter.hue150};
    text-decoration: underline;
`;
const FormCustom = styled(List)`
    padding: 1rem;
    width: 100%;
    background: rgb(250, 251, 251);
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

class DocumentSelector extends React.Component {  
    state = {
        listDocument: this.props.allState.listDocument || [],
    };

    addDocument = () => {
        let temporaryListDocument = this.state.listDocument;
        temporaryListDocument.push({id: ''});

        this.setState({
            listDocument: temporaryListDocument
        }, () => {this.props.stateCallback('listDocument', this.state.listDocument)});
    };

    deleteDocument = (index) => {
        let temporaryListDocument = this.state.listDocument;
        let id = temporaryListDocument[index]?.node?.id;  
        temporaryListDocument.splice(index, 1);
        
        this.setState({
            listDocument: temporaryListDocument
        }, () => {
            this.props.stateCallback('listDocument', this.state.listDocument);
        });
    }

    handleChangeComplete = (document, index) => {
        let temporaryDocument = this.state.listDocument;
        temporaryDocument[index] = document;

        this.setState({
            listDocument: temporaryDocument
        }, () => {this.props.stateCallback('listDocument', this.state.listDocument)});
    };
    
    render(){
        return (
            <FormCustom>
                {
                    this.state.listDocument?.length > 0 ? (
                        <>
                            {
                                this.state.listDocument.map((document, index) => (
                                    <Grid container direction="row" spacing={1} key={`index_listDocuments_${index}`} id={`index_listDocuments_${index}`}>
                                        <Grid item lg={3} xs={12}>
                                            <InputLabelCustom>Document n°{index+1}</InputLabelCustom>
                                        </Grid>
                                        <Grid item lg={9} xs={12}>
                                            <DocumentPicker document={document} deleteDocument={() => {this.deleteDocument(index)}} handleChangeComplete={(document) => {this.handleChangeComplete(document, index)}} />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            <div
                                onClick={() => {this.addDocument()}}
                                style={{cursor: 'pointer', width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'row'}}
                            >
                                <AddIconCustom style={{marginBottom: 0, marginRight: 10}} />
                                <SpanAddCustom>Ajouter un document</SpanAddCustom>
                            </div>
                        </>
                    ) : (
                        <div
                            onClick={() => {this.addDocument()}}
                            style={{cursor: 'pointer', width: '100%', height: '100%', minHeight: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}
                        >
                            <AddIconCustom />
                            <SpanAddCustom>Ajouter un document</SpanAddCustom>
                        </div>
                    )
                }
            </FormCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSelector);