import React from 'react';
import {Grid, Typography} from '@material-ui/core';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../../../ui/button/Button';
import CardCustom from '../../../layouts/Card/CardCustom';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import EditIcon from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { hasRights } from '../../../../js/utils/rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { CRM_AGENCIES, CRM_AGENCIES_POLES, CRM_AGENCIES_RATES, CRM_AGENCIES_TAX_RATES, CRM_AGENCIES_PAYMENT_TERMS, CRM_AGENCIES_PAYMENT_DEADLINES, CREATE, UPDATE, DELETE, VIEW} from '../../../../js/constants/constant-rights';


import { withApollo } from 'react-apollo';
import { GET_AGENCE_POLES, 
    GET_AGENCE_TARIFS, 
    GET_AGENCE_TAX_RATES, 
    ADD_POLE, 
    ADD_TARIF, 
    ADD_TAX_RATE, 
    UPDATE_POLE, 
    UPDATE_TARIF, 
    UPDATE_TAX_RATE, 
    DELETE_POLE, 
    DELETE_TARIF, 
    DELETE_TAX_RATE,
    GET_PAYMENT_TERMS,
    ADD_PAYMENT_TERM,
    UPDATE_PAYMENT_TERM,
    DELETE_PAYMENT_TERM,
    GET_PAYMENT_DEADLINES,
    ADD_PAYMENT_DEADLINE,
    UPDATE_PAYMENT_DEADLINE,
    DELETE_PAYMENT_DEADLINE, 
    GET_CGVS,
    EDIT_CGV,
    ADD_CGV,
    DELETE_CGV} from '../../../../queries/crm_agence';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';

import { eventService } from '../../../../js/services/event.service';

import EmptyCMS from '../../../../assets/pictos/empty-picto/empty_cms.png';
import EmptyDevises from '../../../../assets/pictos/empty-picto/empty_devises.png';
import EmptyMarketplace from '../../../../assets/pictos/empty-picto/empty_marketplaces.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import agencyAddConfig from './config/agencyAddConfig.config';
import tarifAddConfig from './config/tarifAddConfig.config';
import taxAddConfig from './config/taxAddConfig.config';
import cgvAddConfig from './config/cgvAddConfig.config';

import request from '../../../../js/utils/fetch';
import slugify from 'slugify';


class ListAgencies extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openForm: false,
            editForm: 'add',
            isEmpty : false,
            identifier: props.typeAgency,
            emptyPicto: null,
            dataLayout: null,
            openDialogRemove: false,
            currentLang: props.locales[0].node.id,
            errors: {},
            seeErrors: false,
            name: null,
            price: null,
            contentCGV: null,
            valueTax: null,
        };
        this.typingTimer = null;
        // this.typeTesting = 'channel';
    }
    

    doneTyping = (stateName) => {
        if(stateName === 'libelle'){
            this.setState({
                attributeIdentifier: slugify(this.state.libelle, {replacement :'_', lower: true, remove: /[^\w\-\s]+/g}),
            })
        }
        
        if(this.state.attributeIdentifier){
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.attributeIdentifier}`, 'get').then(
                (data) => {
                    if(data.success){
                        eventService.fire({stateName: 'attributeIdentifier', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if(stateName === 'libelle' || stateName === 'attributeIdentifier'){
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {this.doneTyping(stateName)}, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];
            if (!values) {
                values = {};
            }
            values[stateName] = value;
            this.setState({
                [this.state.currentLang]: values,
            });
        } else{
            this.setState({
                [stateName]: value,
            });
        }
        if(this.state.editForm === 'add')
            this.checkIdentifier(stateName)
    }

    handleInputChange = (stateName, evt, custom, translated ) => {  
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    }

    handleInitData = (typeChannel, firstTime) => {
        if(typeChannel === 'agency'){
            this.setState({ 
                content:{
                    title: "Gérer les pôles d'agence",
                    subtitle: "Gestion de vos pôles d'agence (création / modification / suppression)",
                    picto: "ico_gestion-pole",
                    txtBtn: "Ajouter un pôle",
                    emptyTitle: "Vous n'avez pas encore configuré de pôle",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un pôle",
                    emptyPicto: EmptyCMS,
                },
                dataAddLayout: agencyAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau pôle est prêt à être ajouté à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
        else if(typeChannel === 'tarif'){
            this.setState({
                content:{
                    title: "Gérer les tarifs",
                    subtitle: "Gestion de vos tarifs (création / modification / suppression)",
                    picto: "picto-prix",
                    txtBtn: "Ajouter un tarif",
                    emptyTitle: "Vous n’avez pas encore configuré de Tarifs",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un tarif",
                    emptyPicto: EmptyDevises,
                },
                dataAddLayout: tarifAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau tarif est prêt à être ajouté à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
        else if(typeChannel === 'tax'){
            this.setState({
                content:{
                    title: "Gérer les taxes",
                    subtitle: "Gestion de vos taxes (création / modification / suppression)",
                    picto: "ico_gestion-taxe",
                    txtBtn: "Ajouter une taxe",
                    emptyTitle: "Vous n’avez pas encore configuré de taxes",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
                    emptyTxtBtn: "Ajouter une taxe",
                    emptyPicto: EmptyMarketplace,
                },
                dataAddLayout: taxAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouvelle taxe est prêt à être ajoutée à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
        else if(typeChannel === 'paymentTerms'){
            this.setState({ 
                content:{
                    title: "Gérer les conditions de règlement",
                    subtitle: "Gestion de vos conditions de règlements d'agence (création / modification / suppression)",
                    picto: "icon-costs",
                    txtBtn: "Ajouter une condition de règlement",
                    emptyTitle: "Vous n'avez pas encore configuré de conditions de règlement",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter une condition de règlement",
                    emptyPicto: EmptyCMS,
                },
                dataAddLayout: agencyAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouvelle conditions de r§èglement est prête à être ajouté à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
        else if(typeChannel === 'paymentDeadlines'){
            this.setState({ 
                content:{
                    title: "Gérer les délais de paiement",
                    subtitle: "Gestion de vos délais de paiements d'agence (création / modification / suppression)",
                    picto: "icon-costs",
                    txtBtn: "Ajouter un délai de paiement",
                    emptyTitle: "Vous n'avez pas encore configuré de délais de paiement",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter un",
                    emptyTxtBtn: "Ajouter un délai de paiement",
                    emptyPicto: EmptyCMS,
                },
                dataAddLayout: agencyAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau délai de paiement est prêt à être ajouté à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
        else if(typeChannel === 'cgv'){
            this.setState({ 
                content:{
                    title: "Gérer les CGVs",
                    subtitle: "Gestion de vos condtions générales de ventes (création / modification / suppression)",
                    picto: "picto-content",
                    txtBtn: "Ajouter une CGV",
                    emptyTitle: "Vous n'avez pas encore configuré de conditions générales de ventes",
                    emptySubtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
                    emptyTxtBtn: "Ajouter une CGV",
                    emptyPicto: EmptyCMS,
                },
                dataAddLayout: cgvAddConfig(),
            }, () => {
                let finalStep = 
                {
                    title: 'Et voilà !',
                    subtitle: 'Votre nouveau délai de paiement est prêt à être ajouté à Sinfin DXP !',
                    textButton: 'Ajouter'
                }
                let getFinalStep = this.state.dataAddLayout
                getFinalStep.formConfig.finalStep = finalStep
                this.setState({
                    dataAddLayout:  getFinalStep
                })
            });
        }
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            this.props.startLoading();
            let typeOf = null
            switch(this.state.identifier){
                case 'agency' :
                    typeOf = {
                        name : "Pôle d'agence",
                        edit : UPDATE_POLE,
                        add : ADD_POLE,
                        delete : DELETE_POLE,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name
                        },
                        variablesAdd: {
                            "name": this.state.name
                        },
                    }
                break;
                case 'tarif' :
                    typeOf = {
                        name : "Tarif",
                        edit : UPDATE_TARIF,
                        add : ADD_TARIF,
                        delete : DELETE_TARIF,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name,
                            "price": this.state.price
                        },
                        variablesAdd: {
                            "name": this.state.name,
                            "price": this.state.price
                        }
                    }
                break;
                case 'tax' :
                    typeOf = {
                        name : "Taxe",
                        edit : UPDATE_TAX_RATE,
                        add : ADD_TAX_RATE,
                        delete : DELETE_TAX_RATE,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name,
                            "value": this.state.valueTax
                        },
                        variablesAdd: {
                            "name": this.state.name,
                            "value": this.state.valueTax
                        }
                    }
                break;
                case 'paymentTerms' :
                    typeOf = {
                        name : "Conditions de règlement",
                        edit : UPDATE_PAYMENT_TERM,
                        add : ADD_PAYMENT_TERM,
                        delete : DELETE_PAYMENT_TERM,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name
                        },
                        variablesAdd: {
                            "name": this.state.name
                        },
                    }
                break;
                case 'paymentDeadlines' :
                    typeOf = {
                        name : "Délai de paiement",
                        edit : UPDATE_PAYMENT_DEADLINE,
                        add : ADD_PAYMENT_DEADLINE,
                        delete : DELETE_PAYMENT_DEADLINE,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name
                        },
                        variablesAdd: {
                            "name": this.state.name
                        },
                    }
                break;
                case 'cgv' :
                    typeOf = {
                        name : "Condition générale de vente",
                        edit : EDIT_CGV,
                        add : ADD_CGV,
                        delete : DELETE_CGV,
                        variablesEdit: {
                            "id": this.state.editId,
                            "name": this.state.name,
                            "content": this.state.contentCGV
                        },
                        variablesAdd: {
                            "name": this.state.name,
                            "content": this.state.contentCGV
                        },
                    }
                break;
                default:
                    return null;
            }
            let query = null;
            let variables = null;
            switch(this.state.editForm){
                case 'edit' :
                    query = typeOf.edit;
                    variables = typeOf.variablesEdit
                break;
                case 'add' :
                    query = typeOf.add;
                    variables = typeOf.variablesAdd
                break;
                case 'delete' :
                    query = typeOf.delete;
                    variables = {
                        "id": this.state.editId
                    }
                break;
                default:
                    return null;
            }
            this.props.client.mutate({
                mutation: query,
                variables
            }).then(() => {
                if (this.state.editForm === 'add'){
                    this.props.snack(ALERT_SUCCESS, `${typeOf.name} ${this.state.identifier === "tax" ? "ajoutée" : "ajouté"} !`);
                } else if(this.state.editForm === "edit"){
                    this.props.snack(ALERT_SUCCESS, `${typeOf.name} ${this.state.identifier === "tax" ? "modifiée" : "modifié"} !`);
                } else if(this.state.editForm === "delete"){
                    this.props.snack(ALERT_SUCCESS, `${typeOf.name} ${this.state.identifier === "tax" ? "supprimée" : "supprimé"} !`);
                }
                this.setState({
                    openForm: false,
                    openDialogRemove: false,
                })
                this.handleGetDatas(this.state.identifier)
            });
            this.props.stopLoading();
        } catch {
            this.props.snack(ALERT_ERROR, 'Une erreur est survenue');
            this.props.stopLoading();
        }
    };


    editAgency = (agency) => {
        let editSetup = null;
        switch(this.state.identifier){
            case 'agency' :
                editSetup = {
                    title: 'un pôle d\'agence',
                    subtitle: 'pôle d\'agence',
                    textButton: 'ce pôle'
                }
                this.setState({
                    name : agency.name
                })
            break;
            case 'tarif' :
                editSetup = {
                    title: 'un tarif',
                    subtitle: 'tarif',
                    textButton: 'ce tarif'
                }
                this.setState({
                    name : agency.name,
                    price: agency.price
                })
            break;
            case 'tax' :
                editSetup = {
                    title: 'une taxe',
                    subtitle: 'taxe',
                    textButton: 'cette taxe'
                }
                this.setState({
                    name : agency.name,
                    valueTax: agency.value
                })
            break;
            case 'paymentTerms' :
                editSetup = {
                    title: 'une condition de règlement',
                    subtitle: 'condition de règlement',
                    textButton: 'cette condition de règlement'
                }
                this.setState({
                    name : agency.name,
                    valueTax: agency.value
                })
            break;
            case 'paymentDeadlines' :
                editSetup = {
                    title: 'un délai de paiement',
                    subtitle: 'délai de paiement',
                    textButton: 'ce délai de paiement'
                }
                this.setState({
                    name : agency.name,
                    valueTax: agency.value
                })
            break;
            case 'cgv' :
                editSetup = {
                    title: 'une CGV',
                    subtitle: 'condition générale de vente',
                    textButton: 'cette CGV'
                }
                this.setState({
                    name : agency.name,
                    contentCGV: agency.content
                })
            break;
            default:
                return null;
        }
        let configFormAdd = this.state.dataAddLayout;
        configFormAdd.titleForm = `Modifier ${editSetup.title}`;
        configFormAdd.formConfig.finalStep.subtitle = `Votre ${editSetup.subtitle} est désormais prêt pour la modification`;
        configFormAdd.formConfig.finalStep.textButton = `Modifier ${editSetup.textButton}`;
        this.setState({
            dataAddLayout: configFormAdd
        })
        this.setState({
            editForm: 'edit',
            editId: agency.id,
        },() => {this.handleToggleDrawer('form', false)});
    }

    deleteAgency = (id) => {
        this.setState({
            editForm: 'delete',
            editId: id ? id : this.state.editId,
        },() => {this.handleToggleDialog()});
    }


    handleToggleDialog = () => {
        this.setState({ 
            openDialogRemove : !this.state.openDialogRemove
        });
    }
    handleToggleDrawer = (drawer, isAdd) => {
        if(drawer === 'form'){
            if (isAdd){
                if(this.state.identifier === 'agency'){
                    this.setState({
                        dataAddLayout: agencyAddConfig(),
                        name : null,
                    });
                }else if(this.state.identifier === 'tarif'){
                    this.setState({
                        dataAddLayout: tarifAddConfig(),
                        name: null,
                        price: null
                    })
                }else if(this.state.identifier === 'tax'){
                    this.setState({
                        dataAddLayout: taxAddConfig(),
                        name: null,
                        valueTax: null
                    })
                }else if(this.state.identifier === 'cgv'){
                    this.setState({
                        dataAddLayout: cgvAddConfig(),
                        name: null,
                        contentCGV: null
                    })
                }
                this.setState({ 
                    editForm: 'add',
                    openForm : !this.state.openForm,
                });
            }else{
                this.setState({ 
                    openForm : !this.state.openForm,
                });
            }
        }
    }

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleGetDatas(type) {
        this.props.startLoading();
        let query = null;
        let variables = null;
        switch(type){
            case 'agency' :
                query = GET_AGENCE_POLES;
            break;
            case 'tarif' :
                query = GET_AGENCE_TARIFS;
            break;
            case 'tax' :
                query = GET_AGENCE_TAX_RATES;
            break;
            case 'paymentTerms' :
                query = GET_PAYMENT_TERMS;
            break;
            case 'paymentDeadlines' :
                query = GET_PAYMENT_DEADLINES;
            break;
            case 'cgv' :
                query = GET_CGVS;
            break;
            default:
                return null;
        }
        this.props.client.query({
            query: query,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            let newDatas = []
            switch(type){
                case 'agency' :
                    newDatas = result.data.agencyPoles.edges
                break;
                case 'tarif' :
                    newDatas = result.data.agencyRates.edges
                break;
                case 'tax' :
                    newDatas = result.data.agencyTaxRates.edges
                break;
                case 'paymentTerms' :
                    newDatas = result.data.agencyPaymentTerms.edges
                break;
                case 'paymentDeadlines' :
                    newDatas = result.data.agencyPaymentDeadlines.edges
                break;
                case 'cgv' :
                    newDatas = result.data.agencyTermAndConditions.edges
                break;
                default:
                    return null;
            }
            if (newDatas.length === 0){
                this.setState({
                    isEmpty: true
                })
            }else{
                this.setState({
                    listDatas: newDatas,
                    isEmpty: false
                },)
            }
        });
        this.props.stopLoading();
    }

    checkAccess() {
        let type = null;
        switch(this.state.identifier){
            case 'agency' :
                type = CRM_AGENCIES_POLES;
            break;
            case 'tarif' :
                type = CRM_AGENCIES_RATES;
            break;
            case 'tax' :
                type = CRM_AGENCIES_TAX_RATES;
            break;
            case 'paymentTerms' :
                type = CRM_AGENCIES_PAYMENT_TERMS;
            break;
            case 'paymentDeadlines' :
                type = CRM_AGENCIES_PAYMENT_DEADLINES;
            break;
            case 'cgv' :
                type = CRM_AGENCIES_POLES;
            break;
            default:
                return null;
        }
        const getRights = hasRights(CRM_AGENCIES, type, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            this.setState({
                accessType: type,
            })
        }
    }

    componentDidMount() {
        this.checkAccess();
        this.handleInitData(this.state.identifier, true);
        this.handleGetDatas(this.state.identifier);
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.typeAgency !== prevProps.typeAgency){
            this.props.startLoading();
            this.setState({
                openForm: false,
                // openFormSetupChannel: false,
                editForm: 'add',
                isEmpty : false,
                identifier: this.props.typeAgency,
                emptyPicto: null,
                dataLayout: null,
                openDialogRemove: false,
                currentLang: this.props.locales[0].node.id,
                errors: {},
                seeErrors: false,
                name: null,
                price: null,
                valueTax: null,
            }, () => {
                this.checkAccess();
                this.handleInitData(this.state.identifier);
                this.handleGetDatas(this.state.identifier);
                this.props.stopLoading();
            })
        }
    }
    
    render() {
        return (
            <div>
                {this.state.content ?
                    (
                        <TopPanel 
                            icomoon={this.state.content.picto} 
                            colorIcomoon={colors.blue.darker.hue300} 
                            title={this.state.content.title} 
                            subtitle={this.state.content.subtitle} 
                            gradientColor1={colors.menu.regular} 
                            gradientColor2={colors.menu.darker} 
                            handlerAdd={() => {this.handleToggleDrawer('form', true)}} 
                            textAdd={hasRights(CRM_AGENCIES, this.state.accessType, CREATE) ? this.state.content.txtBtn : null} 
                            // searchHandler={(this.state.isEmpty || !this.state.channelByCategorie) ? null : this.handleChange} 
                            // stats={this.stats}
                            openForm={this.state.openForm}
                            buttonAvailable={this.state.listDatas !== null ? true : false}
                            windowWidth={this.props.windowWidth}
                            noResult={this.state.isEmpty}
                            hasBorder={true}
                        />
                    ) : ''
                }
                <Grid container direction="column" justifyContent="center" spacing={0} style={{width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", marginTop: 16, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                    <Grid container direction="row" spacing={2}>
                        {this.state.isEmpty ? 
                            <EmptyCard title={hasRights(CRM_AGENCIES, this.state.accessType, CREATE) ? this.state.content.emptyTitle : "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_AGENCIES, this.state.accessType, CREATE) ? this.state.content.emptySubtitle : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_AGENCIES, this.state.accessType, CREATE) ? this.state.content.emptyTxtBtn : null} onClick={() => {this.handleToggleDrawer('form', true)}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                        :
                            <>
                                {
                                    this.state.listDatas?.length > 0 ? (
                                        this.state.listDatas.map((type, index) =>
                                            <Grid item xs={this.state.openForm ? 4 : 2} key={`Agency${index}`}>
                                                <CardCustom paddingbottom="8px" paddingtop="32px">
                                                    <Grid container justifyContent="center" alignItems="center" direction="column">
                                                        <Typography variant={"h2"}>{type.node.name}</Typography>
                                                        {
                                                            type.node.price ? 
                                                                <Typography variant={"h3"}>{type.node.price} €</Typography>
                                                                : null
                                                        }
                                                        {
                                                            type.node.value ? 
                                                                <Typography variant={"h3"}>{type.node.value} %</Typography>
                                                                : null
                                                        }
                                                        {/* {
                                                            type.node.content ? 
                                                                <div dangerouslySetInnerHTML={{ __html: type.node.content }}></div>
                                                                : null
                                                        } */}
                                                    </Grid>
                                                    <Grid container justifyContent="center" style={{marginTop: 16}}>
                                                        {
                                                            hasRights(CRM_AGENCIES, this.state.accessType, UPDATE) ?
                                                                <IconButton
                                                                    style={{ color: colors.blue.darker.hue300 }}
                                                                    onClick={() => {
                                                                        this.editAgency(type.node)
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            : null
                                                        }
                                                        {
                                                            hasRights(CRM_AGENCIES, this.state.accessType, DELETE) ?
                                                                <IconButton
                                                                    style={{ color: 'red' }}
                                                                    onClick={() => {
                                                                        this.deleteAgency(type.node.id)
                                                                    }}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            : null
                                                        }
                                                    </Grid>
                                                </CardCustom>
                                            </Grid>
                                        )
                                    ) 
                                    :
                                    (<PageLoader />)
                                }
                            </>
                        }
                    </Grid>
                </Grid>
                {/* {
                    this.state.dataLayout && this.state.typeOf?.length > 0 ?
                    (
                        <LayoutBuilder opened={this.state.openForm} forClose={() => {this.handleToggleDrawer('form')}} handlerSetup={this.handlerSetup} dataLayout={this.state.dataLayout} dataCard={this.state.typeOf} drawerWidth={this.props.drawerWidth} />
                    ) : ''
                } */}
                {
                    this.state.dataAddLayout ?
                    (
                        <LayoutBuilder 
                            opened={this.state.openForm}
                            // image={this.state.imageForm}
                            icomoon={this.state.content.picto}
                            forClose={() => {this.handleToggleDrawer('form', false)}} 
                            dataLayout={this.state.dataAddLayout} 
                            handlerMutation={this.handlerMutation}
                            drawerWidth={this.props.drawerWidth}
                            stateCallback={this.handleInputChange}
                            backStepperButtonAction={[
                                () => {
                                    this.setState({ errors: {} });
                                }, 
                                () => {
                                    this.setState({ errors: {} });
                                },
                                () => {
                                    this.setState({ errors: {} });
                                },
                                null
                            ]}
                            stepperButtonAction={[
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: false });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }, 
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                },
                                () => {
                                    if (this.hasErrors()) {
                                        this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                                        this.setState({ seeErrors: true });
                                        eventService.fire();
                                        return false;
                                    }
                                    return true;
                                }
                            ]}
                            errorCallback={this.handleFormError}
                            validateButton={true}
                            deleteMutation={this.state.editForm === 'edit' && hasRights(CRM_AGENCIES, this.state.accessType, DELETE) ? () => this.deleteAgency() : null}
                            deleteText={this.state.editForm === 'edit' ? 'Supprimer' : null}
                            allState={this.state}
                        />
                    ) : ''
                }
                <Dialog
                    open={this.state.openDialogRemove}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer cet élément ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez cet élément celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.handlerMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListAgencies)));