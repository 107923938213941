import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import CategoryMasterSelector from '../../../../../ui/inputs/CategoryMasterSelector';

export default function merchandisingDuplicate(allState) {

    var form = {
        titleForm: 'Dupliquer une catégorie de merchandisings',
        subTitleForm: `Veuillez sélectionner une catégorie pour dupliquer les merchandisings`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Valider la duplication',
        formConfig: {
            type: FORM,
            children:[
                {
                    labelName:  'Informations',
                    isOptionnal: false,
                    component: CategoryMasterSelector,
                    optionsInputs:{
                        typeToLoad: 'asset',
                        stateName: 'categorySelected',
                        required: false,
                        value: allState
                    }
                }
            ]
        }
    }
    return form;
}