import React, { useEffect } from 'react';
import DialogModal from '../../../ui/dialog/DialogModal';
import { Box, TextField, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';

const ModalSearch = ({ openModal,resetSearchSkus,searchSkus, stateCallbackSearch,searchProducts,currentProductsLoaded,currentProducts,changeProductPosition,states,saveNewSort,searchSkuError,searchLoading,currentProductsNotFound,preOrder}) => {
    const [products,setProducts]= React.useState([])
    useEffect(()=>{
        setProducts(currentProducts)
    },[currentProducts])
  return (
    <DialogModal
        icon={false}
        open={openModal}
        onClose={resetSearchSkus}
        title={`Recherche de produits`}
        closeIcon
        noBorderLeft
        containerStyle={{
            width:"100%"
        }}
    >
        <Box
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 10
            }}
        >
            <TextField
                id={"productSearch"}
                name={"productSearch"}
                autoFocus
                variant="outlined"
                helperText="Vous pouvez rechercher plusieurs produits séparés par une virgule"
                style={{
                    width: "100%",
                }}
                value={searchSkus}
                onChange={(evt) => {
                    stateCallbackSearch(evt.target.value.trim())
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        searchProducts();
                    }
                }}
                FormHelperTextProps={{
                    style:{
                        marginLeft:0,
                        marginTop:5
                    }
                }}                
                inputProps={{
                    style: {
                        height: "30px",
                        padding: "5px 10px"
                    },
                }}
            />
            <Button onClick={searchProducts} margin={"0 !important"}>Rechercher</Button>
        </Box>
        {
            currentProductsLoaded ?
            <Box 
                style={{
                    border: "0.5px solid #E3E3E3",
                    maxHeight:"500px",
                    overflowY:"scroll",
                    marginTop:40
                }}
            >
                {
                    products.map((e, index) => {
                        if (e)
                        return (
                            <Box key={index} style={{
                                padding:5,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: index !== currentProducts.length -1 ?  "0.5px solid #E3E3E3":"none"
                            }}>
                                <Box style={{
                                display: "flex",
                                gap:5
                                }}>
                                    <img 
                                        style={{
                                            width: "110px",
                                            height: "110px",
                                        }} 
                                        src={`${process.env.REACT_APP_MEDIAS}/${e.image}`}
                                    />
                                    <Box>
                                        <Typography>Ref. {e.sku}</Typography>
                                        <Typography style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            color: "#000"
                                        }}>{e.name}</Typography>
                                    </Box>    
                                </Box>
                                <Box>
                                    <Typography>Position</Typography>
                                    <TextField
                                        defaultValue={e.position + 1}
                                        type='number'
                                        variant='outlined'
                                        style={{
                                            width: "50px",
                                            marginRight:5,
                                        }}
                                        InputProps={{ 
                                            inputProps: { 
                                                min: 1,
                                                style:{
                                                    padding:"0 7px"
                                                } 
                                            } ,
                                            style: {
                                                height: "40px",
                                            },
                                        }}
                                        onChange={(evt) => {
                                            changeProductPosition(e.sku,e.position,evt.target.value - 1)
                                        }}
                                    />
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
            :null
        }
        {
            searchLoading &&
            <PageLoader/>
        }
        {
            searchSkuError &&
            <Typography style={{color:"red",marginTop:10}}>Attention : plusieurs produits ont la même position</Typography>
        }
        {
            currentProductsNotFound.length > 0 &&
            <Typography style={{color:"red",marginTop:10}}>Certains produits n'ont pas été trouvé : {currentProductsNotFound.map(e=>e).join(', ')}, veuillez les ajouter manuellement.</Typography>
        }
        {
            currentProducts.length > 0 &&
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop:20,
                    gap:10
                }}
            >
                <Button onClick={preOrder} >Ordonner</Button>
                <Button onClick={saveNewSort} bgcolorhover={colors.green.darker} bgcolor={colors.green.regular}>Valider la modification</Button>
            </Box>
        }
    </DialogModal>
  );
};

export default ModalSearch;