import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SearchTags from '../../../../ui/inputs/SearchTags';

export default function mediasCategoriesConfig (categories,categorieSelected,imagePath,type,mediaTags, selectedTags, oldTags){
    var obj={
        langSelect: false,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        titleForm: 'Modifier un média',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour modifier votre média',
        textButtonValidate: 'Modifier',
        formConfig:{
            type:STEPPER,
            finalStep: {
                /*picto: CatalogFinal,*/
                title: 'Bientôt prêt !',
                subtitle: 'Votre media est presque prêt, encore une étape ! ',
                textButton: 'Editer le média'
            },
            
            children:[ 
                    {
                        labelName: 'Média',
                        isOptionnal: false,
                        optionsInputs:[
                            {
                                type:'oneImage',
                                label: 'Média (30 Mo max)',
                                translated: false,
                                helper: {
                                    text: 'Média',
                                    link: false,
                                },
                                required: true,
                                stateName: 'formImage',
                                allowedMedia: type,
                                // handleMediaPicker: handleMediaPicker,
                                // value:state.formImage
                            },                             
                           {
                                type:'text',
                                label: 'Nom du média',
                                translated: false,
                                helper: {
                                    text: 'Nom du média',
                                    link: false,
                                },
                                required: true,
                                stateName: 'fileName',
                                // disabled:true
                            },
                            {
                                type:'text',
                                label: 'Texte alternatif',
                                translated: false,
                                helper: {
                                    text: 'Aide à la SEO et à l\'accessibilité',
                                    link: false,
                                },
                                required: false,
                                stateName: 'fileAlt',
                                // disabled:true
                            },
                            // {
                            //     type:'textarea',
                            //     label: 'Description',
                            //     translated: false,
                            //     helper: {
                            //         text: 'Description du média',
                            //         link: false,
                            //     },
                            //     required: false,
                            //     stateName: 'fileDescription',
                            // },
                            {
                                type: 'selectTree',
                                multiselect : true,
                                label: 'Dossier',
                                translated: false,
                                helper: {
                                    text: 'Indiquez le dossier de destination du média',
                                    link: false,
                                },
                                // disabled:true,
                                stateName: 'parent',
                                data: (() => {
                                    /*let tree    = [];
                                    let root    = 
                                    {
                                        children    : [],
                                        checked     : categorieSelected.length>0 ? false : true,
                                        expanded    : true,
                                        label       : '/',
                                        node        : 
                                        {
                                            id: "root",
                                            libelle: "/",
                                            parent: null 
                                        }
                                    }*/
                                    let data = categories.filter(e => e.node.parent === null);
                                    
                                    let populateChildren = (cats, parent) => {
                                      
                                        let catSelected         = categorieSelected.find(e => e.node.id === parent.node.id);
                                        parent.value            = parent.node.id;
                                        parent.label            = parent.node.libelle;
                                        parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                        parent.checked          = catSelected ? true : false;
                                        parent.expanded         = true;

                                        
                                        for (let child of parent.children){
                                            populateChildren(cats, child);                                            
                                        }
                                    };
    
                                    for (let parent of data){
                                        populateChildren(categories, parent); 
                                    }
                                    /*root.children=data;
                                    tree=tree.concat(root);*/
                                        
    
                                    return data;


                                })()
                            },
                            {
                                type: 'selectTree',
                                multiselect : true,
                                label: 'Tags',
                                translated: false,
                                required:false,
                                bottom:true,
                                helper: {
                                    text: 'Indiquez les tags à associer à votre média',
                                    link: false,
                                },
                                stateName: 'currentTags',
                                data: (() => {
                                    let data = mediaTags
                                    let populateChildren = (cats, parent) => {
                                        let tagSelected         = selectedTags.find(e => e.node?.id === parent.node.id) || '/api/media-object-tags/'+selectedTags[0] === parent.node.id ? parent.node.id : null;
                                        parent.value            = parent.node.id;
                                        parent.label            = parent.node.tag;
                                        parent.id               = parent.node.id;
                                        parent.checked          = tagSelected ? true : false;
                                    };
    
                                    for (let tag of data){
                                        populateChildren(mediaTags, tag); 
                                    }
                                    return data;
                                })()
                            }
                        ]   
                    },

                    // {
                    //     labelName : 'Tags',
                    //     isOptionnal : false,
                    //     component : SearchTags,
                    // }
            ]
        }

    }
    return obj;
}