import { FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Email de contact",
    key: "email_contact",
    type: ItemTypes.EMAIL_CONTACT,
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        firstBlock: {
            id: uuidv4(),
            name: "Paramétrage du bloc",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
        },
        logo: getImageDefault(null, "Logo", false),
        title: getDefaultText("Titre", "Un visiteur vous a laissé un message :",  24, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        object: getDefaultText("Objet", "Objet :", 18, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        email: getDefaultText("Email", "Email :", 16, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        name: getDefaultText("Nom", "Nom :", 16, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        message: getDefaultText("Message", null,  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        footer: {
            id: uuidv4(),
            name: "Paramétrage du bloc 2",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#F0F7FA'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
        },
        footerText: getDefaultTextarea("Texte du footer", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis molestie urna ante, non euismod mi hendrerit eget. Aliquam erat volutpat. Pellentesque placerat arcu sed eros ultrices, id scelerisque erat interdum. Aliquam id est nec ipsum rhoncus molestie. Cras a felis orci. In dictum felis a congue pharetra.", false, "#53839D", null, 'initial', 'initial', 12),
    }
};
