import React, { useEffect, useState, useRef } from "react";
import Div from '../shared/div';
import Img from '../shared/img';
import styled from 'styled-components';

const DivBannerMedia = styled(Div)`
    width: 100%;
    video{
        width: 100%;
    }
`;

const CustomDiv = styled(Div)`
    max-width: ${props=>props.maxWidth}%;
    margin:auto;
    display:flex;
    justify-content : ${props=>props.justify};
    padding : ${props=>props.paddingVertical}px 0;
`;

const BannerMedia = (props) => {
    const [hover, setHover] = useState(false);
    const vidRef = useRef(null);
    
    let {
        preview = false, 
        data,
    } = props;
    
    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];

    let isVideo = VIDEOS.includes(data.blocks.media.inputs.value.value.type) ? true : false;

    console.log(data.blocks.media.inputs?.videoControllers?.value)

    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <DivBannerMedia>
                <Div>
                    <CustomDiv paddingVertical={data.blocks.media.inputs.paddingVertical?.value} justify={data.blocks.media.inputs.justifyImage?.value} maxWidth={data.blocks.media.inputs?.maxWidth?.value}>
                        {
                            isVideo ? (
                                <video autoPlay muted loop ref={vidRef} playsInline controls={data.blocks.media.inputs?.videoControllers?.value}>
                                    <source src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}`} type="video/mp4"/>
                                </video>
                            ) : (
                                <Img 
                                    className="w100" 
                                    src={
                                        typeof data.blocks.media.inputs.value.value === 'string' ? 
                                            data.blocks.media.inputs.value.value 
                                        : data.blocks.media.inputs.value.value.filePath ? 
                                            `${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.value.value.filePath}` 
                                        : null
                                    }
                                    style={{
                                        display: 'block',
                                        maxWidth: `${data.blocks.media.inputs.imageWidth?.value}%`,
                                    }}
                                    alt={data.blocks.media.inputs.alt?.value ?? "Illustration"}
                                />
                            )
                        }
                    </CustomDiv>
                </Div>
            </DivBannerMedia>
        </Div>
    );
};

export default BannerMedia;