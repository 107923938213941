import React, { useEffect, useState } from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardLanguage from '../../../layouts/Card/cardContent/CardLanguage';
import ValidateLanguages from './validate/ValidateLanguages';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import { withApollo } from 'react-apollo';
import { GET_LOCALES } from '../../../../queries/locales';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SETTINGS, SETTINGS_LANGUAGES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {withTranslation} from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import emptyProduct from '../../../../assets/pictos/empty-picto/empty_assets.png';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative', 
        minHeight: 'calc(100% - 64px)',
        height: 'calc(100% - 64px)',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        width: '100%',
    },
    wrapper: {
        marginTop: 16
    },
    nbLanguages: {
        paddingLeft: 16, 
        paddingTop: 16
    },
  }));

const SettingsLanguages = (props) => {
    // State
    // const [isOpenForm, setIsOpenForm] = useState(false)
    const [numberLang, setNumberLang] = useState({
        value: 0,
        totalValue: 0,
        initialValue: 0,
    })
    const [languages, setLanguages] = useState([])
    const [existentLanguages, setExistentLanguages] = useState([])

    const [noLang, setNoLang] = useState(true)

    const [changes, setChanges] = useState([])
    const [isValidate, setIsValidate] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [successDeleteSnack, setSuccessDeleteSnack] = useState({
        text: 'La suppression de la langue s\'est déroulée avec succès',
        type: 'success',
        isOpen: false
    })

    const classes = useStyles();

    // Hooks
    useEffect(() => {
        checkRouting(props);
        handleGetLanguages();
    }, []);

    // Functions
    const handleGetLanguages = () => {
        props.client.query({
            query: GET_LOCALES,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            setLanguages([])
            if(result.data.locales.edges.length > 0){
                setNumberLang(prev => ({
                    ...prev,
                    initialValue: result.data.locales.edges.length,
                    totalValue: result.data.locales.edges.length,
                }))
                setNoLang(false)
                setExistentLanguages(result.data.locales.edges)

                setIsLoading(false)
            }
            else{
                setIsLoading(false)
            }
        });
    }

    const handleExistentLangInputChange = (data) => {
        let found = false;
        for (let item of changes) {
            if (item.id === data.id) {
                item.data = data.data;
                handleCheckValidate();
                found = true;
            }
        }
        
        if (!found)
            changes.push(data);
        
        setChanges([].concat(changes));
    }

    const handleNewLangInputChange = (data) => {
        for (let language of languages) {
            if (language.id === data.id) {
                language.libelle = data.data.libelle;
                language.code = data.data.code;
                handleCheckValidate();
            }
        }
    }


    const handleAddLanguage = () => {
        setNumberLang(prev => ({
            ...prev,
            totalValue: prev.totalValue + 1
        })) 
        setLanguages(prev => [...prev, {
            "id": numberLang.totalValue,
            "libelle": '',
            "code" : '',
        }])
        setIsValidate(false)
    }

    const handleRemoveExistentLanguage = () => {
        if(numberLang.initialValue === 1){
            setNumberLang(prev => ({...prev, initialValue: 0}))
            setNoLang(true)
        } else {
            setNumberLang(prev => ({...prev, initialValue: prev.initialValue - 1}))
        }
        setSuccessDeleteSnack(prev => ({...prev, isOpen: true}))
        handleGetLanguages();
    }


    const handleRemoveLanguage = (index) => {
        setNumberLang(prev => ({...prev, totalValue: prev.totalValue - 1}))

        let i = 0;
        for (let language of languages) {
            if(i>index){
                language.id = language.id - 1;
            }
            i++;
        }
        setLanguages(prev => (prev.filter((t) => t !== index)))
    }

    const handleResetData = () => {
        handleGetLanguages();
        setChanges([]);
    }

    const handleCheckValidate = () => {
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.libelle === '' || item.data.code === ''){
                    success = false;
                }
            }
            
        }
        for (let language of languages) {
            if (success) {
                if(language.libelle === '' || language.code === ''){
                    success = false;
                }
            }
        }
        if(success){
            setIsValidate(true)
        }
        else{
            setIsValidate(false)
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessDeleteSnack(prev => ({...prev, isOpen: false}))
    };

    return (
        <div className={classes.root}>
            <TopPanel 
                icomoon="ico_flag" 
                colorIcomoon={colors.blue.lighter.hue300} 
                title={props.t('settings.langues.title')} 
                subtitle={props.t('settings.langues.subTitle')} 
                handlerAdd={!isLoading ? handleAddLanguage : null} 
                textAdd={props.t('settings.langues.buttonadd')}
                gradientColor1={colors.menu.regular} 
                gradientColor2={colors.menu.darker} 
                buttonAvailable={true}
                hasBorder={true}
                windowWidth={props.windowWidth}
            />
            <Box style={{
                position: 'relative',
            }}>
                <Grid container direction="column" justifyContent="center" spacing={0} className={classes.wrapper}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !isLoading ?
                                (
                                    noLang ? 
                                        languages.length === 0 ? 
                                            (<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={this.props.t('settings.tax.buttonadd')} onClick={handleAddLanguage} picto={emptyProduct} mtImg={2} />) 
                                        :
                                            null
                                    : 
                                        (
                                            /* Existent languages list */
                                            existentLanguages.map((language, i) => {
                                                return(
                                                    <React.Fragment key={`ContainerLangueQuery${i}`}>
                                                        <Grid container className={classes.nbLanguages}>
                                                            <Typography>{props.t('settings.langues.langue')} n°{i+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                <CardLanguage
                                                                    windowWidth={props.windowWidth}
                                                                    type="existing"
                                                                    handleInputChange = {handleExistentLangInputChange}
                                                                    handleRemove={handleRemoveExistentLanguage} 
                                                                    language={language.node} 
                                                                    disabled={null}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                ) 
                                : 
                                (<PageLoader />)
                        }
                
                        {/* New language to add */}
                        {languages.map((language, index) =>
                            <React.Fragment key={`ContainerLangue${index}`}>
                                <Grid container className={classes.nbLanguages}>
                                    <Typography>{props.t('settings.langues.langue')} n°{language.id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardCustom paddingbottom="8px" paddingtop="32px">
                                        <CardLanguage
                                            windowWidth={props.windowWidth}
                                            type="new"
                                            handleInputChange={handleNewLangInputChange} 
                                            handleRemove={() => handleRemoveLanguage(index)}
                                            language={language} 
                                        />
                                    </CardCustom>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
                
                {
                    existentLanguages.length > 0 || languages.length > 0 ?
                    (
                        <ValidateLanguages newLanguages={languages} changesLanguages={changes} validate={isValidate} handleResetData={handleResetData} />
                    ) : null
                }
                
                {/* The delete success dialog */}
                <Snackbar open={successDeleteSnack.isOpen} autoHideDuration={10000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={successDeleteSnack.type}>
                        {successDeleteSnack.text}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withTranslation()(withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsLanguages))));
