import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../CardCustom';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import BlockMinisite from '../../../ui/asset/minisite/BlockMinisite';
import { GET_MINISITE_PAGES } from '../../../../queries/asset_minisites';
import { GET_GAME_PAGES } from '../../../../queries/asset_games';
import { ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE, ROUTE_DIFFUSION_PERMANENTS_PAGE } from '../../../../js/constants/route-names';
import { withTranslation, Trans } from 'react-i18next';
import { ROUTE_COMMUNITIES_MINISITES_DETAIL, ROUTE_SALES_ESHOP_DETAIL, ROUTE_SALES_SHOPS_DETAILS, ROUTE_SALES_SOCIAL_COMMERCE_DETAILS } from '../../../../js/constants/route-names';
import { ASSET_GAME, ASSET_MINISITE, EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;

const SpanColor = styled.span`
    color: ${colors.blue.darker.hue300};
    font-weight: bold;
`;

class CardMinisite extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            minisitePageTypes: [],
            gamePageTypes: [],
            data: this.props.data,
            assetType: '',
        };
    }

    componentDidMount() {
        this.props.data.assetType.identifier === 'jeu'
            ? this.handleGetAttributesAssetGame()
            : this.handleGetAssetPages();
    }

    handleGetAssetPages() {
        this.props.client.query({
            query: GET_MINISITE_PAGES,
            variables: {asset : this.props.data.id}
        }).then(result =>{
            let totalPageNotDone = 0;

            for (let item of result.data.assetMinisitePages.edges) {
                if (item.node.status === '0') {
                    totalPageNotDone++ 
                }
            }

            this.setState({
                minisitePageTypes: result.data.assetMinisitePages.edges,
                totalPageNotDone: totalPageNotDone,
                assetType: 'minisite',
            });
        });
    }

    handleGetAttributesAssetGame() {
        this.props.client.query({
            query: GET_GAME_PAGES,
            variables: { asset: this.props.data.id }
        }).then(result => {
            let totalPageNotDone = 0;

            for (let item of result.data.assetGamePages.edges){
                if (item.status === 0 || 1){
                    totalPageNotDone++
                }
            }

            this.setState({
                minisitePageTypes : result.data.assetGamePages.edges,
                totalPageNotDone  : totalPageNotDone,
                assetType: 'jeu',
            });
        });
    }

    goTo = (route, state) => {
        this.props.history.push({
            pathname : route,
            state: state
        });
    };

    render() {

        const totalPages = this.state.totalPageNotDone;
        const assetType = this.state.assetType;
        let routeByAsset = null
        if (ASSET_MINISITE.includes(this.props.data.assetType.identifier)){
            routeByAsset = ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE
        }
        // else if (this.props.data.assetType.assetCategory.identifier === "instore"){
        //     routeByAsset = ROUTE_SALES_SHOPS_DETAILS
        // }else if (this.props.data.assetType.assetCategory.identifier === "connector"){
        //     routeByAsset = ROUTE_SALES_SOCIAL_COMMERCE_DETAILS
        // }else{
        //     routeByAsset = ROUTE_SALES_ESHOP_DETAIL
        // }
        return (
            <Grid container>
                <Grid container direction="row" justifyContent="center">
                    <GridCustom item xs={12}>
                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'16px 32px'}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} style={{marginBottom:50, marginTop: 20}}>
                                    <Typography variant="h3">
                                        {this.props.data.assetType.identifier === 'jeu' 
                                            ? this.props.t("spread.active_assets.gameConfigurator")
                                            : this.props.isFlipbook
                                                ? this.props.t("spread.active_assets.flipbookConfigurator")
                                                : this.props.t("spread.active_assets.minisiteConfigurator")
                                        }
                                    </Typography>
                                    {
                                            this.state.totalPageNotDone > 0 ?
                                                <Typography variant="body2" style={{lineHeight: '25px'}}>
                                                    {totalPages > 0 ?
                                                        <Trans i18nKey="spread.active_assets.pageUnset_plural">
                                                            Plus que <SpanColor>{{totalPages}} pages</SpanColor> à configurer avant de pouvoir mettre en ligne votre {{assetType}}.
                                                        </Trans>
                                                        :
                                                        <Trans i18nKey="spread.active_assets.pageUnset">
                                                            Plus que <SpanColor>{{totalPages}} page</SpanColor> à configurer avant de pouvoir mettre en ligne votre {{assetType}}.
                                                        </Trans>
                                                    }
                                                </Typography>
                                            :
                                                <Typography variant="body2" style={{lineHeight: '25px'}}>
                                                    <Trans i18nKey="spread.active_assets.allPagesSet">
                                                        <SpanColor>Toutes les pages</SpanColor> de votre {{assetType}} sont configurées.
                                                    </Trans>
                                                </Typography>
                                    }
                                </Grid>
                                <Grid container style={{marginBottom:30}} spacing={4}>
                                    {
                                        this.state.minisitePageTypes.length > 0 ? 
                                            this.state.minisitePageTypes.map((page, index) => {
                                                return (
                                                    <BlockMinisite 
                                                        key={`BlockMinisite${index}`}
                                                        onClick={() => {this.goTo(routeByAsset
                                                            .replace(':id', this.props.data.id.replace('/api/assets/', ''))
                                                            .replace(':page', page.node.assetGamePageType ? page.node.assetGamePageType.identifier : page.node.assetMinisitePageType.identifier), 
                                                            { 
                                                                minisitePageTypes: this.state.minisitePageTypes, 
                                                                minisite: page, 
                                                                asset: this.state.data
                                                            }
                                                        )}} 
                                                        data={page}
                                                        type={this.state.data.assetType.identifier}
                                                    />
                                                )   
                                            })
                                        : null
                                    }                             
                                </Grid>
                            </Grid>
                        </CardCustom>
                    </GridCustom>
                </Grid>                
            </Grid>
        );
    }
}
export default withTranslation()(withApollo(withRouter(CardMinisite)));