import React from 'react';
import { Box, Grid, Typography, Tooltip, TextField, Checkbox, MenuItem } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DoneIcon from '@material-ui/icons/Done';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 13px;
        padding: 10px;
    }
    & > p{
        margin: 0;
    }
`;
const ButtonCustom = styled(Button)`
    margin: 0;
    text-transform: unset;
    font-weight: bold;
`;
const Title = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Variants = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
`;
const Sku = styled(Typography)`
    color: ${colors.black.regular};
    text-align: center;
    font-weight: bold;
`;

const SpanColor = styled.span`
    color: ${colors.black.regular};
    font-weight: bold;
    display: block;
    width: 100%;
`;
const TableCellCustom = styled(TableCell)`
    border-bottom: ${props => props.isLast ? 'none' : `0.5px solid ${colors.grey.border}`};
    /* border-bottom: none; */
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 13px 18px;
`;
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`;

const CheckboxCustom = styled(Checkbox)`
    background: white;
    border-radius: 5px;
    padding: 2px;
    svg{
        fill: ${colors.blue.darker.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;

const CheckboxCustomlist = styled(CheckboxCustom)`
    background: transparent;
    margin: 0;
`
const BoxCustom = styled(Box)`
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
`

export const TooltipCell = (props) => {
    const container = React.useRef(null);
    const child = React.useRef(null);
    const [isOverflow, setIsOverflow] = React.useState(false);

    React.useEffect(() => {
        setIsOverflow(checkIfOverflows(container.current, child.current));
    }, []);

    const checkIfOverflows = (c, e) => {
        return (e.offsetWidth + (18*2) > c.offsetWidth);
    }

    return (
        <TableCellCustom style={{minWidth: props.minimalForm ? null : 150, maxWidth: props.minimalForm ? 150 : 250}} isLast={props.isLast} ref={container}>
            {isOverflow ? (
                <Tooltip title={props.content} arrow>
                        <Title variant="body1"><span ref={child}>{props.content || 'N/A'}</span></Title>
                </Tooltip>
                ) : (
                    <Title variant="body1"><span ref={child}>{props.content || 'N/A'}</span></Title>
                )
            }
        </TableCellCustom>
    )
}

export default function CardProductList(props){

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const product = props.product.node;
    //const attributes = product.productDatas.edges;

    let name = '';
    let image= '';
    let imageSelected = [];
    let getImageByTranslation = '';
    
    // for(let attribute of attributes){
    //     if(attribute.node.attribute.isSystem){
    //         imageSelected = attributes.filter(e => e.node.media !== null)
    //         getImageByTranslation = imageSelected.filter(e => e.node.locale.code === props.currentLang)
    //         name = getTraductionAttributs('product_name', attributes, props.currentLang);
    //         if(attribute.node.attribute.identifier === 'product_image' && image === ''){
    //             image = getTraductionAttributs('product_image', attributes, props.currentLang, 'image');
    //         }
    //     }
    // }
    
    name = product.flatProducts?.edges[0]?.node.name;
    image = product.flatProducts?.edges[0]?.node.image;

    let sku = product.flatProducts?.edges[0]?.node.sku;
    let productStatus = product.flatProducts?.edges[0]?.node.status;
    let categoriesNb = product.flatProducts?.edges[0]?.node.categoriesNb || 0;
    let catalogsNb = product.flatProducts?.edges[0]?.node.catalogsNb;
    let childrenNb = product.flatProducts?.edges[0]?.node.childrenNb;
    let imagesNb = product.flatProducts?.edges[0]?.node.imagesNb || 0;
    let completude = product.flatProducts?.edges[0]?.node.completude;
    let nbAttrImages = product.flatProducts?.edges[0]?.node.imagesMaxNb || 1;

    // let localStorage_GET_NB_ATTR_IMAGES = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // if(!localStorage_GET_NB_ATTR_IMAGES){
    //     let localStorageAttributes  = localStorage.getItem('ATTRIBUTES');
    //     let attributes = JSON.parse(localStorageAttributes);
    //     for (let attribute of attributes.product.attributes.edges){
    //         if(attribute.node.attributeType.input=='image'){
    //             nbAttrImages++;
    //         }
    //     } 
    //     localStorage.setItem('GET_NB_ATTR_IMAGES',nbAttrImages);
    // } else {
    //     nbAttrImages = localStorage.getItem('GET_NB_ATTR_IMAGES');
    // }

    let catalogList = []
    // for (let category of product.productCategories.edges){
    //     if (category.node.category.catalog){
    //         if (catalogList.indexOf(category.node.category.catalog.libelle) === -1){
    //             catalogList.push(category.node.category.catalog.libelle)
    //         }
    //     }
    // }

    const goTo = props.routeProduct;

    return(
        <TableRow key={`rowProduct${props.index}`} onClick={props.selectedProducts ? () => props.handleToggle(props.product) : null} style={{cursor: props.selectedProducts ? 'pointer' : 'initial',boxShadow:"none"}}>
            {
                props.selectedProducts ?
                <TableCellCustom padding={'checkbox'}  style={{borderLeft: `0.5px solid ${colors.grey.border}`}} component="th" scope="row" align="left" isLast={props.index + 1 === props.countProducts}>
                    <CheckboxCustomlist
                        edge="start"
                        checked={props.selectedProducts.find(e=>e.node?.id === product?.id)?true :false}
                    />
                </TableCellCustom>
                : null
            }
            <TableCellCustom padding={'checkbox'}  style={{borderLeft: !props.selectedProducts ? `0.5px solid ${colors.grey.border}` : null}} align="left" isLast={props.index + 1 === props.countProducts}>
                <StatusInfo status={product.status} justify={"center"}/>
            </TableCellCustom>
            {/* <TableCellCustom component="th" scope="row" align="center">
                {
                    image && image !== '' ? 
                        (<img src={`${process.env.REACT_APP_MEDIAS}/${image}`} alt={name} style={{maxWidth: '100%', maxHeight: 40}}/>) 
                    : 
                        (<img src={NoImage} style={{maxWidth: '100%', maxHeight: 40}} alt={name}/>)
                }
            </TableCellCustom> */}
            <TableCellCustom isLast={props.index + 1 === props.countProducts}>
                <Sku variant="body2">{product.sku}</Sku>
            </TableCellCustom>

            <TooltipCell isLast={props.index + 1 === props.countProducts} content={name} minimalForm={props.minimalForm} />

            {!props.minimalForm ? (
                <TableCellCustom isLast={props.index + 1 === props.countProducts}>
                    <Variants variant="body1">{childrenNb || 'N/A'}</Variants>
                </TableCellCustom>
            ) : null}
            {
                !props.minimalForm ?
                <>
                    <TableCellCustom align="left" isLast={props.index + 1 === props.countProducts}>
                       
                        {
                            <BootstrapTooltip 
                                title={categoriesNb}
                                arrow
                            >
                                <SpanColor> {categoriesNb}</SpanColor>
                            </BootstrapTooltip>
                        }
                    </TableCellCustom>
                    <TableCellCustom align="center" isLast={props.index + 1 === props.countProducts}>
                        {
                            catalogsNb> 0 ? (
                                <BootstrapTooltip 
                                    title={catalogsNb}
                                    arrow
                                >
                                    <SpanColor> {catalogsNb}</SpanColor>
                                </BootstrapTooltip>
                            ) : ( <Typography variant="body1"><SpanColor>Aucun catalogue</SpanColor></Typography> ) 
                        }
                    </TableCellCustom>
                </>
                : null
            }
            {
                !props.minimalForm ?
                    <TableCellCustom align="center" isLast={props.index + 1 === props.countProducts}>
                        <Grid container alignItems="center" justifyContent="center">
                            <Typography variant="body1"><SpanColor style={{width: "auto", display:"inline"}}>{imagesNb}</SpanColor></Typography>
                            {/* <Typography variant="body1"><SpanColor style={{width: "auto", display:"inline"}}>{imagesNb}</SpanColor>/{nbAttrImages}</Typography> */}
                        </Grid>
                    </TableCellCustom>
                :null
            }
            {
                process.env.REACT_APP_MODE_SPREAD !== "hub" && !props.minimalForm&& !props.isBundle ? 
                    <>
                        <TableCellCustom align="center" isLast={props.index + 1 === props.countProducts}>
                            <BoxCustom position="relative" display="inline-flex">
                                {/* <CircularProgressCustom variant="determinate" thickness={2.5} value={completude} style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }} size={50}/>
                                <CircularProgress
                                    variant="determinate"
                                    value={100}
                                    disableShrink
                                    thickness={2.5}
                                    size={50}
                                />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" style={{color: colors.green.regular, fontWeight: 'bold'}}>
                                        {completude > 0 ? `${Math.round(completude)}%` : `N/A`}
                                    </Typography>
                                </Box> */}
                                <Typography variant="h5" style={{color: colors.green.regular, fontWeight: 'bold'}}>
                                    {completude > 0 ? `${Math.round(completude)}%` : `N/A`}
                                </Typography>
                            </BoxCustom>
                        </TableCellCustom>
                    </>
                : null
            }
            <TableCellCustom padding={'normal'} style={{borderRight: `0.5px solid ${colors.grey.border}`}} align="right" isLast={props.index + 1 === props.countProducts}>
                {
                    props.noSearch?
                    props.isBundle ?
                        <ButtonCustom 
                            disableElevation={true} 
                            stopPropagation={props.inForm} 
                            text={props.textButton?props.textButton:props.minimalForm ? 'Voir' :"Voir le produit"} 
                            bgcolor={colors.blue.lighter.hue600}  
                            bgcolorhover={colors.blue.lighter.hue600}  
                            color={colors.blue.darker.hue300}  
                            colorhover={colors.blue.darker.hue300}  
                            border={`1px solid ${colors.blue.darker.hue300}`}
                            icon={"edit"}
                            onClick={()=>props.buttonCallback(product)} 
                        />
                        :<ButtonCustom 
                            disableElevation={true} 
                            stopPropagation={props.inForm} 
                            text={props.textButton?props.textButton:props.minimalForm ? 'Voir' :"Voir le produit"} 
                            bgColor={colors.green.regular}  
                            onClick={goTo} 
                        />
                    :<Typography onClick={goTo} style={{
                        textDecoration:"underline",
                        cursor:"pointer",
                        color:colors.blue.darker.hue300
                    }}>Détails produit</Typography>
                }
            </TableCellCustom>
        </TableRow>
    );
}