import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function ContactCategoriesTabs(props){
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(()=>{

    if(props.currentStatus){
      props.categories.forEach((category,index)=>{
        if(category.value === props.currentStatus){
          setValue(index)
        }
      })
    }
  })

    

    return(
        <Tabs value={value} onChange={handleChange}>
            {props.categories ? props.categories.map((category) =>
                <Tab label={category.libelle} onClick={()=>props.handleCategory(category.value,props.isForm)} key={`tab-${category.value}`}></Tab>
            )
            :null
            }
        </Tabs>
    )
}