import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bannière media avancée',
    inputs:{
        height:{
            label: 'Hauteur minimum',
            type: FormInputTypes.SLIDER,
            value: 200,
            params: {max: 1000, min: 0, step: 10},
        },
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
    },
    blocks:{
        media: {
            type: EditableTypes.IMAGE,
            name: "Media",
            id: uuidv4(),
            inputs: {
                img: {
                    type: FormInputTypes.IMAGE,
                    label: 'Image de fond',
                    value: "https://via.placeholder.com/1920x980"
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: null,
                    label:"Texte alternatif"
                }
            }
        },
        content: {
            type: EditableTypes.SHORT_TEXT,
            name: "Contenu",
            id: uuidv4(),
            inputs: {
                title: {
                    type: FormInputTypes.TEXT,
                    label: "Titre",
                    value: "Titre"
                },
                fontSizeTitle: {
                    label: "Taille du titre",
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 2},
                    value: 32
                },
                colorTitle: {
                    label: "Couleur du titre",
                    type: FormInputTypes.COLOR,
                    value: "#FFF"
                },
                text: {
                    type: FormInputTypes.TEXTAREA,
                    label: "Texte",
                    value: "Texte"
                },
                fontSizeText: {
                    label: "Taille du texte",
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 2},
                    value: 16
                },
                colorText: {
                    label: "Couleur du texte",
                    type: FormInputTypes.COLOR,
                    value: "#FFF"
                },
                paddingY: {
                    label: "Marge verticale",
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 2},
                    value: 20
                },
                paddingX: {
                    label: "Marge Horizontale",
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 2},
                    value: 20
                },
                positionY: {
                    label: "Position Horizontale",
                    type: FormInputTypes.BUTTON_GROUP,
                    params: [
                        {label: 'Gauche', value: 'flex-start'}, 
                        {label: 'Centré', value: 'center'}, 
                        {label: 'Droite', value: 'flex-end'}
                    ],
                    value: "flex-start"
                },
                positionX: {
                    label: "Position Verticale",
                    type: FormInputTypes.BUTTON_GROUP,
                    params: [
                        {label: 'Haut', value: 'flex-start'}, 
                        {label: 'Centré', value: 'center'}, 
                        {label: 'Bas', value: 'flex-end'}
                    ],
                    value: "center"
                },
                textAlign: {
                    label: "Aligement du texte",
                    type: FormInputTypes.BUTTON_GROUP,
                    params: [
                        {label: 'Gauche', value: 'left'}, 
                        {label: 'Centré', value: 'center'}, 
                        {label: 'Droite', value: 'right'}
                    ],
                    value: "left"
                },
            }
        },
        button: {
            type: EditableTypes.IMAGE,
            name: "Bouton / lien",
            id: uuidv4(),
            inputs: {
                hideButton: {
                    type: FormInputTypes.SWITCH,
                    label: "Masquer le bouton",
                    value: false
                },
                text: {
                    type: FormInputTypes.TEXT,
                    label: "Texte du bouton",
                    value: "Texte"
                },
                link: {
                    type: FormInputTypes.TEXT,
                    label: "lien du bouton",
                    value: "https://www.sinfin.fr"
                },
                fontSize: {
                    label: "Taille du texte",
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 2},
                    value: 20
                },
                color: {
                    type: FormInputTypes.COLOR,
                    label: "Couleur du texte",
                    value: "#FFF"
                },
                bgcolor: {
                    type: FormInputTypes.COLOR,
                    label: "Couleur de fond",
                    value: "#000"
                },
                borderRadius: {
                    type: FormInputTypes.SLIDER,
                    label: "Arrondi",
                    value: 0,
                    params: {max: 50, min: 0, step: 2},
                },
            }
        },
    }
};