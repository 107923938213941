import {gql} from 'apollo-boost';

export const CREATE_SHELL = gql`
mutation CreateShell($eavType: String!, $createdAt: String!, $updatedAt: String!, $status: Boolean!,$sku: String){
	createShell(input: {eavType:$eavType, createdAt:$createdAt, updatedAt:$updatedAt, status:$status, sku:$sku}){
    shell{
      id
    }
  }  
}`;

export const UPDATE_SHELL = gql`
mutation UpdateShell($id: ID!, $sku: String, $updatedAt: String!, $status: Boolean){
    updateShell(input: {id: $id, sku:$sku, updatedAt:$updatedAt, status:$status}){
        shell{
            id
            sku
            status
        }
    }
}`;

export const DELETE_SHELL = gql`
mutation DeleteShell($id: ID!){
    deleteShell(input: {id:$id}){
        shell{
            id
        }
    }
}`;

export const GET_SHELL_BY_EAVTYPE = gql`
query shellsByEavType ($eavType: String!, $page: Int, $itemsPerPage: Int){
    shells(eavType: $eavType, page: $page, itemsPerPage: $itemsPerPage){
        paginationInfo{
            itemsPerPage
            lastPage
            totalCount
        }
        collection{
            id
            sku
            status
            eavType{
                id
                code
                isSystem
            }
            shellDatas{
                edges{
                    node{
                        id
                        value
                        attributeOption{
                            id
                            identifier
                            translation{
                                translationDatas{
                                    edges{
                                        node{
                                            locale{
                                                id
                                                code
                                            }
                                            value
                                        }
                                    }
                                }
                            }
                        }
                        attribute{
                            id
                            identifier
                            isSystem
                            useInCard
                            translation{
                                id
                                translationDatas{
                                    edges{
                                        node{
                                            id
                                            value
                                                locale{
                                                    id
                                                    code
                                                }
                                            }
                                        }
                                    }
                                }
                            attributeType {
                                id
                                input
                            }
                        }
                        media{
                            id
                            filePath
                            type
                            size
                            name
                            category{
                                edges{
                                    node{
                                        id
                                        libelle
                                    }
                                }
                            }
                        }
                        locale{
                            id
                            code
                        }
                    }
                }
            }
        }
    }
}`;
export const GET_SHELL_BY_EAVTYPE_SEARCH = gql`
query customshellsByEavType ($eavTypeCode: String, $page: Int!, $nbperpage: Int!, $shellDatasFilters: [ArrayType]){
    customSearchableShells(eavTypeCode: $eavTypeCode, page: $page, nbPerPage: $nbperpage, order: "desc", shellDatas: $shellDatasFilters){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        edges{
            node{
                id
                sku
                status
                eavType{
                    id
                    code
                    isSystem
                }
                shellDatas{
                    edges{
                        node{
                            id
                            value
                            attributeOption{
                                id
                                identifier
                                translation{
                                    translationDatas{
                                        edges{
                                            node{
                                                locale{
                                                    id
                                                    code
                                                }
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                            attribute{
                                id
                                identifier
                                isSystem
                                useInCard
                                translation{
                                    id
                                    translationDatas{
                                        edges{
                                            node{
                                                id
                                                value
                                                    locale{
                                                        id
                                                        code
                                                    }
                                                }
                                            }
                                        }
                                    }
                                attributeType {
                                    id
                                    input
                                }
                            }
                            media{
                                id
                                filePath
                                type
                                size
                                name
                                category{
                                    edges{
                                        node{
                                            id
                                            libelle
                                        }
                                    }
                                }
                            }
                            locale{
                                id
                                code
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export const GET_SHELL = gql`
query shellsPagination ($eavType: String!, $nbperpage: Int, $cursor: String, $cursorLast: String){
    shells(first: $nbperpage, after: $cursor, before: $cursorLast, eavType: $eavType){
        pageInfo{
            startCursor
            endCursor
        }
        edges{
            node{
                id
                sku
                status
                eavType{
                    id
                    code
                    isSystem
                }
                shellDatas{
                    edges{
                        node{
                            id
                            value
                            attributeOption{
                                id
                                identifier
                                translation{
                                    translationDatas{
                                        edges{
                                            node{
                                                locale{
                                                    id
                                                    code
                                                }
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                            attribute{
                                id
                                identifier
                                isSystem
                                useInCard
                                translation{
                                    id
                                    translationDatas{
                                        edges{
                                            node{
                                                id
                                                value
                                                locale{
                                                    id
                                                    code
                                                }
                                            }
                                        }
                                    }
                                }
                                attributeType {
                                    id
                                    input
                                }
                            }
                            media{
                                id
                                filePath
                                type
                                size
                                name
                                category{
                                    edges{
                                        node{
                                            id
                                            libelle
                                        }
                                    }
                                }
                            }
                            locale{
                                id
                                code
                            }
                        }
                    }
                }
            }
        }
    }
}`;

export const CREATE_SHELL_DATA = gql`
mutation AddShellData($value: String, $shell: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
    createShellData(input: {value:$value, shell:$shell, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
        shellData{
            id
            value
            shell{
                id
            }
            attribute{
                id
            }
            locale{
                id
            }
            media{
                id
            }
            attributeOption{
                id
            }
        }
    }
}`;

export const UPDATE_SHELL_DATA = gql`
mutation UpdateShellData($id: ID!, $value: String, $shell: String, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
    updateShellData(input: {id:$id, value:$value, shell:$shell, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
        shellData{
            id
            value
            shell{
                id
            }
            attribute{
                id
            }
            locale{
                id
            }
            media{
                id
            }
            attributeOption{
                id
            }
        }
    }
}`;

export const DELETE_SHELL_DATA = gql`
mutation DeleteShellData($id: ID!){
	deleteShellData(input: {id:$id}){
    shellData{
      id
    }
  }  
}`;

export const GET_DATAS_OF_A_SHELL = gql`
query shellDatas($id: ID!) {
    shell(id: $id) {
        id
        sku
        status
        shellDatas(first: 200){
            edges{
                node{
                    value
                    id
                    attributeOption{
                        id
                        identifier
                        translation{
                            translationDatas{
                                edges{
                                    node{
                                        locale{
                                            id
                                            code
                                        }
                                        value
                                    }
                                }
                            }
                        }
                    }
                    attribute{
                        id
                        identifier
                        isSystem
                        translation{
                            id
                            translationDatas{
                                edges{
                                    node{
                                        id
                                        value
                                        locale{
                                            id
                                            code
                                        }
                                    }
                                }
                            }
                        }
                        attributeType {
                            id
                            input
                        }
                    }
                    media{
                        id
                        filePath
                        type
                        size
                        name
                        category{
                            edges{
                                node{
                                    id
                                    libelle
                                }
                            }
                        }
                    }
                    locale{
                        id
                        code
                    }
                }
            }
        }
    }
}`;