import { FormInputTypes } from '../../../shareable/types';
import { getDefaultButtonCms, getDefaultTextareaCms, getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc Titre et Texte',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        centerText: {
            label: 'Centrer les textes',
            type: FormInputTypes.SWITCH,
            value: false,
        },
        noPaddingTop: {
            label: "Désactiver l'espace haut",
            type: FormInputTypes.SWITCH,
            value: false,
        },
        noPaddingBottom: {
            label: "Désactiver l'espace bas",
            type: FormInputTypes.SWITCH,
            value: false,
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
        paddingHorizontal: {
            label: "Espace horizontal",
            type: FormInputTypes.SLIDER,
            value: 50,
            params: {max: 200, min: 0, step: 2},
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Lorem ipsum dolor sit amet, consectetur adipiscing elit.", '#000', 41, null, null, null, true,"bold",null,"p"),
        text: getDefaultTextareaCms("Contenu", "Sed mi quam, dictum at malesuada ac, rutrum in libero. Curabitur sit amet scelerisque libero. Maecenas non tellus sed lorem congue ullamcorper eget posuere diam. Integer ornare elementum congue. Cras in turpis nec urna sagittis tincidunt id sed dui. Sed suscipit nulla et velit molestie tristique.", true),
        button :getDefaultButtonCms('Bouton', 'Découvrir', '#000', '#fff', 'initial',true,null,1)
    }
};