import React, { useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import TypographyCustom from '../../ui/typography/Typography';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';

import * as Pagination from '../../../js/utils/pagination';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { eventService } from '../../../js/services/event.service';
import CardCatalog from '../../layouts/Card/cardContent/CardCatalog';
import ButtonCustom from '../../ui/button/Button';
import { ROUTE_CATALOGS_LIST, ROUTE_SALES_ESHOP, ROUTE_SALES_RETAILERS } from '../../../js/constants/route-names';
import CardAssets from '../../layouts/Card/cardContent/CardAssets';
import CardSetup from '../../layouts/Card/cardContent/CardSetup';
import SearchBar from '../../ui/search/SearchBar';
import { withRouter } from 'react-router';

const GridCustom = styled(Grid) `
    padding-bottom: 16px;
    background: rgb(250, 251, 251);
`;
const GridCatalog = styled(Grid) `
    background: ${colors.white};
    height: 100%;
    border-radius: 4px;
`;
const ToggleButtonCustom = styled(ToggleButton) `
    width: 50%;
    padding: 0;
    height: 100%;
    border: none;
    text-align: inherit;
    padding: 8px;
    background: none !important;
    &.error{
        .MuiToggleButton-label{
            border: 1px solid #f44336;
            border-radius: 4px;
        }
    }
`;
const CheckBoxCustom = styled.span `
    border: 1px solid ${colors.grey.regular};
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
    position: relative;
    &.active{
        &:after{
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            top: 2px;
            left: 2px;
            background: ${colors.blue.darker.hue300}
        }
    }
`;

function CheckboxCards(props) {
    let elementSelected = props.allState[props.optionsInputs.stateName];

    const [seeError, setSeeError]                   = React.useState(false);
    const [errorMessage, setErrorMessage]           = React.useState(null);
    const goTo = route => props.history.push(route);
    const checkError = (value = null) => {
        let val     = value === null ? elementSelected : value;
        let error   = false;
        let message = null;
        let type = null;

        if (val?.length === 0 && props.optionsInputs.required) {
            switch (props.optionsInputs.paginationType){
                case 'catalog':
                    type = 'un catalogue'
                break;
                case 'asset':
                    type = 'un asset'
                break
                case 'retailer':
                    type = 'un retailer'
                break
                default:
                    return null
            }
            // required
            error   = true;
            message = `Veuillez sélectionner ${type}`;
        }
        if (val){
            setSeeError(false);
        }

        setErrorMessage(error ? message : null);  
        if (props.errorCallback){  
            props.errorCallback(props.optionsInputs.stateName,error);
        }
    };

    useEffect(() => {
        checkError(); 
        let subscription = eventService.get().subscribe((data) => {
            setSeeError(true);
            if (data && props.optionsInputs.stateName === data.stateName) {
                setErrorMessage(data.errorMessage);
                setSeeError(true)
            }
        });

        return () => subscription.unsubscribe();
    }, [elementSelected]);

    const handleToggle = (value) => {
        const currentIndex  = elementSelected.find(e => e.node.id === value.node.id)
        const newChecked    = [...elementSelected];

        if (!currentIndex) {
            newChecked.push(value);
        } else {
            const index = newChecked.indexOf(currentIndex)
            if (index > -1) {
                newChecked.splice(index, 1);
            }
        }
        props.stateCallback(props.optionsInputs.stateName, newChecked)
    };
    const renderTypeCard = (type, option) => {
        switch (type){
            case 'catalog':
                return <CardCatalog data={option.values} configCard={option.smallCard} openForm={true} inputCard={true}/>
            case 'asset':
                return <CardAssets asset={option.values} inputCard={true} configCard={option.smallCard}/>
            case 'retailer':
                return <CardSetup type={option.values} inputCard={true}/>
            default:
                return null
        }
    };
    const renderNoData = (type) => {
        let text = null;
        let routeButton = null;
        let textButton = null;
        switch (type){
            case 'catalog':
                text = 'Pas de catalogue disponible, cliquez sur ce bouton pour être redirigé vers la création de catalogue.'
                routeButton = ROUTE_CATALOGS_LIST
                textButton = 'Créer un catalogue'
            break;
            case 'asset':
                text = 'Pas d\'asset disponible, cliquez sur ce bouton pour être redirigé vers la création d\'asset.'
                routeButton = ROUTE_SALES_ESHOP
                textButton = 'Créer un asset'
            break;
            case 'retailer':
                text = 'Pas de retailer disponible, cliquez sur ce bouton pour être redirigé vers la création de retailer.'
                routeButton = ROUTE_SALES_RETAILERS
                textButton = 'Créer un retailer'
            break;
            default:
                return null
        }
        return(
            <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
                <TypographyCustom variant={'h4'} colortext={colors.red.regular} style={{width: '60%', textAlign: 'center', marginBottom: 8, marginTop: 16}}>
                    {text}
                </TypographyCustom>
                <ButtonCustom bgcolor={colors.blue.darker.hue300} shadowcolor={colors.blue.darker.hue300} text={textButton} onClick={() => goTo(routeButton)} className="button"/>
            </Grid>
        )
    };
    
    return (
        <GridCustom item xs={12}>
            {
                !props.optionsInputs.isEmpty ?
                    <div>
                        { 
                            props.optionsInputs.paginationType === 'asset' ?
                                null 
                            :   <SearchBar value={props.allState.searchValue} onChange={props.searchHandler} type={props.optionsInputs.paginationType} style={{padding: '16px 8px'}}/>
                        }
                        <div
                            id={props.optionsInputs.stateName}
                            disabled={props.optionsInputs.disabled ? true : false}
                            style={{width: '100%'}}
                        >
                            {props.optionsInputs.value.map((option,index) => {
                                return(
                                    <ToggleButtonCustom onClick={() => handleToggle(option.values)} key={`CheckboxCard-${index}`} className={!seeError ? null : 'error'} color="primary" value={option.values} aria-label={`MultiSelectCatalog${option.values.node.id}-${index}`} id={`MultiSelectCatalog${option.values.node.id}-${index}`} disabled={props.optionsInputs.disabled ? true : false} style={{width: "50%"}}>
                                        <Grid key={`MultiSelectCatalog${option.values.node.id}${index}`} item xs={12}>
                                            <GridCatalog container alignItems={'center'} justifyContent={'flex-start'} direction="column">
                                                <Grid container direction={'row'}>
                                                    <Box width={'100%'}>
                                                        {renderTypeCard(props.optionsInputs.paginationType, option)}
                                                    </Box>
                                                    <Box pt={2} pb={2} width={'100%'} style={{textAlign: 'center'}}>
                                                        <CheckBoxCustom className={elementSelected?.find(e => e.node.id === option.values.node.id) ? 'active' : null } />
                                                    </Box>
                                                </Grid>
                                            </GridCatalog>
                                        </Grid>
                                    </ToggleButtonCustom>
                                )
                            })}
                        </div>
                        {
                            !seeError ? null : <span className="error-label" style={{marginLeft: 10}}>{ errorMessage }</span>
                        }
                        {
                            props.paginationInfo[props.optionsInputs.paginationType]?.count > 1 ? (
                                <Pagination.CursorPagination
                                    label = 'Nombre par page'
                                    pagination = {props.paginationInfo[props.optionsInputs.paginationType]}
                                    type = "table"
                                    changePageCallback = {(page) => {props.paginationInfo.changePage(page, props.optionsInputs.paginationType)}}
                                    perPageOptions = {[4,8,12]}
                                    rowLabel="Nombre par page :"
                                    changePerPageCallback={(perPage) => {props.paginationInfo.changePerPage(perPage, props.optionsInputs.paginationType)}}
                                />
                            ) : null
                        }
                    </div>
                : renderNoData(props.optionsInputs.paginationType)
            }
        </GridCustom>
    );
}
export default withRouter(CheckboxCards)