import React, { useEffect, useRef, useState } from "react";
import H3 from '../shared/h3';
import Div from '../shared/div';
import Button from '../shared/a';
import styled from 'styled-components';


const Slogan = styled(Div)`
    max-width: 95%;
    width: 1500px;
    margin: auto;
    h3{
        font-family: 'DinRegular', sans-serif;
        font-weight: normal;
        margin: 0;
    }
`
const SloganBlock = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    console.log('data', data)

    return (
        <Div 
            style={{position: 'relative', backgroundColor: data.inputs?.backgroundColor?.value, padding: `${data.inputs?.padding?.value}px`}}
            className="slogan" 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
        >
            { hover && !preview && props.children }
            <Slogan>
                <H3 
                    className="center"
                    color={data.blocks.title.inputs.color?.value} 
                    fontSize={data.blocks.title.inputs.fontSize?.value}
                >
                    {data.blocks.title.inputs.value.value}
                </H3>
            </Slogan>
        </Div>
    );
};

export default SloganBlock;