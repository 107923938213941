import _ from 'lodash';
import moment from 'moment';
import { ItemTypes } from '../../../../../builder/shareable';
import { 
    ADD_PHASE_MODELE, 
    ADD_TACHE_PHASE_MODELE, 
    DELETE_PHASE_MODELE, 
    DELETE_TACHE_PHASE_MODELE, 
    UPDATE_PHASE_MODELE,
    UPDATE_TACHE_PHASE_MODELE
} from '../../../../../queries/crm_modeles';
import { 
    ADD_PHASE_OFFER, 
    ADD_TASK_PHASE_OFFER, 
    DELETE_PHASE_OFFER, 
    DELETE_TASK_PHASE_OFFER, 
    UPDATE_PHASE_OFFER, 
    UPDATE_TASK_PHASE_OFFER 
} from "../../../../../queries/crm_offers";

export const savePhases = async (offer, phases, client, isOffer) => {
    let index = 0;

    for (let phase of phases) {
        if (phase.deleted) {
            if (!phase.new) {
                // Delete a phase in cascade

                await client.mutate({
                    mutation: isOffer ? DELETE_PHASE_OFFER : DELETE_PHASE_MODELE,
                    variables: { 'id': phase.id }
                });
            }

            continue;
        }

        if (phase.new) {
            // Add a new phase

            if (isOffer) {
                const ADD_PHASE_OFFER_RESULT = await client.mutate({
                    mutation: ADD_PHASE_OFFER,
                    variables: {
                        'name': phase.name,
                        'offer': offer,
                        'position': index
                    }
                });
    
                phase.id = ADD_PHASE_OFFER_RESULT.data.createOfferPhase.offerPhase.id;
    
                await savePhaseTasks(phase.taches, phase.id, null, client, isOffer);
            } else {
                const ADD_PHASE_MODEL_RESULT = await client.mutate({
                    mutation: ADD_PHASE_MODELE,
                    variables: {
                        'name': phase.name,
                        'model': offer,
                        'position': index
                    }
                });
    
                phase.id = ADD_PHASE_MODEL_RESULT.data.createModelPhase.modelPhase.id;
    
                await savePhaseTasks(phase.taches, phase.id, null, client, isOffer);
            }
        } else if (phase.updated) {
            // Update a phase

            await client.mutate({
                mutation: isOffer ? UPDATE_PHASE_OFFER : UPDATE_PHASE_MODELE,
                variables: {
                    'id': phase.id,
                    'name': phase.name,
                    'position': index
                }
            });

            await savePhaseTasks(phase.taches, phase.id, null, client, isOffer);
        } else {
            await savePhaseTasks(phase.taches, phase.id, null, client, isOffer);
            await savePhasePosition(phase.id, index, client, isOffer);
        }

        index++;
    }
};

const savePhaseTasks = async (taches, phaseId, taskId, client, isOffer) => {
    let index = 0;

    for (let tache of taches) {
        if (tache.deleted) {
            if (!tache.new) {
                await client.mutate({
                    mutation: isOffer ? DELETE_TASK_PHASE_OFFER : DELETE_TACHE_PHASE_MODELE,
                    variables:{ 'id': tache.id }
                });
            }

            continue;
        }

        if (tache.new) {
            if (isOffer) {
                const ADD_TASK_PHASE_OFFER_RESULT = await client.mutate({
                    mutation: ADD_TASK_PHASE_OFFER,
                    variables: {
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'task': taskId ? taskId : null,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });
    
                tache.id = ADD_TASK_PHASE_OFFER_RESULT.data.createOfferTask.offerTask.id;
            } else {
                const ADD_TACHE_PHASE_MODELE_RESULT = await client.mutate({
                    mutation: ADD_TACHE_PHASE_MODELE,
                    variables: {
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'task': taskId ? taskId : null,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });

                tache.id = ADD_TACHE_PHASE_MODELE_RESULT.data.createModelTask.modelTask.id;
            }

            if (taskId === null)
                await savePhaseTasks(tache.taches, phaseId, tache.id, client, isOffer);
        } else if (tache.updated) {
            if (isOffer) {
                await client.mutate({
                    mutation: UPDATE_TASK_PHASE_OFFER,
                    variables: {
                        'id': tache.id,
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });
            } else {
                await client.mutate({
                    mutation: UPDATE_TACHE_PHASE_MODELE,
                    variables: {
                        'id': tache.id,
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });
            }

            if (taskId === null)
                await savePhaseTasks(tache.taches, phaseId, tache.id, client, isOffer);
        } else if (taskId === null) {
            await savePhaseTasks(tache.taches, phaseId, tache.id, client, isOffer);
            await saveTaskPosition(tache.id, index, client, isOffer);
        }

        index++;
    }
};

const savePhasePosition = async (id, position, client, isOffer) => {
    await client.mutate({
        mutation: isOffer ? UPDATE_PHASE_OFFER : UPDATE_PHASE_MODELE,
        variables: {
            'id': id,
            'position': position,
        }
    });
};

const saveTaskPosition = async (id, position, client, isOffer) => {
    await client.mutate({
        mutation: isOffer ? UPDATE_TASK_PHASE_OFFER : UPDATE_TACHE_PHASE_MODELE,
        variables: {
            'id': id,
            'position': position,
        }
    });
};

export const convertTask = (task, isOffer = false) => {
    return {
        id: task.node.id,
        qty: task.node.quantity,
        price: task.node.price,
        tva: task.node.tax?.id,
        remise: task.node.discountFixed || task.node.discountPercent,
        typeRemise: task.node.discountFixed ? '€' : '%',
        total: task.node.total,
        name: task.node.name,
        ref: task.node.reference,
        new: isOffer ? false : true,
        poles: task.node.pole.edges.map(e => e.node.id),
        taches: []
    };
};

export const convertPhases = (phases, isOffer = false) => {
    if (!phases)
        return [];
    
    let deep = _.cloneDeep(phases);
    let newOnes = [];

    deep.sort((a, b) => {
        let aPosition = a.node.position || 0;
        let bPosition = b.node.position || 0;

        return aPosition > bPosition ? 1 : -1;
    });

    for (let phase of deep) {
        let item = {
            id: phase.node.id,
            new: isOffer ? false : true,
            name: phase.node.name,
            position: phase.node.position,
            taches: []
        };

        let parents = isOffer
            ? phase.node.offerTasks.edges.filter(e => e.node.task === null)
            : phase.node.modelTasks.edges.filter(e => e.node.task === null);

        let deepTasks = _.cloneDeep(parents);

        deepTasks.sort((a, b) => {
            let aPosition = a.node.position || 0;
            let bPosition = b.node.position || 0;

            return aPosition > bPosition ? 1 : -1;
        });

        for (let parent of deepTasks) {
            let formatedParent = convertTask(parent, isOffer);

            let children = isOffer
                ? phase.node.offerTasks.edges.filter(e => e.node.task !== null && e.node.task.id === formatedParent.id)
                : phase.node.modelTasks.edges.filter(e => e.node.task !== null && e.node.task.id === formatedParent.id);

            let deepChildren = _.cloneDeep(children);

            deepChildren.sort((a, b) => {
                let aPosition = a.node.position || 0;
                let bPosition = b.node.position || 0;

                return aPosition > bPosition ? 1 : -1;
            });

            for (let child of deepChildren) {
                let formatedChild = convertTask(child, isOffer);
                formatedParent.taches.push(formatedChild);
            }

            item.taches.push(formatedParent);
        }

        newOnes.push(item);
    }

    return newOnes;
};

export const refreshPhasesOnOffers = (offer, data, isOffer = false) => {
    let offers = isOffer ? offer.offers : offer.models;

    for (let element of data.elements) {
        if (element.type === ItemTypes.OFFER_DEVIS) {
            let offer = offers.edges.find(e => e.node.id === element.offer);

            element.data.phases = convertPhases(isOffer 
                ? offer?.node.offerPhases.edges 
                : offer?.node.modelPhases.edges
            , isOffer);

            if (!offer)
                continue;

            if(offer.node.offerFooter){
                element.data.footer.id = offer.node.offerFooter.id;
                element.data.footer.discountFixed = offer.node.offerFooter.discountFixed;
                element.data.footer.discountPercent = offer.node.offerFooter.discountPercent;
                element.data.footer.advancePayment = offer.node.offerFooter.advancePayment;
                element.data.footer.paymentTerm = offer.node.offerFooter.paymentTerm?.id;
                element.data.footer.paymentDeadline = offer.node.offerFooter.paymentDeadline?.id;
                element.data.footer.comment = offer.node.offerFooter.comment;
                element.data.footer.taxes = offer.node.offerFooter.offerFooterTaxes.edges.map(e => e.node.id);

            }
            
            element.data.inputs.offerName.value = isOffer ? offer.node.title : offer.node.name;
            element.data.inputs.offerDescription.value = offer.node.description;
            element.data.inputs.offerDescription.offerNumberPO = offer.node.poNumber;
            element.data.inputs.offerDescription.offerValidityDate = offer.node.validityDate;
            element.data.inputs.offerRecurring.value = offer.node.recurring;
            element.data.inputs.offerRecurring.conditionalInputs.offerRecurringStartDate.value = moment(offer.node.recurringStartDate).format('YYYY-MM-DD');
            element.data.inputs.offerRecurring.conditionalInputs.offerRecurringInvoiceDay.value = offer.node.recurringInvoiceDay;
            element.data.inputs.offerRecurring.conditionalInputs.offerRecurringDelay.value = offer.node.recurringDelay;
        }
    }
    
    return data;
};