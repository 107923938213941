import { getDefaultTextCms } from '../../helpersCms';
import { FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc Slogan',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        padding: {
            type: FormInputTypes.SLIDER,
            value: 10,
            label: `Marge Vertical`
        }
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam in est justo.", "#000000", 49),
    }
};