import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SETTINGS_GUIDELINE_ADD, ROUTE_SETTINGS_GUIDELINE_UPDATE } from '../../../../js/constants/route-names';
import { GET_BRAND_GUIDELINE } from "../../../../queries/brand_guideline";

import colors from '../../../../config/theme/colors';

import {Box, Grid} from '@material-ui/core';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardGuideline from '../../../layouts/Card/cardContent/CardGuideline';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { checkRouting } from '../../../../js/utils/checkRouting';
import styled from 'styled-components';

const PageWrapper = styled(Box)`
    width: 100%;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
`;

class GuidelineBrand extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            changedLang: false,
            image: '', 
            preferredLangcode: 'fr',
            loading: true,
            attr: {},
            isEmpty: false,
            content:{
                emptyTitle: "Vous n’avez pas encore paramétré votre guideline",
                emptySubtitle: "Cliquez ci-dessous commencer la configuration",
                emptyTxtBtn: "Créer ma guideline",
                emptyPicto: Empty,
            },
        }
    }


    getGuideline = () => {
        this.props.client.query({
            query: GET_BRAND_GUIDELINE,
            fetchPolicy: 'no-cache'
        }).then(result =>{
            if(result.data.guidelines.edges.length === 0){
                this.setState({
                    isEmpty: true,
                }, () => {
                    this.goTo(ROUTE_SETTINGS_GUIDELINE_ADD)
                });
            }
            else{
                this.setState({
                    guidelines: result.data.guidelines.edges
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    componentDidMount = () => {
        checkRouting(this.props);
        for(let locale of this.props.locales){
            this.setState({[locale.node.code]: {}});
        }
        this.getGuideline();
    }

    render() {
        return (
            <PageWrapper>
                <TopPanel 
                    icomoon="ico_guideline"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Guideline" 
                    subtitle="Gestion de votre image de marque"
                    handlerAdd={() => {this.goTo(ROUTE_SETTINGS_GUIDELINE_ADD)}} 
                    buttonAvailable={true}
                    textAdd="Ajouter une charte graphique"
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Box style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                }}>
                    {
                        this.state.loading ? 
                            (
                                <PageLoader />
                            )
                        :
                        !this.state.isEmpty && this.state.guidelines.length > 0 ?
                            (
                                <Grid container direction="column" justifyContent="center" spacing={0} style={{width: '100%'}}>
                                    <Grid container direction="row" spacing={4}>
                                        {
                                            this.state.guidelines.map((guideline, index) => (
                                                <Grid item xl={4} sm={6} xs={12} style={{display: 'flex', flexWrap: 'wrap'}} key={`card-guideline-${index}`}>
                                                    <CardCustom cardContentStyle={{padding: 0, width: '100%'}} style={{width: '100%'}}>
                                                        <CardGuideline guideline={guideline} onClick={() => {this.goTo(ROUTE_SETTINGS_GUIDELINE_UPDATE.replace(':id', guideline.node.id.replace('/api/guidelines/', '')))}} />
                                                    </CardCustom>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            )
                        : 
                            (
                                <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => {this.goTo(ROUTE_SETTINGS_GUIDELINE_ADD)}} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                            ) 
                    }
                </Box>
            </PageWrapper>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname : route,
            state: {id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineBrand)));