import React, { Fragment } from "react";
import {
    OfferFrontPage,
    OfferSectionTitle,
    OfferAboutSlide,
    TripleBlockList,
    TripleBlock,
    TripleBlockText,
    TextWithImage,
    TextWithImageSimple,
    Devis
} from "../../../../shareable/components/assets/offer/components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";

export const getComponent = (element, index, preview, Droppable, updateConfig, inputCallback, deleteCallback, taxes, onSnap, onStyle) => {

    const OfferToolbar = (
        <Toolbar 
            name={element.data?.name} 
            onEdit={() => inputCallback(element.key)} 
            onDelete={() => deleteCallback(element.key)} 
            snapable={true} 
            onSnap={() => onSnap(element)}
            onStyle={() => onStyle(element)}
        />
    );

    switch (element.type) {
        case ItemTypes.OFFER_SECTION_TITLE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <OfferSectionTitle
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </OfferSectionTitle>
                    <Droppable type={ItemTypes.OFFER_SECTION_TITLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_FRONT_PAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <OfferFrontPage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </OfferFrontPage>
                </DndProvider>
                <Droppable type={ItemTypes.OFFER_FRONT_PAGE} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.OFFER_ABOUT_SLIDE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <OfferAboutSlide
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                        toolbarBlock={<Toolbar name={element.data.blocks.descriptionBlock.name} onEdit={() => inputCallback(element.key, 'descriptionBlock')} />}
                    >
                        { OfferToolbar }
                    </OfferAboutSlide>
                    <Droppable type={ItemTypes.OFFER_ABOUT_SLIDE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
            return (<Fragment key={element.key}>
                <TripleBlockList
                    id={element.key}
                    preview={preview}
                    data={element.data}
                    inputCallback={inputCallback}
                    update={updateConfig}
                    spread={true}
                >
                    { OfferToolbar }
                </TripleBlockList>
                <Droppable type={ItemTypes.OFFER_TRIPLE_BLOCK_LIST} id={element.key} index={index} />
            </Fragment>)
        case ItemTypes.OFFER_TRIPLE_BLOCK:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TripleBlock
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </TripleBlock>
                    <Droppable type={ItemTypes.OFFER_TRIPLE_BLOCK} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TripleBlockText
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </TripleBlockText>
                    <Droppable type={ItemTypes.OFFER_TRIPLE_BLOCK_TEXT} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_TEXT_WITH_IMAGE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TextWithImage
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                        toolbarBlock={<Toolbar name={element.data.blocks.descriptionBlock.name} onEdit={() => inputCallback(element.key, 'descriptionBlock')} />}
                    >
                        { OfferToolbar }
                    </TextWithImage>
                    <Droppable type={ItemTypes.OFFER_TEXT_WITH_IMAGE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <TextWithImageSimple
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        spread={true}
                    >
                        { OfferToolbar }
                    </TextWithImageSimple>
                    <Droppable type={ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
        case ItemTypes.OFFER_DEVIS:
            return (<Fragment key={element.key}>
                <DndProvider backend={HTML5Backend}>
                    <Devis
                        id={element.key}
                        preview={preview}
                        data={element.data}
                        inputCallback={inputCallback}
                        update={updateConfig}
                        listTaxes={taxes}
                        spread={true}
                    >
                        <Toolbar name={element.data?.name} onEdit={() => inputCallback(element.key)} onDelete={() => deleteCallback(element.key)} snapable={false} />
                    </Devis>
                    <Droppable type={ItemTypes.OFFER_DEVIS} id={element.key} index={index} />
                </DndProvider>
            </Fragment>)
    }
}