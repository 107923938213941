import { Box, Divider, Grid } from "@material-ui/core"
import { Edit } from "@material-ui/icons";
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { typography } from "@material-ui/system";
import styled from "styled-components";
import colors from "../../../../config/theme/colors";
import OurMenu from "../../../ui/menu/Menu"
import OurTypography from "../../../ui/typography/Typography"

const CardWrapper = styled(Box)`
    position:relative;
    width:100%;
    padding: 16px;
`;

const CardTypographies = (props) => {
  const editMenuOptions = [
    {
      label: 'Modifier',
      isDisabled: false,
      color: colors.blue.darker.hue300,
      icon: <Edit style={{ fill: colors.blue.darker.hue300 }} />,
      action: (e) => {
        e.stopPropagation();
        props.handlerEdit()
      }
    },
    {
      label: 'Supprimer',
      color: colors.red.regular,
      icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
      action: (e) => {
        e.stopPropagation();
        props.handlerDelete()
      }
    }
  ]

  const splitMarkups = (markups) => {
    const headings = props.listMarkups.map((markup, index) => markup.node.value.match(/h[0-6]/g) 
      ? `${props.typo.markup[index]}px` 
      : null ).filter(Boolean).join(', ')

    const paragraph = markups.map((markup, index) => markup.node.value === 'p' ? `${props.typo.markup[index]}px` : null )

    const span = markups.map((markup, index) => markup.node.value === 'span' ? `${props.typo.markup[index]}px` : null )

    return {
      headings,
      paragraph,
      span
    }
  }

  return (
    <CardWrapper>
      {/* <Grid container justifyContent="center" alignItems="center" style={{
        border: `1px solid ${colors.grey.border}`,
        height: '125px'
      }}>
        <OurTypography fontweighttext={700} style={{
          fontSize: '90px',
          // fontFamily: props.typo.libelle
        }}>aA</OurTypography>
      </Grid> */}
      <Box>
        <Grid container justifyContent={'space-between'} style={{
          // paddingTop: '20px',
        }}>
          <OurTypography fontweighttext={700} style={{
            fontSize: '25px',
          }}>{props.typo.libelle}</OurTypography>

          <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
        </Grid>
        <Box mt={'20px'}>
          <Grid container style={{
            marginBottom: '10px',
          }}>
            <Grid item xs={6}><OurTypography style={{ fontSize: '15px' }}>Type :</OurTypography></Grid>
            <Grid item xs={6}><OurTypography fontweighttext={700} style={{ fontSize: '15px' }}>-</OurTypography></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}><OurTypography style={{ fontSize: '15px' }}>Ratio :</OurTypography></Grid>
            <Grid item xs={6}><OurTypography fontweighttext={700} style={{ fontSize: '15px' }}>-</OurTypography></Grid>
          </Grid>

          <Divider style={{ margin: '20px 0px' }} />

          <Grid container style={{
            marginBottom: '10px',
          }}>
            <Grid item xs={6}><OurTypography style={{ fontSize: '15px' }}>Headline :</OurTypography></Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: '15px' }}>
                { splitMarkups(props.listMarkups).headings }
              </OurTypography>
            </Grid>
          </Grid>
          <Grid container style={{
            marginBottom: '10px',
          }}>
            <Grid item xs={6}><OurTypography style={{ fontSize: '15px' }}>Paragraphe :</OurTypography></Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: '15px' }}>
                { splitMarkups(props.listMarkups).paragraph }
              </OurTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}><OurTypography style={{ fontSize: '15px' }}>Note :</OurTypography></Grid>
            <Grid item xs={6}>
              <OurTypography fontweighttext={700} style={{ fontSize: '15px' }}>
                { splitMarkups(props.listMarkups).span }
              </OurTypography>
            </Grid>
          </Grid>

        </Box>
      </Box>
    </CardWrapper>
  )
}

export default CardTypographies