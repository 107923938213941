import styled from 'styled-components';

export default styled.button`
    line-height: 1.2;
    font-size: ${props => `${props.fontSize}px`|| '1.25rem'} ;
    color: ${props => props.color || 'rgba(0, 14, 40, 1)'};
    background-color: ${props => props.backgroundColor || '#fff'};
    border-style: solid;
    border-width: ${props => `${props.borderWidth}px` || 0};
    border-color: ${props => props.borderColor || props.backgroundColor || '#FFF'};
    border-radius: ${props => `${props.borderRadius}px` || 0};
    text-transform: ${props => props.textTransform || "initial"};
    padding: ${props => props.paddingVertical &&props.paddingHorizontal ?`${props.paddingVertical}px ${props.paddingHorizontal}px` : "16px"};
    margin: 16px 0;
    display: block;
    text-decoration: none;
    opacity: 1;
    transition: all ease 0.1s;
    cursor: pointer;
    &:hover{
        background-color: ${props => props.color || 'rgba(0, 14, 40, 1)'}; 
        color: ${props => props.backgroundColor || '#fff'};
    }
    @media screen and (min-width: 1024px){
        line-height: 26px;       
    }
    @media screen and (max-width: 767px){
        font-size: ${props => `${props.fontSizeMobile}px`|| '1.25rem'} ;
    }
`; 