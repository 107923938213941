import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { Grid, InputLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Fab, Checkbox } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import Button from '../../../../ui/button/Button';
import InputBuilder from '../../../../ui/form/InputBuilder';
import colors from '../../../../../config/theme/colors';
import { SortableContainer, SortableElement, SortableHandle } from 'react-ordering';
import arrayMove from 'array-move';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&>div': {
            margin: '16px 0',
            boxShadow: 'inherit',
            '&:first-child': {
                marginTop: 0,
            },
            '&:before': {
                content: 'inherit',
            }
        }
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: colors.blue.lighter.hue900,
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

const GridContainer = styled(Grid)`
  z-index: 999;
  background: white;
  display: flex;
  border: 1px solid #E3E3E3;
  flex-wrap: nowrap;
  padding: 16px;
  position: relative;
  img{
    width: 100%;
  }
`;

const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    width: 42px;
    padding: 0 9px;
    cursor: pointer;
    &:hover{
        background: unset !important;
    }
    input{
        width: 40px;
    }
    svg{
        fill: ${colors.grey.regular}
    }
`;


const SortableItem = SortableElement(
    ({ element, index, id, inputCallback }) => {
        return (
            <GridContainer container alignItems="center" justifyContent="space-between" key={`row-product-${id}`} className="sortable-item" spacing={2}>
                <Grid item xs={4} style={{display: 'flex', justifyContent:"center", alignItems:"center"}}>
                    <img src={element.image} style={{maxHeight: 70, width: "auto"}}/>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h4'>{element.name}</Typography>
                    <Typography variant='h5'>{element.sku}</Typography>
                </Grid>
                <Grid item xs={2} style={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>
                    <CheckboxCustom
                        edge="start"
                        checked={element.selected}
                        onChange={(event) => inputCallback(event,id,'selected')}
                    />
                </Grid>
            </GridContainer>
        )
    }
);

const SortableList = SortableContainer(({ elements, classes, inputCallback }) => {
    return (
        <div className="sortableHelper">
            {
                elements.map((value, index) => (
                    <SortableItem 
                        element={value}
                        key={value.id} 
                        index={index} 
                        id={index} 
                        classes={classes}
                        inputCallback={inputCallback}
                    />
                ))
            }
        </div>
    );
});

function ListProductsDD (props) {
    const [elements, setElements] = useState(props.allState.crossSellsProductsList);
    const [nbElement, setNbElement] = useState(0);

    const classes = useStyles();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let getElements = arrayMove([...elements], oldIndex, newIndex);
        getElements[oldIndex].update = true;
        getElements[newIndex].update = true;
    
        setElements(getElements);
    };
    
    const inputCallback = (e, id, stateName) => {
        let getElements = [...elements];
        getElements[id][stateName] = e.target.checked;
        getElements[id].update = true;
        setElements(getElements);

    };
    
    const checkElements = () => {
        let i = 0;
        for(let element of elements){
            i++;
            if(element.delete){
                i--;
            }
        }
        setNbElement(i);
    }

    useEffect(() => {
        props.stateCallback('crossSellsProductsList', elements);
        checkElements();
    }, [elements])
    useEffect(() => {
        setElements(props.allState.crossSellsProductsList);
    }, [props.allState.crossSellsProductsList])
    return (
        <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', background: 'rgb(250, 251, 251)', padding: '32px 12px'}}>
            {
                props.allState.loadedProducts ?
                    nbElement > 0 ? (
                        <SortableList 
                            elements={elements} 
                            onSortEnd={onSortEnd}
                            axis={"y"} 
                            classes={classes}
                            inputCallback={inputCallback}
                            distance={1}
                        />
                    ) : (
                        <p>Aucun éléments pour le moment</p>
                    )
                : <PageLoader />
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProductsDD))));