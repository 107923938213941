import PropTypes from "prop-types";
import React from "react";
import ShopCategories from "../../components/_internals/product/ShopCategories";
import ShopColor from "../../components/_internals/product/ShopColor";
import ShopSize from "../../components/_internals/product/ShopSize";
import ShopTag from "../../components/_internals/product/ShopTag";

const ShopSidebar = ({ spread, categories, getSortParams, sideSpaceClass }) => {
  let allCats = categories.map(category => ({
    value: category.id,
    name: category.attributes?.category_name?.fr_FR
  }));

  allCats = allCats.filter(e => e.name);

  return (
    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* filter by categories */}
      <ShopCategories
        spread={spread}
        categories={allCats}
        getSortParams={getSortParams}
      />

      {/* filter by color */}
      {/* <ShopColor colors={['Rouge', 'Vert', 'Jaune']} getSortParams={getSortParams} /> */}

      {/* filter by size */}
      {/* <ShopSize sizes={['39', '40', 'S', 'XL']} getSortParams={getSortParams} /> */}

      {/* filter by tag */}
      {/* <ShopTag tags={uniqueTags} getSortParams={getSortParams} /> */}
    </div>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string
};

export default ShopSidebar;
