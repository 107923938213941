import styled from 'styled-components';

export default styled.h4`
    font-size: ${props => props.fontSize ? `${props.fontSize / 16}rem` : '1.25rem'};
    line-height: 1.2;
    margin: 0;
    color: ${props => props.color || '#000'};
    text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
    @media screen and (min-width: 1024px){
        line-height: 3vw;
    }
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
`; 