import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';


export default function feedEdit (assetData){
    var obj=
        {
            titleForm:`Éditer un post ${assetData.assetType.libelle}`,
            subTitleForm: `Éditer un post ${assetData.assetType.libelle}`,
            /*langSelect: true,*/
            drawerType: 'drawer',
            noForm: true,
            component: Button,
            textButtonValidate: 'Editer le post',
            formConfig: {
                type: FORM,
                children: []
            },

        }    
    var children=[];

    switch(assetData.assetType.identifier) {
        case 'facebook':
            let facebookPost = []
            facebookPost.push(
                {
                    type: 'textareaSimple',
                    label : 'Publication',
                    required : false,
                    stateName : 'paramText',
                    helper: {
                        text: 'Rédigez ici votre publication',
                        link: false,

                    },
                } 
            )
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: facebookPost
                }
            ] 
            break;
        case 'linkedin':
        case 'instagram':
            let instagramPost = [
                {
                    type: 'textareaSimple',
                    label : 'Publication',
                    required : false,
                    stateName : 'paramText',
                    helper: {
                        text: 'Rédigez ici votre publication',
                        link: false,

                    },
                } 
            ]
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: instagramPost
                }
            ] 
            break;

        case 'twitter':
            let twitterPost = [
                {
                    type: 'textareaSimple',
                    label : 'Publication',
                    required : false,
                    stateName : 'paramText',
                    helper: {
                        text: 'Moins de 280 caractère',
                        link: false,
                        charCount:true,
                        maxChar:280
                    },

                },
            ]
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: twitterPost
                }
            ] 
        break;
            
        case 'wordpress':
            let wordpressPost = [
                {
                    type: 'text',
                    label : 'Titre',
                    required : true,
                    stateName : 'paramText',
                    helper: {
                        text: 'Titre de votre article',
                        link: false,
                    },
                },
                {
                    type: 'textareaSimple',
                    label : 'Contenu',
                    required : true,
                    stateName : 'paramText2',
                    helper: {
                        text: 'Contenu de votre article',
                        link: false,
                    },
                },
            ]

            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: wordpressPost 
                }
            ] 
        break;  
            
        default: children = [];
    }

    obj.formConfig.children=children;
    


    return obj;
}
