import { EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultSlide } from '../../helpers';

export default {
    name: 'Slider',
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.SLIDER,
            buttonAdd: 'une slide',
            limit: 3,
            config: [ 
                getDefaultSlide("https://via.placeholder.com/1920x720/F0F7FA/C5DEEC", "LOREM IPSUM", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, lorem nec faucibus luctus.", "Lorem ipsum")
            ]
        }
    }
};