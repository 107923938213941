import React, { useEffect } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import styled from 'styled-components';
import ListingProducts from './components/ListingProducts';
import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';

const ListImportsProducts=(props) =>{
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);   
    const [type, setType] = React.useState(null);   

    useEffect(()=>{
        setType(props.location.state.type)
    },[props.location.state.type])

    const getImportId = (id)=>{
        let index = id.lastIndexOf("/")
        return id.substring(index + 1)
    }


    return (
        <div>
            <TopPanel                
                title={
                    type === 'manually'?
                    props.t('products.workflow.title_workflow_product_manually')
                    :type === 'to_verify'?
                    props.t('products.workflow.title_workflow_product_toverify')
                    :props.t('products.workflow.title_workflow_product_tovalidate')
                }
                subtitle={props.t('products.workflow.subtitle')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <div>
                <ListingProducts
                    productBypage = {{
                        card: [12,24,48,72],
                        list: [30,40,50]
                    }}
                    variables = {{
                        scrapStatus: type === "manually"?"404":type === "to_verify"?"200":"99",
                        fileImports: getImportId(props.location.state?.id)
                    }}
                    isCatalog = {false}
                    nbperpage = {12}
                    currentLang={currentLang} 
                    // handleLang={handleLang}
                    windowWidth={props.windowWidth}
                    navButton={true}
                    inAccordion={
                        <>
                            <Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>
                            {
                                type === 'manually'?
                                "Liste des produits à enrichir"
                                :type === 'to_verify'?
                                "Liste des produits à compléter"
                                :"Liste des produits à valider"
                            }
                            </Typography>
                        </>
                    }
                    // handlerExport={handleExportProducts}
                    // openForm={openForm}
                    // callbackFilters={this.callbackFilters}
                    // previousFilters={getItemsLocalStorage("PIM_FILTERS")}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListImportsProducts)))));