export function setItemsLocalStorage(key,variables){
    localStorage.setItem(key, JSON.stringify(variables));
}
export function getItemsLocalStorage(key){
    let getItem = JSON.parse(localStorage.getItem(key));
    return getItem;
}
export function removeItemsLocalStorage(key){
    return new Promise((resolve, reject) => {
        localStorage.removeItem(key);
        resolve();
    })
}