import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SHELLS_LIST } from '../../../../js/constants/route-names';
import { CREATE_EAV_TYPES, DELETE_EAV_TYPES, GET_EAV_TYPES, UNIQUE_EAV_CODE, UPDATE_EAV_TYPES } from "../../../../queries/attributes";
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';

import colors from '../../../../config/theme/colors';

import { Box, Grid } from '@material-ui/core';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";

import EmptyCard from "../../../ui/empty-card/EmptyCard";
import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardGuideline from '../../../layouts/Card/cardContent/CardGuideline';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import formShell from './config/formShell.config';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import slugify from 'slugify';
import request from '../../../../js/utils/fetch';
import { eventService } from '../../../../js/services/event.service';
import DialogModal from '../../../ui/dialog/DialogModal';
import { setRedux } from '../../../../js/utils/functions';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import axios from '../../../../js/utils/axios';
import styled from 'styled-components';


const PageWrapper = styled(Box)`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;

    & > .layout-wrapper {
        display: none;
    }
`;

class Shells extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            shells: [],
            isEmpty: false,
            loading: true,
            content: {
                emptyTitle: "Vous n’avez pas encore paramétré vos enveloppes",
                emptySubtitle: "Cliquez ci-dessous pour commencer la configuration",
                emptyTxtBtn: "Créer mon enveloppe",
                emptyPicto: Empty,
            },
            editForm: false,
            shellName: null,
            errors: {},
            seeErrors: false,
            openForm: false,
            openDialog: false,
            currentShell: null,
            shellView: 'card',
            shellIsSecure: true,
            shellIsModifiable: true
        }
        this.typingTimer = null;
    }

    resetState() {
        this.setState({
            currentShell: null,
            shellName: null,
            seeErrors: false,
            shellPrettyName: null
        });
    }

    addShell = () => {
        this.resetState();

        // for (let locale of this.props.locales) {
        //     this.setState({
        //         [locale.node.code]: {}
        //     });
        // }

        this.setState({
            openForm: true,
            editForm: 'add',
        });
    };

    editShell = (shell) => {
        this.resetState();

        this.setState({
            openForm: true,
            editForm: 'edit',
            currentShell: shell,
            shellName: shell.node.code,
            shellPrettyName: shell.node.name
        });
    };

    deleteShell = () => {
        this.props.startLoading()
        this.props.client.query({
            query: DELETE_EAV_TYPES,
            fetchPolicy: 'no-cache',
            variables: {
                "id": this.state.currentShell.node.id
            }
        }).then(async result => {
            this.handleToggleDrawer();
            this.getShells();
            await setRedux(false, true, false, false);
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Enveloppe supprimée !');
        });
    }
    exportShell = (shell) => {
        const lastSlash = shell.node.id.lastIndexOf("/") + 1
        const realId = shell.node.id.substring(lastSlash)
        this.props.startLoading()
        axios(`${process.env.REACT_APP_API}/shells/${realId}/export/csv`, 'get')
            .then((result) => {
                this.props.stopLoading()
                if (result.message === 'success') {
                    window.open(`${process.env.REACT_APP_MEDIAS}/export/${result.file}`)
                }
            })
    }

    exportShell = (shell) => {
        const lastSlash = shell.node.id.lastIndexOf("/") + 1
        const realId = shell.node.id.substring(lastSlash)
        this.props.startLoading()
        axios(`${process.env.REACT_APP_API}/shells/${realId}/export/csv`, 'get')
            .then((result) => {
                this.props.stopLoading()
                if (result.message === 'success') {
                    window.open(`${process.env.REACT_APP_MEDIAS}/export/${result.file}`)
                }
            })
    }

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value);
    };

    setValue = (stateName, value) => {
        if (this.state.editForm && stateName === 'shellName') {
            if (value !== this.state.shellName)
                this.checkIdentifier(stateName);
        }
        if (stateName === 'shellPagination') {
            let perPage = value ? 12 : null;
            this.setState({ shellPerPage: perPage })
        }
        this.setState({
            [stateName]: value
        });


    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    doneTyping = (stateName) => {
        if (stateName === 'shellName') {
            this.setState({
                attributeIdentifier: slugify(this.state.shellName, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            })
        }

        if (this.state.shellName) {
            this.props.client.query({
                query: UNIQUE_EAV_CODE,
                fetchPolicy: 'no-cache',
                variables: {
                    "code": this.state[stateName]
                }
            }).then(result => {
                if (result.data.eavTypes.edges.length !== 0) {
                    eventService.fire({ stateName: 'shellName', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    let errors = this.state.errors;
                    errors[stateName] = true;
                    this.setState({
                        errors
                    })
                }
            });
        }
        this.forceUpdate();
    };

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm
        });
    };

    getShells = () => {
        this.props.client.query({
            query: GET_EAV_TYPES,
            fetchPolicy: 'no-cache',
            variables: { "isSystem": false, }
        }).then(result => {
            if (result.data.eavTypes.edges.length === 0) {
                this.setState({
                    isEmpty: true,
                });
            }
            else {
                this.setState({
                    shells: result.data.eavTypes.edges
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    componentDidMount = () => {
        checkRouting(this.props);
        for (let locale of this.props.locales) {
            this.setState({ [locale.node.code]: {} });
        }
        this.getShells();
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
            let query = null;
            let variables = null;

            this.props.startLoading();

            switch (this.state.editForm) {
                case 'edit':
                    query = UPDATE_EAV_TYPES;
                    variables = {
                        isSystem: false,
                        id: this.state.currentShell.node.id,
                        code: this.state.shellName,
                        name: this.state.shellPrettyName,
                        isSecure:this.state.shellIsSecure
                    };
                    break;
                case 'add':
                    query = CREATE_EAV_TYPES;
                    variables = {
                        isSystem: false,
                        code: this.state.shellName,
                        isSecure: this.state.shellIsSecure,
                        isModifiable: this.state.shellIsModifiable,
                        name: this.state.shellPrettyName,
                    };
                    break;
                default: return this.props.stopLoading();
            }
            this.props.client.mutate({
                mutation: query,
                variables,
            }).then(result => {


                localStorage.removeItem('GET_EAV_TYPES');
                let localStorage_GET_EAV_TYPES = localStorage.getItem('GET_EAV_TYPES');
                if (!localStorage_GET_EAV_TYPES) {
                    this.props.client.query({
                        query: GET_EAV_TYPES,
                    }).then(result => {
                        localStorage.setItem('GET_EAV_TYPES', JSON.stringify(result));
                    });
                }

                if(result.data.createEavType?.eavType?.id){
                    this.setState({
                        isEmpty: false,
                    })
                }

                this.handleSuccess();
            })
        } catch (e) {
            this.handleError(e);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.getShells();
        this.props.snack(ALERT_SUCCESS, this.state.editForm === 'edit'
            ? 'Enveloppe modifiée !'
            : 'Enveloppe ajoutée !');

        this.handleToggleDrawer();
        this.resetState();
        await setRedux(false, true, false, false);
        this.props.stopLoading();
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    render() {
        return (
            <PageWrapper>
                <TopPanel
                    icomoon="icon-dashboard"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Enveloppes"
                    subtitle="Gérer vos enveloppes Sinfin DXP"
                    handlerAdd={() => { this.addShell() }}
                    buttonAvailable={true}
                    textAdd="Ajouter une enveloppe"
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                    locales={this.props.locales}
                    hasBorder={true}
                />
                <Box style={{position: 'relative'}}>
                    {
                        this.state.loading ?
                            (
                                <PageLoader />
                            )
                            :
                            !this.state.isEmpty && this.state.shells.length > 0 ?
                                (
                                    <Grid container direction="column" justifyContent="center" spacing={0} style={{ width: '100%' }}>
                                        <Grid container direction="row" spacing={4}>
                                            {
                                                this.state.shells.map((shell, index) => (
                                                    <Grid item xl={4} sm={4} xs={6} style={{ display: 'flex', flexWrap: 'wrap' }} key={`card-guideline-${index}`}>
                                                        <CardCustom cardContentStyle={{ width: '100%' }} style={{ width: '100%' }}>
                                                            <Typography>{shell.node.name || shell.node.code}</Typography>
                                                            <Grid container justifyContent="flex-end">
                                                                <Button onClick={() => this.editShell(shell)} style={{ marginRight: 5 }}>Modifier</Button>
                                                                <Button
                                                                    onClick={() => this.exportShell(shell)}
                                                                    color={colors.blue.darker.hue300}
                                                                    colorHover={colors.white}
                                                                    bgcolorhover={colors.blue.lighter.hue600}
                                                                    bgcolor={colors.blue.lighter.hue900}
                                                                    border={`1px solid ${colors.blue.darker.hue300}`}
                                                                >
                                                                    Exporter
                                                                </Button>
                                                            </Grid>
                                                            {/* <CardGuideline guideline={guideline} onClick={() => {this.goTo(ROUTE_SETTINGS_GUIDELINE_UPDATE.replace(':id', guideline.node.id.replace('/api/shells/', '')), guideline.node.id)}} /> */}
                                                        </CardCustom>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                )
                                :
                                (
                                    <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => { this.addShell() }} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                                )
                    }
                </Box>
                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    forClose={this.handleToggleDrawer}
                    handlerMutation={() => (this.handlerMutation())}
                    dataLayout={formShell(this.state.editForm)}
                    allState={this.state}
                    icomoon={(this.state.editForm === 'edit') ? 'ico-modifier-attribut' : 'ico-ajouter-attribut'}
                    stateCallback={this.handleInputChange}
                    handleButtonGroupChange={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    checkError={() => { }}
                    deleteMutation={
                        (this.state.editForm === 'edit') ?
                            this.handleToggleDialog
                            : null
                    }
                    deleteText={this.state.editForm === 'edit' ? "Supprimer l'enveloppe" : null}
                    deleteButton={this.state.editForm}
                    validateButton={true}
                    currentLang={this.state.currentLang}
                    handleLang={this.handleLang}
                />
                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    title={`Êtes-vous sûr de vouloir supprimer cette enveloppe ?`}
                    secondaryAction={this.handleToggleDialog}
                    primaryAction={() => { this.deleteShell(); this.handleToggleDialog() }}
                    windowWidth={this.props.windowWidth}
                >
                    Si vous supprimez cette enveloppe celle-ci ne sera plus accessible. Si vous ne l'utilisez plus mais que vous ne souhaitez pas la supprimer, annulez la suppression.
                </DialogModal>
            </PageWrapper>
        );
    }

    goTo = (route, id = null) => {
        this.props.history.push({
            pathname: route,
            state: { id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        users: state.users,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Shells)));