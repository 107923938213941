import { FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc Entete',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        title:{
            label: 'Titre accueil',
            type: FormInputTypes.TEXT,
            value: "Accueil",
            id: uuidv4(),
        },
        isHome:{
            label: "Page d'accueil ?",
            type: FormInputTypes.SWITCH,
            value: false,
            id: uuidv4(),
        },
        paddingHorizontal:{
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
            id: uuidv4(),
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Savoir-faire engagé", null, null, null, null, null, true),
    }
};