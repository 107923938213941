import { gql } from 'apollo-boost';


//----- Récupération des modèles de présentation
export const GET_MODEL_PRESENTATIONS = gql`
{
    modelPresentations{
      edges{
        node{
          id
          title
          htmlContent
          alias
          models{
            edges{
              node{
                id
                name
                description
                recurring
                recurringStartDate
                recurringInvoiceDay
                recurringDelay
                media{
                  id
                  filePath
                }
                modelPhases{
                  edges{
                    node{
                      id
                      name
                      position
                      modelTasks{
                        edges{
                          node{
                            id
                            name
                            reference
                            description
                            price
                            position
                            total
                            quantity
                            discountFixed
                            discountPercent
                            tax {
                                id
                                name
                            }
                            task {
                              id
                              name
                            }
                            pole{
                              edges{
                                node{
                                  id
                                name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
        }
      }
    }
  }
`;

//----- Rechercher un model de presentation par ID
/*
 Exemple de variables
  {
    "id": "/api/model_presentations/1"
  }
*/
export const GET_MODEL_PRESENTATION_BY_ID = gql`
query modelPresentation($id: ID!) {
  modelPresentation(id: $id) {
    id
    title
    htmlContent
    alias
    models{
      edges{
        node{
          id
        }
      }
    }
    media{
      id
      filePath
      type
      size
      category{
        edges{
          node{
            id
            libelle
          }
        }
      }
    }
  }
}
`;

//----- Rechercher un model de presentation par ALIAS
/*
 Exemple de variables
  {
    "alias": "alias"
  }
*/
export const GET_MODEL_PRESENTATION_BY_ALIAS = gql`
query modelPresentations($alias: String!) {
  modelPresentations(alias: $alias) {
    edges {
      node {
        id
        title
        htmlContent
        alias
        models{
          edges{
            node{
              id
              name
              description
              recurring
              recurringStartDate
              recurringInvoiceDay
              recurringDelay
              media{
                id
                filePath
              }
              modelPhases{
                edges{
                  node{
                    id
                    name
                    position
                    modelTasks{
                      edges{
                        node{
                          id
                          name
                          reference
                          description
                          price
                          position
                          total
                          quantity
                          discountFixed
                          discountPercent
                          tax {
                              id
                              name
                          }
                          task {
                            id
                            name
                          }
                          pole{
                            edges{
                              node{
                                id
                              name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        media{
          id
          filePath
          type
          size
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un modèle de présentation
/*
 Exemple de variables
  {
    "title": "Magento 2",
    "htmlContent": "0009123"
  }
*/
export const ADD_MODEL_PRESENTATION = gql`
mutation AddModelPresentation($title: String!,  $htmlContent: String,  $media: String, $alias: String, $models: [String]){
createModelPresentation(input: {title: $title, htmlContent: $htmlContent, media: $media, alias: $alias, models:$models}){
    modelPresentation{
      id
      title
      htmlContent
      alias
      media{
        id
        filePath
        type
        size
        category{
          edges{
            node{
              id
              libelle
            }
          }
        }
      }
    }
  }  
}`;

//----- Modifier un modèle de présentation
/*
 Exemple de variables
  {
    "id": "/api/model_presentations/1",    
    "title": "Magento 2 - edited"
  }
*/
export const UPDATE_MODEL_PRESENTATION = gql`
mutation UpdateModelPresentation($id: ID!, $title: String,  $htmlContent: String,  $media: String,  $alias: String, $models: [String]){
    updateModelPresentation(input: {id:$id, title: $title, htmlContent: $htmlContent, media: $media, alias: $alias, models: $models}){
        modelPresentation{
          id
          title
          htmlContent
          alias
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
        }
      }  
    }
`;

//----- Supprimer un modèle de présentation
/*
 Exemple de variables
  {
    "id": "/api/model_presentations/1",    
  }
*/
export const DELETE_MODEL_PRESENTATION = gql`
mutation DeleteModelPresentation($id: ID!){
	deleteModelPresentation(input: {id:$id}){
    modelPresentation{
      id
    }
  }  
}`;


//----- Récupération des présentations
export const GET_PRESENTATIONS = gql`
{
  presentations{
    edges{
      node{
        id
      	title
        offers{
          edges{
            node{
              id
            }
          }
        }
        htmlContent
        alias
        createdAt
        media{
          id
          filePath
          type
          size
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}
`;
export const GET_PRESENTATIONS_PAGINATION = gql`
query presentations ($nbperpage: Int, $title: String, $cursor: String, $cursorLast: String){
  presentations(first: $nbperpage, title: $title, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{
        id
      	title
        offers{
          edges{
            node{
              id
              title
            }
          }
        }
        htmlContent
        alias
        number
        createdAt
        status
        media{
          id
          filePath
          type
          size
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
        presentationDocusigns{
          edges{
            node{
              status
              presentationDocusignEmails{
                edges{
                  node{
                    lastname
                    firstname
                    email
                    docusign{
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRESENTATIONS_CURSOR = gql`
{
  presentations (first: 10000){
    edges{
      cursor
    }
  }
}
`;

export const SEARCH_PRESENTATIONS = gql`
query searchPresentations ($title: String,  $first:Int) {
  presentations(first: $first, title: $title) {
    edges {
      node {
        id
        title
      }
    }
  }
}`;

//----- Rechercher une presentation par ID
/*
 Exemple de variables
  {
    "id": "/api/presentations/1"
  }
*/
export const GET_PRESENTATION_BY_ID = gql`
query presentation($id: ID!) {
  presentation(id: $id) {
    id
    title
    number
    offers{
      edges{
        node{
          id
        }
      }
    }
    htmlContent
    alias
    createdAt
    media{
      id
      filePath
      type
      size
      category{
        edges{
          node{
            id
            libelle
          }
        }
      }
    }
  }
}
`;

//----- Rechercher une presentation par ALIAS
/*
 Exemple de variables
  {
    "id": "/api/presentations/1"
  }
*/
export const GET_PRESENTATION_BY_ALIAS = gql`
query presentations($alias: String!) {
  presentations(alias: $alias) {
    edges {
      node {
        id
        title
        number
        offers{
          edges{
            node{
              id
              title
              poNumber
              description
              createdAt
              validityDate
              status
              recurring
              recurringStartDate
              recurringInvoiceDay
              recurringDelay
              offerFooter{
                id 
                discountFixed
                discountPercent
                advancePayment
                comment
                paymentTerm{
                  id
                  name
                }
                paymentDeadline{
                  id
                  name
                }
                offerFooterTaxes{
                  edges{
                    node{
                      id
                      amount
                      tax {
                        id
                      }
                    }
                  }
                }
              }
              offerPhases{
                edges{
                  node{
                    id
                    name
                    position
                    offerTasks{
                      edges{
                        node{
                          id
                          name
                          reference
                          description
                          price
                          quantity
                          total
                          position
                          tax {
                            id
                            name
                          }
                          discountFixed
                          discountPercent
                          task {
                            id
                            name
                          }
                          pole{
                            edges{
                              node{
                                id
                              name
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        htmlContent
        alias
        createdAt
        status
        media{
          id
          filePath
          type
          size
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une presentation
/*
 Exemple de variables
  {
    "title": "Magento 2",
    "offer": ["/api/offers/29"],
    "htmlContent": "0009123"
  }
*/
export const ADD_PRESENTATION = gql`
mutation AddPresentation($title: String!, $offers: [String], $htmlContent: String, $alias: String, $media: String, $createdAt: String, $status: String, $number: String!){
	createPresentation(input: {title: $title, offers: $offers, htmlContent: $htmlContent, alias: $alias, media: $media, createdAt: $createdAt, status: $status, number: $number}){
    presentation{
      id
      title
      number
      htmlContent
      alias
      createdAt
      media{
        id
        filePath
        type
        size
        category{
          edges{
            node{
              id
              libelle
            }
          }
        }
      }
    }
  }  
}`;

//----- Modifier une offre
/*
 Exemple de variables
  {
    "id": "/api/presentations/1",    
    "title": "Magento 2 - edited",
    "offer": ["/api/offers/29"],
    "htmlContent": "0009123",
    "alias": "123-magento-2" 
  }
*/
export const UPDATE_PRESENTATION = gql`
mutation UpdatePresentation($id: ID!, $title: String, $offers: [String], $htmlContent: String, $alias: String, $media: String, $createdAt: String, $status: String, $number: String){
	updatePresentation(input: {id: $id, title: $title, offers: $offers, htmlContent: $htmlContent, alias: $alias, media: $media, createdAt:$createdAt, status: $status, number: $number}){
    presentation{
      id
      title
      number
      htmlContent
      alias
      createdAt
      media{
        id
        filePath
        type
        size
        category{
          edges{
            node{
              id
              libelle
            }
          }
        }
      }
    }
  }  
}
`;

//----- Supprimer une présentation
/*
 Exemple de variables
  {
    "id": "/api/presentations/1",    
  }
*/
export const DELETE_PRESENTATION = gql`
mutation DeletePresentation($id: ID!){
	deletePresentation(input: {id:$id}){
    presentation{
      id
    }
  }  
}`;
