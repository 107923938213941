import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";

import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';

import { GET_CATALOGS } from '../../../../queries/catalogs';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardByAssetType from '../../../layouts/Card/cardContent/CardByAssetType';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';

import request from '../../../../js/utils/fetch';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET, ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET, ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS, ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK } from '../../../../js/constants/route-names';
import { GET_ASSET_BY_ID } from '../../../../queries/assets';
import styled from 'styled-components';
import 'moment-timezone';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Button from '../../../ui/button/Button';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import LayoutFormBuilder from '../../../ui/form/LayoutFormBuilder';
import feedEditConfig from './config/feeds/feedEdit.config';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

var newWindow = null

var intervalRef = null
class DiffusionPermanentPosts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filtered: [],
            readyToken: false,
            openForm: false,
            ready: true,
            postType: true,
            idAsset: `/api/assets/${this.props.match.params.id}`,
            count: 9,
            startCursor: null,
            currentPost: null,
            paramText: null,
            posts: [],
            pagination: {
                count: 9
            }
        };
    }

    componentDidMount() {
        checkRouting(this.props)
        this.handleGetAssetDatas();

        this.setState({
            filtered: this.props.products
        });
    }

    componentWillUnmount() {
        clearInterval(intervalRef)
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleGetAssetDatas = () => {
        this.setState({
            filteredAssets: null
        });

        const getAssetsByType = GET_ASSET_BY_ID;

        this.props.client.query({
            query: getAssetsByType,
            variables: { id: this.state.idAsset },
            fetchPolicy: 'no-cache'
        }).then(GET_ASSET_BY_ID_RESULT => {
            let currentAsset = GET_ASSET_BY_ID_RESULT.data.asset;
            window.setAssetNameMenu(currentAsset)
            this.setState({
                assetData: currentAsset,
            }, async () => {
                if (this.state.assetData.channel && !this.state.assetData.assetType.hasPage) {
                    await this.checkToken();
                    this.getFeed();
                } else {
                    this.setState({
                        readyToken: true
                    })
                }
            });
        })
    }

    checkToken = () => {
        return new Promise((resolve) => {
            request(`${process.env.REACT_APP_API}/social-connector/callback/${this.props.match.params.id}`, 'post', null, 'application/json', true)
                .then(async (data) => {
                    if (data.success) {
                        this.setState({
                            readyToken: true
                        }, () => {
                            resolve();
                        })
                    } else if (data.url) {
                        this.popupCenter({ url: data.url, title: "ConnexionPopup", w: 900, h: 500 })
                    } else {
                        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));
                    }
                });
        })
    }

    popupCenter = ({ url, title, w, h }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : null;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : null;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;

        window.addEventListener('message', function (e) {
            if (e.origin === url) {
                if (e.data === 'closed') {
                    console('popup window was closed');
                    alert('popup window was closed');
                }
            }
        }, false);

        newWindow = window.open(url, title, `scrollbars=yes,width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`)
        intervalRef = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(intervalRef)
                this.checkToken()
            }
        }, 1000)
        if (window.focus) newWindow.focus();
    }

    handleLoad = (cursor) => {
        this.setState({
            startCursor: this.state.assetData.assetType.identifier === "linkedin" ? this.state.startCursor + this.state.pagination.count : cursor,
        }, () => this.getFeed())
    }

    handleToggleDrawer = (stateDrawer) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });
    };

    handleEdit = (post) => {
        let assetType = this.state.assetData.assetType.identifier;
        this.handleToggleDrawer('openForm');
        this.setState({
            currentPost: post,
            paramText: assetType === 'instagram' ? post.caption : post.message
        });
    };

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    editFeed = async () => {

        //---- add feed
        let assetType = this.state.assetData.assetType.identifier;

        let bodyDatas = new FormData;

        if (assetType === 'facebook') {
            bodyDatas.append('message', this.state.paramText)
        } else if (assetType === 'instagram') {
            bodyDatas.append('caption', this.state.paramText)
        } else if (assetType === 'linkedin') {
            bodyDatas.append('caption', this.state.paramText)
        } else if (assetType === 'twitter') {
            bodyDatas.append('message', this.state.paramText.toString().substring(0, 279))
        } else if (assetType === 'wordpress') {
            bodyDatas.append('titre', this.state.paramText)
            bodyDatas.append('message', this.state.paramText2)
        }
        this.setState({
            requesting: true,
        }, () => {
            this.props.startLoading();
            let urlApi = `${process.env.REACT_APP_API}/social-connector/post/update/${this.props.match.params.id}/${this.state.currentPost.id}`
            request(urlApi, 'POST', bodyDatas, null, true).then(async (data) => {
                if (data && !data.errors) {
                    let getPosts = [...this.state.posts]
                    let findPost = getPosts.find(e => e.id === this.state.currentPost.id);
                    findPost.message = this.state.paramText;
                    this.setState({ posts: getPosts })
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `${this.state.assetData.assetType.identifier === 'twitter' ? 'Tweet' : 'Post'} modifié ! `);
                } else {
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));
                }

                this.handleToggleDrawer('openForm');
            }).catch(e => {
                this.props.stopLoading();
                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));
            });
        });
    }

    render() {
        const assetData = this.state.assetData;
        let isBuilder = assetData?.assetType.identifier === 'minisite' || assetData?.assetType.identifier === 'jeu' || assetData?.assetType.identifier === 'flipbook' || assetData?.assetType.identifier === "flipbook_with_cart" || assetData?.assetType.identifier === 'newsletter' || assetData?.assetType.identifier === 'magento' || assetData?.assetType.identifier === 'pages' || assetData?.assetType.identifier === 'shopify';
        let isFlipbook = assetData?.assetType.identifier === 'flipbook' || assetData?.assetType.identifier === "flipbook_with_cart";
        let hasPage = assetData?.assetType.hasPage;
        return (
            <div>
                {assetData ?
                    <>
                        <TopPanel
                            // icomoon="picto-rs"
                            image={assetData?.assetType.logo}
                            colorIcomoon={colors.blue.darker.hue300}
                            title={isBuilder && isFlipbook
                                ? this.props.t("spread.campaigns.manageFlipbook")
                                : isBuilder && !isFlipbook
                                    ? this.props.t("spread.campaigns.manageFlipbookDynamic", { flipbook: assetData?.assetType.identifier })
                                    : this.props.t("spread.campaigns.manageYourFeed", { libelle: assetData?.assetType.libelle })
                            }
                            subtitle={isBuilder ? this.props.t("spread.campaigns.manageYourPages") : this.props.t("spread.campaigns.manageYourPosts")}
                            handlerAdd={isBuilder ? hasPage ? () => { this.goTo(ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET.replace(':id', assetData.id.replace('/api/assets/', ''))) } : null : null}
                            textAdd={isBuilder ? hasPage && assetData?.assetType.identifier !== 'pages' ? this.props.t("spread.campaigns.modifyHeaders") : null : this.state.readyToken ? this.props.t("spread.campaigns.addPost") : null}
                            handlerImport={hasPage ? () => { this.goTo(ROUTE_DIFFUSION_PERMANENTS_PAGES_ASSET.replace(':id', assetData.id.replace('/api/assets/', '')).replace(':typeAsset', assetData.assetType.identifier)) } : null}
                            textImport={hasPage ? this.props.t("spread.campaigns.modifyPages") : null}
                            handlerThirdButton={hasPage ? () => { this.goTo(ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS.replace(':id', assetData.id.replace('/api/assets/', ''))) } : null}
                            textThirdButton={hasPage && assetData?.assetType.identifier !== 'pages' ? this.props.t("spread.campaigns.infoManagement") : null}
                            // searchHandler={this.handleChange} 
                            gradientColor1={colors.menu.regular}
                            gradientColor2={colors.menu.darker}
                            windowWidth={this.props.windowWidth}
                            // openForm={this.state.openForm}
                            buttonAvailable={this.state.ready}
                            // currentLang={this.state.currentLang} 
                            // handleLang={this.handleLang} 
                            locales={this.props.locales}
                            hasBorder={true}
                        >
                            {
                                hasPage && assetData?.assetType.identifier !== 'pages' ?
                                    <Button style={{ marginLeft: '16px' }} text={"Shop the look"} bgcolor={colors.green.regular} onClick={() => { this.goTo(ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK.replace(':id', assetData.id.replace('/api/assets/', ''))) }} shadowcolor={colors.green.regular} disabled={false} />
                                    : null
                            }
                        </TopPanel>
                        {
                            this.state.readyToken ?
                                <CardByAssetType
                                    asset={assetData}
                                    posts={this.state.posts ? this.state.posts : null}
                                    loader={this.state.requesting}
                                    pagination={this.state.pagination}
                                    handleLoad={this.handleLoad}
                                    handleDelete={this.deleteFeed}
                                    handleEdit={this.handleEdit}
                                    isFlipbook={isFlipbook}
                                />
                                :
                                null
                        }
                        <LayoutFormBuilder
                            isSublayout={false}
                            opened={this.state.openForm}
                            dataLayout={feedEditConfig(assetData)}
                            forClose={() => { this.handleToggleDrawer('openForm') }}
                            image={this.state.imageAsset}
                            allState={this.state}
                            stateCallback={this.handleInputChange}
                            handlerMutation={this.editFeed}
                            handleCancel={() => { this.handleToggleDrawer('openForm') }}
                            validateButton={true}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                        />
                    </>
                    : <PageLoader />}
            </div>
        );
    }

    getFeed() {
        if (this.state.readyToken) {
            return new Promise((resolve) => {
                //---- get feed

                this.setState({
                    requesting: true
                }, () => {
                    let formData = new FormData;
                    if (this.state.startCursor) {
                        formData.append('start', this.state.startCursor)
                    }
                    formData.append('count', this.state.count)
                    request(`${process.env.REACT_APP_API}/social-connector/posts/${this.props.match.params.id}`, 'post', formData, 'multipart/form-data')
                        .then(async (data) => {
                            //--- parse response to get posts
                            let dataPosts = this.state.posts.concat(this.state.assetData.assetType.identifier === "linkedin" ? data.elements : data.data)
                            if (this.state.assetData.assetType.identifier === "linkedin") {
                                this.setState({
                                    posts: dataPosts,
                                    pagination: data.paging,
                                    requesting: false
                                });
                            } else {
                                this.setState({
                                    posts: dataPosts,
                                    pagination: data.paging,
                                    requesting: false
                                });
                            }
                        });
                    resolve();
                });
            })
        }
    }

    deleteFeed = (id) => {
        //---- delete feed
        let postId = id;

        this.setState({
            requesting: true
        }, () => {
            this.props.startLoading();
            request(`${process.env.REACT_APP_API}/social-connector/post/delete/${this.props.match.params.id}/${postId}`, 'post', {}, 'application/json', true
            ).then(async (data) => {
                if (data) {
                    await this.getFeed();
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, this.state.assetData.assetType.identifier === 'twitter'
                        ? this.props.t("spread.campaigns.tweetDeleted")
                        : this.props.t("spread.campaigns.postDeleted")
                    );
                }
                else {
                    await this.getFeed();
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));

                }
            })
        });

    }

    goTo = (route, id, typeAsset) => {
        this.props.history.push({
            pathname: route,
            state: {
                assetId: id,
                typeAsset: typeAsset
            }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DiffusionPermanentPosts))));
