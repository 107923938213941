import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function cgvAddConfig() {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom du tarif',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'textarea',
            label: 'Contenu',
            helper: {
                text: 'Indiquez le contenu',
                link: false,
            },
            required: true,
            stateName: 'contentCGV',
        },
    ]
    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
    ];

    var obj = {
        titleForm: 'Ajouter une CGV',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre CGV',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Votre CGV est désormais configurée',
                textButton: 'Créer cette CGV'
            },
            children
        }
    };
    return obj;
}