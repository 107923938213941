import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const GuidelineConfig =  {
    titleForm: "Guideline",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer votre image de marque',
    langSelect: true,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: FORM,
        finalStep:{},
        children:[
            {
                optionsInputs:[
                ]
            },
        ]
    }
}

export default GuidelineConfig;