import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid, TextField, Box } from '@material-ui/core';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../../js/constants/alert-types';
import CardCustom from '../../../../layouts/Card/CardCustom';
import TypographyCustom from '../../../../ui/typography/Typography';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { v4 as uuidv4 } from 'uuid';

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 16px;
        padding: 16px;
    }
    & > p{
        margin: 0;

    }
`;

const drawerWidth = 400;


const styles = theme => ({
    root: {
        width: 200,
        height: 200,
        cursor: 'pointer',
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        margin: '0 auto',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start'
    },
    drawerPaper: {
        width: drawerWidth,
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
});

class FormattingCustomFlux extends React.Component {  
    constructor(props){
        super(props);

        this.state = {
            hover: false,
            file: null,
            uploading: false,
            errorMessage : null,
            seeError : false,
            openMediaPicker: false,
            rowVariantValue: null,
            tabValue:0,
            openModal: false,
            formattingValue: this.props.allState.formattingValue ? 
                this.props.allState.formattingValue : 
                [
                    {                
                        attr: null,
                        id: uuidv4(),
                        new: true,
                        value: null
                    }
                ],
        };
    }

    error = (error) => {
        this.props.snack(ALERT_ERROR, error);
        this.setState({ hover: false });
    };

    getDisableState = (value) => {
        for (let item of this.state.formattingValue) {
            if (item.attr === value){
                return true;
            }
        }
        return false;
    }

    render() {
        const { seeError, errorMessage } = this.state;
        const { allState, stateCallback } = this.props;

        return (
            <div style={{ padding: '32px 32px 32px 32px', backgroundColor: 'rgb(250, 251, 251)' }}>
                <Grid container style={{marginTop: 12}} direction="column">
                    {
                        this.state.formattingValue?.length > 0 ?
                        this.state.formattingValue.map((row, i) => {
                            if (row.deleted)
                                return null;
                            return (
                                    <Grid key={i} item xs={12} style={{ marginBottom: 15 }}>
                                        <CardCustom paddingbottom="16px" paddingtop="16px">
                                            <Grid container direction="row" spacing={0} style={{position: 'relative'}}>
                                                <Grid item xs={2} style={{display: 'flex', alignItems: 'center'}}>
                                                    <TypographyCustom variant="body2" colortext="#333333">Libellé #{row.deleted ? i-1 : i+1}</TypographyCustom>
                                                </Grid>
                                                <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                                                    <TextFieldCustom
                                                        id={`Attr${i}`}
                                                        variant="outlined"
                                                        color="secondary"
                                                        select
                                                        fullWidth
                                                        value={row.attr ? row.attr : ''}
                                                        placeholder="Sélection attribut"
                                                        onChange={(evt) => {
                                                            row.attr = evt.target.value; 
                                                            row.updated = true;
                                                            stateCallback('instantWinList', this.state.formattingValue)}}
                                                        helperText={seeError ? errorMessage : null}
                                                        disabled={row.isWin ? true : false}
                                                        error={seeError && errorMessage ? true : false}
                                                    >
                                                        {
                                                            allState.catalogSelectedDatas?.catalog?.attributes?.edges ?
                                                            (
                                                                <MenuItem 
                                                                    key={`OptionSelect${uuidv4()}`} 
                                                                    value={"SKU"} 
                                                                    disabled={this.getDisableState("SKU")}
                                                                >
                                                                    sku
                                                                </MenuItem>
                                                            ) : null
                                                        }
                                                        {
                                                            allState.catalogSelectedDatas?.catalog?.attributes?.edges ?
                                                            (
                                                                <MenuItem 
                                                                    key={`OptionSelect${uuidv4()}`} 
                                                                    value={"enabled"} 
                                                                    disabled={this.getDisableState("SKU")}
                                                                >
                                                                    actif
                                                                </MenuItem>
                                                            ) : null
                                                        }
                                                        {
                                                            allState.catalogSelectedDatas?.catalog?.attributes?.edges ?
                                                                allState.catalogSelectedDatas.catalog.attributes.edges.map((option,index) => {
                                                                    let getLabel = option.node.identifier;
                                                                    let getValue = option.node.id;
                                                                    let getDisabled = false;
                                                                    for ( let item of this.state.formattingValue ){
                                                                        if (item.attr === getValue){
                                                                            getDisabled = true;
                                                                        }
                                                                    }
                                                                    return(
                                                                        <MenuItem key={`OptionSelect${index}`} value={getValue} disabled={getDisabled}>
                                                                            {getLabel}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            : null
                                                        }
                                                    </TextFieldCustom>
                                                </Grid>
                                                <Grid item xs={4} style={{ paddingRight: 20, marginTop: 8 }}>
                                                    <TextFieldCustom
                                                        id={`Value${i}`}
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={`Libellé #${row.deleted ? i-1 : i+1}`}
                                                        defaultValue={row.value ? row.value : ''}
                                                        error={seeError && errorMessage ? true : false}
                                                        onChange={(evt) => {
                                                            row.value = evt.target.value; 
                                                            row.updated = true;
                                                            stateCallback('formattingValue', this.state.formattingValue)}
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2} style={{ paddingRight: 20, marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                                    <DeleteIcon 
                                                        style={{ color: '#FF6565', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            
                                                            this.state.formattingValue.splice(i,1);
                                                            stateCallback('formattingValue', this.state.formattingValue.filter(
                                                                e =>  e.id !== row.id
                                                            ));
                                                        }} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardCustom>
                                    </Grid>
                            );
                        })
                        : <Box mb={2} style={{width: "100%"}}><TypographyCustom variant="body2">Vous n’avez pas formaté vos attributs</TypographyCustom></Box>
                    }
                    {
                        this.state.formattingValue.length < allState.catalogSelectedDatas?.catalog?.attributes?.edges.length ?
                        (
                            <div 
                                style={{
                                    height: 21,
                                    color: colors.blue.darker.hue300,
                                    textDecoration: 'underline',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    this.state.formattingValue.push({
                                        id: uuidv4(),
                                        new: true,
                                        attr: null,
                                        value: null,
                                    })
                                    this.props.stateCallback(this.state.formattingValue);
                                }}
                            >
                                <AddCircleIcon fontSize="small" style={{ marginRight: 10 }} />
                                <span>Ajouter un libellé</span>
                            </div>
                        ) : null
                    }
                </Grid>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(FormattingCustomFlux));