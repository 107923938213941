import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import OfferManager from '../components/OfferManager';

export default function addDevis(currentLang,handleMediaPicker,allCompanies, handleSelectCompany,currentCompanies,formSettings, action) {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom du modèle',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Indiquez la description du modèle',
                link: false,
            },
            required: true,
            stateName: 'description',
        },
        {
            type: 'mediaPicker',
            label: 'Image modèle',
            helper: {
                text: 'Indiquez le nom',
                link: false,
            },
            handleMediaPicker : handleMediaPicker,
            required: true,
            stateName: 'imageDevis',
        },
        
    ]
    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
        {
            labelName: 'Phase du projet',
            isOptionnal: false,
            component : OfferManager,
            componentProps: {
                stateName: 'modelePhases',
            }
        },
    ];

    var obj = {
        titleForm: action === 'add' ? `Créer un modèle de devis` : `Modifier un modèle de devis`,
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add' ? 'créer' : 'modifier'} votre modèle de devis`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce modèle' : 'Valider la modification de ce modèle',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children
        }
    };
    return obj;
}