import noImageCompany from '../../../../../../assets/images/emptyCompany.jpg';
import CardPages from '../../../../../layouts/Card/cardContent/CardPages';

export const listSettings = {
    grid : {
        components : CardPages,
        columns : {
            xs : 2,
            sm : 3,
            laptop : 3,
        }
    },
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    // {
    //     view : 'table',
    //     mapper : 'companiesListMapper'
    // }
]

export const perPageOptions = {
    'card' : ['9','18','36'],
}