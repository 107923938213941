import PropTypes from "prop-types";
import React from "react";
import ProductImageGallery from "../../components/_internals/product/ProductImageGallery";
import ProductDescriptionInfo from "../../components/_internals/product/ProductDescriptionInfo";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  currency,
  cartItems,
  wishlistItems,
  compareItems,
  params,
  data,
  preview, 
  id,
  inputCallback
}) => {
  const wishlistItem = null;
  const compareItem = null;

  const discountedPrice = 0;
  const finalProductPrice = 0;
  const finalDiscountedPrice = 0

  return (
    <div className="builder-template-scope">
      <div
        className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${
          spaceBottomClass ? spaceBottomClass : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              {/* product image gallery */}
              <ProductImageGallery product={product} />
            </div>
            <div className="col-lg-6 col-md-6">
              {/* product description info */}
              <ProductDescriptionInfo
                product={product}
                params={params}
                data={data}
                preview={preview}
                id={id}
                inputCallback={inputCallback}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

export default ProductImageDescription;
