import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import H1 from '../../../../shared/components/CustomH1';
import { useDrop } from 'react-dnd';

// Who Are We

const OfferFrontPage = (props) => {
    const [hover, setHover] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            data.inputs['backgroundImage'].value = item.media.node;
            update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        minHeight: '700px',
        maxHeight: '880px',
        marginBottom: 20
    };

    let image = data.inputs['backgroundImage']?.value;

    return (
        <div
        className={`offer-template-scope`}  
        ref={drop} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        style={{
            ...style,
        }}
        >
            { !preview && props.children }

            <div className="container" style={{
                height: '100%', 
                padding: 20,
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: 
                    typeof image === 'string' ? 
                        `url(${image})` 
                    : 
                        image?.filePath ?  `url(${process.env.REACT_APP_MEDIAS}/${image?.filePath}` 
                    :
                        null,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}>
                <Pg data={data} preview={preview} component={id} block={'date'} inputCallback={inputCallback} />
                <div className="company_image text-center">
                    {
                        typeof data.blocks.logo.inputs.value?.value === 'string' || data.blocks.logo.inputs.value?.value?.filePath ? (
                            <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                                src={
                                    typeof data.blocks.logo.inputs.value?.value === 'string' ? 
                                        data.blocks.logo.inputs.value.value : 
                                        data.blocks.logo.inputs.value?.value.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                            : "https://via.placeholder.com/500x250/F0F7FA/C5DEEC"
                                }
                                alt="Logo"
                            />
                        ) : <img className="logo" src="https://via.placeholder.com/500x250/F0F7FA/C5DEEC" alt="Logo" />
                    }
                </div>
            
                <div className="front_content text-center">
                    <Div data={data} className="offer_name" preview={preview} component={id} block={'offer_name'} inputCallback={inputCallback} nopadding={true} />
                    <Div data={data} className="infos_company" preview={preview} component={id} block={'infos_company'} inputCallback={inputCallback} nopadding={true} />
                </div>
            </div>
        </div>
    );
};

OfferFrontPage.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default OfferFrontPage;
