import { getDefaultTextSimple, getDefaultTextarea, getDefaultButton } from '../../helpers';
import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Texte image",
    inputs: {
        produit: {
            label: "Produit lié",
            type: FormInputTypes.PRODUCT,
            value: null
        },
    },
    blocks: {
        media_1: {
            title: "Image ou vidéo",
            name: "Image ou vidéo",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                switch: {
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                image: {
                    label: "Image",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/750x500"
                },
                video: {
                    label: "Lien vidéo",
                    type: FormInputTypes.TEXT,
                    value: "https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
                }
            }
        },
        title_1: getDefaultTextSimple("Titre 1", "Ajouter votre texte", 20, "#fff"),
        paragraph_1: getDefaultTextarea("Description 1", "Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.", null, "#433f3f", null, null, 'center', 16),
        media_2: {
            title: "Image ou vidéo",
            name: "Image ou vidéo",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                switch: {
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                image: {
                    label: "Image",
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/750x500"
                },
                video: {
                    label: "Lien vidéo",
                    type: FormInputTypes.LINK,
                    value: "https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
                }
            }
        },
        title_2: getDefaultTextSimple("Titre 2", "Ajouter votre texte", 20, "#fff"),
        paragraph_2: getDefaultTextarea("Description 2", "Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.", null, "#433f3f", null, null, 'center', 16),
        button: getDefaultButton("Bouton", "Cliquez ici", 20, "#666", {backgroundColor: "#fff"} )
    }
};