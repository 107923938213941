import { gql } from 'apollo-boost';

//----- Récupération des offers
export const GET_OFFERS = gql`
{
  offers{
    edges{
      node{
        id
      	title
        poNumber
        description
        createdAt
        validityDate
        status
        offerFooter{
          id
          discountFixed
          discountPercent
          advancePayment
          comment
          paymentTerm{
            id
            name
          }
          paymentDeadline{
            id
            name
          }
          offerFooterTaxes{
            edges{
              node{
                id
                amount
                tax {
                  id
                }
              }
            }
          }
        }
        offerPhases{
          edges{
            node{
              id
              name
              position
              offerTasks{
                edges{
                  node{
                    id
                    name
                    reference
                    description
                    price
                    quantity
                    total
                    position
                    tax {
                      id
                      name
                    }
                    discountFixed
                    discountPercent
                    task {
                      id
                      name
                    }
                    pole{
                      edges{
                        node{
                          id
                     	   name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SEARCH_OFFERS = gql`
query offers ($title: String,  $first:Int) {
  offers(first: $first, title: $title) {
    edges {
      node {
        id
        title
      }
    }
  }
}`;

export const GET_OFFERS_CURSOR = gql`
{
  offers (first: 10000){
    edges{
      cursor
    }
  }
}
`;

export const GET_OFFERS_PAGINATION = gql`
query offers ($nbperpage: Int, $idList: [Int], $cursor: String, $cursorLast: String){
  offers(first: $nbperpage,id_list: $idList, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{
        id
      	title
        poNumber
        description
        createdAt
        validityDate
        status
        offerFooter{
          discountFixed
          discountPercent
          advancePayment
          comment
          paymentTerm{
            id
            name
          }
          paymentDeadline{
            id
            name
          }
          offerFooterTaxes{
            edges{
              node{
                id
                amount
                tax {
                  id
                }
              }
            }
          }
        }
        offerPhases{
          edges{
            node{
              id
              name
              position
              offerTasks{
                edges{
                  node{
                    id
                    name
                    reference
                    description
                    price
                    quantity
                    total
                    position
                    tax {
                      id
                      name
                    }
                    discountFixed
                    discountPercent
                    task {
                      id
                      name
                    }
                    pole{
                      edges{
                        node{
                          id
                     	   name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;



//----- Rechercher une offer par ID
/*
 Exemple de variables
  {
    "id": "/api/offers/1"
  }
*/
export const GET_OFFER_BY_ID = gql`
query offer($id: ID!) {
  offer(id: $id) {
    id
    title
    poNumber
    description
    createdAt
    validityDate
    status
    project{
      id
      name
      description
      media{
        id
        filePath
        type
        size
        category {
          edges {
            node {
              id
              libelle
            }
          }
        }
      }
    }
    offerFooter{
        id
      discountFixed
      discountPercent
      advancePayment
      comment
      paymentTerm{
        id
        name
      }
      paymentDeadline{
        id
        name
      }
      offerFooterTaxes{
        edges{
          node{
            id
            amount
            tax {
              id
            }
          }
        }
      }
    }
    offerPhases{
      edges{
        node{
          id
          name
          position
          offerTasks{
            edges{
              node{
                id
                name
                reference
                description
                price
                quantity
                total
                position
                tax {
                  id
                  name
                }
                discountFixed
                discountPercent
                task {
                  id
                  name
                }
                pole{
                  edges{
                    node{
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }    
  }
}
`;


//----- Ajouter une offre
/*
 Exemple de variables
  {
    "title": "Magento 2",
    "description": "Mise en place Boutique en ligne Magento",
    "poNumber": "0009123",
    "status": 1,
    "createdAt": "2020-12-28 15:22:00",
    "validityDate": "2021-02-28"  
  }
*/
export const ADD_OFFER = gql`
mutation AddOffer($title: String!, $description: String, $project: String, $poNumber: String, $status: String!, $createdAt: String!, $validityDate: String, $recurring: Boolean, $recurringStartDate: String, $recurringInvoiceDay: Int, $recurringDelay: Int){
	createOffer(input: {title: $title, description: $description,project: $project, poNumber: $poNumber, status: $status, createdAt: $createdAt, validityDate: $validityDate, recurring: $recurring, recurringStartDate: $recurringStartDate, recurringInvoiceDay: $recurringInvoiceDay, recurringDelay: $recurringDelay}){
    offer{
      id
      title
      poNumber
      description
      createdAt
      validityDate
      status
    }
  }  
}`;

//----- Modifier une offre
/*
 Exemple de variables
  {
    "id" : "/api/offers/1",
    "title": "Un projet sur Magento 2",
    "description": "Mise en place Boutique en ligne Magento",
    "poNumber": "0009123",
    "status": 1,
    "createdAt": "2020-12-28 15:22:00",
    "validityDate": "2021-02-28"  
  }
*/
export const UPDATE_OFFER = gql`
mutation UpdateOffer($id: ID!, $title: String!, $description: String, $poNumber: String, $status: String, $createdAt: String, $validityDate: String, $recurring: Boolean, $recurringStartDate: String, $recurringInvoiceDay: Int, $recurringDelay: Int){
	updateOffer(input: {id: $id, title: $title, description: $description, poNumber: $poNumber, status: $status, createdAt: $createdAt, validityDate: $validityDate, recurring: $recurring, recurringStartDate: $recurringStartDate, recurringInvoiceDay: $recurringInvoiceDay, recurringDelay: $recurringDelay}){
    offer{
      id
      title
      poNumber
      description
      createdAt
      validityDate
      status
    }
  }  
}
`;

//----- Supprimer une offre
/*
 Exemple de variables
  {
    "id" : "/api/offers/1"
  }
*/
export const DELETE_OFFER = gql`
mutation DeleteOffer($id: ID!){
	deleteOffer(input: {id:$id}){
    offer{
      id
    }
  }  
}`;





//----- Ajouter une phase d'un offer
/*
 Exemple de variables
  {
  "name": "Définition du projet",
  "position": 1,
  "offer" : "/api/offers/1"
  }
*/
export const ADD_PHASE_OFFER = gql`
mutation AddPhaseOffer($name: String!, $position: Int, $offer: String){
	createOfferPhase(input: {name: $name, position: $position, offer: $offer}){
    offerPhase{
      id
      name
    }
  }  
}`;

//----- Modifier une phase d'un offer
/*
 Exemple de variables
  {
  "id": "/api/offer-phases/1",
  "name": "Définition",
  "position": 1,
  "offer" : "/api/offers/1"
  }
*/
export const UPDATE_PHASE_OFFER = gql`
mutation UpdatePhaseOffer($id: ID!, $name: String, $position: Int, $offer: String){
	updateOfferPhase(input: {id: $id, name: $name, position: $position, offer: $offer}){
    offerPhase{
      id
      name
    }
  }  
}`;


//----- Supprimer une phase d'un offer
/*
 Exemple de variables
  {
    "id" : "/api/offer-phases/1"
  }
*/
export const DELETE_PHASE_OFFER = gql`
mutation DeletePhaseOffer($id: ID!){
	deleteOfferPhase(input: {id:$id}){
    offerPhase{
      id
    }
  }  
}`;


//----- Ajouter une task à une phase d'un offer
/*
 Exemple de variables
  {
  "name": "Définition",
  "reference": "REF0001",
  "description": "Définition du projet",
  "price": "99.90",
  "quantity": "1",
  "total": "99.90",
  "position": 1,
  "phase" : "/api/offer-phases/1",
  "task": "/api/offer-tasks/1",
  "poles" : [],
  "tax": "/api/agency-tax-rates/1",
  "discountFixed": 99.90,
  "discountPercent": 10
  }
*/
export const ADD_TASK_PHASE_OFFER = gql`
mutation AddOfferTask($name: String!, $reference: String, $description: String, $price: String, $quantity: String, $total: String, $delay: String, $position: Int, $phase: String, $task: String,  $poles: [String], $tax: String, $discountFixed: String, $discountPercent: String){
	createOfferTask(input: {name: $name, reference: $reference, description: $description, price: $price, quantity: $quantity, total: $total, delay: $delay, position: $position, phase: $phase, task: $task, pole: $poles, tax: $tax, discountFixed: $discountFixed, discountPercent: $discountPercent}){
    offerTask{
      id
      name
      reference
      description
      price
      quantity
      total
      position
      tax {
        id
        name
      }
      discountFixed
      discountPercent
      pole{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }  
}`;



//----- Modifier une task à une phase d'un offer
/*
 Exemple de variables
  {
  "id": "/api/offer-tasks/3",
  "name": "Définition de test",
  "reference": "REF0003",
  "description": "Définition du projet",
  "price": "20.00",
  "quantity": "2",
  "total": "40.00",
  "position": 3,
  "phase" : "/api/offer-phases/1",
  "task": "/api/offer-tasks/1",
  "poles" : [],
  "tax": "/api/agency-tax-rates/1",
  "discountFixed": 99.90,
  "discountPercent": 10
}
*/
export const UPDATE_TASK_PHASE_OFFER = gql`
mutation UpdateOfferTask($id: ID!, $name: String, $reference: String, $description: String, $price: String, $quantity: String, $total: String, $delay: String, $position: Int, $phase: String, $task: String,  $poles: [String], $tax: String, $discountFixed: String, $discountPercent: String){
	updateOfferTask(input: {id:$id, name: $name, reference: $reference, description: $description, price: $price, quantity: $quantity, total: $total, delay: $delay, position: $position, phase: $phase, task: $task, pole: $poles, tax: $tax, discountFixed: $discountFixed, discountPercent: $discountPercent}){
    offerTask{
      id
      name
      reference
      description
      price
      quantity
      total
      position
      tax {
        id
        name
      }
      discountFixed
      discountPercent
      pole{
        edges{
          node{
            id
            name
          }
        }
      }
    }
  }  
}`;


//----- Supprimer une task
/*
 Exemple de variables
  {
    "id" : "/api/offer-tasks/1"
  }
*/
export const DELETE_TASK_PHASE_OFFER = gql`
mutation DeleteOfferTask($id: ID!){
	deleteOfferTask(input: {id:$id}){
    offerTask{
      id
    }
  }  
}`;



//----- Ajouter un pied de page 
/*
 Exemple de variables
  {
  "discountFixed": 99.90,
  "discountPercent": 5.5,
  "advancePayment": 99.90,
  "offer" : "/api/offers/1",
  "paymentTerm" : "/api/agency-payment-terms/1",
  "paymentDeadline" : "/api/agency-payment-deadlines/1",
  "comment" : "My comment here"
  }
*/
export const ADD_FOOTER_OFFER = gql`
mutation AddOfferFooter($discountFixed: String, $advancePayment: String, $discountPercent: String, $offer: String, $paymentTerm: String, $paymentDeadline: String, $comment: String){
	createOfferFooter(input: {discountFixed: $discountFixed, advancePayment: $advancePayment, discountPercent: $discountPercent, offer: $offer, paymentTerm: $paymentTerm, paymentDeadline: $paymentDeadline, comment: $comment}){
    offerFooter{
      id
      discountFixed
      discountPercent
      advancePayment
      comment
      paymentTerm{
        id
        name
      }
      paymentDeadline{
        id
        name
      }
    }
  }  
}`;


//----- Modifier un pied de page 
/*
 Exemple de variables

 
  {
  "id": "/api/offer-footers/3",
  "discountFixed": 99.90,
  "discountPercent": 5.5,
  "advancePayment": 999.90,
  "offer" : "/api/offers/1",
  "paymentTerm" : "/api/agency-payment-terms/1",
  "paymentDeadline" : "/api/agency-payment-deadlines/1",
  "comment" : "My comment here"
}
*/
export const UPDATE_FOOTER_OFFER = gql`
mutation UpdateOfferFooter($id: ID!, $discountFixed: String, $advancePayment: String, $discountPercent: String, $offer: String, $paymentTerm: String, $paymentDeadline: String, $comment: String){
	updateOfferFooter(input: {id: $id,  discountFixed: $discountFixed, advancePayment: $advancePayment, discountPercent: $discountPercent, offer: $offer, paymentTerm: $paymentTerm, paymentDeadline: $paymentDeadline, comment: $comment}){
    offerFooter{
      id
      discountFixed
      discountPercent
      advancePayment
      comment
      paymentTerm{
        id
        name
      }
      paymentDeadline{
        id
        name
      }
    }
  }  
}`;


//----- Supprimer un pied de page 
/*
 Exemple de variables
  {
    "id" : "/api/offer-footers/3"
  }
*/
export const DELETE_FOOTER_OFFER = gql`
mutation DeleteOfferFooter($id: ID!){
	deleteOfferFooter(input: {id:$id}){
    offerFooter{
      id
    }
  }  
}`;



//----- Ajouter un montant de TAXE à un OfferFooter
/*
 Exemple de variables
  {
  "offerFooter": "/api/offer-footers/3",
  "tax": "/api/agency-tax-rates/1",
  "amount": "12"
  }
*/
export const ADD_OFFER_FOOTER_TAX = gql`
mutation AddOfferFooterTax($amount: String, $tax: String, $offerFooter: String){
	createOfferFooterTax(input: {amount: $amount, tax: $tax, offerFooter: $offerFooter}){
    offerFooterTax{
      id
      amount
      tax {
        id
      }
    }
  }  
}`;


//----- Editer un montant de TAXE à un OfferFooter
/*
 Exemple de variables
  {
  "id": "/api/offer-footer-taxes/1",
  "offerFooter": "/api/offer-footers/3",
  "tax": "/api/agency-tax-rates/1",
  "amount": "14"
  }
*/
export const UPDATE_OFFER_FOOTER_TAX = gql`
mutation UpdateOfferFooterTax($id: ID!, $amount: String, $tax: String, $offerFooter: String){
	updateOfferFooterTax(input: {id:$id, amount: $amount, tax: $tax, offerFooter: $offerFooter}){
    offerFooterTax{
      id
      amount
      tax {
        id
      }
    }
  }  
}`;


//----- Supprimer un montant de TAXE lié à un OfferFooter 
/*
 Exemple de variables
  {
    "id" : "/api/offer-footer-taxes/3"
  }
*/
export const DELETE_OFFER_FOOTER_TAX = gql`
mutation DeleteOfferFooterTax($id: ID!){
	deleteOfferFooterTax(input: {id:$id}){
    offerFooterTax{
      id
    }
  }  
}`;