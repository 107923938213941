import { FormInputTypes } from '../../../shareable/types';

export default {
    name: 'Images Instagram',
    paddingTop: 0,
    paddingBottom: 0,
    inputs: {
        identifier: {
            label: 'Identifiant du profil :',
            type: FormInputTypes.TEXT,
            value: 'balenciaga'
        }
    }
};