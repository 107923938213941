import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../js/constants/action-types";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import OurButton from "../../ui/button/Button";
import { useState } from "react";
import TranslateIcon from '@material-ui/icons/Translate';
import colors from "../../../config/theme/colors";

const Translation = (props)=>{
    const {value,stateCallback,currentLang} = props
    const [loading,setLoading]= useState(false)

    const getTranslation = async  ()=>{
        setLoading(true)
        const key = process.env.REACT_APP_TRANSLATION_KEY;
        const target = currentLang;
        const q = encodeURI(value);
        try {
            let response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${key}&target=${target}&q=${q}`);
    
            response = await response.json();
    
            let translation = response?.data?.translations?.[0]?.translatedText;
    
            if (translation)                
                stateCallback(translation)
        } catch(e) {
            console.log(e?.message ?? e);
        } finally {
            setLoading(false)
        }
    }

    return (
        <OurButton bgcolor={'transparent'} bgcolorhover={"transparent"} style={{width:"10%",margin:0}} loading={loading} onClick={getTranslation}>
            <TranslateIcon style={{color:colors.green.regular,fontSize:20}}/>
        </OurButton>        
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Translation))));