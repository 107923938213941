import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getImageDefault, getDefaultTextarea, getDefaultButton, getDefaultText, getDefaultInput } from '../../helpers';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'Section principale',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA',
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },        
        backgroundImageTablet: {
          label: "Image de fond tablet",
          type: FormInputTypes.IMAGE,
          value: null,
        },
        backgroundImageMobile: {
          label: "Image de fond mobile",
          type: FormInputTypes.IMAGE,
          value: null,
        },
        displayForm:{
            type: FormInputTypes.SELECT,
            label: "Affichage du formulaire",
            value: 'show',
            params: [{label: 'Masquer', value: 'hidden'}, {label: 'Afficher', value: 'show'}],
        },
        displayLogo:{
            type: FormInputTypes.SELECT,
            label: "Affichage du logo",
            value: 'show',
            params: [{label: 'Masquer', value: 'hidden'}, {label: 'Afficher', value: 'show'}],
        },
        typeBlock:{
            type: FormInputTypes.SELECT,
            label: "Type du bloc",
            value: 'original',
            params: [{label: 'Bloc original', value: 'original'}, {label: 'Nouveau bloc', value: 'newer'}],
        },
        positionImage:{
            type: FormInputTypes.SELECT,
            label: "Position de l'image",
            value: 'right',
            params: [{label: 'Droite', value: 'right'}, {label: 'Gauche', value: 'left'}],
        },
        positionButton:{
            type: FormInputTypes.SELECT,
            label: "Position du bouton",
            value: 'align',
            params: [{label: 'En dessous', value: 'bottom'}, {label: 'Aligné au champ mail', value: 'align'}],
        }
    },
    blocks: {
        imageAbsolute: getImageDefault(null, 'Image absolute', null, 500, 'relative'),
        linkReglementText: getDefaultText("Texte du lien vers le réglement", "Règlement du jeu", 16, '#000000', null, 'initial'),
        contentTop:{
            id: uuidv4(),
            name: "Contenu du bloc",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: 'rgba(255,255,255,0.5)'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                positionLogo:{
                    label: 'Positionner le logo au dessus du bloc de contenu',
                    type: FormInputTypes.SWITCH,
                    value: false
                },
                paddingVertical: {
                    type: FormInputTypes.SLIDER,
                    value: 10,
                    params: {max: 100, min: 2, step: 2},
                    label: "Padding vertical",
                },
                paddingHorizontal: {
                    type: FormInputTypes.SLIDER,
                    value: 10,
                    params: {max: 100, min: 2, step: 2},
                    label: "Padding horizontal",
                },
            },
            blocks:{
                logo: getImageDefault(null, 'Logo du jeu', false, 500),
                title: getDefaultTextarea("Titre", "Grand Jeu Concours", true, '#53839D', 'rgba(255,255,255,0)', 'initial', null, 50),
                subtitle: getDefaultTextarea("Sous-titre", "Sous-titre", true, "#53839D", 'rgba(255,255,255,0)', 'initial', null, 25),
                labelEmail: getDefaultInput("Libellé de demande d'email", "", "Votre adresse email...", 20, '#53839D',null, null, null, null, 'disabled'),
                labelOtherInput: getDefaultInput("Libellé input optionnel", "", "...", 20, '#53839D', null, null, null, null, false),
                labelConsent: getDefaultInput("Libellé de consentement", "J'accepte le règlement du jeu", null, 20, '#53839D', 'rgba(255,255,255,0)', 'initial', null, null, 'disabled'),
                labelConsentOptionnal: getDefaultInput("Libellé de consentement optionnel", "Je souhaite recevoir les actualités", null, 20, '#53839D', 'rgba(255,255,255,0)', 'initial', null, null, false),
                button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 8, 12),
            }
        },
        popupEmail: {
            id: uuidv4(),
            name: "Popup email",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                use:{
                    label: "Utiliser la popup de validation de mail",
                    type: FormInputTypes.SELECT,
                    value: 'enable',
                    params: [{label: 'Activé', value: 'enable'}, {label: 'Désactivé', value: 'disable'}],
                },
                backgroundColor: {
                    label: 'Couleur de fond overlay',
                    type: FormInputTypes.COLOR,
                    value: 'rgba(0,0,0,0.2)'
                },
                backgroundImage: {
                    label: 'Image de fond overlay',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                backgroundColorContent: {
                    label: 'Couleur de fond contenu',
                    type: FormInputTypes.COLOR,
                    value: 'rgba(0,0,0,0.2)'
                },
                backgroundImageContent: {
                    label: 'Image de fond contenu',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
            blocks:{
                titlePopup: getDefaultText("Titre de la popup", "Bienvenue !", 49, "#FFFFFF"),
                descriptionPopup: getDefaultTextarea("Description de la popup", "Avant d’aller plus loin, surveillez votre boîte mail ! Vous allez bientôt recevoir un lien de confirmation qui vous permettra de jouer !", true, '#53839D', 'rgba(255,255,255,0)', 'initial', null, 50),
                imageLink: getImageDefault(null, 'Image popup'),
                urlImage: getDefaultText("Url redirection image", "", 16),
                buttonPopup: getDefaultButton("Boutton de la popup", "J'ai compris", 20, '#071242', '#FFFFFF', 1, 'rgba(255,255,255,1)', 0),
                titleExplicationPopup: getDefaultText("Explications", "Pourquoi confirmer l’adresse e-mail ?", 16, "#FFFFFF", 'rgba(255,255,255,0)'),
                explicationPopup: getDefaultText("Description des explications", "Nous avons besoin d’une adresse e-mail valide pour vous contacter en cas de victoire de votre part.", 16, "#FFFFFF", 'rgba(255,255,255,0)'),
            }
        },
        imageSide: getImageDefault(null, 'Image sur le côté'),
        copyright: getDefaultText("Copyright", "Copyright", 12, '#000000', null, 'initial'),
    }
};