import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { SET_BREADCRUMB } from "../../js/constants/action-types";
import PageLoader from '../ui/loadings/page-loader/PageLoader';

function LayerBreadCrumb(props) {
    useEffect(() => {
        props.setBreadcrumb(props.bc);
    });

    if (props.attributesCheck && !props.attributesLoaded)
        return <PageLoader style={{height: "auto", flex: 1}} messageLoading={`Chargement des attributs en cours`}/>;

    return props.children;
}

const mapStateToProps = state => {
    return {
        attributesLoaded: state.attributesLoaded,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (breadcrumb) => dispatch({ type: SET_BREADCRUMB, payload: { breadcrumb }}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerBreadCrumb);
