import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getOfferListText } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Devis',
    phases: [],
    footer: {
        id: null,
        discountFixed: 0,
        discountPercent: 0,
        advancePayment: 0,
        paymentTerm: null,
        paymentDeadline: null,
        comment: ''
    },
    inputs: {
        offerName: {
            label: 'Nom devis',
            type: FormInputTypes.TEXT,
            value: `Devis n° ${moment().format('YYYYMM')}XX`
        },
        offerDescription: {
            label: 'Description devis',
            type: FormInputTypes.TEXTAREA,
            value: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`
        },
        offerNumberPO: {
            label: 'Numéro PO',
            type: FormInputTypes.TEXT,
            value: 'N/A',
            hideIfModel: true
        },
        offerValidityDate: {
            label: 'Date de validité',
            type: FormInputTypes.DATE,
            value: moment().add(1, 'month').format('YYYY-MM-DD'),
            hideIfModel: true
        },
        offerRecurring: {
            label: 'Récurrent ?',
            type: FormInputTypes.SWITCH,
            value: false,
            conditionalInputs: {
                offerRecurringStartDate: {
                    label: 'Date de début',
                    type: FormInputTypes.DATE,
                    value: moment().format('YYYY-MM-DD')
                },
                offerRecurringInvoiceDay: {
                    label: 'Jour de facturation',
                    type: FormInputTypes.SELECT,
                    value: '5',
                    params: [{label: '05', value: '5'}, {label: '10', value: '10'}, {label: '15', value: '15'}]
                },
                offerRecurringDelay: {
                    label: 'Durée (en mois)',
                    type: FormInputTypes.NUMBER,
                    value: 1
                },
            }
        },
    },
    blocks: {}
};

