
import store from '../redux/store';
import {eventService} from '../services/event.service';

import {ALERT_ERROR} from '../constants/alert-types'
import {SNACK} from '../constants/action-types';

const snack = (type, message) => store.dispatch({ type: SNACK, payload: { type, message } }) ;
function isset(el){
    let isSet= typeof el !== 'undefined';
    return isSet;
}

const props = store.getState();

/**
 * Mets à jour la la valeur
 * @param {Object} states - States du composant
 * @param {string} stateName - Nom du state à mettre à jour
 * @param {Object} evt - Valeur de l'input
 * @param {Object} custom 
 * @param {boolean} translated - Indique si l'input peut être traduit
 * @param {Object} options - Options supplémentaires : {parent : 'NomDuStateParent'}
 * @returns - valeur par langue
 */
export const setValue = (states,stateName, evt, custom, translated,options) =>{
    const inputValue = evt?.target?.value ?? evt;
    let parent = options?.parent;
    let result = {};
    let lang = states.currentLang;
    if(parent)
        result[parent] = states[parent] ? states[parent] : {};
    
    if (translated) {
        let values = parent ?  result[parent][lang] : states[lang];
        if (!values) {
            values = {};
        }
        values[stateName] = inputValue;

        if(parent){
            result[parent][lang]=values;  
        }
        else{
            result[lang] = values;             
        }

    } else {

        if(parent){           
            
            result[parent][stateName] = inputValue;
        }
        else{
            result[stateName] = inputValue;
        }
    }
    return result;
}

export const hasError = (errors) =>{
    if (errors) {
        for (let error in errors) {
            if (errors[error])
                return true;
        }
    }

    return false;
}

export const handleStep = (type,errors) => {
    if (hasError(errors)) {
        snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
        eventService.fire();
        return false;
    }


    return true;
}

