import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import ColorSelector from '../components/ColorSelector';
import TypographySelector from '../components/TypographySelector';
import DocumentSelector from '../components/DocumentSelector';

const Guideline = {
    titleForm: "Créer une charte graphique",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer votre image de marque',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: TABS,
        finalStep:{},
        children:[
            {
                labelName: 'Identité',
                optionsInputs:[]
            },
            {
                labelName: 'Couleur',
                optionsInputs:[],
                component: ColorSelector,
            },
            {
                labelName: 'Typographie',
                optionsInputs:[],
                component: TypographySelector,
            },
            {
                labelName: 'Documents',
                optionsInputs:[],
                component: DocumentSelector,
            },
        ]
    }
}

export default Guideline;