import { gql } from 'apollo-boost';

export const GET_FORMAT_URLS = gql`
{
  formatUrls{
    edges{
      node{
        id
        delimiter
        asset{
          id
        }
        formatUrlAttributes{
          edges{
            node{
              position
              attribute{
                id
              }
            }
          }
        }
      }
    }
  }
}
`;

/*
{
    "id": "/api/format-urls/1"
}
 */
export const GET_FORMAT_URL = gql`
query getFormatUrl($id: ID!){
  formatUrl(id: $id){
    id
    delimiter
    asset{
      id
    }
    formatUrlAttributes{
      edges{
        node{
          position
          attribute{
            id
          }
        }
      }
    }
  }
}
`;

/*
{
    "asset": "/api/assets/1"
}
 */
export const GET_BY_ASSET_FORMAT_URL = gql`
query getFormatUrl($asset: String!){
  formatUrls(asset: $asset){
    edges{
      node{
        id
        delimiter
        asset{
          id
        }
        formatUrlAttributes{
          edges{
            node{
              id
              position
              attribute{
                id
              }
            }
          }
        }
      }
    }
  }
}
`;

/*
{
    "delimiter": "-",
    "asset": "/api/assets/1"
}
 */
export const CREATE_FORMAT_URL = gql`
mutation createFormatUrl($delimiter: String!, $asset: String!) {
  createFormatUrl(input:{asset: $asset, delimiter: $delimiter}){
    formatUrl{
      id
    }
  }
}
`;

/*
{
    "id": "/api/format-urls/1",
    "delimiter": "-",
    "asset": "/api/assets/1"
}
 */
export const UPDATE_FORMAT_URL = gql`
mutation updateFormatUrl($id: ID!, $delimiter: String, $asset: String) {
  updateFormatUrl(input:{id: $id, asset: $asset, delimiter: $delimiter}){
    formatUrl{
      id
    }
  }
}
`;

/*
{
    "id": "/api/format-urls/1"
}
 */
export const DELETE_FORMAT_URL = gql`
mutation deleteFormatUrl($id: ID!) {
  deleteFormatUrl(input:{id: $id}){
    formatUrl{
      id
    }
  }
}
`;

/*
{
    "formatUrl": "/api/format-urls/1",
    "attribute": "/api/attribute/1",
    "position": 1,
}
 */
export const ADD_FORMAT_URL_ATTRIBUTE = gql`
mutation createFormatUrlAttribute($position: Int, $formatUrl: String!, $attribute:String!) {
  createFormatUrlAttribut(input:{position: $position, formatUrl: $formatUrl, attribute: $attribute}) {
    formatUrlAttribut {
      id
      position
      formatUrl{
        id
      }
      attribute{
        id
      }
    }
  }
}
`;

/*
{
    "position": 1,
}
 */
export const UPDATE_FORMAT_URL_ATTRIBUTE = gql`
mutation updateFormatUrlAttribute($id: ID!, $position: Int) {
  updateFormatUrlAttribut(input:{id: $id, position: $position}) {
    formatUrlAttribut {
      id
      position
      formatUrl{
        id
      }
      attribute{
        id
      }
    }
  }
}
`;

/*
{
    "id": "/api/format-url-attributes/1"
}
 */
export const DELETE_FORMAT_URL_ATTRIBUTE = gql`
mutation deleteFormatUrlAttribute($id: ID!) {
  deleteFormatUrlAttribut(input:{id:$id}) {
    formatUrlAttribut {
      id
    }
  }
}
`;