import styled from 'styled-components';

export default styled.h3`
    color: ${props => props.color || '#000'};
    font-size: ${props => `${props.fontSize}px` || '49px'};
    line-height: 1.2;
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
`; 