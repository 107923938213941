import React, {  useState } from "react";
import Div from '../shared/div';
import H2 from "../shared/h2";
import styled from 'styled-components';
import ArrowRight from "../../../../../../../assets/pictos/arrow-up.svg"
import Down from "../../../../../../../assets/pictos/arrow-down.svg"
import { Box } from "@material-ui/core";

const AccordionButton = styled.button`
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : "rgba(240,246,234,1)"};
    color: ${props=>props.color};
    font-size:${props=>props.fontSize+ "px"};
    font-weight:bold;
    cursor: pointer;
    padding: 18px;
    box-sizing:border-box;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    display: flex;
    align-items: center;
    span{
        margin-right: 10px;
        svg{
            stroke-width:1px;
            stroke: ${props=>props.color};
            transform: rotate(180deg);
        }
    }
    &.active span svg{
        transform: rotate(0deg);
    }
`

const AccordionPanel = styled.div`
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
`

const CustomDiv = styled.div`
    padding: 20px 0;
    p{
        margin:0;
    }
`


const BlocAccordion = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    const onClick = (e)=>{
        e.target.classList.toggle("active");
        const panel = e.target.nextElementSibling;
        if (panel.style.maxHeight) {
            console.log(panel.style.maxHeight)
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }


    return (
        <Div id={data.inputs.identifiant?.value} className={data.inputs.className?.value} style={{position: 'relative',padding:`40px ${data.inputs.noPaddingHorizontal?.value ? 0:"20px"}`}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            {
                data.inputs.value.value ?
                <H2
                color={data.inputs.color?.value}
                fontSize={data.inputs.fontSize?.value}                        
                style={{
                    fontSize:data.inputs.fontSize?.value,
                    textAlign: data.inputs.textAlign?.value,      
                    paddingTop:data.inputs.paddingTop?.value,
                    paddingBottom:data.inputs.paddingBottom?.value,  
                    fontWeight:"bold"                  
                }}
                >{data.inputs.value.value}</H2>
                :null
            }
            {
                data.blocks.accordions.config.map((accordion,index)=>{
                    return (                        
                        <Box style={{
                            marginBottom:data.inputs.rowGap?.value
                        }} className="dxp-accordion">
                            <AccordionButton 
                                onClick={(e) => onClick(e)} 
                                backgroundColor={data.inputs?.backgroundColor?.value}
                                color={accordion.blocks.title?.inputs?.color.value}
                                fontSize={accordion.blocks.title?.inputs?.fontSize.value}
                                className="dxp-accordion-summary"
                            >
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={accordion.blocks.title?.inputs?.color.value} class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>:</svg></span>
                                {accordion.blocks.title?.inputs?.value.value}
                            </AccordionButton>
                            <AccordionPanel className="dxp-accordion-detail">
                                <CustomDiv dangerouslySetInnerHTML={{__html:accordion.blocks.text?.inputs?.content?.value }}/> 
                            </AccordionPanel>                            
                        </Box>
                    )
                })
            }
        </Div>
    );
};

export default BlocAccordion;
