import colors from '../../config/theme/colors';

/* IMAGES */
import PptImage from '../../assets/pictos/file-types/picto_ppt.png';
import PngImage from '../../assets/pictos/file-types/picto_png.png';
import JpgImage from '../../assets/pictos/file-types/picto_jpg.png';
import GifImage from '../../assets/pictos/file-types/picto_gif.png';
import PdfImage from '../../assets/pictos/file-types/picto_pdf.png';
import CsvImage from '../../assets/pictos/file-types/picto_csv.png';
import DocImage from '../../assets/pictos/file-types/picto_doc.png';
import XlsImage from '../../assets/pictos/file-types/picto_xls.png';
import Mp4Image from '../../assets/pictos/file-types/picto_mp4.png';
import Mp3Image from '../../assets/pictos/file-types/picto_mp4.png';
import SvgImage from '../../assets/pictos/file-types/picto_svg.png';
import WebMImage from '../../assets/pictos/file-types/picto_webm.png';
import MpegImage from '../../assets/pictos/file-types/picto_mpeg.png';
import NotFoundImage from '../../assets/pictos/file-types/not-found.png';

/* ICONS */
import PptIcon from '../../assets/pictos/file-types-icons/ppt.svg';
import CsvIcon from '../../assets/pictos/file-types-icons/csv.svg';
import DocIcon from '../../assets/pictos/file-types-icons/doc.svg';
import GifIcon from '../../assets/pictos/file-types-icons/gif.svg';
import JpgIcon from '../../assets/pictos/file-types-icons/jpg.svg';
import Mp4Icon from '../../assets/pictos/file-types-icons/mp4.svg';
import MpegIcon from '../../assets/pictos/file-types-icons/mpeg.svg';
import PdfIcon from '../../assets/pictos/file-types-icons/pdf.svg';
import PngIcon from '../../assets/pictos/file-types-icons/png.svg';
import SvgIcon from '../../assets/pictos/file-types-icons/svg.svg';
import WebmIcon from '../../assets/pictos/file-types-icons/webm.svg';
import XIcon from '../../assets/pictos/file-types-icons/X.svg';
import XlsIcon from '../../assets/pictos/file-types-icons/xsl.svg';

export const getFileConfig = (type, show) => {
    switch (type) {
        case "audio/mpeg":
            return {
                image: Mp3Image,
                icon: PngIcon,
                color: colors.blue.darker.hue300
            }
        case "image/png":
            return {
                image: show && PngImage,
                icon: PngIcon,
                color: colors.blue.darker.hue300
            }
        case "image/svg+xml":
            return {
                image: show && SvgImage,
                icon: SvgIcon,
                color: colors.blue.darker.hue300
            }
        case "image/gif":
            return {
                image: show && GifImage,
                icon: GifIcon,
                color: colors.blue.darker.hue300
            }
        case "image/jpeg":
        case "image/jpg":
            return {
                image: show && JpgImage,
                icon: JpgIcon,
                color: colors.blue.darker.hue300
            }
        case "text/csv":
        case "csv":
            return {
                image: CsvImage,
                icon: CsvIcon,
                color: colors.red.darker
            }
        case "application/vnd.ms-excel":
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.oasis.opendocument.spreadsheet":
            return {
                image: XlsImage,
                icon: XlsIcon,
                color: colors.red.darker
            }
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/vnd.oasis.opendocument.text":
            return {
                image: DocImage,
                icon: DocIcon,
                color: colors.red.darker
            }
        case "application/vnd.oasis.opendocument.presentation":
        case "application/vnd.ms-powerpoint":
            return {
                image: PptImage,
                icon: PptIcon,
                color: colors.red.darker
            }
        case "application/pdf":
            return {
                image: PdfImage,
                icon: PdfIcon,
                color: colors.red.darker
            }
        case "video/mp4":
            return {
                image: Mp4Image,
                icon: Mp4Icon,
                color: colors.grey.regular
            }
        case "video/webm":
            return {
                image: WebMImage,
                icon: WebmIcon,
                color: colors.grey.regular
            }
        case "video/mpeg":
            return {
                image: MpegImage,
                icon: MpegIcon,
                color: colors.grey.regular
            }
        default:
            return {
                image: NotFoundImage,
                icon: XIcon,
                color: colors.black.regular
            }
    }
}