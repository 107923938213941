import { gql } from 'apollo-boost';

export const GET_USERRESTAURANTS = gql`
{
  userRestaurants{
    edges{
      node{
        id
        email
        username
        roles
        restaurant{
          id
        }
      }
    }
  }
}
`;

export const GET_USERRESTAURANTS_EMAILS = gql`
{
  userRestaurants{
    edges{
      node{
        email
      }
    }
  }
}
`;

/*
{
  "email": "testmail@mail",
  "password": "password",
  "restaurant": "/api/restaurants/2",
  "roles": []
}
 */
export const CREATE_USERRESTAURANT = gql`
mutation CreateUserRestaurant($email: String!, $password: String!, $restaurant: String!, $roles: Iterable!, $name: String!, $firstname: String!, $state: String!){
  createUserRestaurant(input:{email: $email, password: $password, restaurant: $restaurant, roles: $roles, name: $name, firstname: $firstname, state: $state}){
    userRestaurant{
      email
      roles
      restaurant{
        id
      }
    }
  }
}
`;

/*
{
  "id": "/api/user_restaurants/1",
  "email": "testmail@mail2",
  "password": "password",
  "restaurant": "/api/restaurants/2",
  "roles": []
}
 */
export const UPDATE_USERRESTAURANT = gql`
mutation UpdateUserRestaurant($id: ID!,  $name: String, $firstname: String, $state: String, $email: String, $password: String, $roles: Iterable){
  updateUserRestaurant(input:{id: $id, email: $email, password: $password, roles: $roles, name: $name, firstname: $firstname, state: $state}){
    userRestaurant{
      id
      email
      roles
      restaurant{
        id
      }
    }
  }
}
`;

/*
{
  "id": "/api/user_restaurants/1"
}
 */
export const DELETE_USERRESTAURANT = gql`
mutation UpdateUserRestaurant($id: ID!){
  deleteUserRestaurant(input:{id: $id}){
    userRestaurant{
      id
    }
  }
}
`;