import { Box, Grid } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { SNACK } from '../../../js/constants/action-types';
import { connect } from "react-redux";
import colors from "../../../config/theme/colors";
import PageLoader from "../../ui/loadings/page-loader/PageLoader";

const CarouselContainer = styled(Grid)`
     * {
      outline: none !important;
    } 
    .slick-slider{
        width:100%;
         margin: 0 -10px;
    }    
    .slick-slide {        
        outline: none;
        >div{
          padding: 0 5px;
        }
    }
    .slick-list {        
      margin: 0 -5px;
  }
    .slick-track{
      margin-left:0;
    }
`

const useStyles = makeStyles((theme) => ({
  arrow: {
    display: 'block',
    zIndex: 2,
    fill: 'black',
    position: "absolute",
    backgroundColor: 'white',
    border: "0.5px solid black",
    borderRadius: "50%",
    width: '30px',
    height: '30px',
    opacity: '1',
    "&:hover": {
      opacity: '1 !important',
      fill: 'black',
      backgroundColor: 'white',
    }
  },
  right: {
    right: '-20px'
  },
  left: {
    left: '-20px'
  }
}));


const CarouselCards = props => {
  const [isStart, setIsStart] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const [slidesNb, setSlideNb] = useState(null)
  const firstCarouselRef = useRef(null)
  const classes = useStyles();
  const [isLoading, setIsloading] = useState(true)

  const SampleArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      props.value === 'next' ?
        <ChevronRightIcon onClick={onClick} className={[classes.arrow, classes.right, className]} style={{ ...style, display: isEnd ? "none" : "block" }} />
        : <ChevronLeftIcon onClick={onClick} className={[classes.arrow, classes.left, className]} style={{ ...style, display: isStart ? "none" : "block" }} />
    );
  }

  const checkIndex = (i) => {
    if (i === 0) {
      setIsStart(true)
      setIsEnd(false)
    }
    if (i !== 0) {
      setIsStart(false)
    }
    if (i === props.children.length - firstCarouselRef?.current.props.slidesToShow) {
      setIsEnd(true)
    }
  }

  useEffect(()=>{
    setIsloading(true)
    if (props.slidesNb) {
      setTimeout(()=>{
        setSlideNb(props.slidesNb)
        setIsloading(false)
      },500)
    }
  },[props.slidesNb])

  const settings = {
    infinite: false,
    dots: false,
    slidesToShow: slidesNb ? slidesNb : 3.5,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    swipe: true,
    nextArrow: <SampleArrow value={'next'} />,
    prevArrow: <SampleArrow value={'prev'} />,
    afterChange: (index) => { checkIndex(index) },
  };

  return (
    <CarouselContainer container style={{ position: "relative" }} isScrap={props.isScrapNew}>
      {
        isLoading?
        <PageLoader/>
        :<Slider style={{ width: '100%' }} ref={firstCarouselRef} {...settings}>
          {props.children}
        </Slider>

      }
    </CarouselContainer>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
  };
};

export default connect(null, mapDispatchToProps)(CarouselCards);