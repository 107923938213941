import React, { useState, useRef } from "react";
import H1 from '../../../../shared/components/CustomH1';
import Swiper from "react-id-swiper";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { LinkCustom } from "../_next/navigation";

const Category = ({
    preview = false,
    id = null, 
    data = null,
    inputCallback = null,
    children = null,
    categories = [],
    spread = false
}) => {
    const [hover, setHover] = useState(false);
    const [update, setUpdate] = useState(0);
    const ref = useRef(null);

    let swiperParams = {
        loop: false,
        speed: 750,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        breakpoints: {
            260: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
            1400: {
                slidesPerView: 5,
            }
        },
        spaceBetween: 20,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        shouldSwiperUpdate: true,
        pagination: false,
    };

    const styles = {
        backgroundColor: '#212121',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    };

    let slides = data.blocks["slides"].config;

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();

            if (typeof Math !== "undefined")
                setUpdate(Math.random());
        }
    };
    
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();

            if (typeof Math !== "undefined")
                setUpdate(Math.random());
        }
    };

    let color = data.blocks['slides'].inputs?.color?.value ?? '#212121';

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="container categories">
                <div className="row">
                    <div className="col-12 title">  
                        <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                    </div>
                    <div className="categories-container">
                        <Swiper {...swiperParams} ref={ref}>
                            {slides && slides.map((element, key) => {
                                let categoryId = +element.inputs['category']?.value?.match(/\d+/)[0];

                                let titleCat = null;
                                let imgCat = null;
                                // let linkCategory = null;

                                let category = categories.find(category => category.id === categoryId);

                                if (category) {
                                    titleCat = category.attributes.category_name?.fr_FR;
                                    imgCat = `${process.env.REACT_APP_MEDIAS}/${category.attributes.category_image?.fr_FR}`;
                                    // linkCategory = `${process.env.REACT_APP_MEDIAS}/category/${category.id}`;
                                }

                                return (  
                                    <div className="category" key={`slide-${key}`}>
                                        <LinkCustom 
                                            spread={spread}
                                            href="/category/[slug]" 
                                            as={`/category/${categoryId}`}
                                            >
                                            <a>
                                                <div className="category-content">
                                                    <p  block={'title_category'}>{titleCat ? titleCat : "Titre catégorie"}</p>
                                                    <img src={imgCat ? imgCat : "https://via.placeholder.com/200x250"} alt="first" />
                                                </div>
                                            </a>
                                        </LinkCustom>
                                    </div>
                                );
                            })}
                        </Swiper>

                        <div className="pagination">
                            {!ref.current?.swiper.isBeginning && (
                                <div className="prev" onClick={goPrev}>
                                    <ChevronLeftIcon style={{ fontSize: 40, color: color }} />
                                </div>
                            )
                            }
                            {!ref.current?.swiper.isEnd && (
                                <div className="next" onClick={goNext}>
                                    <ChevronRightIcon style={{ fontSize: 40, color: color }} />
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;