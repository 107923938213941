import * as pagination from './pagination';
import * as sortBy from './sortingBy';

/**
 * @param {Object} states - Les states du composant
 * @param {string} view - View choisie : table,cards
 * @returns {Object} - Views mise à jour
 */
export function changeViews(states,view){
    return new Promise((resolve,reject)=>{
        let views = states.views;
        views['current'] = view;
        resolve(views)
    })
}

export {pagination,sortBy};

/**
 * @param {Object} result - Resultats de la Query
 * @param {string} listNaming - Nom de la future liste d'items
 * @param {Object} state - States du composant
 * @param {Object} naming - Tableau des noms de states à changer 
 * @returns {Object} - Resultats de la query formatés
 */
export const handleResult = (result,listNaming,state,naming={},type = "edges",identifier)=>{
    return new Promise(async(resolve,reject)=>{
        let obj={};

        let newPagination = await pagination.updatePaginationData(state.pagination,result,type,identifier);

        
        obj[naming.pagination ? naming.pagination : 'pagination'] = newPagination;
        if (type === "edges"){
            obj[listNaming] = state.searchActive  && state.noResult ? null : result?.edges;
            obj[naming.noResult ? naming.noResult : 'noResult'] =state.searchActive  && state.noResult ? true :  result?.edges.length > 0 ? false : true;        
        }else{
            obj[listNaming] = state.searchActive  && state.noResult ? null : result.collection;
            obj[naming.noResult ? naming.noResult : 'noResult'] =state.searchActive  && state.noResult ? true :  result.collection.length > 0 ? false : true;
        }
        obj[naming.ready ? naming.ready : 'ready'] = true;

        resolve(obj);
    })
}

export const initQuery = (state,customVar,type,typeResult = "edges",naming) => {
    return new Promise(async(resolve,reject)=>{
        let paginationData = await pagination.paginationQueryData(state.pagination, typeResult);
        let obj = {
            queryData : {},
            states : {}
        };
        obj.queryData = {...customVar,...paginationData};
        obj.states[naming?.ready ? naming?.ready : 'ready'] = false;

        let result = obj[type];
        resolve(result);
    })
}

