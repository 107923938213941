import { useQuery } from "react-query";
import { GET_CATALOGS } from ".";
import { GET_ASSETS_BY_TYPES, GET_ASSETS_BY_TYPE_CATEGORIES_CATALOG_LIGHT, GET_ASSET_TYPES } from "../assets";
import _request from "../_request";

export function fetchAssetsTypes(client) {
    return client.fetchQuery(
        "assetsTypes",
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ASSET_TYPES,
            );
        }
    );
}

export function fetchAssetsByTypeCategoriesCatalog(client, assetType_list) {
    return client.fetchQuery(
        ["fetchAssetsByTypeCategoriesCatalog", assetType_list],
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ASSETS_BY_TYPES,
                { assetType_list }
            );
        }
    );
}