import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Link from 'next/link';
import RibbonBottomRight from './image/ruban-3.png';
import HowPNG from './image/how.png';
import Tag1PNG from './image/tag-01.png';
import Tag2PNG from './image/tag-02.png';
import Tag3PNG from './image/tag-03.png';
import Pg from "../../../../shared/components/CustomParagraph";


const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const PostCardBook = (props) => {
    let {
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        fullWidth = false,
        noButton = false,
        spread = false,
        preview = false,
    } = props; 

    let image = data?.blocks?.imageHow?.inputs.value.value;

    return (
        <>
            <div className="postCardWrapper" style={{padding: fullWidth ? 0 : null, background: fullWidth ? 'white': null}}>
                { !preview && props.children}
                <div className="container" style={{top: fullWidth ? 0 : null, maxWidth: fullWidth ? 'none' : null}}>
                    <div className="cardWrapper">
                        <div className="regles">
                            <div>
                                <img src={image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : HowPNG} alt="Comment jouer ?" />
                            </div>
                            <div className="list_regles">
                                <div className="regle" id="rule-0">
                                    <img className="img_center" src={Tag1PNG} alt="Règle 1" />
                                    <div className="description">
                                        <div>
                                            {
                                                props.lang === "es_ES" ?
                                                <p>
                                                    <p><strong>Crea tu lista de regalos LEGO®</strong></p>
                                                    <p>desde nuestro catálogo online.</p>
                                                </p>
                                                : 
                                                <Pg data={data} preview={preview} component={id} block={'rule1'} inputCallback={inputCallback} nopadding={true}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="regle" id="rule-1">
                                    <img className="img_center" src={Tag2PNG} alt="Règle 2" />
                                    <div className="description">
                                        <div>
                                            {
                                                props.lang === "es_ES" ?
                                                <p>
                                                    <p><strong>Registra tu correo electrónico</strong></p>
                                                    <p>para confirmar tu inscripción al sorteo.</p>
                                                </p>
                                                : 
                                                <Pg data={data} preview={preview} component={id} block={'rule2'} inputCallback={inputCallback} nopadding={true}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="regle" id="rule-2">
                                    <img className="img_center" src={Tag3PNG} alt="Règle 3" />
                                    <div className="description">
                                        <div>
                                            {
                                                props.lang === "es_ES" ?
                                                <p>
                                                    <p><strong>Te avisaremos por correo del resultado.</strong></p>
                                                    <p>¡ Buena suerte !</p>
                                                </p>
                                                : 
                                                <Pg data={data} preview={preview} component={id} block={'rule3'} inputCallback={inputCallback} nopadding={true}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !noButton ? (
                            <div className="wrapper_button">
                                <LinkCustom spread={spread} href="/catalog">
                                    <div className="container_button">
                                        <a className="button_lego">{data.blocks.button?.inputs.value.value}</a>
                                    </div>
                                </LinkCustom>
                            </div>
                        ) : null
                    }
                    <Pg className="copyright" data={data} preview={preview} component={id} block={'copyright'} inputCallback={inputCallback} nopadding={true} style={{color: fullWidth ? 'black' : null}}/>
                    {/* <div className="copyright" style={{color: fullWidth ? 'black' : null}}>{data.blocks.copyright.inputs.value.value}</div> */}
                </div>
                <div className="ribbon_bottom_right" style={{display: fullWidth ? 'none' : null}}>
                    <picture>
                        <source srcSet={require("./image/Masque-ruban-03.webp")} type="image/webp" />
                        <source srcSet={RibbonBottomRight} type="image/png" />
                        <img src={RibbonBottomRight} alt="Ruban décoratif en bas à droite"/>
                    </picture>
                </div>
            </div>
        </>
    );
};

PostCardBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default PostCardBook;
