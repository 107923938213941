import { FormInputTypes, MenuTypes } from '../../../shareable/types';

export { default as OfferSectionTitle } from './_offerSectionTitle.config';
export { default as OfferFrontPage } from './_offerFrontPage.config';
export { default as OfferAboutSlide } from './_offerAboutSlide.config';
export { default as OfferTripleBlockList } from './_offerTripleBlockList.config';
export { default as OfferTripleBlock } from './_offerTripleBlock.config';
export { default as OfferTripleBlockText } from './_offerTripleBlockText.config';
export { default as OfferTextWithImage } from './_offerTextWithImage.config';
export { default as OfferTextWithImagesSimple } from './_offerTextWithImagesSimple.config';
export { default as OfferDevis } from './_offerDevis.config';

export const MinisiteConfig = {
    logo: {
        id: "main-logo",
        name: "Logo",
        type: FormInputTypes.IMAGE,
        value: "https://via.placeholder.com/200x100"
    },
    moto: {
        id: "moto",
        name: "Slogan",
        type: FormInputTypes.TEXTAREA,
        value: "Lorem ipsum"
    },
    copyright: {
        id: "copyright",
        name: "Copyright",
        type: FormInputTypes.TEXT,
        value: "© 2021 [Votre entreprise]. All Rights Reserved."
    },
    menu: {
        id: "header-menu",
        name: "Menu",
        type: FormInputTypes.MENU,
        value: [
            { 
                type: MenuTypes.LINK,
                readonly: true,
                id: "home",
                name: "Accueil",
                value: "/"
            },
            {
                type: MenuTypes.LINK,
                readonly: true,
                id: "contact",
                name: "Contact",
                value: "/contact"
            }
        ]
    }
};