import { getDefaultTextSimple, getDefaultTextarea, getDefaultButton } from '../../helpers';
import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Texte image",
    blocks: {
        media: {
            title: "Lecteur youtube ou vidéo",
            name: "Lecteur youtube ou vidéo",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                switch: {
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                image: {
                    label: "Importer une vidéo",
                    type: FormInputTypes.IMAGE,
                },
                video: {
                    label: "Lien youtube",
                    type: FormInputTypes.TEXT,
                    value: "https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
                }
            }
        },
        paragraph_1: getDefaultTextarea("Description 1", "Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum. Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.Donec laoreet orci eget nulla consectetur, vel accumsan dui fermentum.", null, "#433f3f", null, null, 'center', 16),
    }
};