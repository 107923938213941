import colors from '../../../../../config/theme/colors';
import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formBundle(type='add',handleMediaPicker,state,allGroups,currentLang,variantsValidated,isVariant,categories,categoriesSelected,customAttributes,mediaCategories,selectedMediaCategories,imageAttributes,metaAttributes) {      
    let typeInput = '';
    let isMediaCategory = false
    let attributeSetup = attribute => {
        let isEmail = false;
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': 
            case 'decimal': 
                typeInput = 'decimal';
                break;
            case 'text':
                typeInput = 'text';
                break;
            case 'mail':
                typeInput = 'text';
                isEmail = true;
                break;
            case 'link':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case "media_category":
                typeInput = 'selectTree';
                // isMediaCategory = true
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            case 'date':
                typeInput = 'date';
                break;
            default: typeInput = null;
        }
        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: false,
            handleMediaPicker:typeInput === 'mediaPicker' || typeInput === 'file' ? handleMediaPicker:null,
            helper: {
                text: '',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: typeInput === "mediaPicker" && isVariant ? false : attribute.node.isRequired,
            stateName: attribute.node.identifier === "media_category"?"selectedMediaCategories":attribute.node.identifier,
            email: isEmail,
            valueWithFlags: false,
            bottom:true,
            value:typeInput=== 'select' ? 
             attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                });
            }):state[attribute.node.identifier],
            data:typeInput === 'selectTree'? (() => {
                let data = [];
                
                if (mediaCategories?.length > 0)
                    data = mediaCategories.filter(e => e.node.parent === null && e.node.libelle === 'Root').map(e => e.node);
                    data.unshift({id:null,checked:false,children:[],expanded:false,label:"Réinitialiser",value:null,libelle:"Réinitialiser"})
                let populateChildren = (cats, parent) => {
                    let catSelected = selectedMediaCategories
                    parent.value  = parent.id;
                    parent.label  = parent.libelle === 'Root' ? '/' : parent.libelle;
                    parent.disabled = false;
                    parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.id).map(e => e.node);
                    parent.expanded         = true;
                    parent.checked   = parent.id ? catSelected === parent.id:false;

                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(mediaCategories, parent);
                return data;
            })():null
        });
    };
    let customInputs    = customAttributes.filter(e => e.node.attributeType.input !== 'image' && e.node.status).concat(metaAttributes).map(attributeSetup);
    let imageInputs     = imageAttributes.map(attributeSetup);

    let steps = [
        {
            labelName: 'Informations générales',
            isOptionnal: false,
            optionsInputs:[
                // {
                //     type: 'mediaPicker',
                //     label: 'Image',
                //     helper: {
                //         text: 'Image du bundle',
                //         link: false,
                //     },
                //     handleMediaPicker: handleMediaPicker,
                //     required: false,
                //     stateName:'bundleImage',
                // },
                {
                    type: 'select',
                    label: `Groupe d'attributs`,
                    helper: {
                        text: variantsValidated && isVariant ? `Une variante produit est déjà utlisée vous ne pouvez changer de groupe d'attributs` : `Indiquez le groupe d'attributs`,
                        link: false,
                    },
                    translated: false,
                    required: true,
                    disabled: variantsValidated && isVariant ? true : false,
                    stateName: 'groupAttribut',
                    value: allGroups.map(group => {
                        const langSelectedValuesDefault = group.node.translation.translationDatas.edges[0];
        
                        const langSelectedValues = group.node.translation.translationDatas.edges.find(
                            lang  => lang.node.locale.code === currentLang
                        );
        
                        return ({
                            value: group.node.id,
                            label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node.value ?? group.node.identifier
                        });
                    })
                },
                {
                    type: 'text',
                    label: 'Nom du bundle',
                    helper: {
                        text: 'Indiquez le nom du bundle',
                        link: false,
                    },
                    required: true,
                    stateName: 'bundleName',
                    disabled: false,
                },
                {
                    type: 'text',
                    label: 'SKU global',
                    helper: {
                        text: 'SKU',
                        link: false,
                    },
                    required: true,
                    stateName: 'bundleSku',
                    disabled: type === "add"?false: true,
                },
                {
                    type: 'number',
                    label: 'Prix global',
                    helper: {
                        text: 'Indiquez le prix',
                        link: false,
                    },
                    required: true,
                    stateName: 'bundlePrice',
                    disabled: false,
                    min:0
                },
                {
                    type: 'selectTree',
                    label: 'Catégories',
                    translated: false,
                    helper: {
                        text: 'Modifier les catégories du produit',
                        link: false,
                    },
                    required: false,
                    stateName: 'categoriesSelected',
                    multiselect: true,
                    bottom:true,
                    data: (() => {
                        let data = state.categories.filter(e => e.node.parent === null);
                        let populateChildren = (cats, parent) => {
                            let catSelected = categoriesSelected.find(e => e.id === parent.node.category.id);
            
                            let finalValue = parent.node.name;
                            parent.value    = parent.node.category.id;
                            parent.id    = parent.node.category.id;
                            parent.label    = finalValue;
                            parent.checked  = catSelected ? true : false;
                            parent.expanded = true;
                            parent.categoryStaticId = parent.node.categoryStaticId
                            parent.children = cats.filter(e => e.node.parent !== null && e.node.parent.category.id === parent.node.category.id);
            
                            for (let child of parent.children)
                                populateChildren(cats, child);
                        };
            
                        for (let parent of data)
                            populateChildren(categories, parent);
            
                        return data;
                    })()
                    // data: (() => {
                    //     let data = state.categories.filter(e => e.parent === null && e.catalog === null);
                    //     let populateChildren = (cats, parent) => {
                    //         let catSelected = state.categoriesSelected.find(e => e?.id === parent.id);
            
                    //         let allNames    = parent.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');
                    //         const defaultValue  = allNames.find(e => e.node.locale.code === currentLang);
                    //         const value         = allNames.find(e => e.node.locale.code === currentLang);
                    //         let finalValue = value?.node.value ?? defaultValue?.node.value ?? parent.libelle;
            
                    //         parent.value    = parent.id;
                    //         parent.label    = finalValue;
                    //         parent.checked  = catSelected ? true : false;
                    //         parent.expanded = parent.id === '/api/categories/1'?true:false;
                    //         parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
            
                    //         for (let child of parent.children)
                    //             populateChildren(cats, child);
                    //     };
            
                    //     for (let parent of data)
                    //         populateChildren(state.categories, parent);
        
                    //     return data;
                    // })()
                },
                {                    
                    type: 'textarea',
                    label: 'Description',
                    helper: {
                        text: '',
                        link: false,
                    },
                    required: false,
                    stateName: 'bundleDesc',
                },
                // {
                //     type: 'text',
                //     label: 'Url du site de la marque',
                //     helper: {
                //         text: 'Indiquez l\'URL du site de la marque',
                //         link: false,
                //     },
                //     required: true,
                //     stateName: 'brandLink',
                //     disabled: false,
                // },
                {                    
                    type: 'switch',
                    label: 'Actif',
                    helper: {
                        label: 'État',
                        text: 'Indiquez l\'état',
                        link: false,
                    },
                    required: true,
                    stateName:'isActive',
                    value: [
                        {
                            value: true,
                            label: 'Actif',
                        },
                        {
                            value: false,
                            label: 'Inactif',
                        },
                    ]
                },
            ]
        },
        {
            labelName: 'Médias',
            isOptionnal: false,
            optionsInputs: imageInputs
        },
        {
            labelName: 'Attributs',
            isOptionnal: false,
            currentLang:currentLang,
            optionsInputs:customInputs
        },
        {
            labelName: 'Ajout produits',
            isOptionnal: false,
            currentLang:currentLang,
            optionsInputs:[
                {
                    type: 'productsSelection',
                    helper: {
                        text: 'Image du bundle',
                        link: false,
                    },
                    required: false,
                    stateName:'bundleProducts'                    
                },
            ]
        },
        {
            labelName: 'Configurations',
            isOptionnal: false,
            currentLang:currentLang,
            value:true,
            optionsInputs:[
                {
                    type: 'bundleConfiguration',
                    helper: {
                        text: 'Image du bundle',
                        link: false,
                    },
                    required: false,
                    stateName:'bundleConfig',
                    defaultValue:true,
                    value:[
                        {
                            value:true,
                            label:"Oui",
                        },
                        {
                            value:false,
                            label:"Non",
                        }
                    ]
                },
            ]
        }
        // {
        //     labelName: 'Gestion des écarts',
        //     isOptionnal: false,
        //     optionsInputs:[
        //         {
        //             type: 'rows',
        //             translated: false,
        //             required: true,
        //             stateName:'brandRows',
        //             autoLabels :{
        //                 show:true,
        //                 textStart : "Site référent "
        //             },
        //             helper: {
        //                 text: 'Indiquez le nom de la marque',
        //                 link: false,
        //             },                    
        //             dictionary: false,
        //             separator:true,
        //             simpleField:true,
        //             stateForCompare : 'oneBrand'
        //             // dictionarySelect: currentTypeInput?.node.input === 'select' ? true : false,
        //         },
        //         {                    
        //             titleSection: 'Réglage des alertes',
        //             type: 'number',
        //             label: 'Écart négatif',
        //             helper: {
        //                 text: 'Mon produit est - cher',
        //                 link: false,
        //             },                    
        //             required: false,
        //             stateName: 'brandEcartNegative',
        //             doubleInput: true,                    
        //             max:0,
        //             suffix : "%"
        //         },
        //         {
        //             type: 'number',
        //             label: 'Écart positif',
        //             helper: {
        //                 text: 'Mon produit est + cher',
        //                 link: false,
        //             },                    
        //             required: false,
        //             stateName: 'brandEcartPositive',
        //             disabled: false,
        //             doubleInput: true,
        //             min:0,
        //             max:100,
        //             suffix : "%"
        //         },
        //         {                    
        //             type: 'switch',
        //             label: 'Alerte activé',
        //             helper: {
        //                 label: 'État',
        //                 text: 'Indiquez l\'état',
        //                 link: false,
        //             },
        //             required: true,
        //             stateName:'isActiveAlert',
        //             value: [
        //                 {
        //                     value: true,
        //                     label: 'Oui',
        //                 },
        //                 {
        //                     value: false,
        //                     label: 'Non',
        //                 },
        //             ]
        //         },
        //     ]                
        // },
        // {
        //     labelName: 'Gestion du scrapping',
        //     isOptionnal: false,
        //     optionsInputs:[
        //         {
        //             type: 'mapper',
        //             translated: false,
        //             required: true,
        //             stateName: "mapOn",                    
        //             helper: {
        //                 text: 'Indiquez le nom de la marque',
        //                 link: false,
        //             },         
        //             mapOn:"brandRows",           
        //             dictionary: false,                    
        //             mapperType : 'brand',
        //             // dictionarySelect: currentTypeInput?.node.input === 'select' ? true : false,
        //         },
        //     ],
        // },
    ]
    var obj = {
        titleForm: type === "add" ? 'Créer un bundle' : 'Modifier un bundle',
        subTitleForm: type === "add" ? 'Veuillez compléter les champs ci-dessous pour créer votre bundle' : 'Veuillez modifier les champs ci-dessous pour éditer votre bundle',
        // langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Action réussi.',
                subtitle: 'Vous pouvez enregistrer le bundle' ,
                textButton: 'Enregistrer le bundle',
                backgroundColor: colors.green.lighter,
                border:`1px solid ${colors.green.regular}`
            },
            children: steps
        }
    };

    return obj;
}