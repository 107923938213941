import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideTop } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider home',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
        height:{
            label: "Hauteur de l'image",
            type: FormInputTypes.SLIDER,
            value: 0,
            id: uuidv4(),
            params: {max: 1000, min: 0, step: 10}
        },
        autoMove:{
            label: 'Mouvement automatique',
            type: FormInputTypes.SWITCH,
            value: false,
            id: uuidv4(),
        },
        delay:{
            label: 'Durée du mouvement automatique (en seconde)',
            type: FormInputTypes.SLIDER,
            value: 0,
            params: {max: 5, min: 0, step: 0.1}
        },
    },
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER,
            buttonAdd: 'une slide',
            limit: 3,
            config: [ 
                getDefaultSlideTop()
            ]
        }
    }
};