import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault, getDefaultTextarea, getDefaultButton, getDefaultText, getDefaultInput } from '../../helpers';

export default {
    name: 'Section principale',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA',
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        displayLogo:{
            type: FormInputTypes.SELECT,
            label: "Affichage du logo",
            value: 'show',
            params: [{label: 'Masquer', value: 'hidden'}, {label: 'Afficher', value: 'show'}],
        },
    },
    blocks: {
        logo: getImageDefault(),
        linkReglementText: getDefaultText("Texte du lien vers le réglement", "Règlement du jeu", 16, '#000000', null, 'initial'),
        title: getDefaultTextarea("Titre", "Grand Jeu Concours", true, '#53839D', 'rgba(255,255,255,0)', 'initial', null, 50),
        subtitle: getDefaultTextarea("Sous-titre", "Sous-titre", true, "#53839D", 'rgba(255,255,255,0)', 'initial', null, 25),
        button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
        labelInput1: getDefaultInput("Paramétrage input 1", "", "Entrez votre nom", 20, '#53839D',null, null, null, null, 'disabled'),
        labelInput2: getDefaultInput("Paramétrage input 2", "", "Entrez votre prénom", 20, '#53839D',null, null, null, null, 'disabled'),
        labelInput3: getDefaultInput("Paramétrage input 3 (champ email obligatoirement)", "", "Entrez votre adresse email", 20, '#53839D',null, null, null, null, 'disabled'),
        labelInput4: getDefaultInput("Paramétrage input 4", "", "Entrez votre année de naissance", 20, '#53839D',null, null, null, null, 'disabled'),
        labelConsent: getDefaultInput("Libellé de consentement", "J'accepte le règlement du jeu", null, 20, '#53839D', 'rgba(255,255,255,0)', 'initial', null, null, 'disabled'),
        labelConsentOptionnal: getDefaultInput("Libellé de consentement optionnel", "Je souhaite recevoir les actualités", null, 20, '#53839D', 'rgba(255,255,255,0)', 'initial', null, null, false),
    }
};