import { EditableTypes, FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultSimpleSlide, getDefaultTextSimple } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider multiple',
    blocks: {
        title: getDefaultTextSimple("Titre", "Lorem ipsum", 16, "#666"),
        slides: {
            name: "Slider",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.SLIDER_MULTIPLE,
            limit: false,
            id: uuidv4(),
            inputs: {
                color: {
                    type: FormInputTypes.COLOR,
                    value: "#f48256",
                    label: "Couleur pagination :"
                }
            },
            config: [
                getDefaultSimpleSlide("https://via.placeholder.com/230x85")
            ]
        }
    }
};