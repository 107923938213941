import { FormInputTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultTextSimple } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Module de wishlist',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fffff'
        },
        productImage: {
            label: 'Champ image du produit',
            type: FormInputTypes.TEXT,
            value: 'Image'
        },
        productName: {
            label: 'Champ nom du produit',
            type: FormInputTypes.TEXT,
            value: 'Nom'
        },
        productPrice: {
            label: 'Champ prix du produit',
            type: FormInputTypes.TEXT,
            value: 'Prix'
        },
        productAction: {
            label: "Colonne d'action",
            type: FormInputTypes.TEXT,
            value: 'Action'
        },
    },
    blocks: {
        title: getDefaultTextSimple("Titre module", "Vos produits", 20, "#000", 'initial'),
        noResult: getDefaultTextSimple("Aucun résultat", "Aucun résultat dans votre liste", 22, "#000", 'center'),
        buttonAdd: getDefaultButton("Bouton Ajouter", "Ajouter des produits", 16, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 10, 20, 'uppercase'),
        buttonClear: getDefaultButton("Bouton Effacer", "VIDER LA LISTE", 16, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 10, 20, 'uppercase'),
        buttonSend: getDefaultButton("Bouton Envoyer", "ENVOYER LA LISTE", 16, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 10, 20, 'uppercase'),
    }
};