import React from 'react';

import {Grid, Typography,Tooltip} from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';





const DataInfosContainer= styled (Grid)`
    .dot{
        width:15px;
        height:15px;
        border-radius:50%;
        display:block;
    }
    .dataTitle{
        font-weight:bold;
        font-style:italic;
    }
    .dataSubtitle{
        margin-top:10px;
        font-size:12px;
        font-style:italic;
    }
`



export default function DataBar(props){

    return(
        <DataInfosContainer item xs={2}> 
            <Grid container>
                <Grid item xs={2}>
                    <span style={{backgroundColor:props.color}} className="dot"></span>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1" className="dataTitle">
                        {props.name}
                    </Typography>
                    {props.size || props.size===0 ?
                        <Typography variant="body2" className="dataSubtitle" style={{verticalAlign:'middle'}}>
                            {props.size} {props.unit}
                            {props.warning === true?
                            <Tooltip title="Limite de stockage bientôt atteinte" arrow placement="right-end ">
                                <WarningIcon style={{color:colors.red.regular,display:'inline-block',verticalAlign:'middle',marginLeft:10,fontSize:15}}/>                                
                            </Tooltip>

                            :null}
                        </Typography>                    
                    :<CircularProgress size={"10px"}/>}

                </Grid>
            </Grid>
        </DataInfosContainer>        
    )

}