import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import colors from "../../../config/theme/colors";
import { useState } from "react";
import { useEffect } from "react";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import { isArray } from "lodash";

const BreadcrumbCustom = ({
  rootName,
  previousFolders,
  currentFolder,
  separator,
  getClickedCategory,
  nbPreviousItems,
  style,
  size,
  windowWidth,
  defaultFolder,
  showAll
}) => {
  const [itemsToBeShown, setItemsToBeShown] = useState([]);

  useEffect(() => {
    if (!showAll) {
      setItemsToBeShown(previousFolders.slice(-nbPreviousItems));
    }else{
      setItemsToBeShown(previousFolders);
    }
  }, [currentFolder]);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={separator} style={style}>
      {!showAll && previousFolders.length > nbPreviousItems && (
        <Grid container style={{ gap: "5px" }}>
          <Typography
            style={{
              cursor: "pointer",
              color: colors.black.regular,
              fontWeight: "bold",
              fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
            }}
            onClick={() => getClickedCategory(null)}
          >
            {rootName ?? "Root"}
          </Typography>
          {separator}
          <Typography
            style={{
              color: colors.black.regular,
              fontWeight: "bold",
              fontSize: windowWidth < 1200 ? "18px" : `${size} px`,
            }}
          >
            ...
          </Typography>
        </Grid>
      )}
      {previousFolders.length > 2
        ? itemsToBeShown.map((folder, i) =>
            folder ? (
              <Typography
                key={i}
                style={{
                  cursor: "pointer",
                  color: colors.black.regular,
                  fontWeight: "bold",
                  fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
                }}
                onClick={() => getClickedCategory(folder.id)}
              >
                {folder?.categoryDatas?.find(
                  (e) => e.attribute === "category_name"
                )?.value || folder.libelle}
              </Typography>
            ) : (
              <Typography
                key={i}
                style={{
                  cursor: "pointer",
                  color: colors.black.regular,
                  fontWeight: "bold",
                  fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
                }}
                onClick={() => getClickedCategory(null)}
              >
                {rootName ?? "Root"}
              </Typography>
            )
          )
        : previousFolders.map((folder, i) =>
            folder ? (
              <Typography
                key={i}
                style={{
                  cursor: "pointer",
                  color: colors.black.regular,
                  fontWeight: "bold",
                  fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
                }}
                onClick={() => getClickedCategory(folder.id)}
              >
                {folder?.categoryDatas?.attribute
                  ? folder?.categoryDatas?.find(
                      (e) => e.attribute === "category_name"
                    )?.value
                  : folder.libelle}
              </Typography>
            ) : (
              <Typography
                key={i}
                style={{
                  cursor: "pointer",
                  color: colors.black.regular,
                  fontWeight: "bold",
                  fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
                }}
                onClick={() => getClickedCategory(null)}
              >
                {rootName ?? "Root"}
              </Typography>
            )
          )}
      <Typography
        style={{
          color: colors.grey.lighter.hue600,
          fontWeight: "bold",
          fontSize: windowWidth < 1200 ? "18px" : `${size}px`,
        }}
      >
        { isArray(currentFolder?.categoryDatas) ?? currentFolder?.categoryDatas?.find(
              (e) => e.attribute === "category_name"
            )?.value
          ? currentFolder?.categoryDatas?.find(
              (e) => e.attribute === "category_name"
            )?.value
          : currentFolder?.libelle
          ? currentFolder?.libelle
          : defaultFolder
          ? defaultFolder
          : rootName ?? "Root"}
      </Typography>
    </Breadcrumbs>
  );
};

BreadcrumbCustom.propTypes = {
  previousFolders: PropTypes.array,
  currentFolder: PropTypes.object || PropTypes.array,
  separator: PropTypes.node,
  getClickedCategory: PropTypes.func,
  nbPreviousItems: PropTypes.number,
  style: PropTypes.object,
  size: PropTypes.number,
  windowWidth: PropTypes.number,
};

BreadcrumbCustom.defaultProps = {
  previousFolders: [],
  currentFolder: [],
  separator: <ArrowForwardIosSharpIcon fontSize="small" />,
  /* Function that is passed to the breadcrumb to know which item has been clicked */
  /* Exec the function passing the item id inside the params */
  getClickedCategory: () => {},
  nbPreviousItems: 2,
  style: {},
  size: 14,
  windowWidth: 0,
};

export default BreadcrumbCustom;