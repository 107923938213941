import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getOfferStats } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'À propos',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Sous-titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        image: getImageDefault(),
        descriptionBlock: {
            id: uuidv4(),
            name: "Bloc d'explication",
            type: EditableTypes.MINI_BLOCKS,
            blocks:{
                logo: getImageDefault(),
                agency_content: getDefaultTextarea("Description", "<p>Depuis 13 ans Sinfin c’est plus de&nbsp;<strong>200 clients pour déjà plus de 300 projets réalisés.</strong></p><p>En plus d’une&nbsp;<strong>expertise dans divers secteurs</strong>&nbsp;économiques, nous mettons à votre disposition une&nbsp;<strong>équipe de professionnels qualifiés.</strong></p><p><strong>Mêlant qualité graphique et compétences techniques</strong>, notre agence a su se spécialiser dans le web digital, produisant ainsi des sites web, rewards, retail, newsletters, catalogues interactifs, applications... Tout ceci grâce à une méthodologie éprouvée et&nbsp;<strong>une maîtrise média et technique.</strong></p>", true, null, null, null, null, 16),
                container_stat:{
                    title: "Statistiques",
                    name: "Statistiques",
                    type: EditableTypes.MULTIPLE_BLOCKS,
                    subtype: ItemTypes.OFFER_STATS,
                    limit: 3,
                    config: [
                        getOfferStats(null, "13", "Ans"),
                        getOfferStats('+de', "200", "Clients"),
                        getOfferStats('+de', "300", "Projets"),
                    ]
                },
            }
        },
    }
};

