import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText, getDefaultTextarea, getDefaultListCodes, getDefaultListProducts } from '../../helpers';


export default {
    name: 'Code finder',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Top (px)",
            params: {max: 200, min: 0, step: 1},
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Bottom (px)",
            params: {max: 200, min: 0, step: 1},
        }
    },
    blocks: {
        number_inputs:{
            type: FormInputTypes.NUMBER,
            name: "Nombre de code à indiquer",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.NUMBER,
                    value: 5
                }
            }
        },
        list_codes:{
            title: "Liste des codes",
            name: "Liste des codes",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CODE_FINDER,
            config: [
                getDefaultListCodes(),
            ]
        },
        list_products:{
            title: "Liste des produits",
            name: "Liste des produits",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CODE_FINDER_PRODUCTS,
            config: [
                getDefaultListProducts(),
            ]
        },
        titleListProducts: getDefaultText('Titre', "EXPLOREZ LES PAGES PRODUITS POUR Y TROUVER LES CODES SECRETS DISSIMULÉS", 16, "#53839D", null, 'uppercase', 'center'),
        middleBox:{
            id: uuidv4(),
            title: "Bloc principal",
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
                paddingTop: {
                    type: FormInputTypes.SLIDER,
                    value: 25,
                    label: "Padding Top (px)",
                    params: {max: 200, min: 0, step: 1},
                },
                paddingBottom: {
                    type: FormInputTypes.SLIDER,
                    value: 25,
                    label: "Padding Bottom (px)",
                    params: {max: 200, min: 0, step: 1},
                }
            },
            blocks: {
                title: getDefaultText('Titre', "Es tu prêt ?", 50, "#53839D", null, 'uppercase', 'initial'),
                desc: getDefaultTextarea('Description du jeu', "Bref résumé du concept du jeu à suivre.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),
                button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
                textCodeFound: getDefaultText('Titre', "de validé ! Trouvez en d’autres pour augmenter vos chances de gagner !", 16, "#53839D", null, 'initial', 'center'),
            }
        }
    }
};
