import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../shareable/types';

export const getDefaultLinkCms = (name, value, link, color = null, backgroundColor = null, border = false, fullWidth, borderRadius = null,align, fontSize=null, fontSizeMobile=null ) => {
    return{
        type: EditableTypes.SHORT_TEXT,
        name,
        id: uuidv4(),
        key: 'button',
        inputs: {
            value: {
                type: FormInputTypes.TEXT,
                value
            },
            link: {
                label: 'Lien',
                type: FormInputTypes.LINK,
                value: link
            },
            color: {
                type: FormInputTypes.COLOR,
                value: color,
                label: "Couleur du texte"
            },
            backgroundColor: {
                type: FormInputTypes.COLOR,
                value: backgroundColor,
                label: "Couleur de fond"
            },
            fontSize: {
                type: FormInputTypes.SLIDER,
                value: fontSize,
                label: "Taille du texte",
                params: {max: 100, min: 6, step: 2},
            },
            fontSizeMobile: {
                type: FormInputTypes.SLIDER,
                value: fontSizeMobile,
                label: "Taille du texte sur mobile",
                params: {max: 100, min: 6, step: 2},
            },
            border: border ? {
                type: FormInputTypes.GROUP,
                label: "Border",
                inputs: {
                    borderColor: {
                        type: FormInputTypes.COLOR,
                        value: '#000',
                        label: "Couleur de la bordure"
                    },
                    borderWidth: {
                        type: FormInputTypes.SLIDER,
                        value: 0,
                        label: "Largeur de la bordure :",
                        params: {max: 5, min: 0, step: 1}
                    },
                    borderRadius: {
                        type: FormInputTypes.SLIDER,
                        value: borderRadius,
                        label: "Arrondi des angles :",
                        params: {max: 50, min: 0, step: 1}
                    }
                }
            } : null,
            fullWidth: fullWidth ? {
                label: 'Fullwidth',
                type: FormInputTypes.SWITCH,
                value: true,
            } : null,
            align: align ? {
                label: 'Disposition du bouton',
                type: FormInputTypes.SELECT,
                value: align,
                params: [{label: 'Initial', value: 'flex-start'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'flex-end'}]
            } : null,
        }
    }
};

export const getDefaultBackgroundCms = ( backgroundColor = null) => {
    return{
        type: EditableTypes.SHORT_TEXT,
        name: 'Couleur de fond',
        id: uuidv4(),
        key: 'color',
        inputs: {
            backgroundColor: {
                type: FormInputTypes.COLOR,
                value: backgroundColor,
                label: "Couleur de fond"
            },
        }
    }
};

export const getDefaultTextCms = (name, value, color = null, fontSize = null, valueFontSize = 'px', textTransform = null, textAlign = null, translate = false,fontWeight=null,maxFont=null,tag =null) => {
    let inputs = {};
    if(color){
        inputs.color = {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        };
    }
    if(fontSize){
        inputs.fontSize = {
            type: FormInputTypes.SLIDER,
            value: fontSize,
            label: `Taille du texte`,
            params: {max: maxFont??100, min: 6, step: 2},
        };
    }

    if(fontWeight){
        inputs.fontWeight = {
            type: FormInputTypes.SELECT,
            label: "Èpaisseur du texte",
            value: fontWeight,
            params: [{label: 'Grasse', value: 'bold'}, {label: 'Normale', value: 'normal'}, {label: 'Fine', value: 'light'}]
        };
    }

    if(tag){
        inputs.tag = {
            type: FormInputTypes.SELECT,
            label: "Type de balise",
            value: tag,
            params: [{label: 'h1', value: 'h1'}, {label: 'h2', value: 'h2'}, {label: 'h3', value: 'h3'},{label: 'h4', value: 'h4'},{label: 'h5', value: 'h5'},{label: 'h6', value: 'h6'},{label: 'normal', value: 'p'}]
        };
    }

    if(textTransform){
        inputs.textTransform = {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        };
    }

    if(textAlign){
        inputs.textAlign = {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    }
    
    let optional = {};

    if (value !== null) {
        optional.value = {
            translate,
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value
        };
    }

    inputs = { ...optional, ...inputs };
    
    return {
        type: EditableTypes.SHORT_TEXT,
        name,
        id: uuidv4(),
        inputs,
    };
};


export const getDefaultTextareaSpecialCms = (name, value, html, color = null, backgroundColor = null, textTransform = null, textAlign = null, size = null, valueFontSize = 'px', paddingVertical = 0, paddingHorizontal = 0, widthAuto = true) => {
    let inputs = {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value
        }
    };

    if (size) {
        inputs.fontSize = {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        };
    }
    
    inputs = {
        ...inputs,
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        },
        paddingVertical: {
            type: FormInputTypes.SLIDER,
            value: paddingVertical,
            params: {max: 100, min: 0, step: 2},
            label: "Padding vertical",
        },
        paddingHorizontal: {
            type: FormInputTypes.SLIDER,
            value: paddingHorizontal,
            params: {max: 100, min: 0, step: 2},
            label: "Padding horizontal",
        },
        widthAuto: {
            label: 'Calcul automatique de la taille',
            type: FormInputTypes.SWITCH,
            value: true,
        }
    }

    return {
        type: EditableTypes.LONG_TEXT,
        name,
        id: uuidv4(),
        inputs
    }
};

export const getDefaultButtonCms = (name, value, color, backgroundColor = null, textTransform = null,showButton = null,link=null,borderRadius=null,backgroundColorHover=null) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    key: 'button',
    inputs: {        
        showButton: {
            label: 'Afficher le bouton',
            type: FormInputTypes.SWITCH,
            value: false,
        },
        value: {
            type: FormInputTypes.TEXT,
            value
        },
        link: {
            type: FormInputTypes.TEXT,
            value:link,
            label: "Lien du bouton"
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        backgroundColorHover: {
            type: FormInputTypes.COLOR,
            value: backgroundColorHover,
            label: "Couleur de fond au survol"
        },
        borderWidth: {
            type: FormInputTypes.SLIDER,
            value: 0,
            label: "Largeur de la bordure :",
            params: {max: 5, min: 0, step: 1}
        },
        borderRadius: {
            type: FormInputTypes.SLIDER,
            value: borderRadius,
            label: "Arrondi des angles :",
            params: {max: 50, min: 0, step: 1}
        },
        borderColor: {
            type: FormInputTypes.COLOR,
            value: '#000',
            label: "Couleur de la bordure"
        },
        paddingVertical: {
            type: FormInputTypes.SLIDER,
            value: 5,
            params: {max: 100, min: 0, step: 1},
            label: "Padding vertical",
        },
        paddingHorizontal: {
            type: FormInputTypes.SLIDER,
            value: 10,
            params: {max: 100, min: 0, step: 1},
            label: "Padding horizontal",
        },
        position: {
            label: 'Position',
            type: FormInputTypes.BUTTON_GROUP,
            params:[
                {value: 'under', label: 'Sous le texte'},
                {value: 'nextTo', label: 'À côté du texte'},
            ],
            value: 'under'
        },
        positionHorizontal: {
            label: 'Alignement horizontale',            
            type: FormInputTypes.BUTTON_GROUP,
            params:[
                {value: 'center', label: 'Centrer'},
                {value: 'flex-start', label: 'À gauche'},
                {value: 'flex-end', label: 'À droite'},
            ],
            value: 'center'
        },
    },
});

export const getDefaultSlideTop = (img = "https://via.placeholder.com/1920x840", imgMobile = "https://via.placeholder.com/780x1088", subtitle = "Sous-titre",ontitle="Sur-titre", title = "Titre", textLink = "Découvrir",link = "https://www.sinfin.fr/", textLink2 = null, link2 = null,alt=null) => ({
    id: uuidv4(),
    blocks: {
        configSlide: {
            name: "Configuration de la slide",
            id: uuidv4(),
            inputs:{
                maxWidthContent:{
                    label: "Taille maximal du contenu (en %)",
                    type: FormInputTypes.SLIDER,
                    value: 100,
                    params: {max: 100, min: 0, step: 1}
                },
                positionVertical: {
                    label: 'Position vertical',
                    type: FormInputTypes.BUTTON_GROUP,
                    params:[
                        {value: 'center', label: 'Center'},
                        {value: 'flex-start', label: 'En haut'},
                        {value: 'flex-end', label: 'En bas'},
                    ],
                    value: 'center'
                },
                positionHorizontal: {
                    label: 'Position horizontal',
                    type: FormInputTypes.BUTTON_GROUP,
                    params:[
                        {value: 'center', label: 'Center'},
                        {value: 'flex-end', label: 'À droite'},
                        {value: 'flex-start', label: 'À gauche'},
                    ],
                    value: 'center'
                },
                margin: {
                    label: "Marge entre le texte et le bouton (en %)",
                    type: FormInputTypes.SLIDER,
                    value: 0,
                },
                alignButton: {
                    label: 'Aligner les boutons avec le texte',
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                gap: {
                    label: 'Espace entre le texte et le boutton',
                    type: FormInputTypes.SLIDER,
                    value: 20,
                    params: {max: 200, min: 0, step: 10}
                },
            }
        },
        imageDesktop: {
            type: EditableTypes.IMAGE,
            name: "Image Desktop",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                objectPosition: {
                    label:"Position de l'image (en hauteur et en %)" ,
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 5},
                    value: 50
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        imageMobile: {
            type: EditableTypes.IMAGE,
            name: "Image Mobile",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imgMobile
                },
                objectPosition: {
                    type: FormInputTypes.SELECT,
                    params: [{label: 'Haut', value: 'top'}, {label: 'Centré', value: 'center'}, {label: 'Bas', value: 'bottom'}],
                    value: 'center'
                },
            }
        },
        ontitle: getDefaultTextareaCms("Sur-titre", ontitle, false, '#FFFFFF', null, 'uppercase', null, 20, '%', true),
        title: getDefaultTextareaCms("Titre", title, false, '#FFFFFF', null, 'uppercase', null, 80, '%', true),
        subtitle: getDefaultTextareaCms("Sous-titre", subtitle, false, '#FFFFFF', null, 'uppercase', null, 70, '%', true),
        link: getDefaultLinkCms("Lien", textLink, link, '#FFFFFF', '#000000', 1, null, 1),
        link2: getDefaultLinkCms("Lien 2", textLink2, link2, '#FFFFFF', '#000000', 1, null, 1)
    }
});

export const getDefaultSlideAbout = ( img = "https://via.placeholder.com/590x700", title = "Titre", text = 'Texte', textLink = "En savoir plus", link = "#",alt=null,height=400) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
                height: {
                    type: FormInputTypes.SLIDER,
                    value: height,
                    label: "Hauteur de l'image",
                    params: {max: 1000, min: 0, step: 5},
                },
            }
        },
        title: getDefaultTextCms("Titre", title, '#000000', 20, 'px',"uppercase"),
        text: getDefaultTextCms("Texte", text, '#000000', 16, 'px'),
        link: getDefaultLinkCms("Lien", textLink, link, null, null, 1)
    }
});

export const getDefaultListCategories = (title = "Titre") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        
        subItem: {
            title: "Sous-items",
            name: "Sous-items",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_LIST_CATEGORIES_SUBITEM,
            buttonAdd: 'un sous item',
            limit: 100,
            config: [ 
                getDefaultListCategoriesSubitem(),
            ]
        }
    }
});

export const getDefaultListCategoriesSubitem = ( img = "https://via.placeholder.com/400x600", title = "Prénom", title2 = "Nom", text = 'Texte', textLink = "En savoir plus", link = "https://www.sinfin.fr/",alt=null) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        title2: getDefaultTextCms("Titre", title2, null),
        title: getDefaultTextCms("Titre", title, null),
        text: getDefaultTextCms("Description", text, null),
        hashtag: getDefaultTextCms("#MotClé", text, null),
    }
});

export const getDefaultTabForSlider = ( img = "https://via.placeholder.com/400x600", title = "Titre", text = 'Texte', textLink = "En savoir plus", link = "https://www.sinfin.fr/") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        description: getDefaultTextareaCms("Texte", "Description de la catégorie", true, '#53839D'),
        subItem: {
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_BY_TAB_SUBITEM,
            buttonAdd: 'une slide',
            limit: 100,
            config: [ 
                getDefaultSlideByTab(),
                getDefaultSlideByTab(),
            ]
        }
    }
});

export const getDefaultSlideByTab = ( img = "https://via.placeholder.com/700x400",alt=null) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        }
    }
});

export const getDefaultSlideInstagram = (imagePrincipale = "https://via.placeholder.com/430x430", imageThumbnail1 = "https://via.placeholder.com/136x136",  imageThumbnail2 = "https://via.placeholder.com/136x136",  imageThumbnail3 = "https://via.placeholder.com/136x136",alt=null,alt1=null,alt2=null,alt3=null,) => ({
    id: uuidv4(),
    blocks: {
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        imageThumbnail1: {
            type: EditableTypes.IMAGE,
            name: "Vignette 1",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail1
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt1,
                    label: "Texte alternatif",
                },
            }
        },
        imageThumbnail2: {
            type: EditableTypes.IMAGE,
            name: "Vignette 2",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail2
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt2,
                    label: "Texte alternatif",
                },
            }
        },
        imageThumbnail3: {
            type: EditableTypes.IMAGE,
            name: "Vignette 3",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imageThumbnail3
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt3,
                    label: "Texte alternatif",
                },
            }
        },
    }
});

export const getDefaultSlideImage = (imagePrincipale = "https://via.placeholder.com/580x580",alt=null) => ({
    id: uuidv4(),
    blocks: {
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        link: getDefaultLinkCms("Lien", null, null, '#FFF', '#000',1, null, 0),
        text: getDefaultTextCms("Texte", "Texte", "#FFF", 24, 'px', 'initial'),
    }
});

export const getDefaultSlidePresentationMedia = (title = "Titre", text="Description", imagePrincipale = "https://via.placeholder.com/200x250",alt=null) => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        description: getDefaultTextCms("Description", text, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
    }
});

export const getDefaultSlideMoments = (title = "Titre", subtitle = "Sous-titre", text="Description", imagePrincipale = "https://via.placeholder.com/1400x1000", tag = "#tag",alt=null) => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre", title, null),
        subtitle: getDefaultTextCms("Sous-Titre", subtitle, null),
        description: getDefaultTextCms("Description", text, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imagePrincipale
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        tag: getDefaultTextCms("Tag", tag, null),
        link: getDefaultLinkCms("Lien", "Voir les produits", "#"),
    }
});

export const getDefaultDescriptionManifest = (title = "Titre", description = "Description", img = null) => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre du bloc", title,  null),
        description: getDefaultTextareaCms("Description du bloc", description, true, null),
        imagePrincipale: {
            type: EditableTypes.IMAGE,
            name: "Image Principale",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
    }
});

export const getDefaultDoubleColonne = () => ({
    id: uuidv4(),
    blocks: {
        is_mini_card: {
            name: "Se comporte en card",
            inputs: {
                handle: {
                    label: 'Se comporte en card',
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                height: {
                    label: 'Hauteur',
                    type: FormInputTypes.SLIDER,
                    value: 30,
                    params: { max: 300, min: 0, step: 50 },
                }
            }
        },
        text: {
            name: 'Texte',
            inputs: {
                content: {
                    type: FormInputTypes.WYSIWYG,
                    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Nullam id dolor id nibh ultricies vehicula ut id elit."
                }
            }
        },
        media: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/430x430",
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: null,
                    label: "Texte alternatif"
                }
            }
        },
        backgroundColor: {
            name: "Couleur du fond ou filtre/dégradé quand image",
            inputs: {
                value_1: {
                    type: FormInputTypes.COLOR,
                    value: "#FCFCFC",
                    label: "Couleur de fond"
                },
                value_2: {
                    type: FormInputTypes.COLOR,
                    value: "transparent",
                    label: "Couleur de fond (si dégradé)"
                }
            }
        },
        color: {
            name: 'Couleur du texte',
            inputs: {
                color: {
                    type: FormInputTypes.COLOR,
                    value: '#000'
                }
            }
        },
        textAlign: {
            name: "Alignement du texte",
            inputs: {
                textAlign: {
                    type: FormInputTypes.SELECT,
                    value: "initial",
                    params: [
                        { label: "Initial", value: "initial" },
                        { label: "Centré", value: "center" },
                        { label: "Droite", value: "right" },
                        { label: "Justifié", value: "justify" },
                    ]
                }
            }
        },
        button: {
            name: "Afficher le bouton",
            inputs: {
                handle: {
                    label: 'Afficher le bouton',
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                content: {
                    label: 'Texte du bouton',
                    type: FormInputTypes.TEXT,
                    value: "google.com"
                },
                link: {
                    label: 'Lien du bouton',
                    type: FormInputTypes.TEXT,
                    value: "google.com"
                },
                backgroundColor: {
                    label: 'Couleur du fond du bouton',
                    type: FormInputTypes.COLOR,
                    value: "#000"
                },
                color: {
                    label: 'Couleur du texte du bouton',
                    type: FormInputTypes.COLOR,
                    value: "#000"
                },
                border: {
                    label: 'Couleur de la bordure',
                    type: FormInputTypes.COLOR,
                    value: "",
                },
                paddingX: {
                    label: 'Padding X du bouton',
                    type: FormInputTypes.SLIDER,
                    value: 20,
                    params: { max: 100, min: 0, step: 2 },
                },
                paddingY: {
                    label: 'Padding Y du bouton',
                    type: FormInputTypes.SLIDER,
                    value: 30,
                    params: { max: 100, min: 0, step: 2 },
                }
            }
        },
        liens_download: {
            name: "Liens de téléchargement",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_DOUBLE_COLONNE,
            buttonAdd: 'un contenu',
            config: [
                {
                    id: uuidv4(),
                    blocks: {
                        media: {
                        }
                    }
                }
            ]
        }
    }
})

export const getDefaultDownloadFiles = () => ({
    id: uuidv4(),
    blocks: {
        media: {
            type: EditableTypes.IMAGE,
            name: "Fichier à télécharger",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/100x100",
                },
                intitule: {
                    type: FormInputTypes.TEXT,
                    value: "Intitulé du média",
                    label: "Intitulé du média"
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: null,
                    label: "Texte alternatif"
                }
            }
        }
    }
})

export const getDefaultCategorieHome = (img = "https://via.placeholder.com/600x600",subtitle="", title = "Catégorie",alt=null) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration de la catégorie",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif"
                }
            }
        },
        title: getDefaultTextCms("Titre de la catégorie", title,  "#000",18,null,true,true,null,null,60),
        subtitle: getDefaultTextCms("Sous-titre de la catégorie", subtitle,  "#000",12,null,true,null,null,null,30),
        categorie: {
            name: 'Choix de la catégorie',
            inputs:{
                category: {
                    name: 'Catégorie',
                    type: FormInputTypes.CATEGORY,
                    value: null
                },
            }
        },
        link: {
            name: 'Lien',
            inputs:{
                value: {
                    label: 'Lien',
                    type: FormInputTypes.TEXT,
                    value: ''
                },
            }
        },
        // buttonText: getDefaultTextCms("Titre du bouton", title,  null)
    }
});


export const getDefaultCategorie2 = (img = "https://via.placeholder.com/600x600", title = "Titre", description = "Description", link = "Voir plus") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        title: getDefaultTextCms("Nom", title,  null),
        description: getDefaultTextCms("Description", description,  null),
        buttonText: getDefaultLinkCms("Titre du bouton", 'Voir plus', '', '#FFF', '#000', 1, null, 0)
    }
});


export const getDefaultTextareaCms = (name, value, html, color = null, backgroundColor = null, textTransform = null, textAlign = null, size = null, valueFontSize = 'px', translate = false, width = false, fontSizeMobile= null) => {
    let inputs = {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value,
            translate
        }
    };

    if (size) {
        inputs.fontSize = {
            type: FormInputTypes.SLIDER,
            value: size,
            label: "Taille du texte",
            params: {max: 100, min: 8, step: 2},
        };
    }
    if (fontSizeMobile) {
        inputs.fontSizeMobile = {
            type: FormInputTypes.SLIDER,
            value: fontSizeMobile,
            label: "Taille du texte mobile",
            params: {max: 100, min: 8, step: 2},
        };
    }

    if (width) {
        inputs.width = {
            type: FormInputTypes.SLIDER,
            value: width,
            label: "Largeur du texte",
            params: {max: 100, min: 8, step: 2},
        };
    }
    
    inputs = {
        ...inputs,
        color: {
            type: FormInputTypes.COLOR,
            value: color,
            label: "Couleur du texte"
        },
        backgroundColor: {
            type: FormInputTypes.COLOR,
            value: backgroundColor,
            label: "Couleur de fond"
        },
        textTransform: {
            type: FormInputTypes.SELECT,
            label: "Transformation du texte",
            value: textTransform,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Tout Majuscule', value: 'uppercase'}, {label: 'Tout Minuscule', value: 'lowercase'}]
        },
        textAlign: {
            type: FormInputTypes.SELECT,
            label: "Alignement du texte",
            value: textAlign,
            params: [{label: 'Initial', value: 'initial'}, {label: 'Centré', value: 'center'}, {label: 'Droite', value: 'end'}]
        }
    }

    return {
        type: EditableTypes.LONG_TEXT,
        name,
        id: uuidv4(),
        inputs
    }
};

export const getDefaultProductHome = (img = "https://via.placeholder.com/430x430", title = "Produit") => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration du produit",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                }
            }
        },
        product: {
            name: 'Produit',
            inputs:{
                product: {
                    type: FormInputTypes.PRODUCT,
                    value: null
                },
            }
        }
    }
});

export const getDefaultCollectionHome = (img = "https://via.placeholder.com/600x600", title = "Collection", subtitle = "Sous-titre",alt=null) => ({
    id: uuidv4(),
    blocks: {
        image: {
            type: EditableTypes.IMAGE,
            name: "Image d'illustration de la collection",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                alt:{
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label:"Texte alternatif"
                }
            }
        },
        title: getDefaultTextCms("Titre de la collection", title, '#FFFFFF', 20, 'px'),
        subtitle: getDefaultTextCms("Sous-titre de la collection", subtitle, '#FFFFFF', 20, 'px'),
        categorie: {
            name: 'Choix de la catégorie',
            inputs:{
                category: {
                    name: 'Collection',
                    type: FormInputTypes.CATEGORY,
                    value: null
                },
            }
        },
        link: {
            name: 'Lien',
            inputs:{
                value: {
                    label: 'Lien',
                    type: FormInputTypes.TEXT,
                    value: ''
                },
            }
        },
        button: getDefaultButtonCms('Bouton', 'Découvrir', '#000', '#fff', 'initial',null,null,null,"#000")
    }
});

export const getDefaultAccordion = (title = "Accordion",text="Lorem ipsum",color="#000",backgroundColor="#FFF") => ({
    id: uuidv4(),
    blocks: {
        title: getDefaultTextCms("Titre de l'accordeon", title,  color,12,null,null,null,false,false,backgroundColor),
        text: {
            name: "Contenu de l'accordeon",
            inputs:{
                content: {
                    name: 'Texte',
                    type: FormInputTypes.WYSIWYG,
                    value: text
                }
            }
        },
    }
});

export const getDefaultSlideTopNew = (img = "https://via.placeholder.com/1920x840", imgMobile = "https://via.placeholder.com/780x1088", subtitle = "Sous-titre",ontitle="Sur-titre", title = "Titre", textLink = "Découvrir", link = "https://www.sinfin.fr/", textLink2 = null, link2 = null,alt=null) => ({
    id: uuidv4(),
    blocks: {
        configSlide: {
            name: "Configuration de la slide",
            id: uuidv4(),
            inputs:{
                maxWidthContent:{
                    label: "Taille maximal du contenu (en %)",
                    type: FormInputTypes.SLIDER,
                    value: 100,
                    params: {max: 100, min: 0, step: 1}
                },
                positionVertical: {
                    label: 'Position vertical',
                    type: FormInputTypes.BUTTON_GROUP,
                    params:[
                        {value: 'center', label: 'Center'},
                        {value: 'flex-start', label: 'En haut'},
                        {value: 'flex-end', label: 'En bas'},
                    ],
                    value: 'center'
                },
                positionHorizontal: {
                    label: 'Position horizontal',
                    type: FormInputTypes.BUTTON_GROUP,
                    params:[
                        {value: 'center', label: 'Center'},
                        {value: 'flex-end', label: 'À droite'},
                        {value: 'flex-start', label: 'À gauche'},
                    ],
                    value: 'center'
                },
                margin: {
                    label: "Marge entre le texte et le bouton (en %)",
                    type: FormInputTypes.SLIDER,
                    value: 0,
                },
                alignButton: {
                    label: 'Aligner les boutons avec le texte',
                    type: FormInputTypes.SWITCH,
                    value: false,
                },
                gap: {
                    label: 'Espace entre le texte et le boutton',
                    type: FormInputTypes.SLIDER,
                    value: 20,
                    params: {max: 200, min: 0, step: 10}
                },
            }
        },
        imageDesktop: {
            type: EditableTypes.IMAGE,
            name: "Image Desktop",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: img
                },
                objectPosition: {
                    label:"Position de l'image (en hauteur et en %)" ,
                    type: FormInputTypes.SLIDER,
                    params: {max: 100, min: 0, step: 5},
                    value: 50
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: alt,
                    label: "Texte alternatif",
                },
            }
        },
        imageMobile: {
            type: EditableTypes.IMAGE,
            name: "Image Mobile",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: imgMobile
                },
                objectPosition: {
                    type: FormInputTypes.SELECT,
                    params: [{label: 'Haut', value: 'top'}, {label: 'Centré', value: 'center'}, {label: 'Bas', value: 'bottom'}],
                    value: 'center'
                },
            }
        },
        ontitle: getDefaultTextareaCms("Sur-titre", ontitle, false, '#FFFFFF', null, 'uppercase', null, 20, '%', true, false, 14),
        title: getDefaultTextareaCms("Titre", title, false, '#FFFFFF', null, 'uppercase', null, 80, '%', true, false, 14),
        subtitle: getDefaultTextareaCms("Sous-titre", subtitle, false, '#FFFFFF', null, 'uppercase', null, 70, '%', true, false, 14),
        link: getDefaultLinkCms("Lien", textLink, link, '#FFFFFF', '#000000', 1, null, 1, false, 14, 14),
        link2: getDefaultLinkCms("Lien 2", textLink2, link2, '#FFFFFF', '#000000', 1, null, 1, false, 14, 14)
    }
});
