import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import LegoHead from './image/head_lego.png'

const BannerLinkLego = ({
    // preview = false,
    // id = null, 
    // data = null,
    // noSticky,
    // inputCallback = null,
    link = '',
    spread = false
}) => {
    // const [scroll, setScroll] = useState(0);
    // const [headerTop, setHeaderTop] = useState(0);
    // const handleScroll = () => {
    //     setScroll(window.scrollY);
    // };
    
    return (
        <div className="banner_link_lego">
            <div className="wrapper_text">
                <p>L'aventure LEGO<sup>®</sup> ne s'arrête pas là ! <br /> Découvrez nos dernières nouveautés</p>
                <a href={link} onClick={e => {
                    if (spread)
                        e.preventDefault();
                }} target="__blank"><img src={LegoHead} alt="Tête LEGO"/>C'est par ici &nbsp;!</a>
            </div>
            <picture>
                <source srcSet={require("./image/double_perso.webp")} type="image/webp" />
                <source srcSet={require("./image/double_perso.png")} type="image/png" />
                <img src={require("./image/double_perso.png")} alt="Deux personnages LEGO" className="double_perso"/>
            </picture>
        </div>
    );
};

BannerLinkLego.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BannerLinkLego;
