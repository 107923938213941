import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ProductGridListSingle from "../../components/_internals/product/ProductGridListSingle";

const ProductGrid = ({
  products,
  currency,
  sliderClassName,
  spaceBottomClass,
  spread = false,
  data,
  params
}) => {
  return (
    <Fragment>
      {products.map((product, i) => {
        return (
          <ProductGridListSingle
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            currency={currency}
            spread={spread}
            data={data}
            params={params}
            key={`ProductGridSingle${i}`}
          />
        );
      })}
    </Fragment>
  );
};

ProductGrid.propTypes = {
  currency: PropTypes.object,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

export default ProductGrid;
