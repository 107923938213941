import PropTypes, { array } from "prop-types";
import React, { useState, useEffect } from "react";
import Button from '../../../../shared/components/CustomButton';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import request from "../../../../../tools/Fetch";

const GameCodeFinder = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hidePopup, setHidePopup] = useState(false);
    const [arrayCheckItems, setArrayCheckItems] = useState([]);
    const [listCodes, setListCodes] = useState(null);
    const [listCodesUser, setListCodesUser] = useState([]);
    const [successCode, setSuccessCode] = useState(0);


    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage']?.value;
    let image2 = data.blocks.middleBox.inputs['backgroundImage'].value;

    let border = null;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }

    const sendCodes = () => {
        let customDataResponse = "";
        for(let i = 0; i<listCodesUser.length; i++){
            if(arrayCheckItems[i]){
                customDataResponse += listCodesUser[i]+',';
            }
        }
        
        localStorage.setItem('dotation', JSON.stringify({p1_name: `VOUS AVEZ VALIDÉ : ${successCode} CODE${successCode > 1 ? 'S' : ''}`}));
        let data = new FormData;
        data.append('username', localStorage.getItem('username'));
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        data.append('customDataResponse', customDataResponse);
        try {
            request(`${process.env.REACT_APP_API}/public/asset/registration/add/custom-data`, 'post', data, 'multipart/form-data').then(result => {
                window.location = '/win';
            })
        }catch(e){
            console.log(e);   
        }
    }

    const checkValue = (e, index) => {
        let value = e.target.value.toLowerCase();
        value = value.replaceAll(' ','');
        value = value.replaceAll('-','');
        let checkItems = arrayCheckItems;
        let arrayCodesUser = listCodesUser;
        let count = 0;
        
        if((!arrayCodesUser.includes(value) || arrayCodesUser[index] === value || !value) && listCodes){
            if(listCodes.includes(value)){
                checkItems[index] = true;
            }
            else{
                checkItems[index] = false;
            }
        }
        for(let i = 0; i<checkItems.length; i++){
            if(checkItems[i]){
                count++;
            }
        }
        
        arrayCodesUser[index] = value;
        setSuccessCode(count);
        setArrayCheckItems([...checkItems]);
        setListCodesUser(arrayCodesUser);
    }

    function renderItems(maxItems){
        let arrayCodes = [];
        let arrayCodesUser = [];
        let checkItems = [];
        let listCodes = data.blocks.list_codes?.config;

        for (let i = 0;i < listCodes.length; i++){
            if(listCodes[i].blocks.identifier.inputs.value.value && !arrayCodes.includes(listCodes[i].blocks.identifier.inputs.value.value.toLowerCase())){
                arrayCodes.push(listCodes[i].blocks.identifier.inputs.value.value.toLowerCase());
            }
        }
        
        for (let i = 0;i < maxItems;i++) {
            checkItems.push(false);
            arrayCodesUser.push('');
        }
        
        setArrayCheckItems(checkItems);
        setListCodesUser(arrayCodesUser);

        setListCodes(arrayCodes);
    }

    useEffect(() => {
        renderItems(data.blocks.number_inputs.inputs.value.value);
    }, []);


    return (
        <div className="game_game" id="game_code_finder">
            <section 
                className={"game"}
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover!important",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    paddingTop: data.inputs['paddingTop'].value,
                    paddingBottom: data.inputs['paddingBottom'].value,
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && hover && props.toolbarSection }
                <div className={hidePopup ? "popup game" : "popup play game"}>
                    <div 
                        className="popup_content" 
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{
                            backgroundColor: data.blocks.middleBox.inputs['backgroundColor'].value,
                            backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                            paddingTop: data.blocks.middleBox.inputs['paddingTop'].value,
                            paddingBottom: data.blocks.middleBox.inputs['paddingBottom'].value,
                            position: 'relative',
                            top: 0,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarBlock }
                        <div>
                            {
                                data.blocks.middleBox?.blocks?.title.inputs.value.value ? 
                                    <H1 data={data.blocks.middleBox} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                                : null
                            }
                            {
                                data.blocks.middleBox?.blocks?.desc.inputs.value.value && data.blocks.middleBox?.blocks?.desc.inputs.value.value !== "<p><br></p>" ? 
                                    <Div data={data.blocks.middleBox} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                                : null
                            }
                            <div className="inputContainer">
                                {
                                    arrayCheckItems.map((e, i) => (
                                        <input type="text" id={`item_${i}`} className={`code_input ${arrayCheckItems[i] ? 'checked' : 'none'}`} placeholder={`Code n°${i + 1}`} onChange={(e) => checkValue(e, i)}/>
                                    ))
                                }
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {
                                    data.blocks.middleBox?.blocks?.textCodeFound?.inputs.value.value ? (
                                        <>
                                            
                                            <p style={{
                                                fontSize: data.blocks.middleBox.blocks.textCodeFound?.inputs.size?.value,
                                                color: data.blocks.middleBox.blocks.textCodeFound?.inputs.color?.value,
                                                backgroundColor: data.blocks.middleBox.blocks.textCodeFound?.inputs.backgroundColor?.value,
                                                textTransform: data.blocks.middleBox.blocks.textCodeFound?.inputs.textTransform?.value,
                                                textAlign: data.blocks.middleBox.blocks.textCodeFound?.inputs.textAlign?.value,
                                                lineHeight: '1.2',
                                                display: 'inline',
                                            }}>
                                                <strong>{successCode} code{successCode > 1 ? 's' : ''} de validé{successCode > 1 ? 's' : ''} ! </strong>
                                                {
                                                    successCode < data.blocks.number_inputs.inputs.value.value ? (
                                                        data.blocks.middleBox?.blocks?.textCodeFound?.inputs.value.value ? (
                                                            data.blocks.middleBox?.blocks?.textCodeFound?.inputs.value.value
                                                        ) : null
                                                    ) : null
                                                } 
                                            </p>
                                            {/* <Pg data={data.blocks.middleBox} preview={preview} component={id} block={'textCodeFound'} inputCallback={inputCallback} style={{display: 'inline '}} /> */}
                                        </>
                                    ) 
                                    : null
                                }
                            </div>
                            <Button data={data.blocks.middleBox} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" disabled={successCode > 0 ? false : true} nopadding={true} onClick={() => sendCodes()} />
                        </div>
                    </div>
                </div>
                {
                    data.blocks.titleListProducts?.inputs?.value ? (
                        <Pg className="titleListProducts" data={data} preview={preview} component={id} block={'titleListProducts'} inputCallback={inputCallback} />
                    ) : null 
                }
                <div className="productsContainer">
                    {
                        data.blocks.list_products ? 
                            data.blocks.list_products.config.map((product, i) => (
                                <a href={product.blocks.link.inputs?.value?.value ? product.blocks.link.inputs.value.value : null} rel="noopener noreferrer" target="_blank" className="product">
                                    <img src={typeof product.blocks.image.inputs.value?.value === 'string' ? product.blocks.image.inputs.value?.value : product.blocks.image.inputs?.value?.value?.filePath ? `${process.env.REACT_APP_MEDIAS}/${product.blocks.image.inputs?.value.value.filePath}` : 'http://via.placeholder.com/200x200/FFFFFF/53839D?text=Produit'} />
                                    <p style={{color: 'white', fontSize: 16, margin: '8px 0', width:'100%', textAlign: 'center'}}>Voir le produit</p>
                                    <p style={{
                                        fontSize: product.blocks.title?.inputs?.size?.value,
                                        fontWeight: 'bold',
                                        color: product.blocks.title?.inputs?.color?.value,
                                        backgroundColor: product.blocks.title?.inputs?.backgroundColor?.value,
                                        textTransform: product.blocks.title?.inputs?.textTransform?.value,
                                        textAlign: product.blocks.title?.inputs?.textAlign?.value,
                                        lineHeight: '1.2'
                                    }}>
                                        {product.blocks.title?.inputs?.value?.value ? product.blocks.title?.inputs?.value?.value : 'Produit'}
                                    </p>
                                </a>
                            ))
                        : null
                    }
                </div>
            </section>
        </div>
    );
};

GameCodeFinder.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GameCodeFinder;
