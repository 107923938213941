import { gql } from 'apollo-boost';

export const GET_NOTIFICATIONS = gql`
query getNotifications ($after:String,$first:Int,$user_email:String,$before:String,$isRead:Boolean){
    appUserNotifications(first:$first,appToken_identifier:$user_email,isRead:$isRead,after:$after,before:$before,order:{id:"desc"}) {
      totalCount
      edges {
        node {
            id
          appNotification{
            scheduledAt
            title
            message
            link
            id
          }
          isRead
        }
      }
      pageInfo {
        hasNextPage    
        hasPreviousPage 
        startCursor   
        endCursor  
        }
    }
}
`;

export const DELETE_APP_USER_NOTIFICATION = gql`
  mutation deleteUserNotification ($id:ID!) {
    deleteAppUserNotification(input: {id:$id}) {
      appUserNotification{
        id
      }
    }
  }
`;

export const CREATE_APP_USER_NOTIFICATION = gql`
  mutation createUserNotification ($appNotification:String,$isRead:Boolean!,$appToken:String) {
    createAppUserNotification(input: {appNotification:$appNotification,isRead:$isRead,appToken:$appToken}) {
      appUserNotification{
        id
      }
    }
  }
`;

export const CREATE_APP_NOTIFICATION = gql`
  mutation createNotification ($title:String!,$message:String,$link:String,$scheduledAt:String!,$asset:String,$appToken:[String],$status:String!) {
    createAppNotification(input: {title:$title,message:$message,link:$link,scheduledAt:$scheduledAt,asset:$asset,appToken:$appToken,status:$status}) {
      appNotification{
        id
      }
    }
  }
`;

export const UPDATE_APP_NOTIFICATION = gql`
  mutation updateNotification ($id:ID!,$title:String,$message:String,$link:String,$scheduledAt:String,$asset:String) {
    updateAppNotification(input: {id:$id,title:$title,message:$message,link:$link,scheduledAt:$scheduledAt,asset:$asset}) {
      appNotification{
        id
      }
    }
  }
`;

export const DELETE_APP_NOTIFICATION = gql`
  mutation deleteNotification ($id:ID!) {
    deleteAppNotification(input:{id:$id}){
      appNotification{
        id
      }
    }
  }
`;

export const GET_APP_TOKENS = gql`
query appTokens {
  appTokens{
        edges{
          node{
            id
            appToken
            identifier
          }
        }
  }
}
`;