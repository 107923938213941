import React, { useState } from "react";
import PG from '../../../../shared/components/CustomParagraph';
import H2 from '../../../../shared/components/CustomH2';
import Button from '../../../../shared/components/CustomButton';
import { LinkCustom } from "../_next/navigation";
import * as ga from '../../../../../tools/GA';

const Category = ({
    preview = false,
    id = null, 
    data = null,
    inputCallback = null,
    children = null,
    spread = false,
    products = [],
    params = null
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: '#212121',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    };

    let image_1 = data.blocks["media_1"]?.inputs.image?.value;
    let video_1 = data.blocks["media_1"]?.inputs.video?.value;
    let image_2 = data.blocks["media_2"]?.inputs.image?.value;
    let video_2 = data.blocks["media_2"]?.inputs.video?.value;
    let switchValue_1 = data.blocks["media_1"]?.inputs.switch.value;
    let switchValue_2 = data.blocks["media_2"]?.inputs.switch.value;

    let productId = data?.inputs?.produit?.value?.item?.id;
    let product = products.find(product => product.id === productId);
    
    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="text-image">
                <div className="text-image-content">
                    <div className="col-6 image text-right">
                        {switchValue_1 ?
                            <div className="video">
                                <iframe 
                                    width="100%" 
                                    height="100%" 
                                    src={video_1 ? `https://www.youtube.com/embed/${video_1}` : null} 
                                    frameborder="0" 
                                    allow="autoplay; encrypted-media"
                                    allowfullscreen 
                                    title="video_1"
                                />
                            </div>
                            :
                            <img src={typeof image_1 === 'string' ? image_1 : `${process.env.REACT_APP_MEDIAS}/${image_1?.filePath}`} alt="first" />
                        }
                    </div>
                    <div className="col-6 text">
                        <H2 data={data} preview={preview} component={id} block={'title_1'} inputCallback={inputCallback} />
                        <PG data={data} preview={preview} component={id} block={'paragraph_1'} inputCallback={inputCallback} />
                    </div>
                    <div className="col-6 text">
                        <H2 data={data} preview={preview} component={id} block={'title_2'} inputCallback={inputCallback} />
                        <PG data={data} preview={preview} component={id} block={'paragraph_2'} inputCallback={inputCallback} />
                        <div className="button">
                            <LinkCustom 
                                spread={spread} 
                                href="/product/[slug]" 
                                as={`/product/${productId}`}
                                external={params?.isExternal?.value}
                            >
                                <a>
                                    <Button 
                                        data={data} 
                                        preview={preview} 
                                        component={id} 
                                        block={'button'} 
                                        inputCallback={inputCallback} 
                                        onClick={() => {
                                            ga.pageview(product?.sku, product?.retailerUrl);

                                            // ga.event({
                                            //     action: 'view_item',
                                            //     params: {
                                            //         items: [{
                                            //             id: product?.sku,
                                            //             name: product?.attributes?.product_name?.fr_FR
                                            //         }]
                                            //     }
                                            // });

                                            if (!spread && params?.isExternal?.value) {
                                                try { window.parent.location.href = product?.retailerUrl; } 
                                                catch (e) { console.log(e); }
                                            }
                                        }}
                                    />
                                </a>          
                            </LinkCustom>
                        </div>
                    </div>
                    <div className="col-6 image text-left">
                        {switchValue_2 ?
                            <div className="video">
                                <iframe 
                                    width="100%" 
                                    height="100%" 
                                    src={video_2 ? `https://www.youtube.com/embed/${video_2}` : null} 
                                    frameborder="0" 
                                    allow="autoplay; encrypted-media"
                                    allowfullscreen 
                                    title="video_2"
                                />
                            </div>
                            :
                            <img src={typeof image_2 === 'string' ? image_2 : `${process.env.REACT_APP_MEDIAS}/${image_2?.filePath}`} alt="first" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Category;