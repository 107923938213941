const ForgetPassword = (link, image) => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
        * {
            margin:0;
            padding:0;
        }
        h1{
            color: #2B4553;
            line-height: 36px;
            font-size: 28px;
        }
      
        .texte {
            font-size:16px;
            line-height: 24px;
            color: #000;
        }
        
        td a.button {
            color: #fff;
            background-color: #59C870;
            text-decoration: none;
            border-radius: 4px;
            font-weight: bold;
            padding: 16px;
            cursor: pointer;
        }
        td a.button:hover{
          background-color: #4CAA5F;
        }
        @media screen and (max-width: 375px) {
            .texte {
            font-size:24px;
            line-height:25px !important;
            }
        }
    </style>
  </head>
  <body>
    <center>
      <table width="600">
        <tr>
          <td>
            <table width="100%" style="height:100px;">
              <tr>
                <td width="25%"></td>
                <td width="50%" style="text-align:center;">
                  <a href="#"><img src="${image}" width="100%" alt="Sinfin DXP"></a>
                </td>
                <td width="25%"></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table id="container1" width="100%">
              <tr height="50">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:25px;letter-spacing:2px;">
                  <h1>Réinitialiser votre mot de passe</h1>
                </td>
                <td width="8%"></td>
              </tr>
              <tr height="90">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:20px;letter-spacing:1px;">
                    Vous nous avez indiqué avoir oublié votre mot de passe. Si c'est vraiment le cas, cliquez ici pour en choisir un autre :
                </td>
                <td width="8%"></td>
              </tr>
              <tr height="90">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:25px;letter-spacing:0.8px;">
                    <a href="${link}" class="button">Choisir un nouveau mot de passe</button>
                </td>
                <td width="8%"></td>
              </tr>
              <tr height="90">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:20px;letter-spacing:1px;">
                    Si vous n'aviez pas l'intention de réinitialiser votre mot de passe, ignorez simplement cet e-mail, et votre mot de passe ne sera pas changé.
                </td>
                <td width="8%"></td>
              </tr>
              <tr height="20">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:20px;letter-spacing:1px;">
                   <hr>
                </td>
                <td width="8%"></td>
              </tr>
              <tr height="90">
                <td width="8%"></td>
                <td class="texte" width="84%" style="font-weight:lighter;text-align:left;font-family:Helvetica;line-height:20px;letter-spacing:1px;">
                  Si le bouton ne fonctionne pas, veuillez cliquer sur ce lien : <a href="${link}">${link}</a>
                </td>
                <td width="8%"></td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>
`

export default ForgetPassword;