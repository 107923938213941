import React from 'react';

const RulesBlock = ({
    isOpen,
    onClose,
    lang
}) => {
    
    return(
        lang === "fr_FR" ? 
            <>
                <div className="overlay_reglement" style={{display: isOpen ? 'block' : 'none'}} onClick={onClose}></div>
                <div className="text_reglement" style={{display: isOpen ? 'block' : 'none'}}>
                    <h2>Règlement LEGO / Wishlist de Noël 2021</h2>
                    
                    <h3>Article 1 : Entité organisatrice du concours</h3>

                    <p>La société LEGO® ci-après dénommée “société organisatrice”, est une société par actions simplifiées inscrite au registre du commerce et des sociétés de Paris (75) sous le numéro B 806 220 216, ayant son siège social 75 rue de Tocqueville 75017 PARIS, a chargé la société SINFIN, au capital de 135 000 €, immatriculée au RCS de Troyes sous le numéro 502 339 864 d’organiser jeu concours intitulé “Wishlist de Noël” et qui se déroule du mercredi 20 octobre au dimanche 05 décembre 2021 jusqu’à minuit fuseau horaire français UTC+01:00 (Paris).</p>

                    <h3>Article 2 : Conditions de participation </h3>

                    <p>Ce jeu-concours est gratuit et sans obligation d’achat. La participation au jeu concours organisé par la société LEGO® est limitée aux personnes physiques résidant en France métropolitaine et en Corse et disposant d’une adresse mail et d’une connexion Internet valide. Les membres du personnel de la société LEGO®, qu’ils soient employés normaux ou cadres, y compris leurs ascendants et descendants directs, ainsi que les sous-traitants, fournisseurs et partenaires de la société LEGO® et toutes les personnes ayant coopéré directement ou indirectement à l’élaboration et / ou la mise en oeuvre de ce concours, ne sont pas autorisées à participer au concours.</p>
                    <p>La participation au jeu-concours s’effectue en ligne sur <a href="https://www.legonoelmagique.com/" target="_blank" rel="noopener noreferrer">https://www.legonoelmagique.com/</a>"   </p>
                    <p>Pour participer au concours de la société LEGO®, les mineurs doivent obtenir l’accord préalable de leur représentant légal, qui assume la responsabilité de la participation.</p>
                    <p>La participation au jeu concours de la société LEGO® est soumise à une inscription nominale et une seule participation sera comptabilisée pour le tirage au sort.</p>
                    <p>Toute tentative de fraude d’un participant entraînera son exclusion définitive. La participation est strictement nominative et le participant ne peut en aucun cas jouer pour le compte d’autres participants. Seules les participations dûment complétées seront prises en compte. Toute indication inexacte, incomplète ou reçue avant ou après la date limite de participation entraînera l’élimination automatique du participant. </p>
                    <p>La société LEGO® peut s’engager à effectuer toutes les vérifications nécessaires pour s’assurer que les informations communiquées par le participant sont correctes, notamment en ce qui concerne son identité et son lieu de résidence. Par le seul fait de participer à ce concours, le participant autorise la société LEGO® sans limitation à effectuer ces vérifications.</p>
                    <p>Par le simple fait de participer à ce concours, le participant déclare accepter toutes les dispositions du présent règlement et se conformer à toutes les dispositions légales, réglementaires et éthiques applicables aux jeux et aux loteries de concurrence en France.</p>

                    <h3>Article 3 - Durée du jeu-concours </h3>

                    <p>16 gagnants seront tirés au sort parmi les participants. Pour pouvoir participer au tirage au sort, les participants doivent avoir participé entre le mercredi 20 octobre et dimanche 05 décembre 2021 jusqu’à minuit fuseau horaire français UTC+01:00 (Paris).</p>

                    <h3>Article 4 : Modalités de participation</h3>

                    <p>Le dispositif de ce jeu se fera uniquement en ligne.</p>
                    <p>Pour participer à ce jeu concours, il suffit de se rendre sur <a href="https://www.legonoelmagique.com/" target="_blank" rel="noopener noreferrer">https://www.legonoelmagique.com/</a>" entre le mercredi 20 octobre et dimanche 05 décembre 2021.</p>
                    <p>Le participant, pour lancer le jeu, devra cliquer sur le bouton ”Créer ma liste”. Le participant peut utiliser les filtres d’âge et d’univers LEGO® présents au-dessus du catalogue pour choisir 4 items LEGO® qui s’ajouteront ensuite à sa wishlist en dessous du catalogue. </p>
                    <p>Une fois les 4 items LEGO® sélectionnés, le joueur devra cliquer sur le bouton “valider ma liste” et renseigner son adresse e-mail, accepter le règlement du jeu et s’inscrire à la newsletter LEGO® s’il le souhaite, et cliquer sur “Participer au tirage au sort” pour s’inscrire au tirage au sort du jeu. Il peut retrouver les 4 items LEGO® sélectionnés juste en dessous du formulaire d’inscription.</p>
                    <p>Une fois son adresse e-mail renseignée, il est redirigé vers une page de confirmation d’inscription et le jeu est finalisé. </p>
                    <p>Le participant peut maximiser ses chances de gagner, en partageant le jeu concours sur facebook ou par mail.Le partage effectué donne une présence supplémentaire sur la liste de tirage au sort. </p>

                    <h3>Article 5 : Lots</h3>

                    <p>Chaque participant joue pour tenter de gagner les lots suivants (dans les conditions prévues ci-après) : </p>
                    <p>1 an de LEGO®* pour un gagnant. Le gagnant sera contacté afin de choisir 12 produits dans la limite de 200€ par unité. Une fois la sélection faite, un cadeau sera envoyé par mois pendant 1 an.</p>
                    <p>4 produits de sa wishlist pour 15 gagnants (valeur en fonction des produits indiqués sur sa wishlist) répartis comme suit ; 2 wishlists gagnantes pour l’activation JouéClub, 2 wishlists gagnantes pour l’activation Cdiscount, 2 wishlists gagnantes pour l’activation FNAC et 9 wishlists gagnantes pour le site LEGO®.</p>

                    <h3>Article 6 : Lots personnalisés</h3>

                    <p>Les lots sont personnalisés et ne peuvent pas être réclamés par un tiers. Si un lot est remporté par un mineur, il doit être réclamé par son / ses représentant(s) légal(aux).</p>
                    <p>Le gagnant ne pourra réclamer le lot sous aucune autre forme que celle prévue et décrite dans le présent règlement. </p>
                    <p>Les lots ne pourront être attribués sous une autre forme que celle prévue par le présent règlement. Il ne sera attribué aucune contre-valeur en espèce, en échange du lot gagné. En cas de force majeure, la société LEGO® se réserve le droit de modifier le lot ou de proposer d’autres lots de nature et de valeur similaires aux gagnants. En participant à ce concours, le participant reconnaît et accepte sans équivoque que la société LEGO® ne saurait en être tenue responsable.</p>

                    <h3>Article 7 : Tirage au sort des gagnants et remise des lots</h3>

                    <p>16 gagnants seront tirés au sort le lundi 06 décembre 2021 parmi les participants qui répondent aux critères de participation établis en vertu du présent règlement.</p>
                    <p>Le participant peut maximiser ses chances de gagner un lot en partageant le jeu-concours sur Facebook ou par mail. En partageant, l’adresse mail du joueur est de nouveau inscrite dans la base de données du tirage au sort et il voit donc ses chances doublées.</p>
                    <p>La société organisatrice prendra contact avec les gagnants via l’adresse mail indiquée lors de la participation des joueurs au jeu pour récupérer leurs coordonnées nécessaires pour l’envoi du lot. </p>
                    <p>La société organisatrice est en charge de la distribution des lots. Ils seront envoyés aux gagnants entre le 20 décembre 2021 et le 07 mars 2022.</p>
                    <p>La société LEGO® ne pourra être tenue responsable de tout retard dans la livraison du lot attribué, ni de tout retard, détérioration, destruction partielle ou totale ou toute forme de dysfonctionnement pouvant survenir lors de la livraison du lot attribué.</p>

                    <h3>Article 8 - Données nominatives et personnelles </h3>

                    <p>Dans le cadre du jeu concours, la société organisatrice collecte et traite, pour le compte de la société LEGO®, les coordonnées des participants afin de permettre leurs inscriptions au jeu concours. Les adresses mails des gagnants seront transmises à la société organisatrice par la société LEGO® pour l’acheminement des lots aux gagnants du jeu-concours.</p>
                    <p>L’utilisation des données personnelles des participants est fondée sur l’exécution du contrat que les participants ont conclu avec la société organisatrice en acceptant le présent règlement.  </p>
                    <p>Les données personnelles fournies par les participants dans le cadre du jeu-concours sont destinées à la société organisatrice, ou à des sous-traitants et/ou des prestataires pour des besoins de gestion. </p>
                    <p>Conformément à la réglementation en vigueur, les informations collectées sont destinées exclusivement à la société organisatrice et elles ne seront ni vendues, ni cédées à des tiers, de quelque manière que ce soit. Les renseignements communiqués par le participant sont destinés à l'usage de la société organisatrice dans le cadre de l'accès à son service conformément aux conditions générales de vente et dans le cadre de la gestion du présent jeu concours.</p>
                    <p>Les données personnelles seront détruites dans un délai de quatre mois suivant la fin du jeu concours. </p>
                    <p>La société organisatrice s'engage à ne pas divulguer vos données personnelles à un tiers sans obtenir votre autorisation expresse et préalable.</p>
                    <p>Toutefois, une exception est présente en cas d’inscription à la newsletter. En vous abonnant à celle-ci, vous recevrez la newsletter LEGO® Family qui regorge d’idées de jeu, de conseils aux parents, d’offres promotionnelles, de cartes d’anniversaire et informe des dernières nouveautés et évènements du Groupe LEGO. Vous pouvez vous désabonner de la newsletter à tout moment. Pour cela, il suffit de cliquer sur le lien “se désabonner” au bas de chaque e-mail. Pour savoir comment contrôler vos données personnelles; veuillez consulter notre politique de confidentialité.</p>
                    <p>La société organisatrice peut être amenée à divulguer des données personnelles aux autorités administratives ou judiciaires lorsque leur divulgation est nécessaire à l'identification, l'interpellation ou la poursuite en justice de tout individu susceptible de porter préjudice aux droits de la société organisatrice, de tout autre utilisateur ou d’un tiers. La société organisatrice peut enfin être légalement tenue de divulguer des données personnelles et ne peut dans ce cas s'y opposer.</p>
                    <p>Conformément au Règlement Général sur la Protection des Données personnelles, les participants peuvent exercer leurs droits d’accès, de rectification, d’opposition, d’effacement, de limitation du traitement et de portabilité pour toutes les données les concernant traitées par la société organisatrice pour le compte de la société LEGO® en envoyant un mail à l’adresse suivante: <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a> </p>
                    <p>Les participants peuvent également introduire une réclamation auprès de la CNIL. </p>

                    <h3>Article 9 : Responsabilité et droits de la société LEGO®</h3>

                    <p>Le participant s’engage à accepter et à respecter sans réserve toutes les dispositions du présent règlement.</p>
                    <p>Tout manquement à se conformer aux dispositions du présent règlement, toute fraude ou tentative de fraude de la part du participant entraînera automatiquement sa récusation.</p>
                    <p>La société LEGO® ne pourra être tenue responsable des dommages causés au participant ou à son équipement informatique au cours de sa participation au jeu LEGO®.</p>
                    <p>La responsabilité de la société LEGO® ne pourra être engagée si un participant est empêché de participer au concours organisé par la société LEGO®, quelle que soit la cause de cette immobilité.</p>
                    <p>La société LEGO® ne peut être tenue responsable des incidents pouvant résulter de l’utilisation du lot attribué.</p>
                    <p>La société LEGO® dégage toute responsabilité en cas de dysfonctionnement du réseau internet, des lignes téléphoniques, du matériel de réception empêchant le bon déroulement du jeu concours. En outre, sa responsabilité ne pourra en aucun cas être retenue pour des problèmes d’acheminement ou de perte de courrier électronique ou postal.</p>
                    <p>La société LEGO® dégage toute responsabilité en cas de défaillances techniques, anomalies matérielles et logiciels de quelque nature (virus, bug…) occasionnés sur le système du participant, sur son équipement informatique et sur les données qui y sont stockées ainsi qu’aux conséquences pouvant en découler sur ses activités personnelles, professionnelles ou commerciales.</p>
                    <p>Si les circonstances l’exigent, la société LEGO® peut modifier les conditions générales du concours organisé par la société LEGO® ou annuler celui-ci sans que sa responsabilité ne soit engagée.</p>

                    <h3>Article 10 : Dépôt et accès au présent règlement</h3>

                    <p>Le présent règlement est déposé en l’étude de Maîtres Jean-Marc GOBET et Christophe CLEMENT située à TROYES (10000), 26 Boulevard Gambetta.</p>
                    <p>Il pourra être adressé sur simple demande.</p>
                    <p>Les frais d’envoi de cette demande seront remboursés sur la base d’un timbre-poste au tarif lent en vigueur en France.</p>
                    <p>Il ne sera adressé qu’un seul remboursement par foyer (même nom, même adresse) et envoi. Sur simple demande écrite aux mêmes adresses que l’article 6, les frais de connexion à internet nécessaire, à la lecture du règlement seront remboursés au tarif local en heure pleine en vigueur (frais de connexion remboursés sur la base de 5 minutes de connexion RTC).</p>
                    <p>La demande de remboursement doit être accompagnée d’un justificatif de l’opérateur télécom ou du fournisseur d’accès internet, mentionnant la date, l’heure et la durée de l’appel. Il est entendu qu’il n’y a pas de remboursement dans le cas où la participation est effectuée dans le cadre d’un forfait illimité (ADSL, Câble ou autre…).</p>
                    <p>Le remboursement des sommes (timbre ou connexion) se fera par virement bancaire ou chèque au choix par la société organisatrice, après fourniture par le plaignant de tous les renseignements nécessaires à l’opération.</p>
                    <p>La société organisatrice se réserve la possibilité de proroger, de suspendre ou d’annuler purement et simplement le jeu-concours pour quelque raison que ce soit, sans que sa responsabilité ni celle de la société LEGO® puisse être engagée.</p>
                    <p>La participation à ce jeu-concours implique l’acceptation pleine et entière du présent règlement dans son intégralité.</p>

                    <h3>Article 11 : Protection des données personnelles et individuelles </h3>

                    <p>Les données collectées au cours du concours seront traitées conformément à la loi n° 78-17 du 6 janvier 1978 relative à l’informatique et aux libertés.</p>
                    <p>Les participants au concours et leur(s) représentant(s) légal(aux), si le participant est mineur, ont le droit d’accéder, de rectifier et d'effacer les données qu’ils communiquent en vertu des dispositions de la présente loi. </p>

                    <h3>Article 12 : Réclamations</h3>

                    <p>Toute contestation ou réclamation à ce jeu-concours ne sera prise en compte que si elle est adressée avant le mardi 07 décembre 2021, le cachet de la poste faisant foi.</p>
                    <p>Une assistance support pour ce jeu concours est en place à l’adresse suivante ; <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a></p>
                    <p>Toute contestation ou réclamation devra être formulée par lettre simple adressée à la société organisatrice dont les coordonnées sont : 1 avenue Louis François Armand - 10430 Rosières-près-Troyes. Cette lettre devra indiquer la date précise de participation au jeu-concours, les coordonnées complètes du participant et le motif exact de la contestation. Aucun autre mode de contestation ou réclamation ne pourra être pris en compte. La société organisatrice sera seule souveraine pour trancher toute question d’application ou d’interprétation du règlement ou en cas de lacunes de celui-ci à l’occasion du déroulement du présent jeu-concours.</p>

                    <h3>Article 13 : Loi applicable et tribunal compétent</h3>

                    <p>Les participants sont soumis au droit français. </p>
                    <p>Le concours et le présent règlement sont également régis par le droit français.</p>
                    <p>Les partis s’efforcent de résoudre à l’amiable tout différend né de l’interprétation ou de l’exécution du présent règlement. </p>
                    <p>Si le désaccord persiste, il sera soumis aux tribunaux compétents. Le participant reconnaît avoir pris connaissance du présent règlement, de l’accepter sans réserve et de s’y conformer.</p>
                    <div className="button_close">
                        <button className="as_button" onClick={onClose}>X</button>
                    </div>
                </div>
            </>
        : 
        <>
                <div className="overlay_reglement" style={{display: isOpen ? 'block' : 'none'}} onClick={onClose}></div>
                <div className="text_reglement" style={{display: isOpen ? 'block' : 'none'}}>
                    <h2>SORTEO “CHRISTMAS WISHLIST” / BASES LEGALES COMPLETAS</h2>
                    <p>Lego SA, con sede en C/ Orense, 34 - Planta 4, 28020 Madrid, España. NIF: A28347821 (en lo sucesivo, “el Promotor”) organiza, en colaboración con Sinfin, con sede en 1 avenue Louis François Armand 10430 Rosières-près-troyes, Francia, FR56502339864, el presente sorteo llamado “Christmas Wishlist”.<br />
El sorteo está abierto a todas las personas físicas residentes en España y que cuenten con conexión a Internet y con una cuenta de correo electrónico válida. Los menores de edad podrán participar con el previo consentimiento de los progenitores o del tutor legal, los cuales asumirán la responsabilidad de la participación. No podrán participar en la presente promoción ningún empleado del Promotor, sus familiares directos, los agentes o todas aquellas personas profesionalmente vinculadas a esta promoción. </p>
                    <p style={{textDecoration: "underline"}}><strong>La participación en el Sorteo es gratuita.</strong></p>
                    <p><i>Al participar en este Sorteo, los participantes declaran que aceptan todas las cláusulas de las presentes bases legales.</i></p>
                    <h3>1. Período de la promoción</h3>

                    <p>La promoción es válida de las 00:00 (CET) del 12 de noviembre de 2021 a las 23:59 (CET) del 19 de diciembre de 2021 (en lo sucesivo, el "Período de participación”). La elección de los ganadores se llevará a cabo antes del 31 de diciembre de 2021 (en lo sucesivo el "Sorteo final").</p>

                    <h3>2. Modalidad de participación</h3>

                    <p>Para participar en esa promoción, que se celebrará entre el 12 de noviembre y el 19 de diciembre de 2021, los participantes deberán visitar la web <a href="https://www.legonavidad.com/" target="_blank" rel="noopener noreferrer">legonavidad.com</a> y crear su lista de deseos compuesta por 4 productos Lego.
                    <br/>Para agilizar el juego, deberán hacer clic en el botón “Crear mi lista” y, para elegir los productos, podrán utilizar los filtros de edad y el universo LEGO® presente encima del catálogo: los productos elegidos serán añadidos a la “wishlist” presente debajo del catálogo.
                    <br/>Una  vez seleccionados los 4 objetos LEGO®, el participante deberá hacer clic en "Convalidar mi lista", introducir su dirección de correo electrónico, aceptar las reglas del juego y, con carácter opcional, suscribirse al boletín informativo LEGO®; por último, deberá hacer clic en el botón "Participar en el sorteo" para poder participar en el sorteo.
                    <br/>El participante podrá incrementar sus posibilidades de premio si al término de la participación comparte el concurso en facebook o a través de correo electrónico utilizando todas las herramientas previstas en el sitio web del concurso: compartir permitirá que el participante sea incluido una vez más en la base de datos del sorteo.
                    <br/>Todas las personas que se registren de acuerdo con lo establecido en el presente artículo, participarán en el sorteo de los premios (más información en el punto 3).</p>
                    <p>Límites de participación: no podrá participar en el sorteo más de una vez una misma dirección de correo electrónico.</p>
                    <p><i><strong>El promotor se reserva el derecho, en todo momento, de comprobar la validez de las participaciones, incluida la identidad, edad y lugar de residencia de los participantes, así como de descalificar a aquellos que hayan infringido las cláusulas de estas bases legales o que hayan actuado de manera fraudulenta.</strong></i></p>

                    <h3>3. Elección de los ganadores</h3>
                    <p>El sorteo para la elección de los ganadores se celebrará antes de la fecha indicada en el punto 1. <br/>Se efectuará un sorteo entre todos los participantes registrados y, en caso de haber compartido la promoción a través de facebook o correo electrónico, el participante será incluido dos veces en la base de datos. Se extraerán al azar 11 ganadores a los que se les adjudicarán los siguientes premios:</p>
                    <ul>
                        <li>El 1º ganador extraído ganará 1 año de Lego® (más información en el siguiente artículo);</li>
                        <li>Los ganadores extraídos en 2º a 11º lugar se les adjudicarán los productos seleccionados en su lista de deseos.</li>
                    </ul>
                    <p>Se extraerán también 5 suplentes, que serán contactados en caso de que no sea posible, por cualquier motivo, asignar el premio a uno de los ganadores.</p>
                    <p>Límites para la asignación de los premios: no se podrá adjudicar más de un premio a cada persona física.</p>

                    <h3>4. Información sobre los premios</h3>

                    <p>Los premios en juego consisten en:</p>
                    <ul>
                        <li>1 año de Lego®, consistente en los 12 productos Lego® elegidos por el ganador, cada uno de ellos con un valor máximo unitario de 200 €. El valor máximo de este premio es de 2.400,00 €.</li>
                        <li>10 listas de deseos, cada una de ellas compuesta por los 4 productos seleccionados por los ganadores en la fase de participación en la promoción. El valor total máximo de cada lista de deseos es de 3.196 €.</li>
                    </ul>

                    <p>El valor total máximo del conjunto de los premios es de 34.360 €. 
                    <br/>El premio no podrá ser cedido a terceros ni solicitado por una tercera persona que no sea el ganador </p>

                    <h3>5. Notificación de la victoria y entrega de premios</h3>

                    <p>Los ganadores serán avisados por correo electrónico en el plazo de los 15 días hábiles siguientes a la fecha del sorteo.  
                    <br/>Para poder recibir el premio, deberán convalidar la victoria respondiendo al correo electrónico en el plazo de los 15 días naturales siguientes y enviar todo cuanto se les solicite, concretamente: </p>
                    <ul>
                        <p>- Copia de un documento de identidad en curso de validez;</p>
                        <p>- La indicación de su dirección completa y de un número de teléfono a los efectos del envío del premio.</p>
                    </ul>
                    <p>En caso de no responder al correo electrónico de notificación en el plazo indicado, no podrá convalidarse el premio y se contactará con un suplente, el cual deberá observar los mismos plazos de aceptación. 
                    <br/>En caso de victoria de un menor de edad, la aceptación del premio deberá ser efectuada por el progenitor o el tutor legal. </p>
                    <p>La sociedad promotora y los terceros contratados por la primera no asumen ninguna responsabilidad en caso de falta de entrega o entrega tardía del correo electrónico de notificación. Corresponde al ganador comprobar que la dirección de correo electrónico indicada en la fase de participación sea correcta y válida y que el buzón esté activado y funcione.</p>
                    <p><strong>Importante: la recepción de la documentación antes indicada es una condición necesaria para la validación del premio:</strong> en caso contrario no podrá validarse; el Promotor se reserva el derecho a realizar las comprobaciones necesarias para averiguar la regularidad de la participación así como la corrección de los datos indicados por el participante. </p>
                    <p>El promotor se reserva el derecho de descalificar a los participantes o ganadores que hayan infringido una de las cláusulas de estas bases legales. </p>
                    <p>Se entregarán los productos de las listas de deseos elegidos por los ganadores en un plazo de 60 días tras la validación del premio.
                    <br/>Los 12 productos del premio “1 año de Lego®” serán entregados al ganador de uno en uno y, concretamente, uno por cada mes del año.</p>
                    <p>Cabe señalar que: </p>
                    <ul>
                        <li>el promotor no acepta ninguna responsabilidad en caso de daños o pérdidas que se deriven de la indicación errónea o inexacta de las direcciones por parte de los participantes.</li>
                        <li>el Promotor se reserva el derecho de elegir, según su criterio, las modalidades de envío más adecuadas. El Promotor no podrá ser considerado responsable en caso de que la recepción del premio comporte el pago de unos aranceles aduaneros. El Promotor no ofrecerá ninguna alternativa en metálico en caso de que los premios no puedan ser disfrutados o recibidos debido a problemas en la expedición u otras normativas locales, o en caso de premios dañados, recibidos con retraso o extraviados por causas imputables al servicio postal o de mensajería.</li>
                        <li>El ganador no podrá reclamar un premio alternativo ni su valor correspondiente en metálico. Sin embargo, en caso de que el Promotor no pudiera entregar el premio prometido por circunstancias imprevisibles o ajenas al control del propio Promotor, se reserva el derecho de modificar, en su totalidad o parcialmente, la composición del premio o bien sustituirlo por un premio por un valor equivalente o superior.</li>
                    </ul>

                    <h3>6. Fiscalidad</h3>
                    <p>Los premios de esta promoción estarán sujetos a retención o ingreso a cuenta de acuerdo a la normativa fiscal vigente sobre el impuesto a la Renta de las Personas Físicas (IRPF). 
                    <br/>El promotor practicará el ingreso en Hacienda del importe que corresponda a cuenta del Impuesto sobre la Renta de las Personas Físicas (IRPF) conforme la normativa de este impuesto, ello sin perjuicio del efecto que represente en la declaración personal de IRPF del ganador. A tal fin, el ganador deberá entregar fotocopia de su DNI y facilitar todos los datos personales y fiscales que el promotor  le solicite.</p>

                    <h3>7. PRIVACY</h3>
                    <p><i>Información que debe proporcionarse en virtud del art. 13 del Reglamento Europeo 679/2016 (el llamado "RGDP")</i></p>
                    <p>Los datos personales espontáneamente proporcionados, serán tratados con modalidades preferentemente electrónicas y con instrumentos de análisis estadístico, por Lego SA, con sede en C/ Orense, 34- Pta. 4 28020 Madrid, España (Responsable del tratamiento), para la realización de todas las fases relacionadas con el concurso denominado "Christmas Wishlist". Los datos solicitados son obligatorios para los propósitos antes mencionados y su no asignación impedirá la realización de las actividades de gestión de la concesión del premio y el cumplimiento de las normas legales.</p>
                    <p>Con el previo consentimiento explícito, los datos serán tratados por el Responsable para el envío de la “newsletter family”. Dichos contactos promocionales serán realizados a través de correo electrónico.</p>
                    <p>La conservación de los datos para la gestión del concurso se limitará al período de realización de sus fases individuales, o sea 4 meses a partir de la fin del concurso, para asegurar el contacto con los ganadores. Posteriormente serán anonimizados para la elaboración de estadísticas y los datos identificativos serán destruidos.</p>
                    <p>Por el contrario, cuando se haya otorgado el consentimiento para contactos promocionales (newsletter), los datos se conservarán en nuestros archivos hasta que se considere que perdura el interés por los productos en venta de Lego® o en respuesta al ejercicio de los derechos de oposición al tratamiento con fines de marketing por parte del interesado. De igual modo, en este caso, una vez transcurrido el período de conservación de los datos, estos serán anonimizados para la elaboración de estadísticas y los datos identificativos serán destruidos y eliminados de nuestros archivos.</p>
                    <p>El encargado del tratamiento es: Sinfin, con sede en 1 avenue Louis François Armand, 10430 Rosières-près-troyes, Francia.</p>
                    <p>Las personas autorizadas para el tratamiento son los encargados de ventas y marketing, del call center, de los sistemas informáticos y de la seguridad de los datos.</p>
                    <p>De conformidad con los artículos 15-21 del GDPR, mediante el envío del escrito al responsable a dicha dirección postal o al correo electrónico support@legonoelmagique.com podrán ejercerse los derechos de consulta, modificación, cancelación y eliminación, limitación del tratamiento de los datos u oposición a su tratamiento por motivos legales o con fines informativos y promocionales. En caso de revocación del consentimiento otorgado, que puede presentarse en cualquier momento, queda entendido que la revocación no perjudica la legalidad del tratamiento basado en el consentimiento previamente manifestado ni en los mecanismos alternativos al consentimiento permitidos por la ley. Cabe señalar asimismo que el interesado tiene derecho a presentar una reclamación a la autoridad de control para hacer valer sus propios derechos. Mediante la comunicación a la dirección postal antes indicada o el envío de un correo electrónico a <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a> podrá solicitar la lista completa y actualizada de los encargados del tratamiento.</p>
                    <p>Por último, existe el derecho de solicitar la portabilidad de los datos, es decir recibir los datos propios en formato estructurado, de uso común y legible con los dispositivos electrónico habituales, para transmitirlos directamente a otro sujeto, responsable autónomo del tratamiento, con el fin de que pueda tratarlos dentro de los límites establecidos por la persona interesada.</p>
                    <p>Toutefois, une exception est présente en cas d’inscription à la newsletter. En vous abonnant à celle-ci, vous recevrez la newsletter LEGO® Family qui regorge d’idées de jeu, de conseils aux parents, d’offres promotionnelles, de cartes d’anniversaire et informe des dernières nouveautés et évènements du Groupe LEGO. Vous pouvez vous désabonner de la newsletter à tout moment. Pour cela, il suffit de cliquer sur le lien “se désabonner” au bas de chaque e-mail. Pour savoir comment contrôler vos données personnelles; veuillez consulter notre politique de confidentialité.</p>
                    <p>La société organisatrice peut être amenée à divulguer des données personnelles aux autorités administratives ou judiciaires lorsque leur divulgation est nécessaire à l'identification, l'interpellation ou la poursuite en justice de tout individu susceptible de porter préjudice aux droits de la société organisatrice, de tout autre utilisateur ou d’un tiers. La société organisatrice peut enfin être légalement tenue de divulguer des données personnelles et ne peut dans ce cas s'y opposer.</p>
                    <p>Conformément au Règlement Général sur la Protection des Données personnelles, les participants peuvent exercer leurs droits d’accès, de rectification, d’opposition, d’effacement, de limitation du traitement et de portabilité pour toutes les données les concernant traitées par la société organisatrice pour le compte de la société LEGO® en envoyant un mail à l’adresse suivante: <a href="mailto:support@legonoelmagique.com">support@legonoelmagique.com</a> </p>
                    <p>Les participants peuvent également introduire une réclamation auprès de la CNIL. </p>

                    <h3>8. Disposiciones Finales</h3>
                    <ul>
                        <li>El promotor no se hace responsable de cualquier daño, gasto o pérdida (directa / indirecta y / o) de cualquier participante o ganador como resultado de la participación en esta promoción o después del uso del premio ganado, junto con la responsabilidad del promotor en caso de muerte o lesión como resultado de su negligencia.</li>
                        <li>El promotor no se hace responsable de cualquier problema de error técnico o humano que pueda afectar a la correcta participación en la promoción.</li>
                        <li>El promotor no asume ninguna responsabilidad por acciones o servicios prestados por terceros.</li>
                        <li>El promotor no asume ninguna responsabilidad en el caso de la ocurrencia de un acto, omisión, evento o circunstancia más allá del control razonable del promotor y que impide que al promotor cumplir con sus obligaciones de conformidad con las bases legales o que suponga un retraso en el desempeño del mismo.</li>
                        <li>Cuando hay circunstancias que dificulten el buen desarrollo de la promoción o que se impida la promoción se lleva a cabo como se había prometido, por cualquier razón más allá del control razonable del promotor, por ejemplo en caso de guerra, actos de terrorismo, emergencia, desastre natural (u otro), las infecciones debidas a virus informativos, gusanos informáticos, el acceso no autorizado al software, dificultades técnicas o cualquier otra cuestión que afecte a la seguridad, la integridad o el buen desarrollo de la promoción, el Promotor se reserva el derecho, a su sola discreción y en la medida máxima permitida por la ley (a) descalificar a cualquier participante o (b) de acuerdo con las instrucciones de la autoridad competente, de modificar, suspender, cancelar o cancelar la presente promoción, incluso parcialmente y en cualquier momento, dando la debida antelación, no introduciendo cambios peyorativos y salvaguardando los derechos ya adquiridos por los participantes.</li>
                        <li>El promotor se reserva el derecho de realizar todos los controles relacionados con la correcta participación en la promoción y comprobar la validez de las participaciones y de descalificar a cualquier participante o ganador que haya intentado alterar la mecánica de participación. El Promotor también se reserva el derecho de descalificar a cualquier persona cuya participación no esté en conformidad con las disposiciones de las presentes bases legales, y / o haya llevado a cabo cualquier acción que pueda alterar el curso normal de la promoción. Si esto sucede, el promotor se reserva el derecho a exigir la indemnización por daños y perjuicios.</li>
                    </ul>
                    <p>Las presentes bases legales pueden consultarse en <a href="https://www.legonavidad.com/" target="_blank" rel="noopener noreferrer">legonavidad.com</a></p>
                    <p>La comunicación y la publicidad se efectuará en la tienda y en línea en el sitio web <a href="https://www.legonavidad.com/" target="_blank" rel="noopener noreferrer">legonavidad.com</a></p>
                    <p><strong>Esta promoción se regula de acuerdo con la legislación española (“Ley 13/2011 de Regulación del juego” 27/05/2011).</strong></p>
                    
                    <div className="button_close">
                        <button className="as_button" onClick={onClose}>X</button>
                    </div>
                </div>
            </>
    )
}

export default RulesBlock;