import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formAttributEdit(allGroups, attributeType, currentLang, allInputType, allLang, selectedGroups, isSystem = false) {
    let currentTypeInput = allInputType.find(
        type => type.node.id === attributeType
    );

    var obj = {
        titleForm: 'Modifier une variable de modèle',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour modifier votre variable',
        langSelect: true,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            children: [
                {
                    labelName: 'Propriétés',
                    optionsInputs: [
                        {
                            type: 'selectTree',
                            label: 'Modèles',
                            translated: false,
                            disabled: isSystem,
                            helper: {
                                text: 'Indiquez les modèles de cette variable',
                                link: false,
                            },
                            required: false,
                            stateName: 'selectedGroups',
                            multiselect: true,
                            data: allGroups.map(group => {
                                group.label     = group.locales.find(e => e.code === currentLang)?.value ?? group.locales[0].value;
                                group.checked   = selectedGroups.find(e => e.node.id === group.node.id) ? true : false;
                                group.expanded  = true;
                                group.children  = [];
                                
                                return group;
                            })
                        },
                        {
                            type: 'text',
                            label: 'Libellé',
                            translated: true,
                            helper: {
                                text: "Libellé de la variable",
                                link: false,
                            },
                            required: false,
                            stateName: 'attributeName',
                        },
                        {
                            type: 'text',
                            label: 'Identifiant',
                            translated: false,
                            helper: {
                                text: "Identifiant de la variable",
                                link: false,
                            },
                            required: true,
                            stateName: 'attributeIdentifier',
                            disabled: true
                        },
                        {
                            type: 'select',
                            label: 'Type',
                            translated: false,
                            helper: {
                                text: 'Indiquez le type de cette variable',
                                link: false,
                            },
                            required: true,
                            disabled: true,
                            stateName: 'attributeType',
                            value: 
                                allInputType.map(node => {
                                    return(
                                        {
                                            value : node.node.id,
                                            label : node.node.translation.translationDatas.edges[0]?.node.value ?? node.node.input,
                                            disabled : node.node.id === attributeType,
                                        }
                                    )
                                })
                        },
                        {
                            type: 'buttonGroup',
                            label: 'Requis',
                            helper: {
                                label: 'État',
                                text: 'Indiquez si cette variable est requise',
                                link: false,
                            },
                            required: true,
                            disabled: isSystem,
                            stateName: 'isRequired',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui',
                                },
                                {
                                    value: false,
                                    label: 'Non',
                                },
                            ]
                        },
                        {
                            type: 'buttonGroup',
                            label: 'Recherchable',
                            helper: {
                                label: 'État',
                                text: 'Indiquez si cette variable est recherchable',
                                link: false,
                            },
                            required: true,
                            disabled: isSystem,
                            stateName: 'isSearchable',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui',
                                },
                                {
                                    value: false,
                                    label: 'Non',
                                },
                            ]
                        },
                    ]
                },
                currentTypeInput?.node.input === 'select' ?? null
                    ? {
                        labelName: 'Valeurs',
                        isOptionnal: false,
                        optionsInputs: [
                            {
                                type: 'rows',
                                translated: false,
                                required: true,
                                stateName: 'values',
                                currentLang
                            }
                        ]
                    } 
                    : null
            ]
        }
    }
    return obj;
}