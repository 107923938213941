import React, { useEffect, useState } from 'react';
import {Box, Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardCurrencies from '../../../layouts/Card/cardContent/CardCurrencies';
import ValidateCurrencies from './validate/ValidateCurrencies';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { withApollo } from 'react-apollo';
import { GET_CURRENCIES } from '../../../../queries/currencies';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {withTranslation} from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';
import emptyProduct from '../../../../assets/pictos/empty-picto/empty_assets.png';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import styled from 'styled-components';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PageWrapper = styled(Box)`
    position: relative;
    min-height: calc(100% - 64px);
    height: calc(100% - 64px);
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
`;

const SettingsCurrencies = (props) => {
    // State
    const [currencies, setCurrencies] = useState({
        total: [],
        existent: []
    })
    const [numberCurrencies, setNumberCurrencies] = useState({
        value: 0,
        totalValue: 0,
        initialValue: 0,
    })
    const [changes, setChanges] = useState([])
    const [isValidate, setIsValidate] = useState(false)
    const [noCurrency, setNoCurrency] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [snack, setSnack] = useState({
        text: 'La suppression de la devise s\'est déroulée avec succès',
        type: 'success',
        isOpen: false
    })

    // Hooks
    useEffect(() => {
        checkRouting(props);
        handleGetCurrencies();
    }, [])

    // Functions
    const handleGetCurrencies = async () => {
        const result = await props.client.query({
            query: GET_CURRENCIES,
            fetchPolicy: 'no-cache'
        })

        setCurrencies(prev => ({...prev, total: []}))

        if(result.data.currencies.edges.length > 0){
            setCurrencies(prev => ({...prev, existent: result.data.currencies.edges}))
            setNumberCurrencies(prev => ({
                ...prev, 
                initialValue: result.data.currencies.edges.length,
                totalValue: result.data.currencies.edges.length
            }))
            setNoCurrency(false)
        }
        setIsLoading(false)
    }

    const handleAddCurrency = () => {
        setNumberCurrencies(prev => ({...prev, totalValue: prev?.totalValue + 1}))
        currencies?.total?.push({
            "libelle": '',
            "code" : '',
            "id": numberCurrencies?.totalValue,
        });
        setIsValidate(false)
    }

    const handleRemoveExistentCurrency = () => {
        if(numberCurrencies?.initialValue === 1){
            setNumberCurrencies(prev => ({...prev, initialValue: 0}))
            setNoCurrency(true)
        } else {
            setNumberCurrencies(prev => ({...prev, initialValue: numberCurrencies?.initialValue - 1}))
        }
        setSnack(prev => ({...prev, isOpen: true}))
        handleGetCurrencies();
    }

    const handleRemoveCurrency = (index) => {
        setNumberCurrencies(prev => ({...prev, totalValue: prev?.totalValue - 1}))

        let i = 0;
        for (let currency of currencies?.total) {
            if(i>index){
                currency.id = currency.id - 1;
            }
            i++;
        }
        currencies?.total.splice(index, 1);
    }

    const handleResetData = () => {
        handleGetCurrencies();
        setChanges([])
    }

    const handleCheckValidate = () => {
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.libelle === '' || item.data.code === ''){
                    success = false;
                }
            }
            
        }
        for (let currency of currencies?.total) {
            if (success) {
                if(currency.libelle === '' || currency.code === ''){
                    success = false;
                }
            }
        }

        if(success) {
            setIsValidate(true)
        }else {
            setIsValidate(false)
        }
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') return
        setSnack(prev => ({...prev, isOpen: false}))
    };

    const handleChoiceInputChange = (data) => {
        let found = false;
        for (let item of changes) {
            if (item.id === data.id) {
                handleCheckValidate();
                found = true;
            }
        }
        
        if (!found)
            changes.push(data);
        
        setChanges([].concat(changes))
        setIsValidate(true);
    }

    const handleNewCurrencyInputChange = (data) => {
        for (let currency of currencies?.total) {
            if (currency.id === data.id) {
                currency.libelle = data.data.libelle;
                currency.code = data.data.code;
                handleCheckValidate();
            }
        }
    }

    return (
        <PageWrapper>
            <TopPanel 
            icomoon="ico_devises" 
            colorIcomoon={colors.blue.darker.hue300} 
            title={props.t('settings.currencies.title')} 
            subtitle={props.t('settings.currencies.subTitle')} 
            handlerAdd={!isLoading ? handleAddCurrency : null} 
            textAdd={props.t('settings.currencies.buttonadd')}
            gradientColor1={colors.menu.regular} 
            gradientColor2={colors.menu.darker} 
            buttonAvailable={true}
            hasBorder={true}
            windowWidth={props.windowWidth}
            />
            <Box style={{
                position: 'relative'
            }}>
                <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 16}}>
                    <Grid container direction="row" spacing={4}>
                        {
                            !isLoading ?
                                (
                                    noCurrency ? 
                                        currencies?.total.length === 0 ? 
                                            (<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={this.props.t('settings.tax.buttonadd')} onClick={handleAddCurrency} picto={emptyProduct} mtImg={2} />) 
                                        :
                                            null
                                    : 
                                        (
                                            currencies?.existent.map((currency, i) => {
                                                return(
                                                    <React.Fragment key={`ContainerDevis${i}`}>
                                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                                            <Typography>{props.t('settings.currencies.currencie')}  n°{i+1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                                <CardCurrencies
                                                                    windowWidth={props.windowWidth}
                                                                    type="existing"
                                                                    handleInputChange = {handleChoiceInputChange} 
                                                                    handleRemove={handleRemoveExistentCurrency} 
                                                                    currency={currency.node}
                                                                    disabled={null}
                                                                />
                                                            </CardCustom>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                ) 
                                : 
                                (<PageLoader />)
                        }
                
                        {
                            currencies?.total.map((currency, index) =>
                                <React.Fragment key={`ContainerCurrency${index}`}>
                                    <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                        <Typography>{props.t('settings.currencies.currencie')}  n°{currency.id}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CardCustom paddingbottom="8px" paddingtop="32px">
                                            <CardCurrencies
                                                windowWidth={props.windowWidth}
                                                type="new"
                                                handleInputChange={handleNewCurrencyInputChange} 
                                                handleRemove={() => handleRemoveCurrency(index)} 
                                                currency={currency} 
                                            />
                                        </CardCustom>
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    currencies?.existent.length > 0 || currencies?.total.length > 0 ? (
                        <ValidateCurrencies newCurrencies={currencies?.total} changesCurrencies={changes} validate={isValidate} handleResetData={handleResetData} />
                    ) : null
                }
                
                
                <Snackbar open={snack?.isOpen} autoHideDuration={10000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={snack?.type}>
                        {snack?.text}
                    </Alert>
                </Snackbar>
            </Box>
        </PageWrapper>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withTranslation()(withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsCurrencies))));