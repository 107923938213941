import { gql } from 'apollo-boost';

//----- Récupération des taxrates
export const GET_TAXRATES = gql`
{
  taxRates{
    edges{
      node{
        id
        code
        rate
        countryCode
        rateGroup{
          id
          code
          currency{
            id
            code
            libelle
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une taxrate
/*
{
  "code": "TVA",
  "rate": 0.2
}
 */
export const ADD_TAXRATE = gql`
mutation addTaxRate($code: String!, $rate: Float!, $rateGroup: String, $countryCode: String!) {
  createTaxRate(input:{code: $code, rate: $rate, rateGroup: $rateGroup, countryCode: $countryCode}){
    taxRate{
      id
      code
      rate
      countryCode
      rateGroup{
        id
        code
      }
    }
  }
}
`;

//----- Modifier une taxrate
/*
{
  "id": "/api/rax-rates/1",
  "code": "TVA",
  "rate": 0.2
}
 */
export const UPDATE_TAXRATE = gql`
mutation updateTaxRate($id: ID!, $code: String, $rate: Float, $rateGroup: String, $countryCode: String) {
  updateTaxRate(input:{id: $id, code: $code, rate: $rate, rateGroup: $rateGroup, countryCode: $countryCode}){
    taxRate{
      id
      code
      rate
      countryCode
      rateGroup{
          id
          code
          currency{
            id
            code
            libelle
          }
        }
    }
  }
}
`;

//----- Supprimer une taxrate
/*
{
  "id": "/api/rax-rates/1"
}
 */
export const DELETE_TAXRATE = gql`
mutation deleteTaxRate($id: ID!) {
  deleteTaxRate(input:{id: $id}){
    taxRate{
      id
    }
  }
}
`;