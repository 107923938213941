import { gql } from 'apollo-boost';

//----- Récupération des selections
export const GET_SELECTIONS = gql`
{
  selections{
    edges{
      node{
        id
        identifier
      }
    }
  }
}
`;

//----- Récupération des selections Pagination
export const GET_SELECTIONS_PAGINATION = gql`
query selectionsPagination ($nbperpage: Int, $cursor: String, $cursorLast: String){
  selections (first: $nbperpage, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        identifier
      }
    }
  }
}
`;

//----- Ajouter une selection
/*
 Exemple de variables
{
  "identifier": "test",
  "productDatas": ["/api/product-datas/1"]
}
*/
export const ADD_SELECTION = gql`
mutation AddSelection($identifier: String!, $productDatas: [String]) {
  createSelection(input:{identifier: $identifier, productData: $productDatas}) {
    selection{
      id
      identifier
    }
  }
}`;

//----- Modifier une selection
/*
 Exemple de variables
{
  "id": "/api/selections/1",
  "identifier": "test",
  "productDatas": ["/api/product-datas/1"]
}
*/
export const UPDATE_SELECTION = gql`
mutation UpdateSelection($id: ID!, $identifier: String, $productDatas: [String]) {
  updateSelection(input:{id: $id, identifier: $identifier, productData: $productDatas}) {
    selection{
      id
      identifier
    }
  }
}`;

//----- Supprimer une currency
/*
 Exemple de variables
  {
    "id" : "/api/selections/1"
  }
*/
export const DELETE_SELECTION = gql`
mutation DeleteSelection($id: ID!) {
  deleteSelection(input:{id: $id}) {
    selection{
      id
    }
  }
}`;
