import { FormInputTypes } from '../../../shareable/types';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Grille',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fffff'
        },
        result: {
            label: 'Texte pagination',
            type: FormInputTypes.TEXT,
            value: 'résultat'
        },
        results: {
            label: 'Texte pagination au pluriel',
            type: FormInputTypes.TEXT,
            value: 'résultats'
        },
        addToWishlist: {
            label: 'Texte ajout',
            type: FormInputTypes.TEXT,
            value: 'Ajouter à la wishlist'
        },
        removeFromWishlist: {
            label: 'Texte retrait',
            type: FormInputTypes.TEXT,
            value: 'Retirer de la wishlist'
        },
    },
    blocks: { }
};