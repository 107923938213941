import React from 'react';
import {Grid} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardLanguage from '../../../layouts/Card/cardContent/CardLanguage';
import ValidateLanguages from './validate/ValidateLanguages';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';

import { Query, withApollo } from 'react-apollo';
import { GET_LOCALES } from '../../../../queries/locales';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, VIEW, CREATE, UPDATE, DELETE} from '../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { connect } from "react-redux";
import { withRouter } from "react-router";

class SettingsLanguages extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openForm: false,
            nbLang: 0,
            changes: [],
            languages: [],
            validate: false,
        }
        this.nbLang = 0;
        this.nbLangInitial = 0;
        this.loadingState = true;
    }

    handleInputChange = (stateName, evt, id) => {
        const value = evt.target.value;
        this.setState({
            [`${stateName}-${id}`]: value
        });
    };

    handleAddLanguage = () => {
        this.nbLang++;
        this.nbLangTotal = this.nbLangInitial + this.nbLang 
        this.state.languages.push({
            "id": this.nbLangTotal,
            "libelle": '',
            "code" : '',
        });
        this.setState({validate: false});
        this.forceUpdate();
    }
    handleRemoveLanguage = (index) => {
        this.nbLang--;
        for (let language of this.state.languages) {
            language.id = language.id - 1;
        }
        this.state.languages.splice(index,1);
        this.forceUpdate();
    }

    handleResetData = () => {
        this.setState({languages: []});
        this.setState({changes: []})
    }

    handleCheckValidate = () => {
        let changes = this.state.changes;
        let languages = this.state.languages;
        let success = true;
        for (let item of changes) {
            if (success) {
                if(item.data.libelle === '' || item.data.code === ''){
                    success = false;
                }
            }
            
        }
        for (let language of languages) {
            if (success) {
                if(language.libelle === '' || language.code === ''){
                    success = false;
                }
            }
        }
        if(success){
            this.setState({validate: true});
        }
        else{
            this.setState({validate: false});
        }
    }

    componentDidMount(){
        const getRights = hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, VIEW)
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }
    }

    render() {
        return (
            <div>
                <TopPanel 
                icomoon="picto-parametre" 
                colorIcomoon={colors.blue.darker.hue300} 
                title="Gérer les langues" 
                subtitle="Gestion de vos langues (création / modification / suppression)"  
                handlerAdd={this.handleAddLanguage} 
                textAdd={hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, CREATE) ? "Ajouter une langue" : null}
                gradientColor1={colors.menu.regular} 
                gradientColor2={colors.menu.darker} 
                buttonAvailable={true}
                hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{marginTop: 24}}>
                    <Grid container direction="row" spacing={4}>

                        <Query query={GET_LOCALES}>
                            {({ loading, error, data }) => {
                                if (loading) return <p>Chargement des langues...</p>;
                                this.nbLangInitial =  data.locales.edges.length;
                                this.loadingState = loading;
                                return data.locales.edges.map((locale, i) => (
                                    <React.Fragment key={`ContainerLangueQuery${i}`}>
                                        <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                            <Typography>Langue n°{i+1}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CardCustom paddingbottom="8px" paddingtop="32px">
                                                <CardLanguage
                                                    type="existing"
                                                    handleInputChange = {(data) => {
                                                        let changes = this.state.changes;
                                                        let found = false;
                                                        for (let item of changes) {
                                                            if (item.id === data.id) {
                                                                item.data = data.data;
                                                                this.handleCheckValidate();
                                                                found = true;
                                                            }
                                                        }
                                                        
                                                        if (!found)
                                                            changes.push(data);
                                                        
                                                        this.setState({ changes: [].concat(changes) });
                                                    }} 
                                                    handleRemove={hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, DELETE) ? this.handleRemoveLanguage : null} 
                                                    language={locale.node}
                                                    disabled={hasRights(CRM_SETTINGS, CRM_SETTINGS_LANGUAGES, UPDATE) ? null : 'disabled'}
                                                />
                                            </CardCustom>
                                        </Grid>
                                    </React.Fragment>
                                ));
                            }}
                        </Query>
                        {this.state.languages.map((language, index) =>
                            <React.Fragment key={`ContainerLangue${index}`}>
                                <Grid container style={{paddingLeft: 16, paddingTop: 16}}>
                                    <Typography>Langue n°{language.id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CardCustom paddingbottom="8px" paddingtop="32px">
                                        <CardLanguage
                                            type="new"
                                            handleInputChange={(data) => {
                                                for (let language of this.state.languages) {
                                                    if (language.id === data.id) {
                                                        language.libelle = data.data.libelle;
                                                        language.code = data.data.code;
                                                        this.handleCheckValidate();
                                                    }
                                                }
                                            }} 
                                            handleRemove={this.handleRemoveLanguage} 
                                            language={language} 
                                        />
                                    </CardCustom>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Grid>
                <ValidateLanguages newLanguages={this.state.languages} changesLanguages={this.state.changes} validate={this.state.validate} handleResetData={this.handleResetData} />
            </div>
        );
    }
    goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(null, mapDispatchToProps)(SettingsLanguages)));
