import { FormInputTypes } from '../../../shareable/types';
import { getDefaultTextarea } from '../../helpers';

export default {
    name: 'Footer',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        footerText: getDefaultTextarea("Texte du footer", "Dans le cadre du jeu concours, Ubisoft Entertainment (107 avenue Henri Fréville – BP 10704 – 35200 RENNES) a chargé la société SINFIN (1 avenue Louis François Armand – 10340 Rosières-près-Troyes) de collecter votre identifiant Ubisoft pour vous permettre de participer au jeu concours. Ubisoft transmettra également à la  société SINFIN l’adresse email des gagnants du jeu concours afin d’organiser la remise de leur prix. Ces  informations seront supprimées dans un délai de 3 mois à compter de la fin du jeu concours. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que vous avez conclu avec UBISOFT en acceptant le règlement du jeu concours, consultable. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours. Vous pouvez exercer vos droits d’accès, de rectification, d’opposition, d’effacement, de limitation du traitement et de portabilité pour toutes les données vous concernant traitées par la société SINFIN pour le compte d’UBISOFT en contactant le Délégué à la protection des données d’Ubisoft ici. Si vous estimez, après l’avoir contacté, que vos droits sur vos données n’ont pas été respectés, vous pouvez adresser une réclamation à la CNIL.", true, "#53839D", null, "initial", "initial", 14),
    }
};