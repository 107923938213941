import { getDefaultTextarea } from '../../helpers';

export default {
    name: 'Texte',
    blocks: {
        text: getDefaultTextarea(
            "Titre", 
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque iaculis ornare interdum. Sed maximus venenatis eros, a dictum libero molestie sed. Duis tempus molestie felis vitae feugiat.</p>", 
            true, 
            "#444", 
            "#fff", 
            null, 
            'center'
        ),
    }
};