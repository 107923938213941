import React from "react";
import { withRouter } from 'react-router';
const options = [
    'customer'
]
function NoResult(props){
    const goTo = (route) => {
        props.history.push(route);

    };

    return(
        props.component ?
            props.component()
        : <p>Pas de résultats</p>
        
    )
}
export default withRouter(NoResult);