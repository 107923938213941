import React from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Tab,
  Tabs,
  Drawer,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import {
  START_LOADING,
  STOP_LOADING,
  SNACK,
} from "../../../../js/constants/action-types";
import {
  ALERT_ERROR,
  ALERT_SUCCESS,
} from "../../../../js/constants/alert-types";
import { eventService } from "../../../../js/services/event.service";

import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import * as moment from "moment";
import _ from "lodash";
import request from "../../../../js/utils/fetch";
import colors from "../../../../config/theme/colors";
import CheckIcon from "@material-ui/icons/Check";
import { ROUTE_PRODUCTS_LIST } from "../../../../js/constants/route-names";
import {
  GET_CATEGORIES_FLAT,
  GET_CATEGORIES_ONLY,
} from "../../../../queries/categories";
import { ALLOWED_IN_PRODUCT } from "../../../../js/constants/medias-types";
import {
  ADD_PRODUCT,
  GET_PRODUCT_DATA,
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_DATA,
  ADD_PRODUCT_DATA,
  DELETE_PRODUCT_DATA,
  GET_PRODUCTS_PAGINATION_FLATS,
  GET_PRODUCT_DATA_LIMITED,
  GET_PRODUCT_SECONDARY_DATA,
  GET_PRODUCT_SOURCES,
  GET_PRODUCT_SOURCES_PRICES,
  GET_PRODUCT_DATA_SOURCE,
  GET_PRODUCT_TAXOS,
  ADD_PRODUCT_SOURCE_WORKFLOW,
  GET_RETAILER_PRODUCT_DATA,
  GET_CATEGORIES_DATA_LIMITED,
  GET_PRODUCT_SUPER_ATTRIBUTES,
} from "../../../../queries/products";
import formProductDetail from "./config/formProductDetail.config";
import formVariantProductAdd from "./config/formVariantProductAdd.config";

import CardProductDetails from "../../../layouts/Card/cardContent/CardProductDetails";
import LayoutBuilder from "../../../ui/form/LayoutFormBuilder";
import DialogModal from "../../../ui/dialog/DialogModal";
import TraductionSelect from "../../../layouts/TopPanel/TraductionSelect";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Select from "@material-ui/core/Select";
import { GET_SELECTIONS } from "../../../../queries/selections";
import styled from "styled-components";
import { checkRouting } from "../../../../js/utils/checkRouting";
import LogsActions from "../../../ui/logs-actions/LogsActions";
import Button from "../../../ui/button/Button";
import { CheckCircle, Edit, Info } from "@material-ui/icons";
import TopPanel from "../../../layouts/TopPanel/TopPanel";
import { withTranslation } from "react-i18next";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OurTypography from "../../../ui/typography/Typography";
import ScrapDrawer from "./components/ScrapDrawer";
import OurMenu from "../../../ui/menu/Menu";
import ReturnLink from "../../../ui/link/ReturnLink";
import {
  GET_MEDIA_CATEGORIES,
  GET_MEDIA_OBJECTS_CATEGORY,
} from "../../../../queries/mediaCategories";
import { menuEntry } from "../../../../js/utils/menuEntry";

const SelectCustom = styled(Select)`
  border-radius: 0;
  width: 100%;
  max-width: 170px;
  margin-right: 16;
  fieldset {
    border: none;
  }
  :before,
  &:after {
    display: none !important;
  }
  input,
  .MuiSelect-select {
    font-size: 14px;
    padding: 16px;
    padding-left: 8px;
    margin-right: 16px;
    color: ${colors.blue.darker.hue300};
    &:focus {
      background: none;
    }
    @media screen and (max-width: 1450px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 13px;
      line-height: 17px;
    }
    @media screen and (max-width: 960px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  svg {
    fill: ${colors.blue.darker.hue300};
  }
  & > p {
    margin: 0;
  }
`;

const DrawerCustom = styled(Drawer)`
  & > .MuiDrawer-paper {
    width: ${(props) =>
      props.drawerwidth
        ? typeof props.drawerwidth === "string"
          ? props.drawerwidth
          : `calc(50% - ${props.drawerwidth}px / 2)`
        : "calc(797px)"};
    padding: 60px;
    z-index: 1201;
  }
`;

const BoxClose = styled(Box)`
  width: 80px;
  padding-bottom: 0;
  color: ${colors.black.regular};
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
  span {
    margin-left: 4px;
  }
`;

const TabsCustom = styled(Tabs)`
  width: 100%;
  padding: 0 !important;
  min-height: auto;
  button {
    text-transform: initial;
    flex: 1;
    max-width: none;
    border: 1px solid ${colors.blue.darker.hue300};
    // &:first-child{
    //     border-top-left-radius: 20px;
    //     border-bottom-left-radius: 20px;
    // }
    // &:last-child{
    //     border-right: 1px solid ${colors.blue.lighter.hue300};
    //     border-top-right-radius: 20px;
    //     border-bottom-right-radius: 20px;
    // }
    &.Mui-selected {
      background: ${colors.blue.darker.hue300};
      color: white;
    }
    & > span:first-child {
      flex-direction: row;
    }
  }
  & > div > span {
    display: none;
  }
`;

const TabStyled = styled(Tab)`
  min-height: auto;
  color: ${colors.blue.darker.hue300};
  font-weight: bold;
  .MuiTab-wrapper {
    text-transform: capitalize;
  }
`;
const GridMessage = styled(Grid)`
  border: 1px solid ${colors.green.regular};
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
  padding: 8px !important;
  background-color: ${colors.green.lighter};
`;

const PageLayout = styled(Box)`
  /* display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr; */
  height: 100%;
`;

class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLang: props.locales[0].node.code,
      groupAttribut: "",
      customAttributes: [],
      metaAttributes: [],
      imageAttributes: [],
      imagesSelected: [],
      attributes: [],
      maxImageNumber: 0,
      imageSrc: [],
      productId: `/api/products/${props.match.params.id}`,
      retailersInfos: [],
      sku: null,
      title: "",
      categories: [],
      categoriesData: [],
      readyForCat: false,
      ready: false,
      categorieReady: false,
      description: "",
      status: "",
      metaTitle: "",
      metaDesc: "",
      openForm: false,
      openDialog: false,
      infoLogs: false,
      openDialogSource: false,
      allGroups: [],
      errors: {},
      secondErrors: {},
      seeErrors: false,
      variantToDelete: [],
      suggestionsProduct: [],
      listSources: [],
      currentCollection: null,
      listCollections: [],
      hasPrice: false,
      listSources: [],
      typeData: "Principal",
      selectedScrap: [],
      listTaxos: [],
      varianteReady: false,
      openScrapDrawer: false,
      currentSource: null,
      scrapAttributes: [],
      sourceData: [],
      scrapIsReady: false,
      scrapImages: [],
      drawerWidth: 0,
      previousPath: null,
      nextPath: null,
      scrapStatus: null,
      deleteItemFromNav: false,
      mediaCategories: [],
      selectedMediaCategories: [],
      skuPrice: null,
      reload: false,
    };
    this.widthRef = React.createRef();
  }

  getMediasCategories = () => {
    this.props.client
      .query({
        query: GET_MEDIA_CATEGORIES,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        this.setState({
          mediaCategories: result.data.mediaObjectCategories.edges,
        });
      });
  };

  async componentDidMount() {
    checkRouting(this.props);
    await this.initCollections();
    this.initProduct();
    this.getMediasCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    checkRouting(this.props);
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(
        {
          productId: `/api/products/${this.props.match.params.id}`,
          ready: false,
        },
        () => {
          this.initProduct();
        }
      );
    }
    // this.getNextAndPrevProduct()
  }

  initSources = () => {
    this.props.client
      .query({
        query: GET_PRODUCT_SOURCES,
        variables: {
          product: this.state.productId,
        },
      })
      .then((result1) => {
        this.setState({
          listSources: result1.data.productSources.edges,
          currentSource: result1.data.productSources.edges[0]?.node.id,
        });

        for (let source of result1.data.productSources.edges) {
          this.props.client
            .query({
              query: GET_PRODUCT_SOURCES_PRICES,
              variables: {
                product: this.state.productId,
                source: source.node.id,
              },
            })
            .then((result2) => {
              source.node.productSourceDatas =
                source.node.productSourceDatas || {};
              source.node.productSourceDatas.edges =
                source.node.productSourceDatas.edges || [];
              source.node.productSourceDatas.edges =
                source.node.productSourceDatas.edges.concat(
                  result2.data.productSourceDatas.edges
                );

              this.setState({
                listSources: result1.data.productSources.edges,
              });
            });
        }
      });
  };

  loopCategories = (after) => {
    let categories;
    let hasNextPage;
    let cursor;
    return this.props.client
      .query({
        query: GET_CATEGORIES_FLAT,
        variables: {
          after,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log(result);
        categories = result.data.categoryFlats.edges;
        hasNextPage = result.data.categoryFlats.pageInfo.hasNextPage;
        cursor = result.data.categoryFlats.pageInfo.endCursor;
        return { categories, hasNextPage, cursor };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleToggleDrawer = (drawer) => {
    if (drawer === "form") {
      this.setState(
        {
          openForm: !this.state.openForm,
        },
        async () => {
          let categories = [];

          let response = await this.props.client.query({
            query: GET_CATEGORIES_ONLY,
            fetchPolicy: "no-cache",
          });
          categories.push(...response.data.categories);
          let newCategories = this.state.categories.map((cat) => {
            let staticId = categories
              .find((e) => e?.id === cat.id)
              ?.id.replace("/api/categories/", "");
            let newObject = {
              ...cat,
              categoryStaticId: staticId,
            };
            return newObject;
          });
          this.setState({
            categories: newCategories,
            categoriesData: categories,
            categorieReady: true,
          });
        }
      );
    }
    if (drawer === "addVariant") {
      this.setState({
        openFormVariant: !this.state.openFormVariant,
        // variantsProduct : this.state.variantsValidated,
      });
      if (this.state.variantsValidated?.values.length > 0) {
        let copyVariantsValidated = _.cloneDeep(
          this.state.variantsValidated.values
        );
        this.setState({
          variantsProduct: copyVariantsValidated,
        });
      } else {
        this.setState({
          variantsProduct: [],
        });
      }
    }
    if (drawer === "infoLogs") {
      this.setState({
        infoLogs: !this.state.infoLogs,
      });
    }
    this.setState({
      seeErrors: false,
    });
  };

  handleToggleDialog = () => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
  };
  handleToggleDialogSource = () => {
    this.setState({
      openDialogSource: !this.state.openDialogSource,
    });
  };

  handleLang = (event) => {
    this.setState({ currentLang: event.target.value });
    this.forceUpdate();
  };

  handleChangeCollection = (event) => {
    this.setState(
      {
        currentCollection:
          typeof event === "object" ? event.target.value : event,
        ready: false,
      },
      () => {
        this.initProduct();
      }
    );
  };

  setValue = (stateName, value, custom, translated, mediaObjectCategory) => {
    if (custom) {
      if (translated) {
        let values = this.state[this.state.currentLang];
        if (!values) {
          values = {};
        }
        if (values[stateName]) {
          if (!values[stateName]?.new) {
            values[stateName][custom] = value;
            values[stateName].updated = true;
            if (custom !== "isLocked") {
              values[stateName].value = value;
            }
            if (mediaObjectCategory) {
              values[stateName].mediaObjectCategory = mediaObjectCategory;
            } else {
              values[stateName].mediaObjectCategory = null;
            }
          } else {
            values[stateName][custom] = value;
            values[stateName].updated = true;
            if (custom !== "isLocked") {
              values[stateName].value = value;
            }
            if (mediaObjectCategory) {
              values[stateName].mediaObjectCategory = mediaObjectCategory;
            } else {
              values[stateName].mediaObjectCategory = null;
            }
          }
        } else {
          if (custom !== "isLocked") {
            values[stateName] = {
              [custom]: value,
              new: true,
              updated: true,
              value: value,
            };
          } else {
            values[stateName] = {
              [custom]: value,
              new: true,
              updated: true,
            };
          }
        }
        if (custom !== "isLocked") {
          this.setState({
            [this.state.currentLang]: values,
            selectedMediaCategories: mediaObjectCategory,
            [stateName]: value,
          });
        } else {
          this.setState({
            [this.state.currentLang]: values,
            selectedMediaCategories: mediaObjectCategory,
          });
        }
      }
    } else {
      if (stateName === "product_price" && value.includes(",")) {
        value = value.replace(",", ".");
      }
      if (translated) {
        let values = this.state[this.state.currentLang];
        if (!values) {
          values = {};
        }
        if (values[stateName]) {
          if (!values[stateName]?.new) {
            values[stateName].value = value;
            values[stateName].updated = true;
            if (mediaObjectCategory) {
              values[stateName].mediaObjectCategory = mediaObjectCategory;
            } else {
              values[stateName].mediaObjectCategory = null;
            }
          } else {
            values[stateName].value = value;
            values[stateName].updated = true;
            if (mediaObjectCategory) {
              values[stateName].mediaObjectCategory = mediaObjectCategory;
            } else {
              values[stateName].mediaObjectCategory = null;
            }
          }
        } else {
          values[stateName] = {
            value: value,
            new: true,
            updated: true,
          };
        }

        this.setState({
          [this.state.currentLang]: values,
          [stateName]: value,
          selectedMediaCategories:
            stateName === "categorie_de_media"
              ? mediaObjectCategory
              : this.state.selectedMediaCategories,
        });
      } else {
        this.setState({
          [stateName]: value,
          selectedMediaCategories:
            stateName === "categorie_de_media"
              ? mediaObjectCategory
              : this.state.selectedMediaCategories,
        });
      }
    }
  };

  handleInputChange = (stateName, evt, custom, translated) => {
    const value =
      evt?.target?.value === null ? null : evt?.target?.value ?? evt;
    if (stateName === "categorie_de_media") {
      if (value) {
        this.props.client
          .query({
            query: GET_MEDIA_OBJECTS_CATEGORY,
            fetchPolicy: "no-cache",
            variables: {
              id: value,
            },
          })
          .then((result) => {
            const tracklist =
              result.data.mediaObjectCategory.mediaObjects.edges.map(
                (media) => {
                  return {
                    name: media.node.name,
                    file_path: media.node.filePath,
                  };
                }
              );
            this.setValue(
              stateName,
              JSON.stringify(tracklist),
              custom,
              translated,
              value
            );
          })
          .catch((err) => console.log(err));
      } else {
        this.setValue(stateName, null, custom, translated, value);
      }
    } else {
      this.setValue(stateName, value, custom, translated);
    }
  };

  handleSelectScrap = (stateName, key, value) => {
    let values = this.state[stateName];

    if (!values) {
      values = [];
    }
    let checkValue = values.find((e) => e.key === key);
    if (checkValue) {
      values = values.filter((e) => e.key !== key);
    } else {
      values.push({
        key,
        value,
      });
    }
    this.setState({
      [stateName]: values,
    });
    // this.setValue(stateName, value, translated);
  };

  handleSelectImage = (index) => {
    let getImagesSelected = [...this.state.imagesSelected];
    let getImageAttribut = getImagesSelected[index];
    this.handleSelectScrap(
      "selectedScrap",
      getImageAttribut.identifier,
      getImageAttribut.id
    );
  };

  resetState() {
    this.setState({
      imageSrc: [],
      errors: {},
    });
    this.resetVariant();
  }

  resetVariant = () => {
    this.setState({
      // attributesSelected: [],
      variantToDelete: [],
      variantsProduct: [],
    });
  };

  handleCancel = () => {
    this.resetState();
    this.initProduct(true);
  };

  handleButtonGroupChange = (stateName, value) => {
    this.handleInputChange(stateName, value, null, true);
  };

  deleteMutation = () => {
    let variables = null;

    variables = { id: this.state.productId };

    this.props.startLoading();

    this.props.client
      .mutate({
        mutation: DELETE_PRODUCT,
        variables,
        // refetchQueries: [{
        //     query: GET_PRODUCTS,
        // }]
      })
      .then((result) => {
        this.goTo(ROUTE_PRODUCTS_LIST);
        this.handleToggleDrawer("form");
        this.handleToggleDialog();
        this.props.stopLoading();
      })
      .catch((e) => {
        this.props.stopLoading();
        this.props.snack(ALERT_ERROR, "Une erreur est survenue");
        console.log(e);
      });
  };

  copy(array) {
    let newArray = [];

    for (let elem of array) newArray.push(Object.assign({}, elem));

    return newArray;
  }

  copyArrayOfObjects = (array) => array.map((a) => ({ ...a }));

  getAttributeTranslatedValue = (id, lang, isScrap = false) => {
    if (isScrap) {
      if (!this.state.initialAttributes) return null;

      let attribute = this.state.initialAttributes.find((e) => e.id === id);

      if (!attribute) return null;

      let translation = attribute.locales.find((e) => e.id === lang);

      if (!translation) return null;

      return translation;
    } else {
      if (!this.state.attributes) return null;

      let attribute = this.state.attributes.find((e) => e.id === id);

      if (!attribute) return null;

      let translation = attribute.locales.find((e) => e.id === lang);

      if (!translation) return null;

      return translation;
    }
  };

  saveVariant = (drawer) => {
    this.handleToggleDrawer(drawer);
    let copyVariants = this.copyArrayOfObjects(this.state.variantsProduct);
    this.setState({
      variantsProduct: [],
      variantsValidated: {
        attributes: this.state.attributesSelected,
        values: copyVariants,
      },
      // changedVariants: copyVariants.map(e => e.idVariant)
    });
    this.getVariantToDelete(copyVariants);
  };

  getVariantToDelete = (result) => {
    let deleteVariants = [];
    for (let i = 0; i < this.state.initialVariantId.length; ++i) {
      let checkDelete = result.find(
        (e) => e.idVariant === this.state.initialVariantId[i]
      );
      if (!checkDelete) {
        deleteVariants.push(this.state.initialVariantId[i]);
      }
    }
    this.setState({
      variantToDelete: deleteVariants,
    });
  };

  getVariantForChildren = () => {
    return new Promise(async (resolve, reject) => {
      let values = this.state.variantsValidated.values;
      let getChildren = [];
      for (let i = 0; i < values.length; ++i) {
        let skuVariant = this.state.sku + "-variant" + (i + 1);
        if (values[i]?.new === true) {
          const ADD_PRODUCT_VARIANT_RESULT = await this.props.client.mutate({
            mutation: ADD_PRODUCT,
            variables: {
              sku: skuVariant,
              attributeGroup: this.state.groupAttribut,
              categories: this.state.categories.map((e) =>
                e.id.replace("/api/categories/", "")
              ),
              createdAt: moment().format("YYYY-MM-DD"),
              updatedAt: moment().format("YYYY-MM-DD"),
              status: this.state.status,
              superAttribute: this.state.attributesSelected.map((e) => e.value),
            },
          });
          await this.saveAttributesVariants(
            ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id,
            values[i]
          );
          let variantCreate =
            ADD_PRODUCT_VARIANT_RESULT.data.createProduct.product.id;
          getChildren.push(variantCreate);
        } else {
          await this.saveAttributesVariants(
            values[i].idVariant,
            values[i],
            true
          );
          getChildren.push(values[i].idVariant);
        }
      }
      resolve(getChildren);
    });
  };

  createVariant = () => {
    return new Promise(async (resolve, reject) => {
      let getChildren = await this.getVariantForChildren();
      resolve(getChildren);
    });
  };

  saveAttributesVariants = (product, variants, update) => {
    return new Promise(async (resolve, reject) => {
      let attributes = this.state.isSystemAttributes.concat(
        this.state.customAttributes
      );
      let getProductData = [];

      // Big for each to push each attribute to the x product
      for (let attribute of attributes) {
        // for (let locale of this.props.locales) {
        let locale = this.props.locales.find(
          (e) => e.node.code === this.state.currentLang
        );
        let formValue = this.state[locale.node.code][attribute.node.identifier];
        let isMedia =
          attribute.node.attributeType.input === "image" ||
          attribute.node.attributeType.input === "file";
        let isForVariant =
          attribute.node.identifier === "product_price" ||
          attribute.node.identifier === "product_image";
        // let currentTranslation  = this.getAttributeTranslatedValue(attribute.node.id, locale.node.id);
        if (formValue && isMedia) {
          /*if (!formValue.changed)
                        continue;*/
          isMedia = true;
          /*formValue   = formValue.file;*/
        }
        if (attribute.node.attributeType.input === "select") {
          formValue = formValue?.value;
        }
        if (
          variants.allAttr.find((e) => e.id === attribute.node.id) ||
          formValue ||
          isForVariant
        ) {
          if (variants && variants.new) {
            let variables = {
              attributeOption:
                attribute.node.attributeType.input === "select"
                  ? formValue
                  : null,
              product: product,
              attribute: attribute.node.id,
              locale: locale.node.id,
              updatedAt: moment().format("YYYY-MM-DD"),
            };

            if (isMedia) {
              if (variants) {
                variables.media = variants.new
                  ? variants.image.value.id
                  : variants.image.value.data.id;
              } else {
                variables.media = formValue.id;
              }
            }

            if (!isMedia)
              if (variants) {
                for (let newAttr of variants.allAttr) {
                  if (attribute.node.id === newAttr.id) {
                    if (newAttr.type === "select") {
                      variables.attributeOption = newAttr.values;
                    } else {
                      variables.value = newAttr.values ? newAttr.values : "";
                    }
                  }
                }
                if (attribute.node.identifier === "product_price") {
                  variables.value = variants.price.value ?? "";
                } else {
                  variables.value = variables.value
                    ? variables.value
                    : formValue?.value
                    ? formValue.value
                    : "";
                }
              } else {
                variables.value = variables.value
                  ? variables.value
                  : formValue?.value
                  ? formValue.value
                  : "";
              }
            await this.props.client.mutate({
              mutation: ADD_PRODUCT_DATA,
              variables,
            });
          } else if (variants.updated) {
            for (let newAttr of variants.allAttr) {
              if (attribute.node.id === newAttr.id) {
                let variables = {
                  id: newAttr.productDataId,
                  attributeOption:
                    attribute.node.attributeType.input === "select"
                      ? newAttr.values
                      : null,
                  product: product,
                  attribute: attribute.node.id,
                  locale: locale.node.id,
                  updatedAt: moment().format("YYYY-MM-DD"),
                };

                if (isMedia) variables.media = formValue.id;

                if (!isMedia) variables.value = newAttr.values;

                await this.props.client.mutate({
                  mutation: UPDATE_PRODUCT_DATA,
                  variables,
                });
              }
            }
            if (
              attribute.node.identifier === "product_price" &&
              variants.price.id
            ) {
              let variables = {
                id: variants.price.id,
                attributeOption: null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                updatedAt: moment().format("YYYY-MM-DD"),
              };
              variables.value = variants.price.value;
              await this.props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            }
            if (
              attribute.node.identifier === "product_image" &&
              variants.image.id
            ) {
              let variables = {
                id: variants.image.id,
                attributeOption: null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                updatedAt: moment().format("YYYY-MM-DD"),
              };
              variables.media = variants.image.value.id;
              await this.props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            }
          }
        }
        // }
      }

      resolve(getProductData);
    });
  };

  saveAttributes = (product) => {
    return new Promise(async (resolve, reject) => {
      let attributes = this.state.isSystemAttributes.concat(
        this.state.customAttributes
      );
      for (let locale of this.props.locales) {
        for (let attribute of attributes) {
          let formValue =
            this.state[locale.node.code][attribute.node.identifier];
          let currentTranslation = this.getAttributeTranslatedValue(
            attribute.node.id,
            locale.node.id
          );
          let isMedia =
            attribute.node.attributeType.input === "image" ||
            attribute.node.attributeType.input === "file";
          if (formValue && isMedia) {
            isMedia = true;
          }
          if (formValue && formValue.value) {
            let toCreate = formValue.new && formValue.updated;
            let toUpdate =
              !formValue.new && formValue.updated && currentTranslation;
            if (toUpdate) {
              // UPDATE STEP
              let variables = {
                id: currentTranslation.productDataId,
                attributeOption:
                  attribute.node.attributeType.input === "select"
                    ? formValue.value
                    : null,
                product: product,
                attribute: attribute.node.id,
                isLocked: formValue.isLocked,
                selection:
                  this.state.currentCollection !== "-1"
                    ? this.state.currentCollection
                    : null,
                locale: locale.node.id,
              };

              if (isMedia) {
                variables.media = formValue.value.id;
              }

              if (!isMedia) {
                if (
                  attribute.node.attributeType.input !== "select" &&
                  attribute.node.attributeType.input !== "media_category" &&
                  attribute.node.attributeType.input !== "select_multiple"
                ) {
                  variables.value = formValue.value;
                }
              }

              if (attribute.node.attributeType.input === "media_category") {
                variables.mediaObjectCategoryId =
                  this.state.selectedMediaCategories ?? formValue.value;
                variables.value = formValue.value;
              }
              if (attribute.node.attributeType.input === "select_multiple") {
                variables.value = JSON.stringify(formValue.value);
              }

              await this.props.client.mutate({
                mutation: UPDATE_PRODUCT_DATA,
                variables,
              });
            } else if (toCreate) {
              // CREATE STEP
              let ADD_PRODUCT_DATA_RESULT = "";
              let variables = {
                attributeOption:
                  attribute.node.attributeType.input === "select"
                    ? formValue.value
                    : null,
                product: product,
                attribute: attribute.node.id,
                locale: locale.node.id,
                isLocked: formValue.isLocked,
                selection:
                  this.state.currentCollection !== "-1"
                    ? this.state.currentCollection
                    : null,
                updatedAt: moment().format("YYYY-MM-DD"),
              };

              if (isMedia) {
                variables.media = formValue.value.id;
              }

              if (attribute.node.attributeType.input === "media_category") {
                variables.mediaObjectCategoryId =
                  this.state.selectedMediaCategories ?? formValue.value;
              }
              if (attribute.node.attributeType.input === "select_multiple") {
                variables.value = JSON.stringify(formValue.value);
              }

              if (!isMedia)
                if (
                  attribute.node.attributeType.input !== "select" &&
                  attribute.node.attributeType.input !== "select_multiple"
                ) {
                  variables.value = formValue.value;
                }
              ADD_PRODUCT_DATA_RESULT = await this.props.client.mutate({
                mutation: ADD_PRODUCT_DATA,
                variables,
              });
            }
          } else if (currentTranslation) {
            // DELETE STEP

            await this.props.client.mutate({
              mutation: DELETE_PRODUCT_DATA,
              variables: { id: currentTranslation.productDataId },
            });
          }
        }
      }

      resolve();
    });
  };

  handleMediaPicker = (selected, stateName) => {
    this.handleInputChange(stateName, selected, null, this.state.currentLang);
  };

  handleError = (e) => {
    this.props.snack(ALERT_ERROR, "Une erreur est survenue");

    this.props.stopLoading();

    if (e.graphQLErrors) {
      for (let error of e.graphQLErrors) {
        console.error("ERROR", `${error.message} =>`, error.debugMessage);
      }
    }
  };

  handleSuccess = async () => {
    await this.initProduct();

    this.props.snack(ALERT_SUCCESS, "Produit modifié !");

    this.handleToggleDrawer("form");
    this.resetState();
    this.props.stopLoading();
  };

  handleFormError = (stateName, error) => {
    let errors = this.state.errors;

    errors[stateName] = error;

    this.setState({ errors });
  };

  handleSecondFromError = (stateName, error) => {
    let secondErrors = this.state.secondErrors;

    secondErrors[stateName] = error;

    this.setState({ secondErrors });
  };

  hasErrors = (formError) => {
    if (formError === "firstForm") {
      if (this.state.errors) {
        for (let error in this.state.errors) {
          if (this.state.errors[error]) return true;
        }
      }
    } else {
      if (this.state.secondErrors) {
        for (let error in this.state.secondErrors) {
          if (this.state.secondErrors[error]) return true;
        }
      }
    }

    return false;
  };

  handlerMutation = async () => {
    try {
      if (this.hasErrors("firstForm")) {
        this.props.snack(ALERT_ERROR, "Veuillez vérifier les champs invalides");
        this.setState({ seeErrors: true });
        return eventService.fire();
      }

      this.props.startLoading();

      let catalogCat = this.state.categoriesCatalog?.map((e) => e.id);
      let allCategories = this.state.categories
        ?.map((e) => e.categoryStaticId)
        .concat(catalogCat);

      if (this.state.isVariant) {
        const newVariants = await this.createVariant();
        if (this.state.variantToDelete.length > 0) {
          for (let variantId of this.state.variantToDelete) {
            await this.props.client.mutate({
              mutation: DELETE_PRODUCT,
              variables: {
                id: variantId,
              },
            });
          }
        }
        let variables = {
          id: this.state.productId,
          attributeGroup: this.state.groupAttribut,
          categories: allCategories.map((e) => e),
          status: this.state.status,
          children: newVariants,
        };
        if (this.state.selectedProducts) {
          variables.suggestions = this.state.selectedProducts;
        }
        await this.props.client.mutate({
          mutation: UPDATE_PRODUCT,
          variables,
        });
      } else {
        let variables = {
          id: this.state.productId,
          attributeGroup: this.state.groupAttribut,
          categories: allCategories.map((e) => e),
          status: this.state.status,
        };
        if (this.state.selectedProducts) {
          variables.suggestions = this.state.selectedProducts;
        }
        await this.props.client.mutate({
          mutation: UPDATE_PRODUCT,
          variables,
        });
      }

      await this.saveAttributes(this.state.productId);

      this.setState({ reload: true });
      this.handleSuccess();
    } catch (e) {
      console.log(e);
      this.handleError(e);
    }
  };

  handleCurrentSource = (value) => {
    this.setState(
      {
        currentSource: value,
      },
      () => {
        this.initProductSources();
      }
    );
  };

  changeTypeData = async (value) => {
    if (this.state.selectedScrap?.length > 0) {
      this.handleToggleDialogSource();
      this.setState({
        typeDataToGo: value,
      });
    } else {
      this.setState(
        {
          selectedScrap: [],
          typeDataToGo: null,
          currentSource: value,
          ready: false,
          openDialogSource: false,
        },
        () => {
          if (value === "Principal") {
            this.initProduct();
          } else {
            this.initProductSources();
          }
        }
      );
    }
  };

  handleOpenScrapDrawer = () => {
    this.setState({
      openScrapDrawer: !this.state.openScrapDrawer,
    });
    this.initProductSources(this.state.currentSource);
  };

  handleValidateSelection = async () => {
    this.props.startLoading();
    await this.saveAttributesScrap();
    this.props.stopLoading();
    let getSource = this.state.listSources.find(
      (e) => e.node.id === this.state.typeData
    );

    if (getSource?.node?.libelle)
      this.props.snack(
        ALERT_SUCCESS,
        `Produit modifié avec les données de ${getSource?.node?.libelle} !`
      );
    else
      this.props.snack(
        ALERT_SUCCESS,
        `Produit modifié avec les données scrappées !`
      );

    this.setState(
      {
        openDialogSource: false,
        selectedScrap: [],
      },
      () => this.changeTypeData("Principal")
    );
  };

  handleValidateProduct = async () => {
    this.props.startLoading();
    try {
      await this.saveProductSourceWorkflow();
      this.props.stopLoading();
      this.props.snack(ALERT_SUCCESS, `Produit validé avec succès`);
    } catch (error) {
      this.props.stopLoading();
      this.props.snack(
        ALERT_ERROR,
        `Une erreur est survenue impossible de validé le produit`
      );
    }
  };

  saveProductSourceWorkflow = () => {
    return new Promise(async (resolve, reject) => {
      let variables = {
        completedAt: moment().format("YYYY-MM-DD"),
        product: this.state.productId,
      };
      await this.props.client
        .mutate({
          mutation: ADD_PRODUCT_SOURCE_WORKFLOW,
          variables,
        })
        .then((result) => {
          this.setState({
            ready: false,
          });
          let getFetchedProduct = { ...this.state.fetchedProduct };
          getFetchedProduct.productSourceWorkflow =
            result.data.createProductSourceWorkflow.productSourceWorkflow;
          this.setState({
            fetchedProduct: getFetchedProduct,
            ready: true,
          });
        });
      resolve();
    });
  };
  saveAttributesScrap = () => {
    return new Promise(async (resolve, reject) => {
      for (let attribute of this.state.selectedScrap) {
        let getAttributeFromList = this.state.isSystemAttributes
          .concat(this.state.customAttributes)
          .find((e) => e.node.identifier === attribute.key);
        let getCurrentData = this.state.initialAttributes.find(
          (e) => e.identifier === attribute.key
        );
        let getCurrentDataByLocale = null;
        let isMedia = null;
        let getLocaleData = this.props.locales.find(
          (e) => e.node.code === this.state.currentLang
        );
        if (getCurrentData) {
          getCurrentDataByLocale = getCurrentData?.locales.find(
            (e) => e.code === this.state.currentLang
          );
          isMedia =
            getCurrentData?.attributeType.input === "image" ||
            getCurrentData.attributeType.input === "file";
        } else {
          isMedia =
            getAttributeFromList?.node.attributeType.input === "image" ||
            getAttributeFromList?.node.attributeType.input === "file";
        }
        if (getCurrentData && getCurrentDataByLocale) {
          let variables = {
            id: getCurrentDataByLocale.productDataId,
            attributeOption:
              getCurrentData.attributeType.input === "select"
                ? attribute.value
                : null,
            product: this.state.productId,
            attribute: getCurrentData.id,
            selection:
              this.state.currentCollection !== "-1"
                ? this.state.currentCollection
                : null,
            locale: getLocaleData.node.id,
          };

          if (isMedia) {
            variables.media = attribute.value;
          }

          if (!isMedia)
            if (getCurrentData.attributeType.input !== "select") {
              variables.value = attribute.value;
            }

          await this.props.client.mutate({
            mutation: UPDATE_PRODUCT_DATA,
            variables,
          });
        } else {
          // CREATE STEP

          let ADD_PRODUCT_DATA_RESULT = "";
          let variables = {
            attributeOption:
              getAttributeFromList.node.attributeType.input === "select"
                ? attribute.value
                : null,
            product: this.state.productId,
            attribute: getAttributeFromList.node.id,
            locale: getLocaleData.node.id,
            selection:
              this.state.currentCollection !== "-1"
                ? this.state.currentCollection
                : null,
            updatedAt: moment().format("YYYY-MM-DD"),
          };

          if (isMedia) {
            variables.media = attribute.value.id;
          }

          if (!isMedia)
            if (getAttributeFromList.node.attributeType.input !== "select") {
              variables.value = attribute.value;
            }

          ADD_PRODUCT_DATA_RESULT = await this.props.client.mutate({
            mutation: ADD_PRODUCT_DATA,
            variables,
          });
        }
      }
      resolve();
    });
  };

  updateScrapStatus = (validate) => {
    this.props.client
      .mutate({
        mutation: UPDATE_PRODUCT,
        variables: {
          id: this.state.productId,
          scrapStatus: validate
            ? "100"
            : this.state.scrapStatus === "404" ||
              this.state.scrapStatus === "200"
            ? "99"
            : "100",
        },
      })
      .then(async () => {
        await this.initProduct();
        this.props.snack(ALERT_SUCCESS, "Produit modifié !");
        this.setState({
          newNavList: this.props.history.location?.state?.productList,
        });
        this.resetState();
        this.props.stopLoading();
      });
  };

  render() {
    const {
      sku,
      nbCatalog,
      status,
      statsProduct,
      stocksProduct,
      commandsProduct,
      retailersInfos,
    } = this.state;
    const editMenuOptions = [
      {
        label: "Modifer",
        color: colors.blue.darker.hue300,
        isDisabled: false,
        icon: <Edit style={{ fill: colors.blue.darker.hue300 }} />,
        action: (e) => {
          e.stopPropagation();
          this.handleToggleDrawer("form");
        },
      },
      {
        label: "Supprimer",
        color: colors.red.regular,
        icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
        action: (e) => {
          e.stopPropagation();
          this.handleToggleDialog();
        },
      },
    ];
    const deleteMenuOptions = [
      {
        label: "Supprimer",
        color: colors.red.regular,
        icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
        action: (e) => {
          e.stopPropagation();
          this.handleToggleDialog();
        },
      },
    ];

    if (menuEntry("bundles") || true) {
      deleteMenuOptions.unshift({
        label: "Convertir en bundle",
        color: colors.blue.darker.hue300,
        icon: <DeleteSharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        action: (e) => {
          e.stopPropagation();
          let product = this.state;
          this.props.history.push({
            pathname: "/products/bundles",
            state: { productId: this.state.productId },
          });
        },
      });
      editMenuOptions.unshift({
        label: "Convertir en bundle",
        color: colors.blue.darker.hue300,
        icon: <DeleteSharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        action: (e) => {
          e.stopPropagation();
          this.handleToggleDialog();
        },
      });
    }

    if (this.state.drawerWidth === 0 && this.widthRef.current?.clientWidth) {
      this.setState({
        drawerWidth: this.widthRef.current?.clientWidth / 2,
      });
    }
    // const currentNavItem = this.props.history?.location?.state?.productList.findIndex(e=>e.node.sku === sku)
    // const currentItem = this.props.history?.location?.state?.productList.find(e=>e.node.sku === sku)

    // const getUrlFromId = (id)=>{
    //     const index = id.lastIndexOf('/')
    //     const result = id.substring(index +1)
    //     return result
    // }

    // const goPrev = ()=>{
    //     if (this.state.navList[currentNavItem - 1]) {
    //         if (this.state.deleteItemFromNav) {
    //             this.setState({
    //                 navList : this.state.navList.filter(e=> e.node.id !== currentItem.node.id)
    //             })
    //         }
    //         this.goTo(`${getUrlFromId(this.state.navList[currentNavItem - 1].node.id)}`,null,this.state.navList)
    //     }
    // }

    // const goNext = ()=>{
    //     if (this.state.navList[currentNavItem + 1]) {
    //         if (this.state.deleteItemFromNav) {
    //             this.setState({
    //                 navList : this.state.navList.filter(e=> e.node.id !== currentItem.node.id)
    //             })
    //         }
    //         this.goTo(`${getUrlFromId(this.state.navList[currentNavItem + 1].node.id)}`,null,this.state.navList)
    //     }
    // }

    // let getNameProduct = this.state.ready ? this.state[this.state.currentLang]?.product_name?.value ? this.state[this.state.currentLang]?.product_name?.value : 'N/A' : <CircularProgress size={"25px"} />

    return (
      <PageLayout
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopPanel
          title={this.props.t("drawer.detail_product")}
          subtitle={"Vue d’ensemble pour gérer le produit dans la plateforme"}
          gradientColor1={colors.menu.regular}
          gradientColor2={colors.menu.darker}
          windowWidth={this.props.windowWidth}
          locales={this.props.locales}
          hasBorder={true}
          buttonAvailable={this.state.readyForCat && this.state.ready}
          alignItems={true}
        >
          {process.env.REACT_APP_ALLOW_PRODUCT_VALIDATE_BUTTON === "true" ? (
            this.state.ready ? (
              this.state.fetchedProduct?.productSourceWorkflow?.completedAt ? (
                <Box style={{ gap: 20 }} display={"flex"} alignItems="center">
                  <Button
                    text={this.props.t("products.list.cardproduct.change")}
                    icon={"edit"}
                    color={colors.blue.darker.hue300}
                    colorHover={colors.white}
                    bgcolorhover={colors.blue.lighter.hue600}
                    bgcolor={colors.blue.lighter.hue900}
                    border={`1px solid ${colors.blue.darker.hue300}`}
                    stopPropagation={true}
                    onClick={() => this.handleToggleDrawer("form")}
                    disabled={!(this.state.readyForCat && this.state.ready)}
                    style={{
                      margin: 0,
                    }}
                  />
                  <CheckCircle
                    style={{ fill: colors.green.regular, marginRight: 4 }}
                  />
                  <Typography
                    style={{ color: colors.green.regular, fontWeight: "bold" }}
                  >
                    Validé le{" "}
                    {moment(
                      this.state.fetchedProduct?.productSourceWorkflow
                        ?.completedAt
                    ).format("DD/MM/YYYY")}
                  </Typography>
                  <OurMenu
                    menuOptions={editMenuOptions}
                    handleClick={(e, item) => item.action(e)}
                  />
                </Box>
              ) : (
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {this.state.scrapStatus === "99" ? (
                    <>
                      {/* <Button
                                                    text={"À retravailler"}
                                                    color={colors.blue.darker.hue300}
                                                    colorHover={colors.white}
                                                    bgcolorhover={colors.blue.lighter.hue600}
                                                    bgcolor={colors.blue.lighter.hue900}
                                                    border={`1px solid ${colors.blue.darker.hue300}`}
                                                    stopPropagation={true}
                                                    onClick={() => this.updateScrapStatus(true)}
                                                    disabled={!(this.state.readyForCat && this.state.ready)}
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                />     */}
                      <Button
                        text={this.props.t(
                          "products.list.cardproduct.validate"
                        )}
                        bgcolor={colors.green.regular}
                        bgcolorhover={colors.green.regular}
                        stopPropagation={true}
                        onClick={() => this.updateScrapStatus(true)}
                        disabled={!(this.state.readyForCat && this.state.ready)}
                      />
                      <OurMenu
                        style={{ display: "flex", alignItems: "baseline" }}
                        menuOptions={editMenuOptions}
                        handleClick={(e, item) => item.action(e)}
                      />
                    </>
                  ) : this.state.scrapStatus === "404" ||
                    this.state.scrapStatus === "200" ? (
                    <>
                      <Button
                        text={this.props.t("products.list.cardproduct.change")}
                        icon={"edit"}
                        color={colors.blue.darker.hue300}
                        colorHover={colors.white}
                        bgcolorhover={colors.blue.lighter.hue600}
                        bgcolor={colors.blue.lighter.hue900}
                        border={`1px solid ${colors.blue.darker.hue300}`}
                        stopPropagation={true}
                        onClick={() => this.handleToggleDrawer("form")}
                        disabled={!(this.state.readyForCat && this.state.ready)}
                        style={{
                          margin: 0,
                        }}
                      />
                      <Button
                        text={"Envoyer pour validation"}
                        bgcolor={colors.green.lighterv2}
                        color={colors.green.regular}
                        bgcolorhover={colors.green.lighterv2}
                        border={`0.5px solid ${colors.green.regular}`}
                        stopPropagation={true}
                        onClick={() => this.updateScrapStatus()}
                        disabled={!(this.state.readyForCat && this.state.ready)}
                      />
                      <OurMenu
                        style={{ display: "flex", alignItems: "baseline" }}
                        menuOptions={deleteMenuOptions}
                        handleClick={(e, item) => item.action(e)}
                      />
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          color: colors.green.regular,
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                          marginRight: 10,
                        }}
                      >
                        <CheckIcon style={{ marginRight: 5 }} /> Produit validé
                      </Typography>
                      <Button
                        text={this.props.t("products.list.cardproduct.change")}
                        icon={"edit"}
                        color={colors.blue.darker.hue300}
                        colorHover={colors.white}
                        bgcolorhover={colors.blue.lighter.hue600}
                        bgcolor={colors.blue.lighter.hue900}
                        border={`1px solid ${colors.blue.darker.hue300}`}
                        stopPropagation={true}
                        onClick={() => this.handleToggleDrawer("form")}
                        disabled={!(this.state.readyForCat && this.state.ready)}
                        style={{
                          margin: 0,
                        }}
                      />
                      <OurMenu
                        style={{ display: "flex", alignItems: "baseline" }}
                        menuOptions={deleteMenuOptions}
                        handleClick={(e, item) => item.action(e)}
                      />
                    </>
                  )}
                  {/* <Button
                                            text={this.props.t('products.list.cardproduct.validate')}
                                            bgcolor={colors.green.regular}
                                            bgcolorhover={colors.green.regular}
                                            stopPropagation={true}
                                            onClick={() => this.handleValidateProduct()}
                                            disabled={!(this.state.readyForCat && this.state.ready)}
                                        /> */}
                  {/* <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />             */}
                </Box>
              )
            ) : (
              <CircularProgress size={"25px"} />
            )
          ) : (
            <>
              <Button
                text={this.props.t("products.list.cardproduct.change")}
                icon={"edit"}
                color={colors.blue.darker.hue300}
                colorHover={colors.white}
                bgcolorhover={colors.blue.lighter.hue600}
                bgcolor={colors.blue.lighter.hue900}
                border={`1px solid ${colors.blue.darker.hue300}`}
                stopPropagation={true}
                onClick={() => this.handleToggleDrawer("form")}
                disabled={!(this.state.readyForCat && this.state.ready)}
                style={{
                  margin: 0,
                }}
              />
              <OurMenu
                menuOptions={deleteMenuOptions}
                handleClick={(e, item) => item.action(e)}
              />
            </>
          )}
        </TopPanel>
        {/* <Grid item xs={12} style={{
                    paddingBottom:0
                }}>
                        <StatsProducts marginBottom={"0px"} />
                </Grid> */}
        <Grid
          container
          direction="column"
          ref={this.widthRef}
          style={{
            width: this.state.openScrapDrawer ? "50%" : "100%",
            paddingTop: 0,
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="baseline"
            style={{ padding: "20px 0" }}
          >
            <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <Box>
                <ReturnLink goTo={this.props.history.goBack} />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                {process.env.REACT_APP_MODE_SPREAD !== "hub" ? (
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    {this.state.typeData === "Principal" ? (
                      <Box style={{ display: "flex" }}>
                        <TraductionSelect
                          margin={-8}
                          currentLang={this.state.currentLang}
                          handleLang={this.handleLang}
                          locales={this.props.locales}
                        />
                        <Info
                          onClick={() => this.handleToggleDrawer("infoLogs")}
                          style={{
                            cursor: "pointer",
                            fill: colors.blue.darker.hue300,
                          }}
                        />
                      </Box>
                    ) : null}
                  </Grid>
                ) : null}
                {this.state.listSources.length > 0 ? (
                  this.state.typeData !== "Principal" ? (
                    <div
                      style={{
                        marginBottom: 16,
                        marginTop: this.state.typeData !== "Principal" ? 16 : 0,
                        width: "100%",
                      }}
                    >
                      <GridMessage container alignItems={"center"}>
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 16,
                          }}
                        >
                          <InfoOutlinedIcon
                            style={{
                              height: 25,
                              width: 25,
                              fill: colors.green.regular,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            style={{ color: colors.green.regular }}
                          >
                            Cliquez sur un attribut pour le sélectionner afin de
                            l'ajouter sur votre fiche principal.
                          </Typography>
                        </Grid>
                      </GridMessage>
                    </div>
                  ) : null
                ) : null}
              </Box>
            </Box>
            {/* {
                            this.state.navList?
                            <Box
                                style={{
                                    display:"flex",
                                    gap:10
                                }}
                            >
                                <Box onClick={goPrev} style={{display:"flex",alignItems:"center",gap:5,cursor:"pointer"}}>
                                    <ChevronLeftIcon
                                        style={{
                                            backgroundColor:"#FFF",
                                            border:`1px solid ${this.state.navList[currentNavItem - 1]?colors.black.regular:colors.grey.lighter.hue600}`,
                                            borderRadius:"100%",
                                            color:this.state.navList[currentNavItem - 1]?colors.black.regular:colors.grey.lighter.hue600
                                        }}
                                    />
                                    <Typography style={{color:this.state.navList[currentNavItem - 1]?colors.black.regular:colors.grey.lighter.hue600}}>Précédent</Typography> 
                                </Box>
                                <Divider orientation='vertical' flexItem/>
                                <Box onClick={goNext} style={{display:"flex",alignItems:"center",gap:5,cursor:"pointer"}}>
                                    <Typography style={{color:this.state.navList[currentNavItem + 1]?colors.black.regular:colors.grey.lighter.hue600}}>Suivant</Typography> 
                                    <ChevronRightIcon
                                        style={{
                                            backgroundColor:"#FFF",
                                            border:`1px solid ${this.state.navList[currentNavItem + 1]?colors.black.regular:colors.grey.lighter.hue600}`,
                                            borderRadius:"100%",
                                            color:this.state.navList[currentNavItem + 1]?colors.black.regular:colors.grey.lighter.hue600
                                        }}
                                    />
                                </Box>
                            </Box>
                            :null
                        }                         */}
          </Grid>
          {this.state.ready ? (
            <CardProductDetails
              sku={sku}
              product={{
                nbCatalog,
                status,
                statsProduct,
                stocksProduct,
                commandsProduct,
                retailersInfos,
                fetchedProduct: this.state.fetchedProduct,
              }}
              routeProduct={() => this.goTo(ROUTE_PRODUCTS_LIST)}
              categories={this.state.categories}
              categoriesNb={this.state.categoriesNb}
              toggleDrawer={() => this.handleToggleDrawer("form")}
              openForm={this.state.openForm}
              imageAttributes={this.state.imageAttributes}
              customAttributes={this.state.customAttributes}
              listTaxos={this.state.listTaxos}
              currentLang={this.state.currentLang}
              locale={this.props.locales.find(
                (e) => e.node.code === this.state.currentLang
              )}
              allState={this.state}
              locales={this.props.locales}
              ready={this.state.readyForCat}
              canModify={true}
              disabledButton={!this.state.secondaryDataLoaded}
              datasStocks={
                this.state.datasStocks ? this.state.datasStocks : null
              }
              windowWidth={this.props.windowWidth}
              selectedProducts={this.state.selectedProducts}
              goTo={this.goTo}
              isScrap={false}
              isScrapNew={this.state.openScrapDrawer}
              listSources={this.state.listSources}
              handleSelectScrap={this.handleSelectScrap}
              handleSelectImage={this.handleSelectImage}
              handleValidateSelection={this.handleValidateSelection}
              handleOpenScrapDrawer={this.handleOpenScrapDrawer}
              handleChangeCollection={this.handleChangeCollection}
              scrappingButton={true}
              skuPrice={this.state.skuPrice}
            />
          ) : (
            <PageLoader />
          )}
          {this.state.ready ? (
            <LayoutBuilder
              isSublayout={false}
              validateButton={true}
              handleCancel={this.handleCancel}
              opened={this.state.openForm}
              forClose={() => this.handleToggleDrawer("form")}
              handlerMutation={this.handlerMutation}
              icomoon={"ico-modifier-produit"}
              useLocking={true}
              dico={true}
              dataLayout={formProductDetail(
                this.state.currentLang,
                this.state.categoriesData,
                this.state.customAttributes,
                this.state.metaAttributes,
                this.state.imageAttributes,
                this.state.categories,
                this.state.allGroups,
                this.state.errors,
                this.state.seeErrors,
                this.handleMediaPicker,
                this.state.imagesSelected,
                this.state.isVariant,
                this.handleToggleDrawer,
                this.state.variantsValidated,
                ALLOWED_IN_PRODUCT,
                this.state.hasPrice,
                this.state.currentCollection,
                this.state.mediaCategories,
                this.state.selectedMediaCategories,
                this.state
              )}
              handleButtonGroupChange={this.handleButtonGroupChange}
              allState={this.state}
              stateCallback={this.handleInputChange}
              errorCallback={this.handleFormError}
              currentLang={this.state.currentLang}
              handleLang={this.handleLang}
              currentCollection={this.state.currentCollection}
              handleChangeCollection={this.handleChangeCollection}
              listCollections={this.state.listCollections}
              drawerWidth={this.props.drawerWidth}
              scrapDrawerIsOpen={this.state.openScrapDrawer}
            />
          ) : null}

          <LayoutBuilder
            isSublayout={false}
            validateButton={true}
            handleCancel={this.resetVariant}
            opened={this.state.openFormVariant}
            forClose={() => this.handleToggleDrawer("addVariant")}
            handlerMutation={() => this.saveVariant("addVariant")}
            icomoon={"ico-modifier-produit"}
            dataLayout={formVariantProductAdd(
              this.state.currentLang,
              this.state.customAttributes,
              this.state.attributesSelected,
              this.state[this.state.currentLang]?.product_price,
              this.state.variantsValidated,
              this.state.defaultImageProduct,
              true,
              this.handleMediaPicker,
              this.state.hasPrice
            )}
            backStepperButtonAction={[
              () => {
                this.setState({ errors: {} });
              },
              () => {
                this.setState({ errors: {} });
              },
              () => {
                this.setState({ errors: {} });
              },
              null,
            ]}
            stepperButtonAction={[
              () => {
                if (this.hasErrors()) {
                  this.props.snack(
                    ALERT_ERROR,
                    "Veuillez vérifier les champs invalides"
                  );
                  this.setState({ seeErrors: true });
                  eventService.fire();
                  return false;
                }
                return true;
              },
              null,
            ]}
            handleButtonGroupChange={this.handleButtonGroupChange}
            allState={this.state}
            stateCallback={this.handleInputChange}
            errorCallback={this.handleFormError}
            currentLang={this.state.currentLang}
            handleLang={this.handleLang}
            drawerWidth={this.props.drawerWidth}
            deleteButton={false}
          />
          <DialogModal
            icon={true}
            type="delete"
            open={this.state.openDialog}
            title={"Êtes-vous sûr de vouloir supprimer ce produit ?"}
            primaryAction={this.deleteMutation}
            secondaryAction={this.handleToggleDialog}
            windowWidth={this.props.windowWidth}
          >
            <OurTypography
              text={
                "Si vous supprimez ce produit celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler."
              }
              colortext={colors.grey.lighter.hue600}
              style={{ fontSize: "16px" }}
            />
          </DialogModal>
          <DialogModal
            open={this.state.openDialogSource}
            title={`Êtes-vous sûr de vouloir changer de source ?`}
            secondaryAction={this.handleToggleDialogSource}
            secondarycolor={colors.grey.regular}
            secondarybgcolor={colors.white}
            secondarybgcolorhover={colors.grey.lighter.hue900}
            secondaryborder={`1px solid ${colors.grey.regular}`}
            primaryAction={() => {
              this.setState({ selectedScrap: [] }, () => {
                this.changeTypeData(this.state.typeDataToGo);
              });
            }}
            primarybgcolor={colors.green.regular}
            primarybgcolorhover={colors.green.darker}
            primaryText="Changer de vue"
            windowWidth={this.props.windowWidth}
          >
            Si vous changez vous perdrez toute les modifications sélectionner
            sur cette vue. Si vous ne souhaitez changer de source, annulez en
            cliquant sur annuler.
          </DialogModal>
          <LogsActions
            typeToLoad={this.state.productId}
            open={this.state.infoLogs}
            toggleDrawer={() => this.handleToggleDrawer("infoLogs")}
            isProduct={true}
            groupAttribut={this.state.groupAttribut}
          />
          {process.env.REACT_APP_DISALLOW_SCRAPPING !== "true" ? (
            <ScrapDrawer
              open={this.state.openScrapDrawer}
              onClose={() => {
                this.setState({ openScrapDrawer: !this.state.openScrapDrawer });
                this.handleChangeCollection("-1");
              }}
              drawerwidth={`${this.state.drawerWidth}px`}
              allState={this.state}
              handleCurrentSource={this.handleCurrentSource}
              handleSelectScrap={this.handleSelectScrap}
              handleValidateSelection={this.handleValidateSelection}
            />
          ) : null}
          {/* <DrawerCustom
                    open={this.state.openScrapDrawer}
                    // BackdropProps={{ invisible: true }} 
                    // hideBackdrop={false}                    
                    onClose={()=>{this.setState({openScrapDrawer:!this.state.openScrapDrawer})}}
                    anchor={"right"}
                    drawerwidth={`${this.state.drawerWidth}px`}
                    elevation={0}    
                    variant={"persistent"}                                                
                >
                    <Typography variant="h5" onClick={()=>{this.setState({openScrapDrawer:!this.state.openScrapDrawer})}} component="div">
                        <BoxClose pb={2} style={{ cursor: 'pointer' }}>
                            <CloseIcon /> <span>{this.props.t("spread.active_assets.close")}</span>
                        </BoxClose>
                    </Typography>
                    <TopPanel inForm={true} title={this.props.t("drawer.products_scrapping")} subtitle={this.props.t("drawer.products_scrapping_subtitle")} />
                    <Grid container>
                        <Grid item xs={12}>                            
                            <Typography variant={"body2"} style={{paddingBottom:10}}>
                              Choisir une source
                            </Typography>
                            <Select variant='outlined' value={this.state.currentSource} style={{width:"100%", marginRight: 16 }} onChange={(event, value) => this.handleCurrentSource(event.target.value)}>
                                {
                                    this.state.listSources.map((source)=>{                                        
                                        return <MenuItem value={source.node.id}>{source.node.libelle}</MenuItem>                                        
                                    })
                                }                                
                            </Select>                            
                        </Grid>
                        {
                            this.state.scrapIsReady?
                                <>
                                    <Grid item xs={12} style={{paddingTop:20,paddingBottom:20}}>                                                                        
                                        <CarouselCustom slidesNb={2.5} images={formatedImagesScrap} />
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px dashed #72777980",marginBottom:10}}>
                                        <Typography variant='h1'>name</Typography>
                                        <OurButton margin={"0"}>Choisir</OurButton>
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px dashed #72777980",marginBottom:10}}>
                                        <Box style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                                            <Typography>Prix :</Typography>
                                            <Typography>100,00</Typography>
                                        </Box>
                                        <OurButton margin={"0"}>Choisir</OurButton>                                        
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px dashed #72777980",marginBottom:10}}>
                                        <Typography>Description</Typography>
                                        <OurButton margin={"0"}>Choisir</OurButton>
                                    </Grid>
                                    <Grid item xs={12} style={{padding:"20px 0"}}>                                        
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:10}}>
                                        <Typography>Attributs du produit</Typography>                                        
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px dashed #72777980",marginBottom:10}}>
                                        <Box style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                                            <Typography>Prix :</Typography>
                                            <Typography>100,00</Typography>
                                        </Box>
                                        <OurButton margin={"0"}>Choisir</OurButton>                                        
                                    </Grid>
                                    <Grid item xs={12} style={{display:"flex",justifyContent:"space-between",alignItems:"center",border:"1px dashed #72777980",marginBottom:10}}>
                                        <Box style={{display:"flex",width:"50%",justifyContent:"space-between"}}>
                                            <Typography>Nom attribut :</Typography>
                                            <Typography>attribut</Typography>
                                        </Box>
                                        <OurButton margin={"0"}>Choisir</OurButton>                                        
                                    </Grid>                                    
                                </>
                            :
                            <Box style={{display:"flex",justifyContent:"center",width:"100%",paddingTop:20}}>
                                <CircularProgress/>
                            </Box>
                        }
                    </Grid>
                </DrawerCustom>         */}
        </Grid>
      </PageLayout>
    );
  }

  handleMediaPicker = (selected, stateName) => {
    this.handleInputChange(stateName, selected, null, this.state.currentLang);
  };

  prepareAttributes() {
    return new Promise(async (resolve, reject) => {
      let productGroup = this.props.attributeGroups?.find(
        (e) => e.node.id === this.state.groupAttribut
      )?.node;

      let isSystemAttributes =
      productGroup?.attributes?.edges.filter(
          (e) => e.node.isSystem
        );
      let metaAttributes =
      productGroup?.attributes?.edges.filter(
          (e) => e.node.isSystem && e.node.identifier.indexOf("meta") > -1
        );
      let customAttributes =
        productGroup?.attributes?.edges?.filter((e) => !e.node.isSystem) ?? [];
      let hasPrice = productGroup?.attributes?.edges?.find(
        (e) => e.node.identifier === "product_price"
      )
        ? true
        : false;
      let imageAttributes = isSystemAttributes
        .filter(
          (e) =>
            e.node.attributeType.input === "image"
        )
        .concat(
          customAttributes.filter(
            (e) =>
              e.node.attributeType.input === "image"
          )
        );

      this.setState({
        isSystemAttributes,
        metaAttributes,
        customAttributes,
        imageAttributes,
        hasPrice,
        maxImageNumber: imageAttributes.length,
        readyForCat: true,
      });

      resolve();
    });
  }

  prepareVariants(result) {
    return new Promise(async (resolve, reject) => {
      let superAttributes = [];
      let variantValues = [];
      if (result.data.product?.superAttribute.edges) {
        for (let attr of result.data.product.superAttribute.edges) {
          let getLabel = attr.node.translation.translationDatas.edges.find(
            (e) => e.node.locale.code === this.state.currentLang
          );
          superAttributes.push({
            label: getLabel
              ? getLabel.node.value
              : attr.node.translation.translationDatas.edges[0].node.value,
            node: attr.node,
          });
        }
      }

      let children = result.data.product?.children?.edges ?? [];

      for (let child of children) {
        let getName = child.node.productDatas.edges.find(
          (e) => e.node.attribute.identifier === "product_name"
        );
        let getPrice = child.node.productDatas.edges.find(
          (e) => e.node.attribute.identifier === "product_price"
        );

        let getImage = child.node.productDatas.edges.find(
          (e) => e.node.attribute.identifier === "product_image"
        );
        let getAttrVariant = [];
        for (let superAttr of superAttributes) {
          let getAttr = child.node.productDatas.edges.find(
            (e) => e.node.attribute.id === superAttr.node.id
          );
          if (getAttr) {
            let getLabel =
              getAttr.node.attribute.translation.translationDatas.edges.find(
                (e) => e.node?.locale.code === this.state.currentLang
              );
            let getVariant = {
              id: getAttr.node.attribute.id,
              identifier: getAttr.node.attribute.identifier,
              label: getLabel
                ? getLabel.node.value
                : getAttr?.node.attribute.translation.translationDatas.edges[0]
                    .node.value,
              productDataId: getAttr.node.id,
              type: getAttr.node.attribute.attributeType.input,
              values:
                getAttr.node.attribute.attributeType.input === "select"
                  ? getAttr.node.attributeOption.id
                  : getAttr.node.value,
              valuesForSelect: superAttr.node.attributeOptions.edges,
            };
            getAttrVariant.push(getVariant);
          }
        }
        variantValues.push({
          idVariant: child.node.id,
          sku: child.node.sku,
          name: getName?.node.value,
          price: {
            id: getPrice?.node.id,
            value: getPrice?.node.value,
          },
          image: {
            id: getImage?.node.id,
            value: getImage?.node.media,
          },
          new: false,
          updated: false,
          allAttr:
            getAttrVariant.length === 0
              ? await this.props.client
                  .query({
                    query: GET_PRODUCT_SUPER_ATTRIBUTES,
                    variables: {
                      id: child.node.id,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then((result) => {
                    return result.data.product.superAttribute.edges.map(
                      (res) => ({
                        label:
                          res.node.translation.translationDatas.edges[0].node
                            .value,
                        type: res.node.attributeType.input,
                        identifier: res.node.identifier,
                        id: res.node.id,
                        valuesForSelect: [],
                        values: child.node.productDatas.edges[3].node.value,
                      })
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                    return getAttrVariant;
                  })
              : getAttrVariant,
          // allAttr: getAttrVariant,
        });
      }
      let copyVariantValues = this.copyArrayOfObjects(variantValues);
      this.setState({
        attributesSelected: superAttributes,
        variantsValidated: {
          attributes: result.data.product?.superAttribute.edges,
          values: variantValues,
        },
        // variantsProduct: variantValues ? copyVariantValues : [],
        initialVariant: copyVariantValues,
        initialVariantId: variantValues.map((e) => e.idVariant),
        varianteReady: true,
      });
    });
  }

  prepareAttributesLocales(result) {
    return new Promise((resolve, reject) => {
      let attributes = [];

      if (result.data.product?.productDatas.edges) {
        for (let { node } of result.data.product?.productDatas.edges) {
          let found = false;
          for (let attribute of attributes) {
            if (attribute.id === node.attribute.id) {
              found = true;
              // add locale to existing attribute
              attribute.locales.push({
                value:
                  node.attribute.attributeType.input === "select"
                    ? node.attributeOption.id
                    : node.attribute.attributeType.input === "select_multiple"
                    ? JSON.parse(node?.value) || []
                    : node.value,
                media: node.media,
                mediaObjectCategory:
                  node.attribute.attributeType.input === "media_category"
                    ? node.mediaObjectCategory
                    : null,
                id: node.locale.id,
                code: node.locale.code,
                productDataId: node.id,
                type: node.attribute.attributeType.input,
                isLocked: node.isLocked,
              });
            }
          }

          if (!found) {
            // create attribute and add locale
            attributes.push({
              id: node.attribute.id,
              identifier: node.attribute.identifier,
              attributeType: node.attribute.attributeType,
              isSystem: node.attribute.isSystem,
              attributeOptions: node.attributeOptions,
              isLocked: node.isLocked,
              translationLabel:
                node.attribute.translation.translationDatas.edges,
              locales: [
                {
                  value:
                    node.attribute.attributeType.input === "select"
                      ? node.attributeOption?.id
                      : node.attribute.attributeType.input === "select_multiple"
                      ? JSON.parse(node?.value) || []
                      : node.value,
                  media: node.media,
                  id: node.locale.id,
                  mediaObjectCategory:
                    node.attribute.attributeType.input === "media_category"
                      ? node.mediaObjectCategory
                      : null,
                  code: node.locale.code,
                  productDataId: node.id,
                  isLocked: node.isLocked,
                  type: node.attribute.attributeType.input,
                },
              ],
            });
          }
        }
      }

      this.setState({ attributes, initialAttributes: attributes }, resolve);
      this.setState({ scrapAttributes: attributes }, resolve);
    });
  }

  prepareAttributesLocalesSource(result) {
    return new Promise((resolve, reject) => {
      let attributes = [];

      for (let { node } of result.data.productSourceDatas.edges) {
        let found = false;

        for (let attribute of attributes) {
          if (attribute.id === node.attribute.id) {
            found = true;
            // add locale to existing attribute
            attribute.locales.push({
              value:
                node.attribute.attributeType.input === "select" ||
                node.attribute.attributeType.input === "select_multiple"
                  ? node.attributeOption.id
                  : node.value,
              media: node.media,
              id: node.locale.id,
              code: node.locale.code,
              productDataId: node.id,
              type: node.attribute.attributeType.input,
            });
          }
        }

        if (!found) {
          // create attribute and add locale
          attributes.push({
            id: node.attribute.id,
            identifier: node.attribute.identifier,
            attributeType: node.attribute.attributeType,
            isSystem: node.attribute.isSystem,
            attributeOptions: node.attributeOptions,
            translationLabel: node.attribute.translation.translationDatas.edges,
            locales: [
              {
                value:
                  node.attribute.attributeType.input === "select" ||
                  node.attribute.attributeType.input === "select_multiple"
                    ? node.attributeOption?.id
                    : node.value,
                media: node.media,
                id: node.locale.id,
                code: node.locale.code,
                productDataId: node.id,
                type: node.attribute.attributeType.input,
              },
            ],
          });
        }
      }
      this.setState({ scrapAttributes: attributes }, resolve);
    });
  }

  prepareAttributeValues() {
    return new Promise((resolve, reject) => {
      for (let locale of this.props.locales) {
        let values = {};
        for (let attribute of this.state.attributes) {
          let attributeLocale = attribute.locales.find(
            (e) => e.code === locale.node.code
          );
          // let defaultLocale   = attribute.locales.find(e => e.code === this.state.currentLang);
          let defaultLocale = attribute.locales.find((e) => e.code === "fr_FR");
          if (!attributeLocale && defaultLocale) {
            if (attribute.attributeType.input === "select") {
              values[attribute.identifier] = {
                value: defaultLocale?.value ?? attribute.locales[0],
                new: false,
                updated: false,
                isLocked: defaultLocale.isLocked
                  ? defaultLocale.isLocked
                  : false,
              };
            }
            switch (attribute.attributeType.input) {
              case "file":
              case "image":
                values[attribute.identifier] = {
                  value: {
                    id: defaultLocale?.media ? defaultLocale.media.id : null,
                    data: defaultLocale?.media
                      ? defaultLocale.media.filePath
                      : null,
                    file: null,
                    changed: false,
                    type: defaultLocale.media?.type,
                    name: defaultLocale.media?.name,
                  },
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                };
                break;
              case "select_multiple":
                values[attribute.identifier] = {
                  value: defaultLocale.value,
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                };
                break;
              case "media_category":
                values[attribute.identifier] = {
                  value: defaultLocale?.value,
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                  mediaObjectCategory: defaultLocale?.mediaObjectCategory?.id,
                };
                this.setState({
                  selectedMediaCategories:
                    defaultLocale?.mediaObjectCategory?.id,
                });
                break;
              default:
                values[attribute.identifier] = {
                  value: defaultLocale.value,
                  new: true,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                };
                break;
            }
          } else if (attributeLocale) {
            switch (attribute.attributeType.input) {
              case "file":
              case "image":
                values[attribute.identifier] = {
                  value: {
                    id: attributeLocale.media?.id,
                    data: attributeLocale.media
                      ? attributeLocale.media.filePath
                      : null,
                    file: null,
                    changed: false,
                    type: attributeLocale.media?.type,
                    name: attributeLocale.media?.name,
                  },
                  new: false,
                  updated: false,
                  isLocked: attribute.isLocked ? attribute.isLocked : false,
                };
                break;
              case "select_multiple":
                values[attribute.identifier] = {
                  value: attributeLocale.value,
                  new: true,
                  updated: false,
                  isLocked: attributeLocale.isLocked
                    ? attributeLocale.isLocked
                    : false,
                };
                break;
              case "media_category":
                values[attribute.identifier] = {
                  value: defaultLocale?.value,
                  new: false,
                  updated: false,
                  isLocked: defaultLocale.isLocked
                    ? defaultLocale.isLocked
                    : false,
                  mediaObjectCategory: attributeLocale?.mediaObjectCategory?.id,
                };
                this.setState({
                  selectedMediaCategories:
                    attributeLocale?.mediaObjectCategory?.id,
                });
                break;
              default:
                values[attribute.identifier] = {
                  value: attributeLocale.value,
                  new: false,
                  updated: false,
                  isLocked: attributeLocale.isLocked
                    ? attributeLocale.isLocked
                    : false,
                };
                break;
            }
          }
        }

        this.setState({
          [locale.node.code]: values,
        });
      }
      resolve();
    });
  }

  initCollections() {
    let localStorage_GET_SELECTIONS = localStorage.getItem("GET_SELECTIONS");
    if (!localStorage_GET_SELECTIONS) {
      this.props.client
        .query({
          query: GET_SELECTIONS,
          fetchPolicy: "no-cache",
        })
        .then((result) => {
          localStorage.setItem("GET_SELECTIONS", JSON.stringify(result));

          this.setState({
            listCollections: result.data.selections.edges,
          });
        });
    } else {
      let result = JSON.parse(localStorage_GET_SELECTIONS);
      this.setState({
        listCollections: result.data.selections.edges,
      });
    }
  }

  getNextAndPrevProduct = () => {
    // this.setState({
    //     nextPath:null,
    //     previousPath:null
    // })
    let currentId = this.state.productId.substring(
      this.state.productId.lastIndexOf("/") + 1,
      this.state.productId.length
    );
    let pathWithoutId = this.state.productId.substring(
      0,
      this.state.productId.lastIndexOf("/") + 1
    );
    let previousId = pathWithoutId + (Number(currentId) - 1);
    let nextId = pathWithoutId + (Number(currentId) + 1);

    this.props.client
      .query({
        query: GET_PRODUCT_DATA,
        fetchPolicy: "no-cache",
        variables: {
          id: nextId,
          selection: null,
        },
      })
      .then((result) => {
        if (result.data.product) {
          this.setState({
            nextPath: `/products/list/${Number(currentId) + 1}`,
          });
        }
        this.props.client
          .query({
            query: GET_PRODUCT_DATA,
            fetchPolicy: "no-cache",
            variables: {
              id: previousId,
              selection: null,
            },
          })
          .then((result) => {
            if (result.data.product) {
              this.setState({
                previousPath: `/products/list/${Number(currentId) - 1}`,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initProduct(blockSecondaryData) {
    this.props.client
      .query({
        query: GET_PRODUCT_DATA_LIMITED,
        fetchPolicy: "no-cache",
        variables: {
          id: this.state.productId,
          selection:
            this.state.currentCollection !== "-1"
              ? this.state.currentCollection
              : null,
        },
      })
      .then(async (GET_PRODUCT_DATA_RESULT) => {
        // Note : We made 2 queries to avoid node resolver load (2s vs 10s)
        let resultWithCategories;
        // let resultWithCategories = await this.props.client.query({
        //     query: GET_PRODUCT_DATA_LIMITED_WITHOUT_CATEGORIES,
        //     fetchPolicy: "no-cache",
        //     variables: {
        //         id: this.state.productId,
        //         selection: this.state.currentCollection !== "-1" ? this.state.currentCollection : null,
        //     }
        // });
        this.setState({
          scrapStatus: GET_PRODUCT_DATA_RESULT.data.product?.scrapStatus,
        });
        let categoriesNb =
          GET_PRODUCT_DATA_RESULT.data.product?.flatProducts?.edges[0]?.node
            .categoriesNb;
        // let filterCategoriesCatalog = GET_PRODUCT_DATA_RESULT?.data?.product?.productCategories?.edges.map(e => e.node.category) || []
        let getCategoriesCatalog =
          resultWithCategories?.data?.product?.productCategories?.edges
            .filter((e) => e.node.category.catalog !== null)
            .map((e) => e.node.category) || [];
        let getimageProduct =
          GET_PRODUCT_DATA_RESULT.data.product?.productDatas.edges.find(
            (e) => e.node.attribute.identifier === "product_image"
          );
        let array = [];
        if (process.env.REACT_APP_MULTIPLE_PRICE === "true") {
          this.props.client
            .query({
              query: GET_RETAILER_PRODUCT_DATA,
              fetchPolicy: "no-cache",
              variables: {
                sku: GET_PRODUCT_DATA_RESULT.data.product?.sku,
              },
            })
            .then(async (skuresult) => {
              const skuPriceResult =
                skuresult.data.retailerProductDatas.edges[0]?.node.price;
              this.setState({
                skuPrice: skuPriceResult ? skuPriceResult : null,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }

        array.push({
          node: GET_PRODUCT_DATA_RESULT.data.product?.attributeGroup,
        });

        let children =
          GET_PRODUCT_DATA_RESULT.data.product?.children?.edges ?? [];
        this.setState(
          {
            sku: GET_PRODUCT_DATA_RESULT.data.product?.sku,
            groupAttribut:
              GET_PRODUCT_DATA_RESULT.data.product?.attributeGroup.id,
            // categories: filterCategoriesCatalog, // product categories
            attributes: [],
            allGroups: array,
            status: GET_PRODUCT_DATA_RESULT.data.product?.status,
            isVariant:
              children.length ||
              GET_PRODUCT_DATA_RESULT.data.product?.superAttribute?.edges
                ?.length > 0
                ? true
                : false,
            defaultImageProduct: getimageProduct?.node.media,
            categoriesCatalog: getCategoriesCatalog,
            fetchedProduct: GET_PRODUCT_DATA_RESULT.data.product,
            categoriesNb,
            ready: true,
          },
          async () => {
            this.initSources();

            this.prepareAttributes();
            await this.prepareAttributesLocales(GET_PRODUCT_DATA_RESULT);
            await this.prepareAttributeValues();

            if (!blockSecondaryData) this.initSecondaryData();
            else if (this.state.selectedProductsClone) {
              this.setState({
                selectedProducts: [...this.state.selectedProductsClone],
              });
            }

            let getNbCatalog =
              GET_PRODUCT_DATA_RESULT.data.product?.flatProducts?.edges[0]?.node
                .catalogsNb;
            this.setState({
              nbCatalog: getNbCatalog,
            });

            if (!this.state.reload) {
              await this.props.client
                .query({
                  query: GET_CATEGORIES_DATA_LIMITED,
                  variables: {
                    id: this.state.productId,
                  },
                })
                .then((result) => {
                  this.setState({
                    categories: result.data.product.productCategories.edges.map(
                      (e) => e.node.category
                    ),
                  });
                });
            }

            //GET TAXOS
            this.props.client
              .query({
                query: GET_PRODUCT_TAXOS,
                fetchPolicy: "no-cache",
                variables: {
                  product: this.props.match.params.id,
                },
              })
              .then(async (GET_PRODUCT_TAXOS_RESULT) => {
                this.setState({
                  listTaxos: GET_PRODUCT_TAXOS_RESULT.data.productTaxos.edges,
                });
              });

            request(
              `${process.env.REACT_APP_API}/getRetailerUrlByProduct/${this.state.sku}`,
              "get"
            ).then((data) => {
              if (data.success && data.products[this.state.sku]?.length > 0) {
                this.setState({
                  retailersInfos: data.products[this.state.sku],
                });
              }
            });

            if (process.env.REACT_APP_MODE_SPREAD === "hub") {
              await request(
                `${process.env.REACT_APP_API}/stockCompletudeBySku/${this.state.sku}`,
                "post"
              ).then((data) => {
                if (data.success) {
                  this.setState({
                    stocksProduct: data.products[this.state.sku].stock,
                    commandsProduct: data.products[this.state.sku].orders,
                    datasStocks: data.products[this.state.sku],
                  });
                }
              });
            }
          }
        );
      });
  }

  initProductSources() {
    this.setState({
      scrapIsReady: false,
    });
    this.props.client
      .query({
        query: GET_PRODUCT_DATA_SOURCE,
        fetchPolicy: "no-cache",
        variables: {
          product: this.state.productId,
          source: this.state.currentSource,
        },
      })
      .then((GET_PRODUCT_DATA_SOURCE_RESULT) => {
        let getimageProduct =
          GET_PRODUCT_DATA_SOURCE_RESULT.data.productSourceDatas.edges.find(
            (e) => e.node.attribute.identifier === "product_image"
          );
        this.setState({
          scrapImages: getimageProduct?.node.media,
        });
        this.setState(
          {
            // sku: GET_PRODUCT_DATA_SOURCE_RESULT.data.product.sku,
            defaultImageProduct: getimageProduct?.node.media,
          },
          async () => {
            await this.prepareAttributesLocalesSource(
              GET_PRODUCT_DATA_SOURCE_RESULT
            );
            await this.prepareAttributeValues();
            this.setState({
              ready: true,
              scrapIsReady: true,
            });
          }
        );
      });
  }

  initSecondaryData() {
    this.props.client
      .query({
        query: GET_PRODUCT_SECONDARY_DATA,
        fetchPolicy: "no-cache",
        variables: {
          id: this.state.productId,
          selection:
            this.state.currentCollection !== "-1"
              ? this.state.currentCollection
              : null,
        },
      })
      .then((GET_PRODUCT_SECONDARY_DATA_RESULT) => {
        this.prepareVariants(GET_PRODUCT_SECONDARY_DATA_RESULT);

        let suggestions =
          GET_PRODUCT_SECONDARY_DATA_RESULT.data.product?.suggestions?.edges ??
          [];
        let children =
          GET_PRODUCT_SECONDARY_DATA_RESULT.data.product?.children?.edges ?? [];

        let selectedProducts = [];

        for (let suggestion of suggestions)
          selectedProducts.push(suggestion.node.id);

        this.setState(
          {
            selectedProducts,
            selectedProductsClone: [...selectedProducts], // used to reset card suggestions count without requesting secondary data on cancel
            isVariant:
              children.length ||
              GET_PRODUCT_SECONDARY_DATA_RESULT.data.product?.superAttribute
                .edges.length > 0
                ? true
                : false,
            secondaryDataLoaded: true,
          },
          () => {
            if (this.state.selectedProducts.length > 0) {
              this.props.client
                .query({
                  query: GET_PRODUCTS_PAGINATION_FLATS,
                  variables: {
                    idList: this.state.selectedProducts.map((e) =>
                      e.replace("/api/products/", "")
                    ),
                    locale: this.props.locales.find(
                      (e) => e.node.code === this.state.currentLang
                    ).node.id,
                  },
                })
                .then((result) => {
                  this.setState({
                    suggestionsProduct: result.data.products.edges,
                  });
                });
            }
          }
        );
      });
  }

  goTo = (route, id, list) => {
    this.props.history.push({
      pathname: route,
      state: { productId: id, productList: list },
    });
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    snack: (type, message) =>
      dispatch({ type: SNACK, payload: { type, message } }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    products: state.products,
    attributes: state.attributes,
    attributeGroups: state.attributeGroups,
    locales: state.locales,
  };
};

export default withTranslation()(
  withRouter(
    withApollo(connect(mapStateToProps, mapDispatchToProps)(ProductDetail))
  )
);
