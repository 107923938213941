import { FormInputTypes } from '../../../shareable/types';
import { getDefaultButton } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Fiche',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fffff'
        },
        moreInfo: {
            label: "Texte attributs",
            type: FormInputTypes.TEXT,
            value: "Plus d'informations"
        },
        description: {
            label: "Texte description",
            type: FormInputTypes.TEXT,
            value: "Description"
        },
        addToWishlist: {
            label: 'Texte ajout',
            type: FormInputTypes.TEXT,
            value: 'Ajouter à la wishlist'
        },
        removeFromWishlist: {
            label: 'Texte retrait',
            type: FormInputTypes.TEXT,
            value: 'Retirer de la wishlist'
        },
    },
    blocks: {
        buttonWishlist: getDefaultButton("Bouton Wishlist", "Texte par défaut", 16, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 10, 20, 'uppercase'),
    }
};