import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultDoubleColonne } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";


export default {
    name: 'Bloc Double Colonne',
    inputs: {
        identifiant: {
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className: {
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        fontSizeMainTitle: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Taille du h1 (en px)",
            params: { max: 100, min: 2, step: 2 },
        },
        fontSizeSecondaryTitle: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Taille des h2 (en px)",
            params: { max: 100, min: 2, step: 2 },
        },
        fontSizeText: {
            type: FormInputTypes.SLIDER,
            value: 20,
            label: "Taille du texte (en px)",
            params: { max: 100, min: 2, step: 2 },
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding top",
            params: { max: 200, min: 0, step: 2 },
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding bottom",
            params: { max: 200, min: 0, step: 2 },
        },
       /*  noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        }, */
        columnGap: {
            type: FormInputTypes.SLIDER,
            value: 0,
            label: "Espace entre les colonnes",
            params: { max: 100, min: 0, step: 1 },
        },
        responsive: {
            type: FormInputTypes.GROUP,
            label: "RESPONSIVE",
            inputs: {
                xs: {
                    type: FormInputTypes.SELECT,
                    label: "extra-small < 600px",
                    value: "1",
                    params: [
                        { label: "1 colonne", value: "1" },
                        { label: "2 colonne", value: "2" },
                    ],
                },
                sm: {
                    type: FormInputTypes.SELECT,
                    label: "small < 900px",
                    value: "12",
                    params: [
                        { label: "1 colonne", value: "1" },
                        { label: "2 colonne", value: "2" },
                    ],
                },
                md: {
                    type: FormInputTypes.SELECT,
                    label: "médium < 1200px",
                    value: "6",
                    params: [
                        { label: "1 colonne", value: "1" },
                        { label: "2 colonne", value: "2" },
                    ],
                },
                lg: {
                    type: FormInputTypes.SELECT,
                    label: "large < 1500px",
                    value: "6",
                    params: [
                        { label: "1 colonne", value: "1" },
                        { label: "2 colonne", value: "2" },
                    ],
                },
            }
        }
    },
    blocks: {
        column_one: {
            title: "Contenu colonne gauche",
            name: "Contenu colonne gauche",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_DOUBLE_COLONNE,
            buttonAdd: 'un contenu',
            config: [
                getDefaultDoubleColonne(),
            ]
        },
        column_two: {
            title: "Contenu colonne droite",
            name: "Contenu colonne droite",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_DOUBLE_COLONNE,
            buttonAdd: 'un contenu',
            config: [
                getDefaultDoubleColonne(),
            ]
        }
    }
};
