import React, { useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";

const SliderWithRightImages = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null
}) => {
    const [hover, setHover] = useState(false);
    const [page, setPage]   = useState(0);
    const ref = useRef(null);

    let slides = data.blocks['slides'].config;

    let params = {
        loop: false,
        speed: 1000,
        autoplay: {
            delay: 5000,
            disableOnInteraction: true
        },
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        shouldSwiperUpdate: true,
        pagination: false,
        on: {
            slideChange: () => {
                if (ref.current !== null && ref.current.swiper !== null) {
                    setPage(ref.current.swiper.activeIndex);
                }
            }
        }
    };

    const styles = {
        backgroundColor: !preview ? '#eee' : '#fff',
        marginBottom: 20,
        width: '100%',
        boxSizing: 'border-box'
    };

    let image1 = data.blocks["image1"].inputs.image?.value;
    let image2 = data.blocks["image2"].inputs.image?.value;

    let col = data.blocks['slides'].inputs?.color?.value;

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="container slider-with-right-images">
                <div className="row">
                    <div className="col-9">
                        <div className="slider-area">
                            <div className="slider-active-2 nav-style-2">
                                <Swiper {...params} ref={ref}>
                                    {slides && slides.map((single, key) => {
                                        let image = single.inputs['image'].value;

                                        return (
                                            <div
                                                key={`slide-${key}`}
                                                className={`slider-height-4 d-flex align-items-center bg-img swiper-slide`}
                                                style={{
                                                    backgroundImage: `url(${typeof image === 'string' ? image : `${process.env.REACT_APP_MEDIAS}/${image.filePath}`})`,
                                                    position: 'relative'
                                                }}
                                            >
                                                <div className="container" style={{ zIndex: 15 }}>
                                                    <div className="row">
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                            <div className="slider-content-5 slider-animated-1 text-center">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Swiper>
                            </div>

                            <div className="pagination-container">
                                {slides && slides.map((single, key) => { 
                                    return <span className={`bullet ${page === key ? 'active' : ''}`} key={key} style={{ backgroundColor: col }} onClick={() => {
                                        if (ref.current !== null && ref.current.swiper !== null) {
                                            ref.current.swiper.slideTo(key);
                                        }
                                    }}></span>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-3 right-images">
                        <div
                            key={`image-1`}
                            className={`slider-image`}
                            style={{ backgroundImage: `url(${typeof image1 === 'string' ? image1 : `${process.env.REACT_APP_MEDIAS}/${image1.filePath}`})` }}
                        />

                        <div
                            key={`image-2`}
                            className={`slider-image`}
                            style={{ backgroundImage: `url(${typeof image2 === 'string' ? image2 : `${process.env.REACT_APP_MEDIAS}/${image2.filePath}`})` }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderWithRightImages;
