import {COMPONENT} from '../../../../js/constants/form-types';
import Button from '../../../ui/button/Button';
import ProductSelectorMerch from '../components/ProductSelectorMerch'

export default function replaceConfig(action = 'add') {
    var form = {
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add'? 'Ajouter un produit' : 'Remplacer le produit',
        formConfig: {
            title: action === 'add'? 'Ajouter un produit':'Remplacer un produit',
            subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'ajouter votre produit':'remplacer le produit'}`,
            uniqueSelect: true,
            type : COMPONENT,
            component : ProductSelectorMerch
        }
    }
    return form;
}