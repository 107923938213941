import React, { useState } from "react";
import { SocialNetworks } from "../../../../../types";
import H1 from "../../../../shared/components/CustomH1";
import H3 from "../../../../shared/components/CustomH3";
import Button from '../../../../shared/components/CustomButton';
import request from "../../../../../tools/Fetch";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

const Contact = ({
  preview = false,
  id = null,
  data = null,
  params = null,
  inputCallback = null,
  spread = false,
  children = null,
  emailTemplate = null,
  assetToken = null
}) => {
  const [hover, setHover] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState(null);
  const [snackType, setSnackType] = useState(null);

  const ContactInfo = () => (
    <div className="contact-info-wrap">
      <div className="single-contact-info">
        <div className="contact-icon">
          <i className="fa fa-phone" />
        </div>
        <div className="contact-info-dec">
          {params?.numberOne?.value ? <p><a href={`tel:${params?.numberOne?.value}`}>{params?.numberOne?.value}</a></p> : null}
          {params?.numberTwo?.value ? <p><a href={`tel:${params?.numberTwo?.value}`}>{params?.numberTwo?.value}</a></p> : null}
        </div>
      </div>
      <div className="single-contact-info">
        <div className="contact-icon">
          <i className="fa fa-globe" />
        </div>
        <div className="contact-info-dec">
          {params?.webOne?.value ? <p><a href={params?.webOneLink?.value} target="_blank">{params?.webOne?.value}</a></p> : null}
          {params?.webTwo?.value ? <p><a href={params?.webTwoLink?.value} target="_blank">{params?.webTwo?.value}</a></p> : null}
        </div>
      </div>
      <div className="single-contact-info">
        <div className="contact-icon">
          <i className="fa fa-map-marker" />
        </div>
        <div className="contact-info-dec">
          {params?.addressOne?.value ? <p>{params?.addressOne?.value}</p> : null}
          {params?.addressTwo?.value ? <p>{params?.addressTwo?.value}</p> : null}
        </div>
      </div>
      <div className="contact-social text-center">
        <H3
          data={data}
          preview={preview}
          component={id}
          block={'followUs'}
          inputCallback={inputCallback}
          nopadding={true}
        />
        <ul>
          {
            params?.social?.value?.map((e, i) => {
              if (!e.value) return null;

              const getIcon = (icon) => {
                switch (icon) {
                  case SocialNetworks.FACEBOOK:
                    return "fa fa-facebook";
                  case SocialNetworks.INSTAGRAM:
                    return "fa fa-instagram";
                  case SocialNetworks.YOUTUBE:
                    return "fa fa-youtube";
                  case SocialNetworks.PINTEREST:
                    return "fa fa-pinterest";
                  case SocialNetworks.TWITTER:
                    return "fa fa-twitter";
                  case SocialNetworks.LINKEDIN:
                    return "fa fa-linkedin";
                  default:
                    return null;
                }
              };

              return (
                <li key={i}>
                  <a href={e.value} target="_blank">
                    <i className={getIcon(e.icon)} />
                  </a>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );

  const onChange = (event, callback) => {
    callback(event.target.value);
  };
  
  return (
    <div
      className="builder-template-scope contact-area pt-100 pb-100"
      onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
      style={{
        width: '100%',
        marginBottom: 20,
        backgroundColor: data.inputs['backgroundColor'].value,
      }}
    >
      { !preview && hover && children}

      <div className="container">
        {/* <div className="contact-map mb-10">
          <LocationMap latitude="47.444" longitude="-122.176" />
        </div> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <MuiAlert elevation={6} variant="filled" onClose={() => setOpen(false)} severity={snackType}>
            {snack}
          </MuiAlert>
        </Snackbar>

        <div className="custom-row-2">
          <div className="col-lg-4 col-md-5">
            <ContactInfo />
          </div>

          {
            !(params.hasContact?.value ?? true) ? null : (
              <div className={!(params.hasContact?.value ?? true) ? "col-lg-8" : "col-lg-8 col-md-7"}>
                <div className="contact-form">
                  <div className="contact-title mb-30">
                    <H1
                      data={data}
                      preview={preview}
                      component={id}
                      block={'title'}
                      inputCallback={inputCallback}
                      nopadding={true}
                    />
                  </div>
                  <form className="contact-form-style">
                    <div className="row">
                      <div className="col-lg-6">
                        <input name="name" placeholder={data.inputs?.['lastname']?.value} onChange={(e) => onChange(e, setName)} type="text" />
                      </div>
                      <div className="col-lg-6">
                        <input name="email" placeholder={data.inputs?.['email']?.value} onChange={(e) => onChange(e, setEmail)} type="email" />
                      </div>
                      <div className="col-lg-12">
                        <input
                          name="subject"
                          placeholder={data.inputs?.['subject']?.value}
                          onChange={(e) => onChange(e, setSubject)}
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          name="message"
                          placeholder={data.inputs?.['message']?.value}
                          defaultValue={""}
                          onChange={(e) => onChange(e, setMessage)}
                        />
                        <Button
                          data={data}
                          preview={preview}
                          component={id}
                          block={'button'}
                          inputCallback={inputCallback}
                          className="button"
                          nopadding={true}
                          type="button"
                          onClick={() => {
                            if (spread) {
                              if (!message || !subject || !name || !email) {
                                setOpen(true);
                                setSnackType('error');
                                setSnack("Veuillez remplir tous les champs...");
                                return;
                              }

                              if (!/\S+@\S+\.\S+/.test(email)) {
                                setOpen(true);
                                setSnackType('error');
                                setSnack("Veuillez vérifier l'email...");
                                return;
                              }

                              let html = emailTemplate.replace('%object%', subject);
                              html = html.replace('%message%', message);
                              html = html.replace('%email%', email);
                              html = html.replace('%name%', name);
                              let data = new FormData();

                              data.append('token', assetToken);
                              data.append('subject', subject);
                              data.append('emailHtml', html);
                              data.append('recipient', 'fteyssou@sinfin.fr');

                              try {
                                request(`${process.env.REACT_APP_API}/asset/send/email`, 'post', data, 'multipart/form-data').then(response => {
                                  setOpen(true);
                                  setSnackType('success');
                                  setSnack('Votre message a bien été envoyé !');
                                });
                              } catch (e) {
                                setOpen(true);
                                setSnackType('error');
                                setSnack('Une erreur est survenue, veuillez réessayer plus tard...');
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Contact;