import { TABS } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CategoryMasterSelector from '../../../../ui/inputs/CategoryMasterSelector';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function formCategoriesEdit(currentId, categories, parentId, inputAttributes, currentLang, errors, seeErrors, handleMediaPicker, productAttributes,isCatalog = false, cms = false, assetMagentoPages = null, allState, contentCategories = [], categoryContentId, userGroups, mediaCategories = [], selectedMediaCategories = []) {
    let typeInput = '';

    let attributeSetup = attribute => {
        let isMeta = attribute.node.identifier.indexOf('meta') > -1
        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = isMeta? 'textareaSimple' : 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'file':
                typeInput = 'file';
                break;
            case 'image':
            case 'file':
                typeInput = 'mediaPicker';
                break;
            default: typeInput = null;
        }

        const specialInputs = {
            category_users: {
                type: 'selectTree',
                data: userGroups?.map(({node: userGroup}) => ({
                    id: userGroup.id,
                    label: userGroup.code,
                    checked: allState[currentLang].category_users?.find(gr => gr.id === userGroup.id) ? true : false,
                })),
                multiselect: true
            },
            category_content_categories: {
                type: 'selectTree',
                data: (() => {
                    // TODO: Create state to check categories
                    let data = mediaCategories.filter(e => e.node.parent === null);
                    let populateChildren = (cats, parent) => {
                        // let catSelected         = categorieSelected.find(e => e.node?.id === parent.node.id) || '/api/media-object-categories/'+categorieSelected[0] === parent.node.id ? parent.node.id : null;
                        let catSelected         = selectedMediaCategories.find(e => e?.id === parent.node.id || e?.node?.id === parent.node.id) || '/api/media-object-categories/'+selectedMediaCategories[0] === parent.node.id ? parent.node.id : null;
                        parent.value            = parent.node.id;
                        parent.label            = parent.node.libelle;
                        parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                        parent.checked          = catSelected ? true : false;
                        parent.expanded         = parent.value === "/api/media-object-categories/1"?true:false
                        
                        for (let child of parent.children){
                            populateChildren(cats, child);                                            
                        }
                    };

                    for (let parent of data){
                        populateChildren(mediaCategories, parent); 
                    }
                    return data;
                })(),
                multiselect: true
            },
            default: {
                type: typeInput,
                value: attribute.node.attributeOptions.edges.map((values) => {
                    const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];
    
                    const langSelectedValues = values.node.translation.translationDatas.edges.find(
                        lang => lang.node.locale.code === currentLang
                    );
    
                    return ({
                        value: values.node.id,
                        label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                    });
                })
            }
        }

        return ({
            type: (specialInputs[attribute.node.identifier] ?? specialInputs.default).type,
            label: langSelected?.node.value ?? defaultLang.node.value,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: false,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            handleMediaPicker : typeInput === 'mediaPicker' || typeInput === 'file'  ? handleMediaPicker : null,
            value: (specialInputs[attribute.node.identifier] ?? specialInputs.default).value,
            ...(specialInputs[attribute.node.identifier] ?? specialInputs.default)
        });
    };

    let generalInputs = [
        {
            type: 'text',
            label: 'Identifiant',
            translated: false,
            helper: {
                text: 'Identifiant de la catégorie',
                link: false,
            },
            required: false,
            disabled: true,
            stateName: 'identifier',
        },
        {
            type: 'selectTree',
            label: 'Parent',
            translated: false,
            helper: {
                text: 'Indiquez le parent',
                link: false,
            },
            required: true,
            stateName: 'parent',
            data: (() => {
                let data = categories?.filter(e => e.parent === null && e.libelle === 'Root');

                let populateChildren = (cats, parent) => {
                    // let nameAttribute = parent.contentCategoryDatas.edges?.filter(e => e.node.attribute.identifier === 'category_name') ?? null;
                    // let nameAttribute = parent.libelle;
                    // let currentTranslation = nameAttribute?.find(
                    //     translation => translation.node.locale === currentLang
                    // ) ?? null;

                    parent.value = parent.id;
                    parent.checked = parent.id === allState.parent;
                    parent.label = isCatalog && parent.parent === null ? '/' : parent.libelle === 'Root' ? '/' : parent.libelle;
                    parent.disabled = false;
                    parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
                    parent.isDefaultValue = parent.id === categoryContentId;
                    // parent.expanded = isCatalog && parent.parent === null ? true : false;
                    parent.expanded = true;
                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(categories, parent);

                return data;
            })()
        },
    ];
    
    if (isCatalog){
        generalInputs.push({
            type: 'buttonGroup',
            label: 'Activation',
            helper: {
                label: 'État',
                text: 'Indiquez si cette catégorie est activé',
                link: false,
            },
            required: true,
            stateName: 'catActivated',
            value: [
                {
                    value: true,
                    label: 'Activé',
                },
                {
                    value: false,
                    label: 'Désactivé',
                },
            ]
        },)
    }

    let specificInputs = []; 

    specificInputs.push({
        type: 'select',
        label: 'Type de catégorie',
        translated: false,
        helper: {
            text: 'Type de catégorie',
            link: false,
        },
        stateName: 'typeCategorie',
        value: [
            {
                value: 'category',
                label: 'Listing de produits'
            },
            {
                value: 'cms',
                label: 'Page'
            },
            {
                value: 'content',
                label: 'Catégorie de contenu'
            },
            {
                value: 'news',
                label: 'Actualités'
            },
            {
                value: "recruitment",
                label: "Offres d'emploi"
            },
            {
                value: "map",
                label: "Carte"
            },
        ],
        disabled: false,
    });

    if(cms === 'cms'){
        let valueCmsPages = [];
        for (let assetMagentoPage of assetMagentoPages){
            if(assetMagentoPage.node.useInCategory){
                valueCmsPages.push({value: assetMagentoPage.node.id, label: assetMagentoPage.node.libelle});
            }
        }

        specificInputs.push({
            type: 'select',
            label: 'CMS Page',
            translated: false,
            required: true,
            helper: {
                text: 'CMS Page',
                link: false,
            },
            stateName: 'cmsPage',
            value: valueCmsPages
        })
    }

    if(cms === 'content'){
        specificInputs.push({
            type: 'selectTree',
            label: 'Catégorie content',
            translated: false,
            required: true,
            helper: {
                text: 'Catégorie content',
                link: false,
            },
            stateName: 'categoryContent',
            data: (() => {
                let data = contentCategories.filter(e => e.parent === null && e.libelle === 'Root');

                let populateChildren = (cats, parent) => {
                    let nameAttribute = parent.contentCategoryDatas.edges?.filter(e => e.node.attribute.identifier === 'category_name') ?? null;

                    let currentTranslation = nameAttribute?.find(
                        translation => translation.node.locale === currentLang
                    ) ?? null;

                    parent.value  = parent.id;
                    parent.label  = isCatalog && parent.parent === null ? '/' : parent.libelle === 'Root' ? '/' : currentTranslation?.value ?? nameAttribute?.[0]?.node?.value ?? parent.libelle;
                    parent.disabled = false;
                    parent.children         = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
                    parent.isDefaultValue   = parent.id === categoryContentId;
                    parent.expanded         = isCatalog && parent.parent === null?true:false;
                    for (let child of parent.children)
                        populateChildren(cats, child);
                };

                for (let parent of data)
                    populateChildren(contentCategories, parent);

                return data;
            })()
        })
    }

    if(specificInputs.length > 0){
        generalInputs = [...specificInputs, ...generalInputs];
    }
    // if((assetMagentoPages && assetMagentoPages.length > 0) ){
    // }

    let inputAttributesArray = [...inputAttributes?.edges];
    let productAttributesArray = null;
    if (productAttributes){
        productAttributesArray = [...productAttributes?.edges]
    }
    let attributesInputs = inputAttributesArray.map(attributeSetup);

    if(productAttributesArray){
        productAttributesArray.edges = productAttributesArray.filter(e => e.node.internalFilter); 
        const sku = {
            node : {
                identifier: 'sku',
                id: "sku",
                attributeType: {
                    input: 'text'
                },
                translation: {
                    translationDatas: {
                        edges: [
                            {
                                node: {
                                    locale: {id: '/api/locales/1', code: 'fr_FR'},
                                    value: 'SKU'
                                }
                            }
                        ]
                    }
                }
            }
        }
        productAttributesArray.edges.push(sku);
    }

    let hasErrors = (inputs) => {
        for (let input of inputs) {
            if (errors[input.stateName])
                return true;
        }

        return false;
    };

    var obj = {
        titleForm: 'Editer une catégorie',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour modifier votre catégorie',
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: TABS,
            seeErrors,
            children: [
                {
                    labelName: 'Général',
                    error: hasErrors(generalInputs),
                    optionsInputs: generalInputs
                },
                {
                    labelName: 'Attributs',
                    isOptionnal: false,
                    error: hasErrors(attributesInputs),
                    optionsInputs: attributesInputs
                },
            ]
        }
    };

    if (isCatalog){
        obj.formConfig.children.push(
            {
                labelName: 'Filtres',
                isOptionnal: false,
                // error: hasErrors(attributesInputs),
                optionsInputs: [
                    {
                        type: 'rows',
                        translated: false,
                        required: false,
                        stateName: 'catFilters',
                        labelsNames:{
                            first: 'Attribut',
                            second: 'Opérateur',
                            third: 'Valeurs',
                        },
                        filter: true,
                        attributes: productAttributesArray,
                        operators:[
                            {
                                id: '=',
                                label: '='
                            },
                            {
                                id: '!=',
                                label: '!='
                            },
                            {
                                id: 'IN',
                                label: 'IN'
                            },
                            {
                                id: 'NOT IN',
                                label: 'NOT IN'
                            },
                            {
                                id: 'LIKE',
                                label: 'LIKE'
                            },
                            {
                                id: 'EMPTY',
                                label: 'empty'
                            }
                        ]
                    }
                ]
            }
        )
        obj.formConfig.children.push(
            {
                labelName:  'Référence',
                isOptionnal: false,
                component: CategoryMasterSelector,
                optionsInputs:{
                    typeToLoad: 'catalog',
                    stateName: 'categoryMaster',
                    required: false,
                    value: allState
                }
            }
        )
    }

    return obj;
}