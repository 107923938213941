import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCategorie2, getDefaultTextareaCms, getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc SEO',
    inputs: {
        content:{
            label: 'Contenu',
            type: FormInputTypes.WYSIWYG,
            value: null,
            id: uuidv4(),
        },
    },
    // blocks:{
    //     title: getDefaultTextCms("Titre", "Titre de la section", '#000000', 20, 'px',null,true),
    //     texts:{
    //         title: "Textes",
    //         name: "Textes",
    //         type: EditableTypes.MULTIPLE_BLOCKS,
    //         subtype: ItemTypes.CMS_TEXTAREA,
    //         buttonAdd: 'un bloc de texte',
    //         limit: 9,
    //         config: [
    //             getDefaultTextareaCms("Contenu", "Sed mi quam, dictum at malesuada ", true,"#000",true,null,true,20,"px",null),
    //         ]
    //     }
    // }
};