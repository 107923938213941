import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../../shareable/components/assets/book/scss/style.scss';

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

export default ({ config, mapper }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} id="builder-template-scope-book">
            { config?.elements?.map(mapper) }
        </div>
    );
};