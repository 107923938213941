import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";

import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';

import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import feedAddConfig from './config/feeds/feedAdd.config';
import Builder from '../../../../builder/builder';
import request from '../../../../js/utils/fetch';
import { GET_ASSET_BY_ID } from '../../../../queries/assets';
import styled from 'styled-components';
import 'moment-timezone';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";


import { getDefaultConfig } from '../../../../builder/utils/data';
import { Pages } from '../../../../builder/shareable/types';
import { withTranslation } from 'react-i18next';
import { IMAGES, VIDEOS } from '../../../../js/constants/medias-types';
import moment from 'moment';
import { eventService } from '../../../../js/services/event.service';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class DiffusionPermanentPosts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filtered: [],
            errors: {},
            ready: false,
            openFormFeedAdd: true,
            postNeedScheduled: false,
            postType: 'classic',
            postTypeMedia: 'image',
            idAsset: `/api/assets/${this.props.match.params.id}`,
            config: null,
            scheduledAt: null,
            minScheduledAt: moment().add(10, 'minutes').format("yyyy-MM-DTHH:mm"),
            maxScheduledAt: null,
        };
    }

    componentDidMount() {
        this.handleGetAssetDatas();
    }

    handleButtonGroupChange = (stateName, value) => {
        if (stateName === "postType") {
            if (value === "product" || value === "content") {
                this.setState({ postTypeMedia: 'image' })
            }
        }
        if (stateName === "postNeedScheduled") {
            this.setState({
                errors: {},
            });
            if (value !== null && value !== "") {
                this.setState({
                    [stateName]: value,
                    errors: {},
                });
            }
        } else {
            if (value !== null && value !== "") {
                this.setState({
                    [stateName]: value
                }, () => {
                    this.initValues();
                });
            }
        }
    };

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({

            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        }

    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            }, () => {
                this.updateBuilderValues();
            });
        }
    };

    getDataInfo = () => {
        let getTitleData = '';
        let getDescriptionData = '';
        let getImageData = '';
        if (this.state.postType === 'product') {
            getTitleData = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')?.node.value;
            getDescriptionData = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description')?.node.value;
            getImageData = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')?.node.media;
        } else if (this.state.postType === 'content') {
            getTitleData = this.state.postContent.item?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title')?.node.value;
            getDescriptionData = this.state.postContent.item?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description')?.node.value;
            getImageData = this.state.postContent.item?.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image')?.node.media;
        }
        return {
            name: getTitleData,
            description: getDescriptionData,
            image: getImageData
        };
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    };

    handleGetAssetDatas = () => {
        this.setState({
            filteredAssets: null
        });

        const getAssetsByType = GET_ASSET_BY_ID;

        this.props.client.query({
            query: getAssetsByType,
            variables: { id: this.state.idAsset },
            fetchPolicy: 'no-cache'
        }).then(GET_ASSET_BY_ID_RESULT => {
            let currentAsset = GET_ASSET_BY_ID_RESULT.data.asset;

            this.setState({
                assetData: currentAsset,
                imageAsset: currentAsset.assetType.logo,
                catalogAsset: currentAsset.catalog,
                ready: true
            }, () => {
                if (this.state.assetData.assetType.identifier === Pages.FACEBOOK) {
                    this.setState({ maxScheduledAt: moment().add(3, 'month').format("YYYY-MM-DDThh:mm:ss") })
                } else {
                    this.setState({ maxScheduledAt: moment().add(24, 'hours').format("YYYY-MM-DDThh:mm:ss") })
                }
                this.initValues();
            });
        })
    }

    initValues = () => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let config = {};
        switch (channelType) {
            case Pages.WORDPRESS:
                config[Pages.WORDPRESS] = getDefaultConfig(channelType, this.state.postTypeMedia);
                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
                break;
            case Pages.FACEBOOK:
                config[Pages.FACEBOOK] = getDefaultConfig(channelType, this.state.postTypeMedia);

                if (this.state.postType === 'product' || this.state.postType === 'content') {
                    let image = config[Pages.FACEBOOK].elements.find(e => e.key === 'image');
                    if (image) {
                        image.hide = false;
                        image.noAction = true;
                    }

                    let link = config[Pages.FACEBOOK].elements.find(e => e.key === 'link');
                    link.data.blocks.content.inputs.value.value = this.state.postType === 'product' ? this.props.t("spread.active_assets.productTitle") : this.props.t("spread.active_assets.contentTitle");
                    link.hide = false;
                }

                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
                break;
            case Pages.INSTAGRAM:
                config[Pages.INSTAGRAM] = getDefaultConfig(channelType, this.state.postTypeMedia);

                if (this.state.postType === 'product' || this.state.postType === 'content') {
                    let image = config[Pages.INSTAGRAM].elements.find(e => e.key === 'image');
                    if (image) {
                        image.hide = false;
                        image.noAction = true;
                    }
                }

                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
            case Pages.LINKEDIN:
                config[Pages.LINKEDIN] = getDefaultConfig(channelType, this.state.postTypeMedia);

                if (this.state.postType === 'product' || this.state.postType === 'content') {
                    let image = config[Pages.LINKEDIN].elements.find(e => e.key === 'image');
                    if (image) {
                        image.hide = false;
                        image.noAction = true;
                    }

                    let link = config[Pages.LINKEDIN].elements.find(e => e.key === 'link');
                    link.data.blocks.content.inputs.value.value = this.state.postType === 'product' ? this.props.t("spread.active_assets.productTitle") : this.props.t("spread.active_assets.contentTitle");
                    link.hide = false;
                }

                this.setState({
                    ...this.extractBuilderValues(config),
                    config: {
                        params: {},
                        ...config
                    }
                });
                break;
            default: return;
        }
    };

    extractBuilderValues = (config) => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let paramImage = null
        switch (channelType) {
            case Pages.WORDPRESS:
                return {
                    paramText: config[Pages.WORDPRESS].elements.find(e => e.key === 'title').data.blocks.content.inputs.value.value,
                    paramText2: config[Pages.WORDPRESS].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value
                };
            case Pages.FACEBOOK:
                if (this.state.postTypeMedia === "image") {
                    paramImage = config[Pages.FACEBOOK].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value
                } else if (this.state.postTypeMedia === "video") {
                    paramImage = config[Pages.FACEBOOK].elements.find(e => e.key === 'video').data.blocks.media.inputs.value.value
                }
                return {
                    paramImage: paramImage,
                    paramLink: config[Pages.FACEBOOK].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value,
                    paramText: config[Pages.FACEBOOK].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value,
                };
            case Pages.INSTAGRAM:
                if (this.state.postTypeMedia === "image") {
                    paramImage = config[Pages.INSTAGRAM].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value
                } else if (this.state.postTypeMedia === "video") {
                    paramImage = config[Pages.INSTAGRAM].elements.find(e => e.key === 'video').data.blocks.media.inputs.value.value
                }
                return {
                    paramImage: paramImage,
                    paramLink: config[Pages.INSTAGRAM].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value,
                    paramText: config[Pages.INSTAGRAM].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value,
                };
            case Pages.LINKEDIN:
                return {
                    paramImage: config[Pages.LINKEDIN].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value,
                    paramLink: config[Pages.LINKEDIN].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value,
                    paramText: config[Pages.LINKEDIN].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value,
                };
            default: return {};
        }
    };

    updateBuilderValues = () => {
        let channelType = this.state.assetData.channel.attributeGroup.identifier;
        let dataInfo = this.getDataInfo();
        let config = { ...this.state.config };

        switch (channelType) {
            case Pages.FACEBOOK:
                if (this.state.postTypeMedia === "image") {
                    config[Pages.FACEBOOK].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value = this.state.postType === 'classic' ? IMAGES.includes(this.state.paramImage?.type) ? this.state.paramImage : null : dataInfo.image;
                } else if (this.state.postTypeMedia === "video") {
                    config[Pages.FACEBOOK].elements.find(e => e.key === 'video').data.blocks.media.inputs.value.value = this.state.postType === 'classic' ? VIDEOS.includes(this.state.paramImage?.type) ? this.state.paramImage : null : dataInfo.image;
                }
                config[Pages.FACEBOOK].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramLink : dataInfo.name;
                config[Pages.FACEBOOK].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramText : dataInfo.description;

                if (this.state.postType === 'classic') {
                    if (this.state.postTypeMedia === "image") {
                        config[Pages.FACEBOOK].elements.find(e => e.key === 'image').hide = this.state.paramLink ? true : false;
                    } else if (this.state.postTypeMedia === "video") {
                        config[Pages.FACEBOOK].elements.find(e => e.key === 'video').hide = this.state.paramLink ? true : false;
                    }
                    config[Pages.FACEBOOK].elements.find(e => e.key === 'link').hide = this.state.paramLink ? false : true;
                }

                break;
            case Pages.INSTAGRAM:
                if (this.state.postTypeMedia === "image") {
                    config[Pages.INSTAGRAM].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value = this.state.postType === 'classic' ? this.state.paramImage : dataInfo.image;
                } else if (this.state.postTypeMedia === "video") {
                    config[Pages.INSTAGRAM].elements.find(e => e.key === 'video').data.blocks.media.inputs.value.value = this.state.postType === 'classic' ? VIDEOS.includes(this.state.paramImage?.type) ? this.state.paramImage : null : dataInfo.image;
                }
                config[Pages.INSTAGRAM].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramLink : dataInfo.name;
                config[Pages.INSTAGRAM].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramText : dataInfo.description;

                if (this.state.postType === 'classic') {
                    if (this.state.postTypeMedia === "image") {
                        config[Pages.INSTAGRAM].elements.find(e => e.key === 'image').hide = this.state.paramLink ? true : false;
                    } else if (this.state.postTypeMedia === "video") {
                        config[Pages.INSTAGRAM].elements.find(e => e.key === 'video').hide = this.state.paramLink ? true : false;
                    }
                    config[Pages.INSTAGRAM].elements.find(e => e.key === 'link').hide = this.state.paramLink ? false : true;
                }

                break;
            case Pages.LINKEDIN:
                config[Pages.LINKEDIN].elements.find(e => e.key === 'image').data.blocks.image.inputs.value.value = this.state.postType === 'classic' ? this.state.paramImage : dataInfo.image;
                config[Pages.LINKEDIN].elements.find(e => e.key === 'link').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramLink : dataInfo.name;
                config[Pages.LINKEDIN].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramText : dataInfo.description;

                if (this.state.postType === 'classic') {
                    config[Pages.LINKEDIN].elements.find(e => e.key === 'image').hide = this.state.paramLink ? true : false;
                    config[Pages.LINKEDIN].elements.find(e => e.key === 'link').hide = this.state.paramLink ? false : true;
                }

                break;
            case Pages.WORDPRESS:
                config[Pages.WORDPRESS].elements.find(e => e.key === 'title').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramText : dataInfo.name;
                config[Pages.WORDPRESS].elements.find(e => e.key === 'content').data.blocks.content.inputs.value.value = this.state.postType === 'classic' ? this.state.paramText2 : dataInfo.description;
                break;
            default: return;
        }

        this.updateBuilder(config);
    }

    updateBuilder = (config) => {
        let newConfig = { ...config };

        this.setState({
            config: newConfig,
            ...this.extractBuilderValues(newConfig)
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    render() {
        const assetData = this.state.assetData;

        return (
            <div>
                {this.state.ready && assetData ?
                    <>
                        <CardCustom style={{ width: this.state.openFormFeedAdd ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", marginTop: 8, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                            <Grid container justifyContent={'space-between'} alignItems='flex-start'>
                                <Box onClick={this.props.history.goBack} style={{ marginBottom: 25 }}>
                                    <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                                </Box>

                                {
                                    this.state.config && <Builder
                                        onlyPreview={true}
                                        onlyContent={true}
                                        noToolbar={true}
                                        assetData={assetData}
                                        pageToEdit={assetData.channel.attributeGroup.identifier}
                                        externalConfig={this.state.config}
                                        updateExternalConfig={this.updateBuilder}
                                    />
                                }

                            </Grid>
                        </CardCustom>
                    </>
                    : <PageLoader />}

                {this.state.ready && assetData?.assetType.hasFeed === true
                    ?
                    <>
                        <LayoutBuilder
                            isSublayout={false}
                            opened={this.state.openFormFeedAdd}
                            dataLayout={feedAddConfig(this.state.disabledLink, this.state.disabledImage, assetData, this.handleMediaPicker, this.state.postType, this.state.postTypeMedia, this.state.postNeedScheduled)}
                            forClose={() => { this.handleToggleDrawer('openFormFeedAdd') }}
                            image={this.state.imageAsset}
                            allState={this.state}
                            stateCallback={this.handleInputChange}
                            handlerMutation={this.addFeed}
                            handleCancel={this.props.history.goBack}
                            validateButton={true}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            catalogDatas={this.state.catalogAsset}
                            errorCallback={this.handleFormError}
                        />

                    </>
                    : null
                }
            </div>
        );
    }

    isNegative = (num) => {
        if (Math.sign(num) === -1) {
            return true;
        }

        return false;
    }

    addFeed = async () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
            this.setState({ seeErrors: true });
            return eventService.fire();
        }
        //---- add feed
        let assetType = this.state.assetData.assetType.identifier;
        let postType = this.state.postType;

        let bodyDatas = new FormData;
        let imageUrl = null;
        let getDateStatusMin = moment(this.state.scheduledAt).diff(moment(this.state.minScheduledAt))
        let getDateStatusMax = moment(this.state.scheduledAt).diff(moment(this.state.maxScheduledAt))

        if (this.state.postNeedScheduled || assetType === 'instagram') {
            if (this.isNegative(getDateStatusMin)) {
                this.props.snack(ALERT_ERROR, "Date de publication trop proche (Min: 10 min de délai)");
                this.setState({ seeErrors: true });
                return eventService.fire();
            } else if (!this.isNegative(getDateStatusMax)) {
                this.props.snack(ALERT_ERROR, `Date de publication trop lointaine (Max: ${assetType === 'facebook' ? "3 mois" : "24h"})`);
                this.setState({ seeErrors: true });
                return eventService.fire();
            }
        }

        if (this.state.paramImage) {
            imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + this.state.paramImage.filePath
        }
        if (assetType === 'facebook') {
            if (this.state.postNeedScheduled) {
                bodyDatas.append('scheduledAt', this.state.scheduledAt)
            }
            if (postType === 'classic') {
                bodyDatas.append('message', this.state.paramText)
                bodyDatas.append('link', this.state.paramLink ? this.state.paramLink : null)
                if (this.state.postTypeMedia === "image") {
                    bodyDatas.append('imageUrl', this.state.paramImage ? imageUrl : null)
                } else {
                    bodyDatas.append('file_url', this.state.paramImage ? imageUrl : null)
                }
            } else if (postType === 'product') {
                let getTitleProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')?.node.value;
                let getDescriptionProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description')?.node.value;
                let publication = getTitleProduct + '\n' + (getDescriptionProduct ? getDescriptionProduct : '');
                let getImageProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageProduct

                bodyDatas.append('message', publication)
                bodyDatas.append('link', this.state.paramLink ? this.state.paramLink : null)
                bodyDatas.append('imageUrl', getImageProduct ? imageUrl : null)
            } else if (postType === 'content') {
                let getTitleContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title')?.node.value;
                let getDescriptionContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description')?.node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent;
                let getImageContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageContent

                bodyDatas.append('message', publication)
                bodyDatas.append('link', this.state.paramLink ? this.state.paramLink : null)
                bodyDatas.append('imageUrl', getImageContent ? imageUrl : null)
            }


        } else if (assetType === 'instagram') {
            bodyDatas.append('scheduledAt', this.state.scheduledAt)
            if (postType === 'classic') {
                bodyDatas.append('caption', this.state.paramText)
                if (this.state.postTypeMedia === "image") {
                    bodyDatas.append('imageUrl', this.state.paramImage ? imageUrl : null)
                } else {
                    bodyDatas.append('file_url', this.state.paramImage ? imageUrl : null)
                }
            } else if (postType === 'product') {
                let getTitleProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')?.node.value;
                let getDescriptionProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description')?.node.value;
                let publication = getTitleProduct + '\n' + (getDescriptionProduct ? getDescriptionProduct : '');
                let getImageProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageProduct

                bodyDatas.append('caption', publication)
                bodyDatas.append('imageUrl', getImageProduct ? imageUrl : null)
            } else if (postType === 'content') {
                let getTitleContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title')?.node.value;
                let getDescriptionContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description')?.node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent ? getDescriptionContent : '';
                let getImageContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageContent

                bodyDatas.append('caption', publication)
                bodyDatas.append('imageUrl', getImageContent ? imageUrl : null)
            }
        } else if (assetType === 'linkedin') {
            if (postType === 'classic') {
                bodyDatas.append('caption', this.state.paramText)
                bodyDatas.append('imageUrl', this.state.paramImage ? imageUrl : null)
            } else if (postType === 'product') {
                let getTitleProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name')?.node.value;
                let getDescriptionProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description')?.node.value;
                let publication = getTitleProduct + '\n' + (getDescriptionProduct ? getDescriptionProduct : '');
                let getImageProduct = this.state.postProduct?.item?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageProduct

                bodyDatas.append('caption', publication)
                bodyDatas.append('imageUrl', getImageProduct ? imageUrl : null)
            } else if (postType === 'content') {
                let getTitleContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title')?.node.value;
                let getDescriptionContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description')?.node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent ? getDescriptionContent : '';
                let getImageContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image')?.node.media.filePath;
                imageUrl = process.env.REACT_APP_API_ROOT + '/medias/' + getImageContent

                bodyDatas.append('caption', publication)
                bodyDatas.append('imageUrl', getImageContent ? imageUrl : null)
            }
        } else if (assetType === 'twitter') {
            let mediasUrl = [];
            if (postType === 'classic') {
                let medias = [this.state.paramTwitterFirstMedia, this.state.paramTwitterSecondMedia, this.state.paramTwitterThirdMedia, this.state.paramTwitterFourthMedia];
                for (let media of medias) {
                    if (media !== null) {
                        mediasUrl.push(process.env.REACT_APP_API_ROOT + '/medias/' + media.filePath);
                    }
                }

                bodyDatas.append('message', this.state.paramText.toString().substring(0, 279))
                bodyDatas.append('medias', mediasUrl)
            } else if (postType === 'product') {
                let getTitleProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
                let getDescriptionProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
                let publication = getTitleProduct + '\n' + (getDescriptionProduct ? getDescriptionProduct : '');
                let getImageProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media.filePath
                mediasUrl.push(process.env.REACT_APP_API_ROOT + '/medias/' + getImageProduct);

                bodyDatas.append('message', publication.toString().substring(0, 279))
                bodyDatas.append('medias', mediasUrl)
            } else if (postType === 'content') {
                let getTitleContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
                let getDescriptionContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
                let publication = getTitleContent + '\n' + getDescriptionContent;
                let getImageContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media.filePath;
                mediasUrl.push(process.env.REACT_APP_API_ROOT + '/medias/' + getImageContent);

                bodyDatas.append('message', publication.toString().substring(0, 279))
                bodyDatas.append('medias', mediasUrl)
            }
        } else if (assetType === 'wordpress') {
            let mediasUrl = [];
            if (postType === 'classic') {
                bodyDatas.append('titre', this.state.paramText)
                bodyDatas.append('message', this.state.paramText2)
            } else if (postType === 'product') {
                let getTitleProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_name').node.value;
                let getDescriptionProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_description').node.value;
                // let publication = getTitleProduct + '\n' + getDescriptionProduct;
                let getImageProduct = this.state.postProduct?.productDatas.edges.find(e => e.node.attribute.identifier === 'product_image').node.media.filePath
                mediasUrl.push(process.env.REACT_APP_API_ROOT + '/medias/' + getImageProduct);

                bodyDatas.append('titre', getTitleProduct)
                bodyDatas.append('message', getDescriptionProduct.toString())
                bodyDatas.append('medias', mediasUrl)
            } else if (postType === 'content') {
                let getTitleContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_title').node.value;
                let getDescriptionContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_description').node.value;
                let getImageContent = this.state.postContent?.item.contentDatas.edges.find(e => e.node.attribute.identifier === 'content_image').node.media.filePath;
                mediasUrl.push(process.env.REACT_APP_API_ROOT + '/medias/' + getImageContent);

                bodyDatas.append('titre', getTitleContent)
                bodyDatas.append('message', getDescriptionContent.toString())
                bodyDatas.append('medias', mediasUrl)
            }
        }
        this.setState({
            requesting: true,
        }, () => {
            this.props.startLoading();
            let urlApi = null
            if (this.state.postTypeMedia === "image") {
                urlApi = `${process.env.REACT_APP_API}/social-connector/post/create/${this.props.match.params.id}`
            } else {
                urlApi = `${process.env.REACT_APP_API}/social-connector/post/video/create/${this.props.match.params.id}`
            }
            request(urlApi, 'POST', bodyDatas, null, true).then(async (data) => {
                if (data && !data.errors) {
                    this.props.stopLoading();
                    this.props.snack(ALERT_SUCCESS, `${this.state.assetData.assetType.identifier === 'twitter' ? 'Tweet' : 'Post'} ajouté ! `);
                } else {
                    this.props.stopLoading();
                    this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));
                }

                this.handleToggleDrawer('openFormFeedAdd');
            }).catch(e => {
                this.props.stopLoading();
                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.errorTryLater"));
            });
        });
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { assetId: id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DiffusionPermanentPosts))));
