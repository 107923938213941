import { withTranslation } from "react-i18next";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../js/constants/action-types";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import OurButton from "../../ui/button/Button";
import EditIcon from '@material-ui/icons/Edit';
import { Box, CircularProgress, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import colors from "../../../config/theme/colors";
import parseHtml from "../../../js/utils/parseHtml";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';    
import React, { useEffect } from "react";
import request from "../../../builder/shareable/tools/Fetch"
import IconeCheck from "../../../assets/pictos/ico-verifier.svg"

const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    color: ${colors.black.regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
    span{
        margin-left: 4px;
    }
`;

const CheckSpeclling =(props)=>{
    const [open, setOpen] = React.useState(false);
    const [newText, setNewText] = React.useState(null);
    const [error, setError] = React.useState(null);    
    const [loading, setLoading] = React.useState(false);

    const handleModal = ()=>{
        setOpen(!open)
    }

    useEffect(()=>{        
        if (open) {
            getGoodText()
        }
    },[open])

    const getGoodText = ()=>{
        setLoading(true)
        const variables ={
            sentence: parseHtml(props.value)
        } 
        request(`https://external-connectors.sinfin.dev/api/corrector/suggest`, 'post',variables)
        .then((result)=>{         
            if (result.correction) {
                setNewText(result.correction)   
            }else{
                setError("Aucune modification apporté")
                setNewText(parseHtml(props.value))
            }
            setLoading(false)
        })
    }

    return (
        <>
            <OurButton
                bgcolor={"#F0F9FD"}
                bgcolorhover={"#F0F9FD"}
                color={"#0273A5"}
                border={"0.5px solid #0273A5"}
                icon={"check"}
                onClick={handleModal}                
            >
                Vérifier
            </OurButton>
            <Modal
              open={open}                              
              onClose={handleModal}  
              style={{display:'flex',alignItems:'center',justifyContent:'center'}}                                                        
            >
                <Box
                    style={{
                        background:"white",
                        width:"50%",
                        padding:"30px 60px"
                    }}
                >
                    <BoxClose onClick={handleModal} pb={2} style={{ cursor: 'pointer' }}>
                        <CloseIcon /> <span>Fermer</span>
                    </BoxClose>                                    
                    <Typography style={{fontSize:33,color:colors.black.regular,padding:"20px 0",fontWeight:"bold"}}>Correction du texte</Typography>
                    <Typography>Faite une description enrichie en fonction du ton que vous voulez donner</Typography>
                    <Box 
                        style={{
                            margin:"30px 0"
                        }}
                    >
                        <Typography style={{color:colors.black.regular,marginBottom:5}}>Ancien texte</Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={parseHtml(props.value)}
                            disabled                            
                            multiline
                            minRows={4}        
                        />
                    </Box>      
                    <Box>
                        <Typography style={{color:colors.black.regular,marginBottom:5}}>Texte corrigé</Typography>
                        {
                            loading?
                            <Box style={{display:"flex",width:"100%",justifyContent:"center",padding:"30px 0"}}>
                                <CircularProgress/>
                            </Box>
                            :<TextField
                            fullWidth
                            variant="outlined"
                            value={newText}  
                            multiline
                            minRows={4}                                   
                            onChange={(e)=>setNewText(e.target.value)}                                          
                        />
                        }                        
                        <Typography style={{color:colors.green.regular,fontSize:12,paddingLeft:5}}>{error}</Typography>
                    </Box>    
                    <Box
                        style={{
                            display:"flex",
                            justifyContent:"end"
                        }}
                    >
                        <OurButton
                            bgcolor={colors.green.regular}
                            bgcolorhover={colors.green.darker}
                            loading={loading}
                            onClick={()=>{
                                props.stateCallback(newText)
                                handleModal()
                            }}
                        >Valider la séléction</OurButton>
                    </Box>  
                </Box>                   
            </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(CheckSpeclling))));