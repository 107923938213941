import PropTypes from "prop-types";
import React from "react";
import H2 from '../../../../../shared/components/CustomH2';
import Button from '../../../../../shared/components/CustomLink';

const RedirectOn4 = (props) => {
    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    let image = data?.inputs['backgroundImage'].value;

    return (
        <div className="results" style={{position: "relative"}} >
            <section 
                className="bandeau4"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                }}
                
            >
                { !preview && props.children }
                <div className="content">
                    <H2 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                    <div className="block">
                        <img class="img_center" onClick={() => inputCallback(id, 'image')} src={data.blocks.image.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` : typeof(data.blocks.image.inputs.value?.value) === "string" ? data.blocks.image.inputs.value.value : "http://via.placeholder.com/500x300/FFFFFF/53839D?text=Image"} />
                    </div>
                    <div className="block">
                        <Button data={data} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="as_button" nopadding={true} />
                    </div>
                </div>
                <div className="block">
                    <img class="img_center" onClick={() => inputCallback(id, 'logo')} src={data.blocks.logo.inputs.value?.value?.filePath  ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` : typeof(data.blocks.logo.inputs.value?.value) === "string" ? data.blocks.logo.inputs.value.value : "http://via.placeholder.com/150x150/FFFFFF/53839D?text=Logo"} />
                </div>
            </section>
        </div>
    );
};

RedirectOn4.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RedirectOn4;
