import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideInstagram } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider instagram',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
        title: {
            type: FormInputTypes.TEXT,
            label: "Changer le titre",
            value: "#LECOQSPORTIF"
        },
        text: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value: "Faites la tendance en montrant ce que #LECOQSPORTIF signifie pour vous"
        },
    },
    blocks:{
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_INSTAGRAM,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideInstagram(),
                getDefaultSlideInstagram(),
                getDefaultSlideInstagram(),
            ]
        }
    }
};