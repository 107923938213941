import React from 'react';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';


class SettingsSubscriptions extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            openForm: false,
        }
    }
    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm
        });
    }
    
    render() {
        return (
            <div>
                <TopPanel icomoon="icon-abonement" colorIcomoon={colors.blue.darker.hue300} title="Gestion de l'abonnement" subtitle="Cliquez sur le bouton ci-dessous pour en ajouter un" gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker} />
            </div>
        );
    }
}

export default SettingsSubscriptions;
