import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import headerEdit from './config/headers/headerEdit.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE, CREATE } from '../../../../js/constants/constant-rights';

import { prepareAttributeValues, saveElement, updateElement, getElements } from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import { listSettings, listMappers, perPageOptions } from './config/headers/listHeaders.config';
import * as formHelper from '../../../../js/helpers/form'
import { makeUnique, searchItem } from '../../../../js/helpers/search'
import { DELETE_TOP_HEADER_MESSAGE } from '../../../../queries/assetMagentoTopHeader';
import { Grid, Typography, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import * as moment from 'moment';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';


const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`
class EngineAssetsDetailsHeaders extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm: false,
            editForm: 'add',
            currentLang: props.locales[0].node.code,
            assetId: `/api/assets/${props.match.params.id}`,
            errors: {},
            seeErrors: false,
            ready: false,
            headerMessage: null,
            headerLink: null,
            headerStartAt: null,
            headerEndAt: null,
            headerStatus: true,
        };
    }

    handleLang = (event) => {
        this.setState({
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };

    handleToggleDrawer = (stateDrawer, reset = false) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset) {
            this.resetState()
        }
    };


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () => {
        let result = formHelper.handleStep('next', this.state.errors);
        this.setState({
            seeErrors: !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }


    handleMediaPicker = (selected, stateName, translated) => {
        this.handleInput(stateName, selected, null, translated);
    }

    handleInput = (stateName, evt, custom, translated) => {
        let value = formHelper.setValue(this.state, stateName, evt, custom, translated);
        this.setState({ ...value })
    }

    resetState = () => {
        this.setState({
            headerIdentifier: null,
            headerMessage: null,
            headerLink: null,
            headerStartAt: null,
            headerEndAt: null,
            headerStatus: true,
        })
    }

    handlerHeaderMutation = async () => {
        this.props.startLoading();
        let variables = null;
        switch (this.state.editForm) {
            case 'add':

                variables = {
                    asset: this.state.assetId,
                    message: this.state.headerMessage,
                    link: this.state.headerLink,
                    startDate: this.state.headerStartAt,
                    endDate: this.state.headerEndAt,
                    status: this.state.headerStatus
                }
                await saveElement('assetMagentoTopHeader', variables, { enableLoad: false })
                this.props.snack(ALERT_SUCCESS, 'Header ajouté avec succès');
                this.handleToggleDrawer('openForm', true);
                this.reloadHeaders();
                this.props.stopLoading();
                break;

            case 'edit':

                variables = {
                    id: this.state.currentHeaderId,
                    asset: this.state.assetId,
                    message: this.state.headerMessage,
                    link: this.state.headerLink,
                    startDate: this.state.headerStartAt,
                    endDate: this.state.headerEndAt,
                    status: this.state.headerStatus
                }
                let getResult = await updateElement(this.state, 'assetMagentoTopHeader', variables, null, { enableLoad: false })
                if (getResult) {
                    this.props.snack(ALERT_SUCCESS, 'Header modifié avec succès');
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadHeaders();
                }

                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_TOP_HEADER_MESSAGE,
            variables: { id: this.state.currentHeaderId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Header supprimé avec succès');
            this.reloadHeaders();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le header`);
            this.reloadHeaders();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    initForm = () => {
        this.setState({
            editForm: 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler = (product) => {
        let local = moment.tz.guess();
        this.setState({
            editForm: 'edit',
            currentHeaderId: product.id,
            headerMessage: product.message,
            headerLink: product.link,
            headerStartAt: moment.tz(product.startDate, local).format('YYYY-MM-DD'),
            headerEndAt: moment.tz(product.endDate, local).format('YYYY-MM-DD'),
            headerStatus: product.status,
        })
        this.handleToggleDrawer('openForm');
    }

    getHeadersCallback = () => {
        this.setState({
            reloadHeaders: false
        })
    }
    reloadHeaders = () => {
        this.setState({ reloadHeaders: true })
    }

    initNavAsset = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${this.props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    componentDidMount() {
        checkRouting(this.props)
        // this.setUpAttributes();
        this.setState({
            isReady: true,
        });
        if (this.props.location.state?.hasMessage) {
            this.props.snack(ALERT_SUCCESS, this.props.location.state.message)
        }
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            this.initNavAsset()
        }
    }

    render() {
        return (
            <div style={{ width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                <TopPanel
                    icomoon="ico_top-header"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("drawer.headers")}
                    subtitle="Gestion de vos headers magento (création / modification / suppression)"
                    handlerAdd={() => this.initForm()}
                    textAdd={"Ajouter un header"}
                    // searchHandler={
                    //     this.handleChangeCompanies
                    // } 
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    hasBorder={true}
                />
                {/* <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                /> */}
                <Grid container style={{ marginTop: 16 }}>
                    {/* <Box onClick={this.props.history.goBack} style={{marginBottom: 25}}>
                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                    </Box> */}
                    <Listing
                        label='header'
                        settings={listSettings}
                        cardProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            textButton: 'Modifier',
                            handlerButton: this.editHandler,
                            windowWidth: this.props.windowWidth,
                        }}
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        identifier='assetMagentoTopHeaders'
                        queryVariables={{
                            asset: this.state.assetId,
                        }}
                        viewsOptions={{
                            current: 'card',
                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadHeaders}
                        listingCallback={this.getHeadersCallback}
                    />
                </Grid>

                {this.state.isReady ? (
                    <div>
                        <LayoutBuilder
                            isSublayout={false}
                            icomoon="ico_top-header"
                            opened={this.state.openForm}
                            forClose={() => this.handleToggleDrawer('openForm', true)}
                            handlerSetup={() => { }}
                            dataLayout={headerEdit(this.state.editForm)}
                            drawerWidth={this.props.drawerWidth}
                            allState={this.state}
                            stateCallback={this.handleInput}
                            errorCallback={this.handleFormError}
                            stepperButtonAction={[
                                this.handleNextStep,
                                this.handleNextStep,
                                this.handleNextStep,
                            ]}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            validateButton={true}
                            handlerMutation={this.handlerHeaderMutation}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            deleteMutation={this.state.editForm === "edit" ? () => { this.handleToggleDialog() } : null}
                            deleteText={'Supprimer le header'}
                        />
                    </div>
                )
                    : null}
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Êtes-vous sûr de vouloir supprimer cet header ?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez cet header, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({ ...a, node: { ...a.node } }) : ({ ...a }));

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsHeaders)))));