import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import StarBlue from './image/asterix-bleu.svg';
import PereNoelPNG from './image/result-pere-noel.png';
import BannerLeftCorner from './image/ban_left_corner.png';
import Link from 'next/link';
import FacebookSVG from './image/ico-facebook.svg';
import MailSVG from './image/ico-mail.svg';
import {FacebookShareButton, EmailShareButton} from 'react-share';
import H2 from "../../../../shared/components/CustomH2";
import H1 from "../../../../shared/components/CustomH1";
import PG from "../../../../shared/components/CustomParagraph";

import request from "../../../../../tools/Fetch";

const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const ResultScreenBook = (props) => {
    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        spread = false,
        toolbarBlock = null,
    } = props;

    const router = useRouter();
    const [email, setEmail] = useState(spread ? 'johndoe@email.com' : '');
    const [hoverBlock, setHoverBlock] = useState(false);
    const [count, setCount] = useState(0);

    const share = () => {
        if (spread)
            return;

        let data = new FormData();
        let uuid = localStorage.getItem('uuid');
        let allCount = count + 1;

        data.append('username', email);
        data.append('uuid', uuid);
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        data.append('origin', process.env.REACT_APP_ORIGIN);
        data.append('emailSubject', null);
        data.append('emailHtml', null);
        data.append('subscribe', localStorage.getItem('subscribed'));
        data.append('share', allCount > 2 ? 2 : allCount);

        try {
            request(`${process.env.REACT_APP_API}/public/assets/wishlist/${process.env.REACT_APP_ASSET_IDENTIFIER}`, 'post', data, 'multipart/form-data');
            setCount(allCount);
        } catch(e) {
            console.log(e);
        }
    };

    useEffect(() => {
        let email = localStorage.getItem('email');

        if (email) {
            setEmail(email);

            // remove email from storage (now from Next)
            // localStorage.removeItem('email');
        } else {
            if (spread)
                setEmail('johndoe@email.com');

            if (!spread) {
                // router.push('/');
                window.location = '/';
            }
        }
    }, []);
    
    let image = data?.blocks?.imageRight?.inputs.value.value;
    let imageMobile = data?.blocks?.imageMobile?.inputs.value.value;

    return (
        <div className="result_screen">
            { !preview && props.children }
            <div className={props.lang === "es_ES" ? `wrapper_card es-version` : `wrapper_card`}>
                <div className="card">
                    <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true}/>
                    <p><span>{email}</span></p>
                    <PG data={data} preview={preview} component={id} block={'text1'} inputCallback={inputCallback} nopadding={true}/>
                    <PG data={data} preview={preview} component={id} block={'text2'} inputCallback={inputCallback} nopadding={true}/>
                </div>
                <img src={image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : PereNoelPNG} alt="Cadeaux Lego" />
                {props.lang === "es_ES" ?
                    <img className="only_mobile" src={imageMobile?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imageMobile.filePath}` : PereNoelPNG} alt="Cadeaux Lego" />
                : null}
                {props.lang === "es_ES" ?
                    <img className="only_mobile banner_left_corner" src={BannerLeftCorner} alt="Banner left corner" />
                : null}
            </div>
            <div className={props.lang === "es_ES" ? `subBlock es-version` : `subBlock`}onMouseEnter={() => setHoverBlock(true)} onMouseLeave={() => setHoverBlock(false)}>
                { !preview && hoverBlock && toolbarBlock }
                <div className="titleContainer">
                    <img src={StarBlue} alt="Etoile bleu" />
                    {/* <h2>MAXIMISEZ VOS CHANCES<br/> DE GAGNER !</h2> */}
                    <H2 data={data.blocks.subblock} preview={preview} component={id} block={'title_subblock'} inputCallback={inputCallback} nopadding={true}/>
                    <img src={StarBlue} alt="Etoile bleu" />
                </div>
                <PG data={data.blocks.subblock} preview={preview} component={id} block={'text_subblock'} inputCallback={inputCallback} nopadding={true}/>
                <div className="buttonContainer">
                    <LinkCustom spread={spread} href="/catalog">
                        <div className="container_button" onClick={() => {
                            share();
                        }}>
                            <FacebookShareButton 
                                url={process.env.REACT_APP_HOST_LINK || 'lego.com'} 
                                quote={props.lang === "fr_FR" ? "Je viens de réaliser ma liste de cadeaux LEGO pour tenter de remporter 1 an de cadeaux LEGO® ou ma liste de cadeaux. Viens tenter ta chance aussi, c’est simple, tu réalises ta liste et tu valides ton inscription avec ton adresse mail." : "Acabo de realizar mi lista de regalos LEGO® para intentar ganar un año de regalos LEGO o mi lista de regalos. ¡Prueba suerte tú también ! Es simple, realiza tu lista y completa tu correo electrónico para confirmar tu lista e inscripción. ¡Gana un año de regalos LEGO®!"}
                                hashtag="#LEGO #Wishlist"
                            >
                                <div className="button_lego facebook">
                                    <img src={FacebookSVG} />
                                    <span>{props.lang === "fr_FR" ? "Partagez sur Facebook" : "Compartir en Facebook"}</span>
                                </div>
                            </FacebookShareButton>
                        </div>
                    </LinkCustom>
                    <LinkCustom spread={spread} href="/catalog">
                        <div className="container_button" onClick={() => {
                            share();
                        }}>
                            <EmailShareButton 
                                url={process.env.REACT_APP_HOST_LINK || 'lego.com'} 
                                subject={props.lang === "fr_FR" ? "Tente toi aussi de remporter un an de cadeaux LEGO® !" : "¡Intenta ganar un año de regalos LEGO®!"}
                                body={props.lang === "fr_FR" ? "Je viens de réaliser ma liste de cadeaux LEGO pour tenter de remporter 1 an de cadeaux LEGO® ou ma liste de cadeaux. Viens tenter ta chance aussi, c’est simple, tu réalises ta liste et tu valides ton inscription avec ton adresse mail.\n" : "Acabo de realizar mi lista de regalo LEGO® para intentar ganar un año de regalos LEGO o mi lista de regalos. ¡Prueba suerte tú también ! Es simple, realiza tu lista y completa tu correo electrónico para confirmar tu lista e inscripción."}
                                separator={props.lang === "fr_FR" ? '\n Je fonce : ': "\n A por ellos : "}
                            >
                                <div className="button_lego mail">
                                    <img src={MailSVG} />
                                    <span>{props.lang === "fr_FR" ? "Partagez par mail" : "Compartir por correo electrónico"}</span>
                                </div>
                            </EmailShareButton>
                        </div>
                    </LinkCustom>
                </div>
            </div>
        </div>
    );
};

ResultScreenBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default ResultScreenBook;
