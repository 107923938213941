import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';
import FooterColumns from '../../components/FooterColumns';

export default function footerEdit(action='add') {
    let generalInputs = []
    generalInputs.push(
        {
            required : true,
            type : 'text',
            label : 'Nom du footer',
            stateName : 'footerName',
            helper: {
                text: 'Nom du footer',
                link: false,
            },
        }, 
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            stateName: 'footerStartAt',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            stateName: 'footerEndAt',
        },
        {
            type: 'switch',
            label: 'Par défault',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'isDefault',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
        {
            type: 'switch',
            label: 'Actif',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'isActive',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    )
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un footer':'Modifier le footer',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer votre footer':'modifier votre footer'}`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add' ? 'Créer ce footer' : 'Modifier le footer',
        formConfig: {
            type: STEPPER,
            finalStep: {
                picto: null,
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Votre nouveau footer est prêt pour sa création' : 'Votre footer est prêt à être mis à jour',
                textButton: action === 'add' ? 'Créer' : 'Modifier le footer'
            },
            children:[
                {
                    labelName: 'Informations du footer',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
                {
                    labelName: 'Gestion des éléments',
                    isOptionnal: true,
                    component: FooterColumns,
                }
            ]
        }
    }
    return form;
}