import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultDescriptionManifest, getDefaultTextareaCms, getDefaultTextCms} from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Section de description de la page',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre principal"),
        text: getDefaultTextareaCms("Sous-titre", "Sed mi quam, dictum at malesuada ac, rutrum in libero. Curabitur sit amet scelerisque libero. Maecenas non tellus sed lorem congue ullamcorper eget posuere diam. Integer ornare elementum congue. Cras in turpis nec urna sagittis tincidunt id sed dui. Sed suscipit nulla et velit molestie tristique.", true),
        description:{
            title: "Bloc de description",
            name: "Bloc de description",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SECTION_MANIFEST_BLOCK,
            buttonAdd: 'un bloc de description',
            limit: 10,
            config: [ 
                getDefaultDescriptionManifest(),
                getDefaultDescriptionManifest(),
                getDefaultDescriptionManifest(),
            ]
        } 
    }
};