import { useEffect, useState } from "react";
import AccordionCustom from "../../../../layouts/Accordion/AccordionCustom";
import OurTypography from "../../../typography/Typography";
import colors from "../../../../../config/theme/colors";
import { Divider } from "@material-ui/core";
import InputBuilder from "../../InputBuilder";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import OurMenu from "../../../menu/Menu";
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import CarouselCustom from "../../../../layouts/Carousel/CarouselCustom";
import VirtualTourModal from "./components/VirtualTourModal";

const VirtualTour = props =>{
    const [markersImageData, setMarkersImageData] = useState([])
    const [currentImageMarker, setCurrentImageMarker] = useState(null);
    const [currentMarker, setCurrentMarker] = useState(null);
    const [openModalMarker, setOpenModalMarker] = useState(false);

    useEffect(()=>{
        if(props.input.type === 'marker' && typeof props.value === "string" && props.value &&markersImageData.length === 0 ){
            let value = JSON.parse(props.value)?.scenes;
            setMarkersImageData(value);
            // setCurrentImageMarker(value[0]);
        }
    },[props.value])

    return (
        <>
            {
                markersImageData.map((imageContainer,index)=>{
                    return (
                        <>
                            <AccordionCustom
                                key={index}
                                custompadding={"0px !important"}
                                custompaddingsummary={"0px !important"}
                                noborder
                                defaultExpanded={false}
                                title={
                                    <div style={{
                                        display:"flex",
                                        justifyContent:"space-between",
                                        alignItems:"center",
                                        width:"100%",
                                        paddingRight:5
                                    }}>
                                        <OurTypography style={{fontSize: "18px",margin:0}} fontweighttext={"bold"} colortext={colors.black.regular}>Image {index + 1}</OurTypography>
                                        <DeleteIcon
                                            onClick={()=>{
                                                let array = markersImageData.filter(e=>e !== imageContainer)
                                                setMarkersImageData(array)
                                                let finalData = {
                                                    scenes : array
                                                }
                                                props.stateCallback(JSON.stringify(finalData))
                                            }}
                                            style={{
                                                color:colors.grey.lighter.hue600,
                                            }} />
                                    </div>
                                }
                            >
                                <div>
                                    <img style={{width:"100%"}} src={`${process.env.REACT_APP_MEDIAS}/${imageContainer.image.filePath}`}/>
                                    <div>
                                        {
                                            imageContainer.markers.map((marker,index)=>{
                                                return (
                                                    <div key={index} style={{position:'relative'}}>
                                                        <Divider flexItem orientation='horizontal' style={{height:"1px",margin:"10px 0"}}/>
                                                        <div style={{
                                                            display:"flex",
                                                            justifyContent:"space-between",
                                                        }}>
                                                            <OurTypography style={{fontSize:16}} fontweighttext={"bold"}>{marker?.title}</OurTypography>
                                                            <OurMenu menuOptions={[
                                                                {
                                                                    label: 'Modifier',
                                                                    color: colors.blue.darker.hue300,
                                                                    icon: <DeleteSharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
                                                                    action: (e) => {
                                                                        e.stopPropagation();
                                                                        setCurrentImageMarker(imageContainer);
                                                                        setCurrentMarker(marker);
                                                                        setOpenModalMarker(!openModalMarker);
                                                                    }
                                                                },
                                                                {
                                                                    label: 'Supprimer',
                                                                    color: colors.red.regular,
                                                                    icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                                                                    action: (e) => {
                                                                        let array = markersImageData.map((f,i) => {
                                                                            if (imageContainer === f) {
                                                                                const newCurrent = {
                                                                                    ...f,
                                                                                    markers: f.markers.filter(g=>g !== marker)
                                                                                }      
                                                                                return newCurrent
                                                                            }
                                                                            return f
                                                                        })
                                                                        setMarkersImageData(array)
                                                                        let finalData = {
                                                                            scenes : array
                                                                        }
                                                                        props.stateCallback(JSON.stringify(finalData))
                                                                    }
                                                                }
                                                            ]}
                                                            handleClick={(e, item) => item.action(e)} 
                                                            />
                                                        </div>
                                                        <OurTypography style={{
                                                            marginTop:10
                                                        }}>{marker?.text}</OurTypography>
                                                        {
                                                            marker?.link &&
                                                                <OurTypography style={{
                                                                    marginTop:20,
                                                                    marginBottom:10
                                                                }}>Lien : <span style={{fontWeight:"bold"}}>{marker?.link}</span></OurTypography>
                                                        }
                                                        {
                                                            marker?.image&&
                                                            <div >
                                                                <CarouselCustom slidesNb={2.2} noDownload height={"auto"} images={[`${process.env.REACT_APP_MEDIAS}/${marker?.image?.filePath}`]} imagesWithoutPlaceholder={[marker?.image?.filePath]}/>
                                                                {/* <img style={{
                                                                    width:"100%"
                                                                }} src={`${process.env.REACT_APP_MEDIAS}/${marker?.image?.filePath}`}/> */}
                                                            </div>    
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <OurTypography style={{cursor:"pointer",color:colors.green.regular, textDecoration:"underline",display:"flex",alignItems:"center",gap:"5px",marginTop:10}} onClick={() => {setCurrentImageMarker(imageContainer);setOpenModalMarker(!openModalMarker)}}> <AddCircleIcon style={{fontSize: "14px"}} /> ajouter un marker</OurTypography>
                                    </div>
                                </div>
                            </AccordionCustom>
                            <Divider flexItem orientation='horizontal' style={{height:"1px",margin:"10px 0"}}/>
                        </>
                    ) 
                })
            }
            <InputBuilder
                type={'button'}
                input={{
                    type:"mediaPicker",
                    hasMediaPicker:true,
                    value: null,
                    translated: false,
                    helper: {
                        text: 'Image Description',
                        link: false,
                    },
                    required: false,
                    handleMediaPicker :(e)=>{
                        const data=  {
                            "image":e,
                            "markers":[
                            ]
                        }
                        let finalData = {
                            scenes : [...markersImageData,data]
                        }
                        props.stateCallback(JSON.stringify(finalData))
                        setMarkersImageData([...markersImageData,data])
                    },
                }}
            />
            <VirtualTourModal
                openModalMarker={openModalMarker}
                setOpenModalMarker={setOpenModalMarker}
                setCurrentImageMarker={setCurrentImageMarker}
                currentImageMarker={currentImageMarker}
                markersImageData={markersImageData}
                setMarkersImageData={setMarkersImageData}
                currentMarker={currentMarker}
                setCurrentMarker={setCurrentMarker}
                stateCallback={props.stateCallback}
            />
        </>
    )
}

export default VirtualTour;