import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultAccordion } from '../../helpersCms';
import {  getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

const blocTextLayers = () => {
    return{
    name: 'Bloc text layer',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className:{
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: 'FFFFFF',
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Top (px)",
            params: {max: 200, min: 0, step: 1},
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Bottom (px)",
            params: {max: 200, min: 0, step: 1},
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
        // value: {
        //     type: FormInputTypes.TEXT,
        //     label: "Titre de la section",
        //     value: "textLayer"
        // },
        // fontSize: {
        //     type: FormInputTypes.SLIDER,
        //     value: 25,
        //     label: "Taille du texte (en px)",
        //     params: {max: 100, min: 2, step: 2},
        // },
        // color: {
        //     label: 'Couleur du texte',
        //     type: FormInputTypes.COLOR,
        //     value: 'FAFAFB',
        // },
        // textAlign: {
        //     type: FormInputTypes.SELECT,
        //     label: "Alignement du texte",
        //     value: "initial",
        //     params: [
        //       { label: "Initial", value: "initial" },
        //       { label: "Centré", value: "center" },
        //       { label: "Droite", value: "right" },
        //     ],
        //   },
        // paddingTop: {
        //     type: FormInputTypes.SLIDER,
        //     value: 60,
        //     label: "Padding top",
        //     params: {max: 200, min: 0, step: 2},
        // },
        // paddingBottom: {
        //     type: FormInputTypes.SLIDER,
        //     value: 60,
        //     label: "Padding bottom",
        //     params: {max: 200, min: 0, step: 2},
        // },
        // noPaddingHorizontal: {
        //     label: "Désactiver l'espace sur les côtés",
        //     type: FormInputTypes.SWITCH,
        //     value: false,
        // },
        // backgroundColor: {
        //     type: FormInputTypes.COLOR,
        //     value: "#FCFCFC",
        //     label: "Couleur du fond"
        // },
        // rowGap: {
        //     type: FormInputTypes.SLIDER,
        //     value: 10,
        //     label: "Espace entre les accordéons",
        //     params: {max: 100, min: 0, step: 2},
        // },        
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre de la section", '#000000', 20, 'px',null,true),
        content:{
            title: "Contenu",
                name: "Contenu",
                // type: EditableTypes.SHORT_TEXT,            
                // subtype: FormInputTypes.WYSIWYG,
                inputs:{
                    value: {
                        type: FormInputTypes.WYSIWYG,
                        value: "Lorem"
                    },
                    identifiant:{
                        label: 'Identifiant',
                        type: FormInputTypes.TEXT,
                        value: "collapse-display-div-question",
                        id: uuidv4(),
                    },
                    className:{
                        label: 'Classe(s)',
                        type: FormInputTypes.TEXT,
                        value: "collapse-display-div-question",
                        id: uuidv4(),
                    },
                    textAlign: {
                        type: FormInputTypes.SELECT,
                        label: "Alignement du texte",
                        value: "initial",
                        params: [
                          { label: "Initial", value: "initial" },
                          { label: "Centré", value: "center" },
                          { label: "Droite", value: "right" },
                        ],
                      },
                }
        },
        displayer:{
            title: "Bouton d'affichage",
            name: "Bouton d'affichage",
            inputs:{
                heightDisplay: {
                    label: 'Hauteur du display',
                    type: FormInputTypes.NUMBER,
                    name: "Hauteur d'affichage :",
                    value: 200,
                },
                transitionDisplay: {
                    label: 'Transition du display',
                    type: FormInputTypes.NUMBER,
                    name: "Temps de transition (en secondes) :",
                    value: 0,
                },
                moreText:{
                    label: 'Plus de texte',
                    type: FormInputTypes.TEXT,
                    value: "Lire la suite",
                    id: uuidv4(),
                },
                lessText:{
                    label: 'Moins de texte',
                    type: FormInputTypes.TEXT,
                    value: "Réduire",
                    id: uuidv4(),
                },
                identifiant:{
                    label: 'Identifiant',
                    type: FormInputTypes.TEXT,
                    value: "collapse-display-button",
                    id: uuidv4(),
                },
                className:{
                    label: 'Classe(s)',
                    type: FormInputTypes.TEXT,
                    value: "collapse-display-button",
                    id: uuidv4(),
                },
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: 'FFFFFF',
                },
                color: {
                    label: 'Couleur du texte',
                    type: FormInputTypes.COLOR,
                    value: '000000',
                },
                paddingVertical: {
                    type: FormInputTypes.SLIDER,
                    value: 5,
                    params: {max: 100, min: 0, step: 1},
                    label: "Padding vertical",
                },
                paddingHorizontal: {
                    type: FormInputTypes.SLIDER,
                    value: 10,
                    params: {max: 100, min: 0, step: 1},
                    label: "Padding horizontal",
                },
                borderWidth: {
                    type: FormInputTypes.SLIDER,
                    value: 0,
                    label: "Largeur de la bordure :",
                    params: {max: 5, min: 0, step: 1}
                },
                borderRadius: {
                    type: FormInputTypes.SLIDER,
                    value: 0,
                    label: "Arrondi des angles :",
                    params: {max: 50, min: 0, step: 1}
                },
                borderColor: {
                    type: FormInputTypes.COLOR,
                    value: '#000',
                    label: "Couleur de la bordure"
                },
            }
        }
    }
}
};

export default blocTextLayers;