import { FormInputTypes  } from '../../../shareable/types';
import { getDefaultLink, getImageDefault } from '../../helpers';


export default {
    name: "Bloc secondaire page de résultat",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks:{
        logo: getImageDefault(),
        textImg: getImageDefault(),
        logo2: getImageDefault(),
        image: getImageDefault(),
        button: getDefaultLink("Boutton", "C'est parti", true, 20, 'rgba(255,255,255,1)', 'rgba(0,0,0,1)', true),
    }
};
