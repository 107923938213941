import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText, getDefaultTextarea, getImageDefault, getDefaultListImages } from '../../helpers';


export default {
    name: 'Image à cliquer',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {        
        gameImage: getImageDefault(null, 'Image du jeu'),           
        title: getDefaultText('Titre', "Es tu prêt ?", 50, "#53839D", null, 'uppercase', 'initial'),        
        number_clicks:{
            type: FormInputTypes.NUMBER,
            name: "Nombre de clique pour gagner",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.NUMBER,
                    value: 10
                }
            }
        },    
        end_game:getImageDefault(null, "Fin du jeu gagné"),
        title_win: getDefaultText('Titre fin du jeu gagné', "Bravo !", 50, "#53839D", null, 'uppercase', 'initial'),        
        desc_win: getDefaultTextarea('Texte fin du jeu gagné', "Vous avez réussi.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),      
        backgroundImage_win:getImageDefault(null, "Image de fond gagné"),   
        end_game_loose:getImageDefault(null, "Fin du jeu perdu"),   
        title_loose: getDefaultText('Titre fin du jeu perdu', "Dommage !", 50, "#53839D", null, 'uppercase', 'initial'),        
        desc_loose: getDefaultTextarea('Texte fin du jeu perdu', "Vous n'avez pas réussi.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),      
        backgroundImage_loose:getImageDefault(null, "Image de fond perdu"),   
        popup:{
            id: uuidv4(),
            title: "Bloc principal",
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },                
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks: {                
                title: getDefaultText('Titre de la popup', "Bienvenue !", 50, "#53839D", null, 'uppercase', 'initial'),        
                desc: getDefaultTextarea('Texte de la popup', "Bref résumé du concept du jeu à suivre.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),
                image: getImageDefault(null, 'Image de la popup'),      
                button: getDefaultButton("Boutton de la popup", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
            }
        } 
    }
};
