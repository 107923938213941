import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import P from '../shared/p';
import Img from '../shared/img';
import { PictureTag } from "../../../../../utils/image";
import Button from "../shared/button";

const Bloc = (props) => {
    const [hover, setHover] = useState(false);
    
    let {
        preview = false,
        data = null,
        spread = false
    } = props;

    return (
        <Div style={{position: 'relative', backgroundColor: data.inputs.backgroundColor.value}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list">
                {
                    data.inputs.value.value ? (
                        <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                    ) : null
                }
                <Div className="list-categories2 ">
                    {
                        data.blocks.categories.config.map((categorie, index) => {
                            let image = categorie.blocks.image.inputs.value.value;
                            return (
                                <a href={categorie.blocks.buttonText.inputs.link.value} className="relative" key={index}>
                                    <Div style={{backgroundImage: `url(${image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image?.filePath}` : image ? image : null})`}} />
                                    <Div>
                                        <H3>{categorie.blocks.title.inputs.value?.value}</H3>
                                        <P>{categorie.blocks.description.inputs.value?.value}</P>
                                        <Button 
                                            color={categorie.blocks.buttonText.inputs.color.value} 
                                            backgroundColor={categorie.blocks.buttonText.inputs.backgroundColor.value}
                                            borderWidth={categorie.blocks.buttonText.inputs.border?.inputs.borderWidth.value}
                                            borderColor={categorie.blocks.buttonText.inputs.border?.inputs.borderColor.value}
                                            borderRadius={categorie.blocks.buttonText.inputs.border?.inputs.borderRadius.value}
                                        >
                                            {categorie.blocks.buttonText.inputs.value?.value}
                                        </Button>
                                    </Div>
                                </a>
                            )
                        })
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default Bloc;