import React from "react";
import { Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import colors from '../../../../../../config/theme/colors';
import moment from 'moment';

function CellDate(props){
    let value = props.attribute?.attribute.attributeType.input === "textarea"
        ? props.value
        : props.attribute?.attribute.attributeType.input === "select" ?
            props.attribute.attributeOption.translation.translationDatas?.edges?.[0].node.value ?? props.attribute.attributeOption.identifier
            : props.value == 'false' 
                ? 'Non' 
                : props.value == 'true' 
                    ? 'Oui' 
                    : props.value;
                
    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                fontWeight : props.fontWeight ? props.fontWeight : 'normal',
                padding : '4px 16px'
            }}
        >

            <Typography variant='body1' style={{color: colors.black.regular, whiteSpace: "nowrap"}}>{props.column.label} <strong>{moment(value).format('DD/MM/YYYY') || "N/A"}</strong></Typography>
        </TableCell>
    )
}
export default CellDate;