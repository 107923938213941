import moment from "moment";
import colors from "../../../../config/theme/colors";
import OurMenu from "../../../ui/menu/Menu";
import OurTypography from "../../../ui/typography/Typography";
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditIcon from '@material-ui/icons/Edit';
moment.locale('fr');
const CardNotifs = (props)=>{
    const {title,message,scheduledAt,link,id,notificationId} = props.data;
    return (
        <div style={{
            padding:20,
            position:"relative",
            display:"flex",
        }}>
            <div style={{
                width:"100%"
            }}>
                <OurTypography style={{
                    color: colors.black.regular
                }} variant="h1" component="h1" gutterBottom>
                    {title}
                </OurTypography>
                <OurTypography style={{
                    color: colors.black.regular
                }} gutterBottom>
                    {message}
                </OurTypography>
                <a href={link} style={{
                    color: colors.black.regular
                }} target={'_blank'}>
                    {link}
                </a>
                <OurTypography style={{
                    color: colors.black.regular
                }} gutterBottom>
                    {console.log(moment(scheduledAt))}
                    {moment(scheduledAt).format('DD/MM/YYYY à HH:mm')}
                </OurTypography>
            </div>
            <OurMenu 
                menuOptions={[
                    {
                        label: 'Modifier',
                        color: colors.blue.darker.hue300,
                        isDisabled: false,
                        icon: <EditIcon style={{ fill: colors.blue.darker.hue300 }} />,
                        action: (e) => {
                            e.stopPropagation();
                            props.actionButton.edit({title,message,scheduledAt,link,id,notificationId});
                            // handleClose(e);
                        }
                    },
                    {
                        label: 'Supprimer',
                        color: colors.red.regular,
                        icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
                        action: (e) => {
                            e.stopPropagation();
                            props.actionButton.delete(e,id,notificationId);
                            // props.deleteCallabck(props.product)
                            // setOpenDeleteModal(!openDeleteModal);
                            // handleClose(e);
                        }
                    }
                    ]} 
                handleClick={(e, item) => item.action(e)} 
            />
        </div>
    )
}

export default CardNotifs;