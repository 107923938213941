import styled from 'styled-components';

export default styled.h2`
    margin: 0;
    color: ${props => props.color || '#000'};
    text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '2.5625rem'};
    line-height: 1.2;
    @media screen and (max-width: 767px){
        font-size: 1.875rem;
    }
    &.din-offc{
        font-size: 2.4375rem;
        line-height: 1.2;
    }
    &.like-h1{
        font-size: 9vw;
        line-height: 1.2;
        max-width: 80vw;
        width: 1000px;
        font-family: 'DINOffc-Bold', sans-serif;
        @media screen and (min-width: 768px){
            font-size: 7.2vw;
        }
        @media screen and (min-width: 1400px){
            font-size: 6vw;
        }
        &.full-width {
            max-width: 100vw;
        } 
    }
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
`; 