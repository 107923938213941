import React from 'react';

import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ReactEcharts from 'echarts-for-react';

class BarCharts extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            xAxisData: this.props.xAxisData,
            yAxisData: this.props.yAxisData,
        };
    }


    
    getOption(){
        var options = {
            // title: {
            //     text: this.props.title,
            //     subtext: this.props.subtext,
            // },
            grid: {
                left: 0,
                bottom:0,
                right:this.props.fullWidth ? 0:50,
                top:40,
                containLabel: true,
            },
            tooltip: {
                trigger: 'axis',
                position: 'top',
            },
            toolbox: {
                right:this.props.fullWidth ? 0:5,
                top:40,
                feature: {
                    saveAsImage: {
                        title: "Télécharger graphique",
                        show:false
                    }
                }
            },
            // legend: {
            //     align: 'left',
            //     icon: 'circle',
            //     right: '0',
            //     itemGap: 90,
            //     textStyle: {
            //         fontSize: 16,
            //         fontFamily: 'Soleil'
            //     }
            // },
            xAxis: {
                type: 'category',                
                data: this.state.xAxisData,               
            },
            yAxis: {
                type: 'value',
            },
            series: this.state.yAxisData.map(serie => {
                return {
                    name: serie.title,
                    type: 'bar',                    
                    label: {                        
                        show: true,
                        position: 'top',
                        formatter:"{a|{c} produits}" ,
                        formatter: [
                            "{a|{c} }",
                            "{b|Produits}"                            
                          ].join('\n'),
                        fontSize:12,
                        fontWeight:"bold",
                        rich: {
                            a: {
                                fontSize:22,
                                align:"center",
                                fontWeight:"bold",
                            }
                        }
                      },                    
                    itemStyle: {
                        color: serie.color
                    },
                    data: serie.data
                }
            })
        };

        return options

    }

    render() {
        return (
            <div>
                <ReactEcharts option={this.getOption()} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
    };
};

export default withRouter(connect(mapStateToProps, null)(BarCharts));
