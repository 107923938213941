import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import merchandisingEdit from './config/merchandising/merchandisingEdit.config';
import merchandisingDuplicate from './config/merchandising/merchandisingDuplicate.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement, updateElement } from '../../../../js/utils/functions';
import { listSettings, listMappers, perPageOptions } from './config/merchandising/listMerchandising.config';
import Listing from '../../../layouts/Listing/Listing';
import * as moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { GET_ASSETS_BY_TYPE, GET_ASSETS_BY_TYPE_CATEGORIES_CATALOG, GET_ASSET_BY_ID_NAVIGATION, GET_ASSET_BY_ID_ONLY_CATALOG, GET_ASSET_TYPES } from '../../../../queries/assets';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardMerchandisingMain from '../../../layouts/Card/cardContent/CardMerchandisingMain';
import { DELETE_MERCHANDISING, GET_MERCHANDISING, GET_MERCHANDISINGS, GET_MERCHANDISINGS_PAGINATION } from '../../../../queries/merchandising';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { GET_CATEGORIE_MERCH, GET_CATEGORY_DATA,ADD_CATEGORY } from '../../../../queries/categories';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import CardMerchandisingVersions from '../../../layouts/Card/cardContent/CardMerchandisingVersions';
import { GET_MERCHANDISINGS_ISACTIVE } from '../../../../queries/merchandising';
import { checkRouting } from '../../../../js/utils/checkRouting';
import DialogModal from '../../../ui/dialog/DialogModal';
import { GET_MAGENTO_PAGES_LIGHT } from '../../../../queries/assetMagentoPage';
import axios from '../../../../js/utils/axios';
import CarouselListing from '../../../layouts/Carousel/CarouselListing';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import SearchBar from '../../../ui/search/SearchBar';
import merchandisingAddCat from './config/merchandising/merchandisingAddCat.config';
import ReturnLink from '../../../ui/link/ReturnLink';

const AccordionCustom2 = styled(Accordion)`
    width: 100%;
    box-shadow: 0px 0px 5px #1622421a;
`;
const AccordionSummaryCustom = styled(AccordionSummary)`
    padding: 0 20px;
    border-bottom: 1px solid ${colors.grey.lighter.hue900};
    min-height: 40px !important;
    .MuiAccordionSummary-content{
        margin: 8px 0 !important;
        align-items: center;
    }
`;
const AccordionDetailsCustom = styled(AccordionDetails)`
    padding: 15px 20px !important;
`;

const CustomSelect = styled(Select)`
    border: 1px solid ${colors.grey.border};
    border-radius: 0;
    min-width: 175px;
    .MuiSelect-root{
        padding: 13px;
    }
`;

const ContainerInput = styled(Grid)`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-top:0!important;
    padding-bottom:0!important;
`;
const VersionLeft = styled(Grid)`
    padding-right: 24px;
    height: 100%;
`;

const Divider = styled.div`
    width:1px;
    height: 70%;
    background-color:black;
`;

const GridSelect = styled(ContainerInput)`
    padding-top:0!important;
    padding-bottom:0!important;
    @media screen and (max-width: 1200px){
        margin-left: 0 !important;
        width: 100%;
        div{
            width: auto !important;
        }

    }
`;

const PageWrapper = styled.div`
    & .layout-wrapper {
        display: none;
    }
`;

function EngineAssetsDetailsMerchandisingDetail(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [currentCat, setCurrentCat] = React.useState(null);
    const [loadedMerch, setLoadedMerch] = React.useState(false);
    const [loadedVersions, setLoadedVersions] = React.useState(false);
    const [loadedCat, setLoadedCat] = React.useState(false);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [reloadVersions, setReloadVersions] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [versionActive, setVersionActive] = React.useState([]);
    const [listAssets, setListAssets] = React.useState([]);
    const [listMerchandisings, setListMerchandisings] = React.useState([]);
    const [merchCurrent, setMerchCurrent] = React.useState(null);
    const [states, setStates] = React.useState({
        rule1: null,
        rule2: null,
        rule3: null,
        merchandisingCategory: null,
        merchandisingLibelle: null,
        merchandisingStartAt: null,
        merchandisingEndAt: null,
        merchandisingStatus: false,
        merchandisingCatLibelle: null,
        merchandisingCatPosition:null,
        merchandisingCategory:null,
        categorySelected: null
    });
    // const [filters, setFilters] = React.useState({
    //     firstRule: {
    //         value: 'all',
    //         status: null
    //     },
    //     secondRule: {
    //         value: 'all',
    //         status: null
    //     },
    // });
    // const [filtersQueryVariables, setFiltersQueryVariables] = React.useState({
    //     firstRule: null,
    //     secondRule: null,
    // });

    useEffect(() => {
        checkRouting(props)
        setCurrentCat(null)
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavigationAsset()
        }
        initMerchData()
        initVersions()
    }, [props.match.params.id, props.match.params.idCat])

    const goTo = route => {
        props.history.push(route);
    };

    const handleClickMenu = (event, id) => {
        setMerchCurrent(id)
        setAnchorEl(event.currentTarget);
    };

    const handleDuplication = () => {
        handleToggleDrawer('openForm', true, 'duplicate');
    };
    const handleDelete = () => {
        handleClose();
        handleToggleDialog();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const initMerchData = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_CATEGORIE_MERCH,
                variables: { id: `/api/categories/${props.match.params.idCat}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setCurrentCat(result.data.category)
                let getState = { ...states };
                getState["merchandisingCategory"] = result.data.category.id
                setStates(getState)
                // setMerchCurrent(result.data.merchandising)
                setLoadedMerch(true)
                // setLoadedCat(true)
                // window.setAssetNameMenu(result.data.asset)
                // props.client.query({
                //     query : GET_CATEGORY_DATA,
                //     variables:{id: `/api/merchandisings/${props.match.params.idCat}`},
                //     fetchPolicy : 'no-cache'
                // }).then(data => {

                // })
            })
            resolve()
        })
    }

    const initNavigationAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                // setAllCategories(result.data.asset.catalog.categories)
                setLoadedCat(true)
                setLoadedMerch(true)
                window.setAssetNameMenu(result.data.asset)
            })
            resolve()
        })
    }

    const initVersions = () => {
        return new Promise(async (resolve, reject) => {
            props.client.query({
                query: GET_MERCHANDISINGS_PAGINATION,
                variables: {
                    category: `/api/categories/${props.match.params.idCat}`
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                props.client.query({
                    query: GET_MERCHANDISINGS_ISACTIVE,
                    variables: {
                        category: `/api/categories/${props.match.params.idCat}`
                    },
                    fetchPolicy: 'no-cache'
                }).then(
                    GET_MERCHANDISINGS_ISACTIVE_RESULT => {
                        setVersionActive(GET_MERCHANDISINGS_ISACTIVE_RESULT.data.merchandisings.edges)
                        setLoadedVersions(true)
                    }
                )
                // setAllCategories(result.data.asset.catalog.categories)
            })
            resolve()
        })
    }

    const getGroupUsersCallback = () => {
        setReloadVersions(false)
    }

    const handleToggleDrawer = (stateDrawer, reset, form) => {
        setEditType(form)
        setOpenForm(!openForm)
        if (reset) {
            resetStates()
        }
    };
    const handleToggleDialog = () => {
        setOpenDialog(!openDialog)
    };

    const resetStates = () => {
        setStates({
            id: null,
            merchandisingCategory: null,
            merchandisingLibelle: null,
            merchandisingStartAt: null,
            merchandisingEndAt: null,
            merchandisingStatus: true,
            categorySelected: null
        })
    }

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const loadMerchandisings = (categoryId) => {
        props.startLoading()
        props.client.query({
            query: GET_MERCHANDISINGS,
            variables: {
                category: categoryId
            },
            fetchPolicy: 'no-cache'
        }).then(result => {
            setListMerchandisings(result.data.merchandisings.edges)
            props.stopLoading()
        })
    }

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)

    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const editHandler = (merchandising) => {
        setEditType('edit')
        let getState = { ...states };
        getState['id'] = merchandising.id;
        getState['merchandisingCategory'] = merchandising.category.id;
        getState['merchandisingLibelle'] = merchandising.title;
        getState['merchandisingStartAt'] = merchandising.startAt;
        getState['merchandisingEndAt'] = merchandising.endAt;
        getState['merchandisingStatus'] = merchandising.status;
        setStates(getState);
        handleToggleDrawer('openForm');
    }

    const initForm = () => {
        setEditType('add')
        handleToggleDrawer('openForm');
    }

    const deleteMutation = async () => {
        props.startLoading();
        props.client.mutate({
            mutation: DELETE_MERCHANDISING,
            variables: { id: merchCurrent }
        }).then(result => {
            props.stopLoading();
            props.snack(ALERT_SUCCESS, 'Merchandising supprimé avec succès');
            setReloadVersions(true);
            handleToggleDialog();
        }).catch(error => {
            props.stopLoading();
            props.snack(ALERT_ERROR, `Impossible de supprimer le merchandising`);
            setReloadVersions(true);
            handleToggleDialog();
        });
    }

    const hasErrors = () => {
        if (errors) {
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }

        return false;
    };

    const handlerMutation = async () => {
        let variables = null;
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        if (editType === "merch") {
            variables = {
                category: states.merchandisingCategory,
                title: states.merchandisingLibelle,
                startAt: states.merchandisingStartAt,
                endAt: states.merchandisingEndAt,
                status: false,
            }
            try {
                let createElement = await saveElement('merchandising', variables, { enableLoad: false })
                props.snack(ALERT_SUCCESS, 'Merchandising ajouté avec succès');
                if (createElement) {
                    handleToggleDrawer('openForm', false);
                    await initMerchData()
                    // setReloadVersions(true)
                }
            } catch {
                props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la création du merchandising');
            }
        } else if (editType === "duplicate") {
            props.startLoading()
            let resultDuplicate = await axios(`${process.env.REACT_APP_API}/category/merchandising/duplicate/${currentCat.id.replace('/api/categories/', '')}/${states.categorySelected.replace('/api/categories/', '')}`, 'GET');
            if (resultDuplicate.success) {
                props.snack(ALERT_SUCCESS, props.t("spread.active_assets.merchandisingsDuplicated"));
            }
            handleToggleDrawer('openForm', true);
            setErrors({})
            props.stopLoading();
        } else if (editType === "cat") {
            props.startLoading()
            // let resultDuplicate = await axios(`${process.env.REACT_APP_API}/category/merchandising/duplicate/${currentCat.id.replace('/api/categories/', '')}/${states.categorySelected.replace('/api/categories/', '')}`, 'GET');
            // if (resultDuplicate.success) {
            //     props.snack(ALERT_SUCCESS, props.t("spread.active_assets.merchandisingsDuplicated"));
            // }
            props.client.query({
                query: ADD_CATEGORY,
                fetchPolicy: 'no-cache',
                variables:{
                    "libelle" : states.merchandisingCatLibelle,
                    "position": states.merchandisingCatPosition,
                    "parent": currentCat.id
                }
            })
            handleToggleDrawer('openForm', true);
            setErrors({})
            props.stopLoading();
        } else {
            variables = {
                category: `/api/categories/${props.match.params.idCat}`,
                title: states.merchandisingLibelle,
                startAt: states.merchandisingStartAt,
                endAt: states.merchandisingEndAt,
                status: false,
            }
            try {
                let createVersionElementResult = await saveElement('merchandising', variables, { enableLoad: true })
                props.snack(ALERT_SUCCESS, 'Version ajoutée avec succès');
                if (createVersionElementResult) {
                    handleToggleDrawer('openForm', true);
                    setReloadVersions(true)
                }
            } catch (e) {
                console.log('e', e)
                props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la modification du merchandising');
            }
        }
    }

    let titleDefault = null
    let title = null
    if (currentCat) {
        titleDefault = getTraductionAttributs('category_name', currentCat.categoryDatas.edges, currentLang)
        title = currentCat.libelle
    }

    // const firstRuleFiltersOptions = {
    //     all: { value: 'all', label: 'Tous', status: null },
    //     active: { value: 'active', label: 'Actif', status: true },
    //     inactive: { value: 'inactive', label: 'Inactif', status: false },
    // }

    // const handleFirstRule = (e, custom) => {
    //     const chosenOption = e.target.value;
    //     setFilters({
    //         ...filters,
    //         firstRule: {
    //             ...filters.firstRule,
    //             value: chosenOption,
    //             status: Object.values(firstRuleFiltersOptions).find(option => option.value === chosenOption).status
    //         }
    //     })
    // }
    
    // const secondRuleFiltersOptions = {
    //     all: { value: 'all', label: 'Tous', status: null },
    //     active: { value: 'active', label: 'Actif', status: true },
    //     inactive: { value: 'inactive', label: 'Inactif', status: false },
    // }

    // const handleSecondRule = (e, custom) => {
    //     const chosenOption = e.target.value;
    //     setFilters({
    //         ...filters,
    //         secondRule: {
    //             ...filters.secondRule,
    //             value: chosenOption,
    //             status: Object.values(secondRuleFiltersOptions).find(option => option.value === chosenOption).status
    //         }
    //     })
    // }

    // const isFilterActive = () => 
    //     filters.firstRule.value !== firstRuleFiltersOptions.all.value || filters.secondRule.value !== secondRuleFiltersOptions.all.value

    // const resetFilters = () => {
    //     setFilters({
    //         firstRule: {
    //             ...filters.firstRule,
    //             value: firstRuleFiltersOptions.all.value,
    //             status: firstRuleFiltersOptions.all.status
    //         },
    //         secondRule: {
    //             ...filters.secondRule,
    //             value: secondRuleFiltersOptions.all.value,
    //             status: secondRuleFiltersOptions.all.status
    //         }
    //     })
    // }

    // const handleFilter = () => {
    //     setFiltersQueryVariables({
    //         ...filtersQueryVariables,
    //         firstRule: filters.firstRule.status,
    //         secondRule: filters.secondRule.status
    //     })
    // }


    return (
        <PageWrapper>
            {
                loadedMerch && currentCat ?
                    <>
                        <TopPanel
                            icomoon={"ico-merchandising"}
                            colorIcomoon={colors.blue.darker.hue300}
                            title={titleDefault ?? title}
                            subtitle={props.t("spread.merchandising.subtitle")}
                            gradientColor1={colors.menu.regular}
                            gradientColor2={colors.menu.darker}
                            openForm={openForm}
                            // currentLang     = {currentLang} 
                            // handleLang      = {handleLang} 
                            // locales         = {props.locales}
                            // textDelete={"Supprimer"}
                            // handlerDelete={this.handleToggleDialog}
                            handlerAdd={handleDuplication}
                            textAdd={props.t("spread.active_assets.duplicate")}
                            hasBorder={true}
                            buttonAvailable={true}
                        />        
                        <>
                            <Grid container>
                            <Box style={{padding:"0 0 10px 0"}}>
                                <ReturnLink goTo={props.history.goBack}/>
                            </Box>        
                            {/* <AccordionCustom
                                title={'Filtres et Recherche'}
                                forcedExpanded={true}
                                custompadding={'0px'}
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                <Grid style={{ flexGrow: 1 }}>
                                    <Grid container style={{ padding: '16px 19px' }}>
                                        <Grid item xs={4} md={2}>
                                            <Grid container>
                                                <Grid container justifyContent='space-between'>
                                                    <Typography variant='body1' style={{
                                                        color: colors.black.regular,
                                                        fontSize: '12px',
                                                        marginBottom: '9px',
                                                        marginTop: props.windowWidth < 600 && '10px'
                                                    }}>Règle 1</Typography>
                                                </Grid>
                                            </Grid>
                                            <CustomSelect
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filters.firstRule.value}
                                                onChange={handleFirstRule}
                                                variant="standard"
                                                disableUnderline
                                                style={{
                                                    width: 'calc(100% - 10px)',
                                                }}
                                            >
                                                <MenuItem value={firstRuleFiltersOptions.all.value}>{firstRuleFiltersOptions.all.label}</MenuItem>
                                                <MenuItem value={firstRuleFiltersOptions.active.value}>{firstRuleFiltersOptions.active.label}</MenuItem>
                                                <MenuItem value={firstRuleFiltersOptions.inactive.value}>{firstRuleFiltersOptions.inactive.label}</MenuItem>
                                            </CustomSelect>
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <Grid container>
                                                <Grid container justifyContent='space-between'>
                                                    <Typography variant='body1' style={{
                                                        color: colors.black.regular,
                                                        fontSize: '12px',
                                                        marginBottom: '9px',
                                                        marginTop: props.windowWidth < 600 && '10px'
                                                    }}>Règle 2</Typography>
                                                </Grid>
                                            </Grid>
                                            <CustomSelect
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filters.secondRule.value}
                                                onChange={handleSecondRule}
                                                variant="standard"
                                                disableUnderline
                                            >
                                                <MenuItem value={secondRuleFiltersOptions.all.value}>{secondRuleFiltersOptions.all.label}</MenuItem>
                                                <MenuItem value={secondRuleFiltersOptions.active.value}>{secondRuleFiltersOptions.active.label}</MenuItem>
                                                <MenuItem value={secondRuleFiltersOptions.inactive.value}>{secondRuleFiltersOptions.inactive.label}</MenuItem>
                                            </CustomSelect>
                                        </Grid>
                                        <Grid container xs={12} md={8} justifyContent={'flex-end'} alignItems='center' style={{
                                            marginRight: props.windowWidth >= 1250 ? 'unset' : '8px',
                                            marginBottom: props.windowWidth >= 1250 ? 'unset' : '8px',
                                        }}>
                                            {
                                                isFilterActive() ?
                                                    <Button onClick={resetFilters} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: 'auto', border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                                    : null
                                            }
                                            <Button onClick={(e) => {
                                                e.preventDefault();
                                                handleFilter();
                                            }} style={{ marginBottom: 0, borderRadius: 0, marginTop: 'auto' }}>Filtrer</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionCustom> */}
                                <AccordionCustom defaultExpanded={true} title={'Contenus liée au merchandising'} scrollable={true} height={'100%'}>
                                    <Grid container style={{ marginBottom: 16, marginTop: 16 }}>
                                        <Grid item container alignItems='center' style={{paddingBottom:"16px"}}>
                                            <Typography variant={"h2"}>Versions merchandising</Typography>
                                            <Button style={{ margin: 0, marginLeft: 25, padding: "14px 32px" }} fontsize={16} text={'+ Nouveau Merch.'} bgcolor={colors.green.regular} onClick={() => handleToggleDrawer('openForm', false, 'version')} shadowcolor={colors.green.darker} disabled={false} />
                                        </Grid>
                                        <Grid container style={{ marginTop: 16 }} item>
                                            {
                                                loadedVersions ?
                                                    <Grid item lg={3} md={3} sm={12} xs={12} style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                        <VersionLeft container justifyContent='center' alignItems='center'>
                                                            {
                                                                versionActive.length > 0 ?
                                                                    <CardCustom
                                                                        style={{ width: "100%", padding: 0, border: "0.5px solid #D0D0D1" }}
                                                                        cardContentStyle={{ padding: 0 }}
                                                                        contentpadding={props.cardContainerProps?.contentpadding}
                                                                        hovercard={false}
                                                                        collapse={true}
                                                                    >
                                                                        <CardMerchandisingVersions handleDuplication={handleDuplication} handleDelete={handleDelete} handleClickMenu={handleClickMenu} handleClose={handleClose} anchorEl={anchorEl} data={versionActive[0]} {...props} />
                                                                    </CardCustom>
                                                                    : <Typography variant={"body1"}>Aucune diffusion en cours</Typography>
                                                            }
                                                        </VersionLeft>
                                                        <Divider/>                                        
                                                    </Grid>
                                                    : null
                                                }
                                            <Grid item lg={loadedVersions ? 9 : 12} md={loadedVersions ? 9 : 12} sm={12} xs={12} style={{ paddingLeft: 24 }}>                                            
                                                <Listing
                                                    label='merchandisings'
                                                    settings={listSettings}
                                                    cardProps={{
                                                        openForm: openForm,
                                                        currentLang: currentLang,
                                                        textButton: 'Modifier',
                                                        handlerButton: editHandler,
                                                        windowWidth: props.windowWidth,
                                                        handleClickMenu: handleClickMenu,
                                                        handleClose: handleClose,
                                                        handleDelete: handleDelete,
                                                        handleDuplication: handleDuplication,
                                                        anchorEl: anchorEl                                                    
                                                    }}
                                                    perPageOptions={perPageOptions}
                                                    mappers={listMappers}
                                                    currentLang={currentLang}
                                                    identifier='merchandisings'
                                                    propsToPass={props}
                                                    queryVariables={{
                                                        "category": `/api/categories/${props.match.params.idCat}`
                                                    }}
                                                    viewsOptions={{
                                                        current: 'carousel',
                                                        settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                                    }}
                                                    pagination={false}
                                                    reload={reloadVersions}
                                                    listingCallback={getGroupUsersCallback}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{paddingTop:20}}>
                                        <Grid container alignItems='center'>
                                            <Typography variant={"h2"}>{currentCat.children.filter(e => e.merchandisings.totalCount > 0).length > 1 ? props.t('content.content.categories') : props.t('content.content.category')}</Typography>
                                            <Button style={{ marginLeft: 25 }} fontsize={16} bgcolor={colors.green.regular} shadowcolor={colors.green.darker} onClick={() => handleToggleDrawer('openForm', false, 'cat')}>Nouvelle catégorie +</Button>
                                        </Grid>
                                        {
                                            currentCat.children.length > 0 ?
                                                <>
                                                    <Grid container style={{ marginTop: 8 }} spacing={2}>
                                                        {
                                                            currentCat.children.map((subCat) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={3}
                                                                        lg={3}
                                                                    >
                                                                        <CardCustom
                                                                            style={{ width: "100%", padding: 0, border: "0.5px solid #D0D0D1" }}
                                                                            cardContentStyle={{ padding: 0 }}
                                                                            contentpadding={props.cardContainerProps?.contentpadding}
                                                                            hovercard={false}
                                                                            collapse={true}
                                                                        >
                                                                            <CardMerchandisingMain data={subCat} currentLang={currentLang} isSub={true} {...props} />
                                                                        </CardCustom>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </>
                                                : <Typography style={{color:colors.grey.lighter.hue700}} >Aucun résultat</Typography>
                                        }
                                    </Grid>
                                </AccordionCustom>
                            </Grid>
                            <LayoutBuilder
                                isSublayout={false}
                                icomoon="ico-merchandising"
                                opened={openForm}
                                forClose={() => handleToggleDrawer('openForm', editType === "duplicate" ? false : true)}
                                handlerSetup={() => { }}
                                dataLayout={editType === "duplicate" ? merchandisingDuplicate({ ...states, listAssets, currentLang, listMerchandisings, merchCurrent }) : editType === "version" ? merchandisingEdit('edit', currentCat.children, currentLang) : editType === "cat" ? merchandisingAddCat(currentCat,currentLang)  :merchandisingEdit('add', currentCat.children, currentLang)}
                                drawerWidth={props.drawerWidth}
                                allState={{ ...states, currentLang }}
                                stateCallback={stateCallback}
                                errorCallback={handleFormError}
                                // handleButtonGroupChange={this.handleButtonGroupChange}
                                validateButton={editType === "duplicate" ? states.categorySelected ? true : false : true}
                                handlerMutation={handlerMutation}
                            // deleteMutation={editType === "edit" ? () => {handleToggleDialog()} :  null}
                            // deleteText={'Supprimer le merchandising'}
                            // currentLang={currentLang}
                            // handleLang={this.handleLang}
                            />

                            <DialogModal
                                icon={true}
                                type='delete'
                                open={openDialog}
                                title={props.t("spread.active_assets.sureToDeleteMerchandising")}
                                primaryAction={deleteMutation} 
                                secondaryAction={() => { handleToggleDialog() }} 
                                windowWidth={props.windowWidth}
                            >
                                {props.t("spread.active_assets.warningToDeleteMerchandising")}
                            </DialogModal>
                        </>
                    </>
                    : <PageLoader />
            }
        </PageWrapper>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsMerchandisingDetail))));