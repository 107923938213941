import React, { useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';

const Text = ({
  preview = false, 
  id = null, 
  data = null,
  inputCallback = null,
  children = null,
  spread = false
}) => {
  const [hover, setHover] = useState(false);
  
  const style = {
    boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px inset' : 'none',
    marginBottom: 10
  };

  return (
    <div className="builder-scope" style={style} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      { !preview && spread && hover && children}
      <Pg data={data} component={id} block={'content'} inputCallback={inputCallback} preview={preview} hover={hover} />
    </div>
  );
};

export default Text;
