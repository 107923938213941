import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SelectCompanies from '../../customers/components/SelectCompanies';

export default function addProject(currentLang,handleMediaPicker,allCompanies, handleSelectCompany,currentCompanies,formSettings, action) {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Indiquez la description du projet',
                link: false,
            },
            required: true,
            stateName: 'description',
        },
        {
            type: 'mediaPicker',
            label: 'Image projet',
            helper: {
                text: 'Indiquez le nom',
                link: false,
            },
            handleMediaPicker : handleMediaPicker,
            required: true,
            stateName: 'imageProject',
        },
        
    ]
    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
        {
            labelName: 'Société',
            isOptionnal: false,
            component : SelectCompanies,
            componentProps :{
                companies:allCompanies ,
                currentLang :currentLang,
                handleSelectCompany : handleSelectCompany,
                currentCompanies : currentCompanies.current,
                paginationSettings : {
                    page : formSettings.page,
                    count:formSettings.count,
                    changePage : formSettings.changePage
                },
                handleSearch : formSettings.handleSearch,
                uniqueSelect: true,
                selectedCompanies: currentCompanies
            }
        },
    ];

    var obj = {
        titleForm: action === 'add'? 'Créer un projet':'Modifier un projet',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre projet`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce projet' : 'Valider la modification de ce projet',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children
        }
    };
    return obj;
}