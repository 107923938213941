import { gql } from 'apollo-boost';

//----- Récupération des CRONLOG_BY_PRODUCT
export const GET_CRONLOGS_BY_PRODUCT= gql`
query getCronLogsByProduct($product: String) {
  cronLogs(product: $product, order:{date: "DESC"}){
    edges{
      node{
        id
        cronType
        date
        status
        message
        cronShedule{
          jobCode
        }
      }
    } 
  }
}
`;