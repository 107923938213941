import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';

import "../../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

import notFound from '../../../../assets/images/not-found.png';
// import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import parse from 'html-react-parser';
import { ROUTE_PRODUCTS_LIST_DETAIL } from '../../../../js/constants/route-names';
import { withTranslation } from 'react-i18next';

const Sku = styled(Typography)``
const BoxCustom = styled(Box)`
    width: auto;
`
const BoxCustomAttributs = styled(Box)`
    background: ${colors.grey.lighter.hue980};
`
const TitleAttributs = styled(Typography)`
    font-weight: bold;
    color: ${colors.black.regular};
`
const TypographyAttributs = styled(Typography)`
    color: ${colors.grey.regular};
`
// const ReturnLink = styled(Typography)`
//     color: ${colors.blue.darker.hue300};
//     width: 70px;
//     cursor: pointer;
//     &:hover{
//         text-decoration: underline;
//     }
// `
// const SpanColor = styled.span`
//     color: ${colors.blue.darker.hue300};
//     font-weight: bold;
// `
const Title = styled(Typography)`
    font-weight: bold;
`
const Subtitle = styled(Typography)`
    color: ${colors.black.regular};
    font-weight: bold;
`;
const AttributContainer = styled(Grid)`
    margin-bottom: 16px;
`;
const IframeCustom = styled.iframe`
    width: 100%;
    border: none;
    height: 100px;
    box-shadow: 0px 0px 5px #1622423b;
    border-radius: 5px;
`;
const ContentGrid = styled(Grid)`
    img {
        max-width: 100%;
    }
    iframe {
        width: 100%;
        height: 300px;
    }
`;

const BoxCustomGallery = styled(Box)`
    .image-gallery-slides{
        display:flex;
        align-items: center;
        justify-content: center;
    }
    .image-gallery-slide-wrapper{
        max-height: 350px;
        min-height: 350px;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .image-gallery-swipe{
            height: 100%;
            max-height: 350px;
            .image-gallery-slide{
                display:flex;
                align-items: center;
                justify-content: center;
                max-height: 350px;
                height: 100%;
                overflow: hidden;
                // width: auto;
            }
        }
    }
    .image-gallery-right-nav, .image-gallery-left-nav {
        padding: 0;
    }
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
        height: 30px !important;
        width: 30px;
        padding: 0;
        stroke-width: 2;
    }
    .image-gallery-icon{
        opacity: 0;
        color: ${colors.black.regular};
        filter: none;
        height: 40px !important;
        width: 40px;
        background: ${colors.grey.lighter.hue900};
        padding: 0px;
        border-radius: 50%;
    }
    .image-gallery-swipe{
        height: 100%
        .image-gallery-swipe{
            height: 100%
            .image-gallery-slide{
                height: 100%   
            }
        }
    }
    .image-gallery-content {
        width: 100%;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image{
        width: 100%;
        max-height: 350px;
    }
    .image-gallery-slide-wrapper:hover .image-gallery-icon{
        opacity: 0.4;
    }
    .image-gallery-icon:hover{
        opacity: 0.8 !important;
    }
    .image-gallery-fullscreen-button svg{
        height: 20px !important;
    }
    .image-gallery-thumbnail{
        transition: unset;
    }
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
        border: 2px solid #0273A58f;
    }
`;

class CardContentDetails extends React.Component {
    render() {
        const content = this.props.content;
        // const goTo      = this.props.routeContent;
        // const totalImages = this.props.imageAttributes.length;
        let values = [];
        // let statsCompletion = 30;
        // let statsCompletion = ;
        if (this.props.locales) {

            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.content_title ?? null,
                    description: this.props.allState[locale.node.code]?.content_description ?? null,
                });
            }
        }

        const defaultName = values.find(e => e.name);
        const defaultDesc = values.find(e => e.description !== '<p><br></p>');

        const valueName = values.find(e => e.code === this.props.currentLang);
        const valueDesc = values.find(e => e.code === this.props.currentLang && e.description !== '<p><br></p>');

        let map = attribute => {
            const defaultLabel = attribute.node.translation.translationDatas.edges[0];

            const label = attribute.node.translation.translationDatas.edges.find(
                lang => lang.node.locale.code === this.props.currentLang
            );

            let values = [];

            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    value: this.props.allState[locale.node.code]?.[attribute.node.identifier] ?? null
                });
            }

            const defaultValue = values.find(e => attribute.node.attributeType.input === 'file' ? e.value?.data ?? null : e.value);
            const value = values.find(e => e.code === this.props.currentLang);

            const finalAttributeValue = attribute.node.attributeType.input === 'file'
                ? (value?.value?.file ?? null)
                    ? value.value.file.name
                    : (value?.value?.data ?? null)
                        ? value.value.data
                        : (defaultValue?.value?.file ?? null)
                            ? defaultValue.value.file.name
                            : defaultValue?.value?.data ?? null
                : value?.value ?? (defaultValue?.value ?? null);

            let finalAttributeOptionValue = null;

            if (attribute.node.attributeType.input === 'select') {
                if (finalAttributeValue) {
                    let option = attribute.node.attributeOptions.edges.find(e => e.node.id === finalAttributeValue);

                    if (option) {
                        const optionDefaultLabel = option.node.translation.translationDatas.edges[0];

                        const optionLabel = option.node.translation.translationDatas.edges.find(
                            lang => lang.node.locale.code === this.props.currentLang
                        );

                        finalAttributeOptionValue = optionLabel?.node.value ?? optionDefaultLabel?.node.value ?? option.node.identifier;
                    }
                }
            }


            return (
                (finalAttributeValue || finalAttributeOptionValue) !== null ?
                    <AttributContainer container direction="row" key={attribute.node.identifier}>
                        <Grid item xs={8} >
                            <TitleAttributs variant="body2">
                                {
                                    label?.node.value
                                    ?? defaultLabel?.node.value
                                    ?? attribute.node.identifier
                                }
                            </TitleAttributs>
                        </Grid>
                        <Grid item xs={4}>
                            <TypographyAttributs variant="body2" style={{ wordBreak: 'break-word' }}>
                                {attribute.node.attributeType.input === 'select' ? finalAttributeOptionValue : finalAttributeValue}
                            </TypographyAttributs>
                        </Grid>
                    </AttributContainer>
                    : null
            );
        };
        return (
            <Box pb={1} px={2}>
                <Grid container direction="row" justifyContent="center" spacing={0}>
                    <Grid item xs={12}>
                        {this.props.type === 'dashboard' ? '' :
                            (
                                <>
                                    <Grid container justifyContent="flex-end">
                                        <Box pt={2} pb={2}>
                                            <StatusInfo status={content.status} />
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }
                        <BoxCustomGallery>
                            {
                                (() => {
                                    let images = this.props.imageAttributes.map((attribute, index) => {
                                        let values = [];

                                        for (let locale of this.props.locales) {
                                            values.push({
                                                code: locale.node.code,
                                                value: this.props.allState[locale.node.code]?.[attribute.node.identifier] ?? null
                                            });
                                        }

                                        const defaultValue = values.find(e => e.value);
                                        const value = values.find(e => e.code === this.props.currentLang);
                                        const finalValue = value?.value ?? defaultValue?.value ?? null;
                                        return {
                                            original: finalValue
                                                ? finalValue.changed
                                                    ? finalValue.data
                                                        ? finalValue.data
                                                        : notFound
                                                    : `${process.env.REACT_APP_MEDIAS}/${finalValue.data ? finalValue.data : finalValue.filePath}`
                                                : index > 0
                                                    ? null
                                                    : notFound,
                                        };
                                    });
                                    if (images[0]?.original !== notFound) {
                                        this.props.allState.imagesSelected = images
                                    } else {
                                        this.props.allState.imagesSelected = []
                                    }
                                    return (
                                        <Box style={{ width: '100%' }}>
                                            <Grid container alignItems="center" justifyContent="center">
                                                {
                                                    images.length > 0 && images[0] ? (
                                                        <img src={images[0].original} width="100%" alt={'ImageCardContent'} />
                                                    ) :
                                                        null
                                                }
                                            </Grid>
                                        </Box>
                                    )
                                    // return <ImageGallery items={images} infinite={true} showPlayButton={false} />
                                })()
                            }
                        </BoxCustomGallery>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="column" justifyContent="flex-start">
                            <ContentGrid item xs={12} style={{ marginTop: 16 }}>
                                <Sku variant="body2">{this.props.sku}</Sku>
                                <Box>
                                    <Title variant="h4">{this.props.allState[this.props.currentLang]?.content_title ?? defaultName?.name ?? null}</Title>
                                </Box>

                                <Box pt={4} pb={1}>
                                    <Subtitle variant="body2">{this.props.t("content.content.categories")}</Subtitle>
                                </Box>
                                <Typography variant="body1">{this.props.categories.map((categorie, index) => {
                                    let allNames = categorie.contentCategoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');

                                    const defaultValue = allNames[0];
                                    const value = allNames.find(e => e.node.locale.code === this.props.currentLang);

                                    let finalValue = value?.node.value ?? defaultValue?.node.value ?? categorie.libelle;

                                    return `${finalValue}${this.props.categories.length === index + 1 ? '' : ' / '}`;
                                })}</Typography>
                                <Box pt={4} pb={1}>
                                    <Subtitle variant="body2">{this.props.t("content.content.description")}</Subtitle>
                                </Box>
                                {
                                    valueDesc?.description || defaultDesc?.description ?
                                        parse(valueDesc?.description ?? defaultDesc?.description ?? null, {
                                            replace: domNode => {
                                                if (domNode.data) {
                                                    if (domNode.data.includes('<product')) {
                                                        var getSku = domNode.data.match('sku=\"(.*)\" idProduct');
                                                        var getIdProduct = domNode.data.match('idProduct=\"(.*)\"');
                                                        if (getSku) {
                                                            return (
                                                                <span style={{ width: '100%', display: 'block', position: 'relative' }} onClick={() => this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', getIdProduct[1].replace('/api/products/', '')))}>
                                                                    <span style={{ display: 'block', width: '100%', position: 'absolute', left: 0, top: 0, height: '100%', cursor: 'pointer' }}></span>
                                                                    <IframeCustom
                                                                        id={`product-${getSku[1]}`}
                                                                        title={`Produit ${getSku[1]}`}
                                                                        src={`${process.env.REACT_APP_PUBLIC}/card/product/${this.props.currentLang}/${getSku[1]}`}
                                                                    />
                                                                </span>
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                        : null
                                }

                                {this.props.type === 'dashboard' ?
                                    (
                                        <Grid container>
                                            <Box pt={4}>
                                                <StatusInfo status={content.status} />
                                            </Box>
                                        </Grid>
                                    ) : ''
                                }

                                {
                                    this.props.customAttributes && this.props.customAttributes.length
                                        ? (
                                            <Box pt={4}>
                                                <Subtitle variant="body2">{this.props.t("content.content.attributes")}</Subtitle>
                                                <BoxCustomAttributs mt={2} py={2} px={1}>
                                                    <Grid container>
                                                        {
                                                            this.props.customAttributes
                                                                ? this.props.customAttributes.filter(e => e.node.attributeType.input !== 'image').map(
                                                                    map)
                                                                : null
                                                        }
                                                    </Grid>
                                                </BoxCustomAttributs>
                                            </Box>
                                        ) : null
                                }
                            </ContentGrid>
                        </Grid>
                        {
                            this.props.creation
                                ? null
                                : <Grid container direction="row" justifyContent="flex-end">
                                    {this.props.type === 'dashboard' ?
                                        (
                                            <Box>
                                                <Button text={this.props.t("content.content.seeProductSheet")} bgColor={colors.green.regular} onClick={this.props.routeContent} />
                                            </Box>
                                        )
                                        // : this.props.toggleDrawer ?
                                        //     (
                                        //         <Box>
                                        //             <Button text={this.props.t("catalogs.catalogs.modify")} disabled={this.props.openForm ? this.props.openForm : false} bgColor={colors.green.regular} onClick={this.props.toggleDrawer} arrow={'right'} shadowcolor={colors.green.darker} />
                                        //         </Box>
                                        //     )
                                        : null
                                    }
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }

    goTo = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { productId: id }
        });
    };
}
export default withTranslation()(withRouter(CardContentDetails));