
export function deleteLocalStorage() {
    return new Promise((resolve, reject) => {
        let localStoragesArray = [
            'AUTH_TOKEN',
            'AUTH_TOKEN_EXPIRY',
            'AUTH_USER',
            'allAssets',
            'allCatalogsTypes',
            'GET_ALL_CHANNELS',
            'GET_EAV_TYPES',
            'GET_ASSET_CATEGORIES',
            'GET_CURRENCIES',
            'GET_RATE_GROUPS_LIGHT',
            'GET_ASSETS_CURSOR',
            'GET_RETAILERS_CURSOR',
            'GET_COMPLETENESSGROUPS',
            'GET_SELECTIONS',
            'GET_NB_ATTR_IMAGES',
            'ATTRIBUTE_TYPES',
            'ATTRIBUTE_GROUPS',
            'ATTRIBUTES',
            'LOCALES',
            'GUIDELINE',
            'ASSETS_FILTERS',
            'PIM_FILTERS',
            'CATALOGS_FILTERS',
            'ATTRIBUTES_LOADED',
            'GET_CATALOGS_CURSOR',
            'PRODUCTS_LISTING_FILTERS',
            "CONTENT_CATEGORY"
        ];

        for (let item of localStoragesArray) {
            localStorage.removeItem(item);
        }
        resolve();
    })
}
