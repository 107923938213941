import React from 'react';

import { Box, CircularProgress, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import { withTranslation } from 'react-i18next';
import Button from '../../../ui/button/Button';
import { ROUTE_PRODUCTS_BRANDS_DETAIL, ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER } from '../../../../js/constants/route-names';
import * as moment from "moment";
import StatusInfo from '../../../ui/status-info/StatusInfo';
import DeleteIcon from '@material-ui/icons/Delete';
import TextEllipsis from './TextEllipsis';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import OurLink from '../../../ui/link/Link';

const TypographyCustom=styled(Typography)`
    color: ${colors.sinfinNewMenu.normal};
    width: 150px;
`
const MenuCustom=styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`
const MenuItemCustom=styled(MenuItem)`
    color: ${colors.sinfinNewMenu.normal};
    svg{
        fill: ${colors.sinfinNewMenu.normal};
    }
`

const DividerCustom = styled(Divider)`
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    width: 100%;
`
const CircularProgressCustom = styled(CircularProgress)`
    color: ${props => props.value > 30 
            ? props.value > 65 
                ? colors.green.regular : "#f6d607"
            : colors.red.regular};
    margin-left: ${props => props.marginleft};
`
const BoxCustom = styled(Box)`
    color: ${props => props.statuscolor};
    svg circle{
        stroke: ${props => props.statuscolor};
    }
    div:nth-child(2) svg circle{
        stroke: #EDEEEE;
    }
    p{
        color: ${props => props.statuscolor};
    }
`;

function CardBrand(props){
    // console.log('CardDiscount', props)
    let data = props.data.node
    let percent = 0;
    percent = 100 - (data.productSourceProductStates?.totalCount*100/data.product?.totalCount);
    // console.log('percent', percent)
    return(
        <Box width={'100%'} height={'100%'} display={"flex"} flexDirection={"column"}>
            <Box padding={'16px'} display={"flex"} flexDirection={"column"} flex={1}>
                <ContainerImage height={210} width={'100%'} backgroundimage={data.media ? `${process.env.REACT_APP_API_ROOT}/medias/${data.media.filePath}` : ImgNotFound}>
                </ContainerImage>
                <Box mt={2} padding={"0 5px"} display={"flex"} flexDirection={"column"}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                        <Typography variant={"h2"} style={{paddingRight: 8, fontSize: 19}}>{data.libelle}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"} flex={1}>
                        <TextEllipsis text={data.description} maxLine={2} ellipsis={'...'} hasModal={false} />
                    </Box>
                    <DividerCustom />
                    <Box>
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Statut : </TypographyCustom>
                                    <StatusInfo width="auto" status={data.status}/>
                                </Box>
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Total de produits : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.product.totalCount}</span>
                                </Box>
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Reste à compléter : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.productSourceProductStates?.totalCount}</span>
                                </Box>
                            </Grid>
                            <Grid item>
                                <BoxCustom mr={2} position="relative" display="flex" justifyContent={"center"} statuscolor={colors.green.regular}>
                                    <CircularProgressCustom variant="determinate" thickness={2.5} value={percent} style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }} size={75}/>
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        disableShrink
                                        thickness={2.5}
                                        size={75}
                                    />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="h2" style={{color: colors.green.regular, fontWeight: 'bold'}}>
                                            {percent > 0 ? `${Math.round(percent)}%` : `N/A`}
                                        </Typography>
                                    </Box>
                                </BoxCustom>
                            </Grid>
                        </Grid>
                        
                    </Box>
                    <DividerCustom />
                    <Box mb={3}>
                        <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                            <TypographyCustom>Sites scrappés : </TypographyCustom>
                            <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                                {data.productSourceScraps?.edges?.map((value2, index) => (
                                    <span key={index} style={{fontWeight: "bold", marginRight: index + 1 < data.productSourceScraps?.edges.length ? 4 : 0}}>{value2.node.name}{index + 1 < data.productSourceScraps?.edges.length ? ',' : null}</span>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Grid container style={{flex: 1}} alignItems={"flex-end"}>
                    <Grid item xs={12}>
                        <OurLink 
                            style={{margin: 0, padding: "14px 32px", width: "100%"}} 
                            text={props.t('products.brands.seeBrand')}
                            stopPropagation={true}
                            link={ROUTE_PRODUCTS_BRANDS_DETAIL.replace(':id', data.id.replace('/api/product-sources/', ''))}
                        />
                    </Grid>
                </Grid>
                <MenuCustom
                    id="simple-menu"
                    anchorEl={props.anchorEl}
                    keepMounted
                    open={Boolean(props.anchorEl)}
                    onClose={props.handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {/* <MenuItemCustom onClick={props.handleDuplication}>
                        <QueueIcon style={{ marginRight: 4}}/>
                        {props.t("spread.active_assets.duplicate")}
                    </MenuItemCustom>
                    <li style={{padding: "0px 5px"}}><hr style={{width: "100%"}}/></li> */}
                    <MenuItemCustom onClick={props.handleDelete} style={{color: 'red'}}>
                        <DeleteIcon style={{ fill: 'red', marginRight: 4}}/>
                        {props.t("spread.active_assets.delete")}
                    </MenuItemCustom>
                </MenuCustom>
            </Box>
        </Box>
    );
}

export default withTranslation()(CardBrand);