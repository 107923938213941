import React, { useEffect, useState } from "react";
import Swiper from 'swiper';
import Div from '../shared/div';
import Img from '../shared/img';
import styled from 'styled-components';

const DivSliderMoments = styled(Div)`
    padding: 0 1rem;
    @media screen and (min-width: 1024px){
        padding : 0 22px;
    }
    @media screen and (min-width: 1450px){
        padding : 0 60px;
    }
    
    .titleBox{
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px){
            margin-bottom: 30px;
        }
        h2{
            font-size: 2.5625rem;
            line-height: 1.2;
            font-family: 'DINOffc-Bold', sans-serif;
            text-transform: uppercase;
            margin-top: 0;
            
            @media screen and (max-width: 767px){
                font-size: 1.875rem;
            }
        }
        a{
            color: #4D5769;
            font-size: 1rem;
            line-height: 1.2;
            text-decoration: none;
            font-family: 'Univers', sans-serif!important;
            cursor: pointer;
            text-decoration: underline;
            @media screen and (max-width: 1023px){
                text-decoration: underline;
                &:hover{
                    color: #000E28;
                }
            }
        }
        @media screen and (min-width: 1024px){
            a{
                font-size: 1.25rem;
            }
        }
    }

    .sliderMoments{
        padding-top: 50px;
        &> div:last-of-type{
            position: relative;
            padding-bottom: 50px;
        }
        div.swiper-wrapper{
            margin-bottom: 50px;
            max-height: 840px;
            height: 75vh;
        }
        .swiper-scrollbar{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }
    }

    .slideMoment,
    .slideMomentReadMore {
        display:flex;
        .overlaySlideMoment {
            background: transparent linear-gradient(180deg, rgba(0, 14, 40, 0) 0%, rgba(0, 14, 40, 1) 100%) 0% 0% no-repeat padding-box;
            opacity: 0.2;
            transition: all ease 500ms;
            width: calc(100% - 32px);
            height: calc(100% - 32px);
        }
        &> div {
            min-width: 90%;
            width: calc(100% - 32px);
            padding: 16px;
            position: absolute;
            &:first-child{
                width: calc(100% - 32px);
                height: calc(100% - 32px);
                overflow: hidden;
                img {
                    position: absolute;
                    visibility: visible;
                    min-width: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .descriptionContainer {
            position: absolute;
            width: calc(100% - 32px);
            &> div:first-child span {
                display: none;
            }
        }
    }
    .slideMomentReadMore {
        overflow: auto;
    }
    .descriptionContainer{
        h3{
            margin: 0;
            font-size: 2.5625rem;
            line-height: 1.2;
            font-family: 'DINOffc-Bold', sans-serif;
            margin-bottom: 4px;
            color: white;
        }
        h4{
            margin: 0;
            font-size: 1.4375rem;
            line-height: 1;
            font-family: 'DINOffc-Bold', sans-serif;
            margin-bottom: 4px;
            color: white;
            text-transform: uppercase;
        }
        p{
            margin: 0;
            font-size: 1rem;
            line-height: 1.2;
            font-family: 'DinRegular', sans-serif;
            color: rgb(128 135 148);
            button{
                background: none;
                border: 0;
                cursor: pointer;
                font-size: 100%;
                padding: 0;
                touch-action: manipulation;
                user-select: none;
                -webkit-appearance: none;
                color: rgb(23, 33, 38);
                font-family: 'DinRegular', sans-serif;
            }
        }
        span{
            font-size: 1rem;
            line-height: 1.2;
            font-family: 'Univers', sans-serif;
            color: white;
        }
    } 

    .slideMoment:hover .overlaySlideMoment,
    .slideMomentReadMore:hover .overlaySlideMoment {
        opacity: 1;
    }
    @media screen and (max-width: 1024px){
        .sliderMoments div.swiper-button-next-moments, .sliderMoments div.swiper-button-prev-moments,
        .slideMomentReadMore div.swiper-button-next-moments, .slideMomentReadMore div.swiper-button-prev-moments{
            top: 40%;
        }
    }
    @media screen and (max-width: 767px){
        .sliderMoments div.swiper-button-next-moments, .sliderMoments div.swiper-button-prev-moments,
        .slideMomentReadMore div.swiper-button-next-moments, .slideMomentReadMore div.swiper-button-prev-moments{
            top: 20%;
        }
        .descriptionMoment{
            p{
                font-size: 1.25rem;
            }
        }
    }
    @media screen and (min-width: 768px){
        .slideMoment .overlaySlideMoment,
        .slideMomentReadMore .overlaySlideMoment{
            opacity: 1;
        }
        .slideMoment > div,
        .slideMomentReadMore > div {
            min-width: 450px;
        }
        .descriptionMoment{
            height: auto;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: all ease 500ms;
        }

        .descriptionContainer{
            bottom: 0;
            h4{
                margin-bottom: 24px;
            }
            p{
                max-width: 1050px;
                width: 90%;
                color: white;
            }
            span{
                font-weight: bold;
                margin-top: 30px;
                display: block;
            }
        }

        
        .actionContainer{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            a{
                background: white;
                color: rgb(0 14 40);
                border: 0;
                padding: 16px 20px;
                margin-top: 12px;
                font-weight: bold;
                font-family: 'DINOffc-Bold', sans-serif;
                transition: all 200ms ease-out;
                text-decoration: none;
                &:hover{
                    background: rgb(0 29 81);
                    color: #fff;
                }
            }
        }
    }

    @media screen and (min-width: 1024px){
        .descriptionMoment{
            height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: all ease 500ms;
        }
        .slideMoment:hover .descriptionMoment,
        .slideMomentReadMore:hover .descriptionMoment{
            height: 170px;
        }
    }


    @media screen and (max-width: 767px){
        .slideMoment > div:first-child img,
        .slideMomentReadMore > div:first-child img {
            width: 100%;
        }
        .sliderMoments div.swiper-wrapper,
        .slideMomentReadMore div.swiper-wrapper{
            max-height: 430px;
        }
        .slideMoment,
        .slideMomentReadMore {
            height: 430px;
        }
        .slideMoment > div:first-of-type,
        .slideMomentReadMore > div:first-of-type {
            height: 230px;
        }
        .slideMoment .overlaySlideMoment,
        .slideMomentReadMore .overlaySlideMoment {
            height: 230px;
        }
        .slideMoment .descriptionContainer,
        .slideMomentReadMore .descriptionContainer {
            height: 100%;
        }
        .descriptionContainer{
            padding: 16px 0!important;
        }
        .descriptionContainer > div:last-child{
            position: absolute;
            top: 250px;
            padding: 16px 0!important;
        }
        .descriptionContainer p{
            font-family: 'Univers', sans-serif;
            color: rgb(0 14 40);
            padding-bottom: 14px;
        }
        .descriptionContainer span{
            display: none;
        }
        .slideMoment .descriptionContainer > div:first-child span,
        .slideMomentReadMore .descriptionContainer > div:first-child span{
            display: block;
        }
        .slideMoment .descriptionContainer > div:first-child,
        .slideMomentReadMore .descriptionContainer > div:first-child {
            position: absolute;
            bottom: 210px;
            padding: 0 16px;
        }
        .actionContainer a{
            margin-top: 16px;
            color: rgb(128 135 148);
            font-family: 'Univers', sans-serif;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    @media screen and (max-width:400px){
        .slideMoment > div:first-of-type,
        .slideMomentReadMore > div:first-of-type {
            height: 200px;
        }
        .slideMoment .overlaySlideMoment,
        .slideMomentReadMore .overlaySlideMoment {
            height: 200px;
        }
        .slideMoment .descriptionContainer > div:first-child,
        .slideMomentReadMore .descriptionContainer > div:first-child {
            position: absolute;
            bottom: 250px;
            padding: 0 16px;
        }
        .descriptionContainer > div:last-child{
            position: absolute;
            top: 230px;
            padding: 16px 0!important;
        }
    }
    @media screen and (max-width:360px){
        .slideMoment > div:first-of-type,
        .slideMomentReadMore > div:first-of-type {
            height: 180px;
        }
        .slideMoment .overlaySlideMoment,
        .slideMomentReadMore .overlaySlideMoment {
            height: 180px;
        }
        .slideMoment .descriptionContainer > div:first-child,
        .slideMomentReadMore .descriptionContainer > div:first-child {
            position: absolute;
            bottom: 270px;
            padding: 0 16px;
        }
        .descriptionContainer > div:last-child{
            position: absolute;
            top: 210px;
            padding: 8px 0!important;
        }
    }
`;

const SliderMoments = (props) => {
    const [hover, setHover] = useState(false);
    
    const [readMore, setReadMore] = useState(false);
    const handleReadMore = () => {
        setReadMore(!readMore);
    };

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    console.log()
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-moments', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-moments',
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-moments",
                prevEl: ".swiper-button-prev-moments",
            },
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                    spaceBetween: 8
                },
                500:{
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                }
            }
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-moments', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-moments',
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-moments",
                prevEl: ".swiper-button-prev-moments",
            },
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                    spaceBetween: 8
                },
                500:{
                    slidesPerView: 1.2,
                    spaceBetween: 16,
                }
            }
        });
    }, [props]);

    return (
        <DivSliderMoments style={{position: 'relative', background: data.inputs.backgroundColor.value}} className="slider-moments" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <div className="sliderMoments">
                <Div className={'titleBox'}>
                    <h2 className={'mainTitle'}>{data.blocks.title.inputs.value.value}</h2>
                </Div>
                <div>
                    <Div className="swiper-container swiper-container-moments">
                        <div className="swiper-wrapper">
                            {
                                data.blocks.slides.config.map((slide, index) => (
                                    <div className={`swiper-slide w100 ${readMore ? 'slideMomentReadMore' : 'slideMoment'}`}>
                                        <div>
                                            <Img
                                                alt={slide.blocks.imagePrincipale?.inputs?.alt?.value ?? "Illustration"}
                                                classes={'imageMoment'}
                                                src={
                                                    typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                        slide.blocks.imagePrincipale?.inputs.value.value 
                                                    : slide.blocks.imagePrincipale?.inputs.value?.value?.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                            />
                                        </div>
                                        <div className="overlaySlideMoment"></div>
                                        <div className="descriptionContainer">
                                            <div>
                                                <h3>{slide.blocks.title.inputs.value.value}</h3>
                                                <h4>{slide.blocks.subtitle.inputs.value.value}</h4>
                                                <span>{slide.blocks.tag.inputs.value.value}</span>
                                            </div>
                                            <div className="descriptionMoment">
                                                <p>{readMore ? slide.blocks.description.inputs.value.value : slide.blocks.description.inputs.value.value.slice(0, 240)}
                                                    { 
                                                        !readMore && slide.blocks.description.inputs.value.value.length > 240 ? 
                                                            (<>... <button onClick={handleReadMore}>Lire plus</button></>)
                                                        : readMore && slide.blocks.description.inputs.value.value.length > 240 ?
                                                            (<button onClick={handleReadMore}>Lire moins</button>)
                                                        : null
                                                    }
                                                </p>
                                                <div className="actionContainer">
                                                    <span>{slide.blocks.tag.inputs.value.value}</span>
                                                    <a href={slide.blocks.link.inputs.link?.value}>{slide.blocks.link.inputs.value?.value}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="swiper-scrollbar swiper-scrollbar-moments"></div>
                        <div className="swiper-button-prev swiper-button-prev-moments"></div>
                        <div className="swiper-button-next swiper-button-next-moments"></div>
                    </Div>
                </div>
            </div>
        </DivSliderMoments>
    );
};

export default SliderMoments;
