import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../shareable/types';

export const getDefaultText = (name, value) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value
        },
    },
})

export const getDefaultTextarea = (name, value, html) => {
    let inputs = {
        value: {
            type: html ? FormInputTypes.WYSIWYG : FormInputTypes.TEXTAREA,
            value
        }
    };
    
    inputs = {
        ...inputs,
    }

    return {
        type: EditableTypes.LONG_TEXT,
        name,
        id: uuidv4(),
        inputs
    }
};

export const getDefaultLink = (name, value) => ({
    type: EditableTypes.SHORT_TEXT,
    name,
    id: uuidv4(),
    inputs: {
        value: {
            type: FormInputTypes.TEXT,
            value
        },
    },
});