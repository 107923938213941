import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import { useDrop } from 'react-dnd';
import { CircularProgress } from '@material-ui/core';

// Who Are We

const OfferAboutSlide = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
        data.inputs['backgroundImage'].value = item.media.node;
        update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        minHeight: '700px',
        maxHeight: '880px',
        marginBottom: 20
    };

    return (
        <div
        className={`offer-template-scope`}  
        ref={drop} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        style={{
            ...style,
        }}
        >
            { !preview && props.children }
            <div className="container about" style={{height: "100%", display: "flex"}}>
                <div className="left-block">
                    <div className="breacrumb">
                        <Div data={data} className="category" preview={preview} component={id} block={'category'} inputCallback={inputCallback} nopadding={true} style={{backgroundColor: data?.inputs?.primaryColor.value, color: data?.inputs?.secondaryColor.value}} />
                        <Div data={data} className="sub-category" preview={preview} component={id} block={'sub_category'} inputCallback={inputCallback} nopadding={true} style={{color: data?.inputs?.primaryColor.value, backgroundColor: data?.inputs?.secondaryColor.value}} />
                    </div>
                    <div className="container_image">
                        {
                            typeof data.blocks.image.inputs.value?.value === 'string' || data.blocks.image.inputs.value?.value?.filePath ? (
                                <img className="image" onClick={() => inputCallback(id, 'image')} 
                                    src={
                                        typeof data.blocks.image.inputs.value?.value === 'string' ? 
                                            data.blocks.image.inputs.value.value : 
                                            data.blocks.image.inputs.value?.value.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                                : "https://via.placeholder.com/720x650/F0F7FA/C5DEEC"
                                    }
                                    alt="Image"
                                />
                            ) : <img onClick={() => inputCallback(id, 'image')} className="image" src="https://via.placeholder.com/720x650/F0F7FA/C5DEEC" alt="Image d'illustration" />
                        }
                    </div>
                </div>
                <div className="right-block" style={{position: 'relative', backgroundColor: data?.inputs?.primaryColor.value }} onMouseEnter={() => {setHover(false); setHoverBlock(true)}} onMouseLeave={() => {setHover(true);setHoverBlock(false)}}>
                    { !preview && hoverBlock && props.toolbarBlock }
                    <div className="agency_image text-center">
                        {
                            typeof data.blocks.descriptionBlock.blocks.logo.inputs.value?.value === 'string' || data.blocks.descriptionBlock.blocks.logo.inputs.value?.value?.filePath ? (
                                <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                                    src={
                                        typeof data.blocks.descriptionBlock.blocks.logo.inputs.value?.value === 'string' ? 
                                            data.blocks.descriptionBlock.blocks.logo.inputs.value.value : 
                                            data.blocks.descriptionBlock.blocks.logo.inputs.value?.value.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.descriptionBlock.blocks.logo.inputs.value.value.filePath}` 
                                                : "https://via.placeholder.com/500x250/F0F7FA/C5DEEC"
                                    }
                                    alt="Logo"
                                    style={{width: 'auto'}}
                                />
                            ) : <img onClick={() => inputCallback(id, 'logo')} className="logo" src="https://via.placeholder.com/500x250/F0F7FA/C5DEEC" alt="Logo" />
                        }
                    </div>
                    <Div data={data.blocks.descriptionBlock} className="agency_content" preview={preview} component={id} block={'agency_content'} inputCallback={inputCallback} nopadding={true}style={{color: data?.inputs?.secondaryColor.value}} />

                    <div className="agency_stats">
                        {
                            data.blocks.descriptionBlock.blocks.container_stat.config.map((config, index) => (
                                <div className="container_stat" id={index}>
                                    <div className="number">
                                        <CircularProgress variant="determinate" thickness={2.5} value={85} size={"100%"} style={{color: data?.inputs?.secondaryColor.value}}/>
                                        <span style={{color: data?.inputs?.secondaryColor.value}}>
                                            {
                                                config.blocks.value.inputs.preValue.value ? 
                                                ( <div className="sup">{config.blocks.value.inputs.preValue.value}</div> ) 
                                                : null
                                            }
                                            {config.blocks.value.inputs.value.value}
                                        </span>
                                    </div>
                                    <div className="text" style={{color: data?.inputs?.secondaryColor.value}}>
                                        {config.blocks.value.inputs.description.value}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

OfferAboutSlide.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default OfferAboutSlide;
