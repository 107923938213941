import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault} from '../../helpers';
import { getDefaultText, getDefaultTextarea, getDefaultLink} from '../../helpersBook';

export default {
    name: 'Section principale',
    blocks: {
        imageLeft: getImageDefault(null,'Image gauche Desktop'),
        imageLeftMobile: getImageDefault(null,'Image gauche Mobile'),
        imageTitre: getImageDefault(null, 'Image titre'),
        subtitle: getDefaultTextarea("Sous-titre", "Créez votre liste de cadeaux et<br> participez au grand tirage au sort LEGO<sup>®</sup>", true),
        button: getDefaultLink("Bouton", "Créer ma liste"),
        rulesText: getDefaultText("Règlement lien", "Voir le règlement du jeu"),
        bottomText: getDefaultText("Texte bas de section", "TENTEZ DE GAGNER <br><span>1 AN DE CADEAUX LEGO®</span><br>ET 15 LISTES DE CADEAUX LEGO", true),
    }
};