import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import Button from '../shared/button';
import Div from '../shared/div';
import styled from 'styled-components';
import { PictureTag } from "../../../../../utils/image";

const H2Custom = styled(H2)`
    max-width: 100%;
    width: 100%;
    &.like-h1{
        text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
        /* font-size: ${props => props.fontSize ? `${(10 * props.fontSize / 100) > 5 ? 10 * props.fontSize / 100 : 5}vw` : '9vw'}; */
        font-size: ${props => props.fontSize}px;
        /* @media screen and (min-width: 768px){
            font-size: ${props => props.fontSize ? `${(8 * props.fontSize / 100) > 3 ? 8 * props.fontSize / 100 : 3}vw` : '7.2vw'};
        }
        @media screen and (min-width: 1400px){
            font-size: ${props => props.fontSize ? `${6.8 * props.fontSize / 100}vw` : '6vw'};
        } */
    
        @media screen and (max-width: 767px){
            font-size: ${props => props.fontSizeMobile ? `${props.fontSizeMobile}px` : `${props.fontSize}px`};
        }
    }
    
`;
    
const H4Custom = styled(H4)`
    max-width: 100%;
    width: 100%;
    margin-bottom: ${props => props.marginBottom ? `${5 * props.marginBottom / 100}vw` : '2.8vw'};
    text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
    @media screen and (max-width: 767px){
        font-size: ${props => props.fontSizeMobile ? `${props.fontSizeMobile}px` : `${props.fontSize}px`};
    }
    p{
        font-weight: initial;
        margin: 0;
    }
`;

const CustomDiv = styled(Div)`
    &.hide-tablet{
        @media screen and (max-width: 1024px) and (min-width: 768px){
            display: none;
        }
    }
    &.hide-mobile{
        @media screen and (max-width: 767px){
            display: none;
        }
    }
    &.hide-desktop{
        @media screen and (min-width: 1001px){
            display: none;
        }
    }
`;

const CustomLayout = styled(Div)`
    @media screen and (max-width: 768px){
        max-width:calc(100% - 10px) !important;
        top: 50% !important;
        width: 100% !important;
    }
`;


const SliderTop = (props) => {
    const [hover, setHover] = useState(false);
    const vidRef = useRef(null);

    let {
        preview = false,
        id = null, 
        data = null,
        spread = false
    } = props;
    

    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];
    
    const getContentSlide = (slide) => {
        return(
            <CustomLayout 
                className={`${slide.blocks.configSlide?.inputs?.alignButton?.value ? 'row' : null} absolute `}
                style={{
                    top: slide.blocks.configSlide?.inputs?.positionVertical?.value  === 'center' || data.inputs?.positionVertical?.value === 'center' ? '50%' : slide.blocks.configSlide?.inputs?.positionVertical?.value === 'flex-start'  || data.inputs?.positionVertical?.value === 'flex-start' ? '5%' : 'inherit',
                    bottom: slide.blocks.configSlide?.inputs?.positionVertical?.value === 'flex-end' || data.inputs?.positionVertical?.value === 'flex-end' ? (slide.blocks.configSlide?.inputs?.positionVertical?.value !== 'center' || data.inputs?.positionHorizontal?.value !== 'center' ? '10%' : '15%') : 'inherit',
                    left: slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? '50%' : slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-start' || data.inputs?.positionHorizontal?.value === 'flex-start' ? slide.blocks.configSlide?.inputs?.alignButton?.value ? '3rem' : '5rem' : 'inherit',
                    right: slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-end' || data.inputs?.positionHorizontal?.value === 'flex-end' ? '5rem' : 'inherit',
                    transform: `translate(${slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? '-50%' : '0'}, ${slide.blocks.configSlide?.inputs?.positionVertical?.value === 'center' || data.inputs?.positionVertical?.value === 'center' ? '-50%' : '0'})`,
                    alignItems: slide.blocks.configSlide?.inputs?.positionHorizontal?.value || data.inputs?.positionHorizontal?.value ? data.inputs?.positionHorizontal?.value : null,
                    width: slide.blocks.configSlide?.inputs?.maxWidthContent?.value ? `${slide.blocks.configSlide?.inputs?.maxWidthContent?.value}%` : '100%',
                    maxWidth:'calc(100% - 10rem)',
                    display: 'flex',
                    flexDirection: slide.blocks.configSlide?.inputs?.alignButton?.value ? 'row' : 'column', 
                    gap:slide.blocks.configSlide?.inputs?.gap?.value
                }}
            >
                <div 
                    style={{
                        width: '100%',
                        paddingLeft: slide.blocks.configSlide?.inputs?.alignButton?.value ? '10px' : null,
                        paddingRight: slide.blocks.configSlide?.inputs?.alignButton?.value ? '10px' : null,
                    }}
                >
                    {
                        slide.blocks.ontitle?.inputs.value.value ? (
                            <H4Custom 
                                className="" 
                                color={slide.blocks.ontitle.inputs.color.value}
                                fontSize={slide.blocks.ontitle.inputs.fontSize?.value}
                                fontSizeMobile={slide.blocks.ontitle.inputs.fontSizeMobile?.value}
                                textTransform={slide.blocks.ontitle.inputs.textTransform?.value}
                                style={{
                                    textAlign: slide.blocks.configSlide?.inputs?.positionHorizontal?.value  === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? 'center' : slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-end' || data.inputs?.positionHorizontal?.value === 'flex-end' ? 'end' : 'start',
                                    // maxWidth: slide.blocks.configSlide?.inputs?.maxWidthContent?.value ? `${slide.blocks.configSlide?.inputs?.maxWidthContent?.value}%` : null,
                                    width: '100%',
                                    marginBottom: "initial"
                                }}
                                dangerouslySetInnerHTML={{__html: slide.blocks.ontitle?.inputs?.value?.value}}
                            />
                        ) : null
                    }
                    {
                        slide.blocks.title.inputs.value.value ? (
                            <H2Custom 
                                className="like-h1 full-width center" 
                                color={slide.blocks.title.inputs.color.value} 
                                fontSize={slide.blocks.title.inputs.fontSize?.value}
                                fontSizeMobile={slide.blocks.title.inputs.fontSizeMobile?.value}
                                textTransform={slide.blocks.title.inputs.textTransform?.value}
                                style={{
                                    textAlign: slide.blocks.configSlide?.inputs?.positionHorizontal?.value  === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? 'center' : slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-end' || data.inputs?.positionHorizontal?.value === 'flex-end' ? 'end' : 'start',
                                    // maxWidth: slide.blocks.configSlide?.inputs?.maxWidthContent?.value ? `${slide.blocks.configSlide?.inputs?.maxWidthContent?.value}%` : null,
                                    width: '100%'
                                }}
                                dangerouslySetInnerHTML={{__html: slide.blocks.title?.inputs?.value?.value}}
                            />
                        ) : null
                    }
                    {
                        slide.blocks.subtitle.inputs.value.value ? (
                            <H4Custom 
                                className="center" 
                                textTransform={slide.blocks.subtitle.inputs.textTransform?.value} 
                                color={slide.blocks.subtitle.inputs.color?.value} 
                                fontSize={slide.blocks.subtitle.inputs.fontSize?.value} 
                                fontSizeMobile={slide.blocks.subtitle.inputs.fontSizeMobile?.value}
                                style={{
                                    textAlign: slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? 'center' : slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-end' || data.inputs?.positionHorizontal?.value === 'flex-end' ? 'end' : 'start' ,
                                    // maxWidth: slide.blocks.configSlide?.inputs?.maxWidthContent?.value ? `${slide.blocks.configSlide?.inputs?.maxWidthContent?.value}%` : null,
                                    // fontSize:slide.blocks.subtitle.inputs.fontSize?.value?slide.blocks.subtitle.inputs.fontSize?.value:"16px",
                                }}
                                dangerouslySetInnerHTML={{__html: slide.blocks.subtitle?.inputs?.value?.value}}
                            />
                        ) : null
                    }
                </div>
                <div 
                    className="containerButton"
                    style={{
                        display: 'flex', 
                        justifyContent: slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'center' || data.inputs?.positionHorizontal?.value === 'center' ? 'center' : slide.blocks.configSlide?.inputs?.positionHorizontal?.value === 'flex-end' || data.inputs?.positionHorizontal?.value === 'flex-end' ? 'end' : 'start' ,
                        alignItems: 'center', 
                        marginTop: slide.blocks.configSlide?.inputs?.margin?.value || data.inputs?.margin?.value,
                        width: slide.blocks.configSlide?.inputs?.alignButton?.value ? 'auto' : '100%',
                        paddingLeft: slide.blocks.configSlide?.inputs?.alignButton?.value ? '10px' : null,
                        paddingRight: slide.blocks.configSlide?.inputs?.alignButton?.value ? '10px' : null,
                    }}
                >
                    {
                        slide.blocks.link.inputs.value.value &&
                            <Button 
                                color={slide.blocks.link.inputs.color.value}
                                fontSize={slide.blocks.link.inputs.fontSize?.value}
                                fontSizeMobile={slide.blocks.link.inputs.fontSizeMobile?.value}
                                backgroundColor={slide.blocks.link.inputs.backgroundColor.value}
                                borderWidth={slide.blocks.link.inputs.border?.inputs.borderWidth.value}
                                borderColor={slide.blocks.link.inputs.border?.inputs.borderColor.value}
                                borderRadius={slide.blocks.link.inputs.border?.inputs.borderRadius.value}
                            >
                                {console.log(slide.blocks.link.inputs.fontSize?.value, 'fontSize')}
                                {console.log(slide.blocks.link.inputs.fontSizeMobile?.value, 'fontSizeMobile')}
                                {slide.blocks.link.inputs.value.value}
                            </Button>
                    }
                    {
                        slide.blocks.link2?.inputs?.value?.value ? (
                            <Button 
                                color={slide.blocks.link2.inputs.color.value} 
                                fontSize={slide.blocks.link2.inputs.fontSize?.value}
                                fontSizeMobile={slide.blocks.link2.inputs.fontSizeMobile?.value}
                                backgroundColor={slide.blocks.link2.inputs.backgroundColor.value}
                                borderWidth={slide.blocks.link2.inputs.border?.inputs.borderWidth.value}
                                borderColor={slide.blocks.link2.inputs.border?.inputs.borderColor.value}
                                borderRadius={slide.blocks.link2.inputs.border?.inputs.borderRadius.value}
                                style={{marginLeft: 16}}
                            >
                                {slide.blocks.link2.inputs.value.value}
                            </Button>
                        ) : null
                    }
                </div>
            </CustomLayout>
        )
    }

    useEffect(() => {
        new Swiper('.swiper-container-top', {
            direction: 'horizontal',
            lazy: true,
            pagination: {
                el: '.swiper-pagination-top',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next-top',
                prevEl: '.swiper-button-prev-top',
            },
            slidesPerView: 1
        });
    }, []);

    useEffect(() => {
        new Swiper('.swiper-container-top', {
            direction: 'horizontal',
            lazy: true,
            pagination: {
                el: '.swiper-pagination-top',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next-top',
                prevEl: '.swiper-button-prev-top',
            },
            slidesPerView: 1
        });
    }, [props]);
    
    return (
        <Div style={{position: 'relative'}} className={`slider-top ${data.inputs.className.value?data.inputs.className.value:""}`} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <CustomDiv  className="swiper-container swiper-container-top hide-tablet hide-desktop" id={`swiper-${id}`} data-config-autoplay={data.inputs.autoMove?.value ?data.inputs.delay?.value * 1000:null}>
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => {
                            let image = slide?.blocks?.imageMobile?.inputs?.value?.value;

                            return (
                                <Div className="swiper-slide w100" key={index}>
                                    <a href={slide.blocks.link.inputs.link.value}>
                                        {
                                            VIDEOS.includes(image?.type) ? (
                                                <video style={{height:data.inputs.height.value,width:"100%"}} autoPlay={true} muted={true} loop={true} ref={vidRef} controls>
                                                    <source src={`${process.env.REACT_APP_MEDIAS}/${image?.filePath}`} type="video/mp4"/>
                                                </video>
                                            ) : (
                                                <PictureTag
                                                    media={image}
                                                    alt={slide.blocks.imageMobile.inputs?.alt?.value ?? "Illustration"}
                                                    spread={spread}
                                                    classes="w100"
                                                    isOptimised={data.inputs.optimisedImage?.value}
                                                    height={data.inputs.height.value}
                                                    objectPosition={slide?.blocks?.imageMobile?.inputs?.objectPosition?.value}
                                                />
                                            )
                                        }
                                        
                                        {getContentSlide(slide)}
                                    </a>
                                </Div>
                            )
                        })
                    }
                </div>
                {
                    data.blocks.slides.config.length > 1 ? (
                        <>
                            <div className="swiper-pagination swiper-pagination-top"></div>
                            <div className="swiper-button-prev swiper-button-prev-top"></div>
                            <div className="swiper-button-next swiper-button-next-top"></div>
                        </>
                    ) : null
                }
            </CustomDiv>
            <CustomDiv className="swiper-container swiper-container-top hide-mobile" data-config-autoplay={data.inputs.autoMove?.value ?data.inputs.delay?.value * 1000:null}>
                <div className="swiper-wrapper">
                {
                    data.blocks.slides.config.map((slide, index) => {
                        let image = slide?.blocks?.imageDesktop?.inputs?.value?.value;

                        return (
                            <Div className="swiper-slide w100" key={index}>
                                <a href={slide?.blocks?.link?.inputs?.link?.value}>
                                    {
                                        VIDEOS.includes(image?.type) ? (
                                            <video style={{height:data.inputs.height.value,width:"100%",objectFit:"unset"}} autoPlay={true} muted={true} loop={true} ref={vidRef} controls>
                                                <source src={`${process.env.REACT_APP_MEDIAS}/${image?.filePath}`} type="video/mp4"/>
                                            </video>
                                        ) : (
                                            <PictureTag
                                                media={image}
                                                alt={slide.blocks.imageDesktop.inputs?.alt?.value ?? "Illustration"}
                                                spread={spread}
                                                classes="w100"
                                                isOptimised={data.inputs.optimisedImage?.value}
                                                height={data.inputs.height.value}
                                                objectPosition={slide?.blocks?.imageDesktop?.inputs?.objectPosition?.value}
                                            />
                                        )
                                    }
                                    {getContentSlide(slide)}
                                </a>
                            </Div>
                        )
                    })
                }
                </div>
                {
                    data.blocks.slides.config.length > 1 ? (
                        <>
                            <div className="swiper-pagination swiper-pagination-top"></div>
                            <div className="swiper-button-prev swiper-button-prev-top"></div>
                            <div className="swiper-button-next swiper-button-next-top"></div>
                        </>
                    ) : null
                }
            </CustomDiv>
        </Div>
    );
};

export default SliderTop;