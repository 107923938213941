import { gql } from 'apollo-boost';

export const GET_DEMANDDATAS = gql`
{
  demandDatas{
    edges{
      node{
        id
        value
        demand{
          id
          code
          imageUrl
        }
        locale{
          id
          libelle
          code
        }
      }
    }
  }
}
`;

/*
{
  "value": "test",
  "demand": "/api/demands/1",
  "locale": "/api/locales/1"
}
 */
export const CREATE_DEMANDDATA = gql`
mutation CreateDemanddatas($value: String!, $demand: String!, $locale: String!){
  createDemandData(input:{value: $value, demand: $demand, locale: $locale}){
    demandData{
      id
      value
      demand{
        id
        code
        imageUrl
      }
      locale {
        id
        libelle
        code
      }
    }
  }
}
`;

/*
{
  "id": "/api/demand_datas/53",
  "value": "test2",
  "demand": "/api/demands/1",
  "locale": "/api/locales/1"
}
 */
export const UPDATE_DEMANDDATA = gql`
mutation CreateDemanddatas($id: ID!, $value: String!, $demand: String!, $locale: String!){
  updateDemandData(input:{id: $id, value: $value, demand: $demand, locale: $locale}){
    demandData{
      id
      value
      demand{
        id
        code
        imageUrl
      }
      locale {
        id
        libelle
        code
      }
    }
  }
}
`;

/*
{
  "id": "/api/demand_datas/53"
}
 */
export const DELETE_DEMANDDATA = gql`
mutation CreateDemanddatas($id: ID!){
  deleteDemandData(input:{id: $id}){
    demandData{
      id
    }
  }
}
`;

/*
{
  "id": "/api/demand_datas/28"
}
 */
export const GETBY_ID_DEMANDDATA = gql`
query GetByIdDemandData($id: ID!){
  demandData(id: $id){
    id
    value
    demand{
      id
      code
      imageUrl
    }
    locale{
      id
      libelle
      codex
    }
    demand{
      id
      code
      imageUrl
    }
    locale{
      id
      libelle
      code
    }
  }
}
`;

