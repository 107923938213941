import { Box, Modal, TextField, Typography } from "@material-ui/core"
import OurButton from "../../ui/button/Button"
import {React, useState } from "react"
import CloseIcon from '@material-ui/icons/Close';    
import styled from 'styled-components';    
import colors from "../../../config/theme/colors";
import request from "../../../builder/shareable/tools/Fetch"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../js/constants/action-types";
import { ALERT_SUCCESS } from "../../../js/constants/alert-types";
import IconeSynonyme from "../../../assets/pictos/ico-synonyme.svg"
import IconeClipboard from "../../../assets/pictos/ico-copier_coller.svg"

const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    color: ${colors.black.regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
    span{
        margin-left: 4px;
    }
`;


const Synonymous =(props)=>{
    const [open,setOpen]= useState(false)
    const [wordToSearch,setWordToSearch]= useState(null)
    const [wordList,setWordList]= useState([])
    const [loading,setLoading]= useState(false)
    const [error,setError]= useState(null)

    const handleOpen = ()=>{
        setOpen(!open)
    }

    const getWords = ()=>{
        setLoading(true)
        setError(null)
        if (!wordToSearch) {
            setError("Vous devez écrire un mot")
            setLoading(false)
        } else if(wordToSearch?.length < 3){
            setError("Mot trop court")
            setLoading(false)
        }else{
            const variables = {
                "word":  wordToSearch
            }
            request(`https://external-connectors.sinfin.dev/api/openai/synonyme`, 'post',variables)
            .then((result)=>{            
                setWordList(result?.synonymes)            
                setLoading(false)
            })
        }
    }

    return (
        <>
            <OurButton
                onClick={handleOpen}
                bgcolor={colors.black.regular}
                bgcolorhover={colors.black.lighter}
                icon={"dicoIcon"}
            >Synonyme</OurButton>
            <Modal
                open={open}
                onClose={handleOpen}
                style={{display:'flex',alignItems:'center',justifyContent:'center'}}                                                        
            >
                <Box
                    style={{
                        background:"white",
                        width:"50%",
                        padding:"30px 60px"
                    }}
                >
                    <BoxClose onClick={handleOpen} pb={2} style={{ cursor: 'pointer' }}>
                        <CloseIcon /> <span>Fermer</span>
                    </BoxClose>                   
                    <Typography style={{fontSize:33,color:colors.black.regular,padding:"20px 0",fontWeight:"bold"}}>Dictionnaire de synonymes</Typography>
                    <Typography>Ecrivez le mot principal afin de trouver des synonymes, et copier coller le.</Typography>                                     
                    <Box style={{marginTop:20}}>
                        <Typography style={{fontSize:14,color:colors.black.regular,paddingBottom:5,fontWeight:"bold"}}>Mot principal</Typography>
                        <Box
                            style={{
                                display:"flex",
                                gap:10
                            }}
                        >
                            <TextField
                                error={error? true:false}
                                variant="outlined"
                                fullWidth
                                value={wordToSearch}
                                onChange={(e)=>{setWordToSearch(e.target.value)}}     
                                autoFocus                           
                                style={{
                                    paddingBottom:0,
                                    paddingTop:0,
                                    height:"100%"
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        getWords();
                                    }
                                }}
                            />
                            <Box>
                                <OurButton
                                    onClick={getWords}
                                    onKeyPress={getWords}
                                    loading={loading}
                                    style={{
                                        margin:0,
                                        height:"100%"
                                    }}
                                >Rechercher</OurButton>
                            </Box>
                        </Box>
                        <Typography style={{color:error ? colors.red.regular:null}}>{error? error :"Tapez ici le mot principal"}</Typography>
                    </Box>
                    {
                        wordList?.length > 0?
                            <Box
                                style={{
                                    marginTop:30
                                }}
                            >
                                <Typography style={{color:colors.black.regular,fontWeight:"bold"}}>Liste des synonymes</Typography>  
                                <Box
                                    style={{
                                        display:"flex",
                                        flexWrap:"wrap",
                                        gap:10,
                                        marginTop:10
                                    }}
                                >
                                    {
                                        wordList.map((word,index)=>{
                                            return (
                                                <Box
                                                    style={{
                                                        backgroundColor : "#F0F9FD",                                                        
                                                        padding:'10px 20px',
                                                        cursor:"pointer",
                                                        fontWeight:"bold"
                                                    }}                                                    
                                                >
                                                    <CopyToClipboard text={word}
                                                      onCopy={() => props.snack(ALERT_SUCCESS, "Le mot a été copié !")}>     
                                                      <Box style={{display:"flex"}}>
                                                        <Typography style={{color:"#0273A5"}}>{word}</Typography>
                                                        <img src={IconeClipboard} alt='Icone copier/coller' style={{marginLeft:5}}/>
                                                      </Box>
                                                    </CopyToClipboard>                                                    
                                                </Box>
                                            )
                                        })
                                    }                              
                                </Box>
                            </Box>
                        :!wordList?
                            <Typography style={{marginTop:20}}>Aucun résultat</Typography>
                        :null
                    }
                </Box>
            </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups,
        locales: state.locales
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Synonymous))));