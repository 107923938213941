import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { SET_GUIDELINE } from '../../../../js/constants/action-types';
import { ROUTE_SETTINGS_GUIDELINE } from '../../../../js/constants/route-names';

import {
    GET_BRAND_GUIDELINE,
    GET_GUIDELINE_BY_ID,
    GET_SYSTEM_TYPOS,
    GET_MARKUPS,
    ADD_COLOR,
    ADD_TYPO,
    ADD_TYPO_DATA,
    ADD_GUIDELINE_DATA,
    UPDATE_GUIDELINE,
    UPDATE_GUIDELINE_DATA,
    EDIT_COLOR,
    EDIT_TYPO,
    EDIT_TYPO_DATA,
    DELETE_COLOR,
    DELETE_TYPO,
    DELETE_GUIDELINE,
} from "../../../../queries/brand_guideline";

import guidelineForm from './config/guideline';
import colors from '../../../../config/theme/colors';

import { Box, Grid } from '@material-ui/core';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardMedia from '../../../layouts/Card/cardContent/CardMedia';
import CardColor from '../../../layouts/Card/cardContent/CardColor';
import Typography from '../../../ui/typography/Typography';
import OurReturnLink from '../../../ui/link/ReturnLink';

import Pagination from '@material-ui/lab/Pagination';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import Button from '../../../ui/button/Button';

import Empty from '../../../../assets/pictos/empty-picto/empty_guidelines.png';
import pictoExternalLink from '../../../../assets/pictos/out.svg';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import TitleIcon from '@material-ui/icons/Title';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import slugify from 'slugify';
import styled from 'styled-components';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { checkRouting } from '../../../../js/utils/checkRouting';
import DialogModal from '../../../ui/dialog/DialogModal';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import OurTypography from '../../../ui/typography/Typography';
import { withTranslation } from 'react-i18next';
import CarouselCards from '../../../layouts/Carousel/CarouselCards';
import CardTypographies from '../../../layouts/Card/cardContent/CardTypographies';

const Link = styled.a`
    color: ${colors.blue.darker.hue300};
    &:hover{
        color: ${colors.black.regular};
    }
`;
const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    margin-bottom: 10px;
`;
const ContainerImage = styled(Box)`
    overflow: hidden;
    background-color: ${props => props.color ? props.color : colors.blue.lighter.hue900}; 
    background-image: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-position: center;
    background-repeat: no-repeat;
    height: 175px;
`;
const Logo = styled(Box)`
    background-color: white;
    background-image: url(${props => props.imagechanged ? props => props.backgroundimage : props.backgroundimage});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 170px;
    width: 170px;
    margin: auto;
`;
const BoxPart = styled(Box)`
    background: ${colors.grey.lighter.hue980};
    margin-bottom: 32px; 
`;
const DivTitle = styled.div`
    display: flex;
    align-items: center;
    padding: 32px;
    svg{
        margin-right: 12px;
    }
`;

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const GuidelineWrapper = styled(Grid)`
    & + .layout-wrapper {
        display: none;
    }
`;

class GuidelineUpdate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: this.props.locales[0].node.code,
            dataAddLayout: guidelineForm,
            openForm: false,
            openDialog: false,
            editForm: 'edit',
            listTypographySystems: null,
            listMarkups: null,
            listColors: [],
            tabState: 2,
            nbperpage: 4,
            page: 1,
            countPage: 0,
            pageDocument: 1,
            pageTypos: 1,
            countPageDocument: 0,
            countPageTypos: 0,
            searchValueColor: '',
            ready: false,
        };
    }

    handleMediaModal = (media, index, isNav = false) => {
        let modalData = {
            ...media.node,
            index: index
        }

        if (!isNav) {
            this.setState({
                openModal: !this.state.openModal,
            })
        }
        this.setState({
            modalData: modalData
        })
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value, changedLang: true });
        this.forceUpdate();
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value);
        this.forceUpdate();
    };

    setValue = (stateName, value) => {
        this.setState({
            [stateName]: value
        }, () => {
            if (stateName === 'listColors') {
                let countPage = this.state.countPage;
                this.setState({ countPage: Math.ceil(this.state.listColors.length / 4) }, () => {
                    if (countPage !== this.state.countPage) {
                        this.setState({ page: this.state.countPage });
                    }
                })
            }
            if (stateName === 'listDocument') {
                let countPageDocument = this.state.countPageDocument;
                this.setState({ countPageDocument: Math.ceil(this.state.listDocument.length / 4) }, () => {
                    if (countPageDocument !== this.state.countPageDocument) {
                        this.setState({ page: this.state.countPageDocument });
                    }
                })
            }
            if (stateName === 'listTypos') {
                let countPageTypos = this.state.countPageTypos;
                this.setState({ countPageTypos: Math.ceil(this.state.listTypos.length / 4) }, () => {
                    if (countPageTypos !== this.state.countPageTypos) {
                        this.setState({ page: this.state.countPageTypos });
                    }
                })
            }
        });
        this.forceUpdate()
    };

    handleToggleDrawer = () => {
        this.setState({ openForm: !this.state.openForm });
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    changePage = (event, page) => {
        let index = 0;
        if (page > 1) {
            index = (page * this.state.nbperpage) - this.state.nbperpage - 1
        }
        this.setState({
            page: page
        });
    };

    changePageDocument = (event, page) => {
        let index = 0;
        if (page > 1) {
            index = (page * this.state.nbperpage) - this.state.nbperpage - 1
        }
        this.setState({
            pageDocument: page
        });
    };

    changePageTypos = (event, page) => {
        let index = 0;
        if (page > 1) {
            index = (page * this.state.nbperpage) - this.state.nbperpage - 1
        }
        this.setState({
            pageTypos: page
        });
    };

    handleSetupForm = () => {
        let allAttr = this.state.attr.attributes.edges;
        let dataAddLayout = this.state.dataAddLayout
        dataAddLayout.formConfig.children[0].optionsInputs = [];

        let inputName = {
            type: 'text',
            label: 'Nom de la marque',
            value: '',
            translated: false,
            helper: {
                text: 'Nom de la marque',
                link: false,
            },
            required: true,
            stateName: 'guideline_name',
        };

        dataAddLayout.formConfig.children[0].optionsInputs.push(inputName)

        this.setState({
            dataAddLayout,
        });

        for (let attr of allAttr) {
            let identifier = attr.node.identifier;
            let childrenTab = 0;
            let label;
            let type;
            let value;
            if (attr.node.translation) {
                for (let trans of attr.node.translation.translationDatas.edges) {
                    if (trans.node.locale.code === this.state.currentLang) {
                        label = trans.node.value;
                    }
                }
            }
            else {
                label = identifier;
            }
            if (identifier.search(/image|logo/i) >= 0) {
                type = 'mediaPicker';
            }
            else if (identifier.search(/url|name/i) >= 0) {
                type = 'text';
            }
            else {
                type = 'text';
                if (identifier.search(/typo/i) >= 0) {
                    type = 'select';
                    value = this.state.listElements;
                }
            }
            let input = {
                type: type,
                label: label,
                value: value,
                translated: false,
                helper: {
                    text: label,
                    link: false,
                },
                required: false,
                stateName: attr.node.identifier,
                handleMediaPicker: type === 'mediaPicker' ? this.handleMediaPicker : null,
            };

            this.state.dataAddLayout.formConfig.children[childrenTab].optionsInputs.push(input);
        }
    };

    getAttributes = () => {
        let attrWithGroup = this.props.attributeGroups;
        for (let attrGroup of attrWithGroup) {
            if (attrGroup.node.identifier === "guideline") {
                this.setState({
                    attr: attrGroup.node
                }, () => {
                    this.handleSetupForm()
                });
                if (this.state.attr) {
                    let allAttr = this.state.attr.attributes.edges;
                    for (let attr of allAttr) {
                        let value = null;
                        for (let data of this.state.guideline.guidelineData.edges) {
                            if (data.node.attribute.id === attr.node.id) {
                                if (attr.node.attributeType.input === 'file') {
                                    value = data.node.media;
                                }
                                else {
                                    value = data.node.value;
                                }
                            }
                        }

                        this.setState({
                            [attr.node.identifier]: value,
                        });
                    }
                }
            }
        }

        this.setState({
            ready: true,
        });
    };

    handlerMutation = () => {
        this.props.startLoading();

        let variables = { "id": this.state.guideline.id, "libelle": this.state.guideline_name };
        let documents = [];

        if (this.state.listDocument && this.state.listDocument.length > 0) {
            for (let doc of this.state.listDocument) {

                if (doc.id || doc.node.id) {
                    documents.push(doc.id || doc.node.id);
                }
            }
        }

        if (documents.length > 0) {
            variables.documents = documents;
        }
        this.props.client.mutate({
            mutation: UPDATE_GUIDELINE,
            variables,
        }).then(result => {
            this.setState({
                idGuideline: result.data.updateGuideline.guideline.id
            }, async () => {
                await this.handlerSaveColors();
                await this.handlerSaveTypo();
                await this.handlerSaveGuidelineData();
                await this.updateGuidelineApp();
                this.handleToggleDrawer();
                this.props.stopLoading();
            })
        })
    };

    updateGuidelineApp = () => {
        return new Promise(async (resolve, reject) => {
            let dataGuideline = await this.props.client.query({ query: GET_BRAND_GUIDELINE, fetchPolicy: 'no-cache' });
            if (dataGuideline.data.guidelines.edges.length) {
                this.props.setGuideline(dataGuideline.data.guidelines.edges[0].node);
                localStorage.setItem('GUIDELINE', JSON.stringify(dataGuideline.data.guidelines.edges[0].node));
            }
            resolve()
        });
    }

    handlerSaveTypo = () => {
        for (let typo of this.state.listTypos) {
            if (typo.idTypo && typo.changed) {
                let variables = {
                    "id": typo.idTypo,
                    "identifier": typo.identifier,
                    "guideline": this.state.idGuideline,
                    "typographySystem": typo.id
                }
                this.props.client.mutate({
                    mutation: EDIT_TYPO,
                    variables,
                }).then(result => {
                    let i = 0;

                    for (let markup of this.state.listMarkups) {
                        let idMarkup = null;
                        for (let typoId of this.state.guideline.typographies.edges) {
                            if (typoId.node.id === typo.idTypo) {
                                for (let markupId of typoId.node.typographyDatas.edges) {
                                    if (markupId.node.markup.id === markup.node.id) {
                                        idMarkup = markupId.node.id;
                                    }
                                }
                            }
                        }

                        let variables = {
                            "id": idMarkup,
                            "typography": result.data.updateTypography.typography.id,
                            "markup": markup.node.id,
                            "px": typo.markup[i]
                        };

                        this.props.client.mutate({
                            mutation: EDIT_TYPO_DATA,
                            variables,
                        });

                        i++;
                    }
                })
            } else if (typo.changed) {
                let identifierTypo = `${typo.identifier}_${slugify(this.state.guideline_name, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })}`
                let variables = {
                    "identifier": identifierTypo,
                    "guideline": this.state.idGuideline,
                    "typographySystem": typo.id
                }
                this.props.client.mutate({
                    mutation: ADD_TYPO,
                    variables,
                }).then(result => {
                    let i = 0;
                    for (let markup of this.state.listMarkups) {
                        let variables = {
                            "typography": result.data.createTypography.typography.id,
                            "markup": markup.node.id,
                            "px": typo.markup[i]
                        }

                        this.props.client.mutate({
                            mutation: ADD_TYPO_DATA,
                            variables,
                        })

                        i++;
                    }
                })
            }
        }
    };

    handlerSaveColors = () => {
        return new Promise(async (resolve, reject) => {
            for (let color of this.state.listColors) {

                if (color.id && color.changed) {
                    let variables = {
                        "id": color.id,
                        "codeHexa": color.hex,
                        "codeR": color.rgb.r,
                        "codeG": color.rgb.g,
                        "codeB": color.rgb.b,
                        "guideline": this.state.idGuideline
                    }
                    variables.markups = color.markups;

                    this.props.client.mutate({
                        mutation: EDIT_COLOR,
                        variables,
                    })
                }
                else if (color.changed) {
                    let variables = {
                        "codeHexa": color.hex,
                        "codeR": color.rgb.r,
                        "codeG": color.rgb.g,
                        "codeB": color.rgb.b,
                        "guideline": this.state.idGuideline
                    }
                    variables.markups = color.markups;

                    this.props.client.mutate({
                        mutation: ADD_COLOR,
                        variables,
                    })
                }
            }
            resolve();
        });
    };

    handlerSaveGuidelineData = () => {
        return new Promise(async (resolve, reject) => {
            for (let attr of this.state.attr.attributes.edges) {
                if (this.state[attr.node.identifier]) {
                    let exist = false;
                    let idGuidelineData;
                    for (let guidelineData of this.state.guideline.guidelineData.edges) {
                        if (guidelineData.node.attribute.identifier === attr.node.identifier) {
                            exist = true;
                            idGuidelineData = guidelineData.node.id;
                        }
                    }
                    if (exist) {
                        let variables = {
                            "id": idGuidelineData,
                            "guideline": this.state.idGuideline,
                            "attribute": attr.node.id,
                            "locale": this.props.locales[0].node.id
                        }

                        if (attr.node.attributeType.input === 'file') {
                            variables.media = this.state[attr.node.identifier].id;
                        } else {
                            variables.value = this.state[attr.node.identifier];
                        }

                        this.props.client.mutate({
                            mutation: UPDATE_GUIDELINE_DATA,
                            variables,
                        })
                    } else {
                        let variables = {
                            "guideline": this.state.idGuideline,
                            "attribute": attr.node.id,
                            "locale": this.props.locales[0].node.id
                        }
                        if (attr.node.attributeType.input === 'file') {
                            variables.media = this.state[attr.node.identifier].id;
                        } else {
                            variables.value = this.state[attr.node.identifier];
                        }

                        this.props.client.mutate({
                            mutation: ADD_GUIDELINE_DATA,
                            variables,
                        })
                    }
                }
            }
            resolve();
        });
    };

    deleteColor = (color, index) => {
        if (color.id) {
            this.props.client.mutate({
                mutation: DELETE_COLOR,
                variables: { id: color.id },
            }).then(result => {
                let listColors = this.state.listColors;
                listColors.splice(index, 1);
                this.setState({ listColors });
            })
        } else {
            let listColors = this.state.listColors;
            listColors.splice(index, 1);
            this.setState({ listColors });
        }
        let countPage = this.state.countPage;

        this.setState({ countPage: Math.ceil(this.state.listColors.length / 4) }, () => {
            if (countPage !== this.state.countPage) {
                this.setState({ page: this.state.countPage });
            }
        })
    };

    deleteDocument = (document, index) => {
        let temporaryListDocument = this.state.listDocument;
        temporaryListDocument.splice(index, 1);
        this.setState({
            listDocument: temporaryListDocument
        }, () => {
            let variables = { "id": this.state.guideline.id }
            let documents = [];
            if (this.state.listDocument && this.state.listDocument.length > 0) {
                for (let doc of this.state.listDocument) {
                    if (doc.id || doc.node.id) {
                        documents.push(doc.id || doc.node.id);
                    }
                }
            }

            if (documents.length > 0) {
                variables.documents = documents;
            }
            this.props.client.mutate({
                mutation: UPDATE_GUIDELINE,
                variables,
            })
        });
    }

    deleteTypo = (typo, index) => {
        if (typo.idTypo) {
            this.props.client.mutate({
                mutation: DELETE_TYPO,
                variables: { id: typo.idTypo },
            }).then(result => {
                let listTypos = this.state.listTypos;
                listTypos.splice(index, 1);
                this.setState({ listTypos });
            })
        } else {
            let listTypos = this.state.listTypos;
            listTypos.splice(index, 1);
            this.setState({ listTypos });
        }
    };

    deleteMutation = () => {
        this.props.client.mutate({
            mutation: DELETE_GUIDELINE,
            variables: { id: this.state.guideline.id },
        }).then(result => {
            this.goTo(ROUTE_SETTINGS_GUIDELINE)
        });
    };

    componentDidMount = () => {
        checkRouting(this.props);
        this.props.client.query({
            query: GET_SYSTEM_TYPOS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({ listTypographySystems: result.data.typographySystems.edges })
        });

        this.props.client.query({
            query: GET_MARKUPS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listMarkups: result.data.markups.edges
            }, () => {
                this.props.client.query({
                    query: GET_GUIDELINE_BY_ID,
                    variables: { 'id': `/api/guidelines/${this.props.match.params.id}` },
                    fetchPolicy: 'no-cache'
                }).then(result => {
                    let guideline = result.data.guideline;
                    let colors = [];
                    let typographies = [];

                    for (let color of guideline.colors.edges) {
                        let markupArray = [];
                        for (let markup of color.node.markups.edges) {
                            markupArray.push(markup.node.id)
                            let temporaryMarkups = this.state.listMarkups;
                            let i = 0;
                            for (let markupSystem of temporaryMarkups) {
                                if (markup.node.id === markupSystem.node.id) {
                                    temporaryMarkups[i].node.checked = true;
                                }
                                i++;
                            }
                            this.setState({ listMarkups: temporaryMarkups })
                        }
                        colors.push({ id: color.node.id, hex: color.node.codeHexa, markups: markupArray })
                    }

                    for (let typo of guideline.typographies.edges) {
                        let typoVariable = { idTypo: typo.node.id, identifier: typo.node.identifier, id: typo.node.typographySystem.id, libelle: typo.node.typographySystem.libelle, value: typo.node.typographySystem.value };
                        let markupArray = [];
                        for (let markup of this.state.listMarkups) {
                            for (let typoMarkup of typo.node.typographyDatas.edges) {
                                if (markup.node.id === typoMarkup.node.markup.id) {
                                    markupArray.push(parseInt(typoMarkup.node.px))
                                }
                            }
                        }
                        typoVariable.markup = markupArray;
                        typographies.push(typoVariable);
                    }

                    this.setState({
                        guideline,
                        guideline_name: guideline.libelle,
                        listColors: colors,
                        countPage: Math.ceil(colors.length / 4),
                        countPageDocument: Math.ceil(guideline.documents.edges.length / 4),
                        countPageTypos: Math.ceil(typographies.length / 4),
                        listTypos: typographies,
                        listDocument: guideline.documents.edges,
                        guideline_logo: guideline?.guidelineData?.edges?.find(e => e?.node?.attribute?.identifier === 'guideline_logo')?.node?.media
                    }, () => {
                        this.getAttributes();
                    });
                });
            });
        });
    };

    render() {
        // TODO: Get website from guideline from back end (currently not available)
        const website = null
        // const website = this.state.guideline?.guidelineData?.edges?.find(e => e?.node?.attribute?.identifier === 'guideline_description')?.node?.value;

        return (
            <div style={{ width: this.state.openForm && this.props.windowWidth > 1000 ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                <TopPanel
                    title={"Identité de marque"}
                    subtitle={"Vue d’ensemble de la gestion de votre image de marque"}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    hasBorder={true}
                    textAdd={'+ Ajouter une identité'}
                    handlerAdd={() => { }}
                    buttonAvailable={this.state.ready && !this.state.openForm}
                />

                <Box>
                    <GuidelineWrapper container direction="column" justifyContent="center">
                        {
                            this.state.ready ? (
                                <Grid container style={{
                                    gap: '30px'
                                }}>
                                    <AccordionCustom expanded={true} title={
                                        <OurTypography colortext={colors.black.lighter}>Information lié à la marque</OurTypography>
                                    }>
                                        <Grid container style={{
                                            flexGrow: 1,
                                        }}>
                                            <Grid container spacing={this.state.openForm ? 3 : 7}>
                                                <Grid item xs={this.state.openForm ? 6 : 3}>
                                                    <Grid container justifyContent='center' alignItems='center' style={{
                                                        height: '100%',
                                                        border: `1px solid ${colors.grey.border}`
                                                    }}>
                                                        <Logo backgroundimage={this.state.guideline_logo ? `${process.env.REACT_APP_MEDIAS}/${this.state.guideline_logo.filePath}` : Empty} />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={this.state.openForm ? 6 : 9}>
                                                    <Grid container direction='column' justifyContent='space-between' style={{
                                                        padding: '10px 0px',
                                                        height: '100%',
                                                    }}>
                                                        <OurTypography fontweighttext={700} style={{
                                                            fontSize: 36,
                                                            marginBottom: 35
                                                        }}>{this.state.guideline.libelle}</OurTypography>

                                                        <Grid container style={{
                                                            gap: '8px',
                                                            marginBottom: 40
                                                        }}>
                                                            <Grid container>
                                                                <Grid item xs={this.state.openForm ? 5 : 2}>Langue :</Grid>
                                                                <Grid item xs={this.state.openForm ? 7 : 10} style={{ fontWeight: 700 }}>-</Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={this.state.openForm ? 5 : 2}>Couleurs :</Grid>
                                                                <Grid item xs={this.state.openForm ? 7 : 10} style={{ fontWeight: 700 }}>
                                                                    {this.state.guideline.colors.edges.map((color, index) => {
                                                                        return (
                                                                            <>
                                                                                <OurTypography fontweighttext={700} style={{ display: 'inline' }}>{color.node.codeHexa}</OurTypography>
                                                                                {index === this.state.guideline.colors.edges.length - 1 ? null : <OurTypography fontweighttext={700} style={{ display: 'inline' }}>, </OurTypography>}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={this.state.openForm ? 5 : 2}>Typographie :</Grid>
                                                                <Grid item xs={this.state.openForm ? 7 : 10} style={{ fontWeight: 700 }}>
                                                                    {this.state.listTypos?.length > 0 ? 
                                                                        this.state.listTypos.map((typo, index) => {
                                                                            return (
                                                                                <>
                                                                                    <OurTypography fontweighttext={700} style={{ display: 'inline' }}>{typo.libelle}</OurTypography>
                                                                                    {index === this.state.listTypos.length - 1 ? null : <OurTypography fontweighttext={700} style={{ display: 'inline' }}>, </OurTypography>}
                                                                                </>
                                                                            )
                                                                        })
                                                                    : <OurTypography fontweighttext={700} style={{ display: 'inline' }}>Aucune typographie associée</OurTypography>}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={this.state.openForm ? 5 : 2}>URL de production :</Grid>
                                                                <Grid item xs={this.state.openForm ? 7 : 10} style={{ fontWeight: 700 }}>
                                                                    <a href={website} target="_blank" rel="noreferrer" style={{
                                                                        color: colors.blue.darker.hue300,
                                                                    }}>{website ?? '-'}</a>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Box>
                                                            <Button
                                                                text={'Modifier'}
                                                                icon={'edit'}
                                                                color={colors.blue.darker.hue300}
                                                                colorHover={colors.white}
                                                                bgcolorhover={colors.blue.lighter.hue600}
                                                                bgcolor={colors.blue.lighter.hue900}
                                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                                stopPropagation={true}
                                                                onClick={() => { this.setState({ openForm: true, tabState: 0 }) }}
                                                                disabled={this.state.openForm || !this.state.ready}
                                                                style={{
                                                                    margin: 0,
                                                                    minWidth: 235,
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                {/* <ContainerImage
                                                    height={245}
                                                    style={{ backgroundSize: this.state.imageDescription ? 'cover' : 'contain' }}
                                                    color={this.state.listColors.length > 0 ? this.state.listColors[0].hex : null}
                                                    backgroundimage={this.state.imageDescription ? `${process.env.REACT_APP_MEDIAS}/${this.state.imageDescription.filePath}` : null}
                                                    imagechanged={this.state.imageDescription ? this.state.imageDescription.changed : null}
                                                />
                                                <Box style={{ marginBottom: 16 }}>
                                                    <Typography variant="h2" colortext={colors.black.regular} style={{ fontSize: 30, lineHeight: '40px', textAlign: 'center' }} component="div">
                                                        <Box fontWeight="bold">
                                                            {this.state.guideline_name ? this.state.guideline_name : 'Nom de la marque'}
                                                        </Box>
                                                    </Typography>
                                                    <Typography variant="body1" component="div" style={{ lineHeight: "32px", textAlign: 'center' }}>
                                                        {this.state.guideline_url ?
                                                            (
                                                                <Link href={this.state.guideline_url} target="_blank">
                                                                    {this.state.guideline_url}
                                                                    <img src={pictoExternalLink} alt="Voir le site" style={{ display: 'inline-block', height: 13, width: 13, marginLeft: 13 }} />
                                                                </Link>
                                                            )
                                                            : null
                                                        }
                                                    </Typography>
                                                    <Typography variant="body1" component="div" style={{ lineHeight: "32px", textAlign: 'center', color: colors.grey.regular }}>
                                                        {this.state.guideline_description ? this.state.guideline_description : 'Description'}
                                                    </Typography>
                                                </Box>
                                                
                                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
                                                    <Button disabled={this.state.openForm} shadowcolor={colors.green.darker} onClick={() => { this.setState({ openForm: true, tabState: 0 }) }}>Modifier la charte graphique</Button>
                                                </Box> */}
                                            </Grid>
                                        </Grid>
                                    </AccordionCustom>

                                    <AccordionCustom expanded={true} title={
                                        <OurTypography colortext={colors.black.lighter}>Logotypes ({[this.state.guideline_logo].length})</OurTypography>
                                    }>
                                        <Box width={'100%'} paddingLeft={'10px'}>
                                            <CarouselCards slidesNb={this.state.openForm ? 2 : 4}>
                                                {
                                                    this.state.guideline_logo ? (
                                                        <CardCustom style={{ width: this.state.openForm ? 'calc(50% - 22px)' : 'calc(25% - 22px)', margin: '0 10px', display: 'inline-block' }} cardContentStyle={{ padding: 0 }}>
                                                            <CardMedia data={this.state.guideline_logo} modal={this.handleMediaModal}></CardMedia>
                                                        </CardCustom>
                                                    ) : null
                                                }
                                            </CarouselCards>
                                        </Box>
                                    </AccordionCustom>

                                    <AccordionCustom expanded={true} title={
                                        <Grid container justifyContent='space-between'>
                                            <OurTypography colortext={colors.black.lighter}>Couleurs ({this.state.listColors?.length})</OurTypography>
                                            <OurTypography colortext={colors.blue.darker.hue300} fontweighttext={700} style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }} onClick={(e) => { 
                                                e.stopPropagation()
                                                this.setState({ openForm: true, tabState: 1 }) 
                                            }}>+ Ajouter une couleur</OurTypography>
                                        </Grid>
                                    }>
                                        <Box width={'100%'} paddingLeft={'10px'}>
                                            {
                                                this.state.listColors && this.state.listColors?.length > 0 ? (
                                                    <CarouselCards slidesNb={this.state.openForm ? 2 : 4}>
                                                        {
                                                            this.state.listColors.map((color, index) => (
                                                                this.state.nbperpage * this.state.page - this.state.nbperpage <= index && this.state.nbperpage * this.state.page > index ? (
                                                                    <CardCustom style={{ margin: 10, display: 'inline-block' }} cardContentStyle={{ padding: 0 }} key={`card-color-${index}`}>
                                                                        <CardColor 
                                                                            color={color.hex ? color.hex : null} 
                                                                            listMarkups={this.state.listMarkups} 
                                                                            markups={color.markups} 
                                                                            handlerEdit={() => { this.setState({ openForm: true, tabState: 1 }) }}
                                                                            handlerDelete={() => { this.deleteColor(color, index) }}
                                                                        />
                                                                    </CardCustom>
                                                                ) : null
                                                            ))
                                                        }
                                                    </CarouselCards>
                                                ) : null
                                            }
                                        </Box>
                                    </AccordionCustom>

                                    <AccordionCustom expanded={true} title={
                                        <Grid container justifyContent='space-between'>
                                            <OurTypography colortext={colors.black.lighter}>Typographie ({this.state.listTypos?.length})</OurTypography>
                                            <OurTypography colortext={colors.blue.darker.hue300} fontweighttext={700} style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }} onClick={(e) => { 
                                                e.stopPropagation()
                                                this.setState({ openForm: true, tabState: 2 })
                                            }}>+ Ajouter une typographie</OurTypography>
                                        </Grid>
                                    }>
                                        <Box width={'100%'} paddingLeft={'10px'}>
                                            <CarouselCards slidesNb={this.state.openForm ? 2 : 4}>
                                                {
                                                    this.state.listTypos.map((typo, index) => (
                                                        this.state.nbperpage * this.state.pageTypos - this.state.nbperpage <= index && this.state.nbperpage * this.state.pageTypos > index ? (
                                                            <CardCustom style={{ margin: '10px', display: 'inline-block' }} cardContentStyle={{ padding: 0 }} key={`card-typo-${index}`}>
                                                                <CardTypographies
                                                                    typo={typo}
                                                                    listMarkups={this.state.listMarkups}
                                                                    handlerEdit={() => { this.setState({ openForm: true, tabState: 2 }) }}
                                                                    handlerDelete={() => { this.deleteTypo(typo, index) }}
                                                                ></CardTypographies>
                                                            </CardCustom>
                                                        ) : null
                                                    ))
                                                }
                                            </CarouselCards>
                                        </Box>
                                    </AccordionCustom>

                                    {/* <AccordionCustom expanded={true} title={'Liste de documents (1)'}>
                                        {
                                            this.state.listDocument && this.state.listDocument?.length > 0 && (this.state.listDocument?.length >= 1 && this.state.listDocument[0].id !== "") ? (
                                                <BoxPart>
                                                    <DivTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body1" component="div" style={{ lineHeight: "32px", textAlign: 'center', color: colors.black.regular, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <PermMediaIcon style={{ color: colors.black.regular }} />
                                                            <Box fontWeight="bold">
                                                                {this.state.listDocument?.length === 1 || this.state.listDocument?.length === 0 ? 'Document' : 'Documents'} | <span style={{ color: colors.grey.lighter.hue600, fontWeight: 'initial' }}>{this.state.listDocument?.length} {this.state.listDocument?.length === 1 || this.state.listDocument?.length === 0 ? 'Ressource' : 'Ressources'}</span>
                                                            </Box>
                                                        </Typography>
                                                        <Box>
                                                            <Button disabled={this.state.openForm} shadowcolor={colors.green.darker} onClick={() => { this.setState({ openForm: true, tabState: 3 }) }}>Ajouter un document</Button>
                                                        </Box>
                                                    </DivTitle>
                                                    <div style={{ padding: 16 }}>
                                                        {
                                                            this.state.listDocument.map((document, index) => (
                                                                this.state.nbperpage * this.state.pageDocument - this.state.nbperpage <= index && this.state.nbperpage * this.state.pageDocument > index ? (
                                                                    document.id !== '' ? (
                                                                        <CardCustom style={{ width: this.state.openForm ? 'calc(50% - 22px)' : 'calc(25% - 22px)' && this.props.windowWidth > 1000 ? 'calc(25% - 22px)' : 'calc(50% - 22px)', margin: 10, display: 'inline-block' }} cardContentStyle={{ padding: 8 }} key={`card-documents-${index}`}>
                                                                            <CardMedia media={document} modal={this.handleMediaModal}></CardMedia>
                                                                            <Box style={{ padding: '0 16px 4px', margin: 'auto' }}>
                                                                                <Button disabled={this.state.openForm} onClick={() => this.deleteDocument(document, index)} bgcolor={colors.white} bgcolorhover={colors.white} color={colors.red.regular} colorhover={colors.red.darker} fontsize={12} style={{ width: '100%', margin: 0 }}>
                                                                                    <HighlightOffIcon style={{ fontSize: '1rem', marginRight: 5 }} />
                                                                                    Supprimer
                                                                                </Button>
                                                                            </Box>
                                                                        </CardCustom>
                                                                    ) : null
                                                                ) : null
                                                            ))
                                                        }
                                                        {
                                                            this.state.listDocument.length > 4 ? (
                                                                <PaginationCustom onChange={(event, page) => { this.changePageDocument(event, page) }} page={this.state.pageDocument} count={this.state.countPageDocument} color="primary" />
                                                            ) : null
                                                        }
                                                    </div>
                                                </BoxPart>
                                            ) : null
                                        }
                                    </AccordionCustom> */}

                                </Grid>
                            ) : <PageLoader />
                        }
                    </GuidelineWrapper>
                </Box>

                <LayoutBuilder
                    isProtected={true}
                    scrollable={true}
                    isSublayout={true}
                    icomoon={"icon-dashboard"}
                    opened={this.state.openForm}
                    forClose={() => { this.handleToggleDrawer() }}
                    dataLayout={this.state.dataAddLayout}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    handlerMutation={this.handlerMutation}
                    currentLang={this.state.currentLang}
                    hideInput={this.state.inputHidden}
                    validateButton={true}
                    drawerWidth={this.props.drawerWidth}
                    deleteMutation={this.handleToggleDialog}
                    deleteText={'Supprimer la charte graphique'}
                    deleteButton={this.state.editForm}
                />

                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer cette charte graphique ?'}
                    primaryAction={this.deleteMutation}
                    secondaryAction={this.handleToggleDialog}
                    windowWidth={this.props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez cette charte graphique celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(GuidelineUpdate))));
