import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function userGroupsEdit(action='add') {
    let generalInputs   = [
        {
            required : true,
            type : 'text',
            label : 'Identifiant du groupe',
            stateName : 'code',
            helper: {
                text: 'Identifiant du groupe d\'utilisateurs',
                link: false,
            },
        },
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un groupe d\'utilisateur':'Modifier un groupe d\'utilisateur',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre groupe`,
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: action === 'add'? 'Créer ce groupe' : 'Modifier ce groupe',
        formConfig: {
            type: FORM,
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}