import {MEDIAPICKER} from '../../../../js/constants/form-types';
import Button from '../../button/Button';
import MediaPicker from '../../../../builder/core/components/builder/MediaSelector';

export default function mediaPicker(onSelect= () => console.log('bug media picker'), stateName, xs = 3, handleOpenMediaPicker = () => {},translated=false, allowedTypes, multiMedias) {
    return {
        titleForm:'Sélectionner un média',
        subTitleForm: 'Sélectionner un média',
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Ajouter le post',
        formConfig: {
            type                    : MEDIAPICKER,
            component               : MediaPicker,
            onSelect                : onSelect,
            stateName               : stateName,
            xs                      : xs,
            handleOpenMediaPicker   : handleOpenMediaPicker,
            translated              : translated,
            allowedTypes            : allowedTypes,
            multiMedias             : multiMedias
        }
    };
}