import React from 'react';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { eventService } from '../../../../../js/services/event.service';
import { CATALOGS, CATALOGS_CATALOGS, VIEW, CREATE } from '../../../../../js/constants/constant-rights';
import { ROUTE_HOME } from '../../../../../js/constants/route-names';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../../js/constants/alert-types';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { GET_ATTR_GROUPE_LIGHT, GET_ATTRIBUTES_BY_GROUPE } from '../../../../../queries/attributes';
import { GET_RESTAURANT, ADD_RESTAURANT } from '../../../../../queries/restaurants';
import { CREATE_RESTAURANTDATA } from '../../../../../queries/restaurantdatas';
import { ALLOWED } from '../../../../../js/constants/medias-types';
import CardCustom from '../../../../layouts/Card/CardCustom';
import CardRestaurant from '../../../../layouts/Card/cardContent/CardRestaurant';
import TopPanel from '../../../../layouts/TopPanel/TopPanel';
import EmptyCard from "../../../../ui/empty-card/EmptyCard";
import EmptyCatalogue from '../../../../../assets/pictos/empty-picto/empty_catalogs.png';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import LayoutBuilder from '../../../../ui/form/LayoutFormBuilder';
import restaurantAdd from './config/restaurantAdd.config';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const ContainerCatalogs = styled(Grid)`
    margin-top: 1.5rem;
`;

class ListRestaurants extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEmpty: false,
            openForm: false,
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            noResult: false,
            restaurantAdd: restaurantAdd,
            userDefault: false,
        };
    }

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            });
        }
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value }, () => {
            eventService.fire();
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        let value = null
        if (custom && !evt) {
            value = custom;
        }
        else {
            value = evt?.target?.value ?? evt;
        }
        this.setValue(stateName, value, translated);
    };


    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, null, selected, null);
    };


    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handlerMutation = async () => {
        this.props.startLoading();
        if (this.hasErrors('firstForm')) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            this.props.stopLoading();
            return eventService.fire();
        }
        let variables = {
            name: this.state.restaurant_name,
            userPem: this.state.userDefault
        };
        this.props.client.mutate({
            mutation: ADD_RESTAURANT,
            variables,
        }).then(result => {
            this.setState({
                newRestaurant: result.data.createRestaurant.restaurant
            }, () => {
                this.props.snack(ALERT_SUCCESS, 'Restaurant ajouté avec succès');
                let i = 0;
                for (let attribute of this.state.listAttributes) {
                    i++;
                    if (attribute.node.status && attribute.node.identifier !== 'restaurant_name' && attribute.node.identifier !== 'restaurant_identifier') {
                        let typeInput = '';
                        let isEmail = false;
                        switch (attribute.node.attributeType.input) {
                            case 'textarea':
                                typeInput = 'textarea';
                                break;
                            case 'number':
                            case 'decimal':
                                typeInput = 'decimal';
                                break;
                            case 'text':
                                typeInput = 'text';
                                break;
                            case 'mail':
                                typeInput = 'text';
                                isEmail = true;
                                break;
                            case 'link':
                                typeInput = 'text';
                                break;
                            case 'select':
                                typeInput = 'select';
                                break;
                            case 'image':
                                typeInput = 'mediaPicker';
                                break;
                            case 'file':
                                typeInput = 'file';
                                break;
                            case 'date':
                                typeInput = 'date';
                                break;
                            default: typeInput = null;
                        }

                        let variables = {
                            restaurant: this.state.newRestaurant.id,
                            attribute: attribute.node.id,
                        }
                        if (typeInput === 'mediaPicker' && this.state[attribute.node.identifier]?.id) {
                            variables.media = this.state[attribute.node.identifier].id;
                        }
                        else if (typeInput === 'select') {
                            variables.attributeOption = this.state[attribute.node.identifier];
                        }
                        else if (this.state[attribute.node.identifier]) {
                            variables.value = this.state[attribute.node.identifier];
                        }
                        if (variables.value || variables.attributeOption || variables.media) {
                            this.props.client.mutate({
                                mutation: CREATE_RESTAURANTDATA,
                                variables
                            }).then(result => {
                                if (i === this.state.listAttributes.length) {
                                    this.setState({ openForm: false });
                                    this.handleGetRestaurants();
                                    this.props.stopLoading();
                                }
                            })
                        }
                        else if (i === this.state.listAttributes.length) {
                            this.setState({ openForm: false });
                            this.handleGetRestaurants();
                            this.props.stopLoading();
                        }
                    }
                }
            });
        });
    };

    handleGetRestaurants = async () => {
        this.props.startLoading();

        this.props.client.query({
            query: GET_RESTAURANT,
            fetchPolicy: 'no-cache'
        }).then(async result => {
            this.setState({
                listRestaurants: result.data.restaurants.edges,
                ready: true,
            })
            this.props.stopLoading();
        });
    };

    handleGetAttributes = () => {
        this.props.client.query({
            query: GET_ATTR_GROUPE_LIGHT,
            fetchPolicy: 'no-cache'
        }).then(async result => {
            let idGroup = result.data.attributeGroups.edges.find(item => item.node.identifier === "restaurant")?.node.id || null;
            if (idGroup) {
                this.setState({
                    idGroup
                }, () => {
                    this.props.client.query({
                        query: GET_ATTRIBUTES_BY_GROUPE,
                        fetchPolicy: 'no-cache',
                        variables: { id: this.state.idGroup }
                    }).then(async result => {
                        let restaurantAdd = this.state.restaurantAdd;
                        restaurantAdd.formConfig.children[0].optionsInputs = [];

                        let switchUserInput = {
                            type: 'switch',
                            label: 'Utilisateurs par défaut',
                            helper: {
                                label: 'Utilisateurs par défaut',
                                text: 'Choississez d\'utiliser un utilisateur par défaut',
                                link: false,
                            },
                            required: true,
                            stateName: 'userDefault',
                            value: [
                                {
                                    value: true,
                                    label: 'Oui',
                                },
                                {
                                    value: false,
                                    label: 'Non',
                                },
                            ]
                        };



                        this.setState({
                            listAttributes: result.data.attributeGroup.attributes.edges
                        }, () => {
                            let restaurantAdd = this.state.restaurantAdd;
                            restaurantAdd.formConfig.children[0].optionsInputs.push(switchUserInput);
                            this.setState({ restaurantAdd }, () => {
                                for (let attribute of this.state.listAttributes) {
                                    if (attribute.node.status && attribute.node.identifier !== 'restaurant_identifier') {
                                        let isEmail = false;
                                        const defaultLang = attribute.node.translation.translationDatas.edges[0];

                                        const langSelected = attribute.node.translation.translationDatas.edges.find(
                                            lang => lang.node.locale.code === this.state.currentLang
                                        );
                                        let typeInput = '';

                                        switch (attribute.node.attributeType.input) {
                                            case 'textarea':
                                                typeInput = 'textarea';
                                                break;
                                            case 'number':
                                            case 'decimal':
                                                typeInput = 'decimal';
                                                break;
                                            case 'text':
                                                typeInput = 'text';
                                                break;
                                            case 'mail':
                                                typeInput = 'text';
                                                isEmail = true;
                                                break;
                                            case 'link':
                                            case 'input':
                                                typeInput = 'text';
                                                break;
                                            case 'select':
                                                typeInput = 'select';
                                                break;
                                            case 'image':
                                                typeInput = 'mediaPicker';
                                                break;
                                            case 'file':
                                                typeInput = 'file';
                                                break;
                                            case 'date':
                                                typeInput = 'date';
                                                break;
                                            default: typeInput = null;
                                        }
                                        let input = {
                                            type: typeInput,
                                            label: langSelected?.node.value && defaultLang.node.value ? defaultLang.node.value : attribute.node.identifier,
                                            handleMediaPicker: typeInput === 'mediaPicker' || typeInput === 'file' ? this.handleMediaPicker : null,
                                            allowedTypes: typeInput === 'file' ? ALLOWED : null,
                                            translated: false,
                                            helper: {
                                                text: '',
                                                link: false,
                                            },
                                            isSystem: false,
                                            currentLang: this.state.currentLang,
                                            required: attribute.node.isRequired,
                                            stateName: attribute.node.identifier,
                                            email: isEmail,
                                            value: attribute.node.attributeOptions.edges.map((values) => {
                                                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                                                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                                                    lang => lang.node.locale.code === this.state.currentLang
                                                );

                                                return ({
                                                    value: values.node.id,
                                                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value
                                                });
                                            })
                                        };
                                        let restaurantAdd = this.state.restaurantAdd;
                                        restaurantAdd.formConfig.children[0].optionsInputs.push(input);
                                        this.setState({ restaurantAdd, [attribute.node.identifier]: null });
                                    }
                                }
                            })
                        })
                    })
                })
            }
        });

    };

    componentDidMount() {
        this.handleGetRestaurants();
        this.handleGetAttributes();
    }

    render() {
        return (
            <div style={{ width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2)))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', paddingBottom: 32 }}>
                <TopPanel
                    icomoon="icon-costs"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={"Gérer les restaurants"}
                    subtitle={"Gestion des restaurants (création / modification / suppression)"}
                    handlerAdd={() => this.handleToggleDrawer('openForm')}
                    textAdd={"Créer un restaurant"}
                    handlerImport={() => this.handleToggleDrawer('')} // set a custom form when data in back-end will be available
                    textImport={"Exporter les restaurants"}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    windowWidth={this.props.windowWidth}
                    hasBorder={true}
                />
                <ContainerCatalogs container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" spacing={2}>
                        {
                            this.state.isEmpty ? (<EmptyCard title={"Vous n'avez pas encore configuré de restaurant"} subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} textButton={"Créer un restaurant"} picto={EmptyCatalogue} onClick={() => this.handleToggleDrawer('openForm')} openForm={this.state.openForm} />)
                                : this.state.listRestaurants?.length > 0 ?
                                    this.state.listRestaurants.map((restaurant, index) =>
                                        <GridCustom item lg={4} md={6} xs={12} key={`listRestaurants${index}`}>
                                            <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0 0px 10px 0px'} >
                                                <CardRestaurant data={restaurant} textButton="Voir le restaurant" windowWidth={this.props.windowWidth} />
                                            </CardCustom>
                                        </GridCustom>
                                    )
                                    : !this.state.ready ?
                                        (<PageLoader />)
                                        : this.state.noResult ?
                                            (<EmptyCard title={"Aucun résultat pour cette recherche"} onClick={() => this.handleToggleDrawer('openForm')} textButton={"Ajouter un restaurant"} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                            :
                                            (<EmptyCard title={"Vous n'avez pas encore configuré de restaurant"} subtitle={"Cliquez sur le bouton ci-dessous pour en ajouter un"} textButton={"Ajouter un restaurant"} onClick={() => this.handleToggleDrawer('openForm')} picto={EmptyCatalogue} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                        }
                    </Grid>
                </ContainerCatalogs>

                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openForm}
                    validateButton={true}
                    handlerMutation={this.handlerMutation}
                    icomoon="icon-costs"
                    dataLayout={restaurantAdd}
                    allState={this.state}
                    stateCallback={this.handleInputChange}
                    errorCallback={this.handleFormError}
                    handleButtonGroupChange={this.handleButtonGroupChange}
                    forClose={() => this.handleToggleDrawer('openForm', true)}
                />
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };


}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListRestaurants)));