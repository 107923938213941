import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@material-ui/core';
import OurTypography from '../../../ui/typography/Typography';
import OurMenu from '../../../ui/menu/Menu';
import colors from '../../../../config/theme/colors';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { Edit } from '@material-ui/icons';

const BigImage=styled(Box)`
    cursor:pointer;
    position:relative;
    width:100%;
    padding: 14px;
`;
const ChipCustom = styled(Box)`
    border-radius: 0px;
    background-color: ${props => props.color ? props.color : '#FFFFFF'};
    display: block;
    height: 120px;
`;

export default function CardColor(props){
    const [color, setColor] = useState(props.color);
    const [codeColor, setCodeColor] = useState({});
    const [listMarkups, setListMarkups] = useState(props.listMarkups);
    const [markups, setMarkups] = useState(props.markups || []);
    
    const renderMarkup = () => {
        let string = '';
        if(markups && listMarkups){
            for(let lMarkup  of listMarkups){
                for(let markup of markups){
                    if(markup === lMarkup.node.id){
                        string += ' - '+ lMarkup.node.libelle+' ';
                    }
                }
            }
        }
        return string;
    }

    useEffect(() => {
        setColor(props.color)
        if (color && color.includes('#')){
            let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            let hex = color.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });
    
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            setCodeColor(result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null);
        }
    }, [color, props.color]); 

    useEffect(() => {
        setMarkups(props.markups)
    }, [props.markups]); 


    const editMenuOptions = [
        {
            label: 'Modifier',
            isDisabled: false,
            color: colors.blue.darker.hue300,
            icon: <Edit style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                props.handlerEdit()
                // props.toggleDuplicateDialog(props.data.node.id)
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handlerDelete()
            }
        }
    ]

    return (
        color ? (
            <BigImage>
                <Box className="bigImageTitle">
                    <ChipCustom style={{transition: 'all ease 500ms'}} color={color} />
                </Box>
                <Box style={{padding: '16px 0px 16px 5px'}}>
                    <Grid container justifyContent={true ? 'space-between' : 'flex-end'}>
                        {true ? (
                            <OurTypography fontweighttext={700} style={{
                                fontSize: '25px',
                            }}>-</OurTypography>
                        ) : null}

                        <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                    </Grid>
                    <Box mt={'20px'}>
                        <Grid container style={{
                            marginBottom: '10px',
                        }}>
                            <Grid item xs={6}><OurTypography style={{fontSize: '15px'}}>RVB :</OurTypography></Grid>
                            <Grid item xs={6}><OurTypography fontweighttext={700} style={{fontSize: '15px'}}>{codeColor.r}, {codeColor.g}, {codeColor.b}</OurTypography></Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}><OurTypography style={{fontSize: '15px'}}>HEX :</OurTypography></Grid>
                            <Grid item xs={6}><OurTypography fontweighttext={700} style={{fontSize: '15px'}}>{color}</OurTypography></Grid>
                        </Grid>
                    </Box>

                    {/* <div>
                        {
                            markups?.length > 0 ? (
                                <p>
                                    <strong>Assigné à : </strong> {renderMarkup()}
                                </p>
                            ) : null
                        }
                    </div> */}
                </Box>
            </BigImage>    
        ) : null
    )
}