import React from 'react';

import { Box, Grid } from '@material-ui/core';
import Typography from '../../ui/typography/Typography';

import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import NotFound from "../../../assets/images/not-found.png";

const CustomGrid = styled(Grid) `
    &:not(:last-child){
        border-bottom: 1px solid grey;
    }
    &:not(:first-child){
        padding-top: 16px;
    }
    padding-bottom: 16px;
`;

const ViewStat = styled(Box) `
    display: inline-block;
    border: 0.5px solid ${colors.green.regular};
    color: ${colors.green.regular};
    text-align: center;
    border-radius: 10px;
    span{
        padding: 4px 8px;
        font-weight: bold;
    }
`;

const VerticalSeparator = styled.div `
    height: 48px;
    border: 0.5px solid #D6D3DB;
`
const VerticalSeparatorColor = styled.div `
    width: 2px;
    height: 48px;
    background: ${props => `${props.colorstat}`};
    margin-left: 10px;
`
const BoxPicto = styled(Box) `
    display: inline-block;
    max-width: 48px;
    max-height: 48px;
    border-radius: 4px;
    background: ${props => `${props.backgroundcolor}`};
    img{
        width: 100%;
    }
`;


export default function Stats(props){
    const listStats = props.listStats;

    return(
        listStats.map((stat, index) => 
            <CustomGrid container direction="row" key={`StatInfos${index}`}>
                <Grid container direction="row" justifyContent={'space-between'}>
                    <Grid item xs={9}>
                        <Grid container alignItems={'center'}>
                            <BoxPicto mr={2}>
                                <img src={stat.img ? stat.img : NotFound}/>
                            </BoxPicto>
                            <Typography variant="h5" component="div" colortext={'#737A8E'}>
                                <Box fontWeight="bold" pb={0.5}>
                                    {stat.name}
                                </Box>
                                <ViewStat>
                                    <span>{stat.view} vues</span>
                                </ViewStat>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
                            <VerticalSeparator />
                            <Typography variant="body2" component="div" colortext={colors.blue.darker.hue300} responsivebreakpoint={1600} responsivecss={`font-size:12px;`}>
                                <Box ml={2} fontWeight="bold">
                                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(stat.price)}
                                </Box>
                            </Typography>
                            <VerticalSeparatorColor colorstat={stat.dataColor}/>
                        </Grid>
                    </Grid>
                </Grid>
            </CustomGrid>
        )
    );
}