import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as moment from 'moment';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import notificationsEdit from './config/notifications/notificationsEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement } from '../../../../js/utils/functions';
import request from '../../../../js/utils/fetch';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import { CREATE_APP_NOTIFICATION, CREATE_APP_USER_NOTIFICATION, DELETE_APP_NOTIFICATION, DELETE_APP_USER_NOTIFICATION, GET_APP_TOKENS, GET_NOTIFICATIONS, UPDATE_APP_NOTIFICATION } from '../../../../queries/notifications.gql';
import { withApollo } from 'react-apollo';
import Listing from '../../../layouts/Listing/Listing';
import { listMappers, listSettings, perPageOptions } from './config/notifications/listNotifs.config';
import DialogModal from '../../../ui/dialog/DialogModal';
import { Typography } from '@material-ui/core';
import { set } from 'lodash';
moment.locale('fr');

function EngineAssetsDetailsNotifications(props) {
    const [asset, setAsset] = React.useState(props.match.params?.id ? props.match.params?.id : null);
    const [ready, setReady] = React.useState(false);
    const [listGroups, setListGroups] = React.useState([]);
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [errors, setErrors] = React.useState({});
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [currentNotif, setCurrentNotif] = React.useState(null);
    const [appTokens, setAppTokens] = React.useState(null);
    const [appToken, setAppToken] = React.useState(null);
    const [reloadListing, setReloadListing] = React.useState(false);
    const [states, setStates] = React.useState({
        notificationTitle: null,
        notificationMessage: null,
        notificationLink: null,
        notificationScheduledAt: moment().format('YYYY-MM-DD HH:mm'),
        notificationGroups: [],
        openForm: false,
        formType: 'add'
    });


    useEffect(() => {
        checkRouting(props)
        getAppTokens()
        initGroups()
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, [])

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    const getAppTokens = async ()=>{
        try {
            const data = await props.client.query({
                query:GET_APP_TOKENS
            })
            setAppTokens(data.data.appTokens.edges.map(e=>e.node.id))
            setAppToken(data.data.appTokens.edges.find(e=>e.node.appToken === props.user.email).node.id)
        } catch (error) {
            console.log(error)
        }
    }

    const goTo = route => {
        props.history.push(route);
    };

    const initGroups = () => {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/application/usergroups`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        setListGroups(data.datas)
                        setReady(true)
                    }
                    resolve()
                }
            );
            resolve()
        })
    }

    const resetStates = () => {
        setStates({
            notificationTitle: null,
            notificationMessage: null,
            notificationLink: null,
            notificationScheduledAt: moment().format('YYYY-MM-DD HH:mm'),
            notificationGroups: [],
            openForm: false,
            formType: 'add'
        })
    };

    const handleToggleDrawer = (stateDrawer) => {
        let getState = { ...states };
        getState[stateDrawer] = !states[stateDrawer];
        setStates(getState)
    };

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const handlerMutation = async () => {
        if(!states.notificationTitle ){
            props.snack(ALERT_ERROR, 'Veuillez entrer un titre');
            return;
        }
        props.startLoading()
        try {
            const variables = {
                asset: `/api/assets/${asset}`,
                title: states.notificationTitle,
                message: states.notificationMessage,
                link: states.notificationLink,
                scheduledAt: moment(states.notificationScheduledAt).format('YYYY-MM-DD HH:mm'),
                appToken: appTokens,
                status:"pending"
            }
            const appNotif =  await props.client.mutate({
                mutation: CREATE_APP_NOTIFICATION,
                variables
            })
            await props.client.mutate({
                mutation: CREATE_APP_USER_NOTIFICATION,
                variables:{
                    appNotification: appNotif.data.createAppNotification.appNotification.id,
                    isRead: false,
                    appToken: appToken,
                }
            })
            props.snack(ALERT_SUCCESS, 'Notification ajoutée avec succès');
            setReloadListing(true)
        } catch (error) {
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la création de la notification');
            console.log(error)
        }
        resetStates();
        handleToggleDrawer('openForm', true);
        props.stopLoading();
    }

    const handlerEditMutation = async () => {
        props.startLoading()
        try {
            const variables = {
                id: currentNotif?.notifId,
                title: states.notificationTitle,
                message: states.notificationMessage,
                link: states.notificationLink,
                scheduledAt: states.notificationScheduledAt?moment(states.notificationScheduledAt).format('YYYY-MM-DD HH:mm'):moment(),
                asset: asset?`/api/assets/${asset}`:null,
            }
            await props.client.mutate({
                mutation: UPDATE_APP_NOTIFICATION,
                variables
            })
            props.snack(ALERT_SUCCESS, 'Notification modifiée avec succès');
            setReloadListing(true)
            resetStates();
            props.stopLoading()
        } catch (error) {
            console.log(error)
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la modification de la notification');
            props.stopLoading()
        }
    }

    const openDialodModal = (id,notifId)=>{
        setOpenDeleteModal(!openDeleteModal)
        setCurrentNotif({
                id,
                notifId
        })
    }

    const openFormEdit = (notif)=>{
        setStates({
            ...states,
            notificationTitle:notif?.title,
            notificationLink:notif?.link,
            notificationMessage:notif?.message,
            notificationScheduledAt:moment(notif?.scheduledAt).format('YYYY-MM-DD HH:mm'),
            openForm:!states.openForm,
            formType:'edit'
        })
        setCurrentNotif({
            notifId:notif?.notificationId
        })
    }

    const closeDialogModal = ()=>{
        setOpenDeleteModal(!openDeleteModal)
        setCurrentNotif(null)
    }

    const deleteNotif = async ()=>{
        props.startLoading()
        try {
            await props.client.mutate({
                mutation: DELETE_APP_USER_NOTIFICATION,
                variables:{
                    id: currentNotif?.id
                }
            })
            await props.client.mutate({
                mutation: DELETE_APP_NOTIFICATION,
                variables:{
                    id: currentNotif?.notifId
                }
            })
            props.snack(ALERT_SUCCESS, 'Notification supprimée avec succès');
            setReloadListing(true)
            props.stopLoading()
        } catch (error) {
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la suppression de la notification');
            console.log(error)
            props.stopLoading()
        }
    }

    return (
        <div>
            <TopPanel
                icomoon={"ico-notifications"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("spread_builder.notifications.title")}
                ready={ready}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                buttonAvailable={true}
                handlerThirdButton={() => { setStates({...states,openForm:!states.openForm}) }}
                textThirdButton={props.t("spread_builder.notifications.add")}
                currentLang={currentLang}
                handleLang={handleLang}
                locales={props.locales}
                hasBorder={true}
            />
            <div>
                <Listing
                    label={"Notifications"}
                    settings = {listSettings}
                    perPageOptions = {perPageOptions}
                    mappers = {listMappers}
                    pagination={true}
                    currentLang = {currentLang}
                    identifier = 'appUserNotifications'
                    viewsOptions = {{
                        current : 'card',
                        settings : ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                    }}
                    cardProps = {{
                        actionButton : {
                            delete:(e,id,notificationId)=> openDialodModal(id,notificationId),
                            edit:(notif)=> openFormEdit(notif),
                        }
                    }}
                    listingCallback = {()=>setReloadListing(false)}
                    reload={reloadListing}
                />
                {
                    ready ?
                        <>
                            {/* <EmptyCard title={props.t("spread_builder.notifications.message_title")} textButton={props.t("spread_builder.notifications.button")} onClick={() => { handleToggleDrawer("openForm") }} openForm={states.openForm} xsImg={states.openForm ? 4 : 2} /> */}
                            <LayoutBuilder
                                padding={"0"}
                                isSublayout={false}
                                icomoon="ico-notifications"
                                opened={states.openForm}
                                forClose={() => resetStates()}
                                handlerSetup={() => { }}
                                dataLayout={notificationsEdit('add', listGroups)}
                                drawerWidth={props.drawerWidth}
                                allState={states}
                                stateCallback={stateCallback}
                                errorCallback={handleFormError}
                                // handleButtonGroupChange={this.handleButtonGroupChange}
                                validateButton={true}
                                handlerMutation={states.formType === "add"?handlerMutation:handlerEditMutation}
                            // currentLang={currentLang}
                            // handleLang={this.handleLang}
                            />
                        </>
                        :
                        <PageLoader />
                }

            <DialogModal
                icon={true}
                type='delete'
                open={openDeleteModal}
                title={'Êtes-vous sûr de vouloir supprimer cette notification ?'}
                primaryAction={(event) => {event.stopPropagation();deleteNotif();closeDialogModal()}}
                secondaryAction={(event) => {event.stopPropagation();closeDialogModal()}}
                windowWidth={props.windowWidth}
            >
                <Typography variant="body2">Êtes-vous sûr de vouloir supprimer cette notification ? <strong>Cette action est irréversible</strong></Typography>
            </DialogModal>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsNotifications))));
