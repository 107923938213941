import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';

export default function catalogAdd(handleMediaPicker, inputAttributes) {
    let internalFilterAttributes = {};
    if(inputAttributes){    
        internalFilterAttributes.edges = inputAttributes.edges.filter(e => e.node.internalFilter);
    }

    var obj = {
        titleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? `Créer un catalogue` : "Créer un entrepôt",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour créer votre catalogue' : 'Veuillez compléter les champs ci-dessous pour créer votre entrepôt',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Créer',
        formConfig: {
            type: STEPPER,
            finalStep: {
                picto: CatalogFinal,
                title: 'Et voilà !',
                subtitle: 'Votre nouveau catalogue est prêt pour sa création',
                textButton: 'Créer'
            },
            children:[
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'mediaPicker',
                            label: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Entête de catalogue' : "Entête d'entrepôt",
                            helper: {
                                // text: 'Indiquez le nom',
                                link: false,
                            },
                            required: false,
                            stateName: 'imageCatalog',
                            translated: false,
                            fullwidth: true,
                            handleMediaPicker : handleMediaPicker
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Indiquez le nom du catalogue' : "Indiquez le nom de l'entrepôt",
                                link: false,
                            },
                            required: true,
                            stateName: 'catalogName',
                        },
                        {
                            type: 'text',
                            label: 'Identifiant',
                            helper: {
                                text: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Indiquez l\'identifiant du catalogue' : "Indiquez l'identifiant de l'entrepôt",
                                link: false,
                            },
                            required: true,
                            stateName: 'catalogIdentifier',
                        },
                        {
                            type: 'textarea',
                            label: 'Description',
                            helper: {
                                text: 'Entrez ici une description',
                                link: false,
                            },
                            required: false,
                            stateName: 'catalogDescription',
                        },
                        // catalogData.type.node.identifier !== "permanent"  ?
                        // {
                        //     type: 'date',
                        //     label: 'Date de début',
                        //     helper: {
                        //         text: 'Date de début',
                        //         link: false,
                        //     },
                        //     required: true,
                        //     stateName: 'catalogStartAt',
                        // } : '',
                        // catalogData.type.node.identifier !== "permanent"  ?
                        // {
                        //     type: 'date',
                        //     label: 'Date de fin',
                        //     helper: {
                        //         text: 'Date de fin',
                        //         link: false,
                        //     },
                        //     required: true,
                        //     stateName: 'catalogEndAt',
                        //     minDate: 'catalogStartAt',
                        // } : '',
                        {
                            type: 'buttonGroup',
                            label: 'État',
                            helper: {
                                label: 'État',
                                text: 'Indiquez l\'état',
                                link: false,
                            },
                            required: false,
                            stateName: 'catalogIsActive',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                        {
                            type: 'buttonGroup',
                            label: 'Autosync',
                            helper: {
                                label: 'Autosync',
                                text: 'Activer l\'autosync',
                                link: false,
                            },
                            required: false,
                            stateName: 'catalogAutosync',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                    ]
                },
                {
                    labelName: 'Filtres',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'rows',
                            translated: false,
                            required: false,
                            stateName: 'catalogFilters',
                            labelsNames:{
                                first: 'Attribut',
                                second: 'Opérateur',
                                third: 'Valeurs',
                            },
                            filter: true,
                            attributes: internalFilterAttributes,
                            operators:[
                                {
                                    id: '=',
                                    label: '='
                                },
                                {
                                    id: '!=',
                                    label: '!='
                                },
                                {
                                    id: 'IN',
                                    label: 'IN'
                                },
                                {
                                    id: 'NOT IN',
                                    label: 'NOT IN'
                                },
                                {
                                    id: 'LIKE',
                                    label: 'LIKE'
                                },
                                {
                                    id: 'empty',
                                    label: 'empty'
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    }
    return obj;
}