import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Grille d'images",
    blocks: {
        image1: {
            title: "Image 1",
            name: "Image 1",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/610x490"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        },
        image2: {
            title: "Image 2",
            name: "Image 2",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/610x245"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        },
        image3: {
            title: "Image 3",
            name: "Image 3",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/610x245"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        }
    }
};