import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import H1 from '../../../../shared/components/CustomH1';
import { useDrop } from 'react-dnd';
import { Avatar } from '@material-ui/core';

// Who Are We

const TripleBlock = (props) => {
    const [hover, setHover] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
        data.inputs['backgroundImage'].value = item.media.node;
        update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        marginBottom: 20
    };
    return (
        <div
        className={`offer-template-scope`}  
        ref={drop} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        style={{
            ...style,
        }}
        >
            { !preview && props.children }
            <div className="container">
                <div className="breacrumb">
                    <Div data={data} className="category" preview={preview} component={id} block={'category'} inputCallback={inputCallback} nopadding={true} style={{backgroundColor: data?.inputs?.primaryColor.value, color: data?.inputs?.secondaryColor.value}} />
                    <Div data={data} className="sub-category" preview={preview} component={id} block={'sub_category'} inputCallback={inputCallback} nopadding={true} style={{color: data?.inputs?.primaryColor.value, backgroundColor: data?.inputs?.secondaryColor.value}} />
                </div>
                <Div data={data} className="subTitle" preview={preview} component={id} block={'subTitle'} inputCallback={inputCallback} nopadding={true} style={{borderLeft: `3px solid ${data.blocks.subTitle.inputs.color.value}`}} />
                <div className="container-blocs type-blocs">
                    {
                        data.blocks.informations.config.map((information, index) => (
                            <div className="bloc-blocs">
                                <img src={
                                    typeof information.blocks.info.inputs.image.value === 'string' ? 
                                    information.blocks.info.inputs.image.value : 
                                    information.blocks.info.inputs.image.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${information.blocks.info.inputs.image.value?.filePath}` 
                                        : "https://via.placeholder.com/440/F0F7FA/C5DEEC"
                                    }
                                 />
                                <div className="bloc-title" style={{color: information.blocks.info.inputs.colorTitle.value}}>
                                    {information.blocks.info.inputs.title.value}
                                </div>
                                <div className="bloc-description">
                                    {information.blocks.info.inputs.description.value}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

TripleBlock.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default TripleBlock;