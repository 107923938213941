import React, { useEffect, useState, useRef } from "react";
import {
    useAnalyticsApi,
    useAuthorize,
    useDataChart,
    useSignOut,
    useViewSelector,
} from "react-use-analytics-api";
import ReactGA from 'react-ga';

export default function UseDataChartExample() {
    const { ready, gapi, authorized, error } = useAnalyticsApi();
    const [viewId, setViewId] = useState();
    const viewSelectorContainerId = "view-selector-container";

    useViewSelector(
        authorized ? gapi : undefined,
        viewSelectorContainerId,
        (viewId) => setViewId(viewId)
    );

    const query = {
        ids: viewId,
        "start-date": "28daysAgo",
        "end-date": "today",
        metrics: "ga:sessions",
        dimensions: "ga:date",
    };

    const chart = {
        container: "data-chart-container",
        type: "LINE",
        options: {
        title: "Sessions (28 Days)",
        },
    };

    useDataChart(authorized ? gapi : undefined, query, chart);

    // ReactGA.initialize('GA1.3.1581278723.1638892641', {
    //     debug: true,
    //     titleCase: false,
    //     gaOptions: {
    //         userId: 123,
    //         clientId: 'some-unique-id'
    //     }
    // });

    const authDiv = useRef(null);
    const [authorizeCalled, setAuthorizeCalled] = useState(false);
    const [clientId, setClientId] = useState('1581278723.1638892641'); // Test with a client id find in _ga cookie
    const hasAuthElements = authDiv.current && authDiv?.current?.children?.length > 0;

    const authorize = useAuthorize(gapi, {
        clientId,
        container: "authorize-container-id",
    });

    const signOut = useSignOut(gapi);

    useEffect(() => {
        if (ready && !error && !authorizeCalled) {
            authorize();
            setAuthorizeCalled(true);
        }
    }, [ready, error, authorizeCalled, authorize]);

    return (
        <div>
            {!ready && <div>Loading...</div>}
            {ready && (
                <div>
                    {authorized && (
                        <div>
                            <div style={{ marginTop: "30px" }}>
                                <div className="data-chart" id="data-chart-container" />
                            </div>
                        <div id={viewSelectorContainerId} />
                            <div>
                                <button onClick={() => signOut()}>Sign Out</button>
                            </div>
                        </div>
                    )}
                    {!authorized && (
                        <div>
                            <div ref={authDiv} id="authorize-container-id"></div>
                            {!hasAuthElements && <div>🔄 Refresh the page to sign in.</div>}
                        </div>
                    )}
                </div>
            )}
            {error && <div>{error.toString()}</div>}
        </div>
    );
}