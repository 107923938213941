import React, { useState } from "react";
import Div from '../shared/div';
import { Grid } from "@material-ui/core";
import styled from 'styled-components';
import img from "../shared/img";
import './BlocDoubleColonne.css'

const CustomContentBloc = styled.div`
    position: relative;
    .bloc-content {
        display: flex;
        gap: 20px;
        align-items: center;
        position: relative;
        z-index: 5;
        
        .text {
            color: ${props => props.color ? props.color : null};
            text-align: ${props => props.textAlign ? props.textAlign : null};
            font-size: ${props => props.fontSizeText ? `${props.fontSizeText}px` : null};

            h1 {
                color: ${props => props.color ? props.color : null};
                font-size: ${props => props.fontSizeMainTitle ? `${props.fontSizeMainTitle}px` : null};
            }
            h2, h3, h4 {
                color: ${props => props.color ? props.color : null};
                font-size: ${props => props.fontSizeSecondaryTitle ? `${props.fontSizeSecondaryTitle}px` : null};
            }
        }

        & + img {
            position: relative;
            width: 100%;
            margin-top: 45px;
        }
    }

    &.mini-card {
        height: ${props => props.hauteur ? `${props.hauteur}px` : 'auto'};
        overflow: hidden;

        .bloc-content {
            width: 100%;
            height: 100%;
            justify-content: space-around;
            gap: 20px;
            padding: 20px;
            box-sizing: border-box;
        }

        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
        }

        .text {
            line-height: 30px; 
            font-size: 45px;
            color: #FFF;
            text-transform: uppercase;
            font-weight: 900;
            p {
                margin: 0;
            }
        }

        & + .mini-card {
            margin-top: 20px;
        }
    }

`

const CustomButton = styled.a`
    &:hover {
        background-color: ${props => props.color ? props.color : null};
        color: ${props => props.backgroundColor ? props.backgroundColor : null};
        border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : props.backgroundColor ? `1px solid ${props.backgroundColor}` : null};
    }
`


const BlocDoubleColonne = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null,
        data = null,
        inputCallback = null,
        spread = false
    } = props;


    const responsive = {
        xs: data.inputs.responsive.inputs.xs.value,
        sm: data.inputs.responsive.inputs.sm.value,
        md: data.inputs.responsive.inputs.md.value,
        lg: data.inputs.responsive.inputs.lg.value,
    }

    const gap = data.inputs.columnGap.value;
    const { identifiant, className, fontSizeMainTitle, fontSizeSecondaryTitle, fontSizeText, paddingTop } = data.inputs;


    return (
        <Div 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)} 
            style={{ position: 'relative' }}>
                
            {hover && !preview && props.children}

            <Grid
                className={className ? className.value : null}
                id={identifiant ? identifiant.value : null}
                container
                spacing={gap}
                style={{
                    paddingTop: paddingTop.value ? `${paddingTop.value}px` : null,  
                    paddingBottom: paddingTop.value ? `${paddingTop.value}px` : null,  
                    display: 'grid',
                    gridTemplateColumns: '4fr 2fr',
                    gap: `${gap}px`,
                }}
                >
        
                {
                    Object.entries(data.blocks).map(([_, {config}], i) => {
                
                        return <Grid item key={i}>
                            {/* 1 ou plusieurs items par colonne */}

                            {
                                config?.map(block => {

                                    const { is_mini_card, button, media, backgroundColor, text, color, textAlign, id } = block.blocks

                                    const backgroundImg = is_mini_card.inputs.handle.value ?typeof media?.inputs?.value?.value === 'string'?media.inputs.value.value
                                    :media?.inputs?.value?.value?.filePath ?`${process.env.REACT_APP_MEDIAS}/${media.inputs.value.value.filePath}`:null: null
                                   
                                    return (
                                        <CustomContentBloc
                                            style={{
                                              position: "relative"  
                                            }}
                                            key={id}
                                            className={is_mini_card.inputs.handle.value ? 'mini-card' : null}
                                            backgroundColor_1={backgroundColor?.inputs?.value_1?.value}
                                            backgroundColor_2={backgroundColor?.inputs?.value_2?.value !== "#02EE00" ? backgroundColor?.inputs?.value_2?.value : null}
                                            color={color.inputs.color.value}
                                            textAlign={textAlign.inputs.textAlign.value}
                                            fontSizeMainTitle={fontSizeMainTitle.value}
                                            fontSizeSecondaryTitle={fontSizeSecondaryTitle.value}
                                            fontSizeText={fontSizeText.value}
                                            hauteur={is_mini_card.inputs.handle.value ? is_mini_card.inputs.height.value : null}
                                        >
                                            {/* {
                                                is_mini_card.inputs.handle.value ?
                                                    typeof media?.inputs?.value?.value === 'string'
                                                        ?
                                                        <img
                                                            src={media.inputs.value.value}
                                                            alt={media.inputs?.alt?.value ?? "Illustration"} />
                                                        :
                                                        media?.inputs?.value?.value?.filePath ?
                                                            <img
                                                                src={`${process.env.REACT_APP_MEDIAS}/${media.inputs.value.value.filePath}`}
                                                                alt={media.inputs?.alt?.value ?? "Illustration"} />
                                                            :
                                                            null
                                                : null
                                            } */}

                                            

                                            <div className="bloc-content" 
                                                style={{
                                                    backgroundImage:`linear-gradient(${backgroundColor?.inputs?.value_1?.value}, ${backgroundColor?.inputs?.value_2?.value !== "#02EE00" ? backgroundColor?.inputs?.value_2?.value : null}),url(${backgroundImg})`,
                                                    gap: "20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    zIndex: 5,
                                                    width: "100%",
                                                    height: "100%",
                                                    justifyContent: "space-between",
                                                    padding:!is_mini_card.inputs.handle.value? 0: "30px",
                                                    boxSizing: "border-box",
                                                }}>
                                                {
                                                    text?.inputs?.content?.value && 
                                                    <div
                                                        className={"text-bloc-content"}
                                                        style={{
                                                            color:color.inputs.color.value? `${color.inputs.color.value} !important`:null,
                                                            lineHeight: "48px",
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: text?.inputs?.content?.value }}
                                                    />
                                                }
                                                {
                                                    button?.inputs?.handle?.value &&
                                                    <CustomButton
                                                        style={{
                                                            display: "block",
                                                            backgroundColor:  button.inputs.backgroundColor.value ? button.inputs.backgroundColor.value : null,
                                                            color:  button.inputs.color.value ? `${button.inputs.color.value} !important`  : "auto",
                                                            padding: `${button.inputs.paddingY.value ? button.inputs.paddingY.value + "px" : null} ${button.inputs.paddingX.value ? button.inputs.paddingX.value +"px" : null}`,
                                                            border: button.inputs.border.value ? `1px solid ${button.inputs.border.value}` : button.inputs.backgroundColor.value ? `1px solid ${button.inputs.backgroundColor.value}` : null,
                                                            transition: "all .2s ease-in-out",
                                                            textTransform: "uppercase",
                                                            fontWeight: "700",
                                                            textDecoration: 'none',
                                                            textWrap:"nowrap",
                                                        }}
                                                        href={button.inputs.link.value}
                                                        className="button"
                                                    >
                                                        {button?.inputs?.content?.value}
                                                    </CustomButton>
                                                }
                                            </div>

                                            {
                                                !is_mini_card.inputs.handle.value ?
                                                    typeof media?.inputs?.value?.value === 'string'
                                                        ?
                                                        <img
                                                            src={media.inputs.value.value}
                                                            alt={media.inputs?.alt?.value ?? "Illustration"} />
                                                        :
                                                        media?.inputs?.value?.value?.filePath ?
                                                            <img
                                                                src={`${process.env.REACT_APP_MEDIAS}/${media.inputs.value.value.filePath}`}
                                                                alt={media.inputs?.alt?.value ?? "Illustration"} />
                                                            :
                                                            null
                                                : null
                                            }

                                        </CustomContentBloc>
                                    )
                                })
                            }
                        </Grid>
                    })
                }
            </Grid>

        </Div>
    )
}

export default BlocDoubleColonne;