import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getOfferInformations } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Division de 3 blocs',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        subTitle: getDefaultText('Sous-titre de la slide', 'Sous-titre de la slide', 39, '#000', null, 'initial', 'initial'),
        informations:  {
            id: uuidv4(),
            title: "Bloc d'information",
            name: "Bloc d'information",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.OFFER_TRIPLE_BLOCK_INFORMATIONS,
            limit: 3,
            config: [
                getOfferInformations(),
                getOfferInformations(),
                getOfferInformations(),
            ]
        }
    }
};

