import { gql } from 'apollo-boost';

export const GET_DEMANDS = gql`
{
  demands{
    edges{
      node{
        id
        code
        imageUrl
        demandData{
          edges{
            node{
              id
              value
              locale{
                id
                code
                libelle
              }
            }
          }
        }
        restaurantDemand{
          edges{
            node{
              id
            }
          }
        }
      }
    }
  }
}
`;

export const GET_DEMANDS_BY_RESTAURANT_ID = gql`
query GetByIdRestaurant($id: ID!){
  restaurant(id: $id){
    id
    demands{
      edges{
        node{
          id
          code
          imageUrl
          demandData{
            edges{
              node{
                value
        			}
            }
          }
        }
      }
    }
    restaurantData{
      edges{
        node{
          id
					value
          attribute{
            id
            identifier
          }
        }
      }
    }
  }
}
`;

/*
{
  "code": "test",
  "imageUrl": "url"
}
 */
export const CREATE_DEMAND = gql`
mutation CreateDemand($code: String!, $imageUrl: String!){
  createDemand(input: {code: $code, imageUrl: $imageUrl}){
    demand {
      id
      code
      imageUrl
    }
  }
}
`;

/*
{
    "id": "/api/demands/27",
 	  "code": "test2",
    "imageUrl": "url"
}
 */
export const UPDATE_DEMAND = gql`
mutation CreateDemand($id: ID!, $code: String!, $imageUrl: String){
  updateDemand(input: {id: $id, code: $code, imageUrl: $imageUrl}){
    demand {
      id
      code
      imageUrl
      demandData{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

/*
{
  "id": "/api/demands/27"
}
 */
export const DELETE_DEMAND = gql`
mutation CreateDemand($id: ID!){
  deleteDemand(input: {id: $id}){
    demand {
      id
    }
  }
}
`;

/*
{
  "id": "/api/demands/2"
}
 */
export const GETBY_ID_DEMAND = gql`
query GetByIdDemand($id: ID!){
  demand(id: $id){
    id
    code
    imageUrl
    demandData{
      edges{
        node{
          id
          value
          locale{
            id
            code
            libelle
          }
        }
      }
    }
    restaurantDemand{
      edges{
        node{
          id
        }
      }
    }
  }
}
`;