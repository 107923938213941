import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import ContactList from '../../contacts/components/ContactList'

export default function companyAdd(currentLang,customAttributes,addType='add',handleMediaPicker,handleAddCompanyChildren=null,customersFormSettings=[],action='add') {
    let typeInput = '';
    
    let attributeSetup = attribute => {

        const defaultLang = attribute.node.translation.translationDatas.edges[0];

        const langSelected = attribute.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );

        switch(attribute.node.attributeType.input) {
            case 'textarea':
                typeInput = 'textarea';
                break;
            case 'number': // create an input
            case 'decimal': // create an input
            case 'text':
                typeInput = 'text';
                break;
            case 'select':
                typeInput = 'select';
                break;
            case 'image':
                typeInput = 'mediaPicker';
                break;
            case 'file':
                typeInput = 'file';
                break;
            default: typeInput = 'text';
        }

        return ({
            type: typeInput,
            label: langSelected?.node.value ?? defaultLang.node.value,
            handleMediaPicker:handleMediaPicker,
            translated: true,
            helper: {
                text: '',
                link: false,
            },
            isSystem: true,
            currentLang,
            required: attribute.node.isRequired,
            stateName: attribute.node.identifier,
            value: attribute.node.attributeOptions.edges.map((values) => {
                const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

                const langSelectedValues = values.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );

                return ({
                    value: values.node.id,
                    label: langSelectedValues?.node.value ?? langSelectedValuesDefault.node.value
                });
            })
        });
    };
    let customInputs    = customAttributes.map(attributeSetup);
    let formSettings    = customersFormSettings;
    let generalInputs   = {
        required : true,
        type : 'text',
        label : 'Identifier',
        stateName : 'companyIdentifier',
        helper: {
            text: '',
            link: false,
        },
    }
    customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer une société':'Modifier une société',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre société`,
        langSelect: true,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cette société' : 'Valider la modification de cette société',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:customInputs
                    
                },
                {
                    labelName: 'Contacts',
                    isOptionnal: false,
                    component : ContactList,
                    componentProps : {
                        smallScreen         : true, 
                        select              : true, 
                        isForm              : true,
                        showTab             : true,
                        handleCategory      : formSettings.handleCategory,
                        currentCompany      : addType === "add" ? formSettings.currentCompany : null,
                        handleSelect        : formSettings.handleSelect,
                        handleSearch        : formSettings.handleSearch,
                        isCard              : true,
                        queryVariables      : {id_list:formSettings.activeSearchCustomers,status:formSettings.status},
                    },
                    

                    optionsInputs:[
                        {
                            type:'selectContact',
                            callback:handleAddCompanyChildren
                        }
                    ]
                },
            ]
        }
    }
    let companyChildrens = {
            labelName: 'Filiales',
            isOptionnal: false,
            optionsInputs:[
                {
                    type:'addCompany',
                    callback:handleAddCompanyChildren
                }
            ]
        }

    if(addType === 'add'){
        form.formConfig.children.splice(1,0,companyChildrens);
    }


    return form;
}