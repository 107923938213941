import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultDoubleColonne, getDefaultDownloadFiles } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";


export default {
    name: 'Download Files',
    inputs: {
        identifiant: {
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        className: {
            label: 'Classe(s)',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding top",
            params: { max: 200, min: 0, step: 2 },
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 60,
            label: "Padding bottom",
            params: { max: 200, min: 0, step: 2 },
        },
        addBorderTop: {
            label: 'Afficher la bordure haute',
            type: FormInputTypes.SWITCH,
            value: true,
        },
        addBorderBottom: {
            label: 'Afficher la bordure basse',
            type: FormInputTypes.SWITCH,
            value: true,
        },
        columnGap: {
            type: FormInputTypes.SLIDER,
            value: 0,
            label: "Espace entre les colonnes",
            params: { max: 50, min: 0, step: 5 },
        },
        containerSize: {
            type: FormInputTypes.SLIDER,
            value: 0,
            label: "Taille du bloc",
            params: { max: 100, min: 0, step: 10 },
        },
        color: {
            name: 'Couleur du bloc',
            type: FormInputTypes.COLOR,
            value: '#000',
            label: 'Couleur du bloc',
        
        },
    },
    blocks: {
        files: {
            title: "Listes des fichiers",
            name: "Listes des fichiers",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_DOWNLOAD_FILES,
            buttonAdd: 'un fichier',
            config: [
                getDefaultDownloadFiles(),
            ]
        }
    }
};
