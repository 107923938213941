import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import H1 from '../../../../shared/components/CustomH1';
import { useDrop } from 'react-dnd';

// Who Are We

const TextWithImageSimple = (props) => {
    const [hover, setHover] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
        data.inputs['backgroundImage'].value = item.media.node;
        update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        marginBottom: 20
    };


    return (
        <div
        className={`offer-template-scope`}  
        ref={drop} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        style={{
            ...style,
        }}
        >
            { !preview && props.children }
            <div className="container simple">
                <div className="header">
                    <div className="breacrumb">
                        <Div data={data} className="category" preview={preview} component={id} block={'category'} inputCallback={inputCallback} nopadding={true} style={{backgroundColor: data?.inputs?.primaryColor.value, color: data?.inputs?.secondaryColor.value}} />
                        <Div data={data} className="sub-category" preview={preview} component={id} block={'sub_category'} inputCallback={inputCallback} nopadding={true} style={{color: data?.inputs?.primaryColor.value, backgroundColor: data?.inputs?.secondaryColor.value}} />
                    </div>
                </div>
                {/* Possibilité de modifier le layout 30/70%  50/50% 70/30% */}
                <div className="content-block">
                    <div className="left-block">
                        <div className="main">
                            <Div data={data} className="subTitle" preview={preview} component={id} block={'subTitle'} inputCallback={inputCallback} nopadding={true} style={{borderLeft: `3px solid ${data.blocks.subTitle.inputs.color.value}`}} />
                            <Div data={data} className="content-text" preview={preview} component={id} block={'content_text'} inputCallback={inputCallback} nopadding={true} />
                        </div>

                        {/* A afficher seulement si remplis */}
                        <div className="list-image">
                            {
                                data.blocks.listLogos.config.map((logo, index) => (
                                    <div 
                                        className="image-item" 
                                        style={{
                                            width: 90, 
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img 
                                            src={
                                                typeof logo.blocks.info.inputs.logo.value === 'string' ? 
                                                logo.blocks.info.inputs.logo.value : 
                                                logo.blocks.info.inputs.logo.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${logo.blocks.info.inputs.logo.value?.filePath}` 
                                                    : "https://via.placeholder.com/90/F0F7FA/C5DEEC"
                                            }
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="right-block">
                    {
                        typeof data.blocks.image.inputs.value?.value === 'string' || data.blocks.image.inputs.value?.value?.filePath ? (
                            <img onClick={() => inputCallback(id, 'image')} 
                                src={
                                    typeof data.blocks.image.inputs.value?.value === 'string' ? 
                                        data.blocks.image.inputs.value.value : 
                                        data.blocks.image.inputs.value?.value.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                            : "https://via.placeholder.com/555x900/F0F7FA/C5DEEC"
                                }
                                alt="Image"
                            />
                        ) : <img src="https://via.placeholder.com/555x900/F0F7FA/C5DEEC"/>
                    }
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

TextWithImageSimple.propTypes = {
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string
};

export default TextWithImageSimple;