import {getTraductionAttributs} from '../utils/functions';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS } from '../constants/route-names';
import moment from 'moment';

export const notificationMapper = (flows,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = flows.map((flow,index)=>{
            return {
                id:flow.node.id,
                isRead: flow.node.isRead,
                title: flow.node.appNotification?.title,
                message: flow.node.appNotification?.message,
                link: flow.node.appNotification?.link,
                scheduledAt:flow.node.appNotification?.scheduledAt,
                notificationId: flow.node.appNotification?.id,
            }
        })
        resolve(results);
    })
}