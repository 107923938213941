import {Grid,List} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import {connect} from 'react-redux';
import ButtonCustom from '../../../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import _ from 'lodash';
import ListingProducts from '../../products/components/ListingProducts';


const ListCustom = styled(List)`
    padding: 0 0rem 1rem 0; 
    &>div{
        padding: 24px;
        background: ${colors.white};
    }
`;

class ProductSelectorByCategories extends React.Component { 
    constructor(props){
        super(props)
        this.state = {
            targetCatId: this.props.allState.selectedCategorie,
            currentLang : this.props.allState.currentLang,
            selectAll: [],
            productByCategorie: {},
            selectedProductByCategorie: {},
            formatedDatas: {},
            load: true,
            selectedCategorie : this.props.allState.selectedCategorie,
        }
    }

    handleFormatedDatas = () => {
        let targetCatId = parseInt(this.state.targetCatId.replace('/api/categories/', ''));
        let formatedDatas = this.state.formatedDatas;
        formatedDatas.targetCatId = targetCatId;
        let idList = _.clone(this.state.selectedProductByCategorie[this.state.selectedCategorie]);
        let i = 0;
        if(idList){
            for(let id of idList){
                idList[i] = parseInt(idList[i].replace('/api/products/', ''));
                i++;
            }
        }
        formatedDatas.products = idList;
    }

    handleToggle = (value) => {
        let currentIndex  = this.state.selectedProductByCategorie[this.state.selectedCategorie].indexOf(value.node.id);
        let newChecked    = [...this.state.selectedProductByCategorie[this.state.selectedCategorie]];
        let selectedProductByCategorie = this.state.selectedProductByCategorie;

        if (currentIndex === -1) {
            newChecked.push(value.node.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        selectedProductByCategorie[this.state.selectedCategorie] = newChecked;

        
        this.setState({
            selectedProductByCategorie,
        }, () => {
            // this.handlePrepareRecap();
            this.handleFormatedDatas();
        });
    };

    handleSelectAll = (listProducts, checkSelectAll) => {
        let getProductsId = listProducts.map(e => e.node.id)
        let newArraySelected = this.state.selectedProductByCategorie
        for(let productId of getProductsId){
            let currentIndex  = this.state.selectedProductByCategorie[this.state.selectedCategorie].indexOf(productId);
            if(currentIndex !== -1){
                if (checkSelectAll){
                    newArraySelected[this.state.selectedCategorie].splice(currentIndex, 1);
                }
            }else{
                newArraySelected[this.state.selectedCategorie].push(productId)
            }
        }
        this.setState({
            selectedProductByCategorie: newArraySelected
        },() => {
            // this.handlePrepareRecap();
            this.handleFormatedDatas();
        })
    }

    componentDidMount(){
        let selectAll = this.state.selectAll;
        if(typeof selectAll[this.state.selectedCategorie] === 'undefined'){
            selectAll[this.state.selectedCategorie] = false;
        }
        this.setState({
            selectAll,
            load: true,
        }); 

        let selectedProductByCategorie = this.state.selectedProductByCategorie;
        if(!selectedProductByCategorie[this.state.selectedCategorie]){
            selectedProductByCategorie[this.state.selectedCategorie] = [];
        }
        this.setState({selectedProductByCategorie})
    }


    render(){
        return(
            <Grid container spacing={4} style={{paddingBottom: 80, background: colors.grey.lighter.hue980, marginTop: 10}}>
                <Grid item xs={12} style={{position: 'fixed', bottom: 0, zIndex: '99', background: 'white', display: 'block', width: 'calc(50% + 360px)'}}>
                    <Grid container justifyContent="flex-end">
                        <ButtonCustom icon="add" onClick={() => {this.props.stateCallback(this.state.formatedDatas)} } disabled={this.state.selectedProductByCategorie[this.state.selectedCategorie]?.length === 0 ? true : false}>
                            {
                                this.state.selectedProductByCategorie[this.state.selectedCategorie]?.length === 0 ? 
                                    'Aucun produit'
                                :   `Ajouter ${this.state.selectedProductByCategorie[this.state.selectedCategorie]?.length} ${this.state.selectedProductByCategorie[this.state.selectedCategorie]?.length > 1 ? 'produits' : 'produit'}`
                            }   
                        </ButtonCustom>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '1rem'}}>
                    <ListCustom dense>
                        <Grid container direction="row" style={{marginTop: 0}}>
                            <ListingProducts 
                                productBypage = {{
                                    card: [9,18,36],
                                    list: [10,20,30]
                                }}
                                nbperpage = {9}
                                currentLang={this.props.allState.currentLang} 
                                windowWidth={this.props.windowWidth}
                                openForm={this.state.openForm}
                                handleToggle={this.handleToggle}
                                selectedProducts={this.state.selectedProductByCategorie[this.state.selectedCategorie] || []}
                                cardSize = {{
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 4
                                }}
                                handleSelectAll={this.handleSelectAll}
                                selectAll={this.state.selectAll[this.state.selectedCategorie]}
                                inForm={true}
                                hoverCard = {false}
                                reverseCheck = {false}
                            />
                        </Grid>
                    </ListCustom>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
};

export default withApollo((connect(mapStateToProps,mapDispatchToProps)(ProductSelectorByCategories)));