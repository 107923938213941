import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault, getDefaultSocialComplete } from '../../helpers';

export default {
    name: 'Récapitulatif de la wishlist',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        backgroundImageTablet: {
          label: "Image de fond tablet",
          type: FormInputTypes.IMAGE,
          value: null,
        },
        backgroundImageMobile: {
          label: "Image de fond mobile",
          type: FormInputTypes.IMAGE,
          value: null,
        },
    },
    blocks: {
        titleRecap: getDefaultText("Titre du récap", "Produits sélectionnés dans ma liste ", 39, '#FFFFFF', null, 'uppercase', 'center'),
        logoDesktop: getImageDefault(null,"Image desktop"),
        logoTablet: getImageDefault(null,"Image tablette"),
        logoMobile: getImageDefault(null,"Image mobile"),
        title: getDefaultText("Texte", "Bravo ! ", 39, '#FFFFFF', null, 'uppercase', 'center'),
        description : getDefaultText("Texte", "Partagez avec vos amis votre liste pour valider votre inscription, participer au tirage au sort & tenter de remporter votre sélection. ", 16, '#FFFFFF', null, 'uppercase', 'center'),
        socialBlock:{
            id: uuidv4(),
            name: "Bloc réseaux sociaux",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fffff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
            blocks: {
                shareText: getDefaultText("Texte de partage", "Texte de partage", 20, '#53839D', 'rgba(0,0,0,0)'),
                social:{
                    title: "Réseau social",
                    name: "Réseaux sociaux",
                    type: EditableTypes.MULTIPLE_BLOCKS,
                    subtype: ItemTypes.SOCIAL_COMPLETE,
                    limit: 3,
                    config: [
                        getDefaultSocialComplete(),
                        getDefaultSocialComplete(),
                    ]
                },
            }
        },
    }
};
