import {STEPPER} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function formContent(action='add', allState) {
    let generalInputs   = [
        {
            required : true,
            type : 'text',
            label : 'Libelle',
            stateName : 'contentLibelle',
            required: action === 'add' ? true : false,
            helper: {
                text: 'Libelle de la page',
                link: false,
            },
        },
        {
            type: 'date',
            label: 'Date de début',
            helper: {
                text: 'Date de début',
                link: false,
            },
            required: true,
            getTime: true,
            stateName: 'contentStartDate',
        },
        {
            type: 'date',
            label: 'Date de fin',
            helper: {
                text: 'Date de fin',
                link: false,
            },
            required: true,
            getTime: true,
            stateName: 'contentEndDate',
        },
        {
            type: 'buttonGroup',
            label: 'État',
            helper: {
                label: 'État',
                text: 'Indiquez l\'état',
                link: false,
            },
            required: true,
            stateName: 'contentIsActive',
            value: [
                {
                    value: true,
                    label: 'Actif',
                },
                {
                    value: false,
                    label: 'Inactif',
                },
            ]
        },
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: action === 'add'? 'Créer un contenu':'Modifier un contenu',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add'? 'créer':'modifier'} votre contenu`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de ce contenu' : 'Valider la modification de ce contenu',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}