import {FORM} from '../../../../../../js/constants/form-types';
import { getTraductionAttributs } from '../../../../../../js/utils/functions';
import Button from '../../../../../ui/button/Button';

export default function formContentDuplicate(allState) {
    let generalInputs   = [
        {
            type: 'select',
            label: 'Asset pour la duplication',
            helper: {
                text: 'Choisissez un asset pour la duplication',
                link: false,
            },
            required: true,
            stateName: 'assetSelected',
            value: allState.listAssets?.map(asset => {
                let getName = getTraductionAttributs('asset_store_name', asset.node.assetDatas.edges, allState.currentLang);
                return ({
                    value: asset.node.id,
                    label: getName || asset.node.identifier
                });
            })
        },
    ]
    if (allState.assetSelected){
        generalInputs.push(
            {
                type: 'select',
                label: 'Pages',
                helper: {
                    text: 'Choisissez une page pour la duplication',
                    link: false,
                },
                required: true,
                stateName: 'pageSelected',
                value: allState.listPages?.map(page => {
                    return ({
                        value: page.node.id,
                        label: page.node.libelle || page.node.identifier
                    });
                })
            }
        )
    }
    if (allState.pageSelected){
        let getContents = allState.listPages?.find(e => e.node?.id === allState.pageSelected)
        generalInputs.push(
            {
                type: 'select',
                label: 'Contenus',
                helper: {
                    text: 'Choisissez les contenus à remplacer',
                    link: false,
                },
                required: true,
                multiselect: true,
                stateName: 'contentsSelected',
                value: getContents.node.pageContents.edges.filter(e => e.node.id !== allState.contentIdToDuplicate)?.map(page => {
                    return ({
                        value: page.node.id,
                        label: page.node.libelle || page.node.identifier
                    });
                })
            }
        )
    }
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: 'Dupliquer un contenu',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour dupliquer votre contenu`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Valider la duplication de ce contenu',
                textButton: 'Valider'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}