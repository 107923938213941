import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function taxAddConfig() {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom de la taxe',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'decimal',
            label: 'Taux',
            helper: {
                text: 'Indiquez le taux de la taxe',
                link: false,
            },
            required: true,
            stateName: 'valueTax',
        },
    ]
    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
    ];

    var obj = {
        titleForm: 'Ajouter une taxe',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre taxe',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Votre taxe est désormais configurée',
                textButton: 'Créer cette taxe'
            },
            children
        }
    };
    return obj;
}