import { gql } from 'apollo-boost';


export const GET_INFO_MANAGEMENTS = gql`
{
  infoManagements{
    edges{
      node{
        id
        status
        libelle
        textButton
        colorButton
        linkButton
        type
        asset{
          id
        }
        product{
          edges{
            node{
              id
            }
          }
        }
        category{
          id
        }
      }
    }
  }
}
`;

export const GET_INFO_MANAGEMENTS_PAGINATION = gql`
query infoManagementsPagination($asset: String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  infoManagements(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        status
        libelle
        libelleColor
        textButton
        textColorButton
        colorButton
        linkButton
        type
        media{
          id
          filePath
          type
          size
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
        asset{
          id
        }
        product{
          edges{
            node{
              id
            }
          }
        }
        category{
          id
        }
      }
    }
  }
}
`;

/*
{
    "type": "test"
}
 */
export const GET_INFO_MANAGEMENTS_BY_TYPE = gql`
query getInfoManagementsByType($type: String!){
  infoManagements(type: $type){
    edges{
      node{
        id
        status
        libelle
        textButton
        colorButton
        linkButton
        type
        asset{
          id
        }
        product{
          edges{
            node{
              id
            }
          }
        }
        category{
          id
        }
      }
    }
  }
}
`;

/*
{
    "id": "/api/info-managements/1"
}
 */
export const GET_INFO_MANAGEMENTS_BY_ID = gql`
query getInfoManagementsById($id: ID!){
  infoManagement(id: $id){
    id
    status
    libelle
    textButton
    colorButton
    linkButton
    type
    media{
      id
      filePath
      type
      size
      category{
        edges{
          node{
            id
            libelle
          }
        }
      }
    }
    asset{
      id
    }
    product{
      edges{
        node{
          id
        }
      }
    }
    category{
      id
    }
  }
}
`;

/*
{
  "status": true,
  "libelle": "tets",
  "textButton": "text",
  "colorButton": "color",
  "linkButton": "link",
  "type": "type",
  "asset": "/api/assets/1",
  "product": ["/api/products/1"]
  "category": ["/api/category/1"]
}
 */
export const CREATE_INFO_MANAGEMENT = gql`
mutation createInfoManagement($status: Boolean!, $libelle: String, $libelleColor: String, $textButton: String, $textColorButton: String $colorButton: String, $linkButton: String, $type: String!, $asset: String!, $product: [String], $category: [String], $media: String){
  createInfoManagement(input:{status: $status, libelle: $libelle, libelleColor: $libelleColor, textButton: $textButton, textColorButton: $textColorButton, colorButton: $colorButton, linkButton: $linkButton, type: $type, asset: $asset, product: $product, category: $category, media:$media}){
    infoManagement{
      id
      status
      libelle
      textButton
      colorButton
      linkButton
      type
      asset{
        id
      }
      product{
        edges{
          node{
            id
          }
        }
      }
      category{
        id
      }
    }
  }
}
`;


/*
{
  "id": "/api/info-managements/1",
  "status": true,
  "libelle": "tets",
  "textButton": "text",
  "colorButton": "color",
  "linkButton": "link",
  "type": "type",
  "asset": "/api/assets/1",
  "product": ["/api/products/1"]
  "category": ["/api/category/1"]
}
 */
export const UPDATE_INFO_MANAGEMENT = gql`
mutation updateInfoManagement($id: ID!, $status: Boolean!, $libelle: String, $libelleColor: String, $textButton: String, $textColorButton: String $colorButton: String, $linkButton: String, $type: String!, $asset: String!, $product: [String], $category: [String], $media: String){
  updateInfoManagement(input:{id: $id, status: $status, libelle: $libelle, libelleColor: $libelleColor, textButton: $textButton, textColorButton: $textColorButton, colorButton: $colorButton, linkButton: $linkButton, type: $type, asset: $asset, product: $product, category: $category, media: $media}){
    infoManagement{
      id
      status
      libelle
      textButton
      colorButton
      linkButton
      type
      asset{
        id
      }
      product{
        edges{
          node{
            id
          }
        }
      }
      category{
        id
      }
    }
  }
}
`;

/*
{
  "id": "/api/info-managements/1",
}
 */
export const DELETE_INFO_MANAGEMENT = gql`
mutation deleteInfoManagement($id: ID!){
  deleteInfoManagement(input:{id: $id}){
    infoManagement{
      id
    }
  }
}
`;