import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import CustomCard from './CustomCard';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    slidePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

export default ({ data, buildFormInput, addSlider }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                data.slides.map((slide, i) => {
                    let inputs = [];
        
                    for (let blockName in slide.blocks) {
                        let block       = slide.blocks[blockName];
                        let cardInputs  = [];
            
                        for (let inputName in block.inputs) {
                            let input = block.inputs[inputName];
            
                            cardInputs.push(buildFormInput(input, block.id));
                        }

                        inputs.push(<CustomCard block={block} cardInputs={cardInputs} />);
                    }

                    return (
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Slide {i + 1}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.slidePanel}>
                                { inputs }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )
                })
            }

            <div className={classes.actionBar}>
                <Chip
                    label="Ajouter"
                    onClick={() => addSlider(data)}
                    icon={<AddIcon />}
                />
            </div>
        </div>
    )
};