

export const getImage = (img,imgTablet,imgMobile)=>{
    let mobileWidth = false;    
    let tabletWidth = false;

    if (typeof window !== "undefined") {
        mobileWidth = window.matchMedia("(max-width: 500px)")    
        tabletWidth = window.matchMedia('(min-width:500px) and (max-width: 1000px)')  
    }

    if (tabletWidth.matches && imgTablet) {            
        return typeof imgTablet === 'string' ? imgTablet : imgTablet?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imgTablet.filePath}` : 'none'
    }
    if (mobileWidth.matches && imgMobile) {
        return typeof imgMobile === 'string' ? imgMobile : imgMobile?.filePath ? `${process.env.REACT_APP_MEDIAS}/${imgMobile.filePath}` : 'none'
    }
    else return typeof img === 'string' ? img : img?.filePath ? `${process.env.REACT_APP_MEDIAS}/${img.filePath}` : 'none'
}