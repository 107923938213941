import {useState} from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';

import HeadingCell from './cells/HeadingCell'
import CellText from './cells/CellText';
import CellImage from './cells/CellImage';
import CellLink from './cells/CellLink';
import CellAvatars from './cells/CellAvatars';
import CellProfilePicture from './cells/CellProfilePicture';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import { Tooltip } from '@material-ui/core';

    
const CustomRow = styled(TableRow)`
    background : ${props => props.styling.rowBackground ? props.styling.rowBackground : 'white'};
    td:first-child{
        border-left : 1px solid rgba(224, 224, 224, 1);
    }
    td:last-child{
        border-right : 1px solid rgba(224, 224, 224, 1);
    }
    &:nth-last-of-type(1){
        td{
            border-bottom:none;
        }
    }
    ${props => props.styling.type == 'cards' ? 
        `
        border-radius : 10px;
        &:hover{
            box-shadow :0px 1px 9px -1px rgba(0,0,0,0.15);
        }
        `
    : null}
    ${props => props.styling.type == 'striped' ? 
        `   
            background : #f2f2f2;
            &:nth-of-type(odd){
                background :  white;
            }
        `
    : null}
`
const CustomTable = styled(Table)`
    th{
        background: unset;
    }
    ${props => props.styling.type == 'cards' ? `
        border-spacing: 0px 10px;
        border-collapse:separate;
    `:null}
`

const HeadRow = styled(TableRow)`

    th{
        background : ${props=> props.styling?.headingBackground ? props.styling.headingBackground : colors.grey.lighter.hue980 };
        color : ${props=> props.styling?.headingColor ? props.styling.headingColor : colors.grey.lighter.hue600 };
    }
`
const ButtonCustom = styled(Button)`
    &.delete{
        background : ${colors.red.regular};
    }
    /* Change style */
    &.edit{
        background : ${colors.red.regular};
    }
`

const cells = {
    'text' : CellText,
    'image' : CellImage,
    'link'  : CellLink,
    'avatars' : CellAvatars,
    'profil_picture' : CellProfilePicture,
    'date' : CellText,
    'switch': CellText,
    'select': CellText,
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const Rows = (props) => {
    return (
        <CustomRow 
            key={`list-${capitalize(props.label)}-${props.index}`}
            styling = {props.styling}
        >
            {props.columns.map((column,i)=>{
                if (column) {
                    let Cell = cells[column?.cellType];
                    let value = props.item.node[column?.field];
                    let media = null;
                    if (!value || value === undefined){
                        if (props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.attribute.attributeType.input === "image"){
                            media = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.media
                        }
                        value = props.item.node.shellDatas.edges.find(e => e.node.attribute.identifier === column.field)?.node.value
                    }
                    return(
                        <Cell key={i} value={value} media={media} width={column.width} column={column} {...column.cellProps} attribute={props.item.node.shellDatas?.edges.find(e => e.node.attribute.identifier === column.field  && e.node.locale?.code === props.currentLang)?.node}/>
                    )
                }
            })}
            <TableCell align='right'>
                {
                    props.tableProps.textButton ? (
                        <Button text={props.tableProps.textButton} onClick={() => props.tableProps.handlerButton(props.item.node.id)}/> 
                    ) : null
                }
                {
                    props.tableProps.textButtonSecondary ?
                    props.tableProps.typeButtonSecondary === 'edit' ? (
                        <Button
                            text={'Modifier'}
                            icon={"edit"}
                            color={colors.blue.darker.hue300}
                            colorHover={colors.white}
                            bgcolorhover={colors.blue.lighter.hue600}
                            bgcolor={colors.blue.lighter.hue900}
                            border={`1px solid ${colors.blue.darker.hue300}`}
                            stopPropagation={true}
                            onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}
                            disabled={false}
                            style={{
                                margin: 0,
                            }}
                        /> 
                    ) : (
                        <ButtonCustom className={props.tableProps.typeButtonSecondary} text={props.tableProps.textButtonSecondary} onClick={() => props.tableProps.handlerButtonSecondary(props.item.node.id)}/>
                    )
                    : null 
                }
            </TableCell>
        </CustomRow>
    )
}

export default function TableView(props){
    let {settings,items,label,currentLang} = props;
    const columns = settings?.columns;
    return(
        <div style={{height: 650, position:'relative', overflow:'auto',borderBottom:"1px solid rgba(224, 224, 224, 1)"}}>
            <CustomTable aria-label="headerList" styling={settings.style} stickyHeader>
                {
                    columns && columns.length > 0 ? 
                        <TableHead>
                            <HeadRow styling={settings.style}>
                                {columns.map((column,i)=>{
                                    return <HeadingCell key={i} {...column?.cellProps} sortCallback = {props.sortCallback} width={column?.cellProps?.width} label={column?.label} filter={column?.filter} sortBy={props.sortBy}/>
                                })}
                                <HeadingCell label={"Actions"} width={props.tableProps.textButtonSecondary ? "200px" : "10%"} align={"center"}/>
                            </HeadRow>
                        </TableHead>
                    : null
                }
                <TableBody>
                    {
                        props.ready && items ? 
                            items.map((item,index)=>{
                                return <Rows item={item} index={index} columns={columns} styling={props.settings.style} key={`row-${index}`} label={label} {...props} />
                            })
                        : 
                        <tr>
                            <td colspan={columns?.length ? columns.length : 1}><PageLoader/></td>                 
                        </tr>
                    }
                </TableBody>
            </CustomTable>
        </div>
    )
}

