import React from 'react';
import { Box, Grid, Typography, Tooltip, Divider, Menu } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as moment from 'moment';
import OurTypography from '../../../ui/typography/Typography';
import { useState } from 'react';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

function CardUserGroup(props) {

    const [rootAccessList, setRootAccessList] = useState([])

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            color: colors.blue.darker.hue300,
            isDisabled: true,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handlerMenu.toggleDeleteDialog(props.data.node.id)
            }
        }
    ]

    useEffect(() => {
        getAccess()
    }, [])

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    const getAccess = () => {
        const rawAccessList = props.data.node.menuEntry.edges.map(access => access.node)

        let formattedAccessList = []

        for (let element of rawAccessList) {
            formattedAccessList.push({
                code: element.code,
                createdAt: element.createdAt,
                id: element.id.replace('/api/menu-entries/', ''),
                parentId: element.parent?.id.replace('/api/menu-entries/', ''),
                updatedAt: element.updatedAt
            })
        }

        let map = {}, node, roots = [], i;

        for (i = 0; i < formattedAccessList.length; i += 1) {
            map[formattedAccessList[i].id] = i; // initialize the map
            formattedAccessList[i].children = []; // initialize the children
        }

        for (i = 0; i < formattedAccessList.length; i += 1) {
            node = formattedAccessList[i];
            if (node.parentId) {
                formattedAccessList[map[node.parentId]]?.children.push(node);
            } else {
                roots.push(node);
            }
        }

        roots = _.uniqBy(roots, 'code')
        
        setRootAccessList(roots)
    }

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const uppercaseFirstChar = (text) => {
        return (text).charAt(0).toUpperCase() + (text).slice(1)
    }

    // Menu

    return (
        <ContainerCard>
            <Grid container style={{ position: 'relative', height: '100%' }} direction={'column'} justifyContent={'space-between'}>
                <Box mb={'36.5px'}>
                    <Grid container direction="column">
                        <Grid container justifyContent='space-between' style={{marginBottom: '12px'}}>
                            <OurTypography variant="h4" fontweighttext={700} style={{ fontSize: '25px' }}>{uppercaseFirstChar(props.data.node.code)}</OurTypography>
                            <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                        </Grid>
                        {/* <OurTypography variant="body" colortext={colors.grey.lighter.hue600} style={{ fontSize: '13px', marginTop: '8px', marginBottom: '12px' }}>{uppercaseFirstChar(props.data.node.code)}</OurTypography> */}
                        <Divider />
                    </Grid>
                    <Grid container direction="column" style={{ marginTop: '12px' }}>
                        <OurTypography variant="body" colortext={colors.black.regular} style={{ fontSize: '13px' }}>Accès du groupe :</OurTypography>
                        <Box mt={'10px'}>
                            {rootAccessList.length ? (
                                rootAccessList.map((i, index) => (
                                    <OurTypography colortext={colors.black.regular} fontweighttext={700} style={{
                                        marginRight: index === (rootAccessList.length - 1) ? null : '5px',
                                        display: 'inline-block'
                                    }}>
                                        {index === (rootAccessList.length - 1)
                                            ? props.t(`drawer.${i.code}`) + '.'
                                            : props.t(`drawer.${i.code}`) + ','}
                                    </OurTypography>
                                ))) : (
                                <OurTypography colortext={colors.black.regular} fontweighttext={700}>Pas d'accès.</OurTypography>
                            )}
                        </Box>
                    </Grid>
                </Box>
                <Grid container justifyContent="flex-end">
                    <Button style={{
                        width: '100%'
                    }} onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                </Grid>
            </Grid>
        </ContainerCard>
    );
}

export default withTranslation()(CardUserGroup)