import React from 'react';
import { Grid, List, InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import _ from 'lodash';
import ListingProducts from './ListingProducts';

const ListCustom = styled(List)`
    position: unset;
    padding: 0;
    &>div{
        padding: 8px;
        background: ${colors.white};
    }
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

class ProductSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentLang: this.props.allState.currentLang,
            selectedProducts: this.props.allState.selectedProducts || [],
            nbProductsTotal: this.props.allState.selectedProducts?.length || 0,
            load: true
        };
    }

    handleToggle = (value) => {
        let currentIndex = this.state.selectedProducts.indexOf(value.node.id);
        let nbProductsTotal = this.state.nbProductsTotal;
        let newChecked = this.state.selectedProducts;

        if (currentIndex === -1) {
            newChecked.push(value.node.id);
            nbProductsTotal++;
        } else {
            newChecked.splice(currentIndex, 1);
            nbProductsTotal--;
        }

        let selectedProducts = newChecked;
        
        this.setState({ selectedProducts, nbProductsTotal }, () => {
            this.props.stateCallback('selectedProducts', this.state.selectedProducts)
        });
    };

    render() {
        return (
            <Grid container style={{ background: colors.grey.lighter.hue980, marginTop: 0 }}>
                <Grid item xs={12} style={{position: 'relative', padding: '16px'}}>
                    <ListCustom dense>
                        <Grid container direction="row" justifyContent="space-around">
                            <Grid container>
                                <InputLabelCustom style={{ maxWidth: 490, margin: 'auto' }}>Sélectionner des produits ({this.state.nbProductsTotal}) </InputLabelCustom>
                                <Grid container direction="row" style={{ margin: 'auto' }}>
                                    <ListingProducts
                                        productBypage={{
                                            card: [8, 16, 32],
                                            list: [10, 20, 30]
                                        }}
                                        catalogId={this.props.optionsInputs?.catalogId.replace('/api/catalogs/', '')}
                                        nbperpage={8}
                                        minimalForm={true}
                                        currentLang={this.props.allState.currentLang}
                                        windowWidth={this.props.windowWidth}
                                        openForm={this.state.openForm}
                                        handleToggle={this.handleToggle}
                                        selectedProducts={this.state.selectedProducts}
                                        cardSize={{
                                            xs: 12,
                                            sm: 6,
                                            md: 6,
                                            lg: 6
                                        }}
                                        noCategories={this.props.optionsInputs?.noCategories}
                                        inForm={true}
                                        hoverCard={false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListCustom>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withApollo((connect(mapStateToProps, mapDispatchToProps)(ProductSelector)));