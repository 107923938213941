import React from 'react';
import { CursorPagination } from '../../../js/utils/pagination'
import styled from 'styled-components';

import PageLoader from '../../ui/loadings/page-loader/PageLoader'
import { Grid } from '@material-ui/core';
import GridView from './components/GridView/GridView';
import TableView from './components/TableView/TableView'
import ChangeView from './components/ChangeView';
import { itemMapper } from '../../../js/mappers/mapper';
import * as listingHelper from '../../../js/utils/listing';
import { getElements } from '../../../js/utils/functions';
import NoResult from './components/NoResult';
import TableViewSimple from './components/TableView/TableViewSimple';
import TraductionSelect from '../TopPanel/TraductionSelect';
import CarouselListing from '../Carousel/CarouselListing';
import EmptyCard from '../../ui/empty-card/EmptyCard';
import emptyConnector from '../../../assets/pictos/empty-picto/empty_connectors.png';
import { withTranslation } from 'react-i18next';

const ListingContainer = styled.div`
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`;

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

class Listing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mappingReady: false,
            items: null,
            pagination: this.props.pagination ?
                {
                    page: 0,
                    perPage: this.props.perPageOptions[this.props.viewsOptions.current][0],                    
                }
                : false,
            views: this.props.viewsOptions,
            sortBy: [],
            noResult: false,
        }
    }
    mapItems = async (items) => {
        this.setState({ mappingReady: false })
        let result = await itemMapper(this.props.mappers, items, this.props.currentLang);
        if(this.props.label === 'marques'){
            result.card =result.card.filter(e=>e.node.libelle !== "GoogleShopping")
        }
        this.setState({ items: result })
    }

    handleGetItems() {
        return new Promise(async (resolve, reject) => {
            this.setState({ ready: false })

            let variables = {
                ...this.props.queryVariables,
            }
            variables = await listingHelper.initQuery(this.state, variables, 'queryData', this.props.typeResult);
            let queryStates = await listingHelper.initQuery(this.state, variables, 'states', this.props.typeResult);

            this.setState({ ...queryStates });
            let result = await getElements(this.props.identifier, variables);
            let handleResults = await listingHelper.handleResult(result.data[this.props.identifier], 'listItems', this.state, {}, this.props.typeResult,this.props.identifier);
            await this.mapItems(handleResults.listItems);

            this.setState({
                ...handleResults,
            })
            
            if (this.props.listingCallback)
                this.props.listingCallback(handleResults.listItems)
            resolve();

        });
    }

    changeViews = async (mode) => {
        let views = await listingHelper.changeViews(this.state, mode);
        this.setState({
            views
        })
        if (this.props.perPageOptions[mode][0] != this.state.pagination.perPage) {
            this.changePerPage(this.props.perPageOptions[mode][0])
        }
    }

    changePage = async (newPage) => {
        let pagination = await listingHelper.pagination.changePage(this.state.pagination, newPage, this.props.typeResult);
        this.setState({
            pagination
        }, () => this.handleGetItems());
    }

    changePerPage = async (perPage) => {
        let reload = null;
        if (this.props.typeResult === "collection") {
            reload = this.state.pagination.itemsPerPage !== perPage;
        } else {
            reload = this.state.pagination.perPage !== perPage;
        }
        if (reload) {
            let pagination = await listingHelper.pagination.updatePerPage(this.state.pagination, perPage);
            this.setState({
                pagination
            }, () => this.handleGetItems());
        }

    }

    handleSort = async (sortBy) => {
        let newSortBy = await listingHelper.sortBy.handleSort(this.state.sortBy, sortBy);
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({
            newSortBy,
            pagination
        }, () => this.handleGetItems())
    }

    resetPagination = async () => {
        let pagination = await listingHelper.pagination.resetPagination(this.state.pagination.perPage);
        this.setState({ pagination, noResult: false }, () => this.handleGetItems())
    }

    componentDidMount() {
        this.handleGetItems();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.queryVariables) != JSON.stringify(prevProps.queryVariables)) {
            this.resetPagination();
        }
        if (this.props.reload != prevProps.reload && this.props.reload == true) {
            this.resetPagination();
        }
        if (this.props.currentLang != prevProps.currentLang) {
            this.mapItems(this.state.listItems)
        }
    }

    render() {
        let { settings, label, perPageOptions, propsToPass } = this.props;
        let pagination = this.props.pagination ? this.state.pagination:null;        
        let currentLang = this.props.currentLang;
        let noResult = this.state.noResult;
        let views = this.state.views;      
        return (
            <ListingContainer className='listing-wrapper'>
                {
                    views.settings?.length > 1 ? (
                        <Grid container justifyContent={this.props.handleLang ? "space-between" : "flex-end"} alignItems="center" style={{ marginBottom: 16 }}>
                            <Grid item>
                                <ChangeView views={views} handleViews={this.changeViews} />
                            </Grid>
                            {
                                this.props.handleLang ?
                                    <Grid item>
                                        <TraductionSelect
                                            currentLang={currentLang}
                                            handleLang={this.props.handleLang}
                                            locales={this.props.locales}
                                        />
                                    </Grid>
                                : null
                            }
                        </Grid>
                    ) : null
                }
                {
                    this.state.ready?
                        !noResult ? (
                            <>
                                {
                                    views.current == 'card' ?
                                        this.state.ready ? (
                                            <GridView settings={settings.grid} propsToPass={propsToPass} items={this.state.items[views.current]} cardProps={this.props.cardProps} label={label} currentLang={currentLang} cardContainerProps={this.props.cardContainerProps} />
                                        ) : <PageLoader />
                                        :
                                        views.current == "table" ?
                                            this.state.ready ? (
                                                <TableView settings={settings.table} propsToPass={propsToPass} items={this.state.items[views.current]} tableProps={this.props.tableProps} label={label} ready={this.state.ready} sortCallback={this.handleSort} sortBy={this.state.sortBy} currentLang={currentLang} />
                                            ) : <PageLoader />
                                            :
                                            views.current == "carousel" ?
                                                this.state.ready ? (
                                                    <CarouselListing items={this.state.items} currentLang={currentLang} propsToPass={propsToPass} cardProps={this.props.cardProps}/>
                                                ) : <PageLoader />                                            
                                                :this.state.ready ? (
                                                    <TableViewSimple settings={settings.table} propsToPass={propsToPass} items={this.state.items[views.current]} tableProps={this.props.cardProps} label={label} ready={this.state.ready} sortCallback={this.handleSort} sortBy={this.state.sortBy} />
                                                    ) : <PageLoader />
                                }
                                {
                                    this.state.ready && pagination ? (
                                        <CursorPagination
                                            rowLabel={`${capitalize(label)} par page`}
                                            pagination={pagination}
                                            type="table"
                                            changePageCallback={this.changePage}
                                            changePerPageCallback={this.changePerPage}
                                            showPerPage={perPageOptions[views.current]?.length > 0}
                                            perPageOptions={perPageOptions[views.current]}
                                            typeResult={this.props.typeResult}
                                        />
                                    ) : null
                                }
                            </>
                        ) : <NoResult component={this.props.noResultComponent} />
                        :<PageLoader/>
                }
            </ListingContainer>
        )
    }
}

export default withTranslation()(Listing);