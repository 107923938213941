import React from "react";
import colors from '../../../colors';

const H2 = ({ data, preview, component, block, inputCallback, className }) => {
    let image = data?.blocks[block]?.inputs['backgroundImage']?.value;
    return (
        <h2 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null} 
            style={{
                fontSize: data?.blocks[block]?.inputs.size?.value,
                color: data?.blocks[block]?.inputs.color?.value,
                backgroundColor: data?.blocks[block]?.inputs.backgroundColor?.value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                lineHeight: typeof image === 'string' || image?.filePath ? '2': '1.2', 
                padding: typeof image === 'string' || image?.filePath ? `10px ${data?.blocks[block]?.inputs.size?.value}px` : null,
                textTransform: data?.blocks[block]?.inputs.textTransform?.value,
                textAlign: data?.blocks[block]?.inputs.textAlign?.value,
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null
            }}
            dangerouslySetInnerHTML={{ __html: data?.blocks[block]?.inputs.value.value }}
        >
        </h2> 
    );
};

export default H2;