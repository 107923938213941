// log the pageview with their URL
export const pageview = (sku, url) => {
    if (!process.env.REACT_APP_GA) return;

    if (typeof document !== "undefined") {
        let parser = document.createElement('a');
        parser.href = url;

        if (parser.pathname) {
            console.log('GA', sku, parser.pathname);

            window.gtag('event', 'page_view', {
                page_title: sku,
                page_path: url
            });
        }
    }
};

// log specific events happening
export const event = ({ action, params }) => {
    if (!process.env.REACT_APP_GA) return;

    console.log('GA', action, params);

    window.gtag('event', action, params)
}

// track product
export const product = (sku, name) => {
    if (!process.env.REACT_APP_GA) return;

    console.log('GA', sku, name);

    window.gtag('event', 'select_content', {
        content_type: "product",
        items: [
            {
                id: sku,
                name
            }
        ]
    });
};