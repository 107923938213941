import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTabForSlider, getDefaultTextareaCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider par catégorie',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        colorButtonTab: {
            label: 'Couleur des boutons',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        colorButtonTabActive: {
            label: 'Couleur du bouton actif',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        backgroundButtonTab: {
            label: 'Couleur de fond du bouton',
            type: FormInputTypes.COLOR,
            value: '#53839D'
        },
        backgroundButtonTabActive: {
            label: 'Couleur de fond du bouton actif',
            type: FormInputTypes.COLOR,
            value: '#004369'
        },
    },
    blocks:{
        logo: {
            type: EditableTypes.IMAGE,
            name: "Image",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "http://via.placeholder.com/400x100/53839D/FFFFFF?text=Logo"
                }
            }
        },
        text: getDefaultTextareaCms("Texte", "Sed mi quam, dictum at malesuada ac, rutrum in libero. Curabitur sit amet scelerisque libero. Maecenas non tellus sed lorem congue ullamcorper eget posuere diam. Integer ornare elementum congue. Cras in turpis nec urna sagittis tincidunt id sed dui. Sed suscipit nulla et velit molestie tristique.", true, '#53839D'),
        categories:{
            title: "Catégories",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_BY_TAB,
            buttonAdd: 'une catégorie',
            limit: 3,
            config: [
                getDefaultTabForSlider(),
                getDefaultTabForSlider(),
            ]
        },
        link: getDefaultLinkCms("Lien", "Découvrir", "#", '#FFFFFF', '#000000', false, true),
    }
};