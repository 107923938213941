import React from 'react';
import {Grid,Box} from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import IcomoonReact from "icomoon-react";
import iconSet from '../../../../../assets/selection.json'
import Typography from '../../../../ui/typography/Typography'
import SyncIcon from '@material-ui/icons/Sync';
import Button from '../../../../ui/button/Button'
import Icon from '../../../../ui/icons/Icon'
import { handleSort } from '../../../../../js/utils/sortingBy';
import { withTranslation } from 'react-i18next';

const HeadingInfos = styled.div`
    display:grid;
    grid-template-columns : auto 1fr;
    gap:10px;

`
const Header = styled(Grid)`
    background:white;
    padding:30px;
    border-bottom : 1px solid ${colors.grey.lighter.hue900};
    padding-right: 40px !important;
`

const HeaderIcon = styled(Box)`
    background : ${colors.black.regular};
    height:48px;
    width:48px;
    border-radius:5px;
    justify-content:center;
    display : grid;
    align-content:center;
`

const HeaderTitle = styled(Typography)`
    color:${colors.black.regular};
`
const HeaderStats = styled(Box)`
    width:100%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;  
    @media screen and (max-width: 1270px){
        padding-left: 40px;
        gap: 0px;
        justify-content: flex-start;  
        flex-wrap: wrap;
    } 
`

const HeaderStatsItem = styled.div`
    color : ${props=> props.color ?? colors.black.regular};
    display: grid;
    align-items: center;
    justify-self: end;
    grid-template-columns: 1fr auto;
    gap:5px;
    @media screen and (max-width: 1270px){
        display: flex;
        width: 33%;
        margin-bottom: 8px;
    }
`
const HeaderButtons = styled(Box)`
    display:flex;
    gap:10px;
`;

const HeaderButton = styled(Button)`
    margin-top: 15px;
    .MuiButton-label{
        gap :5px;
        display:flex;
        align-items:center;
    }
`

const CircleStatus = styled.div`
    background-color: ${props => props.colorcircle ? colors.green.regular : process.env.REACT_APP_MODE_SPREAD === "hub" ? colors.red.regular : colors.grey.lighter.hue600};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-left: 4px;
`;
const SubText = styled.div`
    display: flex;
    align-items: center;
    gap:10px; 
    white-space: nowrap;
    @media screen and (max-width: 1250px){
        white-space: break-spaces;
    }
`;

function CatalogHeader(props){
    let {catalogName,autosync,counter,catalogStatus,catalogType,handlers,buttonsReady} = props;

    const stats = [
        {
            label: props.t("catalogs.catalogs.status"),
            color : colors.blue.darker.hue300,
            value:catalogType,
        },
        {
            label: props.t("catalogs.catalogs.state"),
            color : catalogStatus ? colors.green.regularv2 : colors.red.regular,
            value : catalogStatus ? props.t("catalogs.catalogs.active") : props.t("catalogs.catalogs.inactive"),
            isStatus: true
        },
        {
            label: props.t("catalogs.catalogs.products"),
            icon:'picto-produit',
            value : counter.products || "n/a"
        },
        {
            label: props.t("catalogs.catalogs.categories"),
            icon:'picto-categorie',
            value : counter.categories

        },
        {
            label:'Assets',
            icon:'icon-asset',
            value : counter.assets

        },
    ]

    const buttons = [
        {
            label : props.allCategoriesReady ? props.t("catalogs.catalogs.catgorizeProducts") : props.t("catalogs.catalogs.loading..."),
            icon : 'picto-produit',
            handler : handlers.productsCategories,
            disabled : !props.allCategoriesReady,
        },
        {
            label : 'Sélectionner les produits',
            icon : 'picto-toggle',
            handler : handlers.productsSelect,
        },
        {
            label : props.attributesSelected && props.allGroupsReady ? props.t("catalogs.catalogs.manageAttributes") : props.t("catalogs.catalogs.loading..."),
            icon : 'picto-attribut',
            handler : handlers.attributes,
            disabled : !props.attributesSelected || !props.allGroupsReady,
        },
        {
            label : props.t("catalogs.catalogs.accessSynchro"),
            icon : 'sync',
            iconType : 'mui',
            handler : handlers.synchro,
        }
    ]
    return(
        <Header container>
            <Grid item xs={12} sm={5} >
                <HeadingInfos>
                    {/* <HeaderIcon>
                        <IcomoonReact iconSet={iconSet} color="#FFF" size={28} icon={"icon-catalogue"} />
                        
                    </HeaderIcon> */}
                    <div style={{display:'grid'}}>
                        <HeaderTitle variant="h3">{props.t("catalogs.catalogs.toManage")} <strong>{catalogName}</strong></HeaderTitle>
                        <SubText>
                            <Typography variant="body2" style={{color:autosync ? colors.green.regularv2 : colors.orange.regular,display:'inline-block'}}>
                                <SyncIcon style={{fontSize:'inherit',verticalAlign:'bottom',marginRight:5}}/>
                                    { autosync ? props.t("catalogs.catalogs.synchroProduct") : props.t("catalogs.catalogs.noSynchroProduct")}
                            </Typography>

                            <Button 
                                bgcolor="transparent" 
                                bgcolorhover="transparent" 
                                colorhover={colors.black.regular} 
                                color={colors.blue.darker.hue300}
                                fontweight="normal"
                                style={{padding:'0 3px',marginTop:0,marginBottom:0,display:'inline-flex',alignItems:'center'}}
                                disableElevation={true}
                                onClick={handlers.editCatalog}
                                disabled={!buttonsReady}
                            >
                                <Icon icon="settings" color='inherit' style={{marginRight:5}}/>
                                {props.t("catalogs.catalogs.modify")}
                            </Button>
                        </SubText>
                        
                    </div>

                </HeadingInfos>

            </Grid>
            <Grid item xs={12}>
                <HeaderButtons>
                        {buttons.map(button => (
                                
                            <HeaderButton bgcolor={colors.green.regularv2} shadowcolor={colors.green.darker}  onClick={button.handler} disabled={!buttonsReady || button.disabled}>
                                {button.icon ? <Icon type={button.iconType ?? 'icomoon'} icon={button.icon} color="#FFF" disabled={!buttonsReady || button.disabled} size={16}/>: null}
                                <span>{button.label}</span>
                                
                            </HeaderButton >
                        ))}
                </HeaderButtons>
            </Grid>
        </Header>
    )
}

export default withTranslation()(CatalogHeader);