import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Email de succès",
    key: "email_register",
    type: "email_register",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        products: {
            label: 'Produits',
            type: FormInputTypes.PRODUCTS,
            value: []
        }
    },
    blocks: {
        firstBlock: {
            id: uuidv4(),
            name: "Paramétrage du bloc",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
        },
        logo: getImageDefault(null, "Logo"),
        title: getDefaultText("Titre", "BRAVO !", 40, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        subtitle: getDefaultText("Sous-titre", "Votre inscription au tirage au sort a bien été enregistrée !",  24, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        description: getDefaultText("Description", "Rendez-vous le JJ/MM/AAAA pour le résultat final*",  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        titleList: getDefaultText("Titre de la liste", "Votre liste de cadeaux :",  18, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        illustrations: getImageDefault(null, "Illustration"),
        conditions: getDefaultText("Conditions", "*en cas de victoire vous serez contacté par mail.",  14, "#000000", null, 'initial', 'center', null),
        secondBlock: {
            id: uuidv4(),
            name: "Paramétrage du bloc 2",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#F0F7FA'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
        },
        reassurance: getDefaultText("Texte d'accroche", "L’aventure ne s’arrête pas là ! De nombreuses surprises à découvrir !",  25, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        button: getDefaultLink("Boutton", "C'est par ici !", null, 20, '#fff', '#53839D', null, null, null, 1), 
        footer: {
            id: uuidv4(),
            name: "Paramétrage du bloc 2",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#F0F7FA'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
        },
        footerText: getDefaultTextarea("Texte du footer", "Jeu sans obligation d’achat. Valable en France métropolitaine (Corse comprise).  X a chargé la société SINFIN (1 avenue Louis François Armand – 10340 Rosières-près-Troyes) de collecter votre adresse mail pour vous permettre de participer au jeu concours à l’occasion de l’activation promotionnelle. L’adresse email que vous avez renseignée dans le formulaire sera utilisée par X pour confirmer votre participation. Ces informations seront supprimées dans un délai de 4 mois à compter de la fin du jeu-concours. Elles seront accessibles aux équipes X et SINFIN. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que  vous avez conclu avec X en acceptant le règlement du jeu-concours, consultable ici. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours.", true, "#53839D", null, 'initial', 'initial', 12),
    }
};
