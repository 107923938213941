import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getOfferListLogos, getImageDefault } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Texte et image',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        subTitle: getDefaultText('Sous-titre de la slide', 'Sous-titre de la slide', 39, '#000', null, 'initial', 'initial'),
        content_text: getDefaultTextarea("Titre", "Description", true, "#000", null, 'initial', 'initial', 16),
        listLogos:  {
            id: uuidv4(),
            title: "Liste des logos",
            name: "Liste des logos",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.OFFER_OFFER_TEXT_WITH_IMAGE_SIMPLE_LIST_LOGOS,
            limit: 3,
            config: [
                getOfferListLogos(),
                getOfferListLogos(),
                getOfferListLogos(),
            ]
        },
        image: getImageDefault(),
    }
};

