import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault, getDefaultTextarea } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Référence client',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        descriptionBlock: {
            id: uuidv4(),
            name: "Bloc d'explication",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#f0f7fa',
                },
            },
            blocks:{
                logo: getImageDefault(null, 'Logo'),
                image: getImageDefault(),
                content: getDefaultTextarea("Description", "<p>Refonte de l’UI/UX de l’application citoyenne « Ville de Troyes » !</p><p>Toujours plus collaborative, l’application propose aux utilisateurs un panel de nouvelles fonctionnalités comme « Je circule » ou encore « Je trie ».</p>", true, '#000', null, null, null, 16),
            }
        },
    }
};

