import React, { useEffect } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { Button, Grid, Typography } from '@material-ui/core'
import StopIcon from '@material-ui/icons/Stop';
import MicIcon from '@material-ui/icons/Mic';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';


const GridCustom = styled(Grid)`
    display: flex;
    align-item: center;
    justify-content: center;
`
const ButtonCustom = styled(Button)`
    min-width: auto;
    z-index: 2;
`
const Transcript = styled.div`
    background: white;
    border-radius: 5px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.23);
    z-index: 2;
    padding: 16px;
    width: max-content;
    max-width: 100%;
    min-width: 100%;
    word-break: break-word;
    line-height: 20px;
    right: 0;
    top: 41px;
    display: ${props => props.displayed ? 'block' : 'none'}
`

const SpeechComponent = (props) => {
    const { transcript, finalTranscript, listening } = useSpeechRecognition();

    useEffect(() => {
        if(finalTranscript){
            const regex = /(<([^>]+)>)/ig;
            let strippedValue = props.value.replace(regex, '');
            let newText = strippedValue + ' ' + finalTranscript
            props.callback(newText)
        }
    }, [finalTranscript, props])

    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        return null
    }
    const handleSpeech = () => {
        if (!listening){
            SpeechRecognition.startListening({language: 'fr-FR'})
        }else{
            SpeechRecognition.stopListening()
        }
    }
    

    return (
        <div style={{position: 'relative'}}>
            <GridCustom>
                <ButtonCustom onClick={() => handleSpeech()}>{listening ? <StopIcon style={{fill: 'red', width: 20, height: 20}}/> : <MicIcon style={{fill: colors.blue.darker.hue300,width: 20, height: 20}} />}</ButtonCustom>
            </GridCustom>
            <Transcript displayed={listening ? true : false}>{transcript}</Transcript>
        </div>
    )
}
export default SpeechComponent