import React from 'react';
import { useDrop } from 'react-dnd';

export default ({ addComponent, accept, index, show }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: accept ? [...accept] : [],
		drop: (item, monitor) => {
            addComponent(item.type, index);
        },
		collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
		}),
    });


    return (
        <div
            ref={drop}
            style={{
                opacity: 1,
                fontSize: 18,
                fontWeight: 'normal',
                cursor: 'move',
                backgroundColor: '#fff',
                color: '#000',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                borderRadius: 0,
                overflow: 'hidden',
                position: 'relative',
                borderStyle: 'dashed',
                borderWidth: 1,
                alignSelf: 'center',
                margin: '20px auto',
                padding: 20,
                // display: 'flex'
                display: canDrop || show ? 'flex' : 'none',
            }}
        >
            <span>Vous pouvez placer le composant ici</span>

            {isOver && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                        opacity: 0.3,
                        backgroundColor: 'rgb(159, 255, 244)'
                    }}
                />
            )}
        </div>
    )
}