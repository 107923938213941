import React from 'react';
import Button from '@material-ui/core/Button';
import colors from '../../../config/theme/colors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import styled from 'styled-components';
import IcomoonReact from "icomoon-react";
import iconSet from '../../../assets/selection.json'
import { CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconeSynonyme from "../../../assets/pictos/ico-synonyme.svg"
import FolderIcon from '@material-ui/icons/Folder';

const defaultBg = colors.blue.darker.hue300;
const defaultBgHover = colors.blue.darker.hue300;
const defaultColor = '#fff';
const defaultFontSize = '14px';


const ButtonCustom = styled(Button)`
    margin: ${props => props.margin ? props.margin : '10px 0'};
    text-transform: initial;
    text-transform: ${props => props.texttransform ? props.texttransform + '!important' : 'none'};
    font-weight: ${props => props.fontweight ?? 'bold'};
    color: ${props => props.colortext ? props.colortext : defaultColor};
    background-color: ${props => props.bgcolor ? props.bgcolor : defaultBg};
    box-shadow:  none;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    top: 0;
    border: ${props => (!props.disabled && props.border) ? props.border : ''};
    border-radius: 0;
    padding: 14px 32px;
    box-sizing: border-box;
    height: ${props => props.height ? props.height : '40px'};

    &:hover{
        background-color: ${props => props.disabled ? '#e0e0e0' : props.bgcolorhover ? props.bgcolorhover : defaultBgHover};
        background-color: ${props => (props.shadowcolor && props.bgcolor) ? props.bgcolor : (props.shadowcolor) ? defaultBg : ''};
        background-color: ${props => props.border && !props.bgcolorhover ? props.colortext : ''};
        box-shadow:  none;
        color: ${props => props.colorhover ? props.colorhover : ''};
        // border-color: ${props => props.border ? props.bgcolor : ''};
        span{
            opacity: ${props => props.bgcolorhover && props.bgcolorhover !== colors.green.regular ? 1 : 0.5};
        }
    }
    span{
        font-size: ${props => props.fontSize ? `${props.fontSize}px` : defaultFontSize};
        line-height: 1;
        @media screen and (max-width: 1280px){
            font-size: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 12px;
        }
    }
    .MuiButton-label{
        ${props => props.contentstyle ? props.contentstyle : ''};
    }
    @media screen and (max-width: 1000px){
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &.Mui-disabled{
        color:rgba(0, 0, 0, 0.26)!important;
        background : ${props => props.bgcolor != 'transparent' ? 'rgba(0, 0, 0, 0.12)' : props.bgcolor};
        // box-shadow: ${props => props.bgcolor != 'transparent' ? '0px 3px 0px 0px #00000045' : `0px 3px 0px 0px ${props.bgcolor}`};
    }
`

const OurButton = (props) => {
    const { bgcolor, fontweight, bgcolorhover, color, colorhover, text, disabled, onClick, disableElevation, margin,disableRipple, border, shadowcolor, fontsize, style, textTransform, contentStyle, className, stopPropagation,loading } = props;
    return (
        <ButtonCustom
            variant="contained"
            disabled={disabled?disabled:loading?loading:false}
            onClick={stopPropagation ? (e) => { e.stopPropagation(); onClick(e) } : (e) => { onClick(e) }}
            disableElevation={true}
            disableRipple={disableRipple}
            bgcolor={bgcolor}
            bgcolorhover={bgcolorhover}
            shadowcolor={shadowcolor}
            colortext={color}
            colorhover={colorhover}
            border={border}
            fontSize={fontsize}
            style={style}
            texttransform={textTransform}
            contentstyle={contentStyle}
            className={className}
            fontWeight={fontweight}
            margin={margin}            
        >
            {props.loading ? <CircularProgress size={10} style={{marginRight: 5}} /> : ''}
            {props.deleteIcon ? (<DeleteSharpIcon style={{ paddingRight: 5, width: '1em', height: '1em' }} />) : null}
            {props.arrow === 'left' ? (<ArrowBackIosRoundedIcon style={{ paddingRight: 5, width: '0.8em', height: '0.5em' }} />) : ''}
            {props.icon === "add" ? <AddIcon style={{ marginLeft: 5, width: '15px', height: 'auto' }} /> : null}
            {props.icon === "exitApp" ? <ExitToAppIcon style={{ fontSize: 12, marginRight: 4 }} /> : null}
            {props.icon === "edit" ? <EditSharpIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof props.icon !== "string" ? props.icon : null}
            {props.icon === "check" ? <CheckCircleIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof props.icon !== "string" ? props.icon : null}
            {props.icon === "folder" ? <FolderIcon style={{ marginRight: 10, fontSize: '16px' }} /> : typeof props.icon !== "string" ? props.icon : null}
            {props.downloadIcon ? (<GetAppIcon style={{ marginRight: 10 }} />) : ''}
            {props.icon === "dicoIcon" ? (<img src={IconeSynonyme} alt='Icone synonyme' style={{marginRight:5}}/>) : ''}
            {text ? text : ''}
            {props.children ? props.children : ''}
            {props.arrow === 'right' ? (<ArrowForwardIosRoundedIcon style={{ paddingLeft: 5, width: '0.8em', height: '0.5em' }} />) : ''}
            {props.icomoon ? <IcomoonReact iconSet={iconSet} color={color} size={props.fontsize ? props.fontsize + 5 : 19} icon={props.icomoon} style={{ marginLeft: 5 }} /> : null}
        </ButtonCustom>
    );
};

export default OurButton