import React, { useEffect } from 'react';
import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import styled from 'styled-components';
import ListingProducts from './components/ListingProducts';
import { eventService } from '../../../../js/services/event.service';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import request from '../../../../js/utils/fetch';

const SelectCustom = styled(Select)`
    border-radius: 0; 
    // width: 100%; 
    max-width: 170px; 
    margin-right: 16;
    fieldset{
        border: none;
    }
    :before, &:after{
        display: none !important;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 16px;
        padding-left: 8px;
        margin-right: 16px;
        color: ${colors.blue.darker.hue300};
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.blue.darker.hue300};
    }
    & > p{
        margin: 0;
    }
`;

function ListNewProducts(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [reloadBrands, setReloadBrands] =  React.useState(false);
    const [displaySeasons, setDisplaySeasons] =  React.useState('none');

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
    }, [])

    const handleLang = (event) => {
        setCurrentLang(event.target.value);
        eventService.fire();
    };

    const handleExportProducts = () => {
        try {
            props.startLoading();
            let data = new FormData();
            data.append('name', 'export-nouveautes');
            data.append('format', 'csv');
            data.append('locale', 'fr_FR');
            data.append('status', 'all');
            data.append('image', 'all');
            data.append('onlyNew', "true");

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.csv'}`, '_blank');
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };

    return (
        <div>
            <TopPanel
                title={props.t('products.workflow.title')}
                subtitle={props.t('products.workflow.subtitle')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container direction='column'>
                <Grid container>
                    <ListingProducts
                        productBypage = {{
                            card: [12,24,48,72],
                            list: [30,40,50]
                        }}
                        variables = {{
                            onlyNew: true
                        }}
                        isCatalog = {false}
                        nbperpage = {12}
                        currentLang={currentLang} 
                        handleLang={handleLang}
                        windowWidth={props.windowWidth}
                        inAccordion={
                            <>
                                <Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Visualiser par :</Typography>
                                <SelectCustom
                                    id="collection-select"
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(event) => this.handleChangeDisplay(event)}
                                    disabled={false}
                                    value={displaySeasons}
                                    style={{marginLeft: 16}}
                                >
                                    <MenuItem value={'none'}>Toutes les saisons</MenuItem>
                                </SelectCustom>
                            </>
                        }
                        handlerExport={handleExportProducts}
                        // openForm={openForm}
                        // callbackFilters={this.callbackFilters}
                        // previousFilters={getItemsLocalStorage("PIM_FILTERS")}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListNewProducts)))));