import React from 'react';
import { Box, Grid, Typography, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import { MoreVert } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import OurMenu from '../../../ui/menu/Menu';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`
const CustomItem = styled(Grid)`
    h4 {
        font-size: 13px;
    }
`
const CustomButton = styled(Button)`
    width: 100%;
    height: 40px;
    margin: 0px;
`


export default function CardCrossSell(props) {

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            isDisabled: true,
            color: colors.blue.darker.hue300,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                // props.toggleDuplicateDialog(props.data.node.id)
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.toggleDeleteModal(props?.data.node)
            }
        }
    ]

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    // 
    return (
        <ContainerCard>
            <Grid container justifyContent='space-between' direction='column' style={{ height: '100%' }}>
                <Grid container direction='column'>
                    <Grid container justifyContent='space-between' style={{marginBottom: '15px'}}>
                        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '25px' }}>{props.data.node.name}</Typography>
                        <Box mt={'3px'}>
                            <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                        </Box>
                    </Grid>
                    {/* // TODO: Add description (Form, cards and backend) */}
                    {/* <Typography variant="h4" style={{ fontSize: '13px', marginBottom: '13px' }}>{props.data.node.name}</Typography> */}
                    <Grid container direction="column" style={{ marginBottom: '20px' }}>
                        <Grid container>
                            <CustomItem item xs={6}><Typography variant="h4">Statut :</Typography></CustomItem>
                            <Grid container xs={6}><StatusInfo status={props.data.node.status} width={'auto'} /></Grid>
                        </Grid>
                        <Grid container>
                            <CustomItem item xs={6}><Typography variant="h4">Nombre de produits :</Typography></CustomItem>
                            <CustomItem item xs={6}><Typography variant="h4">{props.data.node.crossSellProducts.edges.length}</Typography></CustomItem>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <CustomButton
                        text={props.textButton}
                        icon={"edit"}
                        color={colors.blue.darker.hue300}
                        colorHover={colors.white}
                        bgcolorhover={colors.blue.lighter.hue600}
                        bgcolor={colors.blue.lighter.hue900}
                        border={`1px solid ${colors.blue.darker.hue300}`}
                        stopPropagation={true}
                        onClick={() => props.handlerButton(props.data.node)}
                    />
                </Grid>
            </Grid>
        </ContainerCard>
    );
}