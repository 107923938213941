import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpersBook';

export default {
    name: "Section du flipbook",
    blocks: {
        title: getDefaultText("Titre", "Remplissez votre liste de cadeaux"),
        subtitle: getDefaultText("Sous-titre", "pour vous inscrire au tirage au sort et tentez de remporter 1 an de cadeaux LEGO®"),
        bottomText: getDefaultText("Texte bas de section", "TENTEZ DE GAGNER <br><span>1 AN DE CADEAUX LEGO®</span><br>ET 15 LISTES DE CADEAUX LEGO", true),
        subBlock: {
            id: uuidv4(),
            name: "Bloc Flipbook",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{},
            blocks:{
                button: getDefaultLink("Bouton", "Valider ma liste"),
            }
        },
    }
};
