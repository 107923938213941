import styled from 'styled-components';

const H1Custom = styled.h1`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const H2Custom = styled.h2`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const H3Custom = styled.h3`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const H4Custom = styled.h4`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const H5Custom = styled.h5`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const H6Custom = styled.h6`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const NormalText = styled.p`
    font-size: ${props => props.fontSize}px;
    color: ${props => props.color};
    line-height: 1.2;
    font-family: 'DINBold', sans-serif;
    font-weight: ${props => props.fontWeight};
    margin: 20px 0;
    @media screen and (max-width: 1400px){
        font-size: ${props => props.fontSize ? `${(4.7 * props.fontSize / 100) > 3 ? 4.7 * props.fontSize / 100 : 4}vw` : '4vw'};
    }
    @media screen and (max-width: 650px){
        margin-top: 0;
        font-size: ${props => props.fontSize ? `${(5.5 * props.fontSize / 100) > 4 ? 5.5 * props.fontSize / 100 : 5}vw` : '5vw'};
    }
`;

const Title = (props)=>{
    switch (props.tag) {
        case "h1":
            return <H1Custom {...props}>{props.children}</H1Custom>
        case "h2":
            return <H2Custom {...props}>{props.children}</H2Custom>
        case "h3":
            return <H3Custom {...props}>{props.children}</H3Custom>
        case "h4":
            return <H4Custom {...props}>{props.children}</H4Custom>
        case "h5":
            return <H5Custom {...props}>{props.children}</H5Custom>
        case "h6":
            return <H6Custom {...props}>{props.children}</H6Custom>
        case "p":
            return <NormalText {...props}>{props.children}</NormalText>
        default:
            return <NormalText {...props}>{props.children}</NormalText>
    }
}

export default Title