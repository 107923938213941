import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RulesBlock from '../RulesBlock/RulesBlock';
import Div from '../../../../shared/components/CustomDiv';

const FooterBook = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        spread = false,
    } = props;

    const clickRules = () => {
        setIsOpen(true)
        if (document.getElementById('flipBook')){
            document.getElementById('flipBook').style.zIndex = "2";
        }
        if (document.getElementsByClassName('postCardWrapper')){
            document.getElementsByClassName('postCardWrapper')[0].style.zIndex = "3";
        }
    }

    return (
        <>
            {
                isOpen ? 
                    <RulesBlock isOpen={isOpen} onClose={() => setIsOpen(false)} lang={props.lang}/>
                : null
            }
            <footer onClick={() => inputCallback(id)} style={{position: 'relative'}}>
                { !preview && props.children}
                <div className="banner">
                    {/* <Div data={data} preview={preview} component={id} block={'footerText'} inputCallback={inputCallback} nopadding={true} /> */}
                    {
                        props.lang === "fr_FR" ?
                            <p>Jeu sans obligation d’achat du 20/10/2021 au 05/12/2021 inclus. Valable en France métropolitaine (Corse comprise). LEGO SAS : (75 rue de Tocqueville 75017 Paris) a chargé la société SINFIN (1 avenue Louis François Armand - 10340 Rosières-près-Troyes) de collecter votre adresse e-mail pour vous permettre de participer au jeu concours LEGO® à l’occasion de l’activation promotionnelle wishlist de Noël. L’adresse e-mail que vous avez renseignée dans le formulaire sera utilisée par LEGO® pour confirmer votre participation. Ces informations seront supprimées dans un délai de 4 mois à compter de la fin du jeu concours. Elles seront accessibles aux équipes LEGO® et SINFIN. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que vous avez conclu avec LEGO® en acceptant le règlement du jeu-concours, consultable <span onClick={() => setIsOpen(true)} style={{textDecoration: 'underline', fontWeight: 'bold'}}>ici</span>. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours.</p>
                        : <p>Juego sin obligación de compra del 12 de noviembre de 2021 a las 23:59 (CET) del 19 de diciembre de 2021.El sorteo está abierto a todas las personas físicas residentes en España. Lego SA, con sede en C/ Orense, 34 - Planta 4, 28020 Madrid, España organiza, en colaboración con Sinfin, con sede en 1 avenue Louis François Armand 10430 Rosières-près-troyes, Francia, para recopilar su dirección de correo electrónico para permitirle participar en el concurso LEGO® para la activación promocional de la lista de deseos de Navidad. LEGO® utilizará la dirección de correo electrónico que ingresó en el formulario para confirmar su participación. Esta información se eliminará dentro de los 4 meses posteriores al final de la competencia. Serán accesibles para los equipos LEGO® y SINFIN. El uso de sus datos personales es necesario para la ejecución del contrato que ha celebrado con LEGO® al aceptar las reglas del concurso, que se pueden consultar <span onClick={() => setIsOpen(true)} style={{textDecoration: 'underline', fontWeight: 'bold'}}>aquí</span>. Si no desea proporcionar esta información, lamentablemente no podrá participar en el concurso.</p>
                    }
                </div>
            </footer>
        </>
    );
};

FooterBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default FooterBook;
