import {searchQueries} from '../constants/queries-type';
import {client} from '../../index'

function findKey (arr,key){
    let keys = key.split('.');
    let item = arr;
    for(let i in keys){
        item = item[keys[i]]
    }
    return(item)
}

let timer = null;

export const searchItem = (event,identifier,idFormat,variables = {},options ={}) =>{
    return new Promise(async(resolve,reject)=>{
        let value = event.target.value;
        let delay = options.delay ?? 0;
        let minCharacter = options.minCharacter ?? 0;
        
        clearTimeout(timer)
        if(value?.length > minCharacter){
            timer = setTimeout(async() => {
                let query = searchQueries[identifier];
                variables['needle'] = value;
                let searchResults = await client.query({
                    query: query,
                    variables: variables,
                    fetchPolicy: 'no-cache'
                })

                searchResults = searchResults.data[identifier+'Datas'].edges;
                let wrapper = options.wrapper ? options.wrapper : e => parseInt(e.replace(idFormat,''),10);
                let key = 'node.'+identifier+'.id';
                let uniqueResults = makeUnique(searchResults,key,wrapper) 
                resolve(uniqueResults?.length > 0 ? uniqueResults : false);

            }, delay);
        } 
        else{
            resolve(null)
        } 

    })
}

/**
 * Supprime des résultats dupliqués
 * @param {Object} result - Resultats à transformer (result.(.......).edges)
 * @param {string} keys - Key sur laquelle vérifier l'unicité 
 * @param {Function} wrapper - Fonction englobant la key (optionnel)
 * @returns 
 */
export const makeUnique = (result,keys,wrapper) => {
    if(result){
        let results = result.map(e=>{
            if(wrapper){
                return wrapper(findKey(e,keys));
            } else {
                return findKey(e,keys)
            }
        })

        let list=[];

        let uniqueResults = results.filter(e=>{
            if(!list.includes(e)){
                list.push(e);
                return e;

            }
        })
        return(uniqueResults);
    } else {
        return false;
    }


}




