import { gql } from 'apollo-boost';

//----- Récupération des assetFooter
export const GET_ASSET_FOOTERS = gql`
{
    assetFooters{
        edges{
            node{
                id
                name
                startedAt
                endedAt
                isDefault
                active
                asset{
                    id
                }
                assetFooterColumns{
                    edges{
                        node{
                            id
                            assetFooterColumnLinks{
                                edges{
                                    node{
                                        id
                                        name
                                        link
                                        position
                                        isExternal
                                    }
                                }
                            }
                            name
                            position
                        }
                    }
                }
            }
        }
    }
}
`;

//----- Récupération des assetFooter
export const GET_ASSET_FOOTERS_PAGINATION = gql`
query assetFootersPagination ($nbperpage: Int, $cursor: String, $cursorLast: String, $asset: String){
    assetFooters(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset){
        totalCount
        pageInfo {
            startCursor
            endCursor
        }
        edges{
            node{
                id
                name
                startedAt
                endedAt
                isDefault
                active
                asset{
                    id
                }
                assetFooterColumns{
                    edges{
                        node{
                            id
                            assetFooterColumnLinks{
                                edges{
                                    node{
                                        id
                                        name
                                        link
                                        position
                                        isExternal
                                    }
                                }
                            }
                            name
                            position
                        }
                    }
                }
            }
        }
    }
}
`;


//----- Ajouter un assetFooter
/*
Exemple de variables
{
    "name" : "New assetFooter",
    "startedAt": "2022-02-14",
    "endedAt": "2022-02-28",
    "isDefault": false,
    "active": false,
    "asset": "/api/assets/2"
}
*/
export const CREATE_ASSET_FOOTER = gql`
mutation createAssetFooter($name: String!, $startedAt: String, $endedAt: String, $isDefault: Boolean!, $active: Boolean!, $asset: String!){
    createAssetFooter(input: {name: $name, startedAt: $startedAt, endedAt: $endedAt, isDefault: $isDefault, active: $active, asset: $asset}){
        assetFooter{
            id
            name
            startedAt
            endedAt
            isDefault
            active
            asset{
                id
            }
        }
    }
}
`;

//----- Modifier un assetFooter
/*
Exemple de variables
{
    "id": "/api/asset-footers/2",
    "name" : "Demo assetFooter",
    "startedAt": "2022-01-02",
    "endedAt": "2022-02-11",
    "isDefault": true,
    "active": false,
    "asset": "/api/assets/3"
}
*/
export const UPDATE_ASSET_FOOTER = gql`
mutation updateAssetFooter($id: ID!, $name: String!, $startedAt: String, $endedAt: String, $isDefault: Boolean!, $active: Boolean!, $asset: String!){
    updateAssetFooter(input: {id: $id, name: $name, startedAt: $startedAt, endedAt: $endedAt, isDefault: $isDefault, active: $active, asset: $asset}){
        assetFooter{
            id
            name
            startedAt
            endedAt
            isDefault
            active
            asset{
                id
                assetType{
                    id
                    libelle
                }
            }
        }
    }
}
`;

//----- Supprimer un assetFooter
/*
Exemple de variables
{
    "id" : "/api/asset-footers/3"
}
*/
export const DELETE_ASSET_FOOTER = gql`
mutation deleteAssetFooter($id: ID!){
    deleteAssetFooter(input: {id:$id}){
        assetFooter{
            id
        }
    }  
}
`;

//----- Récupération d'un seul assetFooter
/*
Exemple de variables
{
    "id" : "/api/asset-footers/2"
}
*/
export const GET_ASSET_FOOTER = gql`
query assetFooter($id: ID!){
    assetFooter(id: $id){
        id
        name
        startedAt
        endedAt
        isDefault
        active
        asset{
            id
        }
        assetFooterColumns{
            edges{
                node{
                    id
                    name
                    position
                    assetFooterColumnLinks{
                        edges{
                            node{
                                id
                                name
                                position
                                isExternal
                            }
                        }
                    }
                }
            }
        }
    }
}
`;




//----- Récupération des assetFooterColumns
export const GET_ASSET_FOOTERS_COLUMNS = gql`
{
    assetFooterColumns{
        edges{
            node{
                id
                assetFooter{
                    id
                    name
                    startedAt
                    endedAt
                    isDefault
                    active
                    asset{
                        id
                    }
                }
                name
                position
                assetFooterColumnLinks{
                    edges{
                        node{
                            id
                            name
                            position
                            isExternal
                        }
                    }
                }
            }
        }
    }
}`;

//----- Ajouter un assetFooterColumn
/*
Exemple de variables
{
    "assetFooter": "/api/asset-footers/1",
    "name": "Demo mentions légales",
    "position": 2
}
*/
export const CREATE_ASSET_FOOTER_COLUMN = gql`
mutation createAssetFooterColumn($assetFooter: String!, $name:String!, $position: Int){
    createAssetFooterColumn(input: {assetFooter: $assetFooter, name: $name, position: $position}) {
        assetFooterColumn{
            id
            assetFooter{
                id
            }
            name
            position
        }
    }
}
`;

//----- Modifier un assetFooterColumn
/*
Exemple de variables
{
    "id": "/api/asset-footer-columns/1",
    "assetFooter" : "/api/asset-footers/3",
    "name": "Demo 1.1",
    "position": 2
}
*/
export const UPDATE_ASSET_FOOTER_COLUMN = gql`
mutation updateAssetFooterColumn($id: ID!, $assetFooter: String!, $name: String, $position: Int){
    updateAssetFooterColumn(input: {id: $id, assetFooter: $assetFooter, name: $name, position: $position}){
        assetFooterColumn{
            id
            assetFooter{
                id
            }
            name
            position
        }
    }
}
`;

//----- Supprimer un assetFooterColumn
/*
Exemple de variables
{
    "id" : "/api/asset-footer-columns/3"
}
*/
export const DELETE_ASSET_FOOTER_COLUMN = gql`
mutation deleteAssetFooterColumn($id: ID!){
    deleteAssetFooterColumn(input: {id:$id}){
        assetFooterColumn{
            id
        }
    }  
}
`;

//----- Récupération d'un seul assetFooterColumn
/*
Exemple de variables
{
    "id" : "/api/asset-footer-columns/1"
}
*/
export const GET_ASSET_FOOTER_COLUMN = gql`
query assetFooterColumn($id: ID!){
    assetFooterColumn(id: $id){
        id
        assetFooter{
            id
            name
            startedAt
            endedAt
            isDefault
            active
            asset{
                id
            }
        }
        assetFooterColumnLinks{
            edges{
                node{
                    id
                    name
                    position
                }
            }
        }
        name
        position
    }
}
`;

//----- Récupération des assetFooterColumnsLinks
export const GET_ASSET_FOOTERS_COLUMNS_LINKS = gql`
{
    assetFooterColumnLinks{
        edges{
            node{
                id
                assetFooterColumn{
                    id
                    assetFooter{
                        id
                        asset{
                            id
                        }
                        name
                        startedAt
                        endedAt
                        isDefault
                        active
                    }
                    name
                    position
                }
                link
                name
                position
                isExternal
            }
        }
    }
}
`;

//----- Ajouter un assetFooterColumnLink
/*
Exemple de variables
{
    "assetFooterColumn": "/api/asset-footer-columns/3",
    "name": "Demo Link",
    "position": 2
}
*/
export const CREATE_ASSET_FOOTER_COLUMN_LINK = gql`
mutation createAssetFooterColumnLink($assetFooterColumn: String!, $name:String!, $link:String!, $position: Int!, $isExternal: Boolean!){
    createAssetFooterColumnLink(input: {assetFooterColumn: $assetFooterColumn, name: $name, link: $link, position: $position, isExternal: $isExternal}) {
        assetFooterColumnLink{
            id
            assetFooterColumn{
                id
            }
            link
            name
            position
            isExternal
        }
    }
}
`;

//----- Modifier un assetFooterColumnLink
/*
Exemple de variables
{
    "id": "/api/asset-footer-column-links/1",
    "assetFooterColumn" : "/api/asset-footer-columns/3",
    "name": "Another link",
    "position": 7
}
*/
export const UPDATE_ASSET_FOOTER_COLUMN_LINK = gql`
mutation updateAssetFooterColumnLink($id: ID!, $assetFooterColumn: String!, $name: String, $link: String, $position: Int, $isExternal: Boolean!){
    updateAssetFooterColumnLink(input: {id: $id, assetFooterColumn: $assetFooterColumn, name: $name, link: $link, position: $position, isExternal: $isExternal}){
        assetFooterColumnLink{
            id
            assetFooterColumn{
                id
            }
            link
            name
            position
            isExternal
        }
    }
}
`;

//----- Supprimer un assetFooterColumnLink
/*
Exemple de variables
{
    "id" : "/api/asset-footer-column-links/3"
}
*/
export const DELETE_ASSET_FOOTER_COLUMN_LINK = gql`
mutation deleteAssetFooterColumnLink($id: ID!){
    deleteAssetFooterColumnLink(input: {id:$id}){
        assetFooterColumnLink{
            id
        }
    }  
}
`;

//----- Récupération d'un seul assetFooterColumnLink
/*
Exemple de variables
{
    "id" : "/api/asset-footer-column-links/1"
}
*/
export const GET_ASSET_FOOTER_COLUMN_LINK = gql`
query assetFooterColumnLink($id: ID!){
    assetFooterColumnLink(id: $id){
        id
        assetFooterColumn{
            id
            assetFooter{
                id
                name
                startedAt
                endedAt
                isDefault
                active
                asset{
                    id
                }
            }
            name
            position
        }
        link
        name
        position
        isExternal
    }
}
`;