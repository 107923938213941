import { gql } from 'apollo-boost';

//----- Récupération des Magento Pages
export const GET_MAGENTO_PAGES_LIGHT = gql`
query getMagentoPages($asset: String){
    assetMagentoPages(asset: $asset, order:{id: "DESC"}){
      totalCount
      edges{
        node{
          id
          libelle
          identifier
          pageContents{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
      }
    }
}
`;
//----- Récupération des Magento Pages Pagination
export const GET_MAGENTO_PAGES_PAGINATION = gql`
query getMagentoPagesPagination($nbperpage: Int, $cursor: String, $cursorLast: String $asset: String, $pageType: String){
  assetMagentoPages(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset, order:{id: "DESC"}, pageType: $pageType){
    pageInfo{
      startCursor
      endCursor
    }
    totalCount
    edges{
      node{
        id
        libelle
        identifier
        url
        isActive
        isMagentoExistingPage
        magentoPageRealId
        metaTitle
        metaDescription
        metaKeywords
        useInCategory
        pageContents{
          totalCount
        }
        pageType{
          id
          identifier
          libelle
        }
        asset{
          id
        }
      }
    }
  }
}
`;

export const GET_MAGENTO_PAGES_BY_ASSETS_LIST = gql`
query getMagentoPages($assetList: [String], $useInCategory:Boolean){
  assetMagentoPages(asset_list: $assetList, useInCategory: $useInCategory, order:{id: "DESC"}){
    edges{
      node{
        id
        libelle
        useInCategory
      }
    }
  }
}
`;

export const GET_MAGENTO_PAGE_TYPES = gql`
query getAssetMagentoPageTypes{
  assetMagentoPageTypes{
    edges{
      node{
        id
        identifier
        libelle
      }
    }
  }
}
`

//----- Ajouter une Page Magento
/*
{
  "asset": "/api/assets/1",
  "libelle": "TEST",
  "identifier": "test",
  "url" : "https://www.sinfin.fr",
  "hasToBePush": true,
  "magentoPageId": "12",
  "isMagentoExistingPage": true,
  "isActive": true
}
 */
export const ADD_MAGENTO_PAGE = gql`
mutation createAssetMagentoPage($asset: String, $libelle: String!, $identifier: String!, $metaTitle: String, $metaDescription: String, $metaKeywords: String, $url: String!, $hasToBePush: Boolean!, $magentoPageId: String, $isMagentoExistingPage: Boolean!, $isActive: Boolean!, $pageType: String!, $useInCategory: Boolean) {
  createAssetMagentoPage(input:{asset: $asset, libelle: $libelle, identifier: $identifier, metaTitle: $metaTitle, metaDescription: $metaDescription, metaKeywords: $metaKeywords, url: $url, hasToBePush: $hasToBePush, magentoPageId: $magentoPageId, isMagentoExistingPage: $isMagentoExistingPage, isActive: $isActive, pageType:$pageType, hasAlreadyBeenPushed: false, useInCategory: $useInCategory}){
    assetMagentoPage{
        id
        asset{
          id
        }
        libelle
        identifier
        url
        isActive
        isMagentoExistingPage
        magentoPageRealId
        useInCategory, 
    }
  }
}
`;

//----- Modifier une Page Magento
/*
{
    "id": "/api/"
    "asset": "/api/assets/1",
    "libelle": "TEST",
    "identifier": "test",
    "url" : "https://www.sinfin.fr",
    "hasToBePush": true,
    "magentoPageId": "12",
    "isMagentoExistingPage": true,
    "isActive": true
}
 */
export const UPDATE_MAGENTO_PAGE = gql`
mutation updateAssetMagentoPage($id: ID!, $asset: String, $libelle: String, $identifier: String, $metaTitle: String, $metaDescription: String, $metaKeywords: String, $url: String, $hasToBePush: Boolean, $hasAlreadyBeenPushed: Boolean, $magentoPageId: String, $isMagentoExistingPage: Boolean, $isActive: Boolean, $useInCategory: Boolean) {
  updateAssetMagentoPage(input:{id: $id, asset: $asset, libelle: $libelle, identifier: $identifier, metaTitle: $metaTitle, metaDescription: $metaDescription, metaKeywords: $metaKeywords, url: $url, hasToBePush: $hasToBePush, hasAlreadyBeenPushed: $hasAlreadyBeenPushed, magentoPageId: $magentoPageId, isMagentoExistingPage: $isMagentoExistingPage, isActive: $isActive, useInCategory: $useInCategory}){
    assetMagentoPage{
        id
        asset{
          id
        }
        libelle
        identifier
        url
        isActive
        isMagentoExistingPage
        magentoPageRealId
        useInCategory
    }
  }
}
`;

//----- Supprimer un Magento Top Header Message
/*
{
  "id" : "/api/asset-magento-pages/1",
}
 */
export const DELETE_MAGENTO_PAGE = gql`
mutation deleteAssetMagentoPage($id: ID!) {
  deleteAssetMagentoPage(input:{id: $id}){
    assetMagentoPage{
        id
    }
  }
}
`;

//----- Récupération des Magento Pages
export const GET_MAGENTO_CONTENTS_PAGINATION = gql`
query getMagentoContentsPagination($nbperpage: Int, $cursor: String, $magentoPage: String){
    assetMagentoPageContents(first: $nbperpage, after: $cursor, magentoPage: $magentoPage){
      pageInfo{
        startCursor
        endCursor
      }
      totalCount
      edges{
        node{
          id
          libelle
          startDate
          endDate
          isDefaultContent
          isActive
          magentoPage{
            asset{
              identifier
            }
          }
          content
          contentConfig
        }
      }
    }
}
`;

//---- Récupération d'un CONTENT
export const GET_CONTENT_PAGE = gql`
query assetMagentoPageContent($id: ID!){
  assetMagentoPageContent(id: $id){
    id
    content
    libelle
    contentConfig
    magentoPage{
      id
      libelle
      identifier
    }
  }
}
`;

//----- Ajouter un Contenu Magento
/*

 */
export const ADD_MAGENTO_PAGE_CONTENT = gql`
mutation createAssetMagentoPageContent($magentoPage: String!, $libelle: String!, $startDate: String, $endDate: String, $isDefaultContent: Boolean!, $isActive: Boolean!, $isCurrentlyDeployed : Boolean!) {
    createAssetMagentoPageContent(input:{magentoPage: $magentoPage, libelle: $libelle, startDate: $startDate, endDate: $endDate, isDefaultContent: $isDefaultContent, isActive: $isActive, isCurrentlyDeployed : $isCurrentlyDeployed}){
    assetMagentoPageContent{
        id
        libelle
        startDate
        endDate
        isDefaultContent
        isActive
    }
  }
}
`;

//----- Modifier un Contenu Magento
/*
{
}
 */
export const UPDATE_MAGENTO_PAGE_CONTENT = gql`
mutation updateAssetMagentoPageContent($id: ID!,$libelle: String, $startDate: String, $endDate: String, $isDefaultContent: Boolean, $isActive: Boolean, $contentConfig: String, $content: String) {
  updateAssetMagentoPageContent(input:{id: $id,libelle: $libelle, startDate: $startDate, endDate: $endDate, isDefaultContent: $isDefaultContent, isActive: $isActive, contentConfig: $contentConfig, content: $content}){
    assetMagentoPageContent{
        id
        libelle
        startDate
        endDate
        isDefaultContent
        isActive
        content
        contentConfig
    }
  }
}
`;

//----- Supprimer un Magento Top Header Message
/*
{
  "id" : "/api/asset-magento-pages/1",
}
 */
export const DELETE_MAGENTO_PAGE_CONTENT = gql`
mutation deleteAssetMagentoPageContent($id: ID!) {
  deleteAssetMagentoPageContent(input:{id: $id}){
    assetMagentoPageContent{
        id
    }
  }
}
`;