import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CheckboxAttributs from '../../../../ui/inputs/CheckboxAttributs';

export default function formAddCompletenessGroup(allGroups, edit, currentLang,t){
    return ({
        titleForm: edit === 'edit' ?  t('products.completeness.edittitle') : t('products.completeness.createtitle'),
        subTitleForm: edit === 'edit' ? t('products.completeness.editsub') : t('products.completeness.createsub'),
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        textButtonValidate: edit === 'edit' ? t('products.completeness.edit') : t('products.completeness.create'),
        formConfig: {
            type: TABS,
            children:[
                {
                    labelName: t('products.completeness.info'),
                    isOptionnal: false,
                    optionsInputs: [
                        {
                            type: 'text',
                            label: t('products.completeness.wording'),
                            helper: {
                                text: t('products.completeness.wordinghere'),
                                link: false,
                            },
                            required: true,
                            stateName: 'libelle',
                        },
                    ]
                },
                {
                    labelName:  t('products.completeness.selectattribut'),
                    isOptionnal: false,
                    component: CheckboxAttributs,
                    optionsInputs:{
                        stateName: 'attributesSelected',
                        required: true,
                        value: allGroups
                    }
                }
            ]
        }
    })
}