import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formAddOffer from './config/addOffer.config';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ROUTE_CRM_OFFERS_LIST, ROUTE_HOME } from '../../../../js/constants/route-names';

import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import * as moment from "moment";
import _ from 'lodash';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { SEARCH_COMPANIES, GET_COMPANIES_CURSOR, GET_COMPANIES_PAGINATION } from '../../../../queries/companies';

import styled from 'styled-components';
import { GET_MODELE_BY_ID } from '../../../../queries/crm_modeles';
import { GET_AGENCE_POLES, GET_AGENCE_TARIFS, GET_AGENCE_TAX_RATES, GET_PAYMENT_TERMS, GET_PAYMENT_DEADLINES } from '../../../../queries/crm_agence';
import { GET_PROJECTS_CURSOR, GET_PROJECTS_PAGINATION_LIGHT } from '../../../../queries/crm_projects';
import { DELETE_OFFER, ADD_OFFER, ADD_TASK_PHASE_OFFER, DELETE_TASK_PHASE_OFFER, UPDATE_TASK_PHASE_OFFER, UPDATE_PHASE_OFFER, DELETE_PHASE_OFFER, ADD_PHASE_OFFER, UPDATE_OFFER, GET_OFFER_BY_ID, ADD_FOOTER_OFFER, UPDATE_FOOTER_OFFER, ADD_OFFER_FOOTER_TAX, UPDATE_OFFER_FOOTER_TAX } from '../../../../queries/crm_offers';
import OfferDisplay from './components/OfferDisplay';
import { getTotals } from './components/Calculator';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE, UPDATE, VIEW, DELETE } from '../../../../js/constants/constant-rights';

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

class OfferDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            offerId: props.location.state?.offerId,
            modeleId: props.location.state?.modelId,
            // imageDevis: null,
            name: 'Offre ' + new Date().getTime(),
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent luctus fermentum libero a consectetur. Fusce et enim sit amet purus imperdiet porta vitae ac lacus. Maecenas euismod ex nibh. Aliquam dignissim ante ac consectetur consectetur. Donec non sem efficitur, condimentum turpis quis, pharetra nisi.',
            poNumber: "dfxs123",
            status: true,
            createdAt: new Date(),
            validityDate: moment().add(5, 'days').toDate(),
            // title: null, 
            // description: null,
            // poNumber: null,
            // status: true,
            // createdAt: null,
            // validityDate: null,
            formType: null,
            openForm: false,
            errors: {},
            seeErrors: false,
            offerSetup: [],
            formSettings: {
                page: 1,
                count: 1,
            },
            companies: {
                current: [],
                toAdd: [],
                toRemove: [],
            },
            nbperPageCompanies: 6,
            projectSelected: null,
            nbperpageProject: 6,
            pageProject: 1,
            countPageProject: 0,
            cursorProject: false,
            searchValue: '',
            isModel: false

        }
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, VIEW)
        const getRights2 = hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, CREATE)
        if (!getRights) {
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        } else if (!this.state.offerId) {
            if (!getRights2) {
                this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
                this.goTo(ROUTE_HOME);
            } else {
                this.initOffer();
            }
        } else {
            this.initOffer();
        }
    }

    // deleteProject = () => {
    //     handleToggleDialog()
    // }

    handleToggleDrawer = (cancel) => {
        this.setState({
            openForm: !this.state.openForm,
            seeErrors: false
        });

        if (this.state.openForm && cancel) {
            this.setState({
                phases: this.convertPhases(this.state.phasesBackup, this.state.formType === "edit" ? true : false),
            });
        }

        if (this.state.openForm) {
            this.changePage(null, 1, null)
            this.changePage(null, 1, 'project')
        }
    };

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleGetCompanies = async () => {
        let variables = {
            nbperpage: this.state.nbperPageCompanies
        }
        if (this.state.cursor && this.state.listCursors !== this.state.listCursors[0].cursor) {

            variables.cursor = this.state.cursor;
        }
        if (this.state.activeSearchCompanies) {
            variables.id_list = this.state.activeSearchCompanies
        }
        this.props.client.query({
            query: GET_COMPANIES_PAGINATION,
            variables: variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let formSettings = this.state.formSettings;
            let companies = result.data.companies.edges;
            formSettings.count = Math.ceil(result.data.companies.totalCount / this.state.nbperPageCompanies);
            this.setState({
                allCompanies: companies,
                formSettings: formSettings
            })
        })
    }

    handleSelectCompany = (checked, company, currentAction = 'add') => {
        let companies = this.state.companies
        companies.toAdd = company
        // if(checked){
        //     companies.toAdd.push(company)
        // }
        // else{
        //     let index = companies.toAdd.indexOf(company);
        //     companies.toAdd.splice(index,1)
        // }
        this.setState({
            companies: companies
        })
    }

    handleGetCursorsProjects = () => {
        this.props.client.query({
            query: GET_PROJECTS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursorsProjects: result.data.projects.edges,
            });
        });
    }

    handleGetProjects = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperpageProject,
            };
            if (this.state.cursorProject && this.state.listCursorsProjects && this.state.cursorProject !== this.state.listCursorsProjects[0].cursor) {
                variables.cursor = this.state.cursorProject;
            }
            if (this.state.searchValue !== '') {
                variables.name = this.state.searchValue;
                // variables.description = this.state.searchValue;
            }
            this.props.client.query({
                query: GET_PROJECTS_PAGINATION_LIGHT,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    countPageProject: Math.ceil(result.data.projects.totalCount / this.state.nbperpageProject),
                    listProjects: result.data.projects.edges,
                });
                this.props.stopLoading();
                resolve();
            })
        })
    }

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if (page > 1) {
            switch (type) {
                case 'project':
                    index = (page * this.state.nbperpageProject) - this.state.nbperpageProject - 1
                    break;
                default:
                    index = (page * this.state.nbperPageCompanies) - this.state.nbperPageCompanies - 1
                    break;
            }
        }
        switch (type) {
            case 'project':
                this.setState({
                    cursorProject: this.state.listCursorsProjects[index].cursor,
                    pageProject: page
                }, () => {
                    this.handleGetProjects();
                });
                break;
            default:
                let formSettings = this.state.formSettings;
                formSettings.page = page
                this.setState({
                    allCompanies: null,
                    cursor: this.state.listCursors[index].cursor,
                    formSettings: formSettings
                }, () => {
                    this.handleGetCompanies();
                });
                break;
        }
    };

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            name: null,
            description: null,
            imageDevis: null,
            errors: {}
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;
        this.setState({
            formType: 'delete'
        })

        query = DELETE_OFFER;
        variables = { id: this.state.offerId };
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.handleSuccess();
        });
    };

    copy(array) {
        let newArray = [];

        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    copyArrayOfObjects = array => array.map(a => ({ ...a }));

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.setState({
            ready: false
        })
        if (this.state.formType === "edit") {
            this.props.snack(ALERT_SUCCESS, 'Offre modifiée !');
            await this.initOffer();
            this.handleToggleDrawer();
            this.resetState();
        } else if (this.state.formType === "add") {
            this.props.snack(ALERT_SUCCESS, 'Offre créée !');
            this.goTo(ROUTE_CRM_OFFERS_LIST);
        } else {
            this.props.snack(ALERT_SUCCESS, 'Offre supprimée !');
            this.goTo(ROUTE_CRM_OFFERS_LIST);
            // this.handleToggleDialog();
        }
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleNextStep = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    savePhaseTasks = async (taches, phaseId, taskId) => {
        let index = 0;

        for (let tache of taches) {
            if (tache.deleted) {
                if (!tache.new) {
                    await this.props.client.mutate({
                        mutation: DELETE_TASK_PHASE_OFFER,
                        variables: { 'id': tache.id }
                    });
                }

                continue;
            }

            if (tache.new) {
                const ADD_TASK_PHASE_OFFER_RESULT = await this.props.client.mutate({
                    mutation: ADD_TASK_PHASE_OFFER,
                    variables: {
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'task': taskId ? taskId : null,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });

                tache.id = ADD_TASK_PHASE_OFFER_RESULT.data.createOfferTask.offerTask.id;

                if (taskId === null)
                    await this.savePhaseTasks(tache.taches, phaseId, tache.id);
            } else if (tache.updated) {
                await this.props.client.mutate({
                    mutation: UPDATE_TASK_PHASE_OFFER,
                    variables: {
                        'id': tache.id,
                        'name': tache.name,
                        'phase': phaseId,
                        'price': tache.price,
                        'quantity': tache.qty,
                        'total': tache.total,
                        'reference': tache.ref,
                        'tax': tache.tva,
                        'discountFixed': tache.typeRemise === '%' ? 0 : tache.remise,
                        'discountPercent': tache.typeRemise === '%' ? tache.remise : 0,
                        'poles': tache.poles,
                        'position': index
                    }
                });

                if (taskId === null)
                    await this.savePhaseTasks(tache.taches, phaseId, tache.id);
            } else if (taskId === null) {
                await this.savePhaseTasks(tache.taches, phaseId, tache.id);
                await this.saveTaskPosition(tache.id, index);
            }

            index++;
        }
    };

    savePhasePosition = async (id, position) => {
        await this.props.client.mutate({
            mutation: UPDATE_PHASE_OFFER,
            variables: {
                'id': id,
                'position': position,
            }
        });
    };

    saveTaskPosition = async (id, position) => {
        await this.props.client.mutate({
            mutation: UPDATE_TASK_PHASE_OFFER,
            variables: {
                'id': id,
                'position': position,
            }
        });
    };

    savePhases = async (offer) => {
        let index = 0;

        for (let phase of this.state.phases) {
            if (phase.deleted) {
                if (!phase.new) {
                    // Delete a phase in cascade

                    await this.props.client.mutate({
                        mutation: DELETE_PHASE_OFFER,
                        variables: { 'id': phase.id }
                    });
                }

                continue;
            }

            if (phase.new) {
                // Add a new phase

                const ADD_PHASE_OFFER_RESULT = await this.props.client.mutate({
                    mutation: ADD_PHASE_OFFER,
                    variables: {
                        'name': phase.name,
                        'offer': offer,
                        'position': index
                    }
                });

                phase.id = ADD_PHASE_OFFER_RESULT.data.createOfferPhase.offerPhase.id;

                await this.savePhaseTasks(phase.taches, phase.id, null);
            } else if (phase.updated) {
                // Update a phase

                await this.props.client.mutate({
                    mutation: UPDATE_PHASE_OFFER,
                    variables: {
                        'id': phase.id,
                        'name': phase.name,
                        'position': index
                    }
                });

                await this.savePhaseTasks(phase.taches, phase.id, null);
            } else {
                await this.savePhaseTasks(phase.taches, phase.id, null);
                await this.savePhasePosition(phase.id, index);
            }

            index++;
        }
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            if (this.state.formType === "add") {
                const ADD_OFFER_RESULT = await this.props.client.mutate({
                    mutation: ADD_OFFER,
                    variables: {
                        'title': this.state.name,
                        'description': this.state.description,
                        'project': this.state.projectSelected,
                        'poNumber': this.state.poNumber,
                        'status': this.state.status,
                        'createdAt': this.state.createdAt,
                        'validityDate': this.state.validityDate,
                    }
                });

                let id = ADD_OFFER_RESULT.data.createOffer.offer.id;

                await this.savePhases(id);

                const ADD_FOOTER_OFFER_RESULT = await this.props.client.mutate({
                    mutation: ADD_FOOTER_OFFER,
                    variables: {
                        'offer': id,
                        'discountFixed': +this.state.footer?.discountFixed,
                        'discountPercent': +this.state.footer?.discountPercent,
                        'advancePayment': +this.state.footer?.advancePayment,
                        'paymentTerm': this.state.footer?.paymentTerm,
                        'paymentDeadline': this.state.footer?.paymentDeadline,
                        'comment': this.state.footer?.comment
                    }
                });

                let { totals } = getTotals(this.state.phases, this.state.listTaxes);

                for (let tax of this.state.listTaxes) {
                    let amount = totals.find(e => e.tax.id === tax.node.id);

                    await this.props.client.mutate({
                        mutation: ADD_OFFER_FOOTER_TAX,
                        variables: {
                            'offerFooter': ADD_FOOTER_OFFER_RESULT.data.createOfferFooter.offerFooter.id,
                            'tax': tax.node.id,
                            'amount': +amount.total
                        }
                    });
                }
            } else if (this.state.formType === "edit") {
                await this.props.client.mutate({
                    mutation: UPDATE_OFFER,
                    variables: {
                        'id': this.state.offerId,
                        'title': this.state.name,
                        'project': this.state.projectSelected,
                        'description': this.state.description,
                        'poNumber': this.state.poNumber,
                        'status': this.state.status,
                        'createdAt': this.state.createdAt,
                        'validityDate': this.state.validityDate,
                    }
                });

                await this.savePhases(this.state.offerId);

                await this.props.client.mutate({
                    mutation: UPDATE_FOOTER_OFFER,
                    variables: {
                        'id': this.state.footer?.id,
                        'discountFixed': +this.state.footer?.discountFixed,
                        'discountPercent': +this.state.footer?.discountPercent,
                        'advancePayment': +this.state.footer?.advancePayment,
                        'paymentTerm': this.state.footer?.paymentTerm,
                        'paymentDeadline': this.state.footer?.paymentDeadline,
                        'comment': this.state.footer?.comment
                    }
                });

                let { totals } = getTotals(this.state.phases, this.state.listTaxes);

                let existingTaxes = this.state.offer.offerFooter?.offerFooterTaxes?.edges ?? [];

                for (let tax of this.state.listTaxes) {
                    let amount = totals.find(e => e.tax.id === tax.node.id);
                    let found = existingTaxes.find(e => e.node.tax.id === tax.node.id);

                    if (found) {
                        // Tax existed
                        await this.props.client.mutate({
                            mutation: UPDATE_OFFER_FOOTER_TAX,
                            variables: {
                                'id': found.node.id,
                                'offerFooter': this.state.offer.offerFooter?.id,
                                'tax': tax.node.id,
                                'amount': +amount.total
                            }
                        });
                    } else {
                        // Tax didn't existed    
                        await this.props.client.mutate({
                            mutation: ADD_OFFER_FOOTER_TAX,
                            variables: {
                                'offerFooter': this.state.offer.offerFooter?.id,
                                'tax': tax.node.id,
                                'amount': +amount.total
                            }
                        });
                    }

                }
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    render() {
        const { name, description, poNumber, createdAt, listTaxes, phases, listProjects, projectSelected } = this.state;

        return (
            <div style={{ width: this.state.openForm ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "calc(100% - 32px)", marginLeft: 16, marginTop: 8, transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms' }}>
                <Grid container style={{ padding: 0 }}>
                    <Box onClick={this.props.history.goBack} style={{ marginTop: 16, marginBottom: 16 }}>
                        <ReturnLink variant={'body2'}>&lt; Retour</ReturnLink>
                    </Box>
                </Grid>
                {
                    this.state.ready ?
                        <CardCustom style={{ paddingLeft: 16 }}>
                            {listTaxes && <OfferDisplay
                                phases={phases}
                                listTaxes={listTaxes}
                                isModel={false}
                                listProjects={listProjects}
                                projectSelected={projectSelected}
                                offer={{
                                    name,
                                    description,
                                    poNumber,
                                    createdAt
                                }}
                            />}
                            {
                                hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, UPDATE) && this.state.formType === 'edit' ?
                                    <Grid container justifyContent="flex-end">
                                        <Button onClick={() => this.handleToggleDrawer()} disabled={this.state.openForm ? 'disabled' : ''}>Modifier</Button>
                                    </Grid>
                                    : null
                            }
                        </CardCustom>
                        : <PageLoader />
                }
                {
                    this.state.ready ?
                        (
                            <LayoutBuilder
                                opened={this.state.openForm}
                                // image={this.state.imageForm}
                                // icomoon={this.state.content.picto}
                                forClose={this.props.history.goBack}
                                paginationInfo={{
                                    changePage: this.changePage,
                                    project: {
                                        count: this.state.countPageProject,
                                        page: this.state.pageProject
                                    },
                                }}
                                dataLayout={formAddOffer(this.state.currentLang, this.handleMediaPicker, this.state.allCompanies, this.handleSelectCompany, this.state.companies, this.state.formSettings, this.state.listProjects, this.state.typeOfModel, this.state.formType)}
                                handlerMutation={this.handlerMutation}
                                drawerWidth={this.props.drawerWidth}
                                stateCallback={this.handleInputChange}
                                backStepperButtonAction={[
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    null
                                ]}
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                errorCallback={this.handleFormError}
                                validateButton={true}
                                deleteMutation={this.state.formType === 'edit' && hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, DELETE) ? () => this.handleToggleDialog() : null}
                                deleteText={this.state.formType === 'edit' ? 'Supprimer' : null}
                                allState={this.state}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                                searchHandler={this.handleChange}
                            />
                        ) : ''
                }
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer ce projet ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez ce projet celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleToggleDialog} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                        </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    handleGetCompaniesCursors = () => {
        this.props.client.query({
            query: GET_COMPANIES_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.companies.edges
            }, () => {
                this.handleGetCompanies();
            });
        });
    }

    handleChange = (e, type) => {
        return new Promise((resolve, reject) => {
            clearTimeout(this.typingSearchTimer);
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleGetProjects()
            }, 500);
            resolve();
        })
    }

    handleChangeCompanies = (e, value) => {
        this.setState({
            currentPage: 1,
            allCompanies: null,
        })
        clearTimeout(this.typingSearchTimer);
        if (e?.target?.value) {
            this.setState({ searchValueCompanies: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchCompanies();

            }, 500);
        }
        else {
            this.setState({ activeSearchCompanies: false }, () => this.handleGetCompanies())
        }
    }

    handleSearchCompanies = () => {
        let value = this.state.searchValueCompanies;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_COMPANIES,
                variables: { "needle": value, "attribute": null },
                fetchPolicy: 'no-cache'
            }).then(result => {
                let companies = result.data.companyDatas.edges.map(e => parseInt(e.node.company.id.replace('/api/companies/', ''), 10));
                let listID = [];
                let uniqueCompanies = companies.filter(company => {
                    if (!listID.includes(company)) {
                        listID.push(company);
                        return company;
                    } else {
                        return null
                    }
                })
                this.setState({
                    activeSearchCompanies: uniqueCompanies
                }, () => this.handleGetCompanies())

            })

        }
    }

    initPoles() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_POLES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listAgencePoles: result.data.agencyPoles.edges
                });
                resolve();
            });
        });
    }

    initTarifs() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_TARIFS,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listTarifs: result.data.agencyRates.edges
                });
                resolve();
            });
        });
    }

    initTaxes() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_AGENCE_TAX_RATES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listTaxes: result.data.agencyTaxRates.edges
                });
                resolve();
            });
        });
    }

    initPayments() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_PAYMENT_TERMS,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listPayments: result.data.agencyPaymentTerms.edges
                }, resolve);
            });
        });
    }

    initDeadlines() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_PAYMENT_DEADLINES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    listDeadlines: result.data.agencyPaymentDeadlines.edges
                }, resolve);
            });
        });
    }

    initModel() {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_MODELE_BY_ID,
                variables: {
                    'id': this.state.modeleId
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    typeOfModel: result.data.model.name,
                    offerSetup: result.data.model.modelPhases.edges,
                    phases: this.convertPhases(result.data.model.modelPhases.edges),
                    footer: {
                        discountFixed: 0,
                        discountPercent: 0,
                        advancePayment: 0,
                        paymentTerm: this.state.listPayments[0]?.node.id,
                        paymentDeadline: this.state.listDeadlines[0]?.node.id,
                        comment: ''
                    }
                }, () => {
                    resolve(result.data.model);
                });
            });
        });
    }

    async initOffer() {
        await this.initPoles();
        await this.initTarifs();
        await this.initTaxes();
        await this.initPayments();
        await this.initDeadlines();

        if (this.state.modeleId) {
            await this.initModel();
        }

        await this.handleGetCursorsProjects();
        await this.handleGetProjects();

        let formSettings = this.state.formSettings;
        formSettings.changePage = this.changePage;
        formSettings.handleSearch = this.handleChangeCompanies;
        this.handleGetCompaniesCursors();

        if (this.state.offerId) {
            this.props.client.query({
                query: GET_OFFER_BY_ID,
                fetchPolicy: "no-cache",
                variables: { id: this.state.offerId }
            }).then(GET_OFFER_DATA_RESULT => {
                let offer = GET_OFFER_DATA_RESULT.data.offer;

                this.setState({
                    ready: true,
                    formType: 'edit',
                    offer,
                    name: offer.title,
                    description: offer.description,
                    projectSelected: offer.project?.id,
                    phasesBackup: offer.offerPhases.edges,
                    phases: this.convertPhases(offer.offerPhases.edges, true),
                    footer: {
                        id: offer.offerFooter?.id,
                        discountFixed: offer.offerFooter?.discountFixed,
                        discountPercent: offer.offerFooter?.discountPercent,
                        advancePayment: offer.offerFooter?.advancePayment,
                        paymentTerm: offer.offerFooter?.paymentTerm?.id,
                        paymentDeadline: offer.offerFooter?.paymentDeadline?.id,
                        comment: offer.offerFooter?.comment
                    }
                }, hasRights(CRM_OFFERS, CRM_OFFERS_OFFERS, UPDATE) ? () => this.handleToggleDrawer() : null)
            });
        } else {
            this.setState({
                ready: true,
                formType: 'add',
                openForm: true
            })
        }
    }

    convertTask = (task, isOffer = false) => {
        return {
            id: task.node.id,
            qty: task.node.quantity,
            price: task.node.price,
            tva: task.node.tax?.id,
            remise: task.node.discountFixed || task.node.discountPercent,
            typeRemise: task.node.discountFixed ? '€' : '%',
            total: task.node.total,
            name: task.node.name,
            ref: task.node.reference,
            new: isOffer ? false : true,
            poles: task.node.pole.edges.map(e => e.node.id),
            taches: []
        };
    };

    convertPhases = (phases, isOffer = false) => {
        let deep = _.cloneDeep(phases);
        let newOnes = [];

        deep.sort((a, b) => {
            let aPosition = a.node.position || 0;
            let bPosition = b.node.position || 0;

            return aPosition > bPosition ? 1 : -1;
        });

        for (let phase of deep) {
            let item = {
                id: phase.node.id,
                new: isOffer ? false : true,
                name: phase.node.name,
                position: phase.node.position,
                taches: []
            };

            let parents = isOffer
                ? phase.node.offerTasks.edges.filter(e => e.node.task === null)
                : phase.node.modelTasks.edges.filter(e => e.node.task === null);

            let deepTasks = _.cloneDeep(parents);

            deepTasks.sort((a, b) => {
                let aPosition = a.node.position || 0;
                let bPosition = b.node.position || 0;

                return aPosition > bPosition ? 1 : -1;
            });

            for (let parent of deepTasks) {
                let formatedParent = this.convertTask(parent, isOffer);

                let children = isOffer
                    ? phase.node.offerTasks.edges.filter(e => e.node.task !== null && e.node.task.id === formatedParent.id)
                    : phase.node.modelTasks.edges.filter(e => e.node.task !== null && e.node.task.id === formatedParent.id);

                let deepChildren = _.cloneDeep(children);

                deepChildren.sort((a, b) => {
                    let aPosition = a.node.position || 0;
                    let bPosition = b.node.position || 0;

                    return aPosition > bPosition ? 1 : -1;
                });

                for (let child of deepChildren) {
                    let formatedChild = this.convertTask(child, isOffer);
                    formatedParent.taches.push(formatedChild);
                }

                item.taches.push(formatedParent);
            }

            newOnes.push(item);
        }

        return newOnes;
    };

    goTo = (route, id, target) => {
        let variables = {};
        variables[target] = id;
        this.props.history.push({
            pathname: route,
            state: variables
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(OfferDetails)));
