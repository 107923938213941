const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        attributeGroups: state.attributeGroups, 
        locales: state.locales
    };
};

export default mapStateToProps;