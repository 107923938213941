import React, { useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import DialogModal from '../../../ui/dialog/DialogModal';

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

const ResponsiveEllipsisCustom = styled(ResponsiveEllipsis) `
    color: ${props=> props.color? props.color :colors.grey.darker};
    font-size:${props => props.fontSize ? `${props.fontSize}px` : "auto"};
    p{
        font-size:${props => props.fontSize ? `${props.fontSize}px` : "auto"};
        color:${props=> props.color? props.color :colors.grey.darker};
        margin: 0;
    }
    span.LinesEllipsis-ellipsis{
        cursor: ${props => props.hasModal ? 'pointer' : 'inherit'};
        color: ${props => props.hasModal ? colors.blue.darker.hue300 : colors.grey.darker};
    }
`;

const TextEllipsis = (props)=>{
    const {title,text,maxLine, ellipsis, hasModal}= props
    const [open,setOpen] = useState(false)    
    const [isClamped,setIsClamped] = useState(false)

    const handleOpen = (e)=>{    
        if (isClamped) {            
            e.stopPropagation()
            setOpen(true)            
        }        
    }
    const handleClose = (e)=>{        
        e.stopPropagation()
        setOpen(false)
    }

    const handleReflow = (rleState, e) => {
        const {
          clamped,
          text,
        } = rleState
        if (hasModal){
            if (clamped) {  
                setIsClamped(true)                      
            }else{
                setIsClamped(false)
            } 
        }  
    }

    return(
        <>
            {
                text ?
                    <>
                        <ResponsiveEllipsisCustom                
                            unsafeHTML={text ? text :  null }
                            maxLine={maxLine ? maxLine : 2}
                            ellipsis={ellipsis || (hasModal ? '... Lire plus' : '...')}
                            basedOn='letters'
                            onClick={hasModal ? handleOpen : null}
                            onReflow={handleReflow}
                            hasModal={hasModal}
                            fontSize={props.fontSize}
                            color={props.color}
                        />  
                        {
                            hasModal ?
                            <DialogModal
                                open={open} 
                                title={title}
                                secondaryAction={handleClose} 
                                secondaryText={'Fermer'}     
                                secondarycolor={colors.red.regular}
                                secondarycolorhover={colors.white}
                                secondarybgcolor={"#FFEBEC"}   
                                secondarybgcolorhover={"#FFEBEC"}     
                                secondaryborder={`1px solid ${colors.red.regular}`} 
                                secondaryText={'Fermer'}  
                                windowWidth={props.windowWidth}
                            >
                                <p dangerouslySetInnerHTML={{__html:text}}/>
                            </DialogModal>
                            : null
                        }   
                    </>  
                : null
            }
        </>
    );
}
export default TextEllipsis