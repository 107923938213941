import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

const restaurantAdd = {
    titleForm: "Créer un restaurant",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer un nouveau restaurant',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    textButtonValidate: 'Ajouter le restaurant',
    formConfig: {
        type: FORM,
        finalStep:{},
        children:[
            {
                labelName: 'Identité',
                optionsInputs:[]
            },
        ]
    }
}

export default restaurantAdd;
