import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router';
import H1 from '../../../../shared/components/CustomH1';
import Div from '../../../../shared/components/CustomDiv';


const ResultScreenBook = (props) => {
    const router = useRouter();

    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [email, setEmail] = useState('');

    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        spread = false
    } = props;

    useEffect(() => {
        let email = localStorage.getItem('email');

        if (email) {
            setEmail(email);

            // remove email from storage
            localStorage.removeItem('email');
        } else {
            if (spread)
                setEmail('johndoe@email.com');

            if (!spread) {
                //router.push('/');
                window.location = '/';
            }
        }
    }, []);
    
    let image = data.inputs['backgroundImage'].value;
    let dataResult = data.blocks.result;
    
    let image2 = dataResult.inputs['backgroundImage'].value;
    let border = null;
    if (dataResult.inputs.border) {
        border = dataResult.inputs.border;
    }

    return (
        <div 
            className="result_screen"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.toolbarSection }
            <div className="wrapper_card">
                <div 
                    className="card"
                    style={{
                        backgroundColor: dataResult.inputs['backgroundColor'].value,
                        backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        position: 'relative',
                        borderStyle: 'solid',
                        borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                        borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                        borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                    }}
                    onMouseEnter={() => {setHover(false); setHoverBlock(true);}} onMouseLeave={() => {setHover(true); setHoverBlock(false);}}
                >
                    { !preview && hoverBlock && props.toolbarBlock }
                    <H1 data={dataResult} preview={preview} component={id} block={'title'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                    <p
                        style={{
                            fontSize: dataResult.blocks['text'].inputs.size?.value,
                            backgroundColor: dataResult.blocks['text'].inputs.backgroundColor?.value,
                            textTransform: dataResult.blocks['text'].inputs.textTransform?.value,
                            textAlign: dataResult.blocks['text'].inputs.textAlign?.value,
                            lineHeight: '1.2'
                        }}
                    ><span>{email}</span></p>
                    <Div data={dataResult} preview={preview} component={id} block={'text'} inputCallback={spread ? inputCallback : null} nopadding={true} />
                </div>
            </div>
        </div>
    );
};

ResultScreenBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default ResultScreenBook;
