import { useQuery } from "react-query";
import { GET_ATTRIBUTES_BY_TYPE } from ".";
import { GET_ATTRIBUTE_TYPES, GET_ATTR_GROUPE_WITH_ATTRIBUTES } from "../attributes";
import _request from "../_request";
import axios from 'axios';

export function useAttributes(id) {
    return useQuery(
        ["attributes", id],
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ATTRIBUTES_BY_TYPE,
                { id }
            );
        },
        { enabled: !!id }
    );
}

export async function fetchAttributes(client, id) {
    const token = localStorage.getItem('AUTH_TOKEN');
    let headers = {'Content-Type': 'multipart/form-data'};
    if (token) headers['Authorization'] = `Bearer ${token}`;
    try {
        let content =  await axios.get(`${process.env.REACT_APP_API_ROOT}/api/media/GET_ATTRIBUTE_TYPES-${id.replace('/api/eav-types/','')}.json`,{headers});
        return content.data;
    } catch(err){
        //---- CREATE FILE WITH GQL RESULT
        return client.fetchQuery(
            ["attributes", id],
            async () => {
                    let result = await _request(
                        process.env.REACT_APP_API_GQL,
                        GET_ATTRIBUTES_BY_TYPE,
                        { id }
                );
                //--- push result in file
                var bodyFormData = new FormData();
                bodyFormData.append('name', 'GET_ATTRIBUTE_TYPES-'+id.replace('/api/eav-types/','')+'.json');
                bodyFormData.append('content', JSON.stringify(result));

                let headers = {'Content-Type': 'multipart/form-data'};
                if (token) headers['Authorization'] = `Bearer ${token}`;
            
                await axios.post(`${process.env.REACT_APP_API_ROOT}/api/media`,bodyFormData,{headers});
                return result;

            }
        );
    }

}
export async function fetchAttributesTypes(client, id) {
    const token = localStorage.getItem('AUTH_TOKEN');
    let headers = {'Content-Type': 'multipart/form-data'};
    if (token) headers['Authorization'] = `Bearer ${token}`;
    try {
        let content =  await axios.get(`${process.env.REACT_APP_API_ROOT}/api/media/GET_ATTRIBUTE_TYPES.json`,{headers});
        return content.data;
    } catch(err){
        //---- CREATE FILE WITH GQL RESULT
        return client.fetchQuery(
            ["attributeTypes"],
            async () => {
                    let result = await _request(
                        process.env.REACT_APP_API_GQL,
                        GET_ATTRIBUTE_TYPES,
        
                );
                //--- push result in file
                var bodyFormData = new FormData();
                bodyFormData.append('name', 'GET_ATTRIBUTE_TYPES.json');
                bodyFormData.append('content', JSON.stringify(result));

                let headers = {'Content-Type': 'multipart/form-data'};
                if (token) headers['Authorization'] = `Bearer ${token}`;
            
                await axios.post(`${process.env.REACT_APP_API_ROOT}/api/media`,bodyFormData,{headers});
                return result;

            }
        );
    }

}

export async function fetchGroupsWithAttributes(client) {

    const token = localStorage.getItem('AUTH_TOKEN');
    let headers = {'Content-Type': 'multipart/form-data'};
    if (token) headers['Authorization'] = `Bearer ${token}`;
    try {
        let content =  await axios.get(`${process.env.REACT_APP_API_ROOT}/api/media/GET_ATTR_GROUPE_WITH_ATTRIBUTES.json`,{headers});
        return content.data;
    } catch(err){
        //---- CREATE FILE WITH GQL RESULT
        return client.fetchQuery(
            "attributesByGroup",
            async () => {
                    let result = await _request(
                    process.env.REACT_APP_API_GQL,
                    GET_ATTR_GROUPE_WITH_ATTRIBUTES
                );
                //--- push result in file
                var bodyFormData = new FormData();
                bodyFormData.append('name', 'GET_ATTR_GROUPE_WITH_ATTRIBUTES.json');
                bodyFormData.append('content', JSON.stringify(result));

                let headers = {'Content-Type': 'multipart/form-data'};
                if (token) headers['Authorization'] = `Bearer ${token}`;
            
                await axios.post(`${process.env.REACT_APP_API_ROOT}/api/media`,bodyFormData,{headers});
                return result;

            }
        );
    }
}