import React, { useEffect, useRef, useState } from "react";
import Div from '../shared/div';
import styled from 'styled-components';

const ContainerSeparator = styled(Div)`
    padding-top: 18px;
    padding-bottom: 18px;
`;

const Separator = styled.hr`
    max-width: 1800px;
    width: calc(100% - 2rem);
    border: none;
    height: 1px;
    background: #e6e7ea;
`;

const SeparatorBlock = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    return (
        <Div style={{position: 'relative'}} className="slogan" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <ContainerSeparator>
                <Separator />
            </ContainerSeparator>
        </Div>
    );
};

export default SeparatorBlock;