import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { Accordion, AccordionSummary, AccordionDetails, InputLabel, MenuItem, Chip, FormControl, Select, TextField, Button, IconButton, Typography, Divider } from '@material-ui/core';
import { ExpandMore, AddCircle, Delete, DragIndicator } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import arrayMove from 'array-move';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { FinderFilterTypes } from '../../../shareable/types';
import 'react-dropdown-tree-select/dist/styles.css';
import './FinderConfig.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '24px'
    },
    dragIndicator: {
        marginRight: 10
    },
    formControl: {
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    expansionPanel: {
        flexDirection: 'column'
    },
    input: {
        marginBottom: theme.spacing(1),
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    },
    delete: {
        marginTop: theme.spacing(1),
        width: 150,
        alignSelf: 'flex-end'
    }
}));

const DeleteButton = withStyles((theme) => ({
    root: {
        color: 'red',
    }
}))(Button);

const DragHandle = sortableHandle(() => {
    const classes = useStyles();
    return <DragIndicator className={classes.dragIndicator} />;
});

const Ordering = ({ options, onSortEnd }) => (
    <div className="options">
        <p>Changer l'ordre :</p>

        <SortableOptionList
            hideSortableGhost={true}
            helperClass="helper"
            useDragHandle={false}
            items={options}
            axis="xy"
            onSortEnd={onSortEnd}
        />
    </div>
);

const EntriesManager = ({ value, item, onChange, isNumber = false }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        }}>
            {
                item.ranges.map((e, i) => {
                    let inputStyle = {
                        width: 90,
                        marginRight: 5
                    };

                    return (
                        <div key={e.id} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <TextField
                                label={isNumber ? 'Valeur 1' : 'Valeur'}
                                variant="outlined"
                                margin="dense"
                                style={inputStyle}
                                value={e.value}
                                onChange={event => {
                                    e.value = event.target.value;
                                    onChange(value);
                                }}
                            />

                            {
                                isNumber && (
                                    <TextField
                                        label={'Valeur 2'}
                                        variant="outlined"
                                        margin="dense"
                                        style={inputStyle}
                                        value={e.end}
                                        onChange={event => {
                                            e.end = event.target.value;
                                            onChange(value);
                                        }}
                                    />
                                )
                            }

                            <TextField
                                label={'Texte'}
                                variant="outlined"
                                margin="dense"
                                style={inputStyle}
                                value={e.display}
                                onChange={event => {
                                    e.display = event.target.value;
                                    onChange(value);
                                }}
                            />

                            <IconButton
                                style={{ color: 'red' }}
                                onClick={() => {
                                    item.ranges.splice(i, 1);
                                    onChange(value);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    );
                })
            }
        </div>
    );
};

const SortableItem = SortableElement(({ value, onChange, panelChange, expanded, item, id, attributes, locales }) => {
    const classes = useStyles();

    let attrs = attributes.product.attributes.edges.filter(e => e.node.isSearchable);
    let type = item.attribute ? item.attribute.attributeType.input : null;

    let options = type === 'select' && item.attribute
        ? item.attribute.attributeOptions.edges.map(e => ({
            option: e.node,
            value: e.node.id,
            label: getAttributeTranslation(e.node.translation.translationDatas.edges, locales[0].node.code, e.node.identifier),
            checked: item.options.find(f => f.id === e.node.id) !== undefined
        })) : null;

    return (
        <Accordion expanded={expanded === `panel-${id}`} onChange={panelChange(`panel-${id}`)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <DragHandle />
                <Typography className={classes.heading}>{item.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanel}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id={`menu-attribute-select`}>Attribut</InputLabel>
                    <Select
                        labelId={`menu-attribute-select`}
                        id="attribute-select"
                        disabled={item.readonly}
                        value={item.attribute?.id}
                        label="Attribut"
                        onChange={(event) => {
                            item.options = [];
                            item.ranges = [];
                            item.attribute = attrs.find(e => e.node.id === event.target.value)?.node;

                            if (item.attribute.attributeType.input === 'select')
                                item.options = item.attribute.attributeOptions.edges.map(e => e.node);

                            onChange(value);
                        }}
                    >
                        {
                            attrs.map((a, i) => (
                                <MenuItem key={i} value={a.node.id}>
                                    { getAttributeTranslation(a.node.translation.translationDatas.edges, locales[0].node.code, a.node.identifier)}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                {
                    item.attribute && (
                        <>
                            <TextField
                                label="Nom (facultatif)"
                                variant="outlined"
                                value={item.name}
                                className={classes.input}
                                onChange={(event) => {
                                    item.name = event.target.value;
                                    onChange(value);
                                }}
                            />

                            {/* TYPE SELECT */}

                            {
                                type === 'select' && (
                                    <>
                                        <DropdownTreeSelect
                                            data={options}
                                            texts={{ placeholder: 'Chercher...' }}
                                            onChange={(currentNode, selectedNodes) => {
                                                item.options = selectedNodes.map(e => e.option);
                                                onChange(value);
                                            }}
                                        />

                                        <Ordering
                                            options={item.options.map(e => getAttributeTranslation(e.translation.translationDatas.edges, locales[0].node.code, e.identifier))}
                                            onSortEnd={({ oldIndex, newIndex }) => {
                                                item.options = arrayMove(item?.options, oldIndex, newIndex);
                                                onChange(value);
                                            }}
                                        />
                                    </>
                                )
                            }

                            {/* TYPE TEXT OR NUMBER */}

                            {
                                (type === 'text' || type === 'textarea' || type === 'number' || type === 'decimal') && (
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <Chip label="Valeurs" />

                                            <IconButton
                                                style={{ color: 'green', marginLeft: 5 }}
                                                onClick={() => {
                                                    item.ranges.push({
                                                        value: null,
                                                        end: null,
                                                        display: null,
                                                        id: uuidv4()
                                                    });

                                                    onChange(value);
                                                }}
                                            >
                                                <AddCircle />
                                            </IconButton>
                                        </div>


                                        <EntriesManager
                                            value={value}
                                            item={item}
                                            onChange={onChange}
                                            type={type}
                                            isNumber={type === 'number' || type === 'decimal'}
                                        />

                                        <Ordering
                                            options={item.ranges.map(e => e.display)}
                                            onSortEnd={({ oldIndex, newIndex }) => {
                                                item.ranges = arrayMove(item?.ranges, oldIndex, newIndex);
                                                onChange(value);
                                            }}
                                        />
                                    </>
                                )
                            }

                            {
                                item.readonly ? null : (
                                    <DeleteButton
                                        color="secondary"
                                        className={classes.delete}
                                        onClick={() => {
                                            value.filters = value.filters.filter(e => e.id !== item.id);
                                            onChange(value);
                                        }}
                                    >Supprimer</DeleteButton>
                                )
                            }
                        </>
                    )
                }
            </AccordionDetails>
        </Accordion>
    );
});

const SortableList = SortableContainer(({ value, onChange, panelChange, expanded, attributes, locales }) => {
    return (
        <div>
            { value?.filters?.map((item, index) => (
                <SortableItem
                    value={value}
                    onChange={onChange}
                    panelChange={panelChange}
                    key={`item-${index}`}
                    item={item}
                    index={index}
                    id={index}
                    expanded={expanded}
                    attributes={attributes}
                    locales={locales}
                />
            ))}
        </div>
    );
});

const SortableOptionItem = SortableElement(({ item }) => <Chip 
    color="secondary" 
    size="small" 
    label={item ? item.length > 10 ? item.substring(0, 10) + '...' : item : ''} 
    style={{ marginRight: 5, marginBottom: 5, cursor: 'move' }} 
    title={item}
/>);

const SortableOptionList = SortableContainer(({ items }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableOptionItem key={`item-child-${index}`} index={index} item={value} />
            ))}
        </div>
    );
});

function getConfigTranslation(identifier, attributes, lang, def) {
    if (!attributes) return null;

    let translations = attributes[identifier];
    let translation = translations ? translations[lang] : null;

    if (!translation)
        translation = translations ? translations['en_US'] : null; // try english

    return translation || def;
}

function getAttributeTranslation(data, lang, def) {
    if (!data) return null;

    let translation = data.find(t => t.node.locale.code === lang);

    if (!translation)
        translation = data.find(t => t.node.locale.code === 'en_US'); // try english

    return translation?.node.value || def;
}

export default function FinderConfig({ value, onChange, categories, locales, attributes }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const panelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (value && value.filters) {
            value.filters = arrayMove(value?.filters, oldIndex, newIndex);
            onChange(value);
        }
    };

    const addMenuItem = () => {
        value.filters.push({
            id: uuidv4(),
            type: FinderFilterTypes.SELECT,
            name: '[nouveau]',
            attribute: null,
            ranges: [],
            options: []
        });

        onChange(value);
    };

    let options = categories.map(e => ({
        value: e.id,
        label: getConfigTranslation('category_name', e.attributes, locales[0].node.code, e.libelle),
        category: e,
        checked: value.categories?.find(f => f.id === e.id) !== undefined
    }));

    return (
        <div className={classes.root}>
            <Chip label="Étape 1 - Catégories" style={{ marginBottom: 10 }} />

            <DropdownTreeSelect
                data={options}
                texts={{
                    placeholder: 'Chercher...'
                }}
                onChange={(currentNode, selectedNodes) => {
                    value.categories = selectedNodes.map(e => e.category);
                    onChange(value);
                }}
            />

            <Ordering
                options={value.categories.map(e => getConfigTranslation('category_name', e.attributes, locales[0].node.code, e.libelle))}
                onSortEnd={({ oldIndex, newIndex }) => {
                    value.categories = arrayMove(value?.categories, oldIndex, newIndex);
                    onChange(value);
                }}
            />

            <Divider variant="middle" style={{ marginTop: 20, marginBottom: 10 }} />

            <Chip label="Étape 2 - Filtres" style={{ marginBottom: 10, marginTop: 10 }} />

            <SortableList
                value={value}
                onChange={onChange}
                onSortEnd={onSortEnd}
                panelChange={panelChange}
                expanded={expanded}
                useDragHandle
                attributes={attributes}
                locales={locales}
                helperClass="helper"
            />

            <div className={classes.actionBar}>
                <Chip
                    label="Ajouter"
                    onClick={addMenuItem}
                    icon={<AddCircle />}
                />
            </div>
        </div>
    );
}
