import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../ui/typography/Typography';

import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';

import * as moment from 'moment';

import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

//ICONES Twitter
import CachedIcon from '@material-ui/icons/Cached';
import FavoriteIcon from '@material-ui/icons/Favorite';

const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const PostDate = styled(Typography)`
    float:right;
    color:#0273A5;
`

const SeeMore = styled(Typography)`
    color:${colors.blue.darker.hue300};
    text-align: center;
    cursor: pointer;
`

const PostTitle = styled(Typography)`
    min-height: 50px;
    max-height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`
const ContentWrapper = styled.div`
    color: ${colors.grey.regular};
    
    h2{
        line-height: 20px;
        font-size: 16px; 
        padding: 0 20px;
    }
    h3{
        line-height: 20px;
        padding: 0 20px;
    }
    p{
        max-height: 160px;
        overflow: hidden;
        line-height: initial;
        &:not(:first-child){
            padding: 0 20px;
        }
    }
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);


export default class CardFacebook extends React.Component{
    constructor(props){
        super(props)
        this.state={
            numberOfItems : 3
        }         

        this.twitterIcons={
            Retweet : <CachedIcon/>,
            Fav     : <FavoriteIcon/>
        }   
    }

    handleClick() {
        this.setState({numberOfItems: this.state.numberOfItems + 3})
    }


    render() {
        let posts=this.props.data;
        return (
            <>
            {posts ? posts.slice(0, this.state.numberOfItems).map((post,index)=>{                             
                return(
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"} style={{height: '100%'}} cardContentStyle={{display: 'flex', flexDirection: 'column'}}>
                                <CardHeader
                                    action={
                                    <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post)}}>
                                        <MoreVertIcon style={{color:'#0273A5'}}/>
                                    </IconButton>
                                    }
                                />
                                <Grid container style={{flex: '1 0 auto', padding: "0 20px"}}>
                                    <Grid item xs={12}>
                                        <PostTitle variant="h2" style={{padding:'0 20px'}}>
                                            {post.title.rendered}
                                        </PostTitle>
                                        <ContentWrapper>
                                            <ResponsiveEllipsis
                                            unsafeHTML={post.content.rendered}
                                            maxLine='4'
                                            ellipsis='...'
                                            basedOn='letters'
                                            />
                                        </ContentWrapper>
                                    </Grid>
                                    <Grid container space={1} style={{marginTop:10}} alignItems="flex-end">
                                        <Grid item xs={12} style={{padding:'0  20px'}}>
                                            <Grid item style={{flexGrow:'1'}}>
                                                <PostDate variant="body2">{moment(post.date).format('L')}</PostDate>
                                            </Grid>
                                        </Grid>
                                    </Grid>                                    
                                </Grid>
                        </CardCustom>
                    </FeedItem>     
                )
            }
            )
            : <PageLoader/>}
            {posts?.length > this.state.numberOfItems ?
                <Grid container justifyContent="center" style={{paddingBottom: 24}}>
                    <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                </Grid>
                : null 
            }
            </>
        );
    }
}