import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function tarifAddConfig() {
    let generalInputs = [
        {
            type: 'text',
            label: 'Nom',
            helper: {
                text: 'Indiquez le nom du tarif',
                link: false,
            },
            required: true,
            stateName: 'name',
        },
        {
            type: 'decimal',
            label: 'Prix',
            helper: {
                text: 'Indiquez le tarif',
                link: false,
            },
            required: true,
            stateName: 'price',
        },
    ]
    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
    ];

    var obj = {
        titleForm: 'Ajouter un tarif',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre tarif',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Votre tarif est désormais configuré',
                textButton: 'Créer ce tarif'
            },
            children
        }
    };
    return obj;
}