import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../config/theme/colors';
import Button from '../../../../ui/button/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputBuilder from '../../../../ui/form/InputBuilder';

const DivColor = styled.div`
    border: 1px solid lightgrey!important;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0 0;
`;
const DivMarkups = styled.div`
    border-radius:  0 0 5px 5px!important;
    border: 1px solid lightgrey!important;
    border-top: 0!important;
    background: white;
    width: 100%;
    padding: 6px 16px;
    margin-bottom: 16px;
    &>div:first-child{
        display: flex;
        align-items: center;
        padding: 6px 0px;
    }
`;

class DocumentPicker extends React.Component {
    state = {
        document: this.props.document,
        config: {
            type: 'mediaPicker',
            handleMediaPicker: (selected,stateName) => {this.handleInputChange(stateName, selected, null, this.state.currentLang)},
            stateName: 'document',
            label: 'Image de la publication',
            helper: {
                text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                link: false,
            },
            required: false
        }, 
    };

    handleInputChange = (stateName, evt, custom, translated ) => {
        if(evt){
            const value = evt?.target?.value ?? evt;
            this.setState({
                document: value
            }, () => {
                this.props.handleChangeComplete(this.state.document)
            });
        } else {
            this.props.deleteDocument();
        }
    };

    componentDidUpdate = (prevProps) => {
        if(this.props.document !== prevProps.document){
            this.setState({document: this.props.document})
        }
    }
    componentDidMount = () => {
        this.setState({document: this.props.document})
    }
    
    render() {
        return (
            <>
                {
                    this.state.document ? (
                        <div style={{width: '100%', position: 'relative'}}>
                            <DivColor style={{borderRadius: this.state.document.id === "" ? '5px 5px 0 0' : 5, marginBottom: this.state.document.id === "" ? 0 : 16}}>
                                <InputBuilder input={this.state.config} value={this.state.document} />
                            </DivColor> 
                            {
                                this.state.document.id === '' ? (
                                    <DivMarkups>
                                        <Button onClick={ () => {this.props.deleteDocument()} } bgcolor={colors.white} bgcolorhover={colors.white} color={colors.red.regular} colorhover={colors.red.darker} fontsize={12} style={{width: '100%', margin: 0}}>
                                            <HighlightOffIcon style={{fontSize: '1rem', marginRight: 5}} /> 
                                            Supprimer
                                        </Button>
                                    </DivMarkups>           
                                ) : null
                            }
                        </div>
                    ) : null
                }
            </>
        )
    }
}

export default DocumentPicker;