import PropTypes from "prop-types";
import React from "react";
import { setActiveSort } from "../../../helpers/product";
import Link from 'next/link';

const LinkCustom = (props) => {
  if (props.spread) {
      return <>
          { props.children }
      </>
  } else {
      return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
  }
}

const ShopCategories = ({ spread, categories, getSortParams }) => {
  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Catégories </h4>
      <div className="sidebar-widget-list mt-30">
        {categories ? (
          <ul>
            {/* <li>
              <div className="sidebar-widget-list-left">
                <button
                  onClick={e => {
                    getSortParams("category", "");
                    setActiveSort(e);
                  }}
                >
                  <span className="checkmark" /> Toutes
                </button>
              </div>
            </li> */}
            {categories.map((category, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <LinkCustom spread={spread} href="/category/[slug]" as={`/category/${category.value}`}>
                      <button
                        onClick={e => {
                          getSortParams("category", category);
                          setActiveSort(e);
                        }}
                      >
                        { category.name }
                        {/* {" "} */}
                        {/* <span className="checkmark" /> {category}{" "} */}
                      </button>
                    </LinkCustom>
                  </div>
                </li>
              );
            })}

            { 
              categories.length === 0 && (
                <li key="no-result">
                  <div className="sidebar-widget-list-left">
                    Aucune catégorie enfant
                  </div>
                </li>
              ) 
            }
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;
