import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultProductHome, getDefaultTextCms, getDefaultLinkCms, getDefaultBackgroundCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider de produits',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
    },
    blocks:{
        background: getDefaultBackgroundCms('rgba(255,255,255,0)'),
        title: getDefaultTextCms("Titre", "Produits", null),
        link: getDefaultLinkCms("Lien", "Tout afficher", "https://www.lecoqsportif.com/", null, null),
        products:{
            title: "Produit",
            name: "Produit",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_PRODUCTS_HOME,
            buttonAdd: 'un Produit',
            limit: 32,
            config: [ 
                getDefaultProductHome(),
                getDefaultProductHome(),
                getDefaultProductHome(),
                getDefaultProductHome(),
            ]
        }
    }
};