import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function addDemandsForm(handleMediaPicker) {
    return({
        titleForm: "Créer une nouvelle demande",
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer une nouvelle demande',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        textButtonValidate: 'Ajouter la demande',
        formConfig: {
            type: FORM,
            finalStep:{},
            children:[
                {
                    labelName: 'Identité',
                    optionsInputs:[
                        {
                            type: 'mediaPicker',
                            label: 'Image de la demande',
                            translated: false,
                            handleMediaPicker: handleMediaPicker,
                            helper:  {
                                text: 'Choisir une image',
                                link: false,
                            },
                            required: true,
                            stateName: 'image',
                        },
                        {
                            type: 'text',
                            label: 'Code',
                            helper: {
                                text: 'Indiquez le code ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'code',
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: 'Indiquez le nom ici',
                                link: false,
                            },
                            required: true,
                            stateName: 'name',
                        },
                    ]
                },
            ]
        }
    })
}

