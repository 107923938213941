import { FormInputTypes } from '../../../shareable/types';

export default {
    name: 'Bloc personnalisable',
    inputs: {
        sources: {
            label: 'Sources',
            type: FormInputTypes.CUSTOM,
            value: {
                html: `<div class="parent-class">Vous pouvez personnaliser votre composant.</div>`,
                css: `.parent-class {}`,
                js: ''
            }
        }
    }
};