import CardMerchandisingVersions from '../../../../../layouts/Card/cardContent/CardMerchandisingVersions';

export const listSettings = {
    grid : {
        components : CardMerchandisingVersions,
        columns : {
            xs : 1,
            sm : 2,
            md: 2,
            laptop : 3,
        }
    },
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    // {
    //     view : 'table',
    //     mapper : 'companiesListMapper'
    // }
]

export const perPageOptions = {
    'card' : ['3'],
}