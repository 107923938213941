import { FormInputTypes } from '../../../shareable/types';
import { getDefaultTextarea } from '../../helpers';

export default {
    name: 'Footer',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        footerText: getDefaultTextarea("Texte du footer", "Jeu sans obligation d’achat. Valable en France métropolitaine (Corse comprise).  X a chargé la société SINFIN (1 avenue Louis François Armand – 10340 Rosières-près-Troyes) de collecter votre adresse mail pour vous permettre de participer au jeu concours à l’occasion de l’activation promotionnelle. L’adresse email que vous avez renseignée dans le formulaire sera utilisée par X pour confirmer votre participation. Ces informations seront supprimées dans un délai de 4 mois à compter de la fin du jeu-concours. Elles seront accessibles aux équipes X et SINFIN. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que  vous avez conclu avec X en acceptant le règlement du jeu-concours, consultable ici. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours.", true, "#53839D", null, 'initial', 'initial', 12),
    }
};