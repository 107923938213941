export default {
    getTotals: (phases, taxes) => {
        let totalHT = 0;

        for (let phase of phases) {
            for (let tache of phase.taches) {
                totalHT += +tache.total;
            }
        }

        let totalTTC = totalHT;
        let totals = [];

        for (let { node } of taxes) {
            let tasks = [];

            for (let phase of phases) {
                let allTaches = phase.taches.filter(e => !e.deleted);

                for (let tache of allTaches) {
                    if (tache.tva === node.id) {
                        tasks.push(tache);
                    }
                }
            }

            let total = 0;

            for (let tache of tasks) {
                total += +tache.total / 100 * node.value;
            }

            totalTTC += total;

            totals.push({
                tax: node,
                total
            });
        }

        // Calculate discounts

        let totalRemises = 0;

        for (let phase of phases) {
            let allTaches = phase.taches.filter(e => !e.deleted);

            for (let tache of allTaches) {
                let HT = tache.qty * tache.price;
                totalRemises += HT - tache.total;
            }
        }

        return {
            ht: totalHT,
            ttc: totalTTC,
            remises: totalRemises,
            totals
        };
    }
}