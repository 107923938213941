import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getDefaultLink } from '../../helpersBook';
import { getImageDefault, getDefaultButton } from '../../helpers';

export default {
    name: "Section du récapitulatif",
    blocks: {
        imageLeft: getImageDefault(null, "Image titre"),
        title: getDefaultText("Titre", "Félicitations !"),
        subtitle: getDefaultText("Sous-titre", "votre liste de cadeaux est prête !"),
        explanation: {
            id: uuidv4(),
            name: "Bloc récapitulatif",
            type: EditableTypes.MINI_BLOCKS,
            blocks:{
                titleExplanation: getDefaultText("Titre", "Inscrivez-vous au tirage au sort"),
                subtitleExplanation: getDefaultTextarea("Sous-titre", "<p>Entrez votre adresse e-mail pour <strong>valider</strong> votre liste et <strong>votre inscription</strong></p>", true),
                labelConsent: getDefaultText("Libellé de consentement", "J'accepte le réglement du jeu"),
                conditions: getDefaultText("Consentement supplémentaire", "Je souhaite recevoir la newsletter LEGO® Family par mail*"),
                button: getDefaultLink("Bouton", "Participer au tirage au sort"),
                titleRecap: getDefaultText("Titre", "Produits sélectionnés dans ma liste :"),
                buttonBack: getDefaultLink("Bouton retour", "< Modifier"),
            }
        },
    }
};
