import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { Box, Typography } from '@material-ui/core';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import infoManagement from './config/infoManagements/infoManagementsEdit';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { SETTINGS, SETTINGS_CURRENCIES, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';

import { prepareAttributeValues, saveElement, updateElement, getElements } from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import { listSettings, listMappers, perPageOptions } from './config/infoManagements/listInfoManagements.config';
import * as formHelper from '../../../../js/helpers/form'
import { makeUnique, searchItem } from '../../../../js/helpers/search'
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { DELETE_INFO_MANAGEMENT } from '../../../../queries/info_management';
import { GET_CURRENCIES } from '../../../../queries/currencies';
import { GET_ASSET_BY_ID_CATALOG, GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';
import styled from 'styled-components';
import { checkRouting } from '../../../../js/utils/checkRouting';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import { withTranslation } from 'react-i18next';
import DialogModal from '../../../ui/dialog/DialogModal';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

const PageWrapper = styled(Box)`
    width: 100%;
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
    position: relative; 
    height: 100%;
    display: flex;
    flex-direction: column;

    & > .layout-wrapper {
        display: none;
    }
`;

class EngineAssetsInfoManagements extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm: false,
            openDialog: false,
            editForm: 'add',
            currentLang: props.locales[0].node.code,
            assetId: `/api/assets/${props.match.params.id}`,
            errors: {},
            seeErrors: false,
            ready: false,
            infoManagementLibelle: null,
            infoManagementLibelleColor: {
                hex: "#ffffff"
            },
            infoManagementMedia: null,
            infoManagementStatus: true,
            infoManagementTextButton: null,
            infoManagementTextColorButton: {
                hex: "#ffffff"
            },
            infoManagementColorButton: {
                hex: "#ffffff"
            },
            infoManagementType: 'product',
            infoManagementLinkButton: null,
            categoriesSelected: [],
            isReady: false,
        };
    }

    handleLang = (event) => {
        this.setState({
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };

    handleToggleDrawer = (stateDrawer, reset = false) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset) {
            this.resetState()
        }
    };


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () => {
        let result = formHelper.handleStep('next', this.state.errors);
        this.setState({
            seeErrors: !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleMediaPicker = (selected, stateName, translated) => {
        this.handleInput(stateName, selected, null, translated);
    }

    handleInput = (stateName, evt, custom, translated) => {
        let value = formHelper.setValue(this.state, stateName, evt, custom, translated);
        if (stateName === "infoManagementType") {
            this.setState({
                categoriesSelected: [],
                selectedProducts: [],
            })
        }
        this.setState({ ...value })
    }

    resetState = () => {
        this.setState({
            infoManagementLibelle: null,
            infoManagementStatus: true,
            infoManagementMedia: null,
            infoManagementTextButton: null,
            infoManagementLibelleColor: {
                hex: "#ffffff"
            },
            infoManagementTextColorButton: {
                hex: "#ffffff"
            },
            infoManagementColorButton: {
                hex: "#ffffff"
            },
            infoManagementType: 'product',
            infoManagementLinkButton: null,
            categoriesSelected: [],
            selectedProducts: [],
            errors: {},
        })
    }

    handlerInfoManagementMutation = async () => {
        this.props.startLoading();
        let variables = null;
        switch (this.state.editForm) {
            case 'add':

                variables = {
                    libelle: this.state.infoManagementLibelle,
                    libelleColor: this.state.infoManagementLibelleColor.hex,
                    status: this.state.infoManagementStatus,
                    textButton: this.state.infoManagementTextButton,
                    textColorButton: this.state.infoManagementTextColorButton.hex,
                    colorButton: this.state.infoManagementColorButton.hex,
                    linkButton: this.state.infoManagementLinkButton,
                    type: this.state.infoManagementType,
                    product: this.state.infoManagementType === "product" ? this.state.selectedProducts : [],
                    category: this.state.infoManagementType === "category" ? this.state.categoriesSelected : [],
                    asset: this.state.assetId,
                    media: this.state.infoManagementMedia?.id
                }
                await saveElement('infoManagement', variables, { enableLoad: false })
                this.props.snack(ALERT_SUCCESS, "Publicité ajoutée avec succès");
                this.handleToggleDrawer('openForm', true);
                this.reloadInfoManagements();
                this.props.stopLoading();
                break;

            case 'edit':

                variables = {
                    id: this.state.currentInfoManagementId,
                    libelle: this.state.infoManagementLibelle,
                    libelleColor: this.state.infoManagementLibelleColor.hex,
                    status: this.state.infoManagementStatus,
                    textButton: this.state.infoManagementTextButton,
                    textColorButton: this.state.infoManagementTextColorButton.hex,
                    colorButton: this.state.infoManagementColorButton.hex,
                    linkButton: this.state.infoManagementLinkButton,
                    type: this.state.infoManagementType,
                    product: this.state.infoManagementType === "product" ? this.state.selectedProducts : [],
                    category: this.state.infoManagementType === "category" ? this.state.categoriesSelected : [],
                    asset: this.state.assetId,
                    media: this.state.infoManagementMedia?.id
                }
                let getResult = await updateElement(this.state, 'infoManagement', variables, null, { enableLoad: false })
                if (getResult) {
                    this.props.snack(ALERT_SUCCESS, "Publicité modifiée avec succès");
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadInfoManagements();
                }

                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_INFO_MANAGEMENT,
            variables: { id: this.state.currentInfoManagementId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, "Publicité supprimée avec succès");
            this.reloadInfoManagements();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, "Impossible de supprimer la publicité");
            this.reloadInfoManagements();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        this.setState({
            [stateName]: value?.target?.value ?? value
        }, callback);
    };

    initForm = () => {
        this.setState({
            editForm: 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler = (nodeInfo) => {
        this.setState({
            editForm: 'edit',
            currentInfoManagementId: nodeInfo.id,
            infoManagementLibelle: nodeInfo.libelle,
            infoManagementLibelleColor: {
                hex: nodeInfo.libelleColor
            },
            infoManagementStatus: nodeInfo.status,
            infoManagementTextButton: nodeInfo.textButton,
            infoManagementTextColorButton: {
                hex: nodeInfo.textColorButton
            },
            infoManagementColorButton: {
                hex: nodeInfo.colorButton
            },
            infoManagementLinkButton: nodeInfo.linkButton,
            infoManagementType: nodeInfo.type,
            selectedProducts: nodeInfo.product.edges.map(e => e.node.id),
            categoriesSelected: nodeInfo.category.map(e => e.id),
            infoManagementMedia: nodeInfo.media
        })
        this.handleToggleDrawer('openForm');
    }

    getInfoManagementsCallback = () => {
        this.setState({
            reloadInfoManagements: false
        })
    }
    reloadInfoManagements = () => {
        this.setState({ reloadInfoManagements: true })
    }

    handleGetAssetInfo = () => {
        this.props.client.query({
            query: GET_ASSET_BY_ID_CATALOG,
            fetchPolicy: 'no-cache',
            variables: { "id": this.state.assetId }
        }).then(result => {
            this.setState({
                assetInfo: result.data.asset,
                isReady: true
            })
        });
    }

    initNavAsset = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${this.props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    componentDidMount() {
        checkRouting(this.props);
        this.handleGetAssetInfo();
        if (this.props.location.state?.hasMessage) {
            this.props.snack(ALERT_SUCCESS, this.props.location.state.message)
        }
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            this.initNavAsset()
        }
    }

    render() {
        return (
            <PageWrapper>
                <TopPanel
                    icomoon="picto-analytics"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Publicités"
                    subtitle="Gestion de vos publicités (création / modification / suppression)"
                    handlerAdd={() => this.initForm()}
                    textAdd={"+ Ajouter une publicité"}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    hasBorder={true}
                />
                <Grid container style={{flexGrow: 1}}>
                    <Listing
                        label='publicités'
                        settings={listSettings}
                        cardProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            textButton: 'Modifier',
                            handlerButton: this.editHandler,
                            windowWidth: this.props.windowWidth,
                        }}
                        cardContainerProps={
                            {
                                style: {
                                    height: "100%"
                                }
                            }
                        }
                        perPageOptions={perPageOptions}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        queryVariables={{
                            asset: this.state.assetId,
                        }}
                        identifier='infoManagements'
                        viewsOptions={{
                            current: 'card',
                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadInfoManagements}
                        listingCallback={this.getInfoManagementsCallback}
                        noResultComponent={() => <Box><EmptyCard title={this.props.t("spread.active_assets.pubNotConfigured")} subtitle={this.props.t("spread.active_assets.clickToAdd")} textButton={this.props.t("spread.active_assets.addPub")} onClick={() => this.initForm()} picto={EmptyAsset} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} /></Box>}
                    />
                </Grid>

                {this.state.isReady ? (
                    <LayoutBuilder
                        isSublayout={false}
                        icomoon="picto-analytics"
                        opened={this.state.openForm}
                        forClose={() => this.handleToggleDrawer('openForm', true)}
                        handlerSetup={() => { }}
                        dataLayout={infoManagement(this.state.editForm, this.handleMediaPicker, this.state)}
                        drawerWidth={this.props.drawerWidth}
                        allState={this.state}
                        stateCallback={this.handleInput}
                        errorCallback={this.handleFormError}
                        stepperButtonAction={[
                            this.handleNextStep,
                            this.handleNextStep,
                            this.handleNextStep,
                        ]}
                        validateButton={true}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        handlerMutation={this.handlerInfoManagementMutation}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        deleteMutation={this.state.editForm === "edit" ? () => { this.handleToggleDialog() } : null}
                        deleteText={"Supprimer la publicité"}
                    />
                )
                    : null}

                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    title={'Êtes-vous sûr de vouloir supprimer cette publicité ?'}
                    primaryAction={this.deleteMutation}
                    secondaryAction={this.handleToggleDialog}
                    windowWidth={this.props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez cette publicité, celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </PageWrapper>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({ ...a, node: { ...a.node } }) : ({ ...a }));

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(EngineAssetsInfoManagements)))));