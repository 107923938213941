import { gql } from 'apollo-boost';

//TODO Dono
//----- Récupération des catégories
export const GET_CATEGORIES = gql`
{
  categories{
    id
    libelle
    status
    position
    parent {
        id
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    productCategories{
      edges{
        node{
          position
          product{
            id
            sku
            status
            attributeGroup {
              id
              identifier
            }
            productCategories{
              edges{
                node{
                  position
                  category{
                    id
                    libelle
                  }
                }
              }
            }
            productDatas{
              edges{
                node{
                  id
                  value
                  selection{
                      id
                      identifier
                  }
                  attributeOption{
                    id
                    identifier
                  }
                  attribute{
                    identifier
                    isSystem
                  }
                  locale {
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                    category{
                      edges{
                        node{
                          id
                          libelle
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

//TODO Dono
export const GET_CATEGORIES_LIGHT = gql`
query getCategoriesLight($status: Boolean, $exists:[CategoryFilter_exists]) {
  categories(exists:$exists){
    id
    libelle
    status
    position
    parent {
      id
      libelle
      status
    }
    master{
      id
      libelle
    }
    productCategories(product_status: $status, exists:{product_parent: false}){
      totalCount
    }
    catalog{
      id
      identifier
    }
    categoryFilters{
      edges{
        node{
          id
          value
          attribute{
            id
            attributeOptions {
              edges {
                node {
                  id
                  identifier
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            attributeType{
              id
              input
              translation{
                id
                translationDatas{
                  edges{
                    node{
                      id
                      value
                      locale{
                        code
                        libelle
                      }
                    }
                  }
                }
              }
            }
          }
          attributeOption{
            id
          }
          operator
        }
      }
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

//TODO Dono
export const GET_CATEGORIES_BY_ID_LIST_LIGHT = gql`
query categoriesByIdList($idList: [Int]) {
  categories(id_list: $idList){
    id
    libelle
    status
    position
    parent {
        id
    }
    master{
      id
      libelle
    }
    productCategories{
      totalCount
    }
    catalog{
      id
      identifier
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

//TODO Dono
export const SEARCH_CATEGORIES = gql`
query categoryDataSearch($attribute: String, $needle: String) {
  categoryDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        category {
          id
          libelle
          status
          parent {
            id
          }
          master {
            id
            libelle
          }
          catalog {
            id
            identifier
          }
          productCategories {
            edges {
              node {
                position
                product{
                  id
                  sku
                  attributeGroup {
                    id
                    identifier
                  }
                  productCategories {
                    edges{
                      node{
                        position
                        category{
                          id
                          libelle
                        }
                      }
                    }
                  }
                  productDatas {
                    edges {
                      node {
                        id
                        value
                        selection{
                            id
                            identifier
                        }
                        attributeOption {
                          id
                          identifier
                        }
                        attribute {
                          identifier
                          isSystem
                        }
                        locale {
                          code
                        }
                        media {
                          id
                          filePath
                          type
                          size
                          category {
                            edges {
                              node {
                                id
                                libelle
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          categoryDatas {
            edges {
              node {
                id
                value
                attribute {
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//TODO Dono
export const GET_CATEGORIES_BY_IDLIST = gql`
query category($id: ID!){
  category(id: $id){
    id
    libelle
    status
    parent {
        id
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    productCategories{
      totalCount
      edges{
        node{
          position
          product{
            id
            sku
            status
            attributeGroup {
              id
              identifier
            }
            productCategories{
              edges{
                node{
                  position
                  category{
                    id
                    libelle
                  }
                }
              }
            }
            productDatas{
              edges{
                node{
                  id
                  value
                  selection{
                      id
                      identifier
                  }
                  attributeOption{
                    id
                    identifier
                  }
                  attribute{
                    identifier
                    isSystem
                  }
                  locale {
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                    category{
                      edges{
                        node{
                          id
                          libelle
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
          
        }
      }
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;

//----- Récupération des catégories sans les produits
export const GET_CATEGORIES_ONLY = gql`
query getCategoriesOnly($exists:[CategoryFilter_exists]) {
  categories(exists:$exists){
    id
    libelle
    status
    position
    parent {
        id
        libelle
        categoryDatas{
          edges{
            node{
              value
            }
          }
        }
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    type
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Récupération d'un catégorie pour merchandising
export const GET_CATEGORIE_MERCH = gql`
query categoryMerch($id: ID!){
  category(id: $id){
    id
    libelle
    status
    position
    parent {
        id
    }
    master{
      id
      libelle
    }
    children{
      id
      libelle
      parent{
        id
      }
      merchandisings{
        totalCount
      }
      categoryDatas{
        edges{
          node{
            value
            attribute{
              identifier
            }
            media{
              filePath
            }
            locale{
              code
            }
          }
        }
      }
    }
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;


//----- Récupération des données d'une catégorie
/*
  Exemple de variables
  {
    "id" : "/api/categories/5"
  }
*/
export const GET_CATEGORY_DATA = gql`
query categoryDatas($id: ID!) {
  category(id: $id) {
    id
    libelle
    position
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

//----- Ajouter une catégorie
/*
 Exemple de variables
  {
    "libelle" : "Cat de démo",
    "parent" : "/api/categories/1",
    "position" : 1
  }
*/
export const ADD_CATEGORY = gql`
mutation AddCategory($position: Int, $libelle: String!, $parent: String, $catalog: String, $master: String, $products: [Int], $status: Boolean, $type: String, $cmsPage: String, $contentCategory: String){
  createCategory(input: {position: $position, libelle:$libelle, parent:$parent, catalog:$catalog, master:$master, products:$products, status: $status, type: $type, cmsPage: $cmsPage, contentCategory: $contentCategory}){
    category{
      id
      libelle
      status
      position
      type
      master{
        id
        libelle
      }
      catalog{
        id
        identifier
      }
      parent {
        id
      }
    }
  }  
}`;

//----- Modifier une catégorie
/*
  Exemple de variables
  {
    "id" : "/api/categories/5",
    "libelle" : "libelle edited",
    "parent" : "/api/categories/1",
    "position" : 1
  }
*/
export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($id: ID!, $position: Int, $libelle: String, $parent: String, $catalog: String, $master: String, $products: [Int], $status: Boolean, $type: String, $cmsPage: String, $contentCategory: String){
  updateCategory(input: {id:$id, position: $position, libelle:$libelle, parent:$parent, catalog:$catalog, master:$master, products:$products, status: $status, type: $type, cmsPage: $cmsPage, contentCategory: $contentCategory}){
    category{
      id
      libelle
      status
      position
      master{
        id
        libelle
      }
      catalog{
        id
        identifier
      }
      parent {
        id
      }
    }
  }  
}`;


export const UPDATE_CATEGORY_POSITION = gql`
mutation UpdateCategory($id: ID!, $position: Int, $parent: String){
  updateCategory(input: {id:$id, position: $position, parent: $parent}){
    category{
      id
      libelle
      position
    }
  }  
}`;

//----- Supprimer une catégorie
/*
  Exemple de variables
  {
    "id" : "/api/categories/1"
  }
*/
export const DELETE_CATEGORY = gql`
mutation DeleteCategory($id: ID!){
	deleteCategory(input: {id:$id}){
    category{
      id
    }
  }  
}`;



//--------------------
//----- DONNEES CATEGORIES 
//--------------------

//----- Ajouter une valeur d'attribut à une catégorie

/*
 Exemple de variables
 {
  "value": "Catégorie 1 de test",
	"category": "/api/categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CATEGORY_DATA = gql`
mutation AddCategoryData($value: String, $category: String!, $attribute: String!, $locale: String!, $media: String){
	createCategoryData(input: {value:$value, category:$category, attribute:$attribute, locale:$locale, media: $media}){
    categoryData{
      id
      value
      category{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;



//----- Modifier la valeur d'attribut d'une catégorie

/*
 Exemple de variables
 {
  "id": "/api/category-datas/1",
  "value": "Catégorie 1 de test edited",
	"category": "/api/categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CATEGORY_DATA = gql`
mutation UpdateCategoryData($id: ID!, $value: String, $category: String!, $attribute: String!, $locale: String!, $media: String){
	updateCategoryData(input: {id:$id, value:$value, category:$category, attribute:$attribute, locale:$locale, media:$media}){
    categoryData{
      id
      value
      category{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;


//----- Supprimer une valeur d'attribut d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/category-datas/68"
  }
*/
export const DELETE_CATEGORY_DATA = gql`
mutation DeleteCategoryData($id: ID!){
	deleteCategoryData(input: {id:$id}){
    categoryData{
      id
    }
  }  
}`;




export const GET_CATEGORIES_LIGHT_2 = gql`
query getCategoriesLight($catalog : String, $exists:[CategoryFilter_exists]) {
  categories(catalog: $catalog, exists:$exists){
    id
    libelle
    status
    position
    parent {
      id
      libelle
      status
    }
    master{
      id
      libelle
    }
    catalog{
      id
      identifier
    }
    type
  
    categoryDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  } 
}
`;


export const GET_CATEGORIES_FLAT = gql`
query getCategoriesLight ($after:String){
  categoryFlats(after:$after,first:100) {
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        categoryStaticId
        category{
          id
        }
        name
        parent{
          id
          categoryStaticId
          name
          category{
            id
          }
        }
      }
    }
  }
}
`;