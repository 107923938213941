import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Email de partage",
    key: "email_share_win_random_draw",
    type: "email_share_win_random_draw",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
    },
    blocks: {
        logo: getImageDefault(null, "Logo"),
        logoLink: {
            type: FormInputTypes.TEXT,
            label: "Lien de l'image logo",
            inputs : {
                value : {
                    type: FormInputTypes.TEXT,
                    label: "Lien de l'image logo",
                    value: null,
                }
            }
        },
        title: getDefaultText("Titre", "BRAVO !", 40, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        subtitle: getDefaultText("Sous-titre", "Votre inscription au tirage au sort a bien été enregistrée !",  24, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        description: getDefaultText("Description", "Rendez-vous le JJ/MM/AAAA pour le résultat final*",  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        titleList: getDefaultText("Titre de la liste", "Liste de cadeaux de :",  18, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        imagePreFooter: getImageDefault(null, "Image avant le footer"),
        imagePreFooterLink: {
            type: FormInputTypes.TEXT,
            label: "Lien de l'image avant le footer",
            inputs : {
                value : {
                    type: FormInputTypes.TEXT,
                    label: "Lien de l'image avant le footer",
                    value: null,
                }
            }
        },
        footer: {
            id: uuidv4(),
            name: "Paramétrage du bloc 2",
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: "#53839D",
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
            },
        },
        footerText: getDefaultTextarea("Texte du footer", "Jeu sans obligation d’achat. Valable en France métropolitaine (Corse comprise).  X a chargé la société SINFIN (1 avenue Louis François Armand – 10340 Rosières-près-Troyes) de collecter votre adresse mail pour vous permettre de participer au jeu concours à l’occasion de l’activation promotionnelle. L’adresse email que vous avez renseignée dans le formulaire sera utilisée par X pour confirmer votre participation. Ces informations seront supprimées dans un délai de 4 mois à compter de la fin du jeu-concours. Elles seront accessibles aux équipes X et SINFIN. L’utilisation de vos données personnelles est nécessaire à l’exécution du contrat que  vous avez conclu avec X en acceptant le règlement du jeu-concours, consultable ici. Si vous ne souhaitez pas fournir ces informations, vous ne pourrez malheureusement pas participer au jeu-concours.", true, "#FFF", null, 'initial', 'initial', 12),
    }
};
