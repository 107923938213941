import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from '../../../../../../store.js';
import { SocialNetworks } from "../../../../../../types.js";
import Button from '../../../../../shared/components/CustomButton';

const ProductDescriptionInfo = ({
  product,
  params,
  data,
  preview,
  id,
  inputCallback
}) => {
  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  );
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock
  );
  const [quantityCount, setQuantityCount] = useState(1);

  const [added, setAdded] = useState(false);
  const context = useContext(Context);

  useEffect(() => {
    let all = context?.products;

    if (all)
        setAdded(all.find(e => e === product.id));
  }, []);

  const productCartQty = 0;

  let allNames    = product.attributes.product_name;
  let name        = allNames?.['fr_FR'] ?? '';

  let allDesc     = product.attributes.product_description;
  let desc        = allDesc?.['fr_FR'] ?? '';

  let allPrices   = product.attributes.product_price;
  let price       = allPrices?.['fr_FR'] ?? '';

  const wishlistCallback = () => {
    let all = localStorage.getItem('products');

    if (all) {
        all = JSON.parse(all);

        if (added) {
            all = all.filter(e => e !== product.id);
            setAdded(false);
            context.update(all);
        } else {
            all.push(product.id);
            setAdded(true);
            context.update(all);
        }
    } else if (!added) {
        all = [product.id];
        setAdded(true);
        context.update(all);
    }

    localStorage.setItem('products', JSON.stringify(all || []));
  };

  const showPrice =  params?.displayPrices?.value;

  return (
    <div className="product-details-content ml-70">
      <h2>{ name }</h2>

      {
        showPrice ? (
          <div className="product-details-price">
            { price && price !== '0' ? <span>{ price ?? 0 } €</span> : null }
          </div>
        ) : null 
      }

      {
        desc ? (
          <div className="pro-details-list">
            <p dangerouslySetInnerHTML={{ __html: desc }} />
          </div>
        ) : null 
      }

      {product.variation ? (
        <div className="pro-details-size-color">
          <div className="pro-details-color-wrap">
            <span>Color</span>
            <div className="pro-details-color-content">
              {product.variation.map((single, key) => {
                return (
                  <label
                    className={`pro-details-color-content--single ${single.color}`}
                    key={key}
                  >
                    <input
                      type="radio"
                      value={single.color}
                      name="product-color"
                      checked={
                        single.color === selectedProductColor ? "checked" : ""
                      }
                      onChange={() => {
                        setSelectedProductColor(single.color);
                        setSelectedProductSize(single.size[0].name);
                        setProductStock(single.size[0].stock);
                        setQuantityCount(1);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="pro-details-size">
            <span>Size</span>
            <div className="pro-details-size-content">
              {product.variation &&
                product.variation.map(single => {
                  return single.color === selectedProductColor
                    ? single.size.map((singleSize, key) => {
                        return (
                          <label
                            className={`pro-details-size-content--single`}
                            key={key}
                          >
                            <input
                              type="radio"
                              value={singleSize.name}
                              checked={
                                singleSize.name === selectedProductSize
                                  ? "checked"
                                  : ""
                              }
                              onChange={() => {
                                setSelectedProductSize(singleSize.name);
                                setProductStock(singleSize.stock);
                                setQuantityCount(1);
                              }}
                            />
                            <span className="size-name">{singleSize.name}</span>
                          </label>
                        );
                      })
                    : "";
                })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {product.affiliateLink ? (
        <div className="pro-details-quality">
          <div className="pro-details-cart btn-hover ml-0">
            <a
              href={product.affiliateLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Buy Now
            </a>
          </div>
        </div>
      ) : (
        <div className="pro-details-quality">
          {
            !(params.hasWishlist?.value ?? true) ? null : (
              <div className="pro-details-cart">
                <Button 
                  data={data} 
                  preview={preview} 
                  component={id} 
                  block={'buttonWishlist'} 
                  inputCallback={inputCallback}
                  type="button" 
                  className="mb-20 button"
                  onClick={wishlistCallback}
                  text={added ? data.inputs['removeFromWishlist'].value : data.inputs['addToWishlist'].value}
                />
              </div>
            )
          }
          
          {/* <div className="pro-details-wishlist">
            <button
              className={"active"}
              disabled={false}
              title={"Added to wishlist"}
              onClick={() => {}}
            >
              <i className="pe-7s-like" />
            </button>
          </div> */}
        </div>
      )}
      {product.category ? (
        <div className="pro-details-meta">
          <span>Categories :</span>
          <ul>
            {product.category.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                    {single}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
      {product.tag ? (
        <div className="pro-details-meta">
          <span>Tags :</span>
          <ul>
            {product.tag.map((single, key) => {
              return (
                <li key={key}>
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                    {single}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}

      <div className="pro-details-social">
        <ul>
          {
            params?.social?.value?.map((e, i) => {
              if (!e.value) return null;

              const getIcon = (icon) => {
                switch (icon) {
                  case SocialNetworks.FACEBOOK:
                    return "fa fa-facebook";
                  case SocialNetworks.INSTAGRAM:
                    return "fa fa-instagram";
                  case SocialNetworks.YOUTUBE:
                    return "fa fa-youtube";
                  case SocialNetworks.PINTEREST:
                    return "fa fa-pinterest";
                  case SocialNetworks.TWITTER:
                    return "fa fa-twitter";
                  case SocialNetworks.LINKEDIN:
                    return "fa fa-linkedin";
                  default:
                    return null;
                }
              };

              return (
                <li key={i}>
                  <a href={e.value} target="_blank">
                    <i className={getIcon(e.icon)} />
                  </a>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object
};

export default ProductDescriptionInfo;
