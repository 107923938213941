import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';

const BlocText = ({
    preview = false,
    id = null,
    data = null,
    block = null,
    inputCallback = null,
    children = null
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: !preview ? '#eee' : '#fff',
        marginBottom: 20,
        width: '100%',
        boxSizing: 'border-box'
    };

    // let col = data.blocks[block].inputs?.color?.value ?? '#f48256';

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children}

            <div className="container bloc-text">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                        </div>
                        <div className="description">
                            <Pg data={data} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlocText;
