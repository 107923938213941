import {COMPONENT} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import ProductSelectorCatalog from '../components/ProductSelectorCatalog'

export default function catalogEditSelectionsProducts(catalogData, treeData, secondTreeData, allProducts,handleMediaPicker, allTypes, allGroups){
    var obj = {
        titleForm: "Sélection des produits",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour mettre à jour votre catalogue' : "Veuillez compléter les champs ci-dessous pour mettre à jour votre entrepôt",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        // component: Button,
        formConfig: {
            type : COMPONENT,
            component : ProductSelectorCatalog
        }
    } 
    return obj;
}