import noImage from '../../../../../assets/images/not-found.png';
import colors from '../../../../../config/theme/colors';
import CardShell from '../../../../layouts/Card/cardContent/CardShell';

export function listSettings(attributs){
    let getImage = attributs?.allGroups?.find(e => e.node.attributeType.input === "image")?.node?.identifier
    const currentLang = attributs.currentLang;

    let columnsListing = [
        {
            label   : 'Sku',
            field   :  'sku',
            cellType : 'text',
            cellProps : {
                noImage : noImage,
                minWidth: "150px",
                maxWidth: "200px"
            },
            hasTooltip: true
        }
    ];
    if (attributs?.name === 'Fiche QR') {
        columnsListing.unshift({
            label   : 'QR code',
            field   :  'qrcode',
            cellType : 'image',
            cellProps : {
                noImage : noImage,
                minWidth: "150px",
                maxWidth: "250px"
            },
            download:true,
            hasTooltip: false
        })
    }
    attributs?.allGroups?.filter(e => e.node.useInCard).map((values, index) => {
        const langSelectedValuesDefault = values.node.translation.translationDatas.edges[0];

        const langSelectedValues = values.node.translation.translationDatas.edges.find(
            lang => lang.node.locale.code === currentLang
        );
        
        columnsListing.push({
            label   : langSelectedValues?.node.value ?? langSelectedValuesDefault?.node?.value,
            field   :  values.node.identifier,
            cellType : values.node.attributeType.input === "textarea" ? "text" : values.node.attributeType.input === "file" ? "link" : values.node.attributeType.input,
            // filter : values.node.identifier,
            cellProps : {
                noImage : noImage,
                minWidth: "150px",
                maxWidth: "250px",
            },
            hasTooltip: true
        })
    })
    var obj = {
        grid : {
            components : CardShell,
            columns : {
                xs : 2,
                sm : 3,
                laptop : 3,
            }
        },
        table : {
            style : {
                type : 'striped',
            },
            columns : columnsListing,
        }
    }
    return obj;
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    {
        view : 'table',
        mapper : null
    }
]

export const perPageOptions = {
    'card' : ['12','24','36'],
    'table' : ['30','50','100']
}
