import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    products: [],
    mapping: {},
    name: 'Produits',
    inputs: {
        rowsNumber: {
            label: 'Nombre de produits',
            type: FormInputTypes.NUMBER,
            value: 8
        }
    },
    blocks: {
        title: getDefaultText("Titre :", "Ma sélection", 18, "#000")
    }
};