import { gql } from 'apollo-boost';

//----- Récupération des mediaObjectCategories par parents
/*
 Exemple de variables
    {
      "parent" : "/api/media-objects/1"
    }
*/

export const GET_MEDIA_OBJECT_CATEGORIES_BY_PARENT= gql`

  query getMediaObjectCategoriesByParent($parent: String){
    mediaObjectCategories(parent: $parent){
      edges{
        node{
          id
          libelle
          children{
            edges{
              node{
                id
                libelle
                children{
                  edges{
                    node{
                      id
                    }
                  }
                }
                mediaObjects{
                  edges{
                    node{
                      id
                      isInternal
                      isOptimized
                      size
                      origin
                      filePath
                      description
                      expirationAt
                      mediaObjectTags{
                        edges{
                          node{
                            id
                          }
                        }
                      }
                    }
                  }
                }
                expirationAt
              }
            }
          }
          mediaObjects{
            edges{
              node{
                id
                isInternal
                isOptimized
                size
                origin
                filePath
                description
                expirationAt
                mediaObjectTags{
                  edges{
                    node{
                      id
                    }
                  }
                }
              }
            }
          }
          expirationAt
        }
      }
    }
  }

`;

export const GET_MEDIA_OBJECT_CATEGORY_BY_ID= gql`
  query getMediaObjectCategoryById($id: ID!){
    mediaObjectCategory(id: $id){
      libelle
    }
  }
`;