import React, { useState } from "react";

const ThreeImagesGrid = ({
    preview = false,
    id = null,
    data = null,
    inputCallback = null,
    children = null,
    spread = false
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: !preview ? '#eee' : '#fff',
        marginBottom: 20,
        width: '100%',
        boxSizing: 'border-box'
    };

    let image1 = data.blocks["image1"].inputs.image?.value;
    let image2 = data.blocks["image2"].inputs.image?.value;
    let image3 = data.blocks["image3"].inputs.image?.value;

    let link1 = data.blocks["image1"].inputs.link?.value;
    let link2 = data.blocks["image2"].inputs.link?.value;
    let link3 = data.blocks["image3"].inputs.link?.value;

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="container three-images-row">
                <div className="row">
                    <div className={`col-6 left-image`}>
                        <a href={link1} target="_blank" onClick={(e) => {
                            if (spread)
                                e.preventDefault();
                        }}>
                            <img src={typeof image1 === 'string' ? image1 : `${process.env.REACT_APP_MEDIAS}/${image1?.filePath}`} alt="first" />
                        </a>
                    </div>

                    <div className={`col-6 right-images`}>
                        <a href={link2} target="_blank" onClick={(e) => {
                            if (spread)
                                e.preventDefault();
                        }}>
                            <div
                                className={`image`}
                                style={{ backgroundImage: `url(${typeof image2 === 'string' ? image2 : `${process.env.REACT_APP_MEDIAS}/${image2?.filePath}`})` }}
                            />
                        </a>

                        <a href={link3} target="_blank" onClick={(e) => {
                            if (spread)
                                e.preventDefault();
                        }}>
                            <div
                                className={`image`}
                                style={{ backgroundImage: `url(${typeof image3 === 'string' ? image3 : `${process.env.REACT_APP_MEDIAS}/${image3?.filePath}`})` }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeImagesGrid;
