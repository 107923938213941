import { gql } from 'apollo-boost';

//----- Récupération des channels (optim)
export const GET_ALL_CHANNELS = gql`
{
  channels(first: 1000){
    edges{
      node
      {
        id
        identifier
        libelle
        logo
        description
        isSystem
        attributeGroup{
          id
          identifier
        }
        assets{
          edges{
            node{
              id
            }
          }
        }
      }
    }
  }
}
`;


//----- Récupération des channels (full)
export const GET_ALL_CHANNELS_WITH_DATAS = gql`
{
  channels{
    edges{
      node
      {
        id
        identifier
        libelle
        logo
        description
        isSystem
        assets{
          edges{
            node{
              id
            }
          }
        }
        channelDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
              }
              media{
                id
                filePath
                type
              }
              locale{
                id
                code
              }
            }
          }
        }
        category{
          id
          identifier
        }
        attributeGroup{
          id
          identifier
          attributes{
            edges{
              node{
                id
                identifier
                isRequired
                isSearchable
                isSystem
                attributeType{
                  id
                  input
                }
                attributeOptions{
                  edges{
                    node{
                      id
                      identifier
                      translation{
                        id
                        translationDatas{
                          edges{
                            node{
                              value
                              locale{
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


//----- Récupération des catégories channels
export const GET_CHANNEL_CATEGORIES = gql`
{
  channelCategories{
    edges{
      node{
        id
        identifier
      }
    }
  }
}
`;

//----- Récupération des channels par catégorie
/*
 Exemple de variables
  {
    "id" : "/api/channel-categories/1"
  }

*/
export const GET_CHANNELS_BY_CATEGORY = gql`
query channelCategory($id: ID!){
	channelCategory(id: $id){
    id
  	identifier
    channels{
      edges{
        node
      	{
        	id
          identifier
          libelle
          logo
          description
          isSystem
          assets{
            edges{
              node{
                id
              }
            }
          }
          channelDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                }
                media{
                  id
                	filePath
                  type
                }
                locale{
                  id
                  code
                }
              }
            }
          }
          attributeGroup{
            id
            identifier
            attributes{
              edges{
                node{
                  id
                  identifier
                  isRequired
                  isSearchable
                  isSystem
                  attributeType{
                    id
                  	input
                  }
                  attributeOptions{
                    edges{
                      node{
                        id
                        identifier
                        translation{
                          id
                          translationDatas{
                            edges{
                              node{
                                value
                                locale{
                                  id
                                  code
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
      	}
      }
    }
  }
}`;



//----- Récupération d'un channel
/*
 Exemple de variables
  {
    "id" : "/api/channels/1"
  }

*/
export const GET_CHANNEL_BY_ID = gql`
query getChannelById($id: ID!){
	channel(id: $id){
    id
    logo
    libelle
    description
    identifier
    channelDatas{
      edges{
        node{
          id
          value
          attributeOption{
            id
            identifier
          }
          attribute{
            id
            identifier
          }
          media{
            id
            filePath
            type
          }
          locale{
            id
            code
          }
        }
      }
    }
    attributeGroup{
      identifier
      id
      attributes{
        edges{
          node{
            identifier
            isRequired
            isSearchable
            isSystem
            attributeType{
              id
              input
            }
            attributeOptions{
              edges{
                node{
                  id
                  identifier
                  translation{
                    id
                    translationDatas{
                      edges{
                        node{
                          value
                          locale{
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            translation{
              translationDatas{
                edges{
                  node{
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeHelper{
              id
              translation{
                id
                translationDatas{
                  edges{
                    node{
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;



//----- Ajouter un channel
/*
 Exemple de variables
  {
    "identifier" : "Channel de démo",
    "category" : "/api/channel-categories/1",
    "attributeGroup" : "/api/attribute-groups/1",
    "isSystem": false
  }
  /!\ Voir l'ensemble des params - liaison catalog et retailer possible
*/
export const ADD_CHANNEL = gql`
mutation AddChannel($identifier: String!, $category: String!, $attributeGroup: String!, $libelle: String, $logo: String, $description: String, $isSystem: Boolean!, $catalog: String, $retailer: String){
	createChannel(input: {identifier:$identifier, category:$category, attributeGroup:$attributeGroup, libelle:$libelle, logo:$logo, description:$description, isSystem:$isSystem, catalog:$catalog, retailer:$retailer}){
    channel{
      id
      identifier
    }
  }  
}`;



//----- Modifier un channel
/*
 Exemple de variables
  {
    "id" : "/api/channels/2",
    "identifier" : "Channel de démo edited",
    "category" : "/api/channel-categories/1",
    "attributeGroup" : "/api/attribute-groups/1"
  }
  /!\ Voir l'ensemble des params - liaison catalog et retailer possible
*/
export const UPDATE_CHANNEL = gql`
mutation UpdateChannel($id: ID!,$identifier: String, $category: String, $attributeGroup: String, $libelle: String, $logo: String, $description: String, $isSystem: Boolean, $catalog: String, $retailer: String){
	updateChannel(input: {id:$id, identifier:$identifier, category:$category, attributeGroup:$attributeGroup, libelle:$libelle, logo:$logo, description:$description, isSystem:$isSystem, catalog:$catalog, retailer:$retailer}){
    channel{
      id
      identifier
    }
  }  
}`;

//----- Supprimer un channel
/*
 Exemple de variables
  {
    "id" : "/api/channels/2"
  }
*/
export const DELETE_CHANNEL = gql`
mutation DeleteChannel($id: ID!){
	deleteChannel(input: {id:$id}){
    channel{
      id
    }
  }  
}`;

//
export const GET_CHANNEL_DATA = gql`
query getchannelDatas($limit: Int){
  channelDatas(first: $limit){
    edges{
      node{
        id
        channel{
          id
        }
      }
    }
  }
}
`;
  


//----- Ajouter une valeur d'attribut à un channel

/*
 Exemple de variables
 {
  "value": "MON_URL_DE_TEST",
	"channel": "/api/channels/1",
  "attribute": "/api/attributes/24", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CHANNEL_DATA = gql`
mutation AddChannelData($value: String, $channel: String!, $attribute: String!, $locale: String!, $media: String, $attributeOption: String){
	createChannelData(input: {value:$value, channel:$channel, attribute:$attribute, locale:$locale, media: $media, attributeOption:$attributeOption}){
    channelData{
      id
      value
      channel{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;

//----- Modifier la valeur d'attribut à un channel

/*
 Exemple de variables
 {
  "id": "/api/channel-datas/4",
  "value": "MON_URL_DE_TEST",
	"channel": "/api/channels/1",
  "attribute": "/api/attributes/24", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CHANNEL_DATA = gql`
mutation UpdateChannelData($id: ID!, $value: String, $channel: String!, $attribute: String!, $locale: String!, $media: String, $attributeOption: String){
	updateChannelData(input: {id: $id, value:$value, channel:$channel, attribute:$attribute, locale:$locale, media:$media, attributeOption:$attributeOption}){
    channelData{
      id
      value
      channel{
        id
      }
      attribute{
        id
      }
      media{
        id
      }
      locale{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un channel
/*
 Exemple de variables
  {
    "id": "/api/channel-datas/4"
  }
*/
export const DELETE_CHANNEL_DATA = gql`
mutation DeleteChannelData($id: ID!){
	deleteChannelData(input: {id:$id}){
    channelData{
      id
    }
  }  
}`;


//----- Récupération des clefs external channel
export const GET_CHANNEL_EXTERNAL_KEYS = gql`
{
  channelExternalKeys(first: 100000){
    edges{
      node{
        id
        externalKey
        translation{
          translationDatas{
            edges{
              node{
                value
                locale{
                  id
                  code
                }
              }
            }
          }
        }
        attributeGroup{
          id
          identifier
        }
      }
    }
  }
}
`;

//
export const GET_CHANNEL_EXTERNAL_KEY_ATTRIBUTE = gql`
query getExternalKeyAttributes($limit: Int){
  channelExternalKeyAttributes(first: $limit){
    edges{
      node{
        id
        externalKey{
          externalKey
          id
          translation{
            translationDatas{
              edges{
                node{
                  value
                  locale{
                    id
                    code
                  }
                }
            	}
            }
          }
        }
        attribute{
          id
        }
        channel{
          id
        }
      }
    }
  }
}
`;

//----- Ajouter un mapping EXTERNAL_KEY / CHANNEL / ATTRIBUTE

/*
 Exemple de variables
  {
      "externalKey": "/api/channel-external-keys/5",
      "attribute": "/api/attributes/34",
      "channel": "/api/channels/2"
  }
*/
export const ADD_CHANNEL_EXTERNAL_KEY_ATTRIBUTE = gql`
mutation AddChannelExternalKeyAttribute($externalKey: String!, $attribute: String!, $channel: String!){
	createChannelExternalKeyAttribute(input: {externalKey:$externalKey, attribute:$attribute, channel:$channel}){
    channelExternalKeyAttribute{
      id
    }
  }  
}`;


//----- Modifier un mapping EXTERNAL_KEY / CHANNEL / ATTRIBUTE

/*
 Exemple de variables
  {
      "id" : "/api/channel-external-key-attributes/36",
      "externalKey": "/api/channel-external-keys/5",
      "attribute": "/api/attributes/35",
      "channel": "/api/channels/2"
  }
*/
export const UPDATE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE = gql`
mutation UpdateChannelExternalKeyAttribute($id: ID!, $externalKey: String, $attribute: String, $channel: String){
	updateChannelExternalKeyAttribute(input: {id:$id, externalKey:$externalKey, attribute:$attribute, channel:$channel}){
    channelExternalKeyAttribute{
      id
    }
  }  
}`;



//----- Supprimer un mapping EXTERNAL_KEY / CHANNEL / ATTRIBUTE
/*
 Exemple de variables
  {
    "id" : "/api/channel-external-key-attributes/36"
  }
*/
export const DELETE_CHANNEL_EXTERNAL_KEY_ATTRIBUTE = gql`
mutation DeleteChannelExternalKeyAttribute($id: ID!){
	deleteChannelExternalKeyAttribute(input: {id:$id}){
    channelExternalKeyAttribute{
      id
    }
  }  
}`;