import React from "react";
import { useDrop } from 'react-dnd';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

export default ({ data, preview, component, block, inputCallback, update, alt, hover = false }) => {
    let image = data?.blocks[block].inputs.value.value;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            onChange(item.media.node);
        }
    });

    const onChange = (value) => {
        if (data) {
            data.blocks[block].inputs.value.value = value;
            update();
        }
    };

    const background = {
        backgroundColor: preview ? '#fff' : '#eee', 
        width: 150, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: 150,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        boxSizing: 'border-box'
    };

    return (
        <div 
            ref={drop}
            style={{ 
                ...background, 
                backgroundImage: image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none' 
            }}
        >
            { !image ? (
                <div className="image-picker">
                    <ImageOutlinedIcon style={{ fontSize: 80, color: 'rgb(83, 131, 157)' }}  />
                </div>
            ) : null }
        </div>
    );
};