import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { BlocTextMedia } from './index';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Slider blocs textes et médias",
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
    },
    blocks:{
        slides:{
            title: "Image",
            name: "Image",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA,
            buttonAdd: 'une image',
            limit: 10,
            config: [ 
                BlocTextMedia(),
                BlocTextMedia(),
                BlocTextMedia(),
            ]
        }
    }
};