import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function mediasCategoriesConfig (categories,parentId,action='add'){

    
    var obj={
        langSelect: false,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        titleForm: action === 'edit' ? 'Editer la catégorie': 'Ajouter une catégorie de médias',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer/modifier votre catégorie de média',
        textButtonValidate: action === 'edit' ? 'Modifier': 'Créer',
        formConfig:{
            type:FORM,
            children:[ 
                    {
                        optionsInputs:[
                            {
                                type:'text',
                                label: 'Libelle',
                                translated: false,
                                helper: {
                                    text: 'Nom de la catégorie',
                                    link: false,
                                },
                                required: true,
                                stateName: 'identifier',
                                limitedCaracters: /[\/\\]/
                            },
                            {
                                type: 'selectTree',
                                label: 'Parent',
                                translated: false,
                                helper: {
                                    text: 'Indiquez le dossier parent (optionnel)',
                                    link: false,
                                },
                                required: false,
                                stateName: 'parentCat',
                                data: (() => {
                                    /*let root    = {
                                        children        : [],
                                        isDefaultValue  :  parentId 
                                            ? parentId === 'root' 
                                                ? true 
                                                : false 
                                            : true,
                                        expanded        : true,
                                        label           : '/',
                                        value           : 'root',
                                        node            : {
                                            id: "root",
                                            libelle: "/",
                                            parent: null 
                                        }
                                    };*/

                                    let data = categories.filter(e => e.node.parent === null);
    
                                    let populateChildren = (cats, parent) => {
                                        
                                        parent.value            = parent.node.id;
                                        parent.label            = parent.node.libelle;
                                        parent.children         = cats.filter(e => e.node.parent !== null && e.node.parent.id === parent.node.id);
                                        parent.isDefaultValue   = parent.node.id === parentId;  
                                        parent.expanded         = true;

                                        for (let child of parent.children)
                                            populateChildren(cats, child);
                                    };
    
                                    for (let parent of data)
                                        populateChildren(categories, parent);

                                   /* root.children = data;*/

                                    return data;
                                })()
                            },
                            
                            
                        ]   
                    }
            ]
        }

    }

    return obj;
}