import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as moment from 'moment';
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import OurTypography from '../../../../ui/typography/Typography';
import { Box, Grid } from '@material-ui/core';

const WrapperButton = styled.a`
    border-radius: 4px;
    color: white;
    padding: 4px 12px;
    width: 190px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    background: ${colors.blue.lighter.hue150};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    &:hover{
        background: ${colors.black.regular};
    }
    &.error{
        background: ${colors.orange.regular};
        &:hover{
            background: ${colors.orange.darker};
        }
    }
`;

const WrapperRow = styled(TableRow)`
    background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
    td{
        font-size: 16px;
        border-bottom: ${props => props.borderBottom ? props.borderBottom : null};
    }
    `

const OurTypographyCustom = styled(OurTypography)`
    padding: 0;
    display: inline-block;
`;
const CircleCustom = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.bgcolor ? props.bgcolor : colors.white};
    display: inline-block;
    margin-right: 12px;
`;

function createData(status, type, name, typeCron, errorFile, date) {
    return { status, type, name, typeCron, errorFile, date };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 300px)',
        borderBottom:"0.5px solid rgba(0,0,0,0.12)"
    },    
    pagination: {
        '& > div': {
            paddingLeft: 0,
        },
    }
});

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 16
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function ArrayHistory(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (props.used == "history-product") {
            setColumns([
                { id: 'status', label: 'Statut', minWidth: 150 },
                { id: 'filename', label: 'Nom du fichier', minWidth: 150 },
                { id: 'executedAt', label: "Date et heure", minWidth: 10, },
                { id: 'user', label: 'Utilisateur', minWidth: 170 },
                { id: 'destination', label: "Destination", minWidth: 10 },
                { id: 'type', label: "Type de produit", minWidth: 10 },
                { id: 'totalImported', label: "Nombre", minWidth: 10 }
            ])
        } else {
            setColumns([
                { id: 'status', label: 'Statut', minWidth: 70 },                
                { id: 'date', label: "Date", minWidth: 10 },
                { id: 'hour', label: "Heure", minWidth: 10 },
                { id: 'typeCron', label: 'Type', minWidth: 10 },
            ])
        }

    }, [])

    useEffect(() => {
        if (props.rows && props.used == "history-product") {
            let temporaryRows = [];
            for (let row of props.rows) {
                temporaryRows.push({
                    status: row.status,
                    filename: row.filename,
                    user: `Par <strong>${row.user}</strong>`,
                    executedAt: `<strong> ${moment(row.executedAt).utc().format('DD/MM/YYYY')}</strong> à ${moment(row.executedAt).utc().format('HH:mm:ss')}`,
                    destination: 'Import produit',
                    type: 'Type de produit',
                    totalImported: row.totalImported,
                });
            }
            setRows(temporaryRows);
        } else if (props.rows) {
            let temporaryRows = [];
            for (let row of props.rows) {
                temporaryRows.push({
                    status: row.status,                    
                    date: `<strong> ${moment(row.start.date).utc().format('DD/MM/YYYY')}</strong>`,
                    hour: moment(row.start.date).utc().format('HH:mm:ss'),
                    typeCron: row.details.retailer ? `${row.details.retailer} - ${row.type}` :row.type,
                }
                );
            }
            setRows(temporaryRows);
        }
    }, [props.rows]);

    return (
        <Paper className={classes.root} style={props.style}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: '#FAFAFA' }}
                                >
                                    <div style={{ display: 'inline-flex', alignItems: 'center', color: colors.grey.border, fontWeight: 'bold' }}>
                                        {column.label}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ background: "#FFFFFF" }}>
                        {
                            rows ? (
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {                                    
                                    return (
                                        <WrapperRow
                                            // hover 
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={`${row.code} ${index}`}
                                            bgcolor={
                                                row.status === "success" ?
                                                    '#FFFFFF'
                                                    : row.status === "success_no_file" ?
                                                        '#FFFFFF'
                                                        : row.status === "pending" ?
                                                            '#FF82171A'
                                                            : row.status === "skipped" ?
                                                                '#808F981A'
                                                                : row.status === "running" ?
                                                                    '#0273a51A'
                                                                    :
                                                                    '#FFEBEC'
                                            }
                                            borderBottom={index + 1 === rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length ? "none":`1px solid rgba(0,0,0,0.12)`}                                            
                                        >
                                            {columns.map((column, index) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ height: 70, verticalAlign: 'middle', borderLeft: index === 0 ? `1px solid rgba(0,0,0,0.12)` : null,borderRight: index + 1 === columns.length ? `1px solid rgba(0,0,0,0.12)` : null }} >
                                                        {
                                                            column.id === 'status' ?
                                                                (
                                                                    <>
                                                                        <CircleCustom
                                                                            bgcolor={
                                                                                row.status === "success" ?
                                                                                    colors.green.regular
                                                                                    : row.status === "success_no_file" ?
                                                                                        colors.green.regular
                                                                                        : row.status === "pending" ?
                                                                                            colors.orange.regular
                                                                                            : row.status === "skipped" ?
                                                                                                colors.grey.regular
                                                                                                : row.status === "running" ?
                                                                                                    colors.blue.darker.hue300
                                                                                                    :
                                                                                                    colors.red.regular
                                                                            }
                                                                        />
                                                                        <OurTypographyCustom variant="body2" style={{ display: 'inline-block' }}
                                                                            colortext={
                                                                                row.status === "success" ?
                                                                                    colors.black.regular
                                                                                    : row.status === "success_no_file" ?
                                                                                        colors.black.regular
                                                                                        : row.status === "pending" ?
                                                                                            colors.orange.regular
                                                                                            : row.status === "skipped" ?
                                                                                                colors.grey.regular
                                                                                                : row.status === "running" ?
                                                                                                    colors.blue.darker.hue300
                                                                                                    :
                                                                                                    colors.red.regular
                                                                            }
                                                                        >
                                                                            {
                                                                                row.status === "success" ?
                                                                                    "Succès"
                                                                                    : row.status === "success_no_file" ?
                                                                                        "0 fichier"
                                                                                        : row.status === "pending" ?
                                                                                            "En attente"
                                                                                            : row.status === "skipped" ?
                                                                                                "Ignoré"
                                                                                                : row.status === "running" ?
                                                                                                    "En cours"
                                                                                                    :
                                                                                                    "Erreur"
                                                                            }
                                                                        </OurTypographyCustom>
                                                                    </>
                                                                )
                                                                : column.id === 'errorFile' ?
                                                                    value.file ?
                                                                        (
                                                                            <WrapperButton className={value.type} href={value.type === "error" ? `${process.env.REACT_APP_API_ROOT}/${value.file}` : `${value.file}`} target="_blank" download>
                                                                                {value.type === "error" ?
                                                                                    (
                                                                                        <ReportProblemOutlinedIcon style={{ marginRight: 12 }} />
                                                                                    ) : <CheckIcon style={{ marginRight: 12 }} />
                                                                                }
                                                                                {value.type === "error" ?
                                                                                    (
                                                                                        "Télécharger le rapport"
                                                                                    ) : "Télécharger le fichier"
                                                                                }
                                                                            </WrapperButton>
                                                                        )
                                                                        : null
                                                                    : column.id === 'name' ?
                                                                        (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {
                                                                                    value.logo ? (
                                                                                        <Avatar style={{ marginRight: 10, width: 30, height: 30 }} src={`${process.env.REACT_APP_API_ROOT}/${value.logo}`} />
                                                                                    ) : value.name ? (
                                                                                        <Avatar style={{ marginRight: 10, width: 30, height: 30 }}>{value.name.charAt(0)}</Avatar>
                                                                                    ) : null
                                                                                }
                                                                                <span>{value.name}</span>
                                                                            </div>
                                                                        )
                                                                        : column.id === 'filename' ?
                                                                            (
                                                                                <strong>{value}</strong>
                                                                            )
                                                                            : column.id === 'date' || column.id === 'executedAt' ?
                                                                                (
                                                                                    <OurTypographyCustom style={{ display: 'inline-block', fontSize: 16 }}
                                                                                        colortext={
                                                                                            row.status === "success" ?
                                                                                                colors.black.regular
                                                                                                : row.status === "success_no_file" ?
                                                                                                    colors.black.regular
                                                                                                    : row.status === "pending" ?
                                                                                                        colors.orange.regular
                                                                                                        : row.status === "skipped" ?
                                                                                                            colors.grey.regular
                                                                                                            : row.status === "running" ?
                                                                                                                colors.blue.darker.hue300
                                                                                                                :
                                                                                                                colors.red.regular
                                                                                        }
                                                                                        dangerouslySetInnerHTML={{ __html: row.date || row.executedAt }}
                                                                                    />
                                                                                )
                                                                                :
                                                                                <OurTypography
                                                                                    colortext={
                                                                                        row.status === "success" ?
                                                                                            colors.black.regular
                                                                                            : row.status === "success_no_file" ?
                                                                                                colors.black.regular
                                                                                                : row.status === "pending" ?
                                                                                                    colors.orange.regular
                                                                                                    : row.status === "skipped" ?
                                                                                                        colors.grey.regular
                                                                                                        : row.status === "running" ?
                                                                                                            colors.blue.darker.hue300
                                                                                                            :
                                                                                                            colors.red.regular
                                                                                    }
                                                                                    style={{ fontSize: 16 }}
                                                                                    dangerouslySetInnerHTML={{ __html: value }}
                                                                                />
                                                        }
                                                    </TableCell>
                                                );
                                            })}
                                        </WrapperRow>
                                    );
                                })
                            ) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container style={{ padding: 0 }}>
                <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelDisplayedRows={(from = page) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                    labelRowsPerPage="Nombre par page :"
                />
            </Grid>
        </Paper>
    );
}
