import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Footer',
    blocks: {
        text: getDefaultText("Titre", "#Husqvarna", 14, "#fffefe"),
        image: {
            type: EditableTypes.IMAGE,
            name: "Logo",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://demo-api.spread.dev.sinfin.fr/medias/5f3d5069e1b5d_husqvarna-logo-0.png"
                }
            }
        },
    }
};