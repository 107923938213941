import noImageFlux from '../../../../../assets/pictos/empty-picto/empty_flux.png';

export const listSettings = {
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label   : 'Sku',
                field   :  'sku',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Prix',
                field   :  'price',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Prix spécial',
                field   :  'specialPrice',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Du',
                field   :  'specialPriceFrom',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Au',
                field   :  'specialPriceTo',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Code taxe',
                field   :  'codeTaxe',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Code devise',
                field   :  'codeDevise',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Identifier "selection"',
                field   :  'selectionIdentifier',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
            {
                label   : 'Date de mise à jour',
                field   :  'updatedAt',
                cellType : 'text',
                cellProps : {
                    width : '10%',
                    noImage : noImageFlux,
                }
            },
        ]   
    }
}

export const listMappers = [
    {
        view : 'table',
        mapper : 'flowListMapper'
    }
]

export const perPageOptions = {
    'table' : ['100']
}