import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault } from '../../helpers';
import moment from 'moment';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Page de garde',
    inputs: {
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            isCroppable: true,
            value: null
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fff'
        }
    },
    blocks: {
        date: getDefaultText('Date', moment().format('DD.MM.YYYY'), 24, '#000', null, 'uppercase', null, null),
        logo: getImageDefault(null, 'Logo'),
        offer_name: getDefaultTextarea("Titre", "Offre: [client]", true, "#000", "rgba(0,0,0,0)", 'initial', 'center', 26),
        infos_company: getDefaultTextarea("Description", "Sinfin - 1 avenue Louis François Armand - 10430 Rosières-près-Troyes<br> Tel: <a href='tel:0325761895'>03 25 76 18 95</a> - <a href='www.sinfin.fr'>www.sinfin.fr</a>- <a href='mailto:contact@sinfin.fr' >contact@sinfin.fr</a>", true, "#000", "rgba(0,0,0,0)", 'initial', 'center', 16),
    }
};

