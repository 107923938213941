import { gql } from 'apollo-boost';

//----- Récupération des assetCartRules
/*
 Exemple de variables
  {
    "asset": "api/assets/1",
    "name": "test"
  }
*/
export const GET_ASSET_CART_RULES = gql`
query assetCartRules($asset:String, $name:String, $isActive:Boolean){
  assetCartRules(asset:$asset, name:$name, isActive:$isActive){
    edges{
      node{
        id
        name
        description
        usePerCustomer
        usePerCoupon
        minProductQty
        maxProductQty
        isOnlyForConnectedCustomers
        hasFreeShipping
        startedAt
        endedAt
        discountType
        buyXGetYQty
        buyXGetYOffer
        discount
        minCartAmount
        maxCartAmount
        hasCoupon
        productSelectionType
        products{
            edges{
                node{
                  id
                }
            }
        }
        assetCartRuleCoupons{
          edges{
            node{
              id
            }
          }
        }
        isActive
      }
    }
  }
}
`;
//----- Récupération des assetCartRules
/*
 Exemple de variables
  {
    "asset": "api/assets/1",
    "name": "test"
  }
*/
export const GET_ASSET_CART_RULES_PAGINATION = gql`
query assetCartRules($nbperpage: Int, $cursor: String, $cursorLast: String, $asset: String, $name: String, $isActive:Boolean){
  assetCartRules(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset, name:$name, isActive:$isActive){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        name
        description
        usePerCustomer
        usePerCoupon
        minProductQty
        maxProductQty
        isOnlyForConnectedCustomers
        hasFreeShipping
        startedAt
        endedAt
        discountType
        buyXGetYQty
        buyXGetYOffer
        discount
        minCartAmount
        maxCartAmount
        hasCoupon
        productSelectionType
        formType
        websites
        customerGroups
        products (first: 1000) {
            edges{
                node{
                  id
                  sku
                }
            }
        }
        assetCartRuleCouponUnique{
          id
          coupon
        }
        assetCartRuleCoupons{
          edges{
            node{
              id
            }
          }
        }
        isActive
      }
    }
  }
}
`;

//----- Ajouter un assetCartRule
/*
 Exemple de variables
  {
    "asset": "api/assets/1",
    "name": "test",
    "description": "test",
    "usePerCustomer": 1,
    "usePerCoupon": 1,
    "minProductQty": 3,
    "maxProductQty": 10,
    "isOnlyForConnectedCustomers": true,
    "hasFreeShipping": false,
    "startedAt": "2021-07-07 06:11:55",
    "endedAt": "2021-07-07 06:11:55",
    "discountType": "buy_x_get_y",
    "buyXGetYQty": 3,
    "buyXGetYOffer": 1,
    "discount": 100,
    "minCartAmount": 1,
    "maxCartAmount": 10,
    "hasCoupon": false,
    "productSelectionType": "in",
    "products": [
        "/api/products/1"
    ],
    "isActive": true
  }
*/
export const ADD_ASSET_CART_RULE = gql`
  mutation AddAssetCartRule(
    $asset: String!
    $name: String!
    $description: String
    $formType: String!
    $usePerCustomer: Int
    $usePerCoupon: Int
    $minProductQty: Int
    $maxProductQty: Int
    $isOnlyForConnectedCustomers: Boolean!
    $hasFreeShipping: Boolean!
    $startedAt: String!
    $endedAt: String
    $discountType: String!
    $buyXGetYQty: Int
    $buyXGetYOffer: Int
    $discount: Float
    $minCartAmount: Float
    $maxCartAmount: Float
    $hasCoupon: Boolean!
    $productSelectionType: String!
    $products: [String]
    $isActive: Boolean!
    $assetCartRuleCouponUnique: String
    $customerGroups: String
    $websites: String
  ) {
    createAssetCartRule(
      input: {
        asset: $asset
        name: $name
        description: $description
        formType: $formType
        usePerCustomer: $usePerCustomer
        usePerCoupon: $usePerCoupon
        minProductQty: $minProductQty
        maxProductQty: $maxProductQty
        isOnlyForConnectedCustomers: $isOnlyForConnectedCustomers
        hasFreeShipping: $hasFreeShipping
        startedAt: $startedAt
        endedAt: $endedAt
        discountType: $discountType
        buyXGetYQty: $buyXGetYQty
        buyXGetYOffer: $buyXGetYOffer
        discount: $discount
        minCartAmount: $minCartAmount
        maxCartAmount: $maxCartAmount
        hasCoupon: $hasCoupon
        productSelectionType: $productSelectionType
        products: $products
        isActive: $isActive
        assetCartRuleCouponUnique: $assetCartRuleCouponUnique
        customerGroups: $customerGroups
        websites: $websites
      }
    ) {
      assetCartRule {
        id
      }
    }
  }
`;

//----- Editer un assetCartRule
/*
 Exemple de variables
  {
    "id": "api/asset-cart-rules/1",
    "asset": "api/assets/1",
    "name": "test",
    "description": "test",
    "usePerCustomer": 1,
    "usePerCoupon": 1,
    "minProductQty": 3,
    "maxProductQty": 10,
    "isOnlyForConnectedCustomers": true,
    "hasFreeShipping": false,
    "startedAt": "2021-07-07 06:11:55",
    "endedAt": "2021-07-07 06:11:55",
    "discountType": "buy_x_get_y",
    "buyXGetYQty": 3,
    "buyXGetYOffer": 1,
    "discount": 100,
    "minCartAmount": 1,
    "maxCartAmount": 10,
    "hasCoupon": false,
    "productSelectionType": "in",
    "products": [
        "/api/products/1"
    ],
    "isActive": true
  }
*/
export const UPDATE_ASSET_CART_RULE = gql`
  mutation UpdateAssetCartRule(
    $id: ID!
    $asset: String
    $name: String
    $description: String
    $formType: String!
    $usePerCustomer: Int
    $usePerCoupon: Int
    $minProductQty: Int
    $maxProductQty: Int
    $isOnlyForConnectedCustomers: Boolean
    $hasFreeShipping: Boolean
    $startedAt: String
    $endedAt: String
    $discountType: String!
    $buyXGetYQty: Int
    $buyXGetYOffer: Int
    $discount: Float
    $minCartAmount: Float
    $maxCartAmount: Float
    $hasCoupon: Boolean!
    $productSelectionType: String!
    $products: [String]
    $isActive: Boolean!
    $assetCartRuleCouponUnique: String
    $customerGroups: String
    $websites: String
  ) {
    updateAssetCartRule(
      input: {
        id: $id
        asset: $asset
        name: $name
        description: $description
        formType: $formType
        usePerCustomer: $usePerCustomer
        usePerCoupon: $usePerCoupon
        minProductQty: $minProductQty
        maxProductQty: $maxProductQty
        isOnlyForConnectedCustomers: $isOnlyForConnectedCustomers
        hasFreeShipping: $hasFreeShipping
        startedAt: $startedAt
        endedAt: $endedAt
        discountType: $discountType
        buyXGetYQty: $buyXGetYQty
        buyXGetYOffer: $buyXGetYOffer
        discount: $discount
        minCartAmount: $minCartAmount
        maxCartAmount: $maxCartAmount
        hasCoupon: $hasCoupon
        productSelectionType: $productSelectionType
        products: $products
        isActive: $isActive
        assetCartRuleCouponUnique: $assetCartRuleCouponUnique
        customerGroups: $customerGroups
        websites: $websites
      }
    ) {
      assetCartRule {
        id
      }
    }
  }
`;

//----- Supprimer un assetCartRule
/*
 Exemple de variables
  {
    "id": "api/asset-cart-rules/3"
  }
*/
export const DELETE_ASSET_CART_RULE = gql`
mutation DeleteAssetCartRule($id: ID!){
	deleteAssetCartRule(input: {id:$id}){
    assetCartRule{
      id
    }
  }  
}
`;


// COUPONS

export const ADD_ASSET_CART_RULE_COUPON = gql`
mutation AddAssetCartRuleCoupon($coupon: String!){
  createAssetCartRuleCoupon(input:{coupon:$coupon}){
    assetCartRuleCoupon{
      id
    }
  }
}
`

export const UPDATE_ASSET_CART_RULE_COUPON = gql`
mutation UpdateAssetCartRuleCoupon($id: ID!, $coupon: String!){
  updateAssetCartRuleCoupon(input:{id:$id, coupon:$coupon}){
    assetCartRuleCoupon{
      id
    }
  }
}
`

export const DELETE_ASSET_CART_RULE_COUPON = gql`
mutation DeleteAssetCartRuleCoupon($id: ID!){
	deleteAssetCartRuleCoupon(input: {id:$id}){
    assetCartRuleCoupon{
      id
    }
  }  
}
`;