import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import H4 from '../shared/h4';
import Img from '../shared/img';
import Button from '../shared/button';
import Swiper from 'swiper';
import { PictureTag } from "../../../../../utils/image";

const PresentationDuo = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    const getContent = (collection, image, alt,inputs) => {
        return(
            <>
                <PictureTag
                    media={image}
                    alt={alt}
                    spread={spread}
                    classes={`${inputs.effect?.value === 'zoom' ? 'zoom ' :''} w100`}
                    lazy={true}
                    isOptimised={data.inputs.optimisedImage?.value}
                />
                {
                    inputs.effect?.value === 'overlay' ? (
                        <Div className="absolute gradient-overlay" />
                    ) : null
                }
                <a href={collection.blocks.link.inputs.value.value}>
                    <Div>
                        <H3 
                            className="white uppercase center"
                            style={{
                                fontSize:collection.blocks.title.inputs.fontSize.value
                            }}
                        >
                            {collection.blocks.title.inputs.value.value}
                        </H3>
                        <H4 
                            className="white uppercase center"
                            style={{
                                fontSize:collection.blocks.subtitle.inputs.fontSize.value
                            }}
                        >
                            {collection.blocks.subtitle.inputs.value.value}
                        </H4>
                        <Button 
                            backgroundColor={collection.blocks.button?.inputs.backgroundColor.value}
                            color={collection.blocks.button?.inputs.color.value}
                            className={`${inputs.positionButton?.value === 'bottom' ? 'bottom' : 'center'} ${typeof inputs.pictoButton?.value?.value === 'string' ? 'withimg': inputs.pictoButton?.value?.filePath ? 'withimg': null}`}
                            style={{
                                fontWeight: 'bold', 
                                textTransform: collection.blocks.button?.inputs.textTransform.value,
                            }}
                        >
                            {collection.blocks.button?.inputs.value.value ? collection.blocks.button?.inputs.value.value : 'Découvrir'}
                            {
                                inputs.pictoButton?.value ? (
                                    <Img 
                                        src={
                                            typeof inputs.pictoButton?.value?.value === 'string' ? 
                                                inputs.pictoButton?.value?.value 
                                            : inputs.pictoButton?.value?.filePath ? 
                                                `${process.env.REACT_APP_MEDIAS}/${inputs.pictoButton?.value?.filePath}` 
                                            : null
                                        }
                                    />
                                ) : null
                            }
                        </Button>
                    </Div>
                </a>
            </>
        )
    }

    useEffect(() => {
        new Swiper('.swiper-container-collections', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-collections',
            },
            navigation: {
                nextEl: '.swiper-button-next-collections',
                prevEl: '.swiper-button-prev-collections',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                },
                800:{
                    slidesPerView: 2.2,
                },
                1200:{
                    slidesPerView: parseInt(data.inputs.elementPerRow?.value)+0.2 || 3.2,
                },
            }
        });
    }, []);
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-collections', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-collections',
            },
            navigation: {
                nextEl: '.swiper-button-next-collections',
                prevEl: '.swiper-button-prev-collections',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1.1,
                },
                800:{
                    slidesPerView: 2.2,
                },
                1200:{
                    slidesPerView: parseInt(data.inputs.elementPerRow?.value)+0.2 || 3.2,
                },
            }
        });
    }, [props]);

    return (
        <Div style={{position: 'relative', background: data.inputs.backgroundColor?.value ? data.inputs.backgroundColor.value : '#FAFAFB'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="bloc-list" style={{width: data.inputs.typeSlider?.value ? '95%' : null, marginLeft: data.inputs.typeSlider?.value ? '5%' : null}}>
                <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                <Div className={`list-collections presentation-duo row-${data.inputs.elementPerRow?.value}-item`} style={{flexWrap: !data.inputs?.typeSlider?.value ? 'wrap' : null }}>
                    {
                        data.inputs.typeSlider?.value ? (
                            <Div className="swiper-container swiper-container-collections">
                                <div className="swiper-wrapper">
                                    {
                                        data.blocks.collections.config.map((collection, index) => {
                                            let image = collection.blocks.image.inputs.value.value;
                                            let alt = collection.blocks.image.inputs.alt?.value ?? "Illustration";
                                            return (
                                                <Div className={`relative no-restriction swiper-slide`}>
                                                    {getContent(collection, image,alt, data.inputs)}
                                                </Div>
                                            );
                                        })
                                    }
                                </div>
                                <div className="swiper-scrollbar swiper-scrollbar-collections"></div>
                                <div className="swiper-button-prev swiper-button-prev-collections"></div>
                                <div className="swiper-button-next swiper-button-next-collections"></div>
                            </Div>
                        ) : (
                            data.blocks.collections.config.map((collection, index) => {
                                let image = collection.blocks.image.inputs.value.value;
                                let alt = collection.blocks.image.inputs.alt?.value ?? "Illustration";
                                return (
                                    <Div className="relative">
                                        {getContent(collection, image,alt, data.inputs)}
                                    </Div>
                                )
                            })
                        )
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default PresentationDuo;