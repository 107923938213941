import { gql } from 'apollo-boost';

/*
{
  "category": "/api/categories/1"
}
 */
// TODO Dono
export const GET_PRODUCT_CATEGORY = gql`
query getProductCategory($category: String!){
  productCategories(category: $category){
    edges{
      node{
        id
        position
        product{
          id
        }
        category{
          id
        }
        selection{
          id
        }
      }
    }
  }
}
`;

export const GET_PRODUCT_CATEGORIES_BY_CATEGORY = gql`
query getProductCategoriesByCategory($category: String!){
  productCategories(first: 3000,category: $category, order:{position: "ASC"}, exists:{product_parent:false}, product_status:true){
    edges{
      node{
        id
        position
        product{
          id
          sku
          status
          productDatas(attribute_identifier_list:["product_image", "product_price", "product_name"]){
            edges {
              node {
                value
                id
                selection{
                  id
                  identifier
                }
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
          productCatalogs{
            edges{
              node{
                catalog{
                  id
                }
                status
              }
            }
          }
        }
      }
    }
  }
}
`

export const UPDATE_POSITION_PRODUCTS = gql`
query updatePositionProducts($category: String!, $listId: [Int!]){
  updatePositionProductCategories(category:$category, list_id: $listId){
    edges{
      node{
        id
        position
        product{
          id
        }
      }
    }
  }
}
`

/*
{
  "category": "/api/categories/1",
  "product": "/api/products/1",
  "selection": "/api/selections/1"
}
 */
export const CREATE_PRODUCT_CATEGORY = gql`
mutation createProductCategory($category: String!, $product: String!, $selection: String){
  createProductCategory(input:{category: $category, product: $product, selection: $selection}){
    productCategory{
      id
    }
  }
}
`;

/*
{
  "id": "/api/product-categories/1",
  "category": "/api/categories/1",
  "product": "/api/products/1",
  "selection": "/api/selections/1"
}
 */
export const UPDATE_PRODUCT_CATEGORY = gql`
mutation updateProductCategory($id: ID!, $category: String, $product: String, $position: Int){
  updateProductCategory(input:{id: $id, category: $category, product: $product, position: $position}){
    productCategory{
      id
    }
  }
}
`;

/*
{
  "id": "/api/product-categories/1"
}
 */
export const DELETE_PRODUCT_CATEGORY = gql`
mutation deleteProductCategory($id: ID!){
  deleteProductCategory(input:{id: $id}){
    ProductCategory{
      id
    }
  }
}
`;