import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import H4 from '../shared/h4';
import Button from '../shared/a';
import Div from '../shared/div';
import P from '../shared/p';
import Img from '../shared/img';
import { PictureTag } from "../../../../../utils/image";

const SliderInstagram = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-instagram', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-instagram',
            },
            navigation: {
                nextEl: '.swiper-button-next-instagram',
                prevEl: '.swiper-button-prev-instagram',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1,
                },
                700:{
                    slidesPerView: 2,
                },
                1100:{
                    slidesPerView: 3.2,
                },
            }
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-instagram', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-instagram',
            },
            navigation: {
                nextEl: '.swiper-button-next-instagram',
                prevEl: '.swiper-button-prev-instagram',
            },
            spaceBetween: 10,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.2,
                },
                1100:{
                    slidesPerView: 3.2,
                },
            }
        });
    }, [props]);

    return (
        <Div style={{position: 'relative'}} className="slider-instagram" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="w100 column">
                <H4 className="center w100">{data.inputs.title.value}</H4>
                <P className="center w100">{data.inputs.text.value}</P>
            </Div>
            <Div className="swiper-container swiper-container-instagram">
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => {
                            let imagePrincipale = slide.blocks.imagePrincipale?.inputs.value.value;
                            let imageThumbnail1 = slide.blocks.imageThumbnail1?.inputs.value.value;
                            let imageThumbnail2 = slide.blocks.imageThumbnail2?.inputs.value.value;
                            let imageThumbnail3 = slide.blocks.imageThumbnail3?.inputs.value.value;
                            let alt = data.inputs.title.value;

                            return (
                                <Div className="swiper-slide w100" key={index}>
                                    <PictureTag
                                        media={imagePrincipale}
                                        alt={slide.blocks.imagePrincipale?.inputs?.alt?.value ?? "Illustration"}
                                        spread={spread}
                                        lazy={true}
                                        isOptimised={data.inputs.optimisedImage?.value}
                                    />
                                    <Div>
                                        <PictureTag
                                            media={imageThumbnail1}
                                            alt={slide.blocks.imageThumbnail1?.inputs?.alt?.value ?? "Illustration"}
                                            spread={spread}
                                            lazy={true}
                                            isOptimised={data.inputs.optimisedImage?.value}
                                        />
                                        <PictureTag
                                            media={imageThumbnail2}
                                            alt={slide.blocks.imageThumbnail2?.inputs?.alt?.value ?? "Illustration"}
                                            spread={spread}
                                            lazy={true}
                                            isOptimised={data.inputs.optimisedImage?.value}
                                        />
                                        <PictureTag
                                            media={imageThumbnail3}
                                            alt={slide.blocks.imageThumbnail3?.inputs?.alt?.value ?? "Illustration"}
                                            spread={spread}
                                            lazy={true}
                                            isOptimised={data.inputs.optimisedImage?.value}
                                        />
                                    </Div>
                                </Div>
                            )
                        })
                    }
                </div>
                <div className="swiper-scrollbar swiper-scrollbar-instagram"></div>
                <div className="swiper-button-prev swiper-button-prev-instagram"></div>
                <div className="swiper-button-next swiper-button-next-instagram"></div>
            </Div>
        </Div>
    );
};

export default SliderInstagram;