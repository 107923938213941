import { withApollo } from "react-apollo";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import TopPanel from "../../../layouts/TopPanel/TopPanel";
import React, { useEffect } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import AccordionCustom from "../../../layouts/Accordion/AccordionCustom";
import { GET_FILE_IMPORT_BY_SCRAP_STATUS } from "../../../../queries/file_import";
import colors from "../../../../config/theme/colors";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import moment from "moment";
import styled from "styled-components";
import OurButton from "../../../ui/button/Button";
import { ROUTE_PRODUCTS_WORKFLOW_LISTING, ROUTE_PRODUCTS_WORKFLOW_NEW, ROUTE_PRODUCTS_WORKFLOW_TO_VALIDATE_LISTING } from "../../../../js/constants/route-names";


const Cell = styled(TableCell)`
    border-bottom:none !important;
    color : ${props=> props.green?colors.green.regular:"inherit"};
`


const ListProductWorkflow = props=>{
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [type, setType] = React.useState(props.type);
    const [items, setItems] = React.useState(false);
    const [loading, setlLoading] = React.useState(true);

    useEffect(()=>{
        setType(props.type)
        getItems()
    },[props.type])

    const getItems = ()=>{
        setlLoading(true)
        const variables ={
            productScrapStatus:props.type === 'manually'?
            "404"
            :props.type === 'to_verify'?
            "200"
            :props.type === 'to_validate'?"99":null
        }
        props.client.query({
            query: GET_FILE_IMPORT_BY_SCRAP_STATUS,
            fetchPolicy: "no-cache",
            variables: variables
        }).then((result)=>{
            let sortedData = result.data.customFileImports.edges.sort((a,b)=>new Date(b.node.importedAt) - new Date(a.node.importedAt))
            setItems(sortedData)
            setlLoading(false)
        })
    }

    const buttonStyle = {        
        bgcolor:props.type === 'manually'?colors.blue.lighter.hue900:props.type === 'to_verify'?colors.blue.lighter.hue900:colors.green.lighter,
        color:props.type === 'manually'?colors.blue.darker.hue300:props.type === 'to_verify'?colors.blue.darker.hue300:colors.green.regular,
        border:props.type === 'manually'?`1px solid ${colors.blue.darker.hue300}`:props.type === 'to_verify'?`0.5px solid ${colors.blue.darker.hue300}`:`0.5px solid ${colors.green.regular}`,
        bgcolorhover:props.type === 'manually'?colors.blue.lighter.hue900:props.type === 'to_verify'?colors.blue.lighter.hue900:colors.green.lighter,
    }

    const goTo = (route, id) => {
        props.history.push({
            pathname: route,
            state: { type: props.type,id:id }
        });
    };

    const updateImport = (id)=>{
        goTo(ROUTE_PRODUCTS_WORKFLOW_LISTING,id)
    }

    const getStatus = ()=>{
        let value
        let color
        switch (props.type) {
            case "manually":
                value = "À enrichir"
                color= colors.blue.darker.hue300
                break;
            case "to_verify":
                value = "À compléter"
                color= colors.blue.darker.hue300
                break;
            case "to_validate":
                value = "À valider"
                color= colors.green.regular
                break;
            default:
                break;
        }
        return {
            value,color
        }
    }


    return (
        <div
            style={{
                height:"100%",
                display:"grid",
                gridTemplateRows:"auto 1fr"
            }}
        >
            <TopPanel
                title={
                    props.type === 'manually'?
                    props.t('products.workflow.title_workflow_manually')
                    :props.type === 'to_verify'?
                    props.t('products.workflow.title_workflow_toverify')
                    :props.t('products.workflow.title_workflow_tovalidate')
                }
                subtitle={props.t('products.workflow.subtitle_delta')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Box>
                <AccordionCustom 
                    title={
                        props.type === 'manually'?
                        "Imports à enrichir"
                        :props.type === 'to_verify'?
                        "Imports à compléter"
                        :"Imports à valider"
                    }
                    style={{
                        height:"100%"
                    }}
                    forcedExpanded={true}
                    hasIcon={true}
                >
                    {
                        loading?
                        <PageLoader/>
                        :items?.length >0?
                        <TableContainer style={{borderRight:`1px solid rgba(224, 224, 224, 1)`,borderLeft:`1px solid rgba(224, 224, 224, 1)`}}>
                            <Table>
                                <TableBody>
                                  {items.map((row) => (
                                    <TableRow style={{borderTop:`1px solid rgba(224, 224, 224, 1)`,borderBottom:`1px solid rgba(224, 224, 224, 1)`}} key={row.node.id}>
                                        <Cell scope="row">
                                            <div
                                                style={{display:"flex",gap:5,alignItems:"center",justifyContent:"center"}}
                                            >
                                              <div
                                              style={{
                                                  background:getStatus().color,
                                                  borderRadius:"100%",
                                                  height:"10px",
                                                  width:"10px"
                                              }}
                                              />
                                            <span>{getStatus().value}</span>
                                            </div>
                                        </Cell>
                                        <Cell align="left">
                                            <Typography style={{fontWeight:"bold"}} >
                                                {moment(row.node.importedAt).format("DD/MM/YYYY")}
                                                <span style={{fontWeight:"normal"}} >{moment(row.node.importedAt).format(" à HH:mm:ss")}</span>
                                            </Typography>           
                                        </Cell>
                                        <Cell align="left">
                                            <Typography style={{fontWeight:"bold"}} >
                                                {row.node.url}
                                            </Typography>           
                                        </Cell>
                                        <Cell align="right">
                                            <Typography style={{fontWeight:"bold"}} ><span style={{fontWeight:"normal"}}>Par</span> {row.node.user?.lastname} {row.node.user?.firstname}</Typography>
                                        </Cell>
                                        <Cell align="right">{row.node.productSource?.libelle}</Cell>
                                        <Cell align="right">{row.node.products.totalCount}/{row.node.totalImported}</Cell>
                                        <Cell align="right" style={{width:"280px"}}>
                                            <OurButton onClick={()=>updateImport(row.node.id)} {...buttonStyle} margin={'0'}>
                                                {
                                                    props.type === 'manually'?
                                                    "Enrichir les produits"
                                                    :props.type === 'to_verify'?
                                                    "Compléter les produits"
                                                    :"Valider les produits"
                                                }
                                            </OurButton>
                                        </Cell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :<p style={{color:colors.grey.lighter.hue800}}>
                            {
                                props.type === 'manually'?
                                "Aucun produit à enrichir"
                                :props.type === 'to_verify'?
                                "Aucun produit à compléter"
                                :"Aucun produit à valider"
                            }
                        </p>
                    }
                </AccordionCustom>
            </Box>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProductWorkflow)))));