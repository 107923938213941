import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCategorie2 } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Catégories mises en avant',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: 'FFFFFF',
        },
        value: {
            type: FormInputTypes.TEXT,
            label: "Titre de la section",
            value: null,
        },
    },
    blocks:{
        categories:{
            title: "Catégorie",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_BLOC,
            buttonAdd: 'une catégorie',
            config: [ 
                getDefaultCategorie2()
            ]
        }
    }
};