import React from 'react';
import { TablePagination} from '@material-ui/core';
import styled from 'styled-components';

const TablePaginationStyle = styled(TablePagination)`
    .MuiToolbar-root{
        padding: 0;
    }
    .MuiTablePagination-spacer{
        display: none;
    }
`;


export default function TablePaginationCustom(props){
    const {count, page, onChangePage, rowsPerPage, rowsPerPageOptions, labelDisplayedRows } = props;
    return(
        <TablePaginationStyle
            {...props}
            count={count}
            page={page}
            onChangePage={onChangePage}
            color="primary"
            component="div"
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            labelDisplayedRows={labelDisplayedRows}
        />
    );
}