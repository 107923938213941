import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H1 from '../../../../shared/components/CustomH1';
import Div from '../../../../shared/components/CustomDiv';
import { useDrop } from 'react-dnd';

// Who Are We

const TextWithImage = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
        data.inputs['backgroundImage'].value = item.media.node;
        update();
        }
    });

    const style = {
        boxShadow: 'none',
        backgroundColor: 'white',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        position: 'relative',
        width: '100%',
        // height: '880px',
        // paddingBottom: 16,
        marginBottom: 20
    };

    return (
        <div
        className={`offer-template-scope`}  
        ref={drop} 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        style={{
            ...style,
        }}
        >
            { !preview && props.children }
            <div className="container">
                <div className="breacrumb">
                    <Div data={data} className="category" preview={preview} component={id} block={'category'} inputCallback={inputCallback} nopadding={true} style={{backgroundColor: data?.inputs?.primaryColor.value, color: data?.inputs?.secondaryColor.value}} />
                    <Div data={data} className="sub-category" preview={preview} component={id} block={'sub_category'} inputCallback={inputCallback} nopadding={true} style={{color: data?.inputs?.primaryColor.value, backgroundColor: data?.inputs?.secondaryColor.value}} />
                </div>
                <div className="container-text-image" style={{position:"relative", backgroundColor: data.blocks.descriptionBlock.inputs?.backgroundColor.value}} onMouseEnter={() => {setHover(false); setHoverBlock(true)}} onMouseLeave={() => {setHover(true);setHoverBlock(false)}}>
                    {!preview && hoverBlock && props.toolbarBlock}
                    <div className="container-text">
                        <div className="image">
                            <img onClick={() => inputCallback(id, 'logo')} 
                                src={
                                    typeof data.blocks.descriptionBlock.blocks.logo.inputs.value?.value === 'string' ? 
                                        data.blocks.descriptionBlock.blocks.logo.inputs.value.value : 
                                        data.blocks.descriptionBlock.blocks.logo.inputs.value?.value?.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.descriptionBlock.blocks.logo.inputs.value.value.filePath}` 
                                            : "https://via.placeholder.com/500x250/ffffff/C5DEEC"
                                }
                            />
                        </div>
                        <Div data={data.blocks.descriptionBlock} className="text" preview={preview} component={id} block={'content'} inputCallback={inputCallback} nopadding={true}/>
                    </div>
                    <div className="container-image">
                        <img onClick={() => inputCallback(id, 'image')} 
                            src={
                                typeof data.blocks.descriptionBlock.blocks.image.inputs.value?.value === 'string' ? 
                                    data.blocks.descriptionBlock.blocks.image.inputs.value.value : 
                                    data.blocks.descriptionBlock.blocks.image.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.descriptionBlock.blocks.image.inputs.value.value.filePath}` 
                                        : "https://via.placeholder.com/700/ffffff/C5DEEC"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

TextWithImage.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default TextWithImage;