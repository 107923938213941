import React from 'react';
import { GET_CAMPAIGN } from '../../../../queries/campaigns';
import { SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { BROADCAST, BROADCAST_CAMPAIGN, VIEW, CREATE } from '../../../../js/constants/constant-rights';
import { ROUTE_DIFFUSION_CAMPAIGNS_ADD, ROUTE_HOME } from '../../../../js/constants/route-names';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';

import {Grid} from '@material-ui/core';

import CardCustom from '../../../layouts/Card/CardCustom';
import CardCampaign from '../../../layouts/Card/cardContent/CardCampaign';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyCampaign from '../../../../assets/pictos/empty-picto/empty_campaigns.png';

import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { withTranslation } from 'react-i18next';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class DiffusionCampaigns extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            listCampaigns: null,
            ready: true,
            hasCampaigns: null,
        };
    }

    componentDidMount() {
        this.handleGetAllCampaigns()
    }

    handleGetAllCampaigns = () =>{
        const getAllCampaigns  = GET_CAMPAIGN;

        this.props.client.query({
            query:getAllCampaigns,
            fetchPolicy:'no-cache'
        }).then(result =>{
            let listCampaigns = result.data.campaigns.edges
            this.setState({
                listCampaigns: listCampaigns,
                // filteredCat: newList
            })
            if(listCampaigns.length>0){
                this.setState({
                    hasCampaigns:true
                })
            }
        })
    }
    
    render() {
        return (
            <div>
                <TopPanel 
                    icomoon="icon-campagne"
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t("spread.campaigns.manageCampaigns")}
                    subtitle={this.props.t("spread.campaigns.manageCampaignPosts")} 
                    handlerAdd={() => this.goTo(ROUTE_DIFFUSION_CAMPAIGNS_ADD)} 
                    textAdd={this.props.t("spread.campaigns.addCampaign")}
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    buttonAvailable={this.state.ready}
                    hasBorder={true}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    style={{marginTop: 16, width: "100%"}}
                > 
                    {
                        this.state.listCampaigns ? (
                            this.state.listCampaigns.length > 0 ? (
                                this.state.listCampaigns.map((item, index)=>
                                    <GridCustom item lg={4} md={6} xs={12} key={`ListCampaign${index}`}>
                                        <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'0 0px 10px 0px'}>
                                            <CardCampaign campaign={item} textButton={this.props.t("spread.campaigns.seeCampaign")} inputCard={true}/>
                                        </CardCustom>
                                    </GridCustom>
                                )
                            ) : !this.state.hasCampaigns ? (
                                <EmptyCard 
                                    title={this.props.t("spread.campaigns.notConfiguredCampaign")} 
                                    subtitle={this.props.t("spread.active_assets.clickToAdd")} 
                                    textButton={"Créer une campagne"} 
                                    picto={EmptyCampaign} 
                                    onClick={() => this.goTo(ROUTE_DIFFUSION_CAMPAIGNS_ADD)}
                                    openForm={this.state.openForm}
                                />  
                            ) : (<EmptyCard title={this.props.t("spread.active_assets.noResult")} subtitle={this.props.t("spread.active_assets.tryOther")} picto={EmptyCampaign}  xsImg={this.state.openForm ? 4 : 2} />)
                        ) : <PageLoader />
                    }
                </Grid>
            </div>
        );
    }
    goTo = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: { campaignId : id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DiffusionCampaigns))));