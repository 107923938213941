import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect';
import formShopTheLook from './config/formShopTheLook.config';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';
import request from '../../../../js/utils/fetch';

import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { PRODUCTS, PRODUCTS_PRODUCTS, VIEW, CREATE, IMPORT } from '../../../../js/constants/constant-rights';

import { prepareAttributeValues, saveElement, updateElement, getElements } from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import { listSettings, listMappers, perPageOptions } from './config/listShopTheLook.config';
import * as formHelper from '../../../../js/helpers/form'
import { makeUnique, searchItem } from '../../../../js/helpers/search'
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Select } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import { CREATE_LOOKBOOK_PRODUCT, DELETE_LOOKBOOK, DELETE_LOOKBOOK_PRODUCT_POSITION, UPDATE_LOOKBOOK_PRODUCT_POSITION } from '../../../../queries/look_book';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { GET_ALL_ASSETS_LIGHT, GET_ASSETS_BY_TYPES, GET_ASSET_BY_ID_CATALOG, GET_ASSET_TYPES, GET_FULL_ASSETS } from '../../../../queries/assets';
import { checkRouting } from '../../../../js/utils/checkRouting';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchBar from '../../../ui/search/SearchBar';
import lookBookFormDuplicate from './config/formLookBookDuplicate.config';
import axios from '../../../../js/utils/axios';
import { ASSET_ESHOP } from '../../../../js/constants/assets-types';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import { withTranslation } from 'react-i18next';
import DialogModal from '../../../ui/dialog/DialogModal';

const CustomSelect = styled(Select)`
    width: 100%;
    border: 1px solid ${colors.grey.border};
    padding-left: 16px;
    padding-right: 16px;
    font-weight: bold;
    height: 40px;

    .MuiSelect-root{
        padding-top: 13px;
        padding-bottom: 13px;
        &:focus{
            background-color:transparent;
        }
    }
    fieldset{
        border: 0.5px solid ${colors.grey.lighter.hue700};
    }
`;

const statusFilterOptions = {
    all: { value: 'all', label: 'Tous', status: null },
    active: { value: 'active', label: 'Actif', status: true },
    inactive: { value: 'inactive', label: 'Inactif', status: false },
}

const LayoutCustom = styled(Box)`
    width: 100%; 
    transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;

    div:has(.layout-wrapper){
        display: none;
    }
`
class ShopTheLook extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openForm: false,
            openFormDuplicate: false,
            openDialog: false,
            editForm: 'add',
            assetId: `/api/assets/${props.match.params.id}`,
            currentLookBookId: null,
            currentLang: props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            ready: false,
            libelle: null,
            imageLook: null,
            status: true,
            selectedProducts: [],
            productsForPosition: [],
            initialProductsList: [],
            productsToDelete: [],
            queryVariables: {
                libelle: '',
                status: null
            },
            filters: {
                name: '',
                status: null,
                statusValue: 'all'
            },
            idAssetToDuplicate: null,
            idAssetSelected: null,
            isReady: false,
            drawerWidthModified: props.drawerWidth
        };
    }

    handleDrawerWidthChange = (width) => {
        this.setState({
            drawerWidthModified: width
        });
    };

    handleLang = (event) => {
        this.setState({
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };


    handleToggleDrawer = (stateDrawer, reset = false) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset) {
            this.resetState()
        }
    };


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = (drawerChange) => {
        let result = formHelper.handleStep('next', this.state.errors);
        this.setState({
            seeErrors: !result
        })
        if (drawerChange) {
            this.handleDrawerWidthChange(drawerChange)
        }
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handleMediaPicker = (selected, stateName, translated) => {
        this.handleInput(stateName, selected, null, translated);
    }

    getProductsToDelete = (result) => {
        let deleteProducts = [];
        let listProducts = this.state.productsForPosition;
        for (let i = 0; i < this.state.initialProductsList.length; ++i) {
            let checkDelete = result.find(e => e === this.state.initialProductsList[i].value)
            if (!checkDelete) {
                deleteProducts.push(this.state.initialProductsList[i].id)
            }
        }
        for (let product of listProducts) {
            let checkDelete = result.find(e => e === product.value)
            if (!checkDelete) {
                product.delete = true
            }
        }
        this.setState({
            productsForPosition: listProducts,
            productsToDelete: deleteProducts,
        });
    }

    handleInput = (stateName, evt, custom, translated) => {
        if (stateName === "selectedProducts") {
            let value = formHelper.setValue(this.state, stateName, evt, custom, translated);
            this.setState({
                ...value
            }, () => {
                let reworkData = [].concat(this.state.productsForPosition)
                for (let product of this.state.selectedProducts) {
                    let create = false
                    if (!this.state.initialProductsList.find(e => e.value === product) && !reworkData?.find(e => e.value === product)) {
                        create = true
                        reworkData.push({
                            value: product,
                            position: null,
                            create,
                            update: false
                        })
                    }
                }
                this.setState({
                    productsForPosition: reworkData
                }, () => {
                    this.getProductsToDelete(this.state.selectedProducts)
                })

            })
        }
        else if (stateName === "position") {
            let getProducts = this.state.productsForPosition
            let productData = getProducts.find(e => e.value === custom)
            productData.position = evt.target.value
            let update = true
            if (!this.state.initialProductsList.find(e => e.value === custom)) {
                update = false
            }
            productData.update = update
            this.setState({
                productsForPosition: getProducts
            });
        }
        else {
            let value = formHelper.setValue(this.state, stateName, evt, custom, translated);
            this.setState({ ...value })
        }
    }
    changePosition = (stateName, value, idProduct) => {
        let getProducts = this.state.listProducts
        let productData = getProducts.find(e => e.node.id === idProduct)
        let getData = productData.node.productCategories.edges.find(e => e.node.category.id === this.props.activeCategorie.id)
        if (stateName === "position") {
            getData.node.position = value?.target?.value ?? value
        } else {
            if (getData.node.selection) {
                getData.node.selection.id = value?.target?.value ?? value
            } else {
                getData.node.selection = { id: value?.target?.value ?? value }
            }
        }
        this.setState({
            listProducts: getProducts
        });
    };

    resetState = () => {
        this.setState({
            libelle: null,
            imageLook: null,
            status: true,
            selectedProducts: [],
            productsForPosition: [],
            initialProductsList: [],
            productsToDelete: [],
        })
    }

    saveProducts = (idLookBook) => {
        return new Promise(async (resolve, reject) => {
            for (let product of this.state.productsForPosition) {
                if (!product.delete) {
                    if (product.create) {
                        this.props.client.mutate({
                            mutation: CREATE_LOOKBOOK_PRODUCT,
                            variables: {
                                lookBook: idLookBook.id,
                                product: product.value,
                                position: product.position
                            }
                        })
                    } else if (product.update) {
                        this.props.client.mutate({
                            mutation: UPDATE_LOOKBOOK_PRODUCT_POSITION,
                            variables: {
                                id: product.id,
                                position: product.position
                            }
                        })
                    }
                }
            }
            for (let deleteProduct of this.state.productsToDelete) {
                this.props.client.mutate({
                    mutation: DELETE_LOOKBOOK_PRODUCT_POSITION,
                    variables: {
                        id: deleteProduct,
                    }
                })
            }
            resolve();
        });
    }

    handlerLookBookMutation = async () => {
        this.props.startLoading();
        let variables = null;
        switch (this.state.editForm) {
            case 'add':

                variables = {
                    asset: this.state.assetId,
                    libelle: this.state.libelle,
                    status: this.state.status,
                    media: this.state.imageLook?.id || null
                }
                let newLookBook = await saveElement('lookBook', variables, { enableLoad: false })
                await this.saveProducts(newLookBook)
                this.props.snack(ALERT_SUCCESS, 'Look ajouté avec succès');
                this.resetState();
                this.handleToggleDrawer('openForm', true);
                this.reloadLookBooks();
                this.props.stopLoading();
                break;

            case 'edit':

                variables = {
                    id: this.state.currentLookBookId,
                    asset: this.state.assetId,
                    libelle: this.state.libelle,
                    status: this.state.status,
                    media: this.state.imageLook?.id || null
                }
                let getResult = await updateElement(this.state, 'lookBook', variables, null, { enableLoad: false })
                if (getResult) {
                    await this.saveProducts(getResult)
                    this.props.snack(ALERT_SUCCESS, 'Look modifié avec succès');
                    this.resetState();
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadLookBooks();
                }
                break;
            case 'duplicate':

                let duplicate = await axios(`${process.env.REACT_APP_API}/lookbooks/duplicate`, 'POST', { lookBookId: parseInt(this.state.currentLookBookId.replace('/api/look-books/', '')), assetId: parseInt(this.state.idAssetSelected.replace('/api/assets/', '')) });
                if (duplicate.success) {
                    this.props.snack(ALERT_SUCCESS, 'Lookbook dupliqué avec succès');
                    this.handleToggleDrawer('openFormDuplicate', true);
                    this.setState({
                        idAssetSelected: null
                    });
                    this.props.stopLoading();
                }
                break;
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: DELETE_LOOKBOOK,
            variables: { id: this.state.currentLookBookId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, 'Look supprimé avec succès');
            this.reloadLookBooks();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, `Impossible de supprimer le look`);
            this.reloadLookBooks();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    handleGetAssetInfo = () => {
        this.props.client.query({
            query: GET_ASSET_BY_ID_CATALOG,
            fetchPolicy: 'no-cache',
            variables: { "id": this.state.assetId }
        }).then(result => {
            this.setState({
                assetInfo: result.data.asset,
                isReady: true
            })
        });
    }

    initForm = () => {
        this.setState({
            editForm: 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    editHandler = async (lookBook) => {
        let newArray = [];
        newArray = lookBook.productLookBooks.edges?.map(e => e.node.product.id)
        this.setState({
            editForm: 'edit',
            currentLookBookId: lookBook.id,
            libelle: lookBook.libelle,
            imageLook: lookBook.media,
            status: lookBook.status,
            selectedProducts: _.clone(lookBook.productLookBooks.edges?.map(e => e.node.product.id))
        })
        await this.prepareProducts(lookBook.productLookBooks.edges)
        this.handleToggleDrawer('openForm');
    }

    prepareProducts = (listProducts) => {
        return new Promise(async (resolve, reject) => {
            let arrayProducts = []
            for (let product of listProducts) {
                arrayProducts.push({
                    value: product.node.product.id,
                    position: product.node.position,
                    create: false,
                    update: false,
                    id: product.node.id,
                })
            }
            this.setState({
                productsForPosition: arrayProducts,
                initialProductsList: arrayProducts
            })
            resolve()
        })
    }

    getLookBooksCallback = () => {
        this.setState({
            reloadLookBooks: false
        })
    }
    reloadLookBooks = () => {
        this.setState({ reloadLookBooks: true })
    }

    componentDidMount() {
        checkRouting(this.props);
        this.handleGetAssetInfo();
        if (this.props.location.state?.hasMessage) {
            this.props.snack(ALERT_SUCCESS, this.props.location.state.message)
        }
    }

    handleResetFilters = () => {
        this.setState({
            queryVariables: {
                ...this.state.queryVariables,
                libelle: '',
                status: null,
            },
            filters: {
                ...this.state.filters,
                name: '',
                status: null,
                statusValue: 'all',
            }
        })
    }

    handleSearchByName = (e) => {
        const value = e.target.value;
        this.setState({
            filters: {
                ...this.state.filters,
                name: value,
            },
        });
    }

    handleSearchByStatus = (e, custom) => {
        const chosenOption = e.target.value;
        this.setState({
            filters: {
                ...this.state.filters,
                statusValue: chosenOption,
                status: Object.values(statusFilterOptions).find(option => option.value === chosenOption).status // TODO: Check
            },
        });
    }

    handleDuplicate = (lookBookId) => {
        this.props.startLoading()
        this.props.client.query({
            query: GET_ASSET_TYPES,
        }).then(result => {
            let eshopTypes = result.data.assetTypes?.edges?.filter(e => ASSET_ESHOP.includes(e.node.identifier))
            this.props.client.query({
                query: GET_ASSETS_BY_TYPES,
                variables: {
                    assetType_list: eshopTypes.map(e => e.node.id)
                }
            }).then(result2 => {
                this.setState({
                    listCatalogsDuplicate: result2.data.assets.edges,
                    editForm: 'duplicate',
                    currentLookBookId: lookBookId,
                }, () => this.props.stopLoading())
                this.handleToggleDrawer('openFormDuplicate');
            })
        })
    }

    isFilterActive = () => this.state.filters.name !== '' || /* filters.rootStatus !== filters.statusValue */ this.state.filters.statusValue !== statusFilterOptions.all.value

    render() {
        return (
            <LayoutCustom>
                <TopPanel
                    icomoon="picto_cart-bag"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Shop the look"
                    subtitle="Gestion de vos looks (création / modification / suppression)"
                    handlerAdd={() => this.initForm()}
                    textAdd={"+ Ajouter un look"}
                    // searchHandler={
                    //     this.handleChangeCompanies
                    // } 
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    // currentLang={this.state.currentLang} 
                    // handleLang={this.handleLang} 
                    // locales={this.props.locales}
                    // handlerImport={() => this.handleToggleDrawer('openImportTypesForm')} 
                    // textImport={"Importer des sociétés"}
                    hasBorder={true}
                />
                {/* <TraductionSelect 
                    currentLang={this.state.currentLang} 
                    handleLang={this.handleLang} 
                    locales={this.props.locales}
                /> */}
                <Grid container style={{
                    height: '100%',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto',
                    position: 'relative'
                }}>
                    <AccordionCustom
                        title={'Filtres et Recherche'}
                        defaultExpanded={true}
                        custompadding={'0px'}
                        style={{
                            marginBottom: '32px',
                        }}
                    >
                        <Grid style={{ flexGrow: 1 }}>
                            <Grid container style={{ padding: '16px 19px' }} spacing={2}>
                                <Grid item xs={8} md={this.isFilterActive() ? 7 : 8}>
                                    <Grid container>
                                        <Grid container justifyContent='space-between'>
                                            <Typography variant='body1' style={{
                                                color: colors.black.regular,
                                                fontSize: '12px',
                                                marginBottom: '9px'
                                            }}>Recherche par nom</Typography>
                                            <Box>
                                                {/* Add Info */}
                                            </Box>
                                        </Grid>
                                        <SearchBar noIcon
                                            style={{
                                                margin: '0 auto 8px auto',
                                                width: '100%',
                                                background: 'white',
                                            }}
                                            customstyle={{
                                                width: '100%',
                                                height: '40px',
                                                boxSizing: 'border-box',
                                                padding: '0px 0px 0px 16px',
                                            }}
                                            onChange={this.handleSearchByName} value={this.state.filters.name} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <Grid container>
                                        <Grid container justifyContent='space-between'>
                                            <Typography variant='body1' style={{
                                                color: colors.black.regular,
                                                fontSize: '12px',
                                                marginBottom: '9px',
                                                marginTop: this.props.windowWidth < 600 && '10px'
                                            }}>Recherche par status</Typography>
                                        </Grid>
                                    </Grid>
                                    <CustomSelect
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.filters.statusValue}
                                        onChange={this.handleSearchByStatus}
                                        variant="standard"
                                        disableUnderline
                                    >
                                        <MenuItem value={statusFilterOptions.all.value}>{statusFilterOptions.all.label}</MenuItem>
                                        <MenuItem value={statusFilterOptions.active.value}>{statusFilterOptions.active.label}</MenuItem>
                                        <MenuItem value={statusFilterOptions.inactive.value}>{statusFilterOptions.inactive.label}</MenuItem>
                                    </CustomSelect>
                                </Grid>
                                <Grid item container xs={12} md={this.isFilterActive() ? 2 : 1} justifyContent={this.props.windowWidth >= 1250 ? "start" : 'flex-end'} alignItems='center' style={{
                                    marginRight: this.props.windowWidth >= 1250 ? 'unset' : '8px',
                                    marginBottom: this.props.windowWidth >= 1250 ? 'unset' : '8px',
                                }}>
                                    {
                                        this.isFilterActive() ?
                                            <Button onClick={this.handleResetFilters} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: this.props.windowWidth >= 1250 ? 20 : 10, border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                            : null
                                    }
                                    <Button onClick={(e) => {
                                        this.setState({
                                            queryVariables: {
                                                ...this.state.queryVariables,
                                                libelle: this.state.filters.name,
                                                status: this.state.filters.status,
                                            }
                                        })
                                    }} style={{ marginBottom: 0, borderRadius: 0, marginTop: this.props.windowWidth >= 1250 ? 20 : 10 }}>Filtrer</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionCustom>
                    <Listing
                        label='look'
                        settings={listSettings}
                        cardProps={{
                            openForm: this.state.openForm,
                            currentLang: this.state.currentLang,
                            windowWidth: this.props.windowWidth,
                            // Card
                            summary: [ /* The properties must be strings since they are computed inside the component */
                                {
                                    "label": 'Statut',
                                    "status": {}
                                },
                                {
                                    "label": 'Nb d\'articles',
                                    "productLookBooks": {
                                        "edges": {}
                                    }
                                }
                            ],
                            modifyButtonProps: {
                                text: 'Modifier',
                                onClick: this.editHandler,
                            },
                            textButtonSecondary: 'Dupliquer',
                            handlerButtonSecondary: this.handleDuplicate,
                            deleteButtonProps: {
                                text: 'Supprimer',
                                onClick: this.handleToggleDialog,
                            },
                        }}
                        queryVariables={{
                            asset: this.state.assetId,
                            libelle: this.state.queryVariables.libelle !== "" ? this.state.queryVariables.libelle : null,
                            status: this.state.queryVariables.status,
                        }}
                        perPageOptions={perPageOptions}
                        pagination={true}
                        mappers={listMappers}
                        currentLang={this.state.currentLang}
                        identifier='lookBooks'
                        viewsOptions={{
                            current: 'card',
                            settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                        }}
                        reload={this.state.reloadLookBooks}
                        listingCallback={this.getLookBooksCallback}
                        noResultComponent={() => <EmptyCard title={this.props.t("spread.active_assets.lookNotConfigured")} subtitle={this.props.t("spread.active_assets.clickToAdd")} textButton={this.props.t("spread.active_assets.addLook")} onClick={() => this.initForm()} picto={EmptyAsset} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />}
                    />
                </Grid>

                {this.state.isReady ? (
                    <div>
                        <LayoutBuilder
                            isSublayout={false}
                            icomoon="picto_cart-bag"
                            opened={this.state.openForm}
                            forClose={() => this.handleToggleDrawer('openForm', true)}
                            handlerSetup={() => { }}
                            dataLayout={formShopTheLook(this.state.editForm, this.handleMediaPicker, this.state)}
                            allState={this.state}
                            stateCallback={this.handleInput}
                            errorCallback={this.handleFormError}
                            drawerWidth={this.state.drawerWidthModified}
                            stepperButtonAction={[
                                () => this.handleNextStep('80%'),
                                () => this.handleNextStep(300),
                                () => this.handleNextStep,
                            ]}
                            backStepperButtonAction={[
                                null,
                                () => this.handleDrawerWidthChange(300),
                                () => this.handleDrawerWidthChange('80%')
                            ]}
                            stepperButtonDisabled={[null, () => this.state.selectedProducts.length === 0, null]}
                            validateButton={true}
                            handlerMutation={this.handlerLookBookMutation}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            // deleteMutation={this.state.editForm === "edit" ? () => { this.handleToggleDialog() } : null}
                            // deleteText={'Supprimer le look'}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                        />
                        {/* Duplicate */}
                        <LayoutBuilder
                            isSublayout={false}
                            opened={this.state.openFormDuplicate}
                            forClose={() => {
                                this.handleToggleDrawer('openFormDuplicate', true); this.setState({
                                    idAssetSelected: null
                                })
                            }}
                            handlerSetup={() => { }}
                            dataLayout={lookBookFormDuplicate(this.state)}
                            drawerWidth={this.props.drawerWidth}
                            allState={this.state}
                            stateCallback={this.handleInput}
                            errorCallback={this.handleFormError}
                            handlerMutation={this.handlerLookBookMutation}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            validateButton={true}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                        />
                    </div>
                )
                    : null}

                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    title='Êtes-vous sûr de vouloir supprimer ce look ?'
                    primaryAction={this.deleteMutation}
                    secondaryAction={this.handleToggleDialog}
                    windowWidth={this.props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        Si vous supprimez ce look, celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.
                    </DialogContentText>
                </DialogModal>
            </LayoutCustom>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({ ...a, node: { ...a.node } }) : ({ ...a }));

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ShopTheLook)))));