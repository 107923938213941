import React from 'react';
import {Grid} from '@material-ui/core';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { GET_RETAILER_PRODUCT_DATAS } from '../../../../queries/retailers';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import {listSettings,listMappers,perPageOptions} from './config/flowListing.config';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import Listing from '../../../layouts/Listing/Listing';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class ListRetailers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            retailerId: this.props.history.location.state.retailerId,
        };
    }

    handleGetRetailers = async () => {
        this.props.client.query({
            query: GET_RETAILER_PRODUCT_DATAS,
            variables: {"retailer": this.props.history.location.state.retailerId},
            fetchPolicy: "no-cache"
        }).then(result => {
            this.setState({data: result.data.retailerProductDatas, ready: true})
        })
    }

    componentDidMount(){
        this.handleGetRetailers();
    }
    
    componentDidUpdate(prevProps, prevState){

    }  

    render() {
        return (
            <div>
                <TopPanel 
                    icomoon={"icon-flux"} 
                    colorIcomoon={colors.blue.darker.hue300} 
                    title={"Mes flux"} 
                    subtitle={"Visualisation"} 
                    gradientColor1={colors.menu.regular} 
                    gradientColor2={colors.menu.darker} 
                    windowWidth={this.props.windowWidth}
                    hasBorder={true}
                />
                <Grid container direction="column" justifyContent="center" spacing={0} style={{width: this.state.openForm && this.state.isEmpty ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', marginTop: 16}}>
                    <Grid container direction="row" spacing={2}>
                        {
                            this.state.ready ? 
                                <Listing
                                    label = 'éléments'
                                    settings = {listSettings}
                                    perPageOptions = {perPageOptions}
                                    mappers = {listMappers}
                                    currentLang = {this.state.currentLang}
                                    identifier = 'retailerProductDatas'
                                    queryVariables={{
                                        retailer: this.state.retailerId
                                    }}
                                    viewsOptions = {{
                                        current : 'table',
                                        settings : ['table'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                    }}
                                />
                            :   <PageLoader />
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };

    goToDetails = (route, id) => {
        this.props.history.push({
            pathname : route,
            state: {retailerId : id}
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListRetailers)));