import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

import Appbar from '../../navigation/Appbar';
import DrawerLeft from '../../navigation/DrawerLeft';

import './Dashboard.scss';
import { START_LOADING, STOP_LOADING, SET_USER, SET_GUIDELINE } from '../../../js/constants/action-types';
import {
  ROUTE_STATS_RETAILERS,
  ROUTE_STATS_ASSETS,
  ROUTE_STATS_CAMPAIGNS,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_DETAIL_USER,
  ROUTE_SETTINGS_SUBSCRIPTIONS,
  ROUTE_PREFERENCES,
  ROUTE_HELPS,
  ROUTE_BRAND_GUIDELINE,
} from '../../../js/constants/route-names';
import {
  ROUTE_CRM,
  ROUTE_CRM_STATS,
  ROUTE_CRM_AGENCY,
  ROUTE_CRM_OFFERS_LIST,
  ROUTE_CRM_OFFERS_ADD,
  ROUTE_CRM_OFFERS_EDIT,
  ROUTE_CRM_OFFERS_PROJECTS,
  ROUTE_CRM_OFFERS_MODELES,
  ROUTE_CRM_OFFERS_MODELES_ADD,
  ROUTE_CRM_OFFERS_MODELES_EDIT,
  ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS,
  ROUTE_CRM_OFFERS_PRESENTATIONS,
  ROUTE_CRM_MEDIAS,
  ROUTE_CRM_MEDIAS_CATEGORIES,
  ROUTE_CRM_CONTACTS,
  ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS,
  ROUTE_CRM_CONTACTS_SOCIETIES,
  ROUTE_CRM_CONTACTS_CLIENTS,
  ROUTE_CRM_CONTACTS_LEADS,
  ROUTE_CRM_CONTACTS_LIST,
  ROUTE_CRM_CONTACTS_DETAILS,
  ROUTE_CRM_CONTACTS_ADD,
  ROUTE_CRM_SETTINGS_LANGUAGES,
  ROUTE_CRM_SETTINGS_USERS,
  ROUTE_CRM_SETTINGS_DETAIL_USER,
  ROUTE_CRM_PRICES,
  ROUTE_CRM_TAXES,
  ROUTE_CRM_PAYMENT_TERMS,
  ROUTE_CRM_PAYMENT_DEADLINES,
  ROUTE_CRM_CGV
} from '../../../js/constants/route-names';

import Homepage from './homepage/Homepage';

import DashboardStats from './stats/DashboardStats';
import StatsAssets from './stats/StatsAssets';
import StatsRetailers from './stats/StatsRetailers';
import StatsCampaigns from './stats/StatsCampaigns';


import ListAgencies from './agency/ListAgencies';

import ContactCompanies from './contacts/ContactCompanies';
import ContactCompaniesDetails from './contacts/ContactCompaniesDetails';

import MediasCategories from './medias/MediasCategories';
import DashboardCustomers from './customers/DashboardCustomers';
import ContactAdd from './customers/ContactAdd';
import ListCustomers from './customers/ListCustomers';


import GuidelineBrand from './brand/GuidelineBrand';

import SettingsLanguages from './settings/SettingsLanguages';
import SettingsUsers from './settings/SettingsUsers';
import UserDetail from '../dashboard/settings/UserDetail';
import SettingsSubscriptions from './settings/SettingsSubscriptions';
import SettingsPreferences from './settings/SettingsPreferences';
import SettingsHelps from './settings/SettingsHelps';
import ListProjects from './offers/ListProjects';
import ListModelsDevis from './offers/ListModelsDevis';
import ListModelsPresentations from './offers/ListModelsPresentations';
import DevisDetails from './offers/DevisDetails';
import ListOffers from './offers/ListOffers';
import ListPresentations from './offers/ListPresentations';
import OfferDetails from './offers/OfferDetails';

function DashboardCRM(props) {

  const [open, setOpen] = React.useState(window.innerWidth > 1000);

  const handleDrawerToggle = () => setOpen(!open);

  let drawerWidth = 300;

  if (window.innerWidth > 1600) {
    open ? drawerWidth = 300 : drawerWidth = 96;
  }
  else if (window.innerWidth <= 1600 && window.innerWidth > 1400) {
    open ? drawerWidth = 240 : drawerWidth = 96;
  }
  else if (window.innerWidth <= 1400 && window.innerWidth > 1000) {
    open ? drawerWidth = 180 : drawerWidth = 96;
  }
  else if (window.innerWidth <= 1000) {
    open ? drawerWidth = 240 : drawerWidth = 0;
  }

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    hide: {
      display: 'none',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      width: `calc(100% - ${drawerWidth}px)`,
      flexGrow: 1,
      // padding: theme.spacing(4),
      // paddingRight: theme.spacing(2),
      paddingTop: '20px',
      paddingBottom: theme.spacing(4),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      "& > div > div": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
      },
      "& > div > h1": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2)
      },
      "& > div > div.MuiBox-root": {
        // padding: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
      },
      "& > div > div.MuiGrid-root": {
        // padding: theme.spacing(4),
        // paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
      }
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Appbar openCallback={handleDrawerToggle} opened={open} user={props.user} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />

      <DrawerLeft openCallback={handleDrawerToggle} opened={open} windowWidth={props.windowWidth} drawerWidth={drawerWidth} />

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

        <Switch>
          <Route path={`${ROUTE_CRM}`} exact>
            <Homepage user={props.user} windowWidth={props.windowWidth} />
          </Route>


          <Route path={`${ROUTE_CRM_STATS}`} exact>
            <DashboardStats />
          </Route>
          <Route path={`${ROUTE_STATS_RETAILERS}`} exact>
            <StatsRetailers />
          </Route>
          <Route path={`${ROUTE_STATS_ASSETS}`} exact>
            <StatsAssets />
          </Route>
          <Route path={`${ROUTE_STATS_CAMPAIGNS}`} exact>
            <StatsCampaigns />
          </Route>

          <Route path={`${ROUTE_CRM_AGENCY}`} exact>
            <ListAgencies typeAgency={"agency"} drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_PRICES}`} exact>
            <ListAgencies typeAgency={"tarif"} drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_TAXES}`} exact>
            <ListAgencies typeAgency={"tax"} drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_PAYMENT_TERMS}`} exact>
            <ListAgencies typeAgency={"paymentTerms"} drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_PAYMENT_DEADLINES}`} exact>
            <ListAgencies typeAgency={"paymentDeadlines"} drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_CGV}`} exact>
            <ListAgencies typeAgency={"cgv"} drawerWidth={drawerWidth} />
          </Route>

          <Route path={`${ROUTE_CRM_OFFERS_LIST}`} exact>
            <ListOffers drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_ADD}`} exact>
            <OfferDetails drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_EDIT}`} exact>
            <OfferDetails drawerWidth={drawerWidth} />
          </Route>
          {/* <Route path={`${ROUTE_CRM_OFFERS_COMMERCIALS}`} exact>
              <OfferManager />
            </Route> */}
          <Route path={`${ROUTE_CRM_OFFERS_PROJECTS}`} exact>
            <ListProjects drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_MODELES}`} exact>
            <ListModelsDevis drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_MODELES_ADD}`} exact>
            <DevisDetails drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_MODELES_EDIT}`} exact>
            <DevisDetails drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS}`} exact>
            <ListModelsPresentations drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_OFFERS_PRESENTATIONS}`} exact>
            <ListPresentations drawerWidth={drawerWidth} />
          </Route>

          <Route path={`${ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS}`} exact>
            <ContactCompaniesDetails drawerWidth={drawerWidth} />
          </Route>

          <Route path={`${ROUTE_CRM_MEDIAS_CATEGORIES}`} exact>
            <MediasCategories />
          </Route>


          <Route path={`${ROUTE_CRM_CONTACTS}`} exact>
            <DashboardCustomers windowWidth={props.windowWidth} />
          </Route>
          {/* <Route path={`${ROUTE_PRODUCTS_CATEGORIES}`} exact>
              <ProductsCategories drawerWidth={drawerWidth} windowWidth={props.windowWidth}/>
            </Route> */}
          <Route path={`${ROUTE_CRM_CONTACTS_ADD}`} exact>
            <ContactAdd drawerWidth={drawerWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_CONTACTS_SOCIETIES}`} exact>
            <ContactCompanies drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_CONTACTS_LIST}`} exact>
            <ListCustomers windowWidth={props.windowWidth} typeContact="contact" />
          </Route>
          <Route path={`${ROUTE_CRM_CONTACTS_CLIENTS}`} exact>
            <ListCustomers windowWidth={props.windowWidth} typeContact="client" />
          </Route>
          <Route path={`${ROUTE_CRM_CONTACTS_LEADS}`} exact>
            <ListCustomers windowWidth={props.windowWidth} typeContact="lead" />
          </Route>
          <Route path={`${ROUTE_CRM_CONTACTS_DETAILS}`} >
            <ContactAdd drawerWidth={drawerWidth} edit={true} />
          </Route>

          <Route path={`${ROUTE_BRAND_GUIDELINE}`} exact>
            <GuidelineBrand drawerWidth={drawerWidth} windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_SETTINGS_LANGUAGES}`} exact>
            <SettingsLanguages windowWidth={props.windowWidth} />
          </Route>
          <Route path={`${ROUTE_CRM_SETTINGS_USERS}`} exact>
            <SettingsUsers drawerWidth={drawerWidth} windowWidth={props.windowWidth} user={props.user} />
          </Route>
          <Route path={`${ROUTE_CRM_SETTINGS_DETAIL_USER}`} exact>
            <UserDetail drawerWidth={drawerWidth} user={props.user} />
          </Route>
          <Route path={`${ROUTE_SETTINGS_SUBSCRIPTIONS}`} exact>
            <SettingsSubscriptions />
          </Route>

          <Route path={`${ROUTE_PREFERENCES}`} exact>
            <SettingsPreferences />
          </Route>
          <Route path={`${ROUTE_HELPS}`} exact>
            <SettingsHelps />
          </Route>

          <Route path={`${ROUTE_SETTINGS}`}>
            <Typography variant="body1">
              Paramètres
              </Typography>
          </Route>
        </Switch>
      </main>

    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: user => dispatch({ type: SET_USER, payload: { user } }),
    setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline } })
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    user: state.user,
    guideline: state.guideline,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardCRM));
