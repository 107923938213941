import PropTypes from "prop-types";
import React, { Fragment, useContext, useState, useEffect } from "react";
import Link from 'next/link';
import { Context } from '../../../../../../store.js';
import * as ga from '../../../../../../tools/GA';
import { LinkCustom } from "../../_next/navigation";

const ProductGridListSingle = ({
  product,
  currency,
  sliderClassName,
  spaceBottomClass,
  spread = false,
  data = null,
  params = null
}) => {
    const context = useContext(Context);
    const [added, setAdded] = useState(false);

    const discountedPrice = null;
    const finalProductPrice = 0;
    const finalDiscountedPrice = 0;

    const datas = product.attributes;

    let allNames    = datas.product_name;
    let name        = allNames?.['fr_FR'] ?? 'n/a';

    let allDesc     = datas.product_description;
    let desc        = allDesc?.['fr_FR'] ?? 'n/a';

    let allImages   = datas.product_image;
    let image       = allImages?.['fr_FR'] ?? 'n/a';

    let price = product?.retailerPrice === 0 
      ? "Non disponible"
      : product?.retailerPrice;

    let allEAN   = product.attributes.product_ean;
    let ean       = allEAN?.['fr_FR'] ?? 'n/a';

    useEffect(() => {
        let all = context?.products;
    
        if (all)
            setAdded(all.find(e => e === product.id));
    }, [context]);

    const onClick = () => {
      ga.pageview(product?.sku, product?.retailerUrl);

      // ga.event({
      //   action: 'view_item',
      //   params: {
      //       items: [{
      //           id: product?.sku,
      //           name: name
      //       }]
      //   }
      // });

      if (!spread && params?.isExternal?.value) {
        try { window.parent.location.href = product?.retailerUrl; } 
        catch (e) { console.log(e); }
      } 
    };

  const showPrice =  params?.displayPrices?.value;
  const isDocumentVersion =  params?.isDocumentVersion?.value;

  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-12 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img">
            <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${product.id}`} external={params?.isExternal?.value}>
                <a onClick={onClick}>
                    <img
                        className="default-img"
                        src={`${process.env.REACT_APP_MEDIAS}/${image}`}
                        alt=""
                    />
                </a>
            </LinkCustom>

            <div className="product-action">
              {
                !(params.hasWishlist?.value ?? true) ? null : (
                  <div className="pro-same-action pro-wishlist">
                    <button
                        className={added ? "" : "active"}
                        disabled={false}
                        title={added ? data.inputs?.['removeFromWishlist']?.value : data.inputs?.['addToWishlist']?.value}
                        onClick={() => {
                            let all = localStorage.getItem('products');

                            if (all) {
                                all = JSON.parse(all);

                                if (added) {
                                    all = all.filter(e => e !== product.id);
                                    setAdded(false);
                                    context.update(all);
                                } else {
                                    all.push(product.id);
                                    setAdded(true);
                                    context.update(all);
                                }
                            } else if (!added) {
                                all = [product.id];
                                setAdded(true);
                                context.update(all);
                            }
        
                            localStorage.setItem('products', JSON.stringify(all || []));
                        }}
                    >
                      <i className={added ? "fa fa-heart" : "fa fa-heart-o"} />
                    </button>
                  </div>
                ) 
              }
            </div>
          </div>
          <div className="product-content text-center">
            <p>
              {product?.sku}
            </p>
            <h3>
              <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${product.id}`} external={params?.isExternal?.value}>
                <a onClick={onClick}>
                  { name }
                </a>
              </LinkCustom>
            </h3>
            {
              showPrice 
                ? (
                  <div className="product-price">
                    {
                      discountedPrice !== null 
                        ? (
                          <Fragment>
                            <span>{currency.currencySymbol + finalDiscountedPrice}</span>{" "}
                            <span className="old">
                              {currency.currencySymbol + finalProductPrice}
                            </span>
                          </Fragment>
                        ) : price !== '0' 
                          ? (
                            <>
                              {
                                price === 'Non disponible' 
                                  ? price 
                                  : <span>{price} €</span>
                              }
                            </>
                          ) : null 
                    }
                  </div>
                ) : null
            }
            {
              !isDocumentVersion 
                ? ( 
                  <LinkCustom spread={spread} href="/product/[slug]" as={`/product/${product.id}`} external={params?.isExternal?.value}>
                    <button 
                      className={product?.retailerUrl ? 'product-button' : 'product-button-disabled'} 
                      onClick={product?.retailerUrl ? onClick : null}
                    >
                      {
                        price === 'Non disponible' 
                          ? 'Indisponible' 
                          : 'Ajouter au panier >'
                      }
                    </button>
                  </LinkCustom>
                  ) : null 
            }
          </div>
        </div>
        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <a to={process.env.PUBLIC_URL + "/product/" + product.id}>
                    <img
                      className="default-img img-fluid"
                      src={`${process.env.REACT_APP_MEDIAS}/${image}`}
                      alt=""
                    />
                    {/* {product.image.length > 1 ? (
                      <img
                        className="hover-img img-fluid"
                        src={process.env.PUBLIC_URL + product.image[1]}
                        alt=""
                      />
                    ) : (
                      ""
                    )} */}
                  </a>
                  {product.discount || product.new ? (
                    <div className="product-img-badges">
                      {product.discount ? (
                        <span className="pink">-{product.discount}%</span>
                      ) : (
                        ""
                      )}
                      {product.new ? <span className="purple">New</span> : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <a to={process.env.PUBLIC_URL + "/product/" + product.id}>
                    { name }
                  </a>
                </h3>
                {/* <div className="product-list-price">
                  {discountedPrice !== null ? (
                    <Fragment>
                      <span>
                        {currency.currencySymbol + finalDiscountedPrice}
                      </span>{" "}
                      <span className="old">
                        {currency.currencySymbol + finalProductPrice}
                      </span>
                    </Fragment>
                  ) : (
                    <span>{currency.currencySymbol + finalProductPrice} </span>
                  )}
                </div> */}
                {desc ? (
                  // <p dangerouslySetInnerHTML={{ __html: desc }} />
                  <p>{}</p>
                ) : (
                  ""
                )}

                {
                  !(params.hasWishlist?.value ?? true) ? null : (
                    <div className="shop-list-actions d-flex align-items-center">
                      <div className="shop-list-wishlist ml-10">
                        <button
                          className={added ? "" : "active"}
                          disabled={false}
                          title={added ? data.inputs?.['removeFromWishlist']?.value : data.inputs?.['addToWishlist']?.value}
                          onClick={() => {
                              let all = localStorage.getItem('products');

                              if (all) {
                                  all = JSON.parse(all);

                                  if (added) {
                                      all = all.filter(e => e !== product.id);
                                      setAdded(false);
                                      context.update(all);
                                  } else {
                                      all.push(product.id);
                                      setAdded(true);
                                      context.update(all);
                                  }
                              } else if (!added) {
                                  all = [product.id];
                                  setAdded(true);
                                  context.update(all);
                              }
          
                              localStorage.setItem('products', JSON.stringify(all || []));
                        }}
                        >
                          <i className={added ? "fa fa-heart" : "fa fa-heart-o"} />
                          {/* <i className="pe-7s-like" /> */}
                        </button>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object
};

export default ProductGridListSingle;
