import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withApollo } from 'react-apollo';
import SearchBarProductDynamic from '../../../../components/ui/inputs/SearchBarProductDynamic';
import ItemCard from './ItemCard';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: 15
    },
    allMedias: {
        paddingTop: 30
    }
}));

function ProductSelector ({ catalog, products, value, onChange, label }) {
    const classes = useStyles();
    const [ selected, setSelected ] = useState(null);

    const onSelect = (values) => {
        let add = [];

        for (let val of values) {
            if (!value.find(e => e.id === val.id)) {
                add.push(val);
            }
        }
        
        let all = value.concat(add);
        onChange(all);
    };

    const onRemove = (item) => {
        let all = value.filter(e => e.id !== item.id);
        onChange(all);
    };
    
    return (
        <div className={classes.root}>
            <h2 style={{ color: "rgb(43, 69, 83)", padding: '10px 0', fontSize: 16, marginBottom: 10 }} >
                { label || "Produits" }
            </h2>
            
            <SearchBarProductDynamic 
                currentLang={'fr_FR'}
                onSelect={onSelect}
                catalog={catalog}
                isMulti={true}
                static={true}
                products={products}
            />

            <Grid 
                container 
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                className={classes.allMedias}
            >
                { value.map((e, i) => <ItemCard key={i} item={e} selected={selected} setSelected={setSelected} onRemove={onRemove} product={true} simpleProduct={true} />) }
            </Grid>
        </div>
    );
}

export default withApollo(ProductSelector);