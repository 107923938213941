import { Pannellum } from "pannellum-react";
import { useEffect, useRef } from "react";

const PanoVirtualTour = ({markersImageData,setCurrentImageMarker,currentImageMarker,stateCallback,setMarkersImageData,setCurrentMarker,currentMarker,setRealoadPano,reloadPano})=>{
    let click = null
    const ref= useRef(null)

    function getCoords(inputX, inputY){
        const viewer =ref?.current?.getViewer() ;
        const container = viewer.getContainer();
        const renderer = viewer.getRenderer();
        const container_rect = container.getBoundingClientRect();
        const pos = {x: inputX - container_rect.left, y: inputY - container_rect.top};
    
        const config = viewer.getConfig();
    
        const canvas = renderer.getCanvas();
        const canvasWidth = canvas.clientWidth;
        const canvasHeight = canvas.clientHeight;
        const x = pos.x / canvasWidth * 2 - 1;
        const y = (1 - pos.y / canvasHeight * 2) * canvasHeight / canvasWidth;
        const focal = 1 / Math.tan(config.hfov * Math.PI / 360);
        const s = Math.sin(config.pitch * Math.PI / 180);
        const c = Math.cos(config.pitch * Math.PI / 180);
        const a = focal * c - y * s;
        const root = Math.sqrt(x*x + a*a);
        const pitch = Math.atan((y * c + focal * s) / root) * 180 / Math.PI;
    
        let yaw = Math.atan2(x / root, a / root) * 180 / Math.PI + config.yaw;
        if (yaw < -180) yaw += 360;
        if (yaw > 180) yaw -= 360;
    
        return {pitch,yaw};
      }

    useEffect(()=>{
        if (reloadPano) {
            const viewer =ref?.current;
            viewer?.forceRender()
            setRealoadPano(false)
        }
    },[reloadPano])

    const handle = (evt)=>{
        if (evt.x === click.x && evt.y === click.y) {
            const coords = getCoords(evt.x,evt.y)
            let array = markersImageData.map((f,i) => {
                if (currentImageMarker === f) {
                    const newObj = {
                        title:"New marker",
                        text:null,
                        image:null,
                        link:null,
                        type:'info',
                        pitch:coords.pitch,
                        yaw:coords.yaw,
                    }
                    const newMarker = {
                        ...f,
                        markers: [...f.markers,newObj]
                    }      
                    setCurrentImageMarker(newMarker)
                    setCurrentMarker(newObj)
                    return newMarker
                }
                return f
            })
            let finalData = {
                scenes : array
            }
            setMarkersImageData(array)
            stateCallback(JSON.stringify(finalData))
            const viewer =ref?.current;
            viewer?.forceRender()
            click =null
        }
    }
    return (
        <div style={{background:"white",position:"relative"}}>
            <Pannellum
                ref={ref}
                width="100%"
                height="100%"
                image={`${process.env.REACT_APP_MEDIAS}/${currentImageMarker?.image?.filePath}`}
                pitch={0}
                yaw={0}
                hfov={110}
                autoLoad
                onMouseup={handle}
                onMousedown={(evt)=>click={x:evt.x,y:evt.y}}
            >
                {
                    currentImageMarker?.markers?.map((marker,index)=>{
                        return (
                            <Pannellum.Hotspot
                              key={index}
                              type={marker?.type}
                              pitch={marker?.pitch}
                              yaw={marker?.yaw}
                              text={`${marker?.text} ${marker?.image ?`<div><img style="width:150px;margin-top:5px;" src="${process.env.REACT_APP_MEDIAS}/${marker?.image?.filePath}" alt="${marker?.image?.alt}" /></div>`:""}`}
                              URL={marker?.link}
                            />
                        )
                    })
                }
            </Pannellum>
        </div>
    )
}

export default PanoVirtualTour;