import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, IconButton, Menu, MenuItem, Typography, Fade, makeStyles } from "@material-ui/core";
import OurButton from "../../../ui/button/Button";
import EditIcon from '@material-ui/icons/Edit';
import colors from "../../../../config/theme/colors";
import { useCallback } from "react";
import StatusInfo from "../../../ui/status-info/StatusInfo";
import DefaultImageCard from "../../../../assets/images/image-fake.png";
import styled from "styled-components";
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import OurMenu from "../../../ui/menu/Menu";

const ImageBox = styled(Box)`
  background-image: ${props => props.src ? `url(${props.origin}/${props.src})` : `url(${DefaultImageCard})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: ${props => props.src ? null : 'scale(2)'};
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const MenuItemCustom=styled(MenuItem)`
    color: ${colors.sinfinNewMenu.normal};
    svg{
        fill: ${colors.sinfinNewMenu.normal};
    }
`

const MenuCustom=styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`

const GridAbsolute=styled(Grid)`
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
`

const Wrapper=styled(Grid)`
    padding: 16px;
    height: 265px;
`

const useStyles = makeStyles((theme) => ({
  editIcon: {
      position: 'absolute',
      color: colors.black.regular,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 25,
      top: -10,
      right: 10,
      transition: 'all .5s',
      display: 'none',
  },
  menu: {
      position: 'absolute',
      top: 0,
      right: 0,
  },
  paper: {
      border: '1px solid #d3d4d5',
  }
}));

const CardSummary = ({
  summary,
  modifyButtonProps,
  data,
  ...props
}) => {

  /**
   * Get the dynamic properties datas of the object
   * @param {Object} obj - The breadcrumb object to get the properties from
   * @param {String} prop - The concatenated properties to get // ex: "node.product.sku"
   * @returns {any}
   */

  // const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const classes = useStyles();

  const editMenuOptions = [
    {
        label: props.textButtonSecondary,
        color: colors.blue.darker.hue300,
        icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        // icon: <EditIcon style={{ fill: colors.blue.lighter.hue300 }} />,
        action: (e) => {
            props.actionButtonSecond();
        }
    },
    {
        label: props.deleteButtonProps.text,
        color: colors.red.regular,
        icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
        action: (e) => {
            e.stopPropagation();
            props.deleteButtonProps.onClick()

        }
    }
]

  const getProp = useCallback((obj, prop) => {
    if (typeof obj === "undefined") return null;
    const breadcrumbs = prop.split(".");

    const check = breadcrumbs.reduce((prev, curr) => {
      if (typeof prev === "undefined") return null;
      return prev[curr];
    }, obj);

    const lastBreadcrumbData = breadcrumbs.reduce((prev, curr) => (prev ? prev[curr] : null), data.node); // data.node -> Source of real data

    if (check) return lastBreadcrumbData
    else return null;
  }, [summary])

  const getMediaOrigin = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // DEV
      return process.env.REACT_APP_MEDIAS;
    } else {
      // PROD
      return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS;
    }
  };
  return (
    <Wrapper container>
      <Grid item xs={6} style={{
        overflow: 'hidden',
        position: 'relative',
      }}>
        {/* Picture */}
        {/* <Image src={data.node.media ?? DefaultImageCard} alt={data.node.libelle} responsive /> */}
        <ImageBox origin={getMediaOrigin()} src={data?.node?.media?.filePath} /> {/* TODO: Ask for path */}
      </Grid>
      <Grid container xs={6} direction='column' justifyContent='space-between' style={{
        position: 'relative',
        paddingLeft: '21px',
      }}>

        <GridAbsolute>
          <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)}/>
        </GridAbsolute>
            
        <Grid item style={{
          marginTop: '20px',
        }}>
          {/* Title */}
          <Typography variant='h3' style={{ marginBottom: '12px', fontSize: '18px' }}>{data.node.libelle}</Typography>
          {/* Summary */}
          <Grid container>
            {summary.map(item => (
              <>
                <Grid item xs={8}>
                  <Typography variant='body1' style={{ color: colors.black.regular,fontSize: '13px' }}>{item.label}</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* INFO: ADD NEW VALUES ACCORDING THE SUMMARY PROP FORMAT */}

                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>{getProp(item, 'productLookBooks.edges')?.length}</Typography> {/* Number of look books products */}
                  {getProp(item, 'status') && ( /* Status */
                    getProp(item, 'status') === true ? <StatusInfo status={true} fontSize='13px' /> : <StatusInfo status={false} fontSize='13px' />
                  )}
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '36px' }}>
          {/* Buttons */}
          {/* Modify Button */}
          {modifyButtonProps && (
            <OurButton
              style={{
                width: '100%',
                height: '40px',
                margin: '0px'
              }}
              text={modifyButtonProps.text}
              icon={"edit"}
              color={colors.blue.darker.hue300}
              colorHover={colors.white}
              bgcolorhover={colors.blue.lighter.hue600}
              bgcolor={colors.blue.lighter.hue900}
              border={`1px solid ${colors.blue.darker.hue300}`}
              stopPropagation={true}
              onClick={() => modifyButtonProps.onClick(data.node)}
            />
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
}

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.array.isRequired,
  modifyButtonProps: PropTypes.objectOf({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
}

CardSummary.defaultProps = {
  title: 'Test',
  summary: [
    {
      label: 'Test',
      value: 'Test'
    },
    {
      label: 'Test',
      value: 'Test'
    },
  ],
  modifyButtonProps: {
    text: 'Test',
    onClick: () => { },
  },
}

export default React.memo(CardSummary);