import { gql } from 'apollo-boost';



export const GET_APPNOTIFICATION_BY_ID = gql`
query getAppNotification($id: ID!){
  appNotification(id:$id) {
    id
    asset{
      id
    }
    title
    message
    link
    createdAt
    scheduledAt
    sendedAt
    status
  }
}
`;

export const GET_APPNOTIFICATIONS = gql`
{
  appNotifications {
    edges{
      node{
        id
        asset{
          id
        }
        title
        message
        link
        createdAt
        scheduledAt
        sendedAt
        status
      }
    }
  }
}
`;



export const CREATE_APPNOTIFICATION = gql`
mutation createAppNotification($asset: String!, $title: String!, $message: String, $link: String, $scheduledAt: String!, $sendedAt: String, $status: String!, $appToken: [String]){
  createAppNotification(input:{asset: $asset, title: $title, message: $message, link: $link, scheduledAt: $scheduledAt, sendedAt: $sendedAt, status: $status, appToken: $appToken}) {
    appNotification{
      id
    }
  }
}
`;



export const UPDATE_APPNOTIFICATION = gql`
mutation updateAppNotification($id: ID!, $asset: String!, $title: String!, $message: String, $link: String, $scheduledAt: String!, $sendedAt: String, $status: String!){
  updateAppNotification(input:{id: $id, asset: $asset, title: $title, message: $message, link: $link, scheduledAt: $scheduledAt, sendedAt: $sendedAt, status: $status}) {
    appNotification{
      id
      asset{
        id
      }
      title
      message
      link
      createdAt
      scheduledAt
      sendedAt
      status
    }
  }
}
`;


export const DELETE_APPNOTIFICATION = gql`
mutation deleteAppNotification($id: ID!){
  deleteAppNotification(input:{id: $id}) {
    appNotification{
      id
    }
  }
}
`;