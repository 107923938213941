import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import colors from '../../../config/theme/colors';
import Button from '../button/Button';
import { Box, Typography } from '@material-ui/core';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import OurTypography from '../typography/Typography';
import CloseIcon from '@material-ui/icons/Close';

const DialogModal = (props) => {

    const dialogConfig = () => {
        switch (props.type) {
            case 'info':
                return {
                    icon: <InfoSharpIcon style={{ color: colors.blue.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.blue.regular,
                    colorhover: colors.blue.lighter.hue300,
                    textPrimary: 'Ok',
                    textSecondary: 'Annuler',
                }
            case 'edit':
                return {
                    icon: <InfoSharpIcon style={{ color: colors.blue.regularv2, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.blue.regularv2,
                    colorhover: colors.blue.lighter.hue300,
                    textPrimary: 'Modifier',
                    textSecondary: 'Annuler',
                }
            case 'delete':
                return {
                    icon: <InfoSharpIcon style={{ color: colors.red.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.red.regular,
                    colorhover: colors.red.lighter,
                    textPrimary: 'Supprimer',
                    textSecondary: 'Annuler',
                }
            case 'add':
                return {
                    icon: <InfoSharpIcon style={{ color: colors.green.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.green.regular,
                    colorhover: colors.green.lighter,
                    textPrimary: 'Ajouter',
                    textSecondary: 'Annuler',
                }
            case 'warning':
                return {
                    icon: <InfoSharpIcon style={{ color: colors.yellow.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.yellow.regular,
                    colorhover: colors.yellow.lighter,
                    textPrimary: 'Ok',
                    textSecondary: 'Annuler',
                }
            default:
                return {
                    icon: <InfoSharpIcon style={{ color: colors.blue.regular, fontSize: '40px', marginTop: '26px', marginLeft: '24px' }} />,
                    title: <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />,
                    color: colors.blue.regular,
                    colorhover: colors.blue.lighter.hue300,
                    textPrimary: 'Ok',
                    textSecondary: 'Annuler',
                }
        }
    }

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={props.onClose ?? null}
            // maxWidth={props.maxWidth}
            fullWidth={props.fullWidth}
            style={{ ...props.customStyle }}
            PaperProps={{
                style: {
                    display: 'flex',
                    flexDirection: 'row',
                    boxSizing: 'border-box',
                    borderLeft: props.noBorderLeft ? null :  `13px solid ${dialogConfig().color}`,
                    borderRadius: '0px',
                    width:'50%',
                    maxWidth:'none',
                    ...props.customStylePaper
                }
            }}
        >
            {props.icon ? (<Box>
                {dialogConfig().icon}
            </Box>) : null}
            <Box
                style={props?.containerStyle}
            >
                {
                    props.closeIcon &&
                    <Box style={{padding:"16px 24px 0 24px"}}>
                        <Typography style={{
                            cursor:"pointer",
                            color: "#000",
                            fontweight:"bold",
                            display:"flex",
                            gap:5,
                            fontSize:16,
                            fontweight:"bold",
                        }} onClick={props.onClose} ><CloseIcon fontSize="small"/> Fermer</Typography>
                    </Box>
                }
                <DialogTitle id="alert-dialog-title" style={{
                    paddingLeft: props.icon ? "20px" : null,
                }}>
                    <OurTypography fontweighttext={900} text={props.title} colortext={colors.black.regular} style={{ fontSize: '25px', lineHeight: '25px', marginTop: '10px', marginBottom: '-10px' }} />
                </DialogTitle>

                <DialogContent style={{
                    paddingLeft: props.icon ? "20px" : null,
                }}>
                    {
                        props.notText ?
                            props.children
                            :
                            <DialogContentText id="alert-dialog-description">
                                {props.children}
                            </DialogContentText>
                    }
                </DialogContent>

                <DialogActions style={{ padding: "8px 24px", justifyContent: 'flex-start', paddingLeft: props.icon ? "20px" : '24px', }}>
                    {
                        props.primaryAction ? (
                            <Button
                                onClick={props.primaryAction}
                                bgcolor={props.primarybgcolor ? props.primarybgcolor : dialogConfig().color}
                                bgcolorhover={props.primarybgcolorhover ? props.primarybgcolorhover : dialogConfig().color}
                                color={props.primarycolor ? props.primarycolor : colors.white}
                                colorhover={props.primarycolorhover ? props.primarycolorhover : dialogConfig().colorhover}
                                text={props.primaryText ? props.primaryText : dialogConfig().textPrimary}
                                disabled={props.primaryDisabled ? props.primaryDisabled : false}
                                autoFocus
                            />
                        ) : null
                    }
                    {
                        props.secondaryAction ? (
                            <Button
                                onClick={props.secondaryAction}
                                color={props.secondarycolor ? props.secondarycolor : colors.grey.regular}
                                colorhover={props.secondarycolorhover ? props.secondarycolorhover : colors.grey.regular}
                                bgcolor={props.secondarybgcolor ? props.secondarybgcolor : colors.white}
                                bgcolorhover={props.secondarybgcolorhover ? props.secondarybgcolorhover : colors.white}
                                border={props.secondaryborder ? props.secondaryborder : null}
                                text={props.secondaryText ? props.secondaryText : dialogConfig().textSecondary}
                            />
                        ) : null
                    }
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default DialogModal;