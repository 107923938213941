import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText, getDefaultTextarea, getImageDefault, getDefaultListImages } from '../../helpers';


export default {
    name: 'Pluie d\'objets',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        overlay: getImageDefault(null, 'Overlay'),
        list_lots:{
            title: "Liste des lots",
            name: "Liste des lots",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.LIST_IMAGES,
            // limit: 5,
            config: [
                getDefaultListImages(),
                getDefaultListImages(),
                getDefaultListImages(),
                getDefaultListImages(),
            ]
        },
        number_items:{
            type: FormInputTypes.NUMBER,
            name: "Nombre d'objets affichés",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.NUMBER,
                    value: 10
                }
            }
        },
        number_items_icone:getImageDefault(null, "Icone score"),
        time:{
            type: FormInputTypes.NUMBER,
            name: "Temps du jeu",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.NUMBER,
                    value: 10
                }
            }
        },
        time_icone:getImageDefault(null, "Icone timer", true, true),
        end_game:getImageDefault(null, "Fin du jeu gagné", true, true),
        end_game_loose:getImageDefault(null, "Fin du jeu perdu"),
        middleBox:{
            id: uuidv4(),
            title: "Bloc principal",
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks: {
                title: getDefaultText('Titre', "Es tu prêt ?", 50, "#53839D", null, 'uppercase', 'initial'),
                desc: getDefaultTextarea('Description du jeu', "Bref résumé du concept du jeu à suivre.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),
                button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
            }
        }
    }
};
