import React, { useEffect } from 'react';
import { Typography, Grid, Box, CircularProgress } from '@material-ui/core';
import Button from '../../ui/button/Button';
import colors from '../../../config/theme/colors';
import SearchBar from '../../ui/search/SearchBar';
import Stats from '../Stats/Stats';
import styled from 'styled-components';
import iconSet from "../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import imgNotFound from '../../../assets/images/not-found.png';
import { withTranslation } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash'
import PageLoader from '../../ui/loadings/page-loader/PageLoader';
import { useRef } from 'react';
import OurMenu from '../../ui/menu/Menu';

const BoxCustom = styled(Box)`
    background: ${colors.white};
`;
const LinkCustom = styled(Link)`
    color: ${colors.black.regular};
    font-size: 12px;
    text-decoration: none;
    &:hover{
        color: ${colors.blue.darker.hue300};
    }
`;
const SeparatorBread = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 12px;
    padding: 0 4px;
`;
const CurrentPage = styled(Typography)`
    color: ${colors.grey.border};
    font-size: 12px;
`;

let getImageType = (asset) => {
    const images = require.context('../../../assets/images', true);
    let image;
    try {
        image = images('./' + asset);
    } catch (e) {
        image = imgNotFound;
    }
    return image;
}

function TopPanel(props) {
    let img = getImageType(props.image);
    const ref = useRef()

    useEffect(()=>{
        if(props.getRef){
            props.setRef(ref.current.clientHeight)
        }
    },[ref?.current])

    return (
        <BoxCustom ref={ref} pt={props.inForm ? 2 : props.windowWidth < 1800 ? 3 : 4} pb={props.noResult ? 3 : props.windowWidth < 1600 ? 2 : props.windowWidth < 1800 ? 2 : 3} style={{ position: 'relative', paddingBottom: props.inForm ? 32 : 24, borderBottom: props.hasBorder ? `1px solid ${colors.grey.lighter.hue800}` : 'none' }} form={props.form}>
            {
                props.breadcrumb.length > 0 && !props.inForm ?
                    <Grid container direction='row'>
                        {
                            props.breadcrumb.map((item, index) => {
                                let newUrl = item.url
                                if (_.isEmpty(props.match?.params)) {
                                } else {
                                    for (const [key, value] of Object.entries(props.match.params)) {
                                        newUrl = newUrl.replaceAll(`:${key}`, value)
                                    }
                                }
                                if (index + 1 === props.breadcrumb.length) {
                                    return (
                                        <CurrentPage key={index}>{props.t(item.identifier)}</CurrentPage>
                                    )
                                } else {
                                    return (
                                        <React.Fragment key={index}>
                                            <LinkCustom to={newUrl} >{props.t(item.identifier)}</LinkCustom>
                                            <SeparatorBread>{'>'}</SeparatorBread>
                                        </React.Fragment>
                                    )
                                }

                            })
                        }
                    </Grid>
                    : null
            }

            <Grid container direction="row" justifyContent="center" spacing={0}>
                <Grid container direction="row" spacing={0} justifyContent="space-between" wrap='nowrap'>
                    <Grid item>
                        <Grid container direction="row" spacing={0} justifyContent="space-between">
                            <Grid container>
                                <Grid container direction="row">
                                    <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        {
                                            props.title|| props.subtitle?
                                            <>
                                                <Typography variant="h3" style={{ lineHeight: 1.2, fontWeight: props.strongText ? 'normal' : 'bold', fontSize: props.windowWidth <= 1000 ? 20 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 26 : 33 }}>{props.title} {props.strongText ? <strong>{props.strongText}</strong> : null}</Typography>
                                                <Typography variant="body2" component="div" style={{ color: colors.black.regular, marginTop: props.inForm ? 8 : 0 }}>
                                                    <Box style={{ fontSize: props.windowWidth <= 1000 ? 12 : props.windowWidth < 1400 && props.windowWidth > 1000 ? 13 : 14, color: props.inForm && colors.grey.lighter.hue600 }}>
                                                        {props.subtitle}
                                                    </Box>
                                                </Typography>
                                            </>
                                            :<Box py={1}><CircularProgress/></Box>
                                        }                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        (props.textDelete || props.textAdd || props.textImport || props.children || props.searchHandler || props.searchStatusHandler || props.handleLang)  ?
                            <Grid item>
                                <Grid container justifyContent={(props.textAdd || props.textImport || props.children) ? 'space-between' : 'flex-end'} alignItems="center" style={{ height: "100%" }}>
                                    {
                                        (props.textDelete || props.textAdd || props.textImport || props.children || props.searchHandler || props.searchStatusHandler || props.handleLang) ?
                                            (
                                                <Grid container>
                                                    <Grid container justifyContent='flex-end'>
                                                        {
                                                            props.children || props.textDelete || props.textAdd || props.textImport || props.searchStatusHandler || props.textThirdButton || props.textFourthButton ?
                                                                <Grid item style={{ display: 'flex', gap: 16, alignItems: props.alignItems ? "center" : "unset", height: '48px' }}>
                                                                    {
                                                                        props.textDelete ?
                                                                            (<Button style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%' }} text={props.textDelete} deleteIcon color={colors.red.regular} border={`1px solid ${colors.red.regular}`} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} onClick={props.handlerDelete} disabled={props.buttonAvailable === true ? props.openForm : true} /* disabled={props.buttonAvailable === true ? props.openForm : true} */ />)
                                                                            : ''
                                                                    }
                                                                    {
                                                                        props.textAdd ?
                                                                            (<Button style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%' }} text={props.textAdd} bgcolor={colors.green.regular} onClick={props.handlerAdd} shadowcolor={colors.green.darker} disabled={props.buttonAvailable === true ? props.openForm : true} />)
                                                                            : ''
                                                                    }
                                                                    {
                                                                        props.textImport ?
                                                                            (<Button style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%' }} color={props.colorImport ? props.colorImport : colors.green.regular} arrow={props.colorImport ? props.colorImport.arrow : null} text={props.textImport} bgcolor={props.colorImport ? props.colorImport.background : colors.green.lighterv2} onClick={props.handlerImport} bgcolorhover={colors.green.lighterv2Hover} disabled={props.buttonAvailable === true ? props.openForm : true} />)
                                                                            : ''
                                                                    }
                                                                    {
                                                                        props.textThirdButton ?
                                                                            (<Button style={{ margin: 0, padding: "14px 32px", boxSizing: 'border-box', height: '100%' }} arrow={props.colorThirdButton ? props.colorThirdButton.arrow : null} text={props.textThirdButton} bgcolor={props.colorThirdButton ? props.colorThirdButton.background : colors.green.regular} onClick={props.handlerThirdButton} shadowcolor={props.colorThirdButton ? props.colorThirdButton.shadow : colors.green.regular} disabled={props.buttonAvailable === true ? props.openForm : true} />) : ''
                                                                    }
                                                                    {
                                                                        props.children ? props.children : null
                                                                    }
                                                                </Grid>
                                                                : null
                                                        }
                                                        {/* <GridCustom item xs={12} style={{display: 'flex', alignItems:'center', flexWrap: 'wrap', justifyContent: 'flex-end'}} needreturn={props.secondSearchHandler && props.searchStatusHandler ? 1 : 0}>
                                                        { 
                                                            props.searchHandler ? 
                                                                (
                                                                    <Grid item xs={12} style={{marginTop: '10px', marginBottom: '10px', maxWidth: 250}}>
                                                                        <SearchBar style={props.searchStyle ? props.searchStyle : null} onChange={props.searchHandler} placeholder={props.searchPlaceholder}/>
                                                                    </Grid>
                                                                ) : ''
                                                        }
                                                        { 
                                                            props.secondSearchHandler ? 
                                                                (
                                                                    <Grid item xs={12} style={{marginLeft: props.secondSearchHandler ? 16 : 0, marginTop: '10px', marginBottom: '10px', maxWidth: 350}}>
                                                                        <SearchBar style={props.searchStyle ? props.searchStyle : null} onChange={props.secondSearchHandler} placeholder={props.secondSearchPlaceholder} id={"second-searchBar"}/>
                                                                    </Grid>
                                                                ) : ''
                                                        }
                                                        {
                                                            props.searchStatusHandler ?
                                                                <GridStatus item style={{marginLeft: 16, display: 'flex', alignItems:'center'}}>
                                                                    <div style={{marginRight: 16, width: '100%'}}>Recherche par status: </div>
                                                                    <CustomSelect
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={props.currentStatus}
                                                                        onChange={props.searchStatusHandler}
                                                                        variant="outlined"
                                                                    >
                                                                        {
                                                                            selectStatus.value.map((e, index) => {
                                                                                return(
                                                                                    <MenuItem value={e.value} key={`SearchStatus${index}`}>{e.label}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </CustomSelect>
                                                                </GridStatus>
                                                            : null
                                                        }
                                                        {
                                                            props.handleLang ?
                                                            <Grid item xs={12}>
                                                                <TraductionSelect 
                                                                    currentLang={props.currentLang} 
                                                                    handleLang={props.handleLang} 
                                                                    locales={props.locales}
                                                                />
                                                            </Grid>
                                                            : null
                                                        }
                                                    </GridCustom> */}
                                                    </Grid>
                                                </Grid>
                                            ) : ''
                                    }
                                </Grid>
                            </Grid>
                            : null
                    }
                </Grid>
            </Grid>
        </BoxCustom>
    );
}

const mapStateToProps = state => {
    return {
        breadcrumb: state.breadcrumb,
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, null)(TopPanel)));