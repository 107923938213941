import store from '../redux/store/index';

export function hasRights(mainSection, secondarySection, right,byRole=null) {
    if(byRole){
        let currentRole = store.getState().user?.userRole?.identifier;
        switch(byRole.operator){
            case '!=':
                return currentRole != byRole.role;
            default :
                return currentRole === byRole.role
        }

    } else{
        let allRights = store.getState().user?.rights
        return allRights?.[mainSection]?.[secondarySection]?.[right] ?? false        
    }

    
}