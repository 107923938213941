import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import Div from '../shared/div';
import Img from '../shared/img';
import Button from '../shared/a';
import styled from 'styled-components';

const DivBlocTextMedia = styled(Div)`
    padding: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        &>div{
            padding: 24px;
            dipslay: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            max-width: 50%;
        }
        &.tier{
            .blocText{
                width: 30%;
            } 
            .blocMedia{
                width: 70%;
                max-width: 70%;
                video{
                    max-width: 100%;
                }
            }
        }
    }
    .container-button{
        display: flex;
        justify-content: center;
        flex-direction: row;
        a{
            margin: 20px;
        }
    }
    h2{
        max-width: 100%;
        width: 600px;
        font-family: 'DINOffc-bold', sans-serif;
        margin-bottom: 16px;
        line-height: 1.2;
    }
    h4{
        max-width: 100%;
        width: 600px;
        line-height: 1.2;
        font-family: 'DinRegular', sans-serif;
        margin-bottom: 25px;
    }
    div{
        font-family: 'Univers',sans-serif;
        font-size: 1rem;
        line-height: 1.2;
        a{
            font-family: 'DINOffc-bold', sans-serif;
        }
        &.center{
            max-width: 100%;
            width: 600px;
            p{
                text-align: center;
                width: 100%;
            }
        }
    }
    p{
        max-width: 100%;
        width: 600px;
        font-size: 1.4375rem;
        line-height: 1.2;
        font-family: 'Univers', sans-serif;
    }
    @media screen and (max-width: 1250px){
        h2{
            font-size: 3rem;
            margin-bottom: 8px;
        }
        h4{
            font-size: 1.875rem;
            margin-bottom: 12px;
        }
        .container-button{
            a{
                margin: 10px;
                font-size: 1rem;
                line-height: 20px;
                padding: 8px 16px;
            }
        }
    }
    @media screen and (max-width: 1023px){
        padding: 20px 5%;
    }
    @media screen and (max-width: 1000px){
        &>div{
            display: flex;
            flex-direction: column;
            &>div{
                max-width: calc(100% - 12px);
            }
        }
    }
    @media screen and (max-width: 800px){
        div, p{
            font-size: 1.3125rem;
        }
    }
    &.fullWidth{
        padding: 0;
        .blocMedia{
            padding: 0;
            video{
                max-width: 100%;
            }
        }
        .blocText{
            padding: 36px 50px;
        }
    }
`;

const SliderBlocTextMedia = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    const vidRef = useRef(null);

    const handlePlayVideo = () => {
        if(vidRef.current){
            vidRef.current.play();
        }
    }

    useEffect(() => {
        handlePlayVideo();
    }, []);
    useEffect(() => {
        handlePlayVideo();
    }, [props]);

    const VIDEOS = ["video/mpeg","video/webm","video/mp4"];

    const initSliders = () => {
        new Swiper('.swiper-container-bloc-text-media', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-image',
            },
            navigation: {
                nextEl: '.swiper-button-next-image',
                prevEl: '.swiper-button-prev-image',
            },
            autoHeight: true,
            calculateHeight:true,
            spaceBetween: 10,
            slidesPerView: 1
        });
    };
    
    useEffect(() => {
        initSliders();
    }, []);

    useEffect(() => {
        initSliders();
    }, [props]);

    return (
        <Div style={{ position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="swiper-container swiper-container-bloc-text-media">
                <div className="swiper-wrapper">
                    {
                        data.blocks.slides.config.map((slide, index) => (
                            <Div className="swiper-slide w100" key={index}>
                                <Div 
                                    style={{
                                        backgroundColor: slide.inputs['backgroundColor'].value,
                                        backgroundImage: typeof slide.inputs['backgroundImage'].value === 'string' ? `url(${slide.inputs['backgroundImage'].value})` : slide.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${slide.inputs['backgroundImage'].value.filePath})` : 'none',
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        position: 'relative',
                                        width: '100%'
                                    }} 
                                >
                                    <DivBlocTextMedia className={slide.inputs.imageFull.value ? 'fullWidth' : null }>
                                        <Div className={slide.inputs.widthMedia.value ? slide.inputs.widthMedia.value : null} style={{flexDirection: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? 'column' : null, maxWidth: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? 1040 : null}}>
                                            <Div className="blocText" style={{order: slide.inputs['positionMedia'].value === 'right' || slide.inputs['positionMedia'].value === 'bottom' ? '0' : '2', maxWidth: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? '100%' : null, width: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? '100%' : null}}>
                                                {
                                                    slide.blocks.title.inputs.value.value ? (
                                                        <H2 
                                                            color={slide.blocks.title.inputs.color.value}
                                                            fontSize={slide.blocks.title.inputs.fontSize.value}
                                                            textTransform={slide.blocks.title.inputs.textTransform.value}
                                                            style={{textAlign: slide.inputs['centerText']?.value ? 'center' : null, marginLeft: slide.inputs['centerText']?.value ? 'auto' : null, marginRight: slide.inputs['centerText']?.value ? 'auto' : null}}
                                                        >
                                                            {slide.blocks.title.inputs.value.value}
                                                        </H2>
                                                    ) : null
                                                }
                                                {slide.blocks.subtitle.inputs.value.value ? (
                                                    <H4 
                                                        color={slide.blocks.subtitle.inputs.color.value}
                                                        fontSize={slide.blocks.subtitle.inputs.fontSize.value}
                                                        textTransform={slide.blocks.subtitle.inputs.textTransform.value}
                                                        style={{textAlign: slide.inputs['centerText']?.value ? 'center' : null, marginLeft: slide.inputs['centerText']?.value ? 'auto' : null, marginRight: slide.inputs['centerText']?.value ? 'auto' : null}}
                                                    >
                                                        {slide.blocks.subtitle.inputs.value.value}
                                                    </H4>
                                                ) : null}
                                                {
                                                    slide.blocks.text.inputs.value.value ? (
                                                        <Div
                                                            className={slide.inputs['centerText']?.value ? 'center' : null}
                                                            color={slide.blocks.text.inputs.color.value}
                                                            style={{textAlign: slide.inputs['centerText']?.value ? 'center' : null, marginLeft: slide.inputs['centerText']?.value ? 'auto' : null, marginRight: slide.inputs['centerText']?.value ? 'auto' : null, color: slide.blocks.text.inputs.color.value}}
                                                            dangerouslySetInnerHTML={{__html: slide.blocks.text.inputs.value.value}}
                                                        />
                                                    ) : null
                                                }
                                                <Div className="container-button" style={{justifyContent: slide.inputs?.centerButton?.value ? 'center' : 'flex-start'}}>
                                                    {
                                                        slide.blocks?.link?.config?.map((link, index) => (
                                                            <Button 
                                                                href={link.inputs.link?.value}
                                                                aria-label={`${slide.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`} title={`${slide.blocks.title.inputs.value?.value} ${link.inputs.value?.value}`}
                                                                backgroundColor={link.inputs.backgroundColor?.value} 
                                                                color={link.inputs.color?.value}
                                                                borderWidth={link.inputs.border?.inputs.borderWidth?.value}
                                                                borderColor={link.inputs.border?.inputs.borderColor?.value}
                                                                borderRadius={link.inputs.border?.inputs.borderRadius?.value}
                                                                className="little"
                                                                style={{marginLeft: !slide.inputs?.centerButton?.value ? 0 : null}}
                                                            >
                                                                {link.inputs.value?.value}
                                                            </Button>
                                                        ))
                                                    }
                                                </Div>
                                            </Div>
                                            {
                                                slide.inputs['showMedia'].value ? (
                                                    <Div className="blocMedia" style={{order: '1', maxWidth: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? '100%' : null, width: slide.inputs['positionMedia'].value === 'top' || slide.inputs['positionMedia'].value === 'bottom' ? '100%' : null}}>
                                                        {
                                                            VIDEOS.includes(slide.blocks.media.inputs.value.value.type) ? (
                                                                <video autoPlay={true} muted={true} loop={true} ref={vidRef}>
                                                                    <source src={`${process.env.REACT_APP_MEDIAS}/${slide.blocks.media.inputs.value.value.filePath}`} type="video/mp4"/>
                                                                </video>
                                                            ) : (
                                                                <Img 
                                                                    className="w100" 
                                                                    src={
                                                                        typeof slide.blocks.media.inputs.value.value === 'string' ? 
                                                                            slide.blocks.media.inputs.value.value 
                                                                        : slide.blocks.media.inputs.value.value.filePath ? 
                                                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.media.inputs.value.value.filePath}` 
                                                                        : null
                                                                    } 
                                                                    alt={slide.blocks.media.inputs?.alt?.value ?? "Illustration"}
                                                                />
                                                            )
                                                        }
                                                    </Div>
                                                ) : null
                                            }
                                        </Div>
                                    </DivBlocTextMedia>
                                </Div>
                            </Div>
                        ))
                    }
                </div>
                <div className="swiper-scrollbar swiper-scrollbar-image"></div>
                <div className="swiper-button-prev swiper-button-prev-image"></div>
                <div className="swiper-button-next swiper-button-next-image"></div>
            </Div>
        </Div>
    );
};

export default SliderBlocTextMedia;