import styled from 'styled-components';

export default styled.h5`
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    font-family: 'DinRegular', sans-serif;
    color: ${props => props.color || '#000'};
    text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
    @media screen and (min-width: 1024px){
        font-size: 1.5625rem;
    }
    &.center{
        text-align: center;
    }
    &.uppercase{
        text-transform: uppercase;
    }
    &.white{
        color: white;
    }
    &.din-offc{
        font-size: 1.5rem;
        line-height: 1.2;
    }
`; 