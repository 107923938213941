import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import Button from '../../../../shared/components/CustomButton';
import { LinkCustom } from "../_next/navigation";
import LogoFatherSmall from './images/logo-fathers-day-217px.png';

const LinkBanner = ({
    preview = false,
    id = null, 
    data = null,
    inputCallback = null,
    children = null,
    spread = false
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        backgroundColor: '#ccc',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box'
    };

    let imageBackground = data.blocks["image"].inputs.image?.value;
    let category = data.inputs?.category;

    return (
        <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children }

            <div className="link-banner">
                <div 
                    className="image"  
                    key={`imageBackground`}
                    style={{ backgroundImage: `url(${typeof imageBackground === 'string' ? imageBackground : `${process.env.REACT_APP_MEDIAS}/${imageBackground.filePath}`})` }}
                >
                    <img className="logo_small" src={LogoFatherSmall} alt="logo fête des pères petit" />
                    <div className="text">
                        <div className="title">
                            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                        </div>
                        <div className="button">
                            <LinkCustom
                                spread={spread}
                                href="/category/[slug]" 
                                as={`/category/${category?.value?.replace('/api/categories/', '')}`}
                            >
                                <a>
                                    <Button 
                                        data={data}
                                        preview={preview} 
                                        component={id} 
                                        block={'button'} 
                                        inputCallback={inputCallback} 
                                    />
                                </a>
                            </LinkCustom>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LinkBanner;