import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../../layouts/Card/CardCustom';
import SettingsIcon from '@material-ui/icons/Settings';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import GetAppIcon from '@material-ui/icons/GetApp';
import Equalizer from '@material-ui/icons/Equalizer';
import CardCatalog from '../../../layouts/Card/cardContent/CardCatalog';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import imgFake from '../../../../assets/images/image-fake.png';
import * as moment from 'moment';
import pictoExternalLink from '../../../../assets/pictos/out.svg';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import { EPHEMERAL_ASSET } from '../../../../js/constants/assets-types';
import AccordionCustom from '../../Accordion/AccordionCustom';
import Button from '../../../ui/button/Button';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';

import { GET_ASSET_COUNT_DETAILS_BY_ID } from '../../../../queries/assets';
import { client } from '../../../../index'
import TextEllipsis from './TextEllipsis';
import OurTypography from '../../../ui/typography/Typography';


const GridCatalog = styled(Grid)`
    height: 100%;
`;
const ContainerStyled = styled(Grid)`
    padding-bottom: 0.5rem;
`;
const AssetDate = styled(Typography)`
    color: ${colors.grey.regular};
    text-transform: uppercase;
    margin-left: ${props => props.marginleft};
`;
const Description = styled(Grid)`
    color: ${props => props.colortext ? props.colortext + ' !important' : ''};
    p{
        font-size: 13px;
        line-height: 17px;
        margin: 0;
        color: ${props => props.colortext ? props.colortext + ' !important' : ''};
    }
`;
const AssetItemImage = styled(Grid)`
    background: url(${props => props.backgroundimage});
    height: 100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    transition:all .5s;
    @media screen and (max-width: 1000px){
        min-height: 400px;
    }
`;
const TypographyCustom = styled(Typography)`
    color: ${colors.black.regular};
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    a{
        color: ${colors.blue.darker.hue300};
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
    }
`;
const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const Link = styled.a`
    color: ${colors.blue.darker.hue300};
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover{
        opacity: 0.8;
    }
`;
const ChipAssets = styled(Box)`
    border-radius: 16px;
    background: ${props => props.typeasset === 'permanent' ? colors.blue.darker.hue300 : colors.purple.regular};
    padding: 6px 24px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    p{
        font-size: 14px;
        font-weight: bold;
    }
`;
const StatusBox = styled(Box)`
    background: ${props => props.status ? colors.green.regular : '#CF3657'};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
`;

const WrapperAttr = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 200px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`
const WrapperActions = styled(Grid)`
    &:hover{
        opacity: 0.8;
    }
`



export default class CardProduct extends React.Component {
    state = {
        nbAssetFooter: "N/A",
        nbAssetMagentoPages: "N/A",
        nbCrossSells: "N/A",
        nbInfoManagement: "N/A",
        nbMerchandisings: "N/A",
        displayPreview: false
    };
    getCounts() {
        let variables = {
            "id": this.props.allState.assetData.id,
        };
        client.query({
            query: GET_ASSET_COUNT_DETAILS_BY_ID,
            variables,
            fetchPolicy: 'no-cache'
        }).then(async result => {

            let nbAssetFooter = result.data.asset.assetFooter.totalCount;
            let nbAssetMagentoPages = result.data.asset.assetMagentoPages.totalCount;
            let nbCrossSells = result.data.asset.crossSells.totalCount;
            let nbInfoManagement = result.data.asset.infoManagement.totalCount;
            let nbMerchandisings = result.data.asset.merchandisings.totalCount;

            this.setState({
                nbAssetFooter,
                nbAssetMagentoPages,
                nbCrossSells,
                nbInfoManagement,
                nbMerchandisings
            });

        });
    }
    componentDidMount() {
        fetch(`${process.env.REACT_APP_MEDIAS}/home-${this.props.allState.idAsset.replace('/api/assets/', '')}.png`, { method: 'HEAD' })
        .then(res => {
            this.setState({displayPreview: true})
        })
        .catch(err => this.setState({displayPreview: false}))
        this.getCounts();
    }

    render() {
        const asset = this.props.asset;
        const goTo = this.props.routeAsset;
        const goToStats = this.props.routeStats;

        let values = [];
        let image = '';
        if (this.props.locales) {

            for (let locale of this.props.locales) {
                values.push({
                    code: locale.node.code,
                    name: this.props.allState[locale.node.code]?.asset_store_name ?? null,
                    description: this.props.allState[locale.node.code]?.asset_store_description ?? null,
                    images: this.props.allState[locale.node.code]?.asset_store_image ?? null,
                    ean: this.props.allState[locale.node.code]?.asset_ean ?? null,
                });
            }
        }

        let getImageType = (asset) => {
            const images = require.context('../../../../assets/images', true);
            let image;
            try {
                image = images('./' + asset);
            } catch (e) {
                image = imgFake;
            }

            return image;
        }

        let defaultName = getTraductionAttributs('asset_store_name', this.props.allState.assetData.assetDatas.edges, this.props.currentLang);
        const defaultDesc = values.find(e => e.description !== '<p><br></p>');

        const valueName = values.find(e => e.code === this.props.currentLang);
        const valueDesc = values.find(e => e.code === this.props.currentLang && e.description !== '<p><br></p>');

        let getImagesChanged = values.find(e => e.code === this.props.currentLang);
        let defaultImage = ImgNotFound;
        let previewImage = getImagesChanged.images?.filePath || getImagesChanged.images?.data

        image = previewImage
            ? `${process.env.REACT_APP_MEDIAS}/${previewImage}`
            : defaultImage
                ? defaultImage ?? ''
                : getImageType(this.props.allState.assetData.assetType.logo);

        let local = moment.tz.guess();
        let toDateStart = null;
        let toDateEnd = null;
        if (asset.paramStartAt) {
            toDateStart = moment.tz(asset.paramStartAt, local).format('DD/MM/YYYY HH:mm');
        }
        if (asset.paramEndAt) {
            toDateEnd = moment.tz(asset.paramEndAt, local).format('DD/MM/YYYY HH:mm');
        }
        let prodUrl = asset.prodUrl;
        let locales = asset.selectedLocales;
        let assetsSelected = asset.assetsSelected;
        return (
            <Grid container spacing={2}>
                <Grid item lg={6} xs={6}>
                <AccordionCustom style={{height:"100%"}} forcedExpanded={true} title={<TypographyCustom variant="h5">{EPHEMERAL_ASSET.indexOf(this.props.allState.assetData.assetType.identifier) > -1 ? 'Asset éphémère' : 'Asset permanent'}</TypographyCustom>}>
                            <Grid container direction='row' wrap='wrap' spacing={2} style={{ margin: 0, width: "100%" }}>
                                {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <AssetItemImage
                                        backgroundimage={typeof image === "string" ? image : image?.default}
                                    />
                                </Grid> */}
                                <Grid item sm={12} xs={12}>
                                    <Box px={2}>
                                        <ContainerStyled
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems={'flex-start'}
                                        >
                                            <Box pb={2} style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%" }}>
                                                <Grid container xs={12}>
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Box>
                                                            <Grid container alignItems='center'>
                                                                <Typography variant="h4" component="div" fontweighttext={'bold'} style={{ paddingRight: 16, fontSize: 25, lineHeight: '21px' }}>
                                                                    {valueName?.name ?? defaultName ?? null}
                                                                </Typography>
                                                            </Grid>
                                                        </Box>
                                                        <Box mt={1}>
                                                            <Description container alignItems='center' colortext={colors.grey.darker}>
                                                                <TextEllipsis title={valueName?.name ?? defaultName ?? null} text={valueDesc?.description ?? defaultDesc?.description ?? null} maxLine={2} hasModal={true} />
                                                            </Description>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box pb={2} mt={2} style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%" }}>
                                                <WrapperAttr container>
                                                    <Typography variant="body1">
                                                        Statut :
                                                    </Typography>
                                                    <StatusInfo status={asset.paramStatus} width={'auto'} />
                                                </WrapperAttr>
                                                {
                                                    locales?.length > 0 ?
                                                        <WrapperAttr container>
                                                            <Typography>{locales?.length > 1 ? 'Langues' : 'Langue'} :</Typography>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    {
                                                                        locales.map((locale, index) => {
                                                                            let getLibelle = this.props.locales.find(e => e.node.id === locale)
                                                                            if ((index + 1) === locales.length) {
                                                                                getLibelle = getLibelle.node.libelle
                                                                            } else {
                                                                                getLibelle = getLibelle.node.libelle + ', '
                                                                            }
                                                                            return getLibelle
                                                                        })
                                                                    }
                                                                </strong>
                                                            </Typography>
                                                        </WrapperAttr>
                                                        : null
                                                }
                                                {
                                                    this.props.allState.assetData.channel ?
                                                        <WrapperAttr container>
                                                            <Typography>Channel :</Typography>
                                                            <Typography variant="body1"><strong>{this.props.allState.assetData.channel.libelle}</strong></Typography>
                                                        </WrapperAttr>
                                                        : null
                                                }
                                                <WrapperAttr container>
                                                    <Typography>État de la synchronisation :</Typography>
                                                    <Typography variant="body1"><strong>À jour</strong></Typography>
                                                </WrapperAttr>
                                                {
                                                    prodUrl ?
                                                        <WrapperAttr container>
                                                            <Typography>URL de production :</Typography>
                                                            <Typography variant="body1">
                                                                <strong>
                                                                    <Link href={prodUrl} target="_blank">
                                                                        {prodUrl}
                                                                        <img src={pictoExternalLink} alt="Voir le site" style={{ display: 'inline-block', height: 13, width: 13, marginLeft: 10 }} />
                                                                    </Link>
                                                                </strong>
                                                            </Typography>
                                                        </WrapperAttr>
                                                        : null
                                                }
                                                {
                                                    toDateStart ?
                                                        <WrapperAttr container>
                                                            <Typography>Date de début :</Typography>
                                                            <Typography variant="body1"><strong>{toDateStart}</strong></Typography>
                                                        </WrapperAttr>
                                                        : null
                                                }
                                                {
                                                    toDateEnd ?
                                                        <WrapperAttr container>
                                                            <Typography>Date de fin :</Typography>
                                                            <Typography variant="body1"><strong>{toDateEnd}</strong></Typography>
                                                        </WrapperAttr>
                                                        : null
                                                }
                                                {
                                                    assetsSelected?.length > 0 ?
                                                        <Grid item xs={12}>
                                                            <Grid container alignItems="flex-start" direction="column">
                                                                <Box mt={3} style={{ width: '100%' }}>
                                                                    <Typography variant="h5" component="div" colortext={colors.black.regular} fontweighttext={'bold'} style={{ marginBottom: '0.5rem' }}>
                                                                        {assetsSelected?.length > 1 ? 'Widgets' : 'Widget'}
                                                                    </Typography>
                                                                    <Grid container>
                                                                        {
                                                                            assetsSelected.map((asset) => {
                                                                                return (
                                                                                    <ChipAssets mt={1} typeasset={EPHEMERAL_ASSET.indexOf(asset.node.assetType.identifier) > -1 ? 'ephemeral' : 'permanent'}>
                                                                                        <StatusBox status={asset.node.status} />
                                                                                        <Typography colortext={colors.white}>{asset.node.assetType.libelle} {asset.node.assetGameType ? `- ${asset.node.assetGameType.libelle}` : null}</Typography>
                                                                                    </ChipAssets>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        : null
                                                }
                                            </Box>
                                            <Box pb={2} mt={2} style={{ width: "100%" }}>
                                                <WrapperAttr container>
                                                    <Typography>Landing en cours :</Typography>
                                                    <Typography variant="body1"><strong>{this.state.nbAssetMagentoPages}</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Merchandising en cours :</Typography>
                                                    <Typography variant="body1"><strong>{this.state.nbMerchandisings}</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Promotion en cours :</Typography>
                                                    <Typography variant="body1"><strong>N/A</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Cross sell en cours :</Typography>
                                                    <Typography variant="body1"><strong>{this.state.nbCrossSells}</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Publicités en cours :</Typography>
                                                    <Typography variant="body1"><strong>{this.state.nbInfoManagement}</strong></Typography>
                                                </WrapperAttr>
                                                <WrapperAttr container>
                                                    <Typography>Nombre de footer liée :</Typography>
                                                    <Typography variant="body1"><strong>{this.state.nbAssetFooter}</strong></Typography>
                                                </WrapperAttr>
                                            </Box>
                                        </ContainerStyled>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} style={{ width: '100%' }}>
                                    <Grid container xs={12} md={10} justifyContent='center' style={{ margin: 'auto' }}>
                                        <Grid direction="row" container style={{ marginTop: 8, gap: 8 }}>
                                            {
                                                this.props.allState.assetData.assetGameType ?
                                                    <>
                                                        {
                                                            this.props.toggleDrawerGifts ?
                                                                <WrapperActions item style={{ flex: 1 }}>
                                                                    <Button style={{ margin: 0, padding: "14px 32px", width: '100%' }} text={'Cadeaux'} color={colors.blue.darker.hue300} colorHover={colors.white} bgcolorhover={colors.blue.lighter.hue600} bgcolor={colors.blue.lighter.hue900} border={`1px solid ${colors.blue.darker.hue300}`} onClick={this.props.ready ? this.props.toggleDrawerGifts : null} disabled={!this.props.ready} />
                                                                </WrapperActions>
                                                                : null
                                                        }
                                                        {
                                                            this.props.toggleImportInstants ?
                                                                <WrapperActions item style={{ flex: 1 }}>
                                                                    <Button style={{ margin: 0, padding: "14px 32px", width: '100%' }} text={'Import. instants'} color={colors.blue.darker.hue300} colorHover={colors.white} bgcolorhover={colors.blue.lighter.hue600} bgcolor={colors.blue.lighter.hue900} border={`1px solid ${colors.blue.darker.hue300}`} onClick={this.props.ready ? this.props.toggleImportInstants : null} disabled={!this.props.ready} />
                                                                </WrapperActions>
                                                                : null
                                                        }
                                                    </>
                                                    : null
                                            }
                                            {/* {
                                                this.props.allState.assetData.assetGameType || this.props.allState.assetData.assetType.identifier === 'flipbook' || this.props.allState.assetData.assetType.identifier === "flipbook_with_cart" || this.props.allState.assetData.assetType.identifier === 'image_recognizer' ?
                                                    <WrapperActions item>
                                                        <Button style={{margin: 0, padding: "14px 32px"}} text={'Stats.'} color={colors.blue.darker.hue300} colorHover={colors.white} bgcolorhover={colors.blue.lighter.hue600} bgcolor={colors.blue.lighter.hue900} border={`1px solid ${colors.blue.darker.hue300}`} onClick={this.props.ready ? this.props.goToStats : null} shadowcolor={colors.green.darker} disabled={!this.props.ready}/>
                                                    </WrapperActions> 
                                                : null
                                            } */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionCustom>
                </Grid>
                <Grid item lg={6} xs={6}>
                <AccordionCustom forcedExpanded={true} title={<TypographyCustom variant="h5" component="div">Catalogue</TypographyCustom>} style={{height: "100%"}}>
                            {
                                this.props.allState.catalogAsset ?
                                    <Grid container alignItems="flex-start" direction="column" style={{ height: "100%" }}>
                                        <Box style={{ width: '100%' }}>
                                            <Grid container justifyContent={'center'}>
                                                <GridCatalog container alignItems={'flex-start'} justifyContent={'flex-start'} direction="column">
                                                    <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0'}>
                                                        <CardCatalog
                                                            data={this.props.allState.catalogAsset}
                                                            textButton={"Voir le catalogue"}
                                                            windowWidth={this.props.windowWidth}
                                                            openForm={true}
                                                        />
                                                    </CardCustom>
                                                </GridCatalog>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    : <PageLoader />
                            }
                        </AccordionCustom>
                </Grid>
                <Grid item xs={12}>                    
                {
                    this.props.allState.retailerAsset?.node ?
                    <AccordionCustom forcedExpanded={true} title={<TypographyCustom variant="h5" component="div">Flux de marque</TypographyCustom>} style={{height: "100%"}}>
                        <Grid container alignItems="flex-start" direction="column" style={{ height: "100%" }}>
                            <Box style={{ width: '100%', height: "100%" }}>
                                <Grid container justifyContent={'center'} style={{ height: "100%" }}>
                                    <GridCatalog container alignItems={'flex-start'} justifyContent={'flex-start'} direction="column" style={{ height: "100%" }}>
                                        <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%", height: "100%" }} contentpadding={'0'}>
                                            {/* <CardSetup
                                                type={this.props.allState.retailerAsset}
                                                configCard={true}
                                            /> */}
                                            <Grid container alignItems='center' justifyContent={this.props.windowWidth <= 1250 ? 'space-between' : 'flex-start'} style={{ padding: '25px 42px', gap: '43px' }}>
                                                <Grid item>
                                                    <StatusInfo status={this.props.allState.retailerAsset.node.assets?.edges.length > 0 ? true : false} width={'auto'} fontSize={14} />
                                                </Grid>
                                                <Grid item>
                                                    <OurTypography text={this.props.allState.retailerAsset.node.libelle} fontweighttext={'bold'} style={{
                                                        fontSize: 16,
                                                    }} />
                                                </Grid>
                                            </Grid>
                                        </CardCustom>
                                    </GridCatalog>
                                </Grid>
                            </Box>
                        </Grid>
                    </AccordionCustom>
                    : null
                }
                </Grid>
            </Grid>
        );
    }
}