import TopPanel from "../../../layouts/TopPanel/TopPanel"
import { withApollo } from 'react-apollo';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import colors from "../../../../config/theme/colors";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import { withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LayoutFormBuilder from "../../../ui/form/LayoutFormBuilder";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types";
import { eventService } from "../../../../js/services/event.service";
import * as formHelper from '../../../../js/helpers/form'
import formBundle from "./config/formBundleAdd.config";
import { GET_CATEGORIES_FLAT, GET_CATEGORIES_LIGHT_2 } from "../../../../queries/categories";
import { ADD_PRODUCT, ADD_PRODUCT_DATA, DELETE_PRODUCT, DELETE_PRODUCT_DATA, GET_ONE_PRODUCT_DATAS, GET_PRODUCTS_BY_SKU_LIGHT, UPDATE_PRODUCT, UPDATE_PRODUCT_DATA } from "../../../../queries/products";
import ListingProducts from "./components/ListingProducts";
import moment from "moment";
import { createProductBundleOption, createProductBundleOptionProduct, deleteProductBundleOption, deleteProductBundleOptionProduct, updateProductBundleOption, updateProductBundleOptionProduct } from "../../../../queries/bundle-products";
import { ContactlessOutlined } from "@material-ui/icons";
import { GET_MEDIA_CATEGORIES, GET_MEDIA_OBJECTS_CATEGORY } from "../../../../queries/mediaCategories";
import request from '../../../../js/utils/fetch';
import DialogModal from "../../../ui/dialog/DialogModal";
import OurTypography from "../../../ui/typography/Typography";

const ListBundles = props =>{
    const [openForm,setOpenForm]= useState(false)
    const [openDialog,setOpenDialog]= useState(false)
    const [formType,setFormType]= useState("add")
    const [currentProduct,setCurrentProduct]= useState(null)
    const [idToDelete,setIdToDelete]= useState(null)
    const [errors,setErrors]= useState({})
    const [listProducts,setListProducts]= useState([])
    const [reload,setReload]= useState(false)
    const [mediaCategories,setMediaCategories]= useState([])
    const [metaAttributes,setMetaAttributes]= useState([])
    const [oldState,setOldState]= useState({
        
    })
    const [state,setState]= useState({
        selectedAreLoad:false,
        currentLang: props.locales[0].node.code,
        bundleImage:"",
        bundleName: null,
        bundlePrice:"",
        bundleSku:"",
        bundleDesc:"",
        categoriesSelected:[],
        errors:{},
        categories:[],
        isVariant:false,
        allGroups: props.attributeGroups?.filter(e => (!e.node.isSystem) || e.node.identifier === 'default'),
        customAttributes:props.attributes.product.attributes.edges.filter(e => !e.node.isSystem || e.node.identifier === 'product_metatitle' || e.node.identifier === 'product_metadesc'),
        imageAttributes:props.attributes.product.attributes.edges.filter(e => e.node.attributeType.input === 'image'),
        isSystemAttributes:[],
        groupAttribut:null,
        mediaCategories:[],
        selectedMediaCategories:[],
        attributes:[],
        selectedProducts:[],
        selectedProductsconfig:[],
        convert:false,
        typeTesting: {
            type: 'product',
            testingState: ['bundleSku'],
            testingTypingState: 'bundleSku',
            identifierState: 'bundleSku'
        },
    })
    let typingTimer = null

    const handleToggleDrawer = async (type,product,current) => {
        setFormType(type)
        if (type === "add"||!type) {
            setOpenForm(!openForm)
        }else if (type === "edit") {
            const currentProduct = listProducts.find(e=>e.node.id === product.id)
            setCurrentProduct(currentProduct)
            const attributesData = await prepareAttributes(currentProduct)
            let categories = []
         
            let selectedProducts = []
            let selectedProductsconfig = []
            currentProduct.node.productCategories.edges.forEach(element => {
                categories.push(state.categories.find(e=>e.id === element.node.category.id))
            });
            let mediaCat
            if (currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "categorie_de_media")) {
                mediaCat = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "categorie_de_media")?.node?.mediaObjectCategory?.id?? null
            } 
            currentProduct.node.productBundleOptions.edges.forEach(async (element,i) => {
                const obj = {
                    node:element.node.productRelations.edges[0]?.node?.product?.parent??element.node.productRelations.edges[0]?.node?.product
                }
                const data = await getProductData(obj.node?.sku,element)
                data.title = element.node.title
                data.isRequired = element.node.isRequired
                data.inputType = element.node.inputType
                data.id = obj.node?.id
                data.bundleId = element.node.id
                data.node = obj.node
                selectedProducts.push(data)
                selectedProductsconfig.push(data)
            });
            setState({
                ...state,
                isActive: currentProduct.node.status,
                bundleImage:product.image,
                bundleName: product.name,
                bundlePrice:currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node.value?? "",
                bundleSku:product.sku,
                bundleDesc:currentProduct?.node?.productDatas.edges.find(e=>e.node.attribute.identifier === "product_description")?.node.value?? "",
                categoriesSelected:categories,
                selectedProducts:selectedProducts,
                selectedProductsconfig:selectedProductsconfig,
                groupAttribut:currentProduct.node.attributeGroup.id,
                selectedMediaCategories:mediaCat,
                ...attributesData
            })
            setOldState({
                ...state,
                bundleImage:product.image,
                bundleName: product.name,
                isActive: currentProduct.node.status,
                bundlePrice:currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node.value?? "",
                bundleSku:product.sku,
                bundleDesc:currentProduct?.node?.productDatas.edges.find(e=>e.node.attribute.identifier === "product_description")?.node.value?? "",
                categoriesSelected:categories,
                selectedProducts:selectedProducts,
                selectedProductsconfig:selectedProductsconfig,
                groupAttribut:currentProduct.node.attributeGroup.id,
                selectedMediaCategories:mediaCat,
                ...attributesData
            })
            setOpenForm(!openForm)
        }else if ("convert") {
            setCurrentProduct(current)
            const attributesData = await prepareAttributes(current,"convert")
            let categories = []
         
            let selectedProducts = []
            let selectedProductsconfig = []
            current.node.productCategories.edges.forEach(element => {
                categories.push(state.categories.find(e=>e.id === element.node.category.id))
            });
            current.node.productBundleOptions.edges.forEach(async element => {
                const obj = {
                    node:element.node.productRelations.edges[0]?.node?.product?.parent??element.node.productRelations.edges[0]?.node?.product
                }
                const data = await getProductData(obj.node.sku,element)
                data.title = element.node.title
                data.isRequired = element.node.isRequired
                data.inputType = element.node.inputType
                data.id = obj.node.id
                data.bundleId = element.node.id
                data.node = obj.node
                selectedProducts.push(data)
                selectedProductsconfig.push(data)
            }); 
            let productInfos = await {...current.node.flatProducts.edges[0].node,id:current.node.id}
            setState({
                ...state,
                bundleImage:productInfos.image,
                bundleName: productInfos.name,
                bundlePrice:current.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node.value?? "",
                bundleSku:productInfos.sku,
                bundleDesc:currentProduct?.node?.productDatas.edges.find(e=>e.node.attribute.identifier === "product_description")?.node.value?? "",
                categoriesSelected:categories,
                selectedProducts:selectedProducts,
                selectedProductsconfig:selectedProductsconfig,
                groupAttribut:current.node.attributeGroup.id,
                convert:true,
                ...attributesData
            })
            setOldState({
                ...state,
                bundleImage:productInfos.image,
                bundleName: productInfos.name,
                bundlePrice:current.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node.value?? "",
                bundleSku:productInfos.sku,
                bundleDesc:currentProduct?.node?.productDatas.edges.find(e=>e.node.attribute.identifier === "product_description")?.node.value?? "",
                categoriesSelected:categories,
                selectedProducts:selectedProducts,
                selectedProductsconfig:selectedProductsconfig,
                groupAttribut:current.node.attributeGroup.id,
                ...attributesData
            })
            setOpenForm(!openForm)
        }
        if (openForm) {
            resetState()
            setOpenForm(!openForm)
        }
    }  

    useEffect(async ()=>{
        if (props.location.state?.productId) {
            await getCategories()
            await getMediasCategories()
            const data =  await props.client.query({
                query: GET_ONE_PRODUCT_DATAS,
                variables: {
                    "id": props.location.state.productId
                },
                fetchPolicy: "no-cache"
            });
            const currentProduct = {node:data.data.product}
            handleToggleDrawer("convert",null,currentProduct)
        }else{
            getCategories()
            getMediasCategories()
        }
    },[])    


    const resetState = ()=>{
        setState({
            ...state,
            bundleImage:"",
            bundleName: "",
            bundlePrice:"",
            bundleDesc:"",
            bundleSku:"",
            categoriesSelected:[],
            selectedProducts:[],
            selectedProductsconfig:[],
            groupAttribut:null,
            selectedMediaCategories:[],
            errors:{},
        })
        setFormType(false)
        setCurrentProduct(null)
    }

    const handleSelectedProducts = async (item)=>{
        const data = await getProductData(item.node.sku)
        let newArray =[...state.selectedProducts]
        let newArray2 =[...state.selectedProductsconfig]
        if (state.selectedProducts.find(e=>e.node.id === item.node.id)) {
            newArray = newArray.filter(e=>e.node.id!==item.node.id)
            newArray2 = newArray2.filter(e=>e.id!==item.node.id)
        }else{
            newArray.push(item)
            data.id =item.node.id
            data.node =item.node
            newArray2.push(data)
        }
        setState({
            ...state,
            selectedProducts:newArray,
            selectedProductsconfig:newArray2
        })
    }

    const getProductData = async (id,item)=>{
        try {
            const data=  await props.client.query({
                query: GET_PRODUCTS_BY_SKU_LIGHT,
                variables: {
                    "sku": id,  
                },
                fetchPolicy: "no-cache"
            });
            let arrayToMap = data.data.products.edges.filter(e=>{
                if (data.data.products.edges.length > 1) {
                 return e.node.sku !== id
                }else{
                    return true
                }
            })
            let newArray = arrayToMap.map((e,i)=>{
                const newArray  = e;
                newArray.node.position = formType === "add" ? i : e.node.position
                return newArray
            })
            let config = {
                // "product": "/api/products/12",
                "title":item?item.node.title:"",
                "isRequired": item?item.node.isRequired: true,
                "inputType": "select",
                "edges": newArray,
            }
            return config
        } catch (error) {
            console.log(error)
        }
    }

    const getCategories = async ()=>{
        try {
            const data = await props.client.query({
                query: GET_CATEGORIES_FLAT,
                variables: {
                    "exists": [{
                        "catalog": false
                    }]
                },
                fetchPolicy: "no-cache"
            });
            setState({
                ...state,
                categories:data.data.categoryFlats.edges
            })
            
        } catch (error) {
            console.log(error)
        }
    }


    const hasErrors = () => {
        if (errors) {
            console.log(errors)
            for (let error in errors) {
                if (errors[error])
                    return true;
            }
        }
        return false;
    };

    const handleNextStep = () =>{
        let result = formHelper.handleStep('next',errors);
        setState({...state,seeErrors: !result})        
        return result;
    }

    const doneTyping = (stateName) => {
        let typeTesting = state.typeTesting;

        if (state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    }
                }
            );
        }
    };


    const checkIdentifier = (stateName) => {
        clearTimeout(typingTimer);
        typingTimer=setTimeout(() => { doneTyping(stateName) }, 500);
    };

    const stateCallback = (stateName, value, type) => {  
        if (type === 'select') {
        }
        if (stateName ==='categorie_de_media') {
            if (value) {
                props.client.query({
                    query: GET_MEDIA_OBJECTS_CATEGORY,
                    fetchPolicy: 'no-cache',
                    variables:{
                        id:value
                    }
                }).then(result => {
                    const tracklist = result.data.mediaObjectCategory.mediaObjects.edges.map((media)=>{
                        return {
                            name : media.node.name,
                            file_path : media.node.filePath,
                        }
                    })
                    let getState = {...state,selectedMediaCategories:value};        
                    getState[stateName] =  JSON.stringify(tracklist);
                    setState(getState)
                }).catch((err)=>console.log(err))
            }else{
                let getState = {...state,selectedMediaCategories:null};        
                getState[stateName] =  null;
                setState(getState)
            }
        }else{
            let getState = {...state};        
            getState[stateName] =  value?.target?.value ?? value;
            setState(getState)    
        }
    };


    useEffect(() => {
        if (formType === "add") {
            checkIdentifier("bundleSku");
        }
    }, [state.bundleSku])

    

    const getMediasCategories = () => {
        props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            setState({
                ...state,
                mediaCategories:result.data.mediaObjectCategories.edges
            })
            setMediaCategories(result.data.mediaObjectCategories.edges);
        }).catch((err)=>console.log(err))
    };

    const stateCallbackConfig = (e,type,product,sku) => {
        const config = state.selectedProductsconfig.find(e=>e.id === product.node.id)
        if (type === 'isRequired') {
            config[type] = e.target.value === "true" ?true:false
        }
        if (type === 'title') {
            config[type] = e.target.value
        }
        if (type === 'inputType') {
            config[type] ="select"
        }
        if (type === 'position') {
            const newArray = [...config.edges]
            let toAdd = newArray.find(e=>e.node.sku === sku)
            let toDelete = newArray.find(e=>e.node.position === 0)
            if (toDelete) {
                toDelete.node.position = toAdd.node.position
            }
            if (toAdd) {
                toAdd.node.position = 0
            }
            config.edges = newArray
        }
        setState({
            ...state,
            selectedProductsconfig: [...state.selectedProductsconfig.filter(e=>e.id !== product.node.id),config]
        }) 
    }

    const handleFormError = (stateName, error) => {
        let getErrors = state.errors;        
        getErrors[stateName] = error;
        setErrors(getErrors);
    };

    const handlerMutationEdit = async () => {
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        props.startLoading();
        let variables = {
            'id':currentProduct.node.id,
            'categories': state.categoriesSelected.map(e => e? parseInt(e.id.replace('/api/categories/', '')) :null),
            'updatedAt': moment().format('YYYY-MM-DD'),
        };
        if (state.groupAttribut !== currentProduct.node.attributeGroup.id) {
            variables.attributeGroup = state.groupAttribut
        }
        if (state.bundleSku !== currentProduct.node.sku) {
            variables.sku = state.bundleSku
        }
        if (state.isActive !== currentProduct.node.status) {
            variables.status = state.isActive
        }
        try {
            const updateProduct = await props.client.mutate({
                mutation: UPDATE_PRODUCT,
                variables
            })                
            await saveAttributes(updateProduct.data.updateProduct.product.id,"edit")
            props.history.push({
                state: { }
            });
            let toAdd = []
            let toDelete = []
            let toUpdate = []
            state.selectedProducts.forEach(e=>{
                if (!currentProduct.node.productBundleOptions.edges.find(f=>{
                    if (f.node.productRelations.edges[0].node.product?.parent?.id) {
                        return f.node.productRelations.edges[0].node.product?.parent?.id === e.node.id
                    }else{
                        return f.node.productRelations.edges[0].node.product.id === e.node.id
                    }
                })) {
                    let obj = {...e}
                    obj.node.product = {
                        id:e.node.id
                    }
                    toAdd.push({
                        ...e,
                        ...obj
                    })
                }
            })
            currentProduct.node.productBundleOptions.edges.forEach(e=>{
                if (!state.selectedProducts.find(f=>{
                    if (e.node.productRelations.edges[0].node.product?.parent?.id) {
                        return f.node.id === e.node.productRelations.edges[0].node.product.parent?.id
                    }else{
                        return f.node.id === e.node.productRelations.edges[0].node.product.id
                    }
                })) {
                    toDelete.push(e)
                }
            })

            state.selectedProducts.forEach(e=>{
                if (currentProduct.node.productBundleOptions.edges.find(f=>{
                    if (f.node.productRelations.edges[0].node.product?.parent?.id) {
                        return f.node.productRelations.edges[0].node.product.parent?.id === e.node.id
                    }else{
                        return f.node.productRelations.edges[0].node.product.id === e.node.id
                    }
                })) {
                    toUpdate.push({...currentProduct.node.productBundleOptions.edges.find(f=>{
                        if (f.node.productRelations.edges[0].node.product?.parent?.id) {
                            return f.node.productRelations.edges[0].node.product?.parent?.id === e.node.id
                        }else{
                            return f.node.productRelations.edges[0].node.product.id === e.node.id
                        }
                    }),isRequired:e.isRequired,title:e.title,inputType:e.inputType})
                }
            })

            toAdd.forEach(e=>{
                const config = state.selectedProductsconfig.find(f=>f.id === e.node.id)
                props.client.mutate({
                    mutation: createProductBundleOption,
                    variables:{
                        "product": currentProduct.node.id,
                        "title": config.title,
                        "isRequired": config.isRequired,
                        "inputType": "select"
                    }
                }).then((result)=>{
                    config.edges.forEach((e,i)=>{
                        props.client.mutate({
                            mutation: createProductBundleOptionProduct,
                            variables:{
                                "productBundleOption": result.data.createProductBundleOption.productBundleOption.id,
                                "product": e.node.id,
                                "position": e.node.position??1
                            }
                        })
                    })
                }).catch((err)=>{
                    console.log(err)
                });
            })

            toDelete.forEach(async(e,j)=>{
                const config = currentProduct.node.productBundleOptions.edges.find(f=>f.node.id === e.node.id)
                try {
                    await config.node.productRelations.edges.forEach(async (k,i)=>{
                        await props.client.mutate({
                            mutation: deleteProductBundleOptionProduct,
                            variables:{
                                "id": `/api/product-bundle-option-products/productBundleOption=${e.node.id.replace('/api/product-bundle-options/','')};product=${k.node.product.id.replace('/api/products/',"")}`
                            }
                        })
                    })
                    props.client.mutate({
                        mutation: deleteProductBundleOption,
                        variables:{
                            "id": `/api/product-bundle-options/${e.node.id.replace('/api/product-bundle-options/','')}`
                        }
                    })   
                } catch (error) {
                    console.log(error)
                }
            })

            toUpdate.forEach((e,j)=>{
                const config = state.selectedProductsconfig.find(f=>f.bundleId === e.node.id)
                props.client.mutate({
                    mutation: updateProductBundleOption,
                    variables:{
                        "product": e.node.product.id,
                        "title": config.title,
                        "isRequired": config.isRequired,
                        "inputType": "select",
                        "id":config.bundleId
                    }
                }).then((result)=>{
                    config.edges.forEach((f,i)=>{
                        props.client.mutate({
                            mutation: updateProductBundleOptionProduct,
                            variables:{
                                "productBundleOption": config.bundleId,
                                "product": f.node.id,
                                "position": f.node.position??1,
                                "id": `/api/product-bundle-option-products/productBundleOption=${e.node.id.replace('/api/product-bundle-options/','')};product=${f.node.id.replace('/api/products/',"")}`,
                            }
                        })
                    })
                }).catch((err)=>{
                    console.log(err)
                });
            })
            handleToggleDrawer()
            props.snack(ALERT_SUCCESS, 'Bundle modifié avec succès !');
            props.stopLoading();
            setReload(!reload)
        } catch (error) {
            console.log(error)
        }
    };

    const handlerMutation = async () => {
        if (hasErrors()) {
            props.snack(ALERT_ERROR, props.t("spread.active_assets.toastCheckField"));
            return eventService.fire();
        }
        props.startLoading();
        let variables = {
            'sku': state.bundleSku,
            'attributeGroup': state.groupAttribut,
            'categories': state.categoriesSelected.map(e => parseInt(e.id.replace('/api/categories/', ''))),
            'createdAt': moment().format('YYYY-MM-DD'),
            'updatedAt': moment().format('YYYY-MM-DD'),
            'status': true,
            'superAttribute': [],
        };
        try {
            props.client.mutate({
                mutation: ADD_PRODUCT,
                variables
            }).then(async(ADD_PRODUCT_RESULT)=>{
                await saveAttributes(ADD_PRODUCT_RESULT.data.createProduct.product.id);
                state.selectedProducts.forEach(element=>{
                    const config = state.selectedProductsconfig.find(e=>e.id === element.node.id)
                    props.client.mutate({
                        mutation: createProductBundleOption,
                        variables:{
                            "product": ADD_PRODUCT_RESULT.data.createProduct.product.id,
                            "title": config.title,
                            "isRequired": config.isRequired,
                            "inputType": "select"
                        }
                    }).then((result)=>{
                        if (config.edges.length === 0) {
                            props.client.mutate({
                                mutation: createProductBundleOptionProduct,
                                variables:{
                                    "productBundleOption": result.data.createProductBundleOption.productBundleOption.id,
                                    "product": config.id,
                                    "position": 0
                                }
                            })
                        }
                        config.edges.forEach((e,i)=>{
                            props.client.mutate({
                                mutation: createProductBundleOptionProduct,
                                variables:{
                                    "productBundleOption": result.data.createProductBundleOption.productBundleOption.id,
                                    "product": e.node.id,
                                    "position": e.node.position
                                }
                            }).then(()=>{
                                handleToggleDrawer()
                                setReload(!reload)
                                props.snack(ALERT_SUCCESS, 'Bundle créé avec succès ! Il apparaîtra dans un petit moment.');
                                props.stopLoading();
                            }).catch(err=>console.log(err))
                        })
                    }).catch((err)=>{
                        console.log(err)
                    });
                })
            }).catch(err=>console.log(err))
            
        } catch (error) {
            console.log(error)
        }
    };

    const saveAttributes =  (product, type="add") => {
        if (type === "edit") {
            return new Promise(async (resolve, reject) => {
                let attributes = props.attributes.product.attributes.edges.filter(e => e.node.isSystem).concat(state.allGroups.find(e => e.node.id === state.groupAttribut).node.attributes.edges.filter(e => !e.node.isSystem));
                let getProductData = [];
                
                for (let attribute of attributes) {
                    for (let locale of props.locales) {
                        let id = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node?.id
                        if (id) {
                            let variables = {
                                "id":id,
                                "product": product,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id,
                                "updatedAt": moment().format('YYYY-MM-DD'),
                            };
                            if (attribute.node.identifier === 'product_name') {
                                variables.value = state.bundleName
                                if (oldState.bundleName !== state.bundleName){
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }
                            }
                            else if (attribute.node.identifier === 'categorie_de_media') {
                                variables.value = state[attribute.node.identifier]
                                variables.mediaObjectCategoryId = state.selectedMediaCategories
                                if (!state[attribute.node.identifier]) {
                                    await props.client.mutate({
                                        mutation: DELETE_PRODUCT_DATA,
                                        variables:{
                                            id
                                        }
                                    })
                                }
                                else if (oldState[attribute.node.identifier] !== state[attribute.node.identifier]){
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }
                            }
                            else if (attribute.node.identifier === 'product_description') {
                                variables.value = state.bundleDesc
                                if (oldState.bundleDesc !== state.bundleDesc){
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }
                            }
                            else if (attribute.node.identifier === 'product_price') {
                                variables.value = state.bundlePrice
                                if (oldState.bundlePrice !== state.bundlePrice){
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }
                            }
                            else if (state.imageAttributes.find(e=>e.node.identifier === attribute.node.identifier)) {
                                variables.media = state[attribute.node.identifier]?.id
                                if (oldState[attribute.node.identifier]?.id !== state[attribute.node.identifier]?.id){
                                    if (!variables.media) {
                                        await props.client.mutate({
                                            mutation: DELETE_PRODUCT_DATA,
                                            variables:{
                                                id
                                            }
                                        })
                                    }else{
                                        let resultMutation = await props.client.mutate({
                                            mutation: UPDATE_PRODUCT_DATA,
                                            variables
                                        })
                                        getProductData.push(resultMutation.data.updateProductData.productData)    
                                    }
                                }
                            }
                            else if (oldState[attribute.node.identifier] !== state[attribute.node.identifier]){
                                if (attribute.node.attributeType.input === "select") {
                                    variables.attributeOption = state[attribute.node.identifier]    
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }else{
                                    variables.value = state[attribute.node.identifier]
                                    let resultMutation = await props.client.mutate({
                                        mutation: UPDATE_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.updateProductData.productData)    
                                }
                            }
                        }else{
                            let variables = {
                                "product": product,
                                "attribute": attribute.node.id,
                                "locale": locale.node.id,
                                "updatedAt": moment().format('YYYY-MM-DD'),
                            };
                            if (attribute.node.identifier === 'product_name') {
                                variables.value = state.bundleName
                                let resultMutation = await props.client.mutate({
                                    mutation: ADD_PRODUCT_DATA,
                                    variables
                                })
                                getProductData.push(resultMutation.data.createProductData.productData)
                            }
                            else if (attribute.node.identifier === 'categorie_de_media') {
                                variables.value = state[attribute.node.identifier]
                                variables.mediaObjectCategoryId = state.selectedMediaCategories
                                let resultMutation = await props.client.mutate({
                                    mutation: ADD_PRODUCT_DATA,
                                    variables
                                })
                                getProductData.push(resultMutation.data.createProductData.productData)
                            }
                            else if (attribute.node.identifier === 'product_description') {
                                variables.value = state.bundleDesc
                                let resultMutation = await props.client.mutate({
                                    mutation: ADD_PRODUCT_DATA,
                                    variables
                                })
                                getProductData.push(resultMutation.data.createProductData.productData)    
                            }
                            else if (attribute.node.identifier === 'product_price') {
                                variables.value = state.bundlePrice
                                let resultMutation = await props.client.mutate({
                                    mutation: ADD_PRODUCT_DATA,
                                    variables
                                })
                                getProductData.push(resultMutation.data.createProductData.productData)
                            }
                            else if (state.imageAttributes.find(e=>e.node.identifier === attribute.node.identifier)) {
                                variables.media = state[attribute.node.identifier]?.id
                                if (oldState[attribute.node.identifier]?.id !== state[attribute.node.identifier]?.id){
                                    let resultMutation = await props.client.mutate({
                                        mutation: ADD_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.createProductData.productData)
                                }
                            }
                            else if (state[attribute.node.identifier]) {
                                if (attribute.node.attributeType.input === "select") {
                                    variables.attributeOption = state[attribute.node.identifier]    
                                    let resultMutation = await props.client.mutate({
                                        mutation: ADD_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.createProductData.productData)    
                                }else{
                                    variables.value = state[attribute.node.identifier]
                                    let resultMutation = await props.client.mutate({
                                        mutation: ADD_PRODUCT_DATA,
                                        variables
                                    })
                                    getProductData.push(resultMutation.data.createProductData.productData)
                                }
                            }
                        }
                    }
                }
    
                // for (let attribute of state.customAttributes) {
                //     let id = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.id === attribute.node.id)?.node?.id
                //     let variables = {
                //         "id":id,
                //         "product": product,
                //         "attribute": attribute.node.id,
                //         "locale": props.locales[0].node.id,
                //         "updatedAt": moment().format('YYYY-MM-DD'),
                //     };
                //     variables.value = state[attribute.node.identifier]??""
                //     console.log(oldState,state)
                //     console.log(oldState[attribute.node.identifier] , state[attribute.node.identifier])
                //     if (oldState[attribute.node.identifier] !== state[attribute.node.identifier]) {
                //         let resultMutation = await props.client.mutate({
                //             mutation: UPDATE_PRODUCT_DATA,
                //             variables
                //         })
                //         getProductData.push(resultMutation.data.updateProductData.productData)
                //     }
                // }
                resolve(getProductData);
            });
        }else{
            return new Promise(async (resolve, reject) => {
                let attributes = props.attributes.product.attributes.edges.filter(e => e.node.isSystem).concat(state.allGroups.find(e => e.node.id === state.groupAttribut).node.attributes.edges.filter(e => !e.node.isSystem));
                let getProductData = [];
                for (let attribute of attributes) {
                    for (let locale of props.locales) {
                        let variables = {
                            "product": product,
                            "attribute": attribute.node.id,
                            "locale": locale.node.id,
                            "updatedAt": moment().format('YYYY-MM-DD'),
                        };
                        if (attribute.node.identifier === 'product_name') {
                            variables.value = state.bundleName
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                        else if (attribute.node.identifier === 'categorie_de_media') {
                            variables.value = state[attribute.node.identifier]
                            variables.mediaObjectCategoryId = state.selectedMediaCategories
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                        else if (attribute.node.identifier === 'product_description') {
                            variables.value = state.bundleDesc
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                        else if (attribute.node.identifier === 'product_price') {
                            variables.value = state.bundlePrice
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                        else if (attribute.node.identifier === 'product_image') {
                            variables.media = state.bundleImage.id
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                        else if (state[attribute.node.identifier]) {
                            variables.value = state[attribute.node.identifier]
                            let resultMutation = await props.client.mutate({
                                mutation: ADD_PRODUCT_DATA,
                                variables
                            })
                            getProductData.push(resultMutation.data.createProductData.productData)
                        }
                    }
                }
                resolve(getProductData);
            });
        }
    };

    const prepareAttributes =async (currentProduct,type) => {
        return new Promise(async (resolve, reject) => {
            let getProductData = {};
            for (let attribute of state.customAttributes) {
                for (let locale of props.locales) {
                    if (type === "convert") {
                        const value = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node?.attributeOption?.id
                        getProductData[attribute.node.identifier] = value
                    }else{
                        let value
                        if (attribute.node.attributeType.input === "select") {
                            value = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node?.attributeOption?.id
                        }else if (attribute.node.identifier === "categorie_de_media") {
                            value = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node.value
                        }
                        else{
                            value = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node.value
                        }
                        getProductData[attribute.node.identifier] = value
                    }
                }
            }
            for (let attribute of state.imageAttributes) {
                for (let locale of props.locales) {
                    const value = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === attribute.node.identifier)?.node.media
                    getProductData[attribute.node.identifier] = value

                }
            }
            // getProductData.bundleImage = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_image")?.node.media
            getProductData.bundlePrice = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_price")?.node.value
            getProductData.bundleName = currentProduct.node.productDatas.edges.find(e=>e.node.attribute.identifier === "product_name")?.node.value
            resolve(getProductData);
        });
    }

    const prepareAttributesLocales=(result) =>{
        return new Promise((resolve, reject) => {
            let attributes = [];

            for (let { node } of result.node.productDatas.edges) {
                let found = false;

                for (let attribute of attributes) {
                    if (attribute.id === node.attribute.id) {
                        found = true;
                        // add locale to existing attribute
                        attribute.locales.push({
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input,
                            isLocked: node.isLocked,
                        });
                    }
                }

                if (!found) {
                    // create attribute and add locale
                    attributes.push({
                        id: node.attribute.id,
                        identifier: node.attribute.identifier,
                        attributeType: node.attribute.attributeType,
                        isSystem: node.attribute.isSystem,
                        attributeOptions: node.attributeOptions,
                        isLocked: node.isLocked,
                        translationLabel: node.attribute.translation.translationDatas.edges,
                        locales: [{
                            value: node.attribute.attributeType.input === 'select' ? node.attributeOption?.id : node.value,
                            media: node.media,
                            id: node.locale.id,
                            code: node.locale.code,
                            productDataId: node.id,
                            type: node.attribute.attributeType.input
                        }]
                    });
                }
            }

            setState({ ...state,attributes, initialAttributes: attributes });
            setState({ ...state,scrapAttributes: attributes });
            resolve()
        });
    }

    const prepareAttributeValues = () => {
        for (let locale of props.locales) {
            let values = {};

            for (let attribute of state.customAttributes) {
                if (attribute.node.attributeType.input === 'select') {
                    if (attribute.node.attributeOptions.edges.length) {
                        values[attribute.node.identifier] = {
                            value: attribute.node.isRequired ? attribute.node.attributeOptions.edges[0].node.id : null
                        }
                    }
                }
            }

            setState({
                [locale.node.code]: values
            });
        }
    }

    useEffect(()=>{
        if (!openForm) {
            resetState()
        }
    },[openForm])

    const handleMediaPicker=(selected,stateName)=>{     
        let getState = {...state};        
        getState[stateName] =  selected
        setState(getState)
    }

    const handleButtonGroupChange = (stateName, value) => {
        let getState = {...state};        
        getState[stateName] =  value
        setState(getState)
    };

    const deleteMutation = async (product)=>{
        props.startLoading();
        try {
            await props.client.mutate({
                mutation: DELETE_PRODUCT,
                variables:{
                    "id":product.id
                }
            })
            props.snack(ALERT_SUCCESS, 'Bundle supprimé avec succès !');
        } catch (error) {
            console.log(error)            
            props.snack(ALERT_ERROR, 'Une erreur es survenue durant la suppression.');
        }
        setReload(!reload)
        setOpenDialog(!openDialog)
        props.stopLoading()
    }

    return (
        <div>
             <TopPanel
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t('products.bundles.title')}
                subtitle={props.t('products.bundles.subtitle')}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                handlerAdd={() => { handleToggleDrawer("add") }}
                textAdd={props.t("products.bundles.add")}
                windowWidth={props.windowWidth}
                currentLang={props.currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <div>
                <ListingProducts
                    productBypage={{
                        list: [30]
                    }}
                    isBundle={true}
                    isCatalog={false}
                    nbperpage={12}
                    currentLang={state.currentLang}
                    handleLang={props.handleLang}
                    windowWidth={props.windowWidth}
                    callbackFilters={props.callbackFilters}
                    reload={reload}
                    onReload={()=>setReload(!reload)}
                    listingCallback={(products)=>setListProducts(products)}
                    cardProps={{
                        buttonCallback:(id) =>handleToggleDrawer("edit",id) ,
                        deleteCallabck:(id)=>{setOpenDialog(!openDialog);setIdToDelete(id)}
                    }}
                />
            </div>
            <LayoutFormBuilder
                opened={openForm} 
                forClose={()=>handleToggleDrawer()}
                // handlerSetup={()=>{}} 
                padding={"20px"}
                drawerWidth={"50vw"}
                dataLayout={formBundle(formType,handleMediaPicker, state,state.allGroups,state.currentLang,state.variantsValidated,state.isVariant,state.categories,state.categoriesSelected,state.customAttributes,mediaCategories,state.selectedMediaCategories,state.imageAttributes,metaAttributes)} 
                allState={{...state,mediaCategories}}    
                setSelectedProducts={handleSelectedProducts}
                selectedProducts={state.selectedProducts}
                stepperButtonDisabled={[null,null,null,()=>state.selectedProducts.length === 0,null]}
                stepperButtonAction={[
                    () => {
                        if (hasErrors()) {
                            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setState({...state, seeErrors: true });
                            eventService.fire();
                            return false;
                        }

                        handleNextStep()

                        return true;
                    },
                    () => {
                        if (hasErrors()) {
                            props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                            setState({...state, seeErrors: true });
                            eventService.fire();
                            return false;
                        }

                        handleNextStep()

                        return true;
                    },
                    handleNextStep, 
                    handleNextStep,
                    handleNextStep,
                ]}
                stateCallback={stateCallback} 
                stateCallbackConfig={stateCallbackConfig} 
                errorCallback={handleFormError}    
                checkError={() => {}}
                handleButtonGroupChange={handleButtonGroupChange}
                // validateButton={true}
                handlerMutation={formType== "add"? handlerMutation: handlerMutationEdit}
                // handlerMutation={()=>console.log(state)}
                // handleButtonGroupChange={handleButtonGroupChange}
            />
             <DialogModal
                icon={true}
                type='delete'
                open={openDialog}
                title={'Êtes-vous sûr de vouloir supprimer ce bundle ?'}
                primaryAction={()=>deleteMutation(idToDelete)}
                secondaryAction={()=>setOpenDialog(!openDialog)}
                windowWidth={props.windowWidth}
            >
                <OurTypography text={'Si vous supprimez ce bundle celui-ci ne sera plus accessible. Si vous ne souhaitez pas le supprimer, annulez la suppression en cliquant sur annuler.'} colortext={colors.grey.lighter.hue600} style={{ fontSize: '16px' }} />
            </DialogModal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListBundles)))));