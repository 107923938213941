import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import IcomoonReact from 'icomoon-react';
import iconSet from "../../../../assets/selection.json";
import CardSummary from './CardSummary';

const ContainerCard = styled(Box)`
    display: block;
    height: 100%;
    padding: 16px;
`

export default function CardShopTheLook(props) {

    const useStylesBootstrap = makeStyles(theme => ({
        arrow: {
            color: colors.black.regular,
        },
        tooltip: {
            backgroundColor: colors.black.regular,
            fontSize: 14,
            padding: 10,
            textAlign: 'left',
        },
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }
    return (
        <ContainerCard>
            <Grid container style={{ position: 'relative', height: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <img src={props.data.node.media ? `${process.env.REACT_APP_MEDIAS}/${props.data.node.media?.filePath}` : NoImage} style={{ width: "100%" }} alt={`image-ShopTheLook-${props.data.node.libelle}`} />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" style={{ marginBottom: 8 }}>{props.data.node.libelle}</Typography>
                        <StatusInfo status={props.data.node.status} />
                        <Typography variant="body1" style={{ marginTop: 8 }}>Nombre d'articles : </Typography>
                        <Grid container alignItems="center">
                            <IcomoonReact iconSet={iconSet} color={colors.blue.darker.hue300} size={25} icon={"picto-produit"} />
                            <Typography variant="body1" style={{ marginLeft: 8, color: colors.blue.darker.hue300, fontWeight: "bold" }}>{props.data.node.productLookBooks.edges.length}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                    <Button onClick={() => props.handlerButton(props.data.node)} text={props.textButton}></Button>
                </Grid>
            </Grid>
        </ContainerCard>
    );
}