import React from 'react';
import { Box, Grid, Typography, Tooltip } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import NoImage from '../../../../assets/images/not-found.png';
import { getTraductionAttributs } from '../../../../js/utils/functions';
import _ from 'lodash';
import * as moment from 'moment';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';
import TextEllipsis from './TextEllipsis';
import EditIcon from '@material-ui/icons/Edit';
import imgNotFound from '../../../../assets/images/image-fake-card.png';
import { withTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useEffect } from 'react';

// const BoxCustomAttributs = styled(Box)`
//     background: ${colors.grey.lighter.hue980};
// `;
// const TitleAttributs = styled(Typography)`
//     font-weight: bold;
//     color: ${colors.blue.regular};
// `;
// const Subtitle = styled(Typography)`
//     color: ${colors.blue.regular};
//     font-weight: bold;
// `;
// const ContainerCard = styled(Box)`
//     display: block;
//     height: 100%;
//     padding-top: 8px;
// `
// const ButtonCustom = styled(Button)`
//     margin: 0;
//     text-transform: unset;
//     font-weight: bold;
// `
// const Title = styled(Typography)`
//     font-weight: bold;
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: ${props => props.nbLine ? props.nbLine : '2'};
//     -webkit-box-orient: vertical;
// `
// const Sku = styled(Typography)``;
// const ProductAttr = styled(Typography)``;
// const ContainerButton = styled(Box)`
//     display: flex;
//     justify-content: flex-end;

// `

const CardDescription = styled(Typography)`
    display: flex;
    flex-direction: row;
    min-height: 40px;
    overflow: hidden;
    font-size: 13px;
    line-height: 17px;
    span{
        white-space: nowrap;
    }
`;

const Libelle = styled(Typography)`
    font-size: 25px;
    line-height: 1.5;
    font-weight: bold;
    max-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
`
const ContainerImage = styled(Box)`
    overflow: hidden;
    background-color: ${props => props.bgcolor};
    background-image: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
`;

const WrapperAttr = styled(Grid)`
    align-items: center;

    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`

const DataValue = (props) => {
    const [isOverflow, setIsOverflow] = React.useState(false);
    const dataContainer = useRef(null)
    const dataValue = useRef(null)

    useEffect(() => {
        setIsOverflow(checkIfOverflows(dataContainer.current, dataValue.current))
    }, [])

    const checkIfOverflows = (c, e) => {
        return (e.offsetWidth + 5 > c.offsetWidth);
    }

    return (
        <div style={{width: '100%'}} ref={dataContainer}>
            {isOverflow ? (
                <Tooltip title={props.value} arrow>
                    <Box style={{ marginLeft: '3px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        <span ref={dataValue}>{props.value}</span>
                    </Box>
                </Tooltip>
            ) : (
                <Box style={{ marginLeft: '3px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <span ref={dataValue}>{props.value}</span>
                </Box>
            )}
        </div>
    )
}

function CardShell(props){

    const shell = props.data.node;
    const attributes = shell?.shellDatas?.edges?.filter(e => e.node.attribute.useInCard && e.node.locale?.code === props.currentLang);  
    
    const datas = () => {
        let image, title, data, type, sku, telephone, email, poste, firstname, lastname, typeNode;

        switch (props.data.node.eavType.code) {
            case 'fiche-qr':
                image = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'image');
                sku = shell.sku;
                firstname = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'firstname');
                lastname = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'lastname');
                telephone = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'phone');
                email = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'email');
                poste = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'poste');

                return {
                    image: image?.node.value,
                    title: `${firstname?.node.value} ${lastname?.node.value}`,
                    data: [
                        { label: 'Sku', value: sku },
                        { label: 'Telephone', value: telephone?.node.value },
                        { label: 'Email', value: email?.node.value },
                        { label: 'Poste', value: poste?.node.value },
                    ]
                }
            default:
                typeNode = shell.shellDatas?.edges.find(e => e.node.attribute.identifier === 'type'  && e.node.locale.code === props.currentLang)?.node
                type = typeNode?.attribute.attributeType.input === "select" 
                    ? typeNode.attributeOption.translation.translationDatas?.edges?.[0].node.value ?? typeNode.attributeOption.identifier
                    : null
                sku = shell.sku;
                image = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'image');
                title = shell.shellDatas.edges.find(e => e.node.attribute.identifier === 'nom');

                return {
                    image: image?.node.value,
                    title: title?.node.value,
                    data: [
                        { label: 'Sku', value: sku },
                        { label: 'Telephone', value: telephone?.node.value },
                        { label: 'Type', value: type },
                    ]
                }
        }
    }

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            color: colors.blue.darker.hue300,
            isDisabled: true,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                // props.actionButtonSecond();
                // handleClose(e);
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.menuHandler.toggleDeleteDialog(shell.id);
                // props.handlerMenu.toggleDeleteDialog(type.node.id)
            }
        }
    ]

    const goTo = props.handlerButton;
    return(
        <Box style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative", padding: 15 }}>
            {/* TODO: Get image */}
            <ContainerImage height={210} backgroundimage={datas().image?.node?.media ? `${process.env.REACT_APP_MEDIAS}/${datas().image.node.media.filePath}` : imgNotFound} />
            <Grid alignItems='center'>
                {/* Title and description */}
                <Box pb={1} mt={2} style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%" }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' style={{position: 'relative'}}>
                            <Box>
                                <Libelle variant="h4" colortext={colors.black.regular}>
                                    {datas().title}
                                </Libelle>
                            </Box>
                            <Box >
                                <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} customStyle={{
                                    position: 'absolute',
                                    right: 0,
                                    top: '25%',
                                }} />            
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" component="div">
                        {/* <CardDescription>
                            <TextEllipsis
                                text={shell.description}
                                maxLine={2}
                                ellipsis='...'
                                hasModal={false}
                            />
                        </CardDescription> */}
                    </Typography>
                </Box>

                <Box mt={2} style={{ width: "100%" }}>
                    <Grid container direction='column' style={{ height: '100%', gap: '5px' }}>
                        {
                            datas().data.map(data => (
                                <WrapperAttr container>
                                    <Grid item xs={5}>
                                        <Typography>{data.label} :</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <DataValue value={data.value} />
                                    </Grid>
                                </WrapperAttr>
                            ))
                        }
                    </Grid>
                </Box>

                {/* {telephone?.value} */}
                {console.log('shell', shell)}

                <Box mt={2} style={{ width: "100%" }}>
                    {/* Modify button */}
                    <Box>
                        {props.handlerEdit && (
                            <Button
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    margin: '0px',
                                    marginTop: '28px',
                                    display: 'flex',
                                    alignSelf: 'end'
                                }}
                                text={props.t("spread_builder.asset_view.edit")}
                                icon={'edit'}
                                color={colors.blue.darker.hue300}
                                colorHover={colors.white}
                                bgcolorhover={colors.blue.lighter.hue600}
                                bgcolor={colors.blue.lighter.hue900}
                                border={`1px solid ${colors.blue.darker.hue300}`}
                                stopPropagation={true}
                                onClick={() => { props.handlerEdit(shell.id) }}
                            />
                        )}
                    </Box>
                </Box>
            </Grid>

        </Box>




        // <ContainerCard>
        //     <Grid container style={{position: 'relative', height: '100%', padding: 20}}>
        //         <Grid item xs={5}>
        //             <Box pr={4}>
        //                 <Grid container alignItems="center" justifyContent="center" style={{minHeight: 180}}>
        //                     <img src={listImages?.length > 0 ? `${process.env.REACT_APP_MEDIAS}/${listImages[0].node.media.filePath}` : NoImage} style={{maxHeight: 180, maxWidth: '100%'}} alt={name} />
        //                 </Grid>
        //             </Box>
        //         </Grid>
        //         <Grid item xs={7} style={{display: 'flex', flexDirection: 'column'}}>
        //             <Box>
        //                 <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
        //                     <Box style={{width: 'auto'}}>
        //                         <StatusInfo status={shell.status}></StatusInfo>
        //                     </Box>
        //                 </Box>
        //                 <Box pt={1}>
        //                     <Title variant="h4" nbLine={props.type === 'dashboard' ? '1' : '2' }>{shell.sku}</Title>
        //                 </Box>
        //             </Box>
        //             {
        //                 <ContainerButton pt={1} style={{justifyContent: "flex-end"}}>
        //                     <ButtonCustom disableElevation={true} text="Modifier" bgColor={colors.green.regular} onClick={() => goTo(shell.id)} fontsize={ 12 } style={props.type === "dashboard" ? {height: '100%', padding: 6} : null} />
        //                 </ContainerButton>
        //             }
        //         </Grid>
        //         <Grid container>
        //             {
        //                 attributes && attributes?.length
        //                     ? (
        //                         <Box pt={4} style={{width:"100%"}}>
        //                             <Subtitle variant="body2">Attributs</Subtitle>
        //                             <BoxCustomAttributs mt={2} py={2} px={1}>
        //                                 <Grid container>
        //                                     {
        //                                         attributes
        //                                             ? attributes.filter(e => e.node.attribute.attributeType.input !== 'image').map((e, index) => {
        //                                                 let getValue = getTraductionAttributs(e.node.attribute.identifier, attributes, props.currentLang, e.node.attribute.attributeType.input);
        //                                                 const defaultLabel = e.node.attribute.translation.translationDatas.edges[0];
        //                                                 const label = e.node.attribute.translation.translationDatas.edges.find(
        //                                                     lang  => lang.node.locale?.code === props.currentLang
        //                                                 );
        //                                                 if (getValue){
        //                                                     return(
        //                                                         <Grid container alignItems={"center"} style={{marginTop: index === 0 ? 0 : 8}}>
        //                                                             <Grid item xs={8} >
        //                                                                 <TitleAttributs variant="body2">
        //                                                                     { 
        //                                                                         label?.node.value 
        //                                                                             ?? defaultLabel?.node.value 
        //                                                                             ?? e.node.attribute.identifier 
        //                                                                     }
        //                                                                 </TitleAttributs>
        //                                                             </Grid>
        //                                                             <Grid item xs={4} >
        //                                                                 {
        //                                                                     e.node.attribute.attributeType.input === "textarea" ?
        //                                                                     <Typography dangerouslySetInnerHTML={{ __html: getValue }} />
        //                                                                     : e.node.attribute.attributeType.input === "date" ? 
        //                                                                         <Typography>{moment(getValue).format('DD/MM/YYYY')}</Typography>
        //                                                                         : e.node.attribute.attributeType.input === "mail" ?
        //                                                                             <a href={`mailto:${getValue}`}><Typography>{getValue}</Typography></a>
        //                                                                             : e.node.attribute.attributeType.input === "file" ?
        //                                                                                 <a href={`${process.env.REACT_APP_MEDIAS}/${getValue.filePath}`} target={'__blank'}>
        //                                                                                     <Typography style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{getValue.filePath}</Typography>
        //                                                                                 </a>
        //                                                                                 : e.node.attribute.attributeType.input === "link" ? 
        //                                                                                     <a href={getValue} target={'__blank'}>
        //                                                                                         <Typography style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{getValue}</Typography>
        //                                                                                     </a>
        //                                                                                     : <Typography>{getValue}</Typography>
        //                                                                 }
        //                                                             </Grid>
        //                                                         </Grid>
        //                                                     )
        //                                                 }
        //                                             }) 
        //                                             : null
        //                                     }
        //                                 </Grid>
        //                             </BoxCustomAttributs>
        //                         </Box>
        //                     ) : null
        //             }
        //         </Grid>
        //     </Grid>
        // </ContainerCard>
    );
}

export default withTranslation()(CardShell);