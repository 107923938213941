import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Footer, Header, ItemTypes } from '../../../shareable';
import { getComponent } from '../../../core/components/assets';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, apiConfig, params, preview, droppable, mapper }) => {
    const classes = useStyles();

    if (!config) return null;

    const Droppable = droppable;

    return (
        <div className={classes.root}>
            {
                params.hasHeaderFooter.value && (
                    <Header 
                        noSticky={true}
                        data={params}
                        categories={apiConfig.categories}
                        spread={true}
                    />
                )
            }
            
            { droppable ? <Droppable type={ItemTypes.HEADER} id={'page-header'} index={-1} /> : null}
            
            { config.elements.map(mapper) }

            {
                params.hasHeaderFooter.value && (
                    <Footer
                        data={params}
                    />
                )
            }
        </div>
    );
};