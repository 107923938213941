export const translatePage = async (startLoading, stopLoading, updateConfig, contentPage) => {
    startLoading();

    for (let element of contentPage.elements) {
        for (const [keyInputs, valueInputs] of Object.entries(element.data.inputs)) {
            if ((valueInputs.type === "text" || valueInputs.type === "textarea") && valueInputs.value) {
                const q = encodeURI(valueInputs.value);

                let translation = await translateValue(q);
            
                if (translation) {
                    valueInputs.value = translation;
                }
            }
        }

        for (const [key, value] of Object.entries(element.data.blocks)) {
            if (value.type === "multiple-block") {
                for (let soloBlock of value.config) {
                    if (soloBlock.blocks) {
                        for (const [key2, value2] of Object.entries(soloBlock.blocks)) {
                            if (value2.config) {
                                for (let element2 of value2.config) {
                                    for (const [key3, value3] of Object.entries(element2.blocks)) {
                                        if ((value3.inputs?.value?.type === "text" || value3.inputs?.value?.type === "textarea") && value3.inputs.value.value) {
                                            const q = encodeURI(value3.inputs.value.value);
    
                                            let translation = await translateValue(q);
                                        
                                            if (translation) {
                                                value3.inputs.value.value = translation;
                                            }
                                        }
                                    }
                                }
                            } else {
                                for (const [key3, value3] of Object.entries(value2.inputs)) {
                                    if (value3) {
                                        if ((value3.type === "text" || value3.type === "textarea") && value3.value) {
                                            const q = encodeURI(value3.value);
    
                                            let translation = await translateValue(q);
                                        
                                            if (translation) {
                                                value3.value = translation;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  
                }
                
            } else {
                if ((value.inputs.value?.type === "text" || value.inputs.value?.type === "textarea") && value.inputs.value?.value) {
                    const q = encodeURI(value.inputs.value.value);

                    let translation = await translateValue(q);
            
                    if (translation) {
                        value.inputs.value.value = translation;
                    }
                }
            }
        }
    }
    
    stopLoading();
    updateConfig();
};

export const translateValue = async (value) => {
    const keyApp = process.env.REACT_APP_TRANSLATION_KEY;
    const target = 'en';

    try {
        let response = await fetch(`https://translation.googleapis.com/language/translate/v2?key=${keyApp}&target=${target}&q=${value}`);

        response = await response.json();

        let translation = response?.data?.translations?.[0]?.translatedText;

        return translation;
    } catch(e) {
        console.log(e?.message ?? e);
        return null;
    }
}