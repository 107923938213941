import React from 'react';
import styled from 'styled-components';
import colors from '../../../../config/theme/colors';
import { Grid, Box } from '@material-ui/core';
import Typography from '../../typography/Typography'
import Button from '../../../ui/button/Button';
import { withRouter } from 'react-router';
import imgNotFound from '../../../../../src/assets/images/not-found.png';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

const SpanColor = styled.span`
  color: ${props => props.colorhover};
  font-weight: bold;
`
const BoxCustom = styled(Box)`
    padding: 9px 15px;
    border: 0.5px solid ${colors.grey.lighter.hue700};
    margin-bottom: 12px;
    &:hover{
        h4{
            color: ${props => props.colorhover};
        }
    }
`;
const Title = styled(Typography)`
    {
        color: ${props => props.colortext};
    }
`;
const Description = styled(Typography)`
    {
        color: ${props => props.colortext};
    }
`;
const ImageContainer = styled(Grid)`
    border: 0.5px solid ${colors.blue.lighter.hue600};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`


class CardDrawerBuilder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabState: 0,
        }
    }
    handleChange = (event, value) => {
        this.setState({ tabState: value });
    };

    handleClickConnect = (id, idType) => {
        this.props.handlerSetup(id, idType)
    }

    componentDidMount() {
        this.setState({
            tabState: 0,
        });
    }

    render() {
        return (
            <Box>
                {(this.props.allState?.topPanel && (
                    Object.keys(this.props.allState?.topPanel).length !== 0) && (
                        <TopPanel
                            title={this.props.allState.topPanel.title}
                            subtitle={this.props.allState.topPanel.subtitle}
                            inForm={true}
                        />)
                )}
                {this.props.allState?.topPanel ? (
                    <Box mt={Object.keys(this.props.allState?.topPanel).length !== 0 ? 0 : '34px'}></Box>
                ) : <Box mt={'34px'}></Box>}
                {
                    this.props.dataCard.length > 0 ?
                        this.props.dataCard.map((child, index) => {
                            let image;
                            if (child.isLocaleImage) {
                                const imagesFolder = require.context('../../../../assets/images', true);
                                try {
                                    image = imagesFolder('./' + child.logo);
                                    image = typeof image === "string" ? image : image?.default;
                                } catch (e) {
                                    image = imgNotFound;
                                }
                            }
                            return (
                                <BoxCustom
                                    index={index}
                                    key={`FormBuilder${index}`}
                                    colorhover={child.colorhover}
                                // onClick={child.onClick ? child.onClick : () => this.handleClickConnect(child.id)}
                                >
                                    <Grid container>
                                        <Grid item xs={8} style={{ flexGrow: 1 }}>
                                            <Grid container alignItems={'center'} style={{ height: !child.hasNoImage ? '100%' : 'auto' }} spacing={!child.hasNoImage ? 10 : 0}>
                                                {
                                                    !child.hasNoImage ?
                                                        <Grid item xs={3}>
                                                            <ImageContainer item style={{ height: '73px', width: '73px' }} >
                                                                <img src={image ? image : child.picto ? child.picto : child.logo ? `${child.customLogo ? process.env.REACT_APP_MEDIAS : process.env.REACT_APP_API_ROOT}/${child.logo}` : imgNotFound} width={"100%"} style={{ maxWidth: 80, maxHeight: 80, filter: child.secondaryStyle ? 'grayscale(1)' : 'none', opacity: child.secondaryStyle ? '0.3' : '1' }} alt={child.libelle} />
                                                            </ImageContainer>
                                                        </Grid>
                                                    : null
                                                }
                                               
                                                <Grid item xs={!child.hasNoImage ? 9 : 12}>
                                                    <Box>
                                                        {child.bicoloreText ?
                                                            <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
                                                                {child.libelle} <SpanColor colorhover={child.colorhover}>{child.bicoloreText}</SpanColor>
                                                            </Typography>
                                                            :
                                                            <Title variant={"h4"} style={{ fontWeight: "bold" }} colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.black.regular}>
                                                                {child.libelle}
                                                            </Title>
                                                        }
                                                        <Box mt={'5px'}>
                                                            {
                                                                child.isTextArea ?
                                                                    <Description variant={"body2"}
                                                                        colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.grey.regular}
                                                                        dangerouslySetInnerHTML={{ __html: child.secondaryText ? child.isActive ? child.secondaryText + ' ' + child.libelle : child.secondaryText : child.description }}
                                                                    />
                                                                    :
                                                                    <Description variant={"body2"} colortext={child.secondaryStyle ? colors.grey.lighter.hue600 : colors.grey.regular}>
                                                                        {child.secondaryText ? child.isActive ? child.secondaryText + ' ' + child.libelle : child.secondaryText : child.description}
                                                                    </Description>
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Grid container justifyContent={"flex-end"} alignItems={"center"} style={{ height: "100%" }}>
                                                <Button
                                                    text={child.textButton ? child.textButton : `Paramétrer`}
                                                    disableElevation={true}
                                                    disabled={child.disabled}
                                                    onClick={child.onClick ? child.onClick : () => this.handleClickConnect(child.id, child.attributeGroup.id)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </BoxCustom>
                            )
                        })
                        :
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid>
                                <Typography variant={"h2"} colortext={colors.red.regular}>{this.props.dataCard.textNoData}</Typography>
                            </Grid>
                            <Grid>
                                <Button
                                    text={this.props.dataCard.textButtonNoData ? this.props.dataCard.textButtonNoData : `Voir`}
                                    bgcolor={colors.white}
                                    color={colors.green.regular}
                                    colorhover={colors.white}
                                    bgcolorhover={colors.green.regular}
                                    bgColor="transparent"
                                    disableElevation={true}
                                    border={`1px solid ${colors.green.regular}`}
                                    arrow="right"
                                    onClick={this.props.dataCard.onClickNoData ? this.props.dataCard.onClickNoData : null}
                                />
                            </Grid>
                        </Grid>
                }
            </Box>
        )
    }
    goTo = route => {
        this.props.history.push(route);
    };
}
export default withRouter(CardDrawerBuilder);