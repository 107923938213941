import { EditableTypes, FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultTextSimple, getDefaultSlideCategory } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Catégorie",
    blocks: {
        title: getDefaultTextSimple("Titre section", "Ajouter votre titre", 30, "#666"),
        slides: {
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CATEGORY,
            limit: false,
            id: uuidv4(),
            inputs: {
                title_category: getDefaultTextSimple("Titre catégorie", "Ajouter un titre à votre catégorie", 16, "#666"),
                color: {
                    type: FormInputTypes.COLOR,
                    value: "#fff",
                    label: "Couleur pagination :"
                }
            },
            config: [
                getDefaultSlideCategory("https://via.placeholder.com/120x160", "Titre"),
                getDefaultSlideCategory("https://via.placeholder.com/120x160", "Titre"),
                getDefaultSlideCategory("https://via.placeholder.com/120x160", "Titre")
            ]
        }
    }
};