import React, { useState } from 'react';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import Paginator from 'react-hooks-paginator';
import ShopSidebar from '../../wrappers/product/ShopSidebar';

const Landing = ({  
    preview = false,  
    id = null, 
    data = null,
    params = null,
    inputCallback = null,
    categories = [],
    products = [],
    category =  null,
    spread = false,
    children = null
}) => {
    const [layout, setLayout] = useState('grid two-column');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('');
    const [sortValue, setSortValue] = useState('');

    const pageLimit = 6;

    const getLayout = (layout) => {
        setLayout(layout)
    };

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    };

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    };

    let directCategories = [];
    let allProducts = [];
    let filteredProducts = [];

    if (category) {
        // SORTING

        directCategories = categories.filter(e => e.parent && e.parent === category.id);
        let allSubCategories = [category.id];
        let roots = categories.filter(e => e.parent && e.parent === category.id);

        const populate = (parent) => {
            parent.children = categories.filter(e => e.parent === parent.id);

            allSubCategories.push(parent.id);

            for (let child of parent.children) {
                populate(child);
            }
        };

        for (let root of roots) {
            populate(root);
        }

        allProducts = products.filter(p => p.categories.some(c => allSubCategories.includes(c)));

        // FILTERING

        let newOffset           = currentPage === 1 ? 0 : (pageLimit * (currentPage - 1));
        filteredProducts        = allProducts.slice(newOffset, newOffset + pageLimit);
    } else {
        // SEARCH PAGE

        allProducts = products;

        let newOffset           = currentPage === 1 ? 0 : (pageLimit * (currentPage - 1));
        filteredProducts        = products.slice(newOffset, newOffset + pageLimit);
    }

    if (spread) {
        allProducts = products;

        let newOffset       = currentPage === 1 ? 0 : (pageLimit * (currentPage - 1));
        filteredProducts    = allProducts.slice(newOffset, newOffset + pageLimit);
    }

    return (
        <div style={{
            width: '100%',
            marginBottom: 20,
            backgroundColor: data.inputs['backgroundColor'].value,
        }}>
            <div className="builder-template-scope shop-area">
                { !preview && children }

                <div className="container pt-40 pb-20">
                    <div className="row">
                        {
                            directCategories?.length > 0 && (
                                <div className="col-lg-3 order-2 order-lg-1">
                                    {/* shop sidebar */}
                                    <ShopSidebar spread={spread} categories={directCategories} getSortParams={getSortParams} sideSpaceClass="mr-30"/>
                                </div>
                            )
                        }

                        <div className={directCategories?.length > 0 ? "col-lg-9 order-1 order-lg-2" : "col-lg-12 order-1 order-lg-12"}>
                            {/* shop topbar default */}
                            <ShopTopbar 
                                getLayout={getLayout} 
                                getFilterSortParams={getFilterSortParams} 
                                productCount={allProducts.length} 
                                sortedProductCount={filteredProducts.length}
                                data={data}
                            />

                            {/* shop page content default */}
                            <ShopProducts layout={layout} products={filteredProducts} spread={spread} data={data} params={params} />

                            {/* shop product pagination */}
                            <div className="pro-pagination-style text-center mt-30">
                                <Paginator
                                    totalRecords={allProducts.length}
                                    pageLimit={pageLimit}
                                    pageNeighbours={2}
                                    setOffset={setOffset}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageContainerClass="mb-0 mt-0"
                                    pagePrevText="«"
                                    pageNextText="»"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;