import noImageCompany from '../../../../../assets/images/emptyCompany.jpg';
import CardGroupRate from '../../../../layouts/Card/cardContent/CardGroupRate';

export const listSettings = {
    grid : {
        components : CardGroupRate,
        columns : {
            xs : 3,
            sm : 4,
            laptop : 4,
        }
    },
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    // {
    //     view : 'table',
    //     mapper : 'companiesListMapper'
    // }
]

export const perPageOptions = {
    'card' : [6],
}

