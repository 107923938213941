import CardNotifs from "../../../../../layouts/Card/cardContent/CardNotifs"

export const listSettings = {
    grid : {
        components : CardNotifs,
        columns : {
            xs : 1,
            sm : 2,
            md: 2,
            laptop : 3,
        },
        layout:{
            spacing : 2,
        }
    },
}

export const listMappers = [
    {
        view : 'card',
        mapper : 'notificationMapper'
    }
]

export const perPageOptions = {
    'card' : [12,24,36]
}
