import UpdateIcon from '@material-ui/icons/Update';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SyncIcon from '@material-ui/icons/Sync';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import GetAppIcon from '@material-ui/icons/GetApp';

const muiIconList = {
    'stock' : UpdateIcon,
    'update' : UpdateIcon,
    'stats' : EqualizerIcon,
    'basket' : ShoppingBasketIcon,
    'saveAlt' : SaveAltIcon,
    'sync' : SyncIcon,
    'settings' : SettingsInputComponentIcon,
    'download': GetAppIcon,
}

export default muiIconList;