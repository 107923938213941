import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getImageDefault } from '../../helpers';
import { getDefaultText, getDefaultTextarea, getDefaultLink } from '../../helpersBook';

export default {
    name: "Section d'explication",
    blocks: {
        imageHow: getImageDefault(null, "Image titre explications"),
        // explanation: {
        //     id: uuidv4(),
        //     name: "Bloc d'explication",
        //     type: EditableTypes.MULTIPLE_BLOCKS,
        //     inputs: {
        //         backgroundColor: {
        //             label: 'Couleur de fond',
        //             type: FormInputTypes.COLOR,
        //             value: '#fff'
        //         },
        //         backgroundImage: {
        //             label: 'Image de fond',
        //             type: FormInputTypes.IMAGE,
        //             value: null
        //         },
        //         border: {
        //             type: FormInputTypes.GROUP,
        //             label: "Border",
        //             inputs: {
        //                 borderColor: {
        //                     type: FormInputTypes.COLOR,
        //                     value: '#53839D',
        //                     label: "Couleur de la bordure"
        //                 },
        //                 borderWidth: {
        //                     type: FormInputTypes.SLIDER,
        //                     value: 0,
        //                     label: "Largeur de la bordure :",
        //                     params: {max: 5, min: 0, step: 1}
        //                 },
        //                 borderRadius: {
        //                     type: FormInputTypes.SLIDER,
        //                     value: 0,
        //                     label: "Arrondi des angles :",
        //                     params: {max: 50, min: 0, step: 1}
        //                 }
        //             }
        //         },
        //     },
        //     blocks:{
        //         rule1: getDefaultTextarea("Règle 1", "Explications du jeu", true),
        //         rule2: getDefaultTextarea("Règle 2", "Explications du jeu", true),
        //         rule3: getDefaultTextarea("Règle 3", "Explications du jeu", true),
        //     }
        // },
        rule1: getDefaultTextarea("Règle 1", "Explications du jeu", true),
        rule2: getDefaultTextarea("Règle 2", "Explications du jeu", true),
        rule3: getDefaultTextarea("Règle 3", "Explications du jeu", true),
        button: getDefaultLink("Bouton", "Créer ma liste"),
        copyright: getDefaultTextarea("Copyright", "LEGO, le logo LEGO, les Minifigures, DUPLO, NINJAGO, le logo LEGO Friends et Mindstorms sont des marques déposées et/ou sous copyright du Groupe LEGO. ©2021 the LEGO Group. © &amp; ™ Lucasfilm Ltd.", true),
    }
};
