import React, { useState, useEffect } from 'react';

export const EmailContact = ({ data, spread }) => {
  let blocks = data.blocks;

  return (
    <center>
      <table width="600" style={{ borderCollapse: 'collapse', width: 600 }} cellpadding="0" cellspacing="0">
        {/* Main */}
        <tr>
          <td
            style={{
              backgroundColor: data?.inputs.backgroundColor.value,
              backgroundImage: typeof data?.inputs.backgroundImage.value === 'string' ? `url(${data?.inputs.backgroundImage.value})` : data?.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${data?.inputs.backgroundImage.value.filePath})` : 'none',
              backgroundSize: 'cover'
            }}
          >
            <table width="600">
              <tr>
                <td style={{ height: 100 }}></td>
              </tr>
              <tr>
                <td width="8%"></td>
                <td
                  width="84%"
                  style={{ 
                    background: blocks?.firstBlock.inputs.backgroundColor.value, 
                    borderRadius: blocks?.firstBlock.inputs.border.inputs.borderRadius.value, 
                    padding: '20px 9px' 
                  }}
                >
                  <table width="500">
                    <tr width="500">
                      <td width="500" style={{ textAlign: 'center' }}>
                        <img
                          style={{ maxHeight: 100 }}
                          src={
                            typeof blocks?.logo.inputs.value.value === 'string'
                              ? blocks?.logo.inputs.value.value
                              : blocks?.logo.inputs.value?.value?.filePath
                                  ? `${process.env.REACT_APP_MEDIAS}/${blocks?.logo.inputs.value.value.filePath}`
                                  : '/img/not-found.png'
                          }
                          alt="Logo"
                        />
                      </td>
                    </tr>
                    <tr width="500">
                      <td width="500" style={{ 
                        fontFamily: 'Arial, Helvetica, sans-serif', 
                        textAlign: blocks?.title.inputs.textAlign?.value, 
                        color: blocks?.title.inputs.color?.value, 
                        backgroundColor: blocks?.title.inputs.backgroundColor?.value, 
                        fontSize: `${blocks?.title.inputs.size?.value}px`, 
                        lineHeight: '1.2', 
                        textTransform: blocks?.title.inputs.textTransform?.value, 
                        fontWeight: 'bold', 
                        paddingTop: 45, 
                        paddingLeft: 50, 
                        paddingRight: 50 
                      }}>
                        {blocks?.title.inputs.value.value}
                      </td>
                    </tr>
                    <tr>
                      <td width="600" style={{ 
                        fontFamily: 'Arial, Helvetica, sans-serif', 
                        color: blocks?.object.inputs.color.value, 
                        textAlign: blocks?.object.inputs.textAlign.value, 
                        textTransform: blocks?.object.inputs.textTransform.value, 
                        fontSize: blocks?.object.inputs.size.value, 
                        lineHeight: '1.2', 
                        paddingTop: 25 
                      }}>
                        {blocks?.object.inputs.value.value} {spread ? 'Lorem ipsum dolor sit amet' : '%object%'}
                      </td>
                    </tr>
                    <tr width="500">
                      <td width="500" style={{ 
                        fontFamily: 'Arial, Helvetica, sans-serif', 
                        color: blocks?.message.inputs.color.value, 
                        textAlign: blocks?.message.inputs.textAlign.value, 
                        textTransform: blocks?.message.inputs.textTransform.value, 
                        fontSize: blocks?.message.inputs.size.value, 
                        lineHeight: '1.2', 
                        paddingTop: 16, 
                        paddingLeft: 50, 
                        paddingRight: 50 
                      }}>
                        {spread ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis molestie urna ante, non euismod mi hendrerit eget. Aliquam erat volutpat.' : '%message%'}
                      </td>
                    </tr>
                    <tr>
                      <td width="600" style={{ 
                        fontFamily: 'Arial, Helvetica, sans-serif', 
                        color: blocks?.name?.inputs.color.value, 
                        textAlign: blocks?.name?.inputs.textAlign.value, 
                        textTransform: blocks?.name?.inputs.textTransform.value, 
                        fontSize: blocks?.name?.inputs.size.value, 
                        lineHeight: '1.2', 
                        paddingTop: 25 
                      }}>
                        {blocks?.name?.inputs.value.value} {spread ? 'John Doe' : '%name%'}
                      </td>
                    </tr>
                    <tr>
                      <td width="600" style={{ 
                        fontFamily: 'Arial, Helvetica, sans-serif', 
                        color: blocks?.email.inputs.color.value, 
                        textAlign: blocks?.email.inputs.textAlign.value, 
                        textTransform: blocks?.email.inputs.textTransform.value, 
                        fontSize: blocks?.email.inputs.size.value, 
                        lineHeight: '1.2', 
                        paddingTop: 10 
                      }}>
                        {blocks?.email.inputs.value.value} {spread ? 'john.doe@website.fr' : '%email%'}
                      </td>
                    </tr>
                  </table>
                </td>
                <td width="8%"></td>
              </tr>
              <tr><td style={{ height: 50 }}></td></tr>
            </table>
          </td>
        </tr>
        {/* Footer */}
        <tr>
          <td>
            <table width="100%" style={{
              backgroundColor: blocks?.footer.inputs.backgroundColor.value,
              backgroundImage: typeof blocks?.footer.inputs.backgroundImage.value === 'string' ? `url(${blocks?.footer.inputs.backgroundImage.value})` : blocks?.footer.inputs.backgroundImage.value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blocks?.footer.inputs.backgroundImage.value.filePath})` : 'none',
              backgroundSize: 'cover',
            }}>
              <tr>
                <td width="100%" style={{ textAlign: blocks?.footerText.inputs.textAlign.value, textTransform: blocks?.footerText.inputs.textTransform.value, fontFamily: 'Arial, Helvetica, sans-serif', color: blocks?.footerText.inputs.color.value, fontSize: blocks?.footerText.inputs.size.value, lineHeight: '1.2', fontWeight: '500', padding: '32px 20px' }}>
                  <div dangerouslySetInnerHTML={{ __html: blocks?.footerText.inputs.value.value }} />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  )
}

export default EmailContact;