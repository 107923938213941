import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { Grid, InputLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import Button from '../../../../ui/button/Button';
import InputBuilder from '../../../../ui/form/InputBuilder';
import colors from '../../../../../config/theme/colors';

import styled from 'styled-components';

const DeleteButton = withStyles(theme => ({
    root: {
        color: colors.red.regular,
        backgroundColor: 'transparent',
        minHeight: 10,
        alignSelf: 'flex-end',
        textTransform: 'inherit',
        borderRadius: 0,
        width: 'auto',
        boxShadow: 'inherit',
        '&:hover': {
            color: colors.red.darker,
            backgroundColor: 'transparent',
        }
    },
}))(Fab);


const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;

const GridContainSwitch = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        marginTop: -10,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

export default function FooterLinks ({ idLink, id, element, inputCallback, deleteLink }) {
    let nameInput = {
        required : true,
        type : 'text',
        label : 'Nom',
        stateName : 'name',
        helper: {
            text: null,
            link: false,
        },
    };
    let linkInput = {
        required : true,
        type : 'text',
        label : 'Lien',
        stateName : 'link',
        helper: {
            text: null,
            link: false,
        },
    };
    let linkIsExternal = {
        type: 'switch',
        label: 'Par défault',
        helper: {
            label: 'État',
            text: 'Indiquez l\'état',
            link: false,
        },
        required: true,
        stateName: 'isExternal',
        value: [
            {
                value: true,
                label: 'Actif',
            },
            {
                value: false,
                label: 'Inactif',
            },
        ]
    };

    return (
        <div>
            <Grid container direction="row" spacing={1}>
                <GridFlexCenter item xs={1} style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 12}}>
                    <InputLabelCustom>{nameInput.label}</InputLabelCustom>
                </GridFlexCenter>
                <InputBuilder xs={4} value={element.links[idLink].name} input={nameInput} stateCallback={(evt) => {inputCallback(evt, id, 'name', 1, idLink)}} /> 
                <GridFlexCenter item xs={1} style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 12}}>
                    <InputLabelCustom>{linkInput.label}</InputLabelCustom>
                </GridFlexCenter>
                <InputBuilder xs={4} value={element.links[idLink].link} input={linkInput} stateCallback={(evt) => {inputCallback(evt, id, 'link', 1, idLink)}} />
                <GridContainSwitch item xs={1}>
                    <BootstrapTooltip title="Lien externe">
                        <div>
                            <InputBuilder xs={1} value={element.links[idLink].isExternal || false} input={linkIsExternal} handleButtonGroupChange={(stateName, value) => {inputCallback(value, id, 'isExternal', 1, idLink)}} />
                        </div>
                    </BootstrapTooltip>
                </GridContainSwitch>
                <Grid item xs={1} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 20}}>
                    <DeleteButton style={{marginTop: 8}} color="secondary" size="small" aria-label="delete" onClick={(event) => {deleteLink(id, idLink) }}>
                        <DeleteIcon style={{ width: 28 }} />
                    </DeleteButton>
                </Grid>
            </Grid>
        </div>
    );
}