import React, {useEffect} from 'react';
import * as moment from 'moment';
import colors from '../../../../../config/theme/colors';
import request from '../../../../../js/utils/fetch';
import styled from 'styled-components';
import { Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';
import CardCustom from '../../../../layouts/Card/CardCustom';
import { spaceOnNumbers } from '../../../../../js/utils/spaceOnNumbers';
import { ArrowDropDown, ArrowDropUp, Create, Restore, Warning } from '@material-ui/icons';
import { BoxCustom } from '../../stats/components/styledComponents';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import StyleIcon from '@material-ui/icons/Style';
import { ROUTE_PRODUCTS_WORKFLOW_DELAYED, ROUTE_PRODUCTS_WORKFLOW_ERRORS, ROUTE_PRODUCTS_WORKFLOW_TO_COMPLETE,ROUTE_PRODUCTS_WORKFLOW_MANUALLY } from '../../../../../js/constants/route-names';

const Container = styled(Grid)`
    height: 100%;
`;

const ContainerData = styled(Grid)`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
`;
const ContainerDataClick = styled(ContainerData)`
    cursor: pointer;
`;

const BigText = styled(Typography)`
    @media screen and (max-width: 1600px){
        strong span{
            font-size: 28px !important;
        }
    }
    @media screen and (max-width: 1450px){
        strong span{
            font-size: 26px !important;
        }
    }
`;
const Title = styled(Typography)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1450px){
        font-size: 12px !important;
    }
`;
const ContainerPriority = styled(Box)`
    p{
        font-size: 12px;
    }
`;

const realTimeBoxStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: "noWrap",
            flexDirection: "column-reverse",
            gap: "20px"
        },
    },
}))

function StatsProducts(props) {
    const [loading, setLoading] = React.useState(true);
    const [stats, setStats] = React.useState(null);

    const getRealTime = () => {
        const date = `${moment().format('dddd DD MMM YYYY [-] HH:mm:ss')}`
        const formattedDate = date[0].toUpperCase() + date.substring(1);
        return formattedDate;
    }

    const goTo = (route, id) => {
        props.history.push({
            pathname : route
        });
    };

    useEffect(() => {
        request(`${process.env.REACT_APP_API_ROOT}/dashboard-products.php`, 'POST', null, null, false).then((result) => {
            setStats(result)
        }).finally(() => {
            setLoading(false)
        });
    }, []);

    const getPercentage = (value1, value2) => {
        const percentage = Math.round(100 - (((value1 * 100) / value2)))
        if (isNaN(percentage)) {
            return "N/A"
        }
        return percentage + " %"
    }

    const realTimeBoxClass = realTimeBoxStyles()

    return (
        <Box pb={props.marginBottom?0:2}>
            <AccordionCustom title={<Typography variant={"h4"} style={{ fontSize: 14 }}>{props.t('dashboard.realtime')} : <strong>{getRealTime()}</strong></Typography>}>
                {
                    !loading ?
                        <Grid container direction="row" justifyContent="space-between">
                            {/* Taux de complétude */}
                            <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={0.5} pr={0.5} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container className={realTimeBoxClass.root}>
                                            <ContainerData item xs={12}>
                                                <Title variant="body1">
                                                    <strong>Complétude</strong>
                                                    <BoxCustom position="relative" maxHeight={20} width={20} display="flex" justifyContent={"center"} statuscolor={"positive"}>
                                                        <CircularProgress
                                                            variant="determinate"
                                                            value={100}
                                                            disableShrink
                                                            style={{ maxHeight: 20, maxWidth: 20, width: "100%", height: "auto" }}
                                                            thickness={2.5}
                                                        />
                                                    </BoxCustom>
                                                </Title>
                                                <Box mt={0.5}>
                                                    <BigText variant="body2"><strong><span style={{ fontSize: 35, lineHeight: '25px', color: colors.green.regular }}>{getPercentage(stats.products.to_complete.total, stats.products.total)}</span></strong></BigText>
                                                </Box>
                                            </ContainerData>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                            {/* Delais de validation */}
                            <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={1} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container justifyContent='space-between' className={realTimeBoxClass.root}>
                                            <ContainerData item xs={12}>
                                                <Title variant="body1">
                                                    <strong>Validation</strong>
                                                    <QueryBuilderIcon style={{fill: colors.black.regular, fontSize: 24}}/>
                                                </Title>
                                                <Box mt={0.5}>
                                                    <BigText variant="body2"><strong><span style={{ fontSize: 33, lineHeight: '25px' }}> {stats?.velocity} heures</span></strong></BigText>
                                                </Box>
                                            </ContainerData>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                             {/* Nb total produit */}
                             <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={1} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container justifyContent='space-between'>
                                            <ContainerDataClick item xs={12} onClick={() => goTo(ROUTE_PRODUCTS_WORKFLOW_MANUALLY)}>
                                                <Title variant="body1">
                                                    <strong>A enrichir</strong>
                                                    <Create style={{fill: colors.black.regular, fontSize: 24}}/>
                                                </Title>
                                                <Box mt={0.5}>
                                                    <BigText variant="body2"><strong><span style={{ fontSize: 33, lineHeight: '25px', color: colors.black.regular }}>{stats?.products?.to_complete?.total}/{stats?.products?.total}</span></strong></BigText>
                                                </Box>
                                            </ContainerDataClick>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                            {/* Produit en retard */}
                            <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={1} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container justifyContent='space-between' className={realTimeBoxClass.root}>
                                            <ContainerDataClick item xs={12} onClick={() => goTo(ROUTE_PRODUCTS_WORKFLOW_DELAYED)} >
                                                <Title variant="body1">
                                                    <strong>En retard</strong>
                                                    <Restore style={{fill: colors.black.regular, fontSize: 24}}/>
                                                </Title>
                                                <Box mt={0.5}>
                                                    <BigText variant="body2"><strong><span style={{ fontSize: 33, lineHeight: '25px', color: colors.black.regular }}>{stats?.products?.delay?.total}/{stats?.products?.total}</span></strong></BigText>
                                                </Box>
                                            </ContainerDataClick>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                            {/* Erreur produit */}
                            <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={1} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container justifyContent='space-between' className={realTimeBoxClass.root}>
                                            <ContainerDataClick item xs={12} onClick={() => goTo(ROUTE_PRODUCTS_WORKFLOW_ERRORS)} >
                                                <Title variant="body1">
                                                    <strong>En erreur</strong>
                                                    <Warning style={{fill: colors.red.regular, fontSize: 24}}/>
                                                </Title>
                                                <Box mt={0.5}>
                                                    <BigText variant="body2"><strong><span style={{ fontSize: 33, lineHeight: '25px', color: colors.red.regular }}>{stats?.products?.errors?.total}/{stats?.products?.total}</span></strong></BigText>
                                                </Box>
                                            </ContainerDataClick>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                            {/* Priorité */}
                            <Grid item style={{width: "calc(100% / 6)"}}>
                                <Box pl={1} height={"100%"}>
                                    <CardCustom style={{ width: "100%", height: "100%" }} cardContentStyle={{ height: "100%", paddingBottom: 16 }} paddingtop={'48px'}>
                                        <Container container justifyContent='space-between' className={realTimeBoxClass.root} style={{height: "100%"}}>
                                            <ContainerData item xs={12} style={{height: "100%", display: "flex", flexDirection: "column"}}>
                                                <Title variant="body1">
                                                    <strong>Priorité</strong>
                                                </Title>
                                                <Box mt={0.5} display="flex" flex={1} flexDirection="column" justifyContent={"space-between"} maxHeight={58}>
                                                    <ContainerPriority display={"flex"} alignItems={"center"} justifyContent={"space-between"} maxWidth={140}>
                                                        <Typography variant="body2">Haute : </Typography>
                                                        <Typography variant="body2" style={{marginLeft: 4}}><strong style={{ color: colors.red.regular }}>{stats?.priorities?.high?.done}/{stats?.priorities?.high?.total}</strong></Typography>
                                                    </ContainerPriority>
                                                    <ContainerPriority display={"flex"} alignItems={"center"} justifyContent={"space-between"} maxWidth={140}>
                                                        <Typography variant="body2">Moyenne : </Typography>
                                                        <Typography variant="body2" style={{marginLeft: 4}}><strong style={{ color: "#FF8217" }}>{stats?.priorities?.middle?.done}/{stats?.priorities?.middle?.total}</strong></Typography>
                                                    </ContainerPriority>
                                                    <ContainerPriority display={"flex"} alignItems={"center"} justifyContent={"space-between"} maxWidth={140}>
                                                        <Typography variant="body2">Basse : </Typography>
                                                        <Typography variant="body2" style={{marginLeft: 4}}><strong style={{ color: colors.green.regular }}>{stats?.priorities?.low?.done}/{stats?.priorities?.low?.total}</strong></Typography>
                                                    </ContainerPriority>
                                                </Box>
                                            </ContainerData>
                                        </Container>
                                    </CardCustom>
                                </Box>
                            </Grid>
                        </Grid>
                        : <PageLoader />
                }
            </AccordionCustom>
        </Box>
    );
}

export default withTranslation()(withRouter(connect(null, null)(StatsProducts)));