import React from 'react';
import { Context } from "../shareable/store";
import { BuilderContext } from "../builder";
import styled from "styled-components";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from '../../components/ui/button/Button';
import { ArrowForwardIos } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getTemplate } from "../templates";
import colors from "../../config/theme/colors";

const SelectCustom = styled(Select)`
    &>div{
        border-radius: 0;
        border: 0;
        div{
            &[class*=singleValue]{
                color: ${colors.black.regular};
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &[class*=IndicatorsContainer]{
                position: relative;
                &>div{
                    color: ${colors.black.regular};
                    padding-right: 16px;
                }
                &>span{
                    margin-bottom: 5px;
                    margin-top: 5px;
                    width: 1px;
                    height: calc(100% - 10px);
                    right: 0;
                    position: absolute;
                }
            }
        }
    }
`;

export const BuilderContent = () => {
    const { 
        asset, 
        ready,
        catalog,
        products, updateProducts,
        data,
        page,
        onlyContent,
        noPanel, setNoPanel,
        pages, 
        pagesImg,
        getPageName,
        changePage,
        height,
        presentation, presentationModel, offer,
        exportLink,
        dev,
        currentTab,
        assetPageName
    } = React.useContext(BuilderContext);

    const [openChangerPage, setOpenChangerPage] = React.useState(false);

    const animatedComponents = makeAnimated();

    const PageChanger = () => {
        return (
            <div className="changer-page">
                <div className="selecter" onClick={() => setOpenChangerPage(!openChangerPage)}>
                    <p>{ getPageName(page) }</p>

                    {
                        openChangerPage 
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />
                    }
                </div>

                {
                    openChangerPage ? (
                        <div className="dynamicChangerPage">
                            <CloseIcon style={{ fill: 'white' }} onClick={() => setOpenChangerPage(!openChangerPage)} />

                            {
                                pages && asset && pages.map((page, index) => {
                                    return (
                                        <div key={index} onClick={() => changePage(page.value)}>
                                            <img
                                                style={{ width: ((pages.length % 3 === 0 && pages.length < 10)|| (pages.length < 6 && pages.length !== 4)) ? '26vw' : '25vw', height: pages.length < 9 ? '40vh' : '30vh' }}
                                                src={typeof page.image === "string" ? page.image : page.image?.default}
                                            />
                                            <p>{ page.label }</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
        )
    };

    const PageSelect = () => {
        return (
            <SelectCustom
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                defaultValue={pages.find(e => e.value === page)}
                options={pages}
                isMulti={false}
                isSearchable={false}
                placeholder="Changer de page..."
                noOptionsMessage={() => "Aucun résultat"}
                components={{ ...animatedComponents }}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        width: 220,
                        background: 'transparent',
                        color: 'white',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        zIndex: 150,
                        padding: 0
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        color: 'white',
                    })
                }}
                onChange={(item) => changePage(item.value)}
            />
        );
    };
    
    return (
        <div className={`builder-page ${onlyContent || noPanel ? 'full-width' : ''}`} style={{height}}>
            <div className="top-bar" style={{display: onlyContent ? 'none' : 'flex'}}>
                {
                    pages.length
                        ? pagesImg === pages.length
                            ? <PageChanger /> 
                            : <PageSelect />
                        : null
                }

                { (presentation || presentationModel) && (
                    <CopyToClipboard text={`https://presentation.sinfin.fr/${offer?.alias}`} onCopy={exportLink}>
                        <Button className="builder-link-button" colortext="white" bgcolor="transparent">
                            <LinkIcon />
                        </Button> 
                    </CopyToClipboard>
                )}

                {
                    noPanel && <div className="builder-expand-button" onClick={() => setNoPanel(false)}>
                        <ArrowForwardIos />
                    </div>
                }

                <div className="top-bar-text">
                    Builder™{assetPageName ? ` : ${assetPageName}` : ''}
                </div>
            </div>

            <div 
                className="content" 
                style={{ 
                    top: onlyContent ? 0 : 42, 
                    height: onlyContent 
                        ? "100%" 
                        : "calc(100% - 42px)" 
                }}
            >
                <Context.Provider value={{ products, update: (products) => updateProducts(products) }}>
                    { 
                        ready && (presentation ? offer : dev ? true : catalog) 
                            ? getTemplate(...data, currentTab) 
                            : <p className="builder-loading">Chargement...</p> 
                    }
                </Context.Provider>
            </div>
        </div>
    );
}