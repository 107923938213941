import { EditableTypes, FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms, getDefaultTextareaCms, getDefaultTextareaSpecialCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

const blocTextAndMedias = () => {
    return {
        id: uuidv4(),
        name: 'Bloc de texte et media',
        inputs:{
            identifiant:{
                label: 'Identifiant',
                type: FormInputTypes.TEXT,
                value: null,
                id: uuidv4(),
            },
            maxWidth: {
                type: FormInputTypes.SLIDER,
                value: 1920,
                label: "Largeur maximum du container",
                params: {max: 1920, min: 1280, step: 10},
            },
            backgroundColor: {
                label: 'Couleur de fond',
                type: FormInputTypes.COLOR,
                value: '#FFF'
            },
            backgroundImage: {
                label: 'Image de fond',
                type: FormInputTypes.IMAGE,
                value: null
            },
            positionMedia: {
                label: 'Media avant le bloc texte',
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: 'right', label: 'À droite'},
                    {value: 'left', label: 'À gauche'},
                    {value: 'bottom', label: 'En bas'},
                    {value: 'top', label: 'En haut'},
                ],
                value: 'right'
            },
            widthMedia: {
                label: 'Taille du média',
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: 'untier', label: '1/3'},
                    {value: 'demi', label: '1/2'},
                    {value: 'tier', label: '2/3'},
                ],
                value: 'demi'
            },
            showMedia: {
                label: 'Afficher le media',
                type: FormInputTypes.SWITCH,
                value: true,
            },
            positionButton: {
                label: 'Positionner les boutons sous le bloc',
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: 'texte', label: 'de texte'},
                    {value: 'image', label: "d'image"},
                ],
                value: 'texte'
            },
            centerButton: {
                label: 'Centrer les boutons',
                type: FormInputTypes.SWITCH,
                value: true,
            },
            imageFull: {
                label: 'Image full',
                type: FormInputTypes.SWITCH,
                value: false,
            },
            paddinghorizontal: {
                label: 'Marge intérieure du texte (horizontale)',
                type: FormInputTypes.SLIDER,
                params: {max: 200, min: 0, step: 1},
                value: 50,
            },
            paddingvertical: {
                label: 'Marge intérieure du texte (verticale)',
                type: FormInputTypes.SLIDER,
                params: {max: 200, min: 0, step: 1},
                value: 50,
            },
            optimisedImage:{
                label: 'Utiliser des images optimisées',
                type: FormInputTypes.SWITCH,
                value: false,
                id: uuidv4(),
            },
        },
        blocks:{
            title: getDefaultTextCms("Titre", "Titre principal", '#000', 61, 'px', 'uppercase', 'initial'),
            subtitle: getDefaultTextCms("Sous-titre", "La marque de sport référence des français depuis 1882 …", '#000', 31, 'px', 'uppercase', 'initial'),
            text: getDefaultTextareaSpecialCms("Texte", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sem dui, tristique a semper vitae, rutrum id lacus. Morbi at dictum nunc, quis consectetur leo. Nulla interdum massa vestibulum elit venenatis laoreet. Nam eu dictum dui. Maecenas nec nibh mattis, tempus augue et, convallis tortor.", true, '#000', '#FFF', 'initial', 'initial', 23, 'px', 0, 0, true),
            link: {
                title: "Lien",
                name: "Lien",
                type: EditableTypes.MULTIPLE_BLOCKS,
                subtype: ItemTypes.CMS_LOOP_LINK,
                buttonAdd: 'un lien',
                limit: 4,
                config: [
                    getDefaultLinkCms("Lien", "Information", "https://www.sinfin.fr/", '#000', '#FFF', true, null, 1,'flex-start'),
                ]
            },
            media: {
                type: EditableTypes.IMAGE,
                name: "Image",
                id: uuidv4(),
                inputs: {
                    value: {
                        type: FormInputTypes.IMAGE,
                        value: "https://via.placeholder.com/1040x640"
                    },
                    alt: {
                        type: FormInputTypes.TEXT,
                        value: null,
                        label: "Texte alternatif"
                    },
                    optimisedImage:{
                        label: 'Lecteur vidéo (seulement pour les vidéos)',
                        type: FormInputTypes.SWITCH,
                        value: false,
                        id: uuidv4(),
                    },
                    height: {
                        type: FormInputTypes.SLIDER,
                        value: 0,
                        label: "Hauteur de l'image",
                        params: {max: 800, min: 0, step: 10},
                    },
                }
            },
        }
    }
}

export default blocTextAndMedias;
