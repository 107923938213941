import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function cmsAddConfig() {
    let generalInputs = [
        {
            type: 'text',
            label: 'Libelle',
            helper: {
                text: 'Indiquez le nom',
                link: false,
            },
            required: true,
            stateName: 'libelle',
        },
        {
            type: 'text',
            label: 'Identifiant',
            translated: false,
            helper: {
                text: "Identifiant de l'attribut",
                link: false,
            },
            required: true,
            stateName: 'attributeIdentifier',
        },
        {
            type: 'textarea',
            label: 'Description',
            helper: {
                text: 'Entrez ici une description',
                link: false,
            },
            required: true,
            stateName: 'description',
        }
    ]
    let children = [
        {
            labelName: 'Initialisation',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
        {
            labelName: 'Paramétrage',
            isOptionnal: false,
            optionsInputs:[]
        },
    ];

    var obj = {
        titleForm: '',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour créer votre connecteur',
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep:{},
            children
        }
    };
    return obj;
}