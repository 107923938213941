import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Grid,Box, Typography, FormControl, InputLabel, Select, MenuItem, makeStyles} from '@material-ui/core';
import { withApollo } from 'react-apollo';
import Pagination from '@material-ui/lab/Pagination';

import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import emptyPicto from '../../../../assets/pictos/empty-picto/empty_stats.png';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import Stats from '../../../layouts/Stats/Stats'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FaceIcon from '@material-ui/icons/Face';
import LineCharts from '../../../layouts/Stats/LineCharts';
import BarCharts from '../../../layouts/Stats/BarCharts';
import CardCustom from '../../../layouts/Card/CardCustom';

import { GET_ASSET_BY_ID, GET_ASSET_BY_ID_NAVIGATION, GET_ASSET_WISHLISTS, GET_ASSET_WISHLISTS_CURSORS } from '../../../../queries/assets';
import { GET_PRODUCTS_PAGINATION } from '../../../../queries/products';
import { GET_GAME_PLAYS, GET_WIN_INSTANT_BY_ASSET_CURSOR, GET_WIN_INSTANT_BY_ASSET } from '../../../../queries/asset_games';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import * as moment from 'moment';
import 'moment-timezone';
import { eventService } from '../../../../js/services/event.service';
import styled from 'styled-components';
import ArrayStats from './components/ArrayStats';
import request from '../../../../js/utils/fetch';

import { BUILDER, BUILDER_ASSETS, VIEW } from '../../../../js/constants/constant-rights';
import { v4 as uuidv4 } from "uuid";
import axios from '../../../../js/utils/axios';
import { saveAs } from 'file-saver';
import Button from '../../../ui/button/Button';
import { checkRouting } from '../../../../js/utils/checkRouting';
import StatsRetailers from '../stats/StatsRetailers';
import GlobalView from './components/GlobalView';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const PanelItem = styled.div `
    padding-top:40px;
`

const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`

const PaginationCustom = styled(Pagination)`
    ul{
        justify-content: center; 
        margin-top: 24px;
    }
`;

const CustomSelect = styled(Select)`
    
`;

 const inputStyles = makeStyles({
    input: {
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        minWidth: '200px',
    }
})



class EngineAssetsDetailsPageStats extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            styleInnerContainer : {
                boxShadow: 'none',
                backgroundInner: colors.white,
                backgroundActions: colors.grey.lighter.hue980,
                padding: '0px',
                height: '630px'
            },
            allPlayers: [],
            allInstants: [],
            openFormEdit : false,
            openFormEditGifts : false,
            openFormFeedAdd:false,
            openDialog : false,
            idAsset: `/api/assets/${this.props.match.params.id}`,
            assetData : null,
            imageAsset : null,
            errors: {},
            seeErrors: false,
            ready: false,
            drawerWidthModified : props.drawerWidth,
            currentLang: props.locales[0].node.code,
            disabledLink    : false,
            toDelete        : null,
            openMediaPicker: false,
            postType: true,
            currentLocale: props.locales[0].node.code,
            tabValue:0,
            stats:[
                {
                    icon: 'picto-profil',
                    dataColor: colors.black.regular,
                    subtitle: 'Participations',
                    gradientColor1: colors.black.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'gamePlays'
                },
                {
                    icon: 'account', 
                    dataColor: colors.black.regular,
                    subtitle: 'Participations Confirmées',
                    gradientColor1: colors.black.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'uniquePlays'
    
                },
                // {
                //     icon: 'icon-win',
                //     statType : 'gameWins',
                //     dataColor: colors.black.regular,
                //     subtitle: 'Lots remportés',
                //     gradientColor1: colors.black.regular,
                //     gradientColor2: colors.blue.lighter,
                //     dataSize:'h3',

                // },
                // {
                //     icon: 'icon-gift',
                //     dataColor: colors.black.regular,
                //     subtitle: 'Lots restants',
                //     gradientColor1: colors.black.regular,
                //     gradientColor2: colors.blue.lighter,
                //     dataSize:'h3',
                //     statType : 'remaingGifts'
                // },
                // {
                //     icon: 'icon-calendrier',
                //     dataColor: colors.black.regular,
                //     subtitle: 'Prochain Lot',
                //     gradientColor1: colors.black.regular,
                //     gradientColor2: colors.blue.lighter,
                //     dataSize:'h3',
                //     statType : 'nextGift'
                // },
            ],
            chartDatas : [],
            dates : [],
            perPlays : [],
            allDatas:[
                    {
                        data: [],
                        color: '#147FAB',
                        title: 'Participant.e.s',
                    },
                    {
                        data: [],
                        color: '#E51C52',
                        title: 'Participant.e.s uniques',
                    }
            ],
            allDatasBar:[
                    {
                        data: [],
                        color: '#147FAB',
                        title: 'Participant.e.s',
                    },
            ],
            playersCount:'',
            nbperpage: 10,
            countPage: 0,
            cursor: false,
            page: 1,
            exportType:1
        };

    }

    initNavAsset = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query:GET_ASSET_BY_ID_NAVIGATION,
                variables:{id: `/api/assets/${this.props.match.params.id}`},
                fetchPolicy:'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    
    componentDidMount() {
        checkRouting(this.props);  
        this.setUpStats();
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))){
            this.initNavAsset()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){

        if (prevState.startDateNotConverted !== this.state.startDateNotConverted) {
            let local = moment.tz.guess();
            let toDateStart = moment.tz(this.state.startDateNotConverted, local).format('L');
            this.setState({
                startDate : toDateStart,
            });
        }

        if(prevState.paramImage !== this.state.paramImage){
            if(this.state.paramImage != null){
                this.setState({
                    disabledLink : true,
                })
            }
            else{
                this.setState({
                    disabledLink : false,
                })
            }
        }
        if(prevState.paramLink !== this.state.paramLink){
            if(this.state.paramLink.length>0){
                this.setState({
                    disabledImage : true,
                })
            }
            else{
                this.setState({
                    disabledImage : false,
                })
            }
        }
    }

    handleGetAssetDatas = () =>{

        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query:GET_ASSET_BY_ID,
                variables:{id: this.state.idAsset},
                fetchPolicy:'no-cache'
            }).then(result => {                
                window.setAssetNameMenu(result.data.asset)
                this.setState({
                    assetData : result.data.asset,
                    ready:true
                })
                resolve();
            });
            
        });
    }

    handleGetCursorsInstant = () => {
        let variables = 
            {
                "asset" : this.state.idAsset,
                "isWin": true,
            };
        this.props.client.query({
            query: GET_WIN_INSTANT_BY_ASSET_CURSOR,
            variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.assetGameInstants.edges,
            });
        });
    }

    handleGetInstantsPagination = () => {

        return new Promise(async (resolve, reject) => {
            let variables = 
                {
                    "nbperpage": this.state.nbperpage, 
                    "asset" : this.state.idAsset,
                    "isWin": true,
                };
                if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                    variables.cursor = this.state.cursor;
                }
            this.props.client.query({
                query: GET_WIN_INSTANT_BY_ASSET,
                variables
            }).then(result =>{
                this.handleGetCursorsInstant();
                this.setState({
                    ready : true, 
                    countPage: Math.ceil(result.data.assetGameInstants.totalCount / this.state.nbperpage),
                    allInstants: result.data.assetGameInstants.edges,
                    // gamePlayersCount : result.data.assetGameInstants.totalCount
                });
                this.props.stopLoading();
            });
            resolve();
        });
    }

    handleGetPlayersApi = () => {
        return new Promise(async (resolve, reject) => {
            let formData = new FormData;
            formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            const getAllDates = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/dates`, 'post', formData)
            const getInstantPlay = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/plays`, 'post', formData)
            const getInstantUniquePlay = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/unique-player`, 'post', formData)
            const getPlayersPerPlays = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/player-count-per-plays-amount`, 'post', formData)
            const getPlayersAllCount = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/all-count`, 'post', formData)
            const getPlayersConfirmed = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/confirmed`, 'post', formData)
            this.setState({
                ready : true, 
                gamePlaysCount: getPlayersAllCount.data[0].allPlayerCount,
                gamePlayersCount : getPlayersConfirmed.data[0].confirmedPlayerCount,
                // gameInstantsWins : data.datas.giftsWon,
                // gameRemaingInstants : data.datas.remainingGifts,
                // gameNextInstant : data.datas.nextGift,
                // chartDatas: data.datas.chart
            });
            let stats = this.state.stats.map((stat,index)=>({
                    ...stat,
                    data : this.handleGetStats(stat.statType)
            }))

            let dates = getAllDates.data
            let instantPlays = getInstantPlay.data
            let instantUniquePlay = getInstantUniquePlay.data
            this.handleSetupCharts(instantPlays, instantUniquePlay, dates);
            this.handleSetupBarCharts(getPlayersPerPlays.data);
            this.setState({
                stats : stats
            })
            resolve();
            // request(`${process.env.REACT_APP_API}/assets/games/stats/${this.state.assetData.identifier}`, 'get').then(
            //     async (data) => {
            //         if (data.success){
            //             this.setState({
            //                 ready : true, 
            //                 gamePlaysCount: data.datas.participations,
            //                 gamePlayersCount : data.datas.singleParticipations,
            //                 gameInstantsWins : data.datas.giftsWon,
            //                 gameRemaingInstants : data.datas.remainingGifts,
            //                 gameNextInstant : data.datas.nextGift,
            //                 chartDatas: data.datas.chart
            //             });
            //         }
            //         let stats = this.state.stats.map((stat,index)=>({
            //                 ...stat,
            //                 data : this.handleGetStats(stat.statType)
                        
            //         }))
            //         this.handleSetupCharts(data.datas.chart);
            //         this.setState({
            //             stats : stats
            //         })
            //         this.props.stopLoading();
            //         let formData = new FormData;
            //         formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            //         const getData = await axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/plays`, 'post', formData)
            //         resolve();
            //     }
            // );
        });
    }

    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if(page > 1){
            index = ( page * this.state.nbperpage ) - this.state.nbperpage - 1
        }
        this.setState({
            cursor: this.state.listCursors[index].cursor,
            page: page
        },()=>{
            if (this.state.assetData.assetType.identifier === 'flipbook' || this.state.assetData.assetType.identifier === "flipbook_with_cart"){
                this.handleGetWishlist(true);
            }else{
                this.handleGetInstantsPagination();
            }
        });
    };

    handleGetCursorsWishlist = () => {
        this.props.client.query({
            query: GET_ASSET_WISHLISTS_CURSORS,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.assetWishlists.edges,
            });
        });
    }

    handleGetWishlist = (changePage) => {

        return new Promise(async (resolve, reject) => {
            let variables = 
                {
                    "nbperpage": this.state.nbperpage, 
                    "asset" : this.state.idAsset,
                };
            if (this.state.cursor && this.state.listCursors && this.state.cursor !== this.state.listCursors[0].cursor) {
                variables.cursor = this.state.cursor;
            }
            this.props.client.query({
                query: GET_ASSET_WISHLISTS,
                variables
            }).then(GET_ASSET_WISHLISTS_RESULT =>{
                this.handleGetCursorsWishlist();
                this.setState({
                    countPage: Math.ceil(GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount / this.state.nbperpage),
                    gamePlays: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.edges,
                    gamePlaysCount: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                });
                this.setState({
                    gamePlays: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.edges,  
                    gamePlaysCount : GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                })
                let statsFlipbook = [{
                    icon: 'picto-profil',
                    dataColor: colors.black.regular,
                    subtitle: 'Wishlists',
                    gradientColor1: colors.black.regular,
                    gradientColor2: colors.blue.lighter,
                    dataSize:'h3',
                    statType : 'gamePlays',
                    data: GET_ASSET_WISHLISTS_RESULT.data.assetWishlists.totalCount,
                }]
                if (!changePage){
                    request(`${process.env.REACT_APP_API}/assets/wishlist/catalogue_de_noel/top_five`, 'get').then(
                        (data) => {
                            let arraySku = [];
                            let rankingProducts = []
                            // data.ranking.forEach(function(item){
                            //     Object.keys(item).forEach(function(key){
                            //         console.log("key:" + key + "value:" + item[key]);
                            //     })
                            // })
                            for (const [key, value] of Object.entries(data.ranking)) {
                                arraySku.push(key)
                                rankingProducts.push({
                                    id: key,
                                    product: null,
                                    value
                                })
                            }
                            rankingProducts.sort(function (a, b) {
                                return b.value - a.value;
                            });
                            this.setState({
                                rankingProducts 
                            })
                            if(data.success){
                                this.props.client.query({
                                    query: GET_PRODUCTS_PAGINATION,
                                    fetchPolicy: 'no-cache',
                                    variables:{
                                        "idList": arraySku
                                    }
                                }).then(GET_PRODUCTS_PAGINATION_RESULT => {
                                    GET_PRODUCTS_PAGINATION_RESULT.data.products.edges.map((product, index) => {
                                        if (this.state.rankingProducts.length > 0){
                                            this.state.rankingProducts[index].product = product.node
                                            return null
                                        }else{
                                            return null;
                                        }
                                    })
                                    let statsRankingProducts = []
                                    if (GET_PRODUCTS_PAGINATION_RESULT.data.products.edges.length > 0){
                                        this.state.rankingProducts.map((rank, index) => {
                                            statsRankingProducts.push({
                                                icon: 'picto-profil',
                                                dataColor: colors.black.regular,
                                                subtitle: `Top Produit #${index + 1}`,
                                                gradientColor1: colors.black.regular,
                                                gradientColor2: colors.blue.lighter,
                                                dataSize:'h3',
                                                statType : 'gamePlays',
                                                data: rank.value,
                                                productInfo: rank.product
                                            })
                                            return null
                                        })
                                        statsFlipbook.push()
                                    }
                                    this.setState({
                                        ready : true, 
                                        statsFlipbook,
                                        statsRankingProducts,
                                    })
                                    resolve();
                                })
                                // eventService.fire({stateName: 'attributeIdentifier', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.'});
                            }
                        }
                    );
                    this.setState({
                        ready : true, 
                        statsFlipbook,
                    })
                }
                this.props.stopLoading();
            });
            
        });
    }
    

    handleGetPlays=()=>{
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_GAME_PLAYS,
                variables:{
                    "asset" : this.state.idAsset
                }
            }).then(result =>{
                let gamesValidated = result.data.assetGamePlays.edges.filter(e => e.node.isPlayed)
                this.setState({
                    gamePlays : gamesValidated,
                    gamePlaysCount : gamesValidated.length
                })
                resolve();
            })
            
        });
    }

    handleGetStatsRecognizer = () => {
        return new Promise(async (resolve, reject) => {
            let formData = new FormData();
                formData.append('uuid', uuidv4())
            request(`${process.env.REACT_APP_STATS_API}`, 'post', formData, 'multipart/form-data').then(
                (data) => {
                    this.setState({
                        gamePlays: data.statistics
                    })
                    let statsRecognizer = [{
                        icon: 'picto-profil',
                        dataColor: colors.black.regular,
                        subtitle: 'Participations',
                        gradientColor1: colors.black.regular,
                        gradientColor2: colors.blue.lighter,
                        dataSize:'h3',
                        statType : 'gamePlays',
                        data: data.statistics.length,
                    }]
                    let counterMatch = 0;
                    let counterTotal = 0;
                    for (let players of data.statistics){
                        for (let play of players.details){
                            if (play.isMatching){
                                counterMatch++
                            }
                            counterTotal++
                        }
                    }
                    
                    let percentMatch = counterMatch / counterTotal;
                    percentMatch = percentMatch * 100;
                    percentMatch = Math.round(percentMatch);
                    statsRecognizer.push(
                        {
                            icon: 'icon-win',
                            dataColor: colors.black.regular,
                            subtitle: 'Taux de succès scan',
                            gradientColor1: colors.black.regular,
                            gradientColor2: colors.blue.lighter,
                            dataSize:'h3',
                            statType : 'gamePlays',
                            data: `${percentMatch} %`,
                        }
                    )
                    let arraySku = [];
                    let rankingProducts = [];
                    for (const [key, value] of Object.entries(data.top)) {
                        arraySku.push(key)
                        rankingProducts.push({
                            id: key,
                            product: value.product,
                            value: value.cpt
                        })
                    }
                    rankingProducts.sort(function (a, b) {
                        return b.value - a.value;
                    });
                    let statsRankingProducts = []
                    rankingProducts.map((rank, index) => {
                        statsRankingProducts.push({
                            icon: 'picto-profil',
                            dataColor: colors.black.regular,
                            subtitle: `Top boite LEGO #${index + 1}`,
                            gradientColor1: colors.black.regular,
                            gradientColor2: colors.blue.lighter,
                            dataSize:'h3',
                            data: rank.value,
                            productInfo: rank.product
                        })
                        return null
                    })
                    this.setState({
                        ready : true,
                        stats: statsRecognizer,
                        statsRankingProducts,
                    });
                    this.props.stopLoading();
                    resolve();
                }
            );
        });
    }
    // handleGetInstants=()=>{
    //     return new Promise(async (resolve, reject) => {
    //         this.props.client.query({
    //             query: GET_GAME_INSTANTS,
    //             variables:{
    //                 "asset" : this.state.idAsset
    //             }
    //         }).then(result =>{
    //             let gameInstants = result.data.assetGameInstants.edges;
    //             let gameInstantsWins = gameInstants.filter(game => game.node.isWin).length;
    //             let gameRemaingInstants = gameInstants.filter(game => !game.node.isWin)
    //             let gameNextInstant = gameRemaingInstants.sort((a,b)=>this.sortInstantsByDate(a,b))[0];
    //             this.setState({
    //                 gameInstantsWins : gameInstantsWins,
    //                 gameRemaingInstants : gameRemaingInstants.length,
    //                 gameNextInstant : gameNextInstant
    //             })

    //             resolve();
    //         })
            
    //     });
    // }
    sortInstantsByDate = (game,gameNext)=>{
        let gameDate = new Date(game.node.winningAt);
        let gameNextDate = new Date(gameNext.node.winningAt);
        return gameDate - gameNextDate;
        
    }
    resetState = () => {
        this.setState({
            paramIdentifier : null,
            paramStartAt    : null,
            paramStatus     : true,
            paramUrl        : null,
        });
        this.handleGetAssetDatas();
        this.prepareAttributeValues();
    }

    handleToggleDrawer = (stateDrawer, changeWidth) => {
        this.setState({ 
            [stateDrawer]: !this.state[stateDrawer],
            errors: {},
        });

        if (changeWidth) {
            this.handleDrawerWidthChange(300);
        } 
        
    };

    handleChangeTab=(event,value)=>{
        this.setState({
            tabValue : value
        })
    }


    handleDrawerWidthChange = (width) => {
        this.setState({ 
            drawerWidthModified: width
        });
    };
    handleSelectPie = (event) => {
        this.setState({ selectedDataPie: event.target.value }, () => {
            eventService.fire();
        });
    };
    handleGetStats = (type) =>{
        switch(type){
            case 'gamePlaysCountByDay':
                return this.state.gamePlaysCountByDay;
            case 'gamePlays':
                return this.state.gamePlaysCount;
            case 'uniquePlays':
                return this.state.gamePlayersCount;
            case 'gameWins':
                return this.state.gameInstantsWins;
            case 'remaingGifts' : 
                return this.state.gameRemaingInstants;
            case 'nextGift' : 
                let next = this.state.gameNextInstant;
                let date = next ? moment.utc(next).format('L à HH:mm') : 'Aucun';
                return date;
            default:
                return null;
        }
    }

    handleSetupCharts = (instantPlays, instantUniquePlays, dates) =>{
        let allDates = this.state.dates;
        let allDatas = this.state.allDatas;

        dates.forEach(function (value, i) {
            allDates.push(value.date)
            allDatas[0]['data'].push(instantPlays[i].playsCount);
            allDatas[1]['data'].push(instantUniquePlays[i].uniquePlayerCount);
        });

        this.setState({
            dates : allDates,
            allDatas : allDatas
        })
    }

    handleSetupBarCharts = (chart) =>{
        let allPerPlays = this.state.perPlays;
        let allDatasBar = this.state.allDatasBar;
        // chart.forEach(function(item) {
        //     Object.keys(item).forEach(function(key) {
        //         console.log("key:" + key + "value:" + item[key]);
        //     });
        // });
        Object.keys(chart)
        .forEach(function eachKey(key) { 
            allPerPlays.push(key);
            // allPerPlays.push(`Participation ${key}`);
            allDatasBar[0]['data'].push(chart[key]);
        });

        this.setState({
            perPlays : allPerPlays,
            allDatasBar : allDatasBar
        })
    }

    setUpStats= async ()=>{
        await this.handleGetAssetDatas();
        // if (this.state.assetData.assetType.identifier === 'flipbook' || this.state.assetData.assetType.identifier === "flipbook_with_cart"){
        //     await this.handleGetWishlist();
        // }else if (this.state.assetData.assetType.identifier === 'image_recognizer'){
        //     await this.handleGetStatsRecognizer();
        // }else{
        //     // await this.handleGetPlays();
        //     // await this.handleGetPlayersApi();
        //     await this.handleGetInstantsPagination();
        //     // await this.handleGetInstants();
        // }
    }

    handlerExportWinners = async () => {
        try {
            this.props.startLoading();
            let formData = new FormData;
            formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/winners/csv`, 'post', formData).then(
                (data) => {
                    if (data){
                        let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, `export_winners_${moment().format('DD-MM-YYYY')}_${moment().unix()}.csv`);
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerParticipationsPerDay = async () => {
        try {
            this.props.startLoading();
            let formData = new FormData;
            formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/plays/csv`, 'post', formData).then(
                (data) => {
                    if (data){
                        let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, `export_participations_per_day_${moment().format('DD-MM-YYYY')}_${moment().unix()}.csv`);
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerUniquePlayers = async () => {
        try {
            this.props.startLoading();
            let formData = new FormData;
            formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/unique-player/csv`, 'post', formData).then(
                (data) => {
                    if (data){
                        let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, `export_unique_players_${moment().format('DD-MM-YYYY')}_${moment().unix()}.csv`);
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    handlerPlayersPerPlay = async () => {
        try {
            this.props.startLoading();
            let formData = new FormData;
            formData.append('assetId', this.state.assetData.id.replace('/api/assets/', ''))
            axios(`${process.env.REACT_APP_API}/asset/games/statistics/instant/player-count-per-plays-amount/csv`, 'post', formData).then(
                (data) => {
                    if (data){
                        let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, `export_players_per_plays_${moment().format('DD-MM-YYYY')}_${moment().unix()}.csv`);
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `Téléchargement réussi !`, 10000);
                    }else{
                        this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
                        this.props.stopLoading();
                    }
                }
            );
        } catch(e) {
            this.props.snack(ALERT_ERROR, `Le téléchargement a échoué !`);
            this.props.stopLoading();
        }
    };

    handleChangeExportType = (e)=>{        
        this.setState({
            exportType : e.target.value,            
        })
    }

    handleClickExport = ()=>{
        switch (this.state.exportType) {
            case 1:
                this.handlerExportWinners()
                break;
            case 2:
                this.handlerParticipationsPerDay()
                break;
            case 3:
                this.handlerUniquePlayers()
                break;
            case 4:
                this.handlerPlayersPerPlay()
                break;
            default:
                break;
        }
    }
        
    render() {                
        const assetData = this.state.assetData;
        const TabPanel=(props)=>{
            return (
                props.index === this.state.tabValue 
                    ? 
                    <PanelItem>{props.children}</PanelItem>
                :null 
                
            );
        }
        
        return (
            <div style={{width: this.state.openFormEdit ? assetData.assetType.hasFeed === true ? "100%" : `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms'}}>
                <TopPanel 
                    icomoon         = "icon-asset" 
                    colorIcomoon    = {colors.blue.darker.hue300}
                    title           = "Gérer mon Asset" 
                    subtitle        = "Visualisation des statistiques"
                    gradientColor1  = {colors.menu.regular} 
                    gradientColor2  = {colors.menu.darker} 
                    openForm        = {this.state.openFormEdit}                                 
                    hasBorder={true}
                    alignItems
                >            
                    <FormControl>                  
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.exportType}
                        onChange={this.handleChangeExportType}                    
                        style={{
                            width: "100%",
                            padding: "10px",
                            fontWeight:'bold',
                            border:`0.5px solid ${colors.grey.lighter.hue700}`,
                            minWidth:"200px"
                        }}
                        disableUnderline
                      >                    
                        <MenuItem value={1}>Exporter les gagnants</MenuItem>
                        <MenuItem value={2}>Exporter participation par jour</MenuItem>
                        <MenuItem value={3}>Exporter joueurs uniques par jour</MenuItem>
                        <MenuItem value={4}>Exporter joueurs pas nombre de participations</MenuItem>
                      </Select>
                    </FormControl>    
                    <Button style={{marginLeft: '16px'}} text={"Exporter"} onClick={() => this.handleClickExport()} bgcolor={colors.green.regular} shadowcolor={colors.green.regular} disabled={!this.state.ready} downloadIcon/>
                </TopPanel>                             
                {
                    this.state.assetData ?
                        this.state.assetData.assetType.identifier !== 'flipbook' ?
                            <>                                      
                                {
                                    this.state.assetData.assetType.identifier === 'image_recognizer' ?
                                        <Grid container justifyContent="center" style={{marginTop: 0}}>
                                            {
                                                this.state.statsRankingProducts ?
                                                    this.state.statsRankingProducts.map((stat, index) =>
                                                        (
                                                            <Grid item key={`statsGrid${index}`} style={{marginRight: 16, width: "calc((100%/5) - (5 * 16px))"}}> 
                                                                <Stats stat={stat} windowWidth={this.props.windowWidth} isRecognizer={true}/>
                                                            </Grid>
                                                        )
                                                    )
                                                : null
                                            }
                                            {
                                                this.state.gamePlays?.length > 0 ? (
                                                    <GridCustom item xs={12} style={{marginTop: 24}}>
                                                        <ArrayStats players={this.state.gamePlays} locale={this.state.currentLocale} isRecognizer={true}/>
                                                        {
                                                            this.state.countPage > 1 ? 
                                                                (<CustomSelect onChange={(event, page) => {this.changePage(event, page, null)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                                                            :
                                                                null
                                                        }  
                                                    </GridCustom>
                                                ) :  
                                                    this.state.ready ?
                                                    (<EmptyCard title={"Aucune statistique pour le moment"} subtitle={"Revenez quand des personnes auront participées à ce jeu"} onClick={() => {this.handleToggleDrawer('form')}} picto={emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                                    :
                                                    (<PageLoader />)
                                            }
                                        </Grid>
                                        :
                                        this.state.ready ?
                                            <>                                            
                                            <GlobalView assetId={this.props.match.params.id} startDate={this.state.assetData?.startAt} endDate={this.state.assetData?.endAt}/>
                                            </>
                                            : <PageLoader />                                            
                                }                                                         
                            </>
                        : <Grid container>
                            {
                                this.state.statsFlipbook ?
                                    this.state.statsFlipbook.map((stat, index) =>
                                        (
                                            <Grid item key={`statsGrid${index}`} style={{marginRight: 16}}> 
                                                <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                            </Grid>
                                        )
                                    )
                                : null
                            }
                            <Grid container justifyContent="center" style={{marginTop: 16}}>
                                {
                                    this.state.statsRankingProducts ?
                                        this.state.statsRankingProducts.map((stat, index) =>
                                            (
                                                <Grid item key={`statsGrid${index}`}> 
                                                    <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                                </Grid>
                                            )
                                        )
                                    : null
                                }
                            </Grid>
                            {
                                this.state.gamePlays?.length > 0 ? (
                                    <GridCustom item xs={12} style={{marginTop: 16}}>
                                        <ArrayStats players={this.state.gamePlays} locale={this.state.currentLocale} isFlipbook={true}/>
                                        {
                                            this.state.countPage > 1 ? 
                                                (<PaginationCustom onChange={(event, page) => {this.changePage(event, page, null)}} page={this.state.page} count={this.state.countPage} color="primary" />)
                                            :
                                                null
                                        }  
                                    </GridCustom>
                                ) :  
                                    this.state.ready ?
                                    (<EmptyCard title={"Aucune statistique pour le moment"} subtitle={"Revenez quand des personnes auront participées à ce jeu"} onClick={() => {this.handleToggleDrawer('form')}} picto={emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />) 
                                    :
                                    (<PageLoader />)
                            }
                        </Grid>
                    : <PageLoader />
                }
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => ({...a})); 
    goTo = route => this.props.history.push(route);
}


const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    };
}

export default withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsPageStats)));