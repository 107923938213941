import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { SocialNetworks } from "../../../../../types";

const Footer = ({
  data = null
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <div className="builder-template-scope" style={{
      backgroundColor: data.footerColor?.value ?? '#000'
    }}>
      <footer className={`footer-area`}>
        <div className={`footer-top text-center pt-80 pb-25`}>
          <div className="container">
            <div className="footer-logo">
              <img 
                alt=""
                style={{ maxHeight: 150 }}
                src={ typeof data.logo.value === 'string' ? data.logo.value : `${process.env.REACT_APP_MEDIAS}/${data.logo.value.filePath}`}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: data.moto.value }} style={{
              color: data.footerTextColor?.value ?? '#fff'
            }} />
            <div className="footer-social">
              <ul>
                {
                  data?.social?.value?.map((e, i) => {
                    if (!e.value) return null;

                    const getIcon = (icon) => {
                      switch (icon) {
                        case SocialNetworks.FACEBOOK:
                          return "fa fa-facebook";
                        case SocialNetworks.INSTAGRAM:
                          return "fa fa-instagram";
                        case SocialNetworks.YOUTUBE:
                          return "fa fa-youtube";
                        case SocialNetworks.PINTEREST:
                          return "fa fa-pinterest";
                        case SocialNetworks.TWITTER:
                          return "fa fa-twitter";
                        case SocialNetworks.LINKEDIN:
                          return "fa fa-linkedin";
                        default:
                          return null;
                      }
                    };

                    return (
                      <li key={i}>
                        <a href={e.value} target="_blank" style={{
                          color: data.footerTextColor?.value ?? '#fff'
                        }}>
                          <i className={getIcon(e.icon)} />
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <div className="container">
            <div className={`copyright-2`}>
              <p style={{
                color: data.footerTextColor?.value ?? '#fff'
              }}>
                { data.copyright?.value }
              </p>
            </div>
          </div>
        </div>
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
      </footer>
    </div>
  );
};

Footer.propTypes = {
};

export default Footer;