import styled from 'styled-components';

export default styled.img`
    &.w100{
        width: 100%;
        display:block;
        height:100%;
    }
    &.absolute{
        position: absolute;
    }
`; 