import React,{ useState } from "react";
import Div from "../shared/div";

const BlocMediaCustom = props=>{
    const [hover, setHover] = useState(false);
    let {
        preview = false,
        data = null,
    } = props;

    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} id={"bloc-media-custom"} className={data.inputs.className?.value}>
            { hover && !preview && props.children }
            <div 
                style={{
                    backgroundImage: data.blocks.media.inputs.img?.value?.filePath ?`url(${process.env.REACT_APP_MEDIAS}/${data.blocks.media.inputs.img?.value?.filePath})`:`url(${data.blocks.media.inputs.img?.value})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight: data.inputs.height?.value || "auto",
                    padding: `${data.blocks.content.inputs.paddingY?.value}px ${data.blocks.content.inputs.paddingX?.value}px`,
                    display: "flex",
                    alignItems: data.blocks.content.inputs.positionX?.value,
                    justifyContent: data.blocks.content.inputs.positionY?.value,
                    textAlign: data.blocks.content.inputs.textAlign?.value,
                }}
            >
                <div
                    style={{
                        display : "flex",
                        flexDirection: "column",
                        gap: 10,
                        alignItems:data.blocks.content.inputs.textAlign?.value === "right"? "flex-end": data.blocks.content.inputs.textAlign?.value === "left"? "flex-start": "center" ,
                    }}
                >
                    <p
                        style={{
                            fontSize: data.blocks.content.inputs.fontSizeTitle?.value,
                            fontWeight: "bold",
                            color: data.blocks.content.inputs.colorTitle?.value,
                            margin: 0,
                        }}
                    >{data.blocks.content.inputs.title?.value}</p>
                    <p
                        style={{
                            fontSize: data.blocks.content.inputs.fontSizeText?.value,
                            color: data.blocks.content.inputs.colorText?.value,
                            margin: 0,
                        }}
                    >{data.blocks.content.inputs.text?.value}</p>
                    {
                        !data.blocks.button.inputs.hideButton?.value ?
                        <button
                            style={{
                                width: "max-content",
                                color:data.blocks.button.inputs.color?.value,
                                backgroundColor: data.blocks.button.inputs.bgcolor?.value,
                                padding: "10px 30px",
                                border: "none",
                                borderRadius: `${data.blocks.button.inputs.borderRadius?.value}px`,
                                fontSize :data.blocks.button.inputs.fontSize?.value,
                            }}
                        >
                            <a
                                style={{
                                    textDecoration: "none",
                                    color: "inherit"
                                }}
                                href={data.blocks.button.inputs.link?.value}
                                target="_blank"
                            >
                                {data.blocks.button.inputs.text?.value}
                            </a>
                        </button>
                        :null
                    }
                </div>
            </div>
        </Div>
    )
}

export default BlocMediaCustom