import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault } from '../../helpers';

export default {
    name: 'Bloc basic présentation',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        logo: getImageDefault(),
        title: getDefaultText("Titre du bloc", "Titre", 40, "#53839D", null, 'uppercase'),
        subtitle: getDefaultText("Sous-titre du bloc", "Sous-titre.", 28, "#53839D", null, 'uppercase'),
        text: getDefaultTextarea("Texte du bloc", "Texte", true, "#53839D", 'rgba(0,0,0,0)', 'uppercase', 'initial', 20),
    }
};