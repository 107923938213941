import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import GiftProductSelector from '../components/GiftProductSelector';
import GameInstantCreator from '../components/GameInstantCreator';

export default function assetsGift(assetsEditData, currentLang, getAssetData, customAttributes, getCatalog){

    let steps = []

    steps = steps.concat(
        [
            {
                labelName: 'Sélection des cadeaux',
                isOptionnal: false,
                component: GiftProductSelector,
                optionsInputs:
                    []
            },
            {
                labelName: 'Choix instant gagnant',
                isOptionnal: false,
                component: GameInstantCreator,
                optionsInputs:
                    []
            },
        ]
    )
    var obj=
        {
            titleForm:`Paramétrage des cadeaux`,
            subTitleForm: 'Veuillez opérer des changements sur les champs ci-dessous pour modifier votre asset',
            langSelect: false,
            drawerType: 'drawer',
            noForm: true,
            component: Button,
            formConfig: {
                type: STEPPER,
                finalStep: {
                    title: 'Et voilà !',
                    subtitle: 'Votre asset est prêt pour la modification',
                    textButton: 'Enregistrer l\'asset'
                },
                children: steps
            },

        }    
    return obj;
}
