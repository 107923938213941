import noImageCompany from '../../../../../assets/images/emptyCompany.jpg';
import CardCompany from '../../../../layouts/Card/cardContent/CardCompany';

export const listSettings = {
    grid : {
        components : CardCompany,
        columns : {
            xs : 1,
            sm : 2,
            laptop : 3,
        }
    },
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label   : 'Image',
                field   :  'image',
                cellType : 'image',
                filter : 'test',
                cellProps : {
                    width : '10%',
                    noImage : noImageCompany,
                }
            },
            {
                label : 'Nom',
                field : 'name',
                cellType : 'text',
                filter : 'name',
                cellProps : {
                    fontWeight : 'bold',
                    width : '20%'
                }

            },
            {
                label : 'Type',
                field : 'type',
                cellType : 'text',
                cellProps : {
                    width : '10%'
                }
            },
            {
                label : 'Contacts',
                field : 'contact',
                cellType : 'text',
                cellProps : {
                    width : '10%'
                }

            },
            {
                label : 'Filliales',
                field : 'children',
                cellType : 'text',
                cellProps : {
                    width : '10%'
                }

            },
            {
                label : '',
                field : 'link',
                cellType :'link'
            }
        ]   
    }
}

export const listMappers = [
    {
        view : 'card',
        mapper : 'companiesGridMapper'
    },
    {
        view : 'table',
        mapper : 'companiesListMapper'
    }
]

export const perPageOptions = {
    'card' : ['6'],
    'table' : ['12','24','48']
}

