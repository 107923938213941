import React from 'react';
import RelatedProductSlider from "../../wrappers/product/RelatedProductSlider";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";

const Product = ({ 
    preview = false,  
    id = null, 
    data = null,
    params = null,
    inputCallback = null,
    catalog = null,
    attributes = [],
    product = null,
    spread = false,
    children = null
}) => {
    return (
        <div 
            className={"builder-template-scope"} 
            style={{
                width: '100%',
                marginBottom: 20,
                backgroundColor: data.inputs['backgroundColor'].value,
            }}
        >
            { !preview && children }
          
            { product && (
                <>
                    <ProductImageDescription
                        spaceTopClass="pt-100"
                        spaceBottomClass="pb-100"
                        product={product}
                        data={data}
                        params={params}
                        preview={preview}
                        id={id}
                        inputCallback={inputCallback}
                    />

                    <ProductDescriptionTab
                        spaceBottomClass="pb-90"
                        product={product}
                        attributes={attributes}
                        data={data}
                        params={params}
                    />
                </>
            )}

            {/* related product slider */}
            {/* <RelatedProductSlider
                spaceBottomClass="pb-95"
                category={{}}
            /> */}
        </div>
    );
};

export default Product;