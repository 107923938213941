import { gql } from 'apollo-boost';

//TODO Dono
//----- Récupération des produits
//--- FILTRES exclusion produits variantes : products (exists:{parent:false}){
export const GET_PRODUCTS = gql`
{
  products (first: 50){
    edges{
      node{
        id
        sku
        status
        suggestions{
          edges{
            node{
              id
            }
          }
        }
        superAttribute{
          edges{
            node{
              id
              identifier
              attributeOptions{
                edges{
                  node{
                    id
                    identifier
                    translation {
                      id
                      translationDatas {
                        edges {
                          node {
                            value
                            locale {
                              id
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              translation{
                translationDatas{
                  edges{
                    node{
                      locale{
                        code
                        libelle
                      }
                      value
                    }
                  }
                }
              }
            }
          }
        }
        children{
          edges{
            node{
              id
              productDatas(first: 200) {
                edges {
                  node {
                    value
                    id
                    selection{
                      id
                      identifier
                    }
                    attributeOption {
                      id
                      identifier
                      translation {
                        translationDatas {
                          edges {
                            node {
                              locale {
                                id
                                code
                              }
                              value
                            }
                          }
                        }
                      }
                    }
                    attribute {
                      id
                      identifier
                      isSystem
                      translation {
                        id
                        translationDatas {
                          edges {
                            node {
                              id
                              value
                              locale {
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                      attributeType {
                        id
                        input
                      }
                    }
                    media {
                      id
                      filePath
                      type
                      size
                      category {
                        edges {
                          node {
                            id
                            libelle
                          }
                        }
                      }
                    }
                    locale {
                      id
                      code
                    }
                  }
                }
              }
            }
          }
        }
        children{
          edges{
            totalCount
            node{
              id
            }
          }
        }
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        
        productCategories{
          edges{
            node{
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      media{
                        filePath
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
              }
              catalog{
                id
                libelle
              }
            }
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCTS_CURSOR = gql`
{
  products (first: 10000, exists:{parent:false}){
    edges{
      cursor
    }
  }
}
`;

export const GET_PRODUCTS_SEARCH_PAGINATION = gql`
query productsSearchPagination ($page : Int!, $categories: [Int], $itemsPerPage: Int!, $sku: String, $catalog: Int, $status : Boolean, $productDatas: [ArrayType], $productSources: [ArrayType], $onlyNew: Boolean,$scrapStatus:String,$skus:[String]){
  researchProducts(page: $page, sku: $sku, categories: $categories, itemsPerPage: $itemsPerPage, catalog: $catalog, status: $status, productDatas: $productDatas, productSources: $productSources, onlyNew: $onlyNew,scrapStatus:$scrapStatus,skus:$skus){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{       
        id
        sku
        status
        children{
          totalCount
        }
        flatCompletudes{
          edges{
            node{
              completude
              locale{
                id
              }
            }
          }
        }
        productCatalogs{
          totalCount
          edges{
            node{
              id
              status
              catalog{
                id
              }
              selection{
                id
              }
            }
          }
        }
        productCategories{
          edges{
            node{
              id
              position
              category{
                id
              }
            }
          }
        	totalCount
        }
        attributeGroup {
          id
        }
        productDatas(first:1000){
          edges{
            node{
              id
              value
              selection{
                id
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                isOptimized
              }
            }
          }          
        }
      }
    }
    totalCount
  }
}`;
export const GET_PRODUCTS_SEARCH_PAGINATION_NO_VARIANTS = gql`
query productsSearchPagination ($page : Int!, $categories: [Int], $itemsPerPage: Int!, $sku: String, $catalog: Int, $status : Boolean, $productDatas: [ArrayType],$localeId:String, $productSources: [ArrayType],$productBundleOptions:Boolean,$skus: [String]){
  researchProducts(page: $page, sku: $sku, categories: $categories, itemsPerPage: $itemsPerPage, catalog: $catalog, status: $status, productDatas: $productDatas, productSources: $productSources,productBundleOptions:$productBundleOptions,skus:$skus){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{       
        id
        sku
        status
        children{
          totalCount
        }
        flatCompletudes{
          edges{
            node{
              completude
              locale{
                id
              }
            }
          }
        }
        productCatalogs{
          totalCount
          edges{
            node{
              id
              status
              catalog{
                id
              }
              selection{
                id
              }
            }
          }
        }
        productCategories{
          edges{
            node{
              id
              position
              category{
                id
              }
            }
          }
        	totalCount
        }
        attributeGroup {
          id
        }
        flatProducts(locale: $localeId){
          edges{
            node{
              sku
              name
              image
              categoriesNb
              catalogsNb
              childrenNb
              imagesNb
              completude
              imagesMaxNb
            }
          }          
        }   
        productDatas(first:10){
          edges{
            node{
              value
              media{
                id
                filePath
              }
              id
              selection{
                id
                identifier
              }
              attributeOption{
                id
                identifier
                translation{
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          code
                          libelle
                          id
                        }
                      }
                    }
                  }
                }
              }
              attribute{
                id
                identifier
                isSystem
                useInCard
                attributeType{
                  input
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          code
                          libelle
                          id
                        }
                      }
                    }
                  }
                }
              }
              locale{
                code
              }
            }
          }      
        }
      }
    }
    totalCount
  }
}`;

export const GET_PRODUCTS_SEARCH_PAGINATION_FULLDATA = gql`
query productsSearchPaginationFullData ($page : Int!, $categories: [Int], $itemsPerPage: Int!, $sku: String, $catalog: Int, $status : Boolean, $productDatas: [ArrayType]){
  researchProducts(page: $page, sku: $sku, categories: $categories, itemsPerPage: $itemsPerPage, catalog: $catalog, status: $status, productDatas: $productDatas){
    totalCount
    edges{
      node{       
        id
        sku
        status
        flatCompletudes{
          edges{
            node{
              completude
              locale{
                id
              }
            }
          }
        }
        suggestions{
          edges{
            node{
              id
            }
          }
        }
        children{
          totalCount
        }
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        productCategories{
          edges{
            node{
              id
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
                catalog{
                  id
                  libelle
                }
              }
            }
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
`

//TODO Dono
export const GET_PRODUCTS_PAGINATION = gql`
query products ($nbperpage: Int, $cursor: String, $cursorLast: String, $sku: String, $status: Boolean, $idList: [Int], $idListCategories: [String],$productBundleOptions:Boolean){
  products (first: $nbperpage, after: $cursor, before: $cursorLast, sku: $sku, status: $status, id_list: $idList, exists:{parent:false},productBundleOptions:$productBundleOptions){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{       
        id
        sku
        status
        suggestions{
          edges{
            node{
              id
            }
          }
        }
        children{
          totalCount
        }
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        productCategories(category_list: $idListCategories){
          edges{
            node{
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
                catalog{
                  id
                  libelle
                }
              }
            }
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
`;
//TODO Dono
export const GET_PRODUCTS_PAGINATION_FLATS = gql`
query products ($nbperpage: Int, $cursor: String, $cursorLast: String, $sku: String, $status: Boolean, $idList: [Int], $idListCategories: [String], $locale: String){
  products (first: $nbperpage, after: $cursor, before: $cursorLast, sku: $sku, status: $status, id_list: $idList, exists:{parent:false}){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{       
        id
        sku
        status
        suggestions{
          edges{
            node{
              id
            }
          }
        }
        children{
          totalCount
        }
        parent{
          id
        }
        attributeGroup {
          id
          identifier
        }
        productCategories(category_list: $idListCategories){
          edges{
            node{
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
                catalog{
                  id
                  libelle
                }
              }
            }
          }
        }
        flatProducts(locale: $locale){
          edges{
            node{
              sku
              name
              image
              categoriesNb
              catalogsNb
              childrenNb
              imagesNb
              completude
              imagesMaxNb
            }
          }          
        } 
      }
    }
    totalCount
  }
}
`;

export const GET_PRODUCTS_LIGHT_POSITION = gql`
query products ($nbperpage: Int, $cursor: String, $cursorLast: String, $sku: String, $status: Boolean, $idList: [Int]){
  products (first: $nbperpage, after: $cursor, before: $cursorLast, sku: $sku, status: $status, id_list: $idList, exists:{parent:false}){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{       
        id
        sku
        status
        parent{
          id
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
    totalCount
  }
}
`;

export const GET_PRODUCTS_TOTAL = gql`
{
  products{
    totalCount
  }
}
`;

export const GET_PRODUCTS_TOTAL_INFOS = gql`
{
  products{
    totalCount
    edges{
      node{
        id
        updated_at
        created_at
      }
    }
  }
}
`;

export const GET_PRODUCT_IDS = gql`
  query productsSearchPagination(
    $page: Int!
    $itemsPerPage: Int!
    $skus: [String]
    $categories: [Int]
    $catalog: Int
    $status: Boolean
    $productDatas: [ArrayType]
    $localeId: String
    $productSources: [ArrayType]
    $onlyNew: Boolean
    $scrapStatus: String
    $fileImports: [ArrayType]
    $productBundleOptions: Boolean
  ) {
    researchProducts(
      page: $page
      itemsPerPage: $itemsPerPage
      skus: $skus
      categories: $categories
      catalog: $catalog
      status: $status
      productDatas: $productDatas
      locale: $localeId
      productSources: $productSources
      onlyNew: $onlyNew
      scrapStatus: $scrapStatus
      fileImports: $fileImports
      productBundleOptions: $productBundleOptions
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
  

//TODO Dono
export const SEARCH_PRODUCTS = gql`
query productDataSearch ($attribute: String, $needle: String) {
  productDatas(attribute: $attribute, value: $needle) {
    edges {
      node {
        id
        value
        selection{
          id
          identifier
        }
        product {
          id
          sku
          status
          suggestions{
            edges{
              node{
                id
              }
            }
          }
          superAttribute{
            edges{
              node{
                id
                identifier
                attributeOptions{
                  edges{
                    node{
                      id
                      identifier
                      translation {
                        id
                        translationDatas {
                          edges {
                            node {
                              value
                              locale {
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          code
                          libelle
                        }
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          children{
            edges{
              node{
                id
                productDatas(first: 200) {
                  edges {
                    node {
                      value
                      id
                      selection{
                        id
                        identifier
                      }
                      attributeOption {
                        id
                        identifier
                        translation {
                          translationDatas {
                            edges {
                              node {
                                locale {
                                  id
                                  code
                                }
                                value
                              }
                            }
                          }
                        }
                      }
                      attribute {
                        id
                        identifier
                        isSystem
                        translation {
                          id
                          translationDatas {
                            edges {
                              node {
                                id
                                value
                                locale {
                                  id
                                  code
                                }
                              }
                            }
                          }
                        }
                        attributeType {
                          id
                          input
                        }
                      }
                      media {
                        id
                        filePath
                        type
                        size
                        category {
                          edges {
                            node {
                              id
                              libelle
                            }
                          }
                        }
                      }
                      locale {
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
          parent{
            id
          }
          productCategories {
            edges{
              node{
                position
                category{
                  id
                  libelle
                  categoryDatas {
                    edges {
                      node {
                        value
                        attribute {
                          identifier
                        }
                        media {
                          filePath
                        }
                        locale {
                          code
                        }
                      }
                    }
                  }
                  catalog {
                    id
                    libelle
                  }
                }
              }
            }
          }
          productDatas(first: 200) {
            edges {
              node {
                value
                id
                selection{
                  id
                  identifier
                }
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const SEARCH_PRODUCTS_MIN = gql`
query productDataSearch ($attribute: String, $needle: String,$first:Int) {
  productDatas(first: $first, attribute: $attribute, value: $needle) {
    edges {
      node {
        product {
          id
          sku
          productDatas(first: 200) {
            edges {
              node {
                value
                id
                attribute {
                  id
                  identifier
                  isSystem
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  filePath
                }
                locale {
                  id
                  code
                }
              }
            }
          }

        }
      }
    }
  }
}`;

export const SEARCH_PRODUCTS_SKU = gql`
query productDataSearch ($attribute: String, $needle: String) {
  productDatas(first: 100000, attribute: $attribute, value: $needle, exists:{product_parent: false}) {
    edges {
      node {
        product {
          id
          sku
        }
      }
    }
  }
}`;

//TODO Dono
export const SEARCH_PRODUCTS_SKU_BY_CAT = gql`
query categories($idlist: [Int]){
  categories(id_list: $idlist) {
    id
    productCategories{
      totalCount
      pageInfo{
        startCursor
        endCursor
      }
      edges{
        cursor
        node{
          position
          product{
            id
            sku
          }
        }
      }
    }
  }
}
`;

//TODO Dono
export const PRODUCTS_BY_CAT_PAGINATION = gql`
  query categories($idlist: [Int], $idList: [Int], $nbperpage: Int, $sku: String, $status: Boolean, $cursor: String, $cursorLast: String){
    categories(id_list: $idlist) {
      id
      libelle
      productCategories(first: $nbperpage, after: $cursor, before: $cursorLast, id_list: $idList, sku: $sku, status: $status, exists:{product_parent:false}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
        edges{
          cursor
          node{
            position
            product{
              id
              sku
              productDatas(first: 200){
                edges{
                  node{
                    id
                    value
                    selection{
                        id
                        identifier
                    }
                    attribute{
                      identifier
                      isSystem
                    }
                    locale{
                      code
                    }
                    media{
                      id
                      filePath
                      type
                      size
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

//TODO Dono
export const PRODUCTS_BY_CATALOGS_PAGINATION = gql`
query products($id: ID!, $nbperpage: Int, $cursor: String, $cursorLast: String){
  products(first: $nbperpage, after: $cursor, before: $cursorLast, exists:{parent:false}) {
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{
        id
        sku
        productCategories{
          edges{
            node{
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
                catalog{
                  id
                  libelle
                }
              }
            }
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
              }
            }
          }
        }
      }
    }
  }
}
`;

//TODO Dono
export const PRODUCTS_BY_CATALOGS_CAT_PAGINATION = gql`
query catalogs($id: ID!, $idlist: [Int], $nbperpage: Int, $cursor: String, $cursorLast: String){
  catalog(id: $id){
    categories(id_list: $idlist){
      id
      productCategories(first: $nbperpage, after: $cursor, before: $cursorLast, exists:{product_parent:false, category_catalog:false}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
        }
        edges{
          cursor
          node{
            position
            product{
              id
              sku
              productDatas(first: 200){
                edges{
                  node{
                    id
                    value
                    selection{
                        id
                        identifier
                    }
                    attribute{
                      identifier
                      isSystem
                    }
                    locale{
                      code
                    }
                    media{
                      id
                      filePath
                      type
                      size
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//TODO Dono
//----- Récupération des produits pour le sélecteur
export const GET_SELECT_PRODUCTS = gql`
{
  products{
    edges{
      node{
        id
        sku
        productCategories{
          edges{
            node{
              position
              category{
                id
                libelle
                categoryDatas{
                  edges{
                    node{
                      value
                      attribute{
                        identifier
                      }
                      media{
                        filePath
                      }
                      locale{
                        code
                      }
                    }
                  }
                }
                catalog{
                  id
                  libelle
                }
              }
            }
          }
        }
        productDatas{
          edges{
            node{
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
              }
              media{
                filePath
              }
              locale{
                code
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des données d'un produit
/*
  Exemple de variables
  {
    "id" : "/api/products/1"
  }
*/

export const GET_PRODUCT_DATA = gql`
query productDatas($id: ID!, $selection: String) {
  product(id: $id) {
    id
    sku
    status
    scrapStatus
    flatCompletudes{
      edges{
        node{
          completude
          locale{
            id
          }
        }
      }
    }
    suggestions{
      edges{
        node{
          id
        }
      }
    }
    flatCompletudes{
      edges{
        node{
          completude
          locale{
            id
          }
        }
      }
    }
    superAttribute{
      edges{
        node{
          id
          identifier
          attributeType {
            id
            input
          }
          attributeOptions{
            edges{
              node{
                id
                identifier
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          translation{
            translationDatas{
              edges{
                node{
                  locale{
                    code
                    libelle
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
    children{
      edges{
        node{
          id
          sku
          flatCompletudes{
            edges{
              node{
                completude
                locale{
                  id
                }
              }
            }
          }
          productDatas(first: 200, selection: $selection) {
            edges {
              node {
                value
                id
                selection{
                  id
                  identifier
                }
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  name
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
    parent{
      id
    }
    attributeGroup{
      id
      identifier
      translation {
        id
        translationKey
        translationDatas {
          edges {
            node {
              id
              value
              locale {
                id
                code
              }
            }
          }
        }
      }
    }
    productCategories{
      edges{
        node{
          position
          category{
            id
            libelle
            categoryDatas{
              edges{
                node{
                  id
                  value
                  attribute{
                    id
                    identifier
                    attributeType {
                      id
                      input
                    }
                  }
                  locale{
                    id
                    code
                  }
                }
              }
            }
            catalog{
              id
              libelle
            }
          }
        }
      }
    }
    productDatas(first: 200, selection: $selection){
      edges{
        node{
          value
          id
          selection{
            id
            identifier
          }
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          media{
            id
            filePath
            type
            size
            name
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

// Limited product data
export const GET_PRODUCT_DATA_LIMITED = gql`
query productDatas($id: ID!, $selection: String) {
  product(id: $id) {
    id
    sku
    status
    scrapStatus
    flatCompletudes{
      edges{
        node{
          completude
          locale{
            id
          }
        }
      }
    }
    parent{
      id
    }
    flatProducts(locale: "/api/locales/1"){
      edges{
        node{
          catalogsNb
          categoriesNb
        }
      }          
    } 
    attributeGroup{
      id
      identifier
      translation {
        id
        translationKey
        translationDatas {
          edges {
            node {
              id
              value
              locale {
                id
                code
              }
            }
          }
        }
      }
    }
    productSourceWorkflow{
      completedAt
    }
    productDatas(first: 1000, selection: $selection){
      edges{
        node{
          value
          id
          isLocked
          selection{
            id
            identifier
          }
          attributeOption{
            id
            identifier
            translation{
              translationDatas{
                edges{
                  node{
                    locale{
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          attribute{
            id
            identifier
            isSystem
            translation{
              id
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      id
                      code
                    }
                  }
                }
              }
            }
            attributeType {
              id
              input
            }
          }
          mediaObjectCategory{
            id
            libelle
          }
          media{
            id
            filePath
            type
            size
            name
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
          locale{
            id
            code
          }
        }
      }
    }
  }
}
`;

export const GET_CATEGORIES_DATA_LIMITED = gql`
query productDatas($id: ID!) {
  product(id: $id) {
    productCategories{
      edges{
        node{
          position
          category{
            id
            libelle
            categoryDatas{
              edges{
                node{
                  id
                  value
                  attribute{
                    id
                    identifier
                    attributeType {
                      id
                      input
                    }
                  }
                  locale{
                    id
                    code
                  }
                }
              }
            }
            catalog{
              id
              libelle
            }
          }
        }
      }
    }
  }
}
`;

// Get taxos
export const GET_PRODUCT_TAXOS = gql`
query productTaxos($product: String) {
  productTaxos(product: $product) {
    edges{
      node{
        id
        categoryName
        attributeName
        taxoValue
      }
    }
  }
}
`

// Limited product data
export const GET_PRODUCT_DATA_LIMITED_WITHOUT_CATEGORIES = gql`
query productDatas($id: ID!) {
  product(id: $id) {
    productCategories(exists: {category_catalog: false }){
      edges{
        node{
          position
          category{
            id
            libelle
            categoryDatas{
              edges{
                node{
                  id
                  value
                  attribute{
                    id
                    identifier
                    attributeType {
                      id
                      input
                    }
                  }
                  locale{
                    id
                    code
                  }
                }
              }
            }
            catalog{
              id
              libelle
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCT_DATA_SOURCE = gql`
query productSourceDatas($product:String,$source:String){
  productSourceDatas(product:$product,source:$source){
    edges{
      node{
        source {
          id
          libelle
        }
        locale{
          id
          code
        }
        attributeOption{
          id
          identifier
          translation{
            translationDatas{
              edges{
                node{
                  locale{
                    id
                    code
                  }
                  value
                }
              }
            }
          }
        }
        attribute{
          id
          identifier
          isSystem
          translation{
            id
            translationDatas{
              edges{
                node{
                  id
                  value
                  locale{
                    id
                    code
                  }
                }
              }
            }
          }
          attributeType {
            id
            input
          }
        }
        media{
          id
          filePath
          type
          size
          name
          category{
            edges{
              node{
                id
                libelle
              }
            }
          }
        }
        value
      }
    }
  }
}
`;

// Gets product secondary data 
export const GET_PRODUCT_SECONDARY_DATA = gql`
query productDatas($id: ID!, $selection: String) {
  product(id: $id) {
    id
    suggestions{
      edges{
        node{
          id
        }
      }
    }
    superAttribute{
      edges{
        node{
          id
          identifier
          attributeType {
            id
            input
          }
          attributeOptions{
            edges{
              node{
                id
                identifier
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          translation{
            translationDatas{
              edges{
                node{
                  locale{
                    code
                    libelle
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
    children{
      edges{
        node{
          id
          sku
          productDatas(first: 200, selection: $selection) {
            edges {
              node {                
                value
                id
                selection{
                  id
                  identifier
                }
                attributeOption {
                  id
                  identifier
                  translation {
                    translationDatas {
                      edges {
                        node {
                          locale {
                            id
                            code
                          }
                          value
                        }
                      }
                    }
                  }
                }
                attribute {
                  id
                  identifier
                  isSystem
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          id
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                  attributeType {
                    id
                    input
                  }
                }
                media {
                  id
                  filePath
                  type
                  size
                  name
                  category {
                    edges {
                      node {
                        id
                        libelle
                      }
                    }
                  }
                }
                locale {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

// Gets product suggestions
export const GET_PRODUCT_SUGGESTIONS = gql`
query productDatas($id: ID!, $selection: String) {
  product(id: $id) {
    id
    suggestions{
      edges{
        node{
          id
        }
      }
    }
  }
}
`;

// Gets product children
export const GET_PRODUCT_CHILDREN = gql`
query productDatas($id: ID!, $selection: String) {
  product(id: $id) {
    id
    suggestions{
      edges{
        node{
          id
          superAttribute{
            edges{
              node{
                id
                identifier
                attributeType {
                  id
                  input
                }
                attributeOptions{
                  edges{
                    node{
                      id
                      identifier
                      translation {
                        id
                        translationDatas {
                          edges {
                            node {
                              value
                              locale {
                                id
                                code
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        locale{
                          code
                          libelle
                        }
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          children{
            edges{
              node{
                id
                sku
                productDatas(first: 200, selection: $selection) {
                  edges {
                    node {
                      value
                      id
                      selection{
                        id
                        identifier
                      }
                      attributeOption {
                        id
                        identifier
                        translation {
                          translationDatas {
                            edges {
                              node {
                                locale {
                                  id
                                  code
                                }
                                value
                              }
                            }
                          }
                        }
                      }
                      attribute {
                        id
                        identifier
                        isSystem
                        translation {
                          id
                          translationDatas {
                            edges {
                              node {
                                id
                                value
                                locale {
                                  id
                                  code
                                }
                              }
                            }
                          }
                        }
                        attributeType {
                          id
                          input
                        }
                      }
                      media {
                        id
                        filePath
                        type
                        size
                        name
                        category {
                          edges {
                            node {
                              id
                              libelle
                            }
                          }
                        }
                      }
                      locale {
                        id
                        code
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCT_SOURCES = gql`
  query productSources($product:String){
    productSources(product:$product, isDisplay: true){
      edges{
        node{
          id
          libelle
          isDisplay
        }
      }
    }
  }
`;

export const GET_PRODUCT_SOURCES_PRICES = gql`
  query productSourceDatas($product:String,$source:String){
    productSourceDatas(attribute: "/api/product-source-datas/3", product:$product, source:$source){
      edges{
        node{
          value
        }
      }
    }
  }
`;

//----- Ajouter un produit
/*
Exemple de variables
  {
    "sku" : "Produit de démo",
    "attributeGroup" : "/api/product_attribute_groups/1",
    "categories": [
      5,
      6
    ],
    "suggestions": [
      "/api/products/1",
      "/api/products/2"
    ]
  }
*/

//TODO Dono
export const ADD_PRODUCT = gql`
mutation AddProduct($sku: String!, $attributeGroup: String!,  $categories: [Int], $createdAt: String!, $updatedAt: String!, $status: Boolean!, $children:[String], $superAttribute: [String], $suggestions: [String]){
  createProduct(input: {sku:$sku, attributeGroup:$attributeGroup, categories:$categories, createdAt:$createdAt, updatedAt:$updatedAt, status:$status, children:$children, superAttribute:$superAttribute, suggestions:$suggestions}){
    product{
      id
      sku
      productDatas{
        edges{
          node{
            id
            value
            attribute{
              identifier
            }
          }
        }
      }
      attributeGroup{
        id
      }
      productCategories {
        edges{
          node{
            category{
              id
            }
          }
        }
      }
      suggestions {
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}
`;
  
//----- Modifier un produit
/*
Exemple de variables
  {
    "id" : "/api/products/14",
    "attributeGroup" : "/api/product_attribute_groups/1",
    "suggestions": [
      "/api/products/1",
      "/api/products/2"
    ],
    "categories": [1, 5]
  }
*/

//TODO Dono
export const UPDATE_PRODUCT = gql`
mutation UpdateProduct($id: ID!, $sku: String, $attributeGroup: String, $categories: [Int], $status: Boolean, $children:[String], $superAttribute: [String], $suggestions: [String], $scrapStatus: String){
  updateProduct(input: {id: $id, sku:$sku, attributeGroup:$attributeGroup, categories: $categories, status:$status, children:$children, superAttribute:$superAttribute, suggestions:$suggestions, scrapStatus: $scrapStatus}){
    product{
      id
      sku
      attributeGroup{
        id
      }
      productCategories{
        edges{
          node{
            category{
              id
            }
          }
        }
      }
      suggestions {
        edges{
          node{
            id
          }
        }
      }
    }
  }  
}`;

//----- Supprimer un produit
/*
Exemple de variables
  {
    "id" : "/api/products/1"
  }
*/
export const DELETE_PRODUCT = gql`
mutation DeleteProduct($id: ID!){
	deleteProduct(input: {id:$id}){
    product{
      id
    }
  }  
}`;


//--------------------
//----- DONNEES PRODUITS 
//--------------------

//----- Ajouter une valeur d'attribut à un produit

/*
 Exemple de variables
 {
  "value": "TEST",
	"product": "/api/products/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2",
	"selection": "/api/selections/2"
}
*/
export const ADD_PRODUCT_DATA = gql`
mutation AddProductData($value: String, $product: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String, $selection: String, $updatedAt: String!, $isLocked: Boolean,$mediaObjectCategoryId:String){
  createProductData(input: {value:$value, product:$product, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media, selection: $selection, updatedAt: $updatedAt, isLocked: $isLocked,mediaObjectCategory:$mediaObjectCategoryId}){
    productData{
      id
      value
      product{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;


//----- Modifier une valeur d'attribut d'un produit
/*
 Exemple de variables
 {
	"id": "/api/product-datas/68",
  "value": "TEST edition",
	"product": "/api/products/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2",
	"selection": "/api/selections/2"
}
*/
export const UPDATE_PRODUCT_DATA = gql`
mutation UpdateProductData($id: ID!, $value: String, $product: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String, $selection: String, $isLocked: Boolean,$mediaObjectCategoryId:String){
  updateProductData(input: {id:$id, value:$value, product:$product, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media, selection: $selection, isLocked: $isLocked,mediaObjectCategory:$mediaObjectCategoryId}){
    productData{
      id
      value
      product{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un produit
/*
 Exemple de variables
  {
    "id" : "/api/product-datas/68"
  }
*/
export const DELETE_PRODUCT_DATA = gql`
mutation DeleteProductData($id: ID!){
	deleteProductData(input: {id:$id}){
    productData{
      id
    }
  }  
}`;

//----- Récup un ou plusieurs produit depuis son sku ( sku exact )
/*
 Exemple de variables
 {
	"sku": "Couch" //-> SI 1 produit
	//"skuList": ["Couch","Water"] //--> Si plusieurs produits ===> N'EST PLUS DISPO car passage de EXACT a PARTIAL
}
*/
export const GET_PRODUCTS_BY_SKU = gql`
query products($sku: String){
  products(sku: $sku) {
    edges{
      node{
        id
        sku
        status
        attributeGroup {
          id
          identifier
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attributeOption{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
              }
              locale{
                code
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Récup un ou plusieurs produit depuis son sku ( sku exact )
/*
 Exemple de variables
 {
	"sku": "Couch" //-> SI 1 produit
	//"skuList": ["Couch","Water"] //--> Si plusieurs produits ===> N'EST PLUS DISPO car passage de EXACT a PARTIAL
}
*/
export const GET_PRODUCTS_BY_SKU_LIGHT = gql`
query products($sku: String,$skulist:[Int],$localeId:String,$id:Int){
  products(sku: $sku,id_list:$skulist,id:$id) {
    edges{
      node{
        id
        sku
        flatProducts(locale: $localeId){
          edges{
            node{
              sku
              name
              image
              categoriesNb
              catalogsNb
              childrenNb
              imagesNb
              completude
              imagesMaxNb
            }
          }          
        }  
        productBundleOptions {
          edges {
            node {
              title
              isRequired
              inputType
              productRelations {
                edges {
                  node {
                    position
                    product {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        productDatas(first: 200){
          edges{
            node{
              id
              value
              selection{
                id
                identifier
              }
              attribute{
                identifier
                isSystem
                translation{
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          code
                          libelle
                          id
                        }
                      }
                    }
                  }
                }
              }
              locale{
                code
              }
              mediaObjectCategory{
                id
                libelle
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCTS_SEARCH_PAGINATION_LIGHT = gql`
query productsSearchPagination ($page : Int!, $categories: [Int], $itemsPerPage: Int!, $sku: String, $catalog: Int, $status : Boolean, $productDatas: [ArrayType], $localeId: String, $productSources: [ArrayType], $onlyNew: Boolean,$scrapStatus:String,$fileImports: [ArrayType],$productBundleOptions:Boolean){
  researchProducts(page: $page, sku: $sku, categories: $categories, itemsPerPage: $itemsPerPage, catalog: $catalog, status: $status, productDatas: $productDatas, locale: $localeId, productSources:$productSources, onlyNew:$onlyNew,scrapStatus:$scrapStatus,fileImports:$fileImports,productBundleOptions:$productBundleOptions){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{       
        id
        sku
        attributeGroup {
          id
        }
        status
        productCatalogs{
          totalCount
        }
        productCategories{
          totalCount
          edges{
            node{
              id
              category{
                id
              }
            }
          }
        }
        productDatas{
          edges{
            node{
              value
              mediaObjectCategory{
                id
                libelle
              }
              media{
                id
                filePath
              }
              id
              selection{
                id
                identifier
              }
              attributeOption{
                id
                identifier
              }
              attribute{
                id
                identifier
                isSystem
                attributeType{
                  input
                }
                translation{
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          code
                          libelle
                          id
                        }
                      }
                    }
                  }
                }
              }
              locale{
                code
              }
            }
          }
        }
        productBundleOptions{
          edges{
            node{
              id
              title
              isRequired
              inputType
              productRelations {
                edges {
                  node {
                    position
                    product {
                      id
                      sku
                      flatProducts(locale: $localeId){
                        edges{
                          node{
                            sku
                            name
                            image
                            categoriesNb
                            catalogsNb
                            childrenNb
                            imagesNb
                            completude
                            imagesMaxNb
                          }
                        }          
                      } 
                      parent{
                        id
                        sku
                        flatProducts(locale: $localeId){
                          edges{
                            node{
                              sku
                              name
                              image
                              categoriesNb
                              catalogsNb
                              childrenNb
                              imagesNb
                              completude
                              imagesMaxNb
                            }
                          }          
                        }  
                      }
                    }
                  }
                }
              }
              product{
                id
                sku
              }
            }
          }
        }
        flatProducts(locale: $localeId){
          edges{
            node{
              sku
              name
              image
              categoriesNb
              catalogsNb
              childrenNb
              imagesNb
              completude
              imagesMaxNb
            }
          }          
        }     
      }
    }
    totalCount
  }
}`;

export const GET_NEW_PRODUCTS = gql`
query getNewProducts {
  products(exists:{productSources:false}){
    totalCount
    edges{
      node {
        flatProducts{
          edges{
            node{
              name
            }
          }
        }
      }
    }
  }
}
`;

export const ADD_PRODUCT_SOURCE_WORKFLOW = gql`
mutation AddProductSourceWorkflow($product: String!, $completedAt: String){
  createProductSourceWorkflow(input: {product:$product, completedAt:$completedAt}){
    productSourceWorkflow{
      id
      completedAt
    }
  }  
}`;


export const GET_PRODUCT_IMAGE_DATAS = gql`
query product($id: ID!,$identifier:String){
	product(id:$id){
    id
    productDatas(attribute_identifier: $identifier){
      edges{
        node{
          media{
            id
            filePath
          }
        }
      }
    }
  }  
}
`

export const GET_ONE_PRODUCT_DATAS = gql`
query product($id: ID!){
	product(id:$id){
    id
    sku
    status
    attributeGroup{
      id
    }
    flatProducts{
      edges{
        node{
          sku
          name
          image
          categoriesNb
          catalogsNb
          childrenNb
          imagesNb
          completude
          imagesMaxNb
        }
      }
    }
    productBundleOptions {
      edges {
        node {
          title
          isRequired
          inputType
          productRelations {
            edges {
              node {
                position
                product {
                  id
                }
              }
            }
          }
        }
      }
    }
    productCategories{
      edges{
        node{
          id
          category{
            id
          }
        }
      }
    }
    productDatas{
      edges{
        node{
          id
          value
          selection{
            id
            identifier
          }
          attribute{
            identifier
            isSystem
            translation{
              translationDatas{
                edges{
                  node{
                    id
                    value
                    locale{
                      code
                      libelle
                      id
                    }
                  }
                }
              }
            }
          }
          attributeOption {
            id
            identifier
            translation {
              translationDatas {
                edges {
                  node {
                    locale {
                      id
                      code
                    }
                    value
                  }
                }
              }
            }
          }
          locale{
            code
          }
          mediaObjectCategory{
            id
            libelle
          }
          media{
            id
            filePath
            type
            size
            category{
              edges{
                node{
                  id
                  libelle
                }
              }
            }
          }
        }
      }
    }
  }  
}
`;

export const GET_RETAILER_PRODUCT_DATA = gql`
query retailerProductDatas($sku: String!) {
  retailerProductDatas(sku: $sku) {
    edges {
      node {
        sku
        price
      }
    }
  }
}
`;

// Gets product SUPER_ATTRIBUTES
export const GET_PRODUCT_SUPER_ATTRIBUTES = gql`
query productDatas($id: ID!) {
  product(id: $id) {
    id
    superAttribute{
      edges{
        node{
          id
          identifier
          attributeType {
            id
            input
          }
          attributeOptions{
            edges{
              node{
                id
                identifier
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        value
                        locale {
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          translation{
            translationDatas{
              edges{
                node{
                  locale{
                    code
                    libelle
                  }
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PRODUCTS_SEARCH_LIGHT_PRODUCTS_MERCH = gql`
query productsSearchPagination ($page : Int!, $categories: [Int], $itemsPerPage: Int!, $sku: String, $catalog: Int, $status : Boolean, $productDatas: [ArrayType], $productSources: [ArrayType], $onlyNew: Boolean,$scrapStatus:String,$skus:[String]){
  researchProducts(page: $page, sku: $sku, categories: $categories, itemsPerPage: $itemsPerPage, catalog: $catalog, status: $status, productDatas: $productDatas, productSources: $productSources, onlyNew: $onlyNew,scrapStatus:$scrapStatus,skus:$skus){
    edges{
      node{       
        id
        sku
        flatProducts{
          edges{
            node{
              name
              image
            }
          }
        }
      }
    }
  }
}`;