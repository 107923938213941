import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import formAddPresentation from './config/addPresentation.config';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import { Grid, Box, Chip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NoImage from '../../../../assets/images/not-found.png';
import { ROUTE_CRM_CONTACTS_SOCIETIES_DETAILS, ROUTE_CRM_OFFERS_MODELES_PRESENTATIONS, ROUTE_CRM_OFFERS_EDIT, ROUTE_HOME, ROUTE_BUILDER_PRESENTATION } from '../../../../js/constants/route-names';
import { eventService } from '../../../../js/services/event.service';
import colors from '../../../../config/theme/colors';
import EmptyProduct from '../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import * as moment from "moment";
import { GET_PRESENTATIONS_PAGINATION, GET_PRESENTATIONS_CURSOR, GET_MODEL_PRESENTATIONS, SEARCH_PRESENTATIONS, ADD_PRESENTATION, UPDATE_PRESENTATION, DELETE_PRESENTATION } from '../../../../queries/crm_presentations';
import { GET_OFFERS_PAGINATION, GET_OFFERS_CURSOR, SEARCH_OFFERS, ADD_OFFER, ADD_FOOTER_OFFER } from '../../../../queries/crm_offers';
import styled from 'styled-components';
import TablePagination from '../../../ui/pagination/TablePaginationCustom';
import { hasRights } from '../../../../js/utils/rights';
import { CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE, UPDATE, DELETE, VIEW } from '../../../../js/constants/constant-rights';
import modelesConfig from './config/modelesType';
import { ItemTypes } from '../../../../builder/shareable';
import { savePhases, convertPhases } from './utils/OfferHelpers';
import SendIcon from '@material-ui/icons/Send';
import OfferPopupSendTo from './components/OfferPopupSendTo';
import InfoIcon from '@material-ui/icons/Info';
import request from '../../../../js/utils/fetch';
import DialogModal from '../../../ui/dialog/DialogModal';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1400px){
        padding: 16px 8px 8px!important;
    }
`;

const CompanyPicture = styled(Box)`
    border-radius: 50%;
    overflow: hidden;
    background: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat : no-repeat;
    height: 35px;
    width: 35px;
    margin-right :15px;
`
const CompanyItem = styled(Grid)`
    border-bottom : 1px solid ${colors.grey.lighter.hue900};
    padding : 15px 0px;
    cursor:pointer;
    padding-left:0px;
    transition : all .2s;
    ${props => !props.selectable ?
        `
        &:hover{
            background-color : ${colors.grey.lighter.hue980};
            padding-left:2px;
        }        
    `
        : ``}
`

const ChipCustom = styled(Chip)`
    background-color: ${
        props => props.colorcustom === 'processing' ? 
            colors.grey.regular
            : props.colorcustom === 'ready' ?
                colors.blue.darker.hue300
                : props.colorcustom === 'pending_signature' ?
                    colors.orange.regular 
                    : props.colorcustom === 'completed' ?
                        colors.green.regular
                        : '#000000'
    };
    color: white;
    span{
        font-weight: 800;
        line-height: initial;
    }
`;

class ListPresentations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentLang: props.locales[0].node.code,
            title: '',
            description: '',
            openForm: false,
            openFormCard: false,
            openDialog: false,
            openPopup: false,
            openInfoDialog: false,
            allGroups: [],
            errors: {},
            secondErrors: {},
            seeErrors: false,
            presentations: [],
            imagePresentation: null,
            numberPresentation: '',
            formSettings: {
                page: 1,
                count: 1,
            },
            offers: {
                current: [],
                toAdd: null,
                toRemove: [],
            },
            nbperPageDevis: 6,
            nbperPagePresentations: 6,
            cursor: false,
            pagePresentations: 0,
            countPagePresentations: 0,
            cursorPresentations: false,
            ready: false,
            listSendContact: [],
        }
    }

    componentDidMount() {
        const getRights = hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, VIEW)
        if (!getRights) {
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        } else {
            this.initProject();
        }
    }

    addPresentation = () => {
        this.changePage(null, 1, null)
        this.setState({
            formType: 'add',
            name: null,
            imagePresentation: null,
            numberPresentation: '',
            offers: {
                current: [],
                toAdd: null,
                toRemove: [],
            },
        }, () => this.handleToggleDrawer('form'))
    }

    editPresentation = (data) => {
        let offers = this.state.offers
        offers.toAdd = data.offer?.id
        this.changePage(null, 1, null)
        this.setState({
            formType: 'edit',
            idPresentation: data.id,
            name: data.title,
            offers,
            imagePresentation: data.media,
            numberPresentation: data.number
        }, () => this.handleToggleDrawer())
    }

    handleToggleDrawer = (drawer) => {
        if (drawer === 'form') {
            this.setState({
                openFormCard: !this.state.openFormCard,
            });
        } else {
            this.setState({
                openForm: !this.state.openForm,
            });
        }
        this.setState({
            seeErrors: false
        });
    };

    handleToggleDialog = (stateName, offerData) => {
        if (stateName === 'openPopup'){
            this.setState({
                listSendContact: [],
                offerPopupId: offerData,
            })
        }
        if (stateName === 'openInfoDialog'){
            this.setState({
                infoPresentation: offerData,
            })
        }
        this.setState({
            [stateName]: !this.state[stateName]
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleGetDevis = async () => {
        let variables = {
            nbperpage: this.state.nbperPageDevis
        }
        if (this.state.cursor && this.state.listCursors !== this.state.listCursors[0].cursor) {
            variables.cursor = this.state.cursor;
        }
        if (this.state.activeSearchOffers) {
            variables.idList = this.state.activeSearchOffers
        }
        this.props.client.query({
            query: GET_OFFERS_PAGINATION,
            variables: variables,
            fetchPolicy: 'no-cache'
        }).then(result => {
            let formSettings = this.state.formSettings;
            let offers = result.data.offers.edges;
            formSettings.count = Math.ceil(result.data.offers.totalCount / this.state.nbperPageDevis);
            this.setState({
                allOffers: offers,
                formSettings: formSettings
            })
        })
        this.props.stopLoading();
    }

    handleOfferSelect = (checked, company, currentAction = 'add') => {
        let offers = this.state.offers
        offers.toAdd = checked ? company : null
        this.setState({
            offers: offers
        })
    }

    handleChange = (e, type) => {
        return new Promise((resolve, reject) => {
            this.setState({
                ready: false,
                cursorPresentations: null,
                cursorPresentationsLast: null,
                pagePresentations: 0,
            })
            clearTimeout(this.typingSearchTimer);
            this.setState({ searchValue: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleGetPresentations()
            }, 500);
            resolve();
        })
    }

    handleGetPresentations = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperPagePresentations,
            };
            if (this.state.cursorPresentations) {
                variables.cursor = this.state.cursorPresentations;
            }
            if (this.state.cursorPresentationsLast) {
                variables.cursorLast = this.state.cursorPresentationsLast;
            }
            if (this.state.searchValue !== '') {
                variables.title = this.state.searchValue;
            }
            this.props.client.query({
                query: GET_PRESENTATIONS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    startPresentationCursor: result.data.presentations.pageInfo.startCursor,
                    endPresentationCursor: result.data.presentations.pageInfo.endCursor,
                    countPagePresentations: result.data.presentations.totalCount,
                    presentations: result.data.presentations.edges,
                });
                if (result.data.presentations.edges.length > 0) {
                    this.setState({
                        isEmpty: false,
                    })
                } else {
                    this.setState({
                        isEmpty: true
                    })
                }
                this.setState({
                    ready: true,
                })
                this.props.stopLoading();
                resolve();
            })
        })
    }


    changePage = (event, page, type) => {
        this.props.startLoading();
        let index = 0;
        if (page > 1) {
            switch (type) {
                case 'presentation':
                    index = (page * this.state.nbperPagePresentations) - this.state.nbperPagePresentations - 1
                    break;
                default:
                    index = (page * this.state.nbperPageDevis) - this.state.nbperPageDevis - 1
                    break;
            }
        }
        switch (type) {
            case 'presentation':
                this.setState({
                    cursorPresentations: page > this.state.pagePresentations ? this.state.endPresentationCursor : null,
                    cursorPresentationsLast: page > this.state.pagePresentations ? null : this.state.startPresentationCursor,
                    pagePresentations: page
                }, () => {
                    this.handleGetPresentations();
                });
                break;
            default:
                let formSettings = this.state.formSettings;
                formSettings.page = page
                this.setState({
                    allOffers: null,
                    // cursor: this.state.listCursors[index]?.cursor,
                    formSettings: formSettings
                }, () => {
                    this.handleGetDevis();
                });
                break;
        }
    };

    setValue = (stateName, value, translated) => {
        this.setState({
            [stateName]: value,
        });
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            name: null,
            description: null,
            imagePresentation: null,
            numberPresentation: '',
            offers: {
                current: [],
                toAdd: null,
                toRemove: [],
            },
            errors: {}
        });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        let query = null;
        let variables = null;
        this.setState({
            formType: 'delete'
        })

        query = DELETE_PRESENTATION;
        variables = { id: this.state.idPresentation };
        this.props.client.mutate({
            mutation: query,
            variables
        }).then(() => {
            this.handleSuccess();
        });
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        this.setState({
            ready: false
        })
        await this.initProject();
        if (this.state.formType === "edit") {
            this.props.snack(ALERT_SUCCESS, 'Offre modifiée !');
        } else if (this.state.formType === "add") {
            this.props.snack(ALERT_SUCCESS, 'Offre créée !');
        } else {
            this.handleToggleDialog('openDialog');
            this.props.snack(ALERT_SUCCESS, 'Offre supprimée !');
        }
        this.setState({
            openForm: false,
            openFormCard: false,
        })
        this.resetState();
        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    handleNextStep = () => {
        if (this.hasErrors()) {
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            this.setState({ seeErrors: true });
            eventService.fire();
            return false;
        }

        return true;
    }

    handlerSendSigning = () => {
        this.props.startLoading();
        let counter = 0
        let data = new FormData();
        for (let contact of this.state.listSendContact){
            data.append(`recipients[${counter}][lastname]`, contact.name);
            data.append(`recipients[${counter}][firstname]`, contact.firstname);
            data.append(`recipients[${counter}][email]`, contact.mail);
            counter++
        }
        data.append('presentationId', this.state.offerPopupId);
        request(`${process.env.REACT_APP_API}/docusign/send`, 'post', data, 'multipart/form-data')
            .then(async (data) => {
                if (data.success){
                    this.props.snack(ALERT_SUCCESS, 'Envoi de la demande de signature effectué');
                }else{
                    this.props.snack(ALERT_ERROR, 'Problème d\'envoi de la demande de signature');
                }
                this.props.stopLoading();
                this.handleToggleDialog('openPopup')
                this.handleGetPresentations()
            });
    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();

            if (this.state.formType === "add") {
                // Convert offers models to offers 
                let offersToAdd = [];
                let matchModels = {};
                
                let html = null;

                if (this.state.htmlContent) {
                    html = JSON.parse(this.state.htmlContent);

                    // List current models and convert them to offers

                    for (let model of this.state.model.models.edges) {
                        const ADD_OFFER_RESULT = await this.props.client.mutate({
                            mutation: ADD_OFFER,
                            variables: {
                                'title': model.node.name,
                                'description': model.node.description,
                                'poNumber': 'n/a',
                                'status': 'processing',
                                'createdAt': moment().format('YYYY-MM-DD'),
                                'validityDate': moment().format('YYYY-MM-DD'),
                                'recurring': model.node.recurring,
                                'recurringStartDate': model.node.recurringStartDate,
                                'recurringInvoiceDay': model.node.recurringInvoiceDay,
                                'recurringDelay': model.node.recurringDelay
                            }
                        });

                        const id = ADD_OFFER_RESULT.data.createOffer.offer.id;

                        matchModels[model.node.id] = id; // Used in builder page config later

                        offersToAdd.push(id);

                        let modelPhases = convertPhases(model.node.modelPhases.edges, false);

                        await savePhases(id, modelPhases, this.props.client, true);

                        await this.props.client.mutate({
                            mutation: ADD_FOOTER_OFFER,
                            variables: {
                                'offer': id,
                                'discountFixed': 0,
                                'discountPercent': 0,
                                'advancePayment': 0,
                                'paymentTerm': null,
                                'paymentDeadline': null,
                                'comment': ''
                            }
                        });
                    }
                
                    for (let element of html.elements) {
                        if (element.type === ItemTypes.OFFER_DEVIS) {
                            element.offer = matchModels[element.offer];
                        }
                    }
                }

                let generatedNumber = await request(`${process.env.REACT_APP_API}/getPresentationNumber`);

                await this.props.client.mutate({
                    mutation: ADD_PRESENTATION,
                    variables: {
                        'title': this.state.name,
                        'media': this.state.imagePresentation.id,
                        'offers': offersToAdd,
                        'htmlContent': html ? JSON.stringify(html) : null,
                        'createdAt': moment().format(),
                        'status': 'processing',
                        'number': generatedNumber?.number
                    }
                });
            } else if (this.state.formType === "edit") {
                await this.props.client.mutate({
                    mutation: UPDATE_PRESENTATION,
                    variables: {
                        'id': this.state.idPresentation,
                        'title': this.state.name,
                        'description': this.state.description,
                        'media': this.state.imagePresentation.id,
                        'offer': this.state.offers.toAdd,
                        'number': this.state.numberPresentation
                    }
                });
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleStatus= (status) => {
        switch (status) {
            case 'processing':
                return "En cours d'édition"
            case 'ready':
                return "Prêt pour signature"
            case 'pending_signature':
                return "En attente de signature"
            case 'completed':
                return "Signé"
            default:
                return "Pas de status"
        }
    }

    render() {
        return (
            <div>
                <TopPanel
                    icomoon="picto-produit"
                    colorIcomoon={colors.blue.darker.hue300}
                    title="Gérer les offres commerciales"
                    subtitle="Gestion de vos offres commerciales (création / modification / suppression)"
                    handlerAdd={() => this.addPresentation()}
                    textAdd={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter une offre" : null}
                    // handlerImport={() => this.handleToggleDrawer('openForm')} 
                    // textImport="Importer des produits" 
                    searchHandler={this.handleChange}
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    windowWidth={this.props.windowWidth}
                    openForm={this.state.openForm}
                    buttonAvailable={this.state.ready}
                    hasBorder="true"
                // currentLang={this.state.currentLang} 
                // handleLang={this.handleLang} 
                // locales={this.props.locales}
                />
                <Grid container direction="column" justifyContent="center" style={{ paddingTop: 8 }} spacing={0}>
                    <Grid container direction="row" spacing={2} style={{ marginTop: 0, marginBottom: 0 }}>
                        {
                            this.state.ready ?
                                this.state.presentations.length > 0 ?
                                    this.state.presentations.map((presentation, index) => {
                                        return (
                                            <GridCustom item lg={4} md={6} xs={12} key={`presentation${index}`}>
                                                <CardCustom style={{ width: "100%", height: "100%", padding: 0 }} cardContentStyle={{ height: "100%", padding: 0 }} hovercard={true}>

                                                    {/* <LineColor height={8}/> */}
                                                    <div style={{ padding: 16, height: '100%', display: 'flex', flexDirection: "column" }}>
                                                        <div style={{ maxHeight: 160, height: '160px', display: 'flex', justifyContent: 'center', alignItems: 'center', position:'relative' }}>
                                                            <img style={{ maxHeight: 160, maxWidth: "100%" }} src={presentation.node.media ? `${process.env.REACT_APP_MEDIAS}/${presentation.node.media.filePath}` : NoImage} />
                                                            <Grid container justifyContent="flex-end" style={{position: "absolute", top: 0, right:0}}>
                                                                <Button 
                                                                    text={<InfoIcon style={{fontSize: '1.2rem'}}/>} 
                                                                    bgcolor={colors.white} 
                                                                    color={colors.blue.darker.hue300} 
                                                                    colorhover="white" 
                                                                    disableElevation={true} 
                                                                    border={`1px solid ${colors.blue.darker.hue300}`} 
                                                                    onClick={() => this.handleToggleDialog('openInfoDialog', presentation)}
                                                                    style={{padding: "4px 8px", minWidth: 40}}
                                                                />
                                                            </Grid>
                                                        </div>
                                                        <Grid container>
                                                            <ChipCustom 
                                                                label={this.handleStatus(presentation.node.status)} 
                                                                colorcustom={presentation.node.status || null}
                                                            />
                                                        </Grid>
                                                        <Typography variant="h4" colortext={colors.black.regular} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }} component="div">
                                                            <Box fontWeight="bold">
                                                                {presentation.node.title}
                                                            </Box>
                                                            <Box style={{ fontSize: 14 }}>
                                                                Créé le : {moment(presentation.node.createdAt).format('DD/MM/YYYY')}
                                                            </Box>
                                                        </Typography>
                                                        {
                                                            presentation.node.offer ?
                                                                <CompanyItem container alignItems="center" onClick={() => this.goTo(ROUTE_CRM_OFFERS_EDIT.replace(':id', presentation.node.offer.title), presentation.node.offer.id)}>
                                                                    <CompanyPicture backgroundimage={NoImage} />
                                                                    <Typography style={{ fontWeight: 'bold', width: 'calc(100% - 50px)', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{presentation.node.offer.title}</Typography>
                                                                </CompanyItem>
                                                                : null
                                                        }
                                                        {
                                                            hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, UPDATE) ?
                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: "flex-end", marginTop: 16, flex: 1 }}>
                                                                    <Button
                                                                        text={"Éditer l'offre"}
                                                                        bgcolor={colors.blue.darker.hue300}
                                                                        color={colors.white}
                                                                        bgcolorhover={colors.blue.darker.hue300}
                                                                        colorhover={colors.white}
                                                                        border={`1px solid ${colors.blue.darker.hue300}`}
                                                                        onClick={() => {
                                                                            if (!presentation)
                                                                                return;

                                                                            window.open(`${window.location.origin}${ROUTE_BUILDER_PRESENTATION.replace(':alias', presentation.node.alias)}`, '_blank');
                                                                        }}
                                                                        arrow="right"
                                                                        style={{ marginRight: 8 }}
                                                                    />
                                                                    <Button text={'Modifier'} onClick={() => this.editPresentation(presentation.node)} border={`1px solid transparent`}/>
                                                                    {
                                                                        presentation.node.status === "ready" ?
                                                                            <Button
                                                                                text={<SendIcon style={{maxHeight: 24}}/>}
                                                                                bgcolor={colors.blue.darker.hue300}
                                                                                color={colors.white}
                                                                                bgcolorhover={colors.blue.darker.hue300}
                                                                                colorhover={colors.white}
                                                                                border={`1px solid ${colors.blue.darker.hue300}`}
                                                                                onClick={() => {this.handleToggleDialog('openPopup', presentation.node.id.replace('/api/presentations/', ''))}}
                                                                                style={{ marginLeft: 8 }}
                                                                            />
                                                                        : null
                                                                    }
                                                                    
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                    })
                                    : <EmptyCard title={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Vous n'avez pas encore configuré d'offre commerciale" : "Vous n'avez aucun droit de création sur cette page"} subtitle={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Cliquez sur le bouton ci-dessous pour en ajouter un" : "Faite une demande auprès d'un administrateur"} textButton={hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, CREATE) ? "Ajouter une offre" : null} onClick={() => { this.addPresentation() }} picto={EmptyProduct} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                                : <PageLoader />
                        }
                    </Grid>
                </Grid>
                {
                    this.state.ready && this.state.countPagePresentations > 1 ?
                        <TablePagination
                            count={this.state.countPagePresentations}
                            page={this.state.pagePresentations}
                            onChangePage={(event, page) => { this.changePage(event, page, 'presentation') }}
                            color="primary"
                            component="div"
                            rowsPerPage={6}
                            rowsPerPageOptions={[6]}
                            labelDisplayedRows={ (from=this.state.pagePresentations) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        // <PaginationCustom onChange={(event, page) => { this.changePage(event, page, 'presentation') }} page={this.state.pagePresentations} count={this.state.countPagePresentations} color="primary" />
                    :
                        null
                }
                {
                    this.state.dataLayout ?
                        (
                            <LayoutBuilder
                                opened={this.state.openFormCard}
                                forClose={() => { this.handleToggleDrawer('form') }}
                                dataLayout={this.state.dataLayout}
                                dataCard={this.state.typeOf}
                            />
                        ) : ''
                }
                {
                    this.state.ready ?
                        (
                            <LayoutBuilder
                                opened={this.state.openForm}
                                // image={this.state.imageForm}
                                // icomoon={this.state.content.picto}
                                forClose={() => { this.handleToggleDrawer() }}
                                dataLayout={formAddPresentation(this.state.currentLang, this.handleMediaPicker, this.state.allOffers, this.handleOfferSelect, this.state.offers, this.state.formSettings, this.state.formType)}
                                handlerMutation={this.handlerMutation}
                                // drawerWidth={this.props.drawerWidth}
                                stateCallback={this.handleInputChange}
                                backStepperButtonAction={[
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    () => {
                                        this.setState({ errors: {} });
                                    },
                                    null
                                ]}
                                stepperButtonAction={[
                                    this.handleNextStep,
                                    this.handleNextStep,
                                ]}
                                errorCallback={this.handleFormError}
                                validateButton={true}
                                deleteMutation={this.state.formType === 'edit' && hasRights(CRM_OFFERS, CRM_OFFERS_PROJECTS, DELETE) ? () => this.handleToggleDialog('openDialog') : null}
                                deleteText={this.state.formType === 'edit' ? 'Supprimer' : null}
                                allState={this.state}
                            />
                        ) : ''
                }
                {
                    this.state.openInfoDialog ?
                        <DialogModal 
                            open={this.state.openInfoDialog} 
                            title={`Informations signature : ${this.state.infoPresentation.node.title}`}  
                            primaryAction={() => this.handleToggleDialog('openInfoDialog')} 
                            primarybgcolor={colors.grey.lighter.hue600} 
                            primarybgcolorhover={colors.grey.regular} 
                            primaryText="Fermer"
                            notText={true}
                        >
                            {
                                this.state.infoPresentation.node.presentationDocusigns.edges.length > 0 ?
                                    this.state.infoPresentation.node.presentationDocusigns.edges.map((docu, index) => {
                                        return(
                                            <Grid container justifyContent="space-between" style={{marginTop: index === 0 ? 0 : 16}} key={`container-infos-${index}`}>
                                                <Typography variant="h3" colortext={colors.black.regular} component="div">
                                                    Email {index  + 1} :
                                                </Typography>
                                                {
                                                    docu.node.presentationDocusignEmails.edges.map((email, i) => {
                                                        return(
                                                            <CardCustom paddingbottom={"16px"} paddingtop="16px" width="100%" style={{marginTop: 8}} key={`info-mail-${index}-${i}`}>
                                                                <Grid container justifyContent="space-between" alignItems="center">
                                                                    <Grid item xs={8}>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="h4" colortext={colors.black.regular} component="div">
                                                                                    <strong>{email.node.lastname} {email.node.firstname}</strong>
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Typography variant="h4" colortext={colors.black.regular} component="div" style={{marginRight: 8}}>
                                                                                        Adresse mail : 
                                                                                    </Typography>
                                                                                    <Typography variant="h4" colortext={colors.black.regular} component="div">
                                                                                        {email.node.email}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Grid container justifyContent={"flex-end"}>
                                                                            <ChipCustom 
                                                                                label={this.handleStatus(email.node.docusign.status)} 
                                                                                colorcustom={email.node.docusign.status || null}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardCustom>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    })
                                : 'Aucun envoi n\'a été effectué'
                            }
                        </DialogModal>
                    : null
                }
                {
                    this.state.openPopup ?
                        <OfferPopupSendTo 
                            open={this.state.openPopup}
                            handleToggleDialog={this.handleToggleDialog}
                            mutation={this.handlerSendSigning}
                            listContacts={this.state.listSendContact}
                            stateCallback={this.handleInputChange}
                        />
                    : null
                }
                <Dialog
                    open={this.state.openDialog}
                    onClose={() => this.handleToggleDialog('openDialog')}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Êtes-vous sûr de vouloir supprimer cette offre ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Si vous supprimez cette offre celle-ci ne sera plus accessible. Si vous ne souhaitez pas la supprimer, annulez la suppression en cliquant sur annuler.
                            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleToggleDialog('openDialog')} color={colors.grey.regular} bgcolor={colors.white} bgcolorhover={colors.grey.lighter.hue900} border={`1px solid ${colors.grey.regular}`}>
                            Annuler
                            </Button>
                        <Button onClick={this.deleteMutation} bgcolor={colors.red.regular} bgcolorhover={colors.red.darker} autoFocus>
                            Supprimer
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    selectModel(model, htmlContent) {
        this.setState({
            model,
            htmlContent
        }, () => this.handleToggleDrawer())
    }

    handleGetModeles() {
        this.props.client.query({
            query: GET_MODEL_PRESENTATIONS,
            fetchPolicy: 'no-cache'
        }).then(result => {
                this.setState({
                dataLayout: modelesConfig,
            }, () => {
                let newTypeOf = [{
                    libelle: 'Sans modèle',
                    colorhover: '#0273A5',
                    textButton: 'Utiliser ce modèle',
                    onClick: () => this.selectModel(null)
                }]
                result.data.modelPresentations.edges.map(model => (
                    newTypeOf.push(
                        {
                            ...model.node,
                            model: model.node,
                            libelle: model.node.title,
                            colorhover: '#0273A5',
                            // isTextArea: true,
                            textButton: 'Utiliser ce modèle',
                            logo: model.node.media?.filePath,
                            customLogo: true,
                            onClick: () => this.selectModel(model.node, model.node.htmlContent),
                            // isLocaleImage   : true
                        }
                    )
                ))
                this.setState({
                    typeOf: newTypeOf
                })
            });
        });
    }

    handleGetDevisCursors = () => {
        this.props.client.query({
            query: GET_OFFERS_CURSOR,
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listCursors: result.data.offers.edges
            }, () => {
                this.handleGetDevis();
            });
        });
    }

    handleChangeDevis = (e, value) => {
        this.setState({
            currentPage: 1,
            allOffers: null,
        })
        clearTimeout(this.typingSearchTimer);
        if (e?.target?.value) {
            this.setState({ searchValueOffers: e.target.value });
            this.typingSearchTimer = setTimeout(() => {
                this.handleSearchOffers();
            }, 500);
        }
        else {
            this.setState({ activeSearchOffers: false }, () => this.handleGetDevis());
        }
    }

    handleSearchOffers = () => {
        let value = this.state.searchValueOffers;
        if (value !== "") {
            this.props.client.query({
                query: SEARCH_OFFERS,
                variables: {
                    "title": value,
                    "first": 100
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                let listID = [];
                for (let item of result.data.offers.edges) {
                    let stripId = item.node.id.replace('/api/offers/', '')
                    listID.push(stripId);
                }
                this.setState({
                    activeSearchOffers: listID,
                    cursor: null
                }, () => this.handleGetDevis())

            })

        }
    }



    async initProject() {
        await this.handleGetPresentations();
        this.handleGetModeles();
        // this.setState({
        //     ready: true
        // })
        let formSettings = this.state.formSettings;
        formSettings.changePage = this.changePage;
        // formSettings.handleSearch = this.handleChangeDevis;
        // this.handleGetDevisCursors();
    }

    goTo = (route, id, model) => {
        this.props.history.push({
            pathname: route,
            state: {
                offerId: id,
                modelId: model
            }
        });
    };

}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ListPresentations)));
