import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideImage, getDefaultTextareaCms, getDefaultTextCms} from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Section de description de la page',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
        showSlider: {
            label: 'Afficher le slider',
            type: FormInputTypes.SWITCH,
            value: true,
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre principal"),
        subtitle: getDefaultTextCms("Sous-titre", "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam in est justo. Vivamus rutrum, lacus quis volutpat gravida, justo massa accumsan tortor, non lobortis felis dolor id ex."),
        text: getDefaultTextareaCms("Sous-titre", "Sed mi quam, dictum at malesuada ac, rutrum in libero. Curabitur sit amet scelerisque libero. Maecenas non tellus sed lorem congue ullamcorper eget posuere diam. Integer ornare elementum congue. Cras in turpis nec urna sagittis tincidunt id sed dui. Sed suscipit nulla et velit molestie tristique.", true),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_IMAGE,
            buttonAdd: 'une slide',
            limit: 10,
            config: [ 
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
                getDefaultSlideImage("https://via.placeholder.com/920x470"),
            ]
        }
    }
};