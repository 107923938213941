import CardPages from '../../../../../layouts/Card/cardContent/CardFooter';

export const listSettings = {
    grid: {
        components: CardPages,
        columns: {
            xs: 1,
            sm: 1,
            laptop: 1,
        },
        layout: {
            spacing: 0
        }
    },
}

export const listMappers = [
    {
        view: 'card',
        mapper: null
    },
]

export const perPageOptions = {
    'card': ['9', '18', '36'],
}