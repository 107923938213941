import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getDefaultLink } from '../../helpersBook';

export default {
    name: 'Section bas de page',
    blocks: {
        title_left: getDefaultText("Titre block gauche", "Venez découvrir les nouveautés et l’univers LEGO® en un clic"),
        button_left: getDefaultLink("Bouton", "JE FONCE !"),
        title_right: getDefaultText("Titre block droit", "L’aventure LEGO®<br/> ne s’arrête pas là !"),
        text_left: getDefaultTextarea("Texte block droit", "Recevez des récompenses<br/> incroyables grâce à LEGO® VIP !", true),
        button_right: getDefaultLink("Bouton", "DEVENIR MEMBRE V.I.P."),
        copyright: getDefaultText("Copyright", "LEGO, le logo LEGO, les Minifigures, DUPLO, NINJAGO, le logo LEGO Friends et Mindstorms sont des marques déposées et/ou sous copyright du Groupe LEGO. ©2021 the LEGO Group. © & ™ Lucasfilm Ltd."),
    }
};