import {companiesGridMapper,companiesListMapper} from './companies';
import {customersListMapper} from './customers';
import { flowListMapper } from './flow';
import { notificationMapper } from './notificationMapper';
import { topMessagesMapper } from './topMessages';

const mapperList = {
    'companiesGridMapper': companiesGridMapper,
    'companiesListMapper': companiesListMapper,
    'customersListMapper': customersListMapper,
    'flowListMapper': flowListMapper,
    'topMessagesMapper': topMessagesMapper,
    'notificationMapper': notificationMapper,
}
export function itemMapper (formats,items,lang,customDatas){
    return new Promise(async(resolve,reject)=>{   
        let result = {};
        for(let format of formats){
            if(format.mapper){
                let mapper = mapperList[format.mapper];
                let formatItems = await mapper(items,lang,customDatas);
                result[format.view] = formatItems ;
            } else {
                result[format.view] = items;
            }
        }
        resolve(result);
    })
}