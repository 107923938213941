import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../../../ui/typography/Typography';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader'
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../../CardCustom';
import * as moment from 'moment';
import CardHeader from '@material-ui/core/CardHeader';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LanguageIcon from '@material-ui/icons/Language';
import imgNotFound from '../../../../../assets/images/not-found.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import likeReaction from '../../../../../assets/pictos/likeInstagram.svg';
import commentReaction from '../../../../../assets/pictos/commentInstagram.svg';

const FeedItem = styled(Grid)`
    margin-bottom:30px;
    img{
        width:100%;
    }
`

const SocialCounter = styled(Grid)`
    color:${colors.blue.lighter.hue150}
`

const PostDate = styled(Typography)`
    color:#0273A5;
`

const MessagePost = styled(Typography)`
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

const SeeMore = styled(Typography)`
    color:${colors.blue.darker.hue300};
    text-align: center;
    cursor: pointer;
`

const SocialsIcon=styled.div`
    background-size: contain;
    width:25px;
    height:25px;
    display:flex;
    border:3px solid white;
    transform : scale(1);
    transition:all .5s;
    &:hover{
        transform : scale(1.1); 
    }
`
export default class CardInstagram extends React.Component{

    handleClick() {
        this.props.handleLoad(this.props.pagination.cursors.after)
    }

    render() {
        
        let getImageType = (asset) => {
            const images = require.context('../../../../../assets/images', true);
            let image;
            try{
                image=images('./' + asset);
            } catch(e){
                image=imgNotFound;
            }
            return image;
        }

        this.instragramIcons={
            like        : likeReaction,
            comment        : commentReaction,
        } 

        let props = this.props;
        let posts=this.props.data;
        let pagination=this.props.pagination;
        let img = getImageType(props.type.logo);

        return (
            <>
                {posts?.length > 0 ? posts.map((post,index)=>
                    <FeedItem item xs={4} key={`post-${index}`}>
                        <CardCustom contentpadding={"0px 0px 20px 0px"} style={{height: "100%"}} cardContentStyle={{display: "flex", flexDirection:'column'}}>
                            {/* <CardHeader
                                action={
                                <IconButton aria-label="settings" onClick={(event)=>{this.props.handleSettings(event,post)}}>
                                    <MoreVertIcon style={{color:'#0273A5'}}/>
                                </IconButton>
                                }
                            /> */}
                            <Grid container style={{height: "100%"}}>
                                <Grid item xs={12} style={{maxHeight: 160,minHeight: 160, overflow: 'hidden', position: 'relative'}}>
                                    {
                                        post.media_type === "IMAGE" ?
                                        post.media_url ?
                                            <img src={post.media_url} style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}/>
                                            : <img src={typeof img === "string" ? img : img?.default} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                        :
                                        post.media_url ?
                                            <video controls src={post.media_url} style={{position: "absolute", top: "50%", transform: "translateY(-50%)", width: "100%", height:"100%"}}/>
                                            : <img src={typeof img === "string" ? img : img?.default} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                                    }
                                </Grid >                                   

                                <Grid item xs={12} style={{padding:20,backgroundColor:colors.grey.lighter.hue980}}>
                                    <MessagePost variant="body2">
                                        {post.caption}
                                    </MessagePost>
                                </Grid>
                                {post.permalink ?
                                    <SocialCounter item xs={12} style={{padding:'20px  20px'}}>
                                        <Grid container alignItems="center" direction="row">
                                            <LanguageIcon/> 
                                            <a href={post.permalink} style={{marginLeft:10,color:colors.blue.lighter.hue150}} target="_blank" rel="noreferrer">Lien vers la publication</a>
                                        </Grid>

                                    </SocialCounter>
                                    :<div style={{height: 64, width: "100%"}}></div>
                                }
                                <Grid item xs={12} style={{padding:'0  20px', display: 'flex', alignItems: "flex-end"}}>
                                    <Grid container space={1} alignItems="center" style={{height: 25}}>
                                        {
                                            post.like_count > 0 || post.comments_count > 0 ? 
                                            <SocialCounter item xs={10}>
                                                <Grid container alignItems="center" direction="row">
                                                    {
                                                        post.like_count > 0 ?
                                                            <Tooltip arrow title={`Like: ${post.like_count}`} key={`Reaction-like_count`}>
                                                                <SocialsIcon style={{backgroundImage:`url(${this.instragramIcons['like']})`,marginLeft: 0 , zIndex: 10}}/>   
                                                            </Tooltip> 
                                                        : null                                             
                                                    }
                                                    {
                                                        post.comments_count > 0 ?
                                                            <Tooltip arrow title={`Comment: ${post.like_count}`} key={`Reaction-comments_count`}>
                                                                <SocialsIcon style={{backgroundImage:`url(${this.instragramIcons['comment']})`,marginLeft: 0, zIndex: 9}}/>   
                                                            </Tooltip> 
                                                        : null                                             
                                                    }
                                                    <Typography variant="body2" style={{marginLeft:10}}>{post.like_count + post.comments_count}</Typography>
                                                </Grid>                                                             
                                            </SocialCounter>                                                            
                                            : 
                                            <Grid item xs={10}><Typography variant="body2">Aucune réaction sur ce post</Typography></Grid>
                                        }
                                        <Grid item xs={2} style={{flexGrow:'1'}}>
                                            <PostDate variant="body2">{moment(post.timestamp).format('L')}</PostDate>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardCustom>
                    </FeedItem>                                    
                )
                : <PageLoader/>}
                {pagination?.cursors?.after !== pagination?.cursors?.before ?
                    <Grid container justifyContent="center" style={{paddingBottom: 24}}>
                        {
                            this.props.loader ?
                                <CircularProgress color="inherit" size={'3rem'} 
                                style={{padding: 0}}
                                />
                            :
                                <SeeMore variant="body2" onClick={()=> this.handleClick()}>Voir plus <br/> ...</SeeMore>
                        }
                    </Grid>
                    : null 
                }
            </>
        );
    }
}