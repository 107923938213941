import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';

import TopPanel from '../../../layouts/TopPanel/TopPanel';

import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import colors from '../../../../config/theme/colors';
import { eventService } from '../../../../js/services/event.service';

import { ROUTE_BUILDER_CMS_CONTENT, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS, ROUTE_HOME, ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE, CREATE } from '../../../../js/constants/constant-rights';

import { prepareAttributeValues, saveElement, updateElement, getElements } from '../../../../js/utils/functions';
import Listing from '../../../layouts/Listing/Listing';
import { listSettings, listMappers, perPageOptions } from './config/pages/listPages.config';
import pageForm from './config/pages/formPage.config';
import contentForm from './config/pages/formContent.config';
import contentFormDuplicate from './config/pages/formContentDuplicate.config';
import * as formHelper from '../../../../js/helpers/form'
import { makeUnique, searchItem } from '../../../../js/helpers/search'
import { DELETE_MAGENTO_PAGE, DELETE_MAGENTO_PAGE_CONTENT, GET_MAGENTO_PAGES_LIGHT, GET_MAGENTO_PAGE_TYPES, UPDATE_MAGENTO_PAGE_CONTENT } from '../../../../queries/assetMagentoPage';
import { DELETE_PAGE, DELETE_PAGE_CONTENT, GET_PAGE_TYPES } from '../../../../queries/assetPagesPage';
import { Grid, Typography, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../ui/button/Button';
import * as moment from 'moment';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { GET_ASSETS_BY_TYPE, GET_ASSETS_BY_TYPES, GET_ASSET_BY_ID, GET_ASSET_BY_ID_NAVIGATION, GET_ASSET_TYPES } from '../../../../queries/assets';
import { checkRouting } from '../../../../js/utils/checkRouting';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import EmptyCard from '../../../ui/empty-card/EmptyCard';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import ReturnLink from '../../../ui/link/ReturnLink';
import DialogModal from '../../../ui/dialog/DialogModal';


/* //* Add to put the pagination at bottom */
const CustomDivContent = styled(Grid)`
    & + div{
        display: none !important;
    }
    & > .listing-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`
class DiffusionListPages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            openForm: false,
            editForm: 'add',
            assetTypeIdentifier: props.match.params.typeAsset,
            currentLang: props.locales[0].node.code,
            assetId: `/api/assets/${props.match.params.id}`,
            pageId: props.match.params.page ? props.match.params.typeAsset === 'pages' ? `/api/asset-pages-pages/${props.match.params.page}` : `/api/asset-magento-pages/${props.match.params.page}` : null,
            errors: {},
            seeErrors: false,
            ready: false,
            pageLibelle: null,
            pageIdentifier: null,
            metaTitle: null,
            metaDesc: null,
            metaKeywords: null,
            pageUrl: null,
            pageStatus: true,
            pageIsExisting: false,
            pageIdMagentoPage: null,
            pageType: null,
            contentLibelle: null,
            contentStartDate: null,
            contentEndDate: null,
            contentIsActive: true,
            contentIsDefaultContent: false,
            contentContent: null,
            contentContentConfig: null,
            hasAlreadyBeenPushed: false,
            useInCategory: false,
            typeTesting: {
                type: props.match.params.typeAsset === 'pages' ? 'pages-page' : 'magento-page',
                testingState: ['pageLibelle', 'pageIdentifier'],
                testingTypingState: 'pageLibelle',
                identifierState: 'pageIdentifier'
            },
            contentsSelected: []
        };
        this.typingTimer = null;
    }

    handleLang = (event) => {
        this.setState({
            currentLang: event.target.value,
        }, () => {
            eventService.fire();
        });
    };

    handleToggleDrawer = (stateDrawer, reset = false) => {
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
        if (reset) {
            this.resetState()
        }
    };


    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    handleNextStep = () => {
        let result = formHelper.handleStep('next', this.state.errors);
        this.setState({
            seeErrors: !result
        })
        return result;
    }

    handleBackStep = () => {
        this.setState({ errors: {} });
    }


    handleMediaPicker = (selected, stateName, translated) => {
        this.handleInput(stateName, selected, null, translated);
    }

    loadAssetSelected = (assetId) => {
        this.props.startLoading()
        this.props.client.query({
            query: GET_MAGENTO_PAGES_LIGHT,
            variables: {
                asset: assetId
            },
            fetchPolicy: 'no-cache'
        }).then(result => {
            this.setState({
                listPages: result.data.assetMagentoPages.edges
            })
            this.props.stopLoading()
        })
    }

    handleInput = (stateName, evt, custom, translated) => {
        let value = formHelper.setValue(this.state, stateName, evt, custom, translated);
        this.setState({ ...value })
        if (stateName === "assetSelected") {
            this.setState({
                pageSelected: null,
                contentsSelected: []
            })
            this.loadAssetSelected(evt?.target?.value ?? evt)
        }
        if (this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName);
    }

    resetState = () => {
        this.setState({
            pageLibelle: null,
            pageIdentifier: null,
            pageUrl: null,
            pageStatus: true,
            pageIsExisting: false,
            pageIdMagentoPage: null,
            pageType: this.props.type ? this.state.listTypesPage?.find(e => e.node.identifier === this.props.type).node.id : null,
            metaTitle: null,
            metaDesc: null,
            metaKeywords: null,
            contentLibelle: null,
            contentStartDate: null,
            contentEndDate: null,
            contentIsActive: true,
            contentIsDefaultContent: false,
            hasAlreadyBeenPushed: false,
            contentContent: null,
            contentContentConfig: null,
            contentIdToDuplicate: null,
            contentsSelected: [],
            pageSelected: null,
            assetSelected: null,
        })
    }

    handlerHeaderMutation = async () => {
        this.props.startLoading();
        let variables = null;
        switch (this.state.editForm) {
            case 'add':
                if (this.props.isContent) {
                    variables = {
                        pagesPage: this.state.pageId,
                        magentoPage: this.state.pageId,
                        libelle: this.state.contentLibelle,
                        startDate: this.state.contentStartDate,
                        endDate: this.state.contentEndDate,
                        isDefaultContent: false,
                        isActive: this.state.contentIsActive,
                        isCurrentlyDeployed: false,
                    }
                } else {
                    variables = {
                        asset: this.state.assetId,
                        libelle: this.state.pageLibelle,
                        identifier: this.state.pageIdentifier,
                        metaTitle: this.state.metaTitle,
                        metaDescription: this.state.metaDesc,
                        metaKeywords: this.state.metaKeywords,
                        // url: this.state.pageUrl,
                        url: '/',
                        hasToBePush: true,
                        isMagentoExistingPage: this.state.pageIsExisting,
                        magentoPageId: this.state.pageIdMagentoPage ?? null,
                        isActive: this.state.pageStatus,
                        pageType: this.state.pageType || null,
                        useInCategory: this.state.useInCategory,
                    }
                }
                await saveElement(this.props.isContent ? this.state.assetTypeIdentifier === 'pages' ? 'assetPagesPageContent' : 'assetMagentoPageContent' : this.state.assetTypeIdentifier === 'pages' ? 'assetPagesPage' : 'assetMagentoPage', variables, { enableLoad: false })
                this.props.snack(ALERT_SUCCESS, this.props.isContent ? this.props.t("spread.active_assets.contentAddSuccess") : this.props.t("spread.active_assets.pageAddSuccess"));
                this.handleToggleDrawer('openForm', true);
                this.reloadPages();
                this.props.stopLoading();
                break;

            case 'edit':
                if (this.props.isContent) {
                    variables = {
                        // TODO: LINK TO PAGE ID ?
                        id: this.state.currentContentId,
                        libelle: this.state.contentLibelle,
                        startDate: this.state.contentStartDate,
                        endDate: this.state.contentEndDate,
                        isActive: this.state.contentIsActive,
                    }
                } else {
                    variables = {
                        id: this.state.currentPageId,
                        asset: this.state.assetId,
                        libelle: this.state.pageLibelle,
                        identifier: this.state.pageIdentifier,
                        metaTitle: this.state.metaTitle,
                        metaDescription: this.state.metaDesc,
                        metaKeywords: this.state.metaKeywords,
                        // url: this.state.pageUrl,
                        url: '/',
                        hasToBePush: true,
                        isMagentoExistingPage: this.state.pageIsExisting,
                        isActive: this.state.pageStatus,
                        useInCategory: this.state.useInCategory,
                    }
                    if (this.state.hasAlreadyBeenPushed) {
                        variables.hasAlreadyBeenPushed = false
                    }
                }
                let getResult = await updateElement(this.state, this.props.isContent ? this.state.assetTypeIdentifier === 'pages' ? 'assetPagesPageContent' : 'assetMagentoPageContent' : this.state.identifier === 'pages' ? 'assetPagesPage' : 'assetMagentoPage', variables, null, { enableLoad: false })
                if (getResult) {
                    this.props.snack(ALERT_SUCCESS, this.props.isContent ? this.props.t("spread.active_assets.contentUpdateSuccess") : this.props.t("spread.active_assets.pageUpdateSuccess"));
                    this.handleToggleDrawer('openForm', true);
                    this.props.stopLoading();
                    this.reloadPages();
                }

                break;
            case 'duplicate':
                this.props.startLoading()
                for (let content of this.state.contentsSelected) {
                    this.props.client.mutate({
                        mutation: UPDATE_MAGENTO_PAGE_CONTENT,
                        variables: {
                            id: content,
                            content: this.state.contentContent,
                            contentConfig: this.state.contentContentConfig
                        }
                    })
                }
                this.props.snack(ALERT_SUCCESS, this.props.t("spread.active_assets.contentDuplicateSuccess"));
                this.handleToggleDrawer('openForm', true);
                this.props.stopLoading()
            default:
                return null;
        }
    }

    handleToggleDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog
        });
    };

    handleButtonGroupChange = (stateName, value) => {
        if (stateName === "pageIsExisting" && !value) {
            let errors = this.state.errors
            errors.pageIdMagentoPage = false
            this.setState({
                errors
            })
        }
        this.setState({
            [stateName]: value
        });
    };

    deleteMutation = () => {
        this.props.startLoading();
        this.props.client.mutate({
            mutation: this.props.isContent ? this.state.assetTypeIdentifier === "pages" ? DELETE_PAGE_CONTENT : DELETE_MAGENTO_PAGE_CONTENT : this.state.assetTypeIdentifier === "pages" ? DELETE_PAGE : DELETE_MAGENTO_PAGE,
            variables: { id: this.props.isContent ? this.state.currentContentId : this.state.currentPageId }
        }).then(result => {
            this.props.stopLoading();
            this.props.snack(ALERT_SUCCESS, this.props.isContent ? this.props.t("spread.active_assets.contentDeleteSuccess") : this.props.t("spread.active_assets.pageDeleteSuccess"));
            this.reloadPages();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        }).catch(error => {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, this.props.isContent ? this.props.t("spread.active_assets.contentDeleteError") : this.props.t("spread.active_assets.contentDeleteError"));
            this.reloadPages();
            this.handleToggleDialog();
            this.handleToggleDrawer('openForm', true);
        });
    };

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }
        if (this.state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/${typeTesting.type}/unique/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if (!data.isUnique) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        if (this.state.editForm === "edit") {
            this.setState({
                hasAlreadyBeenPushed: true
            })
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    initForm = () => {
        this.setState({
            editForm: 'add'
        })
        this.handleToggleDrawer('openForm');
    }

    initNavAsset = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${this.props.match.params?.id ? this.props.match.params?.id : null}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    handleDuplicate = (content) => {
        this.props.startLoading()
        this.props.client.query({
            query: GET_ASSET_TYPES,
        }).then(result => {
            let magentoType = result.data.assetTypes?.edges?.find(e => e.node.identifier === "magento")
            let shopifyType = result.data.assetTypes?.edges?.find(e => e.node.identifier === "shopify")
            this.props.client.query({
                query: GET_ASSETS_BY_TYPES,
                variables: {
                    assetType_list: [magentoType.node.id, shopifyType.node.id]
                }
            }).then(data => {
                this.setState({
                    listAssets: data.data.assets.edges,
                    editForm: 'duplicate',
                    contentIdToDuplicate: content.id,
                    contentContent: content.content,
                    contentContentConfig: content.contentConfig
                }, () => this.props.stopLoading())
                this.handleToggleDrawer('openForm');
            })
        })

    }

    editHandler = (page) => {
        // let local= moment.tz.guess();
        if (this.props.isContent) {
            this.setState({
                editForm: 'edit',
                currentContentId: page.id,
                contentLibelle: page.libelle,
                contentStartDate: moment(page.startDate).utc(),
                contentEndDate: moment(page.endDate).utc(),
                contentIsActive: page.isActive,
            })
        } else {
            this.setState({
                editForm: 'edit',
                currentPageId: page.id,
                pageLibelle: page.libelle,
                pageIdentifier: page.identifier,
                metaTitle: page.metaTitle,
                metaDesc: page.metaDescription,
                metaKeywords: page.metaKeywords,
                pageUrl: page.url,
                pageStatus: page.isActive,
                pageIsExisting: page.magentoPageRealId ?true: false,
                pageIdMagentoPage: page.magentoPageRealId,
                pageType: page.pageType.id,
                useInCategory: page.useInCategory ? page.useInCategory : false,
            })
        }
        this.handleToggleDrawer('openForm', false);
    }

    handlerRedirectContents = (page) => {
        if (this.props.route) {

            let path = this.props.route.replace(':id', this.props.match.params.id).replace(':typeAsset', this.state.assetTypeIdentifier).replace(':page', page.id.replace(this.state.assetTypeIdentifier === "pages" ? '/api/asset-pages-pages/' : '/api/asset-magento-pages/', ''));

            this.goTo(path);
        } else {
            this.goTo(
                this.props.type === "home" ?
                    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS.replace(':id', this.props.match.params.id).replace(':typeAsset', this.state.assetTypeIdentifier).replace(':page', page.id.replace(this.state.assetTypeIdentifier === "pages" ? '/api/asset-pages-pages/' : '/api/asset-magento-pages/', ''))
                    :
                    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS.replace(':id', this.props.match.params.id).replace(':typeAsset', this.state.assetTypeIdentifier).replace(':page', page.id.replace(this.state.assetTypeIdentifier === "pages" ? '/api/asset-pages-pages/' : '/api/asset-magento-pages/', ''))
            )
        }
    }

    handlerClearCache = (page) => {
        try {
            request(`${process.env.REACT_APP_API}/cmsPage/flush/${this.props.match.params.id}/${page.id.replace('/api/asset-magento-pages/', '')}`, 'get').catch((error) => {
                this.props.snack(ALERT_SUCCESS, 'Cache vidé avec succès');
            });
        } catch (e) {
            this.props.snack(ALERT_SUCCESS, 'Cache vidé avec succès');
            console.log(e?.message ?? e);
        }
    }

    getCallback = () => {
        this.setState({
            reloadPages: false,
        })
    }

    reloadPages = () => {
        this.setState({ reloadPages: true })
    }

    getPageTypes = () => {
        return new Promise((resolve, reject) => {
            this.props.client.query({
                query: this.state.assetTypeIdentifier === "pages" ? GET_PAGE_TYPES : GET_MAGENTO_PAGE_TYPES,
            }).then(result => {                
                this.setState({
                    isReady: true,
                    listTypesPage: this.state.assetTypeIdentifier === "pages" ? result.data.assetPagesPageTypes.edges : result.data.assetMagentoPageTypes.edges,
                    pageType: this.props.type ? this.state.assetTypeIdentifier === "pages" ? result.data.assetPagesPageTypes.edges.find(e => e.node.identifier === this.props.type).node.id : result.data.assetMagentoPageTypes.edges.find(e => e.node.identifier === this.props.type).node.id : null
                }, () => resolve())
            })
        })
    }

    async componentDidMount() {
        checkRouting(this.props);
        this.initNavAsset();
        await this.getPageTypes();
        if (!this.props.isContent) {
            this.setState({
                isReady: false,
            });
        }
        this.setState({
            isReady: true,

        });

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isContent !== this.props.isContent && !this.props.isContent) {
            checkRouting(this.props);
            this.reloadPages();
        }
        if (prevProps.type !== this.props.type) {
            this.setState({
                pageType: this.props.type ? this.state.listTypesPage?.find(e => e.node.identifier === this.props.type).node.id : null
            })
        }
        if (prevProps.match.params?.page !== this.props.match.params?.page) {
            this.setState({
                        pageId: this.props.match.params.page ? this.props.match.params.typeAsset === 'pages' ? `/api/asset-pages-pages/${this.props.match.params.page}` : `/api/asset-magento-pages/${this.props.match.params.page}` : null,
            })
        }
    }

    render() {
        return (
            <div style={{ width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', height: 'calc(100% - 64px)', display: 'grid', gridTemplateRows: 'auto auto 1fr' }}>
                <TopPanel
                    icomoon={this.props.isContent ? "picto-content" : "picto-connector-content"}
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.isContent ? this.props.t("spread.active_assets.listPageContent") : this.props.t("spread.active_assets.listAssetPageContent", { typeOf: this.props.type === "home" ? 'Homes' : 'Landings' })}
                    subtitle={this.props.isContent ? this.props.t("spread.active_assets.manageContent") : this.props.t("spread.active_assets.managePage")}
                    handlerAdd={() => this.initForm()}
                    textAdd={this.props.isContent ? this.props.t("spread.active_assets.addContent") : this.props.type === "home" ? '+ ' + this.props.t("spread.active_assets.addHome") : '+ ' + this.props.t("spread.active_assets.addPage")}
                    // searchHandler={
                    //     this.handleChangeCompanies
                    // } 
                    gradientColor1={colors.menu.regular}
                    gradientColor2={colors.menu.darker}
                    openForm={this.state.openForm}
                    buttonAvailable={!this.state.openForm}
                    hasBorder={true}
                />
                {
                    this.props.isContent ?
                    <Box style={{paddingBottom:"12px"}}>
                        <ReturnLink goTo={this.props.history.goBack}/>
                    </Box>
                    :null
                }                
                <CustomDivContent container style={{
                    position: 'relative',
                    paddingTop: this.props.isContent? 0:''
                }}>                    
                    {   
                        this.state.listTypesPage?.length > 0 ?
                            <Listing
                                label={this.props.isContent ? this.props.t("spread.active_assets.contents") : this.props.t("spread.active_assets.pages")}
                                settings={listSettings}
                                cardProps={{
                                    noImage:true,
                                    openForm: this.state.openForm,
                                    currentLang: this.state.currentLang,
                                    textButton: 'Modifier',
                                    handlerButton: this.editHandler,
                                    textButtonSecondary: this.props.isContent ? this.props.t("spread.active_assets.viewContent") : this.props.t("spread.active_assets.viewContents"),
                                    handlerButtonSecondary: this.props.isContent ?
                                        (page) => {
                                            this.state.assetTypeIdentifier === "pages" ?
                                                window.open(`${window.location.origin}${ROUTE_BUILDER_CMS_CONTENT.replace(':assetId', page.pagesPage.asset.identifier).replace(':contentId', page.id.replace('/api/asset-pages-page-contents/', ''))}`, '_blank')
                                                :
                                                window.open(`${window.location.origin}${ROUTE_BUILDER_CMS_CONTENT.replace(':assetId', page.magentoPage.asset.identifier).replace(':contentId', page.id.replace('/api/asset-magento-page-contents/', ''))}`, '_blank')
                                        }
                                        : this.handlerRedirectContents,
                                    textButtonThird: this.props.isContent ? this.props.t("spread.active_assets.duplicate") : this.props.t("spread.active_assets.clearCache"),
                                    handlerButtonThird: this.props.isContent ?
                                        this.handleDuplicate
                                        : this.handlerClearCache,
                                    windowWidth: this.props.windowWidth,
                                    isContent: this.props.isContent
                                }}
                                perPageOptions={perPageOptions}
                                pagination={true}
                                mappers={listMappers}
                                currentLang={this.state.currentLang}
                                identifier={this.props.isContent ? this.state.assetTypeIdentifier === 'pages' ? 'assetPagesPageContents' : 'assetMagentoPageContents' : this.state.assetTypeIdentifier === 'pages' ? 'assetPagesPages' : 'assetMagentoPages'}
                                queryVariables={{
                                    asset: this.state.assetId,
                                    magentoPage: this.props.isContent ? `/api/asset-magento-pages/${this.props.match.params.page}` : null,
                                    pagesPage: this.props.isContent ? `/api/asset-pages-pages/${this.props.match.params.page}` : null,
                                    pageType: this.props.type ? this.state.listTypesPage?.find(e => e.node.identifier === this.props.type).node.id : null,
                                }}
                                viewsOptions={{
                                    current: 'card',
                                    settings: ['card'] //Si il n'y a qu'un seul item il n'y aura pas de changement de vues
                                }}
                                reload={this.state.reloadPages}
                                listingCallback={this.getCallback}
                                noResultComponent={() => (
                                    <Box style={{
                                        height:"100%",
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center"
                                    }}>
                                        <EmptyCard title={this.props.t("spread.active_assets.assetNotConfigured")} subtitle={this.props.t("spread.active_assets.clickToAdd")} textButton={this.props.t("spread.active_assets.addContentManagement")} onClick={() => this.handleToggleDrawer('openForm', true)} picto={EmptyAsset} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                                    </Box>
                                )}
                            />
                            : <PageLoader />
                    }
                </CustomDivContent>

                {this.state.isReady ? (
                    <LayoutBuilder
                        isSublayout={false}
                        icomoon={this.props.isContent ? "picto-content" : "picto-connector-content"}
                        opened={this.state.openForm}
                        forClose={() => this.handleToggleDrawer('openForm', true)}
                        handlerSetup={() => { }}
                        dataLayout={this.props.isContent ? this.state.editForm === "duplicate" ? contentFormDuplicate(this.state) : contentForm(this.state.editForm, this.state) : pageForm(this.state.editForm, this.state, this.props.type)}
                        drawerWidth={this.props.drawerWidth}
                        allState={this.state}
                        stateCallback={this.handleInput}
                        errorCallback={this.handleFormError}
                        stepperButtonAction={[
                            this.handleNextStep,
                            this.handleNextStep,
                            this.handleNextStep,
                        ]}
                        handleButtonGroupChange={this.handleButtonGroupChange}
                        validateButton={true}
                        handlerMutation={this.handlerHeaderMutation}
                        currentLang={this.state.currentLang}
                        handleLang={this.handleLang}
                        deleteMutation={this.state.editForm === "edit" ? () => { this.handleToggleDialog() } : null}
                        deleteText={this.props.isContent ? this.props.t("spread.active_assets.deleteContent") : this.props.t("spread.active_assets.deletePage")}
                    />
                )
                    : null}


                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialog}
                    onClose={this.handleToggleDialog}
                    title={
                        this.props.isContent
                            ? this.props.t("spread.active_assets.sureToDeleteContent")
                            : this.props.t("spread.active_assets.sureToDeletePage")
                    }
                    primaryAction={this.deleteMutation}
                    secondaryAction={this.handleToggleDialog}
                    windowWidth={this.props.windowWidth}
                >
                    <DialogContentText id="alert-dialog-description">
                        {this.props.isContent
                            ? this.props.t("spread.active_assets.warningToDeleteContent")
                            : this.props.t("spread.active_assets.warningToDeletePage")
                        }
                    </DialogContentText>
                </DialogModal>
            </div>
        );
    }

    // be careful, only breaks references at objects level
    copyArrayOfObjects = array => array.map(a => a.node ? ({ ...a, node: { ...a.node } }) : ({ ...a }));

    goTo = (route, typeAsset) => {
        this.props.history.push({
            pathname: route,
            state: {
                typeAsset: typeAsset
            }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    };
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(DiffusionListPages)))));