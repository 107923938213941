import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';

import { withRouter } from 'react-router';
function CellProfilPicture(props){

    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',
                padding : '4px 16px'
            }}
        >
            <Avatar src={`${process.env.REACT_APP_MEDIAS}/${props.value}`}></Avatar>
        </TableCell>
    )
}
export default withRouter(CellProfilPicture);