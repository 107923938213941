import React, { useEffect, useRef, useState } from "react";
import Swiper from 'swiper';
import H2 from '../shared/h2';
import H5 from '../shared/h5';
import Div from '../shared/div';
import P from '../shared/p';
import Button from '../shared/a';
import Img from '../shared/img';
import { PictureTag } from "../../../../../utils/image";

const SliderAbout = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    useEffect(() => {
        new Swiper('.swiper-container-about', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-about',
                prevEl: '.swiper-button-prev-about',
            },
            scrollbar: {
                el: '.swiper-scrollbar-about',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                },
                1100:{
                    slidesPerView: 3.1,
                },
            },
        });
    }, []);

    useEffect(() => {
        new Swiper('.swiper-container-about', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-about',
                prevEl: '.swiper-button-prev-about',
            },
            scrollbar: {
                el: '.swiper-scrollbar-about',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                },
                1100:{
                    slidesPerView: 3.1,
                },
            },
        });
    }, [props]);

    return (
        <Div style={{position: 'relative'}} className="slider-about" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div style={{width:data.inputs?.noPadding?.value?"100%":"auto",marginLeft:data.inputs?.noPadding?.value?"0":"3.5%"}} className="bloc-list bloc-list-about">
                <Div className="title-box">
                    <H2 color={data.inputs?.color?.value} textTransform={data.inputs?.textTransform?.value} className="w100">{data.inputs.value.value}</H2>
                </Div>
                <Div className="swiper-container swiper-container-about">
                    <div className="swiper-wrapper">
                        {
                            data.blocks.slides.config.map((slide, index) => {
                                let image = slide.blocks.image.inputs.value.value;
                                let alt = slide.blocks.image.inputs?.alt?.value ?? "Illustration";

                                return (
                                    <Div className="swiper-slide w100 about" key={index}>
                                        <a href={slide.blocks.link.inputs.link.value}>
                                            <PictureTag
                                                media={image}
                                                alt={alt}
                                                height={slide.blocks.image.inputs?.height?.value}
                                                spread={spread}
                                                classes="w100"
                                                lazy={true}
                                                isOptimised={data.inputs.optimisedImage?.value}
                                            />
                                            <div className={data.inputs.textOnImage?.value ? 'absoluteContent' : ''}>
                                                <H5 style={{color:data.inputs.color?.value}} textTransform={slide.blocks.title.inputs?.textTransform?.value} className="din-offc">{slide.blocks.title.inputs.value.value}</H5>
                                                <P>{slide.blocks.text.inputs.value.value}</P>
                                                <Div>
                                                    <Button backgroundColor="rgba(0,0,0,0)" className="link">{slide.blocks.link.inputs.value.value}</Button>
                                                </Div>
                                            </div>
                                        </a>
                                    </Div>
                                );
                            })
                        }
                    </div>
                    <div className="swiper-scrollbar swiper-scrollbar-about"></div>
                    <div className="swiper-button-prev swiper-button-prev-about"></div>
                    <div className="swiper-button-next swiper-button-next-about"></div>
                </Div>
            </Div>
        </Div>
    );
};

export default SliderAbout;