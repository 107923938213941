import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText, getDefaultTextarea, getImageDefault, getDefaultScratchCard } from '../../helpers';


export default {
    name: 'Image à gratter',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks: {
        startPopup:{
            id: uuidv4(),
            title: "Popup start",
            name: "Popup start",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
                overlay:{
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: 'rgba(0,0,0,0.5)'
                }
            },
            blocks: {
                title: getDefaultText('Titre', "Es tu prêt ?", 50, "#53839D", null, 'uppercase', 'initial'),
                desc: getDefaultTextarea('Description du jeu', "Bref résumé du concept du jeu à suivre.", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),
                button: getDefaultButton("Boutton", "C'est parti", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
            }
        },
        game:{
            id: uuidv4(),
            title: "Jeu",
            name: "Jeu",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                borderCards: {
                    type: FormInputTypes.GROUP,
                    label: "Border des cards",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks: {
                logo: getImageDefault(null, 'Logo'),
                title: getDefaultText('Titre', "Titre", 50, "#53839D", null, 'uppercase', 'center'),
                subtitle: getDefaultTextarea('Sous-titre', "Sous-titre", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'center', 20),
                list_lots:{
                    title: "Liste des lots",
                    name: "Liste des lots",
                    type: EditableTypes.MULTIPLE_BLOCKS,
                    subtype: ItemTypes.SCRATCH_CARD,
                    limit: 3,
                    config: [
                        getDefaultScratchCard(),
                        getDefaultScratchCard(),
                        getDefaultScratchCard(),
                    ]
                },
            }
        },
        endGame:{
            id: uuidv4(),
            title: "Popup start",
            name: "Popup start",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                overlay:{
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: 'rgba(0,0,0,0.5)'
                }
            },
            blocks: {
                imageWin: getImageDefault(null, 'Image gagné'),
                imageLoose: getImageDefault(null, 'Image perdu'),
            }
        }
    }
};
