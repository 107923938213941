import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/empty_products.png';
import UploadFile from '../components/UploadFile';
import Mapping from '../components/Mapping';

const ImportProducts =  {
    titleForm: `Importer des contenus`,
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour importer vos contenus',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: STEPPER,
        finalStep: {
            picto: CatalogFinal,
            title: 'Bientôt prêt !',
            subtitle: 'Votre fichier est prêt à être importé :)',
            textButton: 'Importer le fichier'
        },
        children: [
            {
                labelName: 'Choix du fichier',
                isOptionnal: false,
                component: UploadFile,
                optionsInputs: []
            },
            {
                labelName: 'Mapping',
                isOptionnal: false,
                component: Mapping,
                optionsInputs: []
            }
        ]
    }
};

export default ImportProducts;