import { FormInputTypes  } from '../../../shareable/types';
import { getDefaultLink, getImageDefault, getDefaultTextarea } from '../../helpers';


export default {
    name: "Bloc secondaire page de résultat",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        }
    },
    blocks:{
        title: getDefaultTextarea("Titre", "Le jeu n'est pas fini", true, '#FFFFFF', 'rgba(0,0,0,0)', 'initial', null, 50),
        button: getDefaultLink("Bouton", "Voir les produits", '', 24, '#53839D', '#FFFFFF',  null, 1, '#53839D', 0),
        image: getImageDefault(),
    }
};
