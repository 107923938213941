import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { perPageOptions } from '../../components/screens/dashboardCRM/contacts/config/companyListing.config';
import styled from 'styled-components';

const TablePaginationCustom = styled(TablePagination)`
    .MuiToolbar-root{
        padding: 0;
    }
    .MuiTablePagination-spacer{
        display: none;
    }
`;


/**
 * Change la page en cours
 * @param {Object} pagination - Paramètre de pagination actuelle
 * @param {number} newPage - Page demandée
 * @returns {Object} - Nouveaux paramètres de pagination
 */
export const changePage = (pagination,newPage, typeResult= "edges") =>{
    return new Promise((resolve,reject)=>{
        let {page,startCursor,endCursor} = pagination;
        let newPagination = null;
        if (typeResult === "edges"){
            newPagination = {
                ...pagination,
                startCursor : newPage > page ? endCursor : null,
                endCursor   : newPage > page ? null : startCursor,
                page        : newPage,
            }
        }else{
            newPagination = {
                ...pagination,
                page        : newPage,
            }
        }
        resolve(newPagination)

    })
}

/**
 * Transforme les paramètres de paginations en variables pour une query
 * @param {Object} pagination - Paramètres de pagination
 * @returns {Object} - Variables de query
 */
export const paginationQueryData = (pagination,typeResult="edges")=>{
    return new Promise((resolve,reject)=>{
        let variables = null;
        if (typeResult === "edges"){
            variables = { 
                "nbperpage": pagination.perPage,
                "first": pagination.perPage
            };
        }else{
            variables = { 
                "itemsPerPage": pagination.perPage
            };
        }
        if (pagination.startCursor) {
            variables.cursor = pagination.startCursor;
            variables.after = pagination.startCursor;
        }
        if (pagination.endCursor) {
            variables.cursorLast = pagination.endCursor;
            variables.before = pagination.endCursor;
        }
        if (pagination.page !== null) {
            variables.page = pagination.page + 1;
        }
        resolve(variables)
    })
}


/**
 *  Mets à jour les paramètres de pagination après une query
 * @param {Object} pagination - Paramètres de pagination
 * @param {Object} result - Résultats de la query 
 * @returns {Object} - Nouveaux paramètres de pagination
 */
export const updatePaginationData = (pagination,result, type = "edges",identifier) =>{
    return new Promise((resolve,reject)=>{
        let newPagination = {}
        if (type === "edges"){
            newPagination = {
                ...pagination,
                startCursor : result?.pageInfo?.startCursor,
                endCursor : result?.pageInfo?.endCursor,
                count : result?.totalCount,
            }
        }else{
            newPagination = {
                ...pagination
                ,
                itemsPerPage: pagination.perPage,
                count : result.paginationInfo.totalCount,
                paginationInfo: result.paginationInfo
            }
        }
        resolve(newPagination);
    })
}

/**
  * Change le nombre d'élèments par page
  * @param {Object} pagination - Paramètres de la pagination
  * @param {number} value - Nouveau nombre d'élèments par page
  * @returns {Object} - Paramètres de pagination
  */
export const updatePerPage = (pagination,value) => {
    return new Promise((resolve,reject)=>{
        let newPagination = {
            ...pagination,
            perPage : value,
            page : 0,
            startCursor : null,
            endCursor : null
        }
        resolve(newPagination);
    })
}

export const resetPagination = (perPage = 6) =>{
    return new Promise((resolve,reject)=>{
        let newPagination = {
            perPage : perPage,
            page : 0,
            startCursor : null,
            endCursor : null,
        }   
        resolve(newPagination);
    })
}


export const CursorPagination = (props) => {
    let {type,pagination,rowLabel, typeResult = "edges", inFormType} = props
    ;
    const [page,setPage] = useState(pagination.page ? pagination.page : 0);
    const [perPage,setPerPage] = useState(props.pagination.perPage || props.pagination.itemsPerPage);
    
    
    const handleChangePage = async(event,newPage) => {
        setPage(newPage);
        props.changePageCallback(newPage, inFormType);
    }
    
    const handleRowText = () => {
        let pagination = props.pagination
        ;
        let perPage = typeResult === "edges" ? pagination.perPage : pagination.itemsPerPage;
        let from = pagination.page * perPage;
        let to =(pagination.page +1)  * perPage;

        from = from === 0 ? 1 : from;
        to = to >pagination.count ? pagination.count : to;
        let text = `${from}-${to} sur ${pagination.count} `;
        return(text);
    }

    const handleChangeRowsPerPage = async(event)=>{
        let value = event.target.value;
        setPerPage(value);
        props.changePerPageCallback(value);
    }
    return (
        <div style={{width:'100%', display: props.onLeft ? 'flex' : null}}>
            {type == 'table' ? 
                <TablePaginationCustom
                    page = {page}
                    component="div"
                    onChangePage={handleChangePage}
                    rowsPerPage={perPage}
                    onChangeRowsPerPage={props.perPageOptions ? handleChangeRowsPerPage : ()=>{}}
                    count = {pagination.count}
                    labelDisplayedRows={(pagination) => handleRowText(pagination)}
                    labelRowsPerPage={rowLabel}
                    rowsPerPageOptions={props.perPageOptions ? props.perPageOptions : []}
                    style={{borderBottom: 0}}
                />
            : null}
        </div>
    )
}

