import React, { useState, useEffect } from 'react';
import Swiper from 'swiper';
import Div from '../shared/div';
import H2 from '../shared/h2';
import H5 from '../shared/h5';
import Img from '../shared/img';
import P from '../shared/p';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

const DialogCustom = styled(Dialog)`
    .MuiDialog-paper {
        max-width: 640px;
        width: 100%;
    }
    @media screen and (max-width: 960px) {
        .MuiDialog-paper {
            height: 100vh;
            margin: 0;
            max-width: 360px;
            border-radius: 0;
        }

        .MuiDialog-container {
            height: auto;
        }

        .MuiDialog-scrollPaper {
            justify-content: flex-end;
        }
    }
    @media screen and (min-width: 992px) {
        .MuiDialog-paper {
            max-width: 890px;
            width: 100%;
        }
    }
    
    .containerTop {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: space-between;
        background: rgb(250 250 251);
        padding: 20px;
        p{
            margin: 0;
        }
        h2{
            margin: 0;
        }
    }
    
    .sports {
        font-family: 'DINOffc-Bold', sans-serif;
        color: rgb(6 14 24);
        font-size: 18px;
    }
    
    .close {
        display: flex;
        align-items: center;
        filter: invert(47%) sepia(32%) saturate(209%) hue-rotate(182deg) brightness(84%) contrast(87%);
        cursor: pointer;
    }
    
    .containerSportsList {
        padding: 25px 20px;
        ul{
            list-style: none;
            padding: 0;
        }
    }
    
    .sportCategory,
    .sportCategoryActive {
        font-family: 'DINOffc-Bold', sans-serif;
        font-weight: bold;
        font-size: 31px;
        text-transform: uppercase;
        color: rgb(230 231 234);
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 200ms ease-in;
        padding-left: 20px;
    }
    
    .sportCategory:hover {
        color: rgb(6 14 24);
    }
    
    .sportCategoryActive {
        font-size: 2.5625rem;
        color: rgb(6 14 24);
    }
`;

const ContainerListCategories = styled(Div)`
    position: relative;
    width: 95%;
    margin-left: 5%;
    padding-top: 50px;
    padding-bottom: 20px;
    @media screen and (min-width: 1024px){
        padding-top: 70px;
        padding-bottom: 40px;
    }
    @media screen and (max-width: 767px){
        width: 95%;
        margin: auto;
        .containerAthlete {
            width: calc(100% - 30px);
            height: calc(100% - 30px);
        }
    }
    .container-listCategories {
        display: flex;
        width: 100%;
        position: relative;
        height: 100%;
        max-height: 600px;
    }
    .slideCard {
        display: flex;
        flex-direction: column;
        min-width: 400px;
        width: 450px;
        height: 600px;
    }
    .cardImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .cardText {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding: 15px;
        @media screen and (min-widht: 768px){
            padding: 30px;
        }
    }
    .listCategories {
        position: relative;
        height: 600px;
        margin: 0 20px;
        width: auto;
        min-width: 260px;
        overflow-y: auto;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    h2 {
        text-transform: uppercase;
        margin-bottom: 30px;
        font-family: 'DINOffc-Bold', sans-serif;
    }
    img {
        height: 600px;
    }
    h5 {
        font-family: 'DINOffc-Bold', sans-serif;
        font-size: 31px;
        text-transform: uppercase;
        margin-bottom: 10px;
        color: #fff;
        line-height: 1;
        @media screen and (min-width: 768px){
            font-size: 42px;
            margin-bottom: 15px;
            transition: all 250ms ease-out;
        }
    }
    .description {
        margin: 0;
        position: relative;
        z-index: 1;
        font-family: 'DinRegular', sans-serif;
        font-size: 16px;
        color: #fff;
        @media screen and (min-width: 768px){
            font-size: 20px;
            line-height: 1.3em;
            transition: all 250ms ease-out;
        }
    }
    .hashtag {
        margin: 0;
        position: absolute;
        top: 20px;
        font-family: 'Univers', sans-serif;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        z-index: 1;
        @media screen and (min-width: 768px){
            position: initial;
            font-weight: bold;
            font-size: 18px;
            margin-top: 35px;
            transition: all 250ms ease-out;
            text-transform: lowercase;
            line-height: 1.3em;
        }
    }
    .category {
        font-family: 'DINOffc-Bold', sans-serif;
        font-weight: bold;
        font-size: 31px;
        text-transform: uppercase;
        color: rgb(230 231 234);
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 200ms ease-in;
        padding-left: 20px;
        &:hover {
            color: rgb(0 14 40);
        }
    }
    .cardImgHoverlay {
        background: linear-gradient(to top, rgba(0, 14, 40, .6), rgba(0, 14, 40, .0));
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .slideCard:hover .cardImgHoverlay {
        background: linear-gradient(to top, rgba(0 14 40, 1), rgba(0, 14, 40, 0.1));
        opacity: 1;
    }




    .container {
        width: 100%;
        padding: 0 16px;
    }
    .containerTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    .title {
        font-family: 'DINOffc-Bold', sans-serif;
        font-size: 31px;
        font-weight: bold;
        text-transform: uppercase;
        color: rgb(6 14 24);
        margin: 0;
    }
    
    .sportMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Univers', sans-serif;
        font-size: 16px;
        color: rgb(6 14 24);
        margin: 0;
        cursor: pointer;
    }
    
    .card {
        width: 100%;
        height: 400px;
        position: relative;
        margin-bottom: 15px;
        margin-right: 15px;
    }
    
    .cardImg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    .cardImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-size: cover;
    }
    
    .cardImgHoverlay,
    .cardImgHoverlayActive {
        background: linear-gradient(to top, rgba(0, 14, 40, .6), rgba(0, 14, 40, .0));
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    
    .cardImgHoverlayActive {
        background: linear-gradient(to top, rgba(0, 14, 40, 1), rgba(0, 14, 40, .1));
    }
    
    .containerAthlete {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 15px;
    }
    
    .athleteName,
    .athleteDescription,
    .athleteLink,
    .athleteTagActive {
        position: relative;
        z-index: 1;
        font-family: 'DinRegular', sans-serif;
        font-size: 16px;
        color: rgb(6 14 24);
        color: #fff;
        margin: 0;
        width: calc(100% - 30px);
    }
    
    .athleteName {
        font-family: 'DINOffc-Bold', sans-serif;
        font-size: 31px;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 0;
    }
    
    .athleteLink {
        font-family: 'DINOffc-Bold', sans-serif;
        font-size: 12px;
        text-decoration: underline;
        margin-top: 30px;
        cursor: pointer;
        margin-bottom: 15px;
    }
    
    .athleteTag {
        display: none;
    }
    
    .athleteTagActive {
        position: absolute;
        top: 20px;
        font-family: 'Univers', sans-serif;
        font-size: 12px;
        font-weight: bold;
    }
    
    .containerTop {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: space-between;
        background: rgb(250 250 251);
        padding: 20px;
    }
    
    .sports {
        font-family: 'DINOffc-Bold', sans-serif;
        color: rgb(6 14 24);
        font-size: 18px;
    }
    
    .close {
        display: flex;
        align-items: center;
        filter: invert(47%) sepia(32%) saturate(209%) hue-rotate(182deg) brightness(84%) contrast(87%);
        cursor: pointer;
        font-family: 'Univers', sans-serif;
    }
    
    .containerSportsList {
        padding: 25px 20px;
    }
    
    .sportCategory,
    .sportCategoryActive {
        font-family: 'DINOffc-Bold', sans-serif;
        font-weight: bold;
        font-size: 31px;
        text-transform: uppercase;
        color: rgb(230 231 234);
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 200ms ease-in;
        padding-left: 20px;
    }
    
    .sportCategory:hover {
        color: rgb(6 14 24);
    }
    
    .sportCategoryActive {
        font-size: 2.5625rem;
        color: rgb(6 14 24);
    }

    @media screen and (min-width: 768px) {
        .sportMenu {
            display: none;
        }
    
        .sportCategoryActive {
            display: table-caption;
        }
    
        .card {
            min-width: 400px;
            width: 450px;
            height: 600px;
        }
    
        .card:hover .athleteName,
        .card:hover .athleteDescription,
        .card:hover .athleteTagActive,
        .card:hover .cardImgHoverlayActive {
            opacity: 1;
            bottom: 0px;
        }
    
        .containerAthletes {
            display: flex;
            width: 100%;
            position: relative;
            height: 100%;
            max-height: 600px;
        }
    
        .containerSportsListDesktop {
            position: relative;
            height: 600px;
            margin: 0 20px;
            width: 260px;
        }
    
        .sportsListHeightLine {
            position: absolute;
            left: 1px;
            width: 4px;
            height: 100%;
            background: rgb(230 231 234);
        }
    
        .sportsListScroll {
            height: 100%;
            z-index: 1;
        }
    
        .thumb {
            background: rgb(6 14 24);
            width: 8px;
            z-index: 2;
        }
    
        .athleteName {
            bottom: -20px;
            opacity: 0;
            font-size: 42px;
            margin-bottom: 15px;
            transition: all 250ms ease-out;
        }
    
        .athleteDescription {
            bottom: -20px;
            opacity: 0;
            font-size: 20px;
            line-height: 1.3em;
            transition: all 250ms ease-out;
            margin: 0;
        }
    
        .athleteTagActive {
            bottom: -20px;
            opacity: 0;
            position: initial;
            font-weight: bold;
            font-size: 18px;
            margin-top: 35px;
            transition: all 250ms ease-out;
            text-transform: lowercase;
        }
    
        .cardImgHoverlayActive {
            opacity: 0;
            transition: all 250ms ease-out;
        }
    
        .containerAthlete {
            padding: 30px;
        }
    }
`;

const ListCategories = (props) => {
    const [hover, setHover] = useState(false);
    const [isSportCategoryActive, setIsSportCategoryActive] = React.useState(-1);
    const [open, setOpen] = React.useState(false);
    const [readMore, setReadMore] = React.useState(-1);
    const [categories, setCategories] = React.useState([]);
    const [categoriesItems, setCategoriesItems] = React.useState([]);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {  
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []); 

    const handleReadMore = (index) => {
        if(readMore !== -1 && readMore === index){
            setReadMore(-1);
        }
        else{
            setReadMore(index);
        }
    }; 

    const isDesktop = windowSize.width >= 768;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSportCategoryActive = (index) => {
        if(isSportCategoryActive !== -1 && isSportCategoryActive === index){
            setIsSportCategoryActive(-1);
        }
        else{
            setIsSportCategoryActive(index);
        }
    };

    const exportData = () => {
        let cat = [];
        let subitem = [];
        
        for(let categorie of data.blocks.categories.config){
            cat.push(categorie.blocks.title.inputs.value.value);
            for(let item of categorie.blocks.subItem.config){
                subitem.push({
                    name: `${item.blocks.title.inputs.value.value} ${item.blocks.title2?.inputs.value.value}`, 
                    firstName: item.blocks.title.inputs.value.value, 
                    lastName: item.blocks.title2?.inputs.value.value, 
                    category: categorie.blocks.title.inputs.value.value, 
                    description: item.blocks.text.inputs.value.value, 
                    img: typeof item.blocks.image.inputs.value.value === 'string' ? item.blocks.image.inputs.value.value : item.blocks.image.inputs.value.value.filePath ? `${process.env.REACT_APP_MEDIAS}/${item.blocks.image.inputs.value.value.filePath}` : null 
                })
            }
        }

        setCategories(cat);
        setCategoriesItems(subitem);
    };

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-listCategories', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-listCategories',
                prevEl: '.swiper-button-prev-listCategories',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                }
            },
        });

        // exportData();
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-listCategories', {
            direction: 'horizontal',
            navigation: {
                nextEl: '.swiper-button-next-listCategories',
                prevEl: '.swiper-button-prev-listCategories',
            },
            spaceBetween: 16,
            breakpoints: {
                300:{
                    slidesPerView: 1.2,
                },
                700:{
                    slidesPerView: 2.1,
                }
            },
        });

        // exportData();
    }, [props, isSportCategoryActive, windowSize]);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    return (
        <div style={{ position: 'relative' }} 
            className="slider-categories"
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)} 
            data-config={JSON.stringify(data.blocks.categories.config)} 
        >
            { hover && !preview && props.children }
                <ContainerListCategories className="hide-mobile">
                    <H2>{data.inputs.value.value}</H2>
                    <Div className="container-listCategories">
                        <Div className="swiper-container swiper-container-listCategories">
                            <div className="swiper-wrapper">
                                {
                                    isSportCategoryActive !== -1 ? 
                                        data.blocks.categories.config[isSportCategoryActive].blocks.subItem.config.map((slide, index1) => (
                                            <Div className="swiper-slide w100 slideCard" key={index1}>
                                                <Div className="cardImg">
                                                    <Img 
                                                        className="w100" 
                                                        src={
                                                            typeof slide.blocks.image.inputs.value.value === 'string' ? 
                                                                slide.blocks.image.inputs.value.value 
                                                            : slide.blocks.image.inputs.value.value.filePath ? 
                                                                `${process.env.REACT_APP_MEDIAS}/${slide.blocks.image.inputs.value.value.filePath}` 
                                                            : null
                                                        }
                                                        alt={slide.blocks.image.inputs?.alt?.value ?? "Illustration"}
                                                    />
                                                    <Div className="cardImgHoverlay"></Div>
                                                </Div>
                                                <Div className="cardText">
                                                    <H5>{`${slide.blocks.title.inputs.value.value} ${slide.blocks.title2?.inputs.value.value}`}</H5>
                                                    <P className="description">{slide.blocks.text.inputs.value.value}</P>
                                                    <P className="hashtag">#{slide.blocks.hashtag.inputs.value.value}</P>
                                                </Div>
                                            </Div>
                                        ))
                                    : 
                                        data.blocks.categories.config.map((categorie, index) => (
                                            categorie.blocks.subItem.config.map((slide, index1) => (
                                                <Div className="swiper-slide w100 slideCard" key={index1}>
                                                    <Div className="cardImg">
                                                        <Img 
                                                            className="w100" 
                                                            src={
                                                                typeof slide.blocks.image.inputs.value.value === 'string' ? 
                                                                    slide.blocks.image.inputs.value.value 
                                                                : slide.blocks.image.inputs.value.value.filePath ? 
                                                                    `${process.env.REACT_APP_MEDIAS}/${slide.blocks.image.inputs.value.value.filePath}` 
                                                                : null
                                                            }
                                                            alt={slide.blocks.image.inputs?.alt?.value ?? "Illustration"}
                                                        />
                                                        <Div className="cardImgHoverlay"></Div>
                                                    </Div>
                                                    <Div className="cardText">
                                                        <H5>{`${slide.blocks.title.inputs.value.value} ${slide.blocks.title2?.inputs.value.value}`}</H5>
                                                        <P className="description">{slide.blocks.text.inputs.value.value}</P>
                                                        <P className="hashtag">#{slide.blocks.hashtag.inputs.value.value}</P>
                                                    </Div>
                                                </Div>
                                            )) 
                                        ))
                                }
                            </div>
                            <div className="swiper-button-prev swiper-button-prev-listCategories"></div>
                            <div className="swiper-button-next swiper-button-next-listCategories"></div>
                        </Div>
                        <Div className="listCategories">
                            <ul>
                                {
                                    data.blocks.categories.config.map((slide, index) => (
                                        <li 
                                            key={index}
                                            className={isSportCategoryActive === index ? 'sportCategoryActive' : 'category'}
                                            onClick={() => {
                                                handleSportCategoryActive(index);
                                                setTimeout(() => {
                                                    handleClose();
                                                }, 200)
                                            }}
                                        >
                                            {slide.blocks.title.inputs.value.value}
                                        </li>
                                    ))
                                }
                            </ul>
                        </Div>
                    </Div>
                </ContainerListCategories>
                <ContainerListCategories className="hide-tablet hide-desktop">
                    <div className="containerTitle">
                        <H2 className="title">{data.inputs.value.value}</H2>
                        <p className='sportMenu' onClick={handleClickOpen}>Sports</p>
                    </div>
                    <div className={'containerAthletes'}>
                        {
                            isSportCategoryActive !== -1 ? 
                                data.blocks.categories.config[isSportCategoryActive].blocks.subItem.config.map((slide, index1) => (
                                    <Div className="card" key={index1}>
                                        <Div className="cardImg">
                                            <Img 
                                                className="w100" 
                                                src={
                                                    typeof slide.blocks.image.inputs.value.value === 'string' ? 
                                                        slide.blocks.image.inputs.value.value 
                                                    : slide.blocks.image.inputs.value.value.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.image.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                                alt={slide.blocks.image.inputs?.alt?.value ?? "Illustration"}
                                            />
                                            <Div className="cardImgHoverlay"></Div>
                                        </Div>
                                        <Div className="containerAthlete">
                                            <p className={readMore === index1 ? "athleteTagActive" : "athleteTag"}>#athlètesLCS {slide.blocks.hashtag.inputs.value.value}</p>
                                            <h2 className="athleteName">{`${slide.blocks.title.inputs.value.value} ${slide.blocks.title2?.inputs.value.value}`}</h2>
                                            <p className="athleteDescription">
                                                {readMore === index1 ? 
                                                    slide.blocks.text.inputs.value.value
                                                    : slide.blocks.text.inputs.value.value.slice(0, 60)
                                                }
                                                {
                                                    slide.blocks.text.inputs.value.value.length > 60 && readMore !== index1 ? '...' : null
                                                }
                                            </p>
                                            <p className="athleteLink" onClick={() => handleReadMore(index1)}>{readMore === index1 ? 'Lire moins' : 'Lire plus'}</p>
                                        </Div>
                                    </Div>
                                ))
                            : 
                                data.blocks.categories.config.map((categorie, index) => (
                                    categorie.blocks.subItem.config.map((slide, index1) => (
                                        <Div className="card" key={index1}>
                                            <Div className="cardImg">
                                                <Img 
                                                    className="w100" 
                                                    src={
                                                        typeof slide.blocks.image.inputs.value.value === 'string' ? 
                                                            slide.blocks.image.inputs.value.value 
                                                        : slide.blocks.image.inputs.value.value.filePath ? 
                                                            `${process.env.REACT_APP_MEDIAS}/${slide.blocks.image.inputs.value.value.filePath}` 
                                                        : null
                                                    }
                                                    alt={slide.blocks.image.inputs?.alt?.value ?? "Illustration"}
                                                />
                                                <Div className="cardImgHoverlay"></Div>
                                            </Div>
                                            <Div className="containerAthlete">
                                                <p className={readMore === index1 ? "athleteTagActive" : "athleteTag"}>#athlètesLCS {slide.blocks.hashtag.inputs.value.value}</p>
                                                <h2 className="athleteName">{`${slide.blocks.title.inputs.value.value} ${slide.blocks.title2?.inputs.value.value}`}</h2>
                                                <p className="athleteDescription">
                                                    {readMore === index1 ? 
                                                        slide.blocks.text.inputs.value.value
                                                        : slide.blocks.text.inputs.value.value.slice(0, 60)
                                                    }
                                                    {
                                                        slide.blocks.text.inputs.value.value.length > 60 && readMore !== index1 ? '...' : null
                                                    }
                                                </p>
                                                <p className="athleteLink" onClick={() => handleReadMore(index1)}>{readMore === index1 ? 'Lire moins' : 'Lire plus'}</p>
                                            </Div>
                                        </Div>
                                    )) 
                                ))
                        }
                    </div>
                    <DialogCustom aria-labelledby="customized-dialog-title" open={open}>
                        <div className='containerTop'>
                            <p className='sports'>Sports</p>
                            <p className='close' onClick={handleClose}>Fermer</p>
                        </div>
                        <div className='containerSportsList'>
                            <ul>
                                {
                                    data.blocks.categories.config.map((slide, index) => (
                                        <li 
                                            key={index}
                                            className={isSportCategoryActive === index ? 'sportCategoryActive' : 'sportCategory'}
                                            onClick={() => {
                                                handleSportCategoryActive(index);
                                                setTimeout(() => {
                                                    handleClose();
                                                }, 200)
                                            }}
                                        >
                                            {slide.blocks.title.inputs.value.value}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </DialogCustom>
                </ContainerListCategories>
        </div>
    )
};

export default ListCategories;