import styled from 'styled-components';

export default styled.a`
    font-size: 1.25rem;
    line-height: 1.2;
    color: ${props => props.color || 'rgba(0, 14, 40, 1)'}; 
    background-color: ${props => props.backgroundColor || '#fff'};
    padding: 16px;
    margin: 16px 0;
    display: block;
    text-decoration: none;
    opacity: 1;
    transition: all ease 0.1s;
    border-style: solid;
    border-width: ${props => `${props.borderWidth}px` || 0};
    border-color: ${props => props.borderColor || props.backgroundColor || '#FFF'};
    border-radius: ${props => `${props.borderRadius}px` || 0};
    &:hover{
        background-color: ${props => props.color || 'rgba(0, 14, 40, 1)'}; 
        color: ${props => props.backgroundColor || '#fff'};
    }
    &.little{
        font-size: 1rem;
        padding: 6px 12px;
    }
    &.link{
        padding: 0;
        margin: 0;
    }
    @media screen and (max-width: 767px){
        &.reverseOnResponsive{
            background-color: ${props => props.color || 'rgba(0, 14, 40, 1)'}!important; 
            color: ${props => props.backgroundColor || '#fff'}!important;
            &:hover{
                color: ${props => props.color || 'rgba(0, 14, 40, 1)'}!important; 
                background-color: ${props => props.backgroundColor || '#fff'}!important;
            }
        }
    }
    @media screen and (min-width: 1024px){
        font-size: 1.25rem;
        line-height: 26px;
        padding: 12px 64px;
        &.little{
            font-size: 1.25rem;
            line-height: 26px;
            padding: 12px 24px;
        }
    }
    
`; 