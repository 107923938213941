import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault } from '../../helpers';

export default {
    name:'Bannière image',
    inputs:{
        link: {
            label: 'Lien',
            type: FormInputTypes.TEXT,
            value: ''
        },
    },
    blocks: {
        imageDesktop: getImageDefault(),
        imageTablet: getImageDefault(),
        imageMobile: getImageDefault(),
    }
};
