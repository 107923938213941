import PropTypes from "prop-types";
import React, { useState } from "react";
import ProductGridSingle from "../_internals/product/ProductGridSingle";
import H1 from "../../../../shared/components/CustomH1";

const TabProduct = ({
  preview = false,  
  id = null, 
  data = null,
  inputCallback = null,
  children = [],
  spread = false,
  update = null
}) => {
    const [hover, setHover] = useState(false);

    const style = {
        boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px inset' : 'none',
        marginBottom: 20,
        position: 'relative',
        width: '100%'
    };

    return (
        <div 
            className="builder-template-scope"
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            style={style}
        >
            <div className={`product-area pt-80 pb-60`}>
                { !preview && hover && children }

                <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} className={'text-align-center'} />

                <div className="container">
                    <div className="row">
                        {
                            data.inputs.rowsNumber.value ? [...Array(parseInt(data.inputs.rowsNumber.value))].map((e, i) => {
                                let identifier  = data.mapping?.[i];
                                let product     = data.products.find(e => e.id === identifier);

                                return (
                                    <ProductGridSingle
                                        product={product}
                                        number={i}
                                        key={`product-${i}`}
                                        update={update}
                                        mapping={data.mapping}
                                        spread={spread}
                                    />
                                );
                            }) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

TabProduct.propTypes = {
  bgColorClass: PropTypes.string,
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string
};

export default TabProduct;
