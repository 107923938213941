import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getDefaultLink } from '../../helpers';

export default {
    name: "Section du flipbook",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
    },
    blocks: {
        header:{
            id: uuidv4(),
            name: "Header du flipbook",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#DEEDF5'
                },
                primaryColor: {
                    label: 'Couleur principale',
                    type: FormInputTypes.COLOR,
                    value: '#000000'
                },
                secondaryColor: {
                    label: 'Couleur secondaire',
                    type: FormInputTypes.COLOR,
                    value: '#DEEDF5'
                },
                switch: {
                    label: 'Activer la pagination blanche',
                    type: FormInputTypes.SWITCH,
                    value: false,
                }
            },
            blocks: {
                logo: getImageDefault(null, "Logo")
            }
        },
        title: getDefaultText("Titre", "Remplissez votre liste de cadeaux", 40, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        subtitle: getDefaultText("Sous-titre", "pour vous inscrire au tirage au sort et tentez de remporter des cadeaux",  20, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
        flipbook: {
            id: uuidv4(),
            name: "Bloc du flipbook",
            type: EditableTypes.MINI_BLOCKS,
            inputs: {
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#fff'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#53839D',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks:{
                title: getDefaultText("Titre du flipbook", "Explorez le catalogue", 35, "#53839D", 'rgba(255,255,255,0)'),
            }
        },
    }
};
