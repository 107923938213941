import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';


export default function mediasTagsConfig(action='add'){
    var obj = {
        langSelect: false,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        titleForm: action === 'add'?'Ajouter un tag' :'Editer un tag',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add' ? 'ajouter' :'editer'} un tag`,
        textButtonValidate: action === 'add'?'Ajouter':'Modifier',
        formConfig:{
            type:FORM,
            
            
            children:[ 
                    {
                        optionsInputs:[
                            {
                                type:'text',
                                label:'Libelle',
                                stateName:'tag',
                                helper: {
                                    text: 'Libelle du tag',
                                    link: false,
                                },
                                required:true,
                            }
                        ]
                            
                    }  
            ]
        }
    }
    return obj;
}
