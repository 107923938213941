import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
//import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm';
import H3 from '../../../../shared/components/CustomH3';
import H1 from '../../../../shared/components/CustomH1';
import Pg from '../../../../shared/components/CustomParagraph';
// import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
// import { Navigation, Pagination } from 'swiper/js/swiper.esm.js';

const Slider = ({
  preview = false,
  id = null,
  data = null,
  inputCallback = null,
  children = null
}) => {
  const [hover, setHover] = useState(false);

  let slides = data.blocks['slides'].config;

  const params = {
    // effect: "fade",
    loop: false,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    shouldSwiperUpdate: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };

  const styles = {
    backgroundColor: !preview ? '#eee' : '#fff',
    width: '100%',
    boxSizing: 'border-box'
  };

  return (
    <div className="builder-template-scope" style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      { !preview && children}

      <div className="slider-area">
        <div className="slider-active-2 nav-style-2">
          <Swiper {...params}>
            {slides && slides.map((single, key) => {
              let image = single.blocks['image'].inputs.value.value;
              let hasText = single.blocks['title'].inputs.value.value
                || single.blocks['subtitle'].inputs.value.value
                || single.blocks['text'].inputs.value.value;

              return (
                <div
                  key={`slide-${key}`}
                  className={`slider-height-4 d-flex align-items-center bg-img swiper-slide`}
                  style={{
                    backgroundImage: `url(${typeof image === 'string' ? image : `${process.env.REACT_APP_MEDIAS}/${image.filePath}`})`,
                    // boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px inset' : 'none',
                    position: 'relative'
                  }}
                >
                  <div className="container" style={{ zIndex: 15 }}>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div className="slider-content-5 slider-animated-1 text-center">
                          <H3 data={single} preview={true} component={id} block={'title'} inputCallback={inputCallback} className={'animated'} hover={hover} />
                          <H1 data={single} preview={true} component={id} block={'subtitle'} inputCallback={inputCallback} className={'animated'} hover={hover} />
                          <Pg data={single} preview={true} component={id} block={'text'} inputCallback={inputCallback} className={'animated'} hover={hover} />
                          {/* <div className="slider-btn-5 btn-hover">
                            <a href="" className="animated" target="_blank">SHOP NOW</a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    hasText ? (
                      <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)'
                      }}></div>
                    ) : null
                  }
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Slider;
