import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Link from 'next/link';
import { useRouter } from 'next/router';
import request from "../../../../../tools/Fetch";
import { v4 as uuidv4 } from "uuid";
import RulesBlock from '../RulesBlock/RulesBlock'
import IlluPNG from './image/illu.png';
import H3 from "../../../../shared/components/CustomH3";
import CustomDiv from "../../../../shared/components/CustomDiv";
import H1 from "../../../../shared/components/CustomH1";
import H2 from "../../../../shared/components/CustomH2";


const LinkCustom = (props) => {
    if (props.spread) {
        return <>
            { props.children }
        </>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{ props.children }</Link>
    }
}

const RecapBlockBook = (props) => {
    let {
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        fullWidth = false,
        noButton = false,
        spread = false,
        preview = false,
        emailTemplate = '',
    } = props; 
    const [selected, setSelected] = useState([]);
    const [email, setEmail] = useState('');
    const [subscribe, setSubscribe] = useState(0);
    const router = useRouter();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverBlockRecap, setHoverBlockRecap] = useState(false);
    // const [headerTop, setHeaderTop] = useState(0);
    // const handleScroll = () => {
    //     setScroll(window.scrollY);
    // };

    useEffect(() => {
        let list = localStorage.getItem('products');

        if (list)
            list = JSON.parse(list);

        setSelected(list ? list : []);
        document.getElementById('builder-template-scope-book').scrollIntoView({ behavior: 'instant', block: 'start' });
    }, []);

    const clickRules = () => {
        setIsOpen(true)
    }

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || !process.env.REACT_APP_SPREAD_CDN) {
            return process.env.REACT_APP_MEDIAS;
        } else {
            return `${process.env.REACT_APP_SPREAD_CDN}/medias` 
        }
    };
    let image = data?.blocks?.imageLeft?.inputs.value.value;
    return (
        <div className="recap-wrapper">
            { !preview && props.children}
            <H1 className="title_card" data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true}/>
            <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true}/>
            <div className="wrapper_card">
                <div className="card_container" onMouseEnter={() => setHoverBlock(true)} onMouseLeave={() => setHoverBlock(false)}>
                    { !preview && hoverBlock && props.toolbarBlock }
                    <img className="absolute_img" src={image?.filePath ? `${process.env.REACT_APP_MEDIAS}/${image.filePath}` : IlluPNG} alt="Illustration Père Noël LEGO" />
                    <div className="top_card">
                        <H3 data={data.blocks.explanation} preview={preview} component={id} block={'titleExplanation'} inputCallback={inputCallback} nopadding={true}/>
                        <CustomDiv className="subtitle_text" data={data.blocks.explanation} preview={preview} component={id} block={'subtitleExplanation'} inputCallback={inputCallback} nopadding={true} style={{textAlign:"center"}}/>
                        <div className="form_validation">
                            <form onSubmit={(e) => {
                                e.preventDefault();

                                setError(false);

                                if (spread || saving)
                                    return;

                                setSaving(true);

                                let data = new FormData();
                                let html = emailTemplate.replace(/%link%/g, process.env.REACT_APP_REDIRECT);
                                
                                html = html.replace('%p1_image%', `${process.env.REACT_APP_MEDIAS}/${selected[0]?.product?.image}`);
                                html = html.replace('%p2_image%', `${process.env.REACT_APP_MEDIAS}/${selected[1]?.product?.image}`);
                                html = html.replace('%p3_image%', `${process.env.REACT_APP_MEDIAS}/${selected[2]?.product?.image}`);
                                html = html.replace('%p4_image%', `${process.env.REACT_APP_MEDIAS}/${selected[3]?.product?.image}`);
            
                                html = html.replace(/%p1_ref%/g, `${selected[0]?.product?.ref}`);
                                html = html.replace(/%p2_ref%/g, `${selected[1]?.product?.ref}`);
                                html = html.replace(/%p3_ref%/g, `${selected[2]?.product?.ref}`);
                                html = html.replace(/%p4_ref%/g, `${selected[3]?.product?.ref}`);

                                if (process.env.REACT_APP_ORIGIN === "LEGO"){
                                    html = html.replace('%p1_link_open%', `<a href="${process.env.REACT_APP_REDIRECT}/product/${selected[0]?.product?.ref}">`);
                                    html = html.replace('%p1_link_close%', `</a>`);
                                    html = html.replace('%p2_link_open%', `<a href="${process.env.REACT_APP_REDIRECT}/product/${selected[1]?.product?.ref}">`);
                                    html = html.replace('%p2_link_close%', `</a>`);
                                    html = html.replace('%p3_link_open%', `<a href="${process.env.REACT_APP_REDIRECT}/product/${selected[2]?.product?.ref}">`);
                                    html = html.replace('%p3_link_close%', `</a>`);
                                    html = html.replace('%p4_link_open%', `<a href="${process.env.REACT_APP_REDIRECT}/product/${selected[3]?.product?.ref}">`);
                                    html = html.replace('%p4_link_close%', `</a>`);
                                } else{
                                    html = html.replace('%p1_link_open%', '');
                                    html = html.replace('%p1_link_close%', '');
                                    html = html.replace('%p2_link_open%', '');
                                    html = html.replace('%p2_link_close%', '');
                                    html = html.replace('%p3_link_open%', '');
                                    html = html.replace('%p3_link_close%', '');
                                    html = html.replace('%p4_link_open%', '');
                                    html = html.replace('%p4_link_close%','');
                                }
                                
                                html = html.replace('%p1_name%', `${selected[0]?.product?.name}`);
                                html = html.replace('%p2_name%', `${selected[1]?.product?.name}`);
                                html = html.replace('%p3_name%', `${selected[2]?.product?.name}`);
                                html = html.replace('%p4_name%', `${selected[3]?.product?.name}`);

                                html = html.replace('%mail_winner%', email);
                                html = html.replace('%link_home%', process.env.REACT_APP_HOST_LINK);

                                let newUuid = uuidv4();

                                // set new session uuid
                                localStorage.setItem('uuid', newUuid);

                                localStorage.setItem('subscribed', subscribe);

                                data.append('username', email);
                                data.append('uuid', spread ? '123-456' : newUuid);
                                data.append('token', process.env.REACT_APP_ASSET_TOKEN);
                                data.append('origin', process.env.REACT_APP_ORIGIN);
                                data.append('products[]', selected[0]?.product?.ref);
                                data.append('products[]', selected[1]?.product?.ref);
                                data.append('products[]', selected[2]?.product?.ref);
                                data.append('products[]', selected[3]?.product?.ref);

                                if (props.lang === "fr_FR") {
                                    data.append('emailSubject', 'Participation LEGO liste de cadeaux');
                                } else {
                                    data.append('emailSubject', 'Lista de regalos de participación de LEGO');
                                }
                                
                                data.append('emailHtml', html);
                                data.append('subscribe', subscribe);
                                data.append('share', 0);

                                try {
                                    request(`${process.env.REACT_APP_API}/public/assets/wishlist/${process.env.REACT_APP_ASSET_IDENTIFIER}`, 'post', data, 'multipart/form-data').then(response => {
                                        // reset the wishlist
                                        localStorage.removeItem('products');
    
                                        // save email
                                        localStorage.setItem('email', email);
                                        
                                        // go to the final page
                                        // router.push('/registered');
                                        window.location = '/registered';
                                    });
                                } catch(e) {
                                    console.log(e);
                                    setSaving(false);
                                    setError(true);
                                }
                            }}>
                                <input id="mail" name="mail" type="email" placeholder={props.lang === "fr_FR" ? "Entrez votre adresse e-mail" : "Ingrese su dirección de correo electrónico"} value={email} required onChange={e => {
                                    setEmail(e.target.value);
                                }} />
                                <div className="wrapper_checkbox">
                                    <input id="acceptRules" type="checkbox" required />
                                    <label htmlFor="acceptRules" className="labelAccept">{data.blocks.explanation.blocks.labelConsent.inputs.value.value}</label>
                                </div>
                                {
                                    process.env.REACT_APP_NEWSLETTER !== "true" ? null
                                    : (
                                        <div className="wrapper_checkbox">
                                            <input id="acceptNews" type="checkbox" onChange={e => {
                                                setSubscribe(e.target.checked ? 1 : 0);
                                            }} />
                                            <label htmlFor="acceptNews" className="labelAccept">{data.blocks.explanation.blocks.conditions.inputs.value.value}</label>
                                        </div>
                                    )
                                }
                                { error ? <p style="color: red; margin: 10px auto;">Une erreur est survenue, veuillez réessayer plus tard.</p> : null }
                                <input id="submit" type="submit" value={data.blocks.explanation.blocks.button.inputs.value.value} />
                            </form>
                        </div>
                    </div>
                    <div className="bottom_card">
                        <H3 data={data.blocks.explanation} preview={preview} component={id} block={'titleRecap'} inputCallback={inputCallback} nopadding={true}/>
                        <div className="wrapper_list">
                            {
                                selected.length > 0 ?
                                    selected.map((item, index) => {
                                        return (
                                            <div className="selected_product" key={`SelectedProduct${index}`}>
                                                <div className="image_product">
                                                    { item.product && item.product.image && <img src={`${getMediaOrigin()}/${item.product?.image}`} alt="Image du produit"/>}
                                                </div>
                                                <div className="infos_product">
                                                    {item.product ? item.product?.ref + ' - ' + item.product?.name : 'Aucun produit'}
                                                </div>
                                            </div>
                                        )
                                    }) 
                                : null
                            }
                        </div>
                        <LinkCustom spread={spread} href="/catalog">
                            <a className="return_button">{data.blocks.explanation.blocks.buttonBack?.inputs.value.value}</a>
                        </LinkCustom>
                    </div>
                </div>
            </div>
            {
                isOpen ? 
                    <RulesBlock isOpen={isOpen} onClose={() => setIsOpen(false)} lang={props.lang}/>
                : null
            }
        </div>
    );
};

RecapBlockBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RecapBlockBook;
