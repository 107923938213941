import React from 'react';
import { connect } from "react-redux";
import { Grid, MenuItem } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import DialogModal from '../../../ui/dialog/DialogModal'
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CardCustom from '../CardCustom';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';

import Avatar from '@material-ui/core/Avatar';
import CardFacebook from './feeds/CardFacebook'
import CardTwitter from './feeds/CardTwitter'
import CardWp from './feeds/CardWp'
import CardInstagram from './feeds/CardInstagram';
import CardLinkedIn from './feeds/CardLinkedIn';
import { Delete, Edit } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router'

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
`;

const CustomAvatar = styled(Avatar)`
    width: 70px;
    height: 70px;
    padding: 5px;
    img{
        object-fit: contain;
    }
`;

class CardFeeds extends React.Component{
    constructor(props){
        super(props)
        this.state={
            openSettings:false,
            settingsAnchorEl:null,
            openDeletePostDialog:false,
            hasMenu:false,
            demo : true,
            selectedPost : null,
            buttonText : null,
        }         
    }

    goTo = (route, id) => {
        console.log('this.props', this.props)
        this.props.history.push({
            pathname : route
        });
    };

    handleOpenSettings= (event,post=null) => {

        this.setState({
            settingsAnchorEl    :event.currentTarget,
            selectedPost            : post,
        },()=>{

            this.handleToggleSettings();
        });
        
    };

    handleToggleSettings = () => {
        this.setState({
            openSettings:!this.state.openSettings
        });
    };
    handleToggleDeletePostDialog=()=>{
        this.setState({
            openDeletePostDialog    : !this.state.openDeletePostDialog,
        })
    }

    renderSwitch(param){
        switch(param.assetType.identifier){
            case 'facebook':
                return <CardFacebook data={this.props.posts} loader={this.props.loader} handleLoad={this.props.handleLoad} pagination={this.props.pagination} handleSettings={this.handleOpenSettings} type={param.assetType}/>

            case 'linkedin':
                return <CardLinkedIn data={this.props.posts} loader={this.props.loader} handleLoad={this.props.handleLoad} pagination={this.props.pagination} handleSettings={this.handleOpenSettings} type={param.assetType}/>

            case 'instagram':
                return <CardInstagram data={this.props.posts} loader={this.props.loader} handleLoad={this.props.handleLoad} pagination={this.props.pagination} handleSettings={this.handleOpenSettings} type={param.assetType}/>
            
            case 'twitter':
                return <CardTwitter data={this.props.posts} handleSettings={this.handleOpenSettings} type={param.assetType}/>
    
            case 'wordpress':
                return <CardWp data={this.props.posts} handleSettings={this.handleOpenSettings} type={param.assetType}/>
            default:
                return null;         
        }
    }

    renderButton(param){
        switch(param.assetType.identifier){

            case 'facebook':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le compte</Button>
            
            case 'twitter':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le compte</Button>
    
            case 'wordpress':
                return <Button onClick={() => window.open(`${this.props.data.prodUrl ? this.props.data.prodUrl : this.props.data.stageUrl}`,'_blank')}>Voir le blog</Button>
            default:
                return null;    
        }
    }


    render() {
        let asset=this.props.data;
        let imageGuideline = this.props.guideline.guidelineData.edges.find(e => e.node.attribute.identifier === "guideline_logo")
        return (
            <GridCustom item xs={12}>
                <CardCustom paddingbottom={0} paddingtop={0} style={{width: "100%"}} contentpadding={'16px 32px'}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <CardHeader
                                avatar={
                                    <CustomAvatar aria-label="recipe" src={`${process.env.REACT_APP_API_ROOT}/medias/${imageGuideline?.node?.media?.filePath}`} />
                                }
                                title={this.props.guideline.libelle}
                                subheader={`Votre feed ${asset.assetType.libelle}`}
                            />
                        </Grid>
                        <Grid item>
                            {asset.prodUrl || asset.stageUrl ? 
                                this.renderButton(asset)
                                : null
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-start" spacing={2} style={{paddingTop:20,background:colors.grey.lighter.hue980}}>
                        {this.renderSwitch(asset)}
                    </Grid>
                
                </CardCustom>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.settingsAnchorEl}
                    keepMounted
                    open={this.state.openSettings}
                    onClose={this.handleToggleSettings}
                >
                    <MenuItem onClick={() => {this.props.handleEdit(this.state.selectedPost); this.handleToggleSettings()}}>
                        <Edit style={{fill: colors.grey.regular, marginRight: 4}}/>
                        Editer
                    </MenuItem>
                    {
                        asset.assetType.identifier !== 'instagram' ?
                            <MenuItem onClick={this.handleToggleDeletePostDialog} style={{color: 'red'}}>
                                <Delete style={{ fill: 'red', marginRight: 4}}/>
                                {this.props.t("spread.active_assets.delete")}
                            </MenuItem>
                        : null
                    }

                </Menu>                  
                <DialogModal
                    open            = {this.state.openDeletePostDialog}
                    secondaryAction = {()=>{
                        this.handleToggleDeletePostDialog();
                        this.handleToggleSettings()
                    }}
                    primaryAction   = {()=>{
                        this.props.handleDelete(asset.assetType.identifier === "twitter" ? this.state.selectedPost.id_str : this.state.selectedPost.id);
                        this.handleToggleSettings();
                        this.handleToggleDeletePostDialog()
                    }}
                    title           = 'Supprimer ce post' 
                    windowWidth={this.props.windowWidth}
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce post <strong>Cette action est irréversible</strong></Typography>
                </DialogModal>
            </GridCustom>
        );
    }
}

const mapStateToProps = state => {
    return {
        guideline: state.guideline,
    };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(CardFeeds)));