import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import FlipBook from '../FlipBook/FlipBook';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SapinPNG from './image/image-sapin.png';
import CadeauPNG from './image/image-cadeau.png';
import SetLegoPNG from './image/image-set-lego.png';
import Swiper from 'swiper';
import H2 from "../../../../shared/components/CustomH2";
import H1 from "../../../../shared/components/CustomH1";
import Pg from "../../../../shared/components/CustomParagraph";


const GiftsList = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [position, setPosition] = useState(null);
    const [cart, setCart] = useState([]);
    const [qtyPanier, setQtyPanier] = useState([]);
    const [modal, setModal] = useState(false);
    const newCategories = props.categories.slice(1);
    const [forcePage, setForcePage] = useState(true);
    const [ageFilter, setAgeFilter] = useState(null);

    let {
        preview = false,
        id = null, 
        data = null,
        pagesFlipbook = null,
        noSticky,
        categories= null,
        inputCallback = null,
        children = null,
        products,
        spread,
        params = null,
        readonly = false
    } = props;

    const resetCat = (pageNumber) => {
        setPosition(pageNumber);
    }

    function handleClick(filter) {
        setPosition(filter.attributes.category_flipbook_position?.fr_FR)
        // document.getElementById('flipBook').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // DEV
            return process.env.REACT_APP_MEDIAS;
        } else {
            // PROD
            return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS; 
        }
    };

    function openModal(status){
        setModal(status)
    }

    function getCart(array){
        setCart(array)
        renderQty(array)
    }

    function renderQty(array){
        let counterQty = 0
        if (array.length > 0){
            for (let element of array){
                if (element.product){
                    counterQty = counterQty + element.product.qty
                }
            }
        }
        setQtyPanier(counterQty)
    }

    let header;
    let flipbook;
    let subBlock;
    let giftBlock;
    let image;
    let switchValue = props.params.switch.value;

    if(params?.variante?.value === 'cart'){
        header = data.blocks.header;
        flipbook = data.blocks.flipbook;
        image = flipbook?.inputs?.backgroundImage?.value;
    }
    else{
        flipbook = data.blocks.flipbook;
        subBlock = data.blocks.subBlock;
        giftBlock = data.blocks.giftBlock;
    }

    let filter_age = null;
    if (props.lang === "fr_FR"){
        filter_age = [{attribute: "0-3_ans", libelle: "0 - 3 ans"},  {attribute: "3-5_ans", libelle: "3 - 5 ans"}, {attribute: "6-8_ans", libelle: "6 - 8 ans"}, {attribute: "9-12_ans", libelle: "9 - 12 ans"}, {attribute: "12-16_ans", libelle: "12 - 16 ans"}, {attribute: "adulte", libelle: "ADULTE"}]
    }else{
        filter_age = [{attribute: "0-3_ans", libelle: "0 - 3 años"},  {attribute: "3-5_ans", libelle: "3 - 5 años"}, {attribute: "6-8_ans", libelle: "6 - 8 años"}, {attribute: "9-12_ans", libelle: "9 - 12 años"}, {attribute: "12-16_ans", libelle: "12 - 16 años"}, {attribute: "adulte", libelle: "ADULTO"}]
    }

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-filters', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-filters',
                prevEl: '.swiper-button-prev-filters',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4.1,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                400: {
                    slidesPerView: 5.2,
                    slidesPerGroup: 5,
                },
                600: {
                    slidesPerView: 7.2,
                    slidesPerGroup: 6,
                },
                800:{
                    slidesPerView: 9.2,
                    slidesPerGroup: 8,
                    spaceBetween: 4,
                },
                1200:{
                    slidesPerView: 10.2,
                    slidesPerGroup: 9,
                },
            }
        });
        const swiper2 = new Swiper('.swiper-container-age', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-age',
                prevEl: '.swiper-button-prev-age',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 1,
                },
                400:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                500: {
                    slidesPerView: 4,
                    spaceBetween: 4,
                },
                730: {
                    slidesPerView: 5,
                },
                850:{
                    slidesPerView: 6,
                },
            }
        });
    }, [props]);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-filters', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-filters',
                prevEl: '.swiper-button-prev-filters',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4.1,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                400: {
                    slidesPerView: 5.2,
                    slidesPerGroup: 5,
                },
                600: {
                    slidesPerView: 7.2,
                    slidesPerGroup: 6,
                },
                800:{
                    slidesPerView: 9.2,
                    slidesPerGroup: 8,
                    spaceBetween: 4,
                },
                1200:{
                    slidesPerView: 10.2,
                    slidesPerGroup: 9,
                },
            }
        });
        const swiper2 = new Swiper('.swiper-container-age', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-age',
                prevEl: '.swiper-button-prev-age',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 1,
                },
                400:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                500: {
                    slidesPerView: 4,
                    spaceBetween: 4,
                },
                730: {
                    slidesPerView: 5,
                },
                850:{
                    slidesPerView: 6,
                },
            }
        });
        let firstCat = null
        if (ageFilter){
            firstCat = newCategories.filter(e => e.attributes[ageFilter]?.fr_FR === 1 || e.attributes[ageFilter]?.fr_FR === '1')[0]
        }else{
            firstCat = newCategories[0]
        }

        if (firstCat){
            handleClick(firstCat)
        }
    }, [ageFilter]);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-filters', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-filters',
                prevEl: '.swiper-button-prev-filters',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4.1,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                400: {
                    slidesPerView: 5.2,
                    slidesPerGroup: 5,
                },
                600: {
                    slidesPerView: 7.2,
                    slidesPerGroup: 6,
                },
                800:{
                    slidesPerView: 9.2,
                    slidesPerGroup: 8,
                    spaceBetween: 4,
                },
                1200:{
                    slidesPerView: 10.2,
                    slidesPerGroup: 9,
                },
            }
        });

        const swiper2 = new Swiper('.swiper-container-age', {
            direction: 'horizontal',
            spaceBetween: 4,
            navigation: {
                nextEl: '.swiper-button-next-age',
                prevEl: '.swiper-button-prev-age',
            },
            breakpoints: {
                300:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 1,
                },
                400:{
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 2,
                },
                500: {
                    slidesPerView: 4,
                    spaceBetween: 4,
                },
                730: {
                    slidesPerView: 5,
                },
                850:{
                    slidesPerView: 6,
                },
            }
        });
    }, []);

    return (
        <div className={readonly ? "gift_list_container readOnly" : params?.variante?.value === "cart" ? "gift_list_container cart" : "gift_list_container"} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {
                params?.variante?.value === "cart" && header ?
                    <div className={`header_cart ${spread ? '' : 'sticky'}`} style={{position: "relative", backgroundColor: header.inputs.backgroundColor.value}}>
                        { !preview && props.toolbarHeader}
                        <div className="container">
                            <div className="wrapper_header_left">
                                <a href={process.env.REACT_APP_LINKED_MAGENTO}>
                                    <div className="logo">
                                        <img 
                                            src={
                                                typeof header.blocks.logo.inputs.value.value === 'string' ?
                                                    header.blocks.logo.inputs.value.value : 
                                                    header.blocks.logo.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${header.blocks.logo.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/200x100/FFFFFF/53839D?text=Logo"
                                            } 
                                            alt="Logo" 
                                            style={{ maxHeight: 90, maxWidth: 170 }}
                                        />
                                    </div>
                                </a>
                                <span className="homeIcon" onClick={(e) => {
                                    e.preventDefault();
                                    setForcePage(new Date().getTime());
                                }}>
                                    <HomeIcon style={{fill: header.inputs.primaryColor.value}} />
                                </span>
                            </div>
                            {!switchValue ?
                                <div className="cart_wrapper" onClick={() => openModal(!modal)}>
                                    <div id="dummy_debris" className="debris" />
                                    <div className="cart-icon">
                                        <ShoppingCartIcon style={{fill: header.inputs.primaryColor.value}} />
                                        <div className="product_qty" style={{background: header.inputs.secondaryColor.value}}>{qtyPanier}</div>
                                    </div>
                                    <span style={{color: header.inputs.primaryColor.value}} >VOIR MA LISTE</span>
                                </div>
                            :
                                null
                            }
                        </div>
                    </div>
                : null
            }

            <div className="wrapper_container">
                { !preview && props.toolbarSection }
                <section className="title">
                    <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true}/>
                    <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true}/>
                </section>
                <div className="wrapper_card">
                    <div className="card_container" style={{ position: 'relative', backgroundColor: "#F8F9F9"}} onMouseEnter={() => setHoverBlock(true)} onMouseLeave={() => setHoverBlock(false)}>
                        { !preview && hoverBlock && props.toolbarSubSection}
                        <div className=" filters_wrapper">
                            <div className="container-filters-age" style={{position:"relative", display: 'block', margin: '0 auto 10px', maxWidth: 750,width: '90%'}}>
                                <div className="list_filters_age swiper-container swiper-container-age">
                                    <div className="swiper-wrapper">
                                        {
                                            filter_age.map((filter, index) => {
                                                return(
                                                    <div className="swiper-slide" style={{width: "auto", height: "auto"}} key={`slide-age-${index}`}>
                                                        <button 
                                                            className={ageFilter === filter.attribute ? 'selected' : null} 
                                                            onClick={() => setAgeFilter(ageFilter === filter.attribute ? null : filter.attribute)}
                                                        >
                                                            {filter.libelle}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="swiper-button-prev swiper-button-prev-age"></div>
                                <div className="swiper-button-next swiper-button-next-age"></div>
                            </div>
                            <div style={{position:"relative"}}>
                                <div className={params?.variante?.value === "wishlist" ? "swiper-container swiper-container-filters list_filters" : "not-displayed"}>
                                    <div className="swiper-wrapper">
                                        {
                                            newCategories.length > 0 ?
                                                newCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)).map((filter,index) => {
                                                    if((ageFilter && (filter.attributes[ageFilter]?.fr_FR === 1 || filter.attributes[ageFilter]?.fr_FR === '1')) || !ageFilter){
                                                        return(
                                                            <div className="swiper-slide" style={{width: "auto", height: "auto"}} key={`slide-${index}`}>
                                                                <div className="filter_block" key={`filterBlock${index}`} onClick={() => handleClick(filter)}>
                                                                    <img src={filter.attributes.category_image?.fr_FR ? `${getMediaOrigin()}/${filter.attributes.category_image.fr_FR}` : `http://via.placeholder.com/94x54/FFFFFF/53839D?text=${filter.libelle}`} alt={`Filtre numéro ${index}`}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="swiper-button-prev swiper-button-prev-filters"></div>
                                <div className="swiper-button-next swiper-button-next-filters"></div>
                            </div>
                            
                            <FlipBook 
                                data={props.pagesFlipbook} 
                                pagePosition={position} 
                                resetCat={resetCat} 
                                products={products} 
                                spread={spread} 
                                modal={modal} 
                                openModal={openModal} 
                                readOnly={readonly} 
                                getCart={getCart} 
                                isCartMode={params?.variante?.value === "cart" ? true : false}
                                params={params}
                                forcePage={forcePage}
                                primaryColor={params?.variante?.value === "cart" && header ? header.inputs.primaryColor.value : null}
                                secondaryColor={params?.variante?.value === "cart" && header ? header.inputs.secondaryColor.value : subBlock?.inputs?.['backgroundColor2']?.value}
                                switchValue={params?.variante?.value === "cart" && header ? header.inputs.switch.value : null}
                                paramFlipbook={subBlock}
                                paramGifts={giftBlock}
                                inputCallback={spread ? inputCallback : null}
                                id={id}
                                toolbarGiftBlock={props.toolbarGiftBlock}
                                categories={categories}
                                lang={props.lang}
                            />
                        </div>
                    </div>
                    
                    { params?.variante?.value === "cart" && <div className="space-div" style={{ width: '100%', height: 50 }} /> }
                </div>
                <div className="bottom_section">
                    <div className="container_bottom">
                        <div className="firstPart">
                            <img src={SapinPNG} alt="Sapin lego" />
                            <img src={CadeauPNG} alt="Cadeaux lego" />
                        </div>
                        <div className="text">
                            <Pg data={data} preview={preview} component={id} block={'bottomText'} inputCallback={inputCallback} nopadding={true} />
                        </div>
                        <div class="lastPart">
                            <img src={SetLegoPNG} alt="Set Lego" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

GiftsList.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GiftsList;
