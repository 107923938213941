import React from 'react';
import { alpha } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import 'moment/locale/fr';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Button from '../../components/ui/button/Button';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { connect } from "react-redux";

import { Grid, Box, Divider, TextField, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import iconSet from "../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import HistoryIcon from '@material-ui/icons/History';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/Help';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';

import JohnDoe from '../../assets/images/john-doe.png';

import CloseRounded from '@material-ui/icons/CloseRounded';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import colors from '../../config/theme/colors';
import logoSinfin from '../../assets/images/sinfin-logo.svg';
import styled from 'styled-components';
import Typography from '../ui/typography/Typography';
import { START_LOADING } from '../../js/constants/action-types';
import { ROUTE_HOME, ROUTE_SETTINGS_DETAIL_USER, ROUTE_CRM_SETTINGS_DETAIL_USER, ROUTE_PREFERENCES, ROUTE_HELPS, ROUTE_SETTINGS_GUIDELINE, ROUTE_HISTORY_IMPORT } from '../../js/constants/route-names';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next'
import { deleteLocalStorage } from '../../js/utils/deleteLocalStorage';
import OurTypography from '../ui/typography/Typography';
import LineCharts from '../layouts/Stats/LineCharts';
import { isImgUrl } from '../../js/utils/isValidImgUrl';


const isCRM = process.env.REACT_APP_MODE_CRM === 'true';


const TypographyCustom = styled(Typography)`
    margin: 4px 0;
    font-size: 12px;
    line-height: 16px;
    white-space: initial;
`;

const ListItemTextCustom = styled(ListItemText)`
    margin: 0;
    p{
        color: ${colors.black.regular};
    }
`;

const SelectLang = styled(TextField)`
    fieldset{
        border: none;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 0px 16px !important;
        margin-right: 16px;
        color: ${colors.black.regular};
        font-weight: bold;
        border-radius: 0;
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.black.regular};
        font-weight: bold;
    }
    & > p{
        margin: 0;
    }
`;

const IconButtonCustom = styled(IconButton)`
    display: none;
    position: absolute;
    padding: 0.5rem;
    left: -7px;
    z-index: 999;
    background: ${colors.black.regular};
    top: 200px;
    box-shadow: 0px 0px 5px #1622421A;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    svg{
        font-size: 1.3rem;
    }
    &.close{
        left: 105px;
        transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
    &:hover{
        background: ${colors.black.regular};
    }
    @media screen and (max-width: 1000px){
        display: block;
        &.close{
            top: 16px;
            left: 20px;
            background: white;
            box-shadow: inherit;
            svg{
                fill: ${colors.black.regular};
            }
            &:hover{
                background: ${colors.black.regular};
                svg{
                    fill: white;
                }
            }
        }
    }
`;

const EncartTopLeft = styled(Box)`
    // position: fixed;
    left: 0;
    width: ${props => props.drawerwidth ? `${props.drawerwidth}px` : '300px'};
    top: ${props => props.imagefound === "true" ? '5px' : '10px'};
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    overflow:hidden;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    &:after{
        content: '';
        height: 40px;
        width: 1px;
        background: ${colors.grey.lighter.hue800};
        position: absolute;
        right: 0;
    }
    &.close{
        &:before{
            width: 100px;
            left: -5px;
            top: 95px;
            transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
    }
    img{
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
        max-height: 100%;
        max-width: 100%;
    }
    .MuiTypography-h2{
        color: ${colors.black.regular};
        display: inline-block;
        padding-right: 20px;
    }
    @media screen and (max-width: 1000px){
        display: none;
    }
`;

const Bar = styled(Box)`
    background-color:${colors.grey.lighter.hue900};
    overflow:hidden;
    border-radius:20px;
    height: 7px;
    position: relative;
    margin-top: 3px;

    span{
        height:100%;
        display:inline-block;
        position:absolute;
        top: 0;
        transition:all .5s;
        
      
    }
`

const SpanColor = styled.span`
    // color: ${colors.blue.darker.hue300};
    color: ${colors.black.regular};
    font-weight: bold;
    font-size: 12px;
`

const SpreadLogo = styled.img`
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 110px;
    max-height: 70px;
    // @media screen and (max-width: 1000px){
    //     max-width: 190px;
    // }
    // @media screen and (min-width: 1001px){
    //     left: 50%;
    //     transform: translate(-50%);
    // }
`
const AppBarCustom = styled(AppBar)`
    &.MuiAppBar-root{
        width: ${props => props.drawerwidth ? `calc(100% - ${props.drawerwidth}px) !important` : '100% !important'};
    }
    @media screen and (max-width: 1000px){
        .MuiToolbar-root{
            padding-left: ${props => props.opened ? '8px' : '52px'}
        }
    }
`

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 380,
        paddingTop: '12px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&> .MuiListItemIcon-root': {
            minWidth: 30,
        },
        '&> .logout > p': {
            color: colors.red.regular,
        },
        '&:focus': {
            backgroundColor: colors.grey.lighter.hue900,
            '& .MuiListItemIcon-root, & .MuiListItemTextCustom-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

let demo = true;
var drawerWidth = 300;

if (window.innerWidth <= 1600 && window.innerWidth > 1400) {
    drawerWidth = 240;
}
else if (window.innerWidth <= 1400) {
    drawerWidth = 210;
}

const styles = theme => ({
    root: {
        display: 'flex',
    },
    select: {
        marginTop: 40,
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.06), 0px 8px 10px 1px rgba(0,0,0,0.06), 0px 3px 14px 2px rgba(0,0,0,0.06)",
        "& ul": {
            // backgroundColor: 'r',
            padding: 0,

        },
        "& li": {
            fontSize: 14,
            color: colors.blue.darker.hue300
        },
        "& li.MuiListItem-root.Mui-selected, & li.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: colors.blue.darker.hue300,
            color: colors.white
        },
        "& li.MuiListItem-root:hover": {
            backgroundColor: colors.blue.darker.hue900,
            // color: colors.white
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        right: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none',
        border: `0.5px solid ${colors.grey.lighter.hue700}`,
        borderLeft: 'none',
        background: colors.white,
        '@media (min-width:1001px)': {
            padding: '10px 0',
        }
    },
    appBarShift: {
        marginLeft: 0,
        right: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin', 'padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingRight: '0 !important',
        paddingLeft: '0px',
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: 0,
        marginLeft: 0,
        width: '33%',
    },
    searchIcon: {
        width: 'auto',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.grey.lighter.hue600,
    },
    inputRoot: {
        color: colors.grey.lighter.hue600,
        width: '100%',
        fontSize: 16,
    },
    inputInput: {
        padding: theme.spacing(1, 4, 1, 4),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    toolBar: {
        backgroundColor: colors.white,
        position: 'relative',
        paddingRight: 8,
        justifyContent: 'space-between'
    },
    logout: {
        color: '#000',
        position: 'absolute',
        right: 20,
        cursor: 'pointer'
    }
});

class Appbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            anchorEl: null,
            currentLang: this.props.locales?.[0]?.node?.code
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    goTo = route => {
        this.props.history.push(route);
        this.handleClose();
    };

    goToWithId = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { userId: id }
        });
    };

    getGuidelineName = () => {
        if (this.props.guideline) {
            if (this.props.guideline.libelle) {
                return this.props.guideline.libelle;
            }
            return null;
        }
        else {
            return null;
        }
    };

    getLinkBrand = () => {
        if (this.props.guideline) {
            for (let guidelineData of this.props.guideline.guidelineData.edges) {
                if (guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_url") {
                    return (guidelineData.node.value)
                }
            }
            return null;
        }
        else {
            return null;
        }
    };

    getGuidelineImg = () => {
        if (this.props.guideline) {
            for (let guidelineData of this.props.guideline.guidelineData.edges) {
                if (guidelineData.node.locale.code === this.state.currentLang && guidelineData.node.attribute.identifier === "guideline_logo") {
                    return process.env.REACT_APP_API_ROOT + '/medias/' + guidelineData.node.media?.filePath;
                }
            }
            return null;
        }
        else {
            return null;
        }
    };

    handleChangeLang = (lang) => {
        if (lang === "fr_FR") {
            localStorage.setItem('DXP_LANG', 'en_US');
            i18n.changeLanguage('en_US');
        } else {
            localStorage.setItem('DXP_LANG', 'fr_FR');
            i18n.changeLanguage('fr_FR');
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        isImgUrl(`${process.env.REACT_APP_API_ROOT}/${this.props.user.image}`)
        .then((result)=>{
            this.setState({userImage:result})

        })
        .catch(e=>console.log(e))
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        const { props } = this;
        const { anchorEl } = this.state;
        const { classes } = this.props;
        return (
            <AppBarCustom
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.opened,
                })}
                color={"primary"}
                drawerwidth={this.props.drawerWidth}
                opened={props.opened}
            >
                <Toolbar className={clsx(classes.toolBar, {
                })}>

                    <IconButtonCustom
                        color="inherit"
                        className={props.opened ? 'open' : 'close'}
                        aria-label="open drawer"
                        onClick={props.openCallback}
                        edge="start"
                        drawerwidth={props.drawerWidth}
                    >
                        {props.opened ? <CloseRounded /> : <MenuIcon />}
                    </IconButtonCustom>

                    {/* {
                    isCRM ?
                        null
                    :
                        <EncartTopLeft className={props.opened ? 'open' : 'close'} imagefound={(this.getGuidelineImg() !== null && isCRM === false) ? "true" : "false"} drawerwidth={props.opened ? props.drawerWidth : drawerWidth} onClick={() => this.goTo(ROUTE_HOME)} style={{cursor: 'pointer'}}>
                            {
                                this.getGuidelineImg() !== null && isCRM === false ? (
                                    <img src={this.getGuidelineImg()} alt={this.getGuidelineName()} />
                                ) : this.getGuidelineName() !== null && isCRM === false ? (
                                        <Typography variant="h2">{this.getGuidelineName()}</Typography>
                                    ) : null
                            }
                        </EncartTopLeft>
                } */}
                    <SpreadLogo className={clsx(classes.logoDXP)} src={logoSinfin} onClick={() => this.goTo(ROUTE_HOME)} style={{ cursor: 'pointer' }} height={isCRM ? "32px" : "auto"} width={isCRM ? '' : '285px'} alt="logo-dxp" />
                    {demo !== true && isCRM !== true ?
                        <div className={classes.search}>
                            <div>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={this.props.t("menu.lookInto")}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </div>
                        </div>
                        : null}
                    <div className={classes.grow} />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }} >
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={this.handleClick}
                            disableElevation
                            style={{ background: 'transparent', color: colors.black.regular, textTransform: 'inherit', paddingLeft: 0, paddingRight: 0 }}
                        >
                            {
                                props.windowWidth > 1200 ?
                                    (
                                        <Grid item style={{
                                            width: 38,
                                            height: 38,
                                            position: 'relative',
                                            overflow: 'hidden',
                                            borderRadius: '50%'
                                        }}>
                                            {
                                                this.state.userImage?
                                                <img src={props.user.image ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : JohnDoe} style={{
                                                    height: '100%',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)'
                                                }} />
                                                :
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    height: '72px',
                                                    width: '72px',
                                                    borderRadius: '50%',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor: colors.grey.lighter.hue900,
                                                }}><span style={{
                                                    color: colors.grey.lighter.hue600,
                                                }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</span></div>
                                            }                                            
                                        </Grid>
                                    ) : null
                            }
                            <Typography
                                variant="body1"
                                style={{
                                    background: 'transparent',
                                    color: colors.black.regular,
                                    textTransform: 'inherit',
                                    // minWidth: props.windowWidth > 1200 ? 160 : null, 
                                    maxWidth: 200,
                                    marginLeft: 8,
                                    paddingLeft: props.windowWidth > 1200 ? 0 : 8,
                                    textAlign: 'end',
                                    fontSize: 14,
                                    paddingRight: 3,
                                    fontWeight: '500',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {props.user.firstname} {props.user.lastname}
                            </Typography>
                            <ExpandMoreIcon />
                        </Button>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            autoFocus={false}
                        >
                            <Grid container>
                                {/* Profile */}
                                {
                                    props.windowWidth > 1200 ?
                                        (
                                            <Grid item xs={4} style={{
                                                width: 72,
                                                height: 72,
                                                position: 'relative',
                                                overflow: 'hidden',
                                                borderRadius: '50%'
                                            }}>                                                
                                                {this.state.userImage ? (
                                                    <img src={props.user.image ? `${process.env.REACT_APP_API_ROOT}/${props.user.image}` : JohnDoe} style={{
                                                        height: '100%',
                                                        borderRadius: '50%',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }} />
                                                ) : (
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        height: '72px',
                                                        width: '72px',
                                                        borderRadius: '50%',
                                                        fontWeight: 'bold',
                                                        fontSize: '34px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        backgroundColor: colors.grey.lighter.hue900,
                                                    }}><span style={{
                                                        color: colors.grey.lighter.hue600,
                                                    }}>{props.user.firstname.charAt(0).toUpperCase()}{props.user.lastname.charAt(0).toUpperCase()}</span></div>
                                                )}
                                            </Grid>
                                        ) : null
                                }
                                <Grid item xs={8} style={{ marginBottom: '16.5px' }}>
                                    <OurTypography fontweighttext={700} colortext={colors.black.regular} style={{
                                        fontSize: '20px',
                                        marginTop: '5px',
                                    }} >{props.user.firstname} {props.user.lastname}</OurTypography>
                                    {/* BRAND */}
                                    {/* {
                                        this.getGuidelineName() !== null ? (
                                            <OurTypography colortext={colors.black.regular} style={{
                                                fontSize: '12px',
                                                fontWeight: '500',
                                            }} text={this.getGuidelineName()} />
                                        ) : null
                                    }
                                    {
                                        this.getLinkBrand() !== null ? (
                                            <OurTypography colortext={colors.black.regular} style={{
                                                fontSize: '12px',
                                                fontWeight: '500'
                                            }} text={this.getLinkBrand()} />
                                        ) : null
                                    } */}
                                    <OurTypography colortext={colors.grey.lighter.hue600} style={{
                                        fontSize: '12px',
                                    }}>{props.user.email}</OurTypography>
                                    <OurTypography onClick={() => {
                                        this.goToWithId(isCRM ? ROUTE_CRM_SETTINGS_DETAIL_USER : ROUTE_SETTINGS_DETAIL_USER.replace(':id', props.user.id.replace('/api/users/', '')))
                                        this.handleClose()
                                    }} colortext={colors.grey.darker} style={{
                                        fontSize: '14px',
                                        marginTop: '10px',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}>{props.t("homepage.appbar.profileManagement")}</OurTypography>
                                </Grid>
                            </Grid>
                            <Divider style={{ backgroundColor: colors.grey.border }} />
                            <Box py={'20px'} mx={'17px'} style={{ /* borderBottom: `1px solid ${colors.grey.border}` */ }}>
                                <OurTypography text={props.t("homepage.appbar.yourPlan")} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px' }} />
                                <OurTypography text={process.env.REACT_APP_FORMULE ?? '-'} colortext={colors.black.regular} style={{ marginTop: '3.5px', fontSize: '14px' }} />
                            </Box>
                            {/* STOCKAGE */}
                            {/* <Box py={'20px'} mx={'17px'}>
                                <OurTypography text={'Stockage'} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px', marginBottom: '4px' }} />
                                <Grid container justifyContent='space-between'>
                                    <OurTypography text={'546,46 Mo/1 To'} colortext={colors.grey.regular} style={{ fontSize: '10px' }} />
                                    <OurTypography text={'24 %'} colortext={colors.grey.regular} style={{ fontSize: '10px' }} />
                                </Grid>
                                <Bar>
                                    <Tooltip
                                        title={'Stockage'}
                                        aria-label="add"
                                        placement="top"
                                        arrow
                                    >
                                        <span style={{ backgroundColor: colors.blue.darker.hue300, width: `${24}%` }} />
                                    </Tooltip>
                                </Bar>
                            </Box> */}
                            <Divider style={{ backgroundColor: colors.grey.border }} />
                            <Grid container justifyContent='space-between' alignItems='baseline' style={{
                                padding: '25px 3px 5.5px 17px',
                            }}>
                                <Grid item>
                                    <OurTypography text={this.props.t("menu.disconnect")} fontweighttext={600} colortext={colors.black.regular} style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => {
                                        props.startLoading();

                                        setTimeout(() => {
                                            deleteLocalStorage();
                                        }, 1000);
                                    }} />
                                </Grid>
                                <Grid container alignItems='baseline' style={{
                                    width: 'auto'
                                }}>
                                    {/* <span style={{ fontWeight: i18n.language === "fr_FR" ? "bold" : null, textDecoration: i18n.language === "fr_FR" ? "underline" : null }}>FR</span> | <span style={{ fontWeight: i18n.language === "fr_FR" ? null : "bold", textDecoration: i18n.language === "fr_FR" ? null : "underline" }}>EN</span> */}
                                    <div style={{ color: colors.black.regular }}>{props.t("content.content.choiceLanguage")}</div>
                                    <SelectLang
                                        id={1}
                                        variant="outlined"
                                        color="secondary"
                                        select
                                        // fullWidth
                                        value={localStorage.getItem('DXP_LANG')}
                                        onChange={evt => {
                                            this.handleChangeLang(localStorage.getItem('DXP_LANG'))
                                        }}
                                        SelectProps={{ MenuProps: { classes: { paper: classes.select } } }}
                                    >
                                        <MenuItem key={'FR'} value={'fr_FR'}>
                                            FR
                                        </MenuItem>
                                        <MenuItem key={'EN'} value={'en_US'}>
                                            EN
                                        </MenuItem>
                                    </SelectLang>
                                </Grid>
                            </Grid>
                        </StyledMenu>
                    </div>
                </Toolbar>
            </AppBarCustom>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        guideline: state.guideline,
        locales: state.locales,
    };
};

export default withTranslation()(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Appbar))));