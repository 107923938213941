import React from "react";
import colors from '../../../colors';

const H1 = ({ data, preview, component, block, inputCallback, className, hover, style = null }) => {

    let image = data.blocks[block]?.inputs['backgroundImage']?.value;
   
    return (
        <h1 
            className={className} 
            onClick={() => inputCallback ? inputCallback(component, block) : null} 
            style={{
                fontSize: data.blocks[block]?.inputs.size?.value,
                lineHeight: 1.2,
                color: data.blocks[block]?.inputs.color?.value,
                backgroundColor: data.blocks[block]?.inputs.backgroundColor?.value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                textTransform: data.blocks[block]?.inputs.textTransform?.value,
                textAlign: data.blocks[block]?.inputs.textAlign?.value,
                margin:  data.blocks[block]?.inputs.value.value === "<p><br></p>" ? 0 : null,
                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                // fontFamily: 'Open Sans',
                ...style,
            }}
        >
            <span dangerouslySetInnerHTML={{ __html:  data.blocks?.[block]?.inputs.value.value }}></span>
        </h1> 
    );
};

export default H1;