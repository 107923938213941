import PropTypes from "prop-types";
import React, { useState } from "react";
import CustomImg from "../../../../shared/components/CustomImg";

const Image = ({
  preview = false, 
  id = null, 
  data = null,
  inputCallback = null,
  update = null,
  picker = null,
  children = null,
  spread = false
}) => {
  const [hover, setHover] = useState(false);

  const style = {
    boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px' : 'none',
    marginBottom: 20
  };

  return (
    <div
      className="builder-scope"
      style={style} 
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
    >
      { !preview && spread && hover && children }
      <CustomImg data={data} preview={preview} component={id} block={'image'} inputCallback={inputCallback} update={update} hover={hover} picker={picker} />
    </div>
  );
};

Image.propTypes = {
  
};

export default Image;
