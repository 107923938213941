import React, { useState } from "react";
import Div from '../shared/div';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const DownloadFiles = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null,
        data = null,
        inputCallback = null,
        spread = false
    } = props;


    const { identifiant, className, paddingTop, paddingBottom, addBorderTop, addBorderBottom, containerSize, columnGap, color } = data.inputs

    const getTypeFile = (type) => {

        const pattern_pdf = /application\/pdf/
        const pattern_img = /image\/(png|jpeg|jpg|gif)/
        const pattern_video = /video\/(mp4|webm|mpeg)/
        const pattern_audio = /audio\/(mpeg)/
        const pattern_zip = /zip/

        if (pattern_pdf.test(type)) {
            return 'pdf'
        }
        else if (pattern_img.test(type)) {
            return 'image'
        }
        else if (pattern_video.test(type)) {
            return 'video'
        }
        else if (pattern_audio.test(type)) {
            return 'audio'
        }
        else if (pattern_zip.test(type)) {
            return 'zip'
        }
    }

    const getFormatSize = (size, b = 2) => {
        if (!+size) return "0 Octet"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(size) / Math.log(1024)); return `${parseFloat((size / Math.pow(1024, d)).toFixed(c))} ${["Octets", "Ko", "Mo", "Go", "To"][d]}`
    }

    return (
        <Div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{position:'relative'}}>

            {hover && !preview && props.children}

            <div
                style={{
                    "--color": color?.value ?color?.value : '#000',
                    display: "flex",
                    gap: columnGap?.value ? `${columnGap?.value}px` : '0px',
                    flexWrap: "wrap",
                    width:  containerSize?.value ? `${containerSize?.value}%` : '100%',
                    paddingTop: paddingTop?.value ? `${paddingTop?.value}px` : '0px',
                    paddingBottom:  paddingBottom?.value ? `${paddingBottom?.value}px` : '0px',
                    borderTop: addBorderTop?.value ? '1px solid var(--color)' : 'none',
                    borderBottom:  addBorderBottom?.value ? '1px solid var(--color)' : 'none'
                }}
                className={className?.value}
                id={identifiant?.value}
            >

                {
                    data.blocks?.files?.config?.map((config, i) => {

                        const { media, color } = config.blocks
                        let typeFile = getTypeFile(media?.inputs?.value?.value?.type)
                        let formatSize = getFormatSize(media?.inputs?.value?.value?.size)

                        return (
                            <div
                                key={config.id}
                                style={{marginTop:"0px !important"}}
                            >
                                <a
                                    key={id}
                                    href={`${process.env.REACT_APP_MEDIAS}/` + media?.inputs?.value?.filePath}
                                    download="download"
                                    color={color?.inputs?.color?.value}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        gap:"10px",
                                        color: "var(--color)",
                                        textDecoration: "none",
                                    }}
                                >
                                    <SaveAltIcon style={{fontSize: 24,height:"24px"}} />

                                    <div className="bloc-droite" style={{marginTop:"0px !important"}}>
                                        {
                                            media?.inputs?.intitule?.value && <p
                                                style={{
                                                    textWrap: "nowrap !important",
                                                    fontSize: "20px !important",
                                                    fontWeight: "bold !important",
                                                    margin:"0px !important",
                                                }}
                                            >{media?.inputs?.intitule?.value}</p>
                                        }
                                        <div className="infos-file" style={{fontSize: '16px',marginTop:0}} >
                                            (
                                            <span className="type">{typeFile}</span>, &nbsp;
                                            <span className="size">{formatSize}</span>
                                            )
                                        </div>

                                    </div>
                                </a>
                            </div>
                        )
                    })
                }
            </div>

        </Div>
    )
}

export default DownloadFiles;