import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideMoments, getDefaultTextCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider moments',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#FFF'
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Histoire", null),
        slides:{
            title: "Slide",
            name: "Slide",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_SLIDER_MOMENTS,
            buttonAdd: 'une slide',
            limit: 100,
            config: [ 
                getDefaultSlideMoments(),
                getDefaultSlideMoments(),
                getDefaultSlideMoments(),
            ]
        }
    }
};