import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultSlideImage, getDefaultBackgroundCms, getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Grille d'image",
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        paddingTop: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Top (px)",
            params: {max: 200, min: 0, step: 1},
        },
        paddingBottom: {
            type: FormInputTypes.SLIDER,
            value: 25,
            label: "Padding Bottom (px)",
            params: {max: 200, min: 0, step: 1},
        },
        gridNumber: {
            type: FormInputTypes.SLIDER,
            value: 3,
            label: "Nombre d'image par ligne",
            params: {max: 5, min: 1, step: 1},
        },
        gridGap: {
            type: FormInputTypes.SLIDER,
            value: 20,
            label: "Espace entre les images (px)",
            params: {max: 200, min: 0, step: 1},
        },
        noPaddingHorizontal: {
            label: "Désactiver l'espace sur les côtés",
            type: FormInputTypes.SWITCH,
            value: false,
        },
    },
    blocks:{
        background: getDefaultBackgroundCms('rgba(255,255,255,0)'),
        title: getDefaultTextCms("Titre", "Découvrir", null),
        link: getDefaultLinkCms("Lien", "Tout afficher", "https://www.lecoqsportif.com/", null, null),
        slides:{
            title: "Image",
            name: "Image",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_GRID_IMAGE,
            buttonAdd: 'une image',
            limit: 10,
            config: [ 
                getDefaultSlideImage(),
                getDefaultSlideImage(),
                getDefaultSlideImage(),
            ]
        }
    }
};