import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import ReturnLink from "../../../ui/link/ReturnLink";
import { Box } from "@material-ui/core";
import ContentAddSidebar from "./components/ContentAddSidebar";
import TopPanel from "../../../layouts/TopPanel/TopPanel";
import styled from "styled-components";
import formContentAdd from "./config/formContentAdd.config";
import { v4 as uuidv4 } from "uuid";
import { GET_MEDIA_CATEGORIES } from "../../../../queries/mediaCategories";
import { checkRouting } from "../../../../js/utils/checkRouting";
import request from '../../../../js/utils/fetch';
import { eventService } from "../../../../js/services/event.service";
import React from "react";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../../../js/constants/alert-types";
import { ADD_CONTENT, ADD_CONTENT_DATA } from "../../../../queries/contents";
import { ROUTE_CONTENU_LIST } from "../../../../js/constants/route-names";
import moment from "moment";
import { GET_CONTENT_CATEGORIES_ONLY } from "../../../../queries/content_categories";
import CardContentDetailsNew from "../../../layouts/Card/cardContent/CardContentDetailsNew";


const PageWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    position: relative;
    height: calc(100vh - 64px);
`;

class ContentAdd extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          currentLang: props.locales[0].node.code,
          groupAttribut: "",
          customAttributes: [],
          attributesSelected: [],
          metaAttributes: [],
          imageAttributes: [],
          attributes: [],
          maxImageNumber: 0,
          imageSrc: [],
          contentId: this.props.history.location?.state?.productId,
          sku: uuidv4(),
          title: "",
          categories: props.history.location.state.category
            ? [props.history.location.state.category]
            : [],
          categoriesData: [],
          nbCatalog: 0,
          description: "",
          status: "",
          metaTitle: "",
          metaDesc: "",
          openForm: false,
          allGroups: [],
          ready: false,
          errors: {},
          secondErrors: {},
          seeErrors: false,
          mediaCategories: [],
          selectedMediaCategories: [],
          height: 0,
        };
        this.typingTimer = null;
        this.typeTesting = 'product';
    }


    componentDidMount() {
        checkRouting(this.props);
        this.initContent();
        this.getMediasCategories();                    
    }


    getMediasCategories = () => {
        this.props.client.query({
            query: GET_MEDIA_CATEGORIES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            this.setState({ mediaCategories: result.data.mediaObjectCategories });
        })
    };

    handleToggleDrawer = (drawer) => {
        if (drawer === 'form') {
            this.setState({
                openForm: !this.state.openForm,
            });
        }
        this.setState({
            seeErrors: false
        });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };


    doneTyping = (stateName) => {
        if (this.state.sku) {
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.sku}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'sku', errorMessage: 'Cet identifiant est déjà utilisé et n\'est donc pas valide.' });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'sku') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            }, async () => {
                if (stateName === 'groupAttribut') {
                    await this.prepareAttributes();
                    this.prepareAttributeValues();
                }
            });
        }
        if (stateName === "sku")
            this.checkIdentifier(stateName);
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    resetState() {
        this.setState({
            imageSrc: [],
            categories: [],
            errors: {},
            selectedMediaCategories: []
        });
    }

    handleCancel = () => {
        this.resetState();
        this.initContent();
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    copy(array) {
        let newArray = [];

        for (let elem of array)
            newArray.push(Object.assign({}, elem));

        return newArray;
    }

    getAttributeTranslatedValue = (id, lang) => {
        if (!this.state.attributes)
            return null;

        let attribute = this.state.attributes.find(e => e.id === id);

        if (!attribute)
            return null;

        let translation = attribute.locales.find(e => e.id === lang);

        if (!translation)
            return null;

        return translation;
    };

    saveAttributes = (content) => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.isSystemAttributes.concat(this.state.customAttributes);
            let getContentData = [];
            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

                    if (formValue && isMedia) {
                        isMedia = true;
                    }
                    if (formValue) {
                        let variables = {
                            "attributeOption": attribute.node.attributeType.input === 'select' ? formValue : null,
                            "content": content,
                            "attribute": attribute.node.id,
                            "locale": locale.node.id
                        };

                        if (isMedia) {
                            variables.media = formValue.id;
                        }


                        if (!isMedia)
                            if (attribute.node.attributeType.input !== 'select') {
                                variables.value = formValue;
                            }
                        let resultMutation = await this.props.client.mutate({
                            mutation: ADD_CONTENT_DATA,
                            variables
                        })
                        getContentData.push(resultMutation.data.createContentData.contentData)
                    }
                }
            }

            resolve(getContentData);
        });
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, 'Une erreur est survenue');

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.initContent();

        this.props.snack(ALERT_SUCCESS, 'Contenu ajouté !');

        this.handleToggleDrawer('form');
        this.resetState();

        this.props.stopLoading();

        this.goTo(ROUTE_CONTENU_LIST);
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    handleSecondFormError = (stateName, error) => {
        let secondErrors = this.state.secondErrors;

        secondErrors[stateName] = error;

        this.setState({ secondErrors })
    }

    hasErrors = (formError) => {
        if (formError === 'firstForm') {
            if (this.state.errors) {
                for (let error in this.state.errors) {
                    if (this.state.errors[error])
                        return true;
                }
            }
        } else {
            if (this.state.secondErrors) {
                for (let error in this.state.secondErrors) {
                    if (this.state.secondErrors[error])
                        return true;
                }
            }
        }


        return false;
    };

    handlerMutation = async () => {
        try {
            if (this.hasErrors('firstForm')) {
                this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
                this.setState({ seeErrors: true });
                return eventService.fire();
            }

            this.props.startLoading();
            const ADD_CONTENT_RESULT = await this.props.client.mutate({
                mutation: ADD_CONTENT,
                variables: {
                    'sku': this.state.sku,
                    'attributeGroup': this.state.groupAttribut,
                    'contentCategories': this.state.categories.map(e => e.id),
                    'createdAt': moment().format('YYYY-MM-DD'),
                    'updatedAt': moment().format('YYYY-MM-DD'),
                    'status': this.state.status,
                    'mediaObjectCategories': this.state.selectedMediaCategories.map(e => e.id)
                    // 'superAttribute': this.state.isVariant ? this.state.variantsValidated.attributes.map(e => e.node.id) : [],
                }
            });

            await this.saveAttributes(ADD_CONTENT_RESULT.data.createContent.content.id);

            request(`${process.env.REACT_APP_API}/wordpress/content/sync/${ADD_CONTENT_RESULT.data.createContent.content.id.replace('/api/contents/', '')}`, 'get').then(
                (data) => {
                    if (data.success) {
                        this.props.snack(ALERT_SUCCESS, 'Le contenu a été publié');
                    }
                }
            );

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    render() {
        const { sku, status } = this.state;

        const selectLang = {
            type: 'select',
            label: 'Langue',
            helper: 'Langue',
            required: false,
            stateName: 'currentLang',
            value: this.props.locales.map((locale) => {
                return (
                    {
                        value: locale.node.code,
                        label: locale.node.libelle
                    }
                )
            })
        };

    return (
        <PageWrapper>
            <TopPanel
                title={"Ajouter un contenu"}
                subtitle={"Veuillez compléter les champs ci-dessous pour créer votre contenu"}
                // gradientColor1={colors.menu.regular}
                // gradientColor2={colors.menu.darker}
                // openForm={this.state.openForm}
                hasBorder={true}
                handlerAdd={this.handlerMutation}
                textAdd={"Valider le contenu"}
                buttonAvailable={true}      
                getRef={true}            
                setRef={(e)=>this.setState({height:e})}
            />
            <div 
                style={{ 
                    padding:"20px 30px",
                    display:"grid",
                    gridTemplateRows:"auto 1fr",
                }}
            >                
                <Box style={{padding:0}}>
                    <ReturnLink goTo={this.props.history.goBack}/>
                </Box>                
                <Box 
                    style={{
                        padding:0,
                        marginTop:10,
                        display:"grid",
                        gridTemplateColumns:"70% auto",
                        gap:20,  
                        height: `calc(100vh - 64px - ${this.state.height}px - 28px - 40px - 10px)`                      
                    }}
                >
                    <Box style={{overflow:"hidden"}}>
                        <CardContentDetailsNew
                            allState={this.state}
                            dataLayout={formContentAdd(
                                this.state.currentLang,
                                this.state.categoriesData,
                                this.state.customAttributes,
                                this.state.metaAttributes,
                                this.state.imageAttributes,
                                this.state.categories,
                                this.state.allGroups,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                this.state.mediaCategories.edges,
                                this.state.selectedMediaCategories
                            )}
                            stateCallback={this.handleInputChange}
                        />   
                    </Box>
                    <Box style={{overflow:"hidden"}}>
                        <ContentAddSidebar
                            isSublayout={false}
                            isProtected={false}
                            validateButton={true}
                            //handleCancel={this.handleCancel}
                            opened={this.state.openForm}
                            forClose={() => { this.goTo(ROUTE_CONTENU_LIST) }}
                            handlerMutation={this.handlerMutation}
                            icomoon={'ico-ajouter-produit'}
                            //noCancel={true}
                            dataLayout={formContentAdd(
                                this.state.currentLang,
                                this.state.categoriesData,
                                this.state.customAttributes,
                                this.state.metaAttributes,
                                this.state.imageAttributes,
                                this.state.categories,
                                this.state.allGroups,
                                this.state.errors,
                                this.state.seeErrors,
                                this.handleMediaPicker,
                                this.state.mediaCategories.edges,
                                this.state.selectedMediaCategories
                            )}
                            handleButtonGroupChange={this.handleButtonGroupChange}
                            allState={this.state}
                            stateCallback={this.handleInputChange}
                            errorCallback={this.handleFormError}
                            currentLang={this.state.currentLang}
                            handleLang={this.handleLang}
                            drawerWidth={this.props.drawerWidth}
                            deleteButton={false}                           
                        />
                    </Box>
                </Box>
            </div>
        </PageWrapper>
    )

}

prepareAttributes() {
    return new Promise(async (resolve, reject) => {
        let group = this.state.allGroups.find(e => e.node.id === this.state.groupAttribut);

        let isSystemAttributes = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem);
        let metaAttributes = this.props.attributes.content.attributes.edges.filter(e => e.node.isSystem && e.node.identifier.indexOf('meta') > -1);
        let customAttributes = group.node.attributes.edges.filter(e => !e.node.isSystem);
        let imageAttributes = isSystemAttributes.filter(e => e.node.attributeType.input === 'image')
            .concat(customAttributes.filter(e => e.node.attributeType.input === 'image'));

        this.setState({
            isSystemAttributes,
            metaAttributes,
            customAttributes,
            imageAttributes,
            maxImageNumber: imageAttributes.length,
        });

        resolve();
    });
}

prepareAttributeValues() {
    for (let locale of this.props.locales) {
        let values = {};
    
        for (let attribute of this.state.customAttributes) {
            if (attribute.node.attributeType.input === 'select') {
                if (attribute.node.attributeOptions.edges.length) {
                    values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id
                }
            }
            if (attribute.node.attributeType.input === 'date' || attribute.node.attributeType.input === 'datetime') {
                values[attribute.node.identifier] = moment().format('YYYY-MM-DD H:mm:ss');
            }
        }

        this.setState({
            [locale.node.code]: values
        });
    }
}

initContent() {
    this.setState({
        sku: uuidv4(),
        groupAttribut: this.props.attributeGroups.find(e => e.node.identifier === 'content')?.node?.id,
        allGroups: this.props.attributeGroups.filter(e => (!e.node.isSystem && e.node.isForContent) || e.node.identifier === 'content'),
        attributes: [],
        status: true
    }, async () => {
      await this.prepareAttributes();
      this.prepareAttributeValues();
      const GET_CONTENT_CATEGORIES_ONLY_RESULT = await this.props.client.query({
        query: GET_CONTENT_CATEGORIES_ONLY,
        fetchPolicy: "no-cache",
      });
      this.handleToggleDrawer("form");

      const categories = this.props.history.location.state.categoryID
      ? [
          GET_CONTENT_CATEGORIES_ONLY_RESULT.data.contentCategories.find(
            (e) => e.id.split('/').pop() == this.props.history.location.state.categoryID
          ),
        ]
      : []

      this.setState({
        categoriesData:
          GET_CONTENT_CATEGORIES_ONLY_RESULT.data.contentCategories,
        categories,
        ready: true,
      }); // all categories
    });
}

goTo = route => this.props.history.push(route);
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes,
        locales: state.locales,
        attributeGroups: state.attributeGroups
    };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ContentAdd)));