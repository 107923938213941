import React, { useEffect, useState } from "react";
import Swiper from 'swiper';
import Div from '../shared/div';
import H2 from '../shared/h2';
import Img from '../shared/img';

const SliderProductsHome = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null,
        data = null,
        spread = false
    } = props;

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-products', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            navigation: {
                nextEl: '.swiper-button-next-products',
                prevEl: '.swiper-button-prev-products',
            },
            spaceBetween: 20,
            breakpoints: {
                300: {
                    slidesPerView: 1.2,
                },
                700: {
                    slidesPerView: 2.2,
                },
                1100: {
                    slidesPerView: 3.2,
                },
            },
        });
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-products', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar',
            },
            navigation: {
                nextEl: '.swiper-button-next-products',
                prevEl: '.swiper-button-prev-products',
            },
            spaceBetween: 20,
            breakpoints: {
                300: {
                    slidesPerView: 1.2,
                },
                700: {
                    slidesPerView: 2.2,
                },
                1100: {
                    slidesPerView: 3.2,
                },
            },
        });
    }, [props]);

    let allRefs = [];
    
    if (data) {
        for (let product of data.blocks.products.config) {
            let info = product.blocks.product.inputs.product?.value;

            if (info) 
                allRefs.push(info?.item?.sku);
        }
    }

    if (!spread) {
        return (
            <div
                style={{ position: 'relative', background: data.blocks.background.inputs.backgroundColor.value }}
                id={`slider-${id}`}
                className="slider-product"
                data-type="slider-products"
                data-value={allRefs.join(';')}
                data-title={data.blocks.title.inputs.value.value}
                data-link={data.blocks.link.inputs.link.value}
                data-text-link={data.blocks.link.inputs.value.value}
                data-background={data.blocks.background.inputs.backgroundColor.value}
            />
        );
    }

    return (
        <Div
            style={{ position: 'relative', background: data.blocks.background.inputs.backgroundColor.value }}
            id={`slider-${id}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="slider-product"
            data-type="slider-products"
            data-value={allRefs.join(';')}
            data-title={data.blocks.title.inputs.value.value}
            data-link={data.blocks.link.inputs.link.value}
            data-text-link={data.blocks.link.inputs.value.value}
            data-background={data.blocks.background.inputs.backgroundColor.value}
        >
            {hover && !preview && props.children}

            <Div className="bloc-list bloc-list-products">
                <Div className="title-box">
                    <H2 className="w100 uppercase">{data.blocks.title.inputs.value.value}</H2>
                    <a href={data.blocks.link.inputs.link.value}>{data.blocks.link.inputs.value.value}</a>
                </Div>
                <Div className="list-products">
                    <Div className="swiper-container swiper-container-products">
                        <div className="swiper-wrapper">
                            {
                                data.blocks.products.config.map((product, index) => {
                                    let info = product.blocks.product.inputs.product?.value;

                                    let image = null;
                                    let label = null;

                                    if (info) {
                                        image = `${process.env.REACT_APP_MEDIAS}/${info.image}`;
                                        label = info.label;
                                    } else {
                                        label = "Titre du produit";
                                        image = typeof product.blocks.image.inputs.value.value === 'string' 
                                            ? product.blocks.image.inputs.value.value
                                            : product.blocks.image.inputs.value.value.filePath
                                                ? `${process.env.REACT_APP_MEDIAS}/${product.blocks.image.inputs.value.value.filePath}`
                                                : null;
                                    }
                                    
                                    return (
                                        <Div className="product swiper-slide" key={index}>
                                            <div>
                                                <Img className="w100" src={image} alt={label} />
                                            </div>
                                            <div>
                                                <p className="title">{label}</p>
                                                <p className="price">x €</p>
                                                <button style={{background: 'black', color:'white', border: 0, fontSize: 20, display: 'block', margin: '10px auto', padding: '8px 16px', cursor: 'not-allowed'}}>Ajouter au panier</button>
                                            </div>
                                        </Div>
                                    )
                                })
                            }
                        </div>
                        <div className="swiper-button-prev swiper-button-prev-products"></div>
                        <div className="swiper-button-next swiper-button-next-products"></div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default SliderProductsHome;