import React, { useEffect } from 'react';
import { eventService } from '../../../js/services/event.service';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List, ListItem, Checkbox, Select, MenuItem, InputLabel } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import Button from '../button/Button';
import { GET_CATALOGS } from '../../../queries/catalogs';
import { useLazyQuery, useQuery, withApollo } from 'react-apollo';
import { GET_ASSETS_BY_TYPE_CATEGORIES_CATALOG } from '../../../queries/assets';
import { fetchCatalogs } from '../../../queries/catalogs/hooks';
import { useQueryClient } from 'react-query';
import { fetchGroupsWithAttributes } from '../../../queries/attributes/hooks';
import PageLoader from '../loadings/page-loader/PageLoader';
import TreeView from '../tree-view/TreeView';
import { toggleExpandedForAll } from 'react-sortable-tree';
import { GET_CATEGORIES_LIGHT_2, GET_CATEGORIES_ONLY } from '../../../queries/categories';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import P from '../../../builder/shareable/components/assets/cms/components/shared/p';
import { fetchAssetsByTypeCategoriesCatalog, fetchAssetsTypes } from '../../../queries/assets/hooks';
import { getTraductionAttributs } from '../../../js/utils/functions';

const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    line-height: 20px;
    @media screen and (min-width: 1280px){
        height: 51px;
    }
    @media screen and (max-width: 1450px){
        font-size: 14px;
        line-height: 18px;
    }
    @media screen and (max-width: 1280px){
        font-size: 13px;
        line-height: 17px;
    }
    @media screen and (max-width: 960px){
        font-size: 12px;
        line-height: 16px;
    }
    display: flex;
    align-items: center;
    word-break: break-word;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: 'rgb(250, 251, 251)',
    },
}));

function CategoryMasterSelector(props) {
	const queryClient = useQueryClient();
    const [currentLang, setCurrentLang] =  React.useState(props.locales[0].node.code);
    const [loadingSource, setLoadingSource] = React.useState(false);
    const [loadingCategories, setLoadingCategories] = React.useState(false);
    const [seeError, setSeeError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [listSource, setListSource] = React.useState([]);
    const [treeData, setTreeData] = React.useState([]);
    const [selectedSource, setSelectedSource] = React.useState(props.allState.catalogSelected || null);
    const [selectedCategory, setSelectedCategory] = React.useState(props.allState.categoryMaster || null);
    // });
    const classes = useStyles();

    const checkError = (value = null) => {
        let val     = value === null ? props.allState.catalogSelected : value;
        let error   = false;
        let message = null;
        let type = null;
        if (val?.length === 0 && props.optionsInputs.required) {
            error   = true;
            message = `Veuillez sélectionner au moins un attribut`;
        }
        if (val){
            setSeeError(false);
        }

        setErrorMessage(error ? message : null);  
        if (props.errorCallback){  
            props.errorCallback(props.optionsInputs?.stateName,error);
        }
    };

    useEffect(() => {
        checkError(); 

        let subscription = eventService.get().subscribe((data) => {
            setSeeError(true);
            if (data && props.optionsInputs.stateName === data.stateName) {
                setErrorMessage(data.errorMessage);
                setSeeError(true)
            }
        });

        return () => subscription.unsubscribe();
    }, [props.allState.categoryMasterSelected]);

    const getSource = async() => {
        setLoadingSource(true)
        let getSourceList = props.optionsInputs.typeToLoad === "catalog" ? await fetchCatalogs(queryClient) : await fetchAssetsTypes(queryClient);
        if (props.optionsInputs.typeToLoad === "catalog"){
            setListSource(getSourceList.catalogs.edges)
        }else{
            let magentoType = getSourceList.assetTypes?.edges?.find(e => e.node.identifier === "magento")
            let shopifyType = getSourceList.assetTypes?.edges?.find(e => e.node.identifier === "shopify")
            let getAssetsByType = await fetchAssetsByTypeCategoriesCatalog(queryClient, [magentoType.node.id, shopifyType.node.id])
            setListSource(getAssetsByType.assets.edges)
        }
        setLoadingSource(false)
    }
    const handleChangeSource = async(value) => {
        setSelectedSource(value);
    }
    const handleClickNode = (value) => {
        setSelectedCategory(value.node.id);
        props.stateCallback(props.optionsInputs.stateName, value.node.id)
    }

    const expand = (expanded) => {
        setTreeData(toggleExpandedForAll({
            treeData: treeData,
            expanded,
        }))
    };

    const convertToNode = (data, isRoot = false) => {
        let allNames = data.categoryDatas.edges.filter(e => e.node.attribute.identifier === 'category_name');
        
        let getTraduction = allNames.find(
            translation => translation.node.locale.code === currentLang
        );

        data.title          = data.libelle === 'Root' ? '/' : getTraduction?.node?.value ?? allNames[0]?.node.value ?? data.libelle;
        data.isDirectory    = true;
        data.isRoot         = isRoot;
        data.expanded       = isRoot ? true : data.expanded;
        data.attributes     = [];

        for (let { node } of data.categoryDatas.edges) {
            let found = false;
            
            for (let attribute of data.attributes) {
                if (attribute.id === node.attribute.id) {
                    found = true;

                    // add locale to existing attribute
                    attribute.locales.push({
                        value: node.value,
                        media: node.media,
                        id: node.locale.id,
                        code: node.locale.code,
                        categoryDataId: node.id // category data id
                    });
                }
            }

            if (!found) {
                // create attribute and add locale
                data.attributes.push({
                    id: node.attribute.id,
                    identifier: node.attribute.identifier,
                    attributeType: node.attribute.attributeType,
                    locales: [{
                        value: node.value,
                        media: node.media,
                        id: node.locale.id,
                        code: node.locale.code,
                        categoryDataId: node.id
                    }]
                });
            }
        }
    }

    const populateChildren = (cats, parent) => {
        parent.children = cats.filter(e => e.parent !== null && e.parent.id === parent.id);
        
        for (let child of parent.children) {
            convertToNode(child);
            populateChildren(cats, child);
        }
    }

    const copyArrayOfObjects = array => array.map(a => ({...a})); // be careful, only breaks references at objects level

    useEffect(() => {
        getSource()
    }, []);

    useEffect(() => {
        if (selectedSource){
            prepareTree()
        }
    }, [selectedSource]);

    const prepareTree = () =>  {
        setLoadingCategories(true)
        return new Promise((resolve, reject) => {
            let variables = {
                "exists": [{"catalog": true}],
                "catalog": props.optionsInputs.typeToLoad === "catalog" ? selectedSource : listSource.find(e => e.node.id === selectedSource)?.node.catalog.id 
            }
            props.client.query({
                query: GET_CATEGORIES_LIGHT_2,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                let cats  = result.data.categories;
                cats = cats.sort(function (a, b){
                    return a.position - b.position;
                })
                let data  = cats.filter(e => e.parent === null);
    
                for (let parent of data) {
                    convertToNode(parent, true);
                    populateChildren(cats, parent);
                }
                setTreeData(copyArrayOfObjects(data));
                setLoadingCategories(false)
                resolve();
            });
        });
    }

    return (
        <Grid container direction="row" style={{background: "#FAFBFB",padding: 32}}>
            {
                loadingSource ?
                    <PageLoader />
                : 
                <>
                    {
                        listSource.length > 0 ?
                            <Grid container spacing={1}>
                                <Grid item md={3} xs={12}>
                                    <InputLabelCustom>Sélection {props.optionsInputs.typeToLoad === "catalog" ? "du catalogue" : "de l'asset"}</InputLabelCustom>
                                </Grid>
                                <Grid item md={9} xs={12}>
                                    <Select
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        value={selectedSource}
                                        onChange={(e) => handleChangeSource(e.target.value)}
                                    >
                                        {
                                            listSource.length > 0 ? 
                                                listSource.map((source, index) => {
                                                    let getName = null
                                                    if (props.optionsInputs.typeToLoad === "asset"){
                                                        getName = getTraductionAttributs('asset_store_name', source.node.assetDatas.edges, props.allState.currentLang);
                                                    }
                                                    return(
                                                        <MenuItem value={source.node.id} key={`source-${index}`}>{getName ? getName : source.node.libelle || source.node.identifier}</MenuItem>
                                                    )
                                                })
                                            : null
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                        : null
                    }
                    {
                        selectedSource ?
                            <Grid item xs={12}>
                                <InputLabelCustom>Choisir une catégorie:</InputLabelCustom>
                                {
                                    loadingCategories ? 
                                        <PageLoader />
                                    :
                                        <TreeView 
                                            typeOfTree={'selectCategorieFull'}
                                            dataTree={treeData} 
                                            canDrag={false} 
                                            expand={expand} 
                                            onChange={treeData => setTreeData(treeData)} 
                                            selectCategorie={handleClickNode}
                                            selectedCategory={selectedCategory}
                                        /> 
                                }
                            </Grid>
                        : null
                    }
                </>
            }
            {
                !seeError ? null : 
                <span className="error-label" style={{marginLeft: 10}}>{ errorMessage }</span>
            }
        </Grid>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

export default withTranslation()(withApollo(connect(mapStateToProps, null)(CategoryMasterSelector)));