import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getOfferListText } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Division de 3 textes',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        subTitle: getDefaultText('Sous-titre de la slide', 'Sous-titre de la slide', 39, '#000', null, 'initial', 'initial'),
        informations:  {
            id: uuidv4(),
            title: "Bloc de texte",
            name: "Bloc de texte",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.OFFER_TRIPLE_BLOCK_LIST_TEXT,
            limit: 3,
            config: [
                getOfferListText(`<p><b>1. UI/UX</b></p><ul><li>Proposition d'arborescence</li><li>Accessibilité de l'information</li><li>Ergonomie</li></ul><p><b>2. Technologie</b></p><ul><li>React Native</li><li>Responsive</li><li>Routeur de mail (Mandrill)</li><li>Technologie back-office : Symphony</li></ul>`),
                getOfferListText(`<p><b>3. Fonctionnalités*</b></p><b><i>Actualités</i></b><ul><li>Notification push</li><li>Partage</li><li>Agenda</li><li>Informations évènement selon intérêt</li></ul><p><b><i>Informations pratiques</i></b></p><ul><li>Map & marqueurs</li><li>Base de donnée des lieux</li><li>Compte RS</li></ul><p><b><i>Démarches</i></b></p><ul><li>Démarche administrative</li><li>Les actualités administratives</li><li>Formulaire de contact</li><li>Prise de rdv</li></ul>`)
            ]
        }
    }
};

