import React, { useState, useEffect } from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from '../../../ui/button/Button';
import Typography from '../../../ui/typography/Typography';
import StatusInfo from '../../../ui/status-info/StatusInfo';
import colors from '../../../../config/theme/colors';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import imgNotFound from '../../../../assets/images/image-fake-card.png';
import printempsImage from '../../../../assets/images/medias/Printemps.png';
import googleShopImage from '../../../../assets/images/medias/google-shopping.png';
import nuxeoImage from '../../../../assets/images/medias/Nuxeo.png';
import PDTImage from '../../../../assets/images/medias/Place-des-tendance.png';
import MadeByDesign from '../../../../assets/images/medias/Made-by-desgin.png';
import citadiumImage from '../../../../assets/images/medias/Citadium.png';
import caisseImage from '../../../../assets/images/medias/Caisse.png';

import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import request from '../../../../js/utils/fetch';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import * as moment from "moment";
import FavoriteIcon from '@material-ui/icons/Favorite';
import SecurityIcon from '@material-ui/icons/Security';
import { withTranslation } from 'react-i18next';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import TextEllipsis from './TextEllipsis';
import EditIcon from '@material-ui/icons/Edit';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';

const CardDescription = styled(Typography)`
    display: flex;
    flex-direction: row;
    min-height: 40px;
    overflow: hidden;
    font-size: 13px;
    line-height: 17px;
    span{
        white-space: nowrap;
    }
`;

const Libelle = styled(Typography)`
    font-size: 25px;
    line-height: 1.5;
    font-weight: bold;
    max-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
`
const ContainerImage = styled(Box)`
    overflow: hidden;
    background-color: ${props => props.bgcolor};
    background-image: url(${props => props.backgroundimage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`;

const LineColor = styled(Box)`
    background: ${colors.blue.darker.hue300};
`;

const WrapperAttr = styled(Grid)`
    align-items: center;
    &:not(:last-child){
        margin-bottom: 8px;
    }
    > p:first-child{
        min-width: 200px;
    }
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
`

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

function CardSetup(props) {
    const [displayCron, setDisplayCron] = useState(null);
    const type = props.type;

    // CRON SETUP
    let cronData = null;
    useEffect(() => {
        if (props.isRetailer) {
            let idRetailer = '';
            if (props.isDashboard) {
                idRetailer = type.node.id;
            }
            else {
                idRetailer = type.node?.id?.replace("/api/retailers/", '');
            }
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${idRetailer}`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        cronData = data.crons
                        let displayCron = {};
                        if (cronData.length > 0) {
                            switch (cronData[0].status) {
                                case "success":
                                    displayCron.color = colors.green.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = props.t("spread.campaigns.success");
                                    displayCron.type = cronData[0].type;
                                    displayCron.endDate = moment(cronData[0].end).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <Brightness1OutlinedIcon style={{ fill: displayCron.color, fontSize: '1.2rem' }} />;
                                    break;
                                case "pending":
                                    displayCron.color = colors.orange.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = props.t("spread.campaigns.waiting");
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <ReportProblemOutlinedIcon style={{ fill: displayCron.color, fontSize: '1.2rem' }} />

                                    break;
                                case "running":
                                    displayCron.color = colors.blue.darker.hue300;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = props.t("spread.campaigns.inProgress");
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <Brightness1OutlinedIcon style={{ fill: displayCron.color, fontSize: '1.2rem' }} />;
                                    break;
                                case "skipped":
                                    displayCron.color = colors.grey.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = props.t("spread.campaigns.ignored");
                                    displayCron.type = cronData[0].type;
                                    displayCron.picto = <ErrorOutlineIcon style={{ fill: displayCron.color, fontSize: '1.2rem' }} />
                                    break;
                                case "error":
                                    displayCron.color = colors.red.regular;
                                    displayCron.status = cronData[0].status;
                                    displayCron.statusText = props.t("spread.campaigns.error");
                                    displayCron.type = cronData[0].type;
                                    displayCron.startDate = moment(cronData[0].start).format('DD/MM/YYYY à HH:MM');
                                    displayCron.picto = <ReportProblemOutlinedIcon style={{ fill: displayCron.color, fontSize: '1.2rem' }} />
                                    break;
                                default:
                                    return null;
                            }
                            setDisplayCron(displayCron)
                        }
                    }
                }
            );
        }
    }, []);

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            color: colors.blue.darker.hue300,
            isDisabled: true,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                // props.actionButtonSecond();
                // handleClose(e);
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handlerMenu.toggleDeleteDialog(type.node.id)
            }
        }
    ]

    if (type.node) {
        // IMAGE SETUP
        let image = "";
        // if (type.node.logo) {
        //     image = type.node.logo
        //     const imagesFolder = require.context('../../../../assets/images', true);
        //     try {
        //         image = imagesFolder('./' + type.node.logo);               
        //     } catch (e) {
        //         image = imgNotFound;
        //     }
        // }
        console.log('type.node', type.node.identifier)
        if (type.node.logo) {
            switch (type.node.identifier) {
                case "sap_printemps":                        
                case "printemps":     
                    image = printempsImage;
                    break;
                case "google_shopping": 
                case "google_shopping_custom":                                        
                    image = googleShopImage;
                    break;
                case "nuxeo":                        
                    image = nuxeoImage;
                    break;
                case "place_des_tendances":                        
                case "sap_pdt":  
                case "specifix_pdt":  
                    image = PDTImage;
                    break;   
                case "made_in_design":                        
                case "sap_mid":  
                    image = MadeByDesign;
                    break;                                        
                case "citadium":                        
                case "sap_citadium":  
                    image = citadiumImage;
                    break;   
                case "caisse_magasin":  
                    image = caisseImage;
                    break;                       
                default:
                    break;
            }                
        } else {
            image = imgNotFound;
        }
        if (type.node.attributeGroup.identifier === "retailer" || type.node.identifier === 'retailer' || type.node.attributeGroup.identifier === "brand" || type.node.identifier === 'brand' || props.isRetailer) {
        }

        let bgColor = '#FFFFFF';

        switch (type.node.logo) {
            case "medias/magento-logo.png":
                bgColor = '#f36324'
                break;
            case "medias/cdiscount-logo.png":
                bgColor = '#233244'
                break;
            case "medias/bestbuy-logo.png":
                bgColor = '#0046c2'
                break;
            case "medias/amazon-logo.png":
                bgColor = '#0046c2'
                break;
            case "medias/twitter-logo.png":
                bgColor = '#00aced'
                break;
            case "medias/facebook-logo.png":
                bgColor = '#4267b2'
                break;
            case "medias/cloudcommerce-logo.png":
                bgColor = '#00a1df'
                break;
            case "medias/shopify-logo.png":
                bgColor = '#7ab55c'
                break;
            case "medias/wordpress-logo.png":
                bgColor = '#1e8cbe'
                break;
            case "medias/mailchimp-logo.png":
                bgColor = '#ffdf1b'
                break;
            default: bgColor = '#FFFFFF';
        }

        if (image === imgNotFound) {
            bgColor = '#f8fafb';
        }

        return (
            <Box style={{ height: "100%", display: "flex", flexDirection: "column", position: "relative", padding: 15 }}>
                <ContainerImage height={props.noImage ? 0:210} backgroundimage={props.noImage ? null:image ? typeof image === "string" ? image : image?.default : imgNotFound} />
                <Grid alignItems='center'>
                    {/* Title and description */}
                    <Box pb={1} mt={props.noImage?0:2} style={{ borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%", }}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='space-between' style={{position: 'relative'}}>
                                <Box>
                                    {
                                        type.node.attributeGroup.identifier === "retailer" ?
                                            <Typography variant="h5" colortext={colors.black.regular} style={{ fontWeight: "bold" }}>
                                                Retailer
                                            </Typography>
                                            : null
                                    }
                                    <Libelle variant="h4" colortext={colors.black.regular}>
                                        {type.node.libelle}
                                    </Libelle>
                                </Box>
                                <Box >
                                    <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} customStyle={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '25%',
                                    }} />            
                                </Box>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" component="div">
                            <CardDescription>
                                <TextEllipsis
                                    text={type.node.description}
                                    maxLine={2}
                                    ellipsis='...'
                                    hasModal={false}
                                />
                            </CardDescription>
                        </Typography>
                    </Box>

                    <Box mt={2} style={{ width: "100%" }}>
                        {/* Status */}
                        <WrapperAttr container>
                            <Typography>{props.t("products.list.cardproduct.status")} :</Typography>
                            <Box style={{ marginLeft: '3px' }}>
                                <StatusInfo status={props.isDashboard ? type.node.assets?.length > 0 ? true : false : type.node.assets?.edges.length > 0 ? true : false} width={'auto'} fontSize={13} />
                            </Box>
                        </WrapperAttr>
                        {
                            type.node.preferences ?
                                <Grid container direction="column" style={{ marginBottom: 16 }} justifyContent="center">
                                    <Grid container alignItems="center">
                                        <FavoriteIcon style={{ color: colors.grey.regular }} />
                                        <Typography variant="body1" component="div" style={{ marginLeft: 8 }}>
                                            {props.t("connectors.dashboard.prioWarehouse")} {type.node.preferences?.[0].warehouse}
                                        </Typography>
                                    </Grid>
                                    <Grid container alignItems="center" style={{ marginTop: 8 }}>
                                        <SecurityIcon style={{ color: colors.grey.regular }} />
                                        <Typography variant="body1" component="div" style={{ marginLeft: 8 }}>
                                            {props.t("connectors.dashboard.safetyStock")} {type.node.preferences?.[0].security_stock}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                : null
                        }
                        {
                            type.node.channelDatas ?
                                (
                                    <Grid container direction='column' style={{ height: '100%' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <WrapperAttr container>
                                                <Typography>Flux :</Typography>
                                                <Box style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                                    {props.t("spread.campaigns.outGoing")}
                                                </Box>
                                            </WrapperAttr>

                                            {
                                                props.showDetails ? (
                                                    <Button onClick={props.showDetails}>{props.t("connectors.dashboard.seeDetails")}</Button>
                                                ) : null
                                            }
                                        </Box>
                                    </Grid>
                                )
                                :
                                type.node.retailerData ?
                                    (
                                        <Grid container direction='column' style={{ height: '100%' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <WrapperAttr container>
                                                    <Typography>Flux :</Typography>
                                                    <Box style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                                        {props.t("spread.campaigns.inbound")}
                                                    </Box>
                                                </WrapperAttr>

                                                {
                                                    props.showDetails ? (
                                                        <Button onClick={props.showDetails}>{props.t("connectors.dashboard.seeDetails")}</Button>
                                                    ) : null
                                                }
                                            </Box>
                                        </Grid>
                                    )
                                    :
                                    null
                        }
                        {/* Modify button */}
                        <Box>
                            {props.handlerEdit && (
                                <Button
                                    style={{
                                        width: '100%',
                                        height: '40px',
                                        margin: '0px',
                                        marginTop: '28px',
                                        display: 'flex',
                                        alignSelf: 'end'
                                    }}
                                    text={props.t("spread_builder.asset_view.edit")}
                                    icon={"edit"}
                                    color={colors.blue.darker.hue300}
                                    colorHover={colors.white}
                                    bgcolorhover={colors.blue.lighter.hue600}
                                    bgcolor={colors.blue.lighter.hue900}
                                    border={`1px solid ${colors.blue.darker.hue300}`}
                                    stopPropagation={true}
                                    onClick={() => { props.handlerEdit() }}
                                />
                            )}
                        </Box>
                        {
                            displayCron ?
                                <Box style={{ display: 'flex', alignItems: 'center', marginTop: 16, cursor: 'pointer' }} onClick={() => { props.cronListOpening(props.isDashboard ? type.node.id : type.node.id.replace('/api/retailers/', ''), type.node) }}>
                                    {displayCron.picto}
                                    <Grid container direction="column">
                                        {
                                            displayCron.endDate || displayCron.startDate ?
                                                <Typography variant="body1" style={{ color: displayCron.color, paddingLeft: 8, fontSize: 12, lineHeight: '15px' }}>
                                                    {
                                                        displayCron.endDate ?
                                                            props.t("connectors.dashboard.finishAt", { date: displayCron.endDate })
                                                            : props.t("connectors.dashboard.sheduledAt", { date: displayCron.startDate })
                                                    }
                                                </Typography>
                                                : null
                                        }
                                        <Typography variant="body1" component="div" style={{ color: displayCron.color, paddingLeft: 8, fontSize: 12, lineHeight: '15px' }}>
                                            {displayCron.type}: {displayCron.statusText}
                                        </Typography>
                                    </Grid>
                                </Box>
                                : null
                        }

                        {/* <WrapperAttr container>
                            <Typography>{props.t("catalogs.catalogs.synchro")} :</Typography>
                            {
                                catalogData.autosync ? (
                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <SyncIcon style={{fill: colors.green.regular, fontSize: '1.2rem'}} />
                                        <Typography variant="body1" component="div" style={{color: colors.green.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                            {props.t("catalogs.catalogs.synchroProduct")}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div style={{width: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <SyncProblemIcon style={{fill: colors.orange.regular, fontSize: '1.2rem'}} />
                                        <Typography variant="body1" component="div" style={{color: colors.orange.regular, paddingLeft: 4, fontSize: 14, lineHeight: '19px', fontWeight: 'bold'}}>
                                            {props.t("catalogs.catalogs.noSynchroProduct")}
                                        </Typography>
                                    </div>
                                )
                            }
                        </WrapperAttr> */}

                    </Box>
                    {/* {props.handlerEdit ?
                        <Grid container justifyContent="flex-end" style={{ position: "absolute", top: 8, right: 16 }}>
                            <Button
                                // text={props.textButton} 
                                bgcolor={colors.white}
                                color={colors.blue.darker.hue300}
                                colorhover="white"
                                bgColor="transparent"
                                disableElevation={true}
                                border={`1px solid ${colors.blue.darker.hue300}`}
                                // arrow="right" 
                                onClick={() => { props.handlerEdit() }}
                                style={{ padding: "4px 8px", minWidth: 40 }}
                            >
                                <SettingsInputComponentIcon style={{ fontSize: '1.2rem' }} />
                            </Button>
                        </Grid>
                        : null} */}
                </Grid>

            </Box>
        );
    }
}

export default withTranslation()(withRouter(CardSetup));
