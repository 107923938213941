import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCategorieHome } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Catégories mises en avant',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
        value: {
            type: FormInputTypes.TEXT,
            label: "Titre de la section",
            value: "Catégories"
        },
        linkText: {
            type: FormInputTypes.TEXT,
            label: "Texte",
            value: "Découvrir"
        },
        showTitleOnHover:{
            label: 'Afficher le titre seulement au hover',
            type: FormInputTypes.SWITCH,
            value: false,
            id: uuidv4(),
        },
        disablePadding:{
            label: 'Espace sur les côtés',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
        cardHeight:{
            label: 'Hauteur des cards',
            type: FormInputTypes.SLIDER,
            value: 0,
            params: {max: 800, min: 0, step: 2},
            id: uuidv4(),
        },
    },
    blocks:{
        categories:{
            title: "Catégorie",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_CATEGORIES_HOME,
            buttonAdd: 'une catégorie',
            limit: 8,
            config: [ 
                getDefaultCategorieHome()
            ]
        }
    }
};