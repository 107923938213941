import {FORM} from '../../../../../../js/constants/form-types';
import { ALLOWED, IMAGES, VIDEOS } from '../../../../../../js/constants/medias-types';
import Button from '../../../../../ui/button/Button';


export default function feedAdd (disabledLink=false,disabledImage=false,assetData,handleMediaPicker=()=>{},postType, postTypeMedia, postNeedScheduled){
    var obj=
        {
            titleForm:`Ajouter un post ${assetData.assetType.libelle}`,
            subTitleForm: `Ajouter un post ${assetData.assetType.libelle}`,
            /*langSelect: true,*/
            drawerType: 'drawer',
            noForm: true,
            component: Button,
            textButtonValidate: 'Ajouter le post',
            formConfig: {
                type: FORM,
                children: []
            },

        }    
    var children=[];

    switch(assetData.assetType.identifier) {
        case 'facebook':
            let facebookPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Contenu',
                        },
                    ]
                },
                // {
                //     type: 'buttonGroup',
                //     label: 'Type de média',
                //     helper: {
                //         label: 'Type de média',
                //         text: 'Indiquez le type de média voulu',
                //         link: false,
                //     },
                //     required: true,
                //     stateName: 'postTypeMedia',
                //     value: [
                //         {
                //             value: 'image',
                //             label: 'Image',
                //         },
                //         {
                //             value: 'video',
                //             label: 'Video',
                //         },
                //     ]
                // },
            ]
            if (postType === 'classic'){
                facebookPost.push(
                    {
                        type: 'buttonGroup',
                        label: 'Type de média',
                        helper: {
                            label: 'Type de média',
                            text: 'Indiquez le type de média voulu',
                            link: false,
                        },
                        required: true,
                        stateName: 'postTypeMedia',
                        value: [
                            {
                                value: 'image',
                                label: 'Image',
                            },
                            {
                                value: 'video',
                                label: 'Video',
                            },
                        ]
                    },
                    {
                        type: 'mediaPicker',
                        handleMediaPicker : handleMediaPicker,
                        stateName: 'paramImage',
                        label: 'Média de la publication',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        disabled : disabledImage,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    },   
                    // {
                    //     type: 'text',
                    //     label : 'Lien externe',
                    //     required : false,
                    //     stateName : 'paramLink',
                    //     helper: {
                    //         text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                    //         link: false,
                    //     },
                    //     disabled : disabledLink,
                    // },
                    {
                        type: 'textareaSimple',
                        label : 'Publication',
                        required : false,
                        stateName : 'paramText',
                        helper: {
                            text: 'Rédigez ici votre publication',
                            link: false,

                        },
                    } 
                )
                facebookPost.push({
                    type: 'buttonGroup',
                    label: 'Publication programmée',
                    helper: {
                        label: 'Publication programmée',
                        text: 'Indiquez si vous voulez programmer la publication',
                        link: false,
                    },
                    required: true,
                    stateName: 'postNeedScheduled',
                    value: [
                        {
                            value: true,
                            label: 'Oui',
                        },
                        {
                            value: false,
                            label: 'Non',
                        },
                    ]
                });
                if (postNeedScheduled){
                    facebookPost.push({
                        type: 'date',
                        label: 'Date de publication',
                        helper: {
                            text: 'Date de publication du post (Min: 10 min de délai, Max: 3 mois)',
                            link: false,
                        },
                        required: true,
                        getTime: true,
                        stateName: 'scheduledAt',
                        minDate: 'minScheduledAt',
                        maxDate: 'maxScheduledAt',
                    });
                }
            } else if (postType === 'product'){
                facebookPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            } else {
                facebookPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: facebookPost
                }
            ] 
            break;
        case 'linkedin':
        case 'instagram':
            let instagramPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                    ]
                }
            ]
            if (postType === 'classic'){
                instagramPost.push(
                    {
                        type: 'buttonGroup',
                        label: 'Type de média',
                        helper: {
                            label: 'Type de média',
                            text: 'Indiquez le type de média voulu',
                            link: false,
                        },
                        required: true,
                        stateName: 'postTypeMedia',
                        value: [
                            {
                                value: 'image',
                                label: 'Image',
                            },
                            {
                                value: 'video',
                                label: 'Video',
                            },
                        ]
                    },
                    {
                        type: 'mediaPicker',
                        handleMediaPicker : handleMediaPicker,
                        stateName: 'paramImage',
                        label: 'Média de la publication',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        disabled : disabledImage,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    },   
                    {
                        type: 'textareaSimple',
                        label : 'Publication',
                        required : false,
                        stateName : 'paramText',
                        helper: {
                            text: 'Rédigez ici votre publication',
                            link: false,

                        },
                    } 
                )
            } else if (postType === 'product'){
                instagramPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            }
            if (assetData.assetType.identifier === "instagram"){
                instagramPost.push({
                    type: 'date',
                    label: 'Date de publication',
                    helper: {
                        text: 'Date de publication du post (Min: 10 min de délai, Max: 24h)',
                        link: false,
                    },
                    required: true,
                    getTime: true,
                    stateName: 'scheduledAt',
                    minDate: 'minScheduledAt',
                    maxDate: 'maxScheduledAt',
                });
            }
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: instagramPost
                }
            ] 
            break;

        case 'twitter':
            let twitterPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Produit',
                        },
                    ]
                },
            ]
            if (postType === 'classic'){
                twitterPost.push(
                    {
                        type: 'buttonGroup',
                        label: 'Type de média',
                        helper: {
                            label: 'Type de média',
                            text: 'Indiquez le type de média voulu',
                            link: false,
                        },
                        required: true,
                        stateName: 'postTypeMedia',
                        value: [
                            {
                                value: 'image',
                                label: 'Image',
                            },
                            {
                                value: 'video',
                                label: 'Video',
                            },
                        ]
                    },
                    {
                        type: 'textareaSimple',
                        label : 'Publication',
                        required : false,
                        stateName : 'paramText',
                        helper: {
                            text: 'Moins de 280 caractère',
                            link: false,
                            charCount:true,
                            maxChar:280
                        },

                    },
                    {
                        type: 'mediaPicker',
                        label: 'Image #1',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterFirstMedia',
                        disabled : disabledImage,
                        handleMediaPicker: handleMediaPicker,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    },   
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,
                        label: 'Image #2',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterSecondMedia',
                        disabled : disabledImage,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    }, 
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,

                        label: 'Image #3',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterThirdMedia',
                        disabled : disabledImage,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    }, 
                    {
                        type: 'mediaPicker',
                        handleMediaPicker: handleMediaPicker,

                        label: 'Image #4',
                        helper: {
                            text: 'Vous pouvez ajouter un lien OU une image à votre publication',
                            link: false,
                        },
                        required: false,
                        stateName: 'paramTwitterFourthMedia',
                        disabled : disabledImage,
                        allowedTypes: postTypeMedia === "image" ? IMAGES : VIDEOS
                    }, 
                )
            } else if (postType === 'product') {
                twitterPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            }else {
                twitterPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }
            
            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: twitterPost
                }
            ] 
        break;
            
        case 'wordpress':
            let wordpressPost = [
                {
                    type: 'buttonGroup',
                    label: 'Type de post',
                    helper: {
                        label: 'Type de post',
                        text: 'Indiquez le type de post voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postType',
                    value: [
                        {
                            value: 'classic',
                            label: 'Classique',
                        },
                        {
                            value: 'product',
                            label: 'Produit',
                        },
                        {
                            value: 'content',
                            label: 'Contenu',
                        },
                    ]
                },
                {
                    type: 'buttonGroup',
                    label: 'Type de média',
                    helper: {
                        label: 'Type de média',
                        text: 'Indiquez le type de média voulu',
                        link: false,
                    },
                    required: true,
                    stateName: 'postTypeMedia',
                    value: [
                        {
                            value: 'image',
                            label: 'Image',
                        },
                        {
                            value: 'video',
                            label: 'Video',
                        },
                    ]
                }
            ]

            if (postType === 'classic'){
                wordpressPost.push(
                    {
                        type: 'text',
                        label : 'Titre',
                        required : true,
                        stateName : 'paramText',
                        helper: {
                            text: 'Titre de votre article',
                            link: false,
                        },
                    },
                    {
                        type: 'textarea',
                        label : 'Contenu',
                        required : true,
                        stateName : 'paramText2',
                        helper: {
                            text: 'Contenu de votre article',
                            link: false,
                        },
                    },
                )
            } else if (postType === 'product') {
                wordpressPost.push( {
                    type: 'productSelector',
                    label : 'Produit',
                    required : false,
                    stateName : 'postProduct',
                    helper: {
                        text: 'Veuillez choisir un produit',
                        link: false
                    }
                });
            } else {
                wordpressPost.push( {
                    type: 'productSelector',
                    label : 'Contenu',
                    required : false,
                    stateName : 'postContent',
                    contentSelector : true,
                    helper: {
                        text: 'Veuillez choisir un contenu',
                        link: false
                    }
                });
            }

            children = [
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs: wordpressPost 
                }
            ] 
        break;  
            
        default: children = [];
    }

    obj.formConfig.children=children;
    


    return obj;
}
