import { gql } from 'apollo-boost';

export const GET_RESTAURANTDATAS = gql`
{
  restaurantDatas {
    edges{
      node{
        id
        value
        attribute {
          id
          identifier
        }
        attributeOption{
          id
          identifier
        }
        restaurant{
          id
        }
        media {
          id
          contentUrl
          filePath
          size
          isInternal
          name
          alt
          origin
          description
        }
      }
    }
  }
}
`;

/*
{
  "restaurant": "/api/restaurants/2",
  "attribute": "/api/attributes/1",
  "attributeOption": "/api/attribute_options/1",
  "media": "/api/medias/1",
  "value": "value"
}
 */
export const CREATE_RESTAURANTDATA = gql`
mutation CreateRestaurantDatas($media: String, $value: String, $restaurant: String!, $attribute: String!, $attributeOption: String){
  createRestaurantData(input:{media: $media, value: $value, restaurant: $restaurant, attribute: $attribute, attributeOption: $attributeOption}){
    restaurantData{
      value
      attribute {
        id
      }
      attributeOption {
        id
      }
      media {
        id
      }
      restaurant{
        id
      }
    }
  }
}
`;


/*
{
  {
  "id": "/api/restaurant_datas/1",
  "restaurant": "/api/restaurants/2",
  "attribute": "/api/attributes/1",
  "attributeOption": "/api/attribute_options/1"
}
}
 */
export const UPDATE_RESTAURANTDATA = gql`
mutation updateRestaurantDatas($id: ID!, $media: String, $value: String, $restaurant: String!, $attribute: String!, $attributeOption: String){
  updateRestaurantData(input:{id: $id, media: $media, value: $value, restaurant: $restaurant, attribute: $attribute, attributeOption: $attributeOption}){
    restaurantData{
      id
      value
      attribute {
        id
      }
      attributeOption {
        id
      }
      media {
        id
      }
      restaurant{
        id
      }
    }
  }
}
`;

/*
{
  "id": "/api/restaurant_datas/1"
}
 */
export const DELETE_RESTAURANTDATA = gql`
mutation deleteRestaurantDatas($id: ID!){
  deleteRestaurantData(input:{id: $id}){
    restaurantData{
      id
    }
  }
}
`;

/*
{
  "id": "/api/restaurant_datas/2"
}
 */
export const GETBY_ID_RESTAURANTDATA = gql`
query GetByIdRestaurantData($id: ID!){
  restaurantData(id: $id){
    id
    value
    attribute {
      id
      identifier
    }
    attributeOption{
      id
      identifier
    }
    restaurant{
      id
    }
    media {
      id
      contentUrl
      filePath
      size
      isInternal
      name
      alt
      origin
      description
    }
  }
}
`;
