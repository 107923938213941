import { getItemsLocalStorage, setItemsLocalStorage } from "../helpers/filters";

function resetPaginationLocalStorage(key, listKey){
    let getItem = getItemsLocalStorage(key);
    if (getItem){
        if (listKey && listKey?.length > 0){
            for (let element of listKey){
                if (getItem[element]){
                    getItem[element].pagination = null;
                }
            }
            setItemsLocalStorage(key, getItem)
        }else{
            getItem.pagination = null;
            setItemsLocalStorage(key, getItem)
        }
        
    }
}

export function checkPath(path){
    if(!path.location.pathname.includes('products')){
        resetPaginationLocalStorage("PIM_FILTERS");
    }
    if(!path.location.pathname.includes('catalogs')){
        resetPaginationLocalStorage("CATALOGS_FILTERS");
    }
    if(!path.location.pathname.includes('sales') && !path.location.pathname.includes('communities')){
        resetPaginationLocalStorage("ASSETS_FILTERS", ['eshop','game', 'global', 'marketplace', 'minisite', 'shop', 'social', 'social-commerce', 'applications']);
    }
}

export default resetPaginationLocalStorage;