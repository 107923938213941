import React, { useEffect, useState } from "react";

const BlocSeo = ({
    preview = false,
    id = null,
    data = null,
    block = null,
    inputCallback = null,
    children = null
}) => {
    const [hover, setHover] = useState(false);

    const styles = {
        position: "relative",
        padding: "0",
    };


    return (
        <div style={styles} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { !preview && hover && children}
            <div style={{height:0,overflow:"hidden"}} dangerouslySetInnerHTML={{__html : data.inputs?.content?.value}}/>
            { !preview && <div>Bloc SEO</div>}
        </div>
    );
};

export default BlocSeo;