import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { ROUTE_FORGET_PASSWORD } from '../../../js/constants/route-names';
import { connect } from "react-redux";
import styled from 'styled-components';
import * as moment from 'moment';
import {
	Grid,
	Box,
} from '@material-ui/core';
import Typography from '../../ui/typography/Typography';
import { withApollo } from 'react-apollo';
import { GET_LOCALES } from '../../../queries/locales';
import { GET_EAV_TYPES } from '../../../queries/attributes';
import { GET_CURRENT_USER } from '../../../queries/users';
import { GET_BRAND_GUIDELINE } from '../../../queries/brand_guideline';
import { GET_ALL_CHANNELS } from '../../../queries/channels'
import { GET_ASSET_CATEGORIES, GET_ASSETS_CURSOR} from '../../../queries/assets'
import { GET_RETAILERS_CURSOR } from '../../../queries/retailers';
import { GET_COMPLETENESSGROUPS } from '../../../queries/completeness_group';
import { GET_CURRENCIES } from '../../../queries/currencies';
import { GET_RATE_GROUPS_LIGHT } from '../../../queries/rate_group';
import { GET_SELECTIONS } from '../../../queries/selections';
import { START_LOADING, STOP_LOADING, SET_USER, SNACK, SET_LOCALES, SET_ATTRIBUTES, SET_ATTRIBUTE_GROUPS, SET_GUIDELINE, SET_ATTRIBUTE_TYPES, SET_LOGIN_PROGRESS, SET_ATTRIBUTES_LOADED } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';
import request from '../../../js/utils/fetch';
import ButtonStyled from "../../ui/button/Button";
import logo from '../../../assets/images/logo-sinfin-digital.svg';
import FormBuilder from '../../ui/form/FormBuilder';
import colors from '../../../config/theme/colors';
import { useQueryClient } from "react-query";
import './Login.scss';
import { fetchAttributes, fetchAttributesTypes, fetchGroupsWithAttributes } from '../../../queries/attributes/hooks';
import { withTranslation } from 'react-i18next';

const inputsLogin = [
	{
		type: 'text',
		email: true,
		noSpecificValidation: true,
		label: 'Adresse e-mail',
		helper: {
			text: '',
			link: false
		},
		required: true,
		stateName: 'email',
		disabled: false,
	},
	{
		type: 'password',
		noSpecificValidation: true,
		label: 'Mot de passe',
		helper: {
			text: 'Mot de passe oublié',
			link: true,
			route: ROUTE_FORGET_PASSWORD,
		},
		helperLink: true,
		required: true,
		stateName: 'password',
		disabled: false,
	},
];

const GridCustom = styled(Grid)`
	padding: 0 32px;
	@media screen and (max-width: 1280px) {
		padding: 0 24px;
	}
`;

const ButtonCustom = styled(ButtonStyled)`
	margin:auto;
	width: 100%;
	padding: 14px 32px;
`;

const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

const Login = ({
	snack,
	startLoading,
	stopLoading,
	setGuideline,
	setAttributes,
	setAttributeGroups,
	setAttributeTypes,
	setLocales,
	setUser,
	setLoginProgress,
	setAttributesLoaded,
	client,
	attributes, 
	attributeGroups,
	attributeTypes,
	t
}) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [requesting, setRequesting] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress, false);

		return () => document.removeEventListener("keydown", handleKeyPress, false);
	}, [email, password]);

	const handleInputChange = (stateName, evt) => {
		const value = evt?.target?.value ?? evt;

		if (stateName === 'email')
			setEmail(value);

		if (stateName === 'password')
			setPassword(value);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter')
			onSubmit();
	};
	
	const prepareAllForAssets = async () => {

        let localStorage_GET_ALL_CHANNELS = localStorage.getItem('GET_ALL_CHANNELS');
        if(!localStorage_GET_ALL_CHANNELS){
             await client.query({
                query: GET_ALL_CHANNELS,
            }).then(result =>{
                localStorage.setItem('GET_ALL_CHANNELS', JSON.stringify(result));
            });
        } 

		let localStorage_GET_EAV_TYPES = localStorage.getItem('GET_EAV_TYPES');
        if(!localStorage_GET_EAV_TYPES){
             await client.query({
                query: GET_EAV_TYPES,
            }).then(result =>{
                localStorage.setItem('GET_EAV_TYPES', JSON.stringify(result));
            });
        }

		let localStorage_GET_ASSET_CATEGORIES = localStorage.getItem('GET_ASSET_CATEGORIES');
		if(!localStorage_GET_ASSET_CATEGORIES){
			const getAssetsCategories = GET_ASSET_CATEGORIES;
			 await client.query({
				query: getAssetsCategories,
			}).then(result =>{
				localStorage.setItem('GET_ASSET_CATEGORIES', JSON.stringify(result));
			});
		}

        let localStorage_GET_CURRENCIES = localStorage.getItem('GET_CURRENCIES');
        if(!localStorage_GET_CURRENCIES){
             await client.query({
                query: GET_CURRENCIES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_CURRENCIES', JSON.stringify(result));
            });
        } 

        let localStorage_GET_RATE_GROUPS_LIGHT = localStorage.getItem('GET_RATE_GROUPS_LIGHT');
        if(!localStorage_GET_RATE_GROUPS_LIGHT){
             await client.query({
                query: GET_RATE_GROUPS_LIGHT,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_RATE_GROUPS_LIGHT', JSON.stringify(result));
            });
        }	
		
        let localStorage_GET_ASSETS_CURSOR = localStorage.getItem('GET_ASSETS_CURSOR');
        if(!localStorage_GET_ASSETS_CURSOR){
             await client.query({
                query: GET_ASSETS_CURSOR,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_ASSETS_CURSOR', JSON.stringify(result));
            });
        }

		let localStorage_GET_RETAILERS_CURSOR = localStorage.getItem('GET_RETAILERS_CURSOR');
        if(!localStorage_GET_RETAILERS_CURSOR){
             await client.query({
                query: GET_RETAILERS_CURSOR,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_RETAILERS_CURSOR', JSON.stringify(result));        
            });
		}

		let localStorage_GET_COMPLETENESSGROUPS = localStorage.getItem('GET_COMPLETENESSGROUPS');
        if(!localStorage_GET_COMPLETENESSGROUPS){
             await client.query({
                query: GET_COMPLETENESSGROUPS,
                fetchPolicy: "no-cache",
            }).then(result => {
                localStorage.setItem('GET_COMPLETENESSGROUPS', JSON.stringify(result));
            });
        }

		let localStorage_GET_SELECTIONS = localStorage.getItem('GET_SELECTIONS');
        if(!localStorage_GET_SELECTIONS){
             await client.query({
                query: GET_SELECTIONS,
                fetchPolicy: "no-cache",
            }).then(result => {
                localStorage.setItem('GET_SELECTIONS', JSON.stringify(result));
            });
        }


	};

	const prepareAttributes = async () => {
		// default values so it doesn't break

		const defaultEav = {attributes: {edges: []}};

		let attributesDefault = {
			eavTypes: [],
			category: defaultEav,
			product: defaultEav,
			content: defaultEav,
			company: defaultEav,
			customer: defaultEav
		};

		if (!attributes) {
			setAttributes(attributesDefault);
			localStorage.setItem('ATTRIBUTES', JSON.stringify(attributesDefault));
		}

		if (!attributeGroups) {
			setAttributeGroups([]);
			localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify([]));
		}

		if (!attributeTypes) {
			setAttributeTypes([]);
			localStorage.setItem('ATTRIBUTE_TYPES', JSON.stringify([]));
		}

		// get fresh data

		let attributeGroupsUpdated = await fetchGroupsWithAttributes(queryClient);
		let attributeTypesUpdated = await fetchAttributesTypes(queryClient);
		setAttributeGroups(attributeGroupsUpdated?.attributeGroups?.edges ?? []);
		setAttributeTypes(attributeTypesUpdated?.attributeTypes?.edges ?? []);
		localStorage.setItem('ATTRIBUTE_GROUPS', JSON.stringify(attributeGroupsUpdated?.attributeGroups?.edges ?? []));
		localStorage.setItem('ATTRIBUTE_TYPES', JSON.stringify(attributeTypesUpdated?.attributeTypes?.edges ?? []));

		let dataTypes = await client.query({ query: GET_EAV_TYPES, fetchPolicy: 'no-cache' });
		const allTypes = dataTypes?.data?.eavTypes?.edges ?? [];
		let categoryTypes = [];
		let typesToFetch = allTypes.concat(['product', 'category', 'content']).filter(e => !e.node?.isSystem).map(e => e.node?.code ? e.node.code : e);

		if (isCRM)
			typesToFetch.push('company', 'customer');

		attributesDefault = {
			eavTypes: allTypes,
		};

		for (let type of allTypes) {
			if (~typesToFetch.indexOf(type.node.code)) {
				let data = await fetchAttributes(queryClient, type.node.id);
				categoryTypes.push(data?.eavType);
				attributesDefault[type.node.code] = data?.eavType ?? defaultEav;
				setAttributes(attributesDefault);
				localStorage.setItem('ATTRIBUTES', JSON.stringify(attributesDefault));
			}
		}

		setAttributesLoaded(true); // cf LayerBreadcrumb that prevents access to specific routes
		localStorage.setItem('ATTRIBUTES_LOADED', true);
	};

	const prepareGuidelines = async () => {
		let dataGuideline = await client.query({ query: GET_BRAND_GUIDELINE, fetchPolicy: 'no-cache' });
		setGuideline(dataGuideline?.data?.guidelines?.edges?.[0]?.node ?? null);
		localStorage.setItem('GUIDELINE', JSON.stringify(dataGuideline?.data?.guidelines?.edges?.[0]?.node ?? null));
	};

	const prepareLocales = async () => {
		// default values so it doesn't break

		const defaultLocales = [{
			node: {
				code: "fr_FR",
				id: "/api/locales/1",
				libelle: "French"
			}
		}];

		setLocales(defaultLocales);

		// get fresh data

		let dataLocales = await client.query({ query: GET_LOCALES, fetchPolicy: 'no-cache' });
		setLocales(dataLocales?.data?.locales?.edges ?? null);
		localStorage.setItem('LOCALES', JSON.stringify(dataLocales?.data?.locales?.edges ?? null));
	};

	const onSubmit = () => {
		if (requesting)
			return false;

		if (!email || !password)
			return snack(ALERT_ERROR, 'Veuillez remplir tous les champs');

		startLoading();
		setRequesting(true);

		// Clear to avoid old tokens to be used

		localStorage.removeItem('AUTH_USER');
		localStorage.removeItem('AUTH_TOKEN');
		localStorage.removeItem('AUTH_TOKEN_EXPIRY');

		request(`${process.env.REACT_APP_API}/authentication-token`, 'post', {
			email,
			password
		}).then(async (data) => {
			// - set auth

			localStorage.setItem('AUTH_TOKEN', data.token);
			localStorage.setItem('AUTH_TOKEN_EXPIRY', moment().add(3, 'hours').toISOString()); // normally 4 hours but security margin

			// - fetch user

			let user = await client.query({ query: GET_CURRENT_USER, fetchPolicy: 'no-cache' });

			// - set data asynchronously

			await load();

			// - set user

			let currentUser = user?.data?.meUser;
			currentUser.menuEntries = currentUser?.userGroup?.menuEntry?.edges.map(e => e.node);
			localStorage.setItem('AUTH_USER', JSON.stringify(currentUser));
			setUser(currentUser);

			// - finish
			
			stopLoading();
			snack(ALERT_SUCCESS, 'Bienvenue dans votre espace !');
		}).catch(error => {
			console.error(error);

			if (error?.status && error?.status === 401) {
				// API ERROR
				setRequesting(false);
				stopLoading();
				snack(ALERT_ERROR, `Veuillez vérifier vos identifiants de connexion`);
			} else if (error?.status && error?.status === 429) {
				// TOO MUCH FAILS
				setRequesting(false);
				stopLoading();
				snack(ALERT_ERROR, `Trop de tentatives infructueuses`);
			} else {
				// GQL ERROR
				setRequesting(false);
				stopLoading();
				snack(ALERT_ERROR, `Une erreur est survenue...`);
			}
		});
	};

	const load = async () => {
		// - Attributes first because it takes a long time.
		// - Loading will be longer in products page, only for first load.

		prepareAttributes();
		// setLoginProgress('1/3');
		await prepareLocales(); // at least wait for locales to prevent issues
		// setLoginProgress('2/3');
		prepareGuidelines();
		// setLoginProgress('3/3');
		prepareAllForAssets();
		// setLoginProgress(null);
	};

	return (
		<div className={"login"}>
			<div className={"login-header"}>
				<img src={logo} alt="logo-dxp" style={{ maxWidth: '250px' }} />
			</div>
			<div className={"login-body"}>
				<Box mt={1} mb={4} textAlign={'center'} style={{ maxWidth: '100%', width: '100%' }}>
					<Typography variant={'h2'} style={{color:colors.black.regular, fontSize: 27, maxWidth: 400}}>{t("login.title")}</Typography>
				</Box>
				<FormBuilder
					optionsInputs={inputsLogin}
					stateCallback={handleInputChange}
					backgroundColor={'white'}
					allState={{
						email,
						password
					}}
					isLogin={true}
				/>
				<GridCustom container>
					<ButtonCustom text="Se connecter" className="button" onClick={onSubmit} style={{ marginTop: 0}} />
				</GridCustom>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
    return {
        attributes: state.attributes,
		attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
	return {
		startLoading: () => dispatch({ type: START_LOADING }),
		stopLoading: () => dispatch({ type: STOP_LOADING }),
		setUser: (user) => dispatch({ type: SET_USER, payload: { user } }),
		snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
		setLocales: (locales) => dispatch({ type: SET_LOCALES, payload: { locales } }),
		setGuideline: (guideline) => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
		setAttributes: (attributes) => dispatch({ type: SET_ATTRIBUTES, payload: { attributes } }),
		setAttributeGroups: (attributeGroups) => dispatch({ type: SET_ATTRIBUTE_GROUPS, payload: { attributeGroups } }),
		setAttributeTypes: (attributeTypes) => dispatch({ type: SET_ATTRIBUTE_TYPES, payload: { attributeTypes } }),
		setLoginProgress: (progress) => dispatch({ type: SET_LOGIN_PROGRESS, payload: progress }),
		setAttributesLoaded: (value) => dispatch({ type: SET_ATTRIBUTES_LOADED, payload: value }),
	}
}

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(Login))));
