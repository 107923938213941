import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bannière media',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
    },
    blocks:{
        media: {
            type: EditableTypes.IMAGE,
            name: "Media",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/1920x980"
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: null,
                    label:"Texte alternatif"
                },
                containerWidth: {
                    type: FormInputTypes.SLIDER,
                    value: 1920,
                    params: {max: 1920, min: 10, step: 10},
                    label: "Largeur maximum du container (en px)",
                },
                imageWidth: {
                    type: FormInputTypes.SLIDER,
                    value: 100,
                    params: {max: 100, min: 0, step: 1},
                    label: "Largeur maximum de l'image (en %)",
                },
                justifyImage: {
                  type: FormInputTypes.SELECT,
                  label: "Alignement de l'image",
                  value: "initial",
                  params: [
                    { label: "Initial", value: "flex-start" },
                    { label: "Centré", value: "center" },
                    { label: "Droite", value: "flex-end" },
                  ],
                },
                paddingVertical: {
                    type: FormInputTypes.SLIDER,
                    value: 0,
                    params: {max: 100, min: 0, step: 1},
                    label: "Padding vertical (en px)",
                },
            }
        },
    }
};