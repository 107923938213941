import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultTextCms, getDefaultLinkCms } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bloc storelocator',
    inputs:{
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        optimisedImage:{
            label: 'Utiliser des images optimisées',
            type: FormInputTypes.SWITCH,
            value: true,
            id: uuidv4(),
        },
    },
    blocks:{
        title: getDefaultTextCms("Titre", "Titre principal"),
        subtitle: getDefaultTextCms("Sous-titre", "La marque de sport référence des français depuis 1882 …"),
        text: getDefaultTextCms("Texte", "Une proximité émotionnelle avec les spor-tifs, champions ou amateurs passionnés, et avec leurs supporters. L’amour du sport, le challenge de ses épopées et surtout le partage collectif des émotions qu’il génère, guident la marque depuis son origine."),
        link: getDefaultLinkCms("Lien", "Nous trouver", "https://www.sinfin.fr/", null, null),
        image: {
            type: EditableTypes.IMAGE,
            name: "Image Mobile",
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/890x445"
                },
                alt: {
                    type: FormInputTypes.TEXT,
                    value: null,
                    label: "Texte alternatif"
                }
            }
        },
    }
};