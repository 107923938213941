import { gql } from 'apollo-boost';

//----- Récupération des groupes d'utilisateur
export const GET_USERGROUPS = gql`
{
  userGroups{
    edges{
      node{
        id
        code
        createdAt
        updatedAt
        menuEntry{
          edges{
            node{
              id
              parent{
                id
              }
              code
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
}
`;

//----- Récupération des groupes d'utilisateur light
export const GET_USERGROUPS_LIGHT = gql`
{
  userGroups{
    edges{
      node{
        id
        code
      }
    }
  }
}
`;

//----- Récupération des groupes d'utilisateur
export const GET_USERGROUPS_PAGINATION = gql`
query getUserGroupsPagination ($nbperpage: Int, $cursor: String, $cursorLast: String){
  userGroups (first: $nbperpage, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        code
        createdAt
        updatedAt
        menuEntry{
          edges{
            node{
              id
              parent{
                id
              }
              code
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un groupe d'utilisateur
/*
 Exemple de variables
  {
  "code": "test usergroup",
  "createdAt": "2022-03-02",
  "menuEntry": [
    "/api/menu-entries/1",
    "/api/menu-entries/2"
  ]
}
*/
export const CREATE_USERGROUP= gql`
mutation createUserGroup($code: String! , $createdAt: String!, $updatedAt: String, $menuEntry: [String]){
  createUserGroup(input: {code:$code, createdAt:$createdAt, updatedAt:$updatedAt, menuEntry: $menuEntry}){
    userGroup{
          id
          code
          createdAt
          updatedAt
      		menuEntry{
            edges{
              node{
                id
              }
            }
          }
      }
    }
  }
`;

//----- Modifier un groupe d'utilisateur
/*
 Exemple de variables
  {
  "id": "/api/user-groups/3",
  "code": "Another usergroup",
  "createdAt": "2022-01-02",
  "updatedAt": "2022-03-16",
  "menuEntry": [
    "/api/menu-entries/5",
    "/api/menu-entries/4"
  ]
}
*/
export const UPDATE_USERGROUP = gql`
mutation updateUserGroup($id: ID!, $code: String , $createdAt: String, $updatedAt: String, $menuEntry:[String]){
  updateUserGroup(input: {id:$id, code:$code, createdAt:$createdAt, updatedAt:$updatedAt, menuEntry:$menuEntry}){
    userGroup{
      id
      code
      createdAt
      updatedAt
      menuEntry{
        edges{
          node{
            id
          }
        }
      }
    }
  }
}
`;

//----- Supprimer un groupe d'utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/user-groups/4"
  }
*/
export const DELETE_USERGROUP = gql`
mutation deleteUserGroup($id: ID!){
  deleteUserGroup(input: {id:$id}){
    userGroup{
      id
    }
  }  
}`;

//----- Récupération d'un seul groupe d'utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/user-groups/3"
  }
*/
export const GET_USERGROUP = gql`
query userGroup($id: ID!){
  userGroup(id: $id){
    id
    code
    createdAt
    updatedAt
    menuEntry{
      edges{
        node{
          id
          parent{
            id
          }
          code
          createdAt
          updatedAt
        }
      }
    }	
  }
}`;
