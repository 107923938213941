import { gql } from 'apollo-boost';

//----- Récupération des projects
export const GET_PROJECTS = gql`
{
  projects{
    edges{
      node{
        id
        name
        description
        createdAt
        company{
          id
          libelle
          companyDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
        media{
          id
          filePath
          type
          size
          category {
            edges {
              node {
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_PROJECTS_CURSOR = gql`
{
  projects (first: 10000){
    edges{
      cursor
    }
  }
}
`;

export const GET_PROJECTS_PAGINATION = gql`
query projects ($nbperpage: Int, $cursor: String, $cursorLast: String, $name: String){
  projects(first: $nbperpage,after: $cursor, before: $cursorLast, name: $name){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      cursor
      node{
        id
        name
        description
        createdAt
        company{
          id
          libelle
          companyDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
        media{
          id
          filePath
          type
          size
          category {
            edges {
              node {
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}`

export const GET_PROJECTS_PAGINATION_LIGHT = gql`
query products ($nbperpage: Int, $cursor: String, $name: String){
  projects(first: $nbperpage,after: $cursor, name: $name){
    totalCount
    edges{
      cursor
      node{
        id
        name
        description
        media{
          id
          filePath
          type
          size
          category {
            edges {
              node {
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}`

export const SEARCH_PROJECTS = gql`
query projectsSearch ($name: String) {
  projects(first: 1000, name: $name){
    edges{
      node{
        id
        name
        description
        createdAt
        company{
          id
          libelle
          companyDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
        media{
          id
          filePath
          type
          size
          category {
            edges {
              node {
                id
                libelle
              }
            }
          }
        }
      }
    }
  }
}`;

//----- Ajouter un projet
/*
 Exemple de variables
  {
    "name": "Projet de test",
    "description": "Définition du projet",
    "media": "/api/media-objects/3",
    "company": "/api/companies/1",
    "createdAt": "2020-12-29 15:02:00"
  }
*/
export const ADD_PROJECT = gql`
mutation AddProject($name: String!, $description: String , $media: String , $company: String , $createdAt: String ){
	createProject(input: {name: $name, description: $description, media: $media, company: $company, createdAt: $createdAt}){
    project{
      id
      name
      description
      createdAt
      company{
        id
        libelle
      }
      media{
        id
        filePath
        type
      }
    }
  }  
}`;

//----- Modifier un projet
/*
 Exemple de variables
  {
    "id": "/api/projects/1",
    "name": "Projet de test edited",
    "description": "Définition du projet",
    "media": "/api/media-objects/3",
    "company": "/api/companies/1",
    "createdAt": "2020-12-29 15:02:00"
  }
*/
export const UPDATE_PROJECT = gql`
mutation UpdateProject($id: ID!, $name: String!, $description: String , $media: String , $company: String , $createdAt: String ){
	updateProject(input: {id: $id, name: $name, description: $description, media: $media, company: $company, createdAt: $createdAt}){
    project{
      id
      name
      description
      createdAt
      company{
        id
        libelle
      }
      media{
        id
        filePath
        type
      }
    }
  }  
}`;

//----- Supprimer un projet
/*
 Exemple de variables
  {
    "id" : "/api/projects/1"
  }
*/
export const DELETE_PROJECT = gql`
mutation DeleteProject($id: ID!){
	deleteProject(input: {id:$id}){
    project{
      id
    }
  }  
}`;
