import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultListCategories } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Liste catégories',
    inputs: {
        identifiant:{
            label: 'Identifiant',
            type: FormInputTypes.TEXT,
            value: null,
            id: uuidv4(),
        },
        value: {
            type: FormInputTypes.TEXT,
            label: "Changer le texte",
            value: "Nos Athlètes"
        },
    },
    blocks:{
        categories:{
            title: "Catégories",
            name: "Catégorie",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.CMS_LIST_CATEGORIES,
            buttonAdd: 'une catégorie',
            limit: 100,
            config: [ 
                getDefaultListCategories(),
                getDefaultListCategories(),
                getDefaultListCategories(),
                getDefaultListCategories(),
            ]
        }
    }
};