import React from 'react';
import { Grid, Tooltip, Checkbox, TextField, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardCustom from '../../../../layouts/Card/CardCustom';
import CardProduct from '../../../../layouts/Card/cardContent/CardProduct';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import styled from 'styled-components';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_ADD } from '../../../../../js/constants/route-names';
import colors from '../../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { GET_PRODUCTS_SEARCH_PAGINATION_LIGHT, GET_PRODUCTS_SEARCH_PAGINATION_NO_VARIANTS } from '../../../../../queries/products';
import LayoutBuilder from '../../../../ui/form/LayoutFormBuilder';
import exportProductsConfig from '../config/exportProducts.config';
import EmptyProduct from '../../../../../assets/pictos/empty-picto/empty_products.png';
import EmptyCard from "../../../../ui/empty-card/EmptyCard";
import PageLoader from "../../../../ui/loadings/page-loader/PageLoader";
import request from '../../../../../js/utils/fetch';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../../js/constants/alert-types';
import { eventService } from '../../../../../js/services/event.service';
import TablePagination from '@material-ui/core/TablePagination';

import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import CardProductList from '../../../../layouts/Card/cardContent/CardProductList';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '../../../../ui/typography/Typography';
import '../../../../navigation/DrawerLeft.scss';
import { GET_CATEGORIES_LIGHT_2 } from '../../../../../queries/categories';
import MasterSearch from '../../../../ui/search/MasterSearch';
import Button from '../../../../ui/button/Button';
import { UPDATE_PRODUCT_CATEGORY } from '../../../../../queries/product_position';
import { withTranslation } from 'react-i18next'
import TraductionSelect from '../../../../layouts/TopPanel/TraductionSelect';
import { GetApp } from '@material-ui/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';
import CardBundle from '../../../../layouts/Card/cardContent/CardBundle';


const TextFieldCustom = styled(TextField)`
    padding:0;
    fieldset{
        border-color: ${colors.grey.lighter.hue700};
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding:12px;
        border: 1px solid ${colors.grey.border};
        @media screen and (max-width: 1450px){
            font-size: 14px;
            ${props => props.customstyle};
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;            
        }
    }
    & > p{
        margin: 0;
    }
`;

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;
const ViewModuleIconCustom = styled(ViewModuleIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;
const ViewListIconCustom = styled(ViewListIcon)`
    fill: ${colors.grey.regular};
    cursor: pointer;
    &.active{
        fill: ${colors.blue.darker.hue300};
        cursor: inherit;
    }
`;

const TableCellCustom = styled(TableCell)`
    border: none;
    // display: inline-block;
    // width: calc(100% / 9);
    padding: 3px 16px 14px 16px;
    background-color: ${props => !props.minimalForm ? colors.grey.lighter.hue980 : 'white'};
    color: ${colors.grey.lighter.hue600};
`;
const TablePaginationStyle = styled(TablePagination)`
    .MuiToolbar-root{
        padding: 0;
    }
    .MuiTablePagination-spacer{
        display: none;
    }
`;

const CheckboxCustom = styled(Checkbox)`
    justify-content: flex-start;
    position: absolute;
    background: white;
    padding: 2px;
    margin-top: 10px;
    z-index: 10;
    border-radius: 0;
    svg{
        fill: ${colors.blue.darker.hue300}
    }
    &:hover{
        background: white!important;
        svg{
            fill: ${colors.blue.lighter.hue150}
        }
    }
    input{
        width: 40px;
    }
`;

const CheckboxAll = styled(Checkbox)`
    background: transparent;
    z-index: 10;
    padding: 0;
    margin: 0;
`

const styles = theme => ({
});

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.blue.darker.hue300,
    },
    tooltip: {
        backgroundColor: colors.blue.darker.hue300,
        fontSize: 14,
    },
}));

const ListingWrapper = styled(Box)`
    overflow: auto;

    & > div {
        display: grid; 
        grid-template-rows: 1fr auto;
        height: 100%;
    }
`;

const TableHeadCustom = styled(TableHead)`
    background: ${props => props.minimalForm ? colors.white : colors.grey.lighter.hue100};
`;

const TableRowCustom = styled(TableRow)`
    background-color: transparent;
`;

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class ListingProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFormExport: false,
            catalogFormData: {},
            ready: false,
            page: 0,
            nbperpage: props.nbperpage,
            countPage: 0,
            categories: [],
            skulist: props.idlist ? props.idlist : [],
            searchValue: '',
            searchValueSku: [],
            arrayCatFilter: [],
            viewMode: props.defaultViewMode?props.defaultViewMode: 'card',
            exportName: '',
            exportLang: props.locales[0].node.code,
            exportType: 'csv',
            exportStatus: 'all',
            exportImage: 'all',
            errors: {},
            searchStatus: 'all',
            attributesInternalFilter: [],
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: props.activeCategorie ? [props.activeCategorie] : [],
            catalogId: props.catalogId || null,
            readyFilters: false,
            listCollections: []
        }
        this.errors = {}
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchSku = this.handleSearchSku.bind(this);
        // this.typingSearchTimer = null;
    }

    componentDidMount() {
        this.resetFilters();
        if (this.props.listCategories?.length > 0) {
            this.setState({
                categories: this.props.listCategories
            })
        }
        this.getAll();


        request(`${process.env.REACT_APP_API}/products-count/media`, 'get', false).then(
            (data) => {
                if (data.success) {
                    this.setState({
                        nbImageTotal: data.details
                    });
                }
            }
        );
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.reload !== this.props.reload && this.props.reload) {
            this.props.onReload();
            this.resetFilters();
        }

        if (prevProps.listCategories != this.props.listCategories) {
            if (this.props.listCategories?.length > 0) {
                this.setState({
                    categories: this.props.listCategories
                })
            }
        }

        if (prevProps.currentLang != this.props.currentLang) {
            this.resetFilters();
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.resetFilters();
        }
    }

    async getAll() {
        await this.handleFilters();
        await this.handleGetProducts();
        if (!this.props.listCategories) {
            await this.handleGetCategories();
        }
        this.setState({
            ready: true,
        })
    }

    handleFilters() {
        return new Promise((resolve, reject) => {
            let getFilters = this.props.previousFilters?.filters;
            let getPagination = this.props.previousFilters?.pagination;
            if (getFilters?.searchValue && getFilters.searchValue !== "") {
                this.setState({ searchValue: getFilters.searchValue });
            }
            if (getFilters?.listCategories && getFilters.listCategories.length > 0) {
                this.setState({ listCategories: getFilters.listCategories });
            }
            if (getFilters?.customSearchAttributes) {
                this.setState({ customSearchAttributes: getFilters.customSearchAttributes });
            }
            if (getFilters?.searchValueSku && getFilters.searchValueSku !== "") {
                this.setState({ searchValueSku: getFilters.searchValueSku });
            }
            if (getFilters?.categoriesSelected && getFilters.categoriesSelected !== "") {
                this.setState({ categoriesSelected: getFilters.categoriesSelected });
            }
            if (getFilters?.searchStatus && getFilters.searchStatus !== "") {
                this.setState({ searchStatus: getFilters.searchStatus });
            }
            if (getFilters?.arrayCatFilter && getFilters.arrayCatFilter.length > 0) {
                this.setState({ arrayCatFilter: getFilters.arrayCatFilter });
            }
            if (getPagination?.currentPage) {
                this.setState({ page: getPagination.currentPage });
            }
            if (getPagination?.perPage) {
                this.setState({ nbperpage: getPagination.perPage });
            }
            resolve();
        });
    }

    handleSearchStatus(e) {
        this.setState({
            searchStatus: e.target.value
        });
    }

    handleFormatSkus = (string)=>{
        let delimiter = string.indexOf(' ') > -1 ? ' ' : string.indexOf(',')> -1 ? ',':string.indexOf('|')> -1 ? '|':string.indexOf(';')> -1 ? ';':null;
        let array = delimiter? string.split(delimiter): [string];
        let newArray = [];
        array.forEach(element => {
            newArray.push(element.trim())
        });
        if (newArray.length === 1){
            return newArray[0]
        }
        if (newArray.length === 1 && newArray[0] === ''){
            return null
        }
        return newArray;
    }

    handleSearchSku(e) {
        let array = this.handleFormatSkus(e.target.value)
        this.setState({
            searchValueSku: array
        });
    }

    handleChange(e, value) {
        this.setState({ searchValue: e.target.value });
    }

    resetFilters = () => {
        this.setState({
            ready: false,
            page: 0,
            searchValue: '',
            searchValueSku: '',
            searchStatus: 'all',
            customSearchAttributes: {
                "product_image": 'all'
            },
            categoriesSelected: this.props.activeCategorie ? [this.props.activeCategorie] : [],
        }, () => this.handleGetProducts())
    }

    validateFilters = () => {
        this.setState({
            ready: false,
            page: 0,
        }, () => this.handleGetProducts())
    }

    handleToggleDrawer = (stateDrawer) => {
        if (stateDrawer === "openFormExport") {
            this.setState({
                [stateDrawer]: !this.state[stateDrawer],
                exportName: '',
                exportLang: this.props.currentLang,
                exportType: 'csv',
                exportStatus: 'all',
                exportImage: 'all',
            })
        }
        this.setState({
            [stateDrawer]: !this.state[stateDrawer]
        });
    };

    handlerMutationExport = async () => {
        this.props.startLoading();
        if (this.hasErrors()) {
            this.props.stopLoading();
            this.props.snack(ALERT_ERROR, 'Veuillez vérifier les champs invalides');
            return eventService.fire();
        }
        try {
            let data = new FormData();
            data.append('name', this.state.exportName);
            data.append('locale', this.state.exportLang);
            data.append('format', this.state.exportType);
            data.append('status', this.state.exportStatus);
            data.append('image', this.state.exportImage);

            let urlencoded = new URLSearchParams(data).toString();

            request(`${process.env.REACT_APP_API}/export/excel/products?${urlencoded}`, 'get', null, 'application/x-www-form-urlencoded')
                .then(async (data) => {
                    if (data.success) {
                        window.open(`${process.env.REACT_APP_API_ROOT}/medias/export/${data.mediaObject.filePath + '.' + (this.state.exportType === 'xls' ? this.state.exportType + 'x' : this.state.exportType)}`, '_blank');
                        this.props.stopLoading();
                        this.props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        this.props.stopLoading();
                        this.props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            this.props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            this.props.stopLoading();
        }
        this.handleToggleDrawer('openFormExport')
    };

    handleGetProducts() {
        return new Promise((resolve, reject) => {
            const getProducts = this.props.isBundle ?GET_PRODUCTS_SEARCH_PAGINATION_LIGHT: GET_PRODUCTS_SEARCH_PAGINATION_NO_VARIANTS;
            
            let currentLocale = this.props.locales.find(e => e.node.code === this.props.currentLang);
            let currentLocaleId = currentLocale.node.id;

            let productsDataFilters = [];
            let variables =
            {
                "itemsPerPage": this.state.nbperpage,
                "page": this.state.page + 1,
                "localeId": currentLocaleId,
                "scrapStatus":this.props?.variables?.scrapStatus,
                "productSources":this.props?.variables?.productSources,
                "fileImports":this.props?.variables?.fileImports
            };

            if (this.props?.isBundle || this.props?.isBundle === false) {
                variables.productBundleOptions = this.props?.isBundle
            }
            if (this.state.searchValueSku) {
                if (typeof this.state.searchValueSku === "object") {
                    let value = this.state.searchValueSku.filter(e => e !== '')
                    if (value.length === 1) {
                        variables.sku = value[0]
                        this.setState({
                            searchValueSku: value[0]
                        })    
                    }else{
                        variables.skus = value
                        this.setState({
                            searchValueSku: value
                        })    
                    }
                }
                if (typeof this.state.searchValueSku === "string" || !this.state.searchValueSku) {
                    variables.sku = this.state.searchValueSku    
                }
            }
            if (this.state.catalogId) {
                variables.catalog = this.state.catalogId
            }
            if (this.state.searchValue !== '' && this.state.searchValue) {
                productsDataFilters.push(JSON.stringify({
                    'identifier': 'product_name',
                    'value': this.state.searchValue,
                    'locale': this.props.currentLang,
                    'option': null
                }))
            }

            if (this.state.searchStatus && this.state.searchStatus !== 'all') {
                if (this.state.searchStatus === 'active') {
                    variables.status = true
                } else {
                    variables.status = false
                }
            }

            if (this.state.customSearchAttributes) {
                for (const [key, value] of Object.entries(this.state.customSearchAttributes)) {
                    if (value !== '') {
                        let option = null
                        let newValue = null
                        if (key !== 'product_image') {
                            option = value?.includes('/api/attribute-options')
                        } else {
                            if (value === "active") {
                                newValue = true
                            } else if (value === "inactive") {
                                newValue = false
                            }
                        }
                        if (value !== 'all') {
                            productsDataFilters.push(JSON.stringify({
                                'identifier': key,
                                'value': option ? null : newValue !== null ? newValue : value,
                                'locale': option ? null : this.props.currentLang,
                                'option': option ? value.replace('/api/attribute-options/', '') : null
                            }))
                        }
                    }
                }
            }
            if (this.props.variables) {
                for (const [key, value] of Object.entries(this.props.variables)) {
                    if (key === "productDatas"){
                        if (productsDataFilters.length > 0){
                            value.map(e => {
                                productsDataFilters.push(e)
                            })
                        }else{
                            productsDataFilters = value
                        }
                    }
                }
            }

            if (productsDataFilters.length > 0) {
                variables.productDatas = productsDataFilters;
            }

            if (this.state.categoriesSelected.length > 0 || this.props.activeCategorie) {
                if (this.props.activeCategorie) {
                    variables.categories = this.state.categoriesSelected.concat([this.props.activeCategorie]).map(e => parseInt(e.id.replace('/api/categories/', '')))
                } else {
                    variables.categories = this.state.categoriesSelected.map(e => parseInt(e.id.replace('/api/categories/', '')))
                }
            }

            if (this.props.callbackFilters) {
                let getFilters = {
                    sku: this.state.searchValueSku,
                    searchValue: this.state.searchValue,
                    customSearchAttributes: this.state.customSearchAttributes,
                    categoriesSelected: this.state.categoriesSelected,
                    searchStatus: this.state.searchStatus,
                    searchValueSku: this.state.searchValueSku,
                }
                let getPagination = {
                    perPage: this.state.nbperpage,
                    currentPage: this.state.page
                }
                this.props.callbackFilters(getFilters, getPagination)
            }
            this.props.client.query({
                query: getProducts,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.listProducts = result.data.researchProducts.edges;
                this.setState({
                    countPage: result.data.researchProducts.totalCount
                })
                let dataSku = new FormData();
                let list_sku = this.listProducts.map(e => e.node.sku)
                list_sku.forEach(element => {
                    dataSku.append('sku_list[]', element)
                });

                if (this.props.totalCountCallback)
                    this.props.totalCountCallback(result?.data?.researchProducts?.totalCount ?? 0);

                //----disable it - now available with GET_PRODUCTS_SEARCH_PAGINATION > flatCompletude :D
                // axios(`${process.env.REACT_APP_API}/completude`, 'post',dataSku).then(
                //     (data) => {
                //         if(data.success){
                //             for( var i = 0; i < this.listProducts.length; i++){
                //                 let productSku = this.listProducts[i].node.sku;
                //                 let infoStats = data.products[productSku];
                //                 this.listProducts[i].node.stats = {
                //                     infoStats
                //                 };
                //             }
                //             this.forceUpdate();
                //         }
                //     }

                // );

                //----disable it - now available with productCatalogs > total Count :D
                // request(`${process.env.REACT_APP_API}/products-count/catalog`, 'get', false).then(
                //     (data) => {
                //         if(data.success){
                //             this.setState({
                //                 nbCatalog: data
                //             });
                //         }
                //     }
                // );    

                if (process.env.REACT_APP_MODE_SPREAD === "hub") {
                    request(`${process.env.REACT_APP_API}/stockCompletude`, 'get', false).then(
                        (data) => {
                            if (data.success) {
                                for (var i = 0; i < this.listProducts.length; i++) {
                                    let productSku = this.listProducts[i].node.sku;
                                    let infoStocks = data.products[productSku].stock;
                                    let allInfoStocks = data.products[productSku];
                                    this.listProducts[i].node.stocks = {
                                        infoStocks,
                                        allInfoStocks,
                                    };
                                }
                                this.forceUpdate();
                            }
                        }

                    );
                }
                if (process.env.REACT_APP_MODE_SPREAD === "hub") {
                    request(`${process.env.REACT_APP_API}/stockCompletudeByWarehouse/${this.state.catalogId.replace('/api/catalogs/', '')} `, 'post', false).then(
                        (data) => {
                            if (data.success) {
                                for (var i = 0; i < this.listProducts.length; i++) {
                                    let productSku = this.listProducts[i].node.sku;
                                    let infoStocks = data.products[productSku].stock;
                                    this.listProducts[i].node.stocks = {
                                        infoStocks
                                    };
                                }
                                this.forceUpdate();
                            }
                        }

                    );
                }
                if (this.listProducts.length === 0) {
                    this.setState({ noResult: true });
                }
                else {
                    this.setState({ noResult: false });
                }
                this.setState({
                    ready: true,
                    readyFilters: true,
                    listProducts: this.listProducts
                });
                if (this.props.listingCallback) {
                    this.props.listingCallback(this.listProducts)
                }
                this.props.stopLoading();
                resolve();
            });
        });
    }

    processChildren = async (categories, parent, allIdentifiers) => {
        parent.children = categories.filter(e => e.parent?.id === parent?.id);
        for (let child of parent.children) {
            await this.processChildren(categories, child);
        }
    };

    handleGetCategories() {
        return new Promise((resolve, reject) => {
            let variables = {
                "exists": [{ "catalog": this.props.isCatalog??false  }],
                "status": this.state.searchStatus && this.state.searchStatus !== 'all' ? this.state.searchStatus === 'active' ? true : false : null
            }
            if (this.state.catalogId) {
                variables.catalog = `/api/catalogs/${this.state.catalogId}`
            }
            this.props.client.query({
                query: GET_CATEGORIES_LIGHT_2,
                variables,
                fetchPolicy: "no-cache"
            }).then((result) => {

                let allCategories = result.data.categories;
                let root = Array.isArray(allCategories) ? allCategories?.find(e => e.parent === null) : null;
                if (!this.props.isCatalog) {
                    allCategories = allCategories.filter(e => e.catalog === null)
                }
                if (allCategories.length > 0) {
                    this.processChildren(allCategories, root);
                }
                this.setState({
                    categories: allCategories,
                    activeCategorie: root,
                    ready: true,
                    root,
                })
            })
            resolve()
        });
    }


    handleChangePositionMutation = (data, idProduct) => {
        this.props.startLoading()
        let variables = {
            "id": data.id,
            "category": data.category.id,
            "product": idProduct,
            "position": data.position ? data.position !== '' ? data.position : null : null,
        };
        this.props.client.mutate({
            mutation: UPDATE_PRODUCT_CATEGORY,
            variables,
        }).then(result => {
            this.props.snack(ALERT_SUCCESS, data.selection && data.position ? 'Changement de la position et de la collection bien effectué' : data.selection && !data.position ? 'Changement de la collection bien effectué' : 'Changement de la position bien effectué');
            this.props.stopLoading();
        });
    }

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (custom === true) {
            let customSearch = this.state.customSearchAttributes;
            if (!customSearch) {
                customSearch = {};
            }
            if (!value) {
                delete customSearch[stateName];    
            }else{
                customSearch[stateName] = value?.target?.value ?? value;
            }
            this.setState({
                customSearchAttributes: customSearch
            }, callback);
        } else {
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
    };

    changePage = (event, page) => {
        this.props.startLoading();
        this.setState({
            page: page
        }, () => {
            this.handleGetProducts();
        });
    };

    handleChangePerPage = (event) => {
        this.setState({
            nbperpage: event.target.value
        }, this.changeViewMode(this.state.viewMode))
    }

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;

        errors[stateName] = error;

        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    changeViewMode = (mode) => {
        this.props.startLoading();
        if (mode === "list") {
            this.setState({
                nbperpage: !this.props.productBypage['list'].includes(this.state.nbperpage) ? this.props.productBypage['list'][0] : this.state.nbperpage,
                viewMode: 'list',
                page: 0,
            }, () => this.handleGetProducts())
        } else {
            this.setState({
                nbperpage: this.props.nbperpage,
                viewMode: 'card',
                page: 0,
            }, () => this.handleGetProducts())
        }
    }

    handleBlankGoTo = (e,productId) => {
        e.stopPropagation();
        window.open(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', productId.replace('/api/products/', '')))
    }

    changePosition = (stateName, value, idProduct) => {
        //todo
        let getProducts = this.state.listProducts
        let productData = getProducts.find(e => e.node.id === idProduct)
        let getData = productData.node.productCategories.edges.find(e => e.node.category.id === this.props.activeCategorie.id)
        if (stateName === "position") {
            getData.node.position = value?.target?.value ?? value
        } else {
            if (getData.node.selection) {
                getData.node.selection.id = value?.target?.value ?? value
            } else {
                getData.node.selection = { id: value?.target?.value ?? value }
            }
        }
        this.setState({
            listProducts: getProducts
        });
    };
    _renderListing = (cardSize, productBypage, headCells,noSearch,isBundle) => { 
        return (
            <>
                <Box width={"100%"} style={{
                    position: "relative",
                }}>
                    <Grid container spacing={this.state.viewMode === "card"? 2: 0} direction="row" style={{ marginTop: this.state.viewMode === "list" ? 8 : 0, marginBottom: this.state.viewMode === "list" ? 16 : 0 }}>
                        {
                            this.state.viewMode === "list"&&this.state.listProducts && this.state.listProducts.length > 0 && !this.state.noResult && this.state.ready?
                                <GridCustom item xs={12} key={`HeaderList`} style={{
                                    padding:this.props.inForm?"0": this.props.handleToggle ? '20px 0px 3px' : '8px',
                                    zIndex: 12,
                                }}>
                                    <CardCustom noborder={true} style={{ width: "100%", height: "100%", padding: "0", borderBottom: `0.5px solid ${colors.grey.border}`,borderTop:this.props.inForm ? `0.5px solid ${colors.grey.border}`:"none" }} cardContentStyle={{ height: "100%" }} contentpadding={'0'} hovercard={false} collapse={true}>
                                        <TableContainer elevation={0} component={Paper} style={{ maxHeight: this.props.inForm ? "none" : 700 }} >
                                            <Table aria-label="headerList" style={{
                                            borderCollapse:'unset'
                                        }}>
                                                {
                                                    noSearch?
                                                        <TableHeadCustom minimalForm={this.props.minimalForm}>
                                                            <TableRowCustom>
                                                                {headCells.filter(e => e !== null).map((headCell) => (
                                                                    <TableCellCustom
                                                                        minimalForm={this.props.minimalForm}
                                                                        key={headCell.id}
                                                                        align={headCell.align ? headCell.align : 'left'}
                                                                        style={{ width: `${headCell.style?.width}`, zIndex: 5, borderBottom: `1px solid ${colors.grey.border}` }}
                                                                    >
                                                                        {headCell.label}
                                                                    </TableCellCustom>
                                                                ))}
                                                            </TableRowCustom>
                                                        </TableHeadCustom>
                                                        :null
                                                }
                                                <TableBody>
                                                    {
                                                        this.state.listProducts.map((product, index) => {
                                                            //let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                                                            let productCategory = null;
                                                            // if (this.props.minimalForm) {
                                                            //     productCategory = product.node.productCategories?.edges?.find(e => e.node.category.id === this.props.activeCategorie.id)
                                                            // }
                                                            return (
                                                                <CardProductList
                                                                    currentLang={this.props.currentLang}
                                                                    nbCatalog={this.state.nbCatalog?.products[product.node.sku] ?? 0}
                                                                    nbImageTotal="0"
                                                                    product={product}
                                                                    index={index}
                                                                    countProducts={this.state.listProducts.length}
                                                                    routeProduct={(e) => isBundle?this.props.cardProps.buttonCallback(product.node) : this.props.inForm ? this.handleBlankGoTo(e,product.node.id) : this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', '')))}
                                                                    windowWidth={this.props.windowWidth}
                                                                    inForm={this.props.inForm}
                                                                    minimalForm={this.props.minimalForm}
                                                                    changePosition={this.changePosition}
                                                                    productCategory={productCategory}
                                                                    reverseCheck={this.props.reverseCheck}
                                                                    selectedProducts={this.props.selectedProducts}
                                                                    handleToggle={this.props.handleToggle}
                                                                    handleChangePositionMutation={this.handleChangePositionMutation}
                                                                    listCollections={this.state.listCollections}
                                                                    noSearch={noSearch}
                                                                    textButton={isBundle?'Modifier':'Voir le produit'}
                                                                    isBundle={isBundle}
                                                                    {...this.props.cardProps}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardCustom>
                                </GridCustom>
                                : null
                        }
                        {
                            !this.state.ready ? (
                                <Box style={{
                                    width: '100%',
                                }}>
                                    <PageLoader />
                                </Box>
                            ) : (this.state.listProducts && this.state.listProducts.length > 0 && !this.state.noResult) ? (
                                this.state.listProducts.map((product, index) => {
                                    //let truncAttrGroupId = product.node.attributeGroup.id.replace('/api/attribute-groups/', '');
                                    const labelId = `checkbox-list-secondary-label-${product}`;
                                    let productCategory = null;
                                    // if (this.props.minimalForm) {
                                    //     productCategory = product.node.productCategories?.edges?.find(e => e.node.category.id === this.props.activeCategorie.id)
                                    // }
                                    if (this.state.viewMode === "card") {
                                        return (
                                            <GridCustom item lg={cardSize?.['lg'] ? cardSize?.['lg'] : 4} md={cardSize?.['md'] ? cardSize?.['md'] : 6} sm={cardSize?.['sm'] ? cardSize?.['sm'] : 6} xs={cardSize?.['xs'] ? cardSize?.['xs'] : 12} key={`ListProduct${index}`} onClick={this.props.handleToggle ? () => this.props.handleToggle(product) : null}>
                                                {
                                                    isBundle?
                                                    <CardCustom noborder={isBundle} cardContentStyle={{ height: "100%", width: "100%", boxSizing: "border-box", paddingBottom: 16 }} hovercard={this.props.hoverCard&&!isBundle ? this.props.hoverCard : true} style={{ width: "100%", height: "100%", cursor: this.props.inForm &&!isBundle? 'pointer' : 'initial' }}
                                                        onClick={() => this.props.inForm && !this.props.handleToggle && !isBundle ? this.handleBlankGoTo(product.node.id) : null}
                                                    >
                                                        {
                                                            this.props.inForm ?
                                                                <CheckboxCustom
                                                                    edge="start"
                                                                    checked={this.props.reverseCheck ? this.props.selectedProducts?.indexOf(product.node.id) !== -1 ? false : true : this.props.selectedProducts?.indexOf(product.node.id) === -1 ? false : true}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                                : null
                                                        }
                                                            <CardBundle
                                                                currentLang={this.props.currentLang}
                                                                product={product.node}
                                                                locale={this.props.locales.find(e => e.node.code === this.props.currentLang)}
                                                                index={index}
                                                                windowWidth={this.props.windowWidth}
                                                                textButton={'Modifier'}
                                                                {...this.props.cardProps}
                                                            />
                                                         
                                                    </CardCustom>
                                                    :<Link 
                                                    onClick={this.props.inForm || this.props.handleToggle ? (event) => event.preventDefault() : null} 
                                                    style={{ width: "100%", textDecoration: 'none' }}
                                                    // to={ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', ''))} 
                                                    to={{
                                                        pathname:isBundle?null: ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', '')),
                                                        state:isBundle?null:this.props.navButton? { productList:this.state.listProducts}:null,
                                                      }}
                                                >
                                                    <CardCustom noborder={isBundle} cardContentStyle={{ height: "100%", width: "100%", boxSizing: "border-box", paddingBottom: 16, cursor: 'pointer' }} hovercard={this.props.hoverCard&&!isBundle ? this.props.hoverCard : true} style={{ width: "100%", height: "100%", cursor: this.props.inForm &&!isBundle? 'pointer' : 'initial' }}
                                                        onClick={() => this.props.inForm && !this.props.handleToggle && !isBundle ? this.handleBlankGoTo(product.node.id) : null}
                                                    >
                                                        {
                                                            this.props.inForm ?
                                                                <CheckboxCustom
                                                                    edge="start"
                                                                    checked={this.props.reverseCheck ? this.props.selectedProducts?.indexOf(product.node.id) !== -1 ? false : true : this.props.selectedProducts?.indexOf(product.node.id) === -1 ? false : true}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                                : null
                                                        }
                                                        <CardProduct
                                                            currentLang={this.props.currentLang}
                                                            product={product.node}
                                                            locale={this.props.locales.find(e => e.node.code === this.props.currentLang)}
                                                            index={index}
                                                            routeProduct={() => this.props.inForm ? this.props.handleToggle ? null : this.handleBlankGoTo(product.node.id) : this.goTo(ROUTE_PRODUCTS_LIST_DETAIL.replace(':id', product.node.id.replace('/api/products/', '')),null,this.props.navButton ? this.state.listProducts :null)}
                                                            windowWidth={this.props.windowWidth}
                                                            inForm={this.props.inForm}
                                                        />
                                                    </CardCustom>
                                                </Link>
                                                }
                                                {/* {
                                                    this.props.minimalForm ?
                                                        <Grid spacing={1} container alignItems="center" style={{marginTop: 8}}>
                                                            <Grid item xs={3}>
                                                                <TextFieldCustom
                                                                    id={`Position-${index}`}
                                                                    variant="outlined"
                                                                    value={productCategory.node.position || ''}
                                                                    fullWidth= {true}
                                                                    onChange={evt => {
                                                                        this.changePosition('position', evt, product.node.id);
                                                                    }}
                                                                    name={`Position-${index}`}
                                                                    type={'text'}
                                                                    placeholder={'Pos'}
                                                                    style={{width: '100%'}}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} onClick={(e) => {e.stopPropagation()}}>
                                                                <Grid container justifyContent="center">
                                                                    <Button style={{minWidth: "100%"}} onClick={() => this.handleChangePositionMutation(productCategory.node, product.node.id)}>
                                                                        <DoneIcon style={{fill: colors.white}}/>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    : null
                                                } */}
                                            </GridCustom>
                                        )
                                    }
                                })
                            )
                                : (this.state.noResult) ?
                                    (<EmptyCard title={this.props.t('products.list.filter.emptyCard.noResult')} textButton={this.props.t('products.list.filter.emptyCard.addProduct')} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.props.openForm} xsImg={this.props.openForm ? 4 : 2} mtImg={2} />)
                                    :
                                    (<EmptyCard title={this.props.t('products.list.filter.emptyCard.noSetup')} subtitle={this.props.t('products.list.filter.emptyCard.addProductByClicking')} textButton={this.props.t('products.list.filter.emptyCard.addProduct')} onClick={() => this.goTo(ROUTE_PRODUCTS_ADD)} picto={EmptyProduct} openForm={this.props.openForm} xsImg={this.props.openForm ? 4 : 2} />)
                        }
                    </Grid>
                </Box>
                {
                    this.state.ready && !this.state.noResult ?
                        (
                            <TablePaginationStyle
                                count={this.state.countPage}
                                page={this.state.page}
                                onChangePage={(event, page) => { this.changePage(event, page) }}
                                color="primary"
                                component="div"
                                rowsPerPage={this.state.nbperpage}
                                rowsPerPageOptions={productBypage}
                                labelDisplayedRows={(from = this.state.pagePresentations) => (`${from.from}-${from.to === -1 ? from.count : from.to} sur ${from.count}`)}
                                onChangeRowsPerPage={this.handleChangePerPage}
                                labelRowsPerPage={this.props.t('products.list.bypage')}
                            />
                        ) : null
                }
            </>
        )
    };

    render() {
        let productBypage = [];
        productBypage = this.props.productBypage[this.state.viewMode]
        const { classes, cardSize } = this.props;
        let checkSelectAll = this.props.reverseCheck ? false : true;
        let checker = (arr, target) => target.every(v => arr.includes(v));
        if (this.state.listProducts && this.state.listProducts?.length > 0 && this.props.selectedProducts?.length > 0) {
            let productsIdArray = this.state.listProducts.map(e => e.node.id)
            if (checker(this.props.selectedProducts, productsIdArray)) {
                checkSelectAll = false
            }
        }

        let headCells = [
            (this.props.handleSelectAll ?
                {
                    id: 'check', align: "center", style: null, disablePadding: true, label: <CheckboxAll
                        edge="start"
                        checked={checkSelectAll ? true : false}
                        indeterminate={checkSelectAll ? false : this.props.selectedProducts?.length > 0 ? true : false}
                        onClick={() => { this.props.handleSelectAll(this.state.listProducts, checkSelectAll) }}
                    />
                }
                : null),
            // { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
            { id: 'status', align: "center", style: { width: 'calc(100%/12)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.status') },
            { id: 'sku', align: "center", style: { width: 'calc(100%/12)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.article') },
            { id: 'nom', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : { width: 'calc(100%/3.5)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.name') },
            { id: 'variant', align: "center", style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : { width: 'calc(100%/12)' }, disablePadding: false, label: 'Variantes' },
            { id: 'categories', style: process.env.REACT_APP_MODE_SPREAD !== "hub" ? null : { width: 'calc(100%/12)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.categorie') + '(s)' },
            { id: 'nbCatalogs', align: "center", style: { width: 'calc(100%/12)' }, disablePadding: false, label: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t('products.list.cardproduct.catalogs') + '(s)' : 'Présence dans les entrepôts' },
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                { id: 'nbImages', align: "center", style: { width: 'calc(100%/9)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.nbimage') } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                { id: 'completion', style: { width: 'calc(100%/9) / 1.25' }, disablePadding: false, label: this.props.t('products.list.cardproduct.completeness') } : null,
            process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                { id: 'button', style: { width: 'calc(100%/7)' }, disablePadding: false, label: '' } : null,
        ];
        if (this.props.minimalForm) {
            headCells = [
                { id: 'checkboxes', align: "center", style: null, disablePadding: true, label: '' },
                // { id: 'image', align: "center", style: null, disablePadding: true, label: 'Image' },
                { id: 'status', align: "center", style: null, disablePadding: false, label: 'Statut' },
                { id: 'sku', style: null, disablePadding: false, label: 'Code article' },
                { id: 'nom', style: null, disablePadding: false, label: 'Nom' },
                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                    { id: 'nbImages', align: "center", style: { width: 'calc(100%/9)' }, disablePadding: false, label: this.props.t('products.list.cardproduct.nbimage') } : null,
                { id: 'button', style: null, disablePadding: false, label: '' },
            ]
        }
        if (this.props.isBundle) {
            headCells = [
                { id: 'status', align: "center", style: null, disablePadding: false, label: 'Statut' },
                { id: 'sku', style: null, disablePadding: false, label: 'SKU' },
                { id: 'nom', style: null, disablePadding: false, label: 'Nom' },
                { id: 'products', style: null, disablePadding: false, label: 'Produits' },
                { id: 'attributs', style: null, disablePadding: false, label: 'Attributs' },
                { id: 'catalog', style: null, disablePadding: false, label: 'Catalogues' },
                { id: 'categories', style: null, disablePadding: false, label: 'Catégories' },
                { id: 'button', style: null, disablePadding: false, label: '' },
            ]
        }

        return (
            <Grid container style={{ display: '100%', display: 'grid', gridTemplateRows: 'auto 1fr auto', gridTemplateColumns: '100%' }}>
                <Grid container direction="row" alignItems="center" style={{ marginBottom: 12 }}>
                    <Grid container>
                        {
                            this.state.readyFilters ?
                                 !this.props.noSearch ?
                                    <AccordionCustom isFilter={true} defaultExpanded={false} title={this.props.t('products.list.filter.filterSearch')}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <MasterSearch
                                                currentStatus={this.state.searchStatus}
                                                searchStatusHandler={this.handleSearchStatus}
                                                handleNameDesc={this.handleChange}
                                                handleSearchSku={this.handleSearchSku}
                                                validateSearch={this.validateFilters}
                                                resetFilters={this.resetFilters}
                                                categories={this.state.categories}
                                                attributesInternalFilter={this.props.attributes.product.attributes.edges.filter(e => e.node.internalFilter)}
                                                stateCallback={this.stateCallback}
                                                currentLang={this.props.currentLang}
                                                allState={this.state}
                                                categorieActivated={this.props.activeCategorie ? false : true}
                                                noCategories={this.props.noCategories}
                                            />
                                        </Grid>
                                    </AccordionCustom>
                                    : <>
                                        <Typography style={{
                                            fontSize:12,
                                            color:colors.black.regular
                                        }}>Rechercher un produit</Typography>
                                        <TextFieldCustom
                                            variant={'outlined'}
                                            type="text"
                                            fullWidth
                                            placeholder="Par SKU"
                                            onChange={this.handleSearchSku}
                                            value={this.state.searchValueSku}
                                            style={{ fontSize: 14 }}
                                            onKeyDown={(e)=>{
                                                if (e.code === 'Enter') {
                                                    e.preventDefault()
                                                    this.validateFilters()
                                                }
                                            }}
                                        />
                                    </>
                                :null
                        }
                    </Grid>
                    {
                        !this.props.noSearch?
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 12 }}>
                                <Grid container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Grid container alignItems='center'>
                                            <Grid item style={{ marginRight: 16, display: "flex", alignItems: "center" }}>
                                                <Typography colortext={colors.black.regular} style={{ marginBottom: 4 }}>{this.props.t('products.list.view')} : </Typography>
                                                <BootstrapTooltip title="Vue carte" className={clsx(classes.tooltip)}>
                                                    <ViewModuleIconCustom
                                                        style={{ fontSize: 30, marginLeft: 8 }}
                                                        className={this.state.viewMode === "card" ? 'active' : null}
                                                        onClick={this.state.viewMode === "card" ? null : () => this.changeViewMode('card')}
                                                    />
                                                </BootstrapTooltip>
                                                <BootstrapTooltip title="Vue liste" className={clsx(classes.tooltip)}>
                                                    <ViewListIconCustom
                                                        style={{ fontSize: 30, marginLeft: 8 }}
                                                        className={this.state.viewMode === "list" ? 'active' : null}
                                                        onClick={this.state.viewMode === "list" ? null : () => this.changeViewMode('list')}
                                                    />
                                                </BootstrapTooltip>
                                            </Grid>
                                            <Grid item style={{ height: "100%", display: "block" }}>
                                                <span style={{ width: 1, height: "100%", border: `solid 1px ${colors.grey.lighter.hue900}` }}></span>
                                            </Grid>
                                            {
                                                process.env.REACT_APP_MODE_SPREAD !== "hub" ?
                                                    // <BootstrapTooltip title="Cliquez pour exporter les produits" className={clsx(classes.tooltip)}>
                                                    <Grid item style={{ marginLeft: 16 }}>
                                                        <Grid container alignItems="center" onClick={() => this.props.handlerExport ? this.props.handlerExport() : this.handleToggleDrawer('openFormExport')} style={{ cursor: "pointer" }}>
                                                            <Typography colortext={colors.blue.darker.hue300} style={{ marginBottom: 4 }}>{this.props.handlerExportText ? this.props.handlerExportText : this.props.t('products.list.export.title')}</Typography>
                                                            <GetApp
                                                                style={{ fontSize: 26, marginLeft: 4, fill: colors.blue.darker.hue300 }}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    // </BootstrapTooltip>
                                                    : null
                                            }
                                            {
                                                this.props.loadingSecondExport ?
                                                    <CircularProgress size={"20px"} style={{ marginLeft: 16, fill: colors.blue.darker.hue300 }} />
                                                    :
                                                    this.props.handlerSecondExportText ?
                                                        <Grid item style={{ marginLeft: 16 }}>
                                                            <Grid container alignItems="center" onClick={() => this.props.handlerSecondExport()} style={{ cursor: "pointer" }}>
                                                                <Typography colortext={colors.blue.darker.hue300} style={{ marginBottom: 4 }}>{this.props.handlerSecondExportText ? this.props.handlerSecondExportText : this.props.t('products.list.export.title')}</Typography>
                                                                <GetApp
                                                                    style={{ fontSize: 26, marginLeft: 4, fill: colors.blue.darker.hue300 }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        : null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {
                                            this.props.handleLang ?
                                                <Grid item xs={12} style={{ fontSize: 14 }}>
                                                    <TraductionSelect
                                                        currentLang={this.props.currentLang}
                                                        handleLang={this.props.handleLang}
                                                        locales={this.props.locales}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        :null
                    }
                </Grid>
                {
                    this.props.handleSelectAll && this.state.viewMode === "card" && this.state.listProducts?.length > 0 ?
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Button onClick={() => { this.props.handleSelectAll(this.state.listProducts, checkSelectAll) }}>{checkSelectAll ? this.props.t('products.list.filter.selectAll') : this.props.t('products.list.filter.deselectAll')}</Button>
                        </Grid>
                        : null
                }
                <ListingWrapper>
                    <Box>
                        {
                            this.props.inAccordion ?
                                <Grid container>
                                    <AccordionCustom forcedExpanded={true} title={this.props.inAccordion}>
                                        {this._renderListing(cardSize, productBypage, headCells,!this.props.noSearch,this.props.isBundle)}
                                    </AccordionCustom>
                                </Grid>
                                :
                                this._renderListing(cardSize, productBypage, headCells,!this.props.noSearch,this.props.isBundle)
                        }
                    </Box>
                </ListingWrapper>
                <LayoutBuilder
                    isSublayout={false}
                    opened={this.state.openFormExport}
                    icomoon={'ico-import-fichier'}
                    forClose={() => this.handleToggleDrawer('openFormExport')}
                    dataLayout={exportProductsConfig(this.props.locales, 'products', this.props.t)}
                    drawerWidth={this.state.drawerWidth}
                    handleCancel={this.handleCancel}
                    handlerMutation={this.handlerMutationExport}
                    allState={this.state}
                    stateCallback={this.stateCallback}
                    validateButton={true}
                    errorCallback={this.handleFormError}
                    checkError={() => { }}
                />
            </Grid>
        )
    }

    goTo = (route, id,list) => {
        this.props.history.push({
            pathname: route,
            state: { productId: id,productList:list }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ListingProducts)))));