import { gql } from 'apollo-boost';


/*
{
  "product": "/api/products/12",
  "title": "TEST",
  "isRequired": false,
  "inputType": "select"
}
*/
export const createProductBundleOption = gql`
mutation createProductBundleOption($product: String, $title: String!, $isRequired:Boolean, $inputType:String!){
  createProductBundleOption(input: {product:$product, title:$title, isRequired:$isRequired, inputType:$inputType}){
    productBundleOption{
      id
    }
  }  
}`;

/*
{
  "id": "/api/product-bundle-options/4",
  "product": "/api/products/12",
  "title": "TEST edited",
  "isRequired": false,
  "inputType": "select"
}
*/
export const updateProductBundleOption = gql`
mutation updateProductBundleOption($id: ID!, $product: String, $title: String, $isRequired:Boolean, $inputType:String){
  updateProductBundleOption(input: {id: $id, product:$product, title:$title, isRequired:$isRequired, inputType:$inputType}){
    productBundleOption{
      id
    }
  }  
}`;

/*
{
  "id": "/api/product-bundle-options/4"
}
*/
export const deleteProductBundleOption = gql`
mutation deleteProductBundleOption($id: ID!){
  deleteProductBundleOption(input: {id: $id}){
    productBundleOption{
      id
    }
  }  
}`;

/*
{
  "productBundleOption": "/api/product-bundle-options/4",
  "product": "/api/products/13",
  "position": 1
}
*/
export const createProductBundleOptionProduct = gql`
mutation createProductBundleOptionProduct($productBundleOption: String!, $product: String!, $position:Int!){
  createProductBundleOptionProduct(input: {productBundleOption:$productBundleOption, product:$product, position:$position}){
    productBundleOptionProduct{
      id
    }
  }  
}`;

/*
{
  "id": "/api/product-bundle-option-products/productBundleOption=4;product=13",
  "productBundleOption": "/api/product-bundle-options/4",
  "product": "/api/products/13",
  "position": 2
}
*/
export const updateProductBundleOptionProduct = gql`
mutation updateProductBundleOptionProduct($id: ID!, $productBundleOption: String, $product: String, $position:Int){
  updateProductBundleOptionProduct(input: {id:$id, productBundleOption:$productBundleOption, product:$product, position:$position}){
    productBundleOptionProduct{
      id
    }
  }  
}`;


/*
{
  "id": "/api/product-bundle-option-products/productBundleOption=4;product=13"
}
*/
export const deleteProductBundleOptionProduct = gql`
mutation deleteProductBundleOptionProduct($id: ID!){
  deleteProductBundleOptionProduct(input: {id:$id}){
    productBundleOptionProduct{
      id
    }
  }  
}`;