import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, List } from '@material-ui/core';
import styled from 'styled-components';
import ButtonCustom from '../../ui/button/Button';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import PageLoader from "../../ui/loadings/page-loader/PageLoader";
import ListingProducts from '../../screens/dashboard/products/components/ListingProducts';
import {getTraductionAttributs} from '../../../js/utils/functions';
import colors from '../../../config/theme/colors';

const ListCustom = styled(List)`
    padding: 24px;
    display: block;
    max-height: 100%;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxHeight: 400,
        overflow: "auto",
        height: "100%",
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{paddingRight: 0}}>
                    <Typography style={{paddingRight: 24}}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function CheckboxItems(props) {
    const classes = useStyles();
    const [activeCategorie, setActiveCategorie] = React.useState('');
    const [load, setLoad] = React.useState(true);

    const handleToggle = (value) => {
        if(props.selectedProductByCategorie[props.selectedCategorie]){
            const currentIndex  = props.selectedProductByCategorie[props.selectedCategorie]?.indexOf(value.node.id);
            const newChecked    = [...props.selectedProductByCategorie[props.selectedCategorie]];
            
            if (currentIndex === -1) {
                newChecked.push(value.node.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
    
            props.setSelectedProducts(newChecked);
        }
    };
    
    useEffect(() => {
        setLoad(props.allState.load)
    }, [props.allState]);

    useEffect(() => {
        if(props.selectedCategorie){
            let categorie = props.allState.allCategories.find(element => element.id === props.selectedCategorie);
            setActiveCategorie(categorie)
        }
    }, [props.selectedCategorie]);
    return (
        <div style={{padding: 0}}>
            <ListCustom dense className={classes.root}>
                <Grid container direction="row">
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {
                            activeCategorie ? 
                                getTraductionAttributs('category_name', activeCategorie.categoryDatas.edges, props.currentLang)
                            : null
                        }
                        { 
                            props.selectedCategorie ? 
                                <>
                                    <ButtonCustom onClick={props.addProducts}>Ajouter des produits </ButtonCustom> 
                                    <ButtonCustom onClick={props.merchModal}>Merchandising</ButtonCustom>
                                </>
                            : null
                        }
                    </Grid>
                    {
                        !props.productByCategorie[props.selectedCategorie] ? 
                            <div>Veuillez choisir une catégorie</div> 
                        : load ? (<PageLoader /> )
                        :
                        <ListingProducts 
                            productBypage = {{
                                card: [8,16,32],
                                list: [10,20,30]
                            }}
                            nbperpage = {8}
                            currentLang={props.allState.currentLang}
                            windowWidth={props.windowWidth}
                            handleToggle={handleToggle}
                            selectedProducts={props.selectedProductByCategorie[props.selectedCategorie]}
                            cardSize = {{
                                xs: 12,
                                sm: 6,
                                md: 6,
                                lg: 6
                            }}
                            inForm={true}
                            hoverCard = {false}
                            reverseCheck={true}
                            // hasPositionCollections={true}
                            minimalForm={true}
                            activeCategorie={activeCategorie}
                            reload={props.allState.reloadProductFormList}
                            onReload={props.resetReloadListing}
                        />
                    }
                </Grid>
            </ListCustom>
        </div>
    );
}