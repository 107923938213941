import React from 'react';
import { Box, Grid } from '@material-ui/core';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import { ROUTE_HOME, ROUTE_BRAND_FLOW_DETAILS } from '../../../../js/constants/route-names';
import { BRAND, BRAND_FLOW, CONNECTORS, CONNECTORS_RETAILERS, VIEW, CREATE, UPDATE, DELETE } from '../../../../js/constants/constant-rights';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import fetch from '../../../../js/utils/fetch';
import axios from '../../../../js/utils/axios';
import { withApollo } from 'react-apollo';
import { GET_RETAILERS, ADD_RETAILER, UPDATE_RETAILER, DELETE_RETAILER, ADD_RETAILER_DATA, UPDATE_RETAILER_DATA, DELETE_RETAILER_DATA } from '../../../../queries/retailers';
import { GET_ATTR_GROUPE_WITH_ATTRIBUTES, GET_ATTR_GROUPE_LIGHT } from '../../../../queries/attributes';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_SUCCESS, ALERT_ERROR } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';

import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import DialogModal from '../../../ui/dialog/DialogModal';
import CardCustom from '../../../layouts/Card/CardCustom';
import CardSetup from '../../../layouts/Card/cardContent/CardSetup';
import TopPanel from '../../../layouts/TopPanel/TopPanel';

import EmptyRetailers from '../../../../assets/pictos/empty-picto/empty_retailers.png';
import EmptyFlux from '../../../../assets/pictos/empty-picto/empty_flux.png';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import retailersAddConfig from './config/retailersAdd.config';
import CronNotifications from './components/CronNotifications';
import { setRedux } from '../../../../js/utils/functions';
import { withTranslation } from 'react-i18next'
import { checkRouting } from '../../../../js/utils/checkRouting';

const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

const PageWrapper = styled(Box)`
    position: relative;
    height: calc(100% - 64px);
    display: grid;
    grid-template-rows: auto 1fr;

    & > .layout-wrapper{
        display: none;
    }
`;

class ListRetailers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filtered: [],
            attrGroup: false,
            openForm: false,
            editForm: 'add',
            isEmpty: false,
            emptyPicto: null,
            openDialogRemove: false,
            isInternal: props.isInternal,
            content:
                props.type === "retailer" ?
                    {
                        title: this.props.t("connectors.retailers.manageRetailers"),
                        subtitle: this.props.t("connectors.retailers.manageRetailerInfo"),
                        picto: "picto-retailer",
                        txtBtn: this.props.t("spread.campaigns.addRetailers"),
                        titleForm: this.props.t("spread.campaigns.addRetailers"),
                        emptyTitle: this.props.t("spread.campaigns.notConfigureRetailer"),
                        emptySubtitle: this.props.t("spread.active_assets.clickToAdd"),
                        emptyTxtBtn: this.props.t("spread.campaigns.addRetailers"),
                        emptyPicto: EmptyRetailers,
                    }
                    :
                    props.type === "flow" ?
                        {
                            title: this.props.t("connectors.retailers.manageFlow"),
                            subtitle: this.props.t("connectors.retailers.manageFlowInfo"),
                            picto: "icon-flux",
                            txtBtn: this.props.t("connectors.retailers.addFeed"),
                            titleForm: this.props.t("connectors.retailers.addFeed"),
                            emptyTitle: this.props.t("connectors.retailers.notConfiguredFeed"),
                            emptySubtitle: this.props.t("spread.active_assets.clickToAdd"),
                            emptyTxtBtn: this.props.t("connectors.retailers.addNewFeed"),
                            emptyPicto: EmptyFlux,
                        }
                        :
                        {
                            title: this.props.t("connectors.retailers.manageBrand"),
                            subtitle: this.props.t("connectors.retailers.manageBrandInfo"),
                            picto: "icon-business",
                            txtBtn: this.props.t("connectors.retailers.addBrand"),
                            titleForm: this.props.t("connectors.retailers.addBrand"),
                            emptyTitle: this.props.t("connectors.retailers.notConfiguredBrand"),
                            emptySubtitle: this.props.t("connectors.retailers.clickToAddBrand"),
                            emptyTxtBtn: this.props.t("connectors.retailers.addNewBrand"),
                            emptyPicto: EmptyRetailers,
                        },
            dataAddLayout: retailersAddConfig,
            importLang: this.props.locales[0].node.id,
            currentLang: this.props.locales[0].node.code,
            errors: {},
            seeErrors: false,
            inputHidden: [],
            importFile: null,
            attrTypeFile: [],
            attrTypeUrl: [],
            attrTypeFtp: [],
            ready: false,
            drawerCron: false,
            typeTesting: {
                type: 'retailer',
                testingState: ['libelle', 'attributeIdentifier'],
                testingTypingState: 'libelle',
                identifierState: 'attributeIdentifier'
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.typingTimer = null;
    }

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleChange(e) {
        let currentList = [];
        let newList = [];
        if (e.target.value !== "") {
            currentList = this.state.retailers;
            newList = currentList.filter(item => {
                const lc = item.node.libelle.toLowerCase();
                const filter = e.target.value.toLowerCase();
                return lc.includes(filter);
            });
        } else {
            newList = this.state.retailers;
        }
        this.setState({
            filtered: newList
        });
    }

    doneTyping = (stateName) => {
        let typeTesting = this.state.typeTesting;
        if (stateName === typeTesting.testingTypingState) {
            this.setState({
                [typeTesting.identifierState]: slugify(this.state[typeTesting.testingTypingState], { replacement: '_', lower: true, remove: /[^\w\-\s]+/g }),
            });
        }

        if (this.state[typeTesting.identifierState]) {
            request(`${process.env.REACT_APP_API}/unique/${typeTesting.type}/${this.state[typeTesting.identifierState]}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: typeTesting.identifierState, errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
    };

    stateCallback = (stateName, value, custom, translated, callback) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value?.target?.value ?? value;
            this.setState({ [this.state.currentLang]: values });
        }
        else {
            this.setState({
                [stateName]: value?.target?.value ?? value
            }, callback);
        }
        if (this.state.editForm === 'add' && this.state.typeTesting.testingState.includes(stateName))
            this.checkIdentifier(stateName)
    };

    handleButtonGroupChange = (stateName, value) => {
        this.setState({
            [stateName]: value
        });
    };

    handlerSetup = () => {
        /* // TODO: Refactor. The StartLoading was there because there are many for loops so it is slow to load */
        if (this.state.editForm === 'edit' && this.props.type === "brand") {
            this.props.startLoading();
        }
        this.props.client.query({
            query: GET_ATTR_GROUPE_WITH_ATTRIBUTES,
            fetchPolicy: 'no-cache',
        }).then(result => {
            //RECUPERATION MAPPER
            let mapper = [];
            this.setState({ importSep: "," });

            if (this.state.editForm === 'edit' && this.props.type === "brand") {
                request(`${process.env.REACT_APP_API}/retailers/${this.state.editIdentifier}/mapper`, 'get', false).then(
                    (data) => {
                        this.setState({ existentMapper: data.mapper, importSep: data.delimiter });
                        this.props.stopLoading();
                    }
                );
            }
            else {
                this.setState({ existentMapper: null });
            }

            //CONFIG DES COMPOSANTS STATICS
            const switchType = {
                type: 'select',
                label: this.props.t("connectors.retailers.importBy"),
                translated: false,
                helper: {
                    text: this.props.t("connectors.retailers.chooseImportType"),
                    link: false,
                },
                required: true,
                disabled: false,
                stateName: 'importFile',
                value: [
                    {
                        value: 'file',
                        label: this.props.t("connectors.retailers.file"),
                        disabled: this.props.type === "brand" ? true : false,
                    },
                    {
                        value: 'url',
                        label: 'Url',
                        disabled: this.props.type === "brand" ? true : false,
                    },
                    {
                        value: 'ftp',
                        label: 'FTP',
                    },
                    {
                        value: 'sftp',
                        label: 'SFTP',
                    }
                ]
            };

            const separator = {
                type: 'select',
                label: this.props.t("connectors.retailers.separator"),
                translated: false,
                helper: {
                    text: this.props.t("connectors.retailers.chooseSeparator"),
                    link: false,
                },
                required: false,
                stateName: 'importSep',
                value: [
                    { value: ',', label: ',' },
                    { value: ';', label: ';' },
                    { value: '|', label: '|' },
                    { value: 'xml', label: 'SimpleXML ( temp )' },
                ]
            };

            const setupUploadFile = {
                type: 'uploadFile',
                translated: true,
                required: false
            };

            const staticOptions = [
                {
                    id: 'sku',
                    label: 'Code article'
                },
                {
                    id: 'selection',
                    label: 'Selection'
                },
                {
                    id: 'price',
                    label: 'Price'
                },
                {
                    id: 'specialPrice',
                    label: 'Special Price'
                },
                {
                    id: 'specialPriceFrom',
                    label: 'Special Price From Date'
                },
                {
                    id: 'specialPriceTo',
                    label: 'Special Price To Date'
                },
                {
                    id: 'currency',
                    label: 'Currency'
                },
                {
                    id: 'rateGroup',
                    label: 'Rate Group'
                },
                {
                    id: 'stock',
                    label: 'Stock'
                },
                {
                    id: 'ecopart',
                    label: 'Eco-part'
                },
                {
                    id: 'url',
                    label: 'URL'
                },
                {
                    id: 'version',
                    label: 'Version'
                },
                {
                    id: 'stockType',
                    label: 'StockType'
                }
            ];

            const setupMapping = {
                type: this.props.type === 'brand' ? 'mapper-multiple' : 'mapper',
                translated: true,
                required: false,
            };

            const setupInputText = {
                type: 'text',
                translated: true,
                helper: { link: false },
                required: false,
            };

            this.setState({ importFile: "" })

            /* // TODO: Refactor */
            for (let attrGroup of result.data.attributeGroups.edges) {
                let testType = this.props.type === 'flow' ? 'retailer' : this.props.type;
                if (attrGroup.node.identifier === testType) {
                    let getLayoutAdd = this.state.dataAddLayout;
                    this.setState({ attrGroupId: attrGroup.node.id, attr: attrGroup.node.attributes });

                    //INIT STEP 2 & 3 
                    getLayoutAdd.formConfig.children[1].optionsInputs = [];
                    getLayoutAdd.formConfig.children[2].optionsInputs = [];

                    //PUSH switch type d'import de fichier 
                    getLayoutAdd.formConfig.children[1].optionsInputs.push(switchType);

                    //Initialisation des mapper par locale
                    for (let locale of this.props.locales) {
                        this.setState({ [locale.node.code]: {} });
                        let localeState = this.state[locale.node.code];
                        for (let attr of attrGroup.node.attributes.edges) {
                            if (attr.node.attributeType.input === 'file') {
                                localeState[`upload_${attr.node.identifier}`] = null;
                                localeState[`mapper_${attr.node.identifier}`] = mapper;
                            }
                            else if (attr.node.attributeType.input === 'text') {
                                localeState[attr.node.identifier] = null;
                                localeState[`mapper_text_${attr.node.identifier}`] = mapper;
                            }
                            this.setState({
                                [locale.node.code]: localeState
                            })
                        }
                    }

                    this.setState({
                        dataAddLayout: getLayoutAdd
                    })

                    // SELECT Type File
                    for (let attr of attrGroup.node.attributes.edges) {
                        if (attr.node.attributeType?.input === "select") {
                            let retailer_file_type = {
                                type: 'select',
                                label: this.props.t("connectors.retailers.fileType"),
                                translated: false,
                                helper: {
                                    text: this.props.t("connectors.retailers.chooseFileType"),
                                    link: false,
                                },
                                required: false,
                                stateName: 'retailer_file_type',
                                value: []
                            }

                            for (let item of attr.node.attributeOptions.edges) {
                                if (retailer_file_type.value.length === 0) {
                                    this.setState({ retailer_file_type: item.node.identifier })
                                }
                                retailer_file_type.value.push({ value: item.node.identifier, label: item.node.translation.translationDatas.edges[0].node.value })
                            }

                            let configFormAdd = this.state.dataAddLayout;
                            configFormAdd.formConfig.children[1].optionsInputs.push(retailer_file_type);

                            this.setState({
                                dataAddLayout: configFormAdd
                            });
                        }
                    }
                    this.state.dataAddLayout.formConfig.children[1].optionsInputs.push(separator);

                    for (let attr of attrGroup.node.attributes.edges) {
                        //LABEL ATTRIBUTION
                        let label = '';
                        if (attr.node.translation.translationDatas) {
                            label = attr.node.translation.translationDatas.edges[0].node.value;
                        }
                        else {
                            label = attr.node.identifier
                        }

                        if (attr.node.attributeType.input === "file") {
                            if (this.state.editForm === "add") {
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;
                                if (this.props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                this.state.attrTypeFile.push(`upload_${attr.node.identifier}`);
                                this.state.attrTypeFile.push(`mapper_${attr.node.identifier}`);

                                let configFormAdd = this.state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);

                                this.setState({
                                    dataAddLayout: configFormAdd
                                });
                            }

                            else if (this.state.editForm === "edit") {
                                for (let retailer of this.state.retailers) {
                                    if (retailer.node.id === this.state.editId) {
                                        for (let attribute of retailer.node.retailerData.edges) {
                                            if (attribute.node.attribute.id === attr.node.id && attribute.node.media.filePath) {
                                                this.state[attribute.node.locale.code][`upload_${attr.node.identifier}`] = { file: { name: attribute.node.media.filePath } };
                                            }
                                        }
                                    }
                                }
                                let uploadFile = _.clone(setupUploadFile);
                                uploadFile.stateName = `upload_${attr.node.identifier}`;
                                uploadFile.label = label;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `upload_${attr.node.identifier}`;
                                mapping.stateName = `mapper_${attr.node.identifier}`;

                                if (this.props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;
                                configFormAdd.formConfig.children[1].optionsInputs.push(uploadFile);
                                configFormAdd.formConfig.children[2].optionsInputs.push(mapping);

                                this.state.attrTypeFile.push(`upload_${attr.node.identifier}`);
                                this.state.attrTypeFile.push(`mapper_${attr.node.identifier}`);

                                this.setState({
                                    dataAddLayout: configFormAdd,
                                });
                            }
                        }

                        else if (attr.node.attributeType.input === "text") {
                            if (this.state.editForm === "add") {
                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if (this.props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;

                                if (attr.node.identifier.match(/url/g)) {
                                    this.state.attrTypeUrl.push(attr.node.identifier);
                                    this.state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if (attr.node.identifier.match(/ftp/g)) {
                                    this.state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);

                                    if (attr.node.identifier.match(/filename/g)) {
                                        this.state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }
                                this.setState({ dataAddLayout: configFormAdd });
                            }
                            else if (this.state.editForm === 'edit') {
                                for (let retailer of this.state.retailers) {
                                    if (retailer.node.id === this.state.editId) {
                                        for (let attribute of retailer.node.retailerData.edges) {
                                            if (attribute.node.value !== null) {
                                                this.state[attribute.node.locale.code][attribute.node.attribute.identifier] = attribute.node.value;
                                            }
                                        }
                                    }
                                }

                                let inputText = _.clone(setupInputText);
                                inputText.helper.text = label;
                                inputText.label = label;
                                inputText.stateName = attr.node.identifier;

                                let mapping = _.clone(setupMapping);
                                mapping.label = label;
                                mapping.mapOn = `${attr.node.identifier}`;
                                mapping.stateName = `mapper_text_${attr.node.identifier}`;
                                if (this.props.type !== 'brand') {
                                    mapping.staticOptions = staticOptions;
                                }

                                let configFormAdd = this.state.dataAddLayout;
                                if (attr.node.identifier.match(/url/g)) {
                                    this.state.attrTypeUrl.push(attr.node.identifier);
                                    this.state.attrTypeUrl.push(`mapper_text_${attr.node.identifier}`);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                }

                                else if (attr.node.identifier.match(/ftp/g)) {
                                    this.state.attrTypeFtp.push(attr.node.identifier);
                                    configFormAdd.formConfig.children[1].optionsInputs.push(inputText);
                                    if (attr.node.identifier.match(/filename/g)) {
                                        this.state.attrTypeFtp.push(`mapper_text_${attr.node.identifier}`);
                                        configFormAdd.formConfig.children[2].optionsInputs.push(mapping);
                                    }
                                }

                                this.setState({
                                    dataAddLayout: configFormAdd,
                                });
                            }
                        }
                    }
                }
            }

            this.setState({ importFile: this.props.type === "brand" ? 'ftp' : 'file' });
        });
    }

    handlerSaveRetailerData = () => {
        return new Promise(async (resolve, reject) => {
            if (this.state.editForm === "add") {
                for (let locale of this.props.locales) {
                    for (let item of this.state.attr.edges) {
                        if (this.state.importFile === 'file') {
                            if (this.state[locale.node.code][`upload_${item.node.identifier}`] && this.state[locale.node.code][`upload_${item.node.identifier}`].medias) {
                                let value = this.state[locale.node.code][`upload_${item.node.identifier}`].medias.id;

                                let variables = {
                                    "retailer": this.state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id
                                };

                                variables.media = `/api/media-objects/${value}`;

                                await this.props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                        else {
                            if (this.state[locale.node.code][item.node.identifier]) {
                                let value = this.state[locale.node.code][item.node.identifier];

                                let variables = {
                                    "retailer": this.state.idNewRetailer,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value,
                                };

                                await this.props.client.mutate({
                                    mutation: ADD_RETAILER_DATA,
                                    variables
                                });
                            }
                        }
                    }
                }
            }

            else if (this.state.editForm === "edit") {
                let editRetailerData = {};
                for (let retailer of this.state.retailers) {
                    if (retailer.node.id === this.state.editId) {
                        editRetailerData = retailer.node.retailerData.edges;
                    }
                }

                for (let locale of this.props.locales) {
                    for (let item of this.state.attr.edges) {
                        if (this.state.importFile === 'file') {
                            if (this.state[locale.node.code][`upload_${item.node.identifier}`] && this.state[locale.node.code][`upload_${item.node.identifier}`].medias) {
                                let value = this.state[locale.node.code][`upload_${item.node.identifier}`].medias.id;
                                let variables = {
                                    "retailer": this.state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "media": `/api/media-objects/${value}`
                                };

                                let update = false;
                                for (let attribute of editRetailerData) {
                                    if (attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code) {
                                        update = attribute.node.id;
                                    }
                                }

                                if (update) {
                                    variables.id = update;
                                    await this.props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else {
                                    await this.props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                        else {
                            if (this.state[locale.node.code][item.node.identifier]) {
                                let value = this.state[locale.node.code][item.node.identifier];
                                let variables = {
                                    "retailer": this.state.editId,
                                    "attribute": item.node.id,
                                    "locale": locale.node.id,
                                    "value": value
                                };
                                let update = false;
                                for (let attribute of editRetailerData) {
                                    if (attribute.node.attribute.identifier === item.node.identifier && attribute.node.locale.code === locale.node.code) {
                                        update = attribute.node.id;
                                    }
                                }

                                if (update) {
                                    variables.id = update;
                                    await this.props.client.mutate({
                                        mutation: UPDATE_RETAILER_DATA,
                                        variables
                                    });
                                }

                                else {
                                    await this.props.client.mutate({
                                        mutation: ADD_RETAILER_DATA,
                                        variables
                                    });
                                }
                            }
                        }
                    }
                }
            }
            this.setState({
                openForm: !this.state.openForm
            })
            this.props.stopLoading();
            this.handleGetRetailers();
            resolve();
        });
    }

    handlerMutation = () => {
        this.props.startLoading();
        let query = null;
        let variables = null;
        switch (this.state.editForm) {
            case 'edit':
                query = UPDATE_RETAILER;
                variables =
                {
                    "id": this.state.editId,
                    "identifier": this.state.attributeIdentifier,
                    "description": this.state.description,
                    "libelle": this.state.libelle,
                    "attributeGroup": this.state.attrGroupId,
                    "isInternal": this.state.isInternal,
                }

                break;
            case 'add':
                query = ADD_RETAILER;
                variables =
                {
                    "identifier": this.state.attributeIdentifier,
                    "description": this.state.description,
                    "libelle": this.state.libelle,
                    "attributeGroup": this.state.attrGroupId,
                    "isInternal": this.state.isInternal,
                }
                break;
        }
        if (this.state.image) {
            variables.logo = this.state.image.data
        }
        this.props.client.mutate({
            mutation: query,
            variables,
        }).then(result => {
            if (this.state.editForm === 'add') {
                this.setState({
                    idNewRetailer: result.data.createRetailer.retailer.id
                }, () => {
                    this.handlerSaveRetailerData();
                    this.handlerImportFiles();
                });
            }
            else if (this.state.editForm === "edit") {
                this.setState({
                    idNewRetailer: this.state.editId
                }, () => {
                    this.handlerSaveRetailerData();
                    this.handlerImportFiles();
                });
            }
            setRedux(false, true, false, false)
        })
    }

    handlerFtpFile = async () => {
        for (let locale of this.props.locales) {
            if (this.state[locale.node.code].retailer_ftp_host && this.state[locale.node.code].retailer_ftp_port && this.state[locale.node.code].retailer_ftp_username && this.state[locale.node.code].retailer_ftp_password && this.state[locale.node.code].retailer_ftp_path && this.state[locale.node.code].retailer_ftp_filename) {
                let formData = new FormData();
                formData.append('retailer_ftp_host', this.state[locale.node.code].retailer_ftp_host)
                formData.append('retailer_ftp_port', this.state[locale.node.code].retailer_ftp_port)
                formData.append('retailer_ftp_username', this.state[locale.node.code].retailer_ftp_username)
                formData.append('retailer_ftp_password', this.state[locale.node.code].retailer_ftp_password)
                formData.append('retailer_ftp_path', this.state[locale.node.code].retailer_ftp_path)
                formData.append('retailer_ftp_filename', this.state[locale.node.code].retailer_ftp_filename)
                formData.append('retailer_file_type', this.state.retailer_file_type)

                try {
                    request(this.state.importFile === 'ftp' ? `${process.env.REACT_APP_API_ROOT}/api/retailer/ftp` : `${process.env.REACT_APP_API_ROOT}/api/retailer/sftp`, 'post', formData, 'multipart/form-data').then(
                        (data) => {
                            let getState = this.state[locale.node.code];
                            getState.retailer_ftp_filename_import = data.fileUrl
                            this.setState({
                                [locale.node.code]: getState
                            })
                        }
                    );
                    this.props.snack(ALERT_SUCCESS, `Succès !`);
                } catch (e) {
                    this.props.snack(ALERT_ERROR, `Echec !`);
                }
            }
        }
    }

    getHeaderFtp = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let mapOn = item.node.identifier + "_import";

            let headersFormData = new FormData();
            headersFormData.append(this.state.importSep === 'xml' ? 'xml' : 'csv', this.state[locale.node.code][mapOn]);
            headersFormData.append('separator', this.state.importSep);
            let resultHeaders = await axios(this.state.importSep !== "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = this.state[locale.node.code][`mapper_text_${item.node.identifier}`];
            for (let header of resultHeaders.message) {
                mapper.push(mapping[header])
            }
            let importConfig = {
                "url": this.state[locale.node.code][mapOn],
                "mapper": mapper,
                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": this.state.idNewRetailer,
                "delimiter": this.state.retailer_file_type === "retailer_file_type_csv" || this.state.retailer_file_type === "retailer_file_type_simplexml" ? this.state.importSep : null,
                "fileType": this.state.importSep === "xml" ? "simplexml" : null,
            };

            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                this.props.snack(ALERT_SUCCESS, this.props.t("connectors.retailers.importSuccessful"));
                this.props.stopLoading();
            } catch (e) {
                this.props.snack(ALERT_ERROR, this.props.t("content.content.importMediaFailed"));
                this.props.stopLoading();
            }
            resolve();
        });
    }

    getHeaderUrl = (item, locale) => {
        return new Promise(async (resolve, reject) => {
            let headersFormData = new FormData();
            headersFormData.append(this.state.retailer_file_type === "retailer_file_type_simplexml" ? 'xml' : 'csv', this.state[locale.node.code][item.node.identifier]);
            headersFormData.append('separator', this.state.importSep);

            let resultHeaders = await axios(this.state.importSep !== "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);

            let mapper = [];
            let mapping = this.state[locale.node.code][`mapper_text_${item.node.identifier}`];
            for (let header of resultHeaders.message) {
                mapper.push(mapping[header])
            }

            let importConfig = {
                "url": this.state[locale.node.code][item.node.identifier],
                "mapper": mapper,
                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                "locale": locale.node.id,
                "retailer": this.state.idNewRetailer,
                "delimiter": this.state.importSep,
                "fileType": this.state.importSep === "xml" ? "simplexml" : null,
            };

            try {
                // todo thomas
                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                this.props.snack(ALERT_SUCCESS, this.props.t("connectors.retailers.importSuccessful"));
                this.props.stopLoading();
            } catch (e) {
                this.props.snack(ALERT_ERROR, this.props.t("content.content.importMediaFailed"));
                this.props.stopLoading();
            }
            resolve();
        });
    }

    handlerImportFiles = async () => {
        for (let locale of this.props.locales) {
            for (let item of this.state.attr.edges) {
                if (this.state.importFile === 'file') {
                    if (item.node.identifier && this.state[locale.node.code][`upload_${item.node.identifier}`]) {
                        if (this.state[locale.node.code][`mapper_${item.node.identifier}`]?.length !== [] && this.state[locale.node.code][`upload_${item.node.identifier}`] !== null && this.state[locale.node.code][`upload_${item.node.identifier}`].headers) {
                            let mapper = [];
                            let mapping = this.state[locale.node.code][`mapper_${item.node.identifier}`];
                            for (let header of this.state[locale.node.code][`upload_${item.node.identifier}`].headers) {
                                mapper.push(mapping[header])
                            }
                            let importConfig = {
                                "url": `${process.env.REACT_APP_API_ROOT}${this.state[locale.node.code][`upload_${item.node.identifier}`].medias?.contentUrl ?? null}`,
                                "mapper": mapper,
                                "eavType": this.props.attributes.eavTypes.find(e => e.node.code === 'retailer').node.id,
                                "locale": locale.node.id,
                                "retailer": this.state.idNewRetailer,
                                "delimiter": this.state.importSep,
                                "fileType": this.state.importSep === "xml" ? "simplexml" : null,
                            };

                            try {
                                // todo thomas
                                await fetch(`${process.env.REACT_APP_API_ROOT}/api/file-imports`, 'post', importConfig, undefined, true);
                                this.props.snack(ALERT_SUCCESS, this.props.t("connectors.retailers.importSuccessful"));
                                this.props.stopLoading();
                            } catch (e) {
                                this.props.snack(ALERT_ERROR, this.props.t("content.content.importMediaFailed"));
                                this.props.stopLoading();
                            }
                        }
                    }
                }
                else if (this.state.importFile === 'ftp' || this.state.importFile === 'sftp') {
                    if (item.node.identifier.match(/ftp_filename/g) && this.state[locale.node.code][item.node.identifier]) {
                        this.getHeaderFtp(item, locale);
                    }
                }
                else if (this.state.importFile === "url") {
                    if (item.node.identifier.match(/url/g) && this.state[locale.node.code][item.node.identifier]) {
                        this.getHeaderUrl(item, locale);
                    }
                }
            }
        }
    }

    handleGetAttributGroup = () => {
        return new Promise(async (resolve, reject) => {
            this.props.client.query({
                query: GET_ATTR_GROUPE_LIGHT,
                variables: { "is_internal": this.state.isInternal },
                fetchPolicy: "no-cache"
            }).then(result => {
                for (let item of result.data.attributeGroups.edges) {
                    if (item.node.identifier === this.props.type) {
                        this.setState({ idAttrGroup: item.node.id, attrGroup: true });
                    }
                }
                resolve();
            });
        });
    }

    handleGetRetailers = async () => {
        if (!this.state.idAttrGroup || !this.state.attrGroup) {
            await this.handleGetAttributGroup();
        }
        this.props.client.query({
            query: GET_RETAILERS,
            variables: { "is_internal": this.state.isInternal, "attributeGroup": this.props.type === "flow" ? null : this.state.idAttrGroup },
            fetchPolicy: "no-cache"
        }).then(result => {
            this.setState({
                retailers: result.data.retailers.edges,
                filtered: result.data.retailers.edges,
                categories: [],
                ready: true,
            });
            if (result.data.retailers.edges.length === 0) {
                this.setState({
                    isEmpty: true
                })
            } else {
                this.setState({
                    isEmpty: false
                })
            }
        })
    }

    deleteRetailerDatas = () => {
        return new Promise(async (resolve, reject) => {
            for (let retailer of this.state.retailers) {
                if (retailer.node.id === this.state.editId) {
                    for (let attr of retailer.node.retailerData.edges) {
                        await this.props.client.mutate({
                            mutation: DELETE_RETAILER_DATA,
                            variables: { "id": attr.node.id }
                        })
                    }
                }
            }
            resolve();
        });
    }

    deleteChannel = () => {
        this.props.client.mutate({
            mutation: DELETE_RETAILER,
            variables: { "id": this.state.editId },
        }).then(result => {
            this.handleGetRetailers();
            this.setState({
                openDialogRemove: false
            });
        });
    }

    deleteMutation = async () => {
        this.props.startLoading();
        await this.deleteRetailerDatas();
        await this.deleteChannel();
        await this.props.stopLoading();
    }

    editChannel = async (id, identifier) => {
        this.setState({
            editForm: "edit",
            editId: id,
            editIdentifier: identifier,
        }, () => {
            this.props.type === "retailer" ?
                this.state.dataAddLayout.titleForm = this.props.t("connectors.retailers.editRetailer")
                : this.props.type === "flow" ?
                    this.state.dataAddLayout.titleForm = this.props.t("connectors.retailers.editFeed")
                    :
                    this.state.dataAddLayout.titleForm = this.props.t("connectors.retailers.editBrand")
            this.handlerSetup(this.state.editId);
            this.handleToggleDrawer();
        });
    }

    handleToggleDialog = (id = null) => {
        if(id){
            this.setState({
                openDialogRemove: !this.state.openDialogRemove,
                editId: id
            })
        }
        this.setState({ 
            openDialogRemove: !this.state.openDialogRemove 
        });
    }

    handleToggleDrawerCron = () => {
        this.setState({ drawerCron: false });
    }

    getListCronByRetailer = (id, retailer) => {
        this.setState({
            listCrons: null,
            drawerCron: true,
            retailerCron: retailer
        });

        try {
            request(`${process.env.REACT_APP_API}/scheduler/retailer/${id}`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        this.setState({ listCrons: data.crons })
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }
    }

    handleToggleDrawer = () => {
        this.setState({
            openForm: !this.state.openForm,
            libelle: undefined,
            description: undefined,
            image: undefined,
        }, () => {
            if (this.state.editForm === "edit" && this.state.openForm) {
                for (let retailer of this.state.retailers) {
                    if (retailer.node.id === this.state.editId) {
                        this.setState({
                            libelle: retailer.node.libelle,
                            description: retailer.node.description,
                            image: { changed: false, data: retailer.node.logo },
                            attributeIdentifier: retailer.node.identifier
                        });
                    }
                }
                let children = 0;
                for (let item of this.state.dataAddLayout.formConfig.children) {
                    let optionsInputs = 0;
                    for (let input of item.optionsInputs) {
                        if (input.stateName === "attributeIdentifier") {
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = true
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }
            else if (this.state.editForm === "add" && this.state.openForm) {
                this.setState({
                    libelle: undefined,
                    description: undefined,
                    image: undefined,
                    attributeIdentifier: undefined,
                });
                this.handlerSetup();
                let children = 0;
                for (let item of this.state.dataAddLayout.formConfig.children) {
                    let optionsInputs = 0;
                    for (let input of item.optionsInputs) {
                        if (input.stateName === "attributeIdentifier") {
                            let getLayoutAdd = this.state.dataAddLayout
                            getLayoutAdd.formConfig.children[children].optionsInputs[optionsInputs].disabled = false
                            this.setState({
                                dataAddLayout: getLayoutAdd
                            });
                        }
                        optionsInputs++;
                    }
                    children++;
                }
            }

            if (!this.state.openForm) {
                this.setState({
                    editForm: "add",
                });
                this.state.dataAddLayout.titleForm = this.props.type === "retailer" ? this.props.t("spread.campaigns.addRetailers") : this.props.type === "flow" ? "Ajouter un flux" : "Ajouter une marque";
            }
        });
    }

    handleFormImport = (type, title) => {
        this.setState({
            catalogFormData: {
                type,
                title
            },
            mapper: [],
            media: null,
            headers: [],
            importSep: ',',
            importValues: {},
            importLang: false
        });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }
        return false;
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    componentDidMount() {
        checkRouting(this.props);
        let mainRight = null;
        let secondaryRight = null;

        if (this.props.isInternal) {
            mainRight = BRAND;
            secondaryRight = BRAND_FLOW;
            this.setState({
                mainRight,
                secondaryRight,
            })
        } else {
            mainRight = CONNECTORS;
            secondaryRight = CONNECTORS_RETAILERS;
            this.setState({
                mainRight,
                secondaryRight,
            })
        }
        this.handleGetRetailers();
        let finalStep =
        {
            picto: EmptyRetailers,
            title: this.props.t("connectors.retailers.andThereYouGo"),
            subtitle: this.props.t("connectors.retailers.spreadInfo"),
            textButton: this.props.t("connectors.retailers.connect")
        }
        let getLayoutAdd = this.state.dataAddLayout;
        getLayoutAdd.formConfig.finalStep = finalStep;
        getLayoutAdd.titleForm = this.state.content.titleForm;
        this.setState({
            dataAddLayout: getLayoutAdd
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let mainRight = null;
        let secondaryRight = null;
        if (this.props.isInternal !== prevProps.isInternal || this.props.type !== prevProps.type) {
            if (this.props.isInternal) {
                mainRight = BRAND;
                secondaryRight = BRAND_FLOW;
                this.setState({
                    mainRight,
                    secondaryRight,
                })
            } else {
                mainRight = CONNECTORS;
                secondaryRight = CONNECTORS_RETAILERS;
                this.setState({
                    mainRight,
                    secondaryRight,
                })
            }
            this.setState({
                filtered: [],
                retailer: [],
                openForm: false,
                editForm: 'add',
                isInternal: this.props.isInternal,
                attrGroup: false,
                ready: false,
                content: this.props.type === "retailer" ?
                    {
                        title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.retailers.manageRetailers") : this.props.t("connectors.retailers.manageWarehouses"),
                        subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.retailers.manageRetailerInfo") : this.props.t("connectors.retailers.manageWarehousesinfo"),
                        picto: "picto-retailer",
                        txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("spread.campaigns.addRetailers") : null,
                        titleForm: this.props.t("spread.campaigns.addRetailers"),
                        emptyTitle: this.props.t("connectors.retailers.notConfiguredRetailers"),
                        emptySubtitle: this.props.t("spread.active_assets.clickToAdd"),
                        emptyTxtBtn: this.props.t("spread.campaigns.addRetailers"),
                        emptyPicto: EmptyRetailers,
                    }
                    : this.props.type === "flow" ?
                        {
                            title: this.props.t("connectors.retailers.manageFlow"),
                            subtitle: this.props.t("connectors.retailers.manageFlowInfo"),
                            picto: "icon-flux",
                            txtBtn: this.props.t("connectors.retailers.addFeed"),
                            titleForm: this.props.t("connectors.retailers.addFeed"),
                            emptyTitle: this.props.t("connectors.retailers.notConfiguredFeed"),
                            emptySubtitle: this.props.t("spread.active_assets.clickToAdd"),
                            emptyTxtBtn: this.props.t("connectors.retailers.addNewFeed"),
                            emptyPicto: EmptyFlux,
                        }
                        :
                        {
                            title: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.retailers.manageBrand") : "ERP",
                            subtitle: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.retailers.manageBrandInfo") : this.props.t("connectors.retailers.manageErp"),
                            picto: "icon-business",
                            txtBtn: process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("connectors.retailers.addBrand") : null,
                            titleForm: this.props.t("connectors.retailers.addBrand"),
                            emptyTitle: this.props.t("connectors.retailers.notConfiguredBrand"),
                            emptySubtitle: this.props.t("connectors.retailers.clickToAddBrand"),
                            emptyTxtBtn: this.props.t("connectors.retailers.addNewBrand"),
                            emptyPicto: EmptyRetailers,
                        },
            }, () => {
                this.handleGetRetailers();
                let finalStep =
                {
                    picto: EmptyRetailers,
                    title: this.props.t("connectors.retailers.andThereYouGo"),
                    subtitle: this.props.t("connectors.retailers.spreadInfo"),
                    textButton: this.props.t("connectors.retailers.connect")
                }
                let getLayoutAdd = this.state.dataAddLayout;
                getLayoutAdd.formConfig.finalStep = finalStep;
                getLayoutAdd.titleForm = this.state.content.titleForm;
                this.setState({
                    dataAddLayout: getLayoutAdd
                });
            })
        }
        if (this.state.importFile !== prevState.importFile) {
            let inputHidden = [];
            if (this.state.importFile === 'file') {
                this.setState({
                    inputHidden: []
                }, () => {
                    for (let item of this.state.attrTypeUrl) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFtp) {
                        inputHidden.push(item);
                    }
                    inputHidden.push('retailer_file_type');
                });
            }
            else if (this.state.importFile === 'url') {
                this.setState({
                    inputHidden: []
                }, () => {

                    for (let item of this.state.attrTypeFile) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFtp) {
                        inputHidden.push(item);
                    }
                    inputHidden.push('retailer_file_type');
                })
            }
            else if (this.state.importFile === 'ftp' || this.state.importFile === 'sftp') {
                this.setState({
                    inputHidden: []
                }, () => {
                    for (let item of this.state.attrTypeUrl) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFile) {
                        inputHidden.push(item);
                    }
                });
                if (this.state.retailer_file_type !== "retailer_file_type_csv" && this.state.retailer_file_type !== "retailer_file_type_simplexml") {
                    this.setState({ importSep: ',' });
                    inputHidden.push("importSep");
                }
            }
            this.setState({ inputHidden })
        }

        if (this.state.retailer_file_type !== prevState.retailer_file_type) {
            let inputHidden = [];
            if (this.state.retailer_file_type === "retailer_file_type_csv") {
                this.setState({
                    inputHidden: []
                }, () => {
                    for (let item of this.state.attrTypeUrl) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFile) {
                        inputHidden.push(item);
                    }
                });
            } else if (this.state.retailer_file_type === "retailer_file_type_simplexml") {
                this.setState({
                    inputHidden: []
                }, () => {
                    for (let item of this.state.attrTypeUrl) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFile) {
                        inputHidden.push(item);
                    }
                });
                this.setState({ importSep: 'xml' });
                inputHidden.push("importSep");
            }
            else {
                this.setState({
                    inputHidden: [],
                    importSep: ',',
                }, () => {
                    for (let item of this.state.attrTypeUrl) {
                        inputHidden.push(item)
                    }
                    for (let item of this.state.attrTypeFile) {
                        inputHidden.push(item);
                    }
                    inputHidden.push("importSep");
                });
            }
            this.setState({ inputHidden })
        }

    }

    render() {
        return (
            <PageWrapper>
                {this.state.content ?
                    (
                        <TopPanel
                            icomoon={this.state.content.picto}
                            colorIcomoon={colors.blue.darker.hue300}
                            title={this.state.content.title}
                            subtitle={this.state.content.subtitle}
                            gradientColor1={colors.menu.regular}
                            gradientColor2={colors.menu.darker}
                            handlerAdd={() => { this.handleToggleDrawer() }}
                            // textAdd={this.state.isEmpty ? null : '+ ' + this.state.retailers ? '+ ' + this.state.content.txtBtn : null}
                            textAdd={this.state.content.txtBtn ? '+ ' + this.state.content.txtBtn : null}
                            searchHandler={(this.state.isEmpty || !this.state.retailers) ? null : this.handleChange}
                            searchStyle={this.state.isEmpty ? { paddingTop: 16 } : ''}
                            openForm={this.state.openForm}
                            buttonAvailable={this.state.openForm ? false : true}
                            windowWidth={this.props.windowWidth}
                            noResult={!this.state.filtered.length > 0}
                            hasBorder={true}
                        />
                    ) : null
                }
                <Grid container direction="column" spacing={0} style={{ width: this.state.openForm && this.state.isEmpty ? `calc(100% - ((50% - ${this.props.drawerWidth}px / 2) + (${this.props.drawerWidth}px / 2) + 32px))` : "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', position: 'relative' }}>                    
                    <Grid container direction="row" spacing={2} style={{
                        // position: 'relative',
                        width: '100%',
                        // height: '100%',
                    }}>
                        <div style={{
                            width: '100%',
                            height: '100%',
                            display: 'grid',
                            gridTemplateColumns:this.state.ready?"repeat(3,1fr)":"1fr",
                            gap: '16px',
                        }}>
                            {this.state.isEmpty ?
                                <EmptyCard title={this.state.content.emptyTitle} subtitle={this.state.content.emptySubtitle} textButton={this.state.content.emptyTxtBtn} onClick={() => { this.handleToggleDrawer() }} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                                :
                                this.state.ready ?
                                    <>
                                        {
                                            this.state.filtered.length > 0 ? (
                                                this.state.filtered.map((type, index) =>
                                                    <GridCustom item xs={12} key={`ListRetailers${index}`}>
                                                        <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'0px'}>
                                                            <CardSetup 
                                                                type={type} 
                                                                textButton={'Set up'} 
                                                                handlerEdit={process.env.REACT_APP_MODE_SPREAD !== "hub" ? () => this.editChannel(type.node.id, type.node.identifier) : null} cronListOpening={this.getListCronByRetailer} isRetailer={true} showDetails={this.props.type === 'flow' ? () => this.goToDetails(ROUTE_BRAND_FLOW_DETAILS.replace(':id', type.node.id.replace('/api/retailers/', '')), type.node.id) : false} 
                                                                handlerMenu={{
                                                                    toggleDeleteDialog: this.handleToggleDialog,
                                                                    // toggleEditDialog: this.handleToggleDialog,
                                                                }}
                                                            />
                                                        </CardCustom>
                                                    </GridCustom>
                                                )
                                            )
                                                : (<EmptyCard title={this.props.t("catalogs.catalogs.noResults")} textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.state.content.emptyTxtBtn : null} onClick={() => { this.handleToggleDrawer('form') }} picto={this.state.content.emptyPicto} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />)
                                        }
                                    </>
                                    : (
                                        <Box style={{
                                            width: '100%',
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}>
                                            <PageLoader />
                                        </Box>
                                    )
                            }
                        </div>
                    </Grid>
                </Grid>
                {
                    this.state.dataAddLayout ?
                        (
                            <LayoutBuilder
                                isSublayout={false}
                                opened={this.state.openForm}
                                icomoon={this.props.type === "retailer" ? 'picto-retailer' : this.props.type === "flow" ? 'icon-flux' : 'icon-business'} gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker}
                                forClose={() => { this.handleToggleDrawer() }}
                                dataLayout={this.state.dataAddLayout}
                                drawerWidth={this.props.drawerWidth}
                                stateCallback={this.stateCallback}
                                handleButtonGroupChange={this.handleButtonGroupChange}
                                stepperButtonAction={[
                                    () => {
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        return true;
                                    },
                                    () => {
                                        this.handlerFtpFile();
                                        if (this.hasErrors()) {
                                            this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                            this.setState({ seeErrors: true });
                                            eventService.fire();
                                            return false;
                                        }
                                        return true;
                                    }, null
                                ]}
                                errorCallback={this.handleFormError}
                                stepperSkip={3}
                                validateButton={true}
                                // deleteMutation={this.state.editForm === 'edit' ? this.handleToggleDialog : null}
                                stepperButtonDisabled={[null, null, null]}
                                // deleteText={this.state.editForm === 'edit' ? this.props.t("spread.active_assets.delete") : null}
                                handlerMutation={() => { this.handlerMutation() }}
                                allState={this.state}
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                hideInput={this.state.inputHidden}
                                windowWidth={this.props.windowWidth}
                            />
                        ) : null
                }
                <DialogModal
                    icon={true}
                    type='delete'
                    open={this.state.openDialogRemove}
                    title={
                        this.props.type === "retailer"
                            ? this.props.t("connectors.retailers.sureToDeleteRetailer")
                            : this.props.type === "flow"
                                ? this.props.t("connectors.retailers.sureToDeleteFeed")
                                : this.props.t("connectors.retailers.sureToDeleteBrand")
                    }
                    secondaryAction={this.handleToggleDialog}
                    primaryAction={this.deleteMutation}
                    windowWidth={this.props.windowWidth}
                >
                    {this.props.type === "retailer"
                        ? this.props.t("connectors.retailers.verySureToDeleteRetailer")
                        : this.props.type === "flow"
                            ? this.props.t("connectors.retailers.verySureToDeleteFeed")
                            : this.props.t("connectors.retailers.verySureToDeleteBrand")
                    }
                </DialogModal>
                <CronNotifications closeDrawer={() => this.setState({ drawerCron: false })} drawerCron={this.state.drawerCron} retailerCron={this.state.retailerCron} listCrons={this.state.listCrons} />
            </PageWrapper>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };

    goToDetails = (route, id) => {
        this.props.history.push({
            pathname: route,
            state: { retailerId: id }
        });
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        typeOf: state.typeOf,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ListRetailers))));