import { gql } from 'apollo-boost';


//----- Récupération des guideline
export const GET_BRAND_GUIDELINE = gql`
{
  guidelines{
    edges{
      node{
     		id
    		identifier
    		libelle   
        attributeGroup{
          id
        	identifier
        }
        guidelineData{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
              }
              media{
                id
                filePath
                type
              }
              locale{
                id
                code
              }
            }
          }
        }
        typographies{
          edges{
            node{
              id
              identifier
              typographySystem{
                id
                identifier
                libelle
                value
              }
              typographyDatas{
                edges{
                  node{
                    id
                    px
                    markup{
                      id
                    }
                  }
                }
              }
            }
          }
        }
        documents{
          edges{
            node{
							id
              filePath
              type
              size
            }
          }
        }
        colors{
          edges{
            node{
              id
        			codeHexa
              codeR
              codeG
              codeB
              markups{
                edges{
                  node{
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_GUIDELINE_BY_ID = gql`
  query guideline($id: ID!){
    guideline(id: $id){
      id
      identifier
      libelle   
      attributeGroup{
        id
        identifier
      }
      guidelineData{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
            }
            media{
              id
              filePath
              type
            }
            locale{
              id
              code
            }
          }
        }
      }
      typographies{
        edges{
          node{
            id
            identifier
            typographySystem{
              id
              identifier
              libelle
              value
            }
            typographyDatas{
              edges{
                node{
                  id
                  px
                  markup{
                    id
                  }
                }
              }
            }
          }
        }
      }
      documents{
        edges{
          node{
            id
            filePath
            type
            size
          }
        }
      }
      colors{
        edges{
          node{
            id
            codeHexa
            codeR
            codeG
            codeB
            markups{
              edges{
                node{
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;


//----- Ajouter un brand
/*
  Exemple de variables
  {
    "identifier" : "brand",
    "attributeGroup" : "/api/attribute-groups/1",
    "libelle": "Brand de démo",
    "status": 0
  }
*/
export const ADD_GUIDELINE = gql`
mutation AddGuideline($identifier: String!, $attributeGroup: String!, $libelle: String!, $status: Boolean, $documents: [String]){
	createGuideline(input: {identifier:$identifier, attributeGroup:$attributeGroup, libelle:$libelle, status:$status, documents:$documents}){
    guideline{
      id
      identifier
      libelle
      attributeGroup{
        id
      }
    }
  }  
}`;
  
//----- Modifier un brand
/*
  Exemple de variables
  {
    "id" : "/api/guidelines/5",
    "libelle":"TEST Edited",
    "documents": [
      "/api/media-objects/3",
      "/api/media-objects/4",
      "/api/media-objects/5"
    ]
  }
*/
export const UPDATE_GUIDELINE = gql`
mutation UpdateGuideline($id: ID!, $identifier: String,  $libelle: String, $attributeGroup: String, $status: Boolean, $documents: [String]){
	updateGuideline(input: {id: $id, identifier:$identifier, libelle:$libelle, attributeGroup:$attributeGroup, status:$status, documents:$documents}){
    guideline{
      id
      identifier
      libelle
      attributeGroup{
        id
      }
    }
  }  
}`;

//----- Supprimer un brand
/*
 Exemple de variables
  {
    "id" : "/api/guidelines/1"
  }
*/
export const DELETE_GUIDELINE = gql`
mutation DeleteGuideline($id: ID!){
	deleteGuideline(input: {id:$id}){
    guideline{
      id
    }
  }  
}`;


//--------------------
//----- DONNEES _GUIDELINE 
//--------------------

//----- Ajouter une valeur d'attribut à un brand

/*
 Exemple de variables
 {
  "value": "TEST",
	"guideline": "/api/guidelines/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const ADD_GUIDELINE_DATA = gql`
mutation AddGuidelineData($value: String, $guideline: String!, $attribute: String!, $locale: String!, $attributeOption: String, $media: String){
	createGuidelineData(input: {value:$value, guideline:$guideline, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    guidelineData{
      id
      value
      guideline{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;


//----- Modifier une valeur d'attribut d'un brand
/*
 Exemple de variables
 {
	"id": "/api/guideline-datas/14",
  "value": "TEST edition",
	"guideline": "/api/guidelines/1",
  "attribute": "/api/attributes/12", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_GUIDELINE_DATA = gql`
mutation UpdateGuidelineData($id: ID!, $value: String, $guideline: String!, $attribute: String!, $locale: String, $attributeOption: String, $media: String){
	updateGuidelineData(input: {id:$id, value:$value, guideline:$guideline, attribute:$attribute, locale:$locale, attributeOption:$attributeOption, media:$media}){
    guidelineData{
      id
      value
      guideline{
        id
      }
      attribute{
        id
      }
      locale{
        id
      }
      media{
        id
      }
      attributeOption{
        id
      }
    }
  }  
}`;

//----- Supprimer une valeur d'attribut d'un brand
/*
 Exemple de variables
  {
    "id" : "/api/guideline-datas/14"
  }
*/
export const DELETE_GUIDELINE_DATA = gql`
mutation DeleteGuidelineData($id: ID!){
	deleteGuidelineData(input: {id:$id}){
    guidelineData{
      id
    }
  }  
}`;


//--------------------
//----- MARKUPS

//----- Récupération des markups
export const GET_MARKUPS = gql`
{
  markups{
		edges{
      node{
        id
        libelle
        value
      }
    }
  }
}`;

//--------------------

//--------------------
//----- COLOR 
//--------------------

//----- Récupération des couleurs
export const GET_COLORS = gql`
{
  colors{
    edges{
      node{
        id
        codeHexa
        codeR
        codeG
        codeB
        markups{
          edges{
            node{
              id
            }
          }
        }
        guideline{id}
      }
    }
  }
}
`;

//----- Ajouter une couleur

/*
  Exemple de variables
  {
    "codeHexa": "#f5a001",
    "codeR": "245",
    "codeG": "160", 
    "codeB": "1",
    "markups": [
      "/api/markups/17",
      "/api/markups/18"
    ],
    "guideline": "/api/guidelines/5"
}
*/
export const ADD_COLOR = gql`
mutation addColor($codeHexa: String, $codeR: String, $codeG: String, $codeB: String, $markups: [String], $guideline: String!){
	createColor(input: {codeHexa:$codeHexa, codeR:$codeR, codeG:$codeG, codeB:$codeB, markups:$markups, guideline:$guideline}){
    color{
      id
    }
  }  
}`;



//----- Editer une couleur

/*
 Exemple de variables
 {
  "id" : "/api/colors/1",
  "codeHexa": "#f5a001",
	"codeR": "245",
  "codeG": "160", 
	"codeB": "1",
	"markups": [
    "/api/markups/17",
    "/api/markups/18"
  ],
	"guideline": "/api/guidelines/5"
}
*/
export const EDIT_COLOR = gql`
mutation updateColor($id:ID!, $codeHexa: String, $codeR: String, $codeG: String, $codeB: String, $markups: [String], $guideline: String){
	updateColor(input: {id:$id, codeHexa:$codeHexa, codeR:$codeR, codeG:$codeG, codeB:$codeB, markups:$markups, guideline:$guideline}){
    color{
      id
    }
  }  
}`;

//----- Supprimer une couleur

/*
Exemple de variables
{
  "id" : "/api/colors/1"
}
*/
export const DELETE_COLOR = gql`
mutation deleteColor($id:ID!){
	deleteColor(input: {id:$id}){
    color{
      id
    }
  }  
}`;



//--------------------

//--------------------
//----- TYPOGRAPHY 
//--------------------

//----- Récupération des typos system

export const GET_SYSTEM_TYPOS = gql`
{
	typographySystems{
    edges{
      node{
        id
        identifier
        libelle
        value
      }
    }
  }
}`;

//----- Récupération des typos system

export const GET_TYPOS = gql`
{
	typographies{
    edges{
      node{
        id
        identifier
        typographyDatas{
          edges{
            node{
              id
              markup{
                id
              	identifier
              }
              px
            }
          }
        }
        typographySystem{
          id
        }
        guideline{
          id
        }
      }
    }
  }
}`;



//----- Ajouter une typo

/*
 Exemple de variables
 {
  "identifier": "test typo",
	"guideline": "/api/guidelines/5",
	"typographySystem": "/api/typography-systems/61"
}
*/
export const ADD_TYPO = gql`
mutation addTypography($identifier: String!, $guideline: String!, $typographySystem: String!){
	createTypography(input: {identifier:$identifier, guideline:$guideline, typographySystem:$typographySystem}){
    typography{
      id
    }
  }  
}`;

//----- Editer une typo

/*
 Exemple de variables
 {
  "id": "/api/typographies/1",
  "identifier": "test typo edited",
	"guideline": "/api/guidelines/5",
	"typographySystem": "/api/typography-systems/61"
}
*/
export const EDIT_TYPO = gql`
mutation updateTypography($id: ID!, $identifier: String, $guideline: String, $typographySystem: String){
	updateTypography(input: {id:$id, identifier:$identifier, guideline:$guideline, typographySystem:$typographySystem}){
    typography{
      id
    }
  }  
}`;

//----- Supprimer une couleur

/*
 Exemple de variables
 {
  "id": "/api/typographies/1"
}
*/
export const DELETE_TYPO = gql`
mutation deleteTypography($id: ID!){
	deleteTypography(input: {id:$id}){
    typography{
      id
    }
  }  
}`;


//----- Ajouter une typo datas

/*
Exemple de variables
{
  "typography": "/api/typographies/1",
	"markup": "/api/markups/18",
	"px": 12
}
*/
export const ADD_TYPO_DATA = gql`
mutation addTypographyData($typography: String!, $markup: String!, $px: String!){
	createTypographyData(input: {typography:$typography, markup:$markup, px:$px}){
    typographyData{
      id
    }
  }  
}`;


//----- Modifier une typo datas

/*
 Exemple de variables
 {
  "id": "/api/typography-datas/1",
  "typography": "/api/typographies/1",
	"markup": "/api/markups/18",
	"px": 12
}
*/
export const EDIT_TYPO_DATA = gql`
mutation updateTypographyData($id: ID!, $typography: String, $markup: String, $px: String){
	updateTypographyData(input: {id: $id, typography:$typography, markup:$markup, px:$px}){
    typographyData{
      id
    }
  }  
}`;


//----- Supprimer une typo datas

/*
Exemple de variables
{
  "id": "/api/typography-datas/1"
}
*/
export const DELETE_TYPO_DATA = gql`
mutation deleteTypographyData($id: ID!){
	deleteTypographyData(input: {id: $id}){
    typographyData{
      id
    }
  }  
}`;