import { FormInputTypes  } from '../../../shareable/types';
import { getDefaultLink, getDefaultText, getImageDefault } from '../../helpers';


export default {
    name: "Bloc secondaire page de résultat",
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        position: {
            type: FormInputTypes.SELECT,
            label: "Position des éléments",
            value: 'left',
            params: [{label: 'À Gauche', value: 'left'}, {label: 'À Droite', value: 'right'}, {label: 'Au dessus', value: 'top'}, {label: 'Au dessous', value: 'bottom'}]
        },
        padding: {
            label: 'Padding',
            type: FormInputTypes.TEXT,
            value: null,
        }
    },
    blocks:{
        image: getImageDefault(),
        title: getDefaultText("Titre", "Le jeu n'est pas fini", 38, '#FFFFFF', 'rgba(0,0,0,0)', 'initial', 'center'),
        button: getDefaultLink("Bouton", "Voir les produits", '', 24, '#53839D', '#FFFFFF',  null, 1, '#53839D', 0),
    }
};
