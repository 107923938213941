import { 
    ROUTE_CATALOGS_DETAIL,
    ROUTE_CATALOGS_LIST,
    ROUTE_CATALOGS_SETTINGS, 
    ROUTE_CHANNELS, 
    ROUTE_CHANNELS_BRANDS, 
    ROUTE_CHANNELS_CMS, 
    ROUTE_CHANNELS_CONNECTORS,
    ROUTE_CHANNELS_CONTENTS,
    ROUTE_CHANNELS_MARKETPLACES,
    ROUTE_CHANNELS_MEDIAS,
    ROUTE_CHANNELS_SOCIALS,
    ROUTE_COMMUNITIES_MINISITES,
    ROUTE_COMMUNITIES_MINISITES_DETAIL,
    ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS,
    ROUTE_COMMUNITIES_MINISITES_DIFFUSION,
    ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE,
    ROUTE_COMMUNITIES_NEWSLETTERS,
    ROUTE_CONTENU_ADD,
    ROUTE_CONTENU_ATTRIBUTES,
    ROUTE_CONTENU_CATEGORIES,
    ROUTE_CONTENU_LIST,
    ROUTE_CONTENU_LIST_DETAIL,
    ROUTE_CONTENU_SETTINGS,
    ROUTE_HISTORY,
    ROUTE_HISTORY_IMPORT,
    ROUTE_HOME,
    ROUTE_MEDIAS_ATTRIBUTS,
    ROUTE_MEDIAS_CATEGORIES,
    ROUTE_MEDIAS_LIST,
    ROUTE_MEDIAS_SETTINGS,
    ROUTE_MEDIAS_TAGS,
    ROUTE_PRODUCTS_ADD,
    ROUTE_PRODUCTS_ATTRIBUTS,
    ROUTE_PRODUCTS_CATEGORIES,
    ROUTE_PRODUCTS_COLLECTIONS,
    ROUTE_PRODUCTS_COMPLETUDE,
    ROUTE_PRODUCTS_LIST,
    ROUTE_PRODUCTS_LIST_DETAIL,
    ROUTE_PRODUCTS_SETTINGS,
    ROUTE_RESTAURANTS,
    ROUTE_SALES_APPLICATIONS,
    ROUTE_SALES_APPLICATIONS_DETAILS,
    ROUTE_SALES_APPLICATIONS_NOTIFICATIONS,
    ROUTE_SALES_ESHOP, ROUTE_SALES_ESHOP_DETAIL,
    ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES,
    ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET,
    ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
    ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING,
    ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH,
    ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK,
    ROUTE_SALES_ESHOP_DETAIL_STATS,
    ROUTE_SALES_ESHOP_FOOTER,
    ROUTE_SALES_GLOBAL,
    ROUTE_SALES_MARKETPLACES,
    ROUTE_SALES_MARKETPLACES_DETAILS,
    ROUTE_SALES_RETAILERS,
    ROUTE_SALES_SHOPS,
    ROUTE_SALES_SHOPS_DETAILS,
    ROUTE_SALES_SOCIAL_COMMERCE,
    ROUTE_SALES_SOCIAL_COMMERCE_DETAILS,
    ROUTE_SETTINGS_ACCESS,
    ROUTE_SETTINGS_DETAIL_USER,
    ROUTE_SETTINGS_GUIDELINE,
    ROUTE_SETTINGS_GUIDELINE_ADD,
    ROUTE_SETTINGS_GUIDELINE_UPDATE,
    ROUTE_SETTINGS_USERS,
    ROUTE_SETTINGS_USERS_GROUPS,
    ROUTE_SHELLS,
    ROUTE_SHELLS_CUSTOM,
    ROUTE_SHELLS_CUSTOM_ATTRIBUTES,
    ROUTE_SHELLS_CUSTOM_DETAIL,
    ROUTE_SHELLS_CUSTOM_LIST,
    ROUTE_SHELLS_LIST,
    ROUTE_STATS_ORDERS,
    ROUTE_STATS_ASSETS,
    ROUTE_STATS_CAMPAIGNS,
    ROUTE_STATS_RETAILERS,
    ROUTE_SYSTEM_CURRENCIES,
    ROUTE_SYSTEM_GROUPRATES,
    ROUTE_SYSTEM_LANGUAGES,
    ROUTE_SYSTEM_TAXRATES,
    ROUTE_SALES_ESHOP_DETAIL_CROSSSELL,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS,
    ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME,
    ROUTE_HISTORY_IMPORT_PRODUCT,
    ROUTE_STATS_ORDERS_BY_SITE,
    ROUTE_STATS_ORDERS_BY_MARKETPLACES,
    ROUTE_ATTRIBUTS_CATEGORIES,
    ROUTE_COMMUNITIES_CMS,
    ROUTE_COMMUNITIES_CMS_DETAIL,
    ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING,
    ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS,
    ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES,
    ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS,
    ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER,
    ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS }
from "../constants/route-names";
import { menuEntry } from "./menuEntry";
import { ALERT_ERROR } from "../constants/alert-types";

export function checkRouting(props) {
    const goTo = (route) => {
        props.history.push({
            pathname: route,
        });
    };
    const getPath = props?.match?.path !== '/' ? props.match.path : props.location?.pathname
    // switch (getPath) {
    //     case ROUTE_STATS_ORDERS:
    //     case ROUTE_STATS_ORDERS_BY_SITE:
    //     case ROUTE_STATS_ORDERS_BY_MARKETPLACES:
    //         if (!menuEntry("dashboard_statisticsOrders")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_STATS_RETAILERS:
    //         if (!menuEntry("dashboard_statisticsGames")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_STATS_RETAILERS:
    //         if (!menuEntry("dashboard_analytics")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_HISTORY:
    //     case ROUTE_HISTORY_IMPORT:
    //     case ROUTE_HISTORY_IMPORT_PRODUCT:
    //         if (!menuEntry("history")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_HISTORY_IMPORT:
    //         if (!menuEntry("history_imports")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_GLOBAL:
    //         if (!menuEntry("sells_global")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_ESHOP:
    //     case ROUTE_SALES_ESHOP_DETAIL:
    //     case ROUTE_SALES_ESHOP_DETAIL_HEADERS_ASSET:
    //     case ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME:
    //     case ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_HOME_CHILDS:
    //     case ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING:
    //     case ROUTE_SALES_ESHOP_DETAIL_LANDINGS_ASSET_LANDING_CHILDS:
    //     case ROUTE_SALES_ESHOP_DETAIL_SHOP_THE_LOOK:
    //     case ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING:
    //     case ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH:
    //     case ROUTE_SALES_ESHOP_DETAIL_CROSSSELL:
    //     case ROUTE_SALES_ESHOP_DETAIL_INFOS_MANAGEMENTS:
    //     case ROUTE_SALES_ESHOP_FOOTER:
    //     case ROUTE_SALES_ESHOP_DETAIL_TOP_MESSAGES:
    //     case ROUTE_SALES_ESHOP_DETAIL_CACHE_CATEGORIES:
    //     case ROUTE_SALES_ESHOP_DETAIL_DISCOUNTS:
    //         if (!menuEntry("sells_eshop")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_APPLICATIONS:
    //     case ROUTE_SALES_APPLICATIONS_DETAILS:
    //     case ROUTE_SALES_APPLICATIONS_NOTIFICATIONS:
    //         if (!menuEntry("sells_applications")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_SOCIAL_COMMERCE:
    //     case ROUTE_SALES_SOCIAL_COMMERCE_DETAILS:
    //         if (!menuEntry("sells_social_commerce")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_MARKETPLACES:
    //     case ROUTE_SALES_MARKETPLACES_DETAILS:
    //         if (!menuEntry("sells_marketplaces")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_SHOPS:
    //     case ROUTE_SALES_SHOPS_DETAILS:
    //         if (!menuEntry("sells_shops")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SALES_RETAILERS:
    //         if (!menuEntry("sells_retailers")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_COMMUNITIES_MINISITES_DIFFUSION_PAGE:
    //     case ROUTE_COMMUNITIES_MINISITES_DETAIL_STATS:
    //         if (!menuEntry("community_contests")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_COMMUNITIES_MINISITES:
    //     case ROUTE_COMMUNITIES_MINISITES_DETAIL:
    //     case ROUTE_COMMUNITIES_MINISITES_DIFFUSION:
    //         if (!menuEntry("community_minisites")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_COMMUNITIES_NEWSLETTERS:
    //         if (!menuEntry("community_newsletters")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_COMMUNITIES_CMS:
    //     case ROUTE_COMMUNITIES_CMS_DETAIL:
    //     case ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING:
    //     case ROUTE_COMMUNITIES_CMS_DETAIL_LANDINGS_ASSET_LANDING_CHILDS:
    //     case ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER:
    //     case ROUTE_COMMUNITIES_CMS_DETAIL_FOOTERS_ASSET_FOOTER_CHILDS:
    //         if (!menuEntry("community_cms")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CATALOGS_LIST:
    //     case ROUTE_CATALOGS_DETAIL:
    //         if (!menuEntry("catalogs")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;

    //     case ROUTE_PRODUCTS_LIST:
    //     case ROUTE_PRODUCTS_LIST_DETAIL:
    //     case ROUTE_PRODUCTS_ADD:
    //         if (!menuEntry("products_listing")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }

    //     break;
    //     case ROUTE_PRODUCTS_SETTINGS:
    //     case ROUTE_PRODUCTS_CATEGORIES:
    //     case ROUTE_PRODUCTS_ATTRIBUTS:
    //     case ROUTE_ATTRIBUTS_CATEGORIES:
    //     case ROUTE_PRODUCTS_COMPLETUDE:
    //     case ROUTE_PRODUCTS_COLLECTIONS:
    //         if (!menuEntry("products_configuration")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_MEDIAS_LIST:
    //         if (!menuEntry("medias_listing")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_MEDIAS_SETTINGS:
    //     case ROUTE_MEDIAS_CATEGORIES:
    //     case ROUTE_MEDIAS_ATTRIBUTS:
    //     case ROUTE_MEDIAS_TAGS: 
    //         if (!menuEntry("medias_configuration")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CONTENU_LIST:
    //     case ROUTE_CONTENU_LIST_DETAIL:
    //     case ROUTE_CONTENU_ADD:
    //         if (!menuEntry("content_listing")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CONTENU_SETTINGS:
    //     case ROUTE_CONTENU_CATEGORIES:
    //     case ROUTE_CONTENU_ATTRIBUTES:
    //         if (!menuEntry("content_configuration")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SHELLS:
    //     case ROUTE_SHELLS_LIST:
    //     case ROUTE_SHELLS_CUSTOM:
    //     case ROUTE_SHELLS_CUSTOM_LIST:
    //     case ROUTE_SHELLS_CUSTOM_ATTRIBUTES:
    //         if (!menuEntry("shells")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_BRANDS:
    //         if (!menuEntry("connectors_brands")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_MARKETPLACES:
    //         if (!menuEntry("connectors_marketplaces")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_SOCIALS:
    //         if (!menuEntry("connectors_social_networks")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_CMS:
    //         if (!menuEntry("connectors_cms")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_CONTENTS:
    //         if (!menuEntry("connectors_contents")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_MEDIAS:
    //         if (!menuEntry("connectors_medias")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_CHANNELS_CONNECTORS:
    //         if (!menuEntry("connectors_custom")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SETTINGS_GUIDELINE:
    //     case ROUTE_SETTINGS_GUIDELINE_ADD:
    //     case ROUTE_SETTINGS_GUIDELINE_UPDATE:
    //         if (!menuEntry("settings_brand_guidelines")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SETTINGS_USERS:
    //     case ROUTE_SETTINGS_DETAIL_USER:
    //         if (!menuEntry("settings_users")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SETTINGS_USERS_GROUPS:
    //     if (!menuEntry("settings_manage_users_groups")) {
    //         props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //         goTo(ROUTE_HOME);
    //     }
    //     break;
    //     case ROUTE_SETTINGS_ACCESS:
    //     if (!menuEntry("settings_manage_accesses")) {
    //         props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //         goTo(ROUTE_HOME);
    //     }
    //     break;
    //     case ROUTE_SYSTEM_CURRENCIES:
    //         if (!menuEntry("system_currencies")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SYSTEM_LANGUAGES:
    //         if (!menuEntry("system_locales")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SYSTEM_TAXRATES:
    //         if (!menuEntry("system_taxrates")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case ROUTE_SYSTEM_GROUPRATES:
    //         if (!menuEntry("system_taxrates")) {
    //             props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //             goTo(ROUTE_HOME);
    //         }
    //     break;
    //     case "/":
    //         break;
    //     default:
    //         props.snack(ALERT_ERROR,`Vous n'avez pas les droits suffisants pour accéder à cette page`);
    //         goTo(ROUTE_HOME);
    // }
}
