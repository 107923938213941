import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formContentDuplicate(allState) {
    let generalInputs   = [
        {
            type: 'select',
            label: 'Catalogue cible',
            helper: {
                text: 'Choisissez un catalogue pour la duplication',
                link: false,
            },
            required: true,
            multiselect: true,
            stateName: 'catalogSelected',
            value: allState.listCatalogsDuplicate?.filter(e => e.node.id !== allState.catalogIdToDuplicate).map(catalog => {
                return ({
                    value: catalog.node.id,
                    label: catalog.node.libelle || catalog.node.identifier
                });
            })
        }
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: 'Dupliquer un catalogue',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour dupliquer votre catalogue`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: FORM,
            finalStep: {
                title: 'Et voilà !',
                subtitle: 'Valider la duplication de ce catalogue',
                textButton: 'Valider'
            },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}