import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FolderIcon from '@material-ui/icons/Folder';
import Button from '../../../../ui/button/Button';
import Chip from '@material-ui/core/Chip';
import colors from '../../../../../config/theme/colors'
import styled from 'styled-components';
import pngBackground from '../../../../../assets/images/medias/pixelBackground.jpg'
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';
import ShortTextIcon from '@material-ui/icons/ShortText';
import LabelSharpIcon from '@material-ui/icons/LabelSharp';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { getFileConfig } from '../../../../../js/helpers/files';
import moment from 'moment';
import DialogModal from '../../../../ui/dialog/DialogModal';
import { withTranslation } from 'react-i18next';

const ImageContainer = styled(Grid)`
    /* width: 100%; */
    /* height: 100%; */
    max-width: 90%;
    max-height: 90%;
    overflow:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px){
        max-height: 400px !important;
        img,video{
            max-height: 400px !important;
        }
    }
    img,video{
        background-image: ${props => props.imagetype === 'PNG' ? 'url(' + pngBackground + ')' : 'none'};
        background-size: cover;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        box-shadow: 1px 1px 10px -3px ${colors.grey.lighter.hue800};
        object-fit: contain;
    }
`;

const CloseModal=styled(CloseIcon)`
    position : absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: all .5s;

    &:hover{
        transform: scale(1.1);
    }
`;

const MediaOption=styled(Grid)``;

function getMediaType(type){
    if(type){
        var res=type.match(/\.[0-9a-z]+$/i)?.[0].replace('.', '').toUpperCase();
        return res;
    }
}

function MediaModal(props) {

    const [openConfirmation,setOpenConfirmation] = useState(false);

    let modalData       = props.modalData; 
    let size            = modalData.size;
    // let currentIndex    = modalData.index;
    let round           = 0; 
    let isVideo = ['video/mpeg','video/webm','video/mp4'].includes(modalData.type) ? true : false;
    let isPDF = ['application/pdf'].includes(modalData.type) ? true : false;
    let mimeType        = modalData.type;
    let type        = {
        value: getMediaType(modalData.filePath),
        style: {
            background: getFileConfig(modalData.type).color,
            color: 'white',
            fontWeight: 'bold',
        }
    }
    let convertSize;
    let unit;

    if(size >= 1048576){
        convertSize = size/1048576;
        unit        = 'Mo';
        round       = 1;
    }
    else if(size >= 1024){
        convertSize = size/1024;
        unit        = 'Ko';
    }
    else{
        convertSize = size/1024;
        unit        = 'Ko';
    }

    const useStyles = makeStyles((theme) => ({
        asset: {
            margin: 'auto',
            maxHeight: '90%',
            maxWidth: '80%',
            objectFit: 'contain',
            outline: 'none',
        },
    }));

    convertSize=convertSize.toFixed(round);
    const classes   = useStyles();
    return (
        <>
            <Modal
                open                = {props.open}
                onClose             = {props.onClose}
                aria-labelledby     = "simple-modal-title"
                aria-describedby    = "simple-modal-description"
                style               = {{border:'0px',outline:'none', display: 'flex'}}
                BackdropComponent   = {Backdrop}
                BackdropProps       = {{timeout: 500}}
            >
                <Fade in={props.open}>
                    {isVideo ?
                        <video className={classes.asset} controls autoPlay>
                            <source src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`} type={mimeType}/>
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                        : isPDF ?
                        <iframe className={classes.asset} 
                        src={`${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`} 
                        type={mimeType}
                        title={modalData.name}
                        width="100%"
                        height="100%"
                        allowFullScreen
                        loading='lazy'
                        seamless
                        allowTransparency
                        />
                        :
                        <img className={classes.asset} src={getFileConfig(modalData.type).image ? getFileConfig(modalData.type).image : `${process.env.REACT_APP_API_ROOT}/medias/${modalData.filePath }`}/>
                    }
                </Fade>
            </Modal>
        </>

    );
}

export default withTranslation()(MediaModal);
