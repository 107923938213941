import { FormInputTypes } from '../../../shareable/types';
import { getImageDefault } from '../../helpers';
import { getDefaultText} from '../../helpersBook';

export default {
    name: 'Header',
    blocks: {
        logo: getImageDefault(null, "Logo"),
        title: getDefaultText("Titre", "Du 20 octobre au 5 décembre 2021"),
    }
};
