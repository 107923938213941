import React from 'react';

import { Box, Grid } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import { withRouter } from "react-router-dom";
import * as moment from 'moment';
import { ROUTE_BUILDER, ROUTE_BUILDER_PREVIEW } from "../../../../js/constants/route-names";
import { withTranslation } from 'react-i18next';

const BoxCustom = styled(Box)`
    width: auto;
`;
const ReturnLink = styled(Typography)`
    color: ${colors.blue.darker.hue300};
    width: 70px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;
const BoxImage = styled(Box)`
    img{
        filter : ${props => props.status === 'disabled' ? 'grayscale(1)' : 'grayscale(0)'};
        opacity: ${props => props.status === 'disabled' ? '0.3' : '1'};
        max-width: 90%;
        width: 200px;
    }
`;
const Title = styled(Typography)`
    color: ${colors.black.regular};
    line-height: 32px;
`;
const StatusMinisite = styled.span`
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: relative;
    background: ${
        props => props.status === 'disabled' 
            ? colors.grey.lighter.hue600 
            : props.status === 'incomplete' 
                ? colors.orange.regular
                : colors.green.regular
    }
`;
const StatusTypography = styled(Typography)`
    display: inline-block;
    padding-left: 5px;
    color: ${
        props => props.status === 'disabled' 
            ? colors.grey.lighter.hue600 
            : props.status === 'incomplete' 
                ? colors.orange.regular
                : colors.green.regular
    }
`;
const BoxCheck = styled(Box)`
    position: absolute;
    width: 15px;
    height: 15px;
    top:0;
    bottom:0;
    left:0;
    right:0;
    svg{
        width: 100%;
        height: auto;
        fill: ${colors.white}
    }
`;
const PageBuilderZone = styled.div`
    width: 100%;
    min-height: 1000px;
    border: 1px solid ${colors.grey.lighter.hue900};
`;

function CardAssetsPage(props) {
    let content     = props.content;
    let asset       = props.asset;
    let updatedAt   = props.content.updatedAt;
    let local       = moment.tz.guess();
    let page        = props.page;

    if (updatedAt){
        updatedAt = moment.tz(updatedAt, local).format('L');
    }
    return (
        <Box p={2} pt={0}>
            {/* <BoxCustom onClick={props.history.goBack} style={{marginBottom: 25}}>
                <ReturnLink variant={'body2'}>&lt; {props.t("spread.active_assets.return")}</ReturnLink>
            </BoxCustom> */}
            <Grid container>                    
                <Grid item lg={2} xs={4}>
                    <BoxImage>    
                        <img src={content.image} alt="builder-page" />
                    </BoxImage>
                </Grid>
                <Grid item lg={10} xs={8}>
                    <Grid container>
                        <Grid item xs={12} style={{minHeight: 175}}>
                            <Box mt={4}>
                                <Title variant={"h3"}>
                                    {content.title}
                                </Title>
                            </Box>
                            <Box mt={1} style={{display: 'flex'}} alignItems="center">
                                <StatusMinisite status={content.status}>
                                    {
                                        content.status === 'completed' ? <BoxCheck><CheckIcon/> </BoxCheck>: null
                                    }
                                </StatusMinisite>
                                <StatusTypography status={content.status}>{content.status === "disabled" 
                                    ? props.t("spread.active_assets.pageNotConfigured")
                                    : content.status === "incomplete" 
                                        ? props.t("spread.active_assets.pageConfigurationNotComplete") 
                                        : props.t("spread.active_assets.pageConfigurationComplete")}
                                </StatusTypography>
                            </Box>
                            <Box mt={1}>
                                <Typography variant="h4">
                                    {updatedAt 
                                        ? props.t("spread.active_assets.lastUpdates", {updatedAt: updatedAt})
                                        : props.t("spread.active_assets.noUpdates")
                                    }
                                </Typography>
                            </Box>
                            {
                                props.canUpdate ?
                                <Box mt={4} mb={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button onClick={() => {
                                        window.open(`${window.location.origin}${ROUTE_BUILDER.replace(':assetId', asset.identifier).replace(':pageId', page.node.assetGamePageType ? page.node.assetGamePageType.identifier : page.node.assetMinisitePageType.identifier)}`, '_blank');
                                    }}>{props.t("spread.active_assets.updatePage")}</Button>
                                </Box>
                                : null
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <PageBuilderZone>
                                <iframe title="builder-preview" src={`${window.location.origin}${ROUTE_BUILDER_PREVIEW.replace(':assetId', asset.identifier).replace(':pageId', page.node.assetGamePageType ? page.node.assetGamePageType.identifier : page.node.assetMinisitePageType.identifier)}`} width={200} height={200} style={{ width: '100%', height: '1000px', border: 0 }} />
                            </PageBuilderZone>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
export default withTranslation()(withRouter(CardAssetsPage));