import { getDefaultButton, getDefaultTextSimple } from '../../helpers';
import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Bandeau lien',
    inputs: {
        category: {
            label: 'Catégorie liée',
            type: FormInputTypes.CATEGORY,
            value: null
        }
    },
    blocks: {
        title: getDefaultTextSimple("Titre", "Ajouter votre titre", 20, "#666"),
        button: getDefaultButton("Bouton", "Cliquez ici", 20, "#666", {backgroundColor: "#fff"} ),
        image: {
            title: "Image de fond",
            name: "Image de fond",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/1100x350"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        },
    }
}