import { FormInputTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultTextSimple } from '../../helpers';

export default {
    paddingTop: 95,
    paddingBottom: 90,
    name: 'Module de contact',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#fffff'
        },
        lastname: {
            label: 'Champ nom',
            type: FormInputTypes.TEXT,
            value: 'Nom*'
        },
        email: {
            label: 'Champ email',
            type: FormInputTypes.TEXT,
            value: 'Email*'
        },
        subject: {
            label: 'Champ sujet',
            type: FormInputTypes.TEXT,
            value: 'Sujet*'
        },
        message: {
            label: 'Champ message',
            type: FormInputTypes.TEXT,
            value: 'Votre message*'
        }
    },
    blocks: {
        title: getDefaultTextSimple("Titre module", "Vous souhaitez nous contacter ?", 24, "#4d4d4d", 'initial'),
        followUs: getDefaultTextSimple("Texte réseaux", "Nous suivre", 24, "#4d4d4d", 'center'),
        button: getDefaultButton("Bouton envoyer", "Envoyer", 16, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0, null, 10, 20, 'uppercase'),
    }
};