import { gql } from 'apollo-boost';

export const GET_CATEGORY_FILTERS = gql`
{
  categoryFilters{
    edges{
      node{
        id
        operator
        value
        createdAt
        category{
          id
        }
        attribute{
          id
          identifier
        }
        attributeOption{
          id
          identifier
        }
      }
    }
  }
}
`;

/*
{
  "id": "/api/category-filters/1"
}
 */
export const GET_CATEGORY_FILTER = gql`
query getCategoryFilter($id: ID!) {
  categoryFilter(id: $id) {
    id
    operator
    value
    createdAt
    category{
      id
    }
    attribute{
      id
      identifier
    }
    attributeOption{
      id
      identifier
    }
  }
}
`;

/*
{
  "operator": "!=",
  "value": "différent",
  "createdAt": "00:00:00",
  "category": "/api/categories/1",
  "attribute": "/api/attributes/1"
}
 */
export const CREATE_CATEGORY_FILTER = gql`
mutation createCategoryFilter($operator: String!, $value: String, $createdAt: String!, $category: String!, $attribute: String, $attributeOption: String) {
  createCategoryFilter(input:{operator: $operator, value: $value, createdAt: $createdAt, category: $category, attribute: $attribute, attributeOption: $attributeOption}) {
    categoryFilter{
      id
      operator
      value
      createdAt
      category{
        id
      }
      attribute{
        id
        identifier
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;

/*
{
  "id": "/api/category_filters/1",
  "operator": "!=",
  "value": "différents",
  "category": "/api/categories/1",
  "attribute": "/api/attributes/1"
}
 */
export const UPDATE_CATEGORY_FILTER = gql`
mutation updateCategoryFilter($id: ID!, $operator: String, $value: String, $category: String, $attribute: String, $attributeOption: String) {
  updateCategoryFilter(input:{id: $id, operator: $operator, value: $value, category: $category, attribute: $attribute, attributeOption: $attributeOption}) {
    categoryFilter{
      id
      operator
      value
      createdAt
      category{
        id
      }
      attribute{
        id
        identifier
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;

/*
{
  "id": "/api/category_filters/1"
}
 */
export const DELETE_CATEGORY_FILTER = gql`
mutation deleteCategoryFilter($id: ID!) {
  deleteCategoryFilter(input:{id: $id}) {
    categoryFilter{
      id
    }
  }
}
`;