import { FormInputTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault } from '../../helpers';

export default {
    name: 'Header',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        primaryColor: {
            label: 'Couleur principale',
            type: FormInputTypes.COLOR,
            value: '#000000'
        },
        secondaryColor: {
            label: 'Couleur secondaire',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
    },
    blocks: {
        logo: getImageDefault(null, "Logo"),
        title: getDefaultText("Titre", "Jeu concours", 16, "#53839D", "#fff", 'initial', 'center'),
    }
};