import { FormInputTypes } from '../../../shareable/types';
import { getDefaultTextarea } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Section de titre',
    inputs: {
        sideImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: 'https://via.placeholder.com/333x880/F0F7FA/C5DEEC',
            isCroppable: true
        },
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#70aecf'
        }
    },
    blocks: {
        title: getDefaultTextarea("Titre", "x. Titre de la partie", true, "#FFFFFF", "rgba(0,0,0,0)", 'initial', 'initial', 40),
        desc: getDefaultTextarea("Description", "Description de la partie", true, "#FFFFFF", "rgba(0,0,0,0)", 'initial', 'initial', 22),
    }
};