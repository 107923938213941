import { gql } from 'apollo-boost';

//----- Récupération des locales
export const GET_LOCALES = gql`
{
  locales{
    edges{
      node{
        id
        code
        libelle
      }
    }
  }
}
`;

//----- Ajouter une locale
/*
 Exemple de variables
  {
    "code" : "DEMO,
    "libelle" : "Libelle de demo"
  }
*/
export const ADD_LOCALE = gql`
mutation AddLocale($code: String!, $libelle: String!){
	createLocale(input: {code:$code, libelle:$libelle}){
    locale{
      id
      code
      libelle
    }
  }  
}`;

//----- Modifier une locale
/*
 Exemple de variables
  {
    "id" : "/api/locales/1",
    "code" : "DEMO,
    "libelle" : "Libelle de demo edited"
  }
*/
export const UPDATE_LOCALE = gql`
mutation UpdateLocale($id: ID!,$code: String, $libelle: String){
	updateLocale(input: {id:$id, code:$code, libelle:$libelle}){
    locale{
      id
      code
      libelle
    }
  }  
}`;

//----- Supprimer une locale
/*
 Exemple de variables
  {
    "id" : "/api/locales/1"
  }
*/
export const DELETE_LOCALE = gql`
  mutation deleteLocale($id: ID!){
    deleteLocale(input: {id:$id}){
      locale{
        id
      }
    }  
  }`;
