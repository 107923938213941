import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  BannerImage,
  RecapWishlist,
  CustomComponent,
} from "../../../../shareable/components/assets/game/components";

import { Toolbar } from "../../../../shareable/tools";
import { ItemTypes } from "../../../../shareable/types";

// DND
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { recapWishlist } from "../../../../utils/config/game";

export const getComponent = (
  element,
  index,
  preview,
  Droppable,
  updateConfig,
  deleteComponent,
  inputCallback,
  paramsGeneral,
  apiConfig,
  asset
) => {
  let lang = asset.locales.edges[0]?.node.code;

  switch (element.type) {
    case ItemTypes.RECAP_WISHLIST:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <RecapWishlist
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
              lang={lang ? lang : "fr_FR"}
              toolbarSection={
                <Toolbar
                  name={element.data.name}
                  onEdit={() => inputCallback(element.key)}
                  onDelete={() => deleteComponent(element.key)}
                />
              }
              toolbarBlockSocial={
                <Toolbar
                  name={element.data.blocks.socialBlock.name}
                  onEdit={() => inputCallback(element.key, "socialBlock")}
                />
              }
            />
            <Droppable
              type={ItemTypes.RECAP_WISHLIST}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
    case ItemTypes.BANNER_IMAGE:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <Droppable
              type={ItemTypes.BANNER_IMAGE}
              id={element.key - 1}
              index={index - 1}
            />
            <BannerImage
              id={element.key}
              noSticky={preview}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              update={updateConfig}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </BannerImage>
          </DndProvider>
        </Fragment>
      );
  
    case ItemTypes.CMS_CUSTOM:
      return (
        <Fragment key={element.key}>
          <DndProvider backend={HTML5Backend}>
            <CustomComponent
              id={element.key}
              preview={preview}
              data={element.data}
              inputCallback={inputCallback}
              spread={true}
            >
              <Toolbar
                name={element.data.name}
                onEdit={() => inputCallback(element.key)}
                onDelete={() => deleteComponent(element.key)}
              />
            </CustomComponent>
            <Droppable
              type={ItemTypes.CMS_CUSTOM}
              id={element.key}
              index={index}
            />
          </DndProvider>
        </Fragment>
      );
  }
};
