import styled from 'styled-components';

export default styled.p`
    font-size: ${props => props.fontSize ? `${props.fontSize / 16}rem` : '1rem'};
    color: ${props => props.color || '#000'};
    text-transform: ${props => props.textTransform ? props.textTransform : 'initial'};
    &.center{
        text-align: center;
    }
    &.w100{
        width: 100%;
    }
    &.white{
        color: white;
    }
`; 