import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText, getDefaultTextarea, getImageDefault, getDefaultListEtapesQuiz, getDefaultListResultQuiz, imageDefault } from '../../helpers';


export default {
    name: 'Test de personnalité',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#F0F7FA'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        backgroundImageTablet: {
            label: "Image de fond tablet",
            type: FormInputTypes.IMAGE,
            value: null,
          },
          backgroundImageMobile: {
            label: "Image de fond mobile",
            type: FormInputTypes.IMAGE,
            value: null,
          },
        questionType: {
            type: FormInputTypes.SELECT,
            label: "Format des réponses",
            value: 'image',
            params: [{label: 'Image + texte', value: 'imageAndText'}, {label: 'Image', value: 'image'}]
        },        
    },
    blocks: {
        popupStart:{
            id: uuidv4(),
            title: "Popup d'explication",
            name: "Popup d'explication",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                maxWidth: {
                    type: FormInputTypes.SLIDER,
                    value: 1080,
                    label: "Taille maximale (px)",
                    params: { max: 1920, min: 360, step: 10 },
                },
                width: {
                    type: FormInputTypes.SLIDER,
                    value: 90,
                    label: "Taille (%)",
                    params: { max: 100, min: 20, step: 1 },
                },
                paddingTop: {
                    type: FormInputTypes.SLIDER,
                    value: 25,
                    label: "Padding Top (px)",
                    params: { max: 200, min: 0, step: 1 },
                },
                paddingBottom: {
                    type: FormInputTypes.SLIDER,
                    value: 25,
                    label: "Padding Bottom (px)",
                    params: { max: 200, min: 0, step: 1 },
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
                
            },
            blocks: {
                titlePopup: getDefaultText('Titre', "C’EST PARTI !", 50, "#53839D", null, 'uppercase', 'initial'),
                descPopup: getDefaultTextarea('Description du jeu', "Répondez à 6 questions pour découvrir votre résultat", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'center', 20),
                imgPopup: getImageDefault(null, "Image Popup"),
                buttonPopup: getDefaultButton("Bouton", "Suivant", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
            }
        },
        step:{
            title: "Liste des étapes",
            name: "Liste des étapes",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.GAME_QUIZ_STEP,
            config: [
                getDefaultListEtapesQuiz(),
                getDefaultListEtapesQuiz(),
            ]
        },
        buttonText: getDefaultButton("Lien", "Voir plus", 24, '#53839D', '#FFFFFF',  null, 1, '#53839D', 0),
        result:{
            title: "Liste des étapes",
            name: "Liste des étapes",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.GAME_QUIZ_RESULT,
            config: [
                getDefaultListResultQuiz(),
                getDefaultListResultQuiz(),
            ]
        },
        middleBox:{
            id: uuidv4(),
            title: "Bloc principal",
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                        borderRadius: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Arrondi des angles :",
                            params: {max: 50, min: 0, step: 1}
                        }
                    }
                },
            },
            blocks: {
                title: getDefaultText('Titre', "BRAVO !", 50, "#53839D", null, 'uppercase', 'initial'),
                desc: getDefaultTextarea('Description du jeu', "Vous avez répondu au questionnaire avec succès", true, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'initial', 20),
                button: getDefaultButton("Bouton", "Suivant", 20, '#F0F7FA', '#53839D', 1, 'rgba(255,255,255,1)', 0),
            }
        }
    }
};
