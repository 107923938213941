import React, {useEffect, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../../js/constants/action-types';
import { Grid, InputLabel, Accordion, AccordionSummary, AccordionDetails, Typography, Fab } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import Button from '../../../../ui/button/Button';
import InputBuilder from '../../../../ui/form/InputBuilder';
import colors from '../../../../../config/theme/colors';
import { SortableContainer, SortableElement, SortableHandle } from 'react-ordering';
import arrayMove from 'array-move';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import FooterLinks from './FooterLinks';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&>div': {
            margin: '16px 0',
            boxShadow: 'inherit',
            '&:first-child': {
                marginTop: 0,
            },
            '&:before': {
                content: 'inherit',
            }
        }
    },
    cardHeader: {
        color: '#000'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    rulePanel: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: colors.blue.lighter.hue900,
    },
    actionBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 10
    }
}));

const DeleteButton = withStyles(theme => ({
    root: {
        color: colors.red.regular,
        backgroundColor: 'transparent',
        minHeight: 10,
        alignSelf: 'flex-end',
        textTransform: 'inherit',
        borderRadius: 0,
        width: 'auto',
        boxShadow: 'inherit',
        '&:hover': {
            color: colors.red.darker,
            backgroundColor: 'transparent',
        }
    },
}))(Fab);



const AccordionSummaryCustom = styled(AccordionSummary)`
    background: ${colors.blue.lighter.hue900};
    &>div{
        justify-content: space-between;
        align-items: center;
        p{
            color: ${colors.blue.darker.hue300};
        }
        svg{
            fill: ${colors.blue.darker.hue300};
        }
    }
`;
const InputLabelCustom = styled(InputLabel)`
    color: ${colors.black.regular};
    margin-top: ${props => props.margintop || 0};
    margin-bottom: 24px;
`;
const GridFlexCenter = styled(Grid)`
    display: flex;
    align-items: center;
`;


const DragHandle = SortableHandle(() => <span>:::</span>)


const LinkSortableItem = SortableElement(
    ({ element, id, idLink, inputCallback, deleteLink }) => {
        return (
            <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', borderBottom: '2px solid white', background: colors.grey.lighter.hue980, padding: 8, paddingBottom: 0, paddingTop: 16, display: 'flex', flexDirection: 'row'}} className="sortable-item">
                <div style={{display: 'flex', marginBottom: 26, padding: '4px 8px', justifyContent: 'center', alignItems: 'center'}}>
                    <DragHandle />
                </div>
                <Grid container direction="row">
                    <Grid xs={12}>
                        <FooterLinks idLink={idLink} id={id} element={element} inputCallback={inputCallback} deleteLink={deleteLink} />
                    </Grid>
                </Grid>
            </div>
        )
    }
);

const LinkSortableList = SortableContainer(({ id, element, classes, inputCallback, deleteLink }) => {
    return (
        <Grid container direction="column">
            <div className="sortableHelper">
                {
                    element.links.map((value, index) => (
                        !value.delete ? (
                            <LinkSortableItem 
                                element={element}
                                key={value.id} 
                                idLink={index}
                                index={index}
                                id={id}
                                classes={classes}
                                deleteLink={deleteLink}
                                distance={1}
                                inputCallback={inputCallback}
                            />
                        ) : null
                    ))
                }
            </div>
        </Grid>
    );
});



const SortableItem = SortableElement(
    ({ element, index, id, classes, deleteColumn, addLink, inputCallback, deleteLink, onSortEndLink }) => {

        let libelleInput = {
            required : true,
            type : 'text',
            label : 'Nom de la colonne',
            stateName : 'footerName',
            helper: {
                text: 'Nom de la colonne',
                link: false,
            },
        };

        return (
            <Accordion style={{ borderRadius: 0 }} className="sortable-item">
                <AccordionSummaryCustom
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Colonne du footer {element.name ? `(${element.name})` : `(Sans nom)`}</Typography>
                    <DragHandle />
                </AccordionSummaryCustom>
                <AccordionDetails className={classes.rulePanel} style={{ padding: '0 16px!important' }}>
                    <div style={{padding: 20, width: '100%', background: 'white'}}>
                        <GridFlexCenter container direction="column" justifyContent="center" spacing={0}>
                            <Grid container direction="row" spacing={1}>
                                <GridFlexCenter item xs={3}>
                                    <InputLabelCustom>{libelleInput.label}</InputLabelCustom>
                                </GridFlexCenter>
                                <InputBuilder xs={9} value={element.name} input={libelleInput} stateCallback={(evt) => {inputCallback(evt, id, 'name', 0)}} /> 
                            </Grid>
                            <Grid container direction="row" spacing={1}>
                                <LinkSortableList hideSortableGhost={false} useDragHandle={true} id={id} element={element} inputCallback={inputCallback} deleteLink={deleteLink} onSortEnd={({oldIndex, newIndex}) => onSortEndLink(oldIndex, newIndex, id)}/>
                                <Button text={'Ajouter un lien'} bgcolor="transparent" bgcolorhover="transparent" color={colors.green.regular} onClick={() => addLink(id)} />
                            </Grid>
                        </GridFlexCenter>
                    </div>
                    <DeleteButton style={{ margin: '12px 20px' }} color="secondary" size="small" aria-label="delete" className={classes.button} onClick={() => { deleteColumn(id) }}>
                        <DeleteIcon style={{ width: 16 }} />
                        Supprimer la colonne
                    </DeleteButton>
                </AccordionDetails>
            </Accordion>
        )
    }
);

const SortableList = SortableContainer(({ elements, classes, deleteColumn, addLink, inputCallback, deleteLink, onSortEndLink }) => {
    return (
        <div className="sortableHelper">
            {
                elements.map((value, index) => (
                    !value.delete ? (
                        <SortableItem 
                            element={value}
                            key={value.id} 
                            index={index} 
                            id={index} 
                            classes={classes}
                            deleteColumn={deleteColumn}
                            addLink={addLink}
                            deleteLink={deleteLink}
                            onSortEndLink={onSortEndLink}
                            inputCallback={inputCallback}
                        />
                    ) : null
                ))
            }
        </div>
    );
});

function FooterColumns (props) {
    const [elements, setElements] = useState(props.allState.footerElements);
    const [nbElement, setNbElement] = useState(0);

    const classes = useStyles();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let getElements = arrayMove([...elements], oldIndex, newIndex);
        getElements[oldIndex].update = true;
        getElements[newIndex].update = true;
    
        setElements(getElements);
    };

    const onSortEndLink = (oldIndex, newIndex, column) => {
        let getElements = [...elements];
        getElements[column].links = arrayMove(getElements[column].links, oldIndex, newIndex);
        getElements[column].links[oldIndex].update = true; 
        getElements[column].links[newIndex].update = true; 

        setElements(getElements);
    };

    const addColumn = () => {
        let getElements = [...elements];
        getElements.push({name: '', links:[], new: true, id:  uuidv4()});

        setElements(getElements);
    };
    
    const deleteColumn = (id) => {
        let getElements = [...elements];
        if(getElements[id].new){
            getElements.splice(id, 1);
        }
        else{
            getElements[id].delete = true;
        }
        setElements(getElements);
    };

    const addLink = (index) => {
        let getElements = [...elements];
        getElements[index].links.push({name: '',  link: '', new: true, id: uuidv4(), isExternal: false});

        setElements(getElements);
    };

    const deleteLink = (index, idLink) => {
        let getElements = [...elements];
        if(getElements[index].links[idLink].new){
            getElements[index].links.splice(idLink, 1);
        }
        else{
            getElements[index].links[idLink].delete = true;
        }

        setElements(getElements);
    };
    
    const inputCallback = (e, id, stateName, level, idLink = null) => {
        let value = null;
        if(e?.target?.value){
            value = e.target.value;
        }
        else if(e || e !== null){
            value = e;
        }

        let getElements = [...elements];
        if(level === 0){    
            getElements[id][stateName] = value;
            getElements[id].update = true;
        }
        else{
            getElements[id].links[idLink][stateName] = value;
            getElements[id].links[idLink].update = true;
        }
        setElements(getElements);

    };
    
    const checkElements = () => {
        let i = 0;
        for(let element of elements){
            i++;
            if(element.delete){
                i--;
            }
        }
        setNbElement(i);
    }

    useEffect(() => {
        props.stateCallback('footerElements', elements);
        checkElements();
    }, [elements])

    return (
        <div style={{width: "100%", transition: 'all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms', background: 'rgb(250, 251, 251)', padding: '32px 12px'}}>
            {
                nbElement > 0 ? (
                    <SortableList 
                        hideSortableGhost={false} 
                        useDragHandle={true} 
                        elements={elements} 
                        onSortEnd={onSortEnd}
                        onSortEndLink={onSortEndLink}
                        axis={"y"} 
                        classes={classes}
                        deleteColumn={deleteColumn}
                        addLink={addLink}
                        deleteLink={deleteLink}
                        inputCallback={inputCallback}
                    />
                ) : (
                    <p>Aucun éléments pour le moment</p>
                )
            }
            <Button text={'Ajouter une colonne'} bgcolor={colors.green.regular}  onClick={() => addColumn()} shadowcolor={colors.green.darker} />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }}),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(FooterColumns))));