import { withApollo } from "react-apollo";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SNACK, START_LOADING, STOP_LOADING } from "../../../../js/constants/action-types";
import { Box, Grid, Typography } from "@material-ui/core";
import StatusInfo from "../../../ui/status-info/StatusInfo";
import OurMenu from "../../../ui/menu/Menu";
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import ImgNotFound from '../../../../assets/images/image-fake-card.png';
import colors from "../../../../config/theme/colors";
import OurButton from "../../../ui/button/Button";
import styled from "styled-components";
import { GET_PRODUCT_IMAGE_DATAS } from "../../../../queries/products";
import { useEffect, useState } from "react";

const WrapperAttr = styled(Grid)`
    &:not(:last-child){
        margin-bottom: 8px;
    } 
    p{
        font-size: 13px;
        text-align: left;
        color: ${colors.black.regular};
    }
    display:grid;
    grid-template-columns:1fr 2fr;
`

const CardBundle = props =>{
    const [image,setImage] = useState(null)
    const product = {
        id:props.product.id,
        ...props.product.flatProducts.edges[0].node,
        productDatas: props.product?.productDatas?.edges,
        productCatalogs : props.product?.productCatalogs?.totalCount,
        productCategories : props.product?.productCategories?.totalCount,
    }

    const editMenuOptions = [
        // {
        //     label: 'Dupliquer',
        //     color: colors.blue.darker.hue300,
        //     isDisabled: false,
        //     icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
        //     action: (e) => {
        //         e.stopPropagation();
        //         props.actionButtonSecond();
        //         // handleClose(e);
        //     }
        // },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.deleteCallabck(props.product)
                // setOpenDeleteModal(!openDeleteModal);
                // handleClose(e);
            }
        }
    ]

    const getImage = async ()=>{
        try {
            const data = await props.client.query({
              query: GET_PRODUCT_IMAGE_DATAS,
              variables: { 
                  id: product.id ,
                  identifier : 'product_image'
              }
            })
            const dataImage = data.data.product.productDatas.edges[0]?.node?.media?.filePath
            if (dataImage !== product.image) {
                setImage(dataImage)    
            }else{
                setImage(product.image)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getProductDatas = (identifier,key)=>{
        const productDatas = product.productDatas.find((productData)=>{
            return productData.node.attribute.identifier === identifier
        })
        if(key === 'value'){
            return productDatas?.node[key]
        }else if(key === 'medias'){
            return productDatas?.node?.media?.filePath
        }
    }

    useEffect(()=>{
        getImage()
    },[])

    return (
        <Box width={"100%"}>
                <Box>
                    <img 
                        src={getProductDatas('product_image','medias')?`${process.env.REACT_APP_MEDIAS}/${getProductDatas('product_image','medias')}`:ImgNotFound} 
                        alt={'default'}
                        style={{
                            width:"100%",
                            height:"220px",
                            objectFit:"cover",
                        }}
                    />
                    <Box pb={2} mt={2} style={{borderBottom: `0.5px solid ${colors.grey.lighter.hue700}`, width: "100%"}}>
                        <Grid container justifyContent='space-between' alignItems='flex-start'>
                            <Typography style={{
                                fontSize: "25px",
                                lineHeight: 1.5,
                                fontWeight: 'bold',
                                maxHeight: "40px",
                                maxWidth: "90%",
                                overflow: "hidden",
                                whiteSpace:"nowrap",
                                textOverflow:"ellipsis",
                                "-webkit-line-clamp": 1,
                                "-webkit-box-orient": "vertical",
                                color:colors.black.regular
                            }}>
                                {getProductDatas('product_name','value')}
                            </Typography>
                            <Box mt={'10px'}>
                                <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />  
                            </Box>          
                        </Grid>
                    </Box>
                    <Box pb={2} mt={2} style={{width: "100%"}}>
                        <WrapperAttr container>
                            <Typography>{props.t("products.list.cardproduct.status")} :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <StatusInfo status={props.product.status} width={'auto'} fontSize={14}/>
                            </Box>
                        </WrapperAttr>
                        <WrapperAttr container>
                            <Typography>Produits :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <Typography>{props.product.productBundleOptions.edges.length}</Typography>
                            </Box>
                        </WrapperAttr>
                        {/* <WrapperAttr container>
                            <Typography>Variantes :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <Typography>3</Typography>
                            </Box>
                        </WrapperAttr> */}
                        <WrapperAttr container>
                            <Typography>Catalogues :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <Typography>{product.productCatalogs}</Typography>
                            </Box>
                        </WrapperAttr>
                        <WrapperAttr container>
                            <Typography>Catégories :</Typography>
                            <Box style={{marginLeft:'3px'}}>
                                <Typography>{product.productCategories}</Typography>
                            </Box>
                        </WrapperAttr>
                    </Box>
                    
                    <Grid
                        container 
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {
                            !props.configCard ? 
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Box width={"100%"}>
                                            <OurButton
                                            icon={"edit"}
                                            style={{padding: "14px 32px", margin: 0, width: "100%"}}
                                            disableElevation={true}  
                                            color={colors.blue.darker.hue300}
                                            colorhover={colors.blue.darker.hue300}
                                            bgcolorhover={colors.blue.lighter.hue600}
                                            bgcolor={colors.blue.lighter.hue600}
                                            border={`1px solid ${colors.blue.darker.hue300}`}
                                            onClick={(e)=>{e.stopPropagation();props.buttonCallback(product)}}>
                                                Modifier
                                            </OurButton>
                                        </Box>
                                    </Grid>
                                </Grid> 
                            : null
                        }
                    </Grid>
                </Box>
                {/* Delete modal */}
                {/* <DialogModal
                    open={openDeleteModal}
                    icon={true}
                    type='delete'
                    title={'Êtes-vous sûr de vouloir supprimer ce catalogue ?'}
                    primaryAction={(event) => {
                        event.stopPropagation();
                        props.actionButtonDelete();
                        setOpenDeleteModal(false);
                        event.stopPropagation();
                    }}
                    secondaryAction={(event) => {
                        event.stopPropagation();
                        setOpenDeleteModal(false);
                    }}
                    windowWidth={props.windowWidth}
                >
                    <Typography variant="body2">Êtes-vous sûr de vouloir supprimer ce catalogue ? <strong>Cette action est irréversible</strong></Typography>
                </DialogModal> */}

            </Box>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(CardBundle)))));