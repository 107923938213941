import { gql } from 'apollo-boost';

//----- Récupération des types de catalogue
export const GET_CATALOG_TYPES = gql`
{
  catalogTypes{
    edges{
      node{
        id
        identifier
      }
    }
  }
}
`;

//----- Total catalogues
export const GET_CATALOGS_TOTAL = gql`
{
  catalogs{
    edges{
      node{
        status
      }
    }
  }
}
`;

export const GET_CATALOGS_CURSOR = gql`
{
  catalogs(first: 10000){
    edges{
      cursor
    }
  }
}
`;

//TODO Dono
//----- Récupération des catalogues
export const GET_CATALOGS = gql`
{
  catalogs{
    edges{
      node{
        id
        identifier
        libelle
        description
        type{
          id
          identifier
        }
        status
        media{
          filePath
        }
        autosync
        startAt
        endAt
        categories{
          id
          libelle
          parent {
            id
            libelle
          }
          categoryDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_CATALOGS_LIGHT = gql`
{
  catalogs{
    edges{
      node{
        id
        identifier
        libelle
      }
    }
  }
}
`;

//TODO Dono
//----- Récupération des catalogues
export const GET_CATALOGS_ONLY = gql`
{
  catalogs{
    edges{
      node{
        id
        identifier
        libelle
        description
        type{
          id
          identifier
        }
        status
        media{
          filePath
        }
        autosync
        startAt
        endAt
      }
    }
  }
}
`;

//TODO Dono
export const GET_CATALOGS_PAGINATION = gql`
query catalogs ($nbperpage: Int, $cursor: String, $cursorLast: String, $typeList: [String], $libelle: String, $description: String){
  catalogs (first: $nbperpage, after: $cursor, before: $cursorLast, type_list: $typeList, libelle: $libelle, description: $description, order:{id:"DESC"}){
    edges{
      node{
        id
        identifier
        libelle
        description
        type{
          id
        	identifier
        }
        status
        media{
          filePath
        }
        autosync
        startAt
        endAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
  }
}
`;

export const GET_CATALOGS_BY_ID_ATTRS = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    id
    attributes(first: 10000){
      edges{
        node{
          id
        }
      }
    }
  }
}
`;

export const GET_CATALOGS_BY_ID_CAT_FILTERS = gql`
query catalogs($id: ID!, $categoryId: Int) {
  catalog(id: $id) {
    id
    categories(id: $categoryId) {
      id
      categoryFilters {
        edges {
          node {
            id
            value
            attribute {
              id
              attributeOptions {
                edges {
                  node {
                    id
                    identifier
                    translation {
                      id
                      translationDatas {
                        edges {
                          node {
                            value
                            locale {
                              id
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              attributeType {
                id
                input
                translation {
                  id
                  translationDatas {
                    edges {
                      node {
                        id
                        value
                        locale {
                          code
                          libelle
                        }
                      }
                    }
                  }
                }
              }
            }
            attributeOption {
              id
            }
            operator
          }
        }
      }
    }
  }
}
`;

//TODO Dono
//----- Récupération d'un catalogue par son ID
export const GET_CATALOGS_BY_ID = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    id
    identifier
    libelle
    description
    type{
      id
      identifier
    }
    status
    media{
      filePath
    }
    assets{
      edges{
        node{
          id
        }
      }
    }
    autosync
    startAt
    endAt
    categories{
      id
      libelle
      status
      position
      parent {
        id
        libelle
      }
      master {
        id
        libelle
      }
      type
      cmsPage{
        id
      }
      contentCategory{
        id
      }
      categoryDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media{
              id
              filePath
              type
              size
              category{
                edges{
                  node{
                    id
                    libelle
                  }
                }
              }
            }
            locale{
              id
              code
            }
          }
        }
      }
    }
    catalogFilters{
      edges{
        node{
          id
          value
          catalog{
            id
          }
          attribute{
            id
            attributeOptions {
              edges {
                node {
                  id
                  identifier
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            attributeType{
              id
              input
              translation{
                id
                translationDatas{
                  edges{
                    node{
                      id
                      value
                      locale{
                        code
                        libelle
                      }
                    }
                  }
                }
              }
            }
          }
          attributeOption{
            id
          }
          operator
        }
      }
    }
  }
}
`;

//TODO Dono
export const GET_CATALOGS_CATEGORIES_BY_ID = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    id
    identifier
    libelle
    categories{
      id
      libelle
      status
      type
      position
      cmsPage{
        id
      }
      contentCategory{
        id
      }
      parent {
        id
        libelle
      }
      master {
        id
        libelle
      }
      productCategories(first: 10000){
        totalCount
        edges{
          node{
            position
            product{
              id
            }
          }
        }
      }
      categoryFilters{
        edges{
          node{
            id
            value
            attribute{
              id
              attributeOptions {
                edges {
                  node {
                    id
                    identifier
                    translation {
                      id
                      translationDatas {
                        edges {
                          node {
                            value
                            locale {
                              id
                              code
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              attributeType{
                id
                input
                translation{
                  id
                  translationDatas{
                    edges{
                      node{
                        id
                        value
                        locale{
                          code
                          libelle
                        }
                      }
                    }
                  }
                }
              }
            }
            attributeOption{
              id
            }
            operator
          }
        }
      }
      categoryDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media{
              id
              filePath
              type
              size
              category{
                edges{
                  node{
                    id
                    libelle
                  }
                }
              }
            }
            locale{
              id
              code
            }
          }
        }
      }
    }
  }
}
`;


export const GET_CATALOGS_ATTR_BY_ID = gql `
query catalogs($id: ID!){
  catalog(id: $id){
    id
    identifier
    libelle
    attributes{
      edges{
        node{
          id
          identifier
          translation{
            id
            translationDatas{
              edges{
                node{
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


//TODO Dono
//----- Rechercher des catalogues
export const SEARCH_CATALOGS = gql`
query catalogsSearch($libelle: String, $description: String){
  catalogs(libelle:$libelle, description:$description){
    edges{
      node{
        id
        identifier
        libelle
        description
        type{
          id
          identifier
        }
        status
        media{
          filePath
        }
        autosync
        startAt
        endAt
        categories{
          id
          libelle
          parent {
            id
            libelle
          }
          categoryDatas{
            edges{
              node{
                id
                value
                attribute{
                  id
                  identifier
                  attributeType {
                    id
                    input
                  }
                }
                media{
                  id
                  filePath
                  type
                  size
                  category{
                    edges{
                      node{
                        id
                        libelle
                      }
                    }
                  }
                }
                locale{
                  id
                  code
                }
              }
            }
          }
          productCategories{
            totalCount
            edges{
              node{
                position
                product{
                  id
                  sku
                  productCategories{
                    edges{
                      node{
                        position
                        category{
                          id
                          libelle
                        }
                      }
                    }
                  }
                  productDatas{
                    edges{
                      node{
                        id
                        value
                        selection{
                            id
                            identifier
                        }
                        attributeOption{
                          id
                          identifier
                        }
                        media{
                          filePath
                        }
                        locale{
                          code
                        }
                        attribute{
                          identifier
                          isSystem
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Ajouter un catalogue 
/*
  Exemple de variables
  {
    "identifier": "test",
    "libelle": "Libelle de test",
    "description": "Description de test",
    "type": "/api/catalog-types/1",
    "status": false,
    "image": "medias/magento-logo.png",
    "autosync": true,
    "startAt": "2020-08-01",
    "endAt": "2020-09-01"
  }
*/
export const ADD_CATALOG = gql`
mutation AddCatalog($type: String!, $identifier: String!, $libelle: String!, $description: String, $status: Boolean!, $media: String, $autosync: Boolean!, $startAt: String!, $endAt: String!, $categories: [String], $attributes: [String]){
  createCatalog(input: {type: $type, identifier:$identifier, libelle:$libelle, description:$description, status:$status, media:$media, autosync:$autosync, startAt:$startAt, endAt:$endAt, categories:$categories, attributes:$attributes}){
    catalog{
      id
      identifier
      libelle
      description
      type{
        id
        identifier
      }
      status
      media{
        filePath
      }
      autosync
      startAt
      endAt
      categories{
        id
        libelle
        productCategories{
          edges{
            node{
              position
              product{
                id
                sku
              }
            }
          }
        }
        categoryDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
                attributeType {
                  id
                  input
                }
              }
              media{
                id
                filePath
                type
                size
                category{
                  edges{
                    node{
                      id
                      libelle
                    }
                  }
                }
              }
              locale{
                id
                code
              }
            }
          }
        }
      }
    }
  }
}`;



//----- Modifier un catalogue 
/*
 Exemple de variables
  {
    "id": "/api/catalogs/2",
    "identifier": "test2",
    "libelle": "Libelle de test",
    "description": "Description de test",
    "type": "/api/catalog-types/1",
    "status": false,
    "image": "medias/magento-logo.png",
    "autosync": true,
    "startAt": "2020-08-01",
    "endAt": "2020-09-01"
  }
*/
//TODO Dono
export const UPDATE_CATALOG = gql`
mutation UpdateCatalog($id: ID!, $identifier: String, $libelle: String, $description: String, $status: Boolean, $media: String, $autosync: Boolean, $startAt: String, $endAt: String, $categories: [String], $attributes: [String]){
	updateCatalog(input: {id:$id, identifier:$identifier, libelle:$libelle, description:$description, status:$status, media:$media, autosync:$autosync, startAt:$startAt, endAt:$endAt, categories:$categories, attributes:$attributes}){
  	catalog{
      id
    }
  }
}`;


//----- Supprimer un catalogue
/*
 Exemple de variables
  {
    "id" : "/api/catalogs/2"
  }
*/
export const DELETE_CATALOG = gql`
mutation DeleteCatalog($id: ID!){
	deleteCatalog(input: {id:$id}){
    catalog{
      id
    }
  }  
}`;


//------ Pagination catalog Products
export const GET_CATALOGS_PRODUCTS_PAGINATION = gql`
query catalogProductsPagination($catalog: String, $nbperpage: Int, $cursor: String, $cursorLast: String){
  catalogProducts(first: $nbperpage, catalog:$catalog, after: $cursor, before: $cursorLast){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        status
        selection{
          id
          identifier
        }
        product{
          id
          sku
          productDatas{
            edges{
              node{
                id
                value
                selection{
                    id
                    identifier
                }
                attributeOption{
                  id
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
                attribute{
                  identifier
                  isSystem
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const UPDATE_CATALOG_PRODUCT = gql`
mutation UpdateCatalogProduct($id: ID!, $status: Boolean, $selection: String, $updatedAt: String){
	updateCatalogProduct(input: {id:$id, status:$status, selection:$selection, updatedAt:$updatedAt}){
  	catalogProduct{
      id
    }
  }
}`;

export const CREATE_CATALOG_PRODUCT = gql`
mutation CreateCatalogProduct($catalog: String!,$product: String!, $status: Boolean!, $selection: String, $updatedAt: String!,$publishDate: String!,$ignoreDate:Boolean!){
	createCatalogProduct(input: {catalog:$catalog,product:$product,status:$status, selection:$selection, updatedAt:$updatedAt,publishDate:$publishDate,ignoreDate:$ignoreDate}){
  	catalogProduct{
      id
    }
  }
}`;

export const DELETE_CATALOG_PRODUCT = gql`
mutation DeleteCatalogProduct($id: ID!){
	deleteCatalogProduct(input: {id:$id}){
  	catalogProduct{
      id
    }
  }
}`;




//----- Récupération d'un catalogue par son ID
export const CATALOG_DETAILS_GET_CATALOGS_BY_ID = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    id
    identifier
    libelle
    description
    type{
      id
      identifier
    }
    status
    media{
      filePath
    }
    autosync
    startAt
    endAt
    assets {
      edges {
        node {
          id
        }
      }
    }
  }
}
`;



//----- Récupération d'un catalogue par son ID
export const CATALOG_DETAILS_FILTER_GET_CATALOGS_BY_ID = gql`
query catalogs($id: ID!){
  catalog(id: $id){
    categories{
      id
      libelle
      status
      position
      parent {
        id
        libelle
      }
      master {
        id
        libelle
      }
      type
      cmsPage{
        id
      }
      contentCategory{
        id
      }
      categoryDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media{
              id
              filePath
              type
              size
              category{
                edges{
                  node{
                    id
                    libelle
                  }
                }
              }
            }
            locale{
              id
              code
            }
          }
        }
      }
    }
    catalogFilters{
      edges{
        node{
          id
          value
          attribute{
            id
            attributeOptions {
              edges {
                node {
                  id
                  identifier
                  translation {
                    id
                    translationDatas {
                      edges {
                        node {
                          value
                          locale {
                            id
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            attributeType{
              id
              input
            }
          }
          attributeOption{
            id
          }
          operator
        }
      }
    }
  }
}
`;