import React from "react";
import Swiper from "react-id-swiper";
import notFound from '../../../../../../src/assets/images/not-found.png';
import { Box } from "@material-ui/core";
import colors from '../../../../../config/theme/colors';
import styled from 'styled-components';
import './SliderActu.scss';
import { withTranslation } from 'react-i18next';


const ContainerImage = styled.div`
    max-height: 205px;
    overflow: hidden;
    display: flex;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-width: 1250px){
      max-height: 317px;
    }
`;
const Title = styled.h1`
    font-size: 15px !important;
    line-height: 22px;
    color: ${colors.black.regular} !important;
    font-weight: bold !important;
    margin: 0 !important;
`;
const TextActu = styled.h5`
margin-top: 0px;
    font-size: 13px !important;
    line-height: 19px !important;
    color: ${colors.grey.darker} !important;
    font-weight: 500 !important;
    @media screen and (max-width: 1800px){
      font-size: 13px !important;
      line-height: 18px !important;
    }
    @media screen and (max-width: 1400px){
      font-size: 12px!important;
      line-height: 17px!important;
    }
`;
const LinkActu = styled.p`
  color: ${colors.blue.darker.hue300} !important;
  font-size: 14px!important;
  line-height: 17px!important;
  font-weight: bold;
  text-decoration: underline;
  opacity: 0.7;
  cursor: pointer;
  display: inline-block;
  &:hover{
      text-decoration: underline;
  }
  @media screen and (max-width: 1800px){
    font-size: 13px!important;
  }
  @media screen and (max-width: 1400px){
    font-size: 12px!important;
  }
`;
const ImageArticle = styled.img`
  border: 1px solid ${colors.grey.lighter.hue800};
  box-sizing: border-box;
  object-fit: cover;
`;

function SliderActu(props) {
  const params = {
    loop: true,
    autoHeight: true,
    watchSlidesVisibility: true,
    shouldSwiperUpdate: true,
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: 'bullets',
    //   clickable: true
    // },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev"
    // },
    // renderPrevButton: () => (
    //   <button className="swiper-button-prev ht-swiper-button-nav">
    //     {/* <i className="pe-7s-angle-left" /> */}
    //   </button>
    // ),
    // renderNextButton: () => (
    //   <button className="swiper-button-next ht-swiper-button-nav">
    //     {/* <i className="pe-7s-angle-right" /> */}
    //   </button>
    // )
  };
  return (
    <div id="swiper-home">
      <div className="slider-area">
        <div className="slider-active-2 nav-style-2">
          {
            props.sliderData && props.sliderData?.length > 0 ? (
              props.sliderData?.length > 1 ?
                /* Slider */
                (<Swiper {...params}>
                  {props.sliderData.map((single, key) => {
                    return (
                      <div
                        className={`swiper-slide`}
                        style={{ background: 'white' }}
                        key={key}
                      >
                        <div className="container" style={{ padding: 0 }}>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                              <div className="slider-animated-1">
                                <ContainerImage className="animated">
                                  <ImageArticle className="animated" src={single.image ? single.image : notFound} style={{ width: '100%' }} />
                                </ContainerImage>
                                <Box className="animated" mt={2}>
                                  <Title className="animated">{props.t(single.title)}</Title>
                                </Box>
                                <Box className="animated" mt={0}>
                                  <TextActu className="animated">{single.text}</TextActu>
                                </Box>
                                {/* <Box className="animated d-flex justify-content-end" mt={0.5}>
                              <LinkActu className="animated">{props.t('homepage.notification.learnmore')}</LinkActu>
                            </Box> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Swiper>)
                : (
                  /* Just display the only one item */
                  <div
                    style={{ background: 'white' }}
                  >
                    <div className="container" style={{ padding: 0 }}>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                          <div>
                            <ContainerImage>
                              <ImageArticle src={props.sliderData[0].image ? props.sliderData[0].image : notFound} style={{ width: '100%' }} />
                              {/* <Box style={{
                                position: 'absolute',
                                top: '0',
                                width: '100%',
                                height: '100%',
                                border: `1px solid ${colors.grey.lighter.hue800}`,
                                boxSizing: 'border-box',
                              }}></Box> */}
                            </ContainerImage>
                            <Box mt={2}>
                              <Title>{props.t(props.sliderData[0].title)}</Title>
                            </Box>
                            <Box mt={0}>
                              <TextActu>{props.sliderData[0].text}</TextActu>
                            </Box>
                            {/* <Box className="animated d-flex justify-content-end" mt={0.5}>
                              <LinkActu className="animated">{props.t('homepage.notification.learnmore')}</LinkActu>
                            </Box> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            ) : (
              null
            )
          }
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(SliderActu);