import { FormInputTypes } from '../../../shareable/types';
import { getDefaultButton, getDefaultText} from '../../helpers';


export default {
    name: 'Wishlist',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#53839D'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        nbItemMax: {
            type: FormInputTypes.SLIDER,
            value: 3,
            label: "Nombre d'éléments par Wishlist",
            params: {max: 5, min: 1, step: 1}
        },
        emptyItem: {
            label: 'Image produit non sélectionné',
            type: FormInputTypes.IMAGE,
            value: null
        },
        
    },
    blocks: {
        title: getDefaultText("Titre du jeu", "Tentez votre chance", 35, "#FFFFFF", 'rgba(255,255,255,0)', 'initial','center'),
        text: getDefaultText("Texte du jeu", "Sélectionnez vos articles préférés et validez votre Wishlist !", 26, "#FFFFFF", 'rgba(255,255,255,0)', 'initial','center'),
        button: getDefaultButton("Boutton de validation", "Valider", 20, '#53839D', '#FFFFFF', 1, 'rgba(255,255,255,1)', 0),
    }
};
