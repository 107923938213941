import { STEPPER } from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import SelectCompanies from '../../customers/components/SelectCompanies';

export default function addPresentation(currentLang, handleMediaPicker, allOffers, handleSelectCompany, currentOffers, formSettings, action) {
    
    let generalInputs = action === "add" ? [] : [{
        type: 'text',
        label: 'Numéro',
        helper: {
            text: 'Indiquez le numéro de la présentation',
            link: false,
        },
        required: true,
        stateName: 'numberPresentation'
    }];
    
    generalInputs.push({
        type: 'text',
        label: 'Nom',
        helper: {
            text: 'Indiquez le nom',
            link: false,
        },
        required: true,
        stateName: 'name',
    });

    generalInputs.push({
        type: 'mediaPicker',
        label: 'Image',
        helper: {
            text: 'Indiquez une image',
            link: true,
        },
        handleMediaPicker: handleMediaPicker,
        required: false,
        stateName: 'imagePresentation',
    });

    let children = [
        {
            labelName: 'Paramètres',
            isOptionnal: false,
            optionsInputs: generalInputs
        },
        // {
        //     labelName: 'Devis',
        //     isOptionnal: true,
        //     component : SelectCompanies,
        //     componentProps :{
        //         companies: allOffers,
        //         currentLang :currentLang,
        //         handleSelectCompany : handleSelectCompany,
        //         currentCompanies : currentOffers.current,
        //         paginationSettings : {
        //             page : formSettings.page,
        //             count: formSettings.count,
        //             changePage : formSettings.changePage
        //         },
        //         handleSearch : formSettings.handleSearch,
        //         uniqueSelect: true,
        //         selectedCompanies: currentOffers,
        //         isOffer: true,
        //     }
        // },
    ];

    var obj = {
        titleForm: action === 'add' ? 'Créer une présentation' : 'Modifier une présentation',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour ${action === 'add' ? 'créer' : 'modifier'} votre offre`,
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                title: 'Et voilà !',
                subtitle: action === 'add' ? 'Valider la création de cette présentation' : 'Valider la modification de cette présentation',
                textButton: action === 'add' ? 'Créer' : 'Modifier'
            },
            children
        }
    };
    return obj;
}