import React, { useEffect, useState } from "react";
import Swiper from 'swiper';
import Div from '../shared/div';
import Img from '../shared/img';
import styled from 'styled-components';

const DivSliderPresentation = styled(Div)`
    padding: 0 1rem;
    @media screen and (min-width: 1024px){
        padding : 0 22px;
    }
    @media screen and (min-width:1450px){
        padding : 0 60px;
    }
    
    .titleBox{
        display: flex;
        flex-direction: row; 
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media screen and (min-width: 1024px){
            margin-bottom: 30px;
        }
        h2{
            font-size: 2.5625rem;
            line-height: 1.2;
            font-family: 'DINOffc-Bold', sans-serif;
            text-transform: uppercase;
            margin: 0;
            @media screen and (max-width: 767px){
                font-size: 1.875rem;
            }
        }
        #switch-view{
            .hide{
                display: none!important;
            }
        }
        a{
            color: #4D5769;
            font-size: 1.25rem;
            line-height: 1.2;
            text-decoration: none;
            font-family: 'Univers', sans-serif!important;
            cursor: pointer;
            text-decoration: underline;
            @media screen and (max-width: 1023px){
                text-decoration: underline;
                &:hover{
                    color: #000E28;
                }
            }
        }
    }

    .sliderHistory{
        padding-top: 40px;
        &> .slider{
            width: 1200px;
            max-width: 90%;
            position: relative;
            margin: auto;
            padding-bottom: 50px;
        }
        div[class="swiper-wrapper"]{
            margin-bottom: 50px;
        }
        .swiper-scrollbar{
            display: none;
        }
        .swiper-container{
            width: 1200px;
            max-width: 90%;
            position: relative;
            margin: auto;
            padding-bottom: 50px;
        }
        .swiper-container-presentation-less{
            display: none;
        }
    }
    .sliderHistoryLess{
        padding-top: 40px;
        padding-bottom: 40px;
        .swiper-container-presentation-more{
            display: none;
        }
        &> .slider{
            width: 1200px;
            max-width: 90%;
            position: relative;
            margin: auto;
            padding-bottom: 50px;
        }
        div[class="swiper-wrapper"]{
            margin-bottom: 50px;
        }
        .swiper-container{
            width: 1200px;
            max-width: 90%;
            position: relative;
            margin: auto;
            padding-bottom: 50px;
        }
        .swiper-scrollbar{
            max-width: 50%;
            width: 400px;
            margin-left: 50%;
            transform: translateX(-50%);
            display: block;
            background: #E6E7EA;
            border-radius: 0;
            &>div{
                background:#000E28;
                border-radius: 0;
            }
        }
    }
    


    .slideHistory{
        display:flex;
        flex-direction: column;
        &> div{
            padding: 16px;
        }
        &> div:first-child{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &> div{
                height: 100%;
                img{
                    position: relative;
                }
            }
            &> p{
                font-size: 1.4375rem;
                line-height: 1.2;
                color: rgb(153 159 169);
                font-family: 'DINOffc-Bold', sans-serif;
                margin-top: 10px;
            }
        }
        h3{
            font-size: 23px;
            line-height: 1.2;
            font-family: 'DINOffc-Bold', sans-serif;
            margin-bottom: 12px;
            text-align: center;
        }
        p{
            font-size: 1rem;
            line-height: 1.2;
            font-family: 'Univers', sans-serif;
            max-width: 100%;
            margin: auto;
            color: rgb(128 135 148);
            text-align: center;
        }
        
    }
    @media screen and (min-width: 1024px){
        .slideHistory{
            flex-direction: row;
            &> div:first-child > div img{
                top: 50%;
                transform: translateY(-50%);
            }
            &> div{
                min-width: 450px;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
            h3{
                text-align: left;
            }
            p{
                text-align: left;
                max-width: 450px;
                margin: 0;
            }
        }
    }
`;

const SliderPresentationMedia = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false, 
        data = null,
    } = props;
    
    useEffect(() => {
        const swiper = new Swiper('.swiper-container-presentation-more', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-presentation-more',
                hide: false,
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-presentation-more",
                prevEl: ".swiper-button-prev-presentation-more",
            },
            slidesPerView: 1,
        });
        const swiper2 = new Swiper('.swiper-container-presentation-less', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-presentation-less',
                hide: true,
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-presentation-less",
                prevEl: ".swiper-button-prev-presentation-less",
            },
            slidesPerView: 1,
        });


        var switch_view = document.querySelector("#switch-view");
        if(switch_view){
            switch_view.addEventListener("click", function(e) {
                var btn = switch_view.children;
                var page = document.querySelector(".containerComponentSliderHistory");
                
                if(e.target.tagName === "A") {
                    for(var i=0, len=btn.length; i<len; i++) {
                        btn[i].index = i;
                        btn[i].classList.remove("hide");
                        btn[i].classList.add("show");
                    }
                    if(page.classList.contains('sliderHistoryLess')){
                        page.classList.remove('sliderHistoryLess');
                        page.classList.add('sliderHistory');
                    }else{
                        page.classList.add('sliderHistoryLess');
                        page.classList.remove('sliderHistory');
                    }
                    e.target.classList.remove("show");
                    e.target.classList.add("hide");
                }
            }, false);
        }
    }, []);

    useEffect(() => {
        const swiper = new Swiper('.swiper-container-presentation-more', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-presentation-more',
                hide: false,
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-presentation-more",
                prevEl: ".swiper-button-prev-presentation-more",
            },
            slidesPerView: 1,
        });
        const swiper2 = new Swiper('.swiper-container-presentation-less', {
            direction: 'horizontal',
            scrollbar: {
                el: '.swiper-scrollbar-presentation-less',
                hide: true,
                draggable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next-presentation-less",
                prevEl: ".swiper-button-prev-presentation-less",
            },
            slidesPerView: 1,
        });
    }, [props]);

    return (
        <DivSliderPresentation style={{position: 'relative', background: data.inputs.backgroundColor.value}} className="slider-presentation" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className={'containerComponentSliderHistory sliderHistory'}>
                <Div className={'titleBox'}>
                    <h2 className={'mainTitle'}>{data.blocks.title.inputs.value.value}</h2>
                    <div id="switch-view">
                        <a className="show">Voir moins</a>
                        <a className="hide">Voir plus</a>
                    </div>
                </Div>
                <div id="tab-slider">
                    <Div className="swiper-container swiper-container-presentation-more">
                        <div className="swiper-wrapper">
                            {
                                data.blocks.slides.config.map((slide, index) => (
                                    <Div className="swiper-slide w100 slideHistory" style={{justifyContent: 'initial'}}>
                                        <div> 
                                            <Img
                                                alt={slide.blocks.imagePrincipale?.inputs?.alt?.value ?? "Illustration"}
                                                classes={'imageMoment'}
                                                src={
                                                    typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                        slide.blocks.imagePrincipale?.inputs.value.value 
                                                    : slide.blocks.imagePrincipale?.inputs.value?.value?.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                            />
                                            <p style={{display: 'none'}}>{slide.blocks?.title?.inputs.value.value}</p>
                                        </div>
                                        <div>
                                            <h3>{slide.blocks?.title?.inputs.value.value}</h3>
                                            <p>{slide.blocks?.description?.inputs.value.value}</p>
                                        </div>
                                    </Div>
                                ))
                            }
                        </div>
                        <div className="swiper-scrollbar swiper-scrollbar-presentation-more"></div>
                        <div className="swiper-button-prev swiper-button-prev-presentation-more"></div>
                        <div className="swiper-button-next swiper-button-next-presentation-more"></div>
                    </Div>
                    <Div className="swiper-container swiper-container-presentation-less">
                        <div className="swiper-wrapper">
                            {
                                data.blocks.slides.config.map((slide, index) => (
                                    <Div className="swiper-slide w100 slideHistory" style={{justifyContent: 'center'}}>
                                        <div> 
                                            <Img
                                                alt={slide.blocks.imagePrincipale?.inputs?.alt?.value}
                                                classes={'imageMoment'}
                                                src={
                                                    typeof slide.blocks.imagePrincipale?.inputs.value.value === 'string' ? 
                                                        slide.blocks.imagePrincipale?.inputs.value.value 
                                                    : slide.blocks.imagePrincipale?.inputs.value?.value?.filePath ? 
                                                        `${process.env.REACT_APP_MEDIAS}/${slide.blocks.imagePrincipale?.inputs.value.value.filePath}` 
                                                    : null
                                                }
                                            />
                                            <p style={{display: 'block'}}>{slide.blocks?.title?.inputs.value.value}</p>
                                        </div>
                                    </Div>
                                ))
                            }
                        </div>
                        <div className="swiper-scrollbar swiper-scrollbar-presentation-less"></div>
                        <div className="swiper-button-prev swiper-button-prev-presentation-less"></div>
                        <div className="swiper-button-next swiper-button-next-presentation-less"></div>
                    </Div>
                </div>
            </Div>
        </DivSliderPresentation>
    );
};

export default SliderPresentationMedia;
