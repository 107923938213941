import { EditableTypes, FormInputTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultSimpleSlide } from '../../helpers';
import { v4 as uuidv4 } from "uuid";

export default {
    name: 'Slider avec images',
    blocks: {
        slides: {
            name: "Slider",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.SLIDER_WITH_RIGHT_IMAGES,
            limit: false,
            id: uuidv4(),
            inputs: {
                color: {
                    type: FormInputTypes.COLOR,
                    value: "#f48256",
                    label: "Couleur pagination :"
                }
            },
            config: [ 
                getDefaultSimpleSlide()
            ]
        },
        image1: {
            title: "Image 1",
            name: "Image 1",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/295x218"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        },
        image2: {
            title: "Image 2",
            name: "Image 2",
            type: EditableTypes.IMAGE_LINK,
            id: uuidv4(),
            inputs: {
                image: {
                    type: FormInputTypes.IMAGE,
                    value: "https://via.placeholder.com/295x218"
                },
                link: {
                    type: FormInputTypes.LINK,
                    value: "https://google.com"
                }
            }
        }
    }
};