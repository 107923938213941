import { Grid, Box, FormControl, MenuItem, Select, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, TextField } from '@material-ui/core';
import Typography from '../../../../ui/typography/Typography';
import { DatePickerCustom, inputStyles, StyledLinearProgress, TextFieldCustom, TitleGlobals } from './styledComponents';
import { ExpandMore } from '@material-ui/icons';
import PageLoader from '../../../../ui/loadings/page-loader/PageLoader';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'
import { SNACK } from '../../../../../js/constants/action-types';
import moment from 'moment';
import colors from '../../../../../config/theme/colors';
import { useEffect, useState } from 'react';
import request from '../../../../../js/utils/fetch';
import OurButton from '../../../../ui/button/Button';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AccordionCustom from '../../../../layouts/Accordion/AccordionCustom';

const StatsOrder = props => {
    const { type } = props
    const [commandes, setCommandes] = useState({ list: [], isLoaded: false })
    const [ordersDate, setOrdersDate] = useState({
        startDate: null,
        endDate: null
    })
    const [inputDate, setInputDate] = useState({
        startDate: null,
        endDate: null
    })
    const [ordersPeriode, setOrdersPeriode] = useState('week')
    const [ordersNumber, setOrdersNumber] = useState(0)
    const [error, setError] = useState(null)

    useEffect(() => {
        setOrdersDate({
            startDate: moment().startOf('week').format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD')
        })
        setInputDate({
            startDate: moment().startOf('week').format('yyyy-MM-DD'),
            endDate: moment().format('yyyy-MM-DD')
        })
        setOrdersPeriode('week')
    }, [type])


    const getCommandes = (postData = false) => {
        setCommandes(prev => ({
            ...prev,
            isLoaded: false
        }))
        const origin = type === "byMarketplace" ? ["MP"] : type === "bySite" ? ["B2C"] : null
        if (postData == false) {
            postData = {
                startDate: ordersDate.startDate,
                endDate: ordersDate.endDate,
                origin: origin
            }
        }
        else {
            postData = {
                ...postData,
                origin: origin
            }
        }
        request(`${process.env.REACT_APP_API}/statistics/sales-order/division`, 'POST', postData)
        .then(
            (data) => {
                if (data.success) {
                    let divisions = []
                    Object.keys(data.stats).map((d) => {
                        Object.keys(data.stats[d]).map((item) => {
                            if (divisions.some(e => e.name === item)) {
                                divisions.find(e => e.name === item).orderNumber += data.stats[d][item].orderNumber
                            } else {
                                divisions.push({
                                    name: item,
                                    orderNumber: data.stats[d][item].orderNumber
                                })
                            }
                        })

                    })
                    divisions.sort((a, b) => {
                        return b.orderNumber - a.orderNumber;
                    });
                    let number = 0
                    divisions.forEach(element => {
                        number += element.orderNumber
                    });
                    setOrdersNumber(number)
                    setCommandes({ list: divisions, isLoaded: true })
                }
            }
        ).catch((err)=>{
            console.log("error")
            setError(err)
            setCommandes(prev => ({
                ...prev,
                isLoaded: true
            }))
        })
    }
    const handleChangeOrdersPeriode = (e) => {
        let startDate, endDate;

        switch (e.target.value) {
            case "week":
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;

            case "month":
                startDate = moment().startOf('months').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;

            case "year":
                startDate = moment().startOf('years').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;

            default:
                startDate = moment().startOf('week').format('yyyy-MM-DD');
                endDate = moment().format('yyyy-MM-DD')
                break;
        }


        setOrdersDate({
            startDate,
            endDate
        })
        setOrdersPeriode(e.target.value)
    }

    const checkDateOnInput = (date, value, ref) => {
        if (ref === 'start') {
            setInputDate({
                startDate: value,
                endDate: inputDate.endDate
            })
        } else if (ref === 'end') {
            setInputDate({
                startDate: inputDate.startDate,
                endDate: value
            })
        }
    }

    useEffect(() => {
        getCommandes({ startDate: ordersDate.startDate, endDate: ordersDate.endDate })
        setInputDate(ordersDate)
    }, [ordersDate])

    const inputClass = inputStyles()

    return (
        type === "bySite" || type === "byMarketplace" ?
            <Box pb={2}>
                <AccordionCustom defaultExpanded={true} square={true} title={props.t('Répartition commandes')}>
                    {
                        !commandes?.isLoaded && !error ?
                            <Box style={{
                                position: "relative",
                                height: "300px",
                                width: "100%",
                                color: colors.grey.regular,
                            }}>
                                <PageLoader />
                            </Box>
                        :!error?
                        <Grid container direction='row' lg={12} md={12} xs={12} style={{ gap: '10px', alignItems: 'center' }}>
                            <Box style={{ marginRight: 20 }}>
                                <TitleGlobals variant={"h2"}>Commandes par {props.type === "bySite" ? "E-Commerce" : "Marketplaces"}</TitleGlobals>
                            </Box>
                            <Box pt={1} pb={1} style={{ display: 'flex', gap: '10px' }}>
                                <FormControl>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={ordersPeriode}
                                        className={inputClass.input}
                                        style={{
                                            width: "100%",
                                            padding: "5px 10px",
                                            fontWeight: 'bold'
                                        }}
                                        disableUnderline
                                        onChange={handleChangeOrdersPeriode}
                                    >
                                        <MenuItem value={"week"}>Semaine</MenuItem>
                                        <MenuItem value={"month"}>Mois</MenuItem>
                                        <MenuItem value={"year"}>Année</MenuItem>
                                    </Select>
                                </FormControl>
                                <MuiPickersUtilsProvider utils={MomentUtils} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: "center" }}>
                                    <DatePickerCustom
                                        disableToolbar
                                        autoOk
                                        invalidDateMessage={''}
                                        maxDateMessage={''}
                                        variant="inline"
                                        labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                        format="yyyy-MM-DD"
                                        value={inputDate.startDate}
                                        maxDate={inputDate.endDate}
                                        onChange={(date, value) => checkDateOnInput(date, value, 'start')}
                                        onKeyDown={(e) => e.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    />
                                    <DatePickerCustom
                                        disableToolbar
                                        autoOk
                                        invalidDateMessage={''}
                                        maxDateMessage={''}
                                        variant="inline"
                                        labelFunc={(date, invalidLabel) => moment(date).format("DD/MM/YYYY")}
                                        format="yyyy-MM-DD"
                                        value={inputDate.endDate}
                                        maxDate={moment().format('yyyy-MM-DD')}
                                        minDate={inputDate.startDate}
                                        onChange={(date, value) => checkDateOnInput(date, value, 'end')}
                                        onKeyDown={(e) => e.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    />
                                    <OurButton
                                        onClick={(e) => {
                                            setOrdersDate({
                                                startDate: inputDate.startDate,
                                                endDate: inputDate.endDate
                                            })
                                        }}
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                    >
                                        Valider
                                    </OurButton>
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Grid>
                        :
                        <Box style={{
                            display:"flex",
                            width:"100%",
                            justifyContent:"center"
                        }}>
                            <Typography style={{ padding: '24px 0px', color: colors.grey.regular }}>Aucune donnée disponible</Typography>
                        </Box>
                    }                                    
                </AccordionCustom>
            </Box>
            : null
    )
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(StatsOrder)));