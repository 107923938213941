import { Divider, Modal } from "@material-ui/core";
import AccordionCustom from "../../../../../layouts/Accordion/AccordionCustom";
import OurTypography from "../../../../typography/Typography";
import colors from "../../../../../../config/theme/colors";
import InputBuilder, { TextFieldCustom } from "../../../InputBuilder";
import PanoVirtualTour from "./PanoVirtualTour";
import CloseIcon from '@material-ui/icons/Close';   
import DeleteIcon from '@material-ui/icons/Delete';
import OurButton from "../../../../button/Button";
import { useEffect, useState } from "react";
import RoomIcon from '@material-ui/icons/Room';
import AdjustIcon from '@material-ui/icons/Adjust';

const VirtualTourModal = props =>{
    const [reloadPano, setRealoadPano] = useState(false)

    return (
        <Modal
            open={props.openModalMarker}
            onClose={() => props.setOpenModalMarker(!props.openModalMarker)}
            style={{
                display: 'flex',
                alignItems: 'center',   
                justifyContent: 'center',
                flexDirection: 'column',
            }}
            >
                <div style={{
                        height:'95vh',
                        width:'95vw',
                        display: 'grid',
                        gridTemplateColumns: '1fr',
                        gridTemplateRows:  "45px calc(95vh - 45px)",
                        outline:"none",
                    }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: "10px 20px",
                        background: 'white',
                        borderBottom: `1px solid ${colors.grey.lighter.hue700}`
                    }}>
                        <OurTypography fontweighttext={"bold"} style={{fontSize:"18px"}} colortext={colors.black.regular}>Virtual Tour</OurTypography>
                        <CloseIcon style={{cursor:'pointer'}} onClick={()=>{props.setOpenModalMarker(!props.openModalMarker);props.setCurrentMarker(null);props.setCurrentImageMarker(props.markersImageData[0])}}/>
                    </div>
                    <div style={{
                        height:'100%',
                        width:'100%',
                        display: 'grid',
                        gridTemplateColumns: '3fr 1fr',
                        gridTemplateRows:  " 100%",
                        gap: 20,
                        outline:"none",
                        padding: 20,
                        background: 'white',
                    }} >
                        <PanoVirtualTour setRealoadPano={setRealoadPano} reloadPano={reloadPano} currentMarker={props.currentMarker} setCurrentMarker={props.setCurrentMarker} stateCallback={props.stateCallback} setCurrentImageMarker={props.setCurrentImageMarker} currentImageMarker={props.currentImageMarker} markersImageData={props.markersImageData} setMarkersImageData={props.setMarkersImageData}/>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateRows: '1fr max-content',
                            }}
                        >
                            <div 
                                className='marker-data-container'
                                style={{
                                    background: 'white',
                                    display: 'grid',
                                    gridTemplateRows: `repeat(${props.currentImageMarker?.markers.length * 2}, max-content)`,
                                    gridTemplateColumns: '100%',
                                    rowGap:"10px",
                                    overflowY: 'scroll', 
                                }}
                            >
                                {
                                    props.currentImageMarker?.markers?.map((marker, index) => {
                                        return (
                                            <>
                                                <AccordionCustom
                                                defaultExpanded={marker === props.currentMarker}
                                                custompadding={"0px !important"}
                                                custompaddingsummary={"0px !important"}
                                                noborder
                                                title={
                                                    <div
                                                        style={{
                                                            display:"flex",
                                                            alignItems:"center",
                                                            gap:"5px"
                                                        }}
                                                    >
                                                        {
                                                            marker?.type === 'custom' ?
                                                            <AdjustIcon/>
                                                            :<RoomIcon/>
                                                        }
                                                        <OurTypography fontweighttext={"bold"} style={{fontSize:"18px"}} colortext={colors.black.regular}>{marker?.title}</OurTypography>
                                                    </div>
                                                }
                                                key={index}
                                                >
                                                    <div 
                                                        style={{
                                                            display:"flex",
                                                            flexDirection:"column",
                                                            rowGap:10,
                                                            width:"100%"
                                                        }}
                                                    >
                                                        <label>Image</label>
                                                        <InputBuilder
                                                            input={{
                                                                type: 'mediaPicker',
                                                                helper: {
                                                                    text: `Indiquez le groupe d'attributs`,
                                                                    link: false,
                                                                },
                                                                translated: false,
                                                                required: false,
                                                                disabled: false,
                                                                handleMediaPicker:(e) => {
                                                                    let array = props.markersImageData.map((f,i) => {
                                                                        if (props.currentImageMarker === f) {
                                                                            const newCurrent = {
                                                                                ...f,
                                                                                markers: f.markers.map(g=>{
                                                                                    if (g === marker) {
                                                                                        return {
                                                                                            ...g,
                                                                                            image: e
                                                                                        }
                                                                                    }
                                                                                    return g
                                                                                })
                                                                            }      
                                                                            props.setCurrentImageMarker(newCurrent)
                                                                            return newCurrent
                                                                        }
                                                                        return f
                                                                    })
                                                                    props.setMarkersImageData(array)
                                                                    let finalData = {
                                                                        scenes : array
                                                                    }
                                                                    props.stateCallback(JSON.stringify(finalData))
                                                                }
                                                            }}
                                                            type={'inline'}
                                                            value={marker?.image}
                                                        />
                                                        <label>Nom</label>
                                                        <TextFieldCustom
                                                            variant='outlined'
                                                            type={"text"}
                                                            fullWidth
                                                            value={marker?.title}
                                                            onChange={(e)=>{
                                                                let array = props.markersImageData.map((f,i) => {
                                                                    if (props.currentImageMarker === f) {
                                                                        const newCurrent = {
                                                                            ...f,
                                                                            markers: f.markers.map(g=>{
                                                                                if (g === marker) {
                                                                                    return {
                                                                                        ...g,
                                                                                        title: e.target.value
                                                                                    }
                                                                                }
                                                                                return g
                                                                            })
                                                                        }      
                                                                        props.setCurrentImageMarker(newCurrent)
                                                                        return newCurrent
                                                                    }
                                                                    return f
                                                                })
                                                                props.setMarkersImageData(array)
                                                                let finalData = {
                                                                    scenes : array
                                                                }
                                                                props.stateCallback(JSON.stringify(finalData))
                                                            }}
                                                        />
                                                        <label>Informations</label>
                                                        <TextFieldCustom
                                                            variant="outlined"
                                                            value={marker?.text}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            onChange={e => {
                                                                let array = props.markersImageData.map((f,i) => {
                                                                    if (props.currentImageMarker === f) {
                                                                        const newCurrent = {
                                                                            ...f,
                                                                            markers: f.markers.map(g=>{
                                                                                if (g === marker) {
                                                                                    return {
                                                                                        ...g,
                                                                                        text: e.target.value
                                                                                    }
                                                                                }
                                                                                return g
                                                                            })
                                                                        }      
                                                                        props.setCurrentImageMarker(newCurrent)
                                                                        return newCurrent
                                                                    }
                                                                    return f
                                                                })
                                                                props.setMarkersImageData(array)
                                                                let finalData = {
                                                                    scenes : array
                                                                }
                                                                props.stateCallback(JSON.stringify(finalData))
                                                            }}
                                                            
                                                            spellcheck={true}
                                                            lang="fr"
                                                        />
                                                        <label>Lien</label>
                                                        <TextFieldCustom
                                                            variant='outlined'
                                                            type={"text"}
                                                            fullWidth
                                                            value={marker?.link}
                                                            onChange={(e)=>{
                                                                let array = props.markersImageData.map((f,i) => {
                                                                    if (props.currentImageMarker === f) {
                                                                        const newCurrent = {
                                                                            ...f,
                                                                            markers: f.markers.map(g=>{
                                                                                if (g === marker) {
                                                                                    return {
                                                                                        ...g,
                                                                                        link: e.target.value
                                                                                    }
                                                                                }
                                                                                return g
                                                                            })
                                                                        }      
                                                                        props.setCurrentImageMarker(newCurrent)
                                                                        return newCurrent
                                                                    }
                                                                    return f
                                                                })
                                                                props.setMarkersImageData(array)
                                                                let finalData = {
                                                                    scenes : array
                                                                }
                                                                props.stateCallback(JSON.stringify(finalData))
                                                            }}
                                                        />
                                                        <label>Type de marker</label>
                                                        <InputBuilder
                                                            input={{
                                                                type: 'select',
                                                                helper: {
                                                                    text: `Indiquez le groupe d'attributs`,
                                                                    link: false,
                                                                },
                                                                translated: false,
                                                                required: false,
                                                                disabled: false,
                                                                value:[
                                                                    {value:"info",label:"Simple"},
                                                                    {value:"custom",label:"changement d'image"},
                                                                ]
                                                            }}
                                                            stateCallback={(e) => {
                                                                let array = props.markersImageData.map((f,i) => {
                                                                    if (props.currentImageMarker === f) {
                                                                        const newCurrent = {
                                                                            ...f,
                                                                            markers: f.markers.map(g=>{
                                                                                if (g === marker) {
                                                                                    return {
                                                                                        ...g,
                                                                                        type: e.target.value
                                                                                    }
                                                                                }
                                                                                return g
                                                                            })
                                                                        }      
                                                                        props.setCurrentImageMarker(newCurrent)
                                                                        return newCurrent
                                                                    }
                                                                    return f
                                                                })
                                                                props.setMarkersImageData(array)
                                                                let finalData = {
                                                                    scenes : array
                                                                }
                                                                props.stateCallback(JSON.stringify(finalData))
                                                                setRealoadPano(true)
                                                            }}
                                                            value={marker?.type}
                                                        />
                                                        {
                                                            marker?.type === "custom" &&
                                                          <>
                                                            <label>Redirection</label>
                                                            <InputBuilder
                                                                input={{
                                                                    type: 'select',
                                                                    helper: {
                                                                        text: '',
                                                                        link: false,
                                                                    },
                                                                    translated: false,
                                                                    required: false,
                                                                    disabled: false,
                                                                    value:props.markersImageData.map((e,i)=>{
                                                                        if (e !== props.currentImageMarker) {
                                                                            return {value:e,label:`Image ${i+1}`}
                                                                        }
                                                                    }).filter(e=>e)
                                                                }}
                                                                stateCallback={(e) => {
                                                                    let array = props.markersImageData.map((f,i) => {
                                                                        if (props.currentImageMarker === f) {
                                                                            const newCurrent = {
                                                                                ...f,
                                                                                markers: f.markers.map(g=>{
                                                                                    if (g === marker) {
                                                                                        return {
                                                                                            ...g,
                                                                                            redirect: e.target.value
                                                                                        }
                                                                                    }
                                                                                    return g
                                                                                })
                                                                            }      
                                                                            props.setCurrentImageMarker(newCurrent)
                                                                            return newCurrent
                                                                        }
                                                                        return f
                                                                    })
                                                                    props.setMarkersImageData(array)
                                                                    let finalData = {
                                                                        scenes : array
                                                                    }
                                                                    props.stateCallback(JSON.stringify(finalData))
                                                                    setRealoadPano(true)
                                                                }}
                                                                value={marker?.redirect}
                                                            />
                                                          </>  
                                                        }
                                                    </div>
                                                </AccordionCustom>
                                                <Divider/>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <OurButton 
                                color={colors.white}
                                bgcolor={colors.green.regular}
                                bgcolorhover={colors.green.regular}
                                colorhover={colors.white}
                                onClick={()=>{props.setOpenModalMarker(!props.openModalMarker);props.setCurrentMarker(null);props.setCurrentImageMarker(props.markersImageData[0])}}
                            >
                                Valider le contenu
                            </OurButton>
                        </div>
                    </div>
                </div>
        </Modal>
    )
}

export default VirtualTourModal;