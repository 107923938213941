import { ItemTypes } from "../../../../shareable/types";

export function getTypeName(type) {
  switch (type) {
    // MINISITE
    case ItemTypes.SLIDER:
      return "Slider";
    case ItemTypes.SLIDER_MULTIPLE:
      return "Slider multiple";
    case ItemTypes.SLIDER_WITH_RIGHT_IMAGES:
      return "Slider avec images";
    case ItemTypes.TAB_PRODUCT:
      return "Produits";
    case ItemTypes.IMAGE_ROW:
      return "Images Instagram";
    case ItemTypes.SECTION_TITLE:
      return "Section de titre";
    case ItemTypes.THREE_IMAGES_GRID:
      return "Grille d'images";
    case ItemTypes.THREE_IMAGES_ROW:
      return "Bandeau d'images";
    case ItemTypes.BLOC_TEXT:
      return "Bloc de texte";
    case ItemTypes.GIFT_FINDER:
      return "Gift finder";
    case ItemTypes.INFORMATION_BANNER:
      return "Bandeau d'information";
    case ItemTypes.LINK_BANNER:
      return "Bandeau lien";
    case ItemTypes.CATEGORY:
      return "Catégorie";
    case ItemTypes.TEXT_IMAGE:
      return "Texte image";
    case ItemTypes.SLIDER_PRODUCT:
      return "Slider produit";
    case ItemTypes.TEXT_VIDEO:
      return "Texte vidéo";
    // OFFER
    case ItemTypes.OFFER_FRONT_PAGE:
      return "Page de garde";
    case ItemTypes.OFFER_SECTION_TITLE:
      return "Section de titre";
    case ItemTypes.OFFER_ABOUT_SLIDE:
      return "À propos";
    case ItemTypes.OFFER_TRIPLE_BLOCK_LIST:
      return "Équipe";
    case ItemTypes.OFFER_TRIPLE_BLOCK:
      return "Division de 3 blocs";
    case ItemTypes.OFFER_TRIPLE_BLOCK_TEXT:
      return "Division de 3 textes";
    case ItemTypes.OFFER_TEXT_WITH_IMAGE:
      return "Référence client";
    case ItemTypes.OFFER_TEXT_WITH_IMAGE_SIMPLE:
      return "Texte et image";
    case ItemTypes.OFFER_DEVIS:
      return "Devis";
    // GAME
    case ItemTypes.BASIC_BLOC:
      return "Bloc de présentation";
    case ItemTypes.BASIC_BLOC2:
      return "Bloc de présentation 2";
    case ItemTypes.BANNER_IMAGE:
      return "Bannière d'image";
    case ItemTypes.RECAP_WISHLIST:
      return "Récapitulatif de la Wishlist";
    // AMAZING GAME BLOC
    case ItemTypes.DOTATIONS_ONE_IMAGE:
      return "Image";
    //CMS BLOC
    case ItemTypes.CMS_SLIDER:
      return "Slider Top";
    case ItemTypes.CMS_SLIDER_ABOUT:
      return "Slider à propos";
    case ItemTypes.CMS_LIST_CATEGORIES:
      return "Liste catégories";
    case ItemTypes.CMS_SLIDER_BY_TAB:
      return "Slider par catégorie";
    case ItemTypes.CMS_SLIDER_INSTAGRAM:
      return "Slider Instagram";
    case ItemTypes.CMS_SLIDER_BLOCS_TEXT_MEDIA:
      return "Slider texte & média";
    case ItemTypes.CMS_SLIDER_PRESENTATION_MEDIA:
      return "Slider médias";
    case ItemTypes.CMS_SLIDER_MOMENTS:
      return "Slider moments";
    case ItemTypes.CMS_SLIDER_IMAGE:
      return "Slider images";
    case ItemTypes.CMS_GRID_IMAGE:
      return "Grille d'images";
    case ItemTypes.CMS_TEXT_MEDIA:
      return "Bloc de texte & média";
    case ItemTypes.CMS_MULTIPLE_TEXT:
      return "Bloc de texte multiple";
    case ItemTypes.CMS_BANNER_MEDIA:
      return "Bannière média";
    case ItemTypes.CMS_SECTION_DESCRIPTION_PAGE:
      return "Section description";
    case ItemTypes.CMS_SECTION_MANIFEST_BLOCK:
      return "Section du bloc manifeste";
    case ItemTypes.CMS_CATEGORIES_HOME:
      return "Catégories multiples";
    case ItemTypes.CMS_COLLECTIONS_HOME:
      return "Collections multiples";
    case ItemTypes.CMS_PRESENTATION_DUO:
      return "Présentation duo";
    case ItemTypes.CMS_SLIDER_PRODUCTS_HOME:
      return "Slider de produits";
    case ItemTypes.CMS_BLOC_STORELOCATOR_HOME:
      return "Bloc storelocator";
    case ItemTypes.CMS_ENTETE:
      return "Bloc entête";
    case ItemTypes.CMS_ACCORDION:
      return "Bloc accordéons";
    case ItemTypes.CMS_MEDIA_CUSTOM:
      return "Bloc Média avancé";
    case ItemTypes.CMS_SLIDER_TOP_NEW:
      return "Slider Top custom";
    case ItemTypes.CMS_TEXT_LAYERS:
      return "Bloc de texte layer";
    case ItemTypes.CMS_TOP_IMAGE_FULLSCREEN:
      return "Bloc top image";
    case ItemTypes.CMS_SLOGAN:
      return "Bloc slogan";
    case ItemTypes.CMS_TITLE_TEXT:
      return "Bloc titre et texte";
    case ItemTypes.CMS_SEPARATOR:
      return "Séparateur";
    case ItemTypes.CMS_CUSTOM:
      return "Bloc personnalisable";
    case ItemTypes.CMS_BLOC:
      return "Bloc";
    case ItemTypes.CMS_BLOC_SEO:
      return "Bloc SEO";
    case ItemTypes.CMS_DOUBLE_COLONNE:
      return "Bloc Double Colonne";
    case ItemTypes.CMS_DOWNLOAD_FILES:
      return "Download Files";
    //FLIPBOOK BLOC
    case ItemTypes.HEADER_BOOK:
      return "Header Flipbook";
    case ItemTypes.HEADER_CART_BOOK:
      return "Header Cart Flipbook";
    case ItemTypes.FOOTER_BOOK:
      return "Footer Flipbook";
    case ItemTypes.TOP_HOMEPAGE_BOOK:
      return "Top Homepage Flipbook";
    case ItemTypes.POSTCARD_BOOK:
      return "PostCard Flipbook";
    case ItemTypes.RECAP_BLOCK_BOOK:
      return "Recap block Flipbook";
    case ItemTypes.RESULTS_BOOK:
      return "Results Flipbook";
    case ItemTypes.BANNER_LINK_BOOK:
      return "Banner link Flipbook";
    case ItemTypes.EMAIL_SUCCESS_BOOK:
      return "Email Success Flipbook";
    case ItemTypes.FLIPBOOK:
      return "Flipbook Bloc";
    case ItemTypes.FLIPBOOK_WITH_CART:
      return "Flipbook with cart";
    case ItemTypes.COPYRIGHT_BOOK:
      return "Copyright";
    // case ItemTypes.FLIPBOOK_WITH_WISHLIST: return "Flipbook wishlist";
    default:
      return "n/a";
  }
}
