import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from '../../../../shared/components/CustomButton';
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Pg from '../../../../shared/components/CustomParagraph';
import Div from '../../../../shared/components/CustomDiv';
import request from "../../../../../tools/Fetch";
import styled from "styled-components";


const DivContainerListCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &>div{
        margin: 32px 8px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        img{
            display: block;
            position: relative;
            z-index: 1;
            width: 100%;
            &.imageLeft{
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 50%;
                transition: all ease 500ms;
                &.open{
                    left: -50%;
                }
            }
            &.imageRight{
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0;
                width: 50%;
                transition: all ease 500ms;
                &.open{
                    right: -50%;
                }
            }
        }
        @media screen and (max-width: 500px){
            margin: 24px 2px;
        }
    }
`;


const GameScratchImage = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hidePopup, setHidePopup] = useState(false);
    const [endGame, setEndGame] = useState(false);
    const [open, setOpen] = useState(null);
    const [isWin, setIsWin] = useState(false);
    const [isFinish, setIsFinish] = useState(false);

    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.startPopup.inputs['backgroundImage'].value;

    let border = null;
    if (data.blocks.startPopup.inputs.border) {
        border = data.blocks.startPopup.inputs.border;
    }

    function handlerStartGame(){
        setHidePopup(true);
    }
    function handlerEndGame(index){
        if(!open){
            setOpen(index);
            setTimeout(() => {
                setIsFinish(true);
                setTimeout(() => {
                    if(isWin){
                        window.location = '/win';
                    }else{
                        window.location = '/loose';
                    }
                }, 3000);
            }, 1000);
        }
    }

    useEffect(() => {
        let data = new FormData();
        data.append('username', localStorage.getItem('username'));
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        try {
            request(`${process.env.REACT_APP_API}/public/asset/games/instant/registration/has_win`, 'post', data, 'multipart/form-data').then(response => {
                if (response.code === "has_win"){
                    setIsWin(true);
                    localStorage.setItem('dotation', JSON.stringify(response.productData));
                }else if(response.code === "has_lost"){
                    setIsWin(false);
                }else if (response.code === "already_played_today"){
                    window.location = '/already-played';
                }
            });
        } catch(e) {
            console.log(e);
        }
    }, []);
    
    return (
        <div className="game_game" id="game_scratch_image">
            <section 
                className={endGame ? "game ended" : "game"}
                style={{
                    backgroundColor: data.inputs['backgroundColor']?.value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover!important",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative'
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >   
                { !preview && hover && props.toolbarSection }
                <div className="scratch-container">
                    <img 
                        style={{margin: 'auto', display: 'block'}}
                        src={
                            typeof data.blocks.game.blocks.logo.inputs.value.value === 'string' ? 
                                data.blocks.game.blocks.logo.inputs.value.value : 
                                data.blocks.game.blocks.logo.inputs.value?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.game.blocks.logo.inputs.value.value.filePath}` 
                                    : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Logo"
                        } 
                    />
                    <H1 data={data.blocks.game} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                    <Pg data={data.blocks.game} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} />
                    <DivContainerListCard>
                        {
                            data.blocks.game.blocks.list_lots.config.map((card, index) => (
                                <div style={{
                                    borderWidth: data.blocks.game.inputs.borderCards?.inputs?.borderWidth?.value ? data.blocks.game.inputs.borderCards.inputs.borderWidth.value : '0px',
                                    borderStyle: 'solid',
                                    borderColor: data.blocks.game.inputs.borderCards?.inputs?.borderColor?.value ? data.blocks.game.inputs.borderCards.inputs.borderColor.value : null,
                                    borderRadius: data.blocks.game.inputs.borderCards?.inputs?.borderRadius?.value ? data.blocks.game.inputs.borderCards.inputs.borderRadius.value : null,
                                }} 
                                onClick={() => handlerEndGame(index)}>
                                    {isWin ? (
                                        <img src={
                                            typeof card.blocks.imageWin.inputs.value.value === 'string' ? 
                                                card.blocks.imageWin.inputs.value.value : 
                                                card.blocks.imageWin.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${card.blocks.imageWin.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/360x420/FFFFFF/53839D?text=Image"
                                            } 
                                        />
                                    ) : (
                                        <img src={
                                            typeof card.blocks.imageLoose.inputs.value.value === 'string' ? 
                                                card.blocks.imageLoose.inputs.value.value : 
                                                card.blocks.imageLoose.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${card.blocks.imageLoose.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/360x420/FFFFFF/53839D?text=Image"
                                            } 
                                        />
                                    )}
                                    <img className={`imageRight ${open === index ? 'open' : ''}`} src={
                                        typeof card.blocks.imageRight.inputs.value.value === 'string' ? 
                                            card.blocks.imageRight.inputs.value.value : 
                                            card.blocks.imageRight.inputs.value?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${card.blocks.imageRight.inputs.value.value.filePath}` 
                                                : "http://via.placeholder.com/180x420/FFFFFF/53839D?text=Image"
                                        } 
                                    />
                                    <img className={`imageLeft ${open === index ? 'open' : ''}`} src={
                                        typeof card.blocks.imageLeft.inputs.value.value === 'string' ? 
                                            card.blocks.imageLeft.inputs.value.value : 
                                            card.blocks.imageLeft.inputs.value?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${card.blocks.imageLeft.inputs.value.value.filePath}` 
                                                : "http://via.placeholder.com/180x420/FFFFFF/53839D?text=Image"
                                        } 
                                    />
                                </div>
                            ))
                        }
                    </DivContainerListCard>
                </div>
                <div className={hidePopup ? "popup game" : "popup play game"} style={{position: 'absolute'}}>
                    <div className="overlay" style={{background: data.blocks.startPopup?.inputs['overlay'].value}}></div>
                    <div 
                        className="popup_content" 
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{
                            backgroundColor: data.blocks.startPopup?.inputs['backgroundColor'].value,
                            backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderStyle: 'solid',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarPopup }
                        <div>
                            {
                                data.blocks.startPopup?.blocks?.title?.inputs.value.value ? 
                                    <H2 data={data.blocks.startPopup} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                                : null
                            }
                            {
                                data.blocks.startPopup?.blocks?.desc?.inputs?.value.value && data.blocks.startPopup?.blocks?.desc?.inputs?.value.value !== "<p><br></p>" ? 
                                    <Div data={data.blocks.startPopup} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                                : null
                            }
                            <Button data={data.blocks.startPopup} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" nopadding={true} onClick={() => handlerStartGame()}/>
                        </div>
                    </div>
                </div>
                
                <div className={`animate_result ${isFinish ? 'ended' : ''}`} style={{background: data.blocks.endGame.inputs.overlay.value}}>
                    {
                        isWin ? (
                            <img src={
                                typeof data.blocks.endGame.blocks.imageWin.inputs.value.value === 'string' ? 
                                    data.blocks.endGame.blocks.imageWin.inputs.value.value : 
                                    data.blocks.endGame.blocks.imageWin.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.endGame.blocks.imageWin.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/420x180/FFFFFF/53839D?text=Gagné"
                                } 
                            />
                        ) : (
                            <img src={
                                typeof data.blocks.endGame.blocks.imageLoose.inputs.value.value === 'string' ? 
                                    data.blocks.endGame.blocks.imageLoose.inputs.value.value : 
                                    data.blocks.endGame.blocks.imageLoose.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.endGame.blocks.imageLoose.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/420x180/FFFFFF/53839D?text=Perdu"
                                } 
                            />
                        )
                    }
                </div>
            </section>
        </div>
    );
};

GameScratchImage.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GameScratchImage;
