import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography, Box, Drawer, Divider } from '@material-ui/core';
import _ from 'lodash';
import colors from '../../../../../config/theme/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import TopPanel from '../../../../layouts/TopPanel/TopPanel';
import CloseIcon from '@material-ui/icons/Close';        
import OurButton from '../../../../ui/button/Button';
import CarouselCustom from '../../../../layouts/Carousel/CarouselCustom';

const DrawerCustom = styled(Drawer)`
&> .MuiDrawer-paper{
    width: ${props => props.drawerwidth ? typeof props.drawerwidth === 'string' ? props.drawerwidth : `calc(50% - ${props.drawerwidth}px / 2)` : 'calc(797px)'};
    padding: 60px;
    z-index:1201;
}
`

const BoxClose = styled(Box)`
    width: 80px;
    padding-bottom: 0;
    color: ${colors.black.regular};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
    span{
        margin-left: 4px;
    }
`;

const WrapperAttribut = styled(Grid)`
    display:grid;
    grid-template-columns:1fr max-content;
    align-items:center;
    border:1px dashed #72777980;
    margin-bottom:10px;
    gap:10;
`;

const ScrapDrawer = (props)=>{
    const [name,setName] = useState(null)
    const [description,setDescription] = useState(null)
    const [price,setPrice] = useState(null)
    const [images,setImages] = useState([])
    const [attributs,setAttributs] = useState([])    

    useEffect(()=>{        
        let newAttributs =[]
        let newImages =[]
        props.allState?.scrapAttributes.map((item)=>{
            const findTerm = (term) => {
                if (item.identifier.includes(term)){
                  return item.identifier;
                }
              };            
            switch (item.identifier) {
                case "product_name":
                    setName(item.locales[0].value)
                    break;
                case "product_description":
                    setDescription(item.locales[0].value)
                    break;
                case "product_price":
                    setPrice(item.locales[0].value)
                    break;
                case findTerm("product_image"):                                        
                    newImages.push(`${process.env.REACT_APP_MEDIAS}/${item.locales[0].media?.filePath}`)
                    break;
                default:
                    let attribut = {
                        label: item.translationLabel[0].node.value,
                        value:item.locales[0].value,
                        identifier: item.identifier
                    }
                    newAttributs.push(attribut)                          
                    break;
            }            
        })
        setAttributs(newAttributs)
        setImages(newImages)                              
    },[props.allState])


    return (
        <DrawerCustom
        open={props.open}        
        onClose={props.onClose}
        anchor={"right"}
        drawerwidth={props.drawerwidth}
        elevation={0}    
        variant={"persistent"}                                                
    >
        <Typography variant="h5" onClick={props.onClose} component="div">
            <BoxClose pb={2} style={{ cursor: 'pointer' }}>
                <CloseIcon /> <span>Fermer</span>
            </BoxClose>
        </Typography>
        <TopPanel inForm={true} title={"Scrapping produit"} subtitle={"Cliquez sur un attribut pour le sélectionner afin de l’ajouter sur votre fiche principale."} />
        <Grid container>
            <Grid item xs={12}>                            
                <Typography variant={"body2"} style={{paddingBottom:10}}>
                  Choisir une source
                </Typography>
                <Select variant='outlined' value={props.allState.currentSource} style={{width:"100%", marginRight: 16 }} onChange={(event, value) => props.handleCurrentSource(event.target.value)}>
                    {
                        props.allState.listSources.map((source)=>{                                        
                            return <MenuItem value={source.node.id}>{source.node.libelle}</MenuItem>                                        
                        })
                    }                                
                </Select>                            
            </Grid>
            {
                props.allState.scrapIsReady?
                    <>
                        <Grid item xs={12} style={{paddingTop:20,paddingBottom:20}}>                                                                        
                            <CarouselCustom slidesNb={1.5} images={images} imagesWithoutPlaceholder={images}/>
                        </Grid>
                        <WrapperAttribut item xs={12} >
                            <Typography style={{color:"black",fontWeight:"bold"}} variant='h1'>{name}</Typography>
                            <OurButton margin={"0"} onClick={()=>props.handleSelectScrap('selectedScrap', 'product_name', name)}>
                                {props.allState.selectedScrap?.find(e => e.key === 'product_name') ? "Enlever" : "Choisir"}
                            </OurButton>
                        </WrapperAttribut>
                        <WrapperAttribut item xs={12} >
                            <Box style={{display:"grid",gridTemplateColumns:"1fr 2fr",width:"100%"}}>
                                <Typography style={{color:"black",width:"30%"}}>Prix :</Typography>
                                <Typography style={{color:"black",fontWeight:"bold"}}>
                                    {price}
                                </Typography>
                            </Box>
                            <OurButton margin={"0"} onClick={()=>props.handleSelectScrap('selectedScrap', 'product_price', price)}>
                                {props.allState.selectedScrap?.find(e => e.key === 'product_price') ? "Enlever" : "Choisir"}
                            </OurButton>                                        
                        </WrapperAttribut>
                        <WrapperAttribut item xs={12}>
                            <Typography style={{color:"black"}}>{description}</Typography>
                            <OurButton margin={"0"} onClick={()=>props.handleSelectScrap('selectedScrap', 'product_description', description)}>
                                {props.allState.selectedScrap?.find(e => e.key === 'product_description') ? "Enlever" : "Choisir"}
                            </OurButton>
                        </WrapperAttribut>
                        <Grid item xs={12} style={{padding:"20px 0"}}>                                        
                            <Divider />
                        </Grid>
                        {
                            attributs.length > 0
                            ?<>
                                <Grid item xs={12} style={{display:"grid",gridTemplateColumns:"1fr max-content",alignItems:"center",marginBottom:10}}>
                                    <Typography style={{color:"black",fontWeight:"bold"}}>Attributs du produit</Typography>                                        
                                </Grid>
                                {
                                    attributs.map((attribut)=>{                                
                                        return (
                                            <WrapperAttribut item xs={12}>
                                                <Box style={{display:"grid",gridTemplateColumns:"1fr 2fr",width:"100%"}}>
                                                    <Typography style={{color:"black"}}>{attribut.label} :</Typography>
                                                    <Typography style={{color:"black",fontWeight:"bold"}}>{attribut.value}</Typography>
                                                </Box>
                                                <OurButton margin={"0"} onClick={()=>props.handleSelectScrap('selectedScrap',attribut.identifier, attribut.value)}>
                                                    {props.allState.selectedScrap?.find(e => e.key === attribut.identifier) ? "Enlever" : "Choisir"}
                                                </OurButton>                                        
                                            </WrapperAttribut>
                                        )
                                    })                            
                                }
                            </>
                        :null
                        }                                        
                    </>
                :
                <Box style={{display:"flex",justifyContent:"center",width:"100%",paddingTop:20}}>
                    <CircularProgress/>
                </Box>
            }
            {
                props.allState.selectedScrap.length > 0 
                ?
                <Box style={{display:"flex",justifyContent:"flex-end",width:"100%",paddingTop:10}}>
                    <OurButton text="Valider la sélection" bgcolor={colors.green.regular} bgcolorhover={colors.green.regular} style={{ margin: 0 }} onClick={props.handleValidateSelection} />
                </Box>
                :null
            }
        </Grid>
    </DrawerCustom>     
    )
}

export default ScrapDrawer