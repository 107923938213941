import { gql } from 'apollo-boost';

//----- Récupération des Pages
export const GET_PAGES_PAGINATION = gql`
query getPagesPagesPagination($nbperpage: Int, $cursor: String, $cursorLast: String $asset: String){
    assetPagesPages(first: $nbperpage, after: $cursor, before: $cursorLast, asset: $asset, order:{id: "DESC"}){
      pageInfo{
        startCursor
        endCursor
      }
      totalCount
      edges{
        node{
          id
          libelle
          identifier
          url
          isActive
          pageType{
            id
            identifier
            libelle
          }
          asset{
            id
          }
        }
      }
    }
}
`;

export const GET_PAGE_TYPES = gql`
query getAssetPagesPageTypes{
  assetPagesPageTypes{
    edges{
      node{
        id
        identifier
        libelle
      }
    }
  }
}
`

//----- Ajouter une Page
/*
{
  "asset": "/api/assets/1",
  "libelle": "TEST",
  "identifier": "test",
  "url" : "https://www.sinfin.fr",
  "hasToBePush": true,
  "isActive": true
}
 */
export const ADD_PAGE = gql`
mutation createAssetPagesPage($asset: String, $libelle: String!, $identifier: String!, $url: String!, $hasToBePush: Boolean!, $isActive: Boolean!, $pageType: String!) {
  createAssetPagesPage(input:{asset: $asset, libelle: $libelle, identifier: $identifier, url: $url, hasToBePush: $hasToBePush, isActive: $isActive, pageType:$pageType, hasAlreadyBeenPushed: false}){
    assetPagesPage{
        id
        asset{
          id
        }
        libelle
        identifier
        url
        isActive
    }
  }
}
`;

//----- Modifier une Page
/*
{
    "id": "/api/"
    "asset": "/api/assets/1",
    "libelle": "TEST",
    "identifier": "test",
    "url" : "https://www.sinfin.fr",
    "hasToBePush": true,
    "isActive": true
}
 */
export const UPDATE_PAGE = gql`
mutation updateAssetPagesPage($id: ID!, $asset: String, $libelle: String, $identifier: String, $url: String, $hasToBePush: Boolean, $hasAlreadyBeenPushed: Boolean, $isActive: Boolean) {
  updateAssetPagesPage(input:{id: $id, asset: $asset, libelle: $libelle, identifier: $identifier, url: $url, hasToBePush: $hasToBePush, hasAlreadyBeenPushed: $hasAlreadyBeenPushed, isActive: $isActive}){
    assetPagesPage{
        id
        asset{
          id
        }
        libelle
        identifier
        url
        isActive
    }
  }
}
`;

//----- Supprimer un Top Header Message
/*
{
  "id" : "/api/asset-pages-pages/1",
}
 */
export const DELETE_PAGE = gql`
mutation deleteAssetPagesPage($id: ID!) {
  deleteAssetPagesPage(input:{id: $id}){
    assetPagesPage{
        id
    }
  }
}
`;

//----- Récupération des Pages
export const GET_CONTENTS_PAGINATION = gql`
query getPagesContentsPagination($nbperpage: Int, $cursor: String, $pagesPage: String){
    assetPagesPageContents(first: $nbperpage, after: $cursor, pagesPage: $pagesPage){
      pageInfo{
        startCursor
        endCursor
      }
      totalCount
      edges{
        node{
          id
          libelle
          startDate
          endDate
          isDefaultContent
          isActive
          componentsStructure
          pagesPage{
            asset{
              identifier
            }
          }
        }
      }
    }
}
`;

//---- Récupération d'un CONTENT
export const GET_CONTENT_PAGES_PAGE = gql`
query assetPagesPageContent($id: ID!){
  assetPagesPageContent(id: $id){
    id
    content
    libelle
    contentConfig
    componentsStructure
    pagesPage{
      id
      libelle
      identifier
    }
  }
}
`;

//----- Ajouter un Contenu
/*

 */
export const ADD_PAGE_CONTENT = gql`
mutation createAssetPagesPageContent($pagesPage: String!, $libelle: String!, $startDate: String, $endDate: String, $isDefaultContent: Boolean!, $isActive: Boolean!, $isCurrentlyDeployed : Boolean!) {
    createAssetPagesPageContent(input:{pagesPage: $pagesPage, libelle: $libelle, startDate: $startDate, endDate: $endDate, isDefaultContent: $isDefaultContent, isActive: $isActive, isCurrentlyDeployed : $isCurrentlyDeployed}){
    assetPagesPageContent{
        id
        libelle
        startDate
        endDate
        isDefaultContent
        isActive
        componentsStructure
    }
  }
}
`;

//----- Modifier un Contenu
/*
{
}
 */
export const UPDATE_PAGE_CONTENT = gql`
mutation updateAssetPagesPageContent($id: ID!,$libelle: String, $startDate: String, $endDate: String, $isDefaultContent: Boolean, $isActive: Boolean, $contentConfig: String, $content: String, $componentsStructure: String) {
  updateAssetPagesPageContent(input:{id: $id,libelle: $libelle, startDate: $startDate, endDate: $endDate, isDefaultContent: $isDefaultContent, isActive: $isActive, contentConfig: $contentConfig, content: $content, componentsStructure: $componentsStructure}){
    assetPagesPageContent{
        id
        libelle
        startDate
        endDate
        isDefaultContent
        isActive
        content
        contentConfig
        componentsStructure
    }
  }
}
`;

//----- Supprimer un Top Header Message
/*
{
  "id" : "/api/asset-pages-pages/1",
}
 */
export const DELETE_PAGE_CONTENT = gql`
mutation deleteAssetPagesPageContent($id: ID!) {
  deleteAssetPagesPageContent(input:{id: $id}){
    assetPagesPageContent{
        id
    }
  }
}
`;