import { gql } from 'apollo-boost';

//----- Récupération des menuEntries
export const GET_MENU_ENTRIES = gql`
{
  menuEntries{
    edges {
      node {
        id
        parent {
          id
        }
        code
        createdAt
        updatedAt
      }
    }
    totalCount
  }
}
`;

//----- Ajouter une entrée au menu
/*
 Exemple de variables
  {
    "parent" : "/api/menu-entries/5",
    "code" : "demo1.1",
    "createdAt" : "2022-03-15"
  }
*/
export const CREATE_MENUENTRY = gql`
mutation createMenuEntry($parent: String , $code: String!, $createdAt: String!, $updatedAt: String){
    createMenuEntry(input: {parent:$parent, code:$code, createdAt:$createdAt, updatedAt:$updatedAt}){
      menuEntry{
          id
          parent {
            id
          }
          code
          createdAt
          updatedAt
      }
    }
  }
  `;

 //----- Modifier une entrée du menu
 /*
 Exemple de variables
  {
    "id": "/api/menu-entries/3",
    "parent": "/api/menu-entries/2",
    "code": "demo test 1",
    "createdAt": "2022-01-06",
    "updatedAt": "2022-03-15"
  }
*/
export const UPDATE_MENUENTRY = gql`
mutation updateMenuEntry($id: ID!, $parent: String , $code: String, $createdAt: String, $updatedAt: String){
  updateMenuEntry(input: {id:$id, parent:$parent, code:$code, createdAt:$createdAt, updatedAt:$updatedAt}){
    menuEntry{
        id
        parent {
          id
        }
        code
        createdAt
        updatedAt
    }
  }
}
`;

//----- Supprimer une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/menu-entries/6"
  }
*/
export const DELETE_MENUENTRY = gql`
mutation deleteMenuEntry($id: ID!){
  deleteMenuEntry(input: {id:$id}){
    menuEntry{
      id
    }
  }  
}`;

//----- Récupération d'une seule entrée de menu
/*
 Exemple de variables
  {
    "id" : "/api/menu-entries/3"
  }
*/
export const GET_MENU_ENTRY = gql`
query menuEntry($id: ID!){
  menuEntry(id: $id){
    id
    parent {
      id
    }
    code
    createdAt
    updatedAt
  }
}`;