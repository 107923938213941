import { AssetTypes } from "../../../shareable/types";
import { getComponent as getMinisiteComponent } from './minisite';
import { getComponent as getPresentationComponent } from './offer';
import { getComponent as getBookComponent } from './book';
import { getComponent as getGameComponent } from './game';
import { getComponent as getCMSComponent } from './cms';

export const getComponent = (
    element, index, asset, assetType, assetToken, preview = true, droppable = null, // base
    config, apiConfig, // config
    updateConfig, inputCallback, deleteCallback, // methods
    taxes, pagesFlipbook, onSnap, onStyle, // specific
    currentTab
) => {
    switch(assetType) {
        case AssetTypes.MINISITE:
        case AssetTypes.INSTAGRAM:
        case AssetTypes.LINKEDIN:
        case AssetTypes.FACEBOOK:
            return getMinisiteComponent(element, index, preview, assetToken, droppable, config.params, config, apiConfig, updateConfig, inputCallback, deleteCallback, currentTab);
        case AssetTypes.PRESENTATION:
            return getPresentationComponent(element, index, preview, droppable, updateConfig, inputCallback, deleteCallback, taxes, onSnap, onStyle);
        case AssetTypes.FLIPBOOK:
            return getBookComponent(element, preview, config, apiConfig, updateConfig, inputCallback, pagesFlipbook, deleteCallback, droppable, index, asset);
        case AssetTypes.GAME:
            return getGameComponent(element, index, preview, droppable, updateConfig, deleteCallback, inputCallback, config.params, apiConfig, asset);
        case AssetTypes.CMS:
        case AssetTypes.PAGES:
        case AssetTypes.MAGENTO:
        case AssetTypes.SHOPIFY:
        case AssetTypes.WORDPRESS:
            return getCMSComponent(element, index, preview, assetToken, droppable, config.params, config, apiConfig, updateConfig, inputCallback, deleteCallback);
    }
}