import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getImageDefault, getDefaultLink } from '../../helpers';


export default {
    name: 'Section',
    inputs:{
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        backgroundImageTablet: {
          label: "Image de fond tablet",
          type: FormInputTypes.IMAGE,
          value: null,
        },
        backgroundImageMobile: {
          label: "Image de fond mobile",
          type: FormInputTypes.IMAGE,
          value: null,
        },
    },
    blocks: {
        logo: getImageDefault(),
        middleBox:{
            id: uuidv4(),
            title: "Bloc principal",
            name: "Bloc principal",
            type: EditableTypes.MINI_BLOCKS,
            inputs:{
                backgroundColor: {
                    label: 'Couleur de fond',
                    type: FormInputTypes.COLOR,
                    value: '#FFFFFF'
                },
                backgroundImage: {
                    label: 'Image de fond',
                    type: FormInputTypes.IMAGE,
                    value: null
                },
                border: {
                    type: FormInputTypes.GROUP,
                    label: "Border",
                    inputs: {
                        borderColor: {
                            type: FormInputTypes.COLOR,
                            value: '#000',
                            label: "Couleur de la bordure"
                        },
                        borderWidth: {
                            type: FormInputTypes.SLIDER,
                            value: 0,
                            label: "Largeur de la bordure :",
                            params: {max: 5, min: 0, step: 1}
                        },
                    }
                },
                positionTitle: {
                    type: FormInputTypes.SELECT,
                    label: "Largeur du Titre",
                    value: 'initial',
                    params: [{label: 'Initial', value: 'initial'}, {label: 'Fullwidth', value: 'fullwidth'}]
                },
                positionButton: {
                    type: FormInputTypes.SELECT,
                    label: "Largeur du Titre",
                    value: 'initial',
                    params: [{label: 'Initial', value: 'initial'}, {label: 'Sous le texte', value: 'withText'}]
                }
            },
            blocks:{
                title: getDefaultText('Titre "Jeu pas commencé"', "Le jeu concours n'a pas commencé", 42, "#53839D"),
                redirectionText: getDefaultText('Texte', "L’aventure peut commencer tout de suite !", 21, "#53839D"),
                redirectionImage: getImageDefault(),
                redirectionButtonText: getDefaultLink("Lien", "Voir plus", '', 24, '#53839D', '#FFFFFF',  null, 1, '#53839D', 0),
                
                title: getDefaultText('Titre "Jeu pas commencé"', "Le jeu concours n'a pas commencé", 42, "#53839D", "rgba(0,0,0,0)", 'uppercase', 'center'),
                subtitle: getDefaultText('Sous-titre "Jeu pas commencé"', "Encore un peu de patience...", 28, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
                reassurance: getDefaultText('Texte', "L’aventure peut commencer tout de suite !", 18, "#53839D", "rgba(0,0,0,0)", 'initial', 'center'),
                redirectionText: getDefaultText('Texte', "Rassure-toi, l’aventure ne s’arrête pas forcément là !", 20, "#53839D", "rgba(0,0,0,0)", 'initial', 'initial'),
                redirectionImage: getImageDefault(),
                redirectionButtonText: getDefaultLink("Lien", "Voir plus", '', 24, '#53839D', '#FFFFFF',  null, 1, '#53839D', 0),
            }
        }
    }
};
