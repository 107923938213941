import React, { useState, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import mediaPickerConfig from '../../../components/ui/form/config/mediaPicker.config';
import LayoutBuilder from '../../../components/ui/form/LayoutFormBuilder';
import { VIDEOS, IMAGES } from '../../../js/constants/medias-types';
import ImageIcon from '@material-ui/icons/Image';
import { CodeEditorEditable } from 'react-code-editor-editable';
import 'highlight.js/styles/dracula.css';

const useStyles = makeStyles({
	root: {
		width: '100%'
	},
	link: {
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline'
		}
	},
	content: {
		width: 1000,
		maxWidth: '90vw',
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-end'
	},
	title: {
		width: '100%'
	},
	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end'
	},
	source: {
		width: '100%'
	},
	editorButton: {
		borderRadius: 4,
		backgroundColor: '#eee',
		padding: 5,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 5,
		transition: 'all ease-in-out 0.25s',
		'&:hover': {
			cursor: 'pointer',
			opacity: 0.7
		}
	},
	editorButtonIcon: {
		fontSize: 16
	}
});

const GreyButton = withStyles((theme) => ({
	root: {
		color: '#fff',
		marginTop: 15,
		marginRight: 15,
		alignSelf: 'right',
		backgroundColor: grey[500],
		'&:hover': {
			backgroundColor: grey[700]
		}
	}
}))(Button);

const ColorButton = withStyles((theme) => ({
	root: {
		color: '#fff',
		marginTop: 15,
		alignSelf: 'right',
		backgroundColor: green[500],
		'&:hover': {
			backgroundColor: green[700]
		}
	}
}))(Button);

export default function CustomInput({ onChange, value }) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [html, setHtml] = useState('');
	const [css, setCss] = useState('');
	const [js, setJs] = useState('');
	const [mediasOpened, setMediaOpened] = useState(false);
	const ref = useRef(null);

	const onSelect = (value) => {
		setMediaOpened(false);
		let stack = ref?.current?._history?.stack;
		let selectionStart = stack?.[stack.length - 1]?.selectionStart;

		let isImage = value?.type === 'image/jpeg' || value?.type === 'image/jpg' || value?.type === 'image/png';
		let link = `${process.env.REACT_APP_MEDIAS}/${value?.filePath}`;

		// if (isImage)
		// 	link = `<img src="${link}" alt="${value?.alt}" />`;

		onInsert(link, selectionStart);
	};

	const onInsert = (text, index) => {
		let text2 = html.slice(0, index) + text + html.slice(index);
		setHtml(text2);
	};

	return (
		<div className={classes.root}>
			<span className={classes.link} onClick={() => {
				setHtml(value?.html ?? '');
				setCss(value?.css ?? '');
				setJs(value?.js ?? '');
				setOpen(true);
			}}>Modifier les sources</span>

			<Dialog maxWidth='lg' onClose={() => setOpen(false)} open={open} style={{
				zIndex: 100
			}}>
				<DialogTitle>Modifier les sources</DialogTitle>

				<div className={classes.content}>
					<h3 className={classes.title}>HTML</h3>

					<div className={classes.editorButtons}>
						<div className={classes.editorButton} onClick={() => {
							setMediaOpened(true);
						}}>
							<ImageIcon className={classes.editorButtonIcon} />
						</div>
					</div>

					{/* <CodeEditorEditable
						ref={ref}
						language="html"
						value={html}
						width='100%'
						height='500px'
						setValue={code => {
							// let stack = ref?.current?._history?.stack;
							// console.log('selectionStart', stack?.[stack.length - 1]?.selectionStart);
							setHtml(code);
						}}
						padding={10}
					/> */}

					<textarea
						ref={ref}
						value={html}
						width='100%'
						height='500px'
						onChange={(event) => {
							setHtml(event.target.value);
						}}
						style={{
							height: 500,
							width: '100%'
						}}
					/>

					<h3 className={classes.title}>CSS</h3>

					{/* <CodeEditorEditable
						language="css"
						width='100%'
						height='500px'
						value={css}
						setValue={code => setCss(code)}
						padding={10}
					/> */}

					<textarea
						value={css}
						width='100%'
						height='500px'
						onChange={(event) => {
							setCss(event.target.value);
						}}
						style={{
							height: 500,
							width: '100%'
						}}
					/>

					<h3 className={classes.title}>JS</h3>

					{/* <CodeEditorEditable
						language="javascript"
						width='100%'
						height='500px'
						className={classes.editor}
						value={js}
						setValue={code => setJs(code)}
						padding={10}
					/> */}

					<textarea
						value={js}
						width='100%'
						height='500px'
						onChange={(event) => {
							setJs(event.target.value);
						}}
						style={{
							height: 500,
							width: '100%'
						}}
					/>

					<div className={classes.buttons}>
						<GreyButton
							variant="contained"
							color="secondary"
							onClick={() => {
								setHtml('');
								setCss('');
								setJs('');
								setOpen(false);
							}}
						>Annuler</GreyButton>

						<ColorButton
							variant="contained"
							color="primary"
							onClick={() => {
								onChange({
									html,
									css,
									js
								});

								setHtml('');
								setCss('');
								setJs('');
								setOpen(false);
							}}
						>Valider</ColorButton>
					</div>
				</div>
			</Dialog>

			<LayoutBuilder
				isSublayout={false}
				opened={mediasOpened}
				forClose={() => setMediaOpened(false)}
				dataLayout={mediaPickerConfig(onSelect, false, false, false, false, VIDEOS.concat(IMAGES))}
				isBuilder={true}
			/>
		</div>
	);
}
