import React from 'react';
import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';
import UploadFile from '../components/UploadFile';
import Mapping from '../components/Mapping';
//import {withTranslation} from 'react-i18next'


const ImportCategories =  {
    titleForm: 'products.categories.importation.importcategory',
    subTitleForm: 'products.categories.importation.description',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    formConfig: {
        type: STEPPER,
        finalStep: {
            picto: CatalogFinal,
            title: 'Bientôt prêt !',
            subtitle: 'Votre fichier est prêt à être importé :)',
            textButton: 'Importer le fichier'
        },
        children: [
            {
                labelName: 'products.categories.importation.filechoice',
                isOptionnal: false,
                component: (props) => {
                    return <UploadFile category={true} {...props} />
                },
                optionsInputs: []
            },
            {
                labelName: 'Mapping',
                isOptionnal: false,
                component: (props) => <Mapping category={true} {...props} />,
                optionsInputs: []
            }
        ]
    }
};

export default ImportCategories;