import { gql } from 'apollo-boost';

export const GET_RESTAURANT = gql`
{
  restaurants{
    edges{
      node{
        id
        demands{
          edges{
            node{
              id
              code
              imageUrl
            }
          }
        }
        restaurantData{
          edges{
            node{
              id
              value
              media{
                id
                contentUrl
                description
                filePath
              }
              attribute{
                id
                identifier
              }
              locale{
                id
                code
                libelle
              }
              attributeOption{
                id
                identifier
              }
            }
          }
        }
        rooms{
          edges{
            node{
              id
              libelle
              numberLine
              numberColumn
              roomTables{
                edges{
                  node{
                    id
                    number
                    position
                    maxPeople
                    width
                    height
                    state
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

//----- Add a restaurant

export const ADD_RESTAURANT = gql`
mutation AddRestaurant($name: String!, $userPem: Boolean!){
  createRestaurant(input:{name:$name, userPem: $userPem}) {
      restaurant{
        id
        demands{
          edges{
            node{
              id
              code
              imageUrl
            }
          }
        }
        restaurantData{
          edges{
            node{
              id
              value
              media{
                id
                contentUrl
                description
              }
              attribute{
                id
                identifier
              }
              locale{
                id
                code
                libelle
              }
              attributeOption{
                id
                identifier
              }
            }
          }
        }
        rooms{
          edges{
            node{
              id
              libelle
              numberLine
              numberColumn
              roomTables{
                edges{
                  node{
                    id
                    number
                    position
                    maxPeople
                    width
                    height
                    state
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
}
`;


//----- Delete a restaurant
/*
 Exemple de variables
  {
    "id" : "/api/restaurants/1"
  }
 */

export const DELETE_RESTAURANT = gql`
mutation deleteRestaurant($id: ID!){
    deleteRestaurant(input: {id:$id}) {
      restaurant{
        id
      }
    }
}
`;

/*
{
  "id" : "/api/restaurants/1"
}
 */
export const GETBY_ID_RESTAURANT = gql`
query GetByIdRestaurant($id: ID!){
  restaurant(id: $id){
    id
    demands{
      edges{
        node{
          id
          code
          imageUrl
        }
      }
    }
    restaurantData{
      edges{
        node{
          id
          value
          media{
            id
            contentUrl
            description
            filePath
          }
          attribute{
            id
            identifier
            attributeType{
              id
              input
            }
          }
          locale{
            id
            code
            libelle
          }
          attributeOption{
            id
            identifier
          }
        }
      }
    }
    rooms{
      edges{
        node{
          id
          libelle
          numberLine
          numberColumn
          roomTables{
            edges{
              node{
                id
                number
                position
                maxPeople
                width
                height
                state
                code
              }
            }
          }
        }
      }
    }
    userRestaurants{
      edges{
        node{
          id
          email
          username
          name
          firstname
          roles
        }
      }
    }
  }
}
`;