import React, { useEffect, useState } from "react";

const CustomComponent = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        data = null,
        spread = false,
        displayStyle = false
    } = props;
    
    const [sources, setSources] = useState(JSON.stringify(data?.inputs?.sources?.value));

    useEffect(() => {
        setSources(JSON.stringify(data?.inputs?.sources?.value));
    }, [data]);

    return (
        <div 
            data-config={sources} 
            style={{ position: 'relative', minHeight: spread ? 100 : 'auto'}} 
            className="custom-component" 
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { hover && !preview && props.children }

            <div dangerouslySetInnerHTML={{ __html: data?.inputs?.sources?.value?.html }} />

            { 
                spread || displayStyle ? (
                    <style>
                        { data?.inputs?.sources?.value?.css }
                    </style>
                ) : null
            }
        </div>
    );
};

export default CustomComponent;