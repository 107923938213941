import React from "react";
import { useDrop } from 'react-dnd';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Videocam } from "@material-ui/icons";
import colors from "../../../colors";

export default ({ data, preview, component, block, inputCallback, update, alt, hover = false }) => {
    console.log('data', data)
    let video = data?.blocks[block].inputs.value.value;

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            onChange(item.media.node);
        }
    });

    const onChange = (value) => {
        if (data) {
            data.blocks[block].inputs.value.value = value;
            update();
        }
    };

    const background = {
        backgroundColor: preview ? colors.grey.lighter.hue800 : '#eee', 
        width: "100%", 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: 150,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        boxSizing: 'border-box'
    };

    return (
        !video ?
            <div 
                ref={drop}
                style={{ 
                    ...background, 
                }}
            >
                <div className="image-picker">
                    <Videocam style={{ fontSize: 80, color: 'rgb(83, 131, 157)' }}  />
                </div>
            </div>
        : 
            <div>
                <video id="video" controls width="100%">
                    <source src={`${process.env.REACT_APP_MEDIAS}/${video?.filePath}`} alt="first" type={video.type}/>
                    Désoler, votre navigateur ne supporte pas les vidéos intégrées.
                </video>
            </div>
    );
};