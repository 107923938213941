import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ItemTypes } from '../../../shareable';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    }
});

export default ({ config, params, preview, droppable, mapper }) => {
    const classes = useStyles();
    if (!config) return null;

    const Droppable = droppable;

    return (
        <div className={classes.root}>
            { droppable && <Droppable type={ItemTypes.ALL} id={'page-start'} index={-1} /> }
            
            { config.elements.map(mapper) }
        </div>
    );
};