import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../../js/constants/action-types';
import { Grid } from '@material-ui/core';
import FormBuilder from '../../../../../components/ui/form/FormBuilder';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import colors from '../../../../../config/theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';


const Label = styled.p`
  max-width:100%;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
  margin:0;
`


const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: colors.black.regular,
    },
    tooltip: {
        backgroundColor: colors.black.regular,
        fontSize: 14,
        padding: 10,
        textAlign: 'left',
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

class Mapping extends React.Component {  
    state = {
        loading: false,
        headers: [],
        inputs: [],
        options: [],
        import: {},
        toolTipListener: true,
        tooltipSelected: null,
    };
    

    getHeaders = (options) => {
        return new Promise(async (resolve, reject) => {
            this.setState({ 
                options,
                headers: (this.props.category ? this.props.allState['headers'] || [] : this.props.allState.upload_CSV?.headers || []).map(e => ({
                    used: false,
                    label: e,
                    id: e
                })),
                inputs: [
                    {
                        type: 'select',
                        label: 'Langue',
                        translated: false,
                        helper: {
                            text: `Langue de l'import`,
                            link: false,
                        },
                        required: true,
                        stateName: 'importLang',
                        value: this.props.locales.map(locale => {
                            return ({
                                value: locale.node.id,
                                label: locale.node.libelle
                            });
                        })
                    }
                ]
            });
            resolve();
        });
    }

    async componentDidMount () {
        let options = this.props.category
            ? [
                {
                    id: 'libelle',
                    label: 'Identifiant',
                    identifier: 'libelle',
                    required: true,
                }, {
                    id: 'parent',
                    label: `Parent`,
                    identifier: 'parent',
                    required: true,
                }
            ]: [
                {
                    id: 'sku',
                    label: 'Code article',
                    identifier: 'sku',
                    required: true,
                }, {
                    id: 'category',
                    label: 'Catégorie',
                    identifier: 'category',
                    required: true,
                }, {
                    id: 'attributeGroup',
                    label: `Groupe d'attributs`,
                    identifier: 'attributeGroup',
                    required: true,
                },
                {
                    id: 'status',
                    label: 'Statut',
                    identifier: 'status',
                    required: true,
                }
            ];
        
        this.props.stateCallback(
            'requiredValueMapper', 
            this.props.category ? 
                [ 
                    {
                        id: 'libelle',
                        label: 'Identifiant',
                        required: true,
                    }, {
                        id: 'parent',
                        label: `Parent`,
                        required: true,
                    }
                ] : [
                    {
                        id: 'sku',
                        label: 'Code article',
                        required: true,
                    }
                ], 
            null, null, null
        );

        let currentLang = this.props.locales[0].node.code;

        let attributes = this.props.category 
            ? this.props.attributes.category.attributes.edges
            : this.props.attributes.product.attributes.edges;

        for (let attribute of attributes) {
            const defaultLang = attribute.node.translation.translationDatas.edges[0];

            const langSelected = attribute.node.translation.translationDatas.edges.find(
                lang => lang.node.locale.code === currentLang
            );
            
            if(attribute.node.status){
                options.push({
                    id: attribute.node.id,
                    identifier: attribute.node.identifier,
                    label: langSelected ? langSelected?.node?.value : defaultLang ? defaultLang.node?.value : attribute.node.identifier
                });
            }
        }

        await this.getHeaders(options);

        let values = this.props.allState.importValues;
        let hasSku = false

        if (this.state.headers.find(e=>e.label.toLowerCase() === 'sku'||e.id.toLowerCase() === 'sku')) {
            hasSku =true
        }
        
        for(let header of this.state.headers){
            for (let option of this.state.options){   
                if(option.label.toLowerCase() === header.label.toLowerCase() || option.identifier?.toLowerCase() === header.label.toLowerCase()){
                    values[header.id] = [option.id];
                    this.props.stateCallback('importValues', values, null, null);
                    this.construct();
                }
                if (!hasSku && option.identifier === "product_ean" && (option.label === header.label ||option.label === header.id)) {
                    if (values[header.id]) {
                        values[header.id].push('sku');
                    }
                }
            }
        }
    }

    construct = () => {
        const { allState, stateCallback } = this.props;
        const { headers } = this.state;

        let mapper = [];

        for (let header of headers) {
            let value = allState.importValues[header.id];
            mapper.push(!value || value === '' || value === '-1' || value.length === 0 ? null : value);
        }
        stateCallback('mapper', mapper);
    };

    render() {
        const { inputs, options, headers } = this.state;
        const { allState, stateCallback } = this.props;
        return (
            <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>
                { inputs ? <FormBuilder 
                    optionsInputs={inputs} 
                    stateCallback={stateCallback} 
                    allState={allState}
                /> : null }

                {
                    headers.map((header, i) => {
                        let value = allState.importValues[header.id];
                        return (
                            <div key={`attribute_${i}`} style={{
                                padding: 10,
                                backgroundColor: (!value || value === '' || value === '-1' || value.length === 0) ? '#fff' : 'rgb(224, 241, 251)',
                                width: '90%',
                                margin: '0 auto',
                                marginBottom: 5
                            }}>
                                <Grid container style={{position: "relative"}}>
                                    <Grid item xs={4} style={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        paddingRight: 20,
                                        borderRightWidth: 1,
                                        borderRightStyle: 'solid',
                                        fontWeight: (!value || value === '' || value === '-1' || value.length === 0) ? 'normal' : 'bold'
                                    }}>
                                        <Label>{ header.label }</Label>
                                    </Grid>
                                    <Grid item xs={8} style={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        paddingLeft: 20
                                    }}>
                                        <FormControl style={{maxWidth: "calc(100% - 30px)", width:"100%"}}>
                                            <BootstrapTooltip title={allState.importValues?.[header.id]?.length > 0 ? allState.importValues[header.id].map((data, index) =>{
                                                let getLabel = options.find(e => e.id  === data)
                                                return(
                                                    <span style={{display: 'block'}}>{getLabel.label}</span>
                                                )
                                                }): null} 
                                                disableHoverListener={allState.importValues?.[header.id]?.length > 0 ? true : false} 
                                                disableFocusListener={true} 
                                                disableTouchListener={true}
                                                disableTriggerFocus={true} 
                                                open={this.state.toolTipListener && this.state.tooltipSelected === header.id && allState.importValues?.[header.id]?.length > 0 ? true : false}
                                                // enterTouchDelay={1200} 
                                                arrow
                                                aria-label="add-attributes"
                                            >
                                                <Select
                                                    /* defaultValue={'-1'} */
                                                    disabled={this.props.stepsInfo.disabled}
                                                    value={allState.importValues[header.id] || []}
                                                    multiple
                                                    onChange={(evt) => {
                                                        let values = allState.importValues;
                                                        values[header.id] = evt.target.value;

                                                        stateCallback('importValues', values, null, null);
                                                        this.construct();
                                                    }}
                                                    onMouseEnter={()=> {
                                                        this.setState({
                                                            toolTipListener: true,
                                                            tooltipSelected: header.id,
                                                        })
                                                    }}
                                                    onMouseLeave={()=> {
                                                        this.setState({
                                                            toolTipListener: false,
                                                        })
                                                    }}
                                                    onOpen={() => {
                                                        this.setState({
                                                            toolTipListener: false,
                                                        })
                                                    }}
                                                    onClose={() => {
                                                        this.setState({
                                                            toolTipListener: true,
                                                            tooltipSelected: null,
                                                        })
                                                    }}
                                                >
                                                    { options.map((e, i) => { return(<MenuItem key={`header-${i}`} value={e.id}>{ e.label }</MenuItem>)}) }
                                                </Select>
                                            </BootstrapTooltip>
                                        </FormControl>
                                    </Grid>
                                    {
                                        allState.importValues[header.id] ?
                                            <HighlightOff 
                                                style={{width: 25, cursor: "pointer", position:"absolute",top: "50%", right: 0, transform: 'translateY(-50%)',color:this.props.stepsInfo.disabled?colors.grey.lighter.hue700:"inherit"}} 
                                                onClick={this.props.stepsInfo.disabled?null:() => {
                                                    let values = allState.importValues;
                                                    values[header.id] = null;

                                                    stateCallback('importValues', values, null, null, () => {
                                                        this.construct();
                                                    });
                                                }}
                                            />
                                        : null
                                    }
                                </Grid>
                            </div>
                        );
                    })
                }


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);