import CardContact from '../../../../layouts/Card/cardContent/CardContact';

export const listSettings = {
    grid : {
        components : CardContact,
        columns : {
            xs : 1,
            sm : 2,
            laptop : 3,
        }
    },
    table : {
        style : {
            type : 'striped',
        },
        columns : [
            {
                label : 'Image',
                field : 'image',
                cellType : 'profil_picture',
            },
            {
                label : 'Nom',
                field : 'name',
                cellType : 'text',
                filter : 'name',
                cellProps : {
                    fontWeight : 'bold',
                    width : '20%'
                }

            },
            {
                label : 'Email',
                field : 'email',
                cellType : 'text',
            },
            {
                label : 'Telephone',
                field : 'telephone',
                cellType : 'text',
            },
            {
                label : 'Sociétés',
                field : 'companies',
                cellType : 'avatars',
                cellProps : {
                    fontWeight : 'bold',
                    width : '20%'
                }

            },
            {
                label : '',
                field : 'link',
                cellType : 'link',
            },
            
        ]   
    }
}

export const listMappers = [
    {
        view : 'card',
        mapper : null
    },
    {
        view : 'table',
        mapper : 'customersListMapper'
    }
]

export const perPageOptions = {
    'card' : ['6'],
    'table' : ['12','24','48']
}

export const viewsOptions = {
    current : 'card',
    settings : ['card','table']
}
