import React, { createRef, useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H3 from '../shared/h3';
import P from '../shared/p';
import Img from '../shared/img';
import { PictureTag } from "../../../../../utils/image";
import styled from "styled-components";


const DivCutsom = styled(Div)`
    width: ${props=>`calc(100% / ${props.grid <3? 3: props.grid <=4?props.grid:4} - 16px)`}!important;
    height:${props=>props.height? `${props.height}px`: "auto"};
    @media screen and (max-width: 900px){
        width: ${props=>`calc(100% / 2 - 16px)`} !important;
        flex-direction:row !important; margin:8px !important;
    }
    @media screen and (max-width: 500px){
        width: ${props=>`calc(100% / 1 - 16px)`} !important;
    }
`

const CategoriesHome = (props) => {
    const [hover, setHover] = useState(false);
    const [cardHeight, setCardHeiht] = useState(null);
    const ref = createRef(null);

    let {
        preview = false,
        data = null,
        spread = false
    } = props;
    
    useEffect(() => {
      setCardHeiht(ref?.current.clientHeight)
    }, [ref])
    
    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className={`${!data.inputs?.disablePadding?.value ? 'bloc-list-without-padd': "bloc-list"}`}>
                <H2 className="w100 uppercase">{data.inputs.value.value}</H2>
                <Div className="list-categories">
                    {
                        data.blocks.categories.config.map((categorie, index) => {
                            let image = categorie.blocks.image.inputs.value.value;
                           
                            return (
                                <DivCutsom height={data.inputs?.cardHeight?.value??cardHeight} grid={data.blocks.categories.config.length} className="relative" key={index}>
                                    <PictureTag
                                        media={image}
                                        alt={categorie.blocks.image.inputs.alt?.value ?? "Illustration"}
                                        spread={spread}
                                        classes="w100"
                                        lazy={true}
                                        isOptimised={data.inputs.optimisedImage?.value}
                                    />
                                    <Div style={{height:"100%"}} className="absolute gradient-overlay" />
                                    <a href={categorie.blocks.link.inputs.value.value}>
                                        <Div className="absolute" ref={ref}>
                                            <H3 style={{
                                                height:data.inputs.showTitleOnHover.value?0:"auto",
                                                overflow:data.inputs.showTitleOnHover.value?"hidden":"auto",
                                                transition:data.inputs.showTitleOnHover.value?"all ease 500ms":null,
                                                fontSize:categorie.blocks?.title?.inputs.fontSize?.value,
                                                color:categorie.blocks?.title?.inputs.color?.value,
                                                textTransform:categorie.blocks?.title?.inputs.textTransform?.value,
                                                textAlign:categorie.blocks?.title?.inputs.textAlign?.value,
                                            }} className={`white uppercase ${data.inputs.showTitleOnHover.value?"hovertitle":""}`}>{categorie.blocks.title.inputs.value?.value}</H3>
                                            {
                                                categorie.blocks?.subtitle?.inputs.value?.value?
                                                    <P style={{
                                                        fontSize:categorie.blocks?.subtitle?.inputs.fontSize.value,
                                                        color:categorie.blocks?.subtitle?.inputs.color.value,
                                                        textTransform:categorie.blocks?.subtitle?.inputs.textTransform?.value,
                                                    }} className="subtitle">{categorie.blocks?.subtitle?.inputs.value?.value}</P>
                                                :null
                                            }
                                            <P className="center white">{data.inputs.linkText?.value}</P>
                                        </Div>
                                    </a>
                                </DivCutsom>
                            )
                        })
                    }
                </Div>
            </Div>
        </Div>
    );
};

export default CategoriesHome;