import {TABS} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

export default function formAttributEdit(allGroups, attributeType, currentLang, allInputType, allLang, selectedGroups, isSystem = false, allDisabled = false, noGroup) {
    let currentTypeInput = allInputType.find(
        type => type.node.id === attributeType
    );
    let optionsInputs = [];

    if (!noGroup){
        optionsInputs.push(
            {
                type: 'selectTree',
                label: 'Groupes d\'attributs',
                translated: false,
                helper: {
                    text: 'Indiquez les groupes de cet attribut',
                    link: false,
                },
                required: false,
                stateName: 'selectedGroups',
                multiselect: true,
                data: allGroups.map(group => {
                    group.label     = group.locales.find(e => e.code === currentLang)?.value ?? group.locales[0].value;
                    group.checked   = selectedGroups.find(e => e.node.id === group.node.id) ? true : false;
                    group.expanded  = true;
                    group.children  = [];
                    
                    return group;
                })
            }
        )
    }
    
    optionsInputs.push(
        {
            type: 'text',
            label: 'Libellé',
            translated: true,
            helper: {
                text: "Libellé de l'attribut",
                link: false,
            },
            required: false,
            stateName: 'attributeName',
        },
        {
            type: 'text',
            label: 'Identifiant',
            translated: false,
            helper: {
                text: "Identifiant de l'attribut",
                link: false,
            },
            disabled: true,
            required: true,
            stateName: 'attributeIdentifier',
        },
        {
            type: 'select',
            label: 'Type',
            translated: false,
            helper: {
                text: 'Indiquez le type de cet attribut',
                link: false,
            },
            required: true,
            stateName: 'attributeType',
            value: 
                allInputType.map(node => {
                    return(
                        {
                            value : node.node.id,
                            label : node.node.translation.translationDatas.edges[0]?.node.value ?? node.node.input,
                            disabled : node.node.id === attributeType,
                        }
                    )
                })
        }
    )

    let mainChildren = [
        {
            labelName: 'Propriétés',
            optionsInputs
        }
    ];

    if (currentTypeInput?.node.input === 'select'|| currentTypeInput?.node.input === 'select_multiple'){
        mainChildren.push(
            {
                labelName: 'Valeurs',
                isOptionnal: false,
                optionsInputs: [
                    {
                        type: 'rows',
                        translated: false,
                        required: true,
                        disabled: allDisabled,
                        stateName: 'values',
                        currentLang
                    }
                ]
            },
            {
                labelName: 'Dictionnaire',
                isOptionnal: false,
                optionsInputs: [
                    {
                        type: 'rows',
                        translated: false,
                        required: false,
                        stateName: 'dictionnaryValues',
                        disabled: allDisabled,
                        labelsNames:{
                            first: 'Attributs fichier',
                            second: 'Attributs Sinfin DXP',
                        },
                        dictionary: true,
                        dictionarySelect: currentTypeInput?.node.input === 'select' ? true : false,
                    }
                ]
            }
        )
    }

    // let mainChildren = [
    //     {
    //         labelName: 'Propriétés',
    //         optionsInputs: [
    //             {
    //                 type: 'selectTree',
    //                 label: 'Groupes d\'attributs',
    //                 translated: false,
    //                 disabled: allDisabled ? allDisabled : isSystem,
    //                 helper: {
    //                     text: 'Indiquez les groupes de cet attribut',
    //                     link: false,
    //                 },
    //                 required: false,
    //                 stateName: 'selectedGroups',
    //                 multiselect: true,
    //                 data: allGroups.map(group => {
    //                     group.label     = group.locales.find(e => e.code === currentLang)?.value ?? group.locales[0].value;
    //                     group.checked   = selectedGroups.find(e => e.node.id === group.node.id) ? true : false;
    //                     group.expanded  = true;
    //                     group.children  = [];
                        
    //                     return group;
    //                 })
    //             },
    //             {
    //                 type: 'text',
    //                 label: 'Libellé',
    //                 translated: true,
    //                 helper: {
    //                     text: "Libellé de l'attribut",
    //                     link: false,
    //                 },
    //                 disabled: allDisabled,
    //                 required: false,
    //                 stateName: 'attributeName',
    //             },
    //             {
    //                 type: 'text',
    //                 label: 'Identifiant',
    //                 translated: false,
    //                 helper: {
    //                     text: "Identifiant de l'attribut",
    //                     link: false,
    //                 },
    //                 required: true,
    //                 stateName: 'attributeIdentifier',
    //                 disabled: true
    //             },
    //             {
    //                 type: 'select',
    //                 label: 'Type',
    //                 translated: false,
    //                 helper: {
    //                     text: 'Indiquez le type de cet attribut',
    //                     link: false,
    //                 },
    //                 required: true,
    //                 disabled: true,
    //                 stateName: 'attributeType',
    //                 value: 
    //                     allInputType.map(node => {
    //                         return(
    //                             {
    //                                 value : node.node.id,
    //                                 label : node.node.translation.translationDatas.edges[0]?.node.value ?? node.node.input,
    //                                 disabled : node.node.id === attributeType,
    //                             }
    //                         )
    //                     })
    //             },
    //         ]
    //     },
        
    // ];

    if (currentTypeInput?.node.input === 'decimal' || currentTypeInput?.node.input === 'number'){
        mainChildren[0].optionsInputs.push(
            {
                type: 'select',
                label: 'Unité',
                translated: false,
                helper: {
                    text: 'Indiquez le type de cet attribut',
                    link: false,
                },
                required: true,
                stateName: 'attributUnity',
                disabled: allDisabled,
                value: 
                    [
                        {
                            value : 'none',
                            label : 'Pas d\'unité',
                            // disabled : node.node.id === attributeType,
                        },
                        {
                            value : 'kg',
                            label : 'Kilogramme (Kg)',
                            // disabled : node.node.id === attributeType,
                        },
                        {
                            value : 'euro',
                            label : 'Euros (€)',
                            // disabled : node.node.id === attributeType,
                        }
                    ]
            }
        );
    }

    mainChildren[0].optionsInputs.push(
        {
            type: 'buttonGroup',
            label: 'Requis',
            helper: {
                label: 'État',
                text: 'Indiquez si cet attribut est requis',
                link: false,
            },
            required: true,
            disabled: allDisabled ? allDisabled : isSystem,
            stateName: 'isRequired',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
        {
            type: 'buttonGroup',
            label: 'Surveiller',
            helper: {
                label: 'État',
                text: 'Indiquez si cet attribut est surveillé',
                link: false,
            },
            required: true,
            stateName: 'isWatched',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
        {
            type: 'buttonGroup',
            label: 'Recherchable',
            helper: {
                label: 'État',
                text: 'Indiquez si cet attribut est recherchable',
                link: false,
            },
            required: true,
            disabled: allDisabled ? allDisabled : isSystem,
            stateName: 'isSearchable',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
        {
            type: 'buttonGroup',
            label: 'Utiliser dans les filtres Sinfin DXP',
            helper: {
                label: 'État',
                text: 'Indiquez si cet attribut peut être utiliser comme filtre Sinfin DXP',
                link: false,
            },
            required: true,
            stateName: 'internalFilter',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
        {
            type: 'buttonGroup',
            label: 'Utiliser sur les cartes',
            helper: {
                label: 'État',
                text: 'Indiquez si cet attribut peut être utiliser sur les cartes',
                link: false,
            },
            required: true,
            stateName: 'useInCard',
            value: [
                {
                    value: true,
                    label: 'Oui',
                },
                {
                    value: false,
                    label: 'Non',
                },
            ]
        },
    );

    var obj = {
        titleForm: 'Modifier un attribut',
        subTitleForm: 'Veuillez compléter les champs ci-dessous pour modifier votre attribut',
        langSelect: true,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        textButtonValidate: allDisabled ? `Réactiver l'attribut` : `Valider la modification`,
        formConfig: {
            type: TABS,
            children: mainChildren,
        }
    }
    return obj;
}