export const topMessagesMapper = (topMessages,lang) =>{
    return new Promise((resolve,reject)=>{
        let results = topMessages.map((topMessage,index)=>{
            return {
                id: topMessage.node.id,
                name: topMessage.node.name,
                startAt: topMessage.node.startAt || '-',
                endAt: topMessage.node.endAt || '-',
                message: topMessage.node.message,
                status: topMessage.node.statut,
            }
        })
        resolve(results);
    })
}