import { FormInputTypes } from '../../../shareable/types';
import { getDefaultLink, getImageDefault } from '../../helpers';


export default {
    name: 'Pre-header',
    inputs: {
        backgroundColor: {
            label: 'Couleur de fond',
            type: FormInputTypes.COLOR,
            value: '#DEEDF5'
        },
        backgroundImage: {
            label: 'Image de fond',
            type: FormInputTypes.IMAGE,
            value: null
        },
        padding: {
            label: 'Padding',
            type: FormInputTypes.TEXT,
            value: 0,
        },
        maxWidth: {
            type: FormInputTypes.SLIDER,
            value: 1920,
            label: "Taille maximale (px)",
            params: {max: 1920, min: 360, step: 10},
        },
    },
    blocks: {
        redirectionButtonText: getDefaultLink('Lien', "Voir plus", '', 20, "#53839D", '#F0F7FA'),
        image: getImageDefault(),
        logo: getImageDefault(),
    }
};