import React, {  useState, useEffect } from "react";
import styled from "styled-components";
import Div from '../shared/div';
import H2 from "../shared/h2";
import Button from "../shared/button";


const BlocTextLayers = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;


    const [opendisplayText, setOpenDisplayText] = useState(true)


    useEffect(()=> {
        if(document.getElementById('collapse-display-button') & document.getElementById('collapse-display-div-question')) {
            document.getElementById('collapse-display-button').addEventListener('click', handleClick);
        }
})
    const handleClick = () => {
        const collapseContent = document.getElementById('collapse-display-div-question');
        const collapseButton = document.getElementById('collapse-display-button');
       
        if(opendisplayText) {
            setOpenDisplayText(false)
            collapseContent.style.height = "100%"
            collapseButton.textContent = data.blocks.displayer.inputs?.lessText?.value
        } else{
        setOpenDisplayText(true)
        collapseButton.textContent = data.blocks.displayer.inputs?.moreText?.value
        collapseContent.style.height = `${data.blocks.displayer.inputs.heightDisplay.value}px`
        }
      };
    
const convertHTMLToString = (text) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;
    return tempElement.innerText;
  }
  

    return (
        <Div id={data.inputs.identifiant?.value} className={data.inputs.className?.value} style={{position: 'relative',padding:`40px ${data.inputs.noPaddingHorizontal?.value ? 0:"20px"}`}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div
            style={{
                background: data.inputs.backgroundColor.value,
                margin: data.inputs.noPaddingHorizontal.value? 0:"auto",
                width:data.inputs.noPaddingHorizontal.value?"100%":"90%",
                paddingBottom:`${data.inputs.paddingBottom.value}px`,
                paddingTop:`${data.inputs.paddingTop.value}px`,                
            }} 
            >

                {
                    data.blocks.title.inputs.value.value ?                
                    <H2 
                        color={data.blocks.title.inputs.color?.value}
                        fontSize={data.blocks.title.inputs.fontSize?.value}                        
                        style={{
                            textAlign: data.blocks.title.inputs.textAlign?.value,
                            marginLeft: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null,
                            marginRight: data.blocks.title.inputs.textAlign?.value === 'center' ? 'auto' : null,
                            paddingBottom:30
                        }}
                    >
                        {data.blocks.title.inputs.value.value}
                    </H2>
                    :null
                }

{convertHTMLToString(data.blocks.content.inputs.value.value).length != 0 ? 
                     
<Div style={{textAlign: data.blocks.content.inputs.textAlign.value, height:`${data.blocks.displayer.inputs.heightDisplay.value}px`, overflow:"hidden", transition:`${data.blocks.displayer.inputs.transitionDisplay.value}s`}}
 id={data.blocks.content.inputs.identifiant?.value} className={data.blocks.content.inputs.className?.value} dangerouslySetInnerHTML={{__html: data.blocks.content.inputs.value.value}} /> 
: null}

<Button 
                            
                            id={data.blocks.displayer.inputs.identifiant?.value}
                            className={data.blocks.displayer.inputs.className?.value}
                            backgroundColor={data.blocks.displayer.inputs?.backgroundColor?.value}
                            color={data.blocks.displayer.inputs?.color?.value}
                            borderWidth={data.blocks.displayer.inputs?.borderWidth?.value}
                            borderRadius={data.blocks.displayer.inputs?.borderRadius?.value}
                            borderColor={data.blocks.displayer.inputs?.borderColor?.value}
                            paddingVertical={data.blocks.displayer.inputs?.paddingVertical?.value}
                            paddingHorizontal={data.blocks.displayer.inputs?.paddingHorizontal?.value}

                        >
                            {data.blocks.displayer.inputs?.moreText?.value ? data.blocks.displayer.inputs?.moreText?.value : "Voir plus"}
                            </Button>

            </Div>
            
        </Div>
    );
};

export default BlocTextLayers;
