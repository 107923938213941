import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Typography, Grid, Container, Box } from '@material-ui/core';
import { START_LOADING, STOP_LOADING, SET_USER, SNACK } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';
import request from '../../../js/utils/fetch';
import ButtonStyled from '../../ui/button/Button';
import logo from '../../../assets/images/logo-sinfin-digital.svg';
import FormBuilder from '../../ui/form/FormBuilder';
import colors from '../../../config/theme/colors';

import './Login.scss';
import { ROUTE_LOGIN } from '../../../js/constants/route-names';
import { useTranslation } from 'react-i18next';

const ContainerCustom = styled(Container)`
  background: #fff;
  padding: 4rem !important;
  position: relative;
  left: auto;
  margin: auto;
  border: 0.5px solid ${colors.grey.lighter.hue700};
`

const ButtonCustom = styled(ButtonStyled)`
  margin:auto;
  width: 100%;
  padding: 14px 32px;
`
const GridCustom = styled(Grid)`
	padding: 0 32px;
	@media screen and (max-width: 1280px){
		padding: 0 24px;
	}
`


/**
 * ResetPassword component is also used for first login (so keep the "welcome" logic)
 */
const ResetPassword = ({ history, match, startLoading, stopLoading, snack }) => {
  const { t } = useTranslation()
  const queryParameters = new URLSearchParams(window.location.search)

  const inputsLogin = [
    {
      type: 'password',
      label: t('inputs.labels.password'),
      helper: {
        text: '',
        link: false,
      },
      helperLink: true,
      required: true,
      stateName: 'password',
      disabled: false,
    },
    {
      type: 'password',
      label: t('inputs.labels.confirm-password'),
      helper: {
        text: '',
        link: false,
      },
      helperLink: true,
      required: true,
      stateName: 'repeatPassword',
      disabled: false,
    },
  ];

  const isNewAccount = queryParameters.has('welcome')

  const [state, setState] = useState({
    password: '',
    repeatPassword: '',
    token: match.params.token,
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  document.addEventListener('keydown', handleKeyPress, false);

  const goTo = (route) => {
    history.push(route);
  };

  const handleInputChange = (stateName, evt) => {
    const value = evt.target.value;

    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  const onSubmit = () => {
    const { password, repeatPassword, token } = state;

    if (!password || !repeatPassword) {
      return snack(ALERT_ERROR, t('inputs.toast.errors.fill'));
    }

    if (password !== repeatPassword) {
      return snack(ALERT_ERROR, t('inputs.toast.errors.same-password'));
    }

    startLoading();

    request(`${process.env.REACT_APP_API}/public/forgot-password/reset`, 'post', {
      token,
      password,
    })
      .then(() => {
        snack(ALERT_SUCCESS, t('login.password-changed'));
        goTo(ROUTE_LOGIN);
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <Grid container justifyContent="center" className="login">
      <Grid container justifyContent="center" style={{ marginBottom: 8 }}>
        <img src={logo} alt="logo-dxp" style={{ maxWidth: '250px' }} />
      </Grid>
      <Container item xs={8}>
        <ContainerCustom maxWidth="sm">
          <Grid style={{ marginTop: '2rem' }} container justifyContent="center">
            <Box textAlign="center" style={{ maxWidth: '100%' }}>
              <Typography variant="h3">{isNewAccount ? t('login.welcome') : t('login.reset')}</Typography>
              <Typography variant="body1" style={{ marginTop: '0.5rem' }}>
                {t('login.reset-tagline')}
              </Typography>
            </Box>
            <FormBuilder
              isLogin
              optionsInputs={inputsLogin}
              stateCallback={handleInputChange}
              allState={state}
              backgroundColor="white"
              padding="32px 32px 8px"
            />
            <GridCustom container>
              <ButtonCustom text={t('inputs.labels.submit')} className="button" onClick={onSubmit} />
            </GridCustom>
          </Grid>
        </ContainerCustom>
      </Container>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    setUser: (user) => dispatch({ type: SET_USER, payload: { user } }),
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
