import React from 'react';
import Button from '@material-ui/core/Button';
import colors from '../../../config/theme/colors';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import styled from 'styled-components';
import IcomoonReact from "icomoon-react";
import iconSet from '../../../assets/selection.json'
import { Link } from 'react-router-dom';
const defaultBg = colors.blue.darker.hue300;
const defaultBgHover = colors.blue.darker.hue300;
const defaultColor = '#fff';
const defaultFontSize = '14px';


const LinkCustom = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: initial;
    text-transform: ${props => props.texttransform ? props.texttransform + '!important' : 'none'};
    font-weight: ${props => props.fontweight ?? 'bold'};
    color: ${props => props.colortext ? props.colortext : defaultColor};
    background-color: ${props => props.bgcolor ? props.bgcolor : defaultBg};
    box-shadow:  none;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    top: 0;
    border: ${props => props.border ? props.border : ''};
    border-radius: 0;
    &:hover{
        background-color: ${props => props.disabled ? '#e0e0e0' : props.bgcolorhover ? props.bgcolorhover : defaultBgHover};
        background-color: ${props => (props.shadowcolor && props.bgcolor) ? props.bgcolor : (props.shadowcolor) ? defaultBg : ''};
        background-color: ${props => props.border && !props.bgcolorhover ? props.colortext : ''};
        box-shadow:  none;
        color: ${props => props.colorhover ? props.colorhover : ''};
        // border-color: ${props => props.border ? props.bgcolor : ''};
        span{
            opacity: ${props => props.bgcolorhover ? 1 : 0.5};
        }
    }
    span{
        font-size: ${props => props.fontSize ? `${props.fontSize}px` : defaultFontSize};
        line-height: 1;
        @media screen and (max-width: 1280px){
            font-size: 13px;
        }
        @media screen and (max-width: 1000px){
            font-size: 12px;
        }
    }
    .MuiButton-label{
        ${props => props.contentstyle ? props.contentstyle : ''};
    }
    @media screen and (max-width: 1000px){
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &.Mui-disabled{
        color:rgba(0, 0, 0, 0.26)!important;
        background : ${props => props.bgcolor != 'transparent' ? 'rgba(0, 0, 0, 0.12)' : props.bgcolor};
        // box-shadow: ${props => props.bgcolor != 'transparent' ? '0px 3px 0px 0px #00000045' : `0px 3px 0px 0px ${props.bgcolor}`};
    }
`

const OurLink = (props) => {
    const { bgcolor, fontweight, bgcolorhover, color, colorhover, text, disabled, disableElevation, disableRipple, border, shadowcolor, fontsize, style, textTransform, contentStyle, className, stopPropagation, target = "_self"} = props;
    return (
        <Link to={props.link} target={target}
            style={{
                textDecoration: 'none'
            }}>
            <LinkCustom
                variant="contained"
                disabled={disabled}
                disableElevation={true}
                disableRipple={disableRipple}
                bgcolor={bgcolor}
                bgcolorhover={bgcolorhover}
                shadowcolor={shadowcolor}
                colortext={color}
                colorhover={colorhover}
                border={border}
                fontSize={fontsize}
                style={style}
                texttransform={textTransform}
                contentstyle={contentStyle}
                className={className}
                fontWeight={fontweight}
            >
                {props.deleteIcon ? (<DeleteSharpIcon />) : null}
                {props.arrow === 'left' ? (<ArrowBackIosRoundedIcon style={{ paddingRight: 5, width: '0.8em', height: '0.8em' }} />) : ''}
                {props.icon === "add" ? <AddIcon style={{ marginLeft: 5 }} /> : props.icon !== null ? props.icon : null}
                {text ? text : ''}
                {props.children ? props.children : ''}
                {props.arrow === 'right' ? (<ArrowForwardIosRoundedIcon style={{ paddingLeft: 5, width: '0.8em', height: '0.8em' }} />) : ''}
                {props.icomoon ? <IcomoonReact iconSet={iconSet} color={color} size={props.fontsize ? props.fontsize + 5 : 19} icon={props.icomoon} style={{ marginLeft: 5 }} /> : null}
            </LinkCustom>
        </Link>
    );
};

export default OurLink