import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import colors from '../../../config/theme/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';


const CustomButton=styled(Button)`
    width:${props => props.width ? props.width :`100%`};
    border-radius:5px;
    box-shadow:none;
    background-color:${props => props.bgcolor ? props.bgcolor :colors.white};
    color:${props => props.colortext};
    justify-content:space-between;
    font-size:18px;
    padding : 20px;
    ${props=> props.dotted ? `
    border : 1px dashed ${props.colortext}
    `:null};
    text-transform : none;
`
const ButtonAdd = (props) => {
    const dotted = props.dotted ? props.dotted : false;
    const{bgcolor,color,width,text}=props;

    return(
    <CustomButton bgcolor={bgcolor} colortext={color ? props.color : colors.blue.darker.hue300} width={width} dotted={dotted ? 'dotted' :null} onClick={props.onClick}>
        {text} <AddCircleIcon/>
    </CustomButton>        
    )
}

export default ButtonAdd;