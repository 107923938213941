import React, { useEffect } from 'react';
import { Grid, MenuItem, Select, Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, TableContainer, Paper } from '@material-ui/core';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import colors from '../../../../config/theme/colors';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import '../../../navigation/DrawerLeft.scss';
import { withTranslation } from 'react-i18next'
import styled from 'styled-components';
import { eventService } from '../../../../js/services/event.service';
import AccordionCustom from '../../../layouts/Accordion/AccordionCustom';
import RemoveIcon from '@material-ui/icons/Remove';
import CallMadeIcon from '@material-ui/icons/CallMade';
import moment from 'moment';
import { get_productSourcePriceWatchers_by_brand } from '../../../../queries/price_watcher';
import { ROUTE_PRODUCTS_LIST_DETAIL, ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL } from '../../../../js/constants/route-names';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import { CursorPagination } from '../../../../js/utils/pagination';
import SearchBar from '../../../ui/search/SearchBar';
import Button from '../../../ui/button/Button';
import request from '../../../../js/utils/fetch';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { GetApp } from '@material-ui/icons';
import OurTypography from '../../../ui/typography/Typography';
import { saveAs } from 'file-saver';
import axios from '../../../../js/utils/axios';

const SelectCustom = styled(Select)`
    border-radius: 0; 
    // width: 100%; 
    max-width: 170px; 
    margin-right: 16;
    fieldset{
        border: none;
    }
    :before, &:after{
        display: none !important;
    }
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 16px;
        padding-left: 8px;
        margin-right: 16px;
        color: ${colors.blue.darker.hue300};
        &:focus{
            background: none;
        }
        @media screen and (max-width: 1450px){
            font-size: 14px;
            line-height: 18px;
        }
        @media screen and (max-width: 1280px){
            font-size: 13px;
            line-height: 17px;
        }
        @media screen and (max-width: 960px){
            font-size: 12px;
            line-height: 16px;
        }
    }
    svg{
        fill: ${colors.blue.darker.hue300};
    }
    & > p{
        margin: 0;
    }
`;
const NameProduct = styled(Typography)`
    line-height: 1.3; 
    max-width: 218px; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
`
const WrapperAlert = styled(Grid)`
    svg{
        font-size: 16px;
        margin-right: 4px;
        fill: ${props => props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
    }
    color: ${props => props.isactive ? colors.green.regular : colors.grey.lighter.hue600};
`

const TableRowDataCustom = styled(TableRow)`
    display: inline-block;
    cursor: pointer;
    &:hover{
        background-color: ${colors.grey.lighter.hue980};
    }
    td{
        border-top: 1px solid ${colors.grey.lighter.hue700};
        border-color: ${colors.grey.lighter.hue700};
    }
    td:first-child{
        border-left: 1px solid ${colors.grey.lighter.hue700};
    }
    td:last-child{
        border-right: 1px solid ${colors.grey.lighter.hue700};
    }
`;

const TableRowCustom = styled(TableRow)`
    td,th{
        border-color: #EDEEEE;
        white-space: nowrap;
        line-height: 1rem;
        padding: 8px;
    }
    th{
        color: ${colors.grey.border};
        font-weight: bold;
        background: ${colors.white};
        border: 0;
    }
`
const TableBodyCustom = styled(TableBody)`
    .table-row{
        cursor: pointer;
        color: inherit;
        display: table-row;
        outline: 0;
        vertical-align: middle;
        text-decoration: none;
        td,th{
            padding: 8px;
        }
    }
`

const TableCellCustom = styled(TableCell)`
    font-size: 20px;
    font-weight: bold;
`

const ValueItem = styled.p`
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:14px;
    line-height: 17px;
    font-weight: normal;
    margin: 4px 0;
    text-align: center;
    strong{
        display:flex;
        align-items: center;
    }
    svg{
        font-size:14px;
        margin-left: 4px;
    }
`

function ListProductsDelta(props) {
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [displaySeasons, setDisplaySeasons] =  React.useState('none');
    const [loadingList, setLoadingList] = React.useState(true);
    const [prices, setPrices] = React.useState([]);
    const [pagination, setPagination] = React.useState({
        perPage: 10,
        page: 0,
        startCursor: null,
        endCursor: null,
        count: 0
    });
    const [searchSku, setSearchSku] =  React.useState('');
    const [searchSkuLocal, setSearchSkuLocal] =  React.useState('');
    const [firstRender, setFirstRender] =  React.useState(true);

    //--- call 1 fois dans le cycle de vie du composant
    useEffect(() => {
        let oneBrandVariables = {
            date: moment().format("YYYY-MM-DD"),
            nbperpage: pagination?.perPage,
            //--- permets de chercher uniquement les produits ayant des écarts : 
            productSourcePriceWatchers_hasDiff: true
        };
        getPricesOneBrand(get_productSourcePriceWatchers_by_brand, oneBrandVariables, false);
        setFirstRender(false)
    }, [])

    const handleLang = (event) => {
        setCurrentLang(event.target.value);
        eventService.fire();
    };

    const resetPagination = () => {
        return {
            perPage: 10,
            page: 0,
            startCursor: null,
            endCursor: null,
            count: 0
        };
    }

    const getPricesOneBrand = (query = get_productSourcePriceWatchers_by_brand, variables = { date: moment().format("YYYY-MM-DD") }, pageInfo) => {
        setLoadingList(true);
        props.client.query({
            query,
            fetchPolicy: "no-cache",
            variables
        }).then(result => {

            let getPrices = result.data.products.edges;

            let page = pageInfo || pagination;

            let newPagination = {
                ...page,
                ...result.data.products.pageInfo,
                count: result.data.products.totalCount
            };

            if (!pageInfo)
                pageInfo = resetPagination();

            setPagination(newPagination);
            setPrices(getPrices);
            setLoadingList(false);
        });
    }

    const searchPrices = (pageInfo) => {
        let page = pageInfo;

        if (!page)
            page = resetPagination();

        let variables = {
            productSourcePriceWatchers_hasDiff: true,
            date: moment().format("YYYY-MM-DD"),
            nbperpage: page?.perPage,
            cursor: page?.startCursor,
            cursorLast: page?.endCursor,
            sku: searchSku !== '' ? searchSku : null
        };
        getPricesOneBrand(get_productSourcePriceWatchers_by_brand, variables, page);

    }

    const changePage = newPage => {
        let newPagination = {
            ...pagination,
            page: newPage,
            startCursor: newPage > pagination.page ? pagination.endCursor : null,
            endCursor: newPage > pagination.page ? null : pagination.startCursor
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    const changePerPage = perPage => {
        let newPagination = {
            ...resetPagination(),
            perPage
        };

        setPagination(newPagination);
        searchPrices(newPagination);
    };

    const getListingValues = (array, type) => {
        return (
            array.map((value2, index2) => {
                switch (type) {
                    case 'date':
                        return (
                            <ValueItem><strong>{moment(value2.node.created_at).format('DD/MM/YYYY')}</strong></ValueItem>
                        );
                    case 'retailer':
                        return (
                            <Grid container justifyContent='space-between' alignItems='center' style={{maxWidth: 250}}>
                                <ValueItem>{value2.node.source.libelle}</ValueItem>
                                <ValueItem>
                                    <strong>
                                        {value2.node.product_price.toFixed(2)}EUR</strong>
                                </ValueItem>
                            </Grid>
                        )
                    case 'diff':
                        return (
                            <ValueItem style={{justifyContent:"flex-end"}}>
                                <strong>{value2.node.source_price.toFixed(2)}EUR &nbsp;</strong>
                                ({value2.node.diffPercent <= 0 ? '' : '+'}{value2.node.diffPercent.toFixed(2)}%) 
                                {value2.node.diff_flat == 0 ? <RemoveIcon style={{ fill: "#C9C9C9" }} /> : value2.node.diff_flat < 0 ? <CallMadeIcon style={{ fill: colors.green.regular, transform: 'rotate(-45deg)' }} /> : <CallMadeIcon style={{ fill: colors.red.regular, transform: 'rotate(135deg)' }} />}
                            </ValueItem>
                        )

                    default:
                        break;
                }
            })
        )
    };

    const goTo = (route) => {
        props.history.push({
            pathname: route,
        });
    };

    const filterDelta = (reset) => {
        if (reset){
            setSearchSkuLocal('')
            setSearchSku('')
        }else{
            setSearchSku(searchSkuLocal)
        }
    };

    const handleExportProducts = () => {
        try {
            props.startLoading();

            axios(`${process.env.REACT_APP_API}/product-source-price-watcher/export`, 'POST', {"createdAfter": moment().format('YYYY-MM-DD')})
                .then(async (data) => {
                    if (data) {
                        let blob = new Blob([data], {type: "text/csv;charset=utf-8"});
                        saveAs(blob, `export_produits_delta_${moment().format('DD-MM-YYYY')}-${moment().unix()}.csv`);
                        props.stopLoading();
                        props.snack(ALERT_SUCCESS, `L'exportation a réussi !`);
                    } else {
                        props.stopLoading();
                        props.snack(ALERT_ERROR, `Un problème est survenu lors de l'exportation !`);
                    }

                });
        } catch (e) {
            props.snack(ALERT_ERROR, `L'exportation a échoué !`);
            props.stopLoading();
        }
    };

    useEffect(() => {
        if (!firstRender){
            let newPagination = {
                ...resetPagination(),
            };
    
            setPagination(newPagination);
            searchPrices(newPagination);
        }
    }, [searchSku])

    return (
        <div>
            <TopPanel
                title={props.t('products.workflow.title_delta')}
                subtitle={props.t('products.workflow.subtitle_delta')}
                windowWidth={props.windowWidth}
                currentLang={currentLang}
                locales={props.locales}
                hasBorder={true}
                buttonAvailable={true}
            />
            <Grid container>
                <Grid container>
                    <AccordionCustom defaultExpanded={false} title={"Filtres et Recherche"} custommargin={"16px 22px 22px"} style={{ borderRadius: 0 }}>
                        <Grid container spacing={2} alignItems={"flex-end"}>
                            <Grid item xs={10}>
                                {/* Search by sku */}
                                <SearchBar noIcon
                                    label={"Recherche par sku"}
                                    variant="standard"
                                    underlined={false}
                                    style={{
                                        margin: '0',
                                        width: '100%',
                                        background: 'white',
                                    }}
                                    onChange={(e) => setSearchSkuLocal(e.target.value.trim())}
                                    value={searchSkuLocal}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container justifyContent="flex-end" wrap='nowrap'>
                                    {
                                        searchSkuLocal ?
                                            <Button onClick={() => {filterDelta(true)}} color={colors.red.darker} bgcolor={colors.red.lighterv2} bgcolorhover={colors.red.lighterv2Hover} style={{ marginBottom: 0, marginRight: 8, borderRadius: 0, marginTop: 0, border: `1px solid ${colors.red.darker}` }}>Effacer</Button>
                                        : null
                                    }
                                    <Button onClick={() => {
                                        filterDelta()
                                    }} style={{ marginBottom: 0, borderRadius: 0, marginTop: 0 }}>Filtrer</Button>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        
                    </AccordionCustom>
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                    <Grid container alignItems="center" onClick={() => handleExportProducts()} style={{ cursor: "pointer" }}>
                        <OurTypography colortext={colors.blue.darker.hue300} style={{ marginBottom: 4 }}>{props.t('products.list.export.title')}</OurTypography>
                        <GetApp
                            style={{ fontSize: 26, marginLeft: 4, fill: colors.blue.darker.hue300 }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='column' style={{marginTop: 16}}>
                    <AccordionCustom 
                        forcedExpanded={true} 
                        title={
                            <>
                                <Typography variant={"h4"} style={{ fontSize: 14, fontWeight: '600' }}>Visualiser par :</Typography>
                                <SelectCustom
                                    id="collection-select"
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(event) => this.handleChangeDisplay(event)}
                                    disabled={false}
                                    value={displaySeasons}
                                    style={{marginLeft: 16}}
                                >
                                    <MenuItem value={'none'}>Toutes les saisons</MenuItem>
                                </SelectCustom>
                            </>
                        }
                        custompadding={prices.length > 0 ? "0px 22px 22px !important" : null}
                    >
                        {
                            loadingList ? <PageLoader />
                            :
                            prices.length > 0 ?
                                <Grid container>
                                    <TableContainer>
                                        <Table style={{borderCollapse: 'separate', borderSpacing: '0 8px'}}>
                                            <TableHead>
                                                <TableRowCustom>
                                                    <TableCell>Listes des produits</TableCell>
                                                    <TableCell align="left">Mon prix</TableCell>
                                                    <TableCell align="center">Date</TableCell>
                                                    <TableCell align="center" style={{ width: 250 }}>Information sites référents</TableCell>
                                                    <TableCell align="right">Ecart de mon prix par <br/>rapport au référent</TableCell>
                                                </TableRowCustom>
                                            </TableHead>
                                            <TableBodyCustom>
                                                {
                                                    prices?.map((value, index) => {
                                                        return (
                                                            <TableRowDataCustom className='table-row' onClick={() => {
                                                                goTo(ROUTE_PRODUCTS_PRICE_COMPARE_DETAIL.replace(':sku', value.node.sku))
                                                            }}>
                                                                <TableCell style={{ fontSize: 16}}>
                                                                    <Box width={320}>
                                                                        <Grid container alignItems='center' direction='row' wrap='nowrap'>
                                                                            <Grid item style={{ background: "#F7F7F7" }}>
                                                                                <img src={`${process.env.REACT_APP_MEDIAS}/${value.node.productDatas?.edges.find(e => e.node.attribute.identifier === 'product_image')?.node?.media?.filePath}`} style={{ objectFit: 'cover', objectPosition: 'center', width: 125, height: 125 }} />
                                                                            </Grid>
                                                                            <Grid item style={{ marginLeft: 32 }}>
                                                                                <NameProduct variant="h2"><strong>{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_name')?.node.value}</strong></NameProduct>
                                                                                <Typography variant="body1" style={{ fontSize: 16 }}>REF. {value?.node.sku}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCellCustom align="left">{value?.node.productDatas?.edges.find(e => e?.node.attribute.identifier === 'product_price')?.node.value}&euro;</TableCellCustom>
                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'date')}</TableCellCustom>
                                                                <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'retailer')}</TableCellCustom>
                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'source_price')}</TableCellCustom> */}
                                                                <TableCellCustom align="right">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diff')}</TableCellCustom>
                                                                {/* <TableCellCustom align="center">{getListingValues(value.node?.productSourcePriceWatchers.edges, 'diffPercent')}</TableCellCustom> */}
                                                            </TableRowDataCustom>
                                                        )
                                                    })
                                                }
                                            </TableBodyCustom>
                                        </Table>
                                    </TableContainer>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <CursorPagination
                                                rowLabel={`Résultats par page`}
                                                pagination={pagination}
                                                type="table"
                                                changePageCallback={changePage}
                                                changePerPageCallback={changePerPage}
                                                showPerPage={true}
                                                perPageOptions={['10', '30', '100']}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            : <Typography>Aucun produit n'a d'écart</Typography>
                        }
                    </AccordionCustom>
                </Grid>
            </Grid>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
        attributes: state.attributes,
        attributeGroups: state.attributeGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

export default withTranslation()(withApollo(withRouter((connect(mapStateToProps, mapDispatchToProps)(ListProductsDelta)))));