import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea } from '../../helpersBook';
import { getImageDefault} from '../../helpers';

export default {
    name: "Section de succès",
    blocks: {
        title: getDefaultText("Titre", "BRAVO !"),
        text1: getDefaultText("Text 1", "vous êtes inscrit au tirage au sort."),
        text2: getDefaultText("Text 2", "Nous vous souhaitons bonne chance !"),
        imageRight: getImageDefault(null, "Image droite"),
        imageMobile: getImageDefault(null, "Image droite Mobile"),
        subblock: {
            id: uuidv4(),
            name: "Bloc de partage",
            type: EditableTypes.MINI_BLOCKS,
            blocks:{
                title_subblock: getDefaultText("Titre", "MAXIMISEZ VOS CHANCES<br/> DE GAGNER !"),
                text_subblock: getDefaultTextarea("Sous-titre", "Partagez le jeu concours sur Facebook ou par mail<br> pour doubler vos chances de remporter un lot !", true),
            }
        },
    }
};
