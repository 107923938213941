import { EditableTypes, ItemTypes, FormInputTypes } from '../../../shareable/types';
import { getDefaultCollectionHome } from '../../helpersCms';
import { v4 as uuidv4 } from "uuid";

const presentationDuo = () => {
    return {
        id: uuidv4(),
        name: 'Présentation duo',
        inputs: {
            identifiant:{
                label: 'Identifiant',
                type: FormInputTypes.TEXT,
                value: null,
                id: uuidv4(),
            },
            optimisedImage:{
                label: 'Utiliser des images optimisées',
                type: FormInputTypes.SWITCH,
                value: true,
                id: uuidv4(),
            },
            value: {
                type: FormInputTypes.TEXT,
                label: "Titre de la section",
                value: "Collections"
            },
            backgroundColor: {
                label: 'Couleur de fond',
                type: FormInputTypes.COLOR,
                value: 'FAFAFB',
            },
            typeSlider: {
                label: 'Afficher en tant que slider',
                type: FormInputTypes.SWITCH,
                value: false,
            },
            elementPerRow: {
                label: "Nombre d'élement par ligne",
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: '3', label: '3'},
                    {value: '2', label: '2'},
                    {value: '1', label: '1'},
                ],
                value: '3'
            },
            positionButton: {
                label: "Position du boutton",
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: 'center', label: 'Centré'},
                    {value: 'bottom', label: 'En bas'},
                ],
                value: 'center'
            },
            pictoButton:{
                label: 'Picto du bouton',
                type: FormInputTypes.IMAGE,
                value: null,
            },
            effect: {
                label: "Effet hover",
                type: FormInputTypes.BUTTON_GROUP,
                params:[
                    {value: 'overlay', label: 'Overlay'},
                    {value: 'zoom', label: 'Zoom'},
                ],
                value: 'overlay'
            },
        },
        blocks:{
            collections:{
                title: "Collections",
                name: "Collections",
                type: EditableTypes.MULTIPLE_BLOCKS,
                subtype: ItemTypes.CMS_PRESENTATION_DUO,
                buttonAdd: 'une section',
                limit: 2,
                config: [ 
                    getDefaultCollectionHome(),
                    getDefaultCollectionHome()
                ]
            }
        }
    };
};

export default presentationDuo;
