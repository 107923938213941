import { gql } from "apollo-boost";

//----- Récupération des catégories
export const GET_CONTENT_CATEGORIES = gql`
  {
    contentCategories {
      id
      libelle
      parent {
        id
      }
      master {
        id
        libelle
      }
      contents {
        totalCount
        edges {
          node {
            id
            sku
            attributeGroup {
              id
              identifier
            }
            contentCategories {
              id
              libelle
            }
            contentDatas {
              edges {
                node {
                  id
                  value
                  attributeOption {
                    id
                    identifier
                  }
                  attribute {
                    identifier
                    isSystem
                  }
                  locale {
                    code
                  }
                  media {
                    id
                    filePath
                    type
                    size
                    category {
                      edges {
                        node {
                          id
                          libelle
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      contentCategoryDatas {
        edges {
          node {
            id
            value
            attribute {
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media {
              id
              filePath
              type
              size
              category {
                edges {
                  node {
                    id
                    libelle
                  }
                }
              }
            }
            locale {
              id
              code
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_CONTENT_CATEGORIES = gql`
  query contentCategoryDataSearch($attribute: String, $needle: String) {
    contentCategoryDatas(attribute: $attribute, value: $needle) {
      edges {
        node {
          id
          value
          contentCategory {
            id
            libelle
            parent {
              id
            }
            master {
              id
              libelle
            }
            catalog {
              id
              identifier
            }
            contents {
              edges {
                node {
                  id
                  sku
                  attributeGroup {
                    id
                    identifier
                  }
                  contentCategories {
                    id
                    libelle
                  }
                  contentDatas {
                    edges {
                      node {
                        id
                        value
                        attributeOption {
                          id
                          identifier
                        }
                        attribute {
                          identifier
                          isSystem
                        }
                        locale {
                          code
                        }
                        media {
                          id
                          filePath
                          type
                          size
                          category {
                            edges {
                              node {
                                id
                                libelle
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            contentCategoryDatas {
              edges {
                node {
                  id
                  value
                  attribute {
                    id
                    identifier
                    attributeType {
                      id
                      input
                    }
                  }
                  media {
                    id
                    filePath
                    type
                    size
                    category {
                      edges {
                        node {
                          id
                          libelle
                        }
                      }
                    }
                  }
                  locale {
                    id
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTENT_CATEGORIES_LIST = gql`
  query get($id: ID!) {
    contentCategory(id: $id) {
      children {
        id
        libelle
        position
        status
        parent {
          id
        }
        master {
          id
          libelle
        }
        catalog {
          id
          identifier
        }
        contents {
          totalCount
          edges{
            node{
              id
            }
          }
        }
        mediaObjectCategories {
          edges {
            node {
              id
              libelle
              parent {
                id
                libelle
              }
            }
          }
        }
        contentCategoryDatas {
          edges {
            node {
              id
              value
              attribute {
                id
                identifier
                attributeType {
                  id
                  input
                }
              }
              media {
                id
                filePath
                type
                size
                category {
                  edges {
                    node {
                      id
                      libelle
                    }
                  }
                }
              }
              locale {
                id
                code
              }
            }
          }
        }
      }
      id
      libelle
      position
      status
      parent {
        id
      }
      master {
        id
        libelle
      }
      catalog {
        id
        identifier
      }
      contents {
        totalCount
      }
      mediaObjectCategories {
        edges {
          node {
            id
            libelle
            parent {
              id
              libelle
            }
          }
        }
      }
      contentCategoryDatas {
        edges {
          node {
            id
            value
            attribute {
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media {
              id
              filePath
              type
              size
              category {
                edges {
                  node {
                    id
                    libelle
                  }
                }
              }
            }
            locale {
              id
              code
            }
          }
        }
      }
    }
  }
`;

//----- Récupération des catégories sans les contents
export const GET_CONTENT_CATEGORIES_ONLY = gql`
  {
    contentCategories {
      id
      libelle
      position
      status
      parent {
        id
      }
      master {
        id
        libelle
      }
      catalog {
        id
        identifier
      }
      contents {
        totalCount
      }
      mediaObjectCategories {
        edges {
          node {
            id
            libelle
            parent {
              id
              libelle
            }
          }
        }
      }
      contentCategoryDatas {
        edges {
          node {
            id
            value
            attribute {
              id
              identifier
              attributeType {
                id
                input
              }
            }
            media {
              id
              filePath
              type
              size
              category {
                edges {
                  node {
                    id
                    libelle
                  }
                }
              }
            }
            locale {
              id
              code
            }
          }
        }
      }
    }
  }
`;

//----- Récupération des données d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/content-categories/5"
  }
*/
export const GET_CONTENT_CATEGORY_DATA = gql`
  query contentCategoryDatas($id: ID!) {
    contentCategory(id: $id) {
      id
      libelle
      contentCategoryDatas {
        edges {
          node {
            id
            value
            attribute {
              id
              identifier
            }
            media {
              id
              filePath
              type
              size
              category {
                edges {
                  node {
                    id
                    libelle
                  }
                }
              }
            }
            locale {
              id
              code
            }
          }
        }
      }
    }
  }
`;

//----- Ajouter une catégorie
/*
 Exemple de variables
  {
    "libelle" : "Cat de démo",
    "parent" : "/api/content-categories/1"
  }
*/
export const ADD_CONTENT_CATEGORY = gql`
  mutation AddContentCategory(
    $libelle: String!
    $parent: String
    $catalog: String
    $master: String
    $contents: [String]
    $mediaObjectCategories: [String]
    $status: Boolean!
  ) {
    createContentCategory(
      input: {
        libelle: $libelle
        parent: $parent
        catalog: $catalog
        master: $master
        contents: $contents
        mediaObjectCategories: $mediaObjectCategories
        status: $status
      }
    ) {
      contentCategory {
        id
        libelle
        master {
          id
          libelle
        }
        catalog {
          id
          identifier
        }
        parent {
          id
        }
      }
    }
  }
`;

//----- Modifier une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/content-categories/5",
    "libelle" : "libelle edited",
    "parent" : "/api/content-categories/1"
  }
*/
export const UPDATE_CONTENT_CATEGORY = gql`
  mutation UpdateContentCategory(
    $id: ID!
    $libelle: String
    $parent: String
    $catalog: String
    $master: String
    $contents: [String]
    $mediaObjectCategories: [String]
    $status: Boolean
  ) {
    updateContentCategory(
      input: {
        id: $id
        libelle: $libelle
        parent: $parent
        catalog: $catalog
        master: $master
        contents: $contents
        mediaObjectCategories: $mediaObjectCategories
        status: $status
      }
    ) {
      contentCategory {
        id
        libelle
        master {
          id
          libelle
        }
        catalog {
          id
          identifier
        }
        parent {
          id
        }
      }
    }
  }
`;

//----- Supprimer une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/content-categories/1"
  }
*/
export const DELETE_CONTENT_CATEGORY = gql`
  mutation DeleteContentCategory($id: ID!) {
    deleteContentCategory(input: { id: $id }) {
      contentCategory {
        id
      }
    }
  }
`;

//--------------------
//----- DONNEES CATEGORIES
//--------------------

//----- Ajouter une valeur d'attribut à une catégorie

/*
 Exemple de variables
 {
  "value": "Catégorie 1 de test",
	"contentCategory": "/api/content-categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const ADD_CONTENT_CATEGORY_DATA = gql`
  mutation AddContentCategoryData(
    $value: String
    $contentCategory: String!
    $attribute: String!
    $locale: String!
    $media: String
  ) {
    createContentCategoryData(
      input: {
        value: $value
        contentCategory: $contentCategory
        attribute: $attribute
        locale: $locale
        media: $media
      }
    ) {
      contentCategoryData {
        id
        value
        contentCategory {
          id
        }
        attribute {
          id
        }
        media {
          id
        }
        locale {
          id
        }
      }
    }
  }
`;

//----- Modifier la valeur d'attribut d'une catégorie

/*
 Exemple de variables
 {
  "id": "/api/content-category-datas/1",
  "value": "Catégorie 1 de test edited",
	"contentCategory": "/api/content-categories/5",
  "attribute": "/api/attributes/21", 
	"locale": "/api/locales/2"
}
*/
export const UPDATE_CONTENT_CATEGORY_DATA = gql`
  mutation UpdateContentCategoryData(
    $id: ID!
    $value: String
    $contentCategory: String!
    $attribute: String!
    $locale: String!
    $media: String
  ) {
    updateContentCategoryData(
      input: {
        id: $id
        value: $value
        contentCategory: $contentCategory
        attribute: $attribute
        locale: $locale
        media: $media
      }
    ) {
      contentCategoryData {
        id
        value
        contentCategory {
          id
        }
        attribute {
          id
        }
        media {
          id
        }
        locale {
          id
        }
      }
    }
  }
`;

//----- Supprimer une valeur d'attribut d'une catégorie
/*
 Exemple de variables
  {
    "id" : "/api/content-category-datas/68"
  }
*/
export const DELETE_CONTENT_CATEGORY_DATA = gql`
  mutation DeleteContentCategoryData($id: ID!) {
    deleteContentCategoryData(input: { id: $id }) {
      contentCategoryData {
        id
      }
    }
  }
`;
