import {FORM} from '../../../../../../js/constants/form-types';
import Button from '../../../../../ui/button/Button';

export default function notificationsEdit(action='add', listGroups) {
    let generalInputs   = [
        // {
        //     type: 'select',
        //     label: 'Groupe d\'utilisateurs',
        //     helper: {
        //         text: `Indiquez le/les groupe.s d\'utilisateurs`,
        //         link: false,
        //     },
        //     translated: false,
        //     required: true,
        //     stateName: 'notificationGroups',
        //     multiselect: true,
        //     value: listGroups.map(group => {
        //         return ({
        //             value: group.name,
        //             label: group.name
        //         });
        //     })
        // },
        {
            required : true,
            type : 'text',
            label : 'Titre',
            stateName : 'notificationTitle',
            helper: {
                text: 'Titre de la notification',
                link: false,
            },
        },
        {
            required : true,
            type : 'text',
            label : 'Message',
            stateName : 'notificationMessage',
            helper: {
                text: 'Message de la notification',
                link: false,
            },
        },
        {
            required : false,
            type : 'text',
            label : 'URL',
            stateName : 'notificationLink',
            helper: {
                text: 'Lien contenu dans la notification',
                link: false,
            },
        },
        // {
        //     required : false,
        //     type : 'text',
        //     label : 'Lien',
        //     stateName : 'notificationLink',
        //     helper: {
        //         text: 'Lien de redirection du header',
        //         link: false,
        //     },
        // },
        {
            type: 'date',
            label: 'Date',
            helper: {
                text: 'Date de la notification',
                link: false,
            },
            getTime: true,
            required: false,
            stateName: 'notificationScheduledAt',
        },
        // {
        //     type: 'buttonGroup',
        //     label: 'État',
        //     helper: {
        //         label: 'État',
        //         text: 'Indiquez l\'état',
        //         link: false,
        //     },
        //     required: true,
        //     stateName: 'headerStatus',
        //     value: [
        //         {
        //             value: true,
        //             label: 'Actif',
        //         },
        //         {
        //             value: false,
        //             label: 'Inactif',
        //         },
        //     ]
        // },
    ]
    // customInputs.splice(0,0,generalInputs)
    var form = {
        titleForm: 'Envoyer une notification',
        subTitleForm: `Veuillez compléter les champs ci-dessous pour envoyer votre notification`,
        drawerType: 'swipeable',
        noForm: true,
        component: Button,
        textButtonValidate: 'Envoyer la notification',
        formConfig: {
            type: FORM,
            // finalStep: {
            //     title: 'Et voilà !',
            //     subtitle: 'Envoyer la notification',
            //     textButton: 'Envoyer'
            // },
            children:[
                {
                    labelName: 'Informations',
                    isOptionnal: false,
                    optionsInputs:generalInputs
                },
            ]
        }
    }
    return form;
}