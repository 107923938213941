import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../js/constants/alert-types';
import { Box, Grid, Typography, TextField, Switch, MenuItem, Dialog } from '@material-ui/core';
import { ROUTE_HOME, ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH, ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER } from '../../../js/constants/route-names';
import { withTranslation } from 'react-i18next';
import colors from '../../../config/theme/colors';
import LayoutBuilder from '../../ui/form/LayoutFormBuilder';
import { saveElement, updateElement } from '../../../js/utils/functions';
import * as moment from "moment";
// import { DELETE_USERGROUP } from '../../../../queries/user_groups';
import Button from '../../ui/button/Button';
import { withApollo } from 'react-apollo';
import { GET_ASSET_BY_ID_NAVIGATION, GET_ASSET_BY_ID_ONLY_CATALOG } from '../../../queries/assets';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { GET_MERCHANDISING, GET_MERCHANDISINGS, GET_MERCHANDISINGS_FILTERS, GET_MERCHANDISING_BY_SKUS } from '../../../queries/merchandising';
import PageLoader from '../../ui/loadings/page-loader/PageLoader';
import { GET_CATEGORIE_MERCH, GET_CATEGORY_DATA } from '../../../queries/categories';
import { getTraductionAttributs } from '../../../js/utils/functions';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../assets/selection.json";
import { SortableComponent } from "./components/SortableComponent";
import './components/SortableComponent.scss';
import _ from 'lodash';
import replaceConfig from './config/replaceConfig.config';
import request from '../../../js/utils/fetch';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import DialogModal from '../../ui/dialog/DialogModal';
import arrayMove from 'array-move';
import ModalSearch from './components/ModalSearch';
import { GET_PRODUCTS_SEARCH_LIGHT_PRODUCTS_MERCH } from '../../../queries/products';

const CustomSelect = styled(Select)`
    border-color : ${colors.grey.regular};
    border-radius: 0;
    min-width: 175px;
    .MuiSelect-root{
        padding-top: 13px;
        padding-bottom: 13px;
        text-transform: capitalize;
    }
`;

const MenuItemCustom = styled(MenuItem)`
    text-transform: capitalize;
`;

const ContainerInput = styled(Grid)`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-top:0!important;
    padding-bottom:0!important;
`;

const GridSelect = styled(ContainerInput)`
    padding-top:0!important;
    padding-bottom:0!important;
    @media screen and (max-width: 1200px){
        margin-left: 0 !important;
        width: 100%;
        div{
            width: auto !important;
        }

    }
`;

const BoxCustom = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    border-bottom: 0.5px solid #E3E3E3;
`
const MainTitle = styled(Typography)`
    color: ${colors.sinfinNewMenu.normal};
    font-size: 33px;
    font-weight: bold;
`
const Subtitle = styled(Typography)`
    color: ${colors.sinfinNewMenu.normalAccordion};
    font-size: 12px;
    font-weight: bold;
`

const Logo = styled(Box)`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    overflow:hidden;
    transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    img{
        display: inline-block;
        padding-right: 10px;
        padding-left: 10px;
        max-height: 100%;
        max-width: 100%;
    }
    .MuiTypography-h2{
        color: ${colors.black.regular};
        display: inline-block;
        padding-right: 20px;
    }
`;

const TextFieldCustom = styled(TextField)`
    input, .MuiSelect-select{
        font-size: 14px;
        padding: 13px;
    }
    & > p{
        margin: 0;
    }
`;

const SwitchCustom = styled(Switch)`
    .Mui-checked{
        color: ${colors.blue.lighter.hue150};
        & + .MuiSwitch-track{
            background-color: ${colors.blue.darker.hue300};
        }
    }
`;

const BoxTitlePicto = styled(Box)`
    background: ${colors.white};
    border-radius: 5px;
    border: 1px solid ${props => props.noresult ? colors.grey.lighter.hue600 : colors.blue.darker.hue300};
    width: max-content;
`;


const EngineAssetsDetailsMerchandisingDetail = (props) =>{
    const [rules, setRules] = React.useState([
        {
            id: 'custom',
            code: 'Custom'
        },
        {
            id: 'sku_asc',
            code: 'SKU (ASC)'
        },
        {
            id: 'sku_desc',
            code: 'SKU (DESC)'
        },
        {
            id: 'name_asc',
            code: 'NAME (ASC)'
        },
        {
            id: 'name_desc',
            code: 'NAME (DESC)'
        },
    ]);
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [currentCat, setCurrentCat] = React.useState(null);
    const [catalogId, setCatalogId] = React.useState(null);
    const [currentVersion, setCurrentVersion] = React.useState(null);
    const [loadedMerch, setLoadedMerch] = React.useState(false);
    const [loadedVersions, setLoadedVersions] = React.useState(false);
    const [loadedListItems, setLoadedListItems] = React.useState(false);
    const [editType, setEditType] = React.useState(null);
    const [openForm, setOpenForm] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [allVersions, setAllVersions] = React.useState([]);
    const [merchCurrent, setMerchCurrent] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [seeError, setSeeError] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(null);
    const [initialListItems, setInitialListItems] = React.useState([])
    const [openModal, setOpenModal] = useState(false)
    const [searchSkus, setSearchSkus] = React.useState("")
    const [searchLoading, setSearchLoading] = React.useState(false)
    const [currentProducts, setCurrentProducts] = React.useState([])
    const [currentProductsNotFound, setCurrentProductsNotFound] = React.useState([])
    const [currentProductsLoaded, setCurrentProductsLoaded] = React.useState(false)
    const [currentSort, setCurrentSort] = React.useState([])
    const [searchSkuError, setSearchSkuError] = React.useState(false)
    const [states, setStates] = React.useState({
        rule: 0,
        merchandisingLibelle: null,
        merchandisingStartAt: null,
        merchandisingEndAt: null,
        merchandisingStatus: false,
        listItems: []
    });

    useEffect(() => {
        setMerchCurrent(null)
        initNavigationAsset()
        initMerchData()
        initVersions()
    }, [props.match.params.id, props.match.params.idCat, props.match.params.idMerch])

    const goTo = route => {
        props.history.push(route);
    };

    const initMerchData = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_CATEGORIE_MERCH,
                variables: { id: `/api/categories/${props.match.params.idCat}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setCurrentCat(result.data.category)
                setLoadedMerch(true)
            })
            resolve()
        })
    }

    const initNavigationAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setCatalogId(result.data.asset.catalog.id)
            })
            resolve()
        })
    }

    const initVersions = () => {
        return new Promise(async (resolve, reject) => {
            props.client.query({
                query: GET_MERCHANDISINGS,
                variables: {
                    category: `/api/categories/${props.match.params.idCat}`
                },
                fetchPolicy: 'no-cache'
            }).then(result => {
                setAllVersions(result.data.merchandisings.edges)
                setCurrentVersion(`/api/merchandisings/${props.match.params.idMerch}`)
                props.client.query({
                    query: GET_MERCHANDISING,
                    variables: {
                        id: `/api/merchandisings/${props.match.params.idMerch}`,
                    },
                    fetchPolicy: 'no-cache'
                }).then(data => {
                    setMerchCurrent(data.data.merchandising)
                    setLoadedVersions(true)
                    let initListItems = data.data.merchandising.merchandisingProducts.edges
                    initListItems = initListItems.sort(function (a, b) { return a.node.position - b.node.position; }).map(e => {
                        let name = e.node.product.flatProducts?.edges[0]?.node.name;
                        let image = e.node.product.flatProducts?.edges[0]?.node.image;
                        return {
                            id: parseInt(e.node.product.id.replace('/api/products/', '')),
                            sku: e.node.product.sku,
                            name: name,
                            image: image
                        }
                    })
                    let setupData = {
                        rule: `${data.data.merchandising?.merchandisingFilters?.edges?.[0]?.node.code}_${data.data.merchandising?.merchandisingFilters?.edges?.[0]?.node.sortBy.toLowerCase()}` ?? 0,
                        merchandisingStartAt: data.data.merchandising?.startAt,
                        merchandisingEndAt: data.data.merchandising?.endAt,
                        merchandisingLibelle: data.data.merchandising?.title,
                        merchandisingStatus: data.data.merchandising?.status,
                        listItems: initListItems
                    }
                    setStates(setupData)
                    if (data.data.merchandising.merchandisingProducts.edges.length > 0) {
                        setInitialListItems([...initListItems])
                        setLoadedListItems(true)
                    } else {
                        handleGetProductsViaRules(setupData)
                    }
                })

            })
            resolve()
        })
    }

    const handleToggleDrawer = (stateDrawer, reset, form) => {
        setEditType(form)
        setOpenForm(!openForm)
        // if (reset){
        //     resetStates()
        // }
    };
    const handleToggleDialog = () => {
        setOpenDialog(!openDialog)
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const checkSave = () => {
        let disableButton = true
        if (states.merchandisingLibelle !== merchCurrent.title || moment(states.merchandisingStartAt).format().slice(0, 10) !== moment(merchCurrent.startAt).format().slice(0, 10) || moment(states.merchandisingEndAt).format().slice(0, 10) !== moment(merchCurrent.endAt).format().slice(0, 10) || states.merchandisingStatus !== merchCurrent.status) {
            disableButton = false
        }
        return disableButton
    }
    const checkSaveList = () => {
        let disableButton = true
        let listProducts = states.listItems.map(e => e.sku)
        let listProductsInitial = initialListItems.map(e => e.sku)
        if (listProductsInitial === 0) {
            disableButton = false
        } else {
            if (!_.isEqual(listProducts, listProductsInitial)) {
                disableButton = false
            }
        }
        return disableButton
    }

    const handlerMutation = () => {
        let getProduct = states.productToUse;
        let getStates = { ...states };
        let index = getStates.listItems.findIndex((item) => item.sku === getProduct.sku);
        let name = getProduct.flatProducts?.edges[0]?.node.name;
        let image = getProduct.flatProducts?.edges[0]?.node.image;
        let initProduct = {
            id: parseInt(getProduct.id.replace('/api/products/', '')),
            sku: getProduct.sku,
            name: name,
            image: image
        }
        if (index > -1) {
            props.snack(ALERT_ERROR, "Produit déjà dans le merch");
        } else {
            if (editType === "replace") {
                getStates.listItems[currentIndex] = initProduct
            } else {
                getStates.listItems.splice(currentIndex, 0, initProduct);
            }
            stateCallback('rule', 'custom')
            props.snack(ALERT_SUCCESS, editType === "replace" ? 'Produit remplacé avec succès' : 'Produit ajouté avec succès');
            handleToggleDrawer('openForm', true);
            setStates(getStates)
        }
    }

    const getGuidelineName = () => {
        if (props.guideline) {
            if (props.guideline.libelle) {
                return props.guideline.libelle;
            }
            return null;
        }
        else {
            return null;
        }
    };

    const getGuidelineImg = () => {
        if (props.guideline) {
            for (let guidelineData of props.guideline.guidelineData.edges) {
                if (guidelineData.node.locale.code === currentLang && guidelineData.node.attribute.identifier === "guideline_logo") {
                    return process.env.REACT_APP_API_ROOT + '/medias/' + guidelineData.node.media?.filePath;
                }
            }
            return null;
        }
        else {
            return null;
        }
    };

    const handleButton = (index, type) => {
        setCurrentIndex(index)
        handleToggleDrawer('openForm', null, type);
    }
    const handleDeleteItem = (index) => {
        let getStates = { ...states };
        getStates.listItems.splice(index, 1);
        setStates(getStates)
        stateCallback('rule', 'custom')
        props.snack(ALERT_SUCCESS, 'Produit supprimé avec succès');
        // setCurrentIndex(index)
    }

    const handleSaveInfos = async () => {
        props.startLoading()
        let variables = null;
        variables = {
            id: currentVersion,
            category: states.merchandisingCategory,
            title: states.merchandisingLibelle,
            startAt: states.merchandisingStartAt,
            endAt: states.merchandisingEndAt,
            status: states.merchandisingStatus,
        }
        try {
            let updatedInfos = await updateElement(states, 'merchandising', variables, null, { enableLoad: true })
            if (updatedInfos) {
                await initMerchData()
                await initVersions()
                props.snack(ALERT_SUCCESS, 'Merchandising modifié avec succès');
                props.stopLoading()
                // setReloadVersions(true)
            }
            props.stopLoading()
        } catch {
            props.stopLoading()
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de la création du merchandising');
        }
    }
    const handleSaveListMerchandising = async () => {
        props.startLoading()
        let variables = null;
        variables = states.listItems.map((e, index) => {
            return (
                {
                    id: e.id,
                    position: index
                }
            )
        })
        try {
            let saveList = await request(`${process.env.REACT_APP_API}/merchandising/${props.match.params.idMerch}`, 'post', variables, undefined, true);

            if (saveList.success) {
                await initMerchData()
                await initVersions()
                props.snack(ALERT_SUCCESS, 'Merchandising sauvegardé avec succès');
                props.stopLoading()
            } else {
                props.snack(ALERT_ERROR, 'Une erreur est survenue lors de l\'enregistrement de la liste des produits');
                props.stopLoading()
            }
        } catch {
            props.stopLoading()
            props.snack(ALERT_ERROR, 'Une erreur est survenue lors de l\'enregistrement de la liste des produits');
        }
    }

    const handleChangeRule = () => {
        let formData = new FormData();
        formData.append('filter', states.rule)
        return new Promise((resolve, reject) => {

            request(`${process.env.REACT_APP_API}/merchandising/filter/${props.match.params.idMerch}`, 'post', formData, 'multipart/form-data').then(
                (data) => {
                    if (data.success) {
                        props.snack(ALERT_SUCCESS, 'Règle modifiée avec succès');
                    } else {
                        props.snack(ALERT_ERROR, 'Erreur lors du changement de la règle');
                    }
                    resolve();
                }
            );
        })
    }

    const handleGetProductsViaRules = (setupData) => {
        setLoadedListItems(false)
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/merchandising/${props.match.params.id}/${props.match.params.idMerch}/1000/0`, 'post', false).then(
                (data) => {
                    let getState = setupData ? { ...setupData } : { ...states };
                    getState['listItems'] = data.data;
                    setStates(getState);
                    props.snack(ALERT_SUCCESS, 'Produits récupérés avec succès');
                    setLoadedListItems(true);
                    resolve();
                }
            );
        })
    }

    const queryMerchProducts = async  (skus) =>{
        let array = await Promise.all(skus.map(async (element,i) => {
            try {
                let current = states.listItems.find(e=>e.sku === element)
                if (current) {
                    let currentIndex = states.listItems.findIndex(e=>e.sku === element)
                    return {...current,position:currentIndex}
                }else{
                    const {data} = await props.client.query({
                        query: GET_MERCHANDISING_BY_SKUS,
                        variables: {
                            merchandising : props.match.params.idMerch,
                            sku : element
                        },
                        fetchPolicy: 'no-cache'
                    })
                    let product = data.merchandisingProducts.edges[0]
                    if (product) {
                        let name = product.node.product.flatProducts?.edges[0]?.node.name;
                        let image = product.node.product.flatProducts?.edges[0]?.node.image;
                        return {
                            id: parseInt(product.node.product.id.replace('/api/products/', '')),
                            sku: product.node.product.sku,
                            name: name,
                            image: image,
                            position : product?.node?.position
                        }
                    }else{
                        return element
                    }
                }
            } catch (error) {
                console.log(error)
                return element
            }
        }))
        return array
    }

    const queryOutOfMerchProducts = async  (skus) =>{
        try {
            const {data} = await props.client.query({
                query: GET_PRODUCTS_SEARCH_LIGHT_PRODUCTS_MERCH,
                variables: {
                    page: 1,
                    itemsPerPage:skus?.length,
                    skus : skus
                },
                fetchPolicy: 'no-cache'
            })
            const result = data.researchProducts.edges.map(e=>{
                let name = e.node.flatProducts?.edges[0]?.node.name;
                let image = e.node.flatProducts?.edges[0]?.node.image;
                return {
                    id: parseInt(e.node.id.replace('/api/products/', '')),
                    sku: e.node.sku,
                    name: name,
                    image: image
                }
            })
            return result
        } catch (error) {
            console.log(error)
        }
    }

    const searchProducts = async () => {
        setSearchLoading(true)
        const skus = searchSkus.split(",").filter(e=>e && e !== "")
        let array = await queryMerchProducts(skus)
        const notFounds = array?.filter(e=>typeof e === "string")
        if (notFounds && notFounds.length > 0) {
            const notFoundArray = await queryOutOfMerchProducts(notFounds)
            const finalArray = array?.filter(e=>typeof e === "object").concat(notFoundArray)
            const finalArraySorted = skus.map(e=>{
                return finalArray.find(el=>el.sku === e)
            })
            setCurrentProducts(finalArraySorted)
            setCurrentSort(finalArraySorted)
        }else{
            setCurrentProducts(array?.filter(e=>typeof e === "object"))
            setCurrentSort(array?.filter(e=>typeof e === "object"))
        }
        setCurrentProductsLoaded(true)
        setSearchLoading(false)
    }

    const preOrder = ()=>{
        setCurrentProductsLoaded(false)
        setSearchLoading(true)
        let array = currentSort
        array = array.map((e,i)=>{
            return {
                ...e,
                position : i
            }
        })
        setTimeout(() => {
            setCurrentSort(array)
            setCurrentProducts(array)
            setCurrentProductsLoaded(true)
            setSearchLoading(false)
        }, 300);
    }

    const changeProductPosition = (value,old, newvalue)=>{
        let array = currentSort
        let oldIndex = array.findIndex(e=>e.sku === value)
        array[oldIndex].position = newvalue
        setCurrentSort(array)
    }

    const saveNewSort = ()=>{
        let error = false
        currentSort.forEach(element => {
            if (element.position === null) {
                error = true
            }
            let searching = currentSort.filter(e=>e.position === element.position)
            if (searching?.length > 1) {
                error = true
            }
        })
        if (!error) {
            let sortedArray = currentSort.sort(function (a, b) { return b.position - a.position; })
            let array = states.listItems
            sortedArray.forEach(element => {
                let current = array.findIndex(e=>e?.sku === element.sku)
                if (current >= 0 && element.position + 1 <= array.length) {
                    array = arrayMove(array, current, element.position)
                }else {
                    array[element.position] = element
                }
            });
            stateCallback('listItems', array)
            resetSearchSkus()
        }else{
            setSearchSkuError(error)
        }
    }

    const stateCallbackSearch = (value)=>{
        setSearchSkus(value)
    }

    const resetSearchSkus = ()=>{
        setCurrentProducts([])
        setCurrentSort([])
        setCurrentProductsNotFound([])
        setSearchSkus(null)
        setSearchSkuError(false)
        setCurrentProductsLoaded(false)
        setOpenModal(false)
    }
    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    // React.memo(stateCallbackSearch)
    // React.memo(toggleModal)

    const applyRules = async () => {
        if (states.listItems.length > 0) {
            handleToggleDialog()
        } else {
            await handleChangeRule()
            await handleGetProductsViaRules()
        }
    }

    let titleDefault = null
    let title = null
    if (currentCat) {
        titleDefault = getTraductionAttributs('category_name', currentCat.categoryDatas.edges, currentLang)
        title = currentCat.libelle
    }

    return (
        <div>
            {
                loadedMerch && currentCat && loadedVersions && merchCurrent ?
                    <Grid container style={{ height: "100vh", width: "100vw" }}>
                        <Box width={"calc(100% - 331px)"} padding={"20px"} paddingRight={"40px"}>
                            <Grid container alignItems='center' justifyContent='space-between'>
                                <Grid item>
                                    <Box display={"flex"} flexDirection={"column"} style={{paddingLeft:10}}>                                        
                                        <MainTitle variant='h1'>{title ?? titleDefault}</MainTitle>
                                        <Typography style={{fontSize:16}} variant={"body2"}>Vous pouvez faire votre merchandising avec le système de drag and drop</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Grid container direction='row' alignItems='center'>
                                        <Box display={"flex"} style={{ cursor: "pointer" }} flexDirection="row" onClick={() => goTo(ROUTE_SALES_ESHOP_DETAIL_MERCHANDISING_BY_MERCH.replace(':id', props.match.params.id).replace(':idCat', props.match.params.idCat.replace('/api/categories/', '')))}><KeyboardReturnIcon /><Typography variant={"h4"} style={{ marginRight: 16 }}>Revenir</Typography></Box>
                                        <Button onClick={toggleModal}>Rechercher</Button>
                                        <Button bgcolorhover={colors.green.darker} bgcolor={colors.green.regular} style={{ marginLeft: 16, padding: "14px 32px" }} disabled={checkSaveList()} onClick={() => handleSaveListMerchandising()}>Sauvegarder</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: 20, maxHeight: "calc(100vh - 120px)", overflow: 'auto', paddingRight: 20 }} aria-label="headerList">
                                {
                                    loadedListItems ?
                                        <SortableComponent openModal={openModal} stateCallback={stateCallback} currentRule={states.rule} listItems={states.listItems.slice(0, 1000)} handleButton={handleButton} handleDeleteItem={handleDeleteItem} windowWidth={props.windowWidth} />
                                        : <PageLoader />
                                }
                            </Grid>
                        </Box>
                        <Box width={"100%"} maxWidth={"270px"} style={{ borderLeft: "1px solid #E3E3E3" }}>
                            <BoxCustom alignItems={"center"}>
                                <Logo imagefound={(getGuidelineImg() !== null) ? "true" : "false"}>
                                    {
                                        getGuidelineImg() !== null ? (
                                            <img src={getGuidelineImg()} alt={getGuidelineName()} />
                                        ) : getGuidelineName() !== null ? (
                                            <Typography variant="h2">{getGuidelineName()}</Typography>
                                        ) : null
                                    }
                                </Logo>
                            </BoxCustom>
                            <BoxCustom>
                                <Subtitle variant="h3">{titleDefault ?? title}</Subtitle>
                                <Box mt={1.5} mb={1.5} width={"100%"}>
                                    <GridSelect item>
                                        <div style={{ width: '100%', marginBottom: 5 }}>Choix de la version</div>
                                        <CustomSelect
                                            labelId="version-select"
                                            id="version-simple-select"
                                            value={merchCurrent.id}
                                            onChange={(evt) => { goTo(ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER.replace(':id', props.match.params.id).replace(':idCat', props.match.params.idCat.replace('/api/categories/', '')).replace(':idMerch', evt.target.value.replace('/api/merchandisings/', ''))) }}
                                            variant="outlined"
                                        >
                                            {
                                                allVersions.map((e, index) => {
                                                    return (
                                                        <MenuItem value={e.node.id} key={`version-merch-${index}`}>{e.node.title}</MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    </GridSelect>
                                </Box>
                            </BoxCustom>
                            <BoxCustom>
                                <Box mt={1.5} mb={1.5} width={"100%"}>
                                    <Subtitle variant="h3">Informations générales</Subtitle>
                                    <Box mt={1.5} width={"100%"}>
                                        <GridSelect item>
                                            <Box display={"flex"} flexDirection={"row"} width={"100%"} alignItems={"center"}>
                                                <div>Activé</div>
                                                <SwitchCustom
                                                    checked={states.merchandisingStatus ? states.merchandisingStatus : false}
                                                    className={states.merchandisingStatus ? 'checked' : ''}
                                                    onChange={event => stateCallback("merchandisingStatus", !states.merchandisingStatus)}
                                                    color="primary"
                                                    name="checkedB"
                                                    // value={switchValue || ''}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Box>
                                        </GridSelect>
                                    </Box>
                                    <Box mt={2} width={"100%"}>
                                        <GridSelect item>
                                            <div style={{ width: '100%', marginBottom: 5 }}>Libellé*</div>
                                            <ContainerInput>
                                                <TextFieldCustom
                                                    id={"libelleMerch"}
                                                    variant="outlined"
                                                    value={states.merchandisingLibelle}
                                                    helperText={seeError ? errorMessage : null}
                                                    onChange={evt => {
                                                        setSeeError(true);
                                                        stateCallback("merchandisingLibelle", evt);
                                                    }}
                                                    name={"merchandisingLibelle"}
                                                    type={"text"}
                                                    placeholder={"Libellé"}
                                                    error={seeError && errorMessage ? true : false}
                                                    style={{ width: '100%' }}
                                                />
                                            </ContainerInput>
                                        </GridSelect>
                                    </Box>
                                    <Box mt={1} width={"100%"}>
                                        <GridSelect item>
                                            <div style={{ width: '100%', marginBottom: 5 }}>Date de début*</div>
                                            <ContainerInput>
                                                <TextFieldCustom
                                                    id={"merchandisingStartAt"}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={moment(states.merchandisingStartAt).format().slice(0, 10)}
                                                    helperText={seeError ? errorMessage : null}
                                                    onChange={evt => {
                                                        setSeeError(true);
                                                        stateCallback("merchandisingStartAt", evt);
                                                    }}
                                                    inputProps={{
                                                        step: "1",
                                                    }}
                                                    name={"merchandisingStartAt"}
                                                    type={"date"}
                                                    error={seeError && errorMessage ? true : false}
                                                    style={{ width: '100%' }}
                                                />
                                            </ContainerInput>
                                        </GridSelect>
                                    </Box>
                                    <Box mt={1} width={"100%"}>
                                        <GridSelect item>
                                            <div style={{ width: '100%', marginBottom: 5 }}>Date de fin*</div>
                                            <ContainerInput>
                                                <TextFieldCustom
                                                    id={"merchandisingEndAt"}
                                                    variant="outlined"
                                                    value={moment(states.merchandisingEndAt).format().slice(0, 10)}
                                                    helperText={seeError ? errorMessage : null}
                                                    onChange={evt => {
                                                        setSeeError(true);
                                                        stateCallback("merchandisingEndAt", evt);
                                                    }}
                                                    inputProps={{
                                                        step: "1",
                                                        min: moment(states.merchandisingStartAt).format().slice(0, 10)
                                                    }}
                                                    name={"merchandisingEndAt"}
                                                    type={"date"}
                                                    error={seeError && errorMessage ? true : false}
                                                    style={{ width: '100%' }}
                                                />
                                            </ContainerInput>
                                        </GridSelect>
                                    </Box>
                                    <Grid container>
                                        <Button style={{ padding: "14px 32px", width: "100%" }} onClick={handleSaveInfos} disabled={checkSave()}>Modifier les informations</Button>
                                    </Grid>
                                </Box>
                            </BoxCustom>
                            <BoxCustom border={"0 !important"}>
                                <Subtitle variant="h3">Règle</Subtitle>
                                <Box mt={1.5} mb={1.5} width={"100%"}>
                                    <GridSelect item>
                                        {
                                            rules.length > 0 ?
                                                <>
                                                    <CustomSelect
                                                        labelId="version-select"
                                                        id="version-simple-select"
                                                        value={states.rule}
                                                        onChange={(evt) => {
                                                            stateCallback('rule', evt.target.value)
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <MenuItemCustom value={0} key={`rule-none`}>Aucune</MenuItemCustom>
                                                        {
                                                            rules.map((e, index) => {
                                                                return (
                                                                    <MenuItemCustom value={e.id} key={`rule-${index}`}>{e.code}</MenuItemCustom>
                                                                )
                                                            })
                                                        }
                                                    </CustomSelect>
                                                    <Grid container>
                                                        <Button style={{ width: "100%", padding: "14px 32px" }} onClick={applyRules} disabled={states.rule === "custom"}>Appliquer la règle</Button>
                                                    </Grid>
                                                </>
                                                : <Typography>Chargement...</Typography>
                                        }
                                    </GridSelect>
                                </Box>

                            </BoxCustom>
                        </Box>
                        <LayoutBuilder
                            isSublayout={false}
                            icomoon="ico-merchandising"
                            opened={openForm}
                            forClose={() => handleToggleDrawer('openForm', true)}
                            handlerSetup={() => { }}
                            dataLayout={replaceConfig(editType)}
                            drawerWidth={"85%"}
                            noPaddingTop={true}
                            allState={{ ...states, catalogId, currentLang, currentCat }}
                            stateCallback={stateCallback}
                            errorCallback={handleFormError}
                            noCancel={true}
                            // handleButtonGroupChange={this.handleButtonGroupChange}
                            validateButton={false}
                            handlerMutation={handlerMutation}
                        // deleteMutation={editType === "edit" ? () => {handleToggleDialog()} :  null}
                        // deleteText={'Supprimer le merchandising'}
                        // currentLang={currentLang}
                        // handleLang={this.handleLang}
                        />
                        <DialogModal
                            icon={true}
                            open={openDialog}
                            title={`Changement de la règle`}
                            secondaryAction={handleToggleDialog}
                            primaryAction={async () => { await handleChangeRule(); handleToggleDialog(); await handleGetProductsViaRules(); }}
                        >
                            Si vous validez cette modification de règle, l’organisation actuelle de vos produits risque d’être endommagée. Souhaitez-vous valider ce changement ?
                        </DialogModal>
                    </Grid>
                    : <PageLoader />
            }
            <ModalSearch
                openModal={openModal}
                resetSearchSkus={resetSearchSkus}
                searchSkus={searchSkus}
                stateCallbackSearch={stateCallbackSearch}
                searchProducts={searchProducts}
                currentProductsLoaded={currentProductsLoaded}
                currentProducts={currentProducts}
                changeProductPosition={changeProductPosition}
                states={states}
                saveNewSort={saveNewSort}
                searchSkuError={searchSkuError}
                searchLoading={searchLoading}
                currentProductsNotFound={currentProductsNotFound}
                preOrder={preOrder}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales,
        guideline: state.guideline,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
    }
};

export default withTranslation()(withApollo(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsMerchandisingDetail))));