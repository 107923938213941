import React, { useState } from "react";
import { useRouter } from 'next/router';

const MobileSearch = ({ spread = false }) => {
  const router = useRouter();
  const [search, setSearch] = useState('');

  return (
    <div className="offcanvas-mobile-search-area">
      <form action="#" onSubmit={(e) => {
        e.preventDefault();

        if (!spread)
          router.push(`search?s=${search}`);
          
        setSearch('');
      }}>
        <input type="search" placeholder="Rechercher..." value={search} onChange={e => {
          setSearch(e.target.value);
        }} />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
