import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import ReactPaginate from 'react-paginate';
import $ from "jquery";
import "./turn";
import Link from 'next/link';
import SoundAudio from './audio/stars_sound.mp3';
import SoundAudioCloche from './audio/cloche.mp3';
import notFound from "../../../../../not-found.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const LinkCustom = (props) => {
    if (props.spread) {
        return <>{ props.children}</>
    } else {
        return <Link href={props.href} as={props.as} shallow={true}>{props.children}</Link>
    }
};

class Turn extends React.Component {
    static defaultProps = {
        style: {},
        className: ""
    };

    state = { loading: true };

    componentDidUpdate(prev) {
        if (this.state.loading) return;
        
        if ((prev.heightMag !== this.props.heightMag || prev.widthMag !== this.props.widthMag) && this.el)
            $(this.el).turn('size', this.props.widthMag * 2, this.props.heightMag);

        if ((prev.page !== this.props.page) && this.el) {
            let converted = this.props.isCart ? this.props.page * 2 : (this.props.page + 1) * 2;
            $(this.el).turn('page', converted === 0 ? 1 : converted);
        }
    }

    componentDidMount() {
        if (this.el) {
            this.props.options.width    = Math.floor(this.props.widthMag * 2);
            this.props.options.height   = Math.floor(this.props.heightMag);
            setTimeout(() => {
                this.setState({
                    loading: false
                }, () => {
                    $(this.el).turn(this.props.options);

                    if (this.props.onReady)
                        this.props.onReady();
                })
            }, 1000);
        }

        document.addEventListener("keydown", this.handleKeyDown, false);
    }

    componentWillUnmount() {
        if (this.el) {
            $(this.el)
                .turn("destroy")
                .remove();
        }

        document.removeEventListener("keydown", this.handleKeyDown, false);
    }

    handleKeyDown = event => {
        if (event.keyCode === 37) {
            $(this.el).turn("previous");
        }

        if (event.keyCode === 39) {
            $(this.el).turn("next");
        }
    };

    render() {
        return (
            <div className="magazine-container">
                <div
                    className={this.props.className}
                    style={{ width: Math.floor(this.props.widthMag * 2), height: this.props.heightMag }}
                    ref={el => (this.el = el)}
                >
                    { this.props.children }
                </div>

                { this.state.loading && <div className="turn-overlay">
                    <p>{this.props.lang === "fr_FR" ? "Chargement" : "Cargando"}<span className="ellipsis-anim"><span>.</span><span>.</span><span>.</span></span></p>
                </div> }
            </div>
        );
    }
}

const FlipBook = (props) => {
    const [newArray, setNewArray] = useState(null);
    const [addSuccess, setAddSuccess] = useState(null);
    const [addSuccessNotModal, setAddSuccessNotModal] = useState(null);
    const [numberAdded, setNumberAdded] = useState(0);
    const [giftSelected, setGiftSelected] = useState([]);
    const [numberGift, setNumberGift] = useState(0);
    const [audio, setAudio] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [dimensions, setDimensions] = useState(null);
    const [messageDisplay, setMessageDisplay] = useState(false);
    const [messageAlreadyAddedDisplay, setMessageAlreadyAddedDisplay] = useState(false);
    const [pageToZoom, setPageToZoom] = useState(null);
    const [zoomModal, setZoomModal] = useState(false);
    const [heightParentRef0, setHeightParentRef0] = useState(0);
    const [widthParentRef0, setWidthParentRef0] = useState(0);
    const [heightParentZoom, setHeightParentZoom] = useState(0);
    const [widthParentZoom, setWidthParentZoom] = useState(0);
    const [currentPage, setCurrentPage] = useState(props.pagePosition);
    const [category, setCategory] = useState(null);
    const [update, setUpdate] = useState(0);
    const [timerRef, setTimerRef] = useState(0);
    const [turnReady, setTurnReady] = useState(false);
    const [isCart, setIsCart] = useState(props.isCartMode);
    const [turnReset, setTurnReset] = useState(0);
    const [modalProduct, setModalProduct] = useState(false);
    const [productSelected, setProductSelected] = useState(null);
    const [topPage, setTopPage] = useState(null);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [productIdBySku, setProductIdBySku] = useState(null);
    const [productRetailerUrl, setProductRetailerUrl] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [currentPages, setCurrentPages] = useState(null);
    const [isReadyMobile, setIsReadyMobile] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        pagePosition,
        resetCat,
        products,
        spread = false,
        readOnly,
        getCart,
        isCartMode,
        modal,
        openModal,
        forcePage,
        params,
        primaryColor,
        secondaryColor,
        switchValue,
        paramFlipbook,
        paramGifts,
        categories
    } = props;

    const parentZoom = useCallback(node => {
        if (node !== null) {
            setHeightParentZoom(node.getBoundingClientRect().height);
            setWidthParentZoom(node.getBoundingClientRect().width);
        }
    }, [dimensions, isReady, data]);

    const parentRef0 = useCallback(node => {
        if (node !== null) {
            if (isReadyMobile){
                let bounds = node.getBoundingClientRect();
    
                setHeightParentRef0(bounds.height);
                setWidthParentRef0(bounds.width);
                setIsReady(true);
            }else{
                setTimeout(() => {
                    let bounds = node.getBoundingClientRect();
    
                    setHeightParentRef0(bounds.height);
                    setWidthParentRef0(bounds.width);
                    setIsReady(true);
                }, 500);
            }
        }
    }, [dimensions, isReady, currentPage, currentPages, isReadyMobile]);

    const debounce = function (fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(function () {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        };
    }

    useEffect(() => {
        if (pagePosition) {
            setCategory(pagePosition);
            setCurrentPage(isMobile ? pagePosition - 1 : Math.round(pagePosition / 2) - 1);
            resetCat(null);
        }

        if (data) {
            setNewArray(data);

            if (data) {
                // Preload images for mobile
                for (let page of data) {
                    if (page?.media?.filePath) {
                        let img = new Image();
                        img.src = `${getMediaOrigin()}/${page.media.filePath}`;
                    }
                }
            }
        }

        let newDate = new Date().getTime();

        if (isCartMode !== isCart) {
            setIsCart(isCartMode);
            setTurnReset(newDate);
        }
        
        if (forcePage !== undefined && forcePage !== topPage) {
            setTopPage(forcePage);
            setCurrentPage(0);
        }
        setUpdate(newDate);
        
        if (isReady && !firstLoad){
            setFirstLoad(true);
            let startCat = categories.find(e => e.libelle === "LEGO City").attributes.category_flipbook_position.fr_FR
            setCurrentPage(isMobile ? startCat - 1 : Math.round(startCat / 2) - 1);
        }

        if (newArray) {
            setCurrentPages(newArray[currentPage])
        }
    }, [data, pagePosition, currentPage, isMobile, category, isCartMode, forcePage]);

    useEffect(() => {
        let list = localStorage.getItem('products');

        let initList = [];

        if (list)
            list = JSON.parse(list);

        if (!list || list.length === 0) {
            if (!isCart) {
                for (let i = 1; i <= 4; i++) {
                    initList.push({
                        product: null
                    })
                }
            } else {
                initList = []
            }
        } else {
            initList = list;
        }
        var audio = null;
        if(isCart){
            audio = new Audio(SoundAudioCloche);
        }else{
            audio = new Audio(SoundAudio);
        }
        audio.load()
        setAudio(audio)
        setGiftSelected(initList)

        if (!isCart){
            setNumberGift(initList.filter(e => e.product).length - 1);
        }
        
        getCart(initList)

        let newIsMobile = window.innerWidth < 768 ? true : false;

        if (newIsMobile) {
            setTurnReady(true);
        }

        setIsMobile(newIsMobile);

        const debouncedHandleResize = debounce(() => {
            let newIsMobile = window.innerWidth < 768 ? true : false;

            if (isMobile !== newIsMobile && !newIsMobile) {
                setTurnReady(false);
                setCurrentPage(0);
            }

            setIsMobile(newIsMobile);

            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 500);

        window.addEventListener('resize', debouncedHandleResize)
        document.getElementById('builder-template-scope-book').scrollIntoView({ behavior: 'instant', block: 'start' });
        return _ => window.removeEventListener('resize', debouncedHandleResize);
        
    }, []);

    function r2d(x) {
        return x / (Math.PI / 180);
    }
    
    function d2r(x) {
        return x * (Math.PI / 180);
    }
    
    function pop(start_x, start_y, particle_count) {
        let arr = [];
        let angle = 0;
        let particles = [];
        let offset_x = $("#dummy_debris").width() / 2;
        let offset_y = $("#dummy_debris").height() / 2;
    
        for (let i = 0; i < particle_count; i++) {
            let rad = d2r(angle);
            let x = Math.cos(rad) * (80 + Math.random() * 20);
            let y = Math.sin(rad) * (80 + Math.random() * 20);
            arr.push([start_x + x, start_y + y]);
            let z = $('<div class="debris" />');
            z.css({
                "left": start_x - offset_x,
                "top": start_y - offset_x
            }).appendTo($(".cart_wrapper"));
            particles.push(z);
            angle += 360 / particle_count;
        }
    
        $.each(particles, function (i, v) {
            $(v).show();
            $(v).animate({
                top: arr[i][1],
                left: arr[i][0],
                width: 4,
                height: 4,
                opacity: 0,
                zIndex: 8
            }, 800, function () {
                $(v).remove()
            });
        });
    }

    const addGift = (productId, isModal, e) => {
        let getProduct = products.find(e => e.sku === productId)
        let array = giftSelected;
        const found = array.find(element => element.product?.ref === productId);
        if (!found) {
            if (!isCart) {
                for (let item of array) {
                    if (item.product === null) {
                        item.product = {
                            ref: productId,
                            name: getProduct?.attributes?.product_name?.[props.lang] ? getProduct?.attributes?.product_name?.[props.lang] : Object.values(getProduct?.attributes?.product_name)[0],
                            image: getProduct?.attributes?.product_image?.[props.lang] ? getProduct?.attributes?.product_image?.[props.lang] : Object.values(getProduct?.attributes?.product_image)[0],
                        }
                        break;
                    }
                }
            } else {
                array.push(
                    {
                        product: {
                            qty: 1,
                            ref: productId,
                            // name: getProduct?.attributes?.product_name?.fr_FR,
                            // image: getProduct?.attributes?.product_image?.fr_FR,
                            // price: getProduct?.attributes?.product_price?.fr_FR || 0,
                        }
                    }
                )
            }
            setAddSuccessNotModal(true);
                setNumberAdded(numberAdded + 1);
                clearTimeout(timerRef);
                setTimerRef(setTimeout(function () {
                    setAddSuccessNotModal(false);
                }, 3000))
                setTimerRef(setTimeout(function () {
                    setNumberAdded(0);
                }, 3500))
            setGiftSelected([].concat(array));
            setMessageAlreadyAddedDisplay(false)
            audio.play();
            if (!isCart) {
                setNumberGift(numberGift + 1);
            }
            if (isModal) {
                setAddSuccess(true);
                if (!isCart) {
                    if (numberAdded < 4) {
                        setNumberAdded(numberAdded + 1);
                    }
                } else {
                    setNumberAdded(numberAdded + 1);
                }
                clearTimeout(timerRef);
                setTimerRef(setTimeout(function () {
                    setAddSuccess(false);
                }, 3000))
                setTimerRef(setTimeout(function () {
                    setNumberAdded(0);
                }, 3500))
            }

            localStorage.setItem('products', JSON.stringify(array));
            getCart(array)
        } else {
            if (isCart) {
                let getProduct = array.find(element => element.product?.ref === productId)
                getProduct.product.qty = found.product.qty + 1
                setGiftSelected([].concat(array));
                setAddSuccessNotModal(true);
                setNumberAdded(numberAdded + 1);
                clearTimeout(timerRef);
                setTimerRef(setTimeout(function () {
                    setAddSuccessNotModal(false);
                }, 3000))
                setTimerRef(setTimeout(function () {
                    setNumberAdded(0);
                }, 3500))
                localStorage.setItem('products', JSON.stringify(array));
                getCart(array)
                audio.play();
            } else {
                setMessageAlreadyAddedDisplay(true)
                setTimeout(function () {
                    setMessageAlreadyAddedDisplay(false)
                }, 3000)
            }
        }
        if(isCart){
            setModalProduct(false);
            let positionPanier = $( ".cart-icon" ).first();
            positionPanier = positionPanier.position();
            pop(positionPanier.left + 20, positionPanier.top + 10, 13);
        }
    };

    const messageError = () => {
        setMessageDisplay(true);
    };

    const renderModalProduct = (sku) => {
        setProductSelected(sku);
        setModalProduct(true);
    };

    const minusQty = (product, index) => {
        let array = giftSelected;
        if (product.qty === 1) {
            deleteGift(product, index)
        } else {
            for (let item of array) {
                if (item.product?.ref === product.ref) {
                    let getProduct = array.find(element => element.product?.ref === product.ref)
                    getProduct.product.qty = getProduct.product.qty - 1
                    break;
                }
            }
            setGiftSelected([].concat(array));
            localStorage.setItem('products', JSON.stringify(array))
            getCart(array)
        }
    }

    const addQty = (product) => {
        let array = giftSelected;
        for (let item of array) {
            if (item.product?.ref === product.ref) {
                let getProduct = array.find(element => element.product?.ref === product.ref)
                getProduct.product.qty = getProduct.product.qty + 1
                break;
            }
        }
        setGiftSelected([].concat(array));
        localStorage.setItem('products', JSON.stringify(array))
        getCart(array)
    }

    const deleteGift = (product, index) => {

        let array = giftSelected;
        for (let item of array) {
            if (item.product?.ref === product.ref) {
                if (isCart) {
                    array.splice(index, 1);
                    break;
                } else {
                    item.product = null
                    break;
                }
            }
        }
        // array[index].product = null

        setGiftSelected([].concat(array));
        setNumberGift(numberGift - 1);
        setMessageDisplay(false)
        array.find(e => e.product)
            ? localStorage.setItem('products', JSON.stringify(array))
            : localStorage.removeItem('products');
        getCart(array)
    }

    const clickZoom = (page) => {
        setPageToZoom(page)
        setZoomModal(true)
        if (document.getElementsByClassName('postCardWrapper').length > 0) {
            document.getElementsByClassName('postCardWrapper')[0].style.zIndex = "2";
        }
        document.getElementById('flipBook').style.zIndex = "3";
    }

    const getMediaOrigin = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // DEV
            return process.env.REACT_APP_MEDIAS;
        } else {
            // PROD
            return process.env.REACT_APP_SPREAD_CDN ? `${process.env.REACT_APP_SPREAD_CDN}/medias` : process.env.REACT_APP_MEDIAS;
        }
    };

    const validateCart = () => {
        if (spread)
            return;

        let sku = '';
        let qty = '';

        let values = giftSelected || [];

        if (values?.length === 0)
            return;

        values.forEach((product, i) => {
            let limiter = (i === values.length - 1) ? '' : ',';
            sku += `${product.product?.ref}${limiter}`;
            qty += `${product.product?.qty}${limiter}`;
        });

        window.open(`${process.env.REACT_APP_LINKED_MAGENTO}/addtocart/index/index?sku=${sku}&qty=${qty}`, '_blank');
    };

    let arrayCropColors = ['#cbeef3', '#26547c', '#06d6a0', '#1d3461', '#c2aff0', '#EA8C55', '#c75146', '#ad2e24', '#81171b', '#540804', '#db277e', '#ad099a', '#69059c', '#350575', '#001180'];

    let totalPages  = data.length;
    let alone       = isCart ? (totalPages - 1) % 2 : totalPages % 2;
    let doublePages = Math.floor(totalPages / 2) + alone;
    let totalPrice  = 0;

    if (isCart) {
        if (giftSelected.length > 0) {
            for (let product of giftSelected) {
                let getProduct = products.find(e => e.sku === product?.product?.ref);
                let productPrice = getProduct?.attributes?.special_price?.[props.lang] ? getProduct?.attributes?.special_price?.[props.lang] : Object.values(getProduct?.attributes?.special_price)[0] || getProduct?.attributes?.product_price?.[props.lang] ? getProduct?.attributes?.product_price?.[props.lang] : Object.values(getProduct?.attributes?.product_price)[0] || 0;
                totalPrice = totalPrice + (productPrice * product.product?.qty)
            }
        }
    }
    
    let productNameModal = null;
    let productImageModal = null;
    let productPriceModal = null;
    let productSpecialPriceModal = null;
    let productRetailerUrlModal = null;
    let productDescModal = null;

    let variantes = [];
    let selectedProduct = products.find(e => e.sku === productSelected);

    if (productSelected !== null && productSelected !== undefined) {
        productNameModal    = selectedProduct?.attributes?.product_name?.[props.lang] ? selectedProduct?.attributes?.product_name?.[props.lang] : Object.values(selectedProduct?.attributes?.product_name)[0];
        productImageModal   = selectedProduct?.attributes?.product_image?.[props.lang] ? selectedProduct?.attributes?.product_image?.[props.lang] : Object.values(selectedProduct?.attributes?.product_image)[0];
        productPriceModal   = +selectedProduct?.retailerPrice;
        productSpecialPriceModal = +selectedProduct?.retailerSpecialPrice;
        productRetailerUrlModal = selectedProduct?.retailerUrl;
        productDescModal    = selectedProduct?.attributes?.product_description?.[props.lang] ? selectedProduct?.attributes?.product_description?.[props.lang] : Object.values(selectedProduct?.attributes?.product_description)[0];

        variantes = [selectedProduct].concat(variantes);
    }

    const varianteOnChange = (event) => {
        let i = 0;
        for(let product of products){
            if(event.target.value === product.sku)
                setProductRetailerUrl(product.retailerUrl);
            i++;
        }
        setProductSelected(event.target.value);
    };

    const hasVariantes = variantes.length > 1;
    const prevDisabled = currentPage === 0;
    const nextDisabled = isMobile ? currentPage === totalPages - 1 : currentPage === doublePages - 1;
    return (
        <div className={readOnly ? "flipBook readOnly" : isCart ? "flipBook cart" : "flipBook"} id="flipBook" style={{backgroundColor: "#F8F9F9"}}>
            <div className={`successAdd ${addSuccessNotModal && !zoomModal ? 'show-alert' : 'hide-alert'}`}>
                {
                    props.lang === "fr_FR" ? 
                        <span>{`Vous avez ajouté ${numberAdded} produit${numberAdded > 1 ? 's' : ''}`}</span>
                    : <span>{`Has añadido ${numberAdded} producto${numberAdded > 1 ? 's' : ''}`}</span>
                }
                
            </div>
            <div onClick={() => {openModal(false); setModalProduct(false)}} className={modal ? "overlayModal open" : modalProduct ? "overlayModal open" : "overlayModal"}></div>
            <div onClick={() => {setModalProduct(false)}} className={modalProduct ? "overlayModal black open" : "overlayModal black"}></div>

            {
                modalProduct && isCart ? 
                    <div className={`modalProduct ${modalProduct ? 'show' : null}`}>
                        <span onClick={() => {setModalProduct(false)}} className="popup_detail_close">&times;</span>
                        <div className="productInfo">
                            <div className="wrapper">
                                <div className="modalLeft">
                                    <div className="imageProduct">
                                        <img src={productImageModal ? `${getMediaOrigin()}/${productImageModal}` : notFound} alt={`Modal Product`} />
                                    </div>
                                </div>
                                <div className="modalRight">
                                    <div className="titleProduct">
                                        {productNameModal}
                                    </div>
                                    <div className="price">
                                        Prix: <span className="amount">{(productSpecialPriceModal || productPriceModal)?.toFixed(2).toString().replace(".", ",")} €</span>
                                    </div>
                                    
                                    {
                                        productDescModal ?
                                            <div className="description">
                                                Description: <span dangerouslySetInnerHTML={{ __html: productDescModal }}></span>
                                            </div> : null
                                    }

                                    {
                                        hasVariantes && (
                                            <select className="variante-select" value={productSelected} onChange={varianteOnChange}>
                                                {
                                                    variantes.map((variante, i) => {
                                                        let productName     = variante?.attributes?.product_name?.[props.lang] ? variante?.attributes?.product_name?.[props.lang] : Object.values(variante?.attributes?.product_name)[0];
                                                        let productPrice    = +(variante?.attributes?.special_price?.[props.lang] ? variante?.attributes?.special_price?.[props.lang] : Object.values(variante?.attributes?.special_price)[0] || variante?.attributes?.product_price?.[props.lang] ? variante?.attributes?.product_price?.[props.lang] : Object.values(variante?.attributes?.product_price)[0] || 0);

                                                        return (
                                                            <option key={i} value={variante?.sku}>{variante?.sku} { productName } - { productPrice } € { variante?.stock < 1 && '- Rupture'}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        )
                                    }

                                    {/* {
                                        !oneProductHasStock && (
                                            <p>Non disponible</p>
                                        )
                                    } */}
                                </div>
                            </div>
                            <div className="actions">
                                {!props.spread && props.paramFlipbook?.isExternal?.value ?
                                    <button 
                                        className={`validate `} // ${hasVariantes && selectedProductHasStock ? '' : 'disabled'}
                                        disabled={false} // !hasVariantes || !selectedProductHasStock
                                        onClick= {() => addGift(productSelected)}
                                        style={{background: primaryColor ? primaryColor : null}}
                                    >
                                        Ajouter au panier
                                    </button>   
                                :
                                    <a 
                                        className={`validate_2 `} // ${hasVariantes && selectedProductHasStock ? '' : 'disabled'}
                                        disabled={false} // !hasVariantes || !selectedProductHasStock
                                        style={{background: primaryColor ? primaryColor : null}}
                                        href={productRetailerUrlModal}
                                        target="_blank"
                                        onClick={(e) => {
                                            if (spread)
                                                return e.preventDefault();
                                        }}
                                    >
                                        Voir le produit
                                    </a>
                                } 
                                <button className="cancel" onClick={() => setModalProduct(false)}>Annuler</button>
                            </div>
                        </div>
                    </div>
                : null
            }

            {
                isCart ?
                    <div className="minicart-container">
                        <div className={modal ? "minicart open" : "minicart"} style={{ top: spread ? 46 : 0 }}>
                            <div className="total" style={{background: primaryColor ? primaryColor : null}}>
                                Panier : <span>{totalPrice?.toFixed(2).toString().replace(".", ",") || 0} €</span>
                            </div>
                            <div className="listProducts">
                                {
                                    giftSelected.length > 0 ?
                                        giftSelected.map((product, index) => {
                                            let getProduct = products.find(e => e.sku === product?.product?.ref)
                                            let productName = getProduct?.attributes?.product_name?.[props.lang] ? getProduct?.attributes?.product_name?.[props.lang] : Object.values(getProduct?.attributes?.product_name)[0];
                                            let productImage = getProduct?.attributes?.product_image?.[props.lang] ? getProduct?.attributes?.product_image?.[props.lang] : Object.values(getProduct?.attributes?.product_image)[0];
                                            let productPrice = + (getProduct?.attributes?.special_price?.[props.lang] ? getProduct?.attributes?.special_price?.[props.lang] : Object.values(getProduct?.attributes?.special_price)[0] || getProduct?.attributes?.product_price?.[props.lang] ? getProduct?.attributes?.product_price?.[props.lang] : Object.values(getProduct?.attributes?.product_price)[0] || 0);

                                            return(
                                                <div className="productWrapper" key={`productWrapper${index}`}>
                                                    <div className="imageProduct">
                                                        {productImage && <img src={`${getMediaOrigin()}/${productImage}`} alt={`Product ${productName}`} />}
                                                    </div>
                                                    <div className="infosProduct">
                                                        <div className="name_product">{productName}</div>
                                                        <div className="numbers">
                                                            <div className="qty">
                                                                <span className="text">Qté : </span><div className="wrap-buttons"><button className="qtyButtons" onClick={() => minusQty(product.product, index)}>-</button><span className="value">{product.product?.qty}</span><button className="qtyButtons" onClick={() => addQty(product.product)}>+</button></div>
                                                            </div>
                                                            <div className="price_product">
                                                                {(productPrice * product.product?.qty)?.toFixed(2).toString().replace(".", ",")} €
                                                            </div>
                                                        </div>
                                                        <div className="delete_product" onClick={() => deleteGift(product.product, index)}>Supprimer ce produit</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    : <div>Pas encore de produit</div>
                                }
                            </div>
                            <div className="validateWrapper">
                                <button className={`validateCart ${giftSelected?.length ? '' : 'disabled'}`} onClick={validateCart} style={{background: primaryColor ? primaryColor : null}}>VALIDER MON PANIER</button>
                            </div>
                        </div>
                    </div>   
                    : null
            }


            <div className="container">
                <div className="container_choices" onMouseEnter={() => setHoverBlock(true)} onMouseLeave={() => setHoverBlock(false)}>
                    { !preview && hoverBlock && props.toolbarGiftBlock }
                    <div className="wrapperChoices">
                        {
                            giftSelected.map((gift, index) => {
                                // let image = paramGifts?.blocks.icon.inputs.value.value;
                                return (
                                    <div className="choiceBox" key={`ChoiceBox${index}`}>
                                        {gift.product ?
                                            <div className="selected_product">
                                                <div className="deleteProduct" onClick={() => deleteGift(gift.product, index)}></div>
                                                <div className="image_product">
                                                    {gift.product && gift.product.image && <img src={`${getMediaOrigin()}/${gift.product.image}`} alt={`Produit ${gift.product.name}`} />}
                                                </div>
                                                <div className="infos_product" title={gift.product.ref + ' - ' + gift.product.name}>
                                                    {gift.product.ref + ' - ' + gift.product.name}
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="empty" style={{backgroundColor: "white"}} onClick={spread ? () => inputCallback(id, 'icon') : null} >
                                            <span style={{color: paramGifts?.inputs['backgroundColorGiftTexte'].value}}>{index + 1}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={`message_displayed ${messageAlreadyAddedDisplay && !isCart ? 'show-alert' : 'hide-alert'}`}>{props.lang === "fr_FR" ? "Vous avez déjà ajouté ce produit" : "Ya has agregado este producto"}</div>
                    <div className={`message_displayed ${messageDisplay && !isCart ? 'show-alert' : 'hide-alert'}`}>{props.lang === "fr_FR" ? "Vous avez déjà 4 produits, vous pouvez encore modifier vos choix." : "Ya tiene 4 productos, aún puede modificar sus opciones."}</div>
                </div>
            </div>

            <div className="container">
                <div className="game">
                    {
                        turnReady && (
                            <div className="prevPageWrapper">
                                <KeyboardArrowLeftIcon 
                                    className={ prevDisabled ? "pageButton disabled" : "pageButton" }
                                    style={{
                                        borderColor: 'white', 
                                        color: '#D70008',
                                        background: 'white'
                                    }}
                                    disabled={prevDisabled}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (!turnReady)
                                            return;

                                        if (currentPage !== 0)
                                            setCurrentPage(currentPage - 1);
                                    }}    
                                />
                            </div>
                        )
                    }

                    {
                        turnReady && (
                            <div className="nextPageWrapper">
                                <KeyboardArrowLeftIcon 
                                    className={ nextDisabled ? "pageButton disabled" : "pageButton" }
                                    style={{
                                        borderColor: 'white', 
                                        color: '#D70008',
                                        background: "white"
                                    }}
                                    disabled={nextDisabled}
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        if (!turnReady)
                                            return;

                                        if (!nextDisabled)
                                            setCurrentPage(currentPage + 1);
                                    }}
                                />
                                
                            </div>
                        )
                    }
                    
                    {
                        !isMobile && data && data.length > 0 && isReady && heightParentRef0 && heightParentRef0 > 0 ? (
                            <Turn
                                className="magazine"
                                isMobile={isMobile}
                                heightMag={heightParentRef0}
                                widthMag={widthParentRef0}
                                page={currentPage}
                                isCart={isCart}
                                onReady={() => setTurnReady(true)}
                                key={turnReset}
                                lang={props.lang}
                                options={{
                                    elevation: 90,
                                    gradients: true,
                                    autoCenter: true,
                                    acceleration: true,
                                    turnCorners: 'tl,tr',
                                    duration: 200,
                                    page: isCart ? 1 : 2,
                                    display: 'double',
                                    when: {
                                        start: function (event, pageObject, corner) {
                                            if (!isCart && pageObject.next === 1)
                                                event.preventDefault();
                                        },
                                        turning: function (event, page, view) {
                                            if (!isCart && page === 1)
                                                event.preventDefault();
                                        },
                                        turned: (event, page, view) => {
                                            let converted = isCart
                                                ? Math.round((page - 1) / 2)
                                                : Math.round((page - 3) / 2);

                                            if (isCart && page === 1) {
                                                setCurrentPage(0);
                                            } else if (!isCart && page === 3) {
                                                setCurrentPage(0);
                                            } else if (converted !== currentPage) {
                                                setCurrentPage(converted);
                                            }
                                        }
                                    }
                                }}
                            >
                                <>
                                    { !isCart ? (<div className="fake" key="fake">Fake</div>) : null }
                                </>

                                <>
                                    {
                                        data.map((page, index) => {
                                            let imageSource = `${getMediaOrigin()}/${page.media.filePath}`;

                                            return (
                                                <div className={isMobile ? 'page mobile' : 'page desktop'} key={`PageFlipbook${index}`}>
                                                    { turnReady && <div className="zoomPage" onClick={() => clickZoom(page)}><VisibilityIcon style={{fill: '#fff'}} /></div> }

                                                    <div style={{ height: heightParentRef0, width: widthParentRef0 }}>
                                                        <img src={imageSource} key={imageSource} alt="Page flipbook" />
                                                    </div>

                                                    {
                                                        turnReady && currentPages && page.parsedHtmlContent?.length > 0 ?
                                                            page.parsedHtmlContent.map((crop, indexCrop) => {
                                                                if (crop.crop.value) {
                                                                    let ratioWidth = crop.crop.value.dataCrop.width / crop.crop.value.imageData.naturalWidth;
                                                                    let ratioHeight = crop.crop.value.dataCrop.height / crop.crop.value.imageData.naturalHeight;

                                                                    let ratioX = crop.crop.value.dataCrop.x / crop.crop.value.imageData.naturalWidth;
                                                                    let ratioY = crop.crop.value.dataCrop.y / crop.crop.value.imageData.naturalHeight;

                                                                    let imageWidth = widthParentRef0;
                                                                    let imageHeight = heightParentRef0;
                                                                    
                                                                    if (!props.spread && props.paramFlipbook?.isExternal?.value) {
                                                                        return (
                                                                            <div className="cropPage" 
                                                                                onClick={() => {
                                                                                    if (!props.spread && props.paramFlipbook?.isExternal?.value) {
                                                                                        // /!\ Il faut itérer sur prodcuts => props.products[i]?.attributes.retailerUrl; 
                                                                                        try { window.parent.location.href = productRetailerUrl; }
                                                                                        catch (e) { console.log(e); }
                                                                                    }
                                                                                }}
                                                                                style={{
                                                                                    width: imageWidth * ratioWidth,
                                                                                    height: imageHeight * ratioHeight,
                                                                                    top: imageHeight * ratioY,
                                                                                    left: imageWidth * ratioX,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    background: spread ? arrayCropColors[indexCrop] : 'none',
                                                                                }} 
                                                                                key={`CropElement${indexCrop}`}><AddCircleIcon className="addIcon" style={{fill: '#01a23a', background: 'white', borderRadius: '90%', width: '1.3em', height: '1.3em'}} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <div className="cropPage" 
                                                                            onClick={() => 
                                                                                numberGift === 3 
                                                                                    ? messageError() 
                                                                                    : !crop.product.value 
                                                                                        ? null 
                                                                                        : isCart 
                                                                                            ? renderModalProduct(crop.product.value?.item?.sku) 
                                                                                            : addGift(crop.product.value?.item?.sku)
                                                                            } 
                                                                            style={{
                                                                                width: imageWidth * ratioWidth,
                                                                                height: imageHeight * ratioHeight,
                                                                                top: imageHeight * ratioY,
                                                                                left: imageWidth * ratioX,
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                background: spread ? arrayCropColors[indexCrop] : 'none',
                                                                            }} 
                                                                            key={`CropElement${indexCrop}`}><AddCircleIcon className="addIcon" style={{fill: '#01a23a', background: 'white', borderRadius: '90%', width: '1.3em', height: '1.3em'}} />
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            </Turn>
                        ) : null
                    }

                    {
                        isMobile && currentPages ?
                            <div className="container_flipbook">
                                <div className="page_flipbook" id={`pageFlipbook${0}`} ref={parentRef0} key={`${getMediaOrigin()}/${currentPages.media.filePath}`}>
                                    <div className="zoomPage" onClick={() => clickZoom(currentPages)}><VisibilityIcon style={{fill: '#fff'}} /></div>
                                    <img src={`${getMediaOrigin()}/${currentPages.media.filePath}`} onLoad={() => setIsReadyMobile(true)} alt="Page flipbook" />
                                    {
                                        currentPages.parsedHtmlContent?.length > 0 && heightParentRef0 > 0 ?
                                            currentPages.parsedHtmlContent.map((crop, indexCrop) => {
                                                if (crop.crop.value) {
                                                    let ratioWidth = crop.crop.value.dataCrop.width / crop.crop.value.imageData.naturalWidth;
                                                    let ratioHeight = crop.crop.value.dataCrop.height / crop.crop.value.imageData.naturalHeight;

                                                    let ratioX = crop.crop.value.dataCrop.x / crop.crop.value.imageData.naturalWidth;
                                                    let ratioY = crop.crop.value.dataCrop.y / crop.crop.value.imageData.naturalHeight;

                                                    let imageWidth = widthParentRef0;
                                                    let imageHeight = heightParentRef0;

                                                    return (
                                                        <div className="cropPage" 
                                                            onClick={
                                                                () => numberGift === 3 
                                                                    ? messageError() 
                                                                    : !crop.product.value 
                                                                        ? null 
                                                                        : isCart 
                                                                            ? renderModalProduct(crop.product.value?.item?.sku) 
                                                                            : addGift(crop.product.value?.item?.sku)
                                                            }
                                                            style={{
                                                                width: imageWidth * ratioWidth,
                                                                height: imageHeight * ratioHeight,
                                                                top: imageHeight * ratioY,
                                                                left: imageWidth * ratioX,
                                                                background: spread ? arrayCropColors[indexCrop] : 'none',
                                                            }} 
                                                            key={`CropElement${indexCrop}`}>
                                                                <AddCircleIcon className="addIcon" style={{fill: '#01a23a', background: 'white', borderRadius: '90%', width: '1.3em', height: '1.3em'}} />
                                                        </div>
                                                    )
                                                }
                                            })
                                        : null
                                    }
                                </div>
                            </div>
                            : 
                            null
                    }

                    {
                        !isMobile && data && data.length > 0 ?
                            <div className="magazine hidden">
                                <div className="page" ref={parentRef0} key={`PageFlipbook${0}`}>
                                    <img src={`${getMediaOrigin()}/${data[0]?.media.filePath}`} alt="Page cachée gauche" />
                                </div>
                                <div className="page" key={`PageFlipbook${1}`}>
                                    <img src={`${getMediaOrigin()}/${data[1]?.media.filePath}`} alt="Page cachée droite" />
                                </div>
                                <div className="white" />
                            </div>
                        : null
                    }
                </div>
            </div>

            <div className="wrapperButton">
                <LinkCustom spread={spread} href="/register">
                    <a className={`buttonValidation ${numberGift === 3 ? "" : "disabled"} `} onClick={(e) => {
                        if (numberGift < 3)
                            e.preventDefault();
                    }}>{props.paramFlipbook.blocks.button.inputs.value.value}</a>
                </LinkCustom>
            </div>
            
            {
                turnReady && (
                    <ReactPaginate
                        previousLabel={
                            <div className="prevPageWrapper">
                                <button
                                    className="pageButton"
                                    style={{borderColor: primaryColor ? primaryColor : null}}
                                    disabled={currentPage === 0}
                                />
                                <div style={{marginLeft: 16, display:"inline-block"}}>{props.lang === "fr_FR" ? "Page précedente" : "Página anterior"}</div>
                            </div>
                        }
                        nextLabel={
                            <div className="nextPageWrapper">
                                <div style={{marginRight: 16, display:"inline-block"}}>{props.lang === "fr_FR" ? "Page suivante" : "Página siguiente"}</div>
                                <button
                                    className="pageButton"
                                    style={{borderColor: primaryColor ? primaryColor : null}}
                                    disabled={isMobile ? currentPage === totalPages - 1 : currentPage === doublePages - 1}
                                />
                            </div>
                        }
                        style={{background: primaryColor ? primaryColor : null}}
                        style={{color: primaryColor ? primaryColor : null}}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={isMobile ? totalPages : doublePages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={(page) => {
                            setCurrentPage(page.selected);
                        }}
                        initialPage={currentPage}
                        forcePage={currentPage}
                        pageClassName={switchValue ? 'white' : 'black'}
                        breakLinkClassName={switchValue ? 'white' : 'black'}
                        containerClassName={'turn-pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        key={update}
                    />
                )
            }

            <div className="modalZoom" style={{ display: zoomModal ? 'block' : 'none' }}>
                {
                    pageToZoom ?
                        <div className="contentPage" >
                            <div className="overlay" onClick={() => setZoomModal(false)}></div>
                            <div className={`successAdd ${addSuccess ? 'show-alert' : 'hide-alert'}`}><span>{props.lang === "fr_FR" ? `Vous avez ajouté ${numberAdded} produit${numberAdded > 1 ? 's' : ''}` : `Has añadido ${numberAdded} producto${numberAdded > 1 ? 's' : ''}`}</span></div>
                            <div className={`message_displayed ${messageAlreadyAddedDisplay && !isCart ? 'show-alert' : 'hide-alert'}`}>{props.lang === "fr_FR" ? "Vous avez déjà ajouté ce produit" : "Ya has agregado este producto"}</div>
                            <div className={`message_displayed ${messageDisplay && !isCart ? 'show-alert' : 'hide-alert'}`}>{props.lang === "fr_FR" ? "Vous avez déjà 4 produits, vous pouvez encore modifier vos choix." : "Ya tiene 4 productos, aún puede modificar sus opciones."}</div>
                            <div className="wrapper_page" ref={parentZoom}>
                                <img src={`${getMediaOrigin()}/${pageToZoom.media.filePath}`} alt="Page zoom modale" />
                                {
                                    pageToZoom.parsedHtmlContent?.length > 0 ?
                                        pageToZoom.parsedHtmlContent.map((crop, indexCrop) => {
                                            if (crop.crop.value) {

                                                let ratioWidth = crop.crop.value.dataCrop.width / crop.crop.value.imageData.naturalWidth;
                                                let ratioHeight = crop.crop.value.dataCrop.height / crop.crop.value.imageData.naturalHeight;

                                                let ratioX = crop.crop.value.dataCrop.x / crop.crop.value.imageData.naturalWidth;
                                                let ratioY = crop.crop.value.dataCrop.y / crop.crop.value.imageData.naturalHeight;

                                                let imageWidth = widthParentZoom || 0;
                                                let imageHeight = heightParentZoom || 0;

                                                return (
                                                    <div 
                                                        className="cropPage" 
                                                        onClick={() => {
                                                            return numberGift === 3 
                                                                ? messageError() 
                                                                : !crop.product.value 
                                                                    ? null 
                                                                    : isCart 
                                                                        ? renderModalProduct(crop.product.value.item?.sku) 
                                                                        : addGift(crop.product.value.item?.sku, true)
                                                        }} 
                                                        style={{
                                                            width: imageWidth * ratioWidth,
                                                            height: imageHeight * ratioHeight,
                                                            top: imageHeight * ratioY,
                                                            left: imageWidth * ratioX,
                                                            background: spread ? arrayCropColors[indexCrop] : 'none',
                                                        }} key={`CropElement${indexCrop}`}><AddCircleIcon className="addIcon" style={{fill: '#01a23a', background: 'white', borderRadius: '90%', width: '1.3em', height: '1.3em'}} /></div>
                                                )
                                            }
                                        })
                                        : null
                                }
                                <div className="wrapper_buttonClose">
                                    <button onClick={() => setZoomModal(false)}>{props.lang === "fr_FR" ? "Fermer" : "Cerrar"}</button>
                                </div>
                            </div>
                        </div>
                    : null
                }
            </div>
        </div>
    );
};

FlipBook.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string,
    noSticky: PropTypes.bool
};

export default FlipBook;