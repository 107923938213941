import React, { useEffect, useState } from "react";
import Div from '../shared/div';
import H2 from '../shared/h2';
import H4 from '../shared/h4';
import P from '../shared/p';
import Button from '../shared/a';
import Img from '../shared/img';
import { ReactComponent as Picto } from './icono-map.svg';
import { PictureTag } from "../../../../../utils/image";

const BlocHomeStorelocator = (props) => {
    const [hover, setHover] = useState(false);

    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;

    let image = data.blocks.image.inputs.value.value;
    let alt = data.blocks.image.inputs.alt?.value ?? "Illustration";

    return (
        <Div style={{position: 'relative'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            { hover && !preview && props.children }
            <Div className="storelocator-bloc-home">
                <Div>
                    <Div>
                        <H2 color={data.blocks.title?.inputs?.color?.value} className="uppercase">{data.blocks.title?.inputs?.value?.value}</H2>
                        <H2 className="din-offc" color={data.blocks.subtitle?.inputs?.color?.value}>{data.blocks.subtitle?.inputs?.value?.value}</H2>
                        <P color={data.blocks.text?.inputs?.color?.value}>{data.blocks.text?.inputs?.value?.value}</P>
                        <Div>
                            <Button href={data.blocks.link.inputs.link.value} className="little reverseOnResponsive"><Picto /> {data.blocks.link.inputs.value.value}</Button>
                        </Div>
                    </Div>
                    <Div className="hide-mobile hide-tablet">
                        <PictureTag
                            media={image}
                            alt={alt}
                            spread={spread}
                            classes="w100"
                            lazy={true}
                            isOptimised={data.inputs.optimisedImage?.value}
                        />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default BlocHomeStorelocator;