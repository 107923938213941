import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/ui/button/Button';
import colors from '../../../config/theme/colors';
import Fab from '@material-ui/core/Fab';
import { red } from '@material-ui/core/colors';
import styled from 'styled-components';
import Textarea from './Textarea';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 15,
    '&>div':{
      '&>div':{
        margin: '16px 0',
        boxShadow: 'inherit',
        '&:first-child':{
            marginTop: 0,
        },
        '&:before':{
            content: 'inherit',
        }
      }
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '24px'
  },
  dragIndicator: {
    marginRight: 10,
    cursor: 'pointer'
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  Accordion: {
    flexDirection: 'column',
    background: '#F8F8F8',
  },
  input: {
    marginBottom: theme.spacing(1),
    borderRadius:0,
    '& > div':{
      borderRadius:0,
      '& > div':{
        borderRadius:0,
      },
    },
  },
  actionBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10
  },
  delete: {
    marginTop: theme.spacing(1),
    width: 150,
    alignSelf: 'flex-end'
  }
}));

const DeleteButton = withStyles(theme => ({
  root: {
      color: red[500],
      backgroundColor: 'transparent',
      minHeight: 10,
      alignSelf: 'flex-end',
      textTransform: 'inherit',
      borderRadius: 0,
      width: 'auto',
      boxShadow: 'inherit',
      '&:hover': {
          color: red[700],
          backgroundColor: 'transparent',
      }
  },
}))(Fab);

const AccordionSummaryCustom = styled(AccordionSummary)`
    background: #F8F8F8;
    &>div{
        align-items: center;
        p{
            color: ${colors.menu.regular};
        }
        svg{
            fill: ${colors.regular};
        }
    }
`;

const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return <MenuIcon className={classes.dragIndicator} />;
});

const ItemInput = ({ id, item, label, identifier, onChange, items, classes, isWysiwyg = false } ) => {
  return (
    <>
      <Typography gutterBottom style={{fontSize: 14}}>
        {label}
      </Typography>
      {
        isWysiwyg ? (
          <Textarea
            key={`${id}-${identifier}`}
            value={item[identifier]}
            onChange={(content) => {
              console.log('content', content);
              item[identifier] = content;
              onChange(items);
            }}
          />
        ) : (
          <TextField 
            variant="outlined" 
            value={item[identifier]}
            className={classes.input}
            onChange={(event) => {
              item[identifier] = event.target.value;
              onChange(items);
            }}
            style={{borderRadius: 0}}
          />
        ) 
      }
      
    </>
  );
};

const SortableItem = SortableElement(({ items, onChange, panelChange, expanded, item, id }) => {
  const classes = useStyles();

  return <Accordion expanded={expanded === `panel-${id}`} onChange={panelChange(`panel-${id}`)} style={{borderRadius: '0!important', boxShadow: 'inherit'}}>
    <AccordionSummaryCustom expandIcon={<ExpandMoreIcon />}>
      <DragHandle />
      <Typography className={classes.heading}>{item.identifier}</Typography>
    </AccordionSummaryCustom>
    <AccordionDetails className={classes.Accordion}>
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Identifiant" identifier="identifier" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien jeu" identifier="linkGame" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien thème #1" identifier="link1" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien thème #2" identifier="link2" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien thème #3" identifier="link3" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien thème #4" identifier="link4" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien Facebook" identifier="fbShare" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Lien Twitter" identifier="twitterShare" /> 
      <ItemInput id={id} item={item} items={items} onChange={onChange} classes={classes} label="Règlement" identifier="reg" isWysiwyg={true} /> 

      {
        item.readonly ? null : (
          <DeleteButton color="secondary" size="small" aria-label="delete" className={classes.button} onClick={() => { onChange(items.filter(e => e.id !== item.id));}}>
              <DeleteIcon style={{width: 16}} />
              Supprimer
          </DeleteButton>
        )
      }
    </AccordionDetails>
  </Accordion>
});

const SortableList = SortableContainer(({ items, onChange, panelChange, expanded }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem 
          items={items} 
          onChange={onChange} 
          panelChange={panelChange}
          key={`item-${index}`} 
          item={value} 
          index={index}
          id={index}
          expanded={expanded}
        />
      ))}
    </div>
  );
});

export default function GameRetailerInformation({ value, onChange }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const panelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    onChange(arrayMove(value, oldIndex, newIndex));
  };

  const addRetailerItem = () => {
    value.push({
      id: uuidv4(),
      identifier: '[identifier]',
      linkGame: '/',
      link1: '/',
      link2: '/',
      link3: '/',
      link4: '/',
      fbShare: '/',
      twitterShare: '/',
      reg: null
    });

    onChange(value);
  };

  return (
    <div className={classes.root}>
      <SortableList 
        items={value} 
        onChange={onChange} 
        onSortEnd={onSortEnd} 
        panelChange={panelChange} 
        expanded={expanded}
        useDragHandle 
        helperClass='sortable-helper'
      />
      <div className={classes.actionBar}>
          <Button
              label="Ajouter"
              style={{backgroundColor: colors.menu.regular, color: "white", boxShadow: `0px 3px 0px 0px ${colors.menu.darker}`, textTransform: 'initial', width: '100%'}}
              onClick={addRetailerItem}
          >
              <AddIcon style={{fontSize: 16, fill: colors.white, marginRight: 7.5}} /> <span style={{fontSize: 17}}>Ajouter une entrée</span>
          </Button>
      </div>
    </div>
  );
}
