import { getDefaultTextSimple, getDefaultTextarea, getDefaultButton } from '../../helpers';
import { EditableTypes, FormInputTypes } from '../../../shareable/types';
import { v4 as uuidv4 } from "uuid";

export default {
    name: "Video only",
    blocks: {
        media: {
            title: "Vidéo",
            name: "Vidéo",
            type: EditableTypes.IMAGE,
            id: uuidv4(),
            inputs: {
                value: {
                    type: FormInputTypes.VIDEO,
                    value: null
                },
            }
        },
    }
};