import { v4 as uuidv4 } from "uuid";
import { FormInputTypes, EditableTypes, ItemTypes } from '../../../shareable/types';
import { getDefaultText, getDefaultTextarea, getImageDefault, getOfferPole } from '../../helpers';

export default {
    paddingTop: 60,
    paddingBottom: 0,
    name: 'Équipe',
    inputs: {
        primaryColor: {
            label: 'Couleur principal de la slide',
            type: FormInputTypes.COLOR,
            value: '#70aecf',
        },
        secondaryColor: {
            label: 'Couleur secondaire de la slide',
            type: FormInputTypes.COLOR,
            value: '#FFFFFF',
        }
    },
    blocks: {
        category: getDefaultText('Titre de la catégorie', "x. Titre de la partie", 16, null, null, null, null),
        sub_category: getDefaultText('Titre de la catégorie', "- Sous titre", 16, null, null, null, null),
        polesBlocks: {
            id: uuidv4(),
            title: "Pôle",
            name: "Pôle",
            type: EditableTypes.MULTIPLE_BLOCKS,
            subtype: ItemTypes.OFFER_TRIPLE_BLOCK_LIST_POLE,
            limit: 3,
            config: [
                getOfferPole(),
            ]
        },
    }
};

