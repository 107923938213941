import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import styled from 'styled-components';

import colors from '../../../../../config/theme/colors';

const TabsCustom = styled(Tabs)`
  button{
    text-transform: initial;
    & > span:first-child{
      flex-direction: row;
    }
  }
  &> div > span{
    height: 3px;
    background-color: ${colors.blue.darker.hue300};
  }
`;

const TabStyled = styled(Tab)`
  .MuiTab-wrapper{
    text-transform: capitalize;
  }
`

const useStyles = makeStyles({
  root: {
    marginTop: '-1rem',
    flexGrow: 1,
    boxShadow: 'inherit',
    background: 'white',
    borderBottom: `1px solid ${colors.grey.lighter.hue800}`,
    paddingBottom: '0 !important',
  },
});

export default function TabCatalogs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCatalogTypeName = (identifier) => {
    switch(identifier) {
      case 'permanent':
          return 'Permanent';
      case 'ephemeral':
        return 'Éphémère';
      case 'thematic':
        return 'Thématique';
      default: return 'n/a';
    }
  };

  return (
    <Paper className={classes.root}>
      <TabsCustom
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab onClick={(event) => { props.handleChangeTab(event, 0, false, 'Tout voir' );}} label='Tout voir' />
        {
          props.catalogs ? 
            props.catalogs.map((catalog, index) => {
              return(
                <TabStyled 
                onClick={(event) => { props.handleChangeTab(event, index + 1, catalog.node.id, catalog.node.identifier );}} 
                label={getCatalogTypeName(catalog.node.identifier)} 
                key={`TabCatalog${index}`}
                />
              )}    
            ) : ''
        }
      </TabsCustom>
    </Paper>
  );
}